import React, { FC, useCallback, useContext } from 'react';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../../../autoDiscoveryWizardContext';
import { CredsStatus } from '../../../../../Fmsd/FmsdComponents';
import { BigidColorsV2, BigidLink } from '@bigid-ui/components';
import { BigidInfoFilledIcon } from '@bigid-ui/icons';
import { AutoDiscoverAsideDsList } from './AutoDiscoverAsideDsList';
import { TYPE_TO_PERM_DOCS_LINK, cloudProviderLabel } from '../../../../constants';
import { useLocalTranslation } from '../../../../translations';
import { AccordionInfoPanel } from '../../AutoDiscoveryComponents/AccordionInfoPanel/AccordionInfoPanel';
import { AccordionInfoPanelVariants } from '../../AutoDiscoveryComponents/AccordionInfoPanel/styles';

export const AutoDiscoverAsidePermissionsSummary: FC = () => {
  const { discoveryConfigData, setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const visible = discoveryConfigData?.connectionInfo?.credsStatus === CredsStatus.VALID;
  const expanded = discoveryConfigData.asideExpandedState === AutoDiscoverWizardAsideExpandedStates.PERMISSIONS_SUMMARY;
  const onChange = useCallback(() => {
    setDiscoveryConfigData(current => ({
      ...current,
      asideExpandedState: expanded
        ? AutoDiscoverWizardAsideExpandedStates.MINIMIZED
        : AutoDiscoverWizardAsideExpandedStates.PERMISSIONS_SUMMARY,
    }));
  }, [expanded, setDiscoveryConfigData]);

  return (
    visible &&
    discoveryConfigData.formData.authType && (
      <AccordionInfoPanel
        dataAid={'AutoDiscoverAsidePermissionsSummary'}
        title={t('configModal.aside.dsList.title')}
        variant={AccordionInfoPanelVariants.info}
        titleIconColor={BigidColorsV2.blue[700]}
        titleIcon={BigidInfoFilledIcon}
        onChange={onChange}
        expanded={expanded}
      >
        <AutoDiscoverAsideDsList />
        <div>
          {t('configModal.aside.dsList.info', { type: cloudProviderLabel[discoveryConfigData.type] })}{' '}
          <BigidLink
            text={t('configModal.buttons.learnMoreDsStatus')}
            href={TYPE_TO_PERM_DOCS_LINK[discoveryConfigData.type]}
            shouldOpenNewTab
          />
          .
        </div>
      </AccordionInfoPanel>
    )
  );
};
