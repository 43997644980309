import { ReactText } from 'react';
import { BigidFilter, BigidSelectOption, objectToQueryString, QueryParams } from '@bigid-ui/components';
import { httpService } from '../../services/httpService';
import { DateISO8601 } from '../../types/types';
import { getUsersQuery, getOptionsFromSystemUsers } from '../../utilities/systemUsersUtils';
import { systemUsersService } from '../../services/angularServices';
import { TagEntity } from '../TagsManagement/TagsManagementService';
import { notificationService } from '../../services/notificationService';
import { GlossaryItem } from './CuratedAttributes/actions/types';
import { userPreferencesService } from '../../services/userPreferencesService';
import { CONFIG } from '../../../config/common';
import { CurationGuidedTourStageId, CurationGuidedTourStageStatus } from './useCurationState';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';

export enum ScannerTypeGroup {
  STRUCTURED = 'structured',
  UNSTRUCTURED = 'unstructured',
  EMAIL = 'email',
}

export enum DetailedObjectType {
  STRUCTURED = 'STRUCTURED',
  STRUCTURED_FILE = 'STRUCTURED_FILE',
  PARTITIONED_TABLE = 'PARTITIONED_TABLE',
  UNSTRUCTURED = 'UNSTRUCTURED',
  APP = 'APP',
}

export const structuredObjectsTypes = [
  DetailedObjectType.STRUCTURED,
  DetailedObjectType.STRUCTURED_FILE,
  DetailedObjectType.PARTITIONED_TABLE,
];

export enum CuratedFieldStatus {
  UNCURATED = 'Uncurated',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum SamplingStatus {
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  NEVER_SAMPLED = 'NEVER_SAMPLED',
}

export enum CuratedAttributeType {
  CLASSIFIER = 'Classification',
  CLASSIFICATION_MD = 'ClassificationMd',
  CORRELATION = 'IDSoR Attribute',
  DOC_CLASSIFICATION = 'ClassificationDoc',
  MANUAL = 'Manual',
}

export enum ClassifierType {
  DOC = 'DOC',
  NER = 'NER',
  MD = 'MD',
}

export type CurationStatus = {
  curatedCount: number;
  totalCount: number;
};

export type CurationStatusPerAttribute = CurationStatus & {
  source: string;
};

export type CurationStatusPerField = CurationStatus & {
  fieldName: string;
};

export type CurationStatusResponse<EntityStatus = CurationStatus> = {
  statusPerEntity: EntityStatus[];
  curationStatus: CurationStatus;
};

export type CuratedDataSource = {
  source: string;
  sourceType: string;
  scannerTypeGroup: ScannerTypeGroup;
  totalAttributes: number;
  curatedAttributes: number;
  totalFindings: number;
  owner: string;
  samplingStatus: SamplingStatus;
  scanId: string;
  parentScanId?: string;
  isSupported?: boolean;
  collaborator?: string;
};

export type CuratedObjectFields = {
  containers?: BigidSelectOption[];
  objectNames?: BigidSelectOption[];
  fieldNames?: BigidSelectOption[];
};

export interface LegacyFriendlyName extends Omit<GlossaryItem, 'glossary_id'> {
  glossaryId: string;
}

export interface CuratedAttributeFriendlyName {
  glossaryId: string;
  friendlyName: string;
  originalName: string;
  description: string;
}

export interface LegacyCategoryDetails {
  color: string;
  display_name: string;
  unique_name: string;
  description: string;
  glossary_id: string;
}

export interface CategoryDetails extends Omit<LegacyCategoryDetails, 'display_name' | 'unique_name' | 'glossary_id'> {
  displayName: string;
  uniqueName: string;
}

export type CuratedDataSourceKeys = Array<keyof CuratedDataSource>;

export type CuratedAttribute = {
  attributeName: string;
  displayName: string;
  attributeType: CuratedAttributeType;
  classifierType: ClassifierType;
  totalFields: number;
  curatedFields: number;
  rejectedCount: number;
  approvedCount: number;
  excludedValuesCount: number;
  totalFindings: number;
  isCompletelyCurated: boolean;
  precision: number;
  friendlyName: CuratedAttributeFriendlyName;
  categories: CategoryDetails[];
  scannerTypeGroup: ScannerTypeGroup;
};

export type CuratedAttributeIdentifier = Pick<CuratedAttribute, 'attributeName' | 'attributeType'>;

export type CuratedAttributeKeys = Array<keyof CuratedAttribute>;

export type ObjectProperty = {
  name: string;
  value: string;
  type?: string;
};

export enum ConfidenceLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type CuratedField = {
  id: ReactText;
  source: string;
  scanId?: string;
  fieldName: string;
  fullyQualifiedName: string;
  attributeName: string;
  reviewStatus: CuratedFieldStatus;
  curatedByUser: string;
  detailedObjectType: DetailedObjectType;
  objectName: string;
  container: string;
  confidenceLevel?: ConfidenceLevel;
  updatedConfidenceLevel?: ConfidenceLevel;
  confidenceValue?: number;
  attributeType: CuratedAttributeType;
  objectType: string;
  fieldType: string;
  totalFindings: number;
  objectProperties?: ObjectProperty[];
  curatedAt: DateISO8601;
  sampledAt: DateISO8601;
  scannedAt: DateISO8601;
  tags?: TagEntity[];
  sample1: string;
  sample2: string;
};

export type CuratedFieldKeys = Array<keyof CuratedField>;

export type FindingDetails = {
  findingId: string;
  findingValue: string;
  reviewStatus: CuratedFieldStatus;
  position: number;
  length: number;
  attributeName?: string;
  attributeType?: CuratedAttributeType;
  excludePatternId: string;
};

export type FindingCurateProps = {
  finding: FindingDetails;
  status: CuratedFieldStatus;
  fieldId: ReactText;
  matchType?: MatchType;
};

export type FieldValueChunk = {
  chunkValue: string;
  findingDetails: FindingDetails[];
};

export type FindingField = {
  fieldName: string;
  fieldValueChunks: FieldValueChunk[];
};

export type Finding = {
  fields: FindingField[];
};

export type AdditionalAttribute = {
  attributeId: string;
  attributeName: string;
  attributeType: CuratedAttributeType;
  findings: number;
};

export type AdditionalAttributeField = {
  fieldName: string;
  attributes: AdditionalAttribute[];
};

export type GetFindingsPayload = Pick<
  CuratedField,
  'fieldName' | 'fullyQualifiedName' | 'attributeName' | 'attributeType'
>;

export type GetFindingsResponse = {
  findings: Finding[];
};

export type CurateFindingPayload = {
  curationFieldId: ReactText;
  value: string;
  matchType: MatchType;
};

export type DeleteCurateFindingPayload = {
  curationFieldId: ReactText;
  patternId: string;
};

export enum MatchType {
  EXACT = 'EXACT',
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export type CurateFindingResponse = unknown;

export type GetCuratedDataSourcePayload = {
  scanId: string;
  query: string;
};

export type GetCuratedDataSourceResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  sources: CuratedDataSource[];
};

export type GetCuratedAttributesPayload = {
  sources: string[];
  query: string;
};

export type GetCuratedAttributesResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  attributes: CuratedAttribute[];
};

export type GetCuratedFieldsPayload = {
  query: string;
};

export type GetCuratedFieldsResponse = {
  totalCount: number;
  curationStatus: CurationStatus;
  fields: CuratedField[];
};

export type CurateFieldPayload = {
  ids: ReactText[];
  reviewStatus: CuratedFieldStatus;
};

export type CurateFieldResponse = {
  changedNumber: number;
  pristineNumber: number;
};

export type GetAttributesCurationStatusPayload = {
  sources: string[];
};

export type GetAttributesCurationStatusResponse = CurationStatusResponse<CurationStatusPerAttribute>;

export type GetFieldsCurationStatusResponse = CurationStatusResponse<CurationStatusPerField>;

export type GetFieldsCurationStatusPayload = {
  sources: string[];
  attributeName: string;
  attributeType: CuratedAttributeType;
};

export type CuratedAttributeRemovalPayload = {
  sources: string[];
  attributeName?: string;
  attributeType?: CuratedAttributeType;
};

export type CuratedAttributeRemovalResponse = unknown; //NOTE:  figure out with the backend later

export type GetAdditionalAttributesPayload = {
  fullyQualifiedName: CuratedField['fullyQualifiedName'];
  fieldName?: CuratedField['fieldName'];
  gridConfigQuery?: string;
};

export type GetAdditionalAttributesResponse = {
  fields: AdditionalAttributeField[];
};

export type PreviewPayload = Pick<CuratedField, 'fullyQualifiedName'> & {
  fields: Array<Pick<CuratedField, 'attributeName' | 'attributeType' | 'fieldName'>>;
};

export type CurationUserPreferences = {
  guidedTourStatus: Record<CurationGuidedTourStageId, CurationGuidedTourStageStatus>;
};

export function getCuratedDataSources({ query, scanId }: GetCuratedDataSourcePayload) {
  if (!scanId) {
    return httpService
      .fetch<{ data: GetCuratedDataSourceResponse }>(`data-catalog/results-tuning/sources-all?${query}`)
      .then(({ data }) => data);
  } else {
    const scanIdEncoded = encodeURIComponent(scanId);
    return httpService
      .fetch<{ data: GetCuratedDataSourceResponse }>(`data-catalog/results-tuning/sources/${scanIdEncoded}?${query}`)
      .then(({ data }) => data);
  }
}

export const resampleCurationDs = (scanId: string) => {
  return httpService.post(`data-catalog/results-tuning/sampling/async`, {
    scanId: scanId,
  });
};

export function getCuratedAttributes({ sources, query }: GetCuratedAttributesPayload) {
  return httpService
    .fetch<{ data: GetCuratedAttributesResponse }>(
      `data-catalog/results-tuning/attributes?source=${encodeURIComponent(sources.join(','))}&${query}`,
    )
    .then(({ data }) => data);
}

export const triggerPreviewCurationAttributes = (curationFieldId: string) => {
  return httpService.post(`data-catalog/scan-result-fetch-findings/${curationFieldId}`);
};

export function getCuratedFields({ query }: GetCuratedFieldsPayload) {
  return httpService
    .fetch<{ data: GetCuratedFieldsResponse }>(`data-catalog/results-tuning/curation-fields?${query}`)
    .then(({ data }) => data);
}

export function curateField(payload: CurateFieldPayload) {
  return httpService
    .patch<{ data: CurateFieldResponse }, Pick<CurateFieldPayload, 'reviewStatus'>>(
      `data-catalog/results-tuning/curation-fields/status`,
      payload,
    )
    .then(({ data }) => data);
}

export async function getSystemUsers(searchString?: string): Promise<BigidSelectOption[]> {
  try {
    const query = getUsersQuery({ searchString, maxUsers: 50 });
    const {
      data: { users },
    } = await systemUsersService.getAllSystemUsersByQuery(query);

    return getOptionsFromSystemUsers(users);
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the data owners.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

function getObjectFieldsOptions(data: string[]): BigidSelectOption[] {
  return data.map((value: string) => {
    return {
      value,
      label: value,
      isSelected: false,
    };
  });
}

export async function getObjectsFields(
  source: string,
  attributeType: CuratedAttributeType,
  attributeName: string,
): Promise<CuratedObjectFields> {
  try {
    const filtersConfig: BigidFilter = [
      {
        field: 'attributeName',
        value: attributeName,
        operator: 'in',
      },
      {
        field: 'attributeType',
        value: attributeType,
        operator: 'in',
      },
      {
        field: 'source',
        value: source,
        operator: 'in',
      },
    ];

    const filterQuery = parseFieldFiltersToSearchQuery(
      filtersConfig,
      Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
    );

    const { data } = (
      await httpService.fetch(
        `data-catalog/results-tuning/curation-fields/grid/filters?filter=${encodeURIComponent(filterQuery)}`,
      )
    ).data;

    const containers = data.containers ? getObjectFieldsOptions(data.containers) : null;
    const objectNames = data.objectNames ? getObjectFieldsOptions(data.objectNames) : null;
    const fieldNames = data.fieldNames ? getObjectFieldsOptions(data.fieldNames) : null;

    return {
      containers,
      objectNames,
      fieldNames,
    };
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the objects fields.');
    console.error(`An error has occurred: ${message}`);
    return;
  }
}

export async function getAttributeCategories(source: string): Promise<BigidSelectOption[]> {
  try {
    const { data } = (
      await httpService.fetch(`data-catalog/results-tuning/attributes/categories?source=${encodeURIComponent(source)}`)
    ).data;

    return data.map((category: CategoryDetails) => ({
      label: category.displayName,
      value: category.uniqueName,
      isSelected: false,
    }));
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the categories.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

export async function getSystemUsersByPermissions(source: string): Promise<BigidSelectOption[]> {
  try {
    const {
      data: {
        data: { users },
      },
    } = await httpService.fetch(`data-catalog/classifier-tuning/collaborator/list/${encodeURIComponent(source)}`);
    return getOptionsFromSystemUsers(users);
  } catch ({ message }) {
    notificationService.error('An error has occurred while fetching the relevant users.');
    console.error(`An error has occurred: ${message}`);
    return [];
  }
}

export function getFindings(curationFieldId: string) {
  return httpService
    .fetch<{ data: GetFindingsResponse }>(`data-catalog/scan-result-fetch-findings/${curationFieldId}`)
    .then(({ data }) => data);
}

export function excludeFinding(payload: CurateFindingPayload) {
  return httpService.put<CurateFindingResponse>(
    `data-catalog/results-tuning/curation-fields/excluded-findings`,
    payload,
  );
}

export function unexcludeFinding(payload: DeleteCurateFindingPayload) {
  return httpService.delete<CurateFindingResponse>(
    `data-catalog/results-tuning/curation-fields/excluded-findings`,
    payload,
  );
}

export function getAttributeCurationStatus({ sources }: GetAttributesCurationStatusPayload) {
  return httpService
    .fetch<{ data: GetAttributesCurationStatusResponse }>(
      `data-catalog/results-tuning/curation-fields/status/attribute?source=${encodeURIComponent(sources.join(','))}`,
    )
    .then(({ data }) => data);
}

export function getFieldsCurationStatus({ sources, ...payload }: GetFieldsCurationStatusPayload) {
  const query = objectToQueryString(payload as unknown as QueryParams);

  return httpService
    .fetch<{ data: GetFieldsCurationStatusResponse }>(
      `data-catalog/results-tuning/curation-fields/status/field?source=${encodeURIComponent(
        sources.join(','),
      )}&${query}`,
    )
    .then(({ data }) => data);
}

export function removeCuratedAttribute(payload: CuratedAttributeRemovalPayload) {
  return httpService
    .delete<CuratedAttributeRemovalResponse, CuratedAttributeRemovalPayload>(
      `data-catalog/manual-fields/by-source`,
      payload,
    )
    .then(({ data }) => data);
}

export function getAdditionalAttributes(payload: GetAdditionalAttributesPayload, gridConfigQuery?: string) {
  const query = objectToQueryString(payload);

  return httpService
    .fetch<{ data: GetAdditionalAttributesResponse }>(
      `data-catalog/object-details/fields?${query}&groupByField=true${gridConfigQuery ? '&' + gridConfigQuery : ''}`,
    )
    .then(({ data }) => data);
}

export async function getUserPreferences() {
  try {
    const preference = await userPreferencesService.get(CONFIG.states.CURATION);
    return preference?.data || {};
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
    return {};
  }
}

export async function setUserPreferences(newPreferences: CurationUserPreferences) {
  try {
    const preferences = await userPreferencesService.get(CONFIG.states.CURATION);
    if (preferences) {
      await userPreferencesService.update({
        preference: CONFIG.states.CURATION,
        data: {
          ...preferences.data,
          ...newPreferences,
        },
      });
    } else {
      await userPreferencesService.add({
        preference: CONFIG.states.CURATION,
        data: newPreferences,
      });
    }
  } catch ({ message }) {
    console.error(`An error has occurred: ${message}`);
  }
}
