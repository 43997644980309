import { BigidTagBaseProps } from '@bigid-ui/components';
import { sendSSERequestWithEventId } from '../../../../../../services/sseService';
import { DataCatalogAsyncOperationRoutingKey } from '../../../../../DataCatalog/DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import {
  createTag,
  TagAssignmentTarget,
  TagCompositionPartType,
  TagEntity,
  TagResponseEntity,
} from '../../../../../TagsManagement/TagsManagementService';
import { getTagEntityByName } from '../../../../../TagsManagement/TagsManagementUtils';
import { searchTextMinLength } from '../../../../constants';

export type TagAssignmentMode = 'assign' | 'unassign';

type TagAssignmentPayload = {
  systemTags: TagEntity[];
  tagToProcess: BigidTagBaseProps[];
  filter: string;
  searchText: string;
  assignmentMode: TagAssignmentMode;
};

type TagBulkAssignmentPayloadData = {
  type: TagAssignmentTarget;
  tags: TagEntity[];
};

type TagBulkAssignmentRequestPayload = {
  data: TagBulkAssignmentPayloadData;
  filter?: string;
  search_text?: string;
};

type SseResponse = {
  data: {
    taskUuid: string;
  };
};

const buildAssignTagRequestPayload = ({ data, filter, search_text }: TagBulkAssignmentRequestPayload) => {
  const requestPayload: TagBulkAssignmentRequestPayload = {
    data,
    filter: filter.trim().length > 0 ? filter : undefined,
    search_text: search_text.trim().length >= searchTextMinLength ? search_text : undefined,
  };

  return requestPayload;
};

export const createAndAttachTagsBulk = async ({
  tagToProcess,
  filter,
  systemTags,
  searchText,
}: TagAssignmentPayload) => {
  const data: TagBulkAssignmentPayloadData = {
    type: TagAssignmentTarget.object,
    tags: [],
  };

  for (const tag of tagToProcess) {
    const { name, value, isNew } = tag;

    let nameCreated: TagResponseEntity;
    let valueCreated: TagResponseEntity;
    let tagNameIdToAttach: TagResponseEntity['_id'];
    let tagValueIdToAttach: TagResponseEntity['_id'];
    const tagNameDescription = `${name} tag name`;
    const tagValueDescription = `${value} tag value`;

    if (isNew) {
      const { data: tagCreatedResponse } = await createTag({
        name,
        type: TagCompositionPartType.tag,
        description: tagNameDescription,
      });
      nameCreated = tagCreatedResponse[0];
      tagNameIdToAttach = nameCreated._id;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    } else {
      tagNameIdToAttach = getTagEntityByName(systemTags, name)?.tagId;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    }

    if (tagNameIdToAttach && tagValueIdToAttach) {
      data.tags.push({ tagId: tagNameIdToAttach, tagName: name, valueId: tagValueIdToAttach, tagValue: value });
    }
  }

  const payload: TagBulkAssignmentRequestPayload = buildAssignTagRequestPayload({
    data,
    filter,
    search_text: searchText,
  });

  return sendSSERequestWithEventId(
    'post',
    `data-catalog/manual-fields/tags/async`,
    payload,
    {},
    DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT,
  );
};

export const assignmentTagProcessor = ({
  tagToProcess,
  filter,
  systemTags,
  searchText,
  assignmentMode,
}: TagAssignmentPayload) => {
  const data: TagBulkAssignmentPayloadData = {
    type: TagAssignmentTarget.object,
    tags: tagToProcess.reduce((tagsPayload, tag) => {
      const { name, value } = tag;
      const tagToAttach = getTagEntityByName(systemTags, name, value);

      if (tagToAttach) {
        return [
          ...tagsPayload,
          { tagId: tagToAttach.tagId, tagName: name, valueId: tagToAttach.valueId, tagValue: value },
        ];
      } else {
        return tagsPayload;
      }
    }, []),
  };

  const sseMethod = assignmentMode === 'assign' ? 'post' : 'delete';
  const broadcastEvent =
    assignmentMode === 'assign'
      ? DataCatalogAsyncOperationRoutingKey.TAG_BULK_ASSIGNMENT
      : DataCatalogAsyncOperationRoutingKey.TAG_BULK_UNASSIGNMENT;

  const payload = buildAssignTagRequestPayload({
    data,
    filter,
    search_text: searchText,
  });

  return sendSSERequestWithEventId<SseResponse>(
    sseMethod,
    `data-catalog/manual-fields/tags/async`,
    payload,
    {},
    broadcastEvent,
  );
};
