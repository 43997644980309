import React, { FC, useContext, useEffect, useRef } from 'react';
import { BigidListIcon } from '@bigid-ui/icons';
import { BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumnTypes } from '@bigid-ui/grid';
import { BigidLoader, BigidBody1 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import {
  CaseModel,
  toolbarActions,
  ViewType,
  ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX,
  getSeverityChipProps,
  generateJiraTicketGridField,
  CaseStatus,
} from '../actionableInsightsService';
import { CaseStatusIndicator } from '../ActionableInsightsComponents/CaseStatusIndicator';
import { ActionableInsightsContext } from '../hooks/ActionableInsightsContext';
import { ReducerActions } from '../hooks/ActionableInsightsReducer';
import { useLocalTranslation } from '../translations';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { OpenCaseSidePanelWithData, OpenModalWithData } from './CaseActionsModal/hooks/useCaseActions';
import { PageFilterPreferences, userPreferencesService } from '../../../services/userPreferencesService';
import { formatNumberCompact } from '../../../utilities/numericDataConverter';

export interface ActionableInsightsDetailedCaseProps {
  row: any;
  onCaseAction: () => void;
  onCloseExpandedRows: () => void;
  openModalWithData: OpenModalWithData;
  openCaseReportWithData: OpenCaseSidePanelWithData;
  isDetailedCaseLoading: boolean;
}

const ADDITIONAL_CASES_BUTTON_THRESHOLD = 5;
const POLICY_NAME_FIELD = 'policyName';

const MainContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Indent = styled('div')`
  width: 48px;
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray100};
  border-right: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  border-top: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
`;

const GridWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex-direction: column;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  & > * > :not(aside) > * thead tr:first-child {
    background-color: ${({ theme }) => theme.vars.palette.bigid.white};
    & > * {
      font-weight: 700 !important;
    }
  }
  & > * > :not(aside) > * tbody {
    background-color: ${({ theme }) => theme.vars.palette.bigid.white};
  }
`;

const ButtonsWrapper = styled('div')`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray100};
  align-items: center;
  display: flex;
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200}`};
  gap: 10;
`;

export const LoaderWrapper = styled('div')`
  height: 88px;
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray100};
  position: 'relative';
`;

export const ActionableInsightsDetailedCase: FC<ActionableInsightsDetailedCaseProps> = ({
  row,
  onCaseAction,
  onCloseExpandedRows,
  openModalWithData,
  openCaseReportWithData,
  isDetailedCaseLoading,
}) => {
  const { t } = useLocalTranslation('Case');
  const { caseState, dispatch } = useContext(ActionableInsightsContext);
  const policyNameRef = useRef(row?.policyName);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<CaseModel> = {
    pageSize: 5,
    columns: [
      {
        name: 'caseLabel',
        title: t('fields.case'),
        getCellValue: row => row.caseLabel,
        type: BigidGridColumnTypes.TEXT,
        width: 260,
        disableTooltip: true,
      },
      {
        name: 'assignee',
        title: t('fields.assignee'),
        getCellValue: row => row.assignee,
        type: BigidGridColumnTypes.TEXT,
        width: 180,
        disableTooltip: true,
      },
      {
        name: 'severityLevel',
        title: t('fields.severityLevel'),
        getCellValue: row => getSeverityChipProps(row.severityLevel?.toLowerCase()),
        type: BigidGridColumnTypes.CHIP,
        width: 120,
        disableTooltip: true,
      },
      {
        name: 'caseStatus',
        title: t('fields.caseStatus'),
        getCellValue: row => <CaseStatusIndicator status={row.caseStatus?.toLowerCase() as CaseStatus} />,
        type: BigidGridColumnTypes.CUSTOM,
        width: 140,
        disableTooltip: true,
      },
      {
        name: 'created_at',
        title: t('fields.createdAt'),
        getCellValue: row => row.created_at,
        type: BigidGridColumnTypes.DATE,
        width: 180,
        disableTooltip: true,
      },
      {
        name: 'dataSourceName',
        title: t('fields.dsName'),
        getCellValue: row => row.dataSourceName,
        type: BigidGridColumnTypes.TEXT,
        width: 180,
        disableTooltip: true,
      },
      {
        name: 'dataSourceType',
        title: t('fields.dsType'),
        getCellValue: row => row.dataSourceType,
        type: BigidGridColumnTypes.TEXT,
        width: 160,
        disableTooltip: true,
      },
      {
        name: 'dataSourceOwner',
        title: t('fields.dsOwner'),
        getCellValue: row => row.dataSourceOwner,
        type: BigidGridColumnTypes.TEXT,
        width: 180,
        disableTooltip: true,
      },
      {
        name: 'numberOfAffectedObjects',
        title: t('fields.affectedObjects'),
        getCellValue: row => formatNumberCompact(row.numberOfAffectedObjects),
        type: BigidGridColumnTypes.TEXT,
        width: 140,
        disableTooltip: true,
      },
      {
        name: 'jiraTicketUrl',
        title: t('fields.jiraTicketUrl'),
        type: BigidGridColumnTypes.LINK,
        formatter: {
          preventDefaultAndStopPropagation: false,
        },
        width: 170,
        disableTooltip: true,
        getCellValue: row => generateJiraTicketGridField(row.jiraTicketUrl),
      },
      {
        name: 'compliance',
        title: t('fields.compliance'),
        disableTooltip: true,
        width: 160,
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: row => {
          return {
            chips: {
              value: row.compliance ? [{ label: row.compliance }] : [],
              isEditMode: false,
              isDisabled: true,
            },
          };
        },
      },
    ],
    fetchData: async () => {
      return {
        data: row?.cases,
        totalCount: row?.cases?.length,
      };
    },
    showSortingControls: false,
    displayActionToolbar: false,
    toolbarActions: toolbarActions(openModalWithData, caseState, onCaseAction, false, null, { policies: [row] }),
    onRowClick: row => openCaseReportWithData([row]),
  };

  useEffect(() => {
    if (row?.policyName !== policyNameRef.current) {
      onCloseExpandedRows();
    }
  }, [onCloseExpandedRows, row?.policyName]);

  return (
    <MainContainer>
      <Indent />
      <GridWrapper
        key={row?.cases?.length}
        data-aid={generateDataAid('ActionableInsightsDetailedCase', ['container'])}
        data-tour-id={generateGuidedTourId('ActionableInsightsDetailedCase', ['container'])}
      >
        {isDetailedCaseLoading ? (
          <LoaderWrapper>
            <BigidLoader />
          </LoaderWrapper>
        ) : (
          <>
            <BigidGridWithToolbar {...gridWithToolbarConfig} />
            {row?.numOfCases > ADDITIONAL_CASES_BUTTON_THRESHOLD && (
              <ButtonsWrapper
                onClick={async () => {
                  const newFilter: PageFilterPreferences = {
                    filter: [{ field: POLICY_NAME_FIELD, value: [row.policyName], operator: 'in' }],
                  };
                  const toUpdate = {
                    preference: `${ACTIONABLE_INSIGHTS_PREFERENCE_IDENTIFIER_PREFIX}.${caseState}`,
                    filter: newFilter,
                  };
                  await userPreferencesService.update(toUpdate);
                  dispatch({
                    type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
                    payload: { viewType: ViewType.FLAT },
                  });
                }}
                data-aid={generateDataAid('ActionableInsightsDetailedCase', ['button'])}
              >
                <BigidListIcon />
                <BigidBody1>{t('common.messages.viewAllCases', { numOfCases: row?.numOfCases })}</BigidBody1>
              </ButtonsWrapper>
            )}
          </>
        )}
      </GridWrapper>
    </MainContainer>
  );
};
