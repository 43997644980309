import React from 'react';
import {
  BigidSideFilterSectionBase,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayoutConfig, LayoutContentType, LayoutSideFilterProps } from '@bigid-ui/layout';
import { getFixedT } from '../translations';
import { BusinessGlossaryRecord, GetLayoutConfigProps } from '../types';
import { generateFakeGridData } from '../utils';
import { GridIcon } from './GridIcon';
import {
  fetchAssetTypes,
  fetchAttributes,
  fetchAttributeTypes,
  fetchBusinessTerms,
  fetchCategories,
  fetchCertificationStatuses,
  fetchDataSources,
  fetchDomains,
  fetchIsCertifiedOnlySelected,
  fetchTags,
  updateIsCertifiedOnlySelected,
} from '../BusinessGlossaryService';

const t = getFixedT('');

const sideFilterConfig: LayoutSideFilterProps = {
  showClearAll: true,
  isSideFilterOpen: true,
  getSectionsConfig: async (): Promise<BigidSideFilterSectionBase[]> => {
    try {
      const sectionsConfig = await Promise.allSettled([
        fetchAssetTypes(),
        fetchAttributes(),
        fetchBusinessTerms(),
        fetchDomains(),
        fetchCertificationStatuses(),
        fetchTags(),
        fetchCategories(),
        fetchAttributeTypes(),
        fetchDataSources(),
      ]).then(results => {
        return results.map(result => {
          if (result.status === 'fulfilled') {
            return result.value;
          }
          return null;
        });
      });

      return sectionsConfig.filter(Boolean).flat();
    } catch (error) {
      console.error(error);
    }

    return [];
  },
};

const gridConfig: BigidGridProps<BusinessGlossaryRecord> = {
  showSortingControls: false,
  showSelectionColumn: false,
  columns: [
    {
      getCellValue: ({ businessObjectType, status }) => (
        <GridIcon status={status} businessObjectType={businessObjectType} />
      ),
      title: t('Grid.columns.businessObject'),
      name: 'businessObjectType',
      type: BigidGridColumnTypes.CUSTOM,

      width: 140,
    },
    {
      getCellValue: row => row.objectName,
      title: t('Grid.columns.objectName'),
      name: 'objectName',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.friendlyName,
      title: t('Grid.columns.friendlyName'),
      name: 'friendlyName',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: ({ categories }) => {
        return categories.map(category => category).join(', ');
      },
      title: t('Grid.columns.categories'),
      name: 'categories',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.domain,
      title: t('Grid.columns.domain'),
      name: 'domain',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.definition,
      title: t('Grid.columns.definition'),
      name: 'definition',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.type,
      title: t('Grid.columns.type'),
      name: 'type',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.description,
      title: t('Grid.columns.description'),
      name: 'description',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.project,
      title: t('Grid.columns.project'),
      name: 'project',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      title: t('Grid.columns.tags'),
      name: 'tags',
      type: BigidGridColumnTypes.TAGS,
      getCellValue: ({ manualTags }) => ({
        tags: {
          ...manualTags.tags,
          value: manualTags.tags.value.map(tagDetails => ({
            ...tagDetails,
            name: tagDetails.name,
          })),
        },
      }),
    },
    {
      getCellValue: ({ itAssetsCount }) => itAssetsCount,
      title: t('Grid.columns.itAssetsCount'),
      name: 'itAssetsCount',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: ({ attributesCount }) => attributesCount,
      title: t('Grid.columns.attributesCount'),
      name: 'attributesCount',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: ({ termsCount }) => termsCount,
      title: t('Grid.columns.termsCount'),
      name: 'termsCount',
      type: BigidGridColumnTypes.TEXT,
    },
  ],
};

const getToolbarActions = ({
  createNewDropdownClassName,
  onCreateAttributeClick,
  onCreateTermClick,
}: GetLayoutConfigProps): ToolbarAction[] => {
  return [
    {
      label: t('Grid.toolbar.actions.import'),
      type: ToolbarActionType.SECONDARY,
      isGlobal: true,
      show: () => true,
      execute: async () => {
        console.log('Import');
        return {};
      },
    },
    {
      label: t('Grid.toolbar.actions.createNew'),
      type: ToolbarActionType.DROPDOWN,
      dropdownProps: {
        className: createNewDropdownClassName,
        clearOnSelect: true,
        placeholder: 'Create New',
        options: [
          {
            label: t('Grid.toolbar.actions.attribute'),
            value: 'attribute',
            show: () => true,
            execute: async () => {
              onCreateAttributeClick();
              return {};
            },
          },
          {
            label: t('Grid.toolbar.actions.businessTerm'),
            value: 'businessTerm',
            show: () => true,
            execute: async () => {
              onCreateTermClick();
              return {};
            },
          },
        ],
      },
      show: () => true,
    },
    {
      label: t('Grid.toolbar.actions.showCertifiedOnly'),
      type: ToolbarActionType.SWITCH,
      isGlobal: true,
      show: () => true,
      switchProps: {
        isChecked: actionData => {
          console.log('actionData', actionData);
          return actionData?.selectedItem?.isCertifiedOnlyEnabled;
        },
      },
      execute: async data => {
        const isCertifiedOnlySelected = await fetchIsCertifiedOnlySelected();
        const { selectedItem } = data;
        console.log('data', data);

        updateIsCertifiedOnlySelected(!isCertifiedOnlySelected);

        entityEventsEmitter.emit(EntityEvents.UPDATE, {
          payload: {
            isCertifiedOnlyEnabled: !isCertifiedOnlySelected,
            hello: true,
          },
        });
        return {
          shouldGridReload: true,
        };
      },
    },
  ];
};

const fetchGridData: FetchDataFunction<BusinessGlossaryRecord> = async () => {
  const data = generateFakeGridData(20);
  return {
    data,
    totalCount: 20,
  };
};

export const getPaidLayoutConfig = ({
  createNewDropdownClassName,
  onCreateAttributeClick,
  onCreateTermClick,
}: GetLayoutConfigProps): BigidLayoutConfig => ({
  filter: {
    sideFilter: sideFilterConfig,
    search: {
      isQueryLanguage: false,
      onSearchQueryChange: searchQuery => {
        console.log('searchQuery', searchQuery);
      },
    },
  },
  content: {
    entityName: 'Business Glossary',
    contentTypes: [LayoutContentType.GRID],
    viewConfig: {
      fetchGridData,
      gridConfig,
      filterToolbarConfig: {
        searchConfig: {
          operator: 'contains',
          placeholder: 'Search',
          searchFilterKeys: ['businessObjectType'],
          autoSubmit: false,
        },
      },
    },

    toolbarActions: getToolbarActions({
      createNewDropdownClassName,
      onCreateAttributeClick,
      onCreateTermClick,
    }),
  },
});
