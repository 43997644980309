import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';

export const accordionCommonStyle = {
  backgroundColor: BigidColorsV2.gray[100],
  width: '100%',
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
};

export const accordionDetailsStyle = {
  padding: '12px 20px 20px !important',
  backgroundColor: BigidColorsV2.gray[150],
};

export const accordionStyle = {
  gap: '0px',
  boxShadow: 'none',
  borderRadius: '8px !important',
  border: `1px solid ${BigidColorsV2.gray[150]}`,
  backgroundColor: BigidColorsV2.gray[150],
};

export const accordionSummary = { '& .MuiAccordionSummary-content': { marginLeft: '4px !important' } };

export const AccordionInfoPanelStyled = styled.div`
  width: 100%;

  .MuiAccordionSummary-expandIconWrapper {
    display: ${(props: { showExpandIcon: boolean }) => (props.showExpandIcon ? 'initial' : 'none')};
  }

  .MuiAccordionSummary-content {
    cursor: ${(props: { showExpandIcon: boolean }) => (props.showExpandIcon ? 'pointer' : 'default')};
  }

  .AutoDiscoverWizardAsideOptionItem .MuiAccordionSummary-expandIconWrapper,
  .AutoDiscoverWizardAsideOptionItem .MuiAccordionSummary-content {
    display: initial;
    cursor: 'pointer';
  }
`;

export const AccordionInfoPanelItemTitle = styled.div`
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: center;
`;

export const AccordionInfoTitleEndItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const AccordionInfoTitleEndItemLabel = styled(BigidBody1)`
  color: ${({ theme }) => theme.palette.bigid.blue600} !important;
`;

export enum AccordionInfoPanelVariants {
  info,
  guide,
  error,
}

export const infoStyle = {
  backgroundColor: BigidColorsV2.blue[100],
  borderColor: BigidColorsV2.blue[150],
};

export const errorStyle = {
  backgroundColor: BigidColorsV2.red[100],
};

export const mapVariantToStyle: Record<AccordionInfoPanelVariants, Record<string, string>> = {
  [AccordionInfoPanelVariants.info]: infoStyle,
  [AccordionInfoPanelVariants.guide]: {},
  [AccordionInfoPanelVariants.error]: errorStyle,
};
