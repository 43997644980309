import type { TestConnectionErrorResolution } from './hooks/useTestConnection';
import type { DataSource } from '../../../utilities/dataSourcesUtils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type DsConnectionDocItemResponse = {
  status: string;
  statusCode: number;
  data: DsConnectionDocItemResource[];
  message: string;
};

export type DsConnectionDocItemResource = {
  _id: string;
  dataSourceType: string;
  dataSourceAuthType: string;
  preConnectionType: DsConnectionDocPreConnectionType;
  preConnectionData: string;
};

export enum DsConnectionDocType {
  PRE_CONNECTION = 'preConnection',
  ERROR_RESOLUTION = 'errorResolution',
}
export enum DsConnectionDocDataSourceAuthType {
  CREDENTIALS = 'credentialAuthentication',
}

export enum DsConnectionDocPreConnectionType {
  HOW_TO_CONNECT = 'howToConnect',
  PREREQUISITES = 'prerequisites',
}

export enum DataSourceCollaboratorType {
  COLLABORATOR = 'collaborator',
  OWNER = 'owner',
  ADMIN = 'admin',
}

export enum CollaborationEventType {
  INIT_DATA_SOURCE = 'initDatasource',
  CONNECTED = 'connected',
  ADD_COLLABORATORS = 'addCollaborators',
  UPDATE_COLLABORATORS = 'updateCollaborators',
  DELETE_COLLABORATORS = 'deleteCollaborators',
  REMIND_COLLABORATORS = 'remindCollaborators',
}

export type DsConnectionDocParams = {
  dsConnectionDocType: DsConnectionDocType;
  dataSourceAuthType: DsConnectionDocDataSourceAuthType;
  preConnectionType?: DsConnectionDocPreConnectionType;
  errorCode?: string;
};

export type DataSourceCollaborator = {
  id?: string;
  email: string;
  name: string;
  collaborationRoles: string[];
};

export type DsCollaboratorParams = {
  id: string;
};

export type UpdateDsCollaboratorItemResponse = {
  status: string;
  statusCode: number;
  message: string;
  errors?: string[];
};

export type UpdateDsCollaboratorItemRequest = CollaborationItemRequest<CollaborationEventType.UPDATE_COLLABORATORS>;

export type DeleteDsCollaboratorItemResponse = UpdateDsCollaboratorItemResponse;

export type DeleteDsCollaboratorItemRequest = CollaborationItemRequest<CollaborationEventType.DELETE_COLLABORATORS>;

export type CreateDsCollaboratorItemResponse = UpdateDsCollaboratorItemResponse;

export type CreateDsCollaboratorItemRequest = CollaborationItemRequest<CollaborationEventType.ADD_COLLABORATORS>;

export type RemindCollaboratorsItemRequest = CollaborationItemRequest<CollaborationEventType.REMIND_COLLABORATORS>;

export type RemindCollaboratorsItemResponse = UpdateDsCollaboratorItemResponse;

export type GetDsCollaboratorItemResponse = {
  status: string;
  statusCode: number;
  message: string;
  data?: {
    entityId: string;
    entityName: string;
    serviceType: string;
    flowType: string;
    collaborators: DataSourceCollaborator[];
    creator: {
      email: string;
      name: string;
      collaborationRoles: string[];
    };
    otherProps: {
      deepLink: string;
    };
    flowVersion: number;
    state: string;
    created_at: string;
    updated_at: string;
  };
  errors?: string[];
};

export type CollaboratorGridRow = DataSourceCollaborator & { id: string; type?: string };

export type GetDataSourceTypesItemResponse = DataSource[];

export type CollaborationItemRequest<TEvent extends CollaborationEventType = CollaborationEventType> = {
  eventType: TEvent;
  additionalProperties: CollaborationItemRequestAdditionalProps<TEvent>;
};

export type CollaborationItemResponse = {
  status: string;
  statusCode: number;
  message: string;
  errors?: string[];
};

type CollaborationItemRequestAdditionalPropsMap = {
  [CollaborationEventType.ADD_COLLABORATORS]: {
    uiBaseUrl: string;
    dsTypeLabel: string;
    collaboratorsInfo: { email: string; emailContent?: string; type?: string }[];
  };
  [CollaborationEventType.DELETE_COLLABORATORS]: { collaboratorsInfo: string[] };
};

export type CollaborationItemRequestAdditionalProps<TEvent extends CollaborationEventType> = TEvent extends
  | CollaborationEventType.CONNECTED
  | CollaborationEventType.INIT_DATA_SOURCE
  ? Record<string, never>
  : TEvent extends
      | CollaborationEventType.ADD_COLLABORATORS
      | CollaborationEventType.UPDATE_COLLABORATORS
      | CollaborationEventType.REMIND_COLLABORATORS
  ? CollaborationItemRequestAdditionalPropsMap[CollaborationEventType.ADD_COLLABORATORS]
  : TEvent extends CollaborationEventType.DELETE_COLLABORATORS
  ? CollaborationItemRequestAdditionalPropsMap[CollaborationEventType.DELETE_COLLABORATORS]
  : never;

export type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (...args: any) => Promise<infer R>
  ? R
  : any;

export type GetErrorResolutionResponse = {
  error: string;
  errorResolution: TestConnectionErrorResolution;
};
