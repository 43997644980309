import React, { useRef, useMemo, useCallback, useState } from 'react';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow } from '@bigid-ui/grid';
import { Wrapper } from './ProxiesStyles';
import { getProxies } from '../../services/proxiesService';
import { useLocalTranslation } from './translations';
import { createGridConfig, createLayoutConfig } from './utils';
import { ForwardFormRef, ProxyGridRow, ProxyItemResponse } from './types';
import { ProxiesHeader } from './ProxiesHeader';
import { ProxiesFooter } from './ProxiesFooter';
import { ProxiesFormMemo } from './ProxiesForm';
import { useFormActions } from './hooks/useFormActions';
import { usePageHeader } from './hooks/usePageHeader';
import { ProxiesEmptyState } from './ProxiesEmptyState';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { credentialsService } from '../../services/angularServices';
import { isPermitted } from '../../services/userPermissionsService';
import { CERTIFICATES_PERMISSIONS, CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import { CertificateModel, getCertificates } from '../CertificatesManagement/CertificatesManagement';
import { CredentialItemResponse } from '../Credentials/types';
import { notificationService } from '../../services/notificationService';

type BigidLayoutMasterDetailsRenderProps<GridRow extends BigidGridRow> = {
  config: BigidLayoutMasterDetailsConfig<GridRow>;
  selectedItem: GridRow;
};

export const Proxies = () => {
  const proxiesFormRef = useRef<ForwardFormRef>();
  const [credentialsOptions, setCredentialsOptions] = useState<CredentialItemResponse[]>();
  const [certificatesOptions, setCertificatesOptions] = useState<CertificateModel[]>();
  const { t } = useLocalTranslation();

  const layout = useLayout<ProxyGridRow>('BigidLayoutMasterDetails', {
    pageSize: 1000,
    fetchDataFunction: async () => {
      try {
        const { data: proxiesData } = await getProxies();

        const { data: credentials } = (isPermitted(CREDENTIALS_PERMISSIONS.READ.name) &&
          (await credentialsService.getCredentials())) || {
          data: [],
        };
        setCredentialsOptions(credentials);

        const certificates = (
          (isPermitted(CERTIFICATES_PERMISSIONS.READ.name) && (await getCertificates(''))) || { results: [] }
        ).results.filter(cert => cert.usage === 'rootCA');
        setCertificatesOptions(certificates);

        return {
          data:
            proxiesData?.map((item: ProxyItemResponse) => ({
              ...item,
              id: item._id,
              key: item._id,
              childRows: null,
            })) ?? [],
          totalCount: proxiesData.length ?? 0,
        };
      } catch (error) {
        notificationService.error(t('message.commonError'));
      }
    },
    initialSorting: [{ field: 'name', order: 'asc' }],
  });
  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const selectedRow = getSelectedRow();
  const hasNoProxies = !rows?.length && !isLoading;

  const gridConfig: BigidGridProps<ProxyGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),

    [gridId, rest, rows, isLoading, apiRef],
  );

  const {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleTestConnection,
    handleProxyAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  } = useFormActions({
    row: selectedRow,
    layout,
    proxiesFormRef: proxiesFormRef,
  });
  usePageHeader({ handleProxyAdd, shouldShowAction: !!rows?.length });
  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  const config: BigidLayoutMasterDetailsConfig<ProxyGridRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  return (
    <Wrapper>
      <BigidLayoutMasterDetails
        disabled={isBusy || hasNoProxies}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<ProxyGridRow>): JSX.Element => {
            const { isPending } = selectedItem ?? {};
            return (
              selectedItem && <ProxiesHeader title={isPending ? t('message.addProxiesHeader') : selectedItem?.name} />
            );
          }}
        />
        <BigidLayoutMasterDetails.Content
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<ProxyGridRow>) => {
            return selectedItem ? (
              <ProxiesFormMemo
                credentialsOptions={credentialsOptions}
                certificatesOptions={certificatesOptions}
                key={selectedItem?.id}
                ref={proxiesFormRef}
                row={selectedItem}
                formControls={formControls}
                setFormIsTouched={updateIsRouteLeavingEnabledMemo}
              />
            ) : (
              hasNoProxies && <ProxiesEmptyState handleProxyAdd={handleProxyAdd} />
            );
          }}
        />
        <BigidLayoutMasterDetails.Footer
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<ProxyGridRow>) =>
            selectedItem && (
              <ProxiesFooter
                row={selectedItem}
                isBusy={isBusy}
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
                handleTestConnection={handleTestConnection}
              />
            )
          }
        />
      </BigidLayoutMasterDetails>
    </Wrapper>
  );
};
