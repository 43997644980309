import { isValid } from 'date-fns';
import { httpService, QueryParamsAndDataObject } from '../../services/httpService';

export interface UsageReportState {
  startDate: Date;
  endDate: Date;
  isChecked: boolean;
  hasErrors: boolean;
}

export interface UsageReportStateSetters {
  getUsageReportState: () => UsageReportState;
  setUsageReportState: (newState: Partial<UsageReportState>) => void;
}

export const hasOneNullDate = ({ startDate, endDate }: Pick<UsageReportState, 'startDate' | 'endDate'>) => {
  return Boolean((startDate && !endDate) || (!startDate && endDate));
};

export const isRangeInvalid = ({ startDate, endDate }: Pick<UsageReportState, 'startDate' | 'endDate'>) => {
  return (
    hasOneNullDate({ startDate, endDate }) ||
    (isValid(startDate) && isValid(endDate) && startDate.getTime() - endDate.getTime() > 0)
  );
};

export const downloadUsageReport = (queryParams?: QueryParamsAndDataObject) =>
  httpService.downloadFile('file-download/usage-report', queryParams);
