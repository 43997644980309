import { useEffect, useRef, useState } from 'react';
import { isNull, noop, uniqueId } from 'lodash';
import {
  BigidFormField,
  BigidFormStateAndHandlers,
  BigidFormValues,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidLayoutMasterDetailsEvent, BigidLayoutMasterDetailsGridEvents, useLayout } from '@bigid-ui/layout';
import { BigidGridRow } from '@bigid-ui/grid';
import { generateDataAid } from '@bigid-ui/utils';
import { ApplicationGridRow } from '../types';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { useLocalTranslation } from '../translations';
import { notificationService } from '../../../services/notificationService';
import { getPermissions, prepareRequestData } from '../utils';
import { applicationSetupService } from '../../../../administration/applicationSetup/applicationSetup.service';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { ApplicationDialogContent } from '../ApplicationDialogContent';

interface UseFormActions {
  row: ApplicationGridRow;
  layout: ReturnType<typeof useLayout<ApplicationGridRow>>;
  fields: BigidFormField[];
}

interface ShowUnsavedChangesDialog {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
}

export const useFormActions = ({ row, layout, fields }: UseFormActions) => {
  const [isBusy, setIsBusy] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const selectedRowNameRef = useRef<string>(null);
  const pendingSearchTerm = useRef(null);
  const { isEditPermitted } = getPermissions();

  const { t } = useLocalTranslation();
  const applicationsDataSourceListEnabled = getApplicationPreference('APPLICATIONS_DATA_SOURCE_LIST_ENABLED');
  const {
    refresh,
    addRow,
    deleteRow,
    setSelectedRowById,
    clearSearch,
    updateRowById,
    emit,
    rows,
    EmitterType,
    isLoading,
    apiRef,
  } = layout;

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.clearVirtualTabelCache?.();
      const hasPendingSearch = !isNull(pendingSearchTerm.current);
      const selectedRow = selectedRowNameRef.current
        ? rows?.find(({ name }) => name === selectedRowNameRef.current)
        : false;
      const firstRowId = rows.length && [rows[0].id];
      const selectedId = selectedRow && [selectedRow.id];

      hasPendingSearch &&
        emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);
      setSelectedRowById(selectedId || firstRowId || []);

      selectedRowNameRef.current = null;
      pendingSearchTerm.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const createNewGridRow = (): string => {
    const rowId = uniqueId('');
    const row: ApplicationGridRow = {
      name: t('newApplicationTitle'),
      app_account_name: '',
      source_ip: '',
      ...(applicationsDataSourceListEnabled
        ? {
            target_source_list: [{ source: 'None' }],
          }
        : {
            target_data_source: '',
          }),
      owner_email: '',
      owner_name: '',
      owner_phone: '',
      security_tier: '1',
      location: '',
      data_mapping_classifier: '1',
      isPending: true,
      id: rowId,
    };
    addRow(row);
    return rowId;
  };

  const resetForm = () => {
    formControls.current?.clear();
    formControls.current?.setAllTouched(false);
  };

  const handleSubmit = (shouldRefreshGrid = true) =>
    new Promise<void>(resolve => {
      formControls.current?.validateAndSubmit(async (values: BigidFormValues) => {
        try {
          const { isPending, name } = row;
          const isNameDuplicated = rows.find(({ name: appName }) => appName === values.name);
          if (isPending && isNameDuplicated) {
            formControls.current?.setFieldError('name', t('message.duplicatedName'));
            return;
          }
          const data = prepareRequestData(values, fields, applicationsDataSourceListEnabled);

          setIsBusy(true);
          await Promise.resolve(
            isPending
              ? applicationSetupService.createApplicationItem(data)
              : applicationSetupService.updateApplicationItem(name, data),
          );
          formControls.current?.setAllTouched(false);
          const hasPendingSearch = !isNull(pendingSearchTerm.current);
          const prevSelectedRowNameRef = selectedRowNameRef.current;
          selectedRowNameRef.current = hasPendingSearch ? null : prevSelectedRowNameRef ?? values.name;
          notificationService.success(isPending ? t('message.createdSuccessfully') : t('message.updatedSuccessfully'), {
            shouldCloseOnTransition: false,
          });
          if (shouldRefreshGrid) {
            refresh();
          } else {
            updateRowById(row.id, { ...row, ...data });
          }
          resolve();
        } catch ({ data }) {
          const isDuplicateKeyError = data?.message.includes('E11000 duplicate key error');
          const message = isDuplicateKeyError ? t('message.unique') : t('message.commonError');
          notificationService.error(message);
          pendingSearchTerm.current = null;
          selectedRowNameRef.current = null;
        } finally {
          setIsBusy(false);
        }
      });
    });

  const handleDelete = async () => {
    try {
      const { isPending, name } = row;

      if (isPending) {
        resetForm();
        deleteRow(row);
        const remainingRows = rows.filter(({ id }) => id !== row.id);
        if (remainingRows.length) {
          setSelectedRowById([remainingRows[0].id]);
        }
        return;
      }

      setIsBusy(true);
      await applicationSetupService.deleteApplicationItem(name);
      notificationService.success(t('message.deletedSuccessfully'));
      refresh();
    } catch ({ data }) {
      notificationService.error(data?.message ?? t('message.commonError'));
    } finally {
      setIsBusy(false);
    }
  };

  const handleSubmitCancel = () => {
    const { isPending } = row ?? {};
    const hasPendingSearch = !isNull(pendingSearchTerm.current);

    isPending ? deleteRow(row) : resetForm();
    emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SELECT_STATE_CHANGE, [
      rows?.find(row => row.name === selectedRowNameRef.current)?.id,
    ]);
    hasPendingSearch &&
      emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);

    pendingSearchTerm.current = null;
    selectedRowNameRef.current = null;
  };

  const configureUnsavedChangesDialog = ({ onClickCancel, onClickSave }: ShowUnsavedChangesDialog) =>
    new Promise<boolean>(resolve => {
      const dialogOptions: BigidSystemDialogOptions = {
        title: t('modal.unsavedChanges.title'),
        onClose: noop,
        maxWidth: 'xs',
        buttons: [
          {
            component: SecondaryButton,
            dataAid: generateDataAid('ApplicationFooter', ['cancel', 'submit']),
            isClose: true,
            onClick: () => {
              onClickCancel();
              resolve(true);
            },
            text: t('buttons.no'),
          },
          {
            component: PrimaryButton,
            dataAid: generateDataAid('ApplicationFooter', ['confirm', 'submit']),
            isClose: true,
            disabled: !isEditPermitted,
            onClick: async () => {
              await onClickSave();
              resolve(true);
            },
            text: t('buttons.yes'),
          },
        ],
        content: ApplicationDialogContent,
        contentProps: { body: t('modal.unsavedChanges.body') },
        borderTop: true,
      };

      openSystemDialog(dialogOptions);
    });

  const showUnsavedChangesDialog = () =>
    configureUnsavedChangesDialog({
      onClickCancel: handleSubmitCancel,
      onClickSave: async () => {
        await handleSubmit();
      },
    });

  const addGridRow = () => {
    const hasPendingRows = rows.find(({ isPending }) => isPending);

    if (hasPendingRows) {
      notificationService.warning(t('message.pendingApplication'));
      return;
    }

    clearSearch();
    const rowId = createNewGridRow();
    setSelectedRowById([rowId]);
  };

  const handleApplicationAdd = () => {
    if (formControls.current?.formTouched) {
      return configureUnsavedChangesDialog({
        onClickCancel: addGridRow,
        onClickSave: async () => {
          await handleSubmit(false);
          addGridRow();
        },
      });
    }

    addGridRow();
  };

  const handleSelectionChange = ({ selectedRowIds = [] }: BigidLayoutMasterDetailsEvent<BigidGridRow>): boolean => {
    const isDirty = formControls.current?.formTouched;
    const [id] = selectedRowIds;
    const { isPending, id: prevSelectedId } = row ?? {};
    const hasSelectedRow = !!row;
    const shouldChangeSelection = !hasSelectedRow ? true : !isPending && !isDirty && !isBusy;

    if (id === prevSelectedId) {
      return;
    }

    if (!shouldChangeSelection) {
      selectedRowNameRef.current = rows?.find(row => row.id === id)?.name;
      showUnsavedChangesDialog();
    }

    return shouldChangeSelection;
  };

  const handleSearchChange = ({ searchTerm }: BigidLayoutMasterDetailsEvent<BigidGridRow>) => {
    const { isPending, name } = row ?? {};
    const isDirty = formControls.current?.formTouched;
    const shouldChangeSearchTerm = !isPending && !isDirty && !isBusy;

    if (!shouldChangeSearchTerm) {
      selectedRowNameRef.current = name;
      pendingSearchTerm.current = searchTerm;
      showUnsavedChangesDialog();
    }

    return shouldChangeSearchTerm;
  };

  return {
    isBusy,
    formControls,
    handleSubmit,
    handleApplicationAdd,
    handleDelete,
    handleSelectionChange,
    handleSubmitCancel,
    handleSearchChange,
    showUnsavedChangesDialog,
  };
};
