import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { DataSourceDeletionModel } from './DataMinimizationDataSources';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { dateTimeService } from '@bigid-ui/i18n';

export const dataMinimizationDataSourcesDefaultColumns: BigidGridColumn<DataSourceDeletionModel>[] = [
  {
    title: 'Data Source Name',
    name: 'name',
    width: 220,
    type: BigidGridColumnTypes.LINK,
    isListColumn: true,
    sortingEnabled: true,
    formatter: {
      onClick: async ({ value }) => {
        $state.go(CONFIG.states.DATA_MINIMIZATION_OBJECT, { dsName: value.link.text });
        return {};
      },
    },
    getCellValue: ({ name }) => ({
      link: {
        text: name,
      },
    }),
  },
  {
    title: 'Data Source Type',
    name: 'type',
    width: 200,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ type }) => type,
  },
  {
    title: 'Last Submit Date',
    name: 'updated_at',
    width: 150,
    type: BigidGridColumnTypes.TEXT,
    filteringEnabled: true,
    sortingEnabled: true,
    getCellValue: ({ updated_at }) => dateTimeService.formatDate(updated_at),
  },
  {
    title: 'Progress (# of objects)',
    name: 'states_str',
    width: 300,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    getCellValue: ({ states_str }) => states_str,
  },
];
