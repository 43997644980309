import { Modal, styled } from '@mui/material';
import { noop } from 'lodash';
import React, { FC } from 'react';
import { DomainManagementProvider } from './DomainCreationContext';
import { ModalContent } from './ModalContent/ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 625px;
  width: 960px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  justify-content: space-between;
  border-radius: 8px;
`;

type DomainModalProps = {
  isOpen: boolean;
  onClose: () => void;
  domainId?: string;
};

export const DomainModal: FC<DomainModalProps> = ({ isOpen, onClose, domainId }) => {
  const handleDeleteButtonClick = () => {
    noop();
  };

  const handleSaveButtonClick = () => {
    noop();
  };

  return (
    <DomainManagementProvider domainId={domainId}>
      <StyledModal open={isOpen} onClose={onClose}>
        <ModalContainer>
          <ModalHeader onClose={onClose} />
          <ModalContent />
          <ModalFooter
            onCloseButtonClick={onClose}
            onDeleteButtonClick={handleDeleteButtonClick}
            onSaveButtonClick={handleSaveButtonClick}
          />
        </ModalContainer>
      </StyledModal>
    </DomainManagementProvider>
  );
};
