import { useEffect, useState } from 'react';
import { $stateParams, $transitions } from '../../../../services/angularServices';

type Indexable = { [key: string]: string };

export const useUpdatedStateParams = () => {
  const [stateParams, setStateParams] = useState<Indexable>($stateParams);

  useEffect(() => {
    const deregister = $transitions.onSuccess({}, transition => {
      const params = transition.params('to');
      setStateParams(params);
    });

    return () => {
      deregister();
    };
  }, []);

  return stateParams;
};
