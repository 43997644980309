import { BigidTooltip } from '@bigid-ui/components';
import React, { FC } from 'react';
import { BigidAttributeIcon, BigidTermIcon } from '@bigid-ui/icons';
import { BusinessGlossaryRecord } from '../types';
import { statusToColorMap } from './common';
import { TooltipTitle } from './TooltipTitle';
import { styled } from '@mui/material';

type IconContainerProps = {
  backgroundColor: string;
};

const IconContainer = styled('div')<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
`;

type GridIconProps = Pick<BusinessGlossaryRecord, 'businessObjectType' | 'status'>;

export const GridIcon: FC<GridIconProps> = ({ status, businessObjectType }) => {
  const Icon = businessObjectType === 'attribute' ? BigidAttributeIcon : BigidTermIcon;

  return (
    <BigidTooltip title={<TooltipTitle status={status} />} placement="right">
      <IconContainer backgroundColor={statusToColorMap[status]}>
        <Icon color="white" />
      </IconContainer>
    </BigidTooltip>
  );
};
