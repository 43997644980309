import React, { FC } from 'react';
import { PrimaryButton, SecondaryButton, BigidHeading2, BigidBody1 } from '@bigid-ui/components';
import { SystemDialogContentProps, openSystemDialog } from '../../../../../../services/systemDialogService';
import styled from '@emotion/styled';
import { httpService } from '../../../../../../services/httpService';
import { notificationService } from '../../../../../../services/notificationService';
import { CaseModel } from '../../../../../../views/ActionableInsights/actionableInsightsService';
import {
  mapActionSubTypesToCommands,
  mapCommandToDisplayName,
  REVOKE_OPEN_ACCESS,
  SEND_TO_CORTEX_ACTION,
} from './CaseActionsWidget';
import { CaseSidePanelReducerAction, ReducerActions } from '../../../hooks/CaseSidePanelReducer';
import { CaseReportAffectedObjects } from '../../caseReportService';
import { generateDataAid } from '@bigid-ui/utils';

export const APPLY_ACTION_DEFAULT_CONFIRMATION_MESSAGE_HEADING = 'Apply the action "{action}" to these objects?';
export const APPLY_ACTION_DEFAULT_CONFIRMATION_MESSAGE_BODY =
  'Some actions might affect business flows. Consider the implications before proceeding.';

export enum CaseActionSubTypes {
  REVOKE_EXTERNAL_ACCESS = 'revokeExternalAccess',
  REVOKE_OPEN_ACCESS = 'revokeOpenAccess',
  ENABLE_ACCESS_LOGGING = 'logging',
  RESTRICT_PUBLIC_ACCESS = 'public_access',
  SEND_TO_CORTEX = 'sendToCortex',
}

interface ModalContent {
  ModalContent: FC<SystemDialogContentProps<ModalContentType>>;
  ModalContentTitle: string;
  buttonText: string;
}

interface ModalContentType {
  type: string | CaseActionSubTypes;
}

const ModalContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
});

export const containerActions = [CaseActionSubTypes.ENABLE_ACCESS_LOGGING, CaseActionSubTypes.RESTRICT_PUBLIC_ACCESS];
export const revokeActions = [CaseActionSubTypes.REVOKE_OPEN_ACCESS, CaseActionSubTypes.REVOKE_EXTERNAL_ACCESS];
const mapContainerizedFilterToFilterFieldName = new Map<string, string>([
  ['containerizedRegexFilter', 'containerizedRegexFilters'],
  ['containerizedSpecificNameFilter', 'specificNameFilters'],
  ['containerizedStartWithFilter', 'containerizedStartWithFilters'],
  ['containerizedEndWithFilter', 'containerizedEndWithFilters'],
  ['containerizedContainsFilter', 'containerizedContainsFilters'],
]);
const cortexActions = [CaseActionSubTypes.SEND_TO_CORTEX];

const RevokeModalContent: FC<SystemDialogContentProps<ModalContentType>> = ({ type }) => {
  const revokingType = type === REVOKE_OPEN_ACCESS ? 'Open' : 'External';
  return (
    <ModalContentWrapper>
      <BigidHeading2>
        Revoking {`${revokingType}`} Access for these documents will restrict access for users outside of your
        organization.
      </BigidHeading2>
      <BigidBody1>Please consider the implications before proceeding with this action.</BigidBody1>
    </ModalContentWrapper>
  );
};

const AccessLoggingModalContent: FC<SystemDialogContentProps<ModalContentType>> = ({ type }) => {
  return (
    <ModalContentWrapper>
      <BigidHeading2>
        {`${type}`} action for the identified S3 bucket will help improve security and compliance by recording all
        access activity.
      </BigidHeading2>
      <BigidBody1>
        Note that this may result in increased storage costs. Are you sure you want to proceed with this action?
      </BigidBody1>
    </ModalContentWrapper>
  );
};

const PublicAccessModalContent: FC<SystemDialogContentProps<ModalContentType>> = ({ type }) => {
  return (
    <ModalContentWrapper>
      <BigidHeading2>
        By activating &lsquo;Restrict Bucket Access&lsquo; action, all public access to the S3 bucket will be
        restricted, enhancing data security and ensuring compliance.
      </BigidHeading2>
      <BigidBody1>Are you sure you want to proceed with this action?</BigidBody1>
    </ModalContentWrapper>
  );
};

const CortexModalContent: FC<SystemDialogContentProps<ModalContentType>> = ({ type }) => {
  const confirmationMessage = APPLY_ACTION_DEFAULT_CONFIRMATION_MESSAGE_HEADING.replace('{action}', 'Send to Cortex');
  return (
    <ModalContentWrapper>
      <BigidHeading2>{confirmationMessage}</BigidHeading2>
      <BigidBody1>
        (This action is limited to 100K objects)
        <br />
        {APPLY_ACTION_DEFAULT_CONFIRMATION_MESSAGE_BODY}
      </BigidBody1>
    </ModalContentWrapper>
  );
};

const mapModelContentToAction = new Map<CaseActionSubTypes, ModalContent>([
  [
    CaseActionSubTypes.REVOKE_OPEN_ACCESS,
    {
      ModalContent: RevokeModalContent,
      ModalContentTitle: 'Revoke Open Access',
      buttonText: 'Revoke Access',
    },
  ],
  [
    CaseActionSubTypes.REVOKE_EXTERNAL_ACCESS,
    {
      ModalContent: RevokeModalContent,
      ModalContentTitle: 'Revoke External Access',
      buttonText: 'Revoke Access',
    },
  ],
  [
    CaseActionSubTypes.ENABLE_ACCESS_LOGGING,
    {
      ModalContent: AccessLoggingModalContent,
      ModalContentTitle: 'Enable Access Logging',
      buttonText: 'Enable Access Logging',
    },
  ],
  [
    CaseActionSubTypes.RESTRICT_PUBLIC_ACCESS,
    {
      ModalContent: PublicAccessModalContent,
      ModalContentTitle: 'Restrict Public Access',
      buttonText: 'Restrict Public Access',
    },
  ],
  [
    CaseActionSubTypes.SEND_TO_CORTEX,
    {
      ModalContent: CortexModalContent,
      ModalContentTitle: 'Send to Cortex',
      buttonText: 'Send to Cortex',
    },
  ],
]);

const getContainerName = async (dsName: string): Promise<string> => {
  const {
    data: { ds_connection },
  } = await httpService.fetch(`ds_connections/${dsName}`);
  const bucketName = ds_connection?.bucket_name;
  if (bucketName) {
    return bucketName;
  }
  try {
    const containerizedFilter = ds_connection?.containerizedFilter;
    const containerizedFilterType = containerizedFilter['@containerizedFilterType'];
    const containerizedFilterFieldName = mapContainerizedFilterToFilterFieldName.get(containerizedFilterType);
    const useCase = containerizedFilter[containerizedFilterFieldName].containerUseCase?.useCase;
    const containerName = containerizedFilter[containerizedFilterFieldName].containerUseCase?.name;
    if (useCase === 'INCLUDE' && !containerName.includes(',')) {
      return containerName;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

const ApplyActionOnCase = async (
  payload: any,
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
  caseId: string,
  serviceSuccessMessage: string,
  commandField: string,
) => {
  const preSetupMessage =
    'Once the action is done, you need to rescan the objects to validate that remediation was a success.';
  try {
    const {
      data: { data },
    } = await httpService.post(`actionable-insights/cases/${caseId}:action`, payload);
    dispatch({
      type: ReducerActions.UPDATE_ACTIONS,
      payload: {
        actionsList: {
          [commandField]: data,
        },
      },
    });
    notificationService.info(`${serviceSuccessMessage}.\n${preSetupMessage}`, {
      duration: 3000,
    });
  } catch (e) {
    console.error(e);
    notificationService.error('Could not fetch data. See logs for more information');
  }
};

export const accessLoggingUpdate = async (
  caseSidePanelData: CaseModel,
  remediationType: CaseActionSubTypes,
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
) => {
  const commandField = mapActionSubTypesToCommands.get(remediationType);
  const actionDisplayName = mapCommandToDisplayName.get(commandField);

  const serviceSuccessMessage = `Your request to ${actionDisplayName} from this case is being processed.\n Review the progress in the Action Center`;
  const bucketName = await getContainerName(caseSidePanelData.dataSourceName);
  if (bucketName == undefined) {
    notificationService.error('This data source is missing a bucket, the selected operation cannot be performed');
    return;
  }
  const payload = {
    type: 'remediation',
    subType: remediationType,
    additionalProperties: {
      configurationId: caseSidePanelData.id,
      actionName: 'container remediation',
      command: commandField,
      dsName: caseSidePanelData.dataSourceName,
      policyName: caseSidePanelData.policyName,
      containerName: bucketName,
    },
  };
  await ApplyActionOnCase(payload, dispatch, caseSidePanelData.id, serviceSuccessMessage, commandField);
};

export const revokeAccessUpdate = async (
  caseSidePanelData: CaseModel,
  accessType: CaseActionSubTypes,
  allObjectsSelected: boolean,
  selectedItems: string[],
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
  selectedObjects: CaseReportAffectedObjects[],
) => {
  const commandField = mapActionSubTypesToCommands.get(accessType);
  const allObjectsListSizeValue = allObjectsSelected
    ? caseSidePanelData?.numberOfAffectedObjects
    : selectedItems?.length;
  const serviceSuccessMessage = allObjectsSelected
    ? 'Your request to revoke access violations on objects from this case is being processed.\n Review the progress in the Action Center'
    : 'Your request to revoke access violations on selected objects is being processed.\n Review the progress in the Action Center';

  const listOfSelectedObjects = selectedObjects?.filter((object: CaseReportAffectedObjects) =>
    selectedItems?.includes(object?.id as string),
  );
  const dsName = caseSidePanelData?.dataSourceName;
  const affectedList = listOfSelectedObjects?.map((item: CaseReportAffectedObjects) => `${dsName}.${item.path}`);
  const objectsListValue = allObjectsSelected ? [] : affectedList;
  const payload = {
    type: 'revoke',
    subType: accessType,
    additionalProperties: {
      configurationId: caseSidePanelData.id,
      actionName: 'revoke',
      command: commandField,
      dsName: caseSidePanelData.dataSourceName,
      policyName: caseSidePanelData.policyName,
      allObjectsSelected: allObjectsSelected,
      allObjectsListSize: allObjectsListSizeValue,
      objectList: objectsListValue,
    },
  };
  await ApplyActionOnCase(payload, dispatch, caseSidePanelData.id, serviceSuccessMessage, commandField);
};

export const cortexUpdate = async (
  caseSidePanelData: CaseModel,
  actionType: CaseActionSubTypes,
  allObjectsSelected: boolean,
  selectedItems: string[],
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
  selectedObjects: CaseReportAffectedObjects[],
) => {
  const commandField = mapActionSubTypesToCommands.get(actionType);
  const actionDisplayName = mapCommandToDisplayName.get(commandField);
  const allObjectsListSizeValue = allObjectsSelected
    ? caseSidePanelData?.numberOfAffectedObjects
    : selectedItems?.length;
  const serviceSuccessMessage = `Your request to ${actionDisplayName} from this case is being processed.\n Review the progress in the Action Center`;

  const listOfSelectedObjects = selectedObjects?.filter((object: CaseReportAffectedObjects) =>
    selectedItems?.includes(object?.id as string),
  );
  const affectedList = listOfSelectedObjects?.map((item: CaseReportAffectedObjects) => `${item.id}`);
  const objectsListValue = allObjectsSelected ? [] : affectedList;
  const payload = {
    type: SEND_TO_CORTEX_ACTION,
    subType: actionType,
    additionalProperties: {
      configurationId: caseSidePanelData.id,
      actionName: SEND_TO_CORTEX_ACTION,
      command: commandField,
      dsName: caseSidePanelData.dataSourceName,
      dsType: caseSidePanelData.dataSourceType,
      policyName: caseSidePanelData.policyName,
      allObjectsSelected: allObjectsSelected,
      allObjectsListSize: allObjectsListSizeValue,
      objectList: objectsListValue,
    },
  };
  await ApplyActionOnCase(payload, dispatch, caseSidePanelData.id, serviceSuccessMessage, commandField);
};

export const CaseActionsModal = (
  caseSidePanelData: CaseModel,
  actionSubType: CaseActionSubTypes,
  allObjectsSelected: boolean,
  selectedItems: string[],
  dispatch: React.Dispatch<CaseSidePanelReducerAction>,
  selectedObjects?: CaseReportAffectedObjects[],
) => {
  const { ModalContent, ModalContentTitle, buttonText } = mapModelContentToAction.get(actionSubType);

  return new Promise(resolve => {
    openSystemDialog({
      title: ModalContentTitle,
      content: ModalContent,
      contentProps: {
        type: ModalContentTitle,
      },
      onClose: () => null,
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => resolve({}),
          isClose: true,
        },
        {
          text: buttonText,
          dataAid: generateDataAid('MetaDataWidget', ['action', 'button']),
          component: PrimaryButton,
          onClick: async () => {
            if (revokeActions.includes(actionSubType)) {
              revokeAccessUpdate(
                caseSidePanelData,
                actionSubType,
                allObjectsSelected,
                selectedItems,
                dispatch,
                selectedObjects,
              );
            } else if (containerActions.includes(actionSubType)) {
              accessLoggingUpdate(caseSidePanelData, actionSubType, dispatch);
            } else if (cortexActions.includes(actionSubType)) {
              cortexUpdate(
                caseSidePanelData,
                actionSubType,
                allObjectsSelected,
                selectedItems,
                dispatch,
                selectedObjects,
              );
            }
            resolve({ shouldClearSelection: false });
          },
          isClose: true,
        },
      ],
    });
  });
};
