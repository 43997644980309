import React, { FC } from 'react';
import { Input, InputProps, styled } from '@mui/material';

type InlineInputProps = InputProps;

const StyledInput = styled(Input)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.bigid.primary600};
`;

export const InlineInput: FC<InlineInputProps> = ({ value, onChange, ...props }) => {
  return <StyledInput value={value} onChange={onChange} {...props} disableUnderline={true} required={true} />;
};
