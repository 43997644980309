import { sessionStorageService } from '../../common/services/sessionStorageService';

export class MappingProcessViewModel {
  constructor(
    businessProcess,
    notificationService,
    DeleteConfirmation,
    locationService,
    dataMappingService,
    $q,
    dataSourceConnectionsService,
    applicationSetupService,
    localStorageService,
    dataMappingReportService,
    selectedentityidbyemail,
    $window,
    dataMappingDocxReportService,
    ropaMode,
  ) {
    Object.assign(this, {
      businessProcess,
      notificationService,
      DeleteConfirmation,
      locationService,
      dataMappingService,
      $q,
      dataSourceConnectionsService,
      applicationSetupService,
      localStorageService,
      dataMappingReportService,
      selectedentityidbyemail,
      $window,
      dataMappingDocxReportService,
      ropaMode,
    });
    this.dataLists = {};
    this.loading = true;
    this.disableTitleAndDescription = this.ropaMode;
    locationService.getCountriesList().then(countries => {
      this.dataLists.countries = countries;

      if (ropaMode) {
        const locationsFromRopaBusinessProcess = this.businessProcess?.ropaResource.dataSources.map(ds => ds.location);
        const locationsFromRopaBusinessProcessWithDisplayName = locationsFromRopaBusinessProcess
          ? locationsFromRopaBusinessProcess.map(dsName => {
              if (dsName) {
                return { name: dsName, code: dsName.slice(0, 2), displayName: dsName };
              }
            })
          : [];

        this.dataLists.countries = [...this.dataLists.countries, ...locationsFromRopaBusinessProcessWithDisplayName];
      }
      this.dataLists.dataMappingClassifications = [
        'Collection',
        'Transformation',
        'Retention',
        'Transfer',
        'Disposal',
      ].map((classification, idx) => ({ id: idx + 1, name: classification }));
      this.dataLists.secutiryTiers = [1, 2, 3, 4, 5].map(tier => ({ id: tier, name: tier }));
      this.loading = false;
    });

    if (this.ropaMode) {
      const businessProcessDataSources = this.businessProcess?.ropaResource.dataSources.map(ds => ds.name);
      if (businessProcessDataSources) {
        this.dataLists.dataSourceNames = ['None', ...businessProcessDataSources];
        this.dataLists.appicationNames = ['None', ...businessProcessDataSources];
      } else {
        this.dataLists.dataSourceNames = businessProcessDataSources;
        this.dataLists.appicationNames = businessProcessDataSources;
      }
    } else {
      dataSourceConnectionsService.getAllSystems().then(dataSources => {
        this.dataLists.dataSourceNames = ['None', ...dataSources.systems.map(ds => ds.name)];
      });

      applicationSetupService.getAllApplications().then(applications => {
        this.dataLists.appicationNames = ['None', ...applications.applications.map(app => app.name)];
      });
    }

    if (selectedentityidbyemail) {
      this.dataMappingService.getBusinessEntity(selectedentityidbyemail).then(entity => {
        this.openCollaboration = true;
        this.selectedEntity = entity;
      });
    }

    this._load();
    //this.loadSessionDataSaved();
  }

  loadSessionDataSaved() {
    this.$window.sessionStorage.setItem('dataMapping.bussinessProcessID', this.businessProcess._id);
  }

  editEntity(entity) {
    if (entity) {
      this.$window.sessionStorage.setItem('dataMapping.selectedEntityID', entity._id);
      if (this.selectedConnector) {
        this.cancelConnectorChanges();
      }
      if (entity && entity.country) {
        const country = this.dataLists.countries.find(
          country => country.name === entity.country || country.displayName === entity.country,
        );
        entity.country = country?.name;
      }
      this.selectedEntity = angular.copy(entity);

      if (this.entities) {
        const selectedEntityBefore = this.entities.find(entity => entity.isSelected === true);
        if (selectedEntityBefore) {
          delete selectedEntityBefore.isSelected;
        }
      }
    } else {
      this.cancelEntityChanges();
    }
  }

  updatePosition(data, direction) {
    this.loading = true;
    const selectedDataMappingClassification = data.entity.dataMappingClassification;
    //use it for existing entities that clients have already without a position index
    this.setPositionIndexForEntireEntities(selectedDataMappingClassification);
    const selectedEntities = this.entities.filter(
      a => a.dataMappingClassification === selectedDataMappingClassification,
    );
    const numberOfEntitiesInClassification = selectedEntities.length;

    const entityIndexPosition = selectedEntities.find(a => a.name === data.entity.name).indexPosition;
    //edge cases
    if (
      (direction === 'up' && entityIndexPosition === 0) ||
      (numberOfEntitiesInClassification - 1 === entityIndexPosition && direction === 'down')
    ) {
      this.loading = false;
      return;
    }
    let switchNode = null;
    switch (direction) {
      case 'up':
        data.entity.indexPosition = entityIndexPosition - 1;
        switchNode = this.entities.filter(
          a =>
            a.indexPosition === entityIndexPosition - 1 &&
            a.dataMappingClassification === selectedDataMappingClassification,
        );
        break;
      case 'down':
        data.entity.indexPosition = entityIndexPosition + 1;
        switchNode = this.entities.filter(
          a =>
            a.indexPosition === entityIndexPosition + 1 &&
            a.dataMappingClassification === selectedDataMappingClassification,
        );
        break;
    }
    //save the second Node of switching
    if (switchNode && switchNode.length > 0) {
      switchNode[0].indexPosition = entityIndexPosition;

      this.dataMappingService.updateBusinessEntity(switchNode[0], switchNode[0]._id).then(() => {
        this.dataMappingService.updateBusinessEntity(data.entity, data.entity._id).then(() => {
          this.dataMappingService.getBusinessEntity(data.entity._id).then(entity => {
            this.setHasAttributesWithoutPurposeFlag(entity);

            const index = this.entities.findIndex(entity => entity._id === data.entity._id);
            this.entities =
              index > -1
                ? [...this.entities.slice(0, index), entity, ...this.entities.slice(index + 1)]
                : [...this.entities, entity];

            this.selectedEntity = null;

            this.closeCollaborationWindow();
            this._initGraphData();
            this._initLegendDataModel();
            this.loading = false;
          });
        });
      });
    } else {
      this.loading = false;
    }
  }

  closeDataFlowPropertiesWindow() {
    this.showSetPropertiesPage = false;
  }

  cancelEntityChanges() {
    this.selectedEntity = null;
    this.closeCollaborationWindow();

    this._initGraphData();
  }

  setPositionIndexForEntireEntities(selectedDataMappingClassification) {
    //get all entities in selected data Flows classification for set index if there isn't index
    const entitiesInselectedDataMappingClassification = this.entities.filter(
      a => a.dataMappingClassification === selectedDataMappingClassification,
    );
    entitiesInselectedDataMappingClassification.forEach((entity, idx) => {
      if (typeof entity.indexPosition === 'undefined') {
        entity.indexPosition = idx;
        //update entity that doesn't contains indexPosition
        this.dataMappingService.updateBusinessEntity(entity, entity._id);
      }
    });
  }

  editClassificationName(classification, textNew, textPrevious) {
    if (this.businessProcess && this.businessProcess.classifiers) {
      const classifier = this.businessProcess.classifiers.find(
        classifier => classifier.classifierId === classification,
      );
      if (classifier) {
        classifier.name = textNew;
      }
      this.dataMappingService.updateBusinessProcess(this.businessProcess, this.businessProcess._id);
    }
  }

  duplicateEntity(entity) {
    this.loading = true;
    this.dataMappingService.duplicateBusinessEntity(entity._id).then(duplicatedEntity => {
      this.setHasAttributesWithoutPurposeFlag(duplicatedEntity);

      this.entities = [...this.entities, duplicatedEntity];

      this.selectedEntity = duplicatedEntity;
      this.selectedEntity.isSelected = true;
      this._initGraphData();
      this._initLegendDataModel();
      this.notificationService.success(`${entity.name} Duplicated Successfully!`);
      this.loading = false;
    });
  }

  saveBusinessProcessChanges(message) {
    const model = this.businessProcess;
    this.loading = true;
    const uploads = [];
    const relations = [];

    for (const i in model.annotations) {
      relations[i] = [];
      if (model.annotations[i]['attachmentsDummy']) {
        for (const j in model.annotations[i]['attachmentsDummy']) {
          uploads.push(this.dataMappingService.uploadAttachment(model.annotations[i]['attachmentsDummy'][j]));
          relations[i].push(j);
        }
      }
    }

    this.$q.all(uploads).then(
      attachments => {
        for (const i in relations) {
          const annotationAtt = relations[i];
          for (let j = 0; j < annotationAtt.length; j++) {
            if (attachments[j].upload == 'OK') {
              for (let k = 0; k < model.annotations[i]['attachments'].length; k++) {
                if (model.annotations[i]['attachments'][k]['isDummy']) {
                  model.annotations[i]['attachments'].splice(k, 1);
                }
              }
              model.annotations[i]['attachments'].push({
                attachmentId: attachments[j].attachmentId,
                filename: model.annotations[i]['attachmentsDummy'][j]['filename'],
                fileType: model.annotations[i]['attachmentsDummy'][j]['fileType'],
              });
            } else {
              this.notificationService.error(
                'An error has occurred during ' +
                  model.annotations[i]['attachmentsDummy'][j]['filename'] +
                  ' uploading!',
              );
            }
          }
          delete model.annotations[i]['attachmentsDummy'];
        }
        this.dataMappingService
          .updateBusinessProcess(model, model._id)
          .then(result => {
            this.loading = false;
            this.notificationService.success(message, { timeOut: '1000', progressBar: true, preventDuplicates: true });
          })
          .catch(() => {
            this.loading = false;
            this.notificationService.error(`Update ${model.name} Failed!`);
          });
      },
      err => {
        this.loading = false;
        this.notificationService.error('An error has occurred during attachments uploading! ' + err.data.message);
      },
    );
  }

  saveEntityChanges(CloseSideEntityWindow, message) {
    //const model = angular.copy(this.selectedEntity);
    const model = this.selectedEntity;
    const selectedDataMappingClassification = model.dataMappingClassification;

    //case of insert
    if (!model._id) {
      const index = this.entities?.filter(
        a => a.dataMappingClassification === selectedDataMappingClassification,
      ).length;
      model.indexPosition = index || 0;
    }

    if (model.type == 'application') {
      delete model.additionalDataSources;
    } else if (model.type == 'datasource') {
      delete model.additionalApps;
    }

    this.changeNoneDDLValuesToNull(model);

    const uploads = [];
    const relations = [];
    // TODO waiting for API ready to move forward
    this.loading = true;
    if (model.annotations && model.annotations.length > 0) {
      for (let i = 0; i < model.annotations.length; i++) {
        relations[i] = [];
        if (model.annotations[i]['attachmentsDummy']) {
          for (let j = 0; j < model.annotations[i]['attachmentsDummy'].length; j++) {
            uploads.push(this.dataMappingService.uploadAttachment(model.annotations[i]['attachmentsDummy'][j]));
            relations[i].push(j);
          }
        }
      }
    }

    this.$q.all(uploads).then(
      attachments => {
        if (relations && relations.length > 0) {
          for (let i = 0; i < relations.length; i++) {
            const annotationAtt = relations[i];
            for (let j = 0; j < annotationAtt.length; j++) {
              if (attachments[j].upload == 'OK') {
                for (let k = 0; k < model.annotations[i]['attachments'].length; k++) {
                  if (model.annotations[i]['attachments'][k]['isDummy']) {
                    model.annotations[i]['attachments'].splice(k, 1);
                  }
                }
                model.annotations[i]['attachments'].push({
                  attachmentId: attachments[j].attachmentId,
                  filename: model.annotations[i]['attachmentsDummy'][j]['filename'],
                  fileType: model.annotations[i]['attachmentsDummy'][j]['fileType'],
                });
              } else {
                this.notificationService.error(
                  'An error has occurred during ' +
                    model.annotations[i]['attachmentsDummy'][j]['filename'] +
                    ' uploading!',
                );
              }
            }
            delete model.annotations[i]['attachmentsDummy'];
          }
        }
        this.$q
          .all([
            this.dataMappingService.updateBusinessProcess(this.businessProcess, this.businessProcess._id),
            model._id
              ? Object.assign(model, { businessProcessId: this.businessProcess._id }) &&
                this.dataMappingService.updateBusinessEntity(model, model._id)
              : this.dataMappingService.createBusinessEntity(
                  Object.assign(model, { businessProcessId: this.businessProcess._id }),
                ),
          ])
          .then(([_, { _id }]) => {
            if (_id) {
              model._id = _id;
              if (this.selectedEntity) {
                this.selectedEntity._id = _id;
              }
            }

            this.dataMappingService.getBusinessEntity(model._id).then(entity => {
              if (this.entities && this.entities.length > 0) {
                const index = this.entities.findIndex(entity => entity._id === model._id);
                this.entities =
                  index > -1
                    ? [...this.entities.slice(0, index), entity, ...this.entities.slice(index + 1)]
                    : [...this.entities, entity];
              }

              if (CloseSideEntityWindow !== false) {
                this.selectedEntity = null;
              } else {
                this.notificationService.success(message, { progressBar: true, preventDuplicates: true });

                let selectedEntity = null;
                let entityName = '';

                if (this.selectedEntity) {
                  entityName = this.selectedEntity.name;
                } else {
                  entityName = entity.name;
                }
                if (this.entities && this.entities.length > 0) {
                  selectedEntity = this.entities.find(entity => entity.name === entityName);
                  if (selectedEntity) {
                    selectedEntity.isSelected = true;
                  }
                } else {
                  this.entities.push(entity);
                  selectedEntity = entity;
                  selectedEntity.isSelected = true;
                }
              }

              this.setHasAttributesWithoutPurposeFlag(entity);
              this.closeCollaborationWindow();
              this._initGraphData();
              this._initLegendDataModel();
              this.loading = false;
            });
          })
          .catch(() => {
            this.loading = false;
            this.notificationService.error(`${model._id ? 'Create' : 'Update'} ${model.name} Failed!`);
          });
      },
      err => {
        this.loading = false;
        this.notificationService.error('An error has occurred during attachments uploading! ' + err.data.message);
      },
    );
  }

  changeNoneDDLValuesToNull(model) {
    if (model.dataSourceName === 'None') model.dataSourceName = null;
    if (model.appAccountName === 'None') model.appAccountName = null;
  }

  deleteEntity(entity) {
    const id = entity._id;

    const modalOptions = {
      closeButtonText: 'Close',
      actionButtonText: 'Delete',
      headerText: `Delete ${entity.type}`,
      bodyText: `Deleting a business entity deletes all of the steps associated with it. Are you sure you want to delete "${entity.name}"?`,
    };

    this.DeleteConfirmation.showModal({}, modalOptions).then(() => {
      this.dataMappingService
        .deleteBusinessEntity(id)
        .then(() => {
          const relatedConnectors = this.connectors.filter(connector => connector.from === id || connector.to === id);
          return this.$q
            .all(relatedConnectors.map(connector => this.dataMappingService.deleteBusinessStep(connector._id)))
            .then(() => {
              // this.notificationService.success(`${entity.name} Deleted Successfully!`);
              this.entities = this.entities.filter(entity => entity._id !== id);

              this.connectors = this.connectors.filter(connector => connector.from !== id && connector.to !== id);

              this.selectedEntity = null;

              //get all entities that related to dataMappingClassification
              const entitiesRelatedToSelectedDataMappingClassification = this.entities.filter(
                a => a.dataMappingClassification === entity.dataMappingClassification,
              );
              //get the indexPosition of the current entity
              const deletedEntityIndexPosition = entity.indexPosition;
              //in a loop change the indexPosition of the entities that bigger than the index position by -1
              entitiesRelatedToSelectedDataMappingClassification.forEach(entity => {
                if (entity.indexPosition > deletedEntityIndexPosition) {
                  entity.indexPosition = entity.indexPosition - 1;
                  this.dataMappingService.updateBusinessEntity(entity, entity._id);
                }
              });

              this.closeCollaborationWindow();
              this._initGraphData();
              this._initLegendDataModel();
            });
        })
        .catch(() => this.notificationService.error(`Delete ${entity.name} Failed!`));
    });
  }

  deleteLane(laneNumber) {
    const vm = this;

    const modalOptions = {
      closeButtonText: 'Close',
      actionButtonText: 'Delete',
      headerText: `Delete ${this.businessProcess.classifiers[laneNumber - 1].name}`,
      bodyText: `By deleting this lane, all affected objects will be moved to the left.
                  Are you sure you want to delete ${this.businessProcess.classifiers[laneNumber - 1].name} lane?`,
    };

    this.DeleteConfirmation.showModal({}, modalOptions).then(() => {
      //find all entities that equal or bigger than the data classification number
      const entities = vm.entities.filter(entity => entity.dataMappingClassification >= laneNumber);
      //create array of _id : entityid, id: dataMappingClassification number - 1, name: dataMappingClassification
      const entitiesToUpdate = [];
      entities.forEach(entity => {
        const entityToAdd = {};
        entityToAdd._id = entity._id;
        entityToAdd.fieldName = 'dataMappingClassification';
        entityToAdd.fieldValue = entity.dataMappingClassification - 1;
        entitiesToUpdate.push(entityToAdd);
      });
      if (vm.businessProcess.classifiers) {
        vm.businessProcess.classifiers.splice(laneNumber - 1, 1);
        vm.businessProcess.classifiers.forEach(classifier => {
          if (classifier.classifierId > laneNumber) {
            classifier.classifierId--;
          }
        });
      }

      this.$q
        .all([
          this.dataMappingService.updateBusinessProcess(this.businessProcess, this.businessProcess._id),
          this.dataMappingService.updateBusinessEntities(entitiesToUpdate),
        ])
        .then(() => {
          this._load();
        })
        .catch(() => this.notificationService.error(`Delete Lane Failed!`));
    });
  }

  openCollaborationWindow(entity, isNewCollaboration, task) {
    if (entity && !angular.equals(entity, this.selectedEntity)) {
      this.editEntity(entity);
    }
    this.task = task;
    this.isNewCollaboration = isNewCollaboration;
    this.openCollaboration = true;
    this.openTaskList = false;
    this.openExistingBusinessProcess = false;
    this.openMissingPurpose = false;
    this.disabledActionButton = true;
  }

  closeCollaborationWindow() {
    if (this.selectedEntity && this.selectedEntity.showGroupPicker) {
      this.selectedEntity.showGroupPicker = false;
    }
    this.openCollaboration = false;
    this.disabledActionButton = false;
    this.openExistingBusinessProcess = false;
  }

  closeExistingBusinessProcess() {
    this.openExistingBusinessProcess = false;
  }

  openMissingPurposeWindow(entity, task) {
    if (entity && !angular.equals(entity, this.selectedEntity)) {
      this.editEntity(entity);
    }
    this.task = task;
    this.openMissingPurpose = true;
  }

  closeMissingPurposeWindow() {
    this.openMissingPurpose = false;
  }

  openTaskListWindow(entity) {
    if (entity && !angular.equals(entity, this.selectedEntity)) {
      this.editEntity(entity);
    }
    this.openTaskList = true;
    this.openMissingPurpose = false;
    this.openCollaboration = false;
    this.openExistingBusinessProcess = false;
  }

  closeTaskListWindow() {
    this.openTaskList = false;
  }

  saveCollaboration(comment) {
    const userName = sessionStorageService.get('userName');
  }

  addEntityCollaboration(entityId) {
    const entity = this.entities.find(entity => entity._id === entityId);
    entity.hasActiveCollaborationTask = true;

    this.editEntity(entity);
    this.saveEntityChanges();
  }

  resolvedEntityCollaboration(entityId) {
    const entity = this.entities.find(entity => entity._id === entityId);
    entity.hasActiveCollaborationTask = false;

    this.editEntity(entity);
    this.saveEntityChanges();
  }

  addEntityMissingPurpose(entityId) {
    const entity = this.entities.find(entity => entity._id === entityId);
    entity.hasOpenTasks = true;

    this.editEntity(entity);
    this.saveEntityChanges();
  }

  resolvedEntityMissingPurpose(entityId) {
    const entity = this.entities.find(entity => entity._id === entityId);
    this.closeMissingPurposeWindow();
    this._load();
    //this.editEntity(entity);
    //this.saveEntityChanges();
  }

  setEntityBadge(entityId) {
    const entity = this.entities.find(entity => entity._id === entityId);
    entity.hasAttributesWithoutPurpose = true;

    this.editEntity(entity);
  }

  editConnector(connector) {
    if (this.selectedEntity) {
      this.selectedEntity.type = null;
    }
    this.selectedConnector = angular.copy(connector);
  }

  cancelConnectorChanges() {
    this.selectedConnector = null;

    this._initGraphData();
  }

  saveConnector(CloseSideConnectorWindow, message, connector) {
    const model = connector;
    delete model.toArrow;
    delete model.fromArrow;
    const uploads = [];
    const relations = [];

    for (const i in model.annotations) {
      relations[i] = [];
      if (model.annotations[i]['attachmentsDummy']) {
        for (const j in model.annotations[i]['attachmentsDummy']) {
          uploads.push(this.dataMappingService.uploadAttachment(model.annotations[i]['attachmentsDummy'][j]));
          relations[i].push(j);
        }
      }
    }

    this.$q.all(uploads).then(
      attachments => {
        if (relations && relations.length > 0) {
          for (const i in relations) {
            const annotationAtt = relations[i];
            for (let j = 0; j < annotationAtt.length; j++) {
              if (attachments && attachments.length > 0 && attachments[j].upload == 'OK') {
                for (let k = 0; k < model.annotations[i]['attachments'].length; k++) {
                  if (model.annotations[i]['attachments'][k]['isDummy']) {
                    model.annotations[i]['attachments'].splice(k, 1);
                  }
                }
                model.annotations[i]['attachments'].push({
                  attachmentId: attachments[j].attachmentId,
                  filename: model.annotations[i]['attachmentsDummy'][j]['filename'],
                  fileType: model.annotations[i]['attachmentsDummy'][j]['fileType'],
                });
              } else {
                this.notificationService.error(
                  'An error has occurred during ' +
                    model.annotations[i]['attachmentsDummy'][j]['filename'] +
                    ' uploading!',
                );
              }
            }
            delete model.annotations[i]['attachmentsDummy'];
          }
        }

        this.$q
          .all([
            this.dataMappingService.updateBusinessProcess(this.businessProcess, this.businessProcess._id),
            model._id
              ? this.dataMappingService.updateBusinessStep(model, model._id)
              : this.dataMappingService.createBusinessStep(
                  Object.assign(model, { businessProcessId: this.businessProcess._id }),
                ),
          ])
          .then(([_, { id }]) => {
            //this.notificationService.success(`Step ${model.stepNumber} ${id ? 'Created' : 'Updated'} Successfully!`);

            if (id) {
              model._id = id;
            }

            const index = this.connectors.findIndex(connector => connector._id === model._id);
            this.connectors =
              index > -1
                ? [...this.connectors.slice(0, index), model, ...this.connectors.slice(index + 1)]
                : [...this.connectors, model];

            if (CloseSideConnectorWindow !== false) {
              this.selectedConnector = null;
            } else {
              this.notificationService.success(message, {
                timeOut: '1000',
                progressBar: true,
                preventDuplicates: true,
              });
              if (model._id) {
                this.selectedConnector._id = model._id;
              }
            }

            this.applyFilters();
            this._initLegendDataModel();
          })
          .catch(() =>
            this.notificationService.error(`${model._id ? 'Create' : 'Update'} Step ${model.stepNumber} Failed!`),
          );
      },
      err => {
        this.loading = false;
        this.notificationService.error('An error has occurred during attachments uploading! ' + err.data.message);
      },
    );
  }

  deleteConnector(connector) {
    const id = connector._id;

    this.dataMappingService
      .deleteBusinessStep(id)
      .then(() => {
        //this.notificationService.success(`Step ${connector.stepNumber} Deleted Successfully!`);

        this.connectors = this.connectors.filter(connector => connector._id !== id);

        this.selectedConnector = null;

        this.applyFilters();
        this._initLegendDataModel();
      })
      .catch(() => this.notificationService.error(`Delete Step ${connector.stepNumber} Failed!`));
  }

  cancelEdit() {
    this.$window.sessionStorage.setItem('dataMapping.selectedEntityID', null);
    if (this.selectedEntity) {
      this.cancelEntityChanges();
    }
    if (this.selectedConnector) {
      this.cancelConnectorChanges();
    }
  }

  selectFlow(flow) {
    this.selectedFlow = flow;
    this._initLegendDataModel();
  }

  _initLegendDataModel() {
    const flowId = this.selectedFlow && this.selectedFlow._id;
    this.selectedFlowSetps = flowId
      ? this.connectors
          .filter(connector => connector.flowId === flowId)
          .map(connector => ({
            from: this.entities.find(entity => entity._id === connector.from),
            link: connector,
            to: this.entities.find(entity => entity._id === connector.to),
          }))
          .sort((a, b) => a.link.stepNumber - b.link.stepNumber)
      : null;
  }

  saveFlow(flow) {
    const model = angular.copy(flow);
    const hidden = model.hidden;

    delete model.hidden;

    (model._id
      ? this.dataMappingService.updateDataFlow(model, model._id)
      : this.dataMappingService.createDataFlow(Object.assign(model, { businessProcessId: this.businessProcess._id }))
    )
      .then(({ id }) => {
        //this.notificationService.success(`${model.name} ${id ? 'Created' : 'Updated'} Successfully!`);

        if (id) {
          model._id = id;
        }
        model.hidden = hidden;

        const index = this.businessFlows.findIndex(flow => flow._id === model._id);
        this.businessFlows =
          index > -1
            ? [...this.businessFlows.slice(0, index), model, ...this.businessFlows.slice(index + 1)]
            : [...this.businessFlows, model];

        this.applyFilters();
        this._initLegendDataModel();
      })
      .catch(() => this.notificationService.error(`${model._id ? 'Create' : 'Update'} ${model.name} Failed!`));
  }

  deleteFlow(flow) {
    const id = flow._id;

    this.dataMappingService
      .deleteDataFlow(id)
      .then(() => {
        const relatedConnectors = this.connectors.filter(connector => connector.flowId === id);
        return this.$q
          .all(relatedConnectors.map(connector => this.dataMappingService.deleteBusinessStep(connector._id)))
          .then(() => {
            this.businessFlows = this.businessFlows.filter(flow => flow._id !== id);

            this.connectors = this.connectors.filter(connector => connector.flowId !== id);

            this.applyFilters();
            this._initLegendDataModel();
          });
      })
      .catch(() => this.notificationService.error(`Delete ${flow.name} Failed!`));
  }

  _load() {
    if (typeof this.businessProcess != 'undefined') {
      this.$q
        .all([
          this.dataMappingService.getBusinessEntities(this.businessProcess._id),
          this.dataMappingService.getBusinessSteps(this.businessProcess._id),
          this.dataMappingService.getBusinessFlows(this.businessProcess._id),
        ])
        .then(([entities, connectors, businessFlows]) => {
          Object.assign(this, { entities, connectors, businessFlows });
          if (businessFlows && businessFlows.length) {
            this.selectFlow(businessFlows[0]);
          }
          this.applyFilters();
        });
    }
  }

  applyFilters(flow) {
    // need to save
    const hiddenEntities = JSON.parse(this.$window.sessionStorage.getItem('dataMapping.hiddenEntities')) || null;
    const hiddenConnectors = JSON.parse(this.$window.sessionStorage.getItem('dataMapping.hiddenConnectors')) || null;
    const hiddenFlows = JSON.parse(this.$window.sessionStorage.getItem('dataMapping.hiddenFlows')) || null;
    const hiddenConnectorsArrayIDs = [];
    const hiddenEntitiesArrayIDs = [];
    const hiddenFlowsArrayIDs = [];

    if (flow || !hiddenConnectors || hiddenConnectors === 'null') {
      this.$window.sessionStorage.setItem('dataMapping.hiddenConnectors', null);
      this.$window.sessionStorage.setItem('dataMapping.hiddenFlows', null);
      this.businessFlows.forEach(businessFlow => {
        this.connectors
          .filter(connector => connector.flowId === businessFlow._id)
          .forEach(connector => {
            connector.hidden = businessFlow.hidden;
            if (connector.hidden) {
              hiddenConnectorsArrayIDs.push(connector._id);
              hiddenFlowsArrayIDs.push(businessFlow._id);
            }
          });
      });
      if (hiddenConnectorsArrayIDs && hiddenConnectorsArrayIDs.length > 0) {
        this.$window.sessionStorage.setItem('dataMapping.hiddenConnectors', JSON.stringify(hiddenConnectorsArrayIDs));
        this.$window.sessionStorage.setItem('dataMapping.hiddenFlows', JSON.stringify(hiddenFlowsArrayIDs));
      }
    } else {
      this.connectors.forEach(connector => {
        if (hiddenConnectors.includes(connector._id)) {
          connector.hidden = true;
        }
      });
      this.businessFlows.forEach(businessFlow => {
        if (hiddenFlows.includes(businessFlow._id)) {
          businessFlow.hidden = true;
        }
      });
    }

    if (flow || !hiddenEntities || hiddenEntities === 'null') {
      this.$window.sessionStorage.setItem('dataMapping.hiddenEntities', null);
      this.entities.forEach(entity => {
        const relatedSteps = this.connectors.filter(
          connector => connector.from === entity._id || connector.to === entity._id,
        );
        entity.hidden = relatedSteps.length && relatedSteps.every(connector => connector.hidden);
        if (entity.hidden) {
          hiddenEntitiesArrayIDs.push(entity._id);
        }
        this.setHasAttributesWithoutPurposeFlag(entity);
      });
      if (hiddenEntitiesArrayIDs && hiddenEntitiesArrayIDs.length > 0) {
        this.$window.sessionStorage.setItem('dataMapping.hiddenEntities', JSON.stringify(hiddenEntitiesArrayIDs));
      }
    } else {
      this.entities.forEach(entity => {
        if (hiddenEntities.includes(entity._id)) {
          entity.hidden = true;
        }
      });
    }
    const selectedEntityID = this.$window.sessionStorage.getItem('dataMapping.selectedEntityID') || null;
    if (selectedEntityID) {
      const selectedEntity = this.entities.find(entity => entity._id === selectedEntityID);
      if (selectedEntity) {
        selectedEntity.isSelected = true;
      }
    }

    this._initGraphData();
  }

  setHasAttributesWithoutPurposeFlag(entity) {
    let attributes = [];
    let attributesMap = null;

    if (entity.attributes) {
      attributesMap = new Map(entity.attributes.map(attr => [attr.attribute, attr]));
      const attributesFromSystem = entity.attributesFromSystem.map(item => ({ attribute: item, item }));
      attributesFromSystem.forEach(item => {
        if (!attributesMap.has(item.attribute)) {
          attributesMap.set(item.attribute, item);
        }
      });
    } else if (entity.attributesFromSystem) {
      attributesMap = new Map(entity.attributesFromSystem.map(attr => [attr, attr]));
    }

    if (attributesMap) {
      attributes = [...attributesMap.values()];
      entity.hasAttributesWithoutPurpose = !!attributes.find(attribute => {
        return attribute.purpose === '' || attribute.purpose === undefined;
      });
    }
  }

  createReport(dataImage, vm, type) {
    switch (type) {
      case 'PDF':
        this.dataMappingReportService.createPDFReport(vm, dataImage);
        break;
      case 'Docx':
        this.dataMappingDocxReportService.createDocxReport(vm, dataImage);
        break;
    }
    vm.isReport = false;
  }

  _initGraphData() {
    this.newOrderedEntities = [];
    if (this.entities) {
      const maxValueOfDataMappingClassification = Math.max(
        ...this.entities.map(function (o) {
          return o.dataMappingClassification;
        }),
      );
      for (let i = 1; i <= maxValueOfDataMappingClassification; i++) {
        const entitiesByDataMappingClassification = this.entities.filter(
          entity => entity.dataMappingClassification === i,
        );
        if (entitiesByDataMappingClassification && entitiesByDataMappingClassification.length > 0) {
          entitiesByDataMappingClassification.sort(function (a, b) {
            return a.indexPosition > b.indexPosition ? 1 : b.indexPosition > a.indexPosition ? -1 : 0;
          });
          this.newOrderedEntities.push(...entitiesByDataMappingClassification);
        }
      }
    }

    this.graphData = {
      flows: this.businessFlows || [],
      entities: this.newOrderedEntities || [],
      connectors: this.connectors || [],
      classifiers: this.businessProcess.classifiers || [],
    };

    if (this.graphData.connectors) {
      this.graphData.connectors.forEach(connector => {
        if (!connector.direction) {
          connector.toArrow = 'Triangle';
          connector.fromArrow = '';
        } else {
          if (connector.direction) {
            if (connector.direction === 'outbound') {
              connector.toArrow = 'Triangle';
              connector.fromArrow = '';
            } else if (connector.direction === 'inbound') {
              connector.fromArrow = 'BackwardTriangle';
              connector.toArrow = '';
            } else if (connector.direction === 'biDirectional') {
              connector.fromArrow = 'BackwardTriangle';
              connector.toArrow = 'Triangle';
            }
          }
        }
      });
    }
  }
}
