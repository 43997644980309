import React, { FC, useState, ReactText } from 'react';
import angular from 'angular';
import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { Scope } from '../../views/AccessManagement/Scopes/DataSourcesCollapsables/ScopesDataSourcesContent';
import { DataSourceSelectionGrid } from './DataSourceSelectionGrid';

interface ConnectDataSourcesBySelectionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: ReactText[]) => void;
  scope: Scope;
}

export const ConnectDataSourcesBySelectionDialog: FC<ConnectDataSourcesBySelectionDialogProps> = ({
  isOpen = false,
  onClose,
  onSubmit,
  scope: { dataSourceNames: prevSelected = [] },
}) => {
  const [selected, setSelected] = useState<ReactText[]>([]);

  const onCloseDialog = () => {
    onClose();
    setSelected([]);
  };

  const onConnectClick = () => {
    onSubmit(selected);
    onCloseDialog();
  };

  const buttons = [
    { text: 'Cancel', onClick: onCloseDialog, component: SecondaryButton },
    {
      text: 'Connect Data Sources',
      onClick: onConnectClick,
      component: PrimaryButton,
      disabled: selected?.length === 0,
    },
  ];

  return (
    <BigidDialog
      showCloseIcon
      isOpen={isOpen}
      onClose={onCloseDialog}
      buttons={buttons}
      title="Connect Data Sources by Selection"
      maxWidth="md"
    >
      <DataSourceSelectionGrid setSelected={setSelected} selected={prevSelected} shouldDisableRow compact />
    </BigidDialog>
  );
};

angular
  .module('app')
  .component(
    'connectDataSourcesBySelectionDialog',
    convertToAngular(ConnectDataSourcesBySelectionDialog, ['isOpen', 'onClose', 'onSubmit', 'scope']),
  );
