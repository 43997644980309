import React, { FC, useContext, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { BigidUserIcon, BigidOpenTicketIcon } from '@bigid-ui/icons';
import {
  BigidBody1,
  BigidChip,
  BigidTooltip,
  TertiaryButton,
  EntityEvents,
  entityEventsEmitter,
  BigidSelect,
  BigidSelectOption,
} from '@bigid-ui/components';
import { dateUtils } from '../../../../../services/angularServices';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import {
  getSeverityChipProps,
  getJiraTicketNumber,
  isUrl,
  CaseStatus,
  syncWithJiraAndGetLatestStatus,
  applyJiraSyncWithNewCaseStatus,
} from '../../../actionableInsightsService';
import { useLocalTranslation } from '../../../translations';
import { getTextColorByStatus } from '../../../ActionableInsightsComponents/CaseStatusIndicator';
import { CaseActionsModal } from '../../../ActionableInsightsGridViews/CaseActionsModal/CaseActionsModal';
import { ActionsDialogTypes } from '../../../ActionableInsightsGridViews/CaseActionsModal/hooks/useCaseActions';
import { updateCaseStatus } from '../../../ActionableInsightsGridViews/CaseActionsModal/caseActionsService';
import { getActionTypeForStatus, getSelectionOptionsForSelectedType } from '../caseReportService';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';
import { CaseActionsWidget } from './CaseActionWidget/CaseActionsWidget';

const MetaDataInfo = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const SectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 24,
  width: '100%',
});

const ActionsWrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const BigidChipWrapper = styled.div({
  marginRight: 8,
});

const StatusSelector = styled('div')<{ status: CaseStatus }>`
  width: 156px;
  & p {
    color: ${({ status, theme }) => getTextColorByStatus(status, theme)};
  }
`;

export const MetaDataWidget: FC = () => {
  const { caseSidePanelData, onCaseAction } = useContext(CaseSidePanelContext);
  const { dispatch } = useContext(ActionableInsightsContext);
  const { chip } = getSeverityChipProps(caseSidePanelData?.severityLevel);
  const { t } = useLocalTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ActionsDialogTypes>();
  const [assignee, setAssignee] = useState(caseSidePanelData?.assignee);
  const [jiraTicketUrl, setJiraTicketUrl] = useState(caseSidePanelData?.jiraTicketUrl);
  const [caseStatus, setCaseStatus] = useState(caseSidePanelData?.caseStatus);
  const [selectionOptions, setSelectionOptions] = useState(
    getSelectionOptionsForSelectedType(caseSidePanelData?.caseStatus),
  );
  const [isShowingLoadingMessage, setIsShowingLoadingMessage] = useState(false);
  const filterCase = { caseIds: [caseSidePanelData.id as string] };
  const jiraTicketNumber = getJiraTicketNumber(jiraTicketUrl);

  const openModal = (newModalType: ActionsDialogTypes) => {
    setModalType(newModalType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalType(null);
    setIsModalOpen(false);
  };

  const onJiraButtonClick = () => {
    if (isUrl(jiraTicketUrl)) {
      window.open(jiraTicketUrl, '_blank');
    } else {
      openModal(ActionsDialogTypes.JIRA);
    }
  };

  const handlePayloadExternalUpdate = useCallback(
    (_rowId: string | number, payloadChanges: { assignee: string; jiraTicketUrl: string; caseStatus: CaseStatus }) => {
      payloadChanges?.assignee && setAssignee(payloadChanges.assignee);
      payloadChanges?.jiraTicketUrl && setJiraTicketUrl(payloadChanges.jiraTicketUrl);
      if (payloadChanges?.caseStatus) {
        setCaseStatus(payloadChanges.caseStatus);
        setSelectionOptions(getSelectionOptionsForSelectedType(payloadChanges.caseStatus));
        setIsShowingLoadingMessage(false);
      }
    },
    [],
  );

  const onSelectionChange = async (value: BigidSelectOption[]) => {
    const newStatus = value[0]?.value;
    if (Object.values(CaseStatus).includes(newStatus)) {
      if (newStatus === CaseStatus.OPEN) {
        setIsShowingLoadingMessage(true);
        updateCaseStatus({ caseIds: [caseSidePanelData.id] }, CaseStatus.OPEN, null, onModalCtaClick);
      } else {
        setModalType(getActionTypeForStatus(newStatus));
        setIsModalOpen(true);
      }
    }
  };

  const onModalCtaClick = (modifiedValue?: string) =>
    onCaseAction(modifiedValue, { caseIds: [caseSidePanelData.id] }, modalType);

  useEffect(() => {
    const unregister = entityEventsEmitter.addEventListener(EntityEvents.UPDATE_BY_ID, handlePayloadExternalUpdate);

    return () => {
      unregister();
    };
  }, [handlePayloadExternalUpdate]);

  useEffect(() => {
    const syncCaseStatusWithJira = async () => {
      const latestJiraStatus = await syncWithJiraAndGetLatestStatus(
        caseSidePanelData?.id,
        caseSidePanelData?.jiraTicketMetadata?.configurationId,
        caseSidePanelData?.jiraTicketUrl,
      );
      if (caseSidePanelData.caseStatus !== latestJiraStatus) {
        applyJiraSyncWithNewCaseStatus(caseSidePanelData?.id, latestJiraStatus, dispatch);
      }
    };
    if (caseSidePanelData?.jiraTicketUrl && caseSidePanelData?.jiraTicketMetadata) {
      syncCaseStatusWithJira();
    }
  }, [
    caseSidePanelData?.caseStatus,
    caseSidePanelData?.id,
    caseSidePanelData?.jiraTicketMetadata?.configurationId,
    caseSidePanelData?.jiraTicketUrl,
    caseSidePanelData?.jiraTicketMetadata,
    dispatch,
  ]);

  useEffect(() => {
    caseSidePanelData?.jiraTicketUrl && setJiraTicketUrl(caseSidePanelData?.jiraTicketUrl);
  }, [caseSidePanelData?.jiraTicketUrl]);

  return (
    <MetaDataInfo
      data-aid={generateDataAid('MetaDataWidget', ['main-container'])}
      data-tour-id={generateGuidedTourId('MetaDataWidget', ['main-container'])}
    >
      <SectionWrapper>
        <BigidChipWrapper>
          <BigidChip {...chip} />
        </BigidChipWrapper>
        {caseSidePanelData?.created_at && (
          <BigidTooltip title={t('CaseReport.lastUpdate')} placement="top">
            <BigidBody1 data-aid={generateDataAid('MetaDataWidget', ['case-updated-at'])} paddingLeft="8px">
              {dateUtils.formatDate(caseSidePanelData.created_at, { formatString: 'MMM do, yyyy, HH:mm' })}
            </BigidBody1>
          </BigidTooltip>
        )}
        <ActionsWrapper>
          <TertiaryButton
            dataAid={generateDataAid('MetaDataWidget', ['createJiraTicket', 'button'])}
            onClick={onJiraButtonClick}
            size="medium"
            startIcon={<BigidOpenTicketIcon />}
            text={jiraTicketNumber || 'Create Ticket'}
          />
          <TertiaryButton
            dataAid={generateDataAid('MetaDataWidget', ['assignUser', 'dropdown'])}
            onClick={() => openModal(ActionsDialogTypes.ASSIGN)}
            size="medium"
            startIcon={<BigidUserIcon />}
            text={assignee}
          />
          <CaseActionsWidget />
          {isShowingLoadingMessage ? (
            <BigidBody1 width={180}>{t('Case.common.messages.pleaseWait')}</BigidBody1>
          ) : (
            <StatusSelector
              status={caseStatus}
              data-aid={generateDataAid('MetaDataWidget', ['selected-status', caseStatus])}
            >
              <BigidSelect
                displayLimit={5}
                onChange={newSelection => onSelectionChange(newSelection)}
                options={selectionOptions}
                value={getSelectionOptionsForSelectedType(caseStatus, true)}
              />
            </StatusSelector>
          )}
        </ActionsWrapper>
      </SectionWrapper>

      <CaseActionsModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        rowsData={[caseSidePanelData]}
        type={modalType}
        onCaseAction={onModalCtaClick}
        filters={filterCase}
      />
    </MetaDataInfo>
  );
};
