import React, { FC } from 'react';
import { BigidTextField, TextFieldProps, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { openSystemDialog, SystemDialogContentProps } from '../../../react/services/systemDialogService';
import { Classifier } from '../../../react/services/classifiersService';
import { notificationService } from '../../../react/services/notificationService';

export const BigidDuplicateClassifierDialog: FC<SystemDialogContentProps<TextFieldProps>> = ({ onChange, label }) => {
  return <BigidTextField onChange={onChange} label={label} required />;
};

export const openCreateDuplicateClassifierDialog = async (classifiers: Classifier[]) => {
  let duplicateClassifierName = '';
  const contentProps = {
    label: 'Classifier Name',
    onChange: (event: any) => {
      duplicateClassifierName = event.target.value;
    },
    isError: false,
  };
  return new Promise<{ duplicateClassifierName: string }>(resolve => {
    openSystemDialog({
      title: 'Duplicate Classifier',
      content: BigidDuplicateClassifierDialog,
      contentProps,
      onClose: () => null,
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: 'Submit',
          component: PrimaryButton,
          onClick: () => {
            const classifierNameTrim = duplicateClassifierName.trim();
            if (classifierNameTrim.length === 0) {
              notificationService.error('The classifier name could not be an empty');
              resolve({ duplicateClassifierName: null });
              return;
            }

            const isClassificationNameExist = classifiers.find(
              classifier => classifier.classification_name === classifierNameTrim,
            );
            if (isClassificationNameExist) {
              notificationService.error('Classifier name already exist');
              resolve({ duplicateClassifierName: null });

              return;
            }
            resolve({ duplicateClassifierName: classifierNameTrim });
          },
          isClose: true,
        },
      ],
    });
  });
};
