import './scanResult.component.scss';
import template from './scanResult.component.html';
import { module } from 'angular';
import { setAmMapData } from '../common/amMap/ammap.service';
import { HeaderEvents, headerEventEmitter } from '../react/services/eventEmitters/headerEvents';
import { sessionStorageService } from '../common/services/sessionStorageService';
const app = module('app');
import { convertToReact } from '../common/services/convertToReact';

app.component('scanResult', {
  template,
  controllerAs: 'scanResultModel',
  controller: function (
    $rootScope,
    privacyRiskMapService,
    explorationBarChartService,
    scanResultsService,
    treeMapService,
    $anchorScroll,
    $translate,
  ) {
    'ngInject';

    const scanResultModel = this;

    const DEFAULT_MAP_ZOOM_LEVEL = 1;

    const mapsZoomSettings = sessionStorageService.get('mapsZoomSettings') || {};

    this.initialMapZoomLevel =
      mapsZoomSettings.scanResultMap && mapsZoomSettings.scanResultMap.zoomLevel
        ? mapsZoomSettings.scanResultMap.zoomLevel
        : DEFAULT_MAP_ZOOM_LEVEL;

    this.initialMapZoomLatitude =
      mapsZoomSettings.scanResultMap && mapsZoomSettings.scanResultMap.zoomLatitude
        ? mapsZoomSettings.scanResultMap.zoomLatitude
        : undefined;

    this.initialMapZoomLongitude =
      mapsZoomSettings.scanResultMap && mapsZoomSettings.scanResultMap.zoomLongitude
        ? mapsZoomSettings.scanResultMap.zoomLongitude
        : undefined;

    scanResultModel.zoomLevel = window.innerWidth >= 1200 && window.innerWidth <= 1600 ? 1 : 1.5;

    scanResultModel.columns = {};
    scanResultModel.scanResultColumnsData = [];

    scanResultModel.style =
      (window.innerWidth >= 1200 && window.innerWidth <= 1600
        ? "{'height': '500px','margin-top': '20px',"
        : "{'height': '590px','margin-top': '30px',") + "'width': '97%','margin-left': '2.2%'}";
    scanResultModel.mapContainerStyle =
      window.innerWidth >= 1200 && window.innerWidth <= 1600 ? "{'height': '500px'}" : "{'height': '590px'}";

    scanResultModel.$onInit = function () {
      $translate('SIDEBAR:REPORTS:SCAN_RESULTS_SUMMARY').then(pageTitle =>
        $rootScope.$broadcast('changePage', pageTitle, false),
      );
      headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
      scanResultModel.mapClass = 'imageMapSelected';
      scanResultModel.mapTreeClass = 'imageTreeMapNotSelected';
      scanResultModel.showMapDiv = true;
    };

    privacyRiskMapService.getPrivacyRiskMapResults(null, true).then(function (result) {
      setAmMapData(scanResultModel, result);
    });

    scanResultModel.goToTop5List = function () {
      $anchorScroll.yOffset = 100;
      $anchorScroll('Top5Lists');
    };

    scanResultModel.showMap = function () {
      scanResultModel.mapClass = 'imageMapSelected';
      scanResultModel.mapTreeClass = 'imageTreeMapNotSelected';
      scanResultModel.showMapDiv = true;
    };

    scanResultModel.showTreeMap = function () {
      scanResultModel.mapClass = 'imageMapNotSelected';
      scanResultModel.mapTreeClass = 'imageTreeMapSelected';
      scanResultModel.showMapDiv = false;
    };

    scanResultModel.rollUp = function () {
      $rootScope.$broadcast('rollUpEvent');
    };

    scanResultModel.exportToCSV = function () {
      treeMapService.exportCSV();
    };

    scanResultModel.onTreeMapZoomChanged = ({ zoomLevel, zoomLatitude, zoomLongitude }) => {
      const mapsZoomSettings = sessionStorageService.get('mapsZoomSettings') || {};
      mapsZoomSettings.scanResultMap = { ...mapsZoomSettings.scanResultMap, zoomLevel, zoomLatitude, zoomLongitude };
      sessionStorageService.set('mapsZoomSettings', mapsZoomSettings);
    };

    const sortBy = (arr, p) =>
      [...arr].sort((a, b) => {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });

    explorationBarChartService.getResidenciesInSystemsSummary().then(function (result) {
      const identityLocations = sortBy(result.identity_locations, 'count');

      for (const i in identityLocations) {
        identityLocations[i]['meta'] = {
          descr:
            'found in ' +
            identityLocations[i]['systemCount'] +
            ' Systems in ' +
            identityLocations[i]['systemLocationsCount'] +
            ' Countries',
        };
      }

      const sampledIdentities = result.sampledIdentities || 0;
      const totalInIDSoR = result.totalInIDSoR || 0;
      let tmpCount = result.distinctIdentitiesCount || 0;
      if (sampledIdentities > tmpCount) {
        tmpCount = sampledIdentities;
      }
      if (totalInIDSoR > tmpCount) {
        tmpCount = totalInIDSoR;
      }

      //scanResultModel.distinctIdentitiesCount = result.distinctIdentitiesCount;
      scanResultModel.distinctIdentitiesCount = tmpCount;
      scanResultModel.scanResultColumnsData[0] = {
        data: identityLocations,
        meta: {
          name: 'Residencies',
          tip:
            ': Check that applications and data sources are secure and comply with the local regulations applicable ' +
            'to the residency of the data subjects they handle. Also check that users are properly instructed in handling ' +
            'personal data according to local regulations.',
          descr:
            (result ? result.sampledIdentities : 0) +
            ' identities are monitored by BigID from ' +
            (result ? result.distinctIdentityLocationsCount : 0) +
            ' countries',
          img: '/images/Bigid_Entities.svg',
          color: '#FF5E43',
          itemClass: 'sub_result_text_identities',
          itemType: 'Items',
          itemDescr: '',
        },
      };
    });

    scanResultsService.getAttributeRisksExtended().then(function (result) {
      const attributeRisks = sortBy(result.attribute_risks, 'count');

      for (const i in attributeRisks) {
        attributeRisks[i]['meta'] = {
          descr:
            ', found in ' +
            attributeRisks[i]['systemCount'] +
            ' Systems in ' +
            attributeRisks[i]['systemLocationsCount'] +
            ' Countries',
        };
      }

      scanResultModel.attributesCount = attributeRisks.length;
      scanResultModel.scanResultColumnsData[1] = {
        data: attributeRisks,
        meta: {
          name: 'Attributes',
          tip:
            ': Check that these attributes are genuinely needed by the applications and users that ' +
            'access them, that the applications are secure and comply with regulations, and that the users ' +
            'are properly instructed in handling personal data.',
          descr: (result ? result.distinctAttributeCount : 0) + ' different attributes were discovered',
          img: '/images/Bigid_Attributes.svg',
          color: '#FC003B',
          itemClass: 'sub_result_text_attributes',
          itemType: 'Items',
          itemDescr: '',
        },
      };
    });

    scanResultsService.getAttributesData().then(function (result) {
      scanResultModel.AttributesCount = result.attribute_risks.length;
    });

    scanResultsService.getSystemsData().then(function (result) {
      const systemsRisks = sortBy(result.source_risks, 'count');
      scanResultModel.systemCount = systemsRisks.length;
      scanResultModel.systemsObject = systemsRisks;
      scanResultModel.scanResultColumnsData[2] = {
        data: systemsRisks,
        meta: {
          name: 'Data Sources',
          tip:
            ': Check that these data sources and all applications and users that access them are secure and comply ' +
            'with regulations and best practices.',
          descr:
            (result ? result.totalNumberOfSources : 0) +
            ' data sources that contain ' +
            (result ? result.totalNumberOfPiiRecords : 0) +
            ' personal records were discovered',
          img: '/images/Bigid_Systems.svg',
          color: '#2196F3',
          itemClass: 'sub_result_text_systems',
          itemType: 'Items',
          itemDescr: 'records identities',
        },
      };
    });

    scanResultsService.getApplicationsData().then(function (result) {
      const applicationsRisks = sortBy(result.application_risks, 'count');
      scanResultModel.applicationCount = applicationsRisks.length;
      scanResultModel.scanResultColumnsData[3] = {
        data: applicationsRisks,
        meta: {
          name: 'Applications',
          tip:
            ': Check that the users operating these applications genuinely need access to personal ' +
            'data and are properly instructed in handling it. Also check that the location, devices, and ' +
            'networks from where users are accessing this data are secure and compliant with regulations ' +
            'and best practices.',
          descr: (result ? result.application_risks.length : 0) + ' applications were discovered',
          img: '/images/Bigid_Applications.svg',
          color: '#825BF6',
          itemClass: 'sub_result_text_applications',
          itemType: 'Items',
          itemDescr: 'applications were discovered',
        },
      };
    });
  },
  bindings: {},
});

export const ScanResultComponent = convertToReact('scanResultComponent', { template: '<scan-result/>' });
