import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSpaceIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';

export interface CuratedFieldValuePreviewNoResultsProps {
  dataAid: string;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
});

export const CuratedFieldValuePreviewNoResults: FC<CuratedFieldValuePreviewNoResultsProps> = memo(({ dataAid }) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields');

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.row}>
        <BigidSpaceIcon />
      </div>
      <div className={classes.row}>
        <BigidBody1 data-aid={generateDataAid(dataAid, ['title'])}>{t('noResultsFound')}</BigidBody1>
      </div>
    </div>
  );
});
