import React from 'react';
import { BigidInlineNotification, BigidLink } from '@bigid-ui/components';
import { Collaboration } from '../../mappers/styles';
import { useLocalTranslation } from '../../translations';
import { BigidLampIcon } from '@bigid-ui/icons';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';
import { trackEventDataSources, DataSourcesUITrackingEvent } from '../../../DataSourcesEventTrackerUtils';
import { sessionStorageService } from '../../../../../../common/services/sessionStorageService';
import { LoginStrategies } from '../../../../../views/Login/types';
import { getApplicationPreference } from '../../../../../services/appPreferencesService';

type DataSourceConnectionCollaborationFooterProps = {
  dataAid?: string;
  isCompact?: boolean;
};

type FooterConfig = {
  translationKey: FooterTranslationKeys;
  onLinkClickHandler?: () => void;
};

interface DataSourceConnectionCollaborationTitleProps extends FooterConfig {
  isCompact?: boolean;
}
enum FooterTranslationKeys {
  ON_PREM_NO_SMTP = 'onPremNoSmtp',
  CLOUD_SAML = 'cloudSaml',
  DEFAULT = 'default',
}

const accessManagmentRedirectHandler = () => {
  trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_NAVIGATE_TO_ACCESS_MANAGEMENT_CLICK);
  $state.go(CONFIG.states.IDENTITY_ACCESS_MANAGEMENT, { currentTab: 'systemUsers' });
};

const emailSetUpRedirectHandler = () => {
  trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_NAVIGATE_TO_EMAIL_SETUP_CLICK);
  $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'emailSetup' });
};

const getFooterConfig = (): FooterConfig => {
  const loginStrategy: LoginStrategies = sessionStorageService.get('LOGIN_STRATEGY');
  const isSmtpConfigured: boolean = sessionStorageService.get('IS_SMTP_CONFIGURED');
  const useSaas = getApplicationPreference('USE_SAAS');

  if (!useSaas && !isSmtpConfigured) {
    return { translationKey: FooterTranslationKeys.ON_PREM_NO_SMTP, onLinkClickHandler: emailSetUpRedirectHandler };
  }
  if (!useSaas && isSmtpConfigured) {
    return { translationKey: FooterTranslationKeys.DEFAULT, onLinkClickHandler: accessManagmentRedirectHandler };
  }
  if (useSaas && loginStrategy === LoginStrategies.SAML) {
    return { translationKey: FooterTranslationKeys.CLOUD_SAML };
  }
  if (useSaas && loginStrategy === LoginStrategies.AUTH0) {
    return { translationKey: FooterTranslationKeys.DEFAULT, onLinkClickHandler: accessManagmentRedirectHandler };
  }
};

const DataSourceConnectionCollaborationTitle = ({
  translationKey,
  onLinkClickHandler,
  isCompact,
}: DataSourceConnectionCollaborationTitleProps) => {
  const { t } = useLocalTranslation('collaboration.notification.footer');

  return (
    <Collaboration.Title>
      <Collaboration.FooterContent compact={isCompact}>
        {t(`${translationKey}.message`)}
        {onLinkClickHandler ? (
          <BigidLink
            dataAid={generateDataAid('DataSourceConnectionCollaboration', ['action', 'redirect'])}
            underline="none"
            text={t(`${translationKey}.link`)}
            onClick={onLinkClickHandler}
          />
        ) : null}
      </Collaboration.FooterContent>
    </Collaboration.Title>
  );
};

export const DataSourceConnectionCollaborationFooter = ({
  dataAid = 'DataSourceConnectionCollaborationFooter',
  isCompact,
}: DataSourceConnectionCollaborationFooterProps): JSX.Element => {
  const { t } = useLocalTranslation('collaboration.notification.footer');
  const { translationKey, onLinkClickHandler } = getFooterConfig();
  const tooltip = isCompact ? t(`${translationKey}.message`) : undefined;
  return (
    <Collaboration.Notification>
      <BigidInlineNotification
        dataAid={dataAid}
        tooltip={tooltip}
        icon={BigidLampIcon}
        open
        type="info"
        title={
          (
            <DataSourceConnectionCollaborationTitle
              translationKey={translationKey}
              onLinkClickHandler={onLinkClickHandler}
              isCompact={isCompact}
            />
          ) as unknown as string
        }
      />
    </Collaboration.Notification>
  );
};
