import { BigidContentItem } from '@bigid-ui/components';
import { DataCatalogAttribute } from './DataCatalogAttributesService';

export const probabilityToPercentage = (probability: number | string): string => {
  if (typeof probability === 'string' && isNaN(Number(probability))) {
    return probability;
  }

  // ! Rounding to the closest integer
  return `${Math.round(Number(probability) * 100)}%`;
};

export const getConfidenceLevelScore = (confidenceLevel: number, rank?: string): string => {
  if (confidenceLevel) {
    return probabilityToPercentage(confidenceLevel);
  }

  if (rank) {
    return probabilityToPercentage(rank);
  }

  return '';
};

export const isAttributeExpired = (attributesData: DataCatalogAttribute[], selectedItem: BigidContentItem) => {
  if (!selectedItem?.attribute_name) {
    return false;
  }
  const selectedAttribute = attributesData.find(attr => attr.attribute_name === selectedItem.attribute_name);
  return !!selectedAttribute?.is_expired;
};
