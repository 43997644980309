import React, { FC } from 'react';
import styled from '@emotion/styled';
import type { StyledButtonType } from '@bigid-ui/components';

export type SavedQueriesFooterProps = {
  buttons: (StyledButtonType & { component: FC<StyledButtonType> })[];
};

const Footer = styled.div`
  display: flex;
  height: 100%;
  padding: 0 1rem;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;

export const SavedQueriesFooter = ({ buttons }: SavedQueriesFooterProps): JSX.Element => (
  <Footer>
    {buttons.map(({ component: Component, ...rest }) => (
      <Component key={rest.dataAid} {...rest} />
    ))}
  </Footer>
);
