import { styled } from '@mui/material';
import React, { FC } from 'react';
import { ControlsSection } from './ControlsSection';
import { FriendlyNameSection } from './FriendlyNameSection';
import { NameSection } from './NameSection';

const HeaderContainer = styled('section')`
  display: flex;
  padding: 24px 12px 24px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};
`;

export const Header: FC = () => {
  return (
    <HeaderContainer>
      <NameSection />
      <FriendlyNameSection />
      <ControlsSection />
    </HeaderContainer>
  );
};
