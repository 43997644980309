import React, { FC, useEffect, useState } from 'react';
import { $state, $stateParams } from '../../services/angularServices';
import { AppInfo } from './utils/CustomAppTypes';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { CustomAppUI } from './views/CustomAppUI/CustomAppUI';
import { getCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty } from 'lodash';
import { CONFIG } from '../../../config/common';
import { TPAPageViewsEvents, trackTPAPageView } from './customAppEventsTrackerUtils';

const getAppRoute = () => {
  if ($stateParams.appRoute) {
    return `#/${$stateParams.appRoute}`;
  }
  return '';
};

export const CustomAppUIPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [appRoute, setAppRoute] = useState<string>('');

  useEffect(() => {
    trackTPAPageView(TPAPageViewsEvents.TPA_UI_VIEW);
  }, []);

  useEffect(() => {
    const updateAppInfo = async () => {
      if (appInfo) return;
      const appInfoParam = $stateParams.appInfo;
      const fetchedAppInfo = appInfoParam ? appInfoParam : await getCustomAppInfo($stateParams.id);
      if (!fetchedAppInfo.isInteractive || fetchedAppInfo.hideUI) {
        const params = { id: fetchedAppInfo.id, fetchedAppInfo };
        $state.go(CONFIG.states.CUSTOM_APP_ACTIONS, params, { location: 'replace' });
      }
      setAppInfo(fetchedAppInfo);
    };
    const appRoute = getAppRoute();
    setAppRoute(appRoute);
    updateAppInfo();
  }, [appInfo]);

  return (
    appInfo?.isInteractive && (
      <CustomAppPageWrapper appInfo={appInfo} isReady={!isEmpty(appInfo)}>
        <CustomAppUI appInfo={appInfo} appRoute={appRoute} />
      </CustomAppPageWrapper>
    )
  );
};
