import { httpService } from '../../../services/httpService';
import { AuditGridRow } from './ActionCenterAudit';

export async function getActionCenterAuditData(gridConfigQuery: string) {
  const {
    data: {
      data: { audit, totalCount },
    },
  } = await httpService.fetch<{ data: { audit: AuditGridRow[]; totalCount: number } }>(
    `action-center/audit/actions?${gridConfigQuery}`,
  );

  return {
    audit,
    totalCount,
  };
}
