import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidMaintenanceIcon } from '@bigid-ui/icons';
import { BigidHeading2, BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { MetadataSearchError } from '../../components/MetadataSearch/MetadataSearchTypes';

interface MetadataSearchErrorHandlerProps extends MetadataSearchError {
  dataAid?: string;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  header: {
    color: BigidColorsV2.purple[400],
  },
});

export const MetadataSearchErrorHandler: FC<MetadataSearchErrorHandlerProps> = memo(
  ({
    dataAid = 'MetadataSearchFullErrorHandler',
    title = 'Something went wrong',
    description = 'The search failed because of an error, try to search again.',
  }) => {
    const classes = useStyles();

    return (
      <div className={classes.root} data-aid={dataAid}>
        <div className={classes.row}>
          <BigidMaintenanceIcon />
        </div>
        <div className={classes.row}>
          <BigidHeading2 classes={{ root: classes.header }} data-aid={`${dataAid}-title`}>
            {title}
          </BigidHeading2>
        </div>
        {typeof description === 'string' && (
          <div className={classes.row}>
            <BigidBody1 data-aid={`${dataAid}-description`}>{description}</BigidBody1>
          </div>
        )}
      </div>
    );
  },
);

MetadataSearchErrorHandler.displayName = 'MetadataSearchErrorHandler';
