import React, { FC } from 'react';
import {
  BigidTextField,
  BigidExtraField,
  BigidExtraFieldLabelPosition,
  useExtraFieldControl,
} from '@bigid-ui/components';
import { v4 as uuid } from 'uuid';
import makeStyles from '@mui/styles/makeStyles';
import { IgnoreValue } from '../ClassifiersExcludeListDialog/ClassifiersExcludeListDialog';

export interface ExtraFieldIgnoreValueProps {
  onChange: () => void;
  initialData: IgnoreValue[];
  classificationName: string;
  label: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  titleWithPrefixCount: string;
  type: string;
  deleteExcludeValueHandler: (ignoreValue: IgnoreValue) => void;
}

const useStyles = makeStyles({
  title: {
    marginBottom: '18px',
  },
  wrapperList: {
    height: '340px',
    overflowY: 'auto',
  },
});

export const ExtraFieldIgnoreValue: FC<ExtraFieldIgnoreValueProps> = ({
  onChange,
  initialData,
  classificationName,
  label,
  setIsLoading,
  titleWithPrefixCount,
  type,
  deleteExcludeValueHandler,
}) => {
  const { onAddNew, onDelete, data, onChangeItem } = useExtraFieldControl({
    initialData,
    createNewItem: () => ({ id: uuid(), value: '', classification_name: classificationName, type }),
    onChange,
  });

  const handleOnChangeItem = (id: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    onChangeItem(id, value);
  };

  const handleOnRemoveItem = async (deleteId: string) => {
    const requestedIgnoreValue = data.find(ignoreValue => ignoreValue.id === deleteId);
    // Check if is it object from mongo or object just added only in client
    if (requestedIgnoreValue && requestedIgnoreValue._id) {
      deleteExcludeValueHandler(requestedIgnoreValue as IgnoreValue);
    }
    onDelete(deleteId);
  };

  const classes = useStyles({});
  return (
    <div>
      <div className={classes.title}>
        {data.length} {titleWithPrefixCount}
      </div>
      <div className={classes.wrapperList}>
        <BigidExtraField
          label={label}
          fieldComponent={BigidTextField}
          data={data}
          onChangeItem={handleOnChangeItem}
          onDelete={handleOnRemoveItem}
          onAddNew={onAddNew}
          labelPosition={data.length === 0 ? BigidExtraFieldLabelPosition.left : BigidExtraFieldLabelPosition.top}
          alignCenter
          allowToDeleteAll
          emptyTemplate={{
            title: 'To create your exclude list start by adding a field',
            isShow: true,
          }}
        />
      </div>
    </div>
  );
};
