import React, { FC, memo, useContext } from 'react';
import { PrimaryButton, BigidHeading4, BigidLoader, BigidColors } from '@bigid-ui/components';
import { DataSourceTestConnectionStatusEnum } from '../hooks/useTestConnection';
import { DsScanTypeEnum } from '../types';
import { ScanInProgressContent } from '../DataSourceScanBox/ScanInProgresContent/ScanInProgresContent';
import { ScanResultContent } from '../DataSourceScanBox/ScanResultContent/ScanResultContent';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import { useScanDataSource } from '../hooks/useScanDataSource';
import makeStyles from '@mui/styles/makeStyles';
import { DataSourceConfigurationEmptyState } from '../DataSourceConfigurationNoData/DataSourceConfigurationNoData';
import { GetHighlightedWordsForLogType } from '../utils/scannerLogsUtils';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    minHeight: '570px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  startScanWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonTextWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
    color: BigidColors.white,
  },
  titleWrapper: {
    textAlign: 'center',
    paddingBottom: '24px',
  },
});

export interface DataSourceScanBoxProps {
  isNotSaved: boolean;
  getHighlightedWordsForLog: GetHighlightedWordsForLogType;
}
export const ScanTypeToLabelMap = {
  [DsScanTypeEnum.meta]: 'Metadata',
  [DsScanTypeEnum.hyper]: 'Hyper',
};

export const DataSourceScanBox: FC<DataSourceScanBoxProps> = memo(({ isNotSaved, getHighlightedWordsForLog }) => {
  const {
    testInProgress,
    scanType,
    testStatus,
    isScanSuccess,
    scanInProgress,
    isTestAvailable,
    updateState,
    getValuesContainer,
    scanResultsIsLoading,
  } = useContext(DataSourceConfigurationContext);

  const { wrapper, startScanWrapper, buttonTextWrapper, titleWrapper } = useStyles({ testStatus });
  const { runScan, stopScan } = useScanDataSource({ getValuesContainer, updateState });
  const disabled =
    !isTestAvailable || isNotSaved || testInProgress || testStatus !== DataSourceTestConnectionStatusEnum.success;

  if (scanInProgress) {
    return isPermitted(SCANS_PERMISSIONS.READ_SCAN_ACTIVITY.name) ? (
      <ScanInProgressContent stopScan={stopScan} />
    ) : (
      <DataSourceConfigurationEmptyState
        title={'You don’t have the required permissions to view scan results'}
        reasonList={[]}
      />
    );
  }
  if (isScanSuccess !== undefined && !scanResultsIsLoading) {
    return isPermitted(SCANS_PERMISSIONS.READ_SCAN_ACTIVITY.name) ? (
      <ScanResultContent
        runScan={runScan}
        isNotSaved={isNotSaved}
        getHighlightedWordsForLog={getHighlightedWordsForLog}
      />
    ) : (
      <DataSourceConfigurationEmptyState
        title={'You don’t have the required permissions to view scan results'}
        reasonList={[]}
      />
    );
  }

  return (
    <div className={wrapper} data-aid="DataSourceScanBox">
      <div className={startScanWrapper}>
        {scanResultsIsLoading && <BigidLoader />}
        <BigidHeading4 className={titleWrapper}>Run a scan on this Data Source</BigidHeading4>
        {isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name) && (
          <PrimaryButton
            dataAid={`DataSourceTestConnectionScan-${scanType}`}
            onClick={runScan}
            size="large"
            disabled={disabled}
            margin="4px"
            text={`Run ${ScanTypeToLabelMap[scanType]} Scan`}
          />
        )}
      </div>
    </div>
  );
});
