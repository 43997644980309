import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Add as AddIcon,
  DeleteOutlined,
  FileCopyOutlined,
  ToggleOnOutlined,
  ToggleOffOutlined,
} from '@mui/icons-material';
import { ActionData, BigidLoader, BigidPaper } from '@bigid-ui/components';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { $state, $translate } from '../../../services/angularServices';
import { httpService } from '../../../services/httpService';
import makeStyles from '@mui/styles/makeStyles';
import { $rootScope } from 'ngimport';
import { CorrelationSetsImportDialog } from '../CorrelationSetsImportDialog/CorrelationSetsImportDialog';
import { CORRELATION_SET_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { notificationService } from '../../../services/notificationService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import {
  correlationSetConnectionDefaultColumns,
  getCorrelationSetConnectionFilterConfig,
} from './CorrelationSetConnectionGridConfiguration';
import { CorrelationSetModel } from './CorrelationSetConnectionTypes';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { fetchCorrelationSetsData } from './correlationSetUtils';
import { MtNotSupportedViews } from '../../../components/MtMigrationEmptyState/mtMigrationEmptyStateUtils';
import { MtMigrationWrapper } from '../../../components/MtMigrationEmptyState/MtMigrationWrapper';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
});

const CorrelationSetConnectionsComponent: FC = () => {
  const [isImportModalOpen, setImportModalOpen] = useState(false);

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: correlationSetConnectionDefaultColumns,
    getInitialFilterToolbarConfig: getCorrelationSetConnectionFilterConfig,
  });

  const { gridWrapper } = useStyles({});

  useEffect(() => {
    $translate(['SIDEBAR:ADMIN:ENTITY_SOURCES']).then(translations => {
      $rootScope.$broadcast('changePage', translations['SIDEBAR:ADMIN:ENTITY_SOURCES'], false);
    });
  }, []);

  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  async function updateCorrelationSets(actionData: ActionData, updatedFields: any) {
    let didUpdate = false;
    const { selectedRowIds, allSelected, filter } = actionData;

    try {
      await httpService.put(`correlation-set-connections`, {
        ids: selectedRowIds,
        allSelected,
        query: { filter },
        updatedFields,
      });
      didUpdate = true;
      notificationService.success('Correlation sets updated successfully');
    } catch (e) {
      notificationService.error('Could not update correlation sets. See logs for more information');
    }

    return {
      shouldGridReload: didUpdate,
      shouldClearSelection: didUpdate,
    };
  }

  const gridWithToolbarConfig: BigidGridWithToolbarProps<CorrelationSetModel> = useMemo(
    () => ({
      columns: gridColumns,
      pageSize: 200,
      customRowIdName: 'id',
      entityName: 'Correlation Sets',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      filterToolbarConfig,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: fetchCorrelationSetsData,
      toolbarActions: [
        {
          label: 'New Correlation Set',
          icon: AddIcon,
          execute: async () => {
            $state.go(`newEntityConnection`);

            return {
              shouldGridReload: false,
            };
          },
          disable: () => false,
          show: () => isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name),
        },
        {
          label: 'Import',
          execute: async () => {
            setImportModalOpen(true);

            return {
              shouldGridReload: false,
            };
          },
          disable: () => false,
          show: () => isPermitted(CORRELATION_SET_PERMISSIONS.IMPORT.name),
        },
        {
          label: 'Export',
          execute: async ({ selectedRowIds, allSelected }) => {
            try {
              const queryParams = allSelected ? {} : { ids: selectedRowIds };
              httpService.downloadFile(`correlation-set-connections/file-download/export`, queryParams);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            }
            return {
              shouldGridReload: false,
            };
          },
          disable: ({ totalRows }) => totalRows === 0,
          show: () =>
            getApplicationPreference('ENTITY_SOURCE_EXPORT_ENABLED') &&
            isPermitted(CORRELATION_SET_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Enable',
          isGlobal: true,
          icon: ToggleOnOutlined,
          execute: async queryComponents => updateCorrelationSets(queryComponents, { enabled: 'yes' }),
          disable: () => false,
          show: ({ selectedRowIds }) => selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name),
        },
        {
          label: 'Disable',
          isGlobal: true,
          icon: ToggleOffOutlined,
          execute: async queryComponents => updateCorrelationSets(queryComponents, { enabled: 'no' }),
          disable: () => false,
          show: ({ selectedRowIds }) => selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.EDIT.name),
        },
        {
          label: 'Duplicate',
          isGlobal: true,
          icon: FileCopyOutlined,
          execute: async ({ selectedRowIds }) => {
            let didDuplicate = false;
            try {
              await httpService.post('correlation-set-connections/duplicate', {
                ids: selectedRowIds,
              });
              didDuplicate = true;
              notificationService.success('Correlation set was duplicated successfully');
            } catch (e) {
              console.error(e);
              notificationService.error('Could not duplicate correlation set. See logs for more information');
            }

            return {
              shouldGridReload: didDuplicate,
              shouldClearSelection: didDuplicate,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length === 1 && isPermitted(CORRELATION_SET_PERMISSIONS.CREATE.name),
        },
        {
          label: 'Delete',
          isGlobal: true,
          icon: DeleteOutlined,
          execute: async queryComponents => {
            let shouldDelete = false;
            const { selectedRowIds, allSelected, totalRows, filter } = queryComponents;
            try {
              shouldDelete = await showConfirmationDialog({
                entitiesCount: allSelected ? totalRows : selectedRowIds.length,
                entityNameSingular: 'Correlation Set',
                entityNamePlural: 'Correlation Sets',
              });

              if (shouldDelete) {
                await httpService.delete(`correlation-set-connections`, {
                  ids: selectedRowIds,
                  allSelected,
                  query: { filter },
                });
                notificationService.success('Correlation sets deleted successfully');
              }
            } catch (e) {
              notificationService.error('Could not delete correlation sets. See logs for more information');
              console.error(e);
            }

            return {
              shouldGridReload: shouldDelete,
              shouldClearSelection: shouldDelete,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length > 0 && isPermitted(CORRELATION_SET_PERMISSIONS.DELETE.name),
        },
      ],
    }),
    [gridColumns, preferences, filterToolbarConfig, updatePreferences],
  );

  return (
    <div className={gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      </BigidPaper>
      <CorrelationSetsImportDialog isOpen={isImportModalOpen} onClose={handleImportModalClose} />
    </div>
  );
};

export const CorrelationSetConnections: FC = () => {
  const mtCorrelation = Boolean(getApplicationPreference('MT_CORRELATION_FF_ENABLED'));
  return (
    <>
      {mtCorrelation && <CorrelationSetConnectionsComponent />}
      {!mtCorrelation && (
        <MtMigrationWrapper viewId={MtNotSupportedViews.CORRELATION_SETS}>
          <CorrelationSetConnectionsComponent />
        </MtMigrationWrapper>
      )}
    </>
  );
};
