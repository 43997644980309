import './categoriesModal.component.scss';
import template from './categoriesModal.component.html';
import angular from 'angular';

angular.module('app').component('categoriesModal', {
  template,
  controllerAs: '$ctrl',
  controller: [
    'identityLineageService',
    function (identityLineageService) {
      var ctrl = this;
      ctrl.categories = [];

      ctrl.$onInit = () => {
        ctrl.category = this.category || {};
        ctrl.category.isColorEnabled = true;
        ctrl.action = this.action;
        ctrl.getCategories();
      };
      ctrl.getCategories = () => {
        identityLineageService.getCategories().then(result => {
          ctrl.categories = result;
        });
      };

      ctrl.refreshResults = $select => {
        let search = $select.search;
        let list = angular.copy($select.items);
        let ID = -1;
        //remove last user input
        list = list.filter(function (item) {
          return item.id !== ID;
        });
        if (!search) {
          //use the predefined list
          $select.items = list;
        } else {
          //manually add user input and set selection
          var userInputItem = {
            id: ID,
            display_name: search,
          };
          $select.items = [userInputItem].concat(list);
          $select.selected = userInputItem;
        }
      };

      ctrl.clear = ($event, $select) => {
        $event.stopPropagation();
        ctrl.category.isDescEnabled = true;
        ctrl.category.isColorEnabled = true;
        //to allow empty field, in order to force a selection remove the following line
        $select.selected = undefined;
        //reset search query
        $select.search = undefined;
        //focus and open dropdown
        $select.activate();
      };

      ctrl.setCategoryColorAndDescription = category => {
        let categorySelected = ctrl.categories.filter(
          categoryLocal => categoryLocal.display_name === category.display_name,
        );
        if (categorySelected && categorySelected.length > 0) {
          ctrl.category.color = categorySelected[0].color;
          ctrl.category.glossary_id = categorySelected[0].glossary_id;
          ctrl.category.description = categorySelected[0].description;
          ctrl.category.isDescEnabled = true;
          ctrl.category.isColorEnabled = false;
        }
      };
    },
  ],
  bindings: {
    category: '<',
    action: '<',
    $close: '&',
    $dismiss: '&',
  },
});
