import React, { useEffect } from 'react';
import { SecondaryButton } from '@bigid-ui/components';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { useLocalTranslation } from '../translations';
import { getPermissions } from '../utils';
import { AddBoxOutlined } from '@mui/icons-material';
import { generateDataAid } from '@bigid-ui/utils';

interface UsePageHeader {
  handleCertificateAdd: () => void;
  shouldShowAction: boolean;
}

export const usePageHeader = ({ handleCertificateAdd, shouldShowAction }: UsePageHeader) => {
  const { t } = useLocalTranslation();
  const { isCreatePermitted, isEditPermitted } = getPermissions();

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t('title'),
      rightSideComponentsContainer: shouldShowAction && (
        <SecondaryButton
          disabled={!isCreatePermitted || !isEditPermitted}
          text={t('buttons.addFile')}
          size="large"
          dataAid={generateDataAid('BigidLayoutMasterDetails', ['add-new-certificate'])}
          onClick={handleCertificateAdd}
          startIcon={<AddBoxOutlined />}
        />
      ),
    });
  }, [handleCertificateAdd, isCreatePermitted, isEditPermitted, shouldShowAction, t]);
};
