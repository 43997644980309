import './classifiers.component.scss';
import { module } from 'angular';
import { $rootScope } from 'ngimport/index';
import '../../react/components/ConvertedToAngularComponents/ConvertedToAngularComponenets';
import { $translate } from '../../react/services/angularServices';
import { httpService } from '../../react/services/httpService';
import template from './classifiers.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { userPreferencesService } from '../../react/services/userPreferencesService';
import { CONFIG } from '../../config/common';
import { CLASSIFIERS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { BigidColorsV2, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { ClassifierVersionState } from '../../react/services/classifiersService';
import {
  openClassifierUpdatePreviewDialog,
  openClassifierUpdatePreviewBulkDialog,
  openClassifierRestoreVersionDialog,
} from '../../react/components/ClassifierUpdatePreview/classifierUpdatePreviewService';
import './ClassifiersFilterDropdown/ClassifiersFilterDropdown';
import './CreateClassifiersMenuButton/CreateClassifiersMenuButton';
import '../../react/components/ClassifiersChecksumValidation/EditClassifiersChecksumValidationButton/EditClassifiersChecksumValidationButton';

import { openCreateDuplicateClassifierDialog } from './BigidDuplicateClassifierDialog/BigidDuplicateClassifierDialog';
import { omit, intersectionWith, isNil } from 'lodash';
import { openCreateExcludeListClassifierDialog } from './ClassifiersExcludeListDialog/ClassifiersExcludeListDialog';
import { closeSystemDialog } from '../../react/services/systemDialogService';

import { v4 as uuid } from 'uuid';
import { openImportClassifiersDialog } from './ImportClassifiersDialog/ImportClassifiersDialog';
import { openClassifierChecksumValidationDialog } from '../../react/components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ClassifiersChecksumValidationDialog';
import { CHECKSUM_VALIDATION_TYPE } from '../../react/components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationTypes';
import { duplicateChecksumByClassifierName } from '../../react/components/ClassifiersChecksumValidation/ClassifiersChecksumValidationDialog/ChecksumValidationUtils';
import '../../react/components/ClassifierTester/ClassifierTesterDialog';
import { validateRegExpression } from '../../react/components/ClassifierTester/ClassifierTesterUtils';

const app = module('app');

app.component('classifiers', {
  template,
  controller: function (notificationService, classifiersService, $document, DeleteConfirmation, $timeout) {
    'ngInject';

    const CLASSIFIER_TYPE = {
      DATA: 'data',
      COLUMN_NAME: 'columnName',
    };

    const ATTRIBUTE_TYPE = {
      CLASSIFIER: 'Classification',
      CORRELATION: 'ClassificationMd',
    };

    const ATTRIBUTE_PREFIX_MAP = {
      [ATTRIBUTE_TYPE.CLASSIFIER]: 'classifier.',
      [ATTRIBUTE_TYPE.CORRELATION]: 'classifier.MD::',
    };

    const ATTRIBUTE_CLASSIFIER_TYPE_MAP = {
      [ATTRIBUTE_TYPE.CLASSIFIER]: CLASSIFIER_TYPE.DATA,
      [ATTRIBUTE_TYPE.CORRELATION]: CLASSIFIER_TYPE.COLUMN_NAME,
    };

    const FILTER_BY = {
      SOURCE: {
        CUSTOM: false,
        OOTB: true,
      },
      TYPE: {
        REGULAR: 'data',
        METADATA: 'columnName',
        NER: classifiersService.CLASSIFIER_ALGORITHMS.NER,
        DOC: classifiersService.CLASSIFIER_ALGORITHMS.DOC,
      },
      STATUS: {
        ENABLED: 'enabled',
      },
    };

    const FILTER_KEYS = {
      SOURCE: 'source',
      TYPE: 'type',
      STATUS: 'status',
    };

    const DEFAULT_CHECKSUM_OPTION = { id: null, value: 'None' };

    const CLASSIFIERS_DATA_LIST_ID = 'classifiersDataList';
    const CLASSIFIERS_CONTENT_BODY_ID = 'classifiersContentBody';
    const CLASSIFIERS_DATA_LIST_ITEM_ID = 'classifier';

    const EMPTY_NER_ENTITY = { id: uuid(), entityModelName: '', nerClassifierName: '' };
    const contentBodyContainer = $document[0].getElementById(CLASSIFIERS_CONTENT_BODY_ID);
    this.exportButtonItems = [];
    this.hasClassifiersToUpdate = false;
    this.isFormDirty = false;
    this.checksumValidations = [];
    this.classifiers = [];
    this.nerClassifiers = [];
    this.docClassifiers = [];
    this.exportButtons = [];
    this.classifier = {};
    this.customModel = { entityMap: [{ id: uuid(), entityModelName: '', nerClassifierName: '' }] };
    this.isOpenExcludeListDialog = false;
    this.toShowExcludeListButton = false;
    this.showNegativeSupporTermFields = false;
    this.classifierForm = {};
    this.nerClassifierForm = {};
    this.isNerFormDirty = false;
    this.showNerCustomModelForm = false;
    this.userQuery = '';
    this.editModeOn = false;
    this.createModeOn = false;
    this.isImportExportSupported = getApplicationPreference('CLASSIFICATION_IMPORT_EXPORT_ENABLED');
    this.isMetadataClassifierEnabled = getApplicationPreference('METADATA_CLASSIFIER_ENABLED');
    this.isClassifiersAutoUpgrageDisable = getApplicationPreference('DISABLE_AUTO_UPGRADE_OF_CLASSIFIERS');
    this.isClassifierTesterEnabled = getApplicationPreference('CLASSIFIER_TESTER_ENABLED');
    this.isNerClassifierFlagEnabled = false;
    this.isImportDialogOpen = false;
    this.importClassifiersDescription = 'Choose a CSV file to import';
    this.importClassifiersTitle = 'Import Classifiers';
    this.isTermRegexSupported = false;
    this.isNegativeTermRegexSupported = false;
    this.isEnabledClassifiersListToggled = false;
    this.enabledClassifiersCount = 0;
    this.isDeletePermitted = isPermitted(CLASSIFIERS_PERMISSIONS.DELETE.name);
    this.isEditPermitted = isPermitted(CLASSIFIERS_PERMISSIONS.EDIT.name);
    this.isCreatePermitted = isPermitted(CLASSIFIERS_PERMISSIONS.CREATE.name);
    this.isImportPermitted = isPermitted(CLASSIFIERS_PERMISSIONS.IMPORT.name);
    this.isExportPermitted = isPermitted(CLASSIFIERS_PERMISSIONS.EXPORT.name);

    this.classifierVersionNew = ClassifierVersionState.NEW;
    this.classifierVersionNeedsUpdate = ClassifierVersionState.NEEDS_UPDATE;
    this.classifierVersionUpToDate = ClassifierVersionState.UP_TO_DATE;
    this.classifierVersionDowngradeAvailable = ClassifierVersionState.DOWNGRADE_AVAILABLE;

    this.classifierVersionNewIndicatorColor = BigidColorsV2.purple[200];
    this.classifierVersionNewIndicatorOutline = BigidColorsV2.purple[600];
    this.classifierVersionUpdateIndicatorColor = BigidColorsV2.green[200];
    this.classifierVersionUpdateIndicatorOutline = BigidColorsV2.green[900];

    this.filterCriteria = {};
    this.filterItem = {
      options: [
        {
          id: 'source',
          value: 'source',
          displayValue: 'Source',
          children: [
            {
              id: 'source:ootb',
              parentId: 'source',
              value: true,
              displayValue: 'Out of the box',
            },
            {
              id: 'source:custom',
              parentId: 'source',
              value: false,
              displayValue: 'Custom',
            },
          ],
        },
        {
          id: 'type',
          value: 'type',
          displayValue: 'Type',
          children: [
            {
              id: 'type:regular',
              parentId: 'type',
              value: 'data',
              displayValue: 'Regular',
            },
            {
              id: 'type:NER',
              parentId: 'type',
              value: classifiersService.CLASSIFIER_ALGORITHMS.NER,
              displayValue: 'NER',
            },
            {
              id: 'type:DOC',
              parentId: 'type',
              value: classifiersService.CLASSIFIER_ALGORITHMS.DOC,
              displayValue: 'DOC',
            },
            {
              id: 'type:metadata',
              parentId: 'type',
              value: 'columnName',
              displayValue: 'Metadata',
            },
          ],
        },
        {
          id: 'status',
          value: 'status',
          displayValue: 'Status',
          children: [
            {
              id: 'status:enabled',
              parentId: 'status',
              value: 'enabled',
              displayValue: 'Enabled',
            },
          ],
        },
      ],
      value: [],
      onSelect: options => {
        $rootScope.$apply(() => {
          getDataListContainer().scrollTop = 0;
          this.filterItem.value = options;
          this.filterCriteria = _.groupBy(this.filterItem.value, 'parentId');
          userPreferencesService
            .get(CONFIG.states.CLASSIFIERS)
            .then(preferences => {
              if (preferences) {
                userPreferencesService.update({
                  ...preferences,
                  data: {
                    ...preferences.data,
                    optionsFilter: this.filterItem.value,
                    filterCriteria: this.filterCriteria,
                    isEnabledClassifiersListPreserved: false,
                  },
                });
              } else {
                userPreferencesService.add({
                  preference: CONFIG.states.CLASSIFIERS,
                  data: {
                    optionsFilter: this.filterItem.value,
                    filterCriteria: this.filterCriteria,
                    isEnabledClassifiersListPreserved: false,
                  },
                });
              }
            })
            .catch(() => {
              notificationService.error('An error has occurred while updating user preferences');
            });
        });
      },
    };
    this.removedIgnoreValues = [];
    this.getIsClassifierEnabled = enabled => enabled === true || typeof enabled !== 'boolean';
    this.classifierTesterDialog = {
      isOpen: false,
      onClose: () => {
        this.classifierTesterDialog.isOpen = false;
        $rootScope.$applyAsync();
      },
      classifier: this.classifier,
      onSave: (classifierName, regexToUpdate, classifierPerformance) => {
        const classifierToUpdate = {
          ...omit(this.classifierTesterDialog.classifier, ['selected', 'classification_name']),
          classification_regex: regexToUpdate,
          performance: classifierPerformance,
        };
        updateClassifier(classifierName, classifierToUpdate, true);
        $rootScope.$applyAsync();
      },
    };

    // API calls -- start

    const getClassifiers = () => classifiersService.getClassifiers();

    const getAdvancedClassifiers = () => classifiersService.getAdvancedClassifiers();

    const getChecksumValidations = () => classifiersService.getChecksumValidations();

    const getDataListContainer = () => $document[0].getElementById(CLASSIFIERS_DATA_LIST_ID);

    const getDataListItem = classifierIndex =>
      $document[0].getElementById(`${CLASSIFIERS_DATA_LIST_ITEM_ID}-${classifierIndex}`);

    const sortAdvancedClassifier = (subAdvancedClassifiersArray, isNer) => {
      return subAdvancedClassifiersArray.classifiers
        .reduce((advancedClassifiersAggregator, classifier) => {
          classifier['classification_name'] = isNer
            ? getNerClassifierTitle(classifier['classifierName'], classifier['modelName'])
            : classifiersService.getClassifierNameById(classifier['_id']);
          classifier['isAdvanced'] = true;

          return [...advancedClassifiersAggregator, classifier];
        }, [])
        .sort(alphaSort);
    };

    const getNerClassifierTitle = (classifierName, classifierModel) => {
      return classifierModel === 'default' ? classifierName : `${classifierName}(${classifierModel})`;
    };

    const resetCustomNerModel = () => {
      this.customModel = { entityMap: [{ id: uuid(), entityModelName: '', nerClassifierName: '' }] };
    };

    const extractSortedAdvancedClassifiers = (advancedClassifiers, type) => {
      if (advancedClassifiers) {
        const isNer =
          type === classifiersService.CLASSIFIER_ALGORITHMS.NER &&
          advancedClassifiers.nerClassifiers &&
          advancedClassifiers.nerClassifiers.featureFlag;
        this.isNerClassifierFlagEnabled = advancedClassifiers.nerClassifiers.featureFlag;

        const isDoc =
          type === classifiersService.CLASSIFIER_ALGORITHMS.DOC &&
          advancedClassifiers.docClassifiers &&
          advancedClassifiers.docClassifiers.featureFlag;

        if (isNer) {
          return sortAdvancedClassifier(advancedClassifiers.nerClassifiers, true);
        } else if (isDoc) {
          return sortAdvancedClassifier(advancedClassifiers.docClassifiers, false);
        } else {
          return [];
        }
      }
    };

    const initializeUpdates = classifiersToUpdate => {
      this.hasClassifiersToUpdate = classifiersToUpdate.length > 0;

      if (this.hasClassifiersToUpdate) {
        const handler = () => {
          openClassifierUpdatePreviewBulkDialog({
            classifiers: classifiersToUpdate,
            onUpdate: updatedClassifiers => {
              this.classifiers = this.classifiers.map(classifier => {
                const updatedClassifier = updatedClassifiers.find(
                  ({ classification_name }) => classifier.classification_name === classification_name,
                );

                return updatedClassifier ? { ...classifier, ...updatedClassifier } : classifier;
              });

              const selectedClassifierToUpdate = updatedClassifiers.find(
                ({ classification_name }) => this.classifier.classification_name === classification_name,
              );

              if (selectedClassifierToUpdate) {
                onEditClassifier({ ...this.classifier, ...selectedClassifierToUpdate });
              }

              const classifiersToUpdate = this.classifiers.filter(
                ({ versionState, version }) => version && versionState === ClassifierVersionState.NEEDS_UPDATE,
              );
              initializeUpdates(classifiersToUpdate);

              $rootScope.$applyAsync();
            },
          });
        };

        const isUpdateOptionAlreadyExist = this.exportButtonItems.find(({ action }) => action === 'update');

        if (isUpdateOptionAlreadyExist) {
          this.exportButtonItems = this.exportButtonItems.map(item => {
            if (item.action === 'update') {
              return { ...item, handler };
            } else {
              return item;
            }
          });
        } else {
          this.exportButtonItems.push({
            name: 'Review Available Updates',
            action: 'update',
            handler,
          });
        }
      } else {
        this.exportButtonItems = this.exportButtonItems.filter(({ action }) => action !== 'update');
      }
    };

    const initializeList = (result, preselectedClassifier) => {
      const { classifiers, advancedClassifiers, checksumValidations } = result;
      const sortedNerClassifiers = extractSortedAdvancedClassifiers(
        advancedClassifiers,
        classifiersService.CLASSIFIER_ALGORITHMS.NER,
      );
      this.docClassifiers = extractSortedAdvancedClassifiers(
        advancedClassifiers,
        classifiersService.CLASSIFIER_ALGORITHMS.DOC,
      );
      const [nerClassifiersWithoutCustom, nerCustomClassifiers] = _.partition(sortedNerClassifiers, [
        'isOutOfTheBox',
        true,
      ]);
      this.nerClassifiers = [...nerClassifiersWithoutCustom.sort(alphaSort), ...nerCustomClassifiers.sort(alphaSort)];
      const [classifiersWithoutCustom, customClassifiers] = _.partition(classifiers, ['isOutOfTheBox', true]);

      this.customClassifiers = customClassifiers;
      this.checksumValidations = checksumValidations;
      this.classifiers = [
        ...this.docClassifiers,
        ...this.nerClassifiers,
        ...this.customClassifiers.sort(alphaSort),
        ...classifiersWithoutCustom.sort(alphaSort),
      ];

      this.enabledClassifiersCount = this.classifiers.reduce((counter, { enabled }) => {
        return this.getIsClassifierEnabled(enabled) ? counter + 1 : counter;
      }, 0);

      if (preselectedClassifier) {
        navigateToClassifier(preselectedClassifier);
      }
    };

    const navigateToClassifier = preselectedClassifier => {
      //NOTE: ideally to use an ID to avoid classifier name normalization
      const { name, type } = preselectedClassifier;
      let classifierIndex;

      if (type) {
        classifierIndex = this.classifiers.findIndex(({ classification_name, classifierType }) => {
          return (
            name === `${ATTRIBUTE_PREFIX_MAP[type]}${classification_name}` &&
            (classifierType ?? CLASSIFIER_TYPE.DATA) === ATTRIBUTE_CLASSIFIER_TYPE_MAP[type]
          );
        });
      } else {
        classifierIndex = this.classifiers.findIndex(({ classification_name, classifierType }) => {
          return (
            (name === `${ATTRIBUTE_PREFIX_MAP[ATTRIBUTE_TYPE.CLASSIFIER]}${classification_name}` &&
              (classifierType === CLASSIFIER_TYPE.DATA || classifierType === null)) ||
            (name === `${ATTRIBUTE_PREFIX_MAP[ATTRIBUTE_TYPE.CORRELATION]}${classification_name}` &&
              classifierType === CLASSIFIER_TYPE.COLUMN_NAME)
          );
        });
      }

      if (classifierIndex > 0) {
        $timeout(() => {
          const dataListContainer = getDataListContainer();
          const dataListItem = getDataListItem(classifierIndex);

          if (dataListContainer !== null && dataListItem !== null) {
            const classifier = this.classifiers[classifierIndex];
            onEditClassifier(classifier);

            dataListContainer.scrollTop = classifierIndex * dataListItem.offsetHeight;
          }
        }, 0);
      }
    };

    const fetchClassifiers = (scrollTo = '', entityToProceed = null) => {
      this.onDataLoading();

      initializeData(result => {
        if (this.isClassifiersAutoUpgrageDisable) {
          //TODO: use all the classifiers once NER/DOC classifier version update is supported
          const classifiersToUpdate = result.classifiers.filter(
            ({ versionState, version }) => version && versionState === ClassifierVersionState.NEEDS_UPDATE,
          );
          initializeUpdates(classifiersToUpdate);
        }

        initializeList(result);

        this.onDataLoaded();

        if (entityToProceed !== null) {
          if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
            onCreateClassifier();
          } else {
            onEditClassifier(entityToProceed);
          }
        } else {
          if (this.editModeOn) {
            resetListSelection();
            setListSelection(this.classifier.classification_name);
          }

          $timeout(() => {
            const index = this.classifiers.findIndex(
                classifier => classifier.classification_name === this.classifier.classification_name,
              ),
              dataListItem = $document[0].getElementById(`${CLASSIFIERS_DATA_LIST_ITEM_ID}-${index}`);

            if (dataListItem !== null && index !== -1) {
              getDataListContainer().scrollTop = index * dataListItem.offsetHeight;
            }
          }, 0);
        }

        $timeout(() => {
          if (!getDataListContainer()) return;
          switch (scrollTo) {
            case 'top':
              getDataListContainer().scrollTop = 0;
              break;
            case 'bottom':
              getDataListContainer().scrollTop = getDataListContainer().scrollHeight;
              break;
            default:
              if (entityToProceed !== null) {
                if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                  getDataListContainer().scrollTop = 0;
                } else {
                  const index = this.classifiers.findIndex(classifier => classifier._id === entityToProceed._id),
                    dataListItem = $document[0].getElementById(`${CLASSIFIERS_DATA_LIST_ITEM_ID}-${index}`);

                  if (dataListItem !== null && index !== -1) {
                    getDataListContainer().scrollTop = index * dataListItem.offsetHeight;
                  }
                }
              }
          }
        }, 0);
      });
    };

    const updateAdvancedClassifier = (type, data) => {
      classifiersService.updateAdvancedClassifier(type, data).then(
        () => {
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

          this.isFormDirty = false;

          if (this.formOnly) this.onFormSubmitted({ isUpdated: true });
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    this.addEntity = () => {
      this.customModel.entityMap.push({ id: uuid(), entityModelName: '', nerClassifierName: '' });
    };

    this.deleteEntity = itemId => {
      const filteredEntities = this.customModel.entityMap.filter(({ id }) => id !== itemId);
      this.customModel.entityMap = filteredEntities.length ? filteredEntities : [EMPTY_NER_ENTITY];
    };
    const updateClassifier = (id, data, shouldUpdateClassifierFromTester) => {
      classifiersService.updateClassifier(id, data).then(
        response => {
          data.classification_name = id;
          if (response.data.result == 'OK') {
            updateShowExcludeListButton(data);
            if (shouldUpdateClassifierFromTester) {
              this.classifier = data;
              this.classifierTesterDialog.isOpen = false;
            }
            $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            this.isFormDirty = false;

            if (this.formOnly) this.onFormSubmitted({ isUpdated: true });
            else {
              this.classifiers = this.classifiers.map(classifier => {
                if (classifier._id === data._id) {
                  if (this.classifier._id === classifier._id) {
                    return { ...data, selected: true };
                  } else {
                    return data;
                  }
                } else {
                  return classifier;
                }
              });
            }
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(`${translation} ${response.data.message}`);
            });
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };
    const createCustomNerClassifier = async (customModelName, customModelEntityMap) => {
      const customNerClassifiers = customModelEntityMap.map(entity => ({
        classifierName: entity.nerClassifierName,
        modelName: customModelName,
        nerEntityMap: entity.entityModelName,
      }));
      classifiersService.createCustomNerClassifier(customNerClassifiers).then(
        response => {
          if (response?.data?.data) {
            $translate('CLASSIFIERS:API:MESSAGE:PUT_SUCCESS').then(translation => {
              notificationService.success(translation);
            });

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            this.isFormDirty = false;

            if (this.formOnly) {
              this.onFormSubmitted({ isUpdated: true });
            } else {
              this.editModeOn = true;
              this.createModeOn = false;
              this.showNerCustomModelForm = false;
              const nerEntityToProceed = {
                classification_name: getNerClassifierTitle(
                  customNerClassifiers[0].classifierName,
                  customNerClassifiers[0].modelName,
                ),
                isAdvanced: true,
              };
              fetchClassifiers('', nerEntityToProceed);
            }
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const updateShowExcludeListButton = classifier => {
      const isColumnName = classifier.classifierType === CLASSIFIER_TYPE.COLUMN_NAME;
      this.toShowExcludeListButton = isColumnName ? false : isClassfierExcludeListFF();
    };

    const createClassifier = (data, entityToProceed = null, isDuplicateClassifier = null) => {
      classifiersService
        .createClassifier(data)
        .then(response => {
          if (response.data.result == 'OK') {
            if (isDuplicateClassifier) {
              this.classifier = data;
              closeSystemDialog();
            }
            if (response.config.data.classifierType === CLASSIFIER_TYPE.DATA) {
              $translate('CLASSIFIERS:API:MESSAGE:PUT_SUCCESS').then(translation => {
                notificationService.success(translation);
              });
            } else if (response.config.data.classifierType === CLASSIFIER_TYPE.COLUMN_NAME) {
              $translate('CLASSIFIERS:API:MESSAGE:COLUMN_NAME:PUT_SUCCESS').then(translation => {
                notificationService.success(translation);
              });
            }
            updateShowExcludeListButton(response.config.data);
            if (contentBodyContainer !== null) {
              contentBodyContainer.scrollTop = 0;
            }

            this.isFormDirty = false;

            if (this.formOnly) {
              this.onFormSubmitted({ isUpdated: true });
            } else {
              this.editModeOn = true;
              this.createModeOn = false;
              fetchClassifiers('', entityToProceed);
            }
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(`${translation} ${response.data.message}`);
            });
          }
        })
        .catch(err => {
          let message = err.data.message;
          if (err.data.message.startsWith('E11000 duplicate key error')) {
            message = 'Classifier name already exist';
          }
          notificationService.error(message);
        });
    };

    const deleteAnyClassifier = classifier => {
      if (classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.NER) {
        return classifiersService.deleteCustomNerClassifier(classifier._id);
      } else {
        return classifiersService.deleteClassifier(classifier.classification_name);
      }
    };

    const deleteClassifier = classifier => {
      deleteAnyClassifier(classifier).then(
        () => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          this.editModeOn = false;
          this.createModeOn = false;

          this.classifier = {};

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            fetchClassifiers('top');
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    // API calls -- end

    const alphaSort = (a, b) => {
      const aName = a.classification_name.toLowerCase(),
        bName = b.classification_name.toLowerCase();

      if (aName < bName) {
        return -1;
      } else if (aName > bName) {
        return 1;
      } else {
        return 0;
      }
    };

    const initializeData = async callback => {
      try {
        const classifiersResponse = await getClassifiers();
        const classifiers = classifiersResponse.data;

        const advancedClassifiersResponse = await getAdvancedClassifiers();
        const { classifierAlgorithms: advancedClassifiers } = advancedClassifiersResponse.data;

        const checksumResponse = await getChecksumValidations();
        const checksumValidations = checksumResponse.data.supportedChecksums.map(checksum => ({
          id: checksum,
          displayValue: checksum,
          value: checksum,
        }));

        return callback({ classifiers, advancedClassifiers, checksumValidations });
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback({ classifiers: [], advancedClassifiers: [], checksumValidations: [] });
      }
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onSubmitClassifier = async (entityToProceed = null) => {
      if (this.showNerCustomModelForm) {
        if (!this.nerClassifierForm.$valid) return;
        const customModelPayload = angular.copy(this.customModel);
        await createCustomNerClassifier(customModelPayload.modelName, customModelPayload.entityMap);
      } else {
        if (!this.classifierForm.$valid) return;

        const payload = angular.copy(this.classifier);

        if (typeof payload.selected != 'undefined') delete payload.selected;

        if (!this.isTermRegexSupported) {
          payload.support_term_value && delete payload.support_term_value;
          payload.proximity_before && delete payload.proximity_before;
          payload.proximity_after && delete payload.proximity_after;
        }

        if (!this.isNegativeTermRegexSupported) {
          payload.negative_support_term_value && delete payload.negative_support_term_value;
          payload.nst_proximity_before && delete payload.nst_proximity_before;
          payload.nst_proximity_after && delete payload.nst_proximity_after;
        }

        switch (true) {
          case this.createModeOn:
            payload.enabled = true;
            createClassifier({ ...payload, isOutOfTheBox: false }, entityToProceed);
            break;
          case this.editModeOn:
            updateClassifier(
              payload.classification_name,
              { ...omit(payload, ['classification_name']), isOutOfTheBox: false },
              entityToProceed,
            );
            break;
        }
      }
    };

    this.setClassifierType = () => {
      if (!this.classifier.classifierType) {
        this.classifier.classifierType = CLASSIFIER_TYPE.DATA;
      }
    };

    const onCreateClassifier = () => {
      resetListSelection();
      this.isTermRegexSupported = false;
      this.classifier = {};
      this.setClassifierType();
      this.createModeOn = true;
      this.editModeOn = false;
      resetFormState();
    };

    const onNerCreateClassifier = () => {
      resetListSelection();
      this.createModeOn = true;
      this.editModeOn = false;
      this.classifier = {};
      resetCustomNerModel();
      resetFormState();
    };
    const onEditClassifier = classifier => {
      resetListSelection();
      setListSelection(classifier.classification_name);
      this.classifier = angular.copy(classifier);
      this.isTermRegexSupported = Boolean(this.classifier.support_term_value);
      this.isNegativeTermRegexSupported = Boolean(this.classifier.negative_support_term_value);
      this.setClassifierType();

      this.editModeOn = true;
      this.createModeOn = false;
      this.showNerCustomModelForm = false;

      resetCustomNerModel();
      resetFormState();
      updateShowExcludeListButton(this.classifier);
    };

    const resetFormState = () => {
      if (
        typeof this.classifierForm != 'undefined' &&
        typeof this.classifierForm.$setPristine == 'function' &&
        typeof this.classifierForm.$setUntouched == 'function'
      ) {
        this.classifierForm.$setPristine();
        this.classifierForm.$setUntouched();
      } else {
        this.classifierForm = {};
      }
      this.nerClassifierForm = {};
      this.nerClassifierForm.$submitted = false;
      this.classifierForm.$submitted = false;
      this.isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.classifiers.length; i < len; i++) {
        if (this.classifiers[i].classification_name === except) continue;
        this.classifiers[i]['selected'] = false;
      }
    };

    const setListSelection = name => {
      for (let i = 0, len = this.classifiers.length; i < len; i++) {
        if (this.classifiers[i].classification_name === name) {
          this.classifiers[i]['selected'] = true;
          break;
        }
      }
    };

    this.onEdit = classifier => {
      if (this.classifier.classification_name !== classifier.classification_name) {
        if (this.isEditPermitted && this.isFormDirty) {
          onUserLeavesForm(
            () => {
              if (this.showNerCustomModelForm) {
                this.nerClassifierForm.$submitted = true;
              } else {
                this.classifierForm.$submitted = true;
              }
              onSubmitClassifier(classifier);
            },
            () => {
              onEditClassifier(classifier);
            },
          );
        } else {
          onEditClassifier(classifier);
        }
      }
    };
    this.openChecksumValidationDialog = async () => {
      try {
        let classifierChecksumValidation = null;
        if (this.classifier.validation) {
          const classifierChecksumName = this.classifier.validation;
          const classifierChecksumValidationResponse = await classifiersService.getClassifierChecksumValidationByName(
            classifierChecksumName,
          );
          classifierChecksumValidation = classifierChecksumValidationResponse?.data?.data?.[0];
        }
        const initialCheckSumType = getChecksumValueType(classifierChecksumValidation);
        const { checksumName, isCancelOccurred } = await openClassifierChecksumValidationDialog(
          this.classifier.classification_name,
          classifierChecksumValidation,
          this.checksumValidations,
          initialCheckSumType,
        );
        $rootScope.$apply(async () => {
          if (!isCancelOccurred) {
            this.classifier.validation = checksumName;
            this.isFormDirty = true;
          }
        });
      } catch (error) {
        console.error(error);
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });
      }
    };
    const getChecksumValueType = classifierChecksumValidation => {
      let checksumType = '';
      if (!classifierChecksumValidation) checksumType = CHECKSUM_VALIDATION_TYPE.NONE;
      else {
        if (classifierChecksumValidation.algorithm) {
          checksumType = CHECKSUM_VALIDATION_TYPE.CUSTOM;
        } else {
          checksumType = CHECKSUM_VALIDATION_TYPE.OOTB;
        }
      }
      return checksumType;
    };

    this.onCreate = () => {
      $rootScope.$apply(() => {
        this.showNerCustomModelForm = false;
        if (this.isFormDirty) {
          onUserLeavesForm(
            () => {
              this.classifierForm.$submitted = true;
              onSubmitClassifier({});
            },
            () => {
              onCreateClassifier();
            },
          );
        } else {
          onCreateClassifier();
        }
      });
    };

    this.onUpdateVersion = classifierToUpdate => {
      openClassifierUpdatePreviewDialog(classifierToUpdate, updatedClassifierVersion => {
        this.classifiers = this.classifiers.map(classifier => {
          if (classifier.classification_name === classifierToUpdate.classification_name) {
            return { ...classifier, ...updatedClassifierVersion };
          } else {
            return classifier;
          }
        });
        onEditClassifier({ ...this.classifier, ...updatedClassifierVersion });

        const classifiersToUpdate = this.classifiers.filter(
          ({ versionState, version }) => version && versionState === ClassifierVersionState.NEEDS_UPDATE,
        );
        initializeUpdates(classifiersToUpdate);

        $rootScope.$applyAsync();
      });
    };

    this.onRestoreVersion = async classifierToUpdate => {
      const updatedClassifierVersion = await openClassifierRestoreVersionDialog(classifierToUpdate);

      if (updatedClassifierVersion) {
        this.classifiers = this.classifiers.map(classifier => {
          if (classifier.classification_name === classifierToUpdate.classification_name) {
            return { ...classifier, ...updatedClassifierVersion };
          } else {
            return classifier;
          }
        });
        onEditClassifier({ ...this.classifier, ...updatedClassifierVersion });

        const classifiersToUpdate = this.classifiers.filter(
          ({ versionState, version }) => version && versionState === ClassifierVersionState.NEEDS_UPDATE,
        );
        initializeUpdates(classifiersToUpdate);

        $rootScope.$applyAsync();
      }
    };

    this.onDelete = async classifier => {
      const closeButtonText = await $translate('BUTTONS:CANCEL');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('APP:COMMON:DELETE_CONFIRMATION_HEADER', {
        entityName: classifier.classification_name,
      });
      const bodyText = await $translate('CLASSIFIERS:DELETE_CONFIRMATION_TEXT', {
        entityName: classifier.classification_name,
      });

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };
      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteClassifier(classifier));
    };

    this.shouldShowTesterButton = () => {
      return (
        this.isClassifierTesterEnabled &&
        this.classifier.isClassifierTesterSupported !== false &&
        this.editModeOn &&
        this.isCreatePermitted
      );
    };

    this.onClassifierTesterClicked = classifier => {
      this.classifierTesterDialog.isOpen = true;
      this.classifierTesterDialog.classifier = classifier;
    };

    this.onDuplicate = async classifier => {
      try {
        const { duplicateClassifierName } = await openCreateDuplicateClassifierDialog(this.classifiers);
        if (duplicateClassifierName) {
          const duplicateClassifierChecksumName = await handleDuplicateChecksumIfNeeded(
            classifier.validation,
            duplicateClassifierName,
          );
          const duplicateClassifier = {
            ...omit(classifier, ['_id', 'selected', 'version', 'versionState', 'isClassifierTesterSupported']),
            isOutOfTheBox: false,
            classification_name: duplicateClassifierName,
            validation: duplicateClassifierChecksumName,
          };
          createClassifier(duplicateClassifier, null, true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleDuplicateChecksumIfNeeded = async (classifierChecksumName, duplicateClassifierName) => {
      if (isDuplicateChecksumRequired(classifierChecksumName)) {
        const checksumResponse = await classifiersService.getClassifierChecksumValidationByName(classifierChecksumName);
        return await duplicateChecksumByClassifierName(
          duplicateClassifierName,
          classifierChecksumName,
          checksumResponse?.data?.data?.[0],
        );
      }
      return classifierChecksumName;
    };
    const isDuplicateChecksumRequired = classifierChecksumName => {
      if (classifierChecksumName) {
        return this.checksumValidations.find(checksum => checksum.value === classifierChecksumName) === undefined;
      }
      return false;
    };

    const regexPatternValidator = regexToTest => {
      try {
        validateRegExpression(regexToTest);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    this.onSubmit = () => {
      if (this.showNerCustomModelForm) {
        this.nerClassifierForm.$submitted = true;
      } else {
        const pattern = this.classifier.classification_regex;
        this.classifierForm.classifierRegex.$setValidity('invalidRegexPattern', regexPatternValidator(pattern));
        this.classifierForm.$submitted = true;
      }
      onSubmitClassifier();
    };

    this.onTermRegexSupportedChanged = () => {
      this.classifier.support_term_value = '';
      this.classifier.proximity_before = '';
      this.classifier.proximity_after = '';
    };

    this.onNegativeTermRegexSupportedChanged = () => {
      this.classifier.negative_support_term_value = '';
      this.classifier.nst_proximity_before = '';
      this.classifier.nst_proximity_after = '';
    };

    this.classifyMetadata = () => {
      classifiersService
        .classifyMetadata({ scanType: 'metadata_classification', classifierName: this.classifier.classification_name })
        .then(() => {
          $translate('CLASSIFIERS:CLASSIFY_METADATA').then(translation => {
            notificationService.success(translation);
          });
        });
    };

    this.removeIgnoreValueHandler = ignoreValue => {
      this.removedIgnoreValues.push(ignoreValue);
    };

    this.isNerOrDoc = classifier =>
      classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.NER ||
      classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.DOC;

    this.generateExcludeListRequest = (modifiedItems, removedItems) => {
      const commonItems = intersectionWith(modifiedItems, removedItems, (modifyItem, removeItem) => {
        return modifyItem.value === removeItem.value && modifyItem.type === removeItem.type;
      });
      const updatedModifiedItems = modifiedItems.filter(modifyItem => {
        const isExistInCommon = commonItems.some(
          commonItem => commonItem.value === modifyItem.value && commonItem.type === modifyItem.type,
        );
        if (isExistInCommon) {
          return false;
        }
        return true;
      });

      const updatedRemovedItems = removedItems.filter(removedItem => {
        const isExistInCommon = commonItems.some(
          commonItem => commonItem.value === removedItem.value && commonItem.type === removedItem.type,
        );
        if (isExistInCommon) {
          return false;
        }
        return true;
      });
      return { updatedModifiedItems, updatedRemovedItems };
    };

    this.onExcludeList = async () => {
      this.isOpenExcludeListDialog = true;
      const requestedClassifierName = this.isNerOrDoc(this.classifier)
        ? this.classifier._id
        : this.classifier.classification_name;
      const [regexList, stringList] = await Promise.all([
        classifiersService.getClassifierExcludeListByName(requestedClassifierName, 'regex'),
        classifiersService.getClassifierExcludeListByName(requestedClassifierName, 'string'),
      ]);
      const requestedRegexList = regexList.data.data.excludeList.map(excludeRegexValue => ({
        ...excludeRegexValue,
        id: excludeRegexValue._id,
      }));
      const requestedStringList = stringList.data.data.excludeList.map(excludeStringValue => ({
        ...excludeStringValue,
        id: excludeStringValue._id,
      }));
      const { data, isCancelOccured } = await openCreateExcludeListClassifierDialog(
        requestedClassifierName,
        requestedRegexList,
        requestedStringList,
        this.removeIgnoreValueHandler,
      );

      $rootScope.$apply(async () => {
        if (isCancelOccured) {
          this.removedIgnoreValues = [];
          this.isOpenExcludeListDialog = false;
          return;
        }

        const { updatedModifiedItems, updatedRemovedItems } = this.generateExcludeListRequest(
          data,
          this.removedIgnoreValues,
        );
        this.removedIgnoreValues = [];
        this.isOpenExcludeListDialog = false;

        const requests = [];
        if (updatedModifiedItems.length > 0) {
          const body = {
            excludeList: updatedModifiedItems.map(modifiedItem => omit(modifiedItem, ['id'])),
          };
          const upsertExcludeValues = classifiersService.upsertExcludeList(body);
          requests.push(upsertExcludeValues);
        }
        if (updatedRemovedItems.length > 0) {
          const body = {
            excludeValuesIds: updatedRemovedItems.map(removedItem => removedItem._id),
          };
          const deleteManyExcludeValues = classifiersService.deleteManyExcludeValues(body);
          requests.push(deleteManyExcludeValues);
        }

        try {
          if (requests.length > 0) {
            this.onDataLoading();
            await Promise.all(requests);
            this.onDataLoaded();
            notificationService.success('Exclude list saved successfully');
          }
        } catch (error) {
          this.onDataLoaded();
          notificationService.error(error.data.message);
        }
      });
    };

    this.getLastElementId = array => {
      if (!array) {
        return [];
      } else {
        const lastElement = array[array.length - 1];
        return lastElement ? lastElement._id : undefined;
      }
    };

    this.isSeparatorApplied = classifier => {
      const isLastNerToggle =
        classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.NER &&
        this.getLastElementId(this.nerClassifiers) === classifier._id;

      const isLastDocToggle =
        classifier.type === classifiersService.CLASSIFIER_ALGORITHMS.DOC &&
        this.getLastElementId(this.docClassifiers) === classifier._id;

      const isLastCustom =
        classifier.isOutOfTheBox === false && this.getLastElementId(this.customClassifiers) === classifier._id;

      return isLastNerToggle || isLastDocToggle || isLastCustom;
    };

    this.onCreateNer = () => {
      $rootScope.$apply(() => {
        this.showNerCustomModelForm = true;
        if (this.isFormDirty) {
          onUserLeavesForm(
            () => {
              this.nerClassifierForm.$submitted = true;
              onSubmitClassifier({});
            },
            () => {
              onNerCreateClassifier();
            },
          );
        } else {
          onNerCreateClassifier();
        }
      });
    };

    this.onSwitcherToggled = (isEnabled, ...rest) => {
      const classifier = rest[0];

      classifier.enabled = isEnabled;

      if (classifier.classification_name === this.classifier.classification_name) {
        this.classifier.enabled = isEnabled;
      }

      const payload = angular.copy(classifier);

      if (typeof payload.selected != 'undefined') {
        delete payload.selected;
      }
      if (classifier.isAdvanced) {
        if (typeof payload.isAdvanced != 'undefined') {
          delete payload.isAdvanced;
        }

        if (typeof payload.template != 'undefined') {
          delete payload.template;
        }

        if (typeof payload.classification_name != 'undefined') {
          delete payload.classification_name;
        }

        updateAdvancedClassifier(classifier.type, payload);
      } else {
        updateClassifier(classifier.classification_name, { enabled: isEnabled });
      }

      this.enabledClassifiersCount = isEnabled ? this.enabledClassifiersCount + 1 : this.enabledClassifiersCount - 1;
    };

    this.onImportDialogClosed = () => {
      this.isImportDialogOpen = false;
      $rootScope.$applyAsync();
    };

    this.onImportClicked = async (file, isImportExcludeList) => {
      try {
        const { data } = await httpService.multipart('post', `classifications/import`, {
          files: { file },
          data: { isImportExcludeList },
        });
        const [{ allExcludeListsValid }] = data.data;
        console.log('allExcludeListsValid', allExcludeListsValid);
        if (allExcludeListsValid) {
          notificationService.success('Import was completed successfully');
        } else {
          notificationService.warning(
            `The import process is complete. Some exclude list objects weren't valid and weren't imported.`,
          );
        }
        this.init();
      } catch (e) {
        notificationService.error('Import failed. See logs for more information');
      }
    };

    this.onExportImportButtonItemClick = item => {
      item.handler();
    };

    this.getListTogglerText = () => {
      if (this.isEnabledClassifiersListToggled) {
        return `Show All ${this.classifiers.length}`;
      } else {
        if (this.enabledClassifiersCount > 0) {
          return `Show ${this.enabledClassifiersCount} Enabled`;
        } else {
          return null;
        }
      }
    };

    this.onClose = () => {
      this.editModeOn = false;
      this.createModeOn = false;
      resetListSelection();

      if (this.formOnly) this.onFormClosed();
    };

    this.onFormChanged = () => {
      this.isFormDirty = true;
    };

    const isClassifierDataType = classifier => {
      return (
        classifier.type === undefined &&
        (isNil(classifier.classifierType) || classifier.classifierType === CLASSIFIER_TYPE.DATA)
      );
    };

    const isMatchClassiferByTypeFilter = (filters, classifier) => {
      return filters.some(({ value }) => {
        switch (value) {
          case CLASSIFIER_TYPE.DATA:
            return isClassifierDataType(classifier);
          case FILTER_BY.TYPE.NER:
            return classifier.type === FILTER_BY.TYPE.NER;
          case FILTER_BY.TYPE.DOC:
            return classifier.type === FILTER_BY.TYPE.DOC;
          case CLASSIFIER_TYPE.COLUMN_NAME:
            return classifier.classifierType === CLASSIFIER_TYPE.COLUMN_NAME;
          default:
            throw new Error(`Filter by ${value} is not from the allowed filters`);
        }
      });
    };

    const isMathcClassiferBySourceFilter = (filters, classifier) => {
      return filters.some(({ value }) => {
        if (value === FILTER_BY.SOURCE.CUSTOM) {
          return (
            classifier.isOutOfTheBox === false ||
            (classifier.type !== FILTER_BY.TYPE.NER &&
              classifier.modelId !== 'default' &&
              classifier.isOutOfTheBox === undefined)
          );
        }
        if (value === FILTER_BY.SOURCE.OOTB) {
          return (
            classifier.isOutOfTheBox === true ||
            classifier.type === FILTER_BY.TYPE.NER ||
            classifier.modelId === 'default'
          );
        }
      });
    };

    const isAllFiltersMatching = classifier => {
      let isMatch = true;
      const filtersKeys = _.keys(this.filterCriteria);
      filtersKeys.forEach(filterKey => {
        const children = this.filterCriteria[filterKey];
        if (filterKey === FILTER_KEYS.STATUS) {
          isMatch = isMatch && classifier.enabled === true;
        }
        if (filterKey === FILTER_KEYS.TYPE) {
          isMatch = isMatch && isMatchClassiferByTypeFilter(children, classifier);
        }
        if (filterKey === FILTER_KEYS.SOURCE) {
          isMatch = isMatch && isMathcClassiferBySourceFilter(children, classifier);
        }
      });
      return isMatch;
    };

    this.classifiersFilter = (query = '') => {
      const normalizedQuery = query.toLowerCase();

      return classifier => {
        const { classification_name, description } = classifier;
        const isClassifierMatching =
          classification_name?.toLowerCase().includes(normalizedQuery) ||
          description?.toLowerCase().includes(normalizedQuery);

        if (isClassifierMatching && this.filterItem.value.length === 0) {
          return true;
        }

        return isClassifierMatching && isAllFiltersMatching(classifier);
      };
    };

    this.onUserQueryChange = () => {
      this.scrollToTop();
    };

    // placeholder during initialization
    this.scrollToTop = () => {
      // eslint-disable-line no-empty-function
    };

    this.onScrollBarReady = ({ ps, scrollToTop }) => {
      this.scrollToTop = () => {
        $timeout(
          () => {
            ps.update();
            scrollToTop();
          },
          10,
          false,
        );
      };
    };

    this.$onInit = () => {
      const preselectedClassifier =
        this.classifierName || this.classifierType
          ? {
              name: this.classifierName,
              type: this.classifierType,
            }
          : undefined;

      this.init(preselectedClassifier);
    };

    const isClassfierExcludeListFF = () => getApplicationPreference('CLASSIFIER_EXCLUDE_LIST_FF');

    this.init = preselectedClassifier => {
      this.toShowExcludeListButton = isClassfierExcludeListFF();
      this.showNegativeSupporTermFields = getApplicationPreference('NEGATIVE_SUPPORT_TERM_FF');
      if (this.formOnly) {
        if (typeof this.formData != 'undefined') {
          this.classifier = angular.copy(this.formData);
          this.editModeOn = true;
          this.createModeOn = false;
        } else {
          this.editModeOn = false;
          this.createModeOn = true;
        }
        resetFormState();
      } else {
        this.onDataLoading();

        userPreferencesService
          .get(CONFIG.states.CLASSIFIERS)
          .then(preferences => {
            this.filterItem.value = preferences?.data?.optionsFilter || [];
            this.filterCriteria = preferences?.data?.filterCriteria || {};

            initializeData(result => {
              if (this.isClassifiersAutoUpgrageDisable) {
                //TODO: use all the classifiers once NER/DOC classifier version update is supported
                const classifiersToUpdate = result.classifiers.filter(
                  ({ versionState, version }) => version && versionState === ClassifierVersionState.NEEDS_UPDATE,
                );
                initializeUpdates(classifiersToUpdate);
              }

              initializeList(result, preselectedClassifier);

              if (this.classifiers.length > 0) {
                onEditClassifier(this.classifiers[0]);
              }

              this.onDataLoaded();
            });
          })
          .catch(() => {
            notificationService.error('An error has occurred while fetching user preferences');
          });
      }
    };

    const toTranslate = ['BUTTONS:IMPORT', 'BUTTONS:EXPORT'];

    const buildAggregateFilter = () => {
      return _.reduce(
        this.filterCriteria,
        (filterAggregation, children, filterKey) => {
          switch (filterKey) {
            case FILTER_KEYS.SOURCE:
              const sourceValue = children.map(child => child.value);
              const custom = children.find(child => child.value === false);
              if (custom) {
                sourceValue.push(undefined);
              }
              filterAggregation.push({ field: 'isOutOfTheBox', value: sourceValue, operator: 'in' });
              break;
            case FILTER_KEYS.STATUS:
              filterAggregation.push({ field: 'enabled', value: [true], operator: 'in' });
              break;
            case FILTER_KEYS.TYPE:
              const typeValue = children.map(child => child.value);
              const regular = children.find(child => child.value === 'data');
              if (regular) {
                typeValue.push(undefined);
              }
              filterAggregation.push({ field: 'classifierType', value: typeValue, operator: 'in' });
              break;
            default:
              break;
          }
          return filterAggregation;
        },
        [],
      );
    };

    this.openImportDialog = async () => {
      await openImportClassifiersDialog(this.onImportClicked);
    };

    $translate(toTranslate).then(translations => {
      this.exportButtonItems = [];
      if (this.isImportPermitted) {
        this.exportButtonItems.push({
          name: translations[toTranslate[0]],
          action: 'import',
          handler: async () => {
            await this.openImportDialog();
          },
        });
      }
      if (this.isExportPermitted) {
        this.exportButtonItems.push({
          name: translations[toTranslate[1]],
          action: 'export',
          handler: () => {
            const filter = buildAggregateFilter();
            httpService.downloadFile(`classifications/file-download/export?filter=${JSON.stringify(filter)}`);
          },
        });
      }
    });
  },
  bindings: {
    classifierName: '<',
    classifierType: '<',
    formOnly: '=',
    formData: '<',
    onFormClosed: '&',
    onFormSubmitted: '&',
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
