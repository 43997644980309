import React, { FC, useEffect, useState, useRef } from 'react';
import { CONFIG } from '../../../../../config/common';
import { pageHeaderService } from '../../../../../common/services/pageHeaderService';
import { $state, $stateParams } from '../../../../services/angularServices';
import {
  BigidWizardHorizontalNavStepData,
  BigidWizardHorizontalStepStatus,
  useHorizontalWizard,
  BigidFormStateAndHandlers,
  BigidWizardContainer,
  entityEventsEmitter,
  EntityEvents,
} from '@bigid-ui/components';
import { BasicDetails } from './Steps/BasicDetailsStep/BasicDetails';
import { Classification } from './Steps/ClassificationStep/Classification';
import { Correlation } from './Steps/CorrelationStep/Correlation';
import { ScanTemplate, ScanTypes } from '../scanTemplateTypes';
import { ScanTemplateWizardContext } from '../scanTemplateContext';
import { useTemplateApi } from './useTemplateApi';
import { checkClassificationAndCorrelationByScanTypes } from '../../ScanCreateWizard/hooks/useUpdateSupportedScanTypesBySelectedDs';
import { disableCorrelationAndClassificationStepsIfNecessary } from '../../ScanCreateWizard/ScanCreateWizardUtils';
import { isScanTemplateNameExist } from '../scanTemplatesService';
import { BigidExternalLinkIcon, BigidHelpIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import {
  BigidPageTitleHelper,
  BigidPageTitleHelperButtonType,
  BigidPageTitleHelperProps,
} from '../../../../components/BigidHeader/BigidPageTitle/BigidPageTitleHelper';
import { generateDataAid } from '@bigid-ui/utils';
import { ClassificationGuidedTour } from './Steps/ClassificationStep/GuidedTour/ClassificationGuidedTour';
import { docsUrls } from '../../../../config/publicUrls';
import { SIDEBAR_WIDTH, navigateToForbiddenPageIfScanTemplateOff } from '../../ScanUtils';

const Container = styled('div')`
  height: 100%;
  & [data-aid='BigidWizardContainerHeader'] {
    justify-content: flex-start;
    gap: 6px;
    & svg {
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
  [data-aid='scanTemplateWizard'] [data-aid='BigidPaper'] {
    padding: 24px;
    @media (min-width: 1024px) {
      width: calc(1024px - ${SIDEBAR_WIDTH}px);
    }
    @media (min-width: 1440px) {
      width: 80%;
    }
  }
`;

export enum ScanTemplateWizardSteps {
  BASIC_DETAILS = 'Basic Details',
  CLASSIFICATION = 'Classification',
  CORRELATION = 'Correlation',
}

export const templateWizardStepDisplayNames: Record<string, string> = {
  [ScanTemplateWizardSteps.BASIC_DETAILS]: 'Create New Template',
  [ScanTemplateWizardSteps.CLASSIFICATION]: 'Classification',
  [ScanTemplateWizardSteps.CORRELATION]: 'Correlation',
};

export const EDIT_TEMPLATE_TITLE = 'Edit Template';

export const scanTemplateWizardSteps: BigidWizardHorizontalNavStepData[] = [
  {
    id: ScanTemplateWizardSteps.BASIC_DETAILS,
    title: ScanTemplateWizardSteps.BASIC_DETAILS,
    component: BasicDetails,
  },
  {
    id: ScanTemplateWizardSteps.CLASSIFICATION,
    title: ScanTemplateWizardSteps.CLASSIFICATION,
    component: Classification,
  },
  {
    id: ScanTemplateWizardSteps.CORRELATION,
    title: ScanTemplateWizardSteps.CORRELATION,
    component: Correlation,
  },
];

const checkIfNextStepDisabled = (activeStepId: string, state: ScanTemplate) => {
  switch (activeStepId) {
    case ScanTemplateWizardSteps.BASIC_DETAILS:
      return !Boolean(state?.name && state?.scanType);
    case ScanTemplateWizardSteps.CLASSIFICATION:
      const { classificationRequired } = checkClassificationAndCorrelationByScanTypes([state?.scanType as ScanTypes]);
      return classificationRequired ? !state?.classifiers?.length && !state.isUseGlobalClassifiers : false;
    case ScanTemplateWizardSteps.CORRELATION:
      const hasCorrelation = state.allEnabledIdSor || state.idConnectionList?.length > 0;
      const hasClassification = state.isUseGlobalClassifiers || state.classifiers?.length > 0;
      return !(hasCorrelation || hasClassification) && state.scanType !== ScanTypes.METADATA_SCAN;
    default:
      return false;
  }
};

const DEFAULT_SCAN_TEMPLATE = {
  isReviewFindingsEnabled: true,
  skipIdScan: true,
} as ScanTemplate;

interface RightSideComponentProps {
  setManualStart: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightSideComponent: FC<RightSideComponentProps> = ({ setManualStart }) => {
  const props: BigidPageTitleHelperProps = {
    dataAid: generateDataAid('classification', ['title', 'helper']),
    body: 'This step presents several options to help you select exactly the classifiers you need.',
    title: 'Classifiers selection',
    togglerIcon: BigidHelpIcon,

    buttons: [
      {
        type: BigidPageTitleHelperButtonType.TERTIARY,
        text: 'Open Documentation',
        endIcon: <BigidExternalLinkIcon />,
        onClick: () => {
          window.open(docsUrls.SCAN_TEMPLATE_CLASSIFICATION, '_blank');
        },
      },
      {
        type: BigidPageTitleHelperButtonType.PRIMARY,
        text: 'Guided Tour',
        onClick: () => {
          setManualStart(true);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        },
      },
    ],
  };
  return <BigidPageTitleHelper {...props} />;
};

export const ScanTemplateWizard: FC = ({}) => {
  const [templateToEdit, setTemplateToEdit] = useState<ScanTemplate>(DEFAULT_SCAN_TEMPLATE);
  const [prevScanType, setPrevScanType] = useState<ScanTypes>(templateToEdit?.scanType as ScanTypes);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [manualStart, setManualStart] = useState(false);
  const { id, name } = $stateParams;
  const isEditMode = !!id;
  const { isLoading, fetchTemplate, handleSaveTemplate, getCurrentUserById } = useTemplateApi({
    id,
    templateToEdit,
    setTemplateToEdit,
    isEditMode,
  });
  const basicDetailsHandlersRef = useRef<BigidFormStateAndHandlers>();

  useEffect(() => {
    navigateToForbiddenPageIfScanTemplateOff();
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      breadcrumbs: [
        {
          label: 'Scan Templates',
          onClick: () => $state.go(CONFIG.states.SCANS_SCAN_TEMPLATES),
          dataAid: 'scanPageLink',
        },
        isEditMode ? { label: name } : { label: 'New Template' },
      ],
    });
    if (isEditMode && templateToEdit?._id !== id) {
      fetchTemplate();
    }
    if (!templateToEdit?.owner) {
      getCurrentUserById();
    }
  }, [fetchTemplate, getCurrentUserById, id, isEditMode, name, templateToEdit?._id, templateToEdit?.owner]);

  const { steps, updateStepAttributes, ActiveView, activeStepId, goToNext, goToPrev, noPrev, noNext, getPrevStep } =
    useHorizontalWizard(scanTemplateWizardSteps, '');

  const title =
    isEditMode && activeStepId === ScanTemplateWizardSteps.BASIC_DETAILS
      ? EDIT_TEMPLATE_TITLE
      : templateWizardStepDisplayNames[activeStepId];
  const isShowGuidedTour = title === templateWizardStepDisplayNames[ScanTemplateWizardSteps.CLASSIFICATION];

  const getStepStatus = (stepId: ScanTemplateWizardSteps, next?: boolean, prev?: boolean) => {
    if (next) {
      return BigidWizardHorizontalStepStatus.DONE;
    }
    if (prev) {
      return BigidWizardHorizontalStepStatus.DEFAULT;
    }
    return BigidWizardHorizontalStepStatus.ACTIVE;
  };

  const validateStep = async (activeStepId: ScanTemplateWizardSteps): Promise<boolean> => {
    switch (activeStepId) {
      case ScanTemplateWizardSteps.BASIC_DETAILS:
        const { name } = basicDetailsHandlersRef.current.getValues();
        const isNameExist = await isScanTemplateNameExist(name, !isEditMode);

        const isNextStepAllowed = isEditMode || !isNameExist;

        return new Promise(resolve => {
          basicDetailsHandlersRef.current?.validateAndSubmit(
            () => resolve(isNextStepAllowed),
            () => resolve(false),
          );
        });

      case ScanTemplateWizardSteps.CLASSIFICATION:
        const { classificationRequired } = checkClassificationAndCorrelationByScanTypes([
          templateToEdit?.scanType as ScanTypes,
        ]);
        return classificationRequired
          ? !!templateToEdit?.classifiers?.length || templateToEdit.isUseGlobalClassifiers
          : true;

      case ScanTemplateWizardSteps.CORRELATION:
        const hasCorrelation = templateToEdit.allEnabledIdSor || templateToEdit.idConnectionList?.length > 0;
        const hasClassification = templateToEdit.isUseGlobalClassifiers || templateToEdit.classifiers?.length > 0;
        return hasCorrelation || hasClassification || templateToEdit.scanType === ScanTypes.METADATA_SCAN;
      default:
        return true;
    }
  };

  const handleGoToNext = async () => {
    const isCurrentStepDataValid = await validateStep(activeStepId as ScanTemplateWizardSteps);
    if (isCurrentStepDataValid) {
      if (noNext) {
        await handleSaveTemplate();
      } else {
        updateStepAttributes([
          { stepId: activeStepId, attr: { status: getStepStatus(activeStepId as ScanTemplateWizardSteps, true) } },
        ]);
        goToNext();
      }
    }
  };

  const handleGoToPrev = () => {
    updateStepAttributes([
      {
        stepId: activeStepId,
        attr: { status: getStepStatus(activeStepId as ScanTemplateWizardSteps, false, true) },
      },
      { stepId: getPrevStep(), attr: { status: getStepStatus(activeStepId as ScanTemplateWizardSteps) } },
    ]);
    goToPrev();
  };

  useEffect(() => {
    setIsNextDisabled(checkIfNextStepDisabled(activeStepId, templateToEdit));
  }, [templateToEdit, activeStepId]);

  useEffect(() => {
    if (templateToEdit?.scanType !== prevScanType) {
      const scanType = templateToEdit?.scanType as ScanTypes;
      disableCorrelationAndClassificationStepsIfNecessary(
        scanType,
        updateStepAttributes,
        ScanTemplateWizardSteps.CLASSIFICATION,
        ScanTemplateWizardSteps.CORRELATION,
      );
      setPrevScanType(scanType);
    }
  }, [prevScanType, templateToEdit, updateStepAttributes]);

  const getRightSideComponent = () => {
    if (isShowGuidedTour) {
      return <RightSideComponent setManualStart={setManualStart} />;
    }
  };

  return (
    <Container>
      <ScanTemplateWizardContext.Provider
        value={{
          scanTemplateFormData: templateToEdit,
          setScanTemplateFormData: setTemplateToEdit,
          setPrevScanType,
          basicDetailsHandlersRef,
          isEditMode,
        }}
      >
        <BigidWizardContainer
          steps={steps}
          activeStepId={activeStepId}
          ActiveView={ActiveView}
          isLoading={isLoading}
          onNext={handleGoToNext}
          onPrev={handleGoToPrev}
          noNext={noNext}
          noPrev={noPrev}
          stepsProps={{ templateToEdit }}
          isNextDisabled={isNextDisabled}
          lastStepText={'Save'}
          disableStepClick
          title={title}
          RightSideComponent={() => getRightSideComponent()}
          dataAid="scanTemplateWizard"
        />
      </ScanTemplateWizardContext.Provider>
      {isShowGuidedTour && <ClassificationGuidedTour manualStart={manualStart} setManualStart={setManualStart} />}
    </Container>
  );
};
