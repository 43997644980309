import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidTooltip, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { CuratedFieldRecord } from './useCurationFieldsConfig';
import {
  getFindingIndicatorColorByStatus,
  getStatusDisplayName,
  getCuratedFieldStatusIconConfig,
} from '../curationUtils';
import { capitalize } from 'lodash';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import { CuratedFieldStatus } from '../curationService';

export type CuratedFieldsObjectNameCellProps = CuratedFieldRecord & {
  dataAid?: string;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  icon: {
    flex: '0 0 30px',
    height: '100%',
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  objectName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fieldName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const CuratedFieldsObjectNameCell: FC<CuratedFieldsObjectNameCellProps> = memo(
  ({ dataAid = 'CuratedFieldsObjectNameCell', objectName, reviewStatus }) => {
    const classes = useStyles();

    const Icon = getCuratedFieldStatusIconConfig(reviewStatus);

    const handleOnClick = () => {
      trackEventCurationView(CurationEvents.CURATION_FIELDS_ROW_CLICK);
    };
    return (
      <div className={classes.root} data-aid={dataAid}>
        {Icon && (
          <div className={classes.icon} data-aid={generateDataAid(dataAid, ['icon'])}>
            <BigidTooltip
              title={
                <BigidBody1 sx={{ color: getFindingIndicatorColorByStatus(reviewStatus) }}>
                  {capitalize(getStatusDisplayName(reviewStatus))}
                </BigidBody1>
              }
            >
              <Icon
                size="medium"
                data-aid={generateDataAid(dataAid, ['icon'])}
                color={reviewStatus === CuratedFieldStatus.APPROVED ? 'positive' : 'negative'}
              />
            </BigidTooltip>
          </div>
        )}
        <div className={classes.value}>
          <BigidTooltip followCursor={true} title={<BigidBody1>{objectName}</BigidBody1>}>
            <BigidBody1
              data-aid={generateDataAid(dataAid, ['object-name'])}
              className={classes.objectName}
              onClick={handleOnClick}
            >
              {objectName}
            </BigidBody1>
          </BigidTooltip>
        </div>
      </div>
    );
  },
);
