import { VaultConnectionAPIConfiguration } from '../../components/Credentials/CustomCredentialApiCallDialog/customCredentailDialogUtils';
import { CredentialType } from './credentialsFormUtils';

export interface CredentialActionType {
  label: string;
  value: string;
}

export enum CredentialTypes {
  CyberArk = 'CyberArk',
  RemoteCyberArk = 'RemoteCyberArk',
  BigID = 'simple',
  HashiCorp = 'HashiCorp',
  CustomApplicationVault = 'Custom',
  RemoteHashiCorp = 'RemoteHashiCorp',
  RemoteCustom = 'RemoteCustom',
  Thycotic = 'Thycotic',
  RemoteThycotic = 'RemoteThycotic',
}

export enum EnginesTypes {
  KeyValue = 'Key/Value',
  GoogleCloud = 'Google Cloud',
}

export interface CredentialItemResponse {
  _id: string | number;
  actions: CredentialActionType[];
  apps: CredentialActionType[];
  created_at: string;
  credential_id: string;
  customQuery: string;
  scopes: string[];
  type: CredentialTypes;
  updated_at?: string;
  usernameAsPlainText: boolean;
  cyberArkVaultId?: string;
  query?: string;
  password?: string;
  username?: string;
  passwordIdentifier?: string;
  secret_engine?: EnginesTypes;
  url?: string;
  usernameIdentifier?: string;
  credentialsIdentifiers?: Record<string, string>;
  scanner_group?: string;
  ttl?: string;
  fetchFunctionRequestParams?: VaultConnectionAPIConfiguration;
  subType?: CredentialType;
  credentialFields?: CredentialsFieldsEntity;
}

export interface CredentialsFieldsEntity {
  username?: string;
  password?: string;
  password_enc?: string;
  content_enc?: string;
  authentication_key_enc?: string;
  accountName?: string;
}

export type CredentialGridRow = Omit<CredentialItemResponse, 'created_at' | '_id'> & {
  id: string | number;
  isPending?: boolean;
};

export interface TPAItemResponse {
  _id: string;
  base_url: string;
  bigidBaseUrl: string;
  can_get_ds_credentials: boolean;
  category: string;
  created_at: string;
  description: string;
  friendlyName: string;
  global_params?: TPAGlobalParamsEntity[];
  installation_details: TPAInstallationDetails;
  is_interactive: boolean;
  is_use_proxy: boolean;
  license_type: string;
  license_verification_key: string;
  logo: string;
  side_bar_icon: string;
  status: string;
  tpa_name: string;
  ui_tab_name?: null;
  ui_url: string;
  updated_at: string;
  vendor: string;
  version: string;
  uiTabName: string;
}
export interface TPAGlobalParamsEntity {
  param_name: string;
  param_type: string;
  is_cleartext: boolean;
  param_description: string;
  default_value: string;
  param_priority: string;
  is_mandatory: boolean;
  value: string;
}
export interface TPAInstallationDetails {
  installationType: string;
}

export interface ScopesItemResponse {
  updated_at?: string;
  created_at?: string;
  dataSourceNames?: string[];
  idSourceNames?: string[];
  description?: string;
  name: string;
  containedScopes?: string[];
  id: string;
}

export interface CredentialsPermissions {
  isDeletePermitted: boolean;
  isEditPermitted: boolean;
  isCreatePermitted: boolean;
  isTestConnectionPermitted: boolean;
}

export interface ForwardFormRef {
  fieldsToRender: string[];
  formElement: HTMLDivElement;
}
