import React, { FC } from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { CurationStatus } from './curationService';
import { useLocalTranslation } from './translations';
import { getReviewedEntitiesProgressIcon } from './curationUtils';

export interface ReviewedEntitiesProgressProps {
  curationStatus: CurationStatus;
  amountOfReviewedObjects?: number;
  curatedEntityName: string;
  translation: string;
}

const ReviewedEntitiesProgressContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(BigidBody1)`
  padding-right: 5px;
  padding-top: 5px;
`;

export const ReviewedEntitiesProgress: FC<ReviewedEntitiesProgressProps> = ({
  curationStatus,
  amountOfReviewedObjects,
  curatedEntityName,
  translation,
}) => {
  const { t } = useLocalTranslation(translation);
  const { curatedCount = 0, totalCount = 0 } = curationStatus || {};

  return (
    <ReviewedEntitiesProgressContainer>
      <IconWrapper>{getReviewedEntitiesProgressIcon(curatedCount, totalCount)}</IconWrapper>
      <BigidBody1 data-aid={generateDataAid('reviewed-progress', [])}>
        {amountOfReviewedObjects || curatedCount}/{totalCount} {curatedEntityName} {t('reviewed')}
      </BigidBody1>
    </ReviewedEntitiesProgressContainer>
  );
};
