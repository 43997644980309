import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading3, BigidBody3 } from '@bigid-ui/components';
import FmsdPendingResults from '../../../../../assets/icons/FmsdPendingResults.svg';
import { useLocalTranslation } from '../../../translations';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    paddingTop: 24,
  },
});

export const PendingResults: FC = () => {
  const { wrapper, title } = useStyles({});
  const { t } = useLocalTranslation('Discover.PendingResults');

  return (
    <div className={wrapper}>
      <FmsdPendingResults />
      <BigidHeading3 className={title}>{t('title')}</BigidHeading3>
      <BigidBody3>{t('subTitle')}</BigidBody3>
    </div>
  );
};
