import React, { FC, useState, MouseEvent, useRef } from 'react';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { Popover, Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading4, BigidLink } from '@bigid-ui/components';
import { ExecutionData } from './PresetLatestExecutionStatus';
import { downloadFileByAttachmentId } from '../../views/ActivityLog/ActivityLog';
import { $state, $stateParams } from './../../../../services/angularServices';
import { CustomAppPagesEnum } from '../../utils/CustomAppTypes';
import { CONFIG } from '../../../../../config/common';

interface PresetExecutionInfoPopOverProps {
  latestExecution: ExecutionData;
}

const useStyles = makeStyles({
  popoverRoot: {
    padding: '16px 24px',
    minHeight: '150px',
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
    wordWrap: 'break-word',
  },
  attachmentFileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export const PresetExecutionInfoPopOver: FC<PresetExecutionInfoPopOverProps> = ({ latestExecution }) => {
  const { createdAt, duration, info, attachments } = latestExecution;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<SVGElement>(null);
  const container = useRef();
  const tpaId = $stateParams.id;
  const handleOnMouseEnter = (e: MouseEvent<SVGElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleOnCloseInfo = () => {
    setAnchorEl(null);
  };
  const handleViewActivityLogClick = () => {
    $state.go(CONFIG.states.CUSTOM_APP_ACTIVITY_LOG, { id: tpaId }, { reload: true });
  };

  const handleAttachmentClicked = (id: string) => {
    downloadFileByAttachmentId(id);
  };

  return (
    <>
      <BigidInfoIcon onMouseEnter={handleOnMouseEnter} />
      <Popover
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        onClose={handleOnCloseInfo}
        container={container.current}
      >
        <Card className={classes.popoverRoot} onMouseLeave={handleOnCloseInfo}>
          <div>
            <BigidHeading4>Start time:</BigidHeading4>
            {createdAt}
          </div>
          <div>
            <BigidHeading4>Duration:</BigidHeading4>
            {duration}
          </div>
          {attachments.length > 0 && (
            <div>
              <BigidHeading4>Attachments:</BigidHeading4>
              {attachments.map((attachment, i) => (
                <div key={i} className={classes.attachmentFileName}>
                  <BigidLink
                    title={attachment?.filename}
                    text={attachment?.filename}
                    onClick={() => handleAttachmentClicked(attachment.id)}
                  />
                </div>
              ))}
            </div>
          )}
          {info && (
            <div>
              <BigidHeading4>Info:</BigidHeading4>
              {info}
            </div>
          )}
          <BigidLink onClick={handleViewActivityLogClick} text={'View activity log'} />
        </Card>
      </Popover>
    </>
  );
};
