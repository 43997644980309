import { noop } from 'lodash';
import { generateDataAid } from '@bigid-ui/utils';
import { openSystemDialog } from '../../../../../../services/systemDialogService';
import {
  CuratedFieldValuePreviewProps,
  CuratedFieldValuePreview,
} from '../../../CuratedFieldValuePreview/CuratedFieldValuePreview';

export type OpenFieldValuePreviewDialogProps = Omit<CuratedFieldValuePreviewProps, 'dataAid'> & { fieldName: string };

export async function openFieldValuePreviewDialog({
  fieldName,
  valueChunks,
  onFindingCurate,
  isPermittedToExclude,
}: OpenFieldValuePreviewDialogProps) {
  openSystemDialog({
    borderTop: true,
    borderBottom: true,
    withCloseButton: true,
    title: `Curate findings for '${fieldName}' field`,
    onClose: noop,
    content: CuratedFieldValuePreview,
    contentProps: {
      dataAid: generateDataAid('CuratedFieldValuePreview', ['dialog', fieldName]),
      valueChunks,
      onFindingCurate,
      isPermittedToExclude,
    },
    maxWidth: 'lg',
  });
}
