import { ClassificationStepTypes, BulkClassifierSelectionGridRow } from './classificationStepTypes';
import React, { useMemo } from 'react';
import { BigidPolicyIcon } from '@bigid-ui/icons';
import {
  BigidCaption,
  BigidChip,
  BigidColorsV2,
  BigidSwitch,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import { NormalizedClassifier } from '../../../../../Classification/ClassifiersService';
import { intersection, startCase, uniqBy } from 'lodash';
import { BulkClassifierSelectionMethod, Classifier } from '../../../scanTemplateTypes';
import { openManualClassifierSelection } from './ClassificationManualSelectionGrid';
import {
  convertClassifierToScanClassifier,
  getClassifierIds,
  onSelectBulkClassifiers,
  removeBulkSelectionByName,
} from './classificationService';
import { generateDataAid } from '@bigid-ui/utils';

const NAME_LENGTH_TO_SHORTEN = 40;
const PARTIAL_LABEL = 'Partial';

const HeaderFlex = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AlignedBadge = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
});

const TitleWithButton = styled.div({
  paddingTop: 10,
  display: 'flex',
  alignItems: 'center',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
});

interface ClassificationStepCardHeaderProps {
  bulkSelectionData: BulkClassifierSelectionGridRow;
  classificationStepContext?: ClassificationStepTypes;
}

let manuallySelectedClassifiers: Classifier[] = [];

export const ClassificationStepCardHeader: React.FC<ClassificationStepCardHeaderProps> = ({
  bulkSelectionData,
  classificationStepContext,
}) => {
  const { setScanTemplateFormData, scanTemplateFormData } = classificationStepContext;
  const { _id, name, selectedClassifiers: classifiersFromBulkSelection } = bulkSelectionData;
  const { bulkClassifierSelection = [], classifiers = [] } = scanTemplateFormData;
  const isCategoryView = scanTemplateFormData.bulkClassifierSelectionMethod === BulkClassifierSelectionMethod.CATEGORY;

  const currentSelectedBulkClassifiers = bulkClassifierSelection.find(
    ({ name: regulationName }) => regulationName === name,
  );

  const isBulkClassifiersSelected = currentSelectedBulkClassifiers?.selectedClassifiers.length > 0;

  const isClassifiersPartiallySelection =
    intersection(
      currentSelectedBulkClassifiers?.selectedClassifiers,
      getClassifierIds(classifiers).filter(classifierName => classifiersFromBulkSelection.includes(classifierName)),
    ).length < classifiersFromBulkSelection?.length;

  const isPartialSelection = isBulkClassifiersSelected && isClassifiersPartiallySelection;

  return (
    <HeaderFlex>
      <AlignedBadge>
        <BigidPolicyIcon staticMode={true} />
        {isPartialSelection && (
          <BigidChip
            label={PARTIAL_LABEL}
            height={16}
            width={'auto'}
            bgColor={BigidColorsV2.purple[400]}
            color={BigidColorsV2.white}
            fontSize={'0.75rem'}
            dataAid={generateDataAid('bulkSelectCardPartialIndicator', [_id])}
          />
        )}
      </AlignedBadge>
      <BigidSwitch
        dataAid={generateDataAid('bulkSelectCardSwitch', [_id, isBulkClassifiersSelected])}
        value={isBulkClassifiersSelected}
        checked={isBulkClassifiersSelected}
        onChange={() =>
          onSelectBulkClassifiers({
            isPartialSelection,
            isBulkClassifiersSelected: isBulkClassifiersSelected,
            bulkSelectionData: bulkSelectionData,
            classifiersFromBulkSelection: classifiersFromBulkSelection,
            setScanTemplateFormData,
            isCategoryView,
          })
        }
      />
    </HeaderFlex>
  );
};

export const ClassificationStepContent: React.FC<ClassificationStepCardHeaderProps> = ({ bulkSelectionData }) => {
  const { name } = bulkSelectionData;
  const isNameShouldBeShortened = name.length > NAME_LENGTH_TO_SHORTEN;
  const shortenedName = isNameShouldBeShortened
    ? name.substring(0, NAME_LENGTH_TO_SHORTEN - 3).concat('...')
    : startCase(name);
  return (
    <BigidTooltip title={name} isDisabled={!isNameShouldBeShortened}>
      <TitleWithButton>
        <BigidCaption>{shortenedName}</BigidCaption>
      </TitleWithButton>
    </BigidTooltip>
  );
};

export const ClassificationStepFooter: React.FC<ClassificationStepCardHeaderProps> = ({
  bulkSelectionData,
  classificationStepContext,
}) => {
  const { scanTemplateFormData, setScanTemplateFormData } = classificationStepContext;
  const { selectedClassifiers, name, _id, id } = bulkSelectionData;

  const setManuallySelectedClassifiers = (classifiers: NormalizedClassifier[]): void => {
    manuallySelectedClassifiers = convertClassifierToScanClassifier(classifiers);
  };

  const buttons = useMemo(
    () => [
      {
        text: 'Save',
        component: PrimaryButton,
        onClick: () => {
          setScanTemplateFormData(({ classifiers = [], bulkClassifierSelection = [], ...rest }) => {
            const manuallySelectedClassifierIds = getClassifierIds(manuallySelectedClassifiers);
            const classifiersSelectedInRegulation = classifiers.filter(
              ({ name }) => !selectedClassifiers.includes(name),
            );
            removeBulkSelectionByName(bulkClassifierSelection, name);

            const regulationToAdd =
              manuallySelectedClassifierIds.length > 0
                ? [
                    {
                      name,
                      _id,
                      id,
                      selectedClassifiers: manuallySelectedClassifierIds,
                      isPartial: classifiersSelectedInRegulation.length < selectedClassifiers.length,
                    },
                  ]
                : [];

            return {
              ...rest,
              classifiers: uniqBy([...classifiersSelectedInRegulation, ...manuallySelectedClassifiers], 'name'),
              bulkClassifierSelection: [...bulkClassifierSelection, ...regulationToAdd],
            };
          });
          manuallySelectedClassifiers = [];
        },
        dataAid: 'saveClassifierSelectionButton',
        isClose: true,
      },
    ],
    [_id, id, name, selectedClassifiers, setScanTemplateFormData],
  );

  return (
    <ButtonWrapper>
      <SecondaryButton
        onClick={async () =>
          openManualClassifierSelection({
            buttons,
            isBulkSelection: true,
            bulkSelectedClassifiers: bulkSelectionData,
            setManuallySelectedClassifiers,
            scanTemplateContext: { setScanTemplateFormData, scanTemplateFormData },
          })
        }
        text={'View Classifiers'}
        size={'medium'}
        width={'fill'}
        dataAid={generateDataAid('bulkSelectCardViewClassifiersButton', [_id])}
      />
    </ButtonWrapper>
  );
};
