import { connectOauth, disconnectOauth, connectTrayOauth } from '../actions/oauthActions';
import { connectGenericCloudOauth } from '../actions/genericCloudOauth';
import { DataSourceConfigurationContextInterface } from '../DataSourceConfigurationContext';
import { configureHadoopFieldsFromSource } from '../actions/hadoopConfiguration';

export const ACTION_SOURCE_TO_FUNCTION: Record<
  string,
  (value: any, stateAndHandlers: DataSourceConfigurationContextInterface) => void
> = {
  configureHadoopFieldsFromSource,
  disconnectOauth,
  connectOauth,
  connectTrayOauth,
  connectGenericCloudOauth,
};
