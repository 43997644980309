import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  BigidGridQueryComponents,
  BigidGridWithToolbarProps,
  BigidGridWithToolbar,
  BigidGridSorting,
} from '@bigid-ui/grid';
import { queryService } from '../../../../../services/queryService';
import { selectDataSourceGridColumns, getSelectDataSourceFilters } from './selectDataourceGridConfiguration';
import { DataSourceModel } from '../../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { useSupportedDataSources } from '../../../../DataSources/DataSourceConnections/hooks/useSupportedDataSources';
import { CreateScanWizardContext } from '../../createScanContext';
import { addDataSourceNameToTypeMap } from '../../hooks/useUpdateSupportedScanTypesBySelectedDs';
import { DEFAULT_MAX_DS } from '../../../../../utilities/dataSourcesUtils';
import { getDataSources, getDataSourcesFilteredByNames } from '../../../../../services/dataSourcesService';
import {
  addArchiveFilter,
  FILTER_ARCHIVED,
} from '../../../../DataSources/DataSourceConnections/DataSourceScanSelectedButton';
import { useIsSelectedDsDisabled } from '../../hooks/useIsSelectedDsDisabled';
import styled from '@emotion/styled';
import {
  BigidIconType,
  BigidRadioGroup,
  BigidRadioProps,
  BigidTooltip,
  BigidLoader,
  entityEventsEmitter,
  EntityEvents,
  ToolbarActionType,
  BigidFieldFilterOperator,
} from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { CollectionsOutlined } from '@mui/icons-material';

const DEFAULT_SORTING: BigidGridSorting[] = [{ field: 'name', order: 'asc' }];

const GridWrapper = styled('div')<{ allEnabledDs: boolean }>(
  { overflowX: 'hidden', flexGrow: 1 },
  ({ allEnabledDs }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    gap: 8,
    width: '100%',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    ...(allEnabledDs
      ? {
          overflowY: 'hidden',
          pointerEvents: 'none',
          opacity: 0.3,
          '.TableContainer-root': {
            overflow: 'hidden',
          },
        }
      : { overflowY: 'auto' }),
  }),
);

const SelectDatasourceStepWrapper = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  gap: 8,
});

const RadioLabelStyled = styled('div')<{ dataAid?: string }>({ display: 'flex', alignItems: 'center' });
const IconWrapper = styled('div')<{ dataAid?: string }>({ display: 'flex', marginLeft: 4 });

enum DS_SELECTION_VALUES {
  SELECT = 'select',
  ALL_ENABLED = 'allEnabled',
}

interface RadioLabelProps {
  label: string;
  tooltip?: string;
  icon?: BigidIconType;
  dataAid?: string;
}

const RadioLabel: FC<RadioLabelProps> = ({ label, tooltip, icon: Icon, dataAid }) => {
  return (
    <RadioLabelStyled dataAid={generateDataAid(dataAid, ['Wrapper'])}>
      {label}
      {Icon && (
        <BigidTooltip isDisabled={!tooltip} title={tooltip}>
          <IconWrapper dataAid={generateDataAid(dataAid, ['Icon'])}>
            <Icon />
          </IconWrapper>
        </BigidTooltip>
      )}
    </RadioLabelStyled>
  );
};

const correlationSelectionOptions: BigidRadioProps[] = [
  {
    label: <RadioLabel label="Select data sources" />,
    value: DS_SELECTION_VALUES.SELECT,
    dataAid: generateDataAid('BigidRadioButton', [DS_SELECTION_VALUES.SELECT]),
  },
  {
    label: (
      <RadioLabel
        label="All enabled data sources"
        icon={BigidInfoIcon}
        tooltip="Only data sources that fit the scan type set in the selected template (next step) will be scanned."
      />
    ),
    value: DS_SELECTION_VALUES.ALL_ENABLED,
    dataAid: generateDataAid('BigidRadioButton', [DS_SELECTION_VALUES.ALL_ENABLED]),
  },
];

export const SelectDataSourceStep: FC = () => {
  const { dataSourcesTypeMapping } = useSupportedDataSources();
  const { scanWizardState, setScanWizardState } = useContext(CreateScanWizardContext);
  const [isShowSelectedToggleActive, setIsShowSelectedToggleActive] = useState(
    !!scanWizardState?.isShowSelectedToggleActive,
  );

  const [toolbarConfig, setToolbarConfig] =
    useState<BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig']>();
  const selectedRowIdsRef = useRef(
    scanWizardState.scanId
      ? scanWizardState.dataSourcesIds
      : scanWizardState.dataSourcesPreselected?.map(({ name }) => name) || [],
  );

  const { checkSelectedDsForDisabled, isSelectedDsDisabled } = useIsSelectedDsDisabled();
  const [dsTotalCount, setDsTotalCount] = useState<number>(0);
  const [isAllSelectedLoading, setIsAllSelectedLoading] = useState<boolean>(false);
  useEffect(() => {
    setScanWizardState(state => ({ ...state, isDisabledDataSourcesSelected: isSelectedDsDisabled }));
  }, [isSelectedDsDisabled, setScanWizardState]);

  const config: BigidGridWithToolbarProps<DataSourceModel> = useMemo(
    () => ({
      pageSize: DEFAULT_MAX_DS,
      customRowIdName: 'name',
      entityName: 'Data Sources',
      defaultSorting: DEFAULT_SORTING,
      showSelectionColumn: true,
      showSelectAll: true,
      showSelectionCheckboxes: true,
      showSortingControls: true,
      shouldKeepSelectionAfterFilter: true,
      hideColumnChooser: true,
      showSelectAllColumnChooser: false,
      filterToolbarConfig: toolbarConfig,
      selectedRowIds: selectedRowIdsRef.current,
      preSelectedIds: scanWizardState.isPrefillDsStep
        ? scanWizardState.dataSourcesPreselected?.map(({ name }) => name) || []
        : scanWizardState.dataSourcesIds,
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let data: DataSourceModel[] = [];
        let totalCount = 0;

        try {
          const selectedFilter = isShowSelectedToggleActive
            ? [{ field: 'name', operator: 'in' as BigidFieldFilterOperator, value: selectedRowIdsRef.current }]
            : [];
          const query = {
            ...queryComponents,
            filter: [...queryComponents.filter, FILTER_ARCHIVED, ...selectedFilter],
          };

          const gridConfigQuery = queryService.getGridConfigQuery({ ...query });

          const { dsConnections, count } = await getDataSources(gridConfigQuery);
          let fetchedSelectedDataSources: any[] = [];
          if (scanWizardState.dataSourcesIds.length > 0) {
            const dsNames = dsConnections.map(({ name }) => name);
            const resultSelectedWithNoData = scanWizardState.dataSourcesIds.filter(id => !dsNames.includes(id));
            if (resultSelectedWithNoData.length > 0) {
              fetchedSelectedDataSources = await getDataSourcesFilteredByNames(resultSelectedWithNoData);
            }
          }
          data = dsConnections.map(ds => ({ ...ds, type: dataSourcesTypeMapping.get(ds.type) || ds.type }));
          const dataSourceNameToTypeMap = [...dsConnections, ...fetchedSelectedDataSources]?.reduce(
            addDataSourceNameToTypeMap,
            {},
          );
          setScanWizardState(state => ({
            ...state,
            dataSourceNameToTypeMap: {
              ...state.dataSourceNameToTypeMap,
              ...dataSourceNameToTypeMap,
            },
          }));
          totalCount = count;
          setDsTotalCount(totalCount);
          return {
            data,
            totalCount,
          };
        } catch (e) {
          console.error(e);
          return {
            data: [],
            totalCount: 0,
          };
        }
      },
      columns: selectDataSourceGridColumns,
      onGridStateChange: async nextGridState => {
        let dataSourcesIds: string[];
        let dataSourceNameToTypeMap: Record<string, string>;
        if (nextGridState.allSelected && !isShowSelectedToggleActive) {
          setIsAllSelectedLoading(true);
          const dsFilterQuery = queryService.getGridConfigQuery({
            filter: addArchiveFilter(nextGridState.filter),
            limit: dsTotalCount,
            skip: 0,
          });
          const { dsConnections } = await getDataSources(dsFilterQuery);
          checkSelectedDsForDisabled(dsConnections);
          dataSourceNameToTypeMap = dsConnections?.reduce(addDataSourceNameToTypeMap, {});

          dataSourcesIds = dsConnections.map(({ name }) => name);
          setIsAllSelectedLoading(false);
        } else {
          dataSourcesIds = nextGridState.selectedRowIds as string[];
          checkSelectedDsForDisabled(nextGridState.selectedRows as DataSourceModel[]);
        }
        setScanWizardState(state => {
          const isSelectedDsChanged = selectedRowIdsRef.current?.length !== dataSourcesIds.length;
          const isGridReady = nextGridState.sort.length;
          if (isGridReady && isSelectedDsChanged) {
            selectedRowIdsRef.current = dataSourcesIds;

            return {
              ...state,
              templateIds: [],
              dataSourceNameToTypeMap: {
                ...state.dataSourceNameToTypeMap,
                ...dataSourceNameToTypeMap,
              },
              dataSourcesIds,
            };
          }
          return state;
        });
      },
      toolbarActions: [
        {
          label: '',
          value: '',
          show: () => false,
          execute: async () => ({ shouldGridReload: false }),
        },
        {
          label: 'show selected',
          execute: async _params => {
            setIsShowSelectedToggleActive(isActive => !isActive);
            entityEventsEmitter.emit(EntityEvents.RELOAD);
            return {};
          },
          disable: () => false,
          show: () => true,
          placement: 'end',
          type: ToolbarActionType.SWITCH,
          switchProps: {
            isChecked: _actionData => {
              return isShowSelectedToggleActive;
            },
          },
        },
      ],
    }),
    [
      checkSelectedDsForDisabled,
      dataSourcesTypeMapping,
      dsTotalCount,
      scanWizardState.dataSourcesIds,
      setScanWizardState,
      toolbarConfig,
      isShowSelectedToggleActive,
    ],
  );

  useEffect(() => {
    const loadSelectDataSourcesFilters = async () => {
      const filtersAndSearchSettings = await getSelectDataSourceFilters();
      setToolbarConfig(filtersAndSearchSettings);
    };
    loadSelectDataSourcesFilters();
  }, []);

  const handleRadioButtonChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const allEnabledDs = target.value === DS_SELECTION_VALUES.ALL_ENABLED;
    setScanWizardState({ ...scanWizardState, allEnabledDs, dataSourcesIds: [] });
  };

  return (
    <SelectDatasourceStepWrapper>
      <BigidRadioGroup
        defaultValue={scanWizardState.allEnabledDs ? DS_SELECTION_VALUES.ALL_ENABLED : DS_SELECTION_VALUES.SELECT}
        options={correlationSelectionOptions}
        onChange={handleRadioButtonChange}
        horizontal
      />
      <GridWrapper data-aid={'DataSourceSelectionGridWrapper'} allEnabledDs={scanWizardState.allEnabledDs}>
        {isAllSelectedLoading && <BigidLoader />}
        <BigidGridWithToolbar {...config} />
      </GridWrapper>
    </SelectDatasourceStepWrapper>
  );
};
