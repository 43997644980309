import React, { FC } from 'react';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import styled from '@emotion/styled';

const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  alignItems: 'center',
  height: '70%',
});

export const CuratedFieldValuePreviewLoading: FC = () => {
  const { t } = useLocalTranslation('CuratedFields');

  return (
    <LoadingWrapper>
      <BigidLoader />
      <BigidBody1>{t('loading')}</BigidBody1>
    </LoadingWrapper>
  );
};
