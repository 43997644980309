import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidCaption,
  BigidColors,
  BigidColorsV2,
  BigidPaper,
  BigidQueryBuilder,
  BigidRadioGroup,
  BigidTextField,
  QueryComposerOperandConfig,
  QueryLogicalOperatorMap,
  QueryTree,
  TertiaryButton,
} from '@bigid-ui/components';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';
import { getSystemAttributes } from '../DataCatalog/DataCatalogService';
import classNames from 'classnames';
import { SensitivityClassificationsContext } from './SensitivityClassificationsContext';
import { ClassificationLevel } from './SensitivityClassification';
import { showConfirmationDialog } from '../../services/confirmationDialogService';
import { BigidCopyIcon, BigidWarningIcon } from '@bigid-ui/icons';
import { notificationService } from '../../services/notificationService';
import { getApplicationPreference } from '../../services/appPreferencesService';

export interface QuerySelectorProps {
  classificationLevel: ClassificationLevel;
  onQueryChange: (objectQuery: QueryTree, stringQuery: string) => void;
  onQueryTypeChange: (queryType: QueryType) => void;
  stringQuery: string;
  objectQuery?: QueryTree;
  isQueryTouched?: boolean;
}

const useStyles = makeStyles({
  paper: {
    backgroundColor: BigidColorsV2.purple[50],
    width: 'min-content',
    padding: '10px 10px 20px 10px',
  },
  notValid: {
    border: `1px solid ${BigidColors.red[700]}`,
  },
  errorMsg: {
    color: BigidColors.red[700],
    marginTop: '5px',
  },
  queryToolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  autocompleteWrapper: {
    width: 760,
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 5,
  },
  warningText: {
    marginLeft: 5,
  },
  toolbarButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
});

export enum QueryType {
  String = 'queryString',
  Object = 'queryObject',
}

const copyToClipboard = async (queryString: string) => {
  try {
    await navigator.clipboard.writeText(queryString);
    notificationService.success('Query copied to clipboard');
  } catch (e) {
    console.error(e);
  }
};

export const QuerySelector: FC<QuerySelectorProps> = ({
  objectQuery,
  stringQuery,
  onQueryChange,
  classificationLevel,
  isQueryTouched,
  onQueryTypeChange,
}) => {
  const classes = useStyles({});
  const [queryType, setQueryType] = useState<QueryType>(QueryType.Object);
  const { errorState, sc } = useContext(SensitivityClassificationsContext);
  const { emptyQuery, notValidQuery, containsScQuery } = errorState?.classifications?.[classificationLevel.id] || {};
  const isSensitivityV2: boolean = getApplicationPreference('SENSITIVITY_CLASSIFICATION_V2_ENABLED');

  const operands: QueryComposerOperandConfig[] = useMemo(
    () => [
      {
        leftOperand: {
          label: 'Attribute',
          value: 'attribute',
        },
        fetchRightOperands: () => {
          return new Promise(async (resolve, reject) => {
            try {
              const { attributesList } = await getSystemAttributes('');

              const rightOperandAttributesList = attributesList.map(one => {
                return {
                  label: one.attribute_name,
                  subLabel: one.attribute_type,
                  value: one.attribute_name,
                };
              });
              resolve(rightOperandAttributesList);
            } catch (e) {
              console.error('An error has occurred while fetching');
              reject();
            }
          });
        },
      },
    ],
    [],
  );

  const handleChangeQueryBuilder = (query: any) => {
    onQueryChange(query, bigidQueryObjectSerialization.parseGraphQueryNodes(query));
  };

  const handleQueryToolbarRadioChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onQueryTypeChange(target.value as QueryType);
    setQueryType(target.value as QueryType);
  };

  const handleQueryStringChange = async ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = target.value;
    if (newValue !== stringQuery) {
      let isEditConfirmed = true;
      if (objectQuery !== null) {
        isEditConfirmed = await showConfirmationDialog({
          entityNameSingular: 'Editing',
          actionName: 'Query String',
          actionButtonName: 'Continue',
          customDescription:
            'Editing the query will disable the query builder option, are you sure you want to continue?',
        });
      }
      if (isEditConfirmed) {
        onQueryChange(null, newValue);
      }
    }
  };

  return (
    <>
      <BigidPaper
        classes={{
          root: classNames(
            classes.paper,
            (emptyQuery || notValidQuery || containsScQuery) && isQueryTouched && classes.notValid,
          ),
        }}
      >
        <div className={classes.queryToolbar}>
          <BigidRadioGroup
            defaultValue={objectQuery ? QueryType.Object : QueryType.String}
            horizontal
            onChange={handleQueryToolbarRadioChange}
            options={[
              {
                disabled: objectQuery === null,
                label: 'Query Builder',
                value: QueryType.Object,
              },
              {
                disabled: !isSensitivityV2 && sc.columnTagging?.isActive,
                label: 'Input Query',
                value: QueryType.String,
              },
            ]}
          />
          <TertiaryButton
            onClick={() => copyToClipboard(stringQuery)}
            size={'small'}
            startIcon={<BigidCopyIcon />}
            text="Copy Query"
          />
        </div>
        {queryType === QueryType.Object && objectQuery ? (
          <BigidQueryBuilder
            onQueryChange={handleChangeQueryBuilder}
            initialQuery={objectQuery}
            operands={operands}
            logicalOperators={[QueryLogicalOperatorMap.AND, QueryLogicalOperatorMap.OR]}
          />
        ) : (
          <div className={classes.autocompleteWrapper}>
            <div className={classes.warningWrapper}>
              <BigidWarningIcon size="small" color="default" />
              <BigidCaption className={classes.warningText}>
                Editing this query syntax, will make reversible changes if you will wish to continue editing it via the
                query builder.
              </BigidCaption>
            </div>
            <BigidTextField
              dataAid={'string-query-field'}
              onChange={handleQueryStringChange}
              rows={5}
              multiline
              value={stringQuery}
            />
          </div>
        )}
      </BigidPaper>
      {(emptyQuery || notValidQuery) && isQueryTouched && (
        <BigidCaption className={classes.errorMsg}>Query is empty or not valid</BigidCaption>
      )}
      {containsScQuery && isQueryTouched && (
        <BigidCaption className={classes.errorMsg}>Query contains Sensitivity Classification tag</BigidCaption>
      )}
    </>
  );
};
