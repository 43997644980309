import React, { ChangeEvent, Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CustomAppParamsTable } from '../../components/CustomAppParamsTable/CustomAppParamsTable';
import { CustomAppAction, PresetPayload } from '../CustomAppActions/CustomAppActions';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidHeading4,
  BigidHeading5,
  BigidTextField,
} from '@bigid-ui/components';
import { isEmpty, noop, cloneDeep } from 'lodash';
import { CustomAppParam } from '../EditCustomApp/EditCustomApp';
import { BigidAdvanceSearchIcon, BigidChevronCircleDownIcon, BigidChevronCircleUpIcon } from '@bigid-ui/icons';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';

export interface EditActionPresetProps {
  action: CustomAppAction;
  globalParams: CustomAppParam[];
  preset: PresetPayload;
  onPresetChange?: (obj: Record<string, any>) => void;
  setIsNameValid?: Dispatch<SetStateAction<boolean>>;
  setIsParametersValid?: Dispatch<SetStateAction<boolean>>;
  setIsGlobalParamsValid?: Dispatch<SetStateAction<boolean>>;
  readOnly?: boolean;
  appName?: string;
}

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
  },
  title: {
    color: BigidColorsV2.purple[500],
    paddingBottom: '24px',
  },
  subTitle: {
    paddingBottom: '12px',
  },
  infoContent: {
    paddingBottom: '16px',
    width: '50%',
    minWidth: '500px',
  },
  mandatorySection: {
    paddingLeft: '2px',
    color: BigidColorsV2.red[600],
  },
  presetProperties: {
    width: '25%',
  },
  globalParamsToggleWrapper: {
    color: BigidColorsV2.blue[700],
    display: 'flex',
    paddingBottom: '16px',
  },
  titleSpacing: {
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  actionsParamsWrapper: {
    minHeight: '64px',
  },
});

interface InfoBoxProps {
  title: string;
  isMandatory?: boolean;
  children: ReactElement;
  childClass?: string;
}

const MANDATORY_ERROR_MESSAGE = 'Missing mandatory field';
const NAME_EXISTS_ERROR_MESSAGE = 'Name already in use';

const InfoBox: FC<InfoBoxProps> = ({ title, isMandatory = false, children, childClass }) => {
  const classes = useStyles({});
  return (
    <>
      <div className={classes.subTitle}>
        <BigidHeading4>
          {title}
          {isMandatory && <span className={classes.mandatorySection}>*</span>}
        </BigidHeading4>
      </div>
      <div className={`${classes.infoContent} ${childClass}`}>{children}</div>
    </>
  );
};

export const EditActionPreset: FC<EditActionPresetProps> = ({
  action,
  globalParams,
  preset,
  setIsNameValid = noop,
  setIsParametersValid = noop,
  setIsGlobalParamsValid = noop,
  onPresetChange = noop,
  readOnly = false,
  appName,
}) => {
  const [presetParams, setPresetParams] = useState<Record<string, string>>(preset.paramsKeyValue);
  const [presetGlobalParams, setPresetGlobalParams] = useState<Record<string, string>>(
    cloneDeep(preset.globalParamsKeyValue),
  );
  const [showGlobalParams, setShowGlobalParams] = useState<boolean>(!!preset.overrideGlobalParams);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const classes = useStyles();

  useEffect(() => setIsNameValid(!errorMessage), [errorMessage, setIsNameValid]);

  const validatePresetName = (name: string) => {
    if (isEmpty(name)) {
      setErrorMessage(MANDATORY_ERROR_MESSAGE);
      return;
    }

    const isNameExists = preset.name !== name && action.presets.some(preset => preset.name === name);
    setErrorMessage(isNameExists ? NAME_EXISTS_ERROR_MESSAGE : '');
  };

  const onTextInputFieldChanged =
    (key: string) =>
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (key === 'name') validatePresetName(value);
      onPresetChange({ [key]: value });
    };

  const onPresetParamsChange = (key: string, value: string) => {
    setPresetParams(state => ({ ...state, [key]: value }));
    onPresetChange({ paramsKeyValue: { ...presetParams, [key]: value } });
  };

  const onShowGlobalParams = () => {
    setShowGlobalParams(!showGlobalParams);
  };

  const onGlobalParamsChange = (key: string, value: string) => {
    setPresetGlobalParams(state => ({ ...state, [key]: value }));
    onPresetChange({ globalParamsKeyValue: { ...presetGlobalParams, [key]: value } });
  };

  const toggleGlobalParamValue = (paramKey: string, paramValue: string, enabled: boolean) => {
    if (enabled) {
      trackTPAEvent(TPATrackingEvents.TPA_PRESET_OVERRIDE_GLOBAL_PARAM_CLICK, {
        appName,
        actionName: action.name,
        presetName: preset.name,
      });
      onGlobalParamsChange(paramKey, paramValue);
    } else {
      const globalParamsAfterRemoval = cloneDeep(presetGlobalParams);
      delete globalParamsAfterRemoval[paramKey];
      setPresetGlobalParams(globalParamsAfterRemoval);
      onPresetChange({ globalParamsKeyValue: globalParamsAfterRemoval });
    }
  };

  return (
    <div className={classes.wrapper}>
      {!readOnly && (
        <>
          <div className={classes.title}>
            <BigidHeading5 color={'inherit'}>{`${action.friendlyName || action.name} - Edit Preset`}</BigidHeading5>
          </div>
          <InfoBox title={'Action Description'}>
            <BigidBody1>{action.description}</BigidBody1>
          </InfoBox>
          <InfoBox title={'Preset Name'} isMandatory childClass={classes.presetProperties}>
            <BigidTextField
              defaultValue={preset?.name}
              onChange={onTextInputFieldChanged('name')}
              errorMessage={errorMessage}
            />
          </InfoBox>

          <InfoBox title={'Preset Description'}>
            <BigidTextField
              id={'description'}
              multiline
              rows={2}
              defaultValue={preset?.description}
              onChange={onTextInputFieldChanged('description')}
            />
          </InfoBox>
        </>
      )}
      <div className={classes.actionsParamsWrapper}>
        {!!action.params?.length && (
          <InfoBox title={'Parameters'}>
            <CustomAppParamsTable
              params={action.params}
              onParamChange={onPresetParamsChange}
              setIsValuesValid={setIsParametersValid}
              values={presetParams}
              readOnly={readOnly}
            />
          </InfoBox>
        )}
      </div>
      {!!globalParams?.length && (
        <>
          <div className={classes.globalParamsToggleWrapper}>
            <BigidAdvanceSearchIcon />
            <BigidBody1 color={'inherit'} className={classes.titleSpacing}>
              Override Global Parameters
            </BigidBody1>
            <BigidButtonIcon
              icon={showGlobalParams ? BigidChevronCircleUpIcon : BigidChevronCircleDownIcon}
              onClick={onShowGlobalParams}
              dataAid="quick_view"
            />
          </div>

          {showGlobalParams && (
            <InfoBox title={'Global Parameters'}>
              <CustomAppParamsTable
                params={globalParams}
                setIsValuesValid={setIsGlobalParamsValid}
                onParamChange={onGlobalParamsChange}
                onParamToggle={toggleGlobalParamValue}
                values={presetGlobalParams}
                readOnly={readOnly}
                shouldEnableOverriding={true}
                isGlobalParams={true}
              />
            </InfoBox>
          )}
        </>
      )}
    </div>
  );
};
