import { BigidDropdownOption } from '@bigid-ui/components';
import { randDepartment, randSentence, randUuid, randEmail, randBoolean } from '@ngneat/falso';

export type OwnerDetails = {
  id: string;
  name: string;
  email: string;
};

export type DomainDetails = {
  id: string;
  name: string;
  description: string;
  parentDomainId?: string;
  businessOwners: OwnerDetails[];
  itOwners: OwnerDetails[];
  domainOwners: OwnerDetails[];
  hierarchy?: string[];
};

const generateUserDetails = (): OwnerDetails => ({
  id: randUuid(),
  name: randDepartment(),
  email: randEmail(),
});

export const fetchDomainDetails = async (domainId: string) => {
  return Promise.resolve<DomainDetails>({
    id: randUuid(),
    name: randDepartment(),
    description: randSentence(),
    parentDomainId: randUuid(),
    businessOwners: [generateUserDetails(), generateUserDetails(), generateUserDetails()],
    domainOwners: [generateUserDetails(), generateUserDetails(), generateUserDetails()],
    itOwners: [generateUserDetails(), generateUserDetails(), generateUserDetails(), generateUserDetails()],
    hierarchy: [randDepartment(), randDepartment(), randDepartment()],
  });
};

export const generateUserList = (count: number): OwnerDetails[] => {
  return Array.from({ length: count }, () => generateUserDetails());
};

export const fetchUsers = async () => {
  return Promise.resolve(generateUserList(10));
};

export const mapUsersToDropdownOption = (
  allUsers: OwnerDetails[],
  selectedUsers: OwnerDetails[],
): BigidDropdownOption[] => {
  return allUsers.map(user => ({
    value: user.name,
    displayValue: user.name,
    id: user.id,
    isSelected: selectedUsers.some(selectedUser => selectedUser.id === user.id),
  }));
};
