import React from 'react';
import { DataSourceConnectionGuideTab } from './DataSourceConnectionGuideTab';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { useLocalTranslation } from '../translations';
import { generateDataAid } from '@bigid-ui/utils';
import { isCollaborationEnabled } from '../utils/collaboration';
import type { DataSourceConnectionGuideTab as DataSourceConnectionGuideTabType } from './types';

type DataSourceConnectionGuideContentProps = {
  dataAid?: string;
  tabs: DataSourceConnectionGuideTabType[];
  onCollaborate: () => void;
};

const style = { marginTop: '1rem' };

export const DataSourceConnectionGuideContent = ({
  dataAid = 'DataSourceConnectionGuideContent',
  tabs,
  onCollaborate,
}: DataSourceConnectionGuideContentProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const { isDsCollaborator } = useDataSourceModalContext();
  const isCollaborationExpEnabled = isCollaborationEnabled();

  return (
    <>
      {tabs.map(({ id, ...rest }) => {
        return (
          <DataSourceConnectionGuideTab dataAid={generateDataAid(dataAid, ['tab', id])} key={id} id={id} {...rest} />
        );
      })}
      {!isDsCollaborator && isCollaborationExpEnabled && (
        <BigidBody1 style={style}>
          {t('collaboration.assistance.header')}
          <BigidLink
            dataAid={generateDataAid(dataAid, ['action', 'collaborate'])}
            underline="none"
            onClick={onCollaborate}
            text={t('collaboration.assistance.link')}
          />
        </BigidBody1>
      )}
    </>
  );
};
