import React, { FC, useState, useEffect } from 'react';
import { styled } from '@mui/material';
import { AUTO_DISCOVER_PREREQUISITES_CONTENT_ID, DiscoveryAuthTypes, CloudProvider } from '../../constants';
import { BigidBody1, BigidLink, BigidLoader } from '@bigid-ui/components';
import {
  AutoDiscoveryInfoList,
  AutoDiscoveryInfoListItem,
  AutoDiscoveryInfoListItemText,
} from '../AutoDiscoveryWizardMainModal/AutoDiscoveryComponents/autoDiscoverInfoStyledComponents';
import { PrerequisitesData, TYPE_TO_PREREQUISITES_CONTENT } from '../../constants/prerequisites';

export const AutoDiscoverWizardPrerequisitesMain = styled('div')`
  background-color: ${({ theme }) => theme.palette.bigid.gray150};
  max-width: 536px;
  min-width: 432px;
  padding: 8px 24px 0 16px;
  border-radius: 6px;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const AutoDiscoverPrerequisitesIntroduction = styled(BigidBody1)`
  white-space: break-spaces;
`;

export interface AutoDiscoverPrerequisitesContentProps {
  authType: DiscoveryAuthTypes;
  type?: CloudProvider;
}

export const AutoDiscoverPrerequisitesContent: FC<PrerequisitesData> = ({ introduction, content }) => {
  return (
    <>
      {introduction && <AutoDiscoverPrerequisitesIntroduction>{introduction}</AutoDiscoverPrerequisitesIntroduction>}
      <AutoDiscoveryInfoList>
        {content?.map((items, index) => (
          <AutoDiscoveryInfoListItem key={index}>
            {items?.map((item, i) => {
              return typeof item === 'string' ? (
                <AutoDiscoveryInfoListItemText key={i}>{item}</AutoDiscoveryInfoListItemText>
              ) : (
                <BigidLink key={i} underline="none" href={item.href} text={item.text} shouldOpenNewTab={true} />
              );
            })}
          </AutoDiscoveryInfoListItem>
        ))}
      </AutoDiscoveryInfoList>
    </>
  );
};

export const AutoDiscoveryPrerequisitesContent: FC<AutoDiscoverPrerequisitesContentProps> = ({ authType, type }) => {
  const [data, setData] = useState<PrerequisitesData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateData = async () => {
      const dataOfFn = TYPE_TO_PREREQUISITES_CONTENT[type][authType];
      if (typeof dataOfFn === 'function') {
        const data = await dataOfFn({});
        setData(data);
      } else {
        setData(dataOfFn);
      }
      setIsLoading(false);
    };
    updateData();
  }, [authType, type]);

  return (
    <AutoDiscoverWizardPrerequisitesMain
      data-aid="AutoDiscoveryPrerequisitesContent"
      id={AUTO_DISCOVER_PREREQUISITES_CONTENT_ID}
    >
      {isLoading && <BigidLoader />}
      {data ? <AutoDiscoverPrerequisitesContent {...data} /> : isLoading ? '' : 'TBD'}
    </AutoDiscoverWizardPrerequisitesMain>
  );
};
