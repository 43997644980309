import { get, isEmpty, uniq, isNumber, isString } from 'lodash';
import { httpService } from '../../services/httpService';
import { FetchItems } from './utils/CustomAppTypes';
import { notificationService } from '../../services/notificationService';
import { CustomAppParam } from './views/EditCustomApp/EditCustomApp';
const apisResponsesCache: Record<string, any> = {};

const fetchAndValidateUniqItemsFromPath = ({ pathToArray, pathToValue, api }: FetchItems): string[] => {
  const itemsFromPath: string[] = (get(apisResponsesCache[api], pathToArray) || [])
    .map((obj: any) => (pathToValue ? get(obj, pathToValue) : obj))
    .filter((val: any) => (isString(val) && !isEmpty(val)) || isNumber(val))
    .map((val: any) => val.toString());

  return uniq(itemsFromPath);
};

const fetchItemsFromApi = async (fetchItems: FetchItems): Promise<string[]> => {
  const { api } = fetchItems;
  try {
    if (isEmpty(apisResponsesCache[api])) {
      const { data } = await httpService.fetch(api.replace('api/v1/', ''));
      apisResponsesCache[api] = data;
    }
    return fetchAndValidateUniqItemsFromPath(fetchItems);
  } catch (err) {
    notificationService.error(`Problem fetching items from: ${api},
      Please change values in manifest
    `);
    return [];
  }
};

export const populateParamsIfFetchItemsExists = async (params: CustomAppParam[]): Promise<CustomAppParam[]> => {
  for (const param of params) {
    if (param.fetchItems) param.inputItems = await fetchItemsFromApi(param.fetchItems);
  }
  return params;
};

export const resetFetchItemsCache = (): void =>
  Object.keys(apisResponsesCache).forEach(api => {
    delete apisResponsesCache[api];
  });
