import React, { FC, useEffect, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { BigidLoader } from '@bigid-ui/components';
import { BigidDigestReport, getReportItems } from '@bigid-ui/grid';
import styles from './DigestReport.scss';
import { ReportSubscription, TrendsAvailability } from './DigestReportHeader/ReportSubscription';
import { activityHighlightsService } from '../../services/activityHighlightsService';
import { getOptionsForDigestReport, onReportItemClick } from './utils';
import { userPreferencesService } from '../../services/userPreferencesService';

export const PAGE_TITLE = 'Activity Highlights';

export interface Trend {
  dataSourcesScanned: number;
  objectsScanned: number;
  attributesShared: number;
  discoveredByCorrelation: number;
  discoveredByClassification: number;
  piRecordsWithOpenAccess: number;
  totalRisk: number;
  dataTransferredToThirdParty: number;
  policiesTriggered: number;
  [key: string]: number;
}

export interface TrendOptionItem {
  time: string;
  trends: Trend;
}

export interface TrendOptions {
  day: TrendOptionItem;
  week: TrendOptionItem;
  month: TrendOptionItem;
  [key: string]: TrendOptionItem;
}

const getInitialSelectedToggle = (trendsAvailability: TrendsAvailability): string => {
  if (trendsAvailability.day) {
    return 'day';
  }
  if (trendsAvailability.week) {
    return 'week';
  }
  if (trendsAvailability.month) {
    return 'month';
  }
  return 'day';
};

export const DigestReport: FC = ({}) => {
  const [digestReportData, setDigestReportData] = useState(null);
  const [trendOptions, setTrendOptions] = useState<TrendOptions>(null);
  const [selectedTrendOption, setSelectedTrendOption] = useState(null);
  const [initialSelectedTrend, setInitialSelectedTrend] = useState(null);

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: PAGE_TITLE });

    async function initialSetup() {
      const {
        data: {
          data: [activityHighlights],
        },
      } = await activityHighlightsService.getActivityHighlights();

      const { trends, highlights } = activityHighlights;
      setDigestReportData(highlights);
      setTrendOptions(trends);
      const initialTrend = getInitialSelectedToggle(trends);
      setSelectedTrendOption(initialTrend);
      setInitialSelectedTrend(initialTrend);
      userPreferencesService.setSharedBooleanUserPreference(
        userPreferencesService.SHARED_PREFERENCES.ACTIVITY_HIGHLIGHTS_REPORT_WAS_VISITED,
        true,
      );
    }
    initialSetup();
  }, []);

  if (!digestReportData || !trendOptions || !initialSelectedTrend) {
    return <BigidLoader />;
  }

  return (
    <div className={styles.reportWrapper}>
      <ReportSubscription
        selectedTrendOption={selectedTrendOption}
        onChangeSelectedTrendOption={(selectedTrendOption: string) => setSelectedTrendOption(selectedTrendOption)}
        trendsAvailability={{
          day: trendOptions?.day?.time,
          week: trendOptions?.week?.time,
          month: trendOptions?.month?.time,
        }}
        initialSelectedTrend={initialSelectedTrend}
      />
      <BigidDigestReport
        reportItems={getReportItems(
          digestReportData,
          trendOptions?.[selectedTrendOption]?.trends,
          getOptionsForDigestReport(),
          onReportItemClick,
        )}
      />
    </div>
  );
};
