import React, { FC, useEffect, useState, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidPaper, BigidLoader, BigidSecondaryMenu } from '@bigid-ui/components';
import { ActionWorkflowTop, topBarHeight } from './ActionWorkflowTop';
import { ActionWorkflowContent } from './ActionWorkflowContent/ActionWorkflowContent';
import { getCategories, getEventFilterFromCategory } from './actionWorkflowUtils';
import { WorkflowsTotal, ACCategory, ACEventGroup, WorkflowResponse } from './actionWorkflowTypes';
import { getWorkflows, getWorkflowsTotalByEvents } from './actionWorkflowService';
import { notificationService } from '../../../services/notificationService';
import { useFetch, UseFetchActions } from '@bigid-ui/grid';
import { $state, $stateParams } from '../../../services/angularServices';
import { ActionCenterMetadataContainer } from '../useActionCenterMetadata';
import { CONFIG } from '../../../../config/common';

const useStyles = makeStyles({
  root: { height: '100%', display: 'flex', flexDirection: 'column' },
  contentWrapper: { display: 'flex', height: `calc(100% - ${topBarHeight}px)` },
  loader: { height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
});

export type SelectedCategory = ACCategory | null;
export type SelectedSubCategory = ACEventGroup | null;

export const ActionWorkflow: FC = () => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const { root, contentWrapper, loader } = useStyles({});
  const [workflowsTotal, setWorkflowsTotal] = useState<WorkflowsTotal>();
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [workflowToEdit, setWorkflowToEdit] = useState<WorkflowResponse>();
  const [isCreateMode, setIsCreateMode] = useState(false);

  const { workflowId } = $stateParams;
  const isFormOpen = isCreateMode || !!workflowToEdit;

  const handleWorkFlowEdit = useCallback(
    (workflow?: WorkflowResponse) => {
      const { id: workflowId = null, event } = workflow || {};

      if (event) {
        const { category } =
          actionCenterMetadata.events.find(({ supportedEvents }) => supportedEvents.includes(event)) || {};

        if (category) {
          setSelectedCategory(category);
        }
      }

      $state.go(CONFIG.states.ACTION_CENTER_ACTIONS, { workflowId }).finally(() => {
        setWorkflowToEdit(workflow);
      });
    },
    [actionCenterMetadata.events],
  );

  const {
    onToolbarFiltersChanged,
    rows,
    dispatch,
    totalRowsCount,
    isCountFetched,
    isFetching,
    skip,
    onPagingChanged,
    onSortingChanged,
  } = useFetch({
    pageSize: 1000,
    initialSorting: [{ field: 'name', order: 'asc' }],
    fetchDataFunction: async ({ filter, ...queryComponents }) => {
      let data: WorkflowResponse[] = [];
      let totalCount = 0;

      try {
        const { workflows, totalCount: count } = await getWorkflows({
          ...queryComponents,
          filter: workflowId
            ? [{ field: '_id', operator: 'equal', value: workflowId }]
            : [
                ...getEventFilterFromCategory({ selectedCategory, selectedSubCategory, actionCenterMetadata }),
                ...filter,
              ],
        });

        data = workflows;
        totalCount = count;
      } catch (error) {
        console.error(error);
        notificationService.error('Could not fetch actions. See logs for more information');
      }
      return {
        data,
        totalCount,
      };
    },
  });

  const fetchWorkflows = useCallback(() => dispatch({ type: UseFetchActions.UPDATE_FILTERS, payload: {} }), [dispatch]);

  const onUpdateWorkflows = useCallback(async () => {
    $state.go(CONFIG.states.ACTION_CENTER_ACTIONS, { workflowId: null }).then(async () => {
      fetchWorkflows();
      await updateWorkflowsTotal();
    });
  }, [fetchWorkflows]);

  const handleCloseWorkflowForm = useCallback(() => {
    setIsCreateMode(false);
    handleWorkFlowEdit(undefined);
    const totalWorkflows = Object.values(workflowsTotal).reduce((count, current) => count + current, 0);

    if (totalWorkflows > totalRowsCount) {
      onUpdateWorkflows();
    }
  }, [handleWorkFlowEdit, onUpdateWorkflows, totalRowsCount, workflowsTotal]);

  const updateWorkflowsTotal = async () => {
    const { workflowsTotal } = await getWorkflowsTotalByEvents();
    setWorkflowsTotal(workflowsTotal);
  };

  useEffect(() => {
    const loadWorkflowsTotal = async () => {
      setIsLoading(true);
      try {
        await updateWorkflowsTotal();
      } catch (e) {
        console.error('error getting workflows count', e);
      } finally {
        setIsLoading(false);
      }
    };
    loadWorkflowsTotal();
  }, []);

  useEffect(() => {
    if (rows.length && workflowId) {
      const toEdit = rows.find(({ id }) => id === workflowId);

      if (toEdit) {
        handleWorkFlowEdit(toEdit as WorkflowResponse);
      }
    }
  }, [handleWorkFlowEdit, rows, workflowId]);

  const { categories, disabledCategories } = getCategories(actionCenterMetadata, workflowsTotal);
  const combinedMenuItems = categories.concat(disabledCategories);

  return (
    <BigidPaper classes={{ root }}>
      {isLoading ? (
        <div className={loader}>
          <BigidLoader position="relative" label="Loading actions..." />
        </div>
      ) : (
        <>
          <ActionWorkflowTop setIsCreateMode={setIsCreateMode} isFormOpen={isFormOpen} />
          <div className={contentWrapper}>
            <BigidSecondaryMenu
              menuItems={combinedMenuItems}
              selectedMenuItem={selectedCategory}
              setSelectedMenuItem={setSelectedCategory}
              selectedSubMenuItem={selectedSubCategory}
              setSelectedSubMenuItem={setSelectedSubCategory}
              itemClickHandler={fetchWorkflows}
              tooltip={'Select a category to view and edit actions'}
              zeroCountTooltip={'No workflows for this category'}
              disabledItemTooltip={'Coming soon'}
              dataAid={'ActionCenterCategory'}
            />
            <ActionWorkflowContent
              skip={skip}
              workflows={selectedCategory ? (rows as WorkflowResponse[]) : []}
              totalWorkflows={totalRowsCount}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              onFiltersChanged={onToolbarFiltersChanged}
              onUpdateWorkflows={onUpdateWorkflows}
              setWorkflowToEdit={handleWorkFlowEdit}
              isCreateMode={isCreateMode}
              workflowToEdit={workflowToEdit}
              onCloseWorkflowForm={handleCloseWorkflowForm}
              shouldUpdateCount={isCountFetched}
              isLoadingWorkflows={isFetching}
              onPagingChanged={onPagingChanged}
              onSortingChanged={onSortingChanged}
              isLoading={isLoading}
            />
          </div>
        </>
      )}
    </BigidPaper>
  );
};
