import { CloudProvider } from './types';
import { DiscoveryAuthTypes } from './auth';

export enum HowToContentOptionTypes {
  NumberedList = 'numberedList',
}

export interface HowToContentOption {
  type: HowToContentOptionTypes;
  content: string[] | string | Record<string, any>;
}

export const CLICK_ON_CONNECT_AND_DISCOVER = `Click on “Run" to start the data-source discovery process.`;
export const CLICK_ON_CHECK =
  'Click on “Check permissions" to verify connectivity to the cloud and permitted data sources types.';

export const AWS_IAM_ROLE_BASIC_HOW_TO = [
  `In the External IAM role ARN field, specify the ARN of the external role to assume.`,
];

export const AWS_MULTI_HOW_TO = [
  'In the AWS Config aggregator name* field, specify a name for the aggegator to use for data discovery.',
  'In the AWS Config aggregator region* field, specify where the aggregator is located.',
  'In the In the Scanner role basename* field, specify the role name for the scanner to assume  The ARN for each account will be computed dynamically.',
];

export const AWS_IAM_ROLE_MULTI_HOW_TO = [
  ...AWS_IAM_ROLE_BASIC_HOW_TO,
  ...AWS_MULTI_HOW_TO,
  CLICK_ON_CONNECT_AND_DISCOVER,
];

export const AWS_CREDENTIALS_BASIC_HOW_TO = [
  `In the Access Key ID* field, specify your AWS Identity and Access Management (IAM) credentials key.`,
  `In the Secret Access Key* field, specify your AWS IAM credentials secret key.`,
];

export const AWS_CREDENTIALS_MULTI_HOW_TO = [
  ...AWS_CREDENTIALS_BASIC_HOW_TO,
  ...AWS_MULTI_HOW_TO,
  CLICK_ON_CONNECT_AND_DISCOVER,
];

export const AZURE_CREDENTIALS_BASIC_HOW_TO = [
  `In the Client ID field, specify the OAuth client ID - the identifier that uniquely identifies an OAuth client consumer.`,
  `In the Client Secret field, specify the OAuth client secret - a shared secret that is required to exchange an OAuth Authorization Code for an OAuth token.`,
  `In the Tenant ID field, specify the directory identifier from Azure registration.`,
];

export const AZURE_CREDENTIALS_SECRETLESS = [
  'Enter the "Managed Identity Client ID" if uses User-Assigned Managed Identity with provided Client ID"  ',
];

export const AZURE_CREDENTIALS_MANAGEMENT_GROUPS = 'Get the value of the management group you want to discover';

export const TYPE_TO_HOW_TO_CONTENT: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: HowToContentOption;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.IAM_ROLE]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [...AWS_IAM_ROLE_BASIC_HOW_TO, CLICK_ON_CHECK, CLICK_ON_CONNECT_AND_DISCOVER],
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      type: HowToContentOptionTypes.NumberedList,
      content: AWS_IAM_ROLE_MULTI_HOW_TO,
    },
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [...AWS_CREDENTIALS_BASIC_HOW_TO, CLICK_ON_CHECK, CLICK_ON_CONNECT_AND_DISCOVER],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      type: HowToContentOptionTypes.NumberedList,
      content: AWS_CREDENTIALS_MULTI_HOW_TO,
    },
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [...AZURE_CREDENTIALS_BASIC_HOW_TO, CLICK_ON_CONNECT_AND_DISCOVER],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [...AZURE_CREDENTIALS_BASIC_HOW_TO, AZURE_CREDENTIALS_MANAGEMENT_GROUPS, CLICK_ON_CONNECT_AND_DISCOVER],
    },
    [DiscoveryAuthTypes.IAM_ROLE]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [AZURE_CREDENTIALS_SECRETLESS, CLICK_ON_CONNECT_AND_DISCOVER],
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [AZURE_CREDENTIALS_SECRETLESS, AZURE_CREDENTIALS_MANAGEMENT_GROUPS, CLICK_ON_CONNECT_AND_DISCOVER],
    },
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      type: HowToContentOptionTypes.NumberedList,
      content: [
        'In the Credentials* field, paste in your Google service account credentials.',
        CLICK_ON_CHECK,
        CLICK_ON_CONNECT_AND_DISCOVER,
      ],
    },
  },
};
