import React, { CSSProperties } from 'react';
import { Guide, Aside } from '../mappers/styles';
import { BigidBody1, BigidHeading6, BigidLink } from '@bigid-ui/components';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidExploreDocsIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';
import { isCollaborationEnabled } from '../utils/collaboration';
import { generateDataAid } from '@bigid-ui/utils';

type DataSourceConnectionGuideNoContentProps = {
  dataAid?: string;
  onShowDocumentation: () => void;
  onCollaborate: () => void;
};

type Position = CSSProperties['position'];

const style = { marginTop: '1rem', position: 'absolute' as Position, bottom: '20px' };

export const DataSourceConnectionGuideNoContent = ({
  dataAid = 'DataSourceConnectionGuideNoContent',
  onShowDocumentation,
  onCollaborate,
}: DataSourceConnectionGuideNoContentProps) => {
  const { t } = useLocalTranslation();
  const { isDsCollaborator } = useDataSourceModalContext();
  const isCollaborationExpEnabled = isCollaborationEnabled();

  return (
    <Guide.TabNoContent data-aid={dataAid}>
      <BigidLayoutEmptyState
        title={(<BigidHeading6>{t('connectionGuide.error')}</BigidHeading6>) as unknown as string}
        illustration={BigidExploreDocsIllustration}
        description={
          !isDsCollaborator &&
          ((
            <Aside.Description>
              <BigidLink onClick={onShowDocumentation} underline="none" text={t('connectionGuide.link')} />
            </Aside.Description>
          ) as unknown as string)
        }
      />
      {!isDsCollaborator && isCollaborationExpEnabled && (
        <BigidBody1 style={style}>
          {t('collaboration.assistance.header')}
          <BigidLink
            dataAid={generateDataAid(dataAid, ['action', 'collaborate'])}
            underline="none"
            onClick={onCollaborate}
            text={t('collaboration.assistance.link')}
          />
        </BigidBody1>
      )}
    </Guide.TabNoContent>
  );
};
