import React, { FC, useEffect, useState } from 'react';
import { BigidHeading4, BigidLoader, BigidBody2, BigidBody1 } from '@bigid-ui/components';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { getNewUISupportedApps } from '../../../services/autoDiscoveryService';
import { TYPE_TO_ICON, CloudProvider, cloudProviderLabel } from '../constants';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { notificationService } from '../../../services/notificationService';
import { DataSourceIconByDsType } from '@bigid-ui/icons';

const SelectDiscoveryTypeCard = styled('div')`
  border-radius: 4px;
  padding: 16px 8px 8px 8px;
  box-shadow: 0px 0px 5px 0px #00000026;
  display: flex;
  height: 225px;
  padding: 16px 8px 8px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  cursor: pointer;
`;

const SelectDiscoveryTypeModalContent = styled('div')`
  display: flex;
  gap: 24px;
  padding: 48px 16px 60px 16px;
  min-height: calc(100vh - 260px);
  flex-wrap: wrap;
  align-content: flex-start;
`;

const DiscoveryTypeLabel = styled(BigidHeading4)`
  margin-top: 16px;
`;

const DiscoveryTypeSubtitle = styled(BigidBody2)`
  margin-top: 16px;
`;

const DsTypeIconWrapper = styled('div')`
  position: relative;
  width: 32px;
  height: 32px;
  background-color: transparent;
  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d9d9d9;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  }
`;

const DsTypeIconInnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: relative;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  background-color: white;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
`;

const DsTypeIconsWrapper = styled('div')`
  display: flex;
  padding-top: 8px;
  gap: 4px;
`;

const Instruction = styled(BigidBody1)`
  width: 100%;
  text-align: center;
`;

export interface SelectDiscoveryTypeModalProps {
  openAutoDiscoveryModal: (type: CloudProvider, configData?: AutoDiscoveryConfigModel) => void;
}

const DS_TYPES_FOR_SHOW = {
  [CloudProvider.AWS]: ['s3', 'athena', 'dynamodb'],
  [CloudProvider.AZURE]: ['azure-blob', 'databricks', 'cassandra-v2'],
  [CloudProvider.GCP]: ['gcp-big-query', 'google-bigtable', 'datastore'],
};

export const SelectDiscoveryTypeModal: FC<SelectDiscoveryTypeModalProps> = ({ openAutoDiscoveryModal }) => {
  const [availableDiscoveryApps, setAvailableDiscoveryApps] = useState(null);
  useEffect(() => {
    const updateAvailableDiscoveryApps = async () => {
      try {
        const supportedAppsTypes = await getNewUISupportedApps();
        if (supportedAppsTypes.length === 1) {
          openAutoDiscoveryModal(supportedAppsTypes[0].type);
        }
        setAvailableDiscoveryApps(supportedAppsTypes.map(({ type }) => type));
      } catch (err) {
        notificationService.error('Failed to get list of applications');
      }
    };
    updateAvailableDiscoveryApps();
  }, [openAutoDiscoveryModal]);

  return (
    <SelectDiscoveryTypeModalContent>
      {!availableDiscoveryApps ? (
        <BigidLoader />
      ) : (
        <>
          <Instruction>Where are your data sources located? </Instruction>
          {availableDiscoveryApps.map((type: CloudProvider) => {
            const Icon = TYPE_TO_ICON[type];
            return (
              <SelectDiscoveryTypeCard
                data-aid={generateDataAid('SelectDiscoveryTypeCard', [type])}
                key={type}
                onClick={() => {
                  openAutoDiscoveryModal(type);
                }}
              >
                <Icon size={'xlarge'} />
                <DiscoveryTypeLabel>{cloudProviderLabel[type]}</DiscoveryTypeLabel>
                <DiscoveryTypeSubtitle>Including</DiscoveryTypeSubtitle>
                <DsTypeIconsWrapper>
                  {DS_TYPES_FOR_SHOW[type].map(dsType => (
                    <DsTypeIconWrapper key={dsType}>
                      <DsTypeIconInnerWrapper>
                        <DataSourceIconByDsType
                          data-aid={generateDataAid('ds-type-icon', [dsType])}
                          dataSourceType={dsType}
                          width={28}
                        />
                      </DsTypeIconInnerWrapper>
                    </DsTypeIconWrapper>
                  ))}
                </DsTypeIconsWrapper>
              </SelectDiscoveryTypeCard>
            );
          })}
        </>
      )}
    </SelectDiscoveryTypeModalContent>
  );
};
