import { TertiaryButton } from '@bigid-ui/components';
import { BigidAddTagIcon, BigidExportIcon, BigidRemoveTagIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { getDataExplorerSearchResultsCSV } from '../../../../explorerSearchResultsService';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { ShareUrl } from '../../../../ShareUrl';
import { useLocalTranslation } from '../../../../translations';
import { WithDataAid } from '../../../../types';
import { DataExplorerBulkTagAssignmentDialog } from './DataExplorerBulkTagAssignmentDialog';
import { TagAssignmentMode } from './actionsService';
import { ItemsCount } from '../components/ItemsCount';
import {
  sendDataExplorerResultsPageAddTagCloseBiAnalytics,
  sendDataExplorerResultsPageAddTagOpenBiAnalytics,
  sendDataExplorerResultsPageAddTagSubmitBiAnalytics,
  sendDataExplorerResultsPageRemoveTagCloseBiAnalytics,
  sendDataExplorerResultsPageRemoveTagOpenBiAnalytics,
  sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics,
  sendDataExplorerResultsPageExportSubmitBiAnalytics,
  sendDataExplorerResultsPageShareOpenBiAnalytics,
  sendDataExplorerResultsPageShareCopyBiAnalytics,
  sendDataExplorerResultsPageShareCloseBiAnalytics,
} from '../../../../../DataExplorer/services/analytics';
import { getActionFilterString } from './actionUtils';

const Container = styled('div')`
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.bigid.gray200};
`;

const CatalogActionsContainer = styled('div')`
  gap: 12px;
  display: flex;
`;

const Section = styled('span')`
  display: flex;
  gap: 12px;
  align-items: center;
`;

type BulkTagDialogConfig = {
  isOpen: boolean;
  type: TagAssignmentMode;
};

export const Actions: FC<WithDataAid> = ({ dataAid = 'Actions' }) => {
  const [bulkTagDialogConfig, setBulkTagDialogConfig] = useState<BulkTagDialogConfig>({
    isOpen: false,
    type: null,
  });
  const {
    state: { gridConfig, savedFiltersMap },
    entityType,
    query,
  } = useDataCatalogSearchResultsContext();
  const { selectedRowsIds, isSelectAll, totalCount, unselectedRowsIds } = gridConfig;
  const filter = getActionFilterString({
    filter: savedFiltersMap[entityType],
    isSelectAll,
    selectedRowsIds,
    unselectedRowsIds,
  });

  const { t } = useLocalTranslation('actions');

  const handleExportButtonClick = () => {
    getDataExplorerSearchResultsCSV({
      searchText: query,
      filter,
    });

    sendDataExplorerResultsPageExportSubmitBiAnalytics();
  };

  const handleAssignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'assign',
    });

    sendDataExplorerResultsPageAddTagOpenBiAnalytics();
  };

  const handleUnassignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'unassign',
    });

    sendDataExplorerResultsPageRemoveTagOpenBiAnalytics();
  };

  const areActionsHidden = selectedRowsIds.length === 0 && !isSelectAll;

  const handleBulkTagDialogClose = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagCloseBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagCloseBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleBulkTagDialogSubmit = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagSubmitBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleShareUrlToggled = (isToggled: boolean) => {
    if (isToggled) {
      sendDataExplorerResultsPageShareOpenBiAnalytics();
    }
  };

  const handleShareUrlCopy = () => {
    sendDataExplorerResultsPageShareCopyBiAnalytics();
  };

  const handleShareUrlClose = () => {
    sendDataExplorerResultsPageShareCloseBiAnalytics();
  };

  const actions = [
    <ShareUrl
      key="ShareUrl"
      onToggle={handleShareUrlToggled}
      onCopy={handleShareUrlCopy}
      onClose={handleShareUrlClose}
    />,
  ];

  if (entityType === 'catalog') {
    actions.push(
      <CatalogActionsContainer hidden={areActionsHidden} key="CatalogActionsContainer">
        <TertiaryButton
          startIcon={<BigidAddTagIcon />}
          dataAid={generateDataAid(dataAid, ['assignTag', 'button'])}
          size="medium"
          onClick={handleAssignTagButtonClick}
          text={t('tagAssignment.assign.title')}
          key="assignTag"
        />
        <TertiaryButton
          startIcon={<BigidRemoveTagIcon />}
          dataAid={generateDataAid(dataAid, ['removeTag', 'button'])}
          size="medium"
          onClick={handleUnassignTagButtonClick}
          text={t('tagAssignment.unassign.title')}
          key="removeTag"
        />
        <TertiaryButton
          startIcon={<BigidExportIcon />}
          dataAid={generateDataAid(dataAid, ['export', 'button'])}
          size="medium"
          text={t('export')}
          onClick={handleExportButtonClick}
          key="export"
        />
      </CatalogActionsContainer>,
    );
  }

  return (
    <Container>
      <Section>
        <ItemsCount totalCount={totalCount} />
        {actions}
      </Section>
      <DataExplorerBulkTagAssignmentDialog
        isOpen={bulkTagDialogConfig.isOpen}
        onClose={handleBulkTagDialogClose}
        onSubmit={handleBulkTagDialogSubmit}
        filter={filter}
        searchText={query}
        mode={bulkTagDialogConfig.type}
      />
    </Container>
  );
};
