import React, { FC, useState, useMemo } from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFancyTabs, BigidColors, DashboardCommonContainer, BigidBypassButton } from '@bigid-ui/components';
import {
  MAP_DASHBOARD_CHART_OPTIONS,
  RISK_DASHBOARD_CHART_OPTIONS,
  ATTRIBUTES_DASHBOARD_CHART_OPTIONS,
  POLICY_DASHBOARD_CHART_OPTIONS,
} from './dashboardRightSectionChartsContent';
import { isPermitted } from '../../../services/userPermissionsService';

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
    flexGrow: 1,
    margin: '0',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: '16px',
    fontSize: '16px',
    fontWeight: 600,
    color: BigidColors.gray[700],
  },
});

const TABS = [
  {
    label: 'Location',
    content: MAP_DASHBOARD_CHART_OPTIONS,
  },
  {
    label: 'Risk',
    content: RISK_DASHBOARD_CHART_OPTIONS,
  },
  {
    label: 'Attributes',
    content: ATTRIBUTES_DASHBOARD_CHART_OPTIONS,
  },
  {
    label: 'Policies',
    content: POLICY_DASHBOARD_CHART_OPTIONS,
  },
];

export const DashboardRightSection: FC = React.memo(() => {
  const { header, wrapper, title } = useStyles({});
  const [selectedTab, setSelectedTab] = useState(0);
  const { tabs, tabsContentMap } = useMemo(
    () =>
      TABS.reduce(
        ({ tabs, tabsContentMap }, { label, content }) =>
          isPermitted(content.permission)
            ? {
                tabs: [...tabs, { label }],
                tabsContentMap: [...tabsContentMap, content],
              }
            : {
                tabs,
                tabsContentMap,
              },
        { tabs: [], tabsContentMap: [] },
      ),
    [],
  );

  return (
    <DashboardCommonContainer
      aria-label="Trends And Status"
      data-bypass="dashborad-trends"
      className={`focusable ${wrapper}`}
      dataAid="DashboardRightSectionBoxWrapper"
    >
      <BigidBypassButton bypassTo="dashboard-insights" ariaLabel="Skip to insights" className="bypass-dashboard" />
      <div className={header}>
        <Typography variant={'h4'} className={title} data-aid="SelectDataSourceTypeTitle">
          Trends And Status
        </Typography>
        <BigidFancyTabs tabs={tabs} selectedIndex={selectedTab} onChange={setSelectedTab} centered={false} />
      </div>
      {tabsContentMap.map(({ Component, ...props }, index) => (
        <Component hidden={index !== selectedTab} {...props} key={props.id} />
      ))}
    </DashboardCommonContainer>
  );
});
