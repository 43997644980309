import { CustomAppParam } from '../views/EditCustomApp/EditCustomApp';
import { customAppService } from '../../../services/customAppService';
import { populateParamsIfFetchItemsExists, resetFetchItemsCache } from '../fetchItems.logic';
import {
  APPLICATIONS_PERMISSIONS,
  BigIdPermission,
  getManageBasePermissionNameByTpaName,
  PermissionActions,
  PermissionModules,
  replaceSpacesWithUnderscore,
} from '@bigid/permissions';
import { notificationService } from '../../../services/notificationService';
import { map } from 'lodash';
import { CustomAppAction, Preset } from '../views/CustomAppActions/CustomAppActions';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { AppInfo, CustomAppPagesEnum, ParamResponseType } from './CustomAppTypes';
import { Dispatch, SetStateAction } from 'react';
import { isPermitted } from '../../../services/userPermissionsService';

export const parseAppInfo = (info: Record<string, any>): AppInfo => {
  const {
    _id: id,
    tpa_name: name,
    friendlyName,
    base_url: baseUrl,
    global_params,
    description,
    is_interactive: isInteractive,
    is_use_proxy: isUseProxy,
    can_get_ds_credentials: canGetDsCredentials,
    ui_url: uiUrl,
    installation_details: installationDetails,
    bigidBaseUrl,
    vendor = '',
    ui_tab_name: uiTabName,
    version,
    status,
    license_verification_key,
    settings,
    isVerified,
  } = info;

  const { actions } = isVerified ? parseVerificationKeyJWT(license_verification_key) : [];

  return {
    id,
    name,
    friendlyName,
    baseUrl,
    globalParams: convertParams(global_params),
    description,
    isInteractive,
    isUseProxy,
    canGetDsCredentials,
    bigidBaseUrl,
    uiUrl,
    installationDetails,
    vendor,
    uiTabName: uiTabName || CustomAppPagesEnum.UI,
    version,
    status,
    actionsPermissions: actions,
    settings,
  };
};

const parseVerificationKeyJWT = (token: string) => {
  return JSON.parse(atob(token.split('.')[1]));
};

export const convertParams = (params: ParamResponseType[]): CustomAppParam[] =>
  map(
    params,
    ({
      param_type,
      param_name,
      param_friendly_name,
      param_description,
      hint,
      value,
      is_cleartext,
      default_value,
      is_mandatory,
      param_category,
      input_items,
      input_type,
      fetch_items,
    }) => ({
      type: param_type,
      name: param_name,
      friendlyName: param_friendly_name,
      description: param_description,
      placeholder: hint,
      value,
      isMandatory: is_mandatory,
      paramCategory: param_category,
      isClearText: is_cleartext,
      inputType: input_type,
      inputItems: input_items,
      defaultValue: default_value,
      fetchItems: fetch_items,
    }),
  );

export const getCustomAppInfo = async (appId: string): Promise<AppInfo> => {
  try {
    const {
      data: [info],
    } = await customAppService.getCustomAppByID(appId);
    const appInfo = parseAppInfo(info);
    appInfo.globalParams = await populateParamsIfFetchItemsExists(appInfo.globalParams);
    const hideUiPermissionsName = BigIdPermission.getPermissionName(
      PermissionModules.APPLICATIONS,
      `${PermissionActions.HIDE_UI}_${PermissionModules.CUSTOM_APPS}`,
      replaceSpacesWithUnderscore(appInfo.name),
    );
    appInfo.hideUI = isPermitted(hideUiPermissionsName);
    resetFetchItemsCache();
    return appInfo;
  } catch (e) {
    notificationService.error(e.response.data);
  }
};

export const getCustomAppPermissions = (appName: string): string[] => {
  return [
    APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name,
    APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name,
    getManageBasePermissionNameByTpaName(appName),
  ];
};

export const getCustomAppActionsDetails = async (tpaId: string): Promise<CustomAppAction[]> => {
  try {
    const { data } = await customAppService.getCustomAppActions(tpaId, true);
    const actions = map(
      data,
      ({
        _id,
        action_name,
        friendly_name,
        params,
        description,
        isScheduled,
        cronExpression,
        presets,
        sequential_execute,
      }) => {
        const actionParams = convertParams(params);
        return {
          id: _id,
          tpaId: tpaId,
          name: action_name,
          friendlyName: friendly_name,
          params: actionParams,
          description,
          isScheduled: isScheduled,
          cronExpression: cronExpression,
          presets,
          isEnabled: true,
          sequentialExecute: sequential_execute,
        };
      },
    );
    for (const action of actions) {
      action.params = await populateParamsIfFetchItemsExists(action.params);
      resetFetchItemsCache();
    }
    return actions;
  } catch (e) {
    notificationService.error(e);
  }
};

export const getCustomAppAction = async (tpaId: string, actionId: string): Promise<CustomAppAction> => {
  const actions = await getCustomAppActionsDetails(tpaId);
  return actions.find((action: CustomAppAction) => action.id === actionId);
};

export const getCustomAppActionAndPreset = async (
  tpaId: string,
  actionId: string,
  presetId: string,
): Promise<{ action: CustomAppAction; preset: Preset }> => {
  const action = await getCustomAppAction(tpaId, actionId);
  const preset = action?.presets.find((preset: Preset) => preset._id === presetId);
  return { action, preset };
};

export const setCustomAppInfo = async (
  appInfoParam: AppInfo,
  appId: string,
  setAppInfo: Dispatch<SetStateAction<AppInfo>>,
): Promise<void> => {
  const appInfo = appInfoParam?.id === appId ? appInfoParam : await getCustomAppInfo(appId);
  if (!appInfo) {
    $state.go(CONFIG.states.APPLICATIONS_MANAGEMENT);
    return;
  }
  setAppInfo(appInfo);
};

export const isObjectTypeParam = (param: CustomAppParam): boolean => {
  return param.type === 'Array' || param.type === 'Object';
};
