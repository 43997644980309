import React, { FC, useContext } from 'react';
import { styled } from '@mui/material';
import { AutoDiscoveryWizardContext } from '../../../autoDiscoveryWizardContext';
import { AutoDiscoveryWizardDs } from './AutoDiscoveryWizardDs';
import { BigidHeading6 } from '@bigid-ui/components';
import { cloudProviderLabel } from '../../../../constants';
import { useLocalTranslation } from '../../../../translations';

const AutoDiscoveryAuthAndPermissionsDsContainer = styled('div')`
  flex: 1 0;
`;

const AutoDiscoveryAuthAndPermissionsDsTitle = styled(BigidHeading6)`
  flex: 1 0;
  padding: 6px 4px;
`;

export const AutoDiscoverWizardDsList: FC = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const titleText = discoveryConfigData.formData?.dsList?.length
    ? t('configModal.dsListTitle', { type: cloudProviderLabel[discoveryConfigData.type] })
    : '';

  return (
    <AutoDiscoveryAuthAndPermissionsDsContainer>
      <AutoDiscoveryAuthAndPermissionsDsTitle>{titleText}</AutoDiscoveryAuthAndPermissionsDsTitle>
      {discoveryConfigData.formData?.dsList
        ?.filter(({ isPermitted }: { isPermitted: boolean }) => isPermitted)
        .map(AutoDiscoveryWizardDs)}
    </AutoDiscoveryAuthAndPermissionsDsContainer>
  );
};
