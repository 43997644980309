import { BigidBody2, BigidColorsV2 } from '@bigid-ui/components';
import { BigidLockIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useCardModalContext } from '../../../../CardModalContext';

import { useLocalTranslation } from '../../../../translations';
import { DataRow, Heading } from './AttributeDataCard';

const ValueWrapper = styled('div')`
  width: 50%;
`;

type RowProps = {
  label: string;
  value?: string;
  isLocked?: boolean;
};

type RowValueProps = Pick<RowProps, 'isLocked' | 'value'>;

const RowValue: FC<RowValueProps> = ({ isLocked, value }) => {
  if (isLocked) {
    return <BigidLockIcon disabled />;
  }
  const valueToDisplay = value || '-';
  return (
    <BigidBody2 color={BigidColorsV2.gray[600]} paddingRight="4px">
      {valueToDisplay}
    </BigidBody2>
  );
};

const Row: FC<RowProps> = ({ label, value, isLocked }) => {
  return (
    <DataRow>
      <BigidBody2 color={BigidColorsV2.gray[600]}>{label}</BigidBody2>
      <ValueWrapper>
        <RowValue isLocked={isLocked} value={value} />
      </ValueWrapper>
    </DataRow>
  );
};

export const ContributorsSection = () => {
  const { createdBy, dataSteward, lastModifiedBy, stewardManager } = useCardModalContext().state.tabs.info.contributors;
  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Cards.AttributeData');

  return (
    <>
      <Heading title={t('contributors')} />
      <Row label={t('createdBy')} value={createdBy} />
      <Row label={t('lastModifiedBy')} value={lastModifiedBy} />
      <Row label={t('dataSteward')} isLocked value={dataSteward} />
      <Row label={t('stewardManager')} isLocked value={stewardManager} />
    </>
  );
};
