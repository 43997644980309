import {
  BigidPersonalInfoFmsdIcon,
  BigidElctronicFmsdIcon,
  BigidNumberInfoFmsdIcon,
  BigidLocationFmsdIcon,
  BigidAccessInformationFmsdIcon,
  BigidHealthFmsdIcon,
  BigidFinanceFmsdIcon,
} from '@bigid-ui/icons';
import { NamesAndIds } from '../../../../fmsdServices';
import { getFixedT } from '../../../../translations';
import { RegulationsDataMap } from './FmsdCategories';

const fixedT = getFixedT('Categories');

export const regulationsConfig: Omit<RegulationsDataMap, '_id'>[] = [
  {
    name: 'Personal Information',
    Icon: BigidPersonalInfoFmsdIcon,
    selected: false,
    classifiers: fixedT('classifiers.personal'),
  },
  {
    name: 'Electronic Information',
    Icon: BigidElctronicFmsdIcon,
    selected: false,
    classifiers: fixedT('classifiers.electronic'),
  },
  {
    name: 'Personal Identification Numbers',
    Icon: BigidNumberInfoFmsdIcon,
    selected: false,
    classifiers: fixedT('classifiers.identificationNumbers'),
  },
  { name: 'Location', Icon: BigidLocationFmsdIcon, selected: false, classifiers: fixedT('classifiers.location') },
  {
    name: 'Secrets & Keys',
    Icon: BigidAccessInformationFmsdIcon,
    selected: false,
    classifiers: fixedT('classifiers.security'),
  },
  { name: 'Health Information', Icon: BigidHealthFmsdIcon, selected: false, classifiers: fixedT('classifiers.health') },
  {
    name: 'Financial Information',
    Icon: BigidFinanceFmsdIcon,
    selected: false,
    classifiers: fixedT('classifiers.financial'),
  },
];

export const generalRegulations = ['Suspicious Information', ...regulationsConfig.map(({ name }) => name)];

export const mapRegulationsToData = (data: NamesAndIds[]): RegulationsDataMap[] =>
  data.map(dataRegulation => {
    const currentRegulaion = regulationsConfig.find(regulation => regulation.name === dataRegulation.name);

    return {
      ...dataRegulation,
      ...(currentRegulaion && {
        Icon: currentRegulaion.Icon,
        classifiers: currentRegulaion.classifiers,
        selected: currentRegulaion.selected,
      }),
    };
  });
