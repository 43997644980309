import { BigidAdvancedToolbarFilter } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import React, { FC, useMemo } from 'react';
import { WithDataAid } from '../../../../types';
import { useFilterConfig } from './useCatalogConfig';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';

export const CatalogToolbar: FC<WithDataAid> = ({ dataAid = 'CatalogSearchToolbar' }) => {
  const { query } = useDataCatalogSearchResultsContext();
  const { filterConfig } = useFilterConfig();

  const memoizedConfig = useMemo(() => {
    return filterConfig;
  }, []);

  return (
    <BigidAdvancedToolbarFilter
      dataAid={generateDataAid(dataAid, ['toolbar'])}
      {...memoizedConfig}
      filterSearchMetadata={{
        searchText: query,
      }}
    />
  );
};
