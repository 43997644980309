import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidChip } from '@bigid-ui/components';
import { getObjectSensitivityChipProps } from '../objectPreviewService';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';

const BigidChipWrapper = styled.div({
  marginLeft: 15,
});

const ObjectPreviewInfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

export const ObjectPreviewInfo: FC = () => {
  const { objectSensitivity } = useContext(CaseSidePanelContext);
  const { scMapping } = useContext(ActionableInsightsContext);
  const { chip } = getObjectSensitivityChipProps(scMapping, objectSensitivity);

  return (
    <ObjectPreviewInfoWrapper data-aid={generateDataAid('ObjectPreviewInfo', ['main-container'])}>
      <BigidChipWrapper>{chip ? <BigidChip {...chip} /> : <></>}</BigidChipWrapper>
    </ObjectPreviewInfoWrapper>
  );
};
