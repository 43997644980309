import React, { FC, Suspense, lazy } from 'react';
import { BigidBody1, BigidHeading1, BigidLink, PrimaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from './translations';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources/DataSources';
import { publicUrls } from '../../config/publicUrls';
import styled from '@emotion/styled';
const RiskAssessmentReportEmptyStateIllustration = lazy(
  () => import('../../assets/icons/riskAssessmentReportEmptyStatePlaceholder.svg'),
);

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  padding: '88px',
});

const ActionButton = styled('div')({
  padding: '24px 0',
  width: '100%',
});

const HelpSection = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  paddingBottom: '64px',
});

export const RiskAssessmentEmptyState: FC = () => {
  const { t } = useLocalTranslation('emptyState');

  const onButtonClick = () => {
    $state.go(CONFIG.states.DATA_SOURCE_INIT, {
      path: DATA_SOURCES_INIT_VIEWS.selectDataType.path,
    });
  };

  return (
    <Root>
      <BigidHeading1 fontWeight={900}>{t('title')}</BigidHeading1>
      <BigidBody1 marginTop={'24px'} width={'400px'}>
        {t('body')}
      </BigidBody1>
      <ActionButton>
        <PrimaryButton color={'purple'} size="large" width="fill" onClick={onButtonClick} text={t('scanButton')} />
      </ActionButton>
      <HelpSection>
        <BigidBody1 marginRight={'4px'}>{t('needHelp')}</BigidBody1>
        <BigidLink
          underline="none"
          href={publicUrls.SMALLID_GETTING_STARTED_HELP}
          text={t('scheduleCall')}
          shouldOpenNewTab
        />
      </HelpSection>
      <Suspense fallback={null}>
        <RiskAssessmentReportEmptyStateIllustration />
      </Suspense>
    </Root>
  );
};
