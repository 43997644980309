import { debounce, isEqual, uniqBy, get } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
  BigidFilterOptionType,
  ExtraFieldItemData,
  RequestType,
  BigidFilter,
  BigidFieldFilterOperator,
  BigidFilterOptionValue,
  BigidFormValues,
  BigidFilterType,
  BigidSelectOption,
  MenuItem,
} from '@bigid-ui/components';
import { getUsersQuery, User } from '../../../utilities/systemUsersUtils';
import { systemUsersService } from '../../../services/angularServices';
import {
  ACActionType,
  Action,
  ApiCallActionConfiguration,
  ApiCallActionConfigurationForm,
  InitialFiltersReceivers,
  ActionConfigurationFormTypes,
  ActionCenterMetadata,
  WorkflowConfiguration,
  EventMetadata,
  iconNameToIcon,
  WorkflowsTotal,
  EventMetadataEntity,
  EventMetadataSelectField,
  ACEventType,
  OpenServiceTicketActionConfiguration,
  RunTpaActionConfiguration,
} from './actionWorkflowTypes';
import { queryService } from '../../../services/queryService';
import { httpService } from '../../../services/httpService';

const FETCH_LIMIT = 100;

interface GetWorkflowInitialFormValueInput {
  events: EventMetadata[];
  initialWorkflow?: WorkflowConfiguration;
}

interface GetEventFilterFromCategory {
  selectedCategory: string;
  selectedSubCategory?: string;
  actionCenterMetadata: ActionCenterMetadata;
}

export const getEntityTypeOptions = (entities: EventMetadataEntity[]): BigidSelectOption[] =>
  entities.map(({ name, displayName }) => ({ id: name, label: displayName, value: name }));

export const getWorkflowInitialFormValue = ({
  events,
  initialWorkflow,
}: GetWorkflowInitialFormValueInput): BigidFormValues => {
  if (!initialWorkflow) {
    return {
      name: '',
      event: [getEventGroupOptions(events)[0]],
      entityIds: [],
      receivers: [],
      actions: [],
    };
  }

  const { name, displayName, eventFieldOrder, eventToEntityMap, selectionDynamicFields, entities } = events.find(
    ({ supportedEvents }) => supportedEvents.includes(initialWorkflow.event),
  );

  const fields =
    eventFieldOrder?.split('.').reduce((formValue, field) => {
      if (field === 'entityType') {
        formValue[field] = getEntityTypeOptions(entities).filter(
          ({ value }) => value === eventToEntityMap[initialWorkflow.event],
        );
      } else if (field !== 'event') {
        const dynamicField = selectionDynamicFields.find(({ name }) => name === field);
        formValue[field] = dynamicField.options.filter(({ value }) => initialWorkflow.event.includes(value));
      }
      return formValue;
    }, {} as BigidFormValues) || {};

  return {
    ...initialWorkflow,
    event: [{ label: displayName, value: name }],
    receivers: initialWorkflow.receivers.map(({ displayName, username }) => ({
      label: displayName,
      value: { displayName, username },
    })),
    ...fields,
  };
};

export const getEventGroupOptions = (events: EventMetadata[]) =>
  events.map(({ name, displayName }) => ({
    label: displayName,
    value: name,
  }));

export const convertReceiversToOptions = (users: User[]) =>
  users.reduce<InitialFiltersReceivers>(
    ({ receivers, receiversConfigureOptions }, { firstName, name, lastName, email }) => {
      if (!firstName && !lastName) {
        receivers.push({ label: name, value: name, isSelected: false });
        receiversConfigureOptions.push({
          label: name,
          value: { username: name, displayName: name, ...(email && { email }) },
        });
        return { receivers, receiversConfigureOptions };
      }

      receivers.push({
        label: `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`,
        value: name,
        isSelected: false,
      });
      receiversConfigureOptions.push({
        label: `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`,
        subLabel: name,
        value: {
          username: name,
          displayName: `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`,
          ...(email && { email }),
        },
      });
      return { receivers, receiversConfigureOptions };
    },
    { receivers: [], receiversConfigureOptions: [] },
  );

export const loadUserSearchOptions = ({ isFilter, limit = FETCH_LIMIT }: { isFilter?: boolean; limit?: number }) =>
  debounce(
    async (inputType: string) => {
      const query = getUsersQuery({ maxUsers: limit, searchString: inputType });
      const {
        data: { users },
      } = await systemUsersService.getAllSystemUsersByQuery(query);

      const { receivers, receiversConfigureOptions } = await convertReceiversToOptions(users);
      return isFilter ? receivers : receiversConfigureOptions;
    },
    500,
    {
      leading: true,
      trailing: true,
    },
  );

export const getEntityDataOptions = async ({
  resourceName,
  pathToData,
  optionsMapping,
}: Pick<EventMetadataEntity, 'resourceName' | 'pathToData' | 'optionsMapping'>): Promise<BigidFilterOptionType[]> => {
  const { data } = await httpService.fetch(resourceName);
  const results = pathToData ? get(data, pathToData) : data;
  const options = results.map((result: any) => ({
    label: result[optionsMapping.label],
    value: result[optionsMapping.value],
    isSelected: false,
  }));
  return options;
};

export type LoadSearchOptions = (inputValue: string) => Promise<BigidFilterOptionType[]>;

export const loadEntitySearchOptions = (
  {
    name,
    resourceName,
    pathToData,
    optionsMapping,
  }: Omit<EventMetadataEntity, 'displayName' | 'autocompleteListItems' | 'options'>,
  entityIds?: string[],
): LoadSearchOptions =>
  debounce(
    async (inputType: string) => {
      const paginationObject = { skip: 0, limit: FETCH_LIMIT };

      let filter;
      if (inputType) {
        const splitSearchString = inputType.split(' ').join('|');
        filter = [
          {
            field: optionsMapping.label,
            operator: 'textSearch' as BigidFieldFilterOperator,
            value: splitSearchString,
          },
        ];
      }

      const query = queryService.getGridConfigQuery({
        ...paginationObject,
        ...(filter && { filter }),
      });

      const queryOptions = await getEntityDataOptions({
        pathToData,
        optionsMapping,
        resourceName: `${resourceName}?${query}`,
      });

      if (entityIds?.length > 0) {
        const chosenEntitiesQuery = queryService.getGridConfigQuery({
          filter: [
            {
              field: optionsMapping.value,
              operator: 'in' as BigidFieldFilterOperator,
              value: entityIds,
            },
          ],
        });

        const chosenEntitiesOptions = await getEntityDataOptions({
          pathToData,
          optionsMapping,
          resourceName: `${resourceName}?${chosenEntitiesQuery}`,
        });

        return uniqBy([...queryOptions, ...chosenEntitiesOptions], 'value');
      }

      return [...queryOptions];
    },
    500,
    {
      leading: true,
      trailing: true,
    },
  );

const getEntityMetadataForSelectedCategory = (
  actionCenterMetadata: ActionCenterMetadata,
  selectedCategory?: string,
  selectedSubCategory?: string,
): EventMetadataEntity[] =>
  uniqBy(
    selectedSubCategory
      ? actionCenterMetadata.events.find(({ name }) => name === selectedSubCategory).entities
      : selectedCategory
      ? actionCenterMetadata.categories
          .find(({ name }) => name === selectedCategory)
          .events.reduce((allEntities, eventName) => {
            return [...allEntities, ...actionCenterMetadata.events.find(({ name }) => eventName === name).entities];
          }, [])
      : [],
    'name',
  );

export const getEntityFilters = (
  actionCenterMetadata: ActionCenterMetadata,
  selectedCategory: string,
  selectedSubCategory?: string,
): BigidFilterType[] => {
  const allEntities = getEntityMetadataForSelectedCategory(actionCenterMetadata, selectedCategory, selectedSubCategory);

  return allEntities.map(({ name, displayName, resourceName, pathToData, optionsMapping }) => {
    return {
      title: displayName,
      field: 'entityIds',
      operator: 'in' as BigidFieldFilterOperator,
      value: [] as BigidFilterOptionValue,
      options: [],
      disabled: true,
      isSearchAsync: true,
      loadSearchOptions: loadEntitySearchOptions({ name, resourceName, pathToData, optionsMapping }),
      shouldLoadInitialOptions: true,
    };
  });
};

export const changeExtraFieldToNameValueObject = (extraFieldItemData: ExtraFieldItemData[]) =>
  extraFieldItemData.reduce((nameValueObject, { value: { name, value } }) => {
    nameValueObject[name] = value;
    return nameValueObject;
  }, {} as Record<string, string>);

export const changeNameValueObjectToExtraField = (nameValueObject: Record<string, string>) =>
  Object.keys(nameValueObject).map(key => ({ id: uuid(), value: { name: key, value: nameValueObject[key] } }));

export const convertFormFormatToApiCallAction = ({
  headers,
  queryParams,
  payload,
  ...rest
}: ApiCallActionConfigurationForm): ApiCallActionConfiguration => ({
  ...rest,
  ...(headers?.length && { headers: changeExtraFieldToNameValueObject(headers) }),
  ...(queryParams?.length && { queryParams: changeExtraFieldToNameValueObject(queryParams) }),
  ...(payload && { payload: JSON.parse(payload) }),
});

export const convertApiCallActionToFormFormat = ({
  headers,
  queryParams,
  payload,
  ...rest
}: ApiCallActionConfiguration): ApiCallActionConfigurationForm => ({
  ...rest,
  ...(payload && { payload: JSON.stringify(payload, null, '\t') }),
  ...(headers && { headers: changeNameValueObjectToExtraField(headers) }),
  ...(queryParams && { queryParams: changeNameValueObjectToExtraField(queryParams) }),
});

const apiCallDefaultValue = { enabled: false, url: '', type: RequestType.GET, useCertificate: false };
const emptyFormDefaultValue = { enabled: false };

export const getConfigurationByActionType = (
  actionConfigurations: Action[],
  actionCenterMetadata: ActionCenterMetadata,
): Record<ACActionType, ActionConfigurationFormTypes> =>
  actionCenterMetadata.actions.reduce((configurationByActionType, { name }) => {
    switch (name) {
      case ACActionType.API_CALL: {
        configurationByActionType[name] = convertApiCallActionToFormFormat(
          (actionConfigurations.find(({ type }) => type === ACActionType.API_CALL)?.configuration ||
            apiCallDefaultValue) as ApiCallActionConfiguration,
        );
        break;
      }
      case ACActionType.OPEN_SERVICE_TICKET: {
        configurationByActionType[name] = actionConfigurations.find(
          ({ type }) => type === ACActionType.OPEN_SERVICE_TICKET,
        )?.configuration as OpenServiceTicketActionConfiguration;
        break;
      }
      case ACActionType.RUN_TPA_ACTION: {
        configurationByActionType[name] = actionConfigurations.find(({ type }) => type === ACActionType.RUN_TPA_ACTION)
          ?.configuration as RunTpaActionConfiguration;
        break;
      }
      default: {
        break;
      }
    }
    return configurationByActionType;
  }, {} as Record<ACActionType, ActionConfigurationFormTypes>);

export const validateActions = (actions: Action[] = []) =>
  actions.filter(action => {
    return isConfigurationEmpty(action);
  }).length === 0;

export const isConfigurationEmpty = (action: Action) => {
  const {
    type,
    configuration: { enabled, ...formConfiguration },
  } = action;
  switch (type) {
    case ACActionType.API_CALL: {
      const { enabled, ...apiCallFormInitial } = apiCallDefaultValue;
      return isEqual(formConfiguration, apiCallFormInitial);
    }
    case ACActionType.OPEN_SERVICE_TICKET: {
      const { enabled, ...openServiceTicketFormInitial } = emptyFormDefaultValue;
      return isEqual(formConfiguration, openServiceTicketFormInitial);
    }
    case ACActionType.RUN_TPA_ACTION: {
      const { enabled, ...runTpaFormInitial } = emptyFormDefaultValue;
      return isEqual(formConfiguration, runTpaFormInitial);
    }
    default: {
      return false;
    }
  }
};

export const getCategories = (actionCenterMetadata: ActionCenterMetadata, workflowsTotal: WorkflowsTotal) =>
  actionCenterMetadata.categories.reduce<{
    categories: MenuItem[];
    disabledCategories: MenuItem[];
  }>(
    ({ categories, disabledCategories }, { isDisabled, name, iconName, events, displayName }) => {
      if (isDisabled) {
        disabledCategories.push({
          name,
          displayName,
          leftIcon: iconNameToIcon[iconName],
          subMenuItems: [],
          isDisabled: true,
        });
      } else {
        categories.push({
          name,
          displayName,
          leftIcon: iconNameToIcon[iconName],
          subMenuItems: events.map(name => {
            const { displayName } = actionCenterMetadata.events.find(({ name: eventName }) => name === eventName);
            return { name, displayName, totalItems: workflowsTotal?.[name] };
          }),
        });
      }
      return { categories, disabledCategories };
    },
    {
      categories: [],
      disabledCategories: [],
    },
  );

export const removeEmptyExtraField = ({ queryParams = [], headers = [], ...form }: ApiCallActionConfigurationForm) => {
  return {
    ...form,
    queryParams: queryParams.filter(({ value: { name, value } }) => name || value),
    headers: headers.filter(({ value: { name, value } }) => Boolean(name || value)),
  };
};

export const getEventFilterFromCategory = ({
  selectedCategory,
  selectedSubCategory,
  actionCenterMetadata,
}: GetEventFilterFromCategory): BigidFilter => {
  if (!selectedCategory) {
    return [];
  }

  if (selectedSubCategory) {
    const events = actionCenterMetadata.events.find(({ name }) => name === selectedSubCategory).supportedEvents;
    return [{ field: 'event', operator: 'in', value: events }];
  }

  const eventGroups = actionCenterMetadata.categories.find(({ name }) => name === selectedCategory).events;
  const events = actionCenterMetadata.events.reduce((events, { name, supportedEvents }) => {
    if (eventGroups.includes(name)) {
      return [...events, ...supportedEvents];
    }
    return events;
  }, []);

  return [{ field: 'event', operator: 'in', value: events }];
};

export const getAllSelectionDynamicFields = (events: EventMetadata[]): EventMetadataSelectField[] =>
  events.reduce((allSelectionDynamicFields, { selectionDynamicFields = [] }) => {
    return [...allSelectionDynamicFields, ...selectionDynamicFields];
  }, []);

const getAllSupportedEvents = (events: EventMetadata[]) =>
  events.reduce((allSupportedEvents, { supportedEvents }) => [...allSupportedEvents, ...supportedEvents], []);

export const getSelectedEventFromFormValue = (
  events: EventMetadata[],
  eventFieldOrder: string,
  values: BigidFormValues,
): ACEventType | null => {
  if (!eventFieldOrder) {
    return values.event?.[0].value;
  }

  const eventFieldOrderSplit = eventFieldOrder.split('.');
  const selectedEvent = eventFieldOrderSplit.reduce((eventName, currentField) => {
    const suffix = values[currentField]?.[0]?.value || '';
    return eventName + suffix;
  }, '');

  return getAllSupportedEvents(events).includes(selectedEvent) ? (selectedEvent as ACEventType) : null;
};

export const getAutocompleteListItemsFromSelectedEvent = (
  selectedEvent: ACEventType,
  actionCenterMetadata: ActionCenterMetadata,
) => {
  const selectedEventGroup = actionCenterMetadata.events.find(({ supportedEvents }) =>
    supportedEvents.includes(selectedEvent),
  );

  const entityName = selectedEventGroup.eventToEntityMap[selectedEvent];
  const autocompleteListItems = selectedEventGroup.entities.find(
    ({ name }) => name === entityName,
  ).autocompleteListItems;
  return autocompleteListItems;
};

export const isDuplicationError = (error: Record<string, any>): boolean => {
  const {
    response: {
      data: {
        errors: [firstErr],
      },
    },
  } = error;
  return firstErr?.code === 11000;
};
