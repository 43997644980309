import { BigidBody1, BigidColorsV2, BigidTextField } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { ChangeEvent, Dispatch, FC } from 'react';
import { FormItem } from './AssignCategoryDialogContent';
import { ColorComponent } from './ColorComponent';
import { useLocalTranslation } from '../../../translations';
import { CategoryDetailsActions, CreateCategory, DetailsActionsTypes } from '../types';

interface CreateNewCategoryProps {
  categoryState: CreateCategory;
  dispatchCategoryAction: Dispatch<CategoryDetailsActions>;
  disabled: boolean;
}

const StyledColorInput = styled('input')`
  width: 310px;
`;

export const CreateNewCategory: FC<CreateNewCategoryProps> = ({ categoryState, dispatchCategoryAction, disabled }) => {
  const { t } = useLocalTranslation('AssignCategoryDialogContent.NewCategory');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatchCategoryAction({
      type: DetailsActionsTypes.SET_CATEGORY_DETAILS_FIELD,
      fieldName: e.target.name as keyof CreateCategory,
      value: e.target.value,
    });
  };

  return (
    <>
      <BigidTextField
        size="medium"
        placeholder={t('textInputPlaceholder')}
        onChange={handleChange}
        name="name"
        disabled={disabled}
        value={categoryState.name}
      />
      <BigidTextField
        multiline
        disabled={disabled}
        placeholder={t('description')}
        name="description"
        onChange={handleChange}
        value={categoryState.description}
      />
      <FormItem>
        <BigidBody1 color={BigidColorsV2.gray[700]} alignSelf="center">
          {t('color')}
        </BigidBody1>
        <ColorComponent color={categoryState.color} />
        <StyledColorInput
          type="color"
          value={categoryState.color}
          disabled={disabled}
          name="color"
          onChange={handleChange}
        />
      </FormItem>
    </>
  );
};
