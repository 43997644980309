import './activeDirectory.component.scss';
import template from './activeDirectory.component.html';

import { module } from 'angular';
import { isPermitted } from '../../../react/services/userPermissionsService';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
const app = module('app');

app.component('activeDirectory', {
  template,
  controllerAs: 'activeDirectoryModel',
  controller: [
    '$scope',
    'notificationService',
    '$translate',
    'activeDirectoryService',
    'rolesService',
    function ($scope, notificationService, $translate, activeDirectoryService, rolesService) {
      const activeDirectoryModel = this;

      activeDirectoryModel.data = {};
      activeDirectoryModel.activeDirectoryGroupsFetched = false;
      activeDirectoryModel.originPass = '';
      activeDirectoryModel.passChanged = false;
      activeDirectoryModel.testingMode = false;
      activeDirectoryModel.activeDirectoryForm = {};
      activeDirectoryModel.idpGroups = [];
      activeDirectoryModel.roles = [];
      activeDirectoryModel.isStepTowEnabled = false;
      activeDirectoryModel.isCollapsed = true;
      activeDirectoryModel.activeDirectoryRoles = {};

      const RESET_ROLE = 'None';
      const EMPTY_IDP_GROUP = 'None';

      const getActiveDirectoryData = () => {
        activeDirectoryService.getActiveDirectoryData().then(
          result => {
            if (result && result.data) {
              const _data = angular.copy(result.data);
              activeDirectoryModel.originPass = angular.copy(result.data.password);
              _data.password = _data.password ? _data.password.slice(0, 8) : _data.password;
              activeDirectoryModel.data = _data;
            } else {
              activeDirectoryModel.availableRoleOptions = [];
              activeDirectoryModel.activeDirectoryGroupsFetched = false;
              sessionStorageService.remove('activeDirectoryGroups');
            }
            activeDirectoryModel.onDataLoaded();
          },
          () => {
            activeDirectoryModel.onDataLoaded();
            notificationService.error('An error has occurred!');
          },
        );
      };

      const saveActiveDirectoryData = data => {
        activeDirectoryModel.onDataLoading();
        activeDirectoryService
          .saveActiveDirectoryData(data)
          .then(
            response => {
              $translate('ACTIVE_DIRECTORY:SUCCESS').then(translation => {
                notificationService.success(translation);
              });
              activeDirectoryModel.data._id = response.data._id;
            },
            () => {
              $translate('ACTIVE_DIRECTORY:FAILURE').then(translation => {
                notificationService.error(translation);
              });
            },
          )
          .finally(() => activeDirectoryModel.onDataLoaded());
      };

      const updateActiveDirectoryData = (data, id) => {
        activeDirectoryModel.onDataLoading();
        activeDirectoryService
          .updateActiveDirectoryData(data, id)
          .then(
            () => {
              $translate('ACTIVE_DIRECTORY:SUCCESS').then(translation => {
                notificationService.success(translation);
              });
            },
            () => {
              $translate('ACTIVE_DIRECTORY:FAILURE').then(translation => {
                notificationService.error(translation);
              });
            },
          )
          .finally(() => activeDirectoryModel.onDataLoaded());
      };

      const sortIdpGroups = (group1, group2) => group1.localeCompare(group2);

      const testActiveDirectoryConnection = (data, notify) => {
        activeDirectoryModel.onDataLoading();
        activeDirectoryService
          .testActiveDirectoryConnection(data)
          .then(
            result => {
              if (result && result.data) {
                activeDirectoryModel.availableRoleOptions = [RESET_ROLE].concat(result.data.groups);
                if (result.data.groups) {
                  activeDirectoryModel.idpGroups = result.data.groups;
                  activeDirectoryModel.idpGroups.sort(sortIdpGroups).unshift(EMPTY_IDP_GROUP);
                }
                sessionStorageService.set('activeDirectoryGroups', activeDirectoryModel.availableRoleOptions);
                activeDirectoryModel.testingMode = false;
                activeDirectoryModel.activeDirectoryGroupsFetched =
                  (activeDirectoryModel.availableRoleOptions &&
                    activeDirectoryModel.availableRoleOptions.length == 0) ||
                  !activeDirectoryModel.availableRoleOptions
                    ? false
                    : true;
                activeDirectoryModel.isStepTowEnabled = true;
              }
              if (notify) {
                $translate('ACTIVE_DIRECTORY:TEST_CONNECTION:SUCCESS').then(translation => {
                  notificationService.success(translation);
                });
              }
            },
            ({ data: err }) => {
              $translate('ACTIVE_DIRECTORY:TEST_CONNECTION:FAILURE').then(translation => {
                activeDirectoryModel.isStepTowEnabled = false;
                notificationService.error(`${translation}: ${err}`);
                console.error(err);
              });
            },
          )
          .finally(() => activeDirectoryModel.onDataLoaded());
      };

      activeDirectoryModel.alphaSort = (a, b) => {
        const aName = a.name.toLowerCase(),
          bName = b.name.toLowerCase();
        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        } else {
          return 0;
        }
      };

      activeDirectoryModel.getRoles = () => {
        rolesService.getRBACRoles().then(
          response => {
            activeDirectoryModel.roles = response.data.roles.sort(activeDirectoryModel.alphaSort);
          },
          () => {
            $translate('ACTIVE_DIRECTORY:ROLES:FAILURE').then(translation => {
              notificationService.error(translation);
            });
          },
        );
      };

      activeDirectoryModel.onEnabledChanged = () => {
        if (!activeDirectoryModel.data._id) return;
        const formData = {};
        formData.enabled = activeDirectoryModel.data.enabled;
        formData._id = activeDirectoryModel.data._id;
        updateActiveDirectoryData(formData, formData._id);
      };

      activeDirectoryModel.onTrustSelfSignedChanged = () => {
        if (!activeDirectoryModel.data._id) return;
        const formData = {};
        formData.trustSelfSigned = activeDirectoryModel.data.trustSelfSigned;
        formData._id = activeDirectoryModel.data._id;
        updateActiveDirectoryData(formData, formData._id);
      };

      activeDirectoryModel.postObjectOwner = () => {
        const formData = angular.copy(activeDirectoryModel.data);

        formData['type'] = 'active_directory';
        if (activeDirectoryModel.passChanged) {
          formData['isNewPassword'] = true;
        } else {
          formData['isNewPassword'] = false;
          if (activeDirectoryModel.originPass) formData['password'] = activeDirectoryModel.originPass;
        }

        activeDirectoryService
          .postObjectOwner(formData)
          .then(res => {
            if (res.status >= 200 && res.status < 300) {
              $translate('ACTIVE_DIRECTORY:API:ACTIVE_DIRECTORY_SYNC:UPDATING').then(translation => {
                notificationService.success(translation);
              });
            } else {
              $translate('ACTIVE_DIRECTORY:API:ACTIVE_DIRECTORY_SYNC:ERROR').then(translation => {
                notificationService.error(translation);
              });
            }
          })
          .catch(err => {
            $translate('ACTIVE_DIRECTORY:API:ACTIVE_DIRECTORY_SYNC:ERROR').then(translation => {
              notificationService.error(translation);
            });
          });
      };

      activeDirectoryModel.$onInit = () => {
        activeDirectoryModel.isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE_IDP.name);
        activeDirectoryModel.availableRoleOptions = sessionStorageService.get('activeDirectoryGroups') || [];
        activeDirectoryModel.activeDirectoryGroupsFetched =
          (activeDirectoryModel.availableRoleOptions && activeDirectoryModel.availableRoleOptions.length == 0) ||
          !activeDirectoryModel.availableRoleOptions
            ? false
            : true;
        activeDirectoryModel.onDataLoading();
        getActiveDirectoryData();
        activeDirectoryModel.getRoles();
      };

      activeDirectoryModel.getActiveDirectoryForSave = () => {
        activeDirectoryModel.data.roles = activeDirectoryModel.roles.map(role => ({
          name: role.name,
          idp_group: role.idp_group,
          roleId: role._id,
        }));
        return angular.copy(activeDirectoryModel.data);
      };

      activeDirectoryModel.submit = (isTest = false) => {
        activeDirectoryModel.testingMode = isTest;

        if (!activeDirectoryModel.activeDirectoryForm.$valid) return;
        const formData = activeDirectoryModel.getActiveDirectoryForSave();

        if (activeDirectoryModel.passChanged) {
          formData['isNewPassword'] = true;
        } else {
          formData['isNewPassword'] = false;
          if (activeDirectoryModel.originPass) formData['password'] = activeDirectoryModel.originPass;
        }

        if (!isTest) {
          if (formData._id) {
            updateActiveDirectoryData(formData, formData._id);
          } else {
            saveActiveDirectoryData(formData);
          }
        } else {
          testActiveDirectoryConnection(formData, true);
        }

        activeDirectoryModel.activeDirectoryForm.$submitted = false;
      };

      activeDirectoryModel.passwordChanged = newVal => {
        activeDirectoryModel.passChanged = activeDirectoryModel.originPass != newVal ? true : false;
      };
    },
  ],
  bindings: {
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
