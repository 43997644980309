import React, { FC } from 'react';
import { BigidDisconnectedIllustration } from '@bigid-ui/icons';
import { BigidHeading4, PrimaryButton } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { DataSourcesUITrackingEvent } from '../../../../DataSourcesEventTrackerUtils';
import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { generateDataAid } from '@bigid-ui/utils';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 167px;
`;

export interface MainContentDraftStatusProps {
  onConnect: () => void;
}

export const MainContentDraftStatus: FC<MainContentDraftStatusProps> = ({ onConnect }) => {
  const { t } = useLocalTranslation();

  return (
    <Wrapper>
      <BigidDisconnectedIllustration width={144} height={144} />
      <BigidHeading4 textAlign={'center'}>{t('connectionTab.getStartedConnectingDS')}</BigidHeading4>
      <PrimaryButton
        margin="23px 0px 8px 0px"
        dataAid={generateDataAid('DataSourceConnectionTab', ['draft', 'connect'])}
        onClick={onConnect}
        text={t('buttons.connect')}
        size="medium"
        bi={{ eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_DRAFT_CONNECT_CLICK }}
      />
    </Wrapper>
  );
};
