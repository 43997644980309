import React, { FC, useContext, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CreateScanWizardContext, ScanCreateData } from '../../createScanContext';
import { BigidForm, BigidFormValues } from '@bigid-ui/components';
import { fields } from './scanBasicDetailsFields';
import { isEqual } from 'lodash';

const useStyles = makeStyles({
  formWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    '& > form': {
      width: '520px',
    },
  },
});

const convertFormValueToScheduledScanValue = (values: BigidFormValues) => {
  if (values?.owners?.[0]?.value) {
    values.owners = [values.owners[0].value];
  }

  return values;
};

const convertScheduledScanValueToFormValue = (scanWizardStateValues: ScanCreateData): BigidFormValues => {
  const { name, owners, description } = scanWizardStateValues;
  if (scanWizardStateValues?.owners?.length > 0) {
    return {
      name,
      owners: [
        {
          label: scanWizardStateValues.owners[0],
          value: scanWizardStateValues.owners[0],
        },
      ],
      description,
    };
  }

  return { name, owners, description };
};

export const ScanBasicDetailsStep: FC = () => {
  const { formWrapper } = useStyles();
  const { scanWizardState, setScanWizardState, scanBasicDetailsHandlersRef } = useContext(CreateScanWizardContext);

  const formProps = useMemo(
    () => ({
      fields,
      initialValues: convertScheduledScanValueToFormValue(scanWizardState),
      onChange: (newValues: BigidFormValues) => {
        const scanUpdated = { ...scanWizardState, ...convertFormValueToScheduledScanValue(newValues) };
        if (!isEqual(scanUpdated, scanWizardState)) {
          setScanWizardState(scanUpdated);
        }
      },
      controlButtons: false,
      stateAndHandlersRef: scanBasicDetailsHandlersRef,
    }),
    [scanBasicDetailsHandlersRef, setScanWizardState, scanWizardState],
  );
  const formMemoized = useMemo(() => <BigidForm {...formProps} />, [formProps]);

  return (
    <div data-aid="ScheduledScanBasicDetailsStep" className={formWrapper}>
      {formMemoized}
    </div>
  );
};
