import React, { FC, memo } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidBody1, SecondaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidMaintenanceIcon, BigidNoDataIllustration } from '@bigid-ui/icons';
import { useLocalTranslation } from '../../translations';

export enum CuratedFieldPreviewErrorType {
  ERROR = 'error',
  EXPIRED_ATTRIBUTE = 'expired-attribute',
}

export interface CuratedFieldValuePreviewErrorProps {
  dataAid: string;
  errorText: string;
  onPreviewClick: () => void;
  errorType: CuratedFieldPreviewErrorType;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
});

export const CuratedFieldValuePreviewError: FC<CuratedFieldValuePreviewErrorProps> = memo(
  ({ dataAid, errorText, onPreviewClick, errorType }) => {
    const classes = useStyles();
    const { t } = useLocalTranslation('CuratedFields');

    const handleOnPreviewClick = () => {
      onPreviewClick();
    };

    return (
      <div className={classes.root} data-aid={dataAid}>
        <div className={classes.row}>
          {errorType !== CuratedFieldPreviewErrorType.EXPIRED_ATTRIBUTE ? (
            <BigidMaintenanceIcon />
          ) : (
            <BigidNoDataIllustration />
          )}
        </div>
        <div className={classes.row}>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['title'])}>{errorText}</BigidBody1>
        </div>
        {errorType !== CuratedFieldPreviewErrorType.EXPIRED_ATTRIBUTE && (
          <SecondaryButton
            size="medium"
            onClick={handleOnPreviewClick}
            dataAid={generateDataAid(dataAid, ['preview'])}
            text={t('preview')}
          />
        )}
      </div>
    );
  },
);
