import './savedQueries.component.scss';
import { module } from 'angular';
const app = module('app');
import template from './savedQueries.component.html';
import { TAGS_SAVED_QUERIES_PERMISSIONS, APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';

app.component('savedQueries', {
  template,
  controller: function (
    $document,
    $scope,
    $timeout,
    $translate,
    DeleteConfirmation,
    labelingService,
    notificationService,
    savedQueriesService,
  ) {
    'ngInject';

    const SAVED_QUERIES_DATA_LIST_ID = 'savedQueriesDataList';
    const SAVED_QUERIES_DATA_CONTAINER_BODY_ID = 'savedQueriesContentBody';
    const SAVED_QUERIES_DATA_LIST_ITEM_ID = 'tag';
    const DEFAULT_LABEL_OPTION = { _id: null, displayName: ' ' };
    const LABELS_FRAMEWORKS = { GDRIVE: 'url(/images/gdrive_logo.svg)', MIP: 'url(/images/Microsoft_logo_only.svg)' };

    const dataListContainer = $document[0].getElementById(SAVED_QUERIES_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(SAVED_QUERIES_DATA_CONTAINER_BODY_ID);

    let isFormDirty = false;

    this.savedQueries = [];
    this.labels = [];
    this.savedQuery = {};
    this.editModeOn = false;
    this.createModeOn = false;
    this.savedQueryId = '';
    this.savedQueryForm = {};
    this.userQuery = '';
    this.isDataLoading = false;
    this.labelsUrl = null;

    this.isLabelingFeatureEnabled = getApplicationPreference('LABELER_ENABLED');
    this.isMultiTenantEnabled = getApplicationPreference('MULTI_TENANT_MODE_ENABLED');

    /* API calls --start */
    const getLabels = () => labelingService.getLabels();
    const getSavedQueries = () => savedQueriesService.getSavedQueries();

    const fetchSavedQueries = (scrollTo = '', entityToProceed = null) => {
      this.onDataLoading();

      savedQueriesService.getSavedQueries().then(
        result => {
          this.savedQueries = result.sort(alphaSort('tag_name'));

          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreateSavedQuery();
            } else {
              onEditSavedQuery(entityToProceed);
            }
          } else {
            if (this.editModeOn) {
              resetListSelection();
              setListSelection(this.savedQueryId);

              $timeout(() => {
                const index = this.savedQueries.findIndex(tag => tag._id === this.savedQueryId);
                const dataListItem = $document[0].getElementById(`${SAVED_QUERIES_DATA_LIST_ITEM_ID}-${index}`);

                if (dataListItem !== null && index !== -1) {
                  dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                }
              }, 0);
            }
          }

          if (dataListContainer != null) {
            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.savedQueries.findIndex(tag => tag._id === entityToProceed._id);
                    const dataListItem = $document[0].getElementById(`${SAVED_QUERIES_DATA_LIST_ITEM_ID}-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const createSavedQuery = (data, entityToProceed = null) => {
      savedQueriesService.createSavedQuery(data).then(
        result => {
          $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            this.editModeOn = true;
            this.createModeOn = false;

            this.savedQueryId = result.insertedId;

            isFormDirty = false;

            this.savedQueryForm.$submitted = false;

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            fetchSavedQueries('', entityToProceed);
          }
        },
        err => {
          if (err.data && err.data.message) {
            notificationService.error(err.data.message);
          } else if (err.data && err.data.startsWith('E11000 duplicate key error')) {
            $translate('SAVED_QUERIES:API:ERROR:UNIQUE').then(translation => {
              notificationService.error(translation);
            });
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }
        },
      );
    };

    const updateSavedQuery = (id, data, entityToProceed = null) => {
      savedQueriesService.updateSavedQuery(id, data).then(
        () => {
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            this.savedQueryId = angular.copy(this.savedQuery._id);

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            isFormDirty = false;

            this.savedQueryForm.$submitted = false;

            fetchSavedQueries('', entityToProceed);
          }
        },
        err => {
          if (err.data && err.data.message) {
            notificationService.error(err.data.message);
          } else if (err.data != null && err.data.startsWith('E11000 duplicate key error')) {
            $translate('SAVED_QUERIES:API:ERROR:UNIQUE').then(translation => {
              notificationService.error(translation);
            });
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }
        },
      );
    };

    const deleteSavedQuery = id => {
      savedQueriesService.deleteSavedQuery(id).then(
        () => {
          $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            this.editModeOn = false;
            this.createModeOn = false;

            this.savedQuery = {};

            fetchSavedQueries('top');
          }
        },
        err => {
          if (err.data && err.data.message) {
            notificationService.error(err.data.message);
          } else {
            $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
              notificationService.error(translation);
            });
          }
        },
      );
    };

    /* API calls --end */

    /* component methods --start */

    const initializeData = async (callback, formOnly = false) => {
      const isReadLabelsPermitted = isPermitted(APPLICATIONS_PERMISSIONS.READ_MICROSOFT_INFORMATION_PROTECTION.name);
      try {
        if (formOnly) {
          if (isReadLabelsPermitted) {
            if (!this.isMultiTenantEnabled) {
              const labelsResponse = await getLabels();
              const labels = safeGetLabels(labelsResponse);
              parseLabelsType(labels, labelsResponse);
              return callback({ labels });
            }
          }
          return callback({ labels: [] });
        } else {
          const savedQueriesResponse = await getSavedQueries();
          const savedQueries = savedQueriesResponse;

          if (isReadLabelsPermitted) {
            if (!this.isMultiTenantEnabled) {
              const labelsResponse = await getLabels();
              const labels = safeGetLabels(labelsResponse);
              parseLabelsType(labels, labelsResponse);
              return callback({ savedQueries, labels });
            }
          }

          return callback({ savedQueries, labels: [] });
        }
      } catch (err) {
        $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
          notificationService.error(translation);
        });

        return callback({ savedQueries: [], labels: [] });
      }
    };

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    const onCreateSavedQuery = () => {
      resetListSelection();

      this.savedQuery = {};

      this.createModeOn = true;
      this.editModeOn = false;

      this.savedQueryId = '';

      resetFormState();
    };

    const onEditSavedQuery = savedQuery => {
      resetListSelection();
      setListSelection(savedQuery._id);

      this.savedQuery = angular.copy(savedQuery);
      this.savedQueryId = angular.copy(this.savedQuery._id);

      this.editModeOn = true;
      this.createModeOn = false;

      resetFormState();
    };

    const onSubmitSavedQuery = (entityToProceed = null) => {
      if (!this.savedQueryForm.$valid) return;

      const payload = angular.copy(this.savedQuery);

      if (payload.label && !payload.label.labelId) delete payload.label;
      if (typeof payload.selected != 'undefined') delete payload.selected;

      switch (true) {
        case this.createModeOn:
          createSavedQuery(payload, entityToProceed);
          break;
        case this.editModeOn:
          updateSavedQuery(this.savedQueryId, payload, entityToProceed);
          break;
      }
    };

    const resetFormState = () => {
      if (
        typeof this.savedQueryForm != 'undefined' &&
        typeof this.savedQueryForm.$setPristine == 'function' &&
        typeof this.savedQueryForm.$setUntouched == 'function'
      ) {
        this.savedQueryForm.$setPristine();
        this.savedQueryForm.$setUntouched();
      } else {
        this.savedQueryForm = {};
      }

      this.savedQueryForm.$submitted = false;

      isFormDirty = false;
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.savedQueries.length; i < len; i++) {
        if (this.savedQueries[i]._id === except) {
          continue;
        }
        this.savedQueries[i]['selected'] = false;
      }
    };

    const setListSelection = id => {
      for (let i = 0, len = this.savedQueries.length; i < len; i++) {
        if (this.savedQueries[i]._id === id) {
          this.savedQueries[i]['selected'] = true;
          break;
        }
      }
    };

    const alphaSort = prop => {
      return (a, b) => {
        const aName = a[prop].toLowerCase(),
          bName = b[prop].toLowerCase();

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        } else {
          return 0;
        }
      };
    };

    const safeGetLabels = labelsResponse => {
      const labels = labelsResponse.data?.labels;
      if (labels === undefined) {
        return [];
      }
      return labels || [];
    };

    const parseLabelsType = (labels, labelsResponse) => {
      if (labels.length > 0) {
        const labelsType = labelsResponse.data?.type;
        this.labelsUrl = labelsType !== undefined ? LABELS_FRAMEWORKS[labelsType] : undefined;
      }
    };
    /* component methods --end */

    this.$onInit = () => {
      this.isCreatePermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.CREATE.name);
      this.isEditPermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.EDIT.name);
      this.isDeletePermitted = isPermitted(TAGS_SAVED_QUERIES_PERMISSIONS.DELETE.name);

      if (this.formOnly) {
        this.isDataLoading = true;

        if (typeof this.formData != 'undefined') {
          this.editModeOn = true;
          this.createModeOn = false;
        } else {
          this.editModeOn = false;
          this.createModeOn = true;
        }

        initializeData(result => {
          const { labels } = result;

          this.labels = [DEFAULT_LABEL_OPTION, ...labels.sort(alphaSort('displayName'))];
          switch (true) {
            case this.createModeOn:
              if (typeof this.initialData != 'undefined') {
                this.savedQuery = Object.assign({}, this.initialData);
              }
              break;
            case this.editModeOn:
              this.savedQuery = Object.assign({}, this.formData);
              this.savedQueryId = this.formData._id;
              break;
          }

          $scope.$evalAsync(() => {
            this.isDataLoading = false;
          });
        }, this.formOnly);
      } else {
        this.onDataLoading();

        initializeData(result => {
          const { savedQueries, labels } = result;

          this.savedQueries = savedQueries.sort(alphaSort('tag_name'));
          this.labels = [DEFAULT_LABEL_OPTION, ...labels.sort(alphaSort('displayName'))];
          if (this.savedQueries.length > 0) {
            onEditSavedQuery(this.savedQueries[0]);
          }

          this.onDataLoaded();
        });
      }
    };

    this.onSubmit = () => {
      onSubmitSavedQuery();
    };

    this.onCancel = () => {
      this.savedQuery = {};

      this.savedQueryId = '';

      resetFormState();
    };

    this.onCreate = () => {
      if (isFormDirty) {
        onUserLeavesForm(
          () => {
            this.savedQueryForm.$submitted = true;

            onSubmitSavedQuery({});
          },
          () => {
            onCreateSavedQuery();
          },
        );
      } else {
        onCreateSavedQuery();
      }
    };

    this.onEdit = savedQuery => {
      if (this.savedQuery._id !== savedQuery._id) {
        if (this.isEditPermitted && isFormDirty) {
          onUserLeavesForm(
            () => {
              this.savedQueryForm.$submitted = true;

              onSubmitSavedQuery(savedQuery);
            },
            () => {
              onEditSavedQuery(savedQuery);
            },
          );
        } else {
          onEditSavedQuery(savedQuery);
        }
      }
    };

    this.onDelete = async savedQuery => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:DELETE');
      const headerText = await $translate('SAVED_QUERIES:MESSAGE:DELETE_CONFIRMATION_HEADER', {
        entityName: savedQuery.tag_name,
        entityValue: savedQuery.tag_value,
      });
      const bodyText = await $translate('SAVED_QUERIES:MESSAGE:DELETE_CONFIRMATION_BODY', {
        entityName: savedQuery.tag_name,
        entityValue: savedQuery.tag_value,
      });

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(() => deleteSavedQuery(savedQuery._id));
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    this.onQueryChanged = () => {
      const isQueryValid = bigidQueryObjectSerialization.validateQuery(this.savedQuery.query);
      this.savedQueryForm.tagQuery.$setValidity('invalidQuery', isQueryValid.valid);
    };

    this.savedQueriesFilter = criteria => savedQuery =>
      savedQuery.tag_name && savedQuery.tag_name.toLowerCase().indexOf(criteria.toLowerCase()) != -1;
  },
  bindings: {
    formOnly: '=',
    formData: '<',
    initialData: '<',
    onFormClosed: '&',
    onFormSubmitted: '&',
    onDataLoaded: '&',
    onDataLoading: '&',
  },
});
