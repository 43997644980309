import { useCallback, useEffect, useRef, useState } from 'react';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { CustomAppStatus } from '../../../CustomApp/views/ActivityLog/ActivityLog';
import { notificationService } from '../../../../services/notificationService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import {
  findAutoDiscoveryApp,
  getAutoDiscoveryInfo,
  getNewUISupportedApps,
} from '../../../../services/autoDiscoveryService';
import { CloudProvider } from '../../../AutoDiscovery/constants/types';

const INTERVAL_IN_MS = 30 * 1000;
const NOTIFICATION_DISCOVERY = `Discovery in progress, this could take some time, we will notify you when it's done`;

export enum DiscoveryAppType {
  GCP = 'gcp',
  AWS = 'aws',
  AZURE = 'azure',
}

export enum DiscoveryAppTypeToDisplayName {
  gcp = 'GCP',
  aws = 'AWS',
  azure = 'Azure',
}

export const isAutoDiscoveryAvailableInDs = (): boolean => getApplicationPreference('SHOW_AUTO_DISCOVERY_WIZARD');
export const isNewAutoDiscoveryAvailableInDs = (): boolean => getApplicationPreference('SHOW_NEW_AUTO_DISCOVERY_UI_FF');

const getIsAutoDiscoveryAvailable = async () => {
  try {
    if (isNewAutoDiscoveryAvailableInDs()) {
      const supportedAppsTypes = await getNewUISupportedApps();
      return supportedAppsTypes?.length && supportedAppsTypes[0].type;
    }
    const appData = await findAutoDiscoveryApp(CloudProvider.AWS);
    return !!appData && CloudProvider.AWS;
  } catch (e) {
    console.warn(e);
    return false;
  }
};

export interface AutodiscoveryAppStatus {
  isAutoDiscoveryAvailable: boolean;
  isAutoDiscoveryCompleted: boolean;
  isLoadingAutoDiscoveryAvailability: boolean;
}

export const getAutoDiscoveryStatus = async (autoDiscoveryAvailable: CloudProvider | false) => {
  if (!autoDiscoveryAvailable) {
    return false;
  }
  const { status_enum } = (await getAutoDiscoveryInfo(CloudProvider.AWS)) || {};
  return status_enum;
};

export const getIsAutoDiscoveryInProgress = async (
  autoDiscoveryAvailable: CloudProvider | false = CloudProvider.AWS,
) => {
  const status_enum = await getAutoDiscoveryStatus(autoDiscoveryAvailable);
  return status_enum === CustomAppStatus.IN_PROGRESS;
};

export const getIsAutoDiscoveryComplete = async (autoDiscoveryAvailable: CloudProvider | false) => {
  const status_enum = await getAutoDiscoveryStatus(autoDiscoveryAvailable);
  return status_enum === CustomAppStatus.COMPLETED;
};

export const useAutoDiscoveryAppStatus = (shouldDisplayWarning?: boolean): AutodiscoveryAppStatus => {
  const [isAutoDiscoveryCompleted, setIsAutoDiscoveryCompleted] = useState(false);
  const [isLoadingAutoDiscoveryAvailability, setIsLoadingAutoDiscoveryAvailability] = useState(false);
  const [autoDiscoveryAvailable, setAutoDiscoveryAvailable] = useState<CloudProvider | false>(false);
  const interval = useRef(null);

  const checkIfAutoDiscoveryCompleted = useCallback(async () => {
    const shouldUpdateIsCompleted =
      (await getIsAutoDiscoveryComplete(autoDiscoveryAvailable)) && !isAutoDiscoveryCompleted;
    if (shouldUpdateIsCompleted) {
      setIsAutoDiscoveryCompleted(true);
    }
  }, [autoDiscoveryAvailable, isAutoDiscoveryCompleted]);

  useEffect(() => {
    const checkIfAutoDiscoveryAvailable = async () => {
      setIsLoadingAutoDiscoveryAvailability(true);
      const autoDiscoveryAvailable = await getIsAutoDiscoveryAvailable();
      setAutoDiscoveryAvailable(autoDiscoveryAvailable);
      setIsLoadingAutoDiscoveryAvailability(false);
    };
    checkIfAutoDiscoveryAvailable();
  }, []);

  useEffect(() => {
    const setIntervalToCheckForCompletedStateIfRunning = async () => {
      const isAutoDiscoveryInProgress = await getIsAutoDiscoveryInProgress(autoDiscoveryAvailable);
      if (isAutoDiscoveryInProgress) {
        interval.current = setInterval(checkIfAutoDiscoveryCompleted, INTERVAL_IN_MS);
      }
    };

    autoDiscoveryAvailable && setIntervalToCheckForCompletedStateIfRunning();
    return () => clearInterval(interval.current);
  }, [checkIfAutoDiscoveryCompleted, autoDiscoveryAvailable]);

  useEffect(() => {
    if (isAutoDiscoveryCompleted) {
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }
  }, [isAutoDiscoveryCompleted]);

  useEffect(() => {
    if (shouldDisplayWarning && autoDiscoveryAvailable) {
      notificationService.info(NOTIFICATION_DISCOVERY, { shouldAutoHide: false });
    }
  }, [shouldDisplayWarning, autoDiscoveryAvailable]);

  return {
    isAutoDiscoveryAvailable: !!autoDiscoveryAvailable,
    isAutoDiscoveryCompleted,
    isLoadingAutoDiscoveryAvailability,
  };
};
