import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidWizardLayout,
  BigidLoader,
  BigidForm,
  BigidFormRenderProps,
  PrimaryButton,
  BigidFormStateAndHandlers,
  BigidDialog,
  SecondaryButton,
} from '@bigid-ui/components';
import { DataSourceConfigurationSection } from './DataSourceConfigurationSection/DataSourceConfigurationSection';
import { DataSourceTestConnectionBox } from './DataSourceTestConnection/DataSourceTestConnectionBox';
import { DataSourceConfigurationContext } from './DataSourceConfigurationContext';
import {
  DataSourceConfigurationNoData,
  DEFAULT_NOT_FOUND_DS_TITLE,
  DEFAULT_NOT_FOUND_REASONS,
} from './DataSourceConfigurationNoData/DataSourceConfigurationNoData';
import { DataSourceDetailsBox } from './DataSourceDetailsBox/DataSourceDetailsBox';
import { DataSourceScanBox } from './DataSourceScanBox/DataSourceScanBox';
import { ConnectionSectionAdvancedInfo } from './DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionAdvancedInfo';
import { ConnectionSectionTopInfo } from './DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionTopInfo';
import { DataSourceEnabledStatus } from './DataSourceEnabledStatus/DataSourceEnabledStatus';

import { useDataSourceConfigState } from './hooks/useDataSourceConfigState';
import { useChangesMonitoring } from './hooks/useChangesMonitoring';
import { useSubmitDataSource } from './hooks/useSubmitDataSource';
import { MapTypeValueNormalize } from './utils/prepareDataSourceFormValueDataForSend';
import { useTestConnection } from './hooks/useTestConnection';
import { useStepsWithData } from './hooks/useStepsWithData';
import { useAfterValuesChange } from './hooks/useAfterValuesChange';
import { useSetTemplateConfigWithStateOverrides } from './hooks/useSetTemplateConfigWithStateOverrides';
import { useUpdateDetailsFilled } from './hooks/useUpdateDetailsFilled';
import { useGetDataSourceByName } from './hooks/useGetDataSourceByName';
import { useUpdateEnabledStatus } from './hooks/useUpdateEnabledStatus';

import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { isPermitted } from '../../../services/userPermissionsService';
import {
  DsConnectionFieldSectionsEnum,
  DataSourceConnectionFormField,
  DataSourceTemplateCondition,
  DsTypesEnum,
  DataSourceTypes,
} from './types';
import { $state } from '../../../services/angularServices';

import { CONFIG } from '../../../../config/common';
import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';
import { DetailsSectionTopInfo } from './DataSourceConfigurationSection/SectionsInfo/DetailsSection/DetailsSectionTopInfo';
import { resetDsWizardUsersCache } from './systemUsersCacheForFields';
import { useCustomNotification } from './hooks/useCustomNotification';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../../react/config/query';
import { DataSourceConnectionModal } from '../DataSourceConnectionModal/DataSourceConnectionModal';
import { DataSource, getSupportedDataSources } from '../../../../react/utilities/dataSourcesUtils';
import { useLocalTranslation } from '../DataSourceConnectionModal/translations';
import { useKey } from './hooks/useKey';
import { DataSourceConnectionStatus } from './DataSourceConnectionStatus';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { isNewConnectivityExperienceEnabled } from './utils/isNewConnectivityExperienceEnabled';
import { MainStepConnectionTab } from './DataSourceNewConnectivityConfiguration/DataSourceConnectionTab/MainStepConnectionTab';
import { SideStepConnectionTab } from './DataSourceNewConnectivityConfiguration/DataSourceConnectionTab/SideStepConnectionTab';
import { useTestConnection as useTestConnectionV2 } from '../../DataSources/DataSourceConnectionModal/hooks/useTestConnection';
import { useDataSourceModalNotification } from '../DataSourceConnectionModal/hooks/useDataSourceModalNotification';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { sseEventEmitter } from '../../../services/sseService';

interface DataSourcesConfigUrlParams {
  selectedType?: string;
  id?: string;
  isAfterSave?: boolean;
  redirectToNewConnectivity?: string;
  shouldOpenNewModal?: string;
  wizardStep?: DsConnectionFieldSectionsEnum;
  dsLabel?: string;
}

interface DataSourceConfigurationProps {
  $stateParams: DataSourcesConfigUrlParams;
}

interface DataSourceConfigurationStyleProps {
  isFullWidth: boolean;
}
const useStyles = makeStyles({
  dataSourceConfigurationWrapper: ({ isFullWidth }: DataSourceConfigurationStyleProps) => ({
    display: 'flex',
    height: 'calc(100% - 4px)',
    maxWidth: isFullWidth ? 'unset' : '1360px',
    '& > form': {
      width: '100%',
    },
  }),
  actionWrapper: {
    position: 'absolute',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    background: 'var(--bigid-tokens-bigid-pageBackground)',
    alignItems: 'center',
    gap: '0.5rem',
    top: '2px',
    right: '0',
    zIndex: '1',
    width: '400px',
  },
});

const queryClient = getQueryClient();

const getDataSourceByType = async (type: string): Promise<DataSource> => {
  const supportedDataSources = await getSupportedDataSources();
  const dsTypeRecord = supportedDataSources.filter(({ type: dsType }) => dsType === type);
  const [dataSourceInfo] = dsTypeRecord ?? [{} as DataSource];

  return dataSourceInfo;
};

export const DataSourceConfiguration: FC<DataSourceConfigurationProps> = ({ $stateParams }) => {
  const control = useRef<BigidFormStateAndHandlers>();
  const [showModal, setShowModal] = useState(false);
  const [formKey, resetForm] = useKey();
  const [editKey, resetEditForm] = useKey();
  const [showSaveBeforeEdit, setShowSaveBeforeEdit] = useState(false);
  const [documentationUrl, setDocumentationUrl] = useState<string>();
  const [dsDisplayName, setDsDisplayName] = useState<string>();
  const { updateState, generateInitValues, configDataSourceState } = useDataSourceConfigState();
  const { t } = useLocalTranslation();
  const [shouldAutoConnect, setShouldAutoConnect] = useState(
    $stateParams?.redirectToNewConnectivity === 'true' ?? false,
  );

  const {
    fields,
    initialValues,
    isLoading,
    isLoadingData,
    id,
    testStatus,
    selectedType,
    type,
    isSaveInProgress,
    lastTestDate,
    testInProgress,
    isNewPassword,
    encryptFields,
    scanCompleteDate,
    saveError,
    isScanSuccess,
    wasScanExecuted,
    isScopeSelected,
    notFoundMessage,
    enabled,
  } = configDataSourceState;
  const getFieldPropsFunction = useRef<BigidFormRenderProps['getFieldProps']>();
  const resetValuesToInitFunction = useRef<BigidFormRenderProps['clear']>();
  const createSetValueFunction = useRef<BigidFormRenderProps['setValue']>();
  const { dataSourceConfigurationWrapper, actionWrapper } = useStyles({ isFullWidth: !!notFoundMessage });
  const sourceType = $stateParams.selectedType || type;
  const isEditAvailable = $stateParams?.id
    ? isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name)
    : isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);

  const isNewExperienceEnabled = isNewConnectivityExperienceEnabled(sourceType);
  const sourceId = id || $stateParams?.id;
  const shouldAutoOpenModalAndConnect = isNewExperienceEnabled && shouldAutoConnect;

  const { emitTestConnectionError, emitTestConnectionSucceeded } = useDataSourceModalNotification({
    sourceId,
    source: $stateParams?.selectedType,
    control,
  });

  const { onTestHandler: onTestHandlerV2, addListenerToTestConnectionEvent } = useTestConnectionV2(
    { updateState, generateInitValues, configDataSourceState },
    control,
    {
      onSuccess() {
        emitTestConnectionSucceeded();
      },
      onError() {
        setShowModal(true);
        emitTestConnectionError();
      },
    },
  );

  const refreshData = useGetDataSourceByName({
    updateState,
    name: $stateParams?.id,
    fields,
  });

  const { activeStep, setActiveStep, goToNext, goToPrev, noPrev, noNext, steps } = useStepsWithData({
    lastTestDate,
    testStatus,
    scanCompleteDate,
    isScanSuccess,
    fields,
    initialStep:
      isNewExperienceEnabled && $stateParams?.wizardStep
        ? $stateParams?.wizardStep
        : DsConnectionFieldSectionsEnum.connection,
  });

  const { onSubmitHandler, validateAndSubmitRef } = useSubmitDataSource({
    fields,
    id: id || $stateParams?.id,
    selectedType: selectedType || $stateParams?.selectedType,
    updateState,
    getFieldPropsFunction,
    refreshData,
    initialValues,
    shouldTriggerNotification: !isNewExperienceEnabled,
  });

  const { isTouched, getValuesContainer } = useChangesMonitoring(
    initialValues,
    onSubmitHandler,
    getFieldPropsFunction,
    fields,
    saveError,
  );

  const { updateTestAvailable, onTestHandler, getHighlightedWordsForLog } = useTestConnection({
    fields,
    updateState,
    getValuesContainer,
    getFieldPropsFunction,
    isNewPassword,
    encryptFields,
    initialValues,
    getApplicationPreference,
    sseEventEmitter,
  });

  const updateDetailsFilled = useUpdateDetailsFilled({ fields, updateState, isScopeSelected });
  const updateEnabledStatus = useUpdateEnabledStatus(updateState);
  const { additionalNotification: notificationOcr } = useCustomNotification(fields, 'enabledOcr', 'ocrParameters');
  const { additionalNotification: extractArchives } = useCustomNotification(
    fields,
    'extractArchives',
    'typeRequestProperties',
  );

  const onFormValuesChange = useAfterValuesChange([
    notificationOcr,
    extractArchives,
    updateDetailsFilled,
    updateTestAvailable,
    updateEnabledStatus,
  ]);

  useEffect(() => {
    if (shouldAutoOpenModalAndConnect || $stateParams?.shouldOpenNewModal) {
      setShowModal(true);
    }
  }, [$stateParams, shouldAutoOpenModalAndConnect]);

  useEffect(() => {
    if (!isLoading) {
      const title = `${$stateParams.id ? $stateParams.id : 'New'} ${
        dsDisplayName || $stateParams.selectedType || selectedType || ''
      } Connection`;
      const breadcrumbs = [
        {
          label: $stateParams?.id ? 'Data Sources' : 'Select a Data Source',
          onClick: () =>
            $state.go($stateParams?.id ? CONFIG.states.DATA_SOURCE_CONNECTIONS : CONFIG.states.DATA_SOURCE_INIT, {
              path: DATA_SOURCES_INIT_VIEWS.selectDataType.path,
            }),
        },
        {
          label: title,
        },
      ];

      pageHeaderService.setTitle({
        defaultFrom: { state: CONFIG.states.DATA_SOURCE_ROUTER, params: {} },
        showBackButton: true,
        breadcrumbs,
        rightSideComponentsContainer: isNewExperienceEnabled ? (
          <DataSourceConnectionStatus status={testStatus} />
        ) : (
          <DataSourceEnabledStatus enabled={enabled} />
        ),
      });
    }
  }, [
    $stateParams,
    isLoading,
    selectedType,
    $stateParams.id,
    enabled,
    isNewExperienceEnabled,
    testStatus,
    dsDisplayName,
  ]);

  // @info add field overrides for new connectivity  experience
  useSetTemplateConfigWithStateOverrides(
    {
      updateState,
      generateInitValues,
      selectedType: $stateParams.selectedType || type,
      id: id || $stateParams?.id,
    },
    isNewExperienceEnabled,
  );

  useEffect(() => {
    resetDsWizardUsersCache();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { nameInDocs, displayName } = await getDataSourceByType($stateParams.selectedType || type);

        setDocumentationUrl(nameInDocs);
        setDsDisplayName(displayName);
      } catch {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // useTemplateStatusNotification hook has been temporary reverted BDT-65440

  const formRenderDepsRef = useRef<Record<string, any>>();
  // for prevent form re-render
  formRenderDepsRef.current = {
    activeStep,
    fields,
    getValuesContainer,
    goToNext,
    goToPrev,
    isTouched,
    noNext,
    noPrev,
    onSubmitHandler,
    onTestHandler,
    setActiveStep,
    steps,
    validateAndSubmitRef,
    getHighlightedWordsForLog,
    getFieldPropsFunction,
    isEditAvailable,
    saveError,
    wasScanExecuted,
    onTestHandlerV2,
  };

  const DataSourceConfigFormRender = useMemo(
    () =>
      ({
        setValue,
        renderField,
        getFieldProps,
        getValues,
        formTouched,
        validateAndSubmit,
        clear,
      }: BigidFormRenderProps) => {
        const {
          activeStep,
          fields,
          getValuesContainer,
          goToNext,
          goToPrev,
          isTouched,
          noNext,
          noPrev,
          onSubmitHandler,
          onTestHandler,
          setActiveStep,
          steps,
          validateAndSubmitRef,
          getHighlightedWordsForLog,
          isEditAvailable,
          saveError,
          wasScanExecuted,
          onTestHandlerV2,
        } = formRenderDepsRef.current;
        const renderSectionFields = (
          sectionName: DsConnectionFieldSectionsEnum,
          StartComponent?: FC<any>,
          expandsTitle?: string,
        ) => (
          <React.Fragment>
            <DataSourceConfigurationSection
              renderField={renderField}
              getFieldProps={getFieldProps}
              sectionName={sectionName}
              StartComponent={StartComponent}
              expandsTitle={expandsTitle}
              isEditAvailable={isEditAvailable}
              wasScanExecuted={wasScanExecuted}
            />
          </React.Fragment>
        );
        const isFieldsReadyToSave = (fields as DataSourceConnectionFormField[]).every(({ name, misc }) => {
          let isReady = true;
          if (!$stateParams?.id && misc?.mandatory) {
            const value = getFieldProps(name)?.value;
            isReady = value !== undefined && value !== '';
          }
          if (misc?.blockSaveIf) {
            const { blockSaveIf } = misc;
            isReady = !(blockSaveIf as DataSourceTemplateCondition[]).every(({ field, value }) => {
              const valueNormalized = MapTypeValueNormalize[getFieldProps(field)?.misc?.type as DsTypesEnum]?.(
                getFieldProps(field)?.value,
              );
              return valueNormalized === value;
            });
          }

          return isReady;
        });

        const isSaveAvailable = isEditAvailable && (formTouched || saveError) && isFieldsReadyToSave;
        isTouched.current = formTouched;
        getValuesContainer.current = getValues;
        validateAndSubmitRef.current = validateAndSubmit;
        getFieldPropsFunction.current = getFieldProps;
        resetValuesToInitFunction.current = clear;
        createSetValueFunction.current = setValue;

        const handleEditConnection = async () => {
          try {
            if (control.current.formTouched) {
              await control.current.validate();
              const isSuccess = await onSubmitHandler();

              if (isSuccess) {
                resetEditForm();
                setShowModal(true);
              }
              return;
            }

            resetEditForm();
            setShowModal(true);
          } catch {}
        };

        const connectionBoxRendered = isNewExperienceEnabled ? (
          <SideStepConnectionTab handleEditConnection={handleEditConnection} />
        ) : (
          <DataSourceTestConnectionBox
            onTest={() => {
              onTestHandler(getValues());
            }}
            getHighlightedWordsForLog={getHighlightedWordsForLog}
            isNotSaved={isSaveAvailable}
          />
        );

        const scanBoxRendered = (
          <DataSourceScanBox isNotSaved={isSaveAvailable} getHighlightedWordsForLog={getHighlightedWordsForLog} />
        );

        const mainContentSteps = {
          [DsConnectionFieldSectionsEnum.connection]: isNewExperienceEnabled ? (
            <QueryClientProvider client={queryClient}>
              <MainStepConnectionTab
                onTestAgain={() => {
                  onTestHandlerV2(getValues());
                }}
                handleEditConnection={handleEditConnection}
                addListenerToTestConnectionEvent={addListenerToTestConnectionEvent}
              />
            </QueryClientProvider>
          ) : (
            <React.Fragment>
              {renderSectionFields(DsConnectionFieldSectionsEnum.connection, ConnectionSectionTopInfo)}
              {renderSectionFields(
                DsConnectionFieldSectionsEnum.connectionAdvanced,
                ConnectionSectionAdvancedInfo,
                'Advanced',
              )}
            </React.Fragment>
          ),
          [DsConnectionFieldSectionsEnum.details]: renderSectionFields(
            DsConnectionFieldSectionsEnum.details,
            DetailsSectionTopInfo,
          ),
          [DsConnectionFieldSectionsEnum.scanSettings]: renderSectionFields(DsConnectionFieldSectionsEnum.scanSettings),
        };

        const sideContentSteps = {
          [DsConnectionFieldSectionsEnum.connection]: connectionBoxRendered,
          [DsConnectionFieldSectionsEnum.details]: <DataSourceDetailsBox />,
          [DsConnectionFieldSectionsEnum.scanSettings]: scanBoxRendered,
        };

        return (
          <div style={{ position: 'relative' }}>
            {isNewExperienceEnabled && (
              <div className={actionWrapper}>
                <PrimaryButton
                  dataAid="DataSourceConfiguration-save"
                  disabled={!isSaveAvailable}
                  text={t('buttons.connectionSave')}
                  onClick={() => onSubmitHandler()}
                  size="large"
                />
              </div>
            )}
            <BigidWizardLayout
              stepsMainContent={mainContentSteps}
              stepsSideContent={sideContentSteps}
              steps={steps}
              onNext={goToNext}
              onPrev={goToPrev}
              hideNext={noNext}
              hidePrev={noPrev}
              onSelect={setActiveStep}
              activeId={activeStep}
              onClose={() => {
                $state.go(CONFIG.states.DATA_SOURCE_ROUTER);
              }}
              onSubmit={isSaveAvailable && onSubmitHandler}
            />
          </div>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [$stateParams?.id, isNewExperienceEnabled],
  );

  const handleUpdateEditPageLocation = async (sourceId: string) => {
    await $state.go(
      CONFIG.states.CONFIG_DATA_SOURCE,
      { id: sourceId, shouldOpenNewModal: false },
      {
        location: 'replace',
      },
    );

    const history: History[] = sessionStorageService.get('history') || [];
    sessionStorageService.set('history', history.slice(0, -1));
  };

  const handleCloseModal = async (
    shouldReload?: boolean,
    opts?: { wizardStep?: DsConnectionFieldSectionsEnum; sourceId: string },
  ) => {
    const { sourceId, wizardStep } = opts ?? {};
    setShowModal(false);
    wizardStep && setActiveStep(wizardStep);
    if (shouldReload) {
      handleUpdateEditPageLocation(sourceId);
    } else {
      await refreshData();
      resetForm();
    }
  };

  const handleSubmit = async () => {
    try {
      await control.current.validate();
      const isSuccess = await onSubmitHandler();

      if (isSuccess) {
        setShowModal(true);
      }
    } catch {}
  };

  const handleEditWithoutSaving = () => {
    control.current.setAllTouched(false);
    resetForm();
    setShowModal(true);
    setShowSaveBeforeEdit(false);
  };

  const formProps = useMemo(
    () => ({
      fields,
      initialValues,
      renderForm: DataSourceConfigFormRender,
      onChange: onFormValuesChange,
      stateAndHandlersRef: control,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, initialValues, DataSourceConfigFormRender, onFormValuesChange],
  );

  const saveBeforeEditActions = [
    {
      component: SecondaryButton,
      dataAid: 'DataSourceConfiguration-edit',
      isClose: true,
      onClick: handleEditWithoutSaving,
      text: t('buttons.discard'),
    },
    {
      component: PrimaryButton,
      dataAid: 'DataSourceConfiguration-save',
      isClose: false,
      onClick: handleSubmit,
      text: t('buttons.save'),
    },
  ];
  return (
    <>
      <DataSourceConfigurationContext.Provider
        value={{
          ...configDataSourceState,
          updateState,
          onSubmitHandler,
          getValuesContainer,
          refreshData,
          isTouched,
          resetValuesToInitFunction,
          createSetValueFunction,
        }}
      >
        <div data-aid="DataSourceConfiguration" className={dataSourceConfigurationWrapper}>
          {(isLoading || isLoadingData || isSaveInProgress || (!isNewExperienceEnabled && testInProgress)) && (
            <BigidLoader />
          )}
          {!isLoading && initialValues && !notFoundMessage && <BigidForm key={formKey} {...formProps} />}
          <DataSourceConfigurationNoData title={DEFAULT_NOT_FOUND_DS_TITLE} reasonList={DEFAULT_NOT_FOUND_REASONS} />
        </div>
      </DataSourceConfigurationContext.Provider>
      <QueryClientProvider client={queryClient}>
        <DataSourceConnectionModal
          key={editKey}
          source={($stateParams.selectedType || type) as unknown as DataSourceTypes}
          sourceId={id || $stateParams?.id}
          dsName={$stateParams?.id || id}
          dsTypeLabel={$stateParams?.dsLabel}
          nameInDocs={documentationUrl}
          isOpen={showModal}
          isEdit
          onClose={handleCloseModal}
          shouldAutoConnect={shouldAutoOpenModalAndConnect}
          setShouldAutoConnect={setShouldAutoConnect}
        />
      </QueryClientProvider>
      {showSaveBeforeEdit && (
        <BigidDialog
          title={t('saveDataSourceBeforeEdit.header')}
          isOpen={showSaveBeforeEdit}
          buttons={saveBeforeEditActions}
          onClose={() => setShowSaveBeforeEdit(false)}
        >
          {t('saveDataSourceBeforeEdit.content')}
        </BigidDialog>
      )}
    </>
  );
};
