import React, { FC, useEffect, useReducer, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidLoader, BigidHeading2, BigidSubtitle } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { evaluateGettingStartedItemsStatus, evaluatePreselectedItem } from './GettingStartedService';
import { GettingStartedMenu } from './components/GettingStartedMenu';
import { GettingStartedSelectedCard } from './components/GettingStartedSelectedCard';
import { GettingStartedProgressBar } from './components/GettingStartedProgressBar';
import { GettingStartedFooter, gettingStartedDefaultFooterItems } from './components/GettingStartedFooter';
import { getInitialReducerState, GettingStartedReducer, ReducerActions } from './hooks/GettingStartedReducer';
import { GettingStartedContext } from './hooks/GettingStartedContext';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  mainTitle: {
    padding: '15px 20px',
    margin: '8px 0',
  },
  mainContainer: {
    paddingTop: 72,
    maxWidth: '900px',
    display: 'flex',
    flexDirection: 'column',
  },
  headerText: {
    paddingBottom: 32,
  },
  mainContentContainer: {
    boxShadow: `0px 0px 5px 0px ${theme.vars.palette.bigid.gray300}`,
    borderRadius: 5,
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    flexDirection: 'row',
  },
  footer: {
    padding: '36px 0',
  },
}));

export const GettingStartedWithSmallID: FC = () => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(GettingStartedReducer, {}, getInitialReducerState);
  const { t } = useLocalTranslation('main');

  useEffect(() => {
    const initGettingStartedItems = async () => {
      const items = await evaluateGettingStartedItemsStatus();
      const currentStepObject = evaluatePreselectedItem(items);
      const currentStep = currentStepObject?.name;
      dispatch({ type: ReducerActions.UPDATE_ITEMS, payload: { items, currentStepObject, currentStep } });
      setIsLoading(false);
    };
    pageHeaderService.setTitle({ pageTitle: 'Getting Started' });
    pageHeaderService.setIsHidden(true);

    initGettingStartedItems();
    return () => {
      pageHeaderService.setIsHidden(false);
    };
  }, []);

  return (
    <GettingStartedContext.Provider
      value={{
        currentStep: state.currentStep,
        items: state.items,
        currentStepObject: state.currentStepObject,
        dispatch,
      }}
    >
      <div className={classes.root} data-aid={generateDataAid('GettingStartedWithSmallID', ['root'])}>
        <div
          className={classes.mainContainer}
          data-aid={generateDataAid('GettingStartedWithSmallID', ['main', 'container'])}
        >
          {isLoading && <BigidLoader label="Loading..." />}
          {!isLoading && (
            <div data-aid={generateDataAid('GettingStartedWithSmallID', ['content'])}>
              <div className={classes.headerText} data-aid={generateDataAid('GettingStartedWithSmallID', ['title'])}>
                <BigidHeading2>{t('title')}</BigidHeading2>
                <BigidSubtitle>{t('subTitle')}</BigidSubtitle>
              </div>
              <div
                className={classes.mainContentContainer}
                data-aid={generateDataAid('GettingStartedWithSmallID', ['main', 'content'])}
              >
                <GettingStartedMenu />
                <GettingStartedSelectedCard />
              </div>
              <div className={classes.footer}>
                <GettingStartedProgressBar />
                <GettingStartedFooter items={gettingStartedDefaultFooterItems} />
              </div>
            </div>
          )}
        </div>
      </div>
    </GettingStartedContext.Provider>
  );
};
