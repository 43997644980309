import React, { FC, Fragment, Dispatch, SetStateAction, useEffect, useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { cloneDeep, isEmpty, isUndefined } from 'lodash';
import { BigidColors, BigidColorsV2, BigidTooltip, BigidBody1, BigidSwitch } from '@bigid-ui/components';
import { BigidCollapsable } from '../../../../components/BigidCollapsable/BigidCollapsable';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { CustomAppParam, InputTypes } from '../../views/EditCustomApp/EditCustomApp';
import { CustomAppValueInput } from './CustomAppValueInput';
import { isObjectTypeParam } from '../../utils/CustomAppUtils';

interface CustomAppParamsTableProps {
  params: CustomAppParam[];
  onParamChange: (key: string, value: string) => void;
  onParamToggle?: (paramKey: string, paramValue: string, enabled: boolean) => void;
  setIsValuesValid: Dispatch<SetStateAction<boolean>>;
  values?: Record<string, string>;
  readOnly?: boolean;
  shouldEnableOverriding?: boolean;
  isGlobalParams?: boolean;
}

type CategorizedParams = Record<string, CustomAppParam[]>;

const MANDATORY_ERROR_MESSAGE = 'Missing mandatory field';

const DEFAULT_PARAM_CATEGORY = 'General Parameters';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
  },
  mandatoryParam: {
    paddingLeft: '2px',
    color: BigidColorsV2.red[600],
  },
  missingValue: {
    color: BigidColors.failureRed,
  },
  paramInfo: {
    display: 'flex',
    height: '24px',
  },
  paramValue: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  description: {
    paddingLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  paramName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  paramValueSwitcher: {
    position: 'absolute',
    left: '100%',
  },
  categoryParams: {
    margin: '10px',
  },
});

const getIsValueValid = (param: CustomAppParam, value: string) => {
  const { isMandatory, inputItems, inputType } = param;
  if (isMandatory) {
    if ([InputTypes.MULTIPLE_SELECTION, InputTypes.SINGLE_ELECTION].includes(inputType)) {
      return value?.split(',').every(v => inputItems.includes(v));
    }
    return !isEmpty(value);
  }
  return true;
};
const getCategorizedParams = (params: CustomAppParam[]) => {
  return params.reduce((accumulatedCategorizedParams: CategorizedParams, currentParam: CustomAppParam) => {
    const paramCategory = currentParam.paramCategory || DEFAULT_PARAM_CATEGORY;
    if (!accumulatedCategorizedParams[paramCategory]) {
      accumulatedCategorizedParams[paramCategory] = [];
    }
    accumulatedCategorizedParams[paramCategory].push(currentParam);
    return accumulatedCategorizedParams;
  }, {} as CategorizedParams);
};

const normalizedCategorizedParams = (paramsInCategory: CustomAppParam[], values: Record<string, string>) => {
  return paramsInCategory
    .filter(param => !isObjectTypeParam(param))
    .map(param => {
      const { name, description, isMandatory, friendlyName } = param;
      const paramValue = values?.[name];
      const isValueValid = getIsValueValid(param, paramValue);
      const errorMessage = isValueValid ? '' : MANDATORY_ERROR_MESSAGE;
      return { ...param, name, description, isMandatory, friendlyName, errorMessage, paramValue };
    });
};

export const CustomAppParamsTable: FC<CustomAppParamsTableProps> = ({
  params = [],
  onParamChange,
  onParamToggle,
  setIsValuesValid,
  values: valuesBeforeAddingNewParams = {},
  readOnly = false,
  shouldEnableOverriding = false,
  isGlobalParams = false,
}) => {
  const categorizedParams = getCategorizedParams(params);

  const initialCollapseStates = () => {
    return Object.keys(categorizedParams).reduce((acc, category) => {
      acc[category] = true;
      return acc;
    }, {} as Record<string, boolean>);
  };

  const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>(initialCollapseStates);
  const classes = useStyles({});
  const initialValues = cloneDeep(valuesBeforeAddingNewParams);
  const values = useMemo(() => {
    return params.reduce(
      (acc, { defaultValue, value = '', name }) => {
        if (isUndefined(acc[name])) {
          acc[name] = defaultValue;
          if (isGlobalParams && value) acc[name] = value;
        }
        return acc;
      },
      isGlobalParams ? initialValues : valuesBeforeAddingNewParams,
    );
  }, [params, isGlobalParams, initialValues, valuesBeforeAddingNewParams]);

  useEffect(() => {
    for (const param of params) {
      if (!getIsValueValid(param, values?.[param.name])) {
        setIsValuesValid(false);
        return;
      }
    }
    setIsValuesValid(true);
  }, [params, setIsValuesValid, values]);

  const handleInputChange = useCallback(
    (name: string, value: string) => {
      onParamChange(name, value);
    },
    [onParamChange],
  );

  const onToggle = (paramKey: string, paramValue: string, enabled: boolean) => {
    if (enabled) {
      valuesBeforeAddingNewParams[paramKey] = paramValue;
    } else {
      delete valuesBeforeAddingNewParams[paramKey];
    }
    onParamToggle(paramKey, paramValue, enabled);
  };

  const grayOutInput = (paramName: string): boolean => {
    return readOnly || (isUndefined(valuesBeforeAddingNewParams[paramName]) && !isUndefined(onParamToggle));
  };

  return (
    <div>
      {Object.entries(categorizedParams).map(([category, paramsInCategory]) => {
        return (
          <Fragment key={category}>
            <BigidCollapsable
              leftHeader={category}
              isOpen={collapseStates[category]}
              onCollapseClick={() => {
                setCollapseStates(prevStates => ({
                  ...prevStates,
                  [category]: !prevStates[category],
                }));
              }}
            >
              <div className={classes.categoryParams}>
                {normalizedCategorizedParams(paramsInCategory, values).map((param, key) => {
                  return (
                    <Fragment key={key}>
                      <div className={classes.wrapper}>
                        <div className={classes.paramInfo}>
                          <BigidBody1 className={classes.paramName} title={param.friendlyName || param.name}>
                            {param.friendlyName || param.name}
                          </BigidBody1>
                          {param.isMandatory && <span className={classes.mandatoryParam}>*</span>}
                          <BigidTooltip title={param.description} placement={'top'}>
                            <div className={classes.description}>
                              <BigidInfoIcon />
                            </div>
                          </BigidTooltip>
                        </div>
                        <div className={classes.paramValue}>
                          <CustomAppValueInput
                            param={param}
                            onChange={handleInputChange}
                            paramValue={param.paramValue}
                            errorMessage={param.errorMessage}
                            readOnly={grayOutInput(param.name)}
                          />

                          {shouldEnableOverriding && (
                            <div className={classes.paramValueSwitcher}>
                              <BigidSwitch
                                checked={!isUndefined(valuesBeforeAddingNewParams[param.name])}
                                onChange={() =>
                                  onToggle(
                                    param.name,
                                    param.value,
                                    isUndefined(valuesBeforeAddingNewParams[param.name]),
                                  )
                                }
                                disabled={readOnly}
                                rightLabel="override"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </BigidCollapsable>
          </Fragment>
        );
      })}
    </div>
  );
};
