import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumn,
  ActionsFormatterAlignment,
  FormatterOnClickHandlerPayloadData,
} from '@bigid-ui/grid';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import angular from 'angular';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { dateUtils, $state, $translate } from '../../../services/angularServices';
import { notificationService } from '../../../services/notificationService';
import { EntityEvents, entityEventsEmitter, BigidLoader } from '@bigid-ui/components';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import * as dsarRequestsTypes from './dsarRequestsTypes';

import { SarScansDialog } from '../SarProfileSettings/SarScansDialog/SarScansDialog';
import * as dsarService from '../dsarService';
import {
  BigidDeleteIcon,
  BigidDownloadIcon,
  BigidReportIcon,
  BigidStopIcon,
  BigidPauseIcon,
  BigidResumeIcon,
} from '@bigid-ui/icons';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { openSearchAttributesInfoDialog } from '../../../components/SearchAttributesInfo/searchAttributesInfoService';
import { throttle, isEmpty } from 'lodash';
import { SSE_EVENTS, subscribeToRepeatedSSEEventById } from '../../../services/sseService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { getDownloadReportDialog } from './DsarDownloadReport';
import * as actionDialogHandler from './DsarRequestsActionsDialogsHandlers';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { openCollectFilesFlowDialog, PartsInfo, RetrieveFilesProgress } from './Dialogs/RetrieveFilesDialogSelector';
const useStyles = makeStyles({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    boxShadow: 'none',
    padding: '20px 20px 10px',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexFlow: 'column nowrap',
    overflow: 'hidden',
  },
});

export enum DownloadReportType {
  Full = 'Full',
  Short = 'Short',
}

enum Status {
  COMPLETED = 'Completed',
  PENDING = 'Started',
}

const updateThrottleBuffer = 10000;

export const DsarRequestsGrid: FC<dsarRequestsTypes.DsarRequestsGridProps> = ({ status }) => {
  const classes = useStyles({});
  const [profiles, setProfiles] = useState<{ _id: string }[]>([]);
  const isStatusCompleted = status === Status.COMPLETED;
  const isStatusPending = status === Status.PENDING;

  const calcRetrievedFiles = useCallback((retrievalFilesInfo: PartsInfo): RetrieveFilesProgress | undefined => {
    let _total = 0;
    let _completed = 0;
    let _failed = 0;

    for (const key of Object.keys(retrievalFilesInfo)) {
      const { total, completed, failed } = retrievalFilesInfo[key].partsInfo;
      _total += total ?? 0;
      _completed += completed ?? 0;
      _failed += failed ?? 0;
    }
    return {
      total: _total,
      percentage:
        Object.keys(retrievalFilesInfo).length === 0 ? 0 : Math.round(((_completed + _failed) / _total) * 100),
    };
  }, []);

  const buildRetrievedFiles = useCallback(
    (retrievalFilesInfo: PartsInfo, isSupportRetrieveFiles: boolean): string => {
      if (!isSupportRetrieveFiles) return '';

      if (!retrievalFilesInfo || retrievalFilesInfo === null) {
        return 'Retrieve Files';
      }

      if (isEmpty(retrievalFilesInfo)) {
        return '0 Completed';
      }

      const { percentage, total } = calcRetrievedFiles(retrievalFilesInfo);
      return percentage === 100 ? `${total} Completed` : `In Progress (${percentage}%)`;
    },
    [calcRetrievedFiles],
  );

  const getRequestsGridColumns = useCallback(
    (status: dsarService.DsarScanState, profiles: { _id: string }[]) => {
      const columns: BigidGridColumn<dsarRequestsTypes.DsarScanResultEntity>[] = [
        {
          name: 'identity_display_name',
          title: 'Display name',
          getCellValue: row => row.identity_display_name,
          type: BigidGridColumnTypes.TEXT,
          width: 170,
          sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
        },
        {
          name: 'identity_unique_id',
          title: 'Unique ID',
          getCellValue: row => row.identity_unique_id,
          type: BigidGridColumnTypes.TEXT,
          width: 160,
          sortingEnabled: getApplicationPreference('ENCRYPT_USER_PRIVATE_DATA_FF') === 'false',
        },
        {
          name: 'profile_name',
          title: 'Profile',
          getCellValue: row => (profiles?.find(profile => profile._id === row.profile_id) ? row.profile_name : ''),
          type: BigidGridColumnTypes.TEXT,
          width: 133,
        },
        {
          name: '_id',
          title: 'Request ID',
          formatter: {
            onClick: async ({ row }) => {
              openSearchAttributesInfoDialog(row.identity_display_name, row.request_id);
              return {};
            },
          },
          getCellValue: ({ request_id: requestId }) => ({
            link: {
              text: requestId,
            },
          }),
          type: BigidGridColumnTypes.LINK,
          width: 160,
        },
        {
          name: 'bulkId',
          title: 'Bulk ID',
          getCellValue: row => row.bulkId,
          type: BigidGridColumnTypes.TEXT,
          width: 160,
          sortingEnabled: false,
          isHiddenByDefault: true,
        },
        {
          name: 'created_at',
          title: 'Submit Date',
          filteringEnabled: false,
          getCellValue: row => row.created_at,
          type: BigidGridColumnTypes.DATE,
          width: 133,
        },
        {
          name: 'statuses_str',
          title: 'Progress',
          filteringEnabled: false,
          sortingEnabled: false,
          formatter: {
            onClick: async ({ row }) => {
              setSarScansDialogProps({
                isOpen: true,
                onClose: () =>
                  setSarScansDialogProps({
                    isOpen: false,
                    onClose: () => {
                      return;
                    },
                    requestId: '',
                    displayName: '',
                  }),
                requestId: row.request_id,
                displayName: row.identity_display_name,
              });
              return {};
            },
          },
          getCellValue: ({ statuses_str }) => ({
            link: {
              text: statuses_str,
            },
          }),
          type: BigidGridColumnTypes.LINK,
          width: 133,
        },
      ];
      if (isStatusCompleted) {
        columns.push(
          ...[
            {
              name: 'expires_on',
              title: 'Expires on',
              filteringEnabled: false,
              getCellValue: row => row.expires_on,
              type: BigidGridColumnTypes.DATE,
              width: 133,
            } as BigidGridColumn<dsarRequestsTypes.DsarScanResultEntity>,
            {
              name: 'record_count',
              title: 'Records',
              filteringEnabled: false,
              getCellValue: row => (row.record_count || 0) + (row.manual_record_count || 0),
              type: BigidGridColumnTypes.TEXT,
              width: 100,
            } as BigidGridColumn<dsarRequestsTypes.DsarScanResultEntity>,
          ],
        );
        if (!!getApplicationPreference('DSAR_SHOW_FILES_RETRIEVAL')) {
          columns.push({
            name: 'Retrieve Files',
            title: 'Retrieve Files',
            type: BigidGridColumnTypes.LINK,
            formatter: {
              onClick: async ({ row }) => {
                openCollectFilesFlowDialog(row, row.retrievalFilesInfo);
                return {};
              },
            },
            getCellValue: row => ({
              link: {
                dataAid: `DSAR-file-retrieval-link-${row.request_id}`,
                text: buildRetrievedFiles(row.retrievalFilesInfo, row.isSupportRetrieveFiles),
              },
              getCellValue: (row: dsarRequestsTypes.DsarScanResultEntity) => ({
                link: {
                  text: buildRetrievedFiles(row.retrievalFilesInfo, row.isSupportRetrieveFiles),
                },
              }),
              sortingEnabled: false,
              filteringEnabled: false,
              width: 133,
              isHiddenByDefault: true,
            }),
          });
        }
      }

      columns.push({
        name: 'Actions',
        title: 'Actions',
        filteringEnabled: false,
        sortingEnabled: false,
        width: 180,
        getCellValue: row => {
          return {
            alignment: ActionsFormatterAlignment.LEFT,
            actions: [
              {
                name: 'Stop',
                iconConfig: {
                  icon: BigidStopIcon,
                  isVisible: isStatusPending && isPermitted(DSAR_PERMISSIONS.STOP_REQUEST.name),
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  const isStopConfirmed = await showConfirmationDialog({
                    entityNameSingular: 'Data Subject Access Request',
                    actionName: 'Stop',
                    actionButtonName: 'Stop',
                    customDescription: `Are you sure you want to stop scan related to requestId: ${row.request_id}?`,
                  });
                  if (isStopConfirmed) {
                    actionDialogHandler.handleStopDialog(row.request_id);
                  }
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
              {
                name: 'Pause',
                iconConfig: {
                  icon: BigidPauseIcon,
                  isVisible:
                    isStatusPending &&
                    isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name) &&
                    !row?.bulkId &&
                    !row?.statuses?.Paused,
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  const isPauseConfirmed = await showConfirmationDialog({
                    entityNameSingular: 'Data Subject Access Request',
                    actionName: 'Pause',
                    actionButtonName: 'pause',
                    customDescription: `Are you sure that you want to initiate a pause process for the request ${row.request_id}?`,
                  });
                  if (isPauseConfirmed) {
                    actionDialogHandler.handlePauseDialog(row.request_id);
                  }
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
              {
                name: 'Resume',
                iconConfig: {
                  icon: BigidResumeIcon,
                  isVisible:
                    isStatusPending &&
                    isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name) &&
                    !row?.bulkId &&
                    !!row?.statuses?.Paused,
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  const isResumeConfirmed = await showConfirmationDialog({
                    entityNameSingular: 'Data Subject Access Request',
                    actionName: 'Resume',
                    actionButtonName: 'Resume',
                    customDescription: `Are you sure that you want to initiate a resume process for the request ${row.request_id}?`,
                  });
                  if (isResumeConfirmed) {
                    actionDialogHandler.handleResumeDialog(row.request_id);
                  }
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
              {
                name: 'Download',
                iconConfig: {
                  icon: BigidDownloadIcon,
                  isVisible: !row.data_expired && isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name),
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  if (isStatusPending) {
                    dsarService.getDsarFullReport(row.request_id, 'csv', false);
                    return {
                      shouldGridReload: false,
                      row,
                    };
                  }
                  getDownloadReportDialog(
                    row.request_id,
                    actionDialogHandler.handleDownloadDialog,
                    row.pii_record_count,
                  );
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
              {
                name: 'Delete',
                iconConfig: {
                  icon: BigidDeleteIcon,
                  isVisible: isPermitted(DSAR_PERMISSIONS.DELETE_REQUEST.name),
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  const isDeleteConfirmed = await showConfirmationDialog({
                    entityNameSingular: 'Data Subject Access Request',
                    actionName: 'Delete',
                    actionButtonName: 'Delete',
                    customDescription: `Are you sure you want to delete user data related to requestId: ${row.request_id}?`,
                  });
                  if (isDeleteConfirmed) {
                    actionDialogHandler.handleDeleteDialog(row.request_id, setLastUpdate);
                  }
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
              {
                name: $translate.instant('SUMMARY_REPORT'),
                iconConfig: {
                  icon: BigidReportIcon,
                  isVisible:
                    isStatusCompleted &&
                    !row.data_expired &&
                    (row.is_report_ready === null || row.is_report_ready) &&
                    isPermitted(DSAR_PERMISSIONS.READ_PERSONAL_INFO.name),
                },
                execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                  $state.go('personalInfoReport', {
                    userId: row.request_id,
                    targetTab: 'completed-subject-requests',
                    profileId: row.profile_id,
                    profileName: row.profile_name,
                  });
                  return {
                    shouldGridReload: false,
                    row,
                  };
                },
              },
            ],
          };
        },
        type: BigidGridColumnTypes.ACTIONS,
      });
      return columns;
    },
    [buildRetrievedFiles, isStatusCompleted, isStatusPending],
  );

  const userPrefStateName = `${$state.$current.name}.${status}`;
  const columns: BigidGridColumn<dsarRequestsTypes.DsarScanResultEntity>[] = useMemo(
    () => getRequestsGridColumns(status, profiles),
    [getRequestsGridColumns, status, profiles],
  );
  const { isReady, preferences, gridColumns, updatePreferences } = useUserPreferences({
    stateName: userPrefStateName,
    initialGridColumns: columns,
  });

  const [lastUpdate, setLastUpdate] = useState<string>('');

  const [sarScansDialogProps, setSarScansDialogProps] = useState<dsarRequestsTypes.SarScansDialogProps>({
    isOpen: false,
    onClose: () => {
      return;
    },
    requestId: '',
    displayName: '',
  });
  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<dsarRequestsTypes.DsarScanResultEntity>>(
    () => ({
      columns: gridColumns,
      showSortingControls: true,
      showFilteringControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: '_id', order: 'desc' }],
      onGridStateChange: nextGridState => updatePreferences({ gridState: { ...nextGridState, filter: undefined } }),
      entityName: 'Requests',
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        try {
          const { total, results } = await dsarService.getDsarResults(status, queryComponents);
          setLastUpdate(dateUtils.formatDate(new Date()));
          return {
            data: results,
            totalCount: total,
          };
        } catch (err) {
          const errMsg = `an error in fetch the DSAR results`;
          notificationService.error(errMsg);
          console.error(`${errMsg} ${err}`);
        }
      },
      toolbarActions: [],
    }),
    [gridColumns, preferences?.grid?.sort, status, updatePreferences],
  );

  const getHeader = () => {
    let header;
    if (isStatusCompleted) {
      header = 'Completed Requests';
    } else if (isStatusPending) {
      header = 'Pending Request';
    } else {
      header = 'Unknown State';
    }
    return header;
  };
  useEffect(() => {
    const update = throttle(() => {
      const payload = isStatusCompleted ? { entityId: Status.COMPLETED } : undefined;
      entityEventsEmitter.emit(EntityEvents.RELOAD, payload);
    }, updateThrottleBuffer);
    const unregisterSSEEvent = subscribeToRepeatedSSEEventById(SSE_EVENTS.SCAN_STATE_EVENT, update);

    return () => {
      unregisterSSEEvent();
      update.cancel();
    };
  }, [isStatusCompleted]);

  useEffect(() => {
    async function fetchProfiles() {
      const profiles = await dsarService.getProfiles();
      setProfiles(profiles);
    }

    fetchProfiles();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant={'h6'}>{getHeader()}</Typography>
        {lastUpdate && <Typography variant={'body2'}>Last Update: {lastUpdate}</Typography>}
      </Paper>
      {!isReady && <BigidLoader />}
      {isReady && <BigidGridWithToolbar {...gridWithToolbarConfig} />}
      <SarScansDialog
        isOpen={sarScansDialogProps.isOpen}
        onClose={sarScansDialogProps.onClose}
        parentScanId={sarScansDialogProps.requestId}
        displayName={sarScansDialogProps.displayName}
        gridId={isStatusCompleted ? Status.PENDING : undefined}
      />
    </div>
  );
};

angular.module('app').component('dsarRequestsGrid', convertToAngular(DsarRequestsGrid, ['status']));
