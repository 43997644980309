import React from 'react';
import { MtMigrationWrapper } from '../react/components/MtMigrationEmptyState/MtMigrationWrapper';
import { MtNotSupportedViews } from '../react/components/MtMigrationEmptyState/mtMigrationEmptyStateUtils';
import { ScanResultComponent } from './scanResult.component';

export const ScanResultMtWrapper = () => {
  return (
    <MtMigrationWrapper viewId={MtNotSupportedViews.REPORTS}>
      <ScanResultComponent />
    </MtMigrationWrapper>
  );
};
