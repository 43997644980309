import { MutableRefObject, useRef, useState } from 'react';
import { useDataSourceConfigState } from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import { useSubmitDataSource as useSubmitDataSourceV1 } from '../../DataSourceConfiguration/hooks/useSubmitDataSource';
import { prepareDataSourceFormValueDataForSend } from '../../DataSourceConfiguration/utils/prepareDataSourceFormValueDataForSend';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';

export type UseSubmitDataSourceConfig = {
  onSuccess?: (data?: unknown) => void;
  onError?: () => void;
  canUpsert?: boolean;
  upsertId?: string;
};

export const useSubmitDataSource = (
  { configDataSourceState, updateState }: ReturnType<typeof useDataSourceConfigState>,
  control: MutableRefObject<BigidFormStateAndHandlers>,
  { onSuccess, onError, canUpsert = false, upsertId }: UseSubmitDataSourceConfig = {},
) => {
  const [isSuccess, setSubmitSuccess] = useState(false);
  const [isError, setSubmitError] = useState(false);
  const [isSubmitted, setSubmitted] = useState(!!upsertId);

  const clearRequestStatus = () => {
    setSubmitSuccess(false);
    setSubmitError(false);
  };

  const handleSubmitSuccess = () => {
    setSubmitSuccess(true);
    setSubmitted(true);
    onSuccess?.();
  };

  const handleSubmitError = () => {
    setSubmitError(true);
    onError?.();
  };
  const getFieldPropsFunctionOverride = useRef<BigidFormStateAndHandlers['getFieldProps']>((fieldName: string) =>
    control.current?.getFieldProps(fieldName),
  );
  const dataSourceId = upsertId || configDataSourceState.id;

  const { onSubmitHandler, validateAndSubmitRef } = useSubmitDataSourceV1({
    fields: configDataSourceState.fields,
    id: canUpsert ? dataSourceId : null,
    updateState,
    getFieldPropsFunction: getFieldPropsFunctionOverride,
    refreshData: () => Promise.resolve(false), // @info only used for update operations
    shouldTriggerNotification: false,
  });

  validateAndSubmitRef.current = control.current?.validateAndSubmit;

  const getPayload = () =>
    prepareDataSourceFormValueDataForSend({
      fields: configDataSourceState.fields,
      values: control.current?.getValues?.(),
      getFieldPropsFunction: getFieldPropsFunctionOverride.current,
      isTest: false,
      isNewPassword: configDataSourceState?.isNewPassword,
      encryptFields: configDataSourceState?.encryptFields,
    });

  const handleSubmit = async (saveAsDraft = true) => {
    clearRequestStatus();

    const isSuccess = await onSubmitHandler(false, saveAsDraft);

    isSuccess ? handleSubmitSuccess() : handleSubmitError();

    return isSuccess;
  };

  return {
    onSubmitHandler: handleSubmit,
    errors: configDataSourceState.saveError,
    isLoading: configDataSourceState.isSaveInProgress,
    isSuccess,
    isError,
    isSubmitted,
    getPayload,
    data: configDataSourceState?.id ? { name: configDataSourceState.id } : null,
  };
};
