import React, { FC, useCallback, useMemo } from 'react';
import { CONFIG } from '../../../config/common';
import { MetadataSearch, MetadataSearchProps } from '../../components/MetadataSearch/MetadataSearch';
import {
  getMetadataSearchFilters,
  getMetadataSearchFilterSuggestions,
  MetadataSearchFetchFiltersPayload,
  MetadataSearchFetchFiltersResponse,
  MetadataSearchFetchFilterSuggestionsResponse,
  MetadataSearchFetchQuickResultsPayload,
  MetadataSearchFetchQuickResultsResponse,
} from '../../components/MetadataSearch/MetadataSearchService';
import {
  MetadataSearchEntityTypeConfig,
  MetadataSearchResultEntity,
  MetadataSearchTransitionPayload,
} from '../../components/MetadataSearch/MetadataSearchTypes';
import { previewSearchResultEntity } from '../../components/MetadataSearch/MetadataSearchUtils';
import { UseMetadataSearchInitialState } from '../../components/MetadataSearch/useMetadataSearchState';
import { userPreferencesService } from '../../services/userPreferencesService';
import { DataExplorerPageUserPreferences } from '../DataExplorer/types';
import { useLocalTranslation } from './translations';
import { updateDataCatalogSearchResultsPreferences } from './explorerSearchResultsService';
import { BigidColorsV2 } from '@bigid-ui/components';
import { useDataCatalogSearchResultsContext } from './contexts/dataCatalogSearchResultsContext';
import { WithDataAid } from './types';
import { $state } from '../../services/angularServices';

type DataExplorerSearchBarProps = WithDataAid<
  Pick<MetadataSearchProps, 'variant' | 'width' | 'placeholder' | 'isDisabled'>
> & {
  onSubmit?: (payload: MetadataSearchTransitionPayload) => void;
};

export const DataExplorerSearchBar: FC<DataExplorerSearchBarProps> = ({
  dataAid = 'DataExplorerSearchBar',
  variant,
  width,
  placeholder,
  isDisabled,
  onSubmit,
}) => {
  const { query, entityType } = useDataCatalogSearchResultsContext();

  const { t } = useLocalTranslation('searchBar');

  const fetchDataExplorerSearchFilterSuggestions = useCallback(
    async (payload: MetadataSearchFetchFiltersPayload): Promise<MetadataSearchFetchFilterSuggestionsResponse> => {
      const { promise } = getMetadataSearchFilterSuggestions(payload);
      const { data } = await promise;
      return data;
    },
    [],
  );

  const fetchDataExplorerSearchFilters = useCallback(
    async (payload: MetadataSearchFetchFiltersPayload): Promise<MetadataSearchFetchFiltersResponse> => {
      const { promise } = getMetadataSearchFilters(payload);
      const { data } = await promise;
      return data;
    },
    [],
  );

  const fetchDataExplorerRecentObjects = useCallback(async (): Promise<MetadataSearchResultEntity[]> => {
    try {
      const preferences = await userPreferencesService.get<DataExplorerPageUserPreferences>(
        CONFIG.states.METADATA_SEARCH_RESULTS,
      );

      return preferences?.data?.recentObjects || [];
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      return [];
    }
  }, []);

  const handleDataExplorerSearchResultEntityClick = async (
    entity: MetadataSearchResultEntity,
    payload: MetadataSearchTransitionPayload,
    entityTypeConfig: MetadataSearchEntityTypeConfig,
  ) => {
    previewSearchResultEntity(entity, payload, entityTypeConfig);
  };

  const saveDataExplorerSearchRecentObjects = useCallback(async (recentObjects: MetadataSearchResultEntity[]) => {
    await updateDataCatalogSearchResultsPreferences({ recentObjects });
  }, []);

  const handleDataExplorerSearchSubmit = (payload: MetadataSearchTransitionPayload) => {
    const params = {
      query: encodeURI(payload.text),
      activeTab: entityType || 'catalog',
    };
    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, params, { reload: false });

    onSubmit(payload);
  };

  const initState: UseMetadataSearchInitialState = useMemo(
    () => ({
      filters: [],
      query: decodeURI(query),
    }),
    [query],
  );

  const handleResetClick = useCallback(() => {
    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, { query: '' }, { reload: false });
  }, []);

  const defaultPlaceholder = t('placeholder');

  const fetchDataExplorerSearchResults = useCallback(
    async (payload: MetadataSearchFetchQuickResultsPayload): Promise<MetadataSearchFetchQuickResultsResponse> => {
      return Promise.resolve({
        typeResults: [],
      });
    },
    [],
  );

  return (
    <MetadataSearch
      dataAid={dataAid}
      saveRecentObjects={saveDataExplorerSearchRecentObjects}
      onSubmit={handleDataExplorerSearchSubmit}
      onResultEntityClick={handleDataExplorerSearchResultEntityClick}
      initialState={initState}
      variant={variant}
      fetchResults={fetchDataExplorerSearchResults}
      placeholder={placeholder || defaultPlaceholder}
      fetchFilterSuggestions={fetchDataExplorerSearchFilterSuggestions}
      fetchFilters={fetchDataExplorerSearchFilters}
      fetchRecentObjects={fetchDataExplorerRecentObjects}
      baseBackgroundColor={BigidColorsV2.white}
      focusedBorderColor={BigidColorsV2.purple[900]}
      onResetClick={handleResetClick}
      width={width}
      isDisabled={isDisabled}
      isFilterHidden
      isQuickSearchDisabled
    />
  );
};
