import React, { FC, useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import {
  BigidHeading1,
  BigidBody1,
  BigidLoader,
  PrimaryButton,
  useInterval,
  BigidColorsV2,
} from '@bigid-ui/components';
import { UseCatalogDiscoveryResponse } from '../useCatalogDiscovery';
import { useLocalTranslation } from './translations';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { MetadataSearchIndexingStatus } from '../../../components/MetadataSearch/MetadataSearchTypes';
import { notificationService } from '../../../services/notificationService';
import {
  getCatalogEntityIndexingStatusObject,
  startCatalogEntityIndexing,
  getIsAtLeastOneScanCompleted,
  getCatalogMDSearchEntityStatus,
  getIsAtLeastOneDataSourceConfigured,
} from '../catalogDiscoveryService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import {
  editServiceConfiguration,
  getServicesConfiguration,
  groupServicesConfigurationData,
} from '../../AdvancedTools/ServicesConfiguration/servicesConfigurationService';
import DataOverviewEmptyState from '../../../assets/icons/DataOverviewEmptyState.svg';

interface CatalogDiscoveryEmptyState extends Pick<UseCatalogDiscoveryResponse, 'onEmptyStateComputed'> {
  dataAid?: string;
}

enum ActionItemType {
  DATA_SOURCES = 'DATA_SOURCES',
  SCANS = 'SCANS',
  INDEXING = 'INDEXING',
}

enum ActionItemStatus {
  BLOCKED = 'BLOCKED',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

type ActionItem = {
  id: ActionItemType;
  status: ActionItemStatus;
  secondaryTitlePrefix: string;
  buttonText: string;
};

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;

const HeaderMainText = styled(BigidHeading1)`
  color: ${BigidColorsV2.gray[900]};
  size: 35px;
  line-height: 33px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
  text-align: center;
`;

const OverviewImageWrapper = styled('div')`
  margin-top: 30px;
  height: 50vh;
`;

const ActionItemButton = styled('div')`
  width: 160px;
`;

const Indexing = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const IndexingText = styled('div')`
  margin-top: 8px;
  text-align: center;
`;

const Computing = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function getDataSourcesActionItemStatus(isAtLeastOneDataSourceConfigured: boolean): ActionItemStatus {
  return isAtLeastOneDataSourceConfigured ? ActionItemStatus.COMPLETED : ActionItemStatus.NOT_STARTED;
}

function getCurrentStatus(actionItemsComputed: Map<ActionItemType, ActionItem>) {
  const isDsStatusCompleted =
    actionItemsComputed.get(ActionItemType.DATA_SOURCES).status === ActionItemStatus.COMPLETED;
  const isScansStatusCompleted = actionItemsComputed.get(ActionItemType.SCANS).status === ActionItemStatus.COMPLETED;

  if (isScansStatusCompleted && isDsStatusCompleted) {
    return actionItemsComputed.get(ActionItemType.INDEXING);
  } else if (isDsStatusCompleted) {
    return actionItemsComputed.get(ActionItemType.SCANS);
  } else {
    return actionItemsComputed.get(ActionItemType.DATA_SOURCES);
  }
}

function getScansActionItemStatus(
  isAtLeastOneScanCompleted: boolean,
  dataSourcesActionItemStatus: ActionItemStatus,
): ActionItemStatus {
  let status;

  if (dataSourcesActionItemStatus === ActionItemStatus.NOT_STARTED) {
    status = ActionItemStatus.BLOCKED;
  } else if (isAtLeastOneScanCompleted && dataSourcesActionItemStatus === ActionItemStatus.COMPLETED) {
    status = ActionItemStatus.COMPLETED;
  } else {
    status = ActionItemStatus.NOT_STARTED;
  }

  return status;
}

function getIndexingActionItemStatus(
  isMetadataSearchEnabled: boolean,
  catalogEntityStatus: MetadataSearchIndexingStatus,
  dataSourcesActionItemStatus: ActionItemStatus,
  scansActionItemStatus: ActionItemStatus,
): ActionItemStatus {
  let status;

  if (catalogEntityStatus === MetadataSearchIndexingStatus.INDEXING) {
    status = ActionItemStatus.IN_PROGRESS;
  } else if (
    (dataSourcesActionItemStatus === ActionItemStatus.COMPLETED &&
      scansActionItemStatus === ActionItemStatus.COMPLETED &&
      isMetadataSearchEnabled) ||
    !isMetadataSearchEnabled
  ) {
    status = ActionItemStatus.NOT_STARTED;
  } else {
    status = ActionItemStatus.BLOCKED;
  }

  return status;
}

export const CatalogDiscoveryEmptyState: FC<CatalogDiscoveryEmptyState> = ({
  dataAid = 'CatalogDiscoveryEmptyState',
  onEmptyStateComputed,
}) => {
  const { t } = useLocalTranslation();
  const [isComputingEmptyState, setIsComputingEmptyState] = useState<boolean>(true);
  const [actionItems, setActionItems] = useState<Map<ActionItemType, ActionItem>>(new Map());
  const [currentState, setCurrentState] = useState<ActionItem>();

  const notifyAboutPageReadiness = useCallback(
    (actionItems: Map<ActionItemType, ActionItem>) => {
      if (actionItems.size === Object.values(ActionItemType).length) {
        const isCompleted = [...actionItems]
          .map(actionItem => {
            const { status } = actionItem[1];
            return status;
          })
          .every(status => status === ActionItemStatus.COMPLETED);

        onEmptyStateComputed(!isCompleted);
      }
    },
    [onEmptyStateComputed],
  );

  const setActionItemStatus = useCallback(
    (actionItemType: ActionItemType, status: ActionItemStatus, shouldNotify?: boolean) => {
      setActionItems(prevActionItems => {
        const actionItem = prevActionItems.get(actionItemType);
        const updatedActionItems = prevActionItems.set(actionItemType, {
          ...actionItem,
          status,
        });

        if (shouldNotify) {
          notifyAboutPageReadiness(updatedActionItems);
        }

        return new Map(updatedActionItems);
      });
    },
    [notifyAboutPageReadiness],
  );

  const handleFetchIndexingStatusFailure = useCallback(
    (message?: string, status?: number) => {
      if (status === 503) {
        notificationService.error(t('notifications.indexing.503.body'), {
          title: t('notifications.indexing.503.title'),
          showCloseIcon: true,
        });
        setActionItemStatus(ActionItemType.INDEXING, ActionItemStatus.BLOCKED, true);
      } else {
        notificationService.error(t('notifications.indexing.5xx.body'), {
          title: t('notifications.indexing.5xx.title'),
          showCloseIcon: true,
        });
        setActionItemStatus(ActionItemType.INDEXING, ActionItemStatus.NOT_STARTED, true);
      }

      console.error(message ? 'An error has occurred while indexing the data' : `An error has occurred: ${message}`);
    },
    [setActionItemStatus, t],
  );

  const fetchIndexingStatus = useCallback(async () => {
    try {
      const catalogTypeStatus = await getCatalogEntityIndexingStatusObject();

      if (catalogTypeStatus) {
        let actionItemStatus: ActionItemStatus;

        switch (catalogTypeStatus.status) {
          case MetadataSearchIndexingStatus.INDEXING:
            actionItemStatus = ActionItemStatus.IN_PROGRESS;
            break;
          case MetadataSearchIndexingStatus.UPDATED:
            actionItemStatus = ActionItemStatus.COMPLETED;
            notificationService.success(t('notifications.indexing.completion.body'), {
              title: t('notifications.indexing.completion.title'),
              showCloseIcon: true,
            });
            break;
          default:
            actionItemStatus = ActionItemStatus.NOT_STARTED;
        }

        setActionItemStatus(ActionItemType.INDEXING, actionItemStatus, true);
      } else {
        notificationService.success(t('notifications.indexing.failure.body'), {
          title: t('notifications.indexing.failure.title'),
          showCloseIcon: true,
        });
      }
    } catch (message) {
      handleFetchIndexingStatusFailure(message, 500);
    }
  }, [handleFetchIndexingStatusFailure, setActionItemStatus, t]);

  const handleActionItemButtonClick = useCallback(
    async (id: ActionItemType) => {
      switch (id) {
        case ActionItemType.DATA_SOURCES:
          $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS);
          return;
        case ActionItemType.SCANS:
          $state.go(CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS);
          return;
        case ActionItemType.INDEXING:
          try {
            setActionItemStatus(ActionItemType.INDEXING, ActionItemStatus.IN_PROGRESS);
            const isMetadataSearchEnabled = getApplicationPreference('METADATA_SEARCH_ENABLED');

            if (!isMetadataSearchEnabled) {
              const services = await getServicesConfiguration();
              const [mdServiceConfig] = groupServicesConfigurationData(
                services.filter(({ name }) => name === 'METADATA_SEARCH_ENABLED'),
              );

              if (mdServiceConfig) {
                await editServiceConfiguration(mdServiceConfig, 'true');
              } else {
                handleFetchIndexingStatusFailure();
                setActionItemStatus(ActionItemType.INDEXING, ActionItemStatus.NOT_STARTED);
                return;
              }
            }

            await startCatalogEntityIndexing();
          } catch ({ message, response }) {
            handleFetchIndexingStatusFailure(message, response.status);
          }
          return;
      }
    },
    [handleFetchIndexingStatusFailure, setActionItemStatus],
  );

  useInterval(
    () => {
      fetchIndexingStatus();
    },
    actionItems.get(ActionItemType.INDEXING)?.status === ActionItemStatus.IN_PROGRESS ? 60000 : null,
  );

  useEffect(() => {
    async function computeEmptyState() {
      try {
        const isMetadataSearchEnabled = getApplicationPreference('METADATA_SEARCH_ENABLED');
        const isAtLeastOneDataSourceConfigured = await getIsAtLeastOneDataSourceConfigured();
        const isAtLeastOneScanCompleted = await getIsAtLeastOneScanCompleted();
        const catalogMDSearchEntityStatus = await getCatalogMDSearchEntityStatus();

        const isEmpty =
          !isAtLeastOneDataSourceConfigured ||
          !isAtLeastOneScanCompleted ||
          !isMetadataSearchEnabled ||
          !catalogMDSearchEntityStatus?.finishedIndexingAt;

        if (isEmpty) {
          const dsStatus = getDataSourcesActionItemStatus(isAtLeastOneDataSourceConfigured);

          const dataSourcesActionItem: ActionItem = {
            id: ActionItemType.DATA_SOURCES,
            status: dsStatus,
            secondaryTitlePrefix: t('actionItems.dataSources.secondaryTitlePrefix'),
            buttonText: t('actionItems.dataSources.buttonText'),
          };

          const scanStatus = getScansActionItemStatus(isAtLeastOneScanCompleted, dataSourcesActionItem.status);

          const scansActionItem: ActionItem = {
            id: ActionItemType.SCANS,
            status: scanStatus,
            secondaryTitlePrefix: t('actionItems.scans.secondaryTitlePrefix'),
            buttonText: t('actionItems.scans.buttonText'),
          };

          const indexingStatus = getIndexingActionItemStatus(
            isMetadataSearchEnabled,
            catalogMDSearchEntityStatus?.status,
            dataSourcesActionItem.status,
            scansActionItem.status,
          );

          const indexingActionItem: ActionItem = {
            id: ActionItemType.INDEXING,
            status: indexingStatus,
            secondaryTitlePrefix: t('actionItems.indexing.secondaryTitlePrefix'),
            buttonText: t('actionItems.indexing.buttonText'),
          };

          const actionItemsComputed: Map<ActionItemType, ActionItem> = new Map([
            [ActionItemType.DATA_SOURCES, dataSourcesActionItem],
            [ActionItemType.SCANS, scansActionItem],
            [ActionItemType.INDEXING, indexingActionItem],
          ]);

          const currState: ActionItem = getCurrentStatus(actionItemsComputed);

          setActionItems(actionItemsComputed);
          setCurrentState(currState);
          setIsComputingEmptyState(false);
        }

        onEmptyStateComputed(isEmpty);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        notificationService.error(t('notifications.generic.error.body'));
      }
    }

    computeEmptyState();
  }, [onEmptyStateComputed, t]);

  return isComputingEmptyState ? (
    <Computing data-aid={generateDataAid(dataAid, ['computing'])}>
      <BigidLoader
        position="relative"
        thickness={6}
        size={42}
        dataAid={generateDataAid(dataAid, ['computing', 'spinner'])}
      />
      <BigidBody1 data-aid={generateDataAid(dataAid, ['computing', 'text'])}>{t('computingSpinner.top')}</BigidBody1>
    </Computing>
  ) : (
    <>
      {actionItems.get(ActionItemType.INDEXING)?.status === ActionItemStatus.IN_PROGRESS ? (
        <Indexing data-aid={generateDataAid(dataAid, ['indexing'])}>
          <BigidLoader
            position="relative"
            thickness={6}
            size={42}
            dataAid={generateDataAid(dataAid, ['indexing', 'spinner'])}
          />
          <IndexingText>
            <BigidBody1 data-aid={generateDataAid(dataAid, ['indexing', 'text', 'top'])}>
              {t('indexingSpinner.top')}
            </BigidBody1>
            <BigidBody1 data-aid={generateDataAid(dataAid, ['indexing', 'text', 'bottom'])}>
              {t('indexingSpinner.bottom')}
            </BigidBody1>
          </IndexingText>
        </Indexing>
      ) : (
        <Root data-aid={dataAid}>
          <Header data-aid={generateDataAid(dataAid, ['header'])}>
            <HeaderMainText data-aid={generateDataAid(dataAid, ['header', 'main'])}>
              {t('headerFirstLine')}
              <br />
              {t('headerSecondLine')}
            </HeaderMainText>
            <BigidBody1 style={{ lineHeight: '23px' }} data-aid={generateDataAid(dataAid, ['header', 'secondary'])}>
              {currentState.secondaryTitlePrefix}
              {t('secondaryTitleFirstLine')}
              <br />
              {t('secondaryTitleSecondLine')}
            </BigidBody1>
          </Header>
          <ActionItemButton key={currentState.id}>
            <PrimaryButton
              width="fill"
              dataAid={generateDataAid(dataAid, [currentState.id, 'button'])}
              size="large"
              onClick={() => handleActionItemButtonClick(currentState.id)}
              disabled={currentState.status === ActionItemStatus.BLOCKED}
              text={currentState.buttonText}
            />
          </ActionItemButton>
          <OverviewImageWrapper>
            <DataOverviewEmptyState />
          </OverviewImageWrapper>
        </Root>
      )}
    </>
  );
};
