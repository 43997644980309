import React, { FC, useEffect, useMemo, useRef, useContext, MutableRefObject, SyntheticEvent, forwardRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidLoader,
  BigidForm,
  BigidFormRenderProps,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormField,
  BigidFormValues,
} from '@bigid-ui/components';

import { DataSourceConfigurationSection } from '../DataSourceConfiguration/DataSourceConfigurationSection/DataSourceConfigurationSection';
import { DataSourceTestConnectionBox } from '../DataSourceConfiguration/DataSourceTestConnection/DataSourceTestConnectionBox';
import { DataSourceConfigurationContext } from '../DataSourceConfiguration/DataSourceConfigurationContext';
import { DataSourceScanBox } from '../DataSourceConfiguration/DataSourceScanBox/DataSourceScanBox';
import { ConnectionSectionAdvancedInfo } from '../DataSourceConfiguration/DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionAdvancedInfo';

import { DataSourceConfigurationState } from '../DataSourceConfiguration/hooks/useDataSourceConfigState';
import { useChangesMonitoring } from '../DataSourceConfiguration/hooks/useChangesMonitoring';
import { useSubmitDataSource } from '../DataSourceConfiguration/hooks/useSubmitDataSource';
import { MapTypeValueNormalize } from '../DataSourceConfiguration/utils/prepareDataSourceFormValueDataForSend';
import { useTestConnection } from '../DataSourceConfiguration/hooks/useTestConnection';
import { useAfterValuesChange } from '../DataSourceConfiguration/hooks/useAfterValuesChange';
import { useUpdateDetailsFilled } from '../DataSourceConfiguration/hooks/useUpdateDetailsFilled';
import { useGetDataSourceByName as useGetDataSourceByNameV2 } from './hooks/useGetDataSourceByName';
import { useUpdateEnabledStatus } from '../DataSourceConfiguration/hooks/useUpdateEnabledStatus';

import { isPermitted } from '../../../services/userPermissionsService';
import {
  DsConnectionFieldSectionsEnum,
  DataSourceConnectionFormField,
  DataSourceTemplateCondition,
  DsTypesEnum,
} from '../DataSourceConfiguration/types';
import { sseEventEmitter } from '../../../services/sseService';
import { getApplicationPreference } from '../../../services/appPreferencesService';

import { DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { resetDsWizardUsersCache } from '../DataSourceConfiguration/systemUsersCacheForFields';

import { Section } from './DataSourceConnectionModalStyles';
import { useSetTemplateConfig as useSetTemplateConfigV2 } from './hooks/useSetTemplateConfig';
import { DataSourceConnectionModalContext } from './DataSourceConnectionModalProvider';
import { mapAdditionalPropsToBigidFormField } from './mappers/connection';

interface DataSourcesConfigUrlParams {
  selectedType?: string;
  id?: string;
  isAfterSave?: boolean;
}

interface DataSourceConnectionConfigurationProps {
  onFocus?: (e: SyntheticEvent<HTMLElement, FocusEvent>) => void;
  onChange?: (values: BigidFormValues) => void;
  onStateChange?: (state: DataSourceConfigurationState) => void;
  type?: string;
  sourceId?: string;
  control: MutableRefObject<BigidFormStateAndHandlers>;
  shouldForceRefreshFromSource?: boolean;
}

interface DataSourceConnectionConfigurationStyleProps {
  isFullWidth: boolean;
}

export type DataSourceConnectionConfigurationState = DataSourceConfigurationState & {
  fieldConfig?: BigidFormField[];
  documentationUrl: string;
  image: string;
};

const useStyles = makeStyles({
  dataSourceConfigurationWrapper: ({ isFullWidth }: DataSourceConnectionConfigurationStyleProps) => ({
    display: 'flex',
    height: 'calc(100% - 4px)',
    '& > form': {
      width: '100%',
    },
  }),
});

export const DataSourceConnectionConfiguration = forwardRef<HTMLDivElement, DataSourceConnectionConfigurationProps>(
  (
    { sourceId, onFocus, onChange, onStateChange, type: dsType, control, shouldForceRefreshFromSource = false },
    ref,
  ) => {
    const { updateState, generateInitValues, configDataSourceState, oAuthHandlers } = useContext(
      DataSourceConnectionModalContext,
    );

    const {
      fields,
      initialValues,
      isLoading,
      isLoadingData,
      id,
      testStatus,
      selectedType,
      type,
      isSaveInProgress,
      lastTestDate,
      testInProgress,
      isNewPassword,
      encryptFields,
      scanCompleteDate,
      saveError,
      isScanSuccess,
      wasScanExecuted,
      isScopeSelected,
      notFoundMessage,
      enabled,
      status,
    } = configDataSourceState as DataSourceConnectionConfigurationState;
    const getFieldPropsFunction = useRef<BigidFormRenderProps['getFieldProps']>();
    const resetValuesToInitFunction = useRef<BigidFormRenderProps['clear']>();
    const createSetValueFunction = useRef<BigidFormRenderProps['setValue']>();
    const { dataSourceConfigurationWrapper } = useStyles({ isFullWidth: !!notFoundMessage });

    const isEditAvailable = sourceId
      ? isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name)
      : isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name);

    const refreshData = useGetDataSourceByNameV2(
      {
        updateState,
        name: sourceId,
        fields,
      },
      { forceRefresh: shouldForceRefreshFromSource },
    );

    const handleFocusChange = (event: SyntheticEvent<HTMLElement, FocusEvent>) => onFocus?.(event);

    const { onSubmitHandler, validateAndSubmitRef } = useSubmitDataSource({
      fields,
      id: id,
      selectedType: selectedType || dsType,
      updateState,
      getFieldPropsFunction,
      refreshData,
      initialValues,
    });

    const { isTouched, getValuesContainer } = useChangesMonitoring(
      initialValues,
      onSubmitHandler,
      getFieldPropsFunction,
      fields,
      saveError,
      true,
    );

    const { updateTestAvailable, onTestHandler, getHighlightedWordsForLog } = useTestConnection({
      fields,
      updateState,
      getValuesContainer,
      getFieldPropsFunction,
      isNewPassword,
      encryptFields,
      initialValues,
      sseEventEmitter,
      getApplicationPreference,
    });

    const updateDetailsFilled = useUpdateDetailsFilled({ fields, updateState, isScopeSelected });
    const updateEnabledStatus = useUpdateEnabledStatus(updateState);

    const onFormValuesChange = useAfterValuesChange([updateDetailsFilled, updateTestAvailable, updateEnabledStatus]);

    const handleInputChange = (values: Record<string, any>) => {
      onFormValuesChange(values);
      onChange?.(values);
    };

    useSetTemplateConfigV2(
      {
        updateState,
        generateInitValues,
        selectedType: dsType || type,
        id: id,
        isBulkUpdate: true,
      },
      { getFieldProps: getFieldPropsFunction },
    );

    useEffect(() => {
      resetDsWizardUsersCache();
    }, []);

    useEffect(() => {
      onStateChange?.(configDataSourceState);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configDataSourceState]);

    const formRenderDepsRef = useRef<Record<string, any>>();
    // for prevent form re-render
    formRenderDepsRef.current = {
      fields,
      getValuesContainer,
      isTouched,
      onSubmitHandler,
      onTestHandler,
      validateAndSubmitRef,
      getHighlightedWordsForLog,
      getFieldPropsFunction,
      isEditAvailable,
      saveError,
      wasScanExecuted,
    };

    const DataSourceConfigFormRender = useMemo(
      () =>
        ({
          setValue,
          renderField,
          getFieldProps,
          getValues,
          formTouched,
          validateAndSubmit,
          clear,
        }: BigidFormRenderProps) => {
          const {
            fields,
            getValuesContainer,
            isTouched,
            onTestHandler,
            validateAndSubmitRef,
            getHighlightedWordsForLog,
            isEditAvailable,
            saveError,
            wasScanExecuted,
          } = formRenderDepsRef.current;
          const renderSectionFields = (
            sectionName: DsConnectionFieldSectionsEnum,
            StartComponent?: FC<any>,
            expandsTitle?: string,
          ) => (
            <Section width={sectionName === DsConnectionFieldSectionsEnum.connectionAdvanced ? '85%' : '60%'}>
              <DataSourceConfigurationSection
                className="dataSourceConfigurationSection"
                renderField={renderField}
                getFieldProps={getFieldProps}
                sectionName={sectionName}
                StartComponent={StartComponent}
                expandsTitle={expandsTitle}
                isEditAvailable={isEditAvailable}
                wasScanExecuted={wasScanExecuted}
              />
            </Section>
          );
          const isFieldsReadyToSave = (fields as DataSourceConnectionFormField[]).every(({ name, misc }) => {
            let isReady = true;
            if (!id && misc?.mandatory) {
              const value = getFieldProps(name)?.value;
              isReady = value !== undefined && value !== '';
            }
            if (misc?.blockSaveIf) {
              const { blockSaveIf } = misc;
              isReady = !(blockSaveIf as DataSourceTemplateCondition[]).every(({ field, value }) => {
                const valueNormalized = MapTypeValueNormalize[getFieldProps(field)?.misc?.type as DsTypesEnum]?.(
                  getFieldProps(field)?.value,
                );
                return valueNormalized === value;
              });
            }

            return isReady;
          });

          const isSaveAvailable = isEditAvailable && (formTouched || saveError) && isFieldsReadyToSave;
          isTouched.current = formTouched;
          getValuesContainer.current = getValues;
          validateAndSubmitRef.current = validateAndSubmit;
          getFieldPropsFunction.current = getFieldProps;
          resetValuesToInitFunction.current = clear;
          createSetValueFunction.current = setValue;

          const connectionBoxRendered = (
            <DataSourceTestConnectionBox
              onTest={() => {
                onTestHandler(getValues());
              }}
              getHighlightedWordsForLog={getHighlightedWordsForLog}
              isNotSaved={isSaveAvailable}
            />
          );

          const scanBoxRendered = (
            <DataSourceScanBox isNotSaved={isSaveAvailable} getHighlightedWordsForLog={getHighlightedWordsForLog} />
          );

          return (
            <>
              {renderSectionFields(DsConnectionFieldSectionsEnum.connection)}
              {renderSectionFields(
                DsConnectionFieldSectionsEnum.connectionAdvanced,
                ConnectionSectionAdvancedInfo,
                'Advanced',
              )}
            </>
          );
        },
      [sourceId],
    );

    const formProps: BigidFormProps = useMemo(
      () => ({
        initialValues,
        fields: fields.map(field => mapAdditionalPropsToBigidFormField(field, { onFocus: handleFocusChange })),
        renderForm: DataSourceConfigFormRender,
        onChange: handleInputChange,
        stateAndHandlersRef: control,
      }),
      [fields, initialValues, DataSourceConfigFormRender, onFormValuesChange, control],
    );

    return (
      <DataSourceConfigurationContext.Provider
        value={{
          ...configDataSourceState,
          updateState,
          onSubmitHandler,
          getValuesContainer,
          refreshData,
          isTouched,
          resetValuesToInitFunction,
          createSetValueFunction,
          oAuthHandlers,
        }}
      >
        <div ref={ref} className={`DataSourceConnectionConfiguration ${dataSourceConfigurationWrapper}`}>
          {isLoading && <BigidLoader />}
          {!isLoading && initialValues && !notFoundMessage && <BigidForm {...formProps} />}
        </div>
      </DataSourceConfigurationContext.Provider>
    );
  },
);
