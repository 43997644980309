import { httpRequest } from '../../../../config/request';
import type {
  CollaborationEventType,
  CollaborationItemRequest,
  CollaborationItemResponse,
  CreateDsCollaboratorItemRequest,
  CreateDsCollaboratorItemResponse,
  DeleteDsCollaboratorItemRequest,
  DeleteDsCollaboratorItemResponse,
  DsCollaboratorParams,
  GetDsCollaboratorItemResponse,
  RemindCollaboratorsItemRequest,
  RemindCollaboratorsItemResponse,
  UpdateDsCollaboratorItemRequest,
  UpdateDsCollaboratorItemResponse,
} from '../types';

export const API_VERSION = '/api/v1';
export const DS_COLLABORATOR_ENDPOINT = 'ds-connections-onboardings';

export const createCollaborationEvent = async <TEvent extends CollaborationEventType = CollaborationEventType>(
  dataSource: string,
  data: CollaborationItemRequest<TEvent>,
): Promise<CollaborationItemResponse> =>
  httpRequest<CollaborationItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'post',
    data,
  });

export const createDsCollaborator = async (
  dataSource: string,
  data: CreateDsCollaboratorItemRequest,
): Promise<CreateDsCollaboratorItemResponse> =>
  httpRequest<CreateDsCollaboratorItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'post',
    data,
  });

export const deleteDsCollaborator = async (
  dataSource: string,
  data: DeleteDsCollaboratorItemRequest,
): Promise<DeleteDsCollaboratorItemResponse> =>
  httpRequest<DeleteDsCollaboratorItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'post',
    data,
  });

export const getDsCollaborators = async (
  dataSource: string,
  params?: DsCollaboratorParams,
): Promise<GetDsCollaboratorItemResponse> =>
  httpRequest<GetDsCollaboratorItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'get',
    params,
  });

export const updateDsCollaborator = async (
  dataSource: string,
  data: UpdateDsCollaboratorItemRequest,
): Promise<UpdateDsCollaboratorItemResponse> =>
  httpRequest<UpdateDsCollaboratorItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'post',
    data,
  });

export const resendCollaborationInvite = async (
  dataSource: string,
  data: RemindCollaboratorsItemRequest,
): Promise<RemindCollaboratorsItemResponse> =>
  httpRequest<RemindCollaboratorsItemResponse>({
    url: `${DS_COLLABORATOR_ENDPOINT}/${dataSource}`,
    method: 'post',
    data,
  });
