import React, { FC, useContext } from 'react';
import { BigidBookIcon, BigidExternalLinkIcon } from '@bigid-ui/icons';
import { AutoDiscoverWizardAsideOptionItem } from '../AutoDiscoveryComponents/AutoDiscoverWizardAsideOptionItem';
import { AutoDiscoveryPrerequisitesContent } from '../../AutoDiscoveryWizardPrerequisitesModal/AutoDiscoveryPrerequisitesContent';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { getDiscoverAuthTypeString } from '../../autoDiscoveryWizardFormUtils';
import { TYPE_TO_FULL_DOCS_LINK } from '../../../constants';
import { AutoDiscoverWizardAsideGuideHowTo } from './AutoDiscoverWizardAsideGuideHowTo';
import { useLocalTranslation } from '../../../translations';
import { AccordionInfoPanel } from '../AutoDiscoveryComponents/AccordionInfoPanel/AccordionInfoPanel';
import { AccordionInfoPanelVariants } from '../AutoDiscoveryComponents/AccordionInfoPanel/styles';

export const AutoDiscoverWizardAsideGuide: FC = () => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const { discoveryConfigData, setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);

  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData?.authType);
  const isExpanded = [
    AutoDiscoverWizardAsideExpandedStates.HOW_TO_CONNECT,
    AutoDiscoverWizardAsideExpandedStates.PREREQUISITES,
    AutoDiscoverWizardAsideExpandedStates.OPENED,
  ].includes(discoveryConfigData.asideExpandedState);

  return (
    discoveryConfigData.formData.authType && (
      <AccordionInfoPanel
        dataAid={'AutoDiscoverWizardAsideGuide'}
        variant={AccordionInfoPanelVariants.guide}
        title={t('configModal.aside.connectionGuideTitle')}
        titleIcon={BigidBookIcon}
        endLinkIcon={BigidExternalLinkIcon}
        endLinkOnClick={() => {
          window.open(TYPE_TO_FULL_DOCS_LINK[discoveryConfigData.type], '_blank');
        }}
        endLinkTitle={t('configModal.aside.fullDocsLinkTitle')}
        onChange={() =>
          setDiscoveryConfigData(current => ({
            ...current,
            asideExpandedState: isExpanded
              ? AutoDiscoverWizardAsideExpandedStates.MINIMIZED
              : AutoDiscoverWizardAsideExpandedStates.OPENED,
          }))
        }
        expanded={isExpanded}
      >
        <AutoDiscoverWizardAsideOptionItem
          name={AutoDiscoverWizardAsideExpandedStates.PREREQUISITES}
          title={t('configModal.aside.optionTitlePrerequisites')}
        >
          <AutoDiscoveryPrerequisitesContent authType={authTypeValue} type={discoveryConfigData.type} />
        </AutoDiscoverWizardAsideOptionItem>

        <AutoDiscoverWizardAsideOptionItem
          name={AutoDiscoverWizardAsideExpandedStates.HOW_TO_CONNECT}
          title={t('configModal.aside.optionTitleHowToConnect')}
        >
          <AutoDiscoverWizardAsideGuideHowTo />
        </AutoDiscoverWizardAsideOptionItem>
      </AccordionInfoPanel>
    )
  );
};
