import React, { Fragment } from 'react';
import { BigidMenuItemProps } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { areSomePermitted } from '../../services/userPermissionsService';
import { CustomAppMenu } from './components/CustomAppMenu';
import { CONFIG } from '../../../config/common';
import { CustomAppPagesEnum, AppInfo } from './utils/CustomAppTypes';

export interface CustomAppHeaderProps {
  appInfo: AppInfo;
  pageName?: string;
  permissions: string[];
}

const navigateToPage = (appInfo: any, page: string) => {
  const params = { id: appInfo.id, appInfo };
  $state.go(page, params);
};

export const handleMenuItemSelect = (appInfo: any) => (item: BigidMenuItemProps) => {
  switch (item.id) {
    case CustomAppPagesEnum.UI:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP);
      break;
    case CustomAppPagesEnum.ACTIONS:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_ACTIONS);
      break;
    case CustomAppPagesEnum.ACTIVITY_LOG:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_ACTIVITY_LOG);
      break;
    case CustomAppPagesEnum.EDIT_APP:
      navigateToPage(appInfo, CONFIG.states.CUSTOM_APP_EDIT);
      break;
  }
};

export const CustomAppHeader = ({ appInfo, pageName, permissions }: CustomAppHeaderProps) => {
  const getTitle = () => {
    return pageName
      ? {
          breadcrumbs: [
            {
              label: appInfo.name,
              onClick: () => navigateToPage(appInfo, CONFIG.states.CUSTOM_APP),
            },
            {
              label: pageName,
            },
          ],
        }
      : { pageTitle: appInfo.friendlyName || appInfo.name || 'Custom App' };
  };

  const menu = (
    <Fragment>
      {permissions && areSomePermitted(permissions) && (
        <CustomAppMenu
          onItemSelect={handleMenuItemSelect(appInfo)}
          isInteractive={appInfo.isInteractive && !appInfo.hideUI}
          uiTabName={appInfo.uiTabName}
          popMenuDown
        />
      )}
    </Fragment>
  );

  pageHeaderService.setTitle({
    ...getTitle(),
    rightSideComponentsContainer: menu,
  });
};
