import React, { FC, Fragment, useState, useEffect } from 'react';
import styles from './AccountLicense.scss';
import { FormControl, TextField } from '@mui/material';
import { BigidDropZone, PrimaryButton, BigidRadio } from '@bigid-ui/components';
import { isPermitted } from '../../../../services/userPermissionsService';
import { GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';
import RadioGroup from '@mui/material/RadioGroup';

const INVALID_LICENSE_KEY_ERROR = 'Invalid Account license key';
const APP_LICENSE_KEY_ERROR = 'This is an application license key. Use the “Application Licenses” area instead';

export interface LicenseFormProps {
  onApply?: (licenseKey: string, licenseFile: File) => void;
  onFormChange?: (licenseKey: string, licenseFile: File) => void;
  error?: boolean;
  withButton?: boolean;
  withFile?: boolean;
  isAppLicenseError?: boolean | undefined;
}

const licenseKeyOptions = [
  {
    value: 'text',
    label: 'Type Account License Key',
  },
  {
    value: 'import',
    label: 'Upload Account License',
  },
];

export const LicenseForm: FC<LicenseFormProps> = ({
  onApply,
  error = false,
  withButton,
  onFormChange,
  withFile,
  isAppLicenseError,
}) => {
  const [licenseKey, setLicenseKey] = useState('');
  const [showError, setShowError] = useState(error);
  const [licenseFile, setLicenseFile] = useState<File>(null);
  const [shouldUseLicenseFile, setShouldUseLicenseFile] = useState<boolean>(false);

  useEffect(() => {
    setShowError(error);
  }, [error]);

  useEffect(() => {
    onFormChange?.(licenseKey, licenseFile);
  }, [licenseFile, licenseKey, onFormChange]);

  const handleOnDrop = (files: File[]) => {
    const file = files.length ? files[0] : undefined;
    setLicenseFile(file);
  };

  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    setShouldUseLicenseFile(event.target.value === 'import');
  };

  const handleLicenseKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKey(e.target.value);
  };

  const onClickApply = async () => {
    if (shouldUseLicenseFile) {
      if (!licenseFile) {
        return setShowError(true);
      }
    } else {
      if (!licenseKey) {
        return setShowError(true);
      }
    }
    setShowError(false);
    onApply?.(licenseKey, licenseFile);
  };

  return (
    <Fragment>
      <FormControl fullWidth margin="none">
        {withFile && (
          <RadioGroup
            defaultValue={licenseKeyOptions[0].value}
            row={true}
            aria-label={'license chooser'}
            name="LicenseFormRadioGroup"
            onChange={handleRadioGroupChange}
          >
            {licenseKeyOptions.map((option, index) => {
              return <BigidRadio key={index} {...option} />;
            })}
          </RadioGroup>
        )}
      </FormControl>
      {shouldUseLicenseFile ? (
        <FormControl fullWidth margin="dense">
          <BigidDropZone accept={['.txt']} files={licenseFile ? [licenseFile] : []} onDrop={handleOnDrop} />
        </FormControl>
      ) : (
        <TextField
          multiline
          rows="4"
          fullWidth
          onChange={handleLicenseKeyChange}
          placeholder="Enter account license key"
          error={showError}
          margin="none"
          variant="outlined"
          disabled={!isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_LICENSE.name)}
          id="licenseInput"
          sx={{
            '& legend': {
              display: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 5,
            },
          }}
        />
      )}
      {isPermitted(GENERAL_SETTINGS_PERMISSIONS.MANAGE_LICENSE.name) && (
        <Fragment>
          <div className={styles.errorWrapper}>
            {showError && (
              <div className={styles.error} data-aid={'license-error-message'}>
                <i className="fa fa-times" aria-hidden="true" />
                {isAppLicenseError ? APP_LICENSE_KEY_ERROR : INVALID_LICENSE_KEY_ERROR}
              </div>
            )}
          </div>
          {withButton && (
            <PrimaryButton
              disabled={shouldUseLicenseFile ? !licenseFile : !licenseKey}
              onClick={onClickApply}
              size="large"
              text="Apply"
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
