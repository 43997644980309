import { CloudProvider } from './types';
import { DiscoveryAuthTypes } from './auth';
import { publicUrls, docsUrls } from '../../../config/publicUrls';
import { BigidLinkProps } from '@bigid-ui/components';
import { findAutoDiscoveryApp } from '../../../services/autoDiscoveryService';

export interface PrerequisitesData {
  introduction?: string;
  content?: (BigidLinkProps | string)[][];
}

export const getAwsTemplateLink = async () => {
  const { version } = await findAutoDiscoveryApp(CloudProvider.AWS);
  return `${publicUrls.AWS_TEMPLATE_BASE}/home?region=us-east-1#/stacks/create/review?templateURL=${publicUrls.AWS_TEMPLATE_LATEST}`;
};

const MULTI_AWS_TEXTS = [
  [`Create a dedicated new AWS account or elect a restricted account for cross-scanning activities`],
  [
    'AWS Config must be enabled in all accounts where you need to discover resources (this is already the case if you use AWS Control Tower).',
  ],
  [
    'An multi-account and multi-region aggregator must exist. We recommend to create a Config aggregator with all regions and all accounts. Please see the : ',
    {
      href: `${publicUrls.AMAZON_BLOG}mt/org-aggregator-delegated-admin/`,
      text: 'following blog post to create one',
    },
  ],
  [
    'An IAM Role or User with the following policy attached for the autodiscovery app:',
    `
{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": "config:SelectAggregateResourceConfig",
            "Resource": "*"
        }
    ]
}`,
  ],
];

const AWS_DEPRECATED =
  'AWS is deprecating this authentication type. Consider authenticating with IAM Role authentication instead.';

const REGISTER_AZURE_APP = [
  `Register your application`,
  {
    href: publicUrls.AZURE_PORTAL,
    text: 'Azure portal',
  },
  ', ',
  {
    href: docsUrls.AZURE_APP_REGISTRATION,
    text: 'Doc',
  },
];
const AZURE_GET_MANAGEMENT_ID = [
  'Get your Management Group ID',
  {
    href:
      publicUrls.AZURE_PORTAL + '#view/Microsoft_Azure_ManagementGroups/ManagementGroupBrowseBlade/~/MGBrowse_overview',
    text: 'Azure portal',
  },
  ', ',
  {
    text: 'Doc',
    href: docsUrls.AZURE_MANAGED_IDENTITY,
  },
];

export const TYPE_TO_PREREQUISITES_CONTENT: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: ((args: any) => Promise<PrerequisitesData>) | PrerequisitesData;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.IAM_ROLE]: async () => ({
      introduction: 'Upload a Cloud Formation template to your AWS console.',
      content: [
        [
          'Share these configuration guidelines for automation of IAM roles creation with your AWS admin',
          {
            href: publicUrls.AWS_TEMPLATE_LATEST,
            text: 'configuration template',
          },
        ],
        [
          'If you are the admin of these AWS accounts and have a Cloud Formation template ready, skip this step. Go directly to your AWS console to upload the cloud formation template',
          {
            href: await getAwsTemplateLink(),
            text: 'your AWS console',
          },
        ],
      ],
    }),
    [DiscoveryAuthTypes.CREDENTIALS]: {
      introduction: AWS_DEPRECATED,
      content: [
        [
          `Add access policies with minimum or extended permissions for each data source that you want to include in the auto-discovery.`,
        ],
        [' Associate the access policy with the BigID application'],
      ],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      introduction: AWS_DEPRECATED,
      content: MULTI_AWS_TEXTS,
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      content: MULTI_AWS_TEXTS,
    },
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      content: [
        REGISTER_AZURE_APP,
        [
          'Get your Principal Name/ Client ID and Tenant ID / Subscription ID',
          {
            href: docsUrls.AZURE_CLIENT_ID,
            text: 'Doc',
          },
        ],
        [
          'Create a Client Secret (for the Principal Secret field)',
          {
            href: docsUrls.AZURE_CLIENT_SECRET,
            text: 'Doc',
          },
        ],
      ],
    },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: {
      content: [
        REGISTER_AZURE_APP,
        [
          'Get your Principal Name/ Client ID and Tenant ID / Subscription ID',
          {
            href: docsUrls.AZURE_CLIENT_ID,
            text: 'Doc',
          },
        ],
        [
          'Create a Client Secret (for the Principal Secret field)',
          {
            href: docsUrls.AZURE_CLIENT_SECRET,
            text: 'Doc',
          },
        ],
        AZURE_GET_MANAGEMENT_ID,
      ],
    },
    [DiscoveryAuthTypes.IAM_ROLE]: {
      introduction:
        'Azure provides two types of Managed Identity: \n 1. User-Assigned Managed Identity \n 2. System-Assigned Managed Identity',
      content: [
        REGISTER_AZURE_APP,
        [
          'Checks if Managed Identity Client ID is provided. If yes then uses User-Assigned Managed Identity with provided Client ID',
        ],
      ],
    },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: {
      introduction:
        'Azure provides two types of Managed Identity: \n 1. User-Assigned Managed Identity \n 2. System-Assigned Managed Identity',
      content: [
        REGISTER_AZURE_APP,
        [
          'Checks if Managed Identity Client ID is provided. If yes then uses User-Assigned Managed Identity with provided Client ID',
        ],
        AZURE_GET_MANAGEMENT_ID,
      ],
    },
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: {
      introduction: 'To build and run the application, you need:',
      content: [
        [
          'A GCP service account with access to all relevant GCP projects.',
          {
            href: docsUrls.AUTO_DISCOVERY_GCP_PREREQUISITES,
            text: 'Doc',
          },
        ],
        [
          'API and Services enabled in GCP for each project where you want to use the GCP Auto-Discovery app.',
          {
            href: publicUrls.GOOGLE_CLOUD_CONSOLE,
            text: 'Google Cloud console',
          },
        ],
        ['A service account key that can be used as a parameter for the BigID GCP Auto-Discovery application.'],
      ],
    },
  },
};
