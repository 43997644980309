import React, { FC, useEffect, useState, Fragment } from 'react';
import { Paper } from '@mui/material';
import { BigidColors, BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import {
  CREATE_CUSTOM_APP_DESCRIPTION,
  CREATE_CUSTOM_APP_PAGE_TITLE,
  CREATE_CUSTOM_APP_TITLE,
} from '../../config/consts';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { CreateAppPayload, customAppService } from '../../services/customAppService';
import { AddAppForm } from './AddAppForm';
import { notificationService } from '../../services/notificationService';
import { $state } from '../../services/angularServices';
import { BigidSelectDockerImagesOptions, DockerhubImageDetails } from '../CustomApp/utils/CustomAppTypes';
import { DropdownDockerhubForm } from './DropdownDockerhubForm';
import { NoVerificationDialog } from './NoVerificationDialog';
import { AppLicenseDialog } from './AppLicenseDialog';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CustomAppEvents, customAppEventsEmitter } from '../../services/customAppEvents';
import { CONFIG } from '../../../config/common';

const useStyles = makeStyles({
  paper: {
    height: 'calc(100% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    padding: '0 15%',
    overflow: 'auto',
  },
  title: {
    padding: '24px 0',
    color: BigidColors.gray[900],
    fontSize: '1.25rem',
  },
  description: {
    fontSize: '0.875rem',
    paddingBottom: 24,
  },
});

export const CreateCustomApp: FC = () => {
  const classes = useStyles({});
  const [appPayload, setAppPayload] = useState<CreateAppPayload>({ appUrl: '', isRemoteApplication: false });
  const [selectedAppToDeploy, setSelectedAppToDeploy] = useState<BigidSelectDockerImagesOptions[]>([]);
  const [dockerhubImages, setDockerHubImages] = useState<DockerhubImageDetails[]>([]);
  const [tpaOptions, setTpaOptions] = useState<BigidSelectDockerImagesOptions[]>(null);
  const deployTpaAutomaticallyEnabled = getApplicationPreference('DEPLOY_TPA_AUTOMATICALLY_ENABLED');
  const [isLoading, setLoading] = useState(false);
  const [noVerificationDialogOpen, setNoVerificationDialogOpen] = useState(false);
  const [hasEmptyVerificationKey, setHasEmptyVerificationKey] = useState(false);
  const [appLicenseDialogOpen, setAppLicenseDialogOpen] = useState(false);
  const [enableInstallButton, setEnableInstallButton] = useState<boolean>(true);

  const handleDeploy = async () => {
    try {
      setEnableInstallButton(false);
      const appImageName = selectedAppToDeploy[0].value;
      const selectedRepository = dockerhubImages.filter(repository => repository.repositoryName === appImageName)[0];
      await customAppService.deploySelectedTpa(appImageName, selectedRepository.fullImageName, selectedRepository.port);
      notificationService.success(`Installing app ${appImageName}`);
    } catch (error) {
      console.error(error);
      notificationService.error(`Something went wrong, got error:  ${error.response.data.error}`);
    }
    setEnableInstallButton(true);
  };

  const handleFormChange = (obj: Record<string, any>): void => {
    setAppPayload({ ...appPayload, ...obj });
  };

  const onSubmitForm = () => {
    setLoading(true);
    customAppService.createCustomAppUsingURL({
      createAppPayload: appPayload,
      onUrlIsNotValid: () => {
        setLoading(false);
        notificationService.error('Url is not valid');
      },
      onSuccess: async (tpaId, name, vendor) => {
        setLoading(false);
        await customAppService.handleAppLicenseAfterInstall();
        customAppEventsEmitter.emit(CustomAppEvents.UPDATE_APP_LIST);
        $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id: tpaId });
      },
      onError: message => {
        setLoading(false);
        notificationService.error(message);
      },
    });
  };

  const verifyCustomApp = async () => {
    setEnableInstallButton(false);
    setLoading(true);
    await customAppService.customAppVerification({
      verifyAppPayload: appPayload,
      onVerified: () => {
        setLoading(false);
        setAppLicenseDialogOpen(true);
      },
      onVerifiedWithLicense: async () => {
        setLoading(false);
        await onSubmitForm();
      },
      onNotVerifiedByBigId: emptyVerificationKey => {
        if (getApplicationPreference('ALLOW_UNVERIFIED_APPS')) {
          setLoading(false);
          setNoVerificationDialogOpen(true);
          setHasEmptyVerificationKey(emptyVerificationKey);
        } else {
          notificationService.error('The app you are trying to add is not verified by BigID');
        }
      },
      onError: message => {
        setLoading(false);
        notificationService.error(message);
      },
    });
    setEnableInstallButton(true);
  };

  useEffect(() => {
    if (deployTpaAutomaticallyEnabled) {
      setLoading(true);
      const getTpaImagesData = async () => {
        const { data } = await customAppService.getImageListFromDockerhub();
        const { tpaImagesList: tpaRepositoriesList } = data;
        const tpaOptions = tpaRepositoriesList.map((repository: DockerhubImageDetails) => {
          return {
            label: repository.repositoryName,
            value: repository.repositoryName,
            description: repository.description,
            port: repository.port,
          };
        });

        setDockerHubImages(tpaRepositoriesList);
        setTpaOptions(tpaOptions);
        setLoading(false);
      };
      getTpaImagesData();
    }
  }, [deployTpaAutomaticallyEnabled]);

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: CREATE_CUSTOM_APP_PAGE_TITLE });
  }, []);

  const closeNoVerificationDialog = () => setNoVerificationDialogOpen(false);
  const closeAppLicenseDialog = () => setAppLicenseDialogOpen(false);
  const onClickContinue = async () => {
    closeNoVerificationDialog();
    await onSubmitForm();
  };

  return (
    <Fragment>
      <AppLicenseDialog
        isOpen={appLicenseDialogOpen}
        onClose={closeAppLicenseDialog}
        onApply={onSubmitForm}
        appPayload={appPayload}
      />
      <NoVerificationDialog
        isOpen={noVerificationDialogOpen}
        onClose={closeNoVerificationDialog}
        onContinue={onClickContinue}
        hasEmptyVerificationKey={hasEmptyVerificationKey}
      />
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.content}>
          <div className={classes.title}>{CREATE_CUSTOM_APP_TITLE}</div>
          <div className={classes.description}>{CREATE_CUSTOM_APP_DESCRIPTION}</div>
          {deployTpaAutomaticallyEnabled && (
            <DropdownDockerhubForm
              onDeploy={handleDeploy}
              appOptions={tpaOptions}
              onChange={setSelectedAppToDeploy}
              selectedApp={selectedAppToDeploy}
              enableInstallButton={enableInstallButton}
            />
          )}
          <AddAppForm
            onClick={verifyCustomApp}
            onChange={handleFormChange}
            appPayload={appPayload}
            isLoading={isLoading}
            enableInstallButton={enableInstallButton}
          />
        </div>
      </Paper>
    </Fragment>
  );
};
