import React, { FC } from 'react';
import { BigidConnectedIllustration } from '@bigid-ui/icons';
import { styled } from '@mui/material';

import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { SecondaryButton, TertiaryButton, BigidHeading4 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourcesUITrackingEvent } from '../../../../DataSourcesEventTrackerUtils';

const Wrapper = styled('div')`
  margin-top: 72px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`;

export interface SideContentConnectedStatusProps {
  onEditConnection: () => Promise<void>;
}

export const SideContentConnectedStatus: FC<SideContentConnectedStatusProps> = ({ onEditConnection }) => {
  const { t } = useLocalTranslation();

  return (
    <Wrapper>
      <BigidConnectedIllustration width={144} height={144} />
      <BigidHeading4 textAlign={'center'}>{t('connectionTab.connectedSuccessful')}</BigidHeading4>
      <SecondaryButton
        margin="20px 0 8px 0"
        dataAid={generateDataAid('DataSourceConnectionTab', ['connected', 'view', 'connection'])}
        onClick={onEditConnection}
        text={t('buttons.viewConnectionDetails')}
        size="medium"
        bi={{
          eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_CONNECTION_DETAILS_CLICK,
        }}
      />
      <TertiaryButton
        dataAid={generateDataAid('DataSourceConnectionTab', ['connected', 'edit', 'connection'])}
        text={t('buttons.connectionEdit')}
        onClick={onEditConnection}
        size="medium"
        bi={{
          eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_VIEW_EDIT_CONNECTION_CLICK,
        }}
      />
    </Wrapper>
  );
};
