import React, { FC, useRef, useState, Fragment, ReactElement, useCallback, MouseEvent } from 'react';
import {
  BigidIconType,
  BigidColors,
  BigidMenu,
  BigidTooltip,
  PrimaryButton,
  BigidMenuSeverityEnum,
  BigidMenuItemProps,
  BigidHeading4,
  BigidBody1,
  BigidChip,
  BigidColorsV2,
  BigidBody2,
  BigidButtonIcon,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import BigidAppGenericIcon from '../../../assets/icons/BigidAppGeneric.svg';
import { $state } from '../../../services/angularServices';
import { appsLicenseService } from '../../../services/appsLicenseService';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { openSystemDialog } from '../../../services/systemDialogService';
import { noop, truncate } from 'lodash';
import { AboutApplicationModal } from './AboutApplicationModal';
import { tpaStatusService } from '../../../services/tpaStatusService';
import { isPermitted } from '../../../services/userPermissionsService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { customAppService } from '../../../services/customAppService';
import { notificationService } from '../../../services/notificationService';
import { deleteAppFromPreferences, InstallationDetails } from '../applicationManagementService';
import { CustomAppEvents, customAppEventsEmitter } from '../../../services/customAppEvents';
import {
  BigidMoreActionIcon,
  BigidSyncIcon,
  BigidBadgeIcon,
  BigidApplyFilledIcon,
  BigidStarIcon,
  BigidStarFilledIcon,
} from '@bigid-ui/icons';
import classnames from 'classnames';
import { LockItem } from '../../../components/LockItem/LockItem';
import { CONFIG } from '../../../../config/common';
import { TPATrackingEvents, trackTPAEvent } from '../../CustomApp/customAppEventsTrackerUtils';

const MAX_DESCRIPTION_LENGTH = 74;

interface ApplicationCardProps {
  id?: string;
  name: string;
  description?: string;
  icon?: BigidIconType;
  favorite?: boolean;
  custom?: boolean;
  to?: string;
  onClick?: () => void;
  link?: string;
  logo?: ReactElement;
  onSelectFavorite?: () => void;
  dataAid?: string;
  version?: string;
  status?: string;
  installationDetails?: InstallationDetails;
  isCertified?: boolean;
  isPaid?: boolean;
  isInstalled?: boolean;
  showMarketplaceProps?: boolean;
}

enum MoreMenuItemsEnum {
  LEARN_MORE = 'learnMore',
  VERSION = 'version',
  DELETE_APP = 'deleteApp',
}

const HEADER_HEIGHT = 44;
const FOOTER_HEIGHT = 30;
const APP_ICON_SIZE = 40;

const useStyles = makeStyles({
  wrapper: {
    borderRadius: 6,
    boxShadow: '0px 0px 5px 0px #00000026',
    paddingBottom: '24px',
    paddingTop: '24px',
    paddingLeft: '24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      boxShadow: '0px 8px 28px -6px #18274B1F',
    },
  },
  header: { display: 'flex', justifyContent: 'space-between', height: HEADER_HEIGHT, marginBottom: '8px' },
  headerSection: { display: 'flex', alignItems: 'center', paddingRight: '24px' },
  iconBorder: {
    borderRadius: 4,
    height: APP_ICON_SIZE,
    width: APP_ICON_SIZE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appIcon: {
    height: APP_ICON_SIZE,
    width: APP_ICON_SIZE,
  },
  refreshIcon: {
    marginLeft: '12px',
  },
  nameDisabled: { color: BigidColors.gray[500] },
  certified: {
    display: 'flex',
    color: BigidColorsV2.purple[900],
    alignItems: 'center',
    flexGrow: 1,
  },
  certifiedIcon: {
    flexShrink: 0,
  },
  description: { paddingTop: '8px', width: '90%' },
  installedChip: { paddingRight: '12px' },
  favoriteSection: { display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '12px', zIndex: 22 },
  content: {
    margin: '12px 4px 12px 0',
    flexGrow: 1,
    marginBottom: '16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: FOOTER_HEIGHT,
    marginRight: '6px',
    columnGap: '8px',
  },
  paidLabel: {
    '&:last-child': {
      paddingRight: '10px',
    },
  },
  moreIcon: { display: 'flex', alignItems: 'center', zIndex: 22 },
});

export const ApplicationCard: FC<ApplicationCardProps> = ({
  id,
  name,
  description,
  icon: Icon,
  favorite,
  custom,
  to,
  link,
  onClick,
  logo,
  onSelectFavorite,
  dataAid,
  version,
  status,
  installationDetails,
  isPaid,
  isCertified,
  showMarketplaceProps = false,
  isInstalled,
}) => {
  const classes = useStyles({});
  const moreIconRef = useRef(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const openMoreMenu = (e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setIsMoreMenuOpen(true);
    trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_MORE_INFO_CLICK, { appName: name });
  };
  const closeMoreMenu = () => setIsMoreMenuOpen(false);

  const handleSelectFavorite = (e: MouseEvent<SVGSVGElement>) => {
    onSelectFavorite();
    trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_FAVORITE_CLICK, { appName: name, isFavorite: !favorite });
    e.stopPropagation();
  };

  const openAboutModal = () => {
    trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_ABOUT_CLICK, { appName: name });
    openSystemDialog({
      title: 'About '.concat(name),
      onClose: noop,
      content: () => <AboutApplicationModal version={version} status={status} onRefreshApp={onRefreshAppClick} />,
      maxWidth: 'sm',
      borderTop: true,
      buttons: [
        {
          text: 'Close',
          onClick: noop,
          component: PrimaryButton,
          isClose: true,
        },
      ],
    });
  };

  const handleAppDeletion = useCallback(async () => {
    if (installationDetails?.installationType === 'Automatic') {
      try {
        await customAppService.uninstallTpa(installationDetails.appImageName);
        trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_DELETE_CLICK, { appName: name });
        await appsLicenseService.fetchAndSetAppLicense();
        notificationService.success(`Uninstalling app ${name}`);
      } catch (e) {
        notificationService.error(`Could not uninstall app ${name}, got error: ${e}`);
        return;
      }
    }

    await customAppService.deleteCustomApp(id);
    await deleteAppFromPreferences(id);
    await appsLicenseService.fetchAndSetAppLicense();
    customAppEventsEmitter.emit(CustomAppEvents.UPDATE_APP_LIST);
  }, [id, installationDetails?.appImageName, installationDetails?.installationType, name]);

  const onMoreMenuItemSelect = async (item: BigidMenuItemProps) => {
    switch (item.id) {
      case MoreMenuItemsEnum.LEARN_MORE:
        link && window.open(link, '_blank');
        break;
      case MoreMenuItemsEnum.VERSION:
        openAboutModal();
        break;
      case MoreMenuItemsEnum.DELETE_APP:
        const shouldDeleteApp = await showConfirmationDialog({
          entityNamePlural: 'none',
          entityNameSingular: `${name} App`,
        });
        shouldDeleteApp && handleAppDeletion();
        break;
    }
  };

  const canDeleteApp = () => isPermitted(APPLICATIONS_PERMISSIONS.DELETE_AND_ADD_CUSTOM_APPS.name) && custom;

  const getItems = () => {
    const items = [];
    if (!showMarketplaceProps || isInstalled) {
      !custom && items.push({ id: MoreMenuItemsEnum.LEARN_MORE, label: 'Learn more' });
      version && items.push({ id: MoreMenuItemsEnum.VERSION, label: 'About' });
      items.length && items.push({ divider: true });
      canDeleteApp() &&
        items.push({
          id: MoreMenuItemsEnum.DELETE_APP,
          label: 'Delete app',
          severity: BigidMenuSeverityEnum.DANGER,
        });
    }
    return items;
  };

  const onOpenClick = () => {
    trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_CARD_CLICK, { appName: name });
    onClick?.();
    to &&
      $state.go(to).then(() => {
        appsLicenseService.showAppExpirationNotification(to);
      });
  };

  const onRefreshAppClick = (e: MouseEvent<SVGSVGElement>) => {
    trackTPAEvent(TPATrackingEvents.TPA_MANAGEMENT_REFRESH_APP_CLICK, { appName: name });
    $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id, name }, { reload: true });
    e.stopPropagation();
  };
  const UpdateAppButton = () => {
    if (!tpaStatusService.isUpdateAvailable(status)) return <></>;

    return (
      <BigidTooltip title={'Update Available'} placement={'top'}>
        <>
          <BigidButtonIcon icon={BigidSyncIcon} className={classes.refreshIcon} onClick={onRefreshAppClick} />
        </>
      </BigidTooltip>
    );
  };

  const isEnabled = to ? smallIdLicenseService.isAccessGranted(to) : !smallIdLicenseService.isSmallIDLicense();
  const disableTooltip = !truncate(description, { length: MAX_DESCRIPTION_LENGTH }).includes('...');
  const nameDataAid = name.toLowerCase().replace(/\s/g, '-');
  return (
    <Fragment>
      <div className={classes.wrapper} onClick={onOpenClick} data-aid={dataAid}>
        <div className={classes.header}>
          <div className={classes.iconBorder}>
            {logo || (Icon ? <Icon size={APP_ICON_SIZE} /> : <BigidAppGenericIcon className={classes.appIcon} />)}
          </div>
          <div className={classes.headerSection}>
            {showMarketplaceProps && (
              <>
                {isInstalled && (
                  <div className={classes.installedChip}>
                    <BigidChip
                      bgColor={BigidColorsV2.gray[200]}
                      icon={<BigidApplyFilledIcon size="small" color="positive" />}
                      iconPlacement={'left'}
                      label={'Installed'}
                      variant={'outlined'}
                      size="small"
                    />
                  </div>
                )}
              </>
            )}
            {!showMarketplaceProps &&
              (isEnabled ? (
                <>
                  <UpdateAppButton />
                  <div className={classes.favoriteSection}>
                    <BigidButtonIcon
                      icon={favorite ? BigidStarFilledIcon : BigidStarIcon}
                      onClick={handleSelectFavorite}
                      dataAid={`select-favorite-${nameDataAid}`}
                      size="small"
                    />
                  </div>
                </>
              ) : (
                <LockItem dataAid={`lock-icon-${nameDataAid}`} size="md" background={BigidColors.gray[100]} />
              ))}
          </div>
        </div>
        <div className={classes.content}>
          <BigidHeading4 className={classnames(!isEnabled && classes.nameDisabled)}>{name}</BigidHeading4>
          {description && (
            <BigidBody1 className={classes.description}>
              <BigidTooltip placement={'bottom-end'} title={description} isDisabled={disableTooltip}>
                <span>{truncate(description, { length: MAX_DESCRIPTION_LENGTH })}</span>
              </BigidTooltip>
            </BigidBody1>
          )}
        </div>
        <div className={classes.footer}>
          <div className={classes.certified}>
            {isCertified && (
              <>
                <BigidBadgeIcon className={classes.certifiedIcon} color="currentColor" size="small" />
                <BigidBody2 color={'inherit'}>BigID Certified</BigidBody2>
              </>
            )}
          </div>
          <div className={classes.paidLabel}>
            <BigidBody1>{isPaid ? 'Premium' : 'Free'}</BigidBody1>
          </div>
          {!!getItems().length && (
            <div ref={moreIconRef} className={classes.moreIcon} data-aid={`more-actions-${name}`}>
              <BigidButtonIcon icon={BigidMoreActionIcon} onClick={openMoreMenu} />
            </div>
          )}
        </div>
      </div>
      <BigidMenu
        open={isMoreMenuOpen}
        anchorEl={moreIconRef.current}
        items={getItems()}
        onItemSelect={onMoreMenuItemSelect}
        onMenuClose={closeMoreMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Fragment>
  );
};
