import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BigidColorsV2, PrimaryButton, SecondaryButton, StyledButtonType, TertiaryButton } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { CredentialGridRow, CredentialTypes } from './types';
import { useLocalTranslation } from './translations';
import { getPermissions } from './utils';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidDateTime } from '@bigid-ui/i18n';
import { CredentialsDialogContent } from './CredentialsDialogContent';
import { BigidSystemDialogOptions, openSystemDialog } from '../../services/systemDialogService';

export type CredentialsFooterProps = {
  row: CredentialGridRow;
  isBusy: boolean;
  handleSubmit: () => void;
  handleDelete: () => void;
  handleTestConnection: () => void;
};

const Footer = styled.div`
  display: flex;
  height: 100%;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;
  flex-grow: 1;
`;

const UpdatedAt = styled.span`
  font-size: 12px;
  color: ${BigidColorsV2.gray[600]};
`;

export const CredentialsFooter: FC<CredentialsFooterProps> = ({
  row,
  isBusy,
  handleSubmit,
  handleDelete,
  handleTestConnection,
}) => {
  const { t } = useLocalTranslation();
  const { isDeletePermitted, isCreatePermitted, isEditPermitted, isTestConnectionPermitted } = getPermissions();
  const isTestConnectionAvailable = isTestConnectionPermitted && row.type !== CredentialTypes.BigID && !row?.isPending;

  const showConfirmDeleteDialog = () => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('modal.delete.title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: SecondaryButton,
          dataAid: generateDataAid('CredentialsFooter', ['cancel', 'delete']),
          isClose: true,
          onClick: noop,
          text: t('buttons.cancel'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('CredentialsFooter', ['confirm', 'delete']),
          isClose: true,
          disabled: !isDeletePermitted,
          onClick: handleDelete,
          text: t('buttons.delete'),
        },
      ],
      content: CredentialsDialogContent,
      contentProps: { body: t('modal.delete.body', { entityName: row.credential_id }) },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  };

  const testConnectionAction: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: PrimaryButton,
      dataAid: 'CredentialsFooter-testConnectionButton',
      onClick: handleTestConnection,
      color: 'grey',
      size: 'medium',
      text: t('buttons.testConnection'),
      disabled: isBusy,
    },
  ];

  const footerActions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: generateDataAid('CredentialsFooter', ['delete']),
      startIcon: <BigidDeleteIcon />,
      disabled: !isDeletePermitted || isBusy,
      onClick: () => showConfirmDeleteDialog(),
      color: 'grey',
      size: 'medium',
      text: t('buttons.delete'),
    },
    ...(isTestConnectionAvailable ? testConnectionAction : []),
    {
      component: PrimaryButton,
      dataAid: generateDataAid('CredentialsFooter', ['save']),
      disabled: (row?.isPending ? !isCreatePermitted : !isEditPermitted) || isBusy,
      onClick: handleSubmit,
      color: 'grey',
      size: 'medium',
      text: t('buttons.save'),
    },
  ];

  return (
    <Footer>
      {row.updated_at && (
        <UpdatedAt>
          {`${t('lastUpdated')}: `}
          <BigidDateTime date={row.updated_at}></BigidDateTime>
        </UpdatedAt>
      )}
      <Buttons>
        {footerActions.map(({ component: Component, ...rest }) => (
          <Component key={rest.dataAid} {...rest} />
        ))}
      </Buttons>
    </Footer>
  );
};
