import '../react/components/EditConfigurationModal/EditConfigirationModal';
import '../react/components/ColumnClusteringProgressGrid/ColumnClusteringProgressGrid';
import '../react/components/MetadataSearchIndexingProgressGrid/MetadataSearchIndexingProgressGrid';
import '../react/views/AdvancedTools/ExportCollection';
import './betaTools.component.scss';
import '../react/views/AdvancedTools/DeletePIIData';
import '../react/views/AdvancedTools/DeleteFindings';
import '../react/views/AdvancedTools/SupportPackage/ExportSupportPackage';
import '../react/views/AdvancedTools/HealthCheckStatusGrid/HealthCheckStatusGrid';
import { module } from 'angular';
import template from './betaTools.component.html';
import { areSomePermitted, isPermitted } from '../react/services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS, ADVANCE_TOOLS_PERMISSIONS, SCANS_PERMISSIONS } from '@bigid/permissions';
import { clearEntitiesCacheDialog } from '../react/views/AdvancedTools/ClearEntitiesCache';
import { reloadEntitiesCacheDialog } from '../react/views/AdvancedTools/ReloadEntitiesCache';
import '../react/views/AdvancedTools/ServicesConfiguration/ServicesConfiguration';
import { getApplicationPreference, initApplicationPreferences } from '../react/services/appPreferencesService';
import { $state } from '../react/services/angularServices';
import { invalidateRefreshTokenUUIDDialog } from '../react/views/AdvancedTools/InvalidateRefreshTokenUUID';
import { isMultiTenantModeEnabled } from '../react/utilities/multiTenantUtils';

const app = module('app');

app.component('betatools', {
  template,
  controllerAs: 'betaToolsModel',
  controller: function (
    $http,
    appSettings,
    betaToolsService,
    downloadFileService,
    $interval,
    $timeout,
    notificationService,
    $rootScope,
    $window,
    DeleteConfirmation,
    $uibModal,
    $translate,
    $q,
  ) {
    'ngInject';
    $rootScope.$broadcast('changePage', 'Advanced Tools', false);

    const betaToolsModel = this;
    betaToolsModel.showScannerLog = false;
    betaToolsModel.selectedGrid = null;
    betaToolsModel.loading = false;
    betaToolsModel.isMultiTenantModeEnabled = isMultiTenantModeEnabled();
    betaToolsModel.$onInit = () => {
      betaToolsModel.isClearEntitiesCachePermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name,
      );
      betaToolsModel.isReloadEntitiesCachePermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.MANAGE_CLEAR_ENTITIES_CACHE.name,
      );
      betaToolsModel.isMongoOperationsDownloadPermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.EXPORT_DOWNLOAD_MONGO_OPERATIONS.name,
      );
      betaToolsModel.isExportScanResultsPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SCAN_RESULT.name);
      betaToolsModel.isDeletePIIDataPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.DELETE_PII_DATA.name);
      betaToolsModel.isDeleteFindingsPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.DELETE_FINDINGS.name);
      betaToolsModel.isExportServiceLogsPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name);
      betaToolsModel.isExportServiceJsonLogsPermitted =
        getApplicationPreference('JSON_LOGS_CREATION') &&
        isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name);
      betaToolsModel.isExportSupportPackagePermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.EXPORT_DOWNLOAD_SUPPORT_PACKAGE.name,
      );
      betaToolsModel.isSystemHealthPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.READ_SYSTEM_HEALTH.name);
      betaToolsModel.isClearSystemHealthPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.CLEAR_SYSTEM_HEALTH.name);
      betaToolsModel.isRestartScannerPermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.RESTART_SCANNER_SYSTEM_HEALTH.name,
      );
      betaToolsModel.isServiceConfigurationPermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.READ_SERVICES_CONFIGURATION.name,
      );
      betaToolsModel.isEditServiceConfigurationPermitted = isPermitted(
        ADVANCE_TOOLS_PERMISSIONS.EDIT_SERVICES_CONFIGURATION.name,
      );
      betaToolsModel.isDeleteClusterResultsPermitted =
        getApplicationPreference('CLUSTERING_ENABLED') &&
        isPermitted(ADVANCE_TOOLS_PERMISSIONS.DELETE_CLUSTERS_RESULTS.name);
      betaToolsModel.isExportCollectionPermitted = areSomePermitted([
        ADVANCE_TOOLS_PERMISSIONS.STREAM_COLLECTIONS.name,
        ADVANCE_TOOLS_PERMISSIONS.EXPORT_SCAN_RESULT.name,
      ]);
      betaToolsModel.isExportDocvecsResultsPermitted =
        getApplicationPreference('CLUSTERING_ENABLED') &&
        isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_DOWNLOAD_DOCVECS_RESULTS.name);
      betaToolsModel.showNewServicesConfigurationGrid = getApplicationPreference('SHOW_NEW_SERVICES_CONFIGURATION_FF');

      betaToolsModel.isMetadataSearchIndexingProgressReadPermitted =
        isPermitted(ADVANCE_TOOLS_PERMISSIONS.METADATA_SEARCH_INDEXING_READ.name) &&
        getApplicationPreference('METADATA_SEARCH_ENABLED');

      betaToolsModel.isColumnClusteringActivityReadPermitted =
        isPermitted(ADVANCE_TOOLS_PERMISSIONS.COLUMN_CLUSTERING_ACTIVITY_READ.name) &&
        getApplicationPreference('SHOW_STRUCTURED_CLUSTERING');
      betaToolsModel.isPermittedInvalidateRefreshTokenUUID =
        isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name) &&
        getApplicationPreference('STORE_ORCH_REFRESH_UUID_TOKEN');
    };

    betaToolsModel.restartService = entity => {
      if (entity.isRefreshDisabled) {
        return;
      }

      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Restart',
        headerText: 'Restart service',
        bodyText: `Are you sure you want to restart ${entity.type} service with ID: ${entity.id}? Running scans will be aborted`,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        entity.isRefreshDisabled = true;
        const body = {
          id: entity.id,
          type: entity.type,
        };

        return betaToolsService.restartServer(body).then(
          result => {
            $translate('API:MESSAGE:RESTART_SERVICE_SUCCESS').then(translation =>
              notificationService.success(translation),
            );
          },
          () =>
            $translate('API:MESSAGE:RESTART_SERVICE_FAILED').then(translation =>
              notificationService.error(translation),
            ),
        );
      });
    };

    const getAvailableLogOptions = () => {
      const defaultServices = [
        'web',
        'orch',
        'orch2',
        'scanner',
        'corr-new',
        'clustering',
        'data-catalog',
        // 'data-catalog-consumer',
        'aci',
        'reports',
        'secure-sensitive',
        'ner',
        'metadata-search',
        'process-manager',
      ];

      if (
        isPermitted(ACTION_CENTER_PERMISSIONS.READ_ACTIONS.name) &&
        getApplicationPreference('ENABLE_ACTION_CENTER')
      ) {
        defaultServices.push('action-center');
      }

      if (getApplicationPreference('ENABLE_LINEAGE')) {
        defaultServices.push('lineage');
      }

      if (getApplicationPreference('ENABLE_REPORTING_ETL')) {
        defaultServices.push('reporting-etl');
      }

      if (getApplicationPreference('ENABLE_TENANT_SERVICE')) {
        defaultServices.push('tenant-service');
      }
      return defaultServices;
    };

    betaToolsModel.availableLogOptions = getAvailableLogOptions();

    const cellTemplate = '<div title="{{COL_FIELD}}" class="ui-grid-cell-contents">{{COL_FIELD}}</div>';

    betaToolsModel.servicesConfigurationGrid = {
      appScopeProvider: this,
      enableColumnMenus: false,
      enableSorting: true,
      enableHiding: false,
      enableFiltering: true,

      onRegisterApi: gridApi => {
        betaToolsModel.gridApi = gridApi;

        gridApi.core.on.filterChanged(null, () => {
          if (typeof betaToolsModel.uiGridScrollToTop === 'function') {
            betaToolsModel.uiGridScrollToTop();
          }
        });
      },
      columnDefs: [
        {
          name: 'Service Name',
          field: 'service',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
        },
        {
          name: 'Variable Name',
          field: 'name',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
        },
        {
          name: 'Value',
          field: 'value',
          filterHeaderTemplate: `<ui-grid-bigid-text-filter/>`,
        },
        {
          name: 'Actions',
          field: 'actions',
          enableSorting: false,
          enableCellEdit: false,
          enableFiltering: false,
          width: '100',
          cellTemplate: `
                <div class="actions-container" ng-if="grid.appScope.isEditServiceConfigurationPermitted">
                   <i class="fa fa-edit"
                   ng-click="grid.appScope.editConfiguration(row.entity)"
                   ng-if="row.entity.isEditable"
                   title="Edit"></i>
                </div>`,
        },
      ],
    };

    betaToolsModel.editConfiguration = configuration => {
      const { name: configurationName, service: serviceName, isRestartRequired } = configuration;

      $uibModal
        .open({
          template:
            '<edit-configuration-modal configuration="$ctrl.configuration" on-submit="$close" on-cancel="$dismiss"></edit-configuration-modal>',
          windowClass: 'edit-configuration-modal',
          backdrop: 'static',
          controllerAs: '$ctrl',
          controller: function () {
            this.configuration = configuration;
          },
        })
        .result.then(newConfigurationValue => {
          if (newConfigurationValue !== undefined) {
            const updatedConfiguration = { ...configuration, value: newConfigurationValue };
            betaToolsService
              .editConfiguration({ configuration: updatedConfiguration })
              .then(() => {
                configuration.value = newConfigurationValue;
                const successMessage = isRestartRequired ? 'SUCCESS_RESTART' : 'SUCCESS';

                $translate(`BETA_TOOLS:EDIT_CONFIGURATION:${successMessage}`, {
                  configurationName,
                  serviceName,
                }).then(translation => {
                  notificationService.success(translation, { shouldAutoHide: !isRestartRequired });
                  $rootScope.loaded = false;
                  initApplicationPreferences()
                    .then(() => {
                      return $state.reload();
                    })
                    .finally(() => {
                      $rootScope.loaded = true;
                    });
                });
              })
              .catch(e => {
                $translate('BETA_TOOLS:EDIT_CONFIGURATION:ERROR', { configurationName }).then(translation => {
                  notificationService.error(translation);
                });
                window.console.error(e);
              });
          }
        });
    };

    betaToolsModel.onGridScrollContextReady = context => {
      if (context && context.viewport && typeof context.scrollToTop === 'function') {
        betaToolsModel.uiGridScrollToTop = context.scrollToTop;
      }
    };

    betaToolsModel.retryCorrelations = () => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Run',
        headerText: 'Retry Correlations',
        bodyText: 'Are you sure you want to rerun running uncompleted correlations ?',
      };

      betaToolsModel.retryCorrelationsDisabled = true;

      DeleteConfirmation.showModal({}, modalOptions).then(
        result => {
          betaToolsService.retryCorrelations().then(
            result => {
              notificationService.success('Rerun correlations ended Successfully! ');
              $timeout(() => {
                betaToolsModel.retryCorrelationsDisabled = false;
              }, 10000);
            },
            result => {
              notificationService.success('An error has occurred! ');
            },
          );
        },
        () => {
          betaToolsModel.retryCorrelationsDisabled = false;
        },
      );
    };

    //Generate Data From Scan
    betaToolsModel.generateData = () => {
      betaToolsModel.generateDataButtonDisabled = true;
      betaToolsService.generateData().then(result => {
        notificationService.success('Generate Data From Scan Successfully! ');
        $timeout(() => {
          betaToolsModel.generateDataButtonDisabled = false;
        }, 30000);
      });
    };

    //Delete PII Data
    betaToolsModel.showDeletePIIDataDialog = () => {
      betaToolsModel.isDeletePIIDialogOpen = true;
    };
    betaToolsModel.closeDeletePIIDataDialog = () => {
      betaToolsModel.isDeletePIIDialogOpen = false;
      $rootScope.$apply();
    };

    betaToolsModel.showDeleteFindingsDialog = () => {
      betaToolsModel.isDeleteFindingsDialogOpen = true;
    };
    betaToolsModel.closeDeleteFindingsDialog = () => {
      betaToolsModel.isDeleteFindingsDialogOpen = false;
      $rootScope.$apply();
    };

    betaToolsModel.showExportSupportPackageDialog = () => {
      betaToolsModel.isExportSupportPackageDialogOpen = true;
    };

    betaToolsModel.closeExportSupportPackageDialog = () => {
      betaToolsModel.isExportSupportPackageDialogOpen = false;
      $rootScope.$apply();
    };

    betaToolsModel.setDisableDeleteFindings = disabled => {
      betaToolsModel.DeletePIIButtonDisabled = disabled;
      $rootScope.$apply();
    };

    betaToolsModel.clearEntitiesCache = () => {
      const setDisableClearEntitiesCache = disabled => {
        betaToolsModel.clearEntitiesCacheDisabled = disabled;
        $rootScope.$apply();
      };
      clearEntitiesCacheDialog(setDisableClearEntitiesCache);
    };
    betaToolsModel.reloadEntitiesCache = () => {
      const setDisableReloadEntitiesCache = disabled => {
        betaToolsModel.reloadEntitiesCacheDisabled = disabled;
        $rootScope.$apply();
      };
      reloadEntitiesCacheDialog(setDisableReloadEntitiesCache);
    };

    //get scan results
    betaToolsModel.getScanResults = () => {
      betaToolsModel.loading = true;

      betaToolsService.getScanResults().then(data => {
        const today = getTodayDateAsString();

        downloadFileService.download(data, 'attachment/octet-stream', 'bigid-scan-results-' + today + '.zip');
        betaToolsModel.loading = false;
      });
    };

    betaToolsModel.getDocVecs = () => {
      betaToolsModel.loading = true;

      betaToolsService.getScanResults('doc_vecs').then(data => {
        const today = getTodayDateAsString();

        downloadFileService.download(data, 'attachment/octet-stream', 'doc-vec-results-' + today + '.zip');
        betaToolsModel.loading = false;
      });
    };

    betaToolsModel.deleteClusters = () => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete Clusters',
        bodyText: 'Are you sure you want to delete clusters?',
      };
      betaToolsModel.loading = true;
      DeleteConfirmation.showModal({}, modalOptions).then(
        () => {
          betaToolsService.deleteClusters().then(
            () => {
              notificationService.success('Clusters deleted successfully!');
              betaToolsModel.loading = false;
            },
            () => {
              notificationService.error('An error has occurred while trying to delete clusters!');
            },
          );
        },
        () => {
          betaToolsModel.loading = false;
        },
      );
    };

    betaToolsModel.showExportCollectionDialog = () => {
      betaToolsModel.isExportCollectionDialogOpen = true;
    };

    betaToolsModel.hideExportCollectionDialog = () => {
      betaToolsModel.isExportCollectionDialogOpen = false;
      $rootScope.$apply();
    };

    betaToolsModel.getServicesLogs = isJsonLogs => {
      betaToolsModel.loading = true;

      betaToolsService.getServicesLogs(null, isJsonLogs).then(data => {
        const today = getTodayDateAsString();
        const fileNamePrefix = isJsonLogs ? 'bigid-json-logs-' : 'bigid-logs-';
        downloadFileService.download(data, 'attachment/octet-stream', fileNamePrefix + today + '.zip');

        betaToolsModel.loading = false;
      });
    };

    function getTodayDateAsString() {
      let today = new Date(),
        dd = today.getDate(),
        mm = today.getMonth() + 1;

      const yyyy = today.getFullYear();

      if (dd < 10) dd = '0' + dd;

      if (mm < 10) mm = '0' + mm;

      today = `${yyyy}-${mm}-${dd}`;
      return today;
    }

    const _getScannerData = () => {
      betaToolsModel.selectedGrid = 'scanner-health';
      betaToolsModel.loading = false;
    };

    const _getScannerHealth = () => {
      betaToolsModel.selectedGrid = 'mt-scanner-health';
      betaToolsModel.loading = false;
    };

    betaToolsModel.showEnvironmentVariablesGrid = () => {
      betaToolsModel.loading = true;
      betaToolsModel.selectedGrid = null;
      betaToolsModel.showScannerLog = false;

      $q.resolve(
        betaToolsModel.showNewServicesConfigurationGrid ||
          betaToolsService.getServicesConfiguration().then(data => {
            betaToolsModel.servicesConfigurationGrid.data = data;
          }),
      )
        .catch(err => this.notificationService.error(`An error has occurred ${err.data.message}`))
        .finally(() => {
          betaToolsModel.loading = false;
          betaToolsModel.selectedGrid = 'services-configuration';
        });
    };

    betaToolsModel.showClusterProgressGrid = () => {
      betaToolsModel.selectedGrid = 'column-clustering-progress';
      betaToolsModel.showScannerLog = false;
    };

    betaToolsModel.downloadMongoCurrentOps = () => {
      betaToolsModel.loading = true;
      betaToolsService
        .downloadMongoCurrentOps()
        .then(() => resolve())
        .catch(err => this.notificationService.error(`An error has occurred ${err.data.message}`))
        .finally(() => {
          betaToolsModel.loading = false;
          $rootScope.$applyAsync();
        });
    };

    betaToolsModel.showMetadataSearchIndexingGrid = () => {
      betaToolsModel.selectedGrid = 'metadata-search-indexing';
      betaToolsModel.showScannerLog = false;
    };

    betaToolsModel.getScannerState = () => {
      _getScannerData();
    };

    betaToolsModel.getScannersHealthCheck = () => {
      _getScannerHealth();
    };

    betaToolsModel.clearScannerHealthMap = () => {
      betaToolsService.clearScannerHealthMap().then(() => {
        _getScannerData();
      });
    };

    betaToolsModel.refreshScannerHealth = () => {
      _getScannerData();
    };

    betaToolsModel.getScannerLog = () => {
      if (!betaToolsModel.selectedLog) return;
      betaToolsModel.selectedGrid = null;

      betaToolsModel.loading = true;

      betaToolsModel.logData = '';
      betaToolsService.getScanLogByName(betaToolsModel.selectedLog).then(result => {
        betaToolsModel.showScannerLog = true;
        betaToolsModel.loading = false;
        $timeout(() => {
          const textAreaLogElement = document.querySelector('#textAreaLog');
          textAreaLogElement.scrollTop = textAreaLogElement.scrollHeight;
        });
        betaToolsModel.logData = result;
      });
    };

    betaToolsModel.getServicesLogsAsList = () => {
      if (!betaToolsModel.selectedLog) return;
      const serviceShortNameToArrayName = {
        orch: 'orchestrator',
        orch2: 'orchestrator2',
        web: 'web',
        scanner: 'scanner',
        'corr-new': 'correlator',
        clustering: 'clustering',
        'data-catalog': 'data-catalog',
        // 'data-catalog-consumer': 'data-catalog-consumer',
        reports: 'reports',
        'secure-sensitive': 'secure-sensitive',
        ner: 'ner',
        'metadata-search': 'metadata-search',
        'process-manager': 'process-manager',
        aci: 'aci',
      };
      if (
        isPermitted(ACTION_CENTER_PERMISSIONS.READ_ACTIONS.name) &&
        getApplicationPreference('ENABLE_ACTION_CENTER')
      ) {
        serviceShortNameToArrayName['action-center'] = 'action-center';
      }

      if (getApplicationPreference('ENABLE_LINEAGE')) {
        serviceShortNameToArrayName['lineage'] = 'lineage';
      }

      if (getApplicationPreference('ENABLE_REPORTING_ETL')) {
        serviceShortNameToArrayName['reporting-etl'] = 'reporting-etl';
      }
      if (getApplicationPreference('ENABLE_TENANT_SERVICE')) {
        serviceShortNameToArrayName['tenant-service'] = 'tenant-service';
      }
      const servicesList = [serviceShortNameToArrayName[betaToolsModel.selectedLog]] || [];
      betaToolsModel.selectedGrid = null;
      betaToolsModel.loading = true;
      betaToolsModel.logData = '';

      betaToolsService.getServicesLogs(servicesList).then(data => {
        const today = getTodayDateAsString();

        downloadFileService.download(
          data,
          'attachment/octet-stream',
          serviceShortNameToArrayName[betaToolsModel.selectedLog] + '-logs-' + today + '.zip',
        );

        betaToolsModel.loading = false;
      });
    };

    betaToolsModel.onServiceVariableValueChanged = (serviceVariable, value) => {
      switch (serviceVariable) {
        case 'METADATA_SEARCH_ENABLED':
          betaToolsModel.isMetadataSearchIndexingProgressReadPermitted =
            value === 'true' && isPermitted(ADVANCE_TOOLS_PERMISSIONS.METADATA_SEARCH_INDEXING_READ.name);
          break;
        case 'SHOW_STRUCTURED_CLUSTERING':
          betaToolsModel.isColumnClusteringActivityReadPermitted =
            value === 'true' && isPermitted(ADVANCE_TOOLS_PERMISSIONS.COLUMN_CLUSTERING_ACTIVITY_READ.name);
          break;
      }

      $rootScope.$apply();
    };

    betaToolsModel.invalidateRefreshTokenUUID = () => {
      const setDisabledInvalidateRefreshTokenUUID = disabled => {
        betaToolsModel.invalidateRefreshTokenUUIDDisabled = disabled;
        $rootScope.$apply();
      };
      invalidateRefreshTokenUUIDDialog(setDisabledInvalidateRefreshTokenUUID);
    };
  },
});
