import React, { FunctionComponent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidBody1, BigidBody2, BigidColors } from '@bigid-ui/components';

const useStyles = makeStyles({
  content: {
    margin: '16px 32px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: BigidColors.containerShadow,
    backgroundColor: BigidColors.white,
  },
  summarizeTextTitle: {
    padding: '12px 6px 12px 6px',
    color: BigidColors.gray[500],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 32,
    marginLeft: 32,
  },
  chipsWrapper: {
    marginBottom: '6px',
  },
  summarizeText: {
    padding: '6px 6px 12px 6px',
  },
});

export interface AciEntitySummarizeProps {
  external: boolean;
  sharedObjectsCount: number;
  dataSource: string;
  email: string;
  membersCount?: number;
}

export const AciEntitySummarize: FunctionComponent<AciEntitySummarizeProps> = ({
  external,
  sharedObjectsCount,
  dataSource,
  email,
  membersCount,
}) => {
  const classes = useStyles({});
  let visibilityStr = '';
  if (external != undefined || external != null) {
    visibilityStr = external ? 'External' : 'Internal';
  }
  return (
    <div className={classes.content}>
      <div className={classes.wrapper}>
        <BigidBody2 className={classes.summarizeTextTitle}>Visibility</BigidBody2>
        <BigidBody1 data-aid={'aci-summarize-external-label'} className={classes.summarizeText}>
          {visibilityStr}
        </BigidBody1>
      </div>
      {membersCount && (
        <div className={classes.wrapper}>
          <BigidBody2 className={classes.summarizeTextTitle}>Number of Members</BigidBody2>
          <BigidBody1 data-aid={'aci-summarize-members-label'} className={classes.summarizeText}>
            {membersCount}
          </BigidBody1>
        </div>
      )}
      <div className={classes.wrapper}>
        <BigidBody2 className={classes.summarizeTextTitle}>File Access</BigidBody2>
        <BigidBody1 data-aid={'aci-summarize-objects-label'} className={classes.summarizeText}>
          {sharedObjectsCount}
        </BigidBody1>
      </div>
      <div className={classes.wrapper}>
        <BigidBody2 className={classes.summarizeTextTitle}>Data Source</BigidBody2>
        <BigidBody1 data-aid={'aci-summarize-ds-label'} className={classes.summarizeText}>
          {dataSource}
        </BigidBody1>
      </div>
      <div className={classes.wrapper}>
        <BigidBody2 className={classes.summarizeTextTitle}>Email</BigidBody2>
        <BigidBody1 data-aid={'aci-summarize-email-label'} className={classes.summarizeText}>
          {email}
        </BigidBody1>
      </div>
    </div>
  );
};
