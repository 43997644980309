import { ObjectPreviewEntityType } from './dataSourcesLayoutTypes';
import { getFixedT } from '../../translations';
import { AggregationItemBase } from '../../catalogDiscoveryTypes';

export function getObjectTypeCellDisplayValue(value: ObjectPreviewEntityType): string {
  const t = getFixedT('content.DataSourcesLayout.sidePanel.content.objectsPreview.columns.objectType.values');
  let displayValue;

  switch (true) {
    case value === ObjectPreviewEntityType.TABLE || value === ObjectPreviewEntityType.STRUCTURED_LEAF:
      displayValue = t('rdb');
      break;
    case value === ObjectPreviewEntityType.STRUCTURED_FILE_LEAF:
      displayValue = t('structuredFile');
      break;
    case value === ObjectPreviewEntityType.PARTITIONED_TABLE_LEAF:
      displayValue = t('table');
      break;
    case value === ObjectPreviewEntityType.APP_LEAF:
      displayValue = t('app');
      break;
    case value === ObjectPreviewEntityType.TABLE_VIEW:
      displayValue = t('rdbView');
      break;
    case value === ObjectPreviewEntityType.FILE || value === ObjectPreviewEntityType.UNSTRUCTURED_LEAF:
      displayValue = t('file');
      break;
    default:
      displayValue = value;
  }

  return displayValue;
}

export function getDataSourcesLayoutRecordId(dataSource: AggregationItemBase): string {
  return dataSource.aggItemName;
}
