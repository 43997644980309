import { useEffect } from 'react';

export const useScrollToDsListAfterCheck = (containerRef: React.MutableRefObject<any>, dsLength: number) => {
  useEffect(() => {
    if (dsLength && containerRef?.current) {
      const element = containerRef.current.parentElement;
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
  }, [containerRef, dsLength]);
};
