import React, { FC, useMemo, useCallback, useState, useRef } from 'react';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidForm,
  BigidFormFieldTypes,
  BigidFormRenderProps,
  BigidFormValidateOnTypes,
  BigidHeading4,
  BigidSelectOption,
  BigidFormValues,
  BigidFormField,
  BigidFieldRenderProps,
  BigidFormStateAndHandlers,
} from '@bigid-ui/components';
import { topBarHeight } from '../../ActionWorkflowTop';
import { ConfigureActionWorkflowControlBar } from './ConfigureActionWorkflowControlBar';
import { WorkflowConfiguration, Action } from '../../actionWorkflowTypes';
import {
  getEventGroupOptions,
  loadUserSearchOptions,
  validateActions,
  getWorkflowInitialFormValue,
  loadEntitySearchOptions,
  getAllSelectionDynamicFields,
  getSelectedEventFromFormValue,
  getEntityTypeOptions,
  isDuplicationError,
} from '../../actionWorkflowUtils';
import { notificationService } from '../../../../../services/notificationService';
import { saveWorkflow } from '../../actionWorkflowService';
import { ActionForm } from './ActionForms/ActionForm';
import { ALLOWED_NAME_REGEX, TRAILING_SPACES, TRAILING_SPACES_MESSAGE } from '../../../../../config/consts';
import { ActionCenterMetadataContainer } from '../../../useActionCenterMetadata';

interface ConfigureActionWorkflowProps {
  initialWorkflow?: WorkflowConfiguration;
  onClose: () => void;
  isCreateMode: boolean;
  onUpdateWorkflows: () => void;
  shouldAddConfigurations: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& form': { height: `calc(100% - ${topBarHeight}px)` },
  },
  content: { height: `calc(100% - ${topBarHeight}px)`, display: 'flex' },
});

const useGeneralSettingsStyles = makeStyles({
  root: { width: '50%', padding: '24px 64px', overflow: 'scroll' },
  title: { marginBottom: 16 },
  formWrapper: { display: 'flex', flexDirection: 'column' },
});

const DYNAMIC_FIELDS_DISABLED_IN_EDIT_MODE = ['status'];

export const ConfigureActionWorkflow: FC<ConfigureActionWorkflowProps> = ({
  initialWorkflow,
  onClose,
  isCreateMode,
  onUpdateWorkflows,
  shouldAddConfigurations,
}) => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const { root, content } = useStyles({});
  const generalSettingsClasses = useGeneralSettingsStyles({});
  const formControls = useRef<BigidFormStateAndHandlers>();
  const [currentEntityType, setCurrentEntityType] = useState<string>();

  const handleFormChange = (values: BigidFormValues) => {
    const { entityType: entityTypeSelection, event: eventSelection } = values;

    const event = eventSelection?.[0].value;
    const entityType = entityTypeSelection?.[0].value;

    const { entities = [] } = actionCenterMetadata.events.find(({ name }) => name === event);
    const entityTypeFieldIsHidden = entities.length === 1;

    if (formControls.current) {
      if (currentEntityType && entityTypeFieldIsHidden) {
        formControls.current.clearField('entityType');
        formControls.current.clearField('entityIds');
        setCurrentEntityType(undefined);
      } else if (currentEntityType !== entityType) {
        formControls.current.clearField('entityIds');
        setCurrentEntityType(entityType);
      }
    }
  };

  const initialValues = getWorkflowInitialFormValue({
    events: actionCenterMetadata.events,
    initialWorkflow,
  });

  const onSubmit = useCallback(
    async ({
      id,
      name,
      event: eventSelection,
      receivers,
      entityIds,
      actions,
      entityType,
      ...rest
    }: BigidFormValues) => {
      const eventGroup = eventSelection[0].value;
      const { eventFieldOrder } = actionCenterMetadata.events.find(({ name }) => name === eventGroup);

      const event = getSelectedEventFromFormValue(actionCenterMetadata.events, eventFieldOrder, {
        entityType,
        event: eventSelection,
        ...rest,
      });

      try {
        const workflowToSubmit = {
          name,
          ...(!id && { event }),
          receivers: receivers.map(({ value }: BigidSelectOption) => value),
          entityIds: entityIds.map(({ value }: BigidSelectOption) => value),
          actions,
        };
        await saveWorkflow(workflowToSubmit, id);
        onUpdateWorkflows();
        onClose();
        notificationService.success('Saved successfully!');
      } catch (error) {
        console.error(error);
        const customMsgToSend = createCustomMsg(error);
        notificationService.error(customMsgToSend);
      }
    },
    [actionCenterMetadata.events, onClose, onUpdateWorkflows],
  );

  const createCustomMsg = (error: any): string =>
    isDuplicationError(error) ? 'Failed to save action - Action name is already in use' : 'Failed to save action';

  const renderForm = ({ renderField, getValues, validateAndSubmit }: BigidFormRenderProps) => {
    const { event, entityType, entityIds: entityIdsSelection, ...rest } = getValues();

    const {
      entities = [],
      selectionDynamicFields = [],
      eventFieldOrder,
    } = actionCenterMetadata.events.find(({ name }) => name === event?.[0].value);
    const selectedEntity =
      entities.length > 1 ? entities.find(({ name }) => name === entityType?.[0].value) : entities[0];
    const selectedEvent = getSelectedEventFromFormValue(actionCenterMetadata.events, eventFieldOrder, {
      event,
      entityType,
      ...rest,
    });

    const entityIds = entityIdsSelection.map(({ value }: BigidSelectOption) => value);

    return (
      <>
        <div className={content}>
          <div className={generalSettingsClasses.root}>
            <BigidHeading4 className={generalSettingsClasses.title}>General Settings</BigidHeading4>
            <div className={generalSettingsClasses.formWrapper}>
              {renderField('name')}
              {renderField('receivers', {
                selectProps: {
                  isSearchable: true,
                  placeholder: 'Select Owners',
                  isMulti: true,
                  isAsync: true,
                  loadOptions: loadUserSearchOptions({}),
                  shouldLoadInitialOptions: true,
                  menuPosition: 'fixed',
                },
              })}
              {renderField('event', {
                selectProps: {
                  isSearchable: true,
                  placeholder: 'Select Trigger Type',
                  menuPosition: 'fixed',
                },
              })}
              {selectionDynamicFields.map(({ name, options }) =>
                renderField(name, {
                  selectProps: { options, menuPosition: 'fixed' },
                }),
              )}
              {entities.length > 1 &&
                renderField('entityType', {
                  selectProps: {
                    placeholder: 'Select Entity Type',
                    options: getEntityTypeOptions(entities),
                    menuPosition: 'fixed',
                  },
                })}
              {selectedEntity &&
                renderField('entityIds', {
                  label: `Select ${selectedEntity.displayName}`,
                  selectProps: {
                    isSearchable: true,
                    isMulti: true,
                    isAsync: true,
                    loadOptions: loadEntitySearchOptions(selectedEntity, entityIds),
                    shouldLoadInitialOptions: true,
                    menuPosition: 'fixed',
                  },
                })}
            </div>
          </div>
          <Divider orientation="vertical" />
          {renderField('actions', { fieldProps: { selectedEvent } })}
        </div>
        <ConfigureActionWorkflowControlBar onSave={() => validateAndSubmit(onSubmit)} onClose={onClose} />
      </>
    );
  };

  const fields: BigidFormField[] = useMemo(() => {
    return [
      {
        type: BigidFormFieldTypes.TEXT,
        name: 'name',
        label: 'Action Name',
        misc: {
          fullWidth: true,
          placeholder: 'Enter a name',
        },
        validate: (value: string) => {
          if (!value) {
            return 'Please enter a name';
          }
          if (!ALLOWED_NAME_REGEX.test(value)) {
            return 'Invalid value, special characters';
          }
          if (TRAILING_SPACES.test(value)) {
            return TRAILING_SPACES_MESSAGE;
          }
          return false;
        },
      },
      {
        type: BigidFormFieldTypes.SELECT,
        name: 'event',
        label: 'Trigger Type',
        options: getEventGroupOptions(actionCenterMetadata.events),
        validate: (selectedOption: BigidSelectOption[]) => {
          if (selectedOption?.length === 0) {
            return 'Please select the trigger type';
          }
          return false;
        },
        disabled: !isCreateMode,
      },
      {
        type: BigidFormFieldTypes.SELECT,
        name: 'entityType',
        label: 'Select an Entity Type',
        options: [],
        validate: (selectedOption: BigidSelectOption[], values: BigidFormValues) => {
          const { event } = values;
          const { entities = [] } = actionCenterMetadata.events.find(({ name }) => name === event?.[0].value);

          if (entities.length > 1 && (!selectedOption || selectedOption?.length === 0)) {
            return 'Please select at least one entity type';
          }
          return false;
        },
        disabled: !isCreateMode,
      },
      {
        type: BigidFormFieldTypes.SELECT,
        name: 'entityIds',
        label: 'Select an Entity',
        options: [],
        validate: (selectedOption: BigidSelectOption[]) => {
          if (selectedOption?.length === 0) {
            return 'Please select at least one entity';
          }
          return false;
        },
      },
      {
        type: BigidFormFieldTypes.SELECT,
        name: 'receivers',
        label: 'Select Owners',
        options: [],
        validate: (selectedOption: BigidSelectOption[]) => {
          if (selectedOption?.length === 0) {
            return 'Please select at least one owner';
          }
          return false;
        },
      },
      ...getAllSelectionDynamicFields(actionCenterMetadata.events).map(({ name, displayName, options = [] }) => ({
        type: BigidFormFieldTypes.SELECT,
        name,
        label: displayName,
        options,
        validate: (selectedOption: BigidSelectOption[], values: BigidFormValues) => {
          const { event } = values;
          const { selectionDynamicFields = [] } = actionCenterMetadata.events.find(
            ({ name }) => name === event?.[0].value,
          );
          const isFieldShown = selectionDynamicFields.find(({ name: fieldName }) => name === fieldName);

          if (isFieldShown && (!selectedOption || selectedOption?.length === 0)) {
            return `Please select ${displayName.toLowerCase()}`;
          }
          return false;
        },
        ...(DYNAMIC_FIELDS_DISABLED_IN_EDIT_MODE.includes(name) && { disabled: !isCreateMode }),
      })),
      {
        name: 'actions',
        label: 'Actions',
        validate: (actions: Action[]) => {
          if (actions.length === 0) {
            return 'Please define at least one action';
          } else if (!validateActions(actions)) {
            return 'Please check errors in the action settings';
          }
          return false;
        },
        render: ({ value, setValue, error, fieldProps: { selectedEvent } }: BigidFieldRenderProps) => (
          <ActionForm
            actions={value}
            setActions={setValue}
            error={error}
            selectedEvent={selectedEvent}
            shouldAddConfigurations={shouldAddConfigurations}
          />
        ),
      },
    ];
  }, [actionCenterMetadata.events, isCreateMode, shouldAddConfigurations]);

  return (
    <div className={root}>
      <BigidForm
        controlButtons={false}
        fields={fields}
        initialValues={initialValues}
        renderForm={renderForm}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
        stateAndHandlersRef={formControls}
        onChange={handleFormChange}
      />
    </div>
  );
};
