import { BigidDropdownOption, BigidSelectOption, FormValue } from '@bigid-ui/components';
import { customAppService } from '../../../../../../../services/customAppService';
import { convertParams } from '../../../../../../CustomApp/utils/CustomAppUtils';

export interface TpaApiPayloadOptions {
  _id: string;
  friendlyName: string;
  friendly_name: string;
  name: string;
  tpa_name: string;
  action_name: string;
  logo: string;
  description: string;
}

export const emptyForm: FormStateProps = {
  apps: undefined,
  selectedApp: undefined,
  actions: undefined,
  selectedAction: undefined,
  presets: undefined,
  selectedPreset: undefined,
};

export const fieldsNames = {
  APPS: 'apps',
  ACTIONS: 'actions',
  PRESETS: 'presets',
};

export interface FormStateProps {
  apps: BigidDropdownOption[];
  selectedApp: BigidDropdownOption;
  actions: BigidDropdownOption[];
  selectedAction: BigidDropdownOption;
  presets: BigidDropdownOption[];
  selectedPreset: BigidDropdownOption;
}

export const findById = (data: BigidDropdownOption[], id: string) => {
  return data.find(item => item.id === id);
};

export const convertValuesToObjects = (data: Record<string, FormValue>) => {
  Object.entries(data).forEach(([key, value]) => (data[key] = Array.isArray(value) ? value[0] : value));
  return data;
};

export const getLabel = (tpaObject: TpaApiPayloadOptions) => {
  return (
    tpaObject.friendlyName || tpaObject.friendly_name || tpaObject.name || tpaObject.tpa_name || tpaObject.action_name
  );
};

export const fetchInstalledApps = async () => {
  const { data: data } = await customAppService.getCustomApps();

  return data;
};

export const fetchAppActions = async (tpaId: string) => {
  const { data } = await customAppService.getCustomAppActions(tpaId);

  data.forEach((action: any) => (action.params = convertParams(action.params)));
  return data;
};

export const getActionPresets = (action: BigidDropdownOption) => {
  if (action) return action.value.presets;
};

export const getSelectedValueFromName = (values: FormStateProps, name: string) => {
  switch (name) {
    case fieldsNames.APPS:
      return values.selectedApp;
    case fieldsNames.ACTIONS:
      return values.selectedAction;
    case fieldsNames.PRESETS:
      return values.selectedPreset;
  }
};

export const areObjectsEqual = (first: any, second: any) => {
  return JSON.stringify(first) === JSON.stringify(second);
};
