import { useState, useCallback, useEffect } from 'react';
import { AsyncOperation } from '../../../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { SSEDataMessage, subscribeToRepeatedSSEEventById } from '../../../../../../services/sseService';
import { useLocalTranslation } from '../../../../translations';
import {
  DataExplorerAsyncOperationRoutingKey,
  DataExplorerAsyncOperationStatus,
  tagBulkAssignmentNameMapping,
  TagBulkAssignmentOperationsSorted,
  TagBulkAssignmentSseResults,
  UseTagBulkAssignmentParams,
  UseTagBulkAssignmentResponse,
} from '../../../../types/DataExplorerAsyncOpsTypes';

export const useTagBulkUnassignment = ({
  onOperationRun,
  onOperationCompleted,
}: UseTagBulkAssignmentParams): UseTagBulkAssignmentResponse => {
  const [operations, setOperations] = useState<AsyncOperation[]>([]);
  const [isUnassignmentInProcess, setIsUnassignmentInProcess] = useState<boolean>(false);
  const { t } = useLocalTranslation('operations.tags');

  useEffect(() => {
    onOperationRun(DataExplorerAsyncOperationRoutingKey.TAG_BULK_UNASSIGNMENT, isUnassignmentInProcess);
  }, [isUnassignmentInProcess, onOperationRun]);

  const handleTagBulkUnassignmentBroadcastEventReceived = useCallback(
    ({ results = [] }: SSEDataMessage<TagBulkAssignmentSseResults>) => {
      const operationsSortedSkeleton = Object.keys(DataExplorerAsyncOperationStatus).reduce(
        (operationsSortedBase, status) => ({ ...operationsSortedBase, [status]: [] }),
        {},
      ) as TagBulkAssignmentOperationsSorted;
      const operationsSorted = results.reduce((operationsSortedAggr, operation) => {
        const { status } = operation;
        return { ...operationsSortedAggr, [status]: [...(operationsSortedAggr[status] || []), operation] };
      }, operationsSortedSkeleton);

      const operationsUpdated = [
        ...operationsSorted.ERROR,
        ...operationsSorted.COMPLETED,
        ...operationsSorted.RUNNING,
      ].reduce((operationsAggr, serverOperation) => {
        const { name, payload, status, totalObjects, percentage } = serverOperation;

        if (payload?.tags.length > 0) {
          const { tagName, tagValue } = payload.tags[0];

          return [
            ...operationsAggr,
            {
              status,
              percentage:
                status === DataExplorerAsyncOperationStatus.RUNNING && typeof percentage === 'number'
                  ? Math.round(percentage)
                  : undefined,
              name: tagBulkAssignmentNameMapping[name],
              description:
                totalObjects > 0 ? t('unassign.description.total', { totalObjects }) : t('description.counting'),
              entityName: `"${tagName}:${tagValue}"`,
            },
          ];
        } else {
          return operationsAggr;
        }
      }, []);

      const hasCompletedOperations = operationsUpdated.some(
        ({ status }) => status === DataExplorerAsyncOperationStatus.COMPLETED,
      );

      if (hasCompletedOperations) {
        onOperationCompleted();
      }

      setOperations(operationsUpdated);
      setIsUnassignmentInProcess(!!results.length);
    },
    [onOperationCompleted, t],
  );

  useEffect(() => {
    const unsubscribe = subscribeToRepeatedSSEEventById(
      DataExplorerAsyncOperationRoutingKey.TAG_BULK_UNASSIGNMENT,
      handleTagBulkUnassignmentBroadcastEventReceived,
    );

    return () => {
      unsubscribe();
    };
  }, [handleTagBulkUnassignmentBroadcastEventReceived]);

  return { operations };
};
