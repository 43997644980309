import React, { FC, ReactText, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  BigidContentItem,
  useInterval,
  EntityEvents,
  entityEventsEmitter,
  ToolbarActionType,
} from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridColumn,
  BigidGridUpdateRowByIdPayload,
} from '@bigid-ui/grid';
import {
  CuratedField,
  FindingDetails,
  getFindings,
  CuratedAttributeIdentifier,
  triggerPreviewCurationAttributes,
} from '../../../../curationService';
import { getGridRows, getGridData, getGridColumns } from './attributePreviewUtils';
import { notificationService } from '../../../../../../services/notificationService';
import { CuratedFieldValuePreviewNoResults } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewNoResults';
import {
  CuratedFieldValuePreviewError,
  CuratedFieldPreviewErrorType,
} from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewError';
import { useLocalTranslation } from '../../../../translations';
import { v4 as uuid } from 'uuid';
import { CuratedFieldValuePreviewLoading } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewLoading';
import { CurationGuidedTourStageId } from '../../../../../Curation/useCurationState';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { CurationTabsLayoutStateContext } from '../../../../CurationStateContext';

export interface AttributePreviewProps
  extends BigidContentItem,
    Omit<CuratedField, 'id' | 'tags'>,
    CuratedAttributeIdentifier {
  dataAid?: string;
}

export type AttributePreviewField = Record<PropertyKey, AttributePreviewFieldValue | ReactText>;

export type AttributePreviewFieldValue = {
  fieldValue: string;
  findingDetails: FindingDetails[];
};

export type AttributePreviewGridRecord = BigidGridRow & AttributePreviewField;

type AttributePreviewGridDynamicConfig = {
  rows: AttributePreviewGridRecord[];
  columns: BigidGridColumn<AttributePreviewGridRecord>[];
};

export type AttributePreviewRowUpdatePayload = BigidGridUpdateRowByIdPayload<AttributePreviewGridRecord>;

const EXPIRED_ATTR_PREVIEW_ERR_CODE = 422;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '5px',
    position: 'relative',
  },
});

const defaultGridConfig: AttributePreviewGridDynamicConfig = {
  rows: [],
  columns: [],
};

const currentGuidedTour = CurationGuidedTourStageId.PREVIEW;

export const AttributePreview: FC<AttributePreviewProps> = ({
  dataAid = 'AttributePreview',
  id,
  fullyQualifiedName,
  fieldName,
  attributeName,
  attributeType,
  scannerTypeGroup,
  curatedFieldsGridId,
}) => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.structured.AttributePreview');
  const gridId = useMemo(() => `AttributePreview-${uuid()}`, []);
  const { onProceedToFieldsReviewTab, onCurationGuidedTourStart } = useContext(CurationTabsLayoutStateContext);

  const [gridDynamicConfig, setGridDynamicConfig] = useState<AttributePreviewGridDynamicConfig>(defaultGridConfig);
  const [isPreviewDataFetched, setIsPreviewDataFetched] = useState<boolean>(false);
  const [previewDataFetchError, setPreviewDataFetchError] = useState<string>();
  const [isIntervalFetchActive, setIsIntervalFetchActive] = useState<boolean>(false);
  const [previewErrorType, setPreviewErrorType] = useState<CuratedFieldPreviewErrorType>(
    CuratedFieldPreviewErrorType.ERROR,
  );
  const [isGridReload, setIsGridReload] = useState([]);
  const [reloadState, setReloadState] = useState({});

  useEffect(() => {
    const openGuidedTour = gridDynamicConfig?.columns?.length > 0;
    onProceedToFieldsReviewTab(currentGuidedTour, openGuidedTour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridDynamicConfig?.columns?.length]);

  useInterval(
    () => {
      isIntervalFetchActive && setIsGridReload([]);
    },
    isIntervalFetchActive && gridDynamicConfig?.columns?.length === 0 ? 10000 : null,
  );

  const gridConfig = useMemo<BigidGridWithToolbarProps<AttributePreviewGridRecord>>(
    () => ({
      gridId,
      entityName: t('grid.entityName'),
      hideColumnChooser: true,
      showSortingControls: false,
      fetchData: async () => {
        return {
          totalCount: gridDynamicConfig.rows.length,
          data: gridDynamicConfig.rows,
        };
      },
      columns: gridDynamicConfig.columns,
      toolbarActions: [
        {
          label: '',
          execute: async () => {
            onCurationGuidedTourStart(currentGuidedTour);
            return {
              shouldGridReload: false,
            };
          },
          show: () => true,
          placement: 'end',
          icon: BigidHelpIcon,
          isGlobal: true,
          disable: () => false,
          type: ToolbarActionType.ACTION_ICON,
        },
      ],
    }),
    [gridDynamicConfig.columns, gridDynamicConfig.rows, gridId, onCurationGuidedTourStart, t],
  );

  const onReload = useCallback(() => {
    setIsPreviewDataFetched(false);
    setReloadState({});
  }, []);

  const handlePreviewError = useCallback(
    (attributeName: string, error_code?: number) => {
      if (error_code === EXPIRED_ATTR_PREVIEW_ERR_CODE) {
        setPreviewDataFetchError(t('errors.expiredPreview'));
        setPreviewErrorType(CuratedFieldPreviewErrorType.EXPIRED_ATTRIBUTE);
      } else {
        const notificationMessage = t('errors.fetchingPreview', { attributeName });
        setPreviewDataFetchError(notificationMessage);
        setGridDynamicConfig(defaultGridConfig);
      }
    },
    [t],
  );

  useEffect(() => {
    if (isPreviewDataFetched && !previewDataFetchError) {
      entityEventsEmitter.emit(EntityEvents.RELOAD, curatedFieldsGridId);
    }
  }, [curatedFieldsGridId, isPreviewDataFetched, previewDataFetchError]);

  useEffect(() => {
    setIsPreviewDataFetched(false);

    getFindings(id.toString())
      .then(({ data }) => {
        const { findings } = data;

        if (Array.isArray(findings) && findings.length > 0) {
          const columns = getGridColumns(gridId, findings, id, { attributeName, attributeType }, fieldName, onReload);
          const gridData = getGridData(findings, { attributeName, attributeType });
          const rows = getGridRows(gridData);

          setGridDynamicConfig({
            columns,
            rows,
          });
          setPreviewDataFetchError(null);
        } else {
          handlePreviewError(attributeName);
          setGridDynamicConfig(defaultGridConfig);
        }
      })
      .catch(({ message, response }) => {
        handlePreviewError(attributeName, response?.status);
        console.error(`${t('errors.generic')}: ${message}`);
        if (response?.status !== EXPIRED_ATTR_PREVIEW_ERR_CODE) {
          const notificationMessage = t('errors.fetchingPreview', { attributeName });
          notificationService.error(notificationMessage);
        }
      })
      .finally(() => {
        setIsPreviewDataFetched(true);
      });
  }, [
    attributeName,
    attributeType,
    fullyQualifiedName,
    fieldName,
    id,
    t,
    gridId,
    scannerTypeGroup,
    handlePreviewError,
    isGridReload,
    reloadState,
    onReload,
  ]);

  const onPreviewClick = async () => {
    try {
      setIsPreviewDataFetched(false);
      setIsIntervalFetchActive(true);
      await triggerPreviewCurationAttributes('' + id);
      notificationService.success(t('successNotification'));
    } catch ({ message }) {
      notificationService.error(t('errors.generic'));
      console.error(`An error has occurred: ${message}`);
    }
  };

  return (
    <div
      className={classes.root}
      data-tour-id={generateGuidedTourId(currentGuidedTour, ['start'])}
      data-aid={generateDataAid(dataAid, [fullyQualifiedName, fieldName])}
    >
      {isPreviewDataFetched ? (
        <>
          {previewDataFetchError ? (
            <CuratedFieldValuePreviewError
              dataAid={generateDataAid(dataAid, [fullyQualifiedName, fieldName, previewErrorType])}
              errorText={previewDataFetchError}
              onPreviewClick={onPreviewClick}
              errorType={previewErrorType}
            />
          ) : (
            <>
              {gridDynamicConfig.columns.length > 0 ? (
                <BigidGridWithToolbar {...gridConfig} />
              ) : (
                <CuratedFieldValuePreviewNoResults
                  dataAid={generateDataAid(dataAid, [fullyQualifiedName, fieldName, 'no-results'])}
                />
              )}
            </>
          )}
        </>
      ) : (
        <CuratedFieldValuePreviewLoading />
      )}
    </div>
  );
};
