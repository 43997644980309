import React, { FC, useContext, useCallback } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { BigidBody1, BigidCheckbox } from '@bigid-ui/components';
import { AutoDiscoveryWizardContext } from '../../../autoDiscoveryWizardContext';

const AutoDiscoveryWizardDsWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export interface AutoDiscoveryWizardDsProps {
  selected: boolean;
  id: string;
  label: string;
}

function mapDsList(dsList: AutoDiscoveryWizardDsProps[], id: string, checked: boolean) {
  return dsList.map((item: AutoDiscoveryWizardDsProps) =>
    item.id === id
      ? {
          ...item,
          selected: checked,
        }
      : item,
  );
}

export const AutoDiscoveryWizardDs: FC<AutoDiscoveryWizardDsProps> = ({ id, label, selected }) => {
  const { setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setDiscoveryConfigData(current => ({
        ...current,
        formData: {
          ...current.formData,
          dsList: mapDsList(current.formData.dsList, id, checked),
        },
      }));
    },
    [id, setDiscoveryConfigData],
  );

  return (
    <AutoDiscoveryWizardDsWrapper key={id} data-aid={generateDataAid('ConnectionsItem', [id, selected])}>
      <BigidCheckbox
        checked={selected}
        dataAid={generateDataAid('ConnectionsItem-checkbox', [id, selected])}
        onChange={onChange}
      />
      <BigidBody1 data-aid={generateDataAid('ConnectionsItem-ds-name', [id])}>{label}</BigidBody1>
    </AutoDiscoveryWizardDsWrapper>
  );
};
