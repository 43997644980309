import { ScanProfileActionType, ScanProfileGridRow } from './ScanProfileTypes';
import { httpService } from '../../../services/httpService';
import { ParentScanType } from '../ScanTypes';
import { notificationService } from '../../../services/notificationService';
import { ActionData, EntityEvents, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { startCase, noop } from 'lodash';
import { RunScanProfileActionDialogContent } from './RunScanProfileActionDialogContent';
import { ReactText } from 'react';
import { showDeleteACEntityDialog } from '../../ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { EntityType } from '../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { ScansUITrackingEvent, trackEventScansView } from '../ScansEventTrackerUtils';
import { queryService } from '../../../services/queryService';
import { DEFAULT_SORT_BY_ID } from '../ScanUtils';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { openSystemDialog } from '../../../services/systemDialogService';
import { getFixedT } from '../translations';

const SCAN_PROFILE_IS_ALREADY_RUNNING = 'Scan Is Already Running. Skipping...';

export interface RunScanProfileParams {
  scanType: ParentScanType;
  scanProfileName: string;
}

export interface UpdateScanProfile {
  isReviewFindingsEnabled: boolean;
  operationMsgKey: string;
}

export async function runScanProfile({ scanType, scanProfileName }: RunScanProfileParams) {
  try {
    const {
      data: { result },
    } = await httpService.post(`scans`, {
      scanType,
      scanProfileName,
      scanOrigin: 'Invoked manually',
    });
    if (result.info !== SCAN_PROFILE_IS_ALREADY_RUNNING) {
      notificationService.success(`Scan has been started successfully!`);
      return true;
    }
    notificationService.warning(`Scan is already running...`);
    return false;
  } catch (error) {
    if (error.response.data.statusCode === 400) {
      notificationService.error(
        error.response.data.message ?? 'Rescan failed - the template / profile does not exist.',
      );
      return;
    }

    const errorToDisplay = error?.response?.data?.error;
    if (errorToDisplay && typeof errorToDisplay === 'string') {
      notificationService.error(errorToDisplay);
      return false;
    }
    notificationService.error(`An error occurred running the current scan. See logs for more information.`);
    return false;
  }
}

export async function deleteScanProfile(id: ReactText) {
  try {
    const {
      data: { deleted },
    } = await httpService.delete(`scanProfiles/${id}`);
    if (deleted === 'Failed') {
      notificationService.error('Cannot delete scan profile while it is running...');
      return {};
    } else {
      notificationService.success('Deleted successfully.');
      return { shouldGridReload: true, entityEventToEmit: EntityEvents.RELOAD };
    }
  } catch (error) {
    notificationService.error('An error occurred deleting the current profile. See logs for more information.');
    console.error(error);
  }
}

export async function duplicateScanProfile(id: ReactText) {
  try {
    await httpService.post('scans/scan-profiles/duplicate', {
      id,
    });
    notificationService.success('Scan Profile was duplicated successfully!');
    return {
      shouldGridReload: true,
      entityEventToEmit: EntityEvents.RELOAD,
    };
  } catch (e) {
    console.error(e);
    notificationService.error('Could not duplicate scan profile. See logs for more information.');
    return {};
  }
}

export async function getScanProfiles(gridConfigQuery: string) {
  const {
    data: {
      data: { scanProfiles, totalCount },
    },
  } = await httpService.fetch<{ data: { scanProfiles: ScanProfileGridRow[]; totalCount: number } }>(
    `scans/scan-profiles?${gridConfigQuery}`,
  );

  return {
    scanProfiles,
    totalCount,
  };
}

export async function runActionDialog(actionData: ActionData, actionType: ScanProfileActionType) {
  const {
    selectedRowIds,
    selectedRows: [
      { dataSourceList, allEnabledDs, allEnabledIdSor, idsorList, scanType, name, type, _id, isReviewFindingsEnabled },
    ],
  } = actionData;
  const isRunScanProfile = actionType === ScanProfileActionType.RUN_SCAN;
  const isDeleteScanProfile = actionType === ScanProfileActionType.DELETE;

  let isEnableClassifierTuning: string;

  const changeIsEnableClassifierTuning = (enableClassifierTuning: string) => {
    isEnableClassifierTuning = enableClassifierTuning;
  };

  const onRunScanProfile = async () => {
    if (isEnableClassifierTuning === 'true') {
      await updateScanProfile(_id, { isReviewFindingsEnabled: true, operationMsgKey: 'updatedSuccessfully' });
    }
    await runScanProfile({ scanType, scanProfileName: name });
  };

  const dialogProps = {
    entityNameSingular: 'Scan',
    entityNamePlural: 'Scans',
    actionName: startCase(actionType),
    actionButtonName: startCase(actionType),
  };

  const runScanProfileDialogProps = {
    title: 'Run Scan',
    buttons: [
      { text: 'Cancel', onClick: noop, component: SecondaryButton, isClose: true },
      {
        text: 'Run',
        onClick: onRunScanProfile,
        component: PrimaryButton,
        isClose: true,
        bi: {
          eventType: ScansUITrackingEvent.SCAN_PROFILE_ACTION_CLICK,
          eventData: {
            actionName: actionType,
            ...actionData.selectedRows[0],
          },
        },
      },
    ],
    borderBottom: false,
    onClose: noop,
    contentProps: {
      dataSourceList,
      allEnabledDs,
      allEnabledIdSor,
      idsorList,
      type,
      scanType,
      isReviewFindingsEnabled,
      changeIsEnableClassifierTuning,
    },
    content: RunScanProfileActionDialogContent,
  };

  const shouldCompleteAction = isDeleteScanProfile
    ? await showDeleteACEntityDialog({
        ids: selectedRowIds as string[],
        entityNameSingular: 'scan',
        entityNamePlural: 'scans',
        entityType: EntityType.SCAN_PROFILE,
      })
    : isRunScanProfile
    ? await openSystemDialog(runScanProfileDialogProps)
    : await showConfirmationDialog(dialogProps);

  if (shouldCompleteAction) {
    const [id] = selectedRowIds;
    trackEventScansView(ScansUITrackingEvent.SCAN_PROFILE_ACTION_CLICK, {
      actionName: actionType,
      ...actionData.selectedRows[0],
    });
    switch (actionType) {
      case ScanProfileActionType.RUN_SCAN:
        await runScanProfile({ scanType, scanProfileName: name });
        return {};
      case ScanProfileActionType.DUPLICATE:
        return await duplicateScanProfile(id);
      case ScanProfileActionType.DELETE:
        return await deleteScanProfile(id);
    }
  } else {
    return {};
  }
}

export const fetchScansForGrid = async (queryComponents: BigidGridQueryComponents) => {
  try {
    const gridConfigQuery = queryService.getGridConfigQuery({
      ...queryComponents,
      sort: [...queryComponents.sort, ...DEFAULT_SORT_BY_ID],
    });
    const { scanProfiles, totalCount } = await getScanProfiles(gridConfigQuery);

    return {
      data: scanProfiles,
      totalCount,
    };
  } catch ({ message }) {
    notificationService.error('An error has occurred fetching scan data. See logs for more details.');
    console.error(`An error has occurred: ${message}`);
    return {
      totalCount: 0,
      data: [],
    };
  }
};

export const convertScanProfiles = async (body: Record<string, any>) => {
  try {
    const { data } = await httpService.post('scan-templates/convertScanProfileToScanTemplate', body);
    const { updatedRows, failureRows } = data.data;
    notificationService.success(
      `${updatedRows} Scan Profile${updatedRows > 1 ? 's' : ''} were converted to scan templates.`,
      {
        title: `Scan Profile${updatedRows > 1 ? 's' : ''} converted successfully!`,
      },
    );
    if (failureRows > 0) {
      notificationService.error(
        `${failureRows} Scan Profile${failureRows > 1 ? 's' : ''} could not be converted into`,
        {
          title: 'Profile conversion failed',
        },
      );
    }
  } catch (error) {
    notificationService.error('An error has occurred converting scan profile. See logs for more details.');
  }
};

export async function updateScanProfile(id: ReactText, data: UpdateScanProfile) {
  const t = getFixedT('ScanProfilesService');
  const operationMgsKey = data.operationMsgKey ?? 'updatedSuccessfully';
  try {
    await httpService.put(`scanProfiles/${id}`, data);
    notificationService.success(t(operationMgsKey));
    return { shouldGridReload: true, entityEventToEmit: EntityEvents.RELOAD };
  } catch (e) {
    console.error(e);
    notificationService.error(t('failedToUpdate'));
  }
}
