import React, { FC, useState, ChangeEvent } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PlusIcon from '../../../assets/icons/plus.svg';
import {
  privacyTemplate,
  securityTemplate,
  blankTemplate,
  bigidDefaultTemplate,
  actionableInsightsDashboard,
} from './templates';
import {
  BigidSelect,
  BigidSelectOption,
  PrimaryButton,
  BigidTriggerButtonSize,
  SecondaryButton,
  BigidDropZone,
  BigidRadioGroup,
} from '@bigid-ui/components';
import { riskTemplate } from './templates/riskTemplate';

export enum DASHBOARD_TEMPLATE_TYPE {
  PRIVACY = 'privacy',
  SECURITY = 'security',
  RISK = 'risk',
  BIGID = 'bigid',
  BLANK = 'blank',
  IMPORTED = 'imported',
  DSPM = 'dspm',
}

export enum VIEW_TYPE {
  IMPORT_FILE = 'importFile',
  SELECT_TEMPLATE = 'selectTemplate',
}

export interface TemplateSelectorProps {
  onSelectTemplate: (dashboardName: string, jsonAsString: string) => void;
}

const dashboardTypesOptions: BigidSelectOption[] = [
  {
    label: 'BigID Default Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.BIGID,
  },
  {
    label: 'Privacy Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.PRIVACY,
  },
  {
    label: 'Security Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.SECURITY,
  },
  {
    label: 'Risk Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.RISK,
  },
  {
    label: 'Blank Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.BLANK,
  },
  {
    label: 'DSPM Dashboard',
    value: DASHBOARD_TEMPLATE_TYPE.DSPM,
  },
];

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    flexGrow: 1,
    alignItems: 'flex-start',
    height: '360px',
  },
  selectWrapper: {
    width: '250px',
  },
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButton-label svg': {
      margin: '0 0 0 -5px',
    },
  },
  flexColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '430px',
  },
  errorsListWrapper: {
    height: '165px',
    overflow: 'auto',
  },
  dropZoneWrapper: {
    width: '100%',
  },
  controlsWrapper: {
    margin: '10px 0 0 10px',
  },
});

const getFileContent = (file: File): Promise<string> => {
  if (!file) return null;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      if (event.target.readyState != 2) {
        return reject();
      }
      if (event.target.error) {
        return reject(event.target.error);
      }

      resolve(event.target.result as string);
    };
    reader.readAsText(file);
  });
};

const getNewDashboardPropertiesByTemplateType = (templateType: DASHBOARD_TEMPLATE_TYPE, jsonAsString: string) => {
  let name = '';

  switch (templateType) {
    case DASHBOARD_TEMPLATE_TYPE.BIGID:
      name = 'New BigID default dashboard';
      jsonAsString = JSON.stringify(bigidDefaultTemplate);
      break;
    case DASHBOARD_TEMPLATE_TYPE.PRIVACY:
      name = 'New privacy dashboard';
      jsonAsString = JSON.stringify(privacyTemplate);
      break;
    case DASHBOARD_TEMPLATE_TYPE.SECURITY:
      name = 'New Security dashboard';
      jsonAsString = JSON.stringify(securityTemplate);
      break;
    case DASHBOARD_TEMPLATE_TYPE.RISK:
      name = 'New Risk dashboard';
      jsonAsString = JSON.stringify(riskTemplate);
      break;
    case DASHBOARD_TEMPLATE_TYPE.IMPORTED:
      name = 'New imported dashboard';
      break;
    case DASHBOARD_TEMPLATE_TYPE.DSPM:
      name = 'New DSPM dashboard';
      jsonAsString = JSON.stringify(actionableInsightsDashboard);
      break;
    default:
      name = 'New blank dashboard';
      jsonAsString = JSON.stringify(blankTemplate);
  }

  return { jsonAsString, name };
};

export const TemplateSelector: FC<TemplateSelectorProps> = ({ onSelectTemplate }) => {
  const { wrapper, controlsWrapper, flexRowContainer, selectWrapper, flexColumnContainer, dropZoneWrapper } = useStyles(
    {},
  );
  const [newDashboardTemplate, setNewDashboardTemplate] = useState<BigidSelectOption[]>([dashboardTypesOptions[0]]);
  const [fileToImport, setFileToImport] = useState<File>(null);
  const [importedFileContent, setImportedFileContent] = useState<string>(null);
  const [viewType, setViewType] = useState<VIEW_TYPE>(VIEW_TYPE.SELECT_TEMPLATE);

  const handleCreateDashboardButtonClick = (templateType: DASHBOARD_TEMPLATE_TYPE) => {
    const { name, jsonAsString } = getNewDashboardPropertiesByTemplateType(templateType, importedFileContent);
    onSelectTemplate(name, jsonAsString);
  };

  const handleDashboardTypeChanged = (value: BigidSelectOption[]) => {
    setNewDashboardTemplate(value);
  };

  const handleOnDrop = async (files: File[]) => {
    const file = files.length ? files[0] : null;
    setFileToImport(file);
    const content = await getFileContent(file);
    setImportedFileContent(content);
  };

  const handleTabChange = (event: ChangeEvent<HTMLInputElement>) => {
    setViewType(event.target.value as VIEW_TYPE);
  };

  return (
    <div className={wrapper}>
      <div>Select and edit a predefined configuration or import your own JSON file</div>
      <div className={controlsWrapper}>
        <div>
          <BigidRadioGroup
            defaultValue={VIEW_TYPE.SELECT_TEMPLATE}
            onChange={handleTabChange}
            horizontal
            options={[
              { label: 'Select Template', value: VIEW_TYPE.SELECT_TEMPLATE },
              { label: 'Import File', value: VIEW_TYPE.IMPORT_FILE },
            ]}
          />
        </div>
        {viewType === VIEW_TYPE.SELECT_TEMPLATE && (
          <div className={flexRowContainer}>
            <div className={selectWrapper} data-aid="CustomDashboardSelectTemplateSelect">
              <BigidSelect
                onChange={handleDashboardTypeChanged}
                options={dashboardTypesOptions}
                value={newDashboardTemplate}
              />
            </div>
            <div data-aid="CustomDashboardSelectTemplateCreateButton">
              <PrimaryButton
                size={BigidTriggerButtonSize.large}
                onClick={() => handleCreateDashboardButtonClick(newDashboardTemplate[0].value)}
                startIcon={<PlusIcon />}
                text="Create"
              />
            </div>
          </div>
        )}
        {viewType === VIEW_TYPE.IMPORT_FILE && (
          <div>
            <div className={flexColumnContainer}>
              <div className={dropZoneWrapper}>
                <BigidDropZone
                  files={fileToImport ? [fileToImport] : []}
                  onDrop={handleOnDrop}
                  multiple={false}
                  accept={'.json'}
                />
              </div>
              <div>
                <SecondaryButton
                  size={BigidTriggerButtonSize.large}
                  onClick={() => handleCreateDashboardButtonClick(DASHBOARD_TEMPLATE_TYPE.IMPORTED)}
                  disabled={fileToImport === null}
                  text="Import JSON File"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
