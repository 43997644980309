import React, { FC, ComponentType, useContext } from 'react';
import { FormControl, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { BigidFormRenderProps, BigidFormField, BigidBody2, BigidColors } from '@bigid-ui/components';
import { DsConnectionFieldSectionsEnum, DsTypesEnum } from '../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import {
  checkIsFieldVisible,
  checkIsEnabled,
  checkIsMandatoryForTest,
  calculateNestedDisabled,
} from '../utils/conditionUtils';

const AccordionStyled = withStyles({
  root: {
    borderColor: 'transparent',
    boxShadow: 'none',
    margin: '10px 0',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '10px 0',
    },
  },
  expanded: {},
})(Accordion);

const AccordionSummaryStyled = withStyles({
  root: {
    background: BigidColors.white,
    border: `1px solid ${BigidColors.gray[100]}`,
    borderRadius: '6px',
    minHeight: '32px',
    boxSizing: 'border-box',
    height: '32px',
    display: 'flex',
    padding: '8px',
    '&.Mui-expanded': {
      minHeight: 32,
      marginTop: 10,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginLeft: 8,
    },
  },
  content: {
    display: 'flex',
    padding: '12px 0',
    justifyContent: 'center',
    maxWidth: '50px',
    margin: '0',
    '&.Mui-expanded': {
      margin: 0,
      display: 'flex',
      padding: '12px 0',
      justifyContent: 'center',
    },
  },
  expanded: {},
})(AccordionSummary);

const AccordionDetailsStyles = withStyles({
  root: {
    padding: '12px 0',
  },
})(AccordionDetails);

const useStyles = makeStyles({
  fieldsWrapper: {
    display: 'flex',
    width: '100%',
    padding: '8px 8px 8px 0',
    maxWidth: '820px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyItems: 'center',
  },
});

export interface DataSourceConfigurationSection {
  renderField: BigidFormRenderProps['renderField'];
  getFieldProps: BigidFormRenderProps['getFieldProps'];
  sectionName: DsConnectionFieldSectionsEnum;
  StartComponent?: ComponentType;
  expandsTitle?: string;
  isEditAvailable?: boolean;
  wasScanExecuted?: boolean;
  className?: string;
}

export const DataSourceConfigurationSection: FC<DataSourceConfigurationSection> = React.memo(
  ({
    sectionName,
    getFieldProps,
    renderField,
    StartComponent,
    expandsTitle,
    isEditAvailable,
    wasScanExecuted,
    className,
  }) => {
    const { fields } = useContext(DataSourceConfigurationContext);
    const { fieldsWrapper } = useStyles({});
    const getIsVisibleAndMatchSection = ({ misc }: Pick<BigidFormField, 'misc'>) => {
      const { section } = misc;
      return section === sectionName && checkIsFieldVisible({ misc, getFieldProps });
    };
    const contentRendered = (
      <FormControl
        data-aid={`DataSourceConfiguration-section-${sectionName}`}
        className={fieldsWrapper}
        component="fieldset"
      >
        {StartComponent ? <StartComponent /> : null}
        {fields.map(({ name, misc }: BigidFormField) => {
          const isNameFieldDisabled = name === 'name' && wasScanExecuted;
          const isVisible = getIsVisibleAndMatchSection({ misc });
          const nestedDisabled =
            isVisible &&
            calculateNestedDisabled({
              name,
              misc,
              getFieldProps,
            });

          return isVisible
            ? renderField(name, {
                disabled: isNameFieldDisabled || !isEditAvailable || !checkIsEnabled({ misc, getFieldProps }),
                isRequired: checkIsMandatoryForTest({ misc, getFieldProps }),
                getFieldProps,
                ...(misc?.type === DsTypesEnum.verticalObject ? { nestedDisabled } : {}),
              })
            : null;
        })}
      </FormControl>
    );

    return expandsTitle ? (
      <AccordionStyled square>
        <AccordionSummaryStyled
          className={`${className}-header`}
          aria-controls={`section-content-${sectionName}`}
          id={`section-content-${sectionName}-header`}
          expandIcon={<ExpandMoreIcon />}
          data-aid={`section-content-${sectionName}-header`}
        >
          <BigidBody2>{expandsTitle}</BigidBody2>
        </AccordionSummaryStyled>
        <AccordionDetailsStyles>{contentRendered}</AccordionDetailsStyles>
      </AccordionStyled>
    ) : (
      contentRendered
    );
  },
);
