import { styled } from '@mui/material';
import React, { useEffect } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { CONFIG } from '../../../config/common';
import { $state } from '../../services/angularServices';
import { BottomContent } from './BottomContent/BottomContent';
import { TopContent } from './TopContent/TopContent';
import { useLocalTranslation } from './translations';
import { isDataExplorerEnabled } from './dataExplorerService';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../config/query';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const TopSection = styled('div')`
  padding: 0 20px 32px 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const DataExplorer = () => {
  const { t } = useLocalTranslation();
  const queryClient = getQueryClient();

  useEffect(() => {
    if (!isDataExplorerEnabled()) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: t('title') });
  }, [t]);

  if (!isDataExplorerEnabled()) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <TopSection>
          <TopContent />
        </TopSection>
        <BottomContent />
      </Container>
    </QueryClientProvider>
  );
};
