import React, { FC } from 'react';

const FORMAT_QUESTION_REGEX = new RegExp(/\?/);
export const MessageParser: FC<any> = ({ children, actions }) => {
  const parse = (message: string): void => {
    actions.initialSetup(true, true);
    let formattedQuestion = message;
    if (!FORMAT_QUESTION_REGEX.test(message)) {
      formattedQuestion += ' ?';
    }
    return actions.handleUserAskQuestion(formattedQuestion);
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};
