import React from 'react';
import {
  BigidDropdownOption,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidStatusBadge,
  BigidStatusBadgeSize,
  BigidStatusBadgeType,
  BooleanMap,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import {
  BigidLayoutMasterDetails,
  BigidLayoutMasterDetailsConfig,
  BigidLayoutMasterDetailsGridRowLayouts as BigidGridRowLayouts,
  BigidLayoutMasterDetailsGridSearchTypes as BigidGridSearchType,
} from '@bigid-ui/layout';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CredentialsNoData } from './CredentialsNoData';
import { getFixedT } from './translations';
import {
  CredentialGridRow,
  CredentialItemResponse,
  CredentialsFieldsEntity,
  CredentialsPermissions,
  CredentialTypes,
  EnginesTypes,
  ScopesItemResponse,
  TPAItemResponse,
} from './types';
import { ActionItemResponse } from '../CustomApp/types';
import { isPermitted } from '../../services/userPermissionsService';
import { CREDENTIALS_PERMISSIONS } from '@bigid/permissions';
import {
  CredentialField,
  CredentialsTypesMetadata,
  CredentialType as MetadataType,
  dsTypeToFormFieldType,
  ENCRYPTED_CREDENTIALS_FORMAT,
  IdentifiersMapValuesType,
} from './credentialsFormUtils';
import { ALLOWED_NAME_REGEX } from '../../config/consts';
import { DsTypesEnum } from '../DataSources/DataSourceConfiguration/types';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';
import { values } from 'lodash';

const BIGID_TYPE_LABEL = 'BigID';

interface GetFormFields {
  scopes: ScopesItemResponse[];
  isEditMode: boolean;
  apps: TPAItemResponse[];
  actions: ActionItemResponse[];
  metadata: CredentialsTypesMetadata[];
  cyberArkProviders?: BigidDropdownOption[];
  credentialType: CredentialTypes;
}

interface PrepareRequestData {
  values: BigidFormValues;
  includeRequiredFields?: boolean;
  renderedFields: string[];
  metadata?: CredentialsTypesMetadata[];
}

interface PrepareTestConnectionData {
  values: BigidFormValues;
  renderedFields: string[];
}

const t = (key: string): string => getFixedT('')(key);
const isRemoteCyberArk = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteCyberArk;
const isRemoteHashiCorp = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteHashiCorp;
const isRemoteCustom = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteCustom;
const isThycotic = (value: CredentialTypes): boolean => value === CredentialTypes.Thycotic;
const isRemoteThycotic = (value: CredentialTypes): boolean => value === CredentialTypes.RemoteThycotic;

const shouldShowRemoteCyberArk = (value: CredentialTypes): boolean =>
  isRemoteCyberArk(value) && getApplicationPreference('SHOW_REMOTE_CYBERARK_CREDENTIALS_TYPE') === true;
const shouldShowRemoteHashiCorp = (value: CredentialTypes): boolean =>
  isRemoteHashiCorp(value) && getApplicationPreference('SHOW_REMOTE_HASHICORP_CREDENTIALS_TYPE') === true;
const shouldShowRemoteCustom = (value: CredentialTypes): boolean =>
  isRemoteCustom(value) && getApplicationPreference('SHOW_REMOTE_CUSTOM_CREDENTIALS_TYPE') === true;
const shouldShowThycotic = (value: CredentialTypes): boolean =>
  isThycotic(value) && getApplicationPreference('SHOW_THYCOTIC_CREDENTIALS_TYPE') === true;
const shouldShowRemoteThycotic = (value: CredentialTypes): boolean =>
  isRemoteThycotic(value) && getApplicationPreference('SHOW_REMOTE_THYCOTIC_CREDENTIALS_TYPE') === true;

export const isMultipleCyberArkProvidersEnabled = () =>
  getApplicationPreference('ENABLE_UNIFIED_VAULTS') === true ||
  getApplicationPreference('ENABLE_UNIFIED_VAULTS') === 'true';

const filterCredentilaTypes = (value: CredentialTypes) =>
  (!isRemoteCyberArk(value) &&
    !isRemoteHashiCorp(value) &&
    !isRemoteCustom(value) &&
    !isThycotic(value) &&
    !isRemoteThycotic(value)) ||
  shouldShowRemoteCyberArk(value) ||
  shouldShowRemoteHashiCorp(value) ||
  shouldShowThycotic(value) ||
  shouldShowRemoteThycotic(value) ||
  shouldShowRemoteCustom(value);

export const getTypesDropdownOptions = (): BigidDropdownOption[] => {
  if (isMultiTenantModeEnabled()) {
    return [
      {
        displayValue: BIGID_TYPE_LABEL,
        value: CredentialTypes[BIGID_TYPE_LABEL],
        id: CredentialTypes[BIGID_TYPE_LABEL],
      },
    ];
  } else
    return Object.entries(CredentialTypes)
      .map(([displayValue, value]) => ({ displayValue, value, id: value }))
      .filter(({ value }) => filterCredentilaTypes(value));
};

export const getCredentialProvidersDropdownOptions = (providers?: BigidDropdownOption[]): BigidDropdownOption[] => {
  return providers;
};

export const getScopesDropdownOptions = (scopes: ScopesItemResponse[]): BigidDropdownOption[] =>
  scopes.map(({ id, name }) => ({ displayValue: name, value: id, id }));

export const getEngineTypesDropdownOptions = (): BigidDropdownOption[] =>
  Object.values(EnginesTypes).map(value => ({ displayValue: value, value, id: value }));

export const getTPADropdownOptions = (apps: TPAItemResponse[]): BigidDropdownOption[] =>
  apps.map(({ tpa_name: displayValue, _id: id }) => ({ value: id, displayValue, id }));

export const getActionsDropdownOptions = (actions: ActionItemResponse[]): BigidDropdownOption[] =>
  actions.map(({ action_name: displayValue, _id: id }) => ({ id, value: id, displayValue }));

export const getMetadataDropdownOptions = (
  metadata: CredentialsTypesMetadata[],
  credentialType: CredentialTypes,
): BigidDropdownOption[] => {
  let dropDownOptions = metadata.map(({ type, displayName }) => ({
    displayValue: displayName,
    value: type,
    id: type,
  }));
  if (credentialType === CredentialTypes.RemoteHashiCorp && !getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED')) {
    dropDownOptions = dropDownOptions.filter(
      option => option.value === MetadataType.AAD || option.value === MetadataType.BASIC,
    );
  }

  return dropDownOptions;
};

const validateField = (message: string) => (value: string) => value ? false : t(message);

const validateCredentialId = (value: string) => {
  if (!value) {
    return t('message.requiredField');
  }

  if (!value.match(ALLOWED_NAME_REGEX)) {
    return t('message.invalidCredentialIdPattern');
  }

  return false;
};

const validateJSON = (message: string) => (value: string) => {
  try {
    if (!value) {
      return t(message);
    }

    if (value === ENCRYPTED_CREDENTIALS_FORMAT) {
      return false;
    }

    JSON.parse(value);
    return false;
  } catch (e) {
    return t(message);
  }
};

const validateDropdown = (message: string) => (value: BigidDropdownOption[]) => value?.length > 0 ? false : t(message);

export const generateFormFieldsFromMetadata = (metadata: CredentialsTypesMetadata[]): BigidFormField[] =>
  metadata
    .reduce((prev, current) => [...prev, ...current.fields], [])
    .map(({ name, displayName, type, tooltipText, isMasked }) => {
      const isTextArea = type === DsTypesEnum.json;
      const isPassword = type === DsTypesEnum.password || isMasked;

      return {
        name,
        label: displayName,
        type: dsTypeToFormFieldType[type as DsTypesEnum],
        isRequired: true,
        tooltipText,
        fieldProps: {
          lpIgnore: true,
          size: 'large',
          ...(isTextArea && {
            multiline: true,
            rows: 5,
          }),
          ...(isPassword && { showValueOnlyIfDirty: true }),
        },
        validate:
          name === 'content_enc' ? validateJSON('message.validateJSON') : validateField('message.requiredField'),
      };
    })
    .filter(({ name }) => name !== 'username' && name !== 'password');

export const buildAuthenticationTypesToIdentifiersMap = (metadata: CredentialsTypesMetadata[]) => {
  const fields = metadata.map(obj => obj.fields);
  const mapAuthenticationTypesToIdentifiers: Record<string, IdentifiersMapValuesType[]> = {};
  metadata.forEach((meta, index) => {
    const typeOfMeta = meta.type;
    const typeFields = fields[index];

    mapAuthenticationTypesToIdentifiers[typeOfMeta] = typeFields.map((field: { name: string }) => ({
      ...field,
      name: `${field.name}Identifier`,
    }));
  });
  return mapAuthenticationTypesToIdentifiers;
};

const generateFormFieldsFromMetadataWithIdentifier = (
  metadata: CredentialsTypesMetadata[],
  t: (key: string) => string,
): BigidFormField[] => {
  const metadataFormFields: BigidFormField[] = [];

  metadata
    .reduce((prev, current) => [...prev, ...current.fields], [])
    .forEach(({ name }) => {
      const formField: BigidFormField = {
        name: `${name}Identifier`,
        label: t(`form.${name}Identifier`),
        isRequired: false,
        fieldProps: {
          lpIgnore: true,
          size: 'large',
        },
      };

      metadataFormFields.push(formField);
    });

  return metadataFormFields;
};

export const getFormFields = ({
  scopes,
  isEditMode,
  apps,
  actions,
  metadata,
  cyberArkProviders,
  credentialType,
}: GetFormFields): BigidFormField[] => [
  {
    name: 'credential_id',
    label: t('form.credential_id'),
    isRequired: true,
    validate: validateCredentialId,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'type',
    label: t('form.type'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getTypesDropdownOptions(),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'scopes',
    label: t('form.scopes'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getScopesDropdownOptions(scopes),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      isMulti: true,
      applyOnChange: true,
      isExpandable: true,
      size: 'large',
    },
  },
  {
    name: 'subType',
    label: t('form.subType'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getMetadataDropdownOptions(metadata, credentialType),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      size: 'large',
    },
  },
  ...(generateFormFieldsFromMetadata(metadata) ?? []),
  {
    name: 'username',
    label: t('form.username'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
      lpIgnore: true,
    },
  },
  {
    name: 'password',
    label: t('form.password'),
    isRequired: true,
    type: BigidFormFieldTypes.PASSWORD,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
      showValueOnlyIfDirty: true,
      lpIgnore: true,
    },
  },
  {
    name: 'query',
    label: t('form.query'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 3,
    },
    fieldProps: {
      multiline: true,
    },
  },
  {
    name: 'scanner_group',
    label: t('form.scannerGroup'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'secret_engine',
    label: t('form.secretEngine'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getEngineTypesDropdownOptions(),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'cyberArkVaultId',
    label: t('form.cyberArkVaultId'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    dropDownOptions: getCredentialProvidersDropdownOptions(cyberArkProviders),
    type: BigidFormFieldTypes.DROP_DOWN,
    disabled: !isEditMode,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'url',
    label: t('form.url'),
    isRequired: true,
    validate: validateField('message.requiredField'),
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'usernameAsPlainText',
    type: BigidFormFieldTypes.RADIO,
    misc: {
      fullWidth: false,
    },
    options: [
      {
        value: false,
        label: t('form.usernameIdentifier'),
      },
      {
        value: true,
        label: t('form.username'),
      },
    ],
    isRequired: false,
  },
  {
    name: 'ttl',
    label: t('form.ttl'),
    isRequired: false,
    type: BigidFormFieldTypes.NUMBER,
    fieldProps: {
      size: 'large',
      min: 0,
    },
  },
  {
    name: 'passwordIdentifier',
    label: t('form.passwordIdentifier'),
    isRequired: false,
    fieldProps: {
      size: 'large',
    },
  },
  {
    name: 'apps',
    label: t('form.apps'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getTPADropdownOptions(apps),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'actions',
    label: t('form.actions'),
    isRequired: true,
    validate: validateDropdown('message.requiredField'),
    dropDownOptions: getActionsDropdownOptions(actions),
    type: BigidFormFieldTypes.DROP_DOWN,
    fieldProps: {
      isSearchable: true,
      size: 'large',
    },
  },
  {
    name: 'customQuery',
    label: t('form.customQuery'),
    isRequired: false,
    type: BigidFormFieldTypes.TEXTAREA,
    misc: {
      fullWidth: true,
      rows: 3,
    },
    fieldProps: {
      multiline: true,
    },
  },
  ...generateFormFieldsFromMetadataWithIdentifier(metadata, t),
];

const getDropdownInitialValue = (field: BigidFormField, row: CredentialGridRow): BigidDropdownOption[] => {
  const { name, dropDownOptions } = field;
  if (name === 'scopes') {
    return dropDownOptions.filter(option => row[name]?.includes(option.value));
  }

  if (name === 'actions' || name === 'apps') {
    return row[name]?.map(({ value, label }) => ({ id: value, value: value, displayValue: label }));
  }

  if (name === 'subType') {
    return dropDownOptions.filter(option => option.value === (row[name] ?? MetadataType.BASIC));
  }

  return dropDownOptions.filter(option => option.value === row[name as keyof CredentialGridRow]);
};

export const getInitialValues = (formFields: BigidFormField[], row: CredentialGridRow): BigidFormValues =>
  formFields.reduce<BigidFormValues>((accumulator, field) => {
    const { name, type } = field;
    const fieldName = name as keyof CredentialGridRow;
    const isDropDownType = type === BigidFormFieldTypes.DROP_DOWN;
    const isCustomType = !!row.credentialFields;

    const getValue = () => {
      if (isDropDownType) {
        return getDropdownInitialValue(field, row);
      }

      if (row.credentialsIdentifiers && Object.keys(row.credentialsIdentifiers).includes(name)) {
        return row.credentialsIdentifiers[name];
      }

      if (!isCustomType && name === 'password_enc' && row.password && !row.credentialFields?.password_enc) {
        return row.password;
      }

      if (isCustomType) {
        return row.credentialFields[name as keyof CredentialsFieldsEntity] ?? row[fieldName];
      }

      if (name === 'credential_id' && row.isPending) {
        return '';
      }

      return row[fieldName];
    };

    return {
      ...accumulator,
      [name]: getValue(),
    };
  }, {} as BigidFormValues);

export const createLayoutConfig = (
  gridConfig: BigidGridProps<CredentialGridRow>,
): BigidLayoutMasterDetailsConfig<CredentialGridRow> => ({
  grid: gridConfig,
  search: {
    fields: ['credential_id'],
    placeholder: t('search.placeholder'),
    type: BigidGridSearchType.INTEGRATED,
  },
});

export const createGridConfig = (
  id: string,
  config: ReturnType<typeof useFetch<CredentialGridRow>>,
): BigidGridProps<CredentialGridRow> => ({
  gridId: id,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  noDataContent: <CredentialsNoData message={t('message.noCredentials')} />,
  columns: [
    {
      name: 'credential_id',
      title: 'credential_id',
      width: 'auto',
      getCellValue: row => {
        const subTitle = row.type === 'simple' ? 'BigID' : row.type;
        return (
          <BigidLayoutMasterDetails.Row
            title={row.credential_id}
            subtitle={
              row.isPending ? (
                <BigidStatusBadge
                  label={t('statusText')}
                  type={BigidStatusBadgeType.DARK}
                  size={BigidStatusBadgeSize.SMALL}
                />
              ) : (
                subTitle
              )
            }
            layout={BigidGridRowLayouts.TWO_ROW}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
    },
  ],
});

export const getPermissions = (): CredentialsPermissions => ({
  isDeletePermitted: isPermitted(CREDENTIALS_PERMISSIONS.DELETE.name),
  isEditPermitted: isPermitted(CREDENTIALS_PERMISSIONS.EDIT.name),
  isCreatePermitted: isPermitted(CREDENTIALS_PERMISSIONS.CREATE.name),
  isTestConnectionPermitted: isPermitted(CREDENTIALS_PERMISSIONS.TEST_CONNECTION.name),
});

const mapDropdownOptionToRequesOptions = ({ value, displayValue }: BigidDropdownOption) => ({
  value,
  label: displayValue,
});

const mapFormFieldValueToRequestData: Partial<Record<keyof CredentialItemResponse, any>> = {
  usernameAsPlainText: (value: string) => value === 'true',
  type: (options: BigidDropdownOption[]) => options[0].value,
  scopes: (options: BigidDropdownOption[]) => options.map(({ value }: BigidDropdownOption) => value),
  actions: (options: BigidDropdownOption[]) => options.map(mapDropdownOptionToRequesOptions),
  apps: (options: BigidDropdownOption[]) => options.map(mapDropdownOptionToRequesOptions),
  subType: (options: BigidDropdownOption[]) => options[0].value,
  secret_engine: (options: BigidDropdownOption[]) => options[0].value,
  ttl: (value: string) => Number(value),
  cyberArkVaultId: (options: BigidDropdownOption[]) => options[0].value,
};

export const prepareRequestData = ({
  values,
  includeRequiredFields = true,
  renderedFields,
  metadata,
}: PrepareRequestData) => {
  const credentialType = mapFormFieldValueToRequestData['type'](values.type);
  const isCustomCredentials =
    getApplicationPreference('ENABLE_CREDENTIAL_CUSTOM_TYPES') && credentialType === CredentialTypes.BigID;
  const metadataType =
    isCustomCredentials && values.subType?.length && mapFormFieldValueToRequestData['subType'](values.subType);
  const metadataFields = metadataType
    ? metadata.find(({ type }) => type === metadataType)?.fields.map(({ name }) => name) ?? []
    : [];

  const data = Object.keys(values).reduce((accumulator, fieldName) => {
    if (!renderedFields.includes(fieldName)) {
      return accumulator;
    }

    const getValue = mapFormFieldValueToRequestData[fieldName as keyof CredentialItemResponse];
    const value = getValue ? getValue(values[fieldName]) : values[fieldName];
    const isCustomField = metadataFields.includes(fieldName);

    if (fieldName.endsWith('Identifier') && getApplicationPreference('NEW_CREDENTIALS_FLOW_ENABLED')) {
      return {
        ...accumulator,
        credentialsIdentifiers: {
          ...accumulator.credentialsIdentifiers,
          [fieldName]: value,
        },
      };
    } else if (isCustomCredentials && isCustomField) {
      return {
        ...accumulator,
        credentialFields: {
          ...accumulator.credentialFields,
          [fieldName]: value,
        },
      };
    } else {
      return {
        ...accumulator,
        [fieldName]: value,
      };
    }
  }, {} as BigidFormValues);

  return {
    ...(includeRequiredFields && {
      apps: [],
      actions: [],
      customQuery: '',
      usernameAsPlainText: false,
    }),
    ...data,
    ...(credentialType === CredentialTypes.RemoteCustom && {
      fetchFunctionRequestParams: values.fetchFunctionRequestParams,
    }),
  } as CredentialItemResponse;
};

export const prepareUpdateRequestData = (values: BigidFormValues, updatedFields: BooleanMap) => {
  const customFields = values.credentialFields ?? {};
  const updatedFieldKeys = new Set(Object.keys(updatedFields));

  const updatedCustomFields = Object.keys(customFields).filter(customFieldName =>
    updatedFieldKeys.has(customFieldName),
  );

  const identifierFields = values.credentialsIdentifiers ?? {};

  const updatedIdentifierField = Object.keys(identifierFields).filter(customFieldName =>
    updatedFieldKeys.has(customFieldName),
  );

  const credentialsIdentifiers =
    updatedIdentifierField.length > 0
      ? updatedIdentifierField.reduce((accumulator, customFieldKey) => {
          return {
            ...accumulator,
            [customFieldKey]: values.credentialsIdentifiers[customFieldKey],
          };
        }, {})
      : {};

  const credentialFields =
    updatedCustomFields.length > 0
      ? updatedCustomFields.reduce((accumulator, customFieldKey) => {
          return {
            ...accumulator,
            [customFieldKey]: values.credentialFields[customFieldKey],
          };
        }, {})
      : {};

  const restFields = Object.keys(values).filter(name => !customFields[name]);
  const fields = restFields.reduce<BigidFormValues>((accumulator, fieldName) => {
    const isFieldUpdated = updatedFieldKeys.has(fieldName);
    return {
      ...accumulator,
      ...(isFieldUpdated && { [fieldName]: values[fieldName] }),
    };
  }, {});

  const result: BigidFormValues = {
    ...fields,
    ...(Object.keys(credentialFields).length && { credentialFields }),
    ...(Object.keys(credentialsIdentifiers).length && { credentialsIdentifiers }),
  };

  if (result.password) {
    result.isPasswordChanged = true;
  }

  return result;
};

export const prepareTestConnectionData = ({ values, renderedFields }: PrepareTestConnectionData) => {
  const data = prepareRequestData({ values, includeRequiredFields: false, renderedFields });
  delete data.scopes;
  return data;
};
