import { Reducer, useReducer, useEffect, useCallback, useMemo } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import {
  CuratedDataSource,
  CuratedAttribute,
  CurationStatus,
  GetFieldsCurationStatusResponse,
  GetAttributesCurationStatusResponse,
  getFieldsCurationStatus,
  getAttributeCurationStatus,
  getUserPreferences,
  setUserPreferences,
} from './curationService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { History, HistoryItem } from '../../components/BigidHeader/HeaderService';
import { $state } from '../../services/angularServices';
import { useCancelablePromise, Breadcrumb } from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { CONFIG } from '../../../config/common';
import { getFixedT, useLocalTranslation } from './translations';
import { isUndefined } from 'lodash';
import {
  curationPageTitleHelper,
  CurationHeaderHelpIconProps,
  getGuidedTourKeyByCurationStateId,
} from './curationUtils';

interface UseCurationStateProps {
  scanId: string;
  scanProfileName: string;
}

enum UseCurationStateAction {
  UPDATE_CURATION_WIZARD_STAGE,
  UPDATE_ATTRIBUTES_CURATION_STATUS,
  UPDATE_FIELDS_CURATION_STATUS,
  UPDATE_FIELDS_CURATION_STATUS_INCREMENT,
  UPDATE_GUIDED_TOUR_STATUS_BY_STAGE,
  UPDATE_GUIDED_TOUR_CURRENT_STAGE,
  INITIALIZE_USER_PREFERENCES,
}

export enum CurationStageId {
  CURATED_SOURCES = 'CURATED_SOURCES',
  CURATED_ATTRIBUTES = 'CURATED_ATTRIBUTES',
  CURATED_FIELDS = 'CURATED_FIELDS',
}

export enum CurationGuidedTourStageStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_AUTOMATICALLY = 'STARTED_AUTOMATICALLY',
  STARTED_MANUALLY = 'STARTED_MANUALLY',
  IS_AKNOWLEGED = 'IS_AKNOWLEGED',
}

export interface UseCurationState {
  currentStageId: CurationStageId;
  attributesCurationStatus: CurationStatus;
  fieldsCurationStatus: CurationStatus;
  currentCuratedDataSource: CuratedDataSource;
  currentCuratedAttribute: CuratedAttribute;
  guidedTourStatus: Record<CurationGuidedTourStageId, CurationGuidedTourStageStatus>;
  isPageInitialised: boolean;
  currentGuidedTour: CurationGuidedTourStageId;
}

export enum CurationGuidedTourStageId {
  DATA_SOURCES = 'DATA_SOURCES',
  ATTRIBUTES = 'ATTRIBUTES',
  FIELDS = 'FIELDS',
  PREVIEW = 'PREVIEW',
  COLUMN_PROFILE = 'COLUMN_PROFILE',
  ADDITIONAL_ATTRIBUTES = 'ADDITIONAL_ATTRIBUTES',
}

type UseCurationStatePayload = {
  action: UseCurationStateAction;
  data?: Partial<UseCurationState>;
};

export interface UseCurationStateResponse
  extends Pick<
    UseCurationState,
    | 'currentStageId'
    | 'currentCuratedAttribute'
    | 'currentCuratedDataSource'
    | 'attributesCurationStatus'
    | 'fieldsCurationStatus'
    | 'guidedTourStatus'
    | 'isPageInitialised'
    | 'currentGuidedTour'
  > {
  originState: HistoryItem;
  onProceedToAttributeList: (curatedDataSource: CuratedDataSource) => void;
  onProceedToFieldsReview: (curatedAttribute: CuratedAttribute) => void;
  onProceedToFieldsReviewTab: (currentGuidedTour: CurationGuidedTourStageId, openGuidedTour: boolean) => void;
  onGuidedTourFirstRun: (currentGuidedTour: CurationGuidedTourStageId) => void;
  onFieldReviewed: (isBulk?: boolean) => void;
  onBackToDataSourceList: () => void;
  onBackToAttributeList: () => void;
  onBackToOriginPage: () => void;
  goToScanPage: () => void;
  onGuidedTourStageFinished: (currentGuidedTour: CurationGuidedTourStageId) => void;
  onCurationGuidedTourStart: (currentGuidedTour?: CurationGuidedTourStageId) => void;
}

const getCurationInitialState = (): UseCurationState => {
  return {
    attributesCurationStatus: null,
    fieldsCurationStatus: null,
    currentStageId: CurationStageId.CURATED_SOURCES,
    currentCuratedAttribute: null,
    currentCuratedDataSource: null,
    currentGuidedTour: CurationGuidedTourStageId.DATA_SOURCES,
    guidedTourStatus: {
      [CurationGuidedTourStageId.DATA_SOURCES]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.ATTRIBUTES]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.FIELDS]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.PREVIEW]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.COLUMN_PROFILE]: CurationGuidedTourStageStatus.NOT_STARTED,
      [CurationGuidedTourStageId.ADDITIONAL_ATTRIBUTES]: CurationGuidedTourStageStatus.NOT_STARTED,
    },
    isPageInitialised: false,
  };
};

const reducer: Reducer<UseCurationState, UseCurationStatePayload> = (state, { action, data }) => {
  switch (action) {
    case UseCurationStateAction.UPDATE_GUIDED_TOUR_CURRENT_STAGE: {
      return {
        ...state,
        currentGuidedTour: data.currentGuidedTour,
      };
    }
    case UseCurationStateAction.INITIALIZE_USER_PREFERENCES: {
      const newState = {
        ...state,
        guidedTourStatus: { ...state.guidedTourStatus, ...data.guidedTourStatus },
        isPageInitialised: true,
      };
      return newState;
    }
    case UseCurationStateAction.UPDATE_GUIDED_TOUR_STATUS_BY_STAGE: {
      const newState = {
        ...state,
        guidedTourStatus: { ...state.guidedTourStatus, ...data },
        isPageInitialised: true,
      };
      return newState;
    }
    case UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE: {
      const {
        currentCuratedAttribute,
        currentCuratedDataSource,
        currentStageId,
        attributesCurationStatus,
        fieldsCurationStatus,
      } = data;

      const guidedTourStageId = getGuidedTourKeyByCurationStateId(currentStageId);

      let updatedState = {
        ...state,
        currentStageId,
        isPageInitialised: true,
        currentGuidedTour: guidedTourStageId,
      };

      if (!isUndefined(currentCuratedAttribute)) {
        updatedState = { ...updatedState, currentCuratedAttribute };
      }

      if (!isUndefined(currentCuratedDataSource)) {
        updatedState = { ...updatedState, currentCuratedDataSource };
      }

      if (!isUndefined(attributesCurationStatus)) {
        updatedState = { ...updatedState, attributesCurationStatus };
      }

      if (!isUndefined(fieldsCurationStatus)) {
        updatedState = { ...updatedState, fieldsCurationStatus };
      }

      return updatedState;
    }
    case UseCurationStateAction.UPDATE_ATTRIBUTES_CURATION_STATUS: {
      const { attributesCurationStatus } = data;

      return { ...state, attributesCurationStatus };
    }
    case UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS: {
      const { fieldsCurationStatus } = data;

      return { ...state, fieldsCurationStatus };
    }
    case UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS_INCREMENT: {
      const { fieldsCurationStatus } = state;

      return {
        ...state,
        fieldsCurationStatus: {
          ...fieldsCurationStatus,
          curatedCount: fieldsCurationStatus.curatedCount + 1,
        },
      };
    }
    default:
      return state;
  }
};

export const useCurationState = ({ scanProfileName }: UseCurationStateProps): UseCurationStateResponse => {
  const [
    {
      attributesCurationStatus,
      fieldsCurationStatus,
      currentStageId,
      currentCuratedAttribute,
      currentCuratedDataSource,
      guidedTourStatus,
      isPageInitialised,
      currentGuidedTour,
    },
    dispatch,
  ] = useReducer(reducer, {}, getCurationInitialState);

  const fetchAttributesCurationStatusCancelable = useCancelablePromise<{
    data: GetAttributesCurationStatusResponse;
  }>();
  const fetchFieldsCurationStatusCancelable = useCancelablePromise<{ data: GetFieldsCurationStatusResponse }>();
  const { t } = useLocalTranslation('Curation.common');

  const originState = useMemo((): HistoryItem => {
    const history: History[] = sessionStorageService.get('history') ?? [];
    let originState;

    if (history.length > 0) {
      const { to } = history.pop();
      originState = to;
    }

    return originState;
  }, []);

  const fetchAttributeCurationStatus = useCallback(async () => {
    try {
      const {
        data: { curationStatus },
      } = await fetchAttributesCurationStatusCancelable(
        getAttributeCurationStatus({
          sources: [currentCuratedDataSource.source],
        }) as Promise<{ data: GetAttributesCurationStatusResponse }>,
      );

      dispatch({
        action: UseCurationStateAction.UPDATE_ATTRIBUTES_CURATION_STATUS,
        data: {
          attributesCurationStatus: curationStatus,
        },
      });
    } catch ({ message }) {
      notificationService.error(t('errors.fetchingAttributeCurationStatus'));
      console.error(`An error has occurred: ${message}`);
    }
  }, [currentCuratedDataSource?.source, fetchAttributesCurationStatusCancelable, t]);

  const fetchFieldsCurationStatus = useCallback(async () => {
    try {
      const { attributeName, attributeType } = currentCuratedAttribute;

      const {
        data: { curationStatus },
      } = await fetchFieldsCurationStatusCancelable(
        getFieldsCurationStatus({
          sources: [currentCuratedDataSource.source],
          attributeName,
          attributeType,
        }) as Promise<{ data: GetFieldsCurationStatusResponse }>,
      );

      dispatch({
        action: UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS,
        data: {
          fieldsCurationStatus: curationStatus,
        },
      });
    } catch ({ message }) {
      notificationService.error(t('errors.fetchingFieldsCurationStatus'));
      console.error(`An error has occurred: ${message}`);
    }
  }, [currentCuratedAttribute, currentCuratedDataSource?.source, fetchFieldsCurationStatusCancelable, t]);

  const onProceedToAttributeList = useCallback((curatedDataSource: CuratedDataSource): void => {
    dispatch({
      action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
      data: {
        currentCuratedDataSource: curatedDataSource,
        currentStageId: CurationStageId.CURATED_ATTRIBUTES,
      },
    });
  }, []);

  const onProceedToFieldsReview = useCallback((curatedAttribute: CuratedAttribute): void => {
    dispatch({
      action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
      data: {
        currentCuratedAttribute: curatedAttribute,
        currentStageId: CurationStageId.CURATED_FIELDS,
      },
    });
  }, []);

  const onBackToDataSourceList = useCallback(() => {
    dispatch({
      action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
      data: {
        currentStageId: CurationStageId.CURATED_SOURCES,
        currentCuratedDataSource: null,
        currentCuratedAttribute: null,
        attributesCurationStatus: null,
        fieldsCurationStatus: null,
      },
    });
  }, []);

  const onBackToAttributeList = useCallback(() => {
    dispatch({
      action: UseCurationStateAction.UPDATE_CURATION_WIZARD_STAGE,
      data: {
        currentStageId: CurationStageId.CURATED_ATTRIBUTES,
        currentCuratedAttribute: null,
        fieldsCurationStatus: null,
      },
    });
  }, []);

  const onBackToOriginPage = useCallback(() => {
    const { state, params } = originState;
    $state.go(state, params);
  }, [originState]);

  const goToScanPage = useCallback(() => {
    $state.go(CONFIG.states.SCANS_NEW_SCANS_COMPLETED);
  }, []);

  const onCurationGuidedTourStart = useCallback(
    (currentGuidedTour?: CurationGuidedTourStageId) => {
      if (currentGuidedTour) {
        onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.STARTED_MANUALLY);
      } else {
        onGuidedTourStageChanged(
          getGuidedTourKeyByCurationStateId(currentStageId),
          CurationGuidedTourStageStatus.STARTED_MANUALLY,
        );
      }
    },
    [currentStageId],
  );

  const onFieldReviewed = useCallback(
    (isBulk: boolean) => {
      if (isBulk) {
        fetchFieldsCurationStatus();
      } else {
        dispatch({
          action: UseCurationStateAction.UPDATE_FIELDS_CURATION_STATUS_INCREMENT,
        });
      }
    },
    [fetchFieldsCurationStatus],
  );

  const onGuidedTourFirstRun = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId) => {
      if (guidedTourStatus[currentGuidedTour] === CurationGuidedTourStageStatus.NOT_STARTED) {
        onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.STARTED_AUTOMATICALLY);
      }
    },
    [guidedTourStatus],
  );

  const onProceedToFieldsReviewTab = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId, openGuidedTour: boolean) => {
      if (openGuidedTour) {
        onGuidedTourFirstRun(currentGuidedTour);
        onUpdateGuidedTourCurrentStage(currentGuidedTour);
      }
    },
    [onGuidedTourFirstRun],
  );

  const onGuidedTourStageFinished = useCallback(
    (currentGuidedTour: CurationGuidedTourStageId) => {
      if (guidedTourStatus[currentGuidedTour] === CurationGuidedTourStageStatus.STARTED_AUTOMATICALLY) {
        const userNewPreferences = {
          guidedTourStatus: {
            ...guidedTourStatus,
            ...{ [currentGuidedTour]: CurationGuidedTourStageStatus.IS_AKNOWLEGED },
          },
        };
        setUserPreferences(userNewPreferences);
      }
      onGuidedTourStageChanged(currentGuidedTour, CurationGuidedTourStageStatus.IS_AKNOWLEGED);
    },
    [guidedTourStatus],
  );

  const setCurationWizardBreadcrumbs = useCallback(
    (currentStageId: CurationStageId, { onCurationGuidedTourStart }: CurationHeaderHelpIconProps): void => {
      const t = getFixedT('Curation.breadcrumbs');
      const breadcrumbs: Breadcrumb[] = [];

      switch (originState?.state) {
        case CONFIG.states.SCANS_NEW_SCANS_COMPLETED:
          breadcrumbs.push({
            label: t('completedScans'),
            onClick: onBackToOriginPage,
          });
          break;
        case CONFIG.states.SCANS_SCAN_INSIGHTS:
          breadcrumbs.push({
            label: t('scanProfile', { scanProfile: scanProfileName }),
            onClick: onBackToOriginPage,
          });
          break;
        case CONFIG.states.DATA_SOURCE_CONNECTIONS:
          breadcrumbs.push({
            label: t('dataSourcesPage'),
            onClick: onBackToOriginPage,
          });
          break;
      }

      switch (currentStageId) {
        case CurationStageId.CURATED_SOURCES:
          breadcrumbs.push({
            label: t('dataSources'),
          });
          break;
        case CurationStageId.CURATED_ATTRIBUTES:
          breadcrumbs.push(
            {
              label: t('dataSources'),
              onClick: onBackToDataSourceList,
            },
            {
              label: t('attributes', { dataSource: currentCuratedDataSource.source }),
            },
          );
          break;
        case CurationStageId.CURATED_FIELDS:
          breadcrumbs.push(
            {
              label: t('dataSources'),
              onClick: onBackToDataSourceList,
            },
            {
              label: t('attributes', { dataSource: currentCuratedDataSource.source }),
              onClick: onBackToAttributeList,
            },
            {
              label: t('reviewObjects', {
                attributeName: currentCuratedAttribute.displayName,
              }),
            },
          );
          break;
      }

      const helpIcon = curationPageTitleHelper({ onCurationGuidedTourStart });
      pageHeaderService.setTitle({
        breadcrumbs,
        ...helpIcon,
      });
    },
    [
      currentCuratedAttribute?.displayName,
      currentCuratedDataSource?.source,
      onBackToAttributeList,
      onBackToDataSourceList,
      onBackToOriginPage,
      originState?.state,
      scanProfileName,
    ],
  );

  useEffect(() => {
    switch (currentStageId) {
      case CurationStageId.CURATED_ATTRIBUTES:
        fetchAttributeCurationStatus();
        break;
      case CurationStageId.CURATED_FIELDS:
        fetchFieldsCurationStatus();
        break;
    }
  }, [currentStageId, fetchAttributeCurationStatus, fetchFieldsCurationStatus]);

  useEffect(() => {
    async function getUserCurationPreferences() {
      const { guidedTourStatus } = await getUserPreferences();
      dispatch({ action: UseCurationStateAction.INITIALIZE_USER_PREFERENCES, data: { guidedTourStatus } });
    }
    getUserCurationPreferences();
  }, []);

  useEffect(() => {
    setCurationWizardBreadcrumbs(currentStageId, { onCurationGuidedTourStart });
  }, [currentStageId, onCurationGuidedTourStart, setCurationWizardBreadcrumbs]);

  const onGuidedTourStageChanged = (
    currentGuidedTour: CurationGuidedTourStageId,
    shouldRun: CurationGuidedTourStageStatus,
  ) => {
    dispatch({
      action: UseCurationStateAction.UPDATE_GUIDED_TOUR_STATUS_BY_STAGE,
      data: { [currentGuidedTour]: shouldRun },
    });
  };

  const onUpdateGuidedTourCurrentStage = (currentGuidedTour: CurationGuidedTourStageId) => {
    dispatch({
      action: UseCurationStateAction.UPDATE_GUIDED_TOUR_CURRENT_STAGE,
      data: { currentGuidedTour: currentGuidedTour },
    });
  };

  return {
    currentStageId,
    originState,
    attributesCurationStatus,
    fieldsCurationStatus,
    currentCuratedAttribute,
    currentCuratedDataSource,
    onProceedToAttributeList,
    onProceedToFieldsReview,
    onProceedToFieldsReviewTab,
    onGuidedTourFirstRun,
    onBackToDataSourceList,
    onBackToAttributeList,
    onBackToOriginPage,
    goToScanPage,
    onFieldReviewed,
    guidedTourStatus,
    isPageInitialised,
    onGuidedTourStageFinished,
    currentGuidedTour,
    onCurationGuidedTourStart,
  };
};
