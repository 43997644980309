import React, { FC, useMemo, memo, useContext } from 'react';
import {
  SecondaryButton,
  BigidButtonTypography,
  BigidHeading6,
  BigidHeading3,
  BigidBody1,
  PrimaryButton,
  BigidTooltip,
  BigidHeading5,
} from '@bigid-ui/components';
import { DataSourceTestConnectionStatusEnum } from '../hooks/useTestConnection';
import { useStyles } from './DataSourceTestConnectionStyles';
import { isPermitted } from '../../../../services/userPermissionsService';
import { ADVANCE_TOOLS_PERMISSIONS, DATA_SOURCES_PERMISSIONS } from '@bigid/permissions';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import { DataSourcesTestConnectionGrid } from '../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { getScannerLogs, GetHighlightedWordsForLogType } from '../utils/scannerLogsUtils';
import { isMultiTenantModeEnabled } from '../../../../utilities/multiTenantUtils';
import { BigidConnectIllustration, BigidDisconnectedIllustration } from '@bigid-ui/icons';

const RESULT_HEADER_TEXT_MAP = {
  [DataSourceTestConnectionStatusEnum.notStarted]: '',
  [DataSourceTestConnectionStatusEnum.success]: 'Connection Successful',
  [DataSourceTestConnectionStatusEnum.failed]: 'Connection Failed',
};

export interface DataSourceTestConnectionBoxProps {
  onTest: () => void;
  getHighlightedWordsForLog: GetHighlightedWordsForLogType;
  isNotSaved: boolean;
}

export const DataSourceTestConnectionBox: FC<DataSourceTestConnectionBoxProps> = memo(
  ({ onTest, getHighlightedWordsForLog }) => {
    const { lastTestDate, testError, testInProgress, testStatus, isTestAvailable, tablesResult, getValuesContainer } =
      useContext(DataSourceConfigurationContext);

    const {
      wrapper,
      dateWrapper,
      buttonTextWrapper,
      actionsWrapper,
      resultHeaderWrapper,
      errorTextWrapper,
      resultTableWrapper,
      tableNameWrapper,
      buttonTextFirstTestWrapper,
      connectionNotSupported,
      connectedIllustration,
    } = useStyles({ testStatus });

    const disabled = !isTestAvailable || testInProgress;
    const isFirstTestAvailable = testStatus === DataSourceTestConnectionStatusEnum.notStarted && !disabled;

    const dateText = useMemo(() => `Tested on ${lastTestDate}`, [lastTestDate]);

    const rows = useMemo(
      () => tablesResult?.map((table, index) => ({ id: index.toString(), ...table })) || [],
      [tablesResult],
    );

    const error = useMemo(() => {
      const text =
        (typeof testError === 'string' && testError?.replace('- Please check logs for more details', '')) || '';
      return (
        testStatus === DataSourceTestConnectionStatusEnum.failed &&
        testError && (
          <BigidTooltip title={text} placement="top">
            <div data-aid="DataSourceTestConnectionErrorText" className={errorTextWrapper}>
              <BigidHeading3>{text}</BigidHeading3>
            </div>
          </BigidTooltip>
        )
      );
    }, [testError, testStatus, errorTextWrapper]);

    const resultHeader = useMemo(
      () =>
        RESULT_HEADER_TEXT_MAP[testStatus] && (
          <React.Fragment>
            <BigidHeading6 data-aid={`DataSourceTestConnection-result-${testStatus}`} className={resultHeaderWrapper}>
              {RESULT_HEADER_TEXT_MAP[testStatus]}
            </BigidHeading6>
            <BigidBody1 data-aid={`DataSourceTestConnection-result-date`} className={dateWrapper}>
              {dateText}
            </BigidBody1>
            {error}
          </React.Fragment>
        ),
      [dateText, dateWrapper, error, resultHeaderWrapper, testStatus],
    );

    const TestButtonComponent = isFirstTestAvailable ? PrimaryButton : SecondaryButton;

    const { type } = getValuesContainer.current();
    const isNotTestable = ['hadoop', 'external-catalog', 'emr'].includes(type);

    return (
      <div className={wrapper} data-aid="DataSourceTestConnection">
        {isNotTestable ? (
          <>
            <div className={connectedIllustration}>
              <BigidDisconnectedIllustration />
            </div>
            <BigidHeading5 className={connectionNotSupported} data-aid="DataSourceTestConnection-text-not-supported">
              Test connection not supported!
            </BigidHeading5>
          </>
        ) : (
          <div className={actionsWrapper} data-aid="DataSourceTestConnection-actions">
            <div className={connectedIllustration}>
              {testStatus === DataSourceTestConnectionStatusEnum.success ? (
                <BigidConnectIllustration />
              ) : (
                <BigidDisconnectedIllustration width={340} height={208} />
              )}
            </div>
            {resultHeader}
            {isPermitted(DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name) && (
              <TestButtonComponent
                dataAid={`DataSourceTestConnectionTestButton`}
                onClick={onTest}
                size="large"
                disabled={disabled}
                margin="4px"
              >
                <BigidButtonTypography
                  className={isFirstTestAvailable ? buttonTextFirstTestWrapper : buttonTextWrapper}
                  data-aid={`DataSourceTestConnectionTestText-${testStatus}`}
                >
                  {testStatus === DataSourceTestConnectionStatusEnum.notStarted ? 'Test Connection' : 'Test again'}
                </BigidButtonTypography>
              </TestButtonComponent>
            )}

            {testStatus === DataSourceTestConnectionStatusEnum.failed &&
              isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name) &&
              !isMultiTenantModeEnabled() && (
                <SecondaryButton
                  data-aid={`DataSourceTestConnectionViewLogsButtons`}
                  disabled={testInProgress}
                  onClick={() => {
                    getScannerLogs(dateText, getHighlightedWordsForLog);
                  }}
                  size="large"
                  margin="4px"
                  text="View logs"
                />
              )}
            {tablesResult && testStatus === DataSourceTestConnectionStatusEnum.success && (
              <React.Fragment>
                <BigidHeading3 gutterBottom className={tableNameWrapper}>
                  Objects
                </BigidHeading3>
                <div className={resultTableWrapper} data-aid="DataSourceTestResultTableWrapper">
                  <DataSourcesTestConnectionGrid rows={rows} isSmall={true} />
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  },
);
