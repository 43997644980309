import { runScanActionDialog } from '../ScanService';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidFieldFilterOperator, BigidLoader, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialActiveScansGridColumns } from './ActiveScansGridConfiguration';
import { ActiveScanState, ScanActions, ScansGridRow, SSEType } from '../ScanTypes';
import {
  fetchScanData,
  shouldShowScanAction,
  getInitialScanFilters,
  subscribeToSSEScanUpdates,
  STOP_DISABLED_LABEL,
  PAUSED_DISABLED_LABEL,
  isFinishedState,
} from '../ScanUtils';
import { BigidPauseIcon, BigidResumeIcon, BigidStopIcon } from '@bigid-ui/icons';
import { ScanViewEnum } from '../ScansEventTrackerUtils';
import { NoDataContentActiveScans } from './NoDataContentActiveScans';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '91%',
    maxHeight: '91%',
    overflow: 'hidden',
  },
});

// Need to add backend support to those filters
const ACTIVE_STATES_FILTER = [
  ActiveScanState.IN_PROGRESS,
  ActiveScanState.NOT_RUNNING,
  ActiveScanState.PAUSE_REQUESTED,
  ActiveScanState.PAUSED,
  ActiveScanState.RESUME_REQUESTED,
  ActiveScanState.PENDING,
  ActiveScanState.STOP_REQUESTED,
];

const ACTIVE_STATES = [ActiveScanState.STARTED];
const defaultFilter = [{ field: 'state', operator: 'in' as BigidFieldFilterOperator, value: ACTIVE_STATES }];

export const ActiveScans: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    const unregister = subscribeToSSEScanUpdates(SSEType.PARENT, ScanViewEnum.ACTIVITY_QUEUE);
    return () => unregister();
  }, []);

  const initialActiveScansGridColumns = useMemo(getInitialActiveScansGridColumns, []);
  const getInitialFilterToolbarConfig = useCallback(() => getInitialScanFilters(ACTIVE_STATES_FILTER), []);
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialActiveScansGridColumns,
    getInitialFilterToolbarConfig,
  });

  const config: BigidGridWithToolbarProps<ScansGridRow> = useMemo(
    () => ({
      pageSize: 100,
      entityName: 'Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'startTime', order: 'desc' }],
      showSelectionColumn: true,
      showSortingControls: true,
      showSelectAll: false,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: queryComponents => fetchScanData(queryComponents, defaultFilter),
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Pause',
          icon: BigidPauseIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.PAUSE),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isPauseAvailable, isRbacPermitted }] }) => isPauseAvailable && isRbacPermitted,
        },
        {
          label: PAUSED_DISABLED_LABEL,
          icon: () => <BigidPauseIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Pause" />,
          execute: async _actionData => ({}),
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isPauseAvailable, isResumeAvailable, isRbacPermitted }] }) => {
            return isRbacPermitted && !isResumeAvailable && !isPauseAvailable;
          },
        },
        {
          label: 'Resume',
          icon: BigidResumeIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.RESUME),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isResumeAvailable, isRbacPermitted }] }) => isResumeAvailable && isRbacPermitted,
        },
        {
          label: 'Stop',
          icon: BigidStopIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.STOP),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ state, isStopAvailable, isRbacPermitted }] }) =>
            isStopAvailable &&
            isRbacPermitted &&
            shouldShowScanAction({
              state,
              stateCondition: [ActiveScanState.STOP_REQUESTED],
              notEqual: true,
            }),
        },
        {
          label: STOP_DISABLED_LABEL,
          icon: () => <BigidStopIcon staticMode={true} disabled={true} dataAid="GridInlineActions-icon-Stop" />,
          execute: async _actionData => ({}),
          disable: () => true,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isStopAvailable, isRbacPermitted }] }) => !isStopAvailable && isRbacPermitted,
        },
      ],
      noDataContent: <NoDataContentActiveScans />,
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
    </div>
  );
};
