import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import BigidAppGenericIcon from '../../../../../assets/icons/BigidAppGeneric.svg';
import {
  BigidDialog,
  BigidDialogButtonType,
  PrimaryButton,
  BigidCarouselMedia,
  BigidHeading4,
  BigidBody1,
  BigidCarousel,
} from '@bigid-ui/components';
import { MarketplaceApp } from '../../../applicationManagementService';
import { CustomAppIcon } from '../../../Components/CustomAppIcon';
import { ClickToInstallTabs } from './ClickToInstallTabs/ClickToInstallTabs';

interface AppInfoModalProps {
  appData: MarketplaceApp;
  isOpen: boolean;
  onClose: () => void;
  onClickToInstall: (installationUrl: string) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  appIcon: {
    height: 24,
    width: 24,
  },
  carouselWrapper: {
    marginTop: 24,
  },
  detailsWrapper: {
    overflow: 'hidden',
    display: 'box',
    lineClamp: 5,
    boxOrient: 'vertical',
  },
});

export const goToMarketplace = (marketplaceLink: string) => {
  window.open(marketplaceLink, '_blank');
};

export const AppInfoModal: FC<AppInfoModalProps> = ({ appData, onClose, isOpen, onClickToInstall }) => {
  const classes = useStyles();

  const handleMoreInfoButtonClicked = () => {
    goToMarketplace(appData.marketplaceLink);
  };

  const getButtons = (installationUrl: string): BigidDialogButtonType[] =>
    installationUrl
      ? [{ component: PrimaryButton, onClick: () => onClickToInstall(installationUrl), text: 'Install' }]
      : [{ component: PrimaryButton, onClick: handleMoreInfoButtonClicked, text: 'More Info' }];

  const images: BigidCarouselMedia[] = appData?.mediaLinks?.images?.map(url => ({ type: 'img', url })) || [];
  const videos: BigidCarouselMedia[] = appData?.mediaLinks?.videos?.map(url => ({ type: 'video', url })) || [];
  const shouldShowCarousel = images.length > 0 || videos.length > 0;

  return (
    <>
      {appData && (
        <BigidDialog
          isOpen={isOpen}
          onClose={onClose}
          title={` ${appData.friendlyName || appData.appName}`}
          buttons={getButtons(appData.installationUrl)}
          showCloseIcon
          icon={() =>
            appData.icon ? (
              <CustomAppIcon logo={appData.icon} isSidebar />
            ) : (
              <BigidAppGenericIcon className={classes.appIcon} />
            )
          }
        >
          <div className={classes.root}>
            <BigidHeading4>{appData.description}</BigidHeading4>
            <div className={classes.detailsWrapper}>
              <BigidBody1 title={appData.details}>{appData.details}</BigidBody1>
            </div>
            {shouldShowCarousel && (
              <div className={classes.carouselWrapper}>
                <BigidCarousel
                  items={images.concat(videos)}
                  isAutoPlay={false}
                  mediaHeight={200}
                  size={'sm'}
                  mediaObjectFit={'contain'}
                />
              </div>
            )}
            {appData.installationUrl && <ClickToInstallTabs appData={appData} />}
          </div>
        </BigidDialog>
      )}
    </>
  );
};
