import React, { useEffect, useState } from 'react';
import { getOnboardingCollaborationTasksOwners } from '../AutoDiscoveryWizard/autoDiscoveryCommonComponents/AutoDiscoveryCollaboration/autoDiscoveryCollaborationService';
import { uniq } from 'lodash';
import { User } from '../../../utilities/systemUsersUtils';
import { mapUserToBigidDropdownOption } from '../../DataSources/DataSourceConnectionModal/mappers/collaboration';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';

export interface UseCheckAutoDiscoverCollaboration {
  users: User[];
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCheckAutoDiscoverCollaboration = ({
  users,
  discoveryConfigDataRef,
  setIsLoading,
}: UseCheckAutoDiscoverCollaboration): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const { t } = useLocalTranslation('AutoDiscovery');
  const [inviteStatus, setInviteStatus] = useState(false);

  useEffect(() => {
    const checkIfTaskCreated = async () => {
      try {
        const collaboratorsIds = await getOnboardingCollaborationTasksOwners(discoveryConfigDataRef?.current?.type);
        if (collaboratorsIds?.length) {
          const usersForMap = uniq(collaboratorsIds).map(
            id => users.find(user => user.id === id) ?? ({ name: id, id } as User),
          );
          const collaborators = mapUserToBigidDropdownOption(usersForMap);
          setInviteStatus(true);
          discoveryConfigDataRef.current = {
            ...discoveryConfigDataRef.current,
            collaborators,
          };
        }
      } catch (e) {
        notificationService.error(t('collaborateModal.createError'));
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    };

    if (!!users) {
      checkIfTaskCreated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!users]);

  return [inviteStatus, setInviteStatus];
};
