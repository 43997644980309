import { BigidSideFilterSectionBase } from '@bigid-ui/components';
import { getFixedT } from './translations';
import { fakeSelection } from './utils';

const t = getFixedT('');

const fetchAssetTypes = async () => {
  const assetTypesConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: 'field',
    displayName: t('SideFilter.sections.assetType'),
    showTooltips: true,

    items: [
      {
        id: 'attribute',
        name: 'Attribute',
        value: 'attribute',
        isSelected: false,
      },
      {
        id: 'businessTerm',
        name: 'Business Term',
        value: 'businessTerm',
        isSelected: false,
      },
    ],
  };
  return assetTypesConfig;
};

const fetchAttributes = async () => {
  // const attributes = await getDistinctAttributes();
  const attributesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.attributes'),
    displayName: t('SideFilter.sections.attributes'),
    showTooltips: true,
    items: [
      {
        id: 'accountHolder',
        name: 'Account Holder',
        value: 'accountHolder',
        isSelected: false,
      },
      {
        id: 'accountId',
        name: 'Account ID',
        value: 'accountId',
        isSelected: false,
      },
      {
        id: 'accountName',
        name: 'Account Name',
        value: 'accountName',
        isSelected: false,
      },
      {
        id: 'address',
        name: 'Address',
        value: 'address',
        isSelected: false,
      },
      {
        id: 'addressNew',
        name: 'Address-new',
        value: 'addressNew',
        isSelected: false,
      },
      {
        id: 'addressType',
        name: 'Address Type',
      },
      {
        id: 'age',
        name: 'Age',
      },
    ],
  };
  return attributesSectionConfig;
};

const fetchBusinessTerms = async () => {
  const businessTermsSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.businessTerms'),
    displayName: t('SideFilter.sections.businessTerms'),
    showTooltips: true,
    items: [
      {
        id: 'accountHolder',
        name: 'Account Holder',
        value: 'accountHolder',
        isSelected: false,
      },
      {
        id: 'actionTimeLimit',
        name: 'Action Time Limit',
        value: 'actionTimeLimit',
        isSelected: false,
      },
      {
        id: 'additionalProductIdentification',
        name: 'Additional Product Identification',
        isSelected: false,
      },
    ],
  };
  return businessTermsSectionConfig;
};

const fetchDomains = async () => {
  const domainsSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.domain'),
    displayName: t('SideFilter.sections.domain'),
    showTooltips: true,
    items: [
      {
        id: 'airlines',
        name: 'Airlines',
      },
      {
        id: 'banking',
        name: 'Banking',
      },
      {
        id: 'Retail',
        name: 'Retail',
      },
      {
        id: 'telecom',
        name: 'Telecom',
      },
      {
        id: 'travel',
        name: 'Travel',
      },
    ],
  };
  return domainsSectionConfig;
};

const fetchCertificationStatuses = async () => {
  const certificationStatusesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.certificationStatus'),
    displayName: t('SideFilter.sections.certificationStatus'),
    showTooltips: true,
    items: [
      {
        id: 'underReview',
        name: 'Under Review',
      },
      {
        id: 'approved',
        name: 'Approved',
      },
      {
        id: 'rejected',
        name: 'Rejected',
      },
      {
        id: 'sentForApproval',
        name: 'Sent For Approval',
      },
      {
        id: 'pendingAttestation',
        name: 'Pending Attestation',
      },
    ],
  };
  return certificationStatusesSectionConfig;
};

const fetchTags = async () => {
  const tagsSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.tags'),
    displayName: t('SideFilter.sections.tags'),
    showTooltips: true,
    items: [
      {
        id: 'asset',
        name: 'Asset',
      },
      {
        id: 'attribute',
        name: 'Attribute',
      },
      {
        id: 'stock',
        name: 'Stock',
      },
      {
        id: 'customer:silver',
        name: 'Customer: Silver',
      },
      {
        id: 'customer:gold',
        name: 'Customer: Gold',
      },
    ],
  };
  return tagsSectionConfig;
};

const fetchCategories = async () => {
  const categoriesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.categories'),
    displayName: t('SideFilter.sections.categories'),
    showTooltips: true,
    items: [
      {
        id: 'Personal Sensitive',
        name: 'Personal Sensitive',
      },
      {
        id: 'Health Information',
        name: 'Health Information',
      },
      {
        id: 'Non Sensitive Info',
        name: 'Non Sensitive Info',
      },
      {
        id: 'HumanResource',
        name: 'HumanResource',
      },
    ],
  };
  return categoriesSectionConfig;
};

const fetchAttributeTypes = async () => {
  const attributeTypesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.attributeType'),
    displayName: t('SideFilter.sections.attributeType'),
    showTooltips: true,
    items: [
      {
        id: 'manual',
        name: 'Manual',
      },
      {
        id: 'classification',
        name: 'Classification',
      },
      {
        id: 'IDSoR',
        name: 'IDSoR',
      },
      {
        id: 'Enrichment',
        name: 'Enrichment',
      },
    ],
  };
  return attributeTypesSectionConfig;
};

const fetchDataSources = async () => {
  const dataSourcesSectionConfig: BigidSideFilterSectionBase = {
    id: 'field',
    name: t('SideFilter.sections.dataSources'),
    displayName: t('SideFilter.sections.dataSources'),
    showTooltips: true,
    items: [
      {
        id: 'APAC Users',
        name: 'APAC Users',
      },
      {
        id: 'Banking Dev',
        name: 'Banking Dev',
      },
      {
        id: 'Banking Prod',
        name: 'Banking Prod',
      },
      {
        id: 'Benefits',
        name: 'Benefits',
      },
    ],
  };
  return dataSourcesSectionConfig;
};

const fetchIsCertifiedOnlySelected = async () => {
  return fakeSelection.getIsSelected();
};

const updateIsCertifiedOnlySelected = async (isSelected: boolean) => {
  fakeSelection.setIsSelected(isSelected);
};

export {
  fetchBusinessTerms,
  fetchDomains,
  fetchCertificationStatuses,
  fetchTags,
  fetchCategories,
  fetchAttributeTypes,
  fetchDataSources,
  fetchAssetTypes,
  fetchAttributes,
  fetchIsCertifiedOnlySelected,
  updateIsCertifiedOnlySelected,
};
