import React, { FC } from 'react';
import { BigidBody1, BigidColorsV2, BigidHeading4 } from '@bigid-ui/components';
import styled from '@emotion/styled';

export interface HorizontalBarTooltipProps {
  left?: string;
  right?: string;
  bottom?: string;
}

const Left = styled('div')({
  color: BigidColorsV2.purple[400],
  display: 'inline-block',
  marginRight: 8,
});

const Right = styled('div')({
  display: 'inline-block',
});

const StyledBigidBody1 = styled(BigidBody1)({
  color: BigidColorsV2.gray[500],
  whiteSpace: 'pre-wrap',
});

export const BigidHorizontalBarWithActionsRowTooltip: FC<HorizontalBarTooltipProps> = ({ left, right, bottom }) => {
  return (
    <>
      <BigidHeading4>
        <Left>{left}</Left>
        <Right>{right}</Right>
      </BigidHeading4>
      <StyledBigidBody1>{bottom}</StyledBigidBody1>
    </>
  );
};
