import React, { FC } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGridConfiguration';
import { TYPE_TO_ICON } from '../constants';

interface AutoDiscoveryConfigNameCellProps {
  dataAid?: string;
  row: AutoDiscoveryConfigModel;
}

const Root = styled('div')`
  width: 100%;
  height: 100%;
`;

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const IconContainer = styled('div')`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AutoDiscoveryConfigNameCell: FC<AutoDiscoveryConfigNameCellProps> = ({
  dataAid = 'AutoDiscoveryConfigNameCell',
  row: { name, type },
}) => {
  const Icon = TYPE_TO_ICON[type];
  return (
    <Root data-aid={generateDataAid(dataAid, ['discoveryConfigNameCell'])}>
      <CellContent>
        <IconContainer>
          <Icon size={'small'} />
        </IconContainer>
        {name}
      </CellContent>
    </Root>
  );
};
