import { compact, find, flatten, orderBy, slice } from 'lodash';
import { SidebarMenuGroup, SidebarMenuItem } from './Sidebar';
import { CONFIG } from '../../../config/common';
import CorrelationIcon from '../../assets/icons/correlation.svg';
import ClassificationIcon from '../../assets/icons/classification.svg';
import { sidebarService } from '../../services/sidebarService';
import ClusteringIcon from '../../assets/icons/clustering.svg';
import CatalogIcon from '../../assets/icons/catalog.svg';
import DashboardIcon from '../../assets/icons/dashboard.svg';
import SpaceshipIcon from '../../assets/icons/spaceship.svg';
import FlameIcon from '../../assets/icons/flame.svg';
import InventoryIcon from '../../assets/icons/inventory.svg';
import PoliciesIcon from '../../assets/icons/policies.svg';
import ReportsIcon from '../../assets/icons/reports.svg';
import AdministrationIcon from '../../assets/icons/administration.svg';
import ApplicationsIcon from '../../assets/icons/BigidSidebarApplications.svg';
import { APPS_PAGE_TITLE } from '../../views/ApplicationsManagement/ApplicationsManagement';
import {
  AppPreferencesFavorites,
  CustomApps,
  getApps,
} from '../../views/ApplicationsManagement/applicationManagementService';
import SAPSidebarIcon from '../../assets/icons/BigidSidebarSAP.svg';
import CyberarkSidebarIcon from '../../assets/icons/BigidSidebarCyberark.svg';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import BigidDiscoveryInsights from '../../assets/icons/BigidDiscoveryInsights.svg';

import {
  BigidAppLogoRiskConfigurationIcon,
  BigidAppLogoAccessIntelligenceIcon,
  BigidAppLogoBreachResponseIcon,
  BigidAppLogoConsentGovernanceIcon,
  BigidAppLogoDataProcessingIcon,
  BigidAppLogoDataRightsFulfillmentIcon,
  BigidAppLogoPrivacyPortalIcon,
  BigidAppLogoFileLabelingIcon,
  BigidAppLogoDataDeletionIcon,
  BigidAppLogoSapIcon,
  BigidNavigationAppCyberarkIcon,
  BigidNavigationAppHashicorpIcon,
  BigidAppLogoDataPipelineIcon,
  BigidAppLogoDataInsightsStudioIcon,
  BigidNavigationAppGenericIcon,
} from '@bigid-ui/icons';

import { appsLicenseService } from '../../services/appsLicenseService';
import { licenseService } from '../../services/licenseService';
import { hasRootScope, isPermitted } from '../../services/userPermissionsService';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  ACTION_CENTER_PERMISSIONS,
  ADVANCE_TOOLS_PERMISSIONS,
  APPLICATION_SETUP_PERMISSIONS,
  AUDIT_PERMISSIONS,
  CATALOG_PERMISSIONS,
  CERTIFICATES_PERMISSIONS,
  CLASSIFICATION_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  CORRELATION_PERMISSIONS,
  CORRELATION_SET_PERMISSIONS,
  CREDENTIALS_PERMISSIONS,
  CUSTOM_DASHBOARD_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  INVENTORY_PERMISSIONS,
  POLICIES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCAN_RESULT_DETAILS_PERMISSIONS,
  SCAN_WINDOWS_PERMISSIONS,
  SCANS_PERMISSIONS,
  SECONDARY_SOURCE_PERMISSIONS,
  TAGS_PERMISSIONS,
  TAGS_SAVED_QUERIES_PERMISSIONS,
  COMPOUND_ATTRIBUTE_PERMISSIONS,
  ACTIONABLE_INSIGHTS_PERMISSIONS,
  SENSITIVITY_CLASSIFICATION_PERMISSIONS,
  GETTING_STARTED_PERMISSIONS,
} from '@bigid/permissions';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { isDataExplorerEnabled } from '../../views/DataExplorer/dataExplorerService';
import { CUSTOM_APP_PAGE } from '../NavigationSideBar/NavigationSidebar';
import { isMultiTenantConfigEnabled, isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';
import GenericIcon from '../../assets/icons/BigidSidebarGeneric.svg';

const MAX_FAVORITES_IN_MENU = 3;

const isLocked = (state?: string) =>
  state ? !smallIdLicenseService.isAccessGranted(state) : smallIdLicenseService.isSmallIDLicense();

const isSmallId = () => smallIdLicenseService.isSmallIDLicense();

const isShown = (permission: string) => !licenseService.shouldBlockUserWithLicenseExpired() && isPermitted(permission);

const removeFmsdLockedItemsFromMenu = (groups: SidebarMenuGroup[]) =>
  groups
    .map(({ menuItems }) => ({
      menuItems: menuItems
        .map(({ innerMenuItems, selectedStates, ...rest }: SidebarMenuItem) => {
          const isLockedItem = !innerMenuItems && isLocked(selectedStates?.[0]);
          return (
            !isLockedItem && {
              ...rest,
              ...(selectedStates && { selectedStates }),
              ...(innerMenuItems && {
                innerMenuItems: innerMenuItems
                  .map(
                    innerMenuItem =>
                      !isLocked(innerMenuItem.selectedStates?.[0]) && {
                        ...innerMenuItem,
                      },
                  )
                  .filter(Boolean),
              }),
            }
          );
        })
        .filter(Boolean),
    }))
    .filter(({ menuItems }) => Boolean(menuItems.length));

export const getArrayToTranslate = (groups: SidebarMenuGroup[]): string[] =>
  groups.reduce((acc, group) => {
    const menuItemTitles = flatten(
      group.menuItems.map(menuItem => {
        if (menuItem.innerMenuItems) {
          const innerMenuItemTitles = menuItem.innerMenuItems.map(innerMenuItem => innerMenuItem.title);
          return [...innerMenuItemTitles, menuItem.title];
        } else {
          return menuItem.title;
        }
      }),
    );
    return [...acc, ...menuItemTitles];
  }, []);

export const getGroupsWithTranslations = (translations: any, groups: SidebarMenuGroup[]): SidebarMenuGroup[] =>
  groups.reduce((acc, group) => {
    const newGroup = {
      ...group,
      menuItems: group.menuItems.map(menuItem => {
        if (menuItem.innerMenuItems) {
          const newInnerMenuItems = menuItem.innerMenuItems.map(innerMenuItem => ({
            ...innerMenuItem,
            title: translations[innerMenuItem.title],
          }));
          return { ...menuItem, title: translations[menuItem.title], innerMenuItems: newInnerMenuItems };
        }
        return { ...menuItem, title: translations[menuItem.title] };
      }),
    };
    return [...acc, newGroup];
  }, []);

export const getAccordionMenuItemIfSelected = (
  groups: SidebarMenuGroup[],
  selectedMenuItem: string,
): SidebarMenuItem => {
  const accordionMenuItems = groups.reduce((acc, group) => {
    const accordionMenuItemsForGroup = group.menuItems.filter(menuItem => !!menuItem.innerMenuItems);
    acc = [...acc, ...accordionMenuItemsForGroup];
    return acc;
  }, []);

  return find(
    accordionMenuItems,
    accordionMenuItem =>
      !!find(
        accordionMenuItem.innerMenuItems,
        innerMenuItem => innerMenuItem.selectedStates && innerMenuItem.selectedStates.includes(selectedMenuItem),
      ),
  );
};

export const getGroups = (): SidebarMenuGroup[] => {
  const groups = [];
  if (
    isSmallId()
      ? isShown(GENERAL_SETTINGS_PERMISSIONS.ACCESS.name)
      : getApplicationPreference('SHOW_GETTING_STARTED_PAGE_FF') && isShown(GETTING_STARTED_PERMISSIONS.ACCESS.name)
  ) {
    groups.push({
      menuItems: [
        {
          icon: SpaceshipIcon,
          title: 'SIDEBAR:GETTING_STARTED',
          link: CONFIG.states.GETTING_STARTED,
          selectedStates: [CONFIG.states.GETTING_STARTED],
        },
      ],
    });
  }
  if (getApplicationPreference('SHOW_DASHBOARD_PAGE') && isShown(DASHBOARD_PERMISSIONS.ACCESS.name)) {
    groups.push({
      menuItems: [
        {
          icon: DashboardIcon,
          title: 'SIDEBAR:DASHBOARD',
          link: CONFIG.states.DASHBOARD,
          selectedStates: [CONFIG.states.DASHBOARD],
        },
      ],
    });
  }
  if (isShown(INVENTORY_PERMISSIONS.ACCESS.name)) {
    if (getApplicationPreference('DATA_OVERVIEW_ENABLED')) {
      groups.push({
        menuItems: [
          {
            icon: BigidDiscoveryInsights,
            title: 'SIDEBAR:DISCOVERY_OVERVIEW',
            link: CONFIG.states.CATALOG_DISCOVERY,
            selectedStates: [CONFIG.states.CATALOG_DISCOVERY],
          },
        ],
      });
    } else if (!isMultiTenantModeEnabled()) {
      groups.push({
        menuItems: [
          {
            icon: InventoryIcon,
            title: 'SIDEBAR:INVENTORY',
            link: CONFIG.states.INVENTORY,
            selectedStates: [CONFIG.states.INVENTORY],
          },
        ],
      });
    }
  }

  const fourCsGroup = getFourCsGroup();
  fourCsGroup.length > 0 && groups.push({ menuItems: fourCsGroup });

  const actionCenterReportsGroup = [];
  const actionCenterGroup = getActionCenterInnerMenu();
  if (actionCenterGroup.length > 0) {
    actionCenterReportsGroup.push({
      icon: PoliciesIcon,
      title: 'SIDEBAR:ACTION_CENTER',
      innerMenuItems: actionCenterGroup,
    });
  }

  const reportsInnerMenu = [];

  if (isShown(REPORTS_PERMISSIONS.ACCESS_EXECUTIVE_REPORT.name) && getApplicationPreference('SHOW_EXECUTIVE_REPORT')) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:EXECUTIVE_REPORT',
      link: CONFIG.states.EXECUTIVE_REPORT,
      selectedStates: [CONFIG.states.EXECUTIVE_REPORT],
    });
  }
  if (isShown(REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name) && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:DIGEST_REPORT',
      link: CONFIG.states.DIGEST_REPORT,
      selectedStates: [CONFIG.states.DIGEST_REPORT],
    });
  }
  if (
    getApplicationPreference('RISK_ASSESSMENT_ENABLED') &&
    isShown(REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name) &&
    !isMultiTenantModeEnabled()
  ) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:RISK_ASSESSMENT',
      link: CONFIG.states.RISK_ASSESSMENT,
      selectedStates: [CONFIG.states.RISK_ASSESSMENT],
    });
  }
  if (isShown(SCAN_RESULT_DETAILS_PERMISSIONS.ACCESS.name)) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:SCAN_RESULTS_DETAILS',
      link: CONFIG.states.SCAN_ANALYSIS,
      selectedStates: [CONFIG.states.SCAN_ANALYSIS],
    });
  }
  if (isShown(REPORTS_PERMISSIONS.READ_SCAN_RESULT_SUMMARY.name) && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:SCAN_RESULTS_SUMMARY',
      link: CONFIG.states.SCAN_RESULT,
      selectedStates: [CONFIG.states.SCAN_RESULT],
    });
  }
  const isEnabledScannedFilesReport =
    getApplicationPreference('ENABLE_SCANNED_FILES_REPORT') && !isMultiTenantModeEnabled();
  if (isShown(REPORTS_PERMISSIONS.DOWNLOAD_SCAN_FILES_REPORT.name) && isEnabledScannedFilesReport) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:SCANNED_FILES',
      onClick: sidebarService.onGetScannedFilesReport,
    });
  }
  if (isShown(REPORTS_PERMISSIONS.DOWNLOAD_DATA_CUSTODIAN_REPORT.name) && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:DATA_CUSTODIAN',
      onClick: sidebarService.onGetDataCustodianReport,
    });
  }
  if (isShown(REPORTS_PERMISSIONS.DOWNLOAD_FILES_ATTRIBUTE_DISTRIBUTION_REPORT.name) && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:FILES_ATTRIBUTE_DIST',
      onClick: sidebarService.onGetFilesAttributeDistributionReport,
    });
  }
  if (
    getApplicationPreference('LABELER_ENABLED') &&
    isShown(REPORTS_PERMISSIONS.DOWNLOAD_LABELING_PROPAGATION_REPORT.name)
  ) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:LABELER',
      onClick: sidebarService.onGetLabelerReport,
    });
  }

  if (isShown(CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name) && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.unshift({
      title: 'SIDEBAR:REPORTS:CUSTOM_DASHBOARD',
      link: CONFIG.states.CUSTOM_DASHBOARD,
      selectedStates: [CONFIG.states.CUSTOM_DASHBOARD],
    });
  }

  if (isShown(REPORTS_PERMISSIONS.DOWNLOAD_USAGE_REPORT.name) && hasRootScope() && !isMultiTenantModeEnabled()) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:USAGE',
      onClick: sidebarService.onGetUsageReport,
    });
  }

  // hide this for now per Tomer's request
  /*  if (getApplicationPreference('METADATA_CLASSIFIER_ENABLED')) {
        reportsInnerMenu.push({
          title: 'SIDEBAR:REPORTS:METADATA_CLASSIFIER',
          onClick: sidebarService.onGetMetadataClassifierReport,
        });
      }*/

  // TODO: what is the permission for this (?)
  if (getApplicationPreference('ENTITY_LEVEL_TAGGING_SUPPORTED')) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:TAGGED_ENTITIES',
      onClick: sidebarService.onGetTaggedEntityReport,
    });
  }

  if (isShown(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name) && getApplicationPreference('ENABLE_HOTSPOTS_REPORT')) {
    reportsInnerMenu.push({
      title: 'SIDEBAR:REPORTS:HOTSPOTS_REPORT',
      link: CONFIG.states.HOTSPOTS_REPORT,
      selectedStates: [CONFIG.states.HOTSPOTS_REPORT],
    });
  }

  if (
    // TODO: turn string permissins to array in isShown function
    isShown(REPORTS_PERMISSIONS.ACCESS.name) ||
    isShown(SCAN_RESULT_DETAILS_PERMISSIONS.ACCESS.name) ||
    isShown(CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name)
  ) {
    actionCenterReportsGroup.push({ icon: ReportsIcon, title: 'SIDEBAR:REPORTS', innerMenuItems: reportsInnerMenu });
  }
  actionCenterReportsGroup.length > 0 && groups.push({ menuItems: actionCenterReportsGroup });

  const administrationInnerMenu = getAdministrationInnerMenu();
  administrationInnerMenu.length > 0 &&
    groups.push({
      menuItems: [
        {
          icon: AdministrationIcon,
          title: 'SIDEBAR:ADMIN',
          innerMenuItems: administrationInnerMenu,
        },
      ],
    });

  return removeFmsdLockedItemsFromMenu(groups);
};

const getFourCsGroup = (): SidebarMenuItem[] => {
  const fourCsGroup = [];
  if (getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED') && isShown(ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name)) {
    fourCsGroup.push({
      icon: FlameIcon,
      title: 'SIDEBAR:ACTIONABLE_INSIGHTS',
      link: CONFIG.states.ACTIONABLE_INSIGHTS,
      selectedStates: [CONFIG.states.ACTIONABLE_INSIGHTS, CONFIG.states.ACTIONABLE_INSIGHTS_CASE],
    });
  }
  if (isShown(CORRELATION_PERMISSIONS.ACCESS.name)) {
    fourCsGroup.push({
      icon: CorrelationIcon,
      title: 'SIDEBAR:ENTITY_CORRELATION',
      link: CONFIG.states.CORRELATION,
      selectedStates: [CONFIG.states.CORRELATION],
    });
  }
  if (getApplicationPreference('CLUSTERING_ENABLED') && isShown(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name)) {
    fourCsGroup.push({
      icon: ClusteringIcon,
      title: 'SIDEBAR:CLUSTERING',
      link: CONFIG.states.CLUSTERING,
      selectedStates: [CONFIG.states.CLUSTERING],
    });
  }
  if (isShown(CLASSIFICATION_PERMISSIONS.ACCESS.name)) {
    fourCsGroup.push({
      icon: ClassificationIcon,
      title: 'SIDEBAR:CLASSIFICATION',
      link: CONFIG.states.CLASSIFICATION,
      selectedStates: [CONFIG.states.CLASSIFICATION],
    });
  }

  //TODO: consider adding RBAC for this feature
  if (isDataExplorerEnabled()) {
    fourCsGroup.push({
      icon: CatalogIcon,
      title: 'SIDEBAR:DATA_EXPLORER',
      link: CONFIG.states.DATA_EXPLORER,
      selectedStates: [CONFIG.states.DATA_EXPLORER],
    });
  } else if (isShown(CATALOG_PERMISSIONS.ACCESS.name)) {
    fourCsGroup.push({
      icon: CatalogIcon,
      title: 'SIDEBAR:DATA_CATALOG',
      link: CONFIG.states.CATALOG,
      selectedStates: [CONFIG.states.CATALOG],
    });
  }

  return fourCsGroup;
};

const getActionCenterInnerMenu = () => {
  const actionCenterInnerMenu = [];
  if (
    (isShown(ACTION_CENTER_PERMISSIONS.ACCESS.name) && getApplicationPreference('ENABLE_ACTION_CENTER')) ||
    isMultiTenantModeEnabled()
  ) {
    actionCenterInnerMenu.push({
      title: 'SIDEBAR:ACTION_CENTER:ACTIONS',
      link: CONFIG.states.ACTION_CENTER,
      selectedStates: [
        CONFIG.states.ACTION_CENTER,
        CONFIG.states.ACTION_CENTER_AUDIT,
        CONFIG.states.ACTION_CENTER_ACTIONS,
        CONFIG.states.ACTION_CENTER_OVERVIEW,
        CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
      ],
    });
  }
  if (isShown(POLICIES_PERMISSIONS.ACCESS.name)) {
    actionCenterInnerMenu.push({
      title: 'SIDEBAR:ACTION_CENTER:POLICIES',
      link: CONFIG.states.POLICIES,
      selectedStates: [CONFIG.states.POLICIES],
    });
  }
  return actionCenterInnerMenu;
};

const getAdministrationInnerMenu = () => {
  const administrationInnerMenu = [];
  if (isShown(DATA_SOURCES_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:DS_CONNECTIONS',
      link: CONFIG.states.DATA_SOURCE_ROUTER,
      selectedStates: [
        CONFIG.states.DATA_SOURCE_CONNECTIONS,
        CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
        CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
        CONFIG.states.DATA_SOURCE_INIT,
      ],
    });
  }
  if (isShown(SCANS_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:SCANS',
      link: CONFIG.states.SCANS_CONTROLLER,
      selectedStates: [
        CONFIG.states.SCANS_NEW,
        CONFIG.states.SCANS_NEW_SCANS,
        CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
        CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
        CONFIG.states.SCANS,
        CONFIG.states.SCANS_SAVED_PROFILES,
        CONFIG.states.EDIT_SCAN_PROFILE,
        CONFIG.states.CREATE_SCAN_PROFILE,
      ],
    });
  }
  if (isShown(CORRELATION_SET_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:ENTITY_SOURCES',
      link: CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
      selectedStates: [CONFIG.states.IDENTITY_SOURCE_CONNECTIONS, CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION],
    });
  }
  if (isShown(SECONDARY_SOURCE_PERMISSIONS.ACCESS.name) && !isMultiTenantModeEnabled()) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:SECONDARY_CONNECTIONS',
      link: CONFIG.states.SECONDARY_CONNECTIONS,
      selectedStates: [CONFIG.states.SECONDARY_CONNECTIONS, CONFIG.states.NEW_SECONDARY_CONNECTION],
    });
  }
  if (isShown(APPLICATION_SETUP_PERMISSIONS.ACCESS.name) && !isMultiTenantModeEnabled()) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:APP_SETUP',
      link: CONFIG.states.APPLICATIONS_SETUP,
      selectedStates: [CONFIG.states.APPLICATIONS_SETUP],
    });
  }
  if (isShown(CLASSIFIERS_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:CLASSIFIERS',
      link: CONFIG.states.CLASSIFIERS,
      selectedStates: [CONFIG.states.CLASSIFIERS],
    });
  }
  if (isShown(COMPOUND_ATTRIBUTE_PERMISSIONS.ACCESS.name) && getApplicationPreference('RULES_PAGE_ENABLED_FF')) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:RULES',
      link: CONFIG.states.RULES,
      selectedStates: [CONFIG.states.RULES],
    });
  }
  if (
    isShown(SCAN_WINDOWS_PERMISSIONS.ACCESS.name) &&
    getApplicationPreference('SCAN_WINDOW_FF') &&
    !isMultiTenantModeEnabled()
  ) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:SCAN_WINDOW',
      link: CONFIG.states.SCAN_WINDOW,
      selectedStates: [CONFIG.states.SCAN_WINDOW],
    });
  }
  if (isShown(CREDENTIALS_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:CREDENTIALS',
      link: CONFIG.states.CREDENTIALS,
      selectedStates: [CONFIG.states.CREDENTIALS],
    });
  }
  if (isShown(CERTIFICATES_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:CERTIFICATES_MANAGEMENT',
      link: CONFIG.states.CERTIFICATES_MANAGEMENT,
      selectedStates: [CONFIG.states.CERTIFICATES_MANAGEMENT],
    });
  }
  if (isShown(TAGS_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:TAGS',
      link: CONFIG.states.TAGS,
      selectedStates: [CONFIG.states.TAGS],
    });
  }
  if (isShown(TAGS_SAVED_QUERIES_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:SAVED_QUERIES',
      link: CONFIG.states.SAVED_QUERIES,
      selectedStates: [CONFIG.states.SAVED_QUERIES],
    });
  }
  if (
    isShown(SENSITIVITY_CLASSIFICATION_PERMISSIONS.READ_CONFIGS.name) &&
    getApplicationPreference('SENSITIVITY_CLASSIFICATION_ENABLED')
  ) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:SENSITIVITY_CLASSIFICATION',
      link: CONFIG.states.SENSITIVITY_CLASSIFICATION,
      selectedStates: [CONFIG.states.SENSITIVITY_CLASSIFICATION],
    });
  }
  if (isShown(ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:IDENTITY_ACCESS_MANAGEMENT',
      link: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
      selectedStates: [CONFIG.states.IDENTITY_ACCESS_MANAGEMENT],
    });
  }
  if (isShown(AUDIT_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:AUDIT',
      link: CONFIG.states.AUDIT,
      selectedStates: [CONFIG.states.AUDIT],
    });
  }
  if (isShown(GENERAL_SETTINGS_PERMISSIONS.ACCESS.name)) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:GENERAL_SETTINGS',
      link: CONFIG.states.GENERAL_SETTINGS,
      selectedStates: [CONFIG.states.GENERAL_SETTINGS],
    });
  }
  if (isShown(ADVANCE_TOOLS_PERMISSIONS.ACCESS.name) && (!isMultiTenantModeEnabled() || isMultiTenantConfigEnabled())) {
    administrationInnerMenu.push({
      title: 'SIDEBAR:ADMIN:ADVANCED_TOOLS',
      link: CONFIG.states.ADVANCED_TOOLS,
      selectedStates: [CONFIG.states.ADVANCED_TOOLS],
    });
  }
  return administrationInnerMenu;
};

export const appToNewIcon = [
  {
    appId: '2383d397-4442-4c7e-be1a-6550c95fde2a',
    icon: BigidAppLogoDataProcessingIcon,
  },
  {
    appId: '5b594c0f-2a99-4032-9fbc-ab8d76529a0a',
    icon: BigidAppLogoAccessIntelligenceIcon,
  },
  {
    appId: '394b34dc-6965-4ce7-9420-198f2422b319',
    icon: BigidAppLogoBreachResponseIcon,
  },
  {
    appId: 'd49597f4-6a8d-4f2a-9ac3-137fa10381f9',
    icon: BigidAppLogoConsentGovernanceIcon,
  },
  {
    appId: '4639b384-5a33-41c1-b973-45424cacc5fa',
    icon: BigidAppLogoDataRightsFulfillmentIcon,
  },
  {
    appId: '4639b384-5a33-41c1-b973-45424cacc5fb',
    icon: BigidAppLogoDataDeletionIcon,
  },
  {
    appId: '12d9bdf5-c159-4009-8da3-24cd366eae3e',
    icon: BigidAppLogoRiskConfigurationIcon,
  },
  {
    appId: '34df7f84-625b-4d40-b612-9ac959775050',
    icon: BigidAppLogoFileLabelingIcon,
  },
  { appId: 'd6a589f-189a-4cdb-9064-04294c7dce4f', icon: BigidAppLogoSapIcon },
  { appId: '2dba75cb-34dd-467f-949f-02b0b127a2d2', icon: BigidNavigationAppCyberarkIcon },
  {
    appId: '147600b3-b7ab-44e7-9a56-8fa97f19ae09',
    icon: BigidAppLogoPrivacyPortalIcon,
  },
  { appId: '64870c9a0e72d7d4f1ee9aa6', icon: BigidAppLogoPrivacyPortalIcon },
  { appId: 'cd07f2cf-dce8-4e35-8e2b-fed4185f1284', icon: BigidNavigationAppHashicorpIcon },
  { appId: '2192539b-f750-4151-b30f-3deda418e7c7', icon: BigidAppLogoDataInsightsStudioIcon },
  { appId: 'bf3c0bb5-e0b3-4ca4-83dd-37db40ea84d5', icon: BigidAppLogoDataPipelineIcon },
];

export const getFavoriteApps = (
  favorites: AppPreferencesFavorites[],
  customApps: CustomApps[],
  isLimitNumberOfFavorites?: boolean,
) => {
  const apps = getApps();
  const enabledFavorites = favorites.filter(({ appId }) => {
    const app = apps.find(({ id }) => id === appId);
    const customApp = customApps.find(({ _id }) => _id === appId);
    return app || customApp;
  });

  let favoritesToAdd = orderBy(enabledFavorites, ['dateAdded'], ['asc']);
  if (isLimitNumberOfFavorites) {
    favoritesToAdd = slice(favoritesToAdd, 0, MAX_FAVORITES_IN_MENU);
  }
  const isNewNavigation = getApplicationPreference('ENABLE_NEW_UX_NAVIGATION');
  const appToIcon = [
    {
      appId: '2383d397-4442-4c7e-be1a-6550c95fde2a',
      icon: BigidAppLogoDataProcessingIcon,
    },
    {
      appId: '5b594c0f-2a99-4032-9fbc-ab8d76529a0a',
      icon: BigidAppLogoAccessIntelligenceIcon,
    },
    {
      appId: '394b34dc-6965-4ce7-9420-198f2422b319',
      icon: BigidAppLogoBreachResponseIcon,
    },
    {
      appId: 'd49597f4-6a8d-4f2a-9ac3-137fa10381f9',
      icon: BigidAppLogoConsentGovernanceIcon,
    },
    {
      appId: '4639b384-5a33-41c1-b973-45424cacc5fa',
      icon: BigidAppLogoDataRightsFulfillmentIcon,
    },
    {
      appId: '4639b384-5a33-41c1-b973-45424cacc5fb',
      icon: BigidAppLogoDataDeletionIcon,
    },
    {
      appId: '12d9bdf5-c159-4009-8da3-24cd366eae3e',
      icon: BigidAppLogoRiskConfigurationIcon,
    },
    {
      appId: '34df7f84-625b-4d40-b612-9ac959775050',
      icon: BigidAppLogoFileLabelingIcon,
    },
    { appId: 'd6a589f-189a-4cdb-9064-04294c7dce4f', icon: SAPSidebarIcon },
    { appId: '2dba75cb-34dd-467f-949f-02b0b127a2d2', icon: CyberarkSidebarIcon },
    {
      appId: '147600b3-b7ab-44e7-9a56-8fa97f19ae09',
      icon: BigidAppLogoPrivacyPortalIcon,
    },
  ];

  const favoritesMenuItems = compact(
    favoritesToAdd.map(({ appId }) => {
      const app = apps.find(app => app.id === appId);
      const customApp = customApps.find(customApp => customApp._id === appId);
      const fallbackAppIcon = isNewNavigation ? BigidNavigationAppGenericIcon : GenericIcon;

      if (app) {
        const { name: title, icon, to: link, onClick, id } = app;
        const sideBarAppIcons = isNewNavigation ? appToNewIcon : appToIcon;
        const sideBarIcon = sideBarAppIcons.find(({ appId: sidebarIconId }) => sidebarIconId === id)?.icon || icon;
        return {
          title,
          icon: sideBarIcon || fallbackAppIcon,
          ...(link ? { link, selectedStates: [link] } : { link: appId }),
          ...(onClick && { onClick }),
        };
      }
      if (customApp) {
        const { tpa_name, friendlyName, _id: id, vendor, logo, side_bar_icon }: any = customApp;
        const title = friendlyName || tpa_name;
        if (isNewNavigation) {
          return {
            title,
            logo: side_bar_icon || logo,
            icon: side_bar_icon || fallbackAppIcon,
            onClick: () =>
              appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(id, tpa_name, vendor),
            selectedStates: [`${CUSTOM_APP_PAGE}/${id}/actions`, `${CUSTOM_APP_PAGE}/${id}`],
          };
        } else {
          return {
            title,
            logo: side_bar_icon || logo,
            icon: side_bar_icon || fallbackAppIcon,
            onClick: () =>
              appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(id, tpa_name, vendor),
            selectedStates: [id],
          };
        }
      }
    }),
  );

  return favoritesMenuItems;
};

export const getBottomGroups =
  (favorites: AppPreferencesFavorites[], customApps: CustomApps[]) => (): SidebarMenuGroup[] => {
    if (licenseService.shouldBlockUserWithLicenseExpired()) {
      return [];
    }
    const groups = [];

    const favoritesMenuItems = getFavoriteApps(favorites, customApps, true);
    groups.push({ menuItems: favoritesMenuItems });

    !isSmallId() &&
      groups.push({
        menuItems: [
          {
            icon: ApplicationsIcon,
            title: APPS_PAGE_TITLE,
            link: CONFIG.states.APPLICATIONS_MANAGEMENT,
            selectedStates: [CONFIG.states.APPLICATIONS_MANAGEMENT],
          },
        ],
      });
    return groups;
  };
