import { styled } from '@mui/material';
import React, { FC } from 'react';
import { SearchBar } from './SearchBar';
import { Message } from './Message';
import { BigidBigIdIllustration } from '@bigid-ui/icons';

const Container = styled('div')`
  width: 640px;
  flex-grow: 1;
  gap: 24px;
  justify-content: center;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
`;

type TopContentProps = {
  dataAid?: string;
};
export const TopContent: FC<TopContentProps> = ({ dataAid = 'TopContent' }) => {
  return (
    <Container>
      <BigidBigIdIllustration />
      <SearchBar />
      <Message />
    </Container>
  );
};
