import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { $state } from '../../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, isEqual } from 'lodash';
import { customAppService } from '../../../../services/customAppService';
import { notificationService } from '../../../../services/notificationService';
import { appsLicenseService } from '../../../../services/appsLicenseService';
import { BigidFormBox } from '../../../../components/BigidFormBox/BigidFormBox';
import { AppInfo, FetchItems } from '../../utils/CustomAppTypes';
import { convertToParamsValuesObject } from '../CustomAppActions/CustomAppActions';
import { AppVersionSection } from './Sections/AppVersionSection';
import { InteractiveSection } from './Sections/InteractiveSection';
import { BaseUrlSection } from './Sections/BaseUrlSection';
import { AdditionalPermissionsSection } from './Sections/AdditionalPermissionsSection';
import { GlobalParamsSection } from './Sections/GlobalParamsSection';
import { CONFIG } from '../../../../../config/common';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';

export interface TpaGeneralValues {
  ui_tab_name?: string;
  ui_url?: string;
  is_use_proxy?: boolean;
  bigid_base_url?: string;
  can_get_ds_credentials?: boolean;
}

interface EditCustomAppProps {
  appInfo: AppInfo;
  setIsFormValid: Dispatch<SetStateAction<boolean>>;
  setIsValueChanged: Dispatch<SetStateAction<boolean>>;
  onSaveMutableRef: MutableRefObject<() => void>;
}

export enum InputTypes {
  SINGLE_ELECTION = 'singleSelection',
  MULTIPLE_SELECTION = 'multipleSelection',
  TEXT = 'text',
}

export interface CustomAppParam {
  type: string;
  name: string;
  friendlyName?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  isMandatory?: boolean;
  isClearText?: boolean;
  inputType?: InputTypes;
  inputItems?: string[];
  defaultValue?: string;
  fetchItems: FetchItems;
  paramCategory: string;
}

const useStyles = makeStyles({
  wrapper: {
    padding: '16px',
  },
  formBoxWrapper: {
    marginBottom: 24,
  },
});

const initGeneralValues = (appInfo: AppInfo) => ({
  can_get_ds_credentials: appInfo.canGetDsCredentials,
  bigid_base_url: appInfo.bigidBaseUrl,
  ui_tab_name: appInfo.uiTabName,
  ui_url: appInfo.uiUrl,
  is_use_proxy: appInfo.isUseProxy,
});

export const EditCustomApp: FC<EditCustomAppProps> = ({
  appInfo,
  setIsFormValid,
  setIsValueChanged,
  onSaveMutableRef,
}) => {
  const classes = useStyles({});
  const initGlobalParams = convertToParamsValuesObject(appInfo.globalParams);
  const initGeneralParams = initGeneralValues(appInfo);
  const [globalParamsValues, setGlobalParamsValues] = useState<Record<string, string>>(initGlobalParams);
  const [generalParams, setGeneralParams] = useState<Record<string, string | boolean>>(initGeneralParams);
  const [interactiveSectionValid, setInteractionSectionValid] = useState<boolean>(true);
  const [baseUrlSectionValid, setBaseUrlSectionValid] = useState<boolean>(true);
  const [globalParamsSectionValid, setGlobalParamsSectionValid] = useState<boolean>(true);

  useEffect(() => {
    setIsValueChanged(!isEqual(initGlobalParams, globalParamsValues) || !isEqual(initGeneralParams, generalParams));
  }, [initGlobalParams, globalParamsValues, initGeneralParams, generalParams, setIsValueChanged]);

  useEffect(() => {
    setIsFormValid(interactiveSectionValid && baseUrlSectionValid && globalParamsSectionValid);
  }, [interactiveSectionValid, baseUrlSectionValid, globalParamsSectionValid, setIsFormValid]);

  const handleUpdateTpa = async () => {
    try {
      const params = {
        appUrl: appInfo.baseUrl,
      };
      const { id, name } = appInfo;
      await customAppService.upgradeCustomApp(id, params);
      notificationService.success('Software update completed successfully');
      trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_UPDATE_CLICK, { appName: appInfo.name });
      $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id, name }, { reload: true, inherit: false });
    } catch (error) {
      notificationService.error(error.response.data.errors[0]?.message);
    }
  };

  const onGeneralValuesChange = (interactiveObj: TpaGeneralValues) => {
    setGeneralParams(state => ({ ...state, ...interactiveObj }));
  };

  onSaveMutableRef.current = async () => {
    try {
      const toUpdate = {
        paramsKeyValuesMap: globalParamsValues,
        generalKeyValuesMap: generalParams,
      };

      if (!isEmpty(toUpdate)) {
        await customAppService.updateCustomAppParamValues(appInfo.id, toUpdate);
      }
      trackTPAEvent(TPATrackingEvents.TPA_SETTINGS_SAVE_CLICK, { appName: appInfo.name });
      appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(
        appInfo.id,
        appInfo.name,
        appInfo.vendor,
      );
    } catch (error) {
      console.error(error);
      notificationService.error('Something went wrong, please try again.');
    }
  };

  const handleGlobalParamsValueChange = (obj: Record<string, string>) => {
    setGlobalParamsValues({ ...globalParamsValues, ...obj });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formBoxWrapper}>
        <BigidFormBox title="Application Base URL" description={appInfo.baseUrl} />
      </div>

      <div className={classes.formBoxWrapper}>
        <AppVersionSection appName={appInfo.name} version={appInfo.version} onUpdate={handleUpdateTpa} />
      </div>

      {appInfo.isInteractive && (
        <div className={classes.formBoxWrapper}>
          <InteractiveSection
            uiUrl={appInfo.uiUrl}
            menuItemName={appInfo.uiTabName}
            isUseProxy={appInfo.isUseProxy}
            onChange={onGeneralValuesChange}
            setSectionValid={setInteractionSectionValid}
          />
        </div>
      )}

      <div className={classes.formBoxWrapper}>
        <BaseUrlSection
          bigidBaseUrl={appInfo.bigidBaseUrl}
          onChange={onGeneralValuesChange}
          setSectionValid={setBaseUrlSectionValid}
        />
      </div>

      <div className={classes.formBoxWrapper}>
        <AdditionalPermissionsSection
          canGetCredentialsChecked={appInfo.canGetDsCredentials}
          onChange={onGeneralValuesChange}
        />
      </div>

      {!!appInfo.globalParams.length && (
        <div className={classes.formBoxWrapper}>
          <GlobalParamsSection
            globalParams={appInfo.globalParams}
            setSectionValid={setGlobalParamsSectionValid}
            onChange={handleGlobalParamsValueChange}
            globalParamsValues={globalParamsValues}
          />
        </div>
      )}
    </div>
  );
};
