import { BigidGridRow } from '@bigid-ui/grid';
import { ScanTypes } from '../ScanTemplates/scanTemplateTypes';
import { SchedulerSettingsWithCronExpression } from '@bigid-ui/components';

export interface ScanProfileGridRow extends BigidGridRow {
  _id: string;
  name: string;
  scanType: ScanTypes;
  owners?: string[];
  schedule?: string | SchedulerSettingsWithCronExpression;
  active: boolean;
  description?: string;
  allEnabledDs?: boolean;
  dataSourceList?: string[];
  created_at: Date;
  allEnabledIdSor?: boolean;
  idsorList?: string[];
  scanTemplateId?: string;
  isReviewFindingsEnabled: boolean;
}

export enum ScanProfileActionType {
  RUN_SCAN = 'run',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
}
