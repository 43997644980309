import React, { Dispatch, FC, ReactText, useEffect, useState, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { BigidTooltip } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { FindingDetails, CuratedFieldStatus, MatchType, FindingCurateProps } from '../../curationService';
import { getFindingHighlightColorByStatus } from '../../curationUtils';
import { CuratedFieldValuePreviewTooltip } from './CuratedFieldValuePreviewTooltip';
import { CuratedFieldValuePreviewExcludeDialog } from './CuratedFieldValuePreviewExcludeDialog';

export interface CuratedFieldValuePreviewFindingProps {
  dataAid: string;
  finding: FindingDetails;
  isPermittedToExclude?: boolean;
  onFindingCurate: (findingCurateProps: FindingCurateProps) => void;
  fieldId: ReactText;
  dispatchGridReload?: Dispatch<SetStateAction<any[]>>;
}

interface StyledMarkProps {
  status: CuratedFieldStatus;
}

const StyledMark = styled('mark')<StyledMarkProps>`
  background-color: ${({ status }) => getFindingHighlightColorByStatus(status)};
  cursor: pointer;
  padding: 0;
`;

export const CuratedFieldValuePreviewFinding: FC<CuratedFieldValuePreviewFindingProps> = ({
  dataAid,
  finding,
  isPermittedToExclude,
  onFindingCurate,
  fieldId,
  dispatchGridReload,
}) => {
  const [findingDetails, setFindingDetails] = useState<FindingDetails>(finding);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [radioButtonValue, setRadioButtonValue] = useState<MatchType>();

  const handleFindingCurate = async ({ finding, status }: FindingCurateProps): Promise<void> => {
    setFindingDetails(prevFinding => ({ ...prevFinding, reviewStatus: status }));
    const findingCurateProps = { finding, status, fieldId, matchType: radioButtonValue };
    await onFindingCurate(findingCurateProps);
    dispatchGridReload([]);
  };

  useEffect(() => {
    setFindingDetails(finding);
  }, [finding]);

  const handleOnDialogStateChange = (isOpen: boolean) => {
    setIsDialogOpen(isOpen);
  };

  const handleExcludeSelectedOption = (option: MatchType) => {
    setRadioButtonValue(option);
  };

  return (
    <>
      <BigidTooltip
        title={
          <CuratedFieldValuePreviewTooltip
            dataAid={generateDataAid(dataAid, ['tooltip'])}
            finding={findingDetails}
            onFindingCurate={handleFindingCurate}
            onDialogStateChange={handleOnDialogStateChange}
            onExcludeOptionSelected={handleExcludeSelectedOption}
            fieldId={fieldId}
          />
        }
        isDisabled={!isPermittedToExclude}
      >
        <StyledMark status={findingDetails.reviewStatus} data-aid={generateDataAid(dataAid, ['mark'])}>
          {findingDetails.findingValue}
        </StyledMark>
      </BigidTooltip>
      <CuratedFieldValuePreviewExcludeDialog
        dataAid={generateDataAid(dataAid, ['dialog'])}
        finding={findingDetails}
        onDialogStateChange={handleOnDialogStateChange}
        isOpen={isDialogOpen}
        excludeSelectedOption={radioButtonValue}
        onExcludeOptionSelected={handleExcludeSelectedOption}
        fieldId={fieldId}
        onFindingCurate={handleFindingCurate}
      />
    </>
  );
};
