import { BigidBody2, BigidButtonIcon, BigidColorsV2 } from '@bigid-ui/components';
import { BigidAddIcon, BigidChevronDownIcon, BigidChevronUpIcon } from '@bigid-ui/icons';
import React, { FC, useState } from 'react';
import { useCardModalContext } from '../../../../CardModalContext';
import { useLocalTranslation } from '../../../../translations';
import { Heading } from './AttributeDataCard';

type ExpandIconProps = {
  isExpanded: boolean;
  onClick: () => void;
};

const ExpandIcon: FC<ExpandIconProps> = ({ isExpanded, onClick }) => (
  <BigidButtonIcon icon={isExpanded ? BigidChevronUpIcon : BigidChevronDownIcon} onClick={onClick} />
);

const domainHierarchyToString = (domainChain: string[]) => {
  return domainChain.join(' > ');
};

export const AdditionalDomainsSection = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Cards.AttributeData');
  const { state } = useCardModalContext();

  const { additionalDomains } = state.tabs.info;

  const handleExpandToggle = () => {
    setIsExpanded(isExpanded => !isExpanded);
  };

  const handleAddDomainClick = () => {
    console.log('Add domain');
  };

  return (
    <>
      <Heading title={t('additionalDomains')}>
        <div>
          <BigidButtonIcon icon={BigidAddIcon} onClick={handleAddDomainClick} />
          <ExpandIcon isExpanded={isExpanded} onClick={handleExpandToggle} />
        </div>
      </Heading>
      {isExpanded &&
        additionalDomains.map((domain, index) => {
          return (
            <BigidBody2 color={BigidColorsV2.gray[600]} key={index}>
              {domainHierarchyToString(domain)}
            </BigidBody2>
          );
        })}
    </>
  );
};
