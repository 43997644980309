import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { get, isBoolean, isEmpty, uniqBy } from 'lodash';
import styled from '@emotion/styled';
import { BigidGridQueryComponents, BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import {
  ActionData,
  BigidBody1,
  BigidColorsV2,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidLoader,
  BigidNotes1,
  BigidPaper,
  BigidTabsAndContent,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
  ToolbarActionType,
} from '@bigid-ui/components';
import {
  $state,
  $stateParams,
  dataSourceConnectionsService,
  localStorageService,
} from '../../../services/angularServices';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import {
  sendSSERequestWithEventId,
  SSEDataMessage,
  subscribeToRepeatedSSEEventById,
} from '../../../services/sseService';
import { notificationService } from '../../../services/notificationService';
import { smallIdLicenseService } from '../../../services/smallIdLicenseService';
import { openImportDataSourceFormModal } from '../../../components/ImportDataSourcesForm/openImportDataSourceFormModal';
import makeStyles from '@mui/styles/makeStyles';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { v4 as uuid } from 'uuid';
import { isPermitted } from '../../../services/userPermissionsService';
import { DATA_SOURCES_PERMISSIONS, REVIEW_FINDINGS_PERMISSIONS, SCANS_PERMISSIONS } from '@bigid/permissions';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import {
  ACTION_SUFFIX,
  DataSourceModel,
  DataSourcesResponse,
  DELETE_DESCRIPTION_FINDINGS,
  IMPORT_PREFIX,
  TEST_CONNECTION_PREFIX,
  TOTAL_ROWS_THRESHOLD,
} from './DataSourceConnectionTypes';
import {
  getDataSourceConnectionDefaultColumns,
  getDataSourceConnectionFilterConfig,
} from './DataSourceConnectionGridConfiguration';
import { useSupportedDataSources } from './hooks/useSupportedDataSources';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { showDeleteACEntityDialog } from '../../ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { EntityType } from '../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { useAutoDiscoveryAppStatus } from './hooks/useAutoDiscoveryAppStatus';
import { useSSEDataSourceEvents } from './hooks/useSSEDataSourceEvents';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { DataSourceAsyncOperationsWidget } from './DataSourceAsyncOperationsWidget';
import {
  AddDataSourceButtons,
  goToAutoDiscovery,
} from '../../../components/CreateNewDataButtons/CreateNewDataSourceButtons';
import {
  BigidConnectionIcon,
  BigidImportIcon,
  BigidInstantScanIcon,
  BigidDataSourceIllustration,
} from '@bigid-ui/icons';
import { addDataSourceNameToTypeMap } from '../../Scans/ScanCreateWizard/hooks/useUpdateSupportedScanTypesBySelectedDs';
import { getSelectedDataSourceFromStorage } from '../../Scans/ScanCreateWizard/hooks/useGetPreselectedDataSources';
import { DataSourceScanSelectedButton } from './DataSourceScanSelectedButton';
import { useSupportedScanTypesForSelectedDs } from './hooks/useSupportedScanTypesForSelectedDs';
import { trackEventDataSources, DataSourcesUITrackingEvent } from '../DataSourcesEventTrackerUtils';
import { CONFIG } from '../../../../config/common';
import { closeSystemDialog, openSystemDialog } from '../../../services/systemDialogService';
import { DataSourceBulkUpdate } from '../DataSourceConfiguration/DataSourceBulkUpdate/DataSourceBulkUpdate';
import { updateScopeIfNeeded } from '../DataSourceConfiguration/hooks/useSubmitDataSource';
import { prepareDataSourceFormValueDataForSend } from '../DataSourceConfiguration/utils/prepareDataSourceFormValueDataForSend';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { updateDataSourceFields } from '../DataSourcesService';
import { getValuesToSubmit } from '../DataSourceConfiguration/DataSourceBulkUpdate/dsBulkUpdateUtils';
import { NavigateToFindingsReviewFromDsButton } from '../../Scans/ScanInsights/ScanInsightsInfo/NavigateToFindingsReviewFromDsButton';
import { DataSourceConnectionModal } from '../DataSourceConnectionModal/DataSourceConnectionModal';
import { QueryClientProvider } from 'react-query';
import { getQueryClient } from '../../../config/query';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { useDataSourceTabs } from './hooks/useDataSourceTabs';
import { AutoDiscoveryGrid } from '../../AutoDiscovery/AutoDiscoveryGrid/AutoDiscoveryGrid';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';
import { DATA_SOURCE_CONNECTIONS_QUERY_KEY } from '../constants/newConnectionFlowConstants';
import { useAutoDiscoverWizard } from '../../AutoDiscovery/hooks/useAutoDiscoverWizard';
import { useInstantScanData } from './hooks/useInstantScanData';
import { CallbackProps, DataSourceCollaborationGuidedTour } from '../DataSourceConnectionModal/DataSourceConnectionCollaboration/DataSourceCollaborationGuidedTour';
import { getGuidedTourDataSourceEvent } from '../DataSourceConnectionModal/mappers/collaboration';

const RightSideComponentWrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
    flexDirection: 'column',
  },
  actionsButton: {
    minWidth: 135,
  },
  quickScanActionButton: {
    minWidth: 125,
    '& > div > div > div': {
      color: BigidColorsV2.gray['700'],
    },
  },
  dialogContent: {
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});

const getGuidedTourDataSource = (dataSources: DataSourceModel[]): DataSourceModel => {
  const [first] = dataSources;

  return first;
};

const calcProcessedRecords = (totalRecords: number, processedRecords: number, processedChunks: number) =>
  processedRecords + Math.min(totalRecords - processedRecords, Math.round(processedRecords / processedChunks));

const queryClient = getQueryClient();

const useGetDataSources = (): { data: DataSourcesResponse } =>
  queryClient.getQueryData<{ data: DataSourcesResponse }>(DATA_SOURCE_CONNECTIONS_QUERY_KEY) ?? {
    data: { ds_connections: [], totalCount: 0 },
  };

export const DataSourceConnections: FC = () => {
  const unregisterImportHandler = useRef(null);
  const unregisterTestConnectionsHandler = useRef(null);
  const { dataSourcesTypeMapping, supportedDataSources } = useSupportedDataSources();
  const {
    filter,
    shouldDisplayAutoDiscoveryWarning,
    shouldDisplayNewConnectionModal,
    shouldDisplayGuidedTour,
    sourceType,
    sourceId,
    dsLabel,
    nameInDocs,
  } = $stateParams;

  const [showModal, setShowModal] = useState(
    () => !!(getApplicationPreference('CONNECTIVITY_EXPERIENCE_ENABLED') && shouldDisplayNewConnectionModal),
  );

  const [isLoadingData, setLoadingData] = useState(false);
  const [showGuidedTour] = useState<boolean>(() => {
    const skipGuidedTour = localStorageService.get<boolean>('skipGuidedTour');
    return shouldDisplayGuidedTour && (isBoolean(skipGuidedTour) ? !skipGuidedTour : skipGuidedTour !== 'true');
  });
  const [dataSourceId, setDataSourceId] = useState(sourceId ?? null);
  const [dataSourcesIds, setSelectedDsIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filterForDs, setFilterForDs] = useState([]);
  const [dsTotalCount, setDsTotalCount] = useState<number>(0);
  const bulkDataSourceUpdateHandlersRef = useRef<BigidFormStateAndHandlers>();
  const bulkDataSourceFieldsToModifyRef = useRef<any>();
  const {
    supportedScanTypesForSelectedDs,
    setDataSourceNameToTypeMap,
    setDisabledAndArchivedDataSources,
    updateSupportedScanTypes,
  } = useSupportedScanTypesForSelectedDs(supportedDataSources);
  const { isAutoDiscoveryCompleted, isLoadingAutoDiscoveryAvailability, isAutoDiscoveryAvailable } =
    useAutoDiscoveryAppStatus(shouldDisplayAutoDiscoveryWarning);
  const { tabsAndContentConfig, selectedTabIndex, isTabs } = useDataSourceTabs();
  const { openAutoDiscoveryModal } = useAutoDiscoverWizard();
  const isDataSourceList = !isTabs || selectedTabIndex === 0;
  useSSEDataSourceEvents({ dataSourcesTypeMapping, isActive: isDataSourceList, filterForDs });

  const dataSourceConnectionDefaultColumns = useMemo(() => getDataSourceConnectionDefaultColumns(), []);
  const getInitialFilterToolbarConfig = useCallback(() => {
    const filterParsed = filter ? JSON.parse(decodeURIComponent(filter)) : [];
    return getDataSourceConnectionFilterConfig(filterParsed);
  }, [filter]);
  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: dataSourceConnectionDefaultColumns,
    getInitialFilterToolbarConfig,
  });

  const { gridWrapper, actionsButton, dialogContent, quickScanActionButton, noData } = useStyles({});

  const {
    data: { ds_connections: dataSources },
  } = useGetDataSources();

  const guidedTourDataSourceId = getGuidedTourDataSource(dataSources)?.name;

  // fixed implicit any during react 18 migration
  const handleProcessingWidgetGotCompletedOperations = useCallback((dataSourceType: any) => {
    dataSourceType?.length > 0 &&
      notificationService.success(`Auto Discovery Service Completed for ${dataSourceType}!`);
  }, []);

 const handleEndTour = () => localStorageService.set('skipGuidedTour', true);

const handleSkipTour = (props: CallbackProps) => {
  const event = getGuidedTourDataSourceEvent(props);
    handleEndTour();

    event && trackEventDataSources(event)
}

  const handleCompleteTour = (props: CallbackProps) => {
    const event = getGuidedTourDataSourceEvent(props);
    handleEndTour();

    event && trackEventDataSources(event)

    $state.go(CONFIG.states.CONFIG_DATA_SOURCE, {
      id: guidedTourDataSourceId,
      shouldOpenNewModal: true,
    });
  };

  const handleTourStateChange = (props: CallbackProps) => {
    const event = getGuidedTourDataSourceEvent(props);
    event && trackEventDataSources(event)
  } 

  const shouldShowReviewFindingsButton =
    getApplicationPreference('CLASSIFIER_TUNING_ENABLED') && isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name);

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Data Sources',
      rightSideComponentsContainer: (
        <RightSideComponentWrapper>
          {!isAutoDiscoveryCompleted && !isLoadingAutoDiscoveryAvailability && isAutoDiscoveryAvailable && (
            <DataSourceAsyncOperationsWidget onOperationCompleted={handleProcessingWidgetGotCompletedOperations} />
          )}
          <DataSourceScanSelectedButton
            dataSourcesIds={dataSourcesIds}
            supportedScanTypesForSelectedDs={supportedScanTypesForSelectedDs}
            isAllSelected={isAllSelected}
            filterForDs={filterForDs}
            totalCount={dsTotalCount}
          />
          {shouldShowReviewFindingsButton && <NavigateToFindingsReviewFromDsButton />}
          <AddDataSourceButtons isAutoDiscoveryAvailable={isAutoDiscoveryAvailable} />
        </RightSideComponentWrapper>
      ),
    });
  }, [
    dataSourcesIds,
    dsTotalCount,
    filterForDs,
    handleProcessingWidgetGotCompletedOperations,
    isAllSelected,
    isAutoDiscoveryAvailable,
    isAutoDiscoveryCompleted,
    isLoadingAutoDiscoveryAvailability,
    shouldShowReviewFindingsButton,
    supportedScanTypesForSelectedDs,
  ]);

  useEffect(() => {
    const uniqueBroadcastEventForImport: any = sessionStorageService.get('uniqueBroadcastEventForImportDataSource');
    const uniqueBroadcastEventForTest: any = sessionStorageService.get('uniqueBroadcastEventForTestDataSource');

    if (uniqueBroadcastEventForImport) {
      unregisterImportHandler.current = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEventForImport,
        onBroadCastEventFormImport,
      );
    }

    if (uniqueBroadcastEventForTest) {
      unregisterTestConnectionsHandler.current = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEventForTest,
        onBroadCastEventFormTestConnection,
      );
    }

    return () => {
      unregisterImportHandler.current?.();
      unregisterTestConnectionsHandler.current?.();
    };
  }, []);

  const onBroadCastEventFormImport = ({ results }: SSEDataMessage) => {
    const hasProgress = !!results?.[0]?.progress;
    const { isFinished, processedRecords, totalRecords, processedChunks } = results?.[0]?.progress || {};
    const dsImportRes = results?.[0]?.results || [];
    entityEventsEmitter.emit(EntityEvents.RELOAD);

    if (isFinished) {
      const failedDs = dsImportRes.filter((ds: { isTestConnectionArchiveError: boolean }) => {
        if (ds?.isTestConnectionArchiveError === true) return ds;
      }).length;
      sessionStorageService.set('uniqueBroadcastEventForImportDataSource', '');
      let importMessage = `Data Sources imported successfully! Imported ${totalRecords} Data Sources`;
      if (failedDs > 0) {
        importMessage = `${importMessage}, of which ${failedDs} records are archived and not tested.`;
      }
      notificationService.success(importMessage);
      unregisterImportHandler.current?.();
    } else if (hasProgress) {
      const importedRecords = calcProcessedRecords(totalRecords, processedRecords, processedChunks);
      notificationService.success(`${IMPORT_PREFIX} ${importedRecords}/${totalRecords} imported`);
    } else {
      notificationService.success(`${IMPORT_PREFIX} ${ACTION_SUFFIX}`);
    }
  };

  const onBroadCastEventFormTestConnection = ({ results }: SSEDataMessage) => {
    const hasProgress = !!results?.[0]?.progress;
    const {
      isFinished,
      processedRecords,
      totalRecords,
      processedChunks,
      processedRecordsWithSuccess,
      processedRecordsWithErrors,
    } = results?.[0]?.progress || {};
    entityEventsEmitter.emit(EntityEvents.RELOAD);

    if (isFinished) {
      sessionStorageService.set('uniqueBroadcastEventForTestDataSource', '');
      const message = `Data Sources test connection completed. ${processedRecordsWithSuccess} tested successfully, and ${processedRecordsWithErrors} failed.`;

      processedRecordsWithErrors > 0 ? notificationService.warning(message) : notificationService.success(message);
      unregisterImportHandler.current?.();
    } else if (hasProgress) {
      const tested = calcProcessedRecords(totalRecords, processedRecords, processedChunks);
      notificationService.success(`${TEST_CONNECTION_PREFIX} ${tested}/${totalRecords} tested`);
    } else {
      notificationService.success(`${TEST_CONNECTION_PREFIX} ${ACTION_SUFFIX}`);
    }
  };

  async function updateDataSources(actionData: ActionData, updatedFields: any) {
    let didUpdate = false;
    const { selectedRowIds, allSelected, filter } = actionData;

    try {
      await updateDataSourceFields({
        ids: selectedRowIds,
        allSelected,
        query: { filter },
        updatedFields,
      });
      didUpdate = true;
      notificationService.success('Data sources updated successfully');
    } catch (e) {
      notificationService.error('Could not update data sources. See logs for more information');
    }

    return {
      shouldGridReload: didUpdate,
      shouldClearSelection: didUpdate,
    };
  }

  async function updateArchiveStatusDataSources(actionData: ActionData, updatedFields: any, label: string) {
    let didUpdate = false;
    let shouldChangeArchiveStatus = false;
    const { selectedRowIds, allSelected, filter } = actionData;
    try {
      const isUnarchived = label.toLowerCase() === 'unarchive';

      shouldChangeArchiveStatus = await showConfirmationDialog({
        entitiesCount: selectedRowIds.length,
        entityNameSingular: 'Data Source',
        entityNamePlural: 'Data Sources',
        actionName: label,
        allSelected,
        postfix: isUnarchived ? ' from the archive?' : '? (Previous scan results will not be deleted)',
      });

      if (shouldChangeArchiveStatus) {
        await httpService.put(`ds-connections`, {
          ids: selectedRowIds,
          allSelected,
          query: { filter },
          updatedFields,
        });
        didUpdate = true;
        notificationService.success('Data sources updated successfully');
        entityEventsEmitter.emit(EntityEvents.RELOAD);
      }
    } catch (e) {
      notificationService.error('Could not update data sources. See logs for more information');
    }

    return {
      shouldGridReload: didUpdate,
      shouldClearSelection: didUpdate,
    };
  }

  const handleCloseModal = () => {
    setShowModal(false);
    $state.go(
      CONFIG.states.DATA_SOURCE_CONNECTIONS,
      {
        shouldDisplayNewConnectionModal: false,
        sourceType: null,
      },
      { reload: true },
    );
  };

  const preselectedDataSourceIds = useMemo(() => getSelectedDataSourceFromStorage(), []);

  const {
    isScanTemplatesLoading,
    isScanTemplatesEnabled,
    oneFavoriteScanTemplate,
    favoriteTemplatesRef,
    updateFavoriteTemplates,
  } = useInstantScanData();

  const loadFavoriteTemplates = useCallback(async () => {
    const templates = favoriteTemplatesRef.current;
    if (!templates) {
      return new Promise(resolve => setTimeout(() => resolve(favoriteTemplatesRef.current ?? []), 0));
    }
    return templates;
  }, []);

  const emptyStateActions = useMemo(
    () => [
      {
        execute: async () => {
          await goToAutoDiscovery(openAutoDiscoveryModal);
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: 'Start Onboarding',
        show: () => isAutoDiscoveryAvailable,
      },
      {
        execute: async () => {
          $state.go(CONFIG.states.DATA_SOURCE_INIT, {
            path: DATA_SOURCES_INIT_VIEWS.selectDataType.path,
          });
          return { shouldGridReload: false, shouldClearSelection: false };
        },
        label: 'Connect Data Source',
        show: () => true,
      },
    ],
    [isAutoDiscoveryAvailable, openAutoDiscoveryModal],
  );

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<DataSourceModel>>(
    () => ({
      columns: gridColumns,
      filterToolbarConfig,
      totalRowsThreshold: TOTAL_ROWS_THRESHOLD,
      pageSize: 200,
      customRowIdName: 'id',
      entityName: 'Data Sources',
      noDataContent: (
        <div className={noData}>
          <BigidLayoutEmptyState
            illustration={BigidDataSourceIllustration}
            description="Connect to a data source and discover your data"
            showEmptyStateWithFilter={false}
            actions={emptyStateActions}
          />
        </div>
      ),
      preSelectedIds: preselectedDataSourceIds?.map(({ name }) => name),
      onGridStateChange: ({ filter, allSelected, ...gridState }) => {
        setSelectedDsIds(currentState => {
          const ids =
            gridState.selectedRowIds?.length !== currentState.length ? gridState.selectedRowIds : currentState;
          const scanTypes = updateSupportedScanTypes(ids);
          updateFavoriteTemplates(scanTypes);
          return ids;
        });
        setIsAllSelected(allSelected);
        setFilterForDs(filter);

        return updatePreferences({ filterState: { filter }, gridState });
      },
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let data: DataSourceModel[] = [];
        let totalCount = 0;

        try {
          setLoadingData(true);

          const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents });
          const {
            data: {
              data: { ds_connections, totalCount: count },
            },
          } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${gridConfigQuery}`);

          queryClient.setQueryData<{ data: DataSourcesResponse }>(DATA_SOURCE_CONNECTIONS_QUERY_KEY, {
            data: { ds_connections, totalCount: count },
          });

          setLoadingData(false);

          data = ds_connections.map(ds => ({ ...ds, displayType: dataSourcesTypeMapping.get(ds.type) || ds.type }));

          const dataSourceNameToTypeMap = ds_connections?.reduce(addDataSourceNameToTypeMap, {});
          setDataSourceNameToTypeMap(currentState => ({ ...currentState, ...dataSourceNameToTypeMap }));
          setDisabledAndArchivedDataSources(ds_connections);
          totalCount = count;
        } catch (e) {
          setLoadingData(false);

          console.error(e);
          notificationService.error('Could not fetch data. See logs for more information');
        }

        setDsTotalCount(totalCount);

        return {
          data,
          totalCount,
        };
      },
      showSortingControls: true,
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      toolbarActions: [
        {
          label: 'Import',
          icon: BigidImportIcon,
          tooltip: 'Import Data Sources',
          execute: async () => {
            if (smallIdLicenseService.goToUpgradeIfLimitedSmallID()) {
              return {
                shouldGridReload: false,
              };
            }

            return new Promise(resolve => {
              openImportDataSourceFormModal(() => {
                const uniqueBroadcastEventForImport: string = sessionStorageService.get(
                  'uniqueBroadcastEventForImportDataSource',
                );

                if (uniqueBroadcastEventForImport) {
                  unregisterImportHandler.current = subscribeToRepeatedSSEEventById(
                    uniqueBroadcastEventForImport,
                    onBroadCastEventFormImport,
                  );
                }

                resolve({
                  shouldGridReload: true,
                  shouldClearSelection: true,
                });
              });
            });
          },
          type: ToolbarActionType.ACTION_ICON,
          disable: () => false,
          show: () => isPermitted(DATA_SOURCES_PERMISSIONS.IMPORT.name),
        },
        {
          label: 'Test Connection',
          icon: BigidConnectionIcon,
          type: ToolbarActionType.ACTION_ICON,
          tooltip: 'Test Connection',
          isGlobal: true,
          execute: async ({ selectedRowIds, allSelected, filter }) => {
            const uniqueBroadcastEvent = `test-ds-${uuid()}`;
            subscribeToRepeatedSSEEventById(uniqueBroadcastEvent, onBroadCastEventFormTestConnection);
            trackEventDataSources(DataSourcesUITrackingEvent.ADMINISTRATION_DATA_SOURCES_BULK_TEST_CONNECTION_CLICK, {
              dataSourceNames: selectedRowIds,
            });
            const {
              data: { message },
            } = await sendSSERequestWithEventId<any>(
              'post',
              'ds-connections/test',
              {
                ids: selectedRowIds,
                allSelected,
                query: { filter },
              },
              {},
              uniqueBroadcastEvent,
            );

            notificationService.success(message);

            return {
              shouldGridReload: false,
            };
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.TEST_CONNECTION.name),
        },
        {
          label: 'Instant Scan',
          type: ToolbarActionType.DROPDOWN,
          show: ({ selectedRowIds }) => {
            const shouldShowInstantScanAction =
              !!selectedRowIds.length &&
              isScanTemplatesEnabled &&
              dataSourcesIds.length === selectedRowIds.length &&
              !oneFavoriteScanTemplate.current;
            return shouldShowInstantScanAction;
          },
          dropdownProps: {
            controlShouldRenderValue: true,
            placeholder: 'Instant Scan',
            className: quickScanActionButton,
            entityIcon: <BigidInstantScanIcon staticMode />,
            isAsync: true,
            options: [],
            clearOnSelect: true,
            shouldLoadInitialOptions: true,
            isTertiary: true,
            loadOptions: loadFavoriteTemplates,
            noOptionsMessage: `Couldn't find a template to support your data source selection`,
            onMenuOpen: () => {
              oneFavoriteScanTemplate.current?.execute?.({
                selectedRows: dataSourcesIds.map(name => ({ name })),
                selectedOptions: [oneFavoriteScanTemplate.current],
              });
            },
          },
        },
        {
          label: 'Instant Scan',
          icon: BigidInstantScanIcon,
          type: ToolbarActionType.TERTIARY,
          show: ({ selectedRowIds }) => {
            const shouldShowInstantScanAction =
              !!selectedRowIds.length &&
              isScanTemplatesEnabled &&
              dataSourcesIds.length === selectedRowIds.length &&
              !!oneFavoriteScanTemplate.current;
            return shouldShowInstantScanAction;
          },
          execute: async () => {
            oneFavoriteScanTemplate.current?.execute?.({
              selectedRows: dataSourcesIds.map(name => ({ name })),
              selectedOptions: [oneFavoriteScanTemplate.current],
            });
            return {
              shouldGridReload: false,
            };
          },
        },
        {
          label: 'More Actions',
          type: ToolbarActionType.DROPDOWN,
          show: ({ selectedRowIds }) => !!selectedRowIds.length,
          dropdownProps: {
            placeholder: 'More Actions',
            clearOnSelect: true,
            className: actionsButton,
            options: [
              {
                label: 'Export',
                value: 'export',
                execute: async ({ allSelected, selectedRowIds, filter }) => {
                  try {
                    const queryParams = {
                      ...(filter ? { filter: JSON.stringify(filter) } : {}),
                      ...(allSelected ? {} : { ids: selectedRowIds }),
                    };

                    await httpService.downloadFile('ds-connections/file-download/export', queryParams);
                  } catch (e) {
                    console.error(e);
                    notificationService.error('Could not export. See logs for more information');
                  }
                  return {
                    shouldGridReload: false,
                  };
                },
                show: () => isPermitted(DATA_SOURCES_PERMISSIONS.EXPORT.name),
              },
              {
                label: 'Enable',
                value: 'enable',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                execute: async actionData => updateDataSources(actionData, { enabled: 'yes' }),
              },
              {
                label: 'Disable',
                value: 'disable',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                execute: async actionData => updateDataSources(actionData, { enabled: 'no' }),
              },
              {
                label: 'Archive',
                value: 'archive',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                execute: async actionData => updateArchiveStatusDataSources(actionData, { archived: true }, 'Archive'),
              },
              {
                label: 'Unarchive',
                value: 'unarchive',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name),
                execute: async actionData =>
                  updateArchiveStatusDataSources(actionData, { archived: false }, 'Unarchive'),
              },
              {
                label: 'Duplicate',
                value: 'duplicate',
                show: ({ selectedRowIds, selectedRows }) =>
                  selectedRowIds.length === 1 &&
                  isPermitted(DATA_SOURCES_PERMISSIONS.CREATE.name) &&
                  !selectedRows?.[0]?.isDsarOnly,
                execute: async ({ selectedRowIds }) => {
                  let didDuplicate = false;
                  try {
                    await httpService.post('ds-connections/duplicate', {
                      ids: selectedRowIds,
                    });
                    didDuplicate = true;
                    notificationService.success('Data source was duplicated successfully');
                  } catch (e) {
                    console.error(e);
                    notificationService.error('Could not duplicate data source. See logs for more information');
                  }

                  return {
                    shouldGridReload: didDuplicate,
                    shouldClearSelection: didDuplicate,
                  };
                },
              },
              {
                label: 'Delete',
                value: 'delete',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length > 0 && isPermitted(DATA_SOURCES_PERMISSIONS.DELETE.name),
                execute: async actionData => {
                  const { selectedRowIds, allSelected, totalRows, filter, selectedRows } = actionData;
                  let shouldDelete = false;
                  try {
                    const ContentComponent = (
                      <div className={dialogContent}>
                        <BigidBody1>{`Are you sure you want to delete ${selectedRowIds.length} data source${
                          selectedRowIds.length > 1 ? 's' : ''
                        }${DELETE_DESCRIPTION_FINDINGS}? `}</BigidBody1>
                        <BigidNotes1>{`NOTE: Deleting data sources with large quantity of PII may take a while.`}</BigidNotes1>
                      </div>
                    );

                    shouldDelete = await showDeleteACEntityDialog({
                      ids: allSelected ? [] : (selectedRows.map(({ _id }) => _id) as string[]),
                      entityType: EntityType.DATA_SOURCE,
                      entityNameSingular: 'data source',
                      entityNamePlural: 'data sources',
                      totalRows,
                      ContentComponent,
                      allSelected,
                    });

                    if (shouldDelete) {
                      await httpService.delete(`ds-connections`, {
                        ids: selectedRowIds,
                        allSelected,
                        query: { filter },
                      });
                      notificationService.success('Data sources deleted successfully');
                    }
                  } catch (e) {
                    notificationService.error('Could not delete data sources. See logs for more information');
                    console.error(e);
                  }

                  return {
                    shouldGridReload: shouldDelete,
                    shouldClearSelection: shouldDelete,
                  };
                },
              },
              {
                label: 'Delete Findings',
                value: 'delete',
                show: ({ selectedRowIds }) =>
                  selectedRowIds.length === 1 && isPermitted(DATA_SOURCES_PERMISSIONS.DELETE_FINDING.name),
                execute: async actionData => {
                  let shouldDelete = false;
                  const { selectedRowIds } = actionData;
                  const [selectedRow] = selectedRowIds;
                  try {
                    shouldDelete = await showConfirmationDialog({
                      allSelected: true,
                      entityNameSingular: `${selectedRow} Findings`,
                    });

                    if (shouldDelete) {
                      await dataSourceConnectionsService.deletePiiDataForDataSource(selectedRow);
                      notificationService.success('Data sources findings were deleted successfully');
                    }
                  } catch (error) {
                    const defaultMessage = 'Could not delete data source findings. See logs for more information';
                    notificationService.error(error?.data?.message ?? defaultMessage);
                    console.error(error);
                  }

                  return {
                    shouldGridReload: shouldDelete,
                    shouldClearSelection: shouldDelete,
                  };
                },
              },
              {
                label: 'Modify',
                value: 'modify',
                show: ({ selectedRows }) => {
                  return (
                    selectedRows.length > 0 &&
                    isPermitted(DATA_SOURCES_PERMISSIONS.EDIT.name) &&
                    getApplicationPreference('ENABLE_DATA_SOURCE_BULK_UPDATES')
                  );
                },
                execute: async ({ selectedRows, selectedRowIds, allSelected, filter }) => {
                  const dsType = selectedRows[0].type;
                  const isSingleDsType = selectedRows.every(({ type }) => dsType === type);

                  await openSystemDialog({
                    title: 'Modify data sources',
                    content: DataSourceBulkUpdate,
                    contentProps: {
                      isSingleDataSourceType: isSingleDsType,
                      selectedType: dsType,
                      selectedRowIds: selectedRowIds as string[],
                      bulkDataSourceUpdateHandlersRef,
                      bulkDataSourceFieldsToModifyRef,
                    },
                    buttons: [
                      { component: SecondaryButton, text: 'Cancel', onClick: () => null, isClose: true },
                      {
                        component: PrimaryButton,
                        text: 'save',
                        onClick: async () => {
                          await bulkDataSourceUpdateHandlersRef.current.validateAndSubmit(
                            async (values: BigidFormValues) => {
                              const fieldsToSubmit = [...bulkDataSourceFieldsToModifyRef.current].filter(
                                ({ name, misc }) => {
                                  if (bulkDataSourceUpdateHandlersRef.current.disabledFields[name]) {
                                    return false;
                                  }

                                  return (
                                    !misc?.visibleIf?.[0]?.field ||
                                    !bulkDataSourceUpdateHandlersRef.current.disabledFields[misc?.visibleIf?.[0]?.field]
                                  );
                                },
                              );

                              if (values.scope) {
                                await Promise.all(
                                  selectedRowIds.map(name => {
                                    updateScopeIfNeeded({ name }, values.scope as Record<string, any>[]);
                                  }),
                                );
                              }

                              const normalizedFields = prepareDataSourceFormValueDataForSend({
                                fields: bulkDataSourceFieldsToModifyRef.current,
                                values: bulkDataSourceUpdateHandlersRef.current.getValues(),
                                getFieldPropsFunction: bulkDataSourceUpdateHandlersRef.current.getFieldProps,
                                isNewPassword: true,
                                isBulkUpdate: true,
                              });

                              const updatedFields = getValuesToSubmit(normalizedFields, fieldsToSubmit);

                              try {
                                const { data } = await updateDataSourceFields({
                                  ids: selectedRowIds,
                                  allSelected,
                                  query: { filter },
                                  updatedFields,
                                  isMultiFieldUpdate: true,
                                });

                                notificationService.success(
                                  `Updated ${data?.data?.updatedRows || ''} data source(s) successfully!`,
                                );
                              } catch (e) {
                                notificationService.error(
                                  'Could not update data sources. See logs for more information',
                                );
                              } finally {
                                closeSystemDialog();
                              }
                            },
                            () => {
                              notificationService.error('Form validation failed, please check form.');
                            },
                          );
                        },
                      },
                    ],
                    isContentScrollable: true,
                    onClose: () => null,
                  });
                  return { shouldClearSelection: true };
                },
              },
            ],
          },
        },
      ],
      forceSelectAll: true,
    }),
    [
      gridColumns,
      filterToolbarConfig,
      noData,
      emptyStateActions,
      preselectedDataSourceIds,
      preferences?.grid?.sort,
      quickScanActionButton,
      loadFavoriteTemplates,
      actionsButton,
      updatePreferences,
      setDataSourceNameToTypeMap,
      setDisabledAndArchivedDataSources,
      dataSourcesTypeMapping,
      dataSourcesIds.length,
      dialogContent,
    ],
  );
  const tabsIndexToComponentMap: Record<number, () => JSX.Element> = {
    0: () =>
      isReady && !isScanTemplatesLoading ? <BigidGridWithToolbar {...gridWithToolbarConfig} /> : <BigidLoader />,
    1: () => <AutoDiscoveryGrid isAutoDiscoveryAvailable={isAutoDiscoveryAvailable} />,
  };

  return (
    <>
      <div className={gridWrapper}>
        <QueryClientProvider client={queryClient}>
          {isTabs && <BigidTabsAndContent {...tabsAndContentConfig} />}

          <BigidPaper>
            {tabsIndexToComponentMap[selectedTabIndex ?? 0]()}
            <DataSourceConnectionModal
              source={sourceType}
              sourceId={dataSourceId}
              dsName={dsLabel}
              nameInDocs={nameInDocs}
              isOpen={showModal}
              onClose={handleCloseModal}
              onAddDataSource={id => setDataSourceId(id)}
            />
          </BigidPaper>
          {showGuidedTour && (
            <DataSourceCollaborationGuidedTour
              loading={isLoadingData}
              source={guidedTourDataSourceId}
              start={showGuidedTour}
              onSkip={handleSkipTour}
              onDone={handleCompleteTour}
              onClose={handleEndTour}
              onUpdate={handleTourStateChange}
            />
          )}
        </QueryClientProvider>
      </div>
    </>
  );
};
