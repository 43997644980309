import { createContext } from 'react';
import { DiscoveryErrors, CloudProvider } from '../constants';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { CredsStatus } from '../../Fmsd/FmsdComponents';
import { BigidSelectOption } from '@bigid-ui/components';

export interface AutoDiscoveryWizardForm extends Record<string, any> {
  authType?: BigidSelectOption[];
  name?: string;
}

export enum AutoDiscoverWizardAsideExpandedStates {
  MINIMIZED = 'minimized',
  OPENED = 'opened',
  PREREQUISITES = 'prerequisites',
  HOW_TO_CONNECT = 'howToConnect',
  OPENED_ERROR = 'openedError',
  PERMISSIONS_SUMMARY = 'permissionsSummary',
}

export interface AutoDiscoveryWizardContextState {
  formData?: AutoDiscoveryWizardForm;
  type?: CloudProvider;
  configData?: AutoDiscoveryConfigModel;
  configDataMultiply?: AutoDiscoveryConfigModel;
  connectionInfo?: {
    credsStatus: CredsStatus;
    permissions: string[];
  };
  isCheck?: boolean;
  isDiscovery?: boolean;
  isScan?: boolean;
  errorMessage?: string;
  errorMessageKey?: DiscoveryErrors;
  fieldsNamesByAuthTypeFields?: string[];
  asideExpandedState?: AutoDiscoverWizardAsideExpandedStates;
  collaborators?: Record<string, any>[];
  collaborationText?: string;
}

export interface AutoDiscoveryWizardContextInterface {
  discoveryConfigData?: AutoDiscoveryWizardContextState;
  setDiscoveryConfigData?: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>;
  discoveryConfigDataRef?: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

export const DEFAULT_AUTO_DISCOVERY_CONFIG_STATE = {
  formData: {},
  asideExpandedState: AutoDiscoverWizardAsideExpandedStates.HOW_TO_CONNECT,
};

export const AutoDiscoveryWizardContext = createContext<AutoDiscoveryWizardContextInterface>({});
