import { MutableRefObject, useCallback } from 'react';
import { useDataSourceConfigState as useDataSourceConfigStateV1 } from '../../DataSourceConfiguration/hooks/useDataSourceConfigState';
import type { DataSourceConnectionTemplateField } from '../../DataSourceConfiguration/types';
import type { BigidFormStateAndHandlers } from '@bigid-ui/components';

type UseDataSourceConfigStateOpts = { loadInitialDataFromTemplate?: boolean };

export const useDataSourceConfigState = (
  control?: MutableRefObject<BigidFormStateAndHandlers>,
  { loadInitialDataFromTemplate = true }: UseDataSourceConfigStateOpts = {},
) => {
  const { generateInitValues: generateInitValuesFromTemplate, updateState, ...rest } = useDataSourceConfigStateV1();

  const generateInitValuesOverride = useCallback(
    (fieldsConfig: DataSourceConnectionTemplateField[]) => {
      if (loadInitialDataFromTemplate) {
        generateInitValuesFromTemplate(fieldsConfig);
        return;
      }

      const values = control?.current.getValues?.() ?? {};

      // @info load initial values from the passed form not from template
      updateState({
        initialValues: fieldsConfig.reduce(
          (acc, { name }: Partial<DataSourceConnectionTemplateField>) => ({
            ...acc,
            [name]: values[name],
          }),
          {},
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState],
  );

  return { generateInitValues: generateInitValuesOverride, updateState, ...rest };
};
