import { BigidBody2, BigidSwitch, BigidTextField } from '@bigid-ui/components';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { useLocalTranslation } from '../../../../translations';
import { ControlsWrapper, DataRow } from './AttributeDataCard';

export const AttributesSection = () => {
  const [isCriticalDataSelected, setIsCriticalDataSelected] = useState(false);

  const handleSwitchChange = () => {
    setIsCriticalDataSelected(isSelected => !isSelected);
  };
  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Cards.AttributeData');

  return (
    <>
      <DataRow>
        <BigidBody2>{t('attributeData')}</BigidBody2>
        <BigidSwitch
          checked={isCriticalDataSelected}
          onChange={handleSwitchChange}
          label={t('criticalDataDescription')}
        />
      </DataRow>
      <DataRow>
        <BigidBody2>{t('attributeType')}</BigidBody2>
        <ControlsWrapper>
          <BigidTextField onChange={noop} disabled placeholder="Manual" />
        </ControlsWrapper>
      </DataRow>
      <DataRow>
        <BigidBody2>{t('attributeOrigin')}</BigidBody2>
        <ControlsWrapper>
          <BigidTextField onChange={noop} disabled placeholder="Manual" />
        </ControlsWrapper>
      </DataRow>
    </>
  );
};
