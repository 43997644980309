import {
  BigidDialog,
  PrimaryButton,
  BigidObjectDetailsSection,
  BigidIcon,
  BigidColors,
  BigidIconSize,
  BigidIconSourceType,
  SecondaryButton,
  BigidTextField,
} from '@bigid-ui/components';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import angular from 'angular';
import React, { FC, useState } from 'react';
import { convertToAngular } from '../../../common/services/convertToAngular';

import DuplicateIcon from '../../assets/icons/BigidDuplicate.svg';

interface RefreshTokenData {
  tokenName: string;
  value: string;
  expiresInDays: string;
}

interface RefreshTokenDialogTranslations {
  headerText: string;
  modalText: string;
  tokenName: string;
  tokenValue: string;
  generateButton: string;
  cancelButton: string;
  closeButton: string;
}

export interface RefreshTokenDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onGenerate: (expireInDays: string) => void;
  onCopy: () => void;
  translations: RefreshTokenDialogTranslations;
  refreshTokenData: RefreshTokenData;
}

const useStyles = makeStyles({
  expireDaysText: {
    fontSize: 5,
    fontWeight: 'bold',
    color: BigidColors.gray[700],
  },
  expireDaysColumn: {
    display: 'flex',
  },
  expireDaysButton: {
    marginLeft: '5px',
  },
  longText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  sectionArea: {
    marginBottom: '10px',
  },
  tokenValueSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tokenValueText: {
    display: 'flex',
    width: '95%',
  },
});

export const RefreshTokenDialog: FC<RefreshTokenDialogProps> = ({
  isOpen = false,
  onClose,
  onGenerate,
  onCopy,
  translations,
  refreshTokenData,
}) => {
  const classes = useStyles({});

  const [expireInDays, setExpireInDays] = useState<string>('30');

  const handleDialogClose = (): void => {
    onClose();
  };

  const handleGenerateClicked = (): void => {
    onGenerate(expireInDays);
  };

  const handleCopyClicked = (): void => {
    onCopy();
  };

  const onExpireInDaysChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = Number(event.currentTarget.value);
    if (!isNaN(input) && input > 0 && input < 3650) {
      setExpireInDays(parseInt(event.currentTarget.value).toString());
    } else {
      event.currentTarget.value = expireInDays;
    }
  };

  const headerText = translations ? translations.headerText : 'Generate Token to User';
  const modalText = translations ? translations.modalText : 'Token expires after (days)';
  const generateButton = translations ? translations.generateButton : 'Generate';
  const tokenNameTitle = translations ? translations.tokenName : 'Token Name';
  const tokenValue = translations ? translations.tokenValue : 'Token Value';
  const tokenName = refreshTokenData ? refreshTokenData.tokenName : null;
  const value = refreshTokenData ? refreshTokenData.value : null;

  if (refreshTokenData === null) {
    return (
      <BigidDialog
        buttons={[
          {
            component: SecondaryButton,
            onClick: handleDialogClose,
            text: translations ? translations.cancelButton : 'Cancel',
          },
        ]}
        isOpen={isOpen}
        onClose={handleDialogClose}
        onExit={handleDialogClose}
        title={headerText}
        maxWidth="sm"
      >
        <div>
          <Typography className={classes.expireDaysText}>{modalText}</Typography>
          <div className={classes.expireDaysColumn}>
            <div>
              <BigidTextField onChange={onExpireInDaysChange} defaultValue={expireInDays} type={'number'} />
            </div>
            <div className={classes.expireDaysButton}>
              <PrimaryButton
                onClick={handleGenerateClicked}
                size={'medium'}
                dataAid="generateRefreshTokenButton"
                text={generateButton}
              />
            </div>
          </div>
        </div>
      </BigidDialog>
    );
  } else {
    return (
      <BigidDialog
        buttons={[
          {
            component: PrimaryButton,
            onClick: handleDialogClose,
            text: translations ? translations.closeButton : 'Close',
            dataAid: 'generateRefreshTokenDialogCloseButton',
          },
        ]}
        isOpen={isOpen}
        onClose={handleDialogClose}
        onExit={handleDialogClose}
        title={headerText}
        maxWidth="sm"
      >
        <div className={classes.sectionArea}>
          <BigidObjectDetailsSection description={tokenName} title={tokenNameTitle} />
        </div>
        <div className={classes.sectionArea}>
          <BigidObjectDetailsSection title={tokenValue}>
            <div className={classes.tokenValueSection}>
              <div className={classes.tokenValueText}>
                <Typography className={classes.longText}> {value} </Typography>
              </div>
              <div>
                <BigidIcon
                  icon={DuplicateIcon}
                  onClick={handleCopyClicked}
                  size={BigidIconSize.MEDIUM}
                  type={BigidIconSourceType.CUSTOM}
                />
              </div>
            </div>
          </BigidObjectDetailsSection>
        </div>
        <div className={classes.sectionArea}>
          <BigidObjectDetailsSection
            description={refreshTokenData ? refreshTokenData.expiresInDays : null}
            title={translations ? translations.modalText : 'Token expires after (days)'}
          />
        </div>
      </BigidDialog>
    );
  }
};

angular
  .module('app')
  .component(
    'refreshTokenDialogReact',
    convertToAngular<RefreshTokenDialogProps>(RefreshTokenDialog, [
      'isOpen',
      'onClose',
      'onGenerate',
      'onCopy',
      'translations',
      'refreshTokenData',
    ]),
  );
