import React, { MutableRefObject, SyntheticEvent, useContext, useRef } from 'react';
import { Content } from './DataSourceConnectionModalStyles';
import { DataSourceConnectionConfiguration } from './DataSourceConnectionConfiguration';
import { GetHighlightedWordsForLogType } from '../DataSourceConfiguration/utils/scannerLogsUtils';
import { BigidFormStateAndHandlers, BigidFormValues } from '@bigid-ui/components';
import { DataSourceConnectionDialogLayout } from '../DataSourceConnectionDialog/DataSourceConnectionDialog';
import { DataSourceConnectionPrerequisites } from './DataSourceConnectionPrerequisites';
import { DataSourceConnectionModalContext } from './DataSourceConnectionModalProvider';
import { PREREQUISITES_REQUIRED_FIELDS } from './constants/constants';
import type { DataSourceConfigurationState } from '../DataSourceConfiguration/hooks/useDataSourceConfigState';
import { DataSourceConnectionModalContentLoader } from './DataSourceConnectionModalContentLoader';

type DataSourceConnectionModalContentProps = {
  control: MutableRefObject<BigidFormStateAndHandlers>;
  logs: GetHighlightedWordsForLogType;
  source: string;
  layout: string;
  errorType?: string;
  errorDescription?: string;
  sourceId?: string;
  shouldForceRefreshFromSource?: boolean;
  onFocus?: (e: SyntheticEvent<HTMLElement, FocusEvent>) => void;
  onChange?: (values: BigidFormValues) => void;
  onStateChange?: (state: DataSourceConfigurationState) => void;
  isLoading?: boolean;
};

export const DataSourceConnectionModalContent = ({
  control,
  shouldForceRefreshFromSource = false,
  source: sourceType,
  sourceId,
  layout,
  onFocus,
  onChange,
  onStateChange,
  isLoading = false,
}: DataSourceConnectionModalContentProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>();
  const { configDataSourceState } = useContext(DataSourceConnectionModalContext);

  const handleFocusChange = (e: SyntheticEvent<HTMLElement, FocusEvent>) => onFocus?.(e);
  const handleStateChange = (state: DataSourceConfigurationState) => onStateChange?.(state);
  const handleInputChange = (values: BigidFormValues) => onChange?.(values);

  const fields = configDataSourceState.fields?.filter(({ name }) => PREREQUISITES_REQUIRED_FIELDS.includes(name)) ?? [];
  const isPrerequisitesStage = layout === DataSourceConnectionDialogLayout.SIMPLE;

  return (
    <Content>
      {isPrerequisitesStage ? (
        <DataSourceConnectionPrerequisites source={sourceType} fields={fields} />
      ) : (
        <>
          {isLoading ? (
            <DataSourceConnectionModalContentLoader translationKey="insights.disconnectOAuth" />
          ) : (
            <DataSourceConnectionConfiguration
              ref={ref}
              shouldForceRefreshFromSource={shouldForceRefreshFromSource}
              sourceId={sourceId}
              control={control}
              onFocus={handleFocusChange}
              onChange={handleInputChange}
              onStateChange={handleStateChange}
              type={sourceType}
            />
          )}
        </>
      )}
    </Content>
  );
};
