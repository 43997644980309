import { CloudProvider } from './types';
import { docsUrls } from '../../../config/publicUrls';

export const TYPE_TO_FULL_DOCS_LINK = {
  [CloudProvider.AWS]: docsUrls.AUTO_DISCOVERY_AWS_DOCS,
  [CloudProvider.AZURE]: docsUrls.AUTO_DISCOVERY_AZURE_DOCS,
  [CloudProvider.GCP]: docsUrls.AUTO_DISCOVERY_GCP_DOCS,
};

export const TYPE_TO_PERM_DOCS_LINK = {
  [CloudProvider.AWS]: docsUrls.AUTO_DISCOVERY_AWS_DOCS,
  [CloudProvider.AZURE]: docsUrls.AUTO_DISCOVERY_AZURE_DOCS,
  [CloudProvider.GCP]: docsUrls.AUTO_DISCOVERY_GCP_DOCS,
};
