import React, { FC, useCallback, useContext, useMemo } from 'react';
import { styled } from '@mui/material';
import {
  BigidColorsV2,
  BigidFormStateAndHandlers,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  TertiaryButton,
  BigidFormValues,
} from '@bigid-ui/components';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import { createAutoDiscoveryConfig, findErrorKeyFromMessage, runAutoDiscovery } from '../autoDiscoveryWizardUtils';
import { closeSystemDialog } from '../../../../services/systemDialogService';
import { notificationService } from '../../../../services/notificationService';
import { useCheckPermissionsErrors } from '../../hooks/useCheckPermissionsErrors';
import { CredsStatus } from '../../../Fmsd/FmsdComponents';
import { TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS } from '../../constants';
import { useLocalTranslation } from '../../translations';
import { getDiscoverAuthTypeString } from '../autoDiscoveryWizardFormUtils';
import { RunAndScanWithConfirm } from './AutoDiscoveryComponents/RunAndScanWithConfirm';
import { getApplicationPreference } from '../../../../services/appPreferencesService';

const AutoDiscoverWizardFooterWrapper = styled('div')`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  height: 100%;
  color: ${BigidColorsV2.gray[600]};
  margin-top: 8px;
`;

const AutoDiscoverWizardFooterSaveWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
`;

export interface AutoDiscoverWizardFooterProps {
  formControlRef: React.MutableRefObject<Partial<BigidFormStateAndHandlers>>;
}

export const AutoDiscoveryWizardFooter: FC<AutoDiscoverWizardFooterProps> = ({ formControlRef }) => {
  const { discoveryConfigData, setDiscoveryConfigData, discoveryConfigDataRef } =
    useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData?.authType);
  const isAssessmentScanEnabled = useMemo(() => {
    return (
      getApplicationPreference('ASSESSMENT_SCAN_ENABLED') && getApplicationPreference('DISCOVERY_RUN_AND_SCAN_ENABLED')
    );
  }, []);

  const setLoading = useCallback(
    (isLoading: boolean) => {
      setDiscoveryConfigData(current => ({
        ...current,
        isDiscovery: isLoading,
      }));
      discoveryConfigDataRef.current.isDiscovery = isLoading;
    },
    [setDiscoveryConfigData],
  );

  const onRunDiscover = useCallback(
    async (overrideValues: Record<string, any>) => {
      const handleRunErrors = (err: any) => {
        setLoading(false);
        const errorMessageKey = findErrorKeyFromMessage(err?.data?.error);
        setDiscoveryConfigData(current => ({
          ...current,
          errorMessageKey,
        }));
        notificationService.error(t('configModal.errors.discovery'));
        console.warn(err);
      };
      try {
        setLoading(true);
        await formControlRef.current?.validateAndSubmit?.(
          async (values: BigidFormValues) => {
            try {
              await runAutoDiscovery(discoveryConfigData, { ...values, ...overrideValues });
            } catch (err) {
              handleRunErrors(err);
            }
          },
          () => setLoading(false),
        );
      } catch (err) {
        handleRunErrors(err);
      }
    },
    [discoveryConfigData, formControlRef, setDiscoveryConfigData, setLoading, t],
  );

  const onSavePreset = useCallback(async () => {
    try {
      await createAutoDiscoveryConfig(discoveryConfigData);
      closeSystemDialog();
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    } catch (err) {
      const errorMessageKey = findErrorKeyFromMessage(err?.response?.data?.error);
      setDiscoveryConfigData(current => ({
        ...current,
        asideExpandedState: AutoDiscoverWizardAsideExpandedStates.OPENED_ERROR,
        errorMessageKey,
      }));
      notificationService.error(t('configModal.errors.save'));
      console.warn(err);
    }
  }, [discoveryConfigData, setDiscoveryConfigData, t]);

  const isDiscoveryReady =
    discoveryConfigData?.formData?.name &&
    (!TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[discoveryConfigData.type][authTypeValue] ||
      discoveryConfigData?.connectionInfo?.credsStatus === CredsStatus.VALID);
  const isDisabled = !isDiscoveryReady || discoveryConfigData.isDiscovery;

  useCheckPermissionsErrors(formControlRef);

  return (
    <AutoDiscoverWizardFooterWrapper>
      <AutoDiscoverWizardFooterSaveWrapper>
        <TertiaryButton
          dataAid={'AutoDiscoverWizardFooterSaveDraft'}
          size={'large'}
          disabled={!discoveryConfigData?.formData?.name}
          onClick={onSavePreset}
          text={t('configModal.buttons.saveAsDraft')}
        />
      </AutoDiscoverWizardFooterSaveWrapper>
      <RunAndScanWithConfirm onRunDiscover={onRunDiscover} isDisabled={!isDiscoveryReady || !isAssessmentScanEnabled} />
      <PrimaryButton
        size={'large'}
        disabled={isDisabled}
        dataAid={'AutoDiscoverWizardFooterDiscover'}
        onClick={onRunDiscover}
        text={t('configModal.buttons.runDiscovery')}
      />
    </AutoDiscoverWizardFooterWrapper>
  );
};
