import React, { useRef, useMemo, useCallback, FC, useEffect, useState } from 'react';
import { BigidLoader } from '@bigid-ui/components';
import { BigidLayoutMasterDetails, BigidLayoutMasterDetailsConfig, useLayout } from '@bigid-ui/layout';
import { BigidGridProps, BigidGridRow } from '@bigid-ui/grid';
import { LegacyCredentialsPage } from '../../../administration/credentials/credentials.component';
import { LoaderWrapper, Wrapper } from './CredentialsStyles';
import { credentialsService } from '../../../administration/credentials/credentials.service';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { useLocalTranslation } from './translations';
import { createGridConfig, createLayoutConfig } from './utils';
import { CredentialGridRow, CredentialItemResponse, ForwardFormRef } from './types';
import { CredentialsHeader } from './CredentialsHeader';
import { CredentialsFooter } from './CredentialsFooter';
import { CredentialsFormMemo } from './CredentialsForm';
import { useFormActions } from './hooks/useFormActions';
import { usePageHeader } from './hooks/usePageHeader';
import { useMetadata } from './hooks/useMetadata';
import { CredentialsEmptyState } from './CredentialsEmptyState';
import { useRouteLeavingListener } from '../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { cyberArcProvidersService } from '../../services/angularServices';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';

type BigidLayoutMasterDetailsRenderProps<GridRow extends BigidGridRow> = {
  config: BigidLayoutMasterDetailsConfig<GridRow>;
  selectedItem: GridRow;
};

export const CredentialsPage = () => {
  const credentialsFormRef = useRef<ForwardFormRef>();
  const [cyberArkProviders, setCyberArkProviders] = useState([]);
  const { t } = useLocalTranslation();

  const layout = useLayout<CredentialGridRow>('BigidLayoutMasterDetails', {
    pageSize: 1000,
    fetchDataFunction: async () => {
      const { data: credentialsData } = await credentialsService.getCredentials();
      if (isMutliCyberArkProviders) {
        const { results } = await cyberArcProvidersService.getAllCyberArkCredentialProviders();
        const providers = results.map((provider: any) => ({
          id: provider._id,
          value: provider._id,
          displayValue: provider.name,
        }));
        setCyberArkProviders(providers);
      }

      return {
        data:
          credentialsData.map((item: CredentialItemResponse) => ({
            ...item,
            id: item._id,
          })) ?? [],
        totalCount: credentialsData.length ?? 0,
      };
    },
    initialSorting: [{ field: 'credential_id', order: 'asc' }],
  });
  const { gridId, rows, isLoading, apiRef, getSelectedRow, ...rest } = layout;
  const selectedRow = getSelectedRow();
  const hasNoCredentials = !rows?.length && !isLoading;
  const isLegacy = useMemo(() => getApplicationPreference('SHOW_LEGACY_MASTER_DETAILS_VIEWS'), []);
  const isMutliCyberArkProviders = useMemo(
    () => getApplicationPreference('ENABLE_UNIFIED_VAULTS') && !isMultiTenantModeEnabled(),
    [],
  );

  const gridConfig: BigidGridProps<CredentialGridRow> = useMemo(
    () => createGridConfig(gridId, { ...rest, rows, isLoading, apiRef }),

    [gridId, rest, rows, isLoading, apiRef],
  );

  const { metadata, apps, scopes, isMetadataLoading } = useMetadata();

  const {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleTestConnection,
    handleCredentialAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  } = useFormActions({
    row: selectedRow,
    layout,
    metadata,
    credentialsFormRef,
  });
  usePageHeader({ handleCredentialAdd, shouldShowAction: !!rows?.length && !isMetadataLoading && !isLegacy });
  const { updateIsRouteLeavingEnabled } = useRouteLeavingListener(showUnsavedChangesDialog);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateIsRouteLeavingEnabledMemo = useCallback(updateIsRouteLeavingEnabled, []);

  const config: BigidLayoutMasterDetailsConfig<CredentialGridRow> = useMemo(
    () => createLayoutConfig(gridConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridConfig],
  );

  return isLegacy ? (
    <LegacyCredentialsPage />
  ) : (
    <Wrapper>
      <BigidLayoutMasterDetails
        disabled={isBusy || hasNoCredentials}
        onSelectionChange={handleSelectionChange}
        onSearchSubmit={handleSearchChange}
        onSearchChange={handleSearchChange}
        config={config as unknown as BigidLayoutMasterDetailsConfig<BigidGridRow>}
      >
        <BigidLayoutMasterDetails.Header
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CredentialGridRow>): JSX.Element => {
            const { isPending } = selectedItem ?? {};
            return (
              selectedItem && (
                <CredentialsHeader
                  title={isPending ? t('message.addCredentialsHeader') : selectedItem?.credential_id}
                />
              )
            );
          }}
        />
        <BigidLayoutMasterDetails.Content
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CredentialGridRow>) => {
            if (isMetadataLoading) {
              return (
                <LoaderWrapper>
                  <BigidLoader />
                </LoaderWrapper>
              );
            }

            return selectedItem ? (
              <CredentialsFormMemo
                key={selectedItem?.id}
                metadata={metadata}
                ref={credentialsFormRef}
                row={selectedItem}
                scopes={scopes}
                apps={apps}
                formControls={formControls}
                setFormIsTouched={updateIsRouteLeavingEnabledMemo}
                cyberArkProviders={cyberArkProviders}
              />
            ) : (
              hasNoCredentials && <CredentialsEmptyState handleCredentialAdd={handleCredentialAdd} />
            );
          }}
        />
        <BigidLayoutMasterDetails.Footer
          render={({ selectedItem }: BigidLayoutMasterDetailsRenderProps<CredentialGridRow>) =>
            selectedItem && (
              <CredentialsFooter
                row={selectedItem}
                isBusy={isBusy}
                handleSubmit={handleSubmit}
                handleDelete={handleDelete}
                handleTestConnection={handleTestConnection}
              />
            )
          }
        />
      </BigidLayoutMasterDetails>
    </Wrapper>
  );
};

export const Credentials: FC = () => {
  return <CredentialsPage />;
};
