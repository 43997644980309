import { ComponentType, HTMLAttributes } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { CONFIG } from '../../../config/common';
import { $state } from '../../services/angularServices';
import { GettingStartedUITrackingEvent } from './GettingStartedEventTrackerUtils';
import { publicUrls } from '../../config/publicUrls';
import { getFixedT } from './translations';
import {
  BigidAcademyIcon,
  SmallidGettingStartedGoal,
  SmallidGettingStartedPlug,
  SmallidGettingStartedShield,
  SmallidGettingStartedTeam,
  SmallidGettingStartedShare,
} from '@bigid-ui/icons';
import { setFmsdActiveView } from '../Fmsd/fmsdServices';
import { FmsdViewsIds } from '../Fmsd/FmsdViews';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { openSlackAppInstallPage } from './GettingStartedService';

const itemsText = getFixedT('items');

export interface GettingStartedItem {
  id: number;
  name: keyof GettingStartedItems;
  title: string;
  isCompleted: boolean;
  icon: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  body: string;
  buttonText: string;
  onButtonClick: () => void;
  buttonBiEvent?: GettingStartedUITrackingEvent;
  footerIconLeft?: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  footerTextLeft?: string;
  footerLinkLeft?: string;
  footerLinkLeftBiEvent?: GettingStartedUITrackingEvent;
  footerIconRight?: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  footerTextRight?: string;
  footerLinkRight?: string;
  footerLinkRightBiEvent?: GettingStartedUITrackingEvent;
}

export interface GettingStartedItems {
  connectAndScan: GettingStartedItem;
  defineSensitivity: GettingStartedItem;
  reduceRisks: GettingStartedItem;
  addTeammates: GettingStartedItem;
  shareFindings: GettingStartedItem;
  integrateWithSlack: GettingStartedItem;
}

export const gettingStartedItemsObject: GettingStartedItems = {
  defineSensitivity: {
    id: 1,
    name: 'defineSensitivity',
    title: itemsText('defineSensitivity.title'),
    isCompleted: false,
    icon: SmallidGettingStartedGoal,
    body: itemsText('defineSensitivity.body'),
    buttonText: itemsText('defineSensitivity.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_DEFINE_SENSITIVITY_SETUP_CLICK,
    footerIconLeft: BigidAcademyIcon,
    footerTextLeft: itemsText('defineSensitivity.footerTextLeft'),
    footerLinkLeft: publicUrls.SMALLID_GETTING_STARTED_CLASSIFIERS_DOC,
    footerLinkLeftBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_DEFINE_SENSITIVITY_DOCS_CLICK,
    onButtonClick: () => {
      setFmsdActiveView(FmsdViewsIds.PRE_DISCOVER).then(r => $state.go(CONFIG.states.FMSD));
    },
  },
  connectAndScan: {
    id: 2,
    name: 'connectAndScan',
    title: itemsText('connectAndScan.title'),
    isCompleted: false,
    icon: SmallidGettingStartedPlug,
    body: itemsText('connectAndScan.body'),
    buttonText: itemsText('connectAndScan.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_CONNECT_SETUP_CLICK,
    footerIconLeft: BigidAcademyIcon,
    footerTextLeft: itemsText('connectAndScan.footerTextLeft'),
    footerLinkLeft: publicUrls.SMALLID_GETTING_STARTED_SCAN_TYPES_DOC,
    footerLinkLeftBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_CONNECT_DOCS_CLICK,
    onButtonClick: () => {
      $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS);
    },
  },
  reduceRisks: {
    id: 3,
    name: 'reduceRisks',
    title: itemsText('reduceRisks.title'),
    isCompleted: false,
    icon: SmallidGettingStartedShield,
    body: itemsText('reduceRisks.body'),
    buttonText: itemsText('reduceRisks.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_REDUCE_RISKS_SETUP_CLICK,
    onButtonClick: () => {
      $state.go(CONFIG.states.DASHBOARD);
    },
  },
  shareFindings: {
    id: 4,
    name: 'shareFindings',
    title: itemsText('shareFindings.title'),
    isCompleted: false,
    icon: SmallidGettingStartedShare,
    body: itemsText('shareFindings.body'),
    buttonText: itemsText('shareFindings.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_FINDINGS_SETUP_CLICK,
    onButtonClick: () => {
      const isRiskAssessmentEnabled = getApplicationPreference('RISK_ASSESSMENT_ENABLED');
      isRiskAssessmentEnabled ? $state.go(CONFIG.states.RISK_ASSESSMENT) : $state.go(CONFIG.states.DIGEST_REPORT);
    },
  },
  addTeammates: {
    id: 5,
    name: 'addTeammates',
    title: itemsText('addTeammates.title'),
    isCompleted: false,
    icon: SmallidGettingStartedTeam,
    body: itemsText('addTeammates.body'),
    buttonText: itemsText('addTeammates.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_MANAGE_TEAM_SETUP_CLICK,
    onButtonClick: () => {
      $state.go(CONFIG.states.IDENTITY_ACCESS_MANAGEMENT);
    },
  },
  integrateWithSlack: {
    id: 6,
    name: 'integrateWithSlack',
    title: itemsText('integrateWithSlack.title'),
    isCompleted: false,
    icon: SmallidGettingStartedTeam,
    body: itemsText('integrateWithSlack.body'),
    buttonText: itemsText('integrateWithSlack.buttonText'),
    buttonBiEvent: GettingStartedUITrackingEvent.GETTING_STARTED_SLACK_INTEGRATION_CLICK,
    onButtonClick: async () => {
      await openSlackAppInstallPage();
    },
  },
};
