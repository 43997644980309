import { useEffect, useRef, useState } from 'react';
import { BigidFormStateAndHandlers, BigidFormValues, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { isNull, noop, uniqueId } from 'lodash';
import { getPermissions, prepareRequestData, prepareTestConnectionData, prepareUpdateRequestData } from '../utils';
import { credentialsService } from '../../../../administration/credentials/credentials.service';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';
import { CredentialGridRow, CredentialTypes, EnginesTypes, ForwardFormRef } from '../types';
import { BigidLayoutMasterDetailsEvent, BigidLayoutMasterDetailsGridEvents, useLayout } from '@bigid-ui/layout';
import { BigidGridRow } from '@bigid-ui/grid';
import { CredentialsTypesMetadata } from '../credentialsFormUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { CredentialsDialogContent } from '../CredentialsDialogContent';

interface UseFormActions {
  row: CredentialGridRow;
  layout: ReturnType<typeof useLayout<CredentialGridRow>>;
  credentialsFormRef: React.MutableRefObject<ForwardFormRef>;
  metadata: CredentialsTypesMetadata[];
}

interface ShowUnsavedChangesDialog {
  onClickCancel: () => void;
  onClickSave: () => Promise<void>;
}

export const useFormActions = ({ row, layout, metadata, credentialsFormRef }: UseFormActions) => {
  const [isBusy, setIsBusy] = useState(false);
  const pendingSearchTerm = useRef(null);
  const selectedRowNameRef = useRef(null);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const { isEditPermitted } = getPermissions();
  const { t } = useLocalTranslation();

  const {
    refresh,
    addRow,
    deleteRow,
    setSelectedRowById,
    clearSearch,
    updateRowById,
    emit,
    rows,
    EmitterType,
    isLoading,
    apiRef,
  } = layout;

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.clearVirtualTabelCache?.();
      const hasPendingSearch = !isNull(pendingSearchTerm.current);
      const selectedRow = selectedRowNameRef.current
        ? rows?.find(({ credential_id }) => credential_id === selectedRowNameRef.current)
        : false;
      const firstRowId = rows.length && [rows[0].id];
      const selectedId = selectedRow && [selectedRow.id];

      hasPendingSearch &&
        emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);
      setSelectedRowById(selectedId || firstRowId || []);

      selectedRowNameRef.current = null;
      pendingSearchTerm.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const createNewGridRow = (): string => {
    const rowId = uniqueId('');
    const row: CredentialGridRow = {
      apps: [],
      actions: [],
      credential_id: t('message.newCredentials'),
      customQuery: '',
      scopes: [],
      type: CredentialTypes.BigID,
      usernameAsPlainText: false,
      isPending: true,
      secret_engine: EnginesTypes.KeyValue,
      ttl: '0',
      id: rowId,
    };
    addRow(row);
    return rowId;
  };

  const resetForm = () => {
    formControls.current?.clear();
    formControls.current?.setAllTouched(false);
  };

  const handleSubmit = (shouldRefreshGrid = true) =>
    new Promise<void>(resolve => {
      formControls.current?.validateAndSubmit(
        async (values: BigidFormValues) => {
          try {
            const { isPending, credential_id } = row ?? {};
            const isNameDuplicated = rows.find(({ credential_id: name }) => name === values.credential_id);
            if (isPending && isNameDuplicated) {
              formControls.current?.setFieldError('credential_id', t('message.duplicatedName'));
              return;
            }

            const data = prepareRequestData({
              values,
              renderedFields: credentialsFormRef.current?.fieldsToRender,
              metadata,
            });
            const updateData = prepareUpdateRequestData(data, formControls.current?.touchedFields);
            setIsBusy(true);

            await Promise.resolve(
              isPending
                ? credentialsService.createCredential(data)
                : credentialsService.updateCredential(credential_id, updateData),
            );

            const hasPendingSearch = !isNull(pendingSearchTerm.current);
            const prevSelectedRowNameRef = selectedRowNameRef.current;
            formControls.current?.setAllTouched(false);
            selectedRowNameRef.current = hasPendingSearch ? null : prevSelectedRowNameRef ?? data.credential_id;
            notificationService.success(
              isPending ? t('message.createdSuccessfully') : t('message.updatedSuccessfully'),
              {
                shouldCloseOnTransition: false,
              },
            );
            if (shouldRefreshGrid) {
              refresh();
            } else {
              updateRowById(row.id, { ...row, ...updateData });
            }
            resolve();
          } catch ({ data }) {
            const message = typeof data === 'string' ? data : t('message.commonError');
            notificationService.error(message);
            pendingSearchTerm.current = null;
            selectedRowNameRef.current = null;
          } finally {
            setIsBusy(false);
          }
        },
        () => {
          selectedRowNameRef.current = null;
          return credentialsFormRef.current?.formElement.scrollTo({ top: 0, behavior: 'smooth' });
        },
      );
    });

  const handleDelete = async () => {
    try {
      const { isPending, credential_id } = row;

      if (isPending) {
        resetForm();
        deleteRow(row);
        const remainingRows = rows.filter(({ id }) => id !== row.id);
        if (remainingRows.length) {
          setSelectedRowById([remainingRows[0].id]);
        }
        return;
      }

      setIsBusy(true);
      await credentialsService.deleteCredential(credential_id);
      notificationService.success(t('message.commonDeleteSuccess'));

      refresh();
    } catch ({ data }) {
      notificationService.error(data?.message ?? t('message.commonError'));
    } finally {
      setIsBusy(false);
    }
  };

  const handleTestConnection = () => {
    formControls.current?.validateAndSubmit(
      async (values: BigidFormValues) => {
        try {
          setIsBusy(true);
          const data = prepareTestConnectionData({
            values,
            renderedFields: credentialsFormRef.current?.fieldsToRender,
          });
          await credentialsService.testConnection(data);
          notificationService.success(t('message.testConnectionSuccess'));
        } catch (e) {
          notificationService.error(e.data.error);
          console.warn(e);
        } finally {
          setIsBusy(false);
        }
      },
      () => {
        return credentialsFormRef.current?.formElement.scrollTo({ top: 0, behavior: 'smooth' });
      },
    );
  };

  const handleSubmitCancel = () => {
    const { isPending } = row ?? {};
    const hasPendingSearch = !isNull(pendingSearchTerm.current);

    isPending ? deleteRow(row) : resetForm();
    emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SELECT_STATE_CHANGE, [
      rows?.find(row => row.credential_id === selectedRowNameRef.current)?.id,
    ]);
    hasPendingSearch &&
      emit(EmitterType.LAYOUT, BigidLayoutMasterDetailsGridEvents.SEARCH_STATE_CHANGE, pendingSearchTerm.current);

    pendingSearchTerm.current = null;
    selectedRowNameRef.current = null;
  };

  const configureUnsavedChangesDialog = ({ onClickCancel, onClickSave }: ShowUnsavedChangesDialog) =>
    new Promise<boolean>(resolve => {
      const dialogOptions: BigidSystemDialogOptions = {
        title: t('modal.unsavedChanges.title'),
        onClose: noop,
        maxWidth: 'xs',
        buttons: [
          {
            component: SecondaryButton,
            dataAid: generateDataAid('ApplicationFooter', ['cancel', 'submit']),
            isClose: true,
            onClick: () => {
              onClickCancel();
              resolve(true);
            },
            text: t('buttons.no'),
          },
          {
            component: PrimaryButton,
            dataAid: generateDataAid('ApplicationFooter', ['confirm', 'submit']),
            isClose: true,
            disabled: !isEditPermitted,
            onClick: async () => {
              await onClickSave();
              resolve(true);
            },
            text: t('buttons.yes'),
          },
        ],
        content: CredentialsDialogContent,
        contentProps: { body: t('modal.unsavedChanges.body') },
        borderTop: true,
      };

      openSystemDialog(dialogOptions);
    });

  const showUnsavedChangesDialog = () =>
    configureUnsavedChangesDialog({
      onClickCancel: () => {
        handleSubmitCancel();
        row.isPending && refresh();
      },
      onClickSave: async () => {
        await handleSubmit();
      },
    });

  const addGridRow = () => {
    const hasPendingRows = rows.find(({ isPending }) => isPending);

    if (hasPendingRows) {
      notificationService.warning(t('message.pendingCredential'));
      return;
    }

    clearSearch();
    const rowId = createNewGridRow();
    setSelectedRowById([rowId]);
  };

  const handleCredentialAdd = () => {
    if (formControls.current?.formTouched) {
      return configureUnsavedChangesDialog({
        onClickCancel: addGridRow,
        onClickSave: async () => {
          await handleSubmit(false);
          addGridRow();
        },
      });
    }

    addGridRow();
  };

  const handleSelectionChange = ({ selectedRowIds = [] }: BigidLayoutMasterDetailsEvent<BigidGridRow>): boolean => {
    const isDirty = formControls.current?.formTouched;
    const [id] = selectedRowIds;
    const { isPending, id: prevSelectedId } = row ?? {};
    const hasSelectedRow = !!row;
    const shouldChangeSelection = !hasSelectedRow ? true : !isPending && !isDirty && !isBusy;

    if (id === prevSelectedId) {
      return;
    }

    if (!shouldChangeSelection) {
      selectedRowNameRef.current = rows?.find(row => row.id === id)?.credential_id;
      showUnsavedChangesDialog();
    }

    return shouldChangeSelection;
  };

  const handleSearchChange = ({ searchTerm }: BigidLayoutMasterDetailsEvent<BigidGridRow>) => {
    const { isPending, credential_id } = row ?? {};
    const isDirty = formControls.current?.formTouched;
    const shouldChangeSearchTerm = !isPending && !isDirty && !isBusy;

    if (!shouldChangeSearchTerm) {
      selectedRowNameRef.current = credential_id;
      pendingSearchTerm.current = searchTerm;
      showUnsavedChangesDialog();
    }

    return shouldChangeSearchTerm;
  };

  return {
    isBusy,
    formControls,
    handleSubmit,
    handleDelete,
    handleTestConnection,
    handleSubmitCancel,
    handleCredentialAdd,
    handleSelectionChange,
    handleSearchChange,
    showUnsavedChangesDialog,
  };
};
