import { CompletedParentScanState, ScanActions, ScansGridRow, SSEType } from '../ScanTypes';
import { runScanActionDialog } from '../ScanService';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { BigidGridWithToolbar, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { BigidFieldFilterOperator, BigidLoader, BigidPaper } from '@bigid-ui/components';
import { REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import makeStyles from '@mui/styles/makeStyles';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { $state } from '../../../services/angularServices';
import { getInitialCompletedScansGridColumns } from './CompletedScansGridConfiguration';
import {
  fetchScanData,
  getInitialScanFilters,
  labelingScanSubtypeIsTaggingScan,
  shouldShowScanAction,
  subscribeToSSEScanUpdates,
} from '../ScanUtils';
import {
  BigidArchiveIcon,
  BigidRefreshIcon,
  BigidSyncIcon,
  BigidDownloadIcon,
  BigidCheckedViewIcon,
} from '@bigid-ui/icons';
import { ScanViewEnum } from '../ScansEventTrackerUtils';
import { CONFIG } from '../../../../config/common';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { isPermitted } from '../../../services/userPermissionsService';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';
import { NoDataContentCompletedScans } from './NoDataContentCompletedScans';

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '91%',
    maxHeight: '91%',
    overflow: 'hidden',
  },
});

const allCompletedScanStates = Object.values(CompletedParentScanState);

const defaultFilter = [{ field: 'state', operator: 'in' as BigidFieldFilterOperator, value: allCompletedScanStates }];

export const ScansCompleted: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    const unregister = subscribeToSSEScanUpdates(SSEType.PARENT, ScanViewEnum.COMPLETED_SCANS);
    return () => unregister();
  }, []);

  const initialCompletedScansGridColumns = useMemo(getInitialCompletedScansGridColumns, []);
  const getInitialFilterToolbarConfig = useCallback(
    () => getInitialScanFilters(Object.values(allCompletedScanStates)),
    [],
  );
  const { isReady, gridColumns, preferences, updatePreferences, filterToolbarConfig } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: initialCompletedScansGridColumns,
    getInitialFilterToolbarConfig,
  });

  const config: BigidGridWithToolbarProps<ScansGridRow> = useMemo(
    () => ({
      pageSize: 100,
      entityName: 'Scans',
      defaultSorting: preferences?.grid?.sort || [{ field: 'completedAt', order: 'desc' }],
      showSortingControls: true,
      showSelectAll: false,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      filterToolbarConfig,
      fetchData: queryComponents => fetchScanData(queryComponents, defaultFilter),
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Rescan',
          icon: BigidRefreshIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.RESCAN),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ state, isRbacPermitted, type, name }] }) =>
            isRbacPermitted &&
            shouldShowScanAction({
              state,
              stateCondition: [
                CompletedParentScanState.STOPPED,
                CompletedParentScanState.FAILED,
                CompletedParentScanState.COMPLETED_WITH_FAILURES,
                CompletedParentScanState.COMPLETED,
                CompletedParentScanState.ABORTED,
              ],
            }),
        },
        {
          label: 'Retry Failed Objects',
          icon: BigidSyncIcon,
          execute: async actionData => runScanActionDialog(actionData, ScanActions.RETRY),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          show: ({ selectedRows: [{ isRetryAvailable, isRbacPermitted }] }) => isRbacPermitted && isRetryAvailable,
        },
        {
          label: 'Archive',
          execute: async actionData => runScanActionDialog(actionData, ScanActions.ARCHIVE),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidArchiveIcon,
          show: ({ selectedRows: [{ isRbacPermitted }] }) => isRbacPermitted,
        },
        {
          label: 'Download Failed Objects report',
          execute: async actionData => runScanActionDialog(actionData, ScanActions.DOWNLOAD_FAILED_OBJECTS_REPORT),
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidDownloadIcon,
          show: ({ selectedRows: [{ isFailedObjectsReportEmpty }] }) =>
            !isFailedObjectsReportEmpty && !isMultiTenantModeEnabled(),
        },
        {
          label: 'Tune Classifiers',
          execute: async ({ selectedRows }) => {
            const { _id, name } = selectedRows[0];
            $state.go(CONFIG.states.CURATION, { scanId: _id, scanProfileName: name });
            return {};
          },
          disable: () => false,
          isInline: true,
          hideActionInToolBar: true,
          icon: BigidCheckedViewIcon,
          show: ({ selectedRows: [{ isReviewFindingsEnabled, state }] }) => {
            return (
              getApplicationPreference('CLASSIFIER_TUNING_ENABLED') &&
              isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name) &&
              isReviewFindingsEnabled &&
              (state === CompletedParentScanState.COMPLETED ||
                state === CompletedParentScanState.COMPLETED_WITH_FAILURES)
            );
          },
        },
      ],
      noDataContent: <NoDataContentCompletedScans />,
    }),
    [filterToolbarConfig, gridColumns, preferences?.grid?.sort, updatePreferences],
  );

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        {!isReady && <BigidLoader />}
        {isReady && <BigidGridWithToolbar {...config} />}
      </BigidPaper>
    </div>
  );
};
