import React, { FC, useContext, useMemo, useRef, useState } from 'react';
import { BigidBody1, BigidMenu, BigidMenuItem, TertiaryButton } from '@bigid-ui/components';
import { BigidActionIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { CaseActionsModal, CaseActionSubTypes } from './CaseActionsModal';
import { CaseSidePanelContext } from '../../../hooks/CaseSidePanelContext';
import { ActionsListProps } from '../../../hooks/CaseSidePanelReducer';
import { generateDataAid } from '@bigid-ui/utils';

export const REVOKE_EXTERNAL_ACCESS = 'Revoke External Access';
export const REVOKE_OPEN_ACCESS = 'Revoke Open Access';
export const ENABLE_ACCESS_LOGGING = 'Enable Access Logging';
export const RESTRICT_PUBLIC_ACCESS = 'Restrict Public Access';

export const SEND_TO_CORTEX = 'Send to Cortex';
export const SEND_TO_CORTEX_ACTION = 'send_to_cortex';

export const ACTION_COMPLETED = 'COMPLETED';
export const ACTION_IN_PROGRESS = 'IN_PROGRESS';

export enum CaseActionCommands {
  REVOKE_EXTERNAL_ACCESS = 'Revoke External Access',
  REVOKE_OPEN_ACCESS = 'Revoke Open Access',
  ENABLE_ACCESS_LOGGING = 'logging',
  RESTRICT_PUBLIC_ACCESS = 'public_access',
  SEND_TO_CORTEX = 'send_to_cortex_default_command',
}

export const mapCommandsToActionSubTypes = new Map<CaseActionCommands, CaseActionSubTypes>([
  [CaseActionCommands.REVOKE_EXTERNAL_ACCESS, CaseActionSubTypes.REVOKE_EXTERNAL_ACCESS],
  [CaseActionCommands.REVOKE_OPEN_ACCESS, CaseActionSubTypes.REVOKE_OPEN_ACCESS],
  [CaseActionCommands.ENABLE_ACCESS_LOGGING, CaseActionSubTypes.ENABLE_ACCESS_LOGGING],
  [CaseActionCommands.RESTRICT_PUBLIC_ACCESS, CaseActionSubTypes.RESTRICT_PUBLIC_ACCESS],
  [CaseActionCommands.SEND_TO_CORTEX, CaseActionSubTypes.SEND_TO_CORTEX],
]);

export const mapActionSubTypesToCommands = new Map(
  [...mapCommandsToActionSubTypes.entries()].map(([key, value]) => [value, key]),
);

export const mapCommandToDisplayName = new Map<CaseActionCommands, string>([
  [CaseActionCommands.REVOKE_EXTERNAL_ACCESS, REVOKE_EXTERNAL_ACCESS],
  [CaseActionCommands.REVOKE_OPEN_ACCESS, REVOKE_OPEN_ACCESS],
  [CaseActionCommands.ENABLE_ACCESS_LOGGING, ENABLE_ACCESS_LOGGING],
  [CaseActionCommands.RESTRICT_PUBLIC_ACCESS, RESTRICT_PUBLIC_ACCESS],
  [CaseActionCommands.SEND_TO_CORTEX, SEND_TO_CORTEX],
]);

const MenuItemWrapper = styled.div({
  width: 229,
  display: 'grid',
  gridTemplateColumns: '40px 169px',
  alignItems: 'center',
  cursor: 'pointer',
});

const IconWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const CaseActionsWidget: FC = () => {
  const { caseSidePanelData, actionsList, enableActionsOnCaseLevel, dispatch } = useContext(CaseSidePanelContext);
  const [open, setOpen] = useState(false);
  const menuSectionRef = useRef();
  const listOfActions = Object.keys(actionsList);

  const handleClick = () => setOpen(!open);
  const handleClose = () => {
    setOpen(false);
  };

  const menuProps = {
    open,
    onMenuClose: handleClose,
    anchorEl: menuSectionRef.current,
  };

  const handleSelectItem = (actionSubType: CaseActionSubTypes) => {
    CaseActionsModal(caseSidePanelData, actionSubType, true, [], dispatch).then(() => {
      setOpen(false);
    });
  };

  const currentAction = (action: string) => {
    return handleSelectItem(mapCommandsToActionSubTypes.get(action as CaseActionCommands));
  };

  const MenuItems = useMemo(() => {
    return (
      <BigidMenu {...menuProps}>
        {listOfActions?.map((action: string) => {
          const displayName = mapCommandToDisplayName.get(action as CaseActionCommands);
          return (
            <BigidMenuItem
              key={action}
              onClick={() => currentAction(action)}
              disabled={
                actionsList[action as keyof ActionsListProps]?.status === ACTION_IN_PROGRESS ||
                (actionsList[action as keyof ActionsListProps]?.status === ACTION_COMPLETED &&
                  actionsList[action as keyof ActionsListProps]?.isBlocked) ||
                !enableActionsOnCaseLevel
              }
              dataAid={generateDataAid('MetaDataWidget', ['actions', action])}
            >
              <MenuItemWrapper>
                <IconWrapper>
                  <BigidActionIcon />
                </IconWrapper>
                <BigidBody1>{displayName}</BigidBody1>
              </MenuItemWrapper>
            </BigidMenuItem>
          );
        })}
      </BigidMenu>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsList, currentAction, listOfActions, menuProps]);

  const NoActionsItem = useMemo(() => {
    return (
      <BigidMenu {...menuProps}>
        <BigidMenuItem key="NoOptions">
          <MenuItemWrapper>
            <BigidBody1>No options</BigidBody1>
          </MenuItemWrapper>
        </BigidMenuItem>
      </BigidMenu>
    );
  }, [menuProps]);

  return (
    <>
      <div ref={menuSectionRef}>
        <TertiaryButton
          onClick={handleClick}
          size="medium"
          endIcon={<BigidChevronDownIcon />}
          text="All actions"
          dataAid={generateDataAid('MetaDataWidget', ['actions'])}
        />
      </div>
      {listOfActions?.length > 0 ? MenuItems : NoActionsItem}
    </>
  );
};
