import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { AutoDiscoveryWizardContext } from '../autoDiscoveryWizardContext';
import {
  BigidConnectionInProgressAnimation,
  EntityEvents,
  entityEventsEmitter,
  SecondaryButton,
} from '@bigid-ui/components';
import { Typography } from '@mui/material';
import { closeSystemDialog } from '../../../../services/systemDialogService';
import { useLocalTranslation } from '../../translations';

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

export const Image = styled.div`
  margin-bottom: 1rem;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.vars.palette.bigid.white};
`;

export const OverlayInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Actions = styled.div`
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  & button {
    width: 216px;
    border: none;
  }

  & button:hover {
    border: none;
  }
`;

export const AutoDiscoveryWizardOverlay = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');

  return (
    discoveryConfigData.isDiscovery && (
      <Overlay>
        <OverlayInnerWrapper data-aid={'AutoDiscoveryWizardOverlay-Content'}>
          <Image>
            <BigidConnectionInProgressAnimation width={159} height={155} />
          </Image>
          <Content>
            <Typography variant="h5">{t('configModal.discoveringInProgress.line1')}</Typography>
            <Typography variant="h6">{t('configModal.discoveringInProgress.line2')}</Typography>
            <Typography variant="body1">
              {t(`configModal.discoveringInProgress.${discoveryConfigData?.isScan ? 'line3WithScan' : 'line3'}`)}
            </Typography>
            <Actions>
              <SecondaryButton
                dataAid={'AutoDiscoveryWizardOverlay-Save'}
                size="large"
                onClick={() => {
                  closeSystemDialog();
                  entityEventsEmitter.emit(EntityEvents.RELOAD);
                }}
                text={t('configModal.buttons.saveAndClose')}
              />
            </Actions>
          </Content>
        </OverlayInnerWrapper>
      </Overlay>
    )
  );
};
