import { datadogRum, DefaultPrivacyLevel, RumEvent } from '@datadog/browser-rum';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { CONFIG } from '../../config/common';
import { getApplicationPreference } from './appPreferencesService';
import { sessionStorageService } from '../../common/services/sessionStorageService';
import { RumActionEvent } from '@datadog/browser-rum-core/src/rumEvent.types';

const DEFAULT_VIEW = 'default_view';
const TARGET_NAME_MAX_LENGTH = 20;

const initialize = () => {
  const applicationId = getApplicationPreference('DATADOG_APPLICATION_ID');
  const clientToken = getApplicationPreference('DATADOG_CLIENT_TOKEN');
  const sessionSampleRate = parseInt(getApplicationPreference('DATADOG_SESSION_SAMPLE_RATE'));
  const sessionReplaySampleRate = parseInt(getApplicationPreference('DATADOG_SESSION_REPLAY_SAMPLE_RATE'));

  const requiredParams = [applicationId, clientToken, sessionSampleRate, sessionReplaySampleRate];
  const isRequiredParamMissing = requiredParams.some(item => !item);
  if (isRequiredParamMissing) {
    console.error(`one of the parameters is missing can't start datadog rum`);
    return;
  }

  const env = getApplicationPreference('SAAS_ENV');
  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: CONFIG.DATADOG_SITE,
    service: CONFIG.DATADOG_SERVICE_NAME,
    env: env,
    sessionSampleRate: sessionSampleRate,
    sessionReplaySampleRate: sessionReplaySampleRate,
    trackUserInteractions: true,
    actionNameAttribute: 'data-aid',
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    beforeSend: (event, context) => {
      updateViewName(event);
      limitTargetName(event);
      return true;
    },
  });
};

const updateViewName = (event: RumEvent) => {
  if (!event?.view?.url) {
    return;
  }
  const hashSplit = event.view.url.split('/#');
  if (hashSplit.length < 2) {
    return DEFAULT_VIEW;
  }
  const paramsSplit = hashSplit[1].split('?');
  if (paramsSplit.length < 1) {
    return DEFAULT_VIEW;
  }
  event.view.name = paramsSplit[0];
};

const limitTargetName = (event: RumEvent) => {
  const actionEvent = event as RumActionEvent;
  if (!actionEvent?.action?.target?.name) {
    return;
  }
  actionEvent.action.target.name = actionEvent.action.target.name.substring(0, TARGET_NAME_MAX_LENGTH);
};

const enrichUserData = () => {
  const companyName = getApplicationPreference('COMPANY_NAME');
  const isMultiTenant = getApplicationPreference('MULTI_TENANT_MODE_ENABLED');
  const userEmail = sessionStorageService.get<string>('userEmail');
  const userName = sessionStorageService.get<string>('userName');

  datadogRum.setUser({
    email: userEmail,
    name: userName,
    id: companyName, //to support company name filtering in dd dashboard
    multi_tenant: isMultiTenant,
  });
};
const datadogRumEnabled = () => {
  return ['true', true].includes(getApplicationPreference('DATADOG_RUM_ENABLED'));
};
const initDatadogRum = () => {
  if (datadogRumEnabled()) {
    initialize();
    enrichUserData();
  }
};

const clearDatadogRum = () => {
  datadogRum.clearUser();
};

export const datadogInitListeners = () => {
  systemEventsEmitter.addEventListener(SystemEvents.LOGIN, () => {
    initDatadogRum();
  });
  systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
    clearDatadogRum();
  });
};
