import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';

const FooterContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const RightSection = styled('div')`
  display: flex;
  gap: 14px;
`;

type ModalFooterProps = {
  onCloseButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onSaveButtonClick: () => void;
};

export const ModalFooter: FC<ModalFooterProps> = ({ onCloseButtonClick, onDeleteButtonClick, onSaveButtonClick }) => {
  const { t } = useLocalTranslation('Settings.DomainModal');

  return (
    <FooterContainer>
      <SecondaryButton size="medium" onClick={onCloseButtonClick} text={t('closeButton')} />

      <RightSection>
        <SecondaryButton size="medium" onClick={onDeleteButtonClick} text={t('deleteButton')} />
        <PrimaryButton size="medium" onClick={onSaveButtonClick} text={t('saveButton')} />
      </RightSection>
    </FooterContainer>
  );
};
