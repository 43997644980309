import React, { FC } from 'react';
import { BigidGridWithToolbar } from '@bigid-ui/grid';
import { UseCurationStateResponse } from '../useCurationState';
import { useCuratedAttributesConfig } from './useCuratedAttributesConfig';
import { BigidLoader } from '@bigid-ui/components';
import { CurationStatus } from '../curationService';

export interface CuratedAttributesProps
  extends Pick<UseCurationStateResponse, 'currentCuratedDataSource' | 'onProceedToFieldsReview'> {
  curationStatus: CurationStatus;
  curatedEntityName: string;
}

export const CuratedAttributes: FC<CuratedAttributesProps> = ({
  currentCuratedDataSource,
  onProceedToFieldsReview,
  curationStatus,
  curatedEntityName,
}) => {
  const { gridConfig, isReady } = useCuratedAttributesConfig({
    currentCuratedDataSource,
    onProceedToFieldsReview,
    curationStatus,
    curatedEntityName,
  });

  return isReady ? <BigidGridWithToolbar {...gridConfig} /> : <BigidLoader />;
};
