import {
  BigidFieldRenderProps,
  BigidFormFieldSideLabelWrapper,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import {
  BigidRichTextEditor,
  BigidRichTextEditorProps,
  markdownToStateObject,
  stateObjectToMarkdown,
} from '@bigid-ui/rich-text-editor';
import { FormControl } from '@mui/material';
import React, { FC, memo } from 'react';

interface FormRichTextFieldValue {
  markdown: string;
  editorState: BigidRichTextEditorProps['data'];
}
interface FormRichTextFieldProps extends BigidFieldRenderProps {
  value: FormRichTextFieldValue;
}
export const FormRichTextField: FC<FormRichTextFieldProps> = memo(
  ({
    label,
    name,
    value: { markdown = '', editorState },
    setValue,
    onBlur,
    misc,
    isRequired,
    labelWidth,
    tooltipText,
  }) => {
    const currentMarkdownState = markdown ?? '';
    const currentEditorStateData = editorState ?? markdownToStateObject(currentMarkdownState);

    const handleOnChange: BigidRichTextEditorProps['onChange'] = newEditorStateData => {
      const newValue: FormRichTextFieldValue = {
        markdown: stateObjectToMarkdown(newEditorStateData),
        editorState: newEditorStateData,
      };
      setValue(newValue);
    };

    return (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-rich-text-${name}`}
        name={name}
        label={String(label)}
        isRequired={isRequired}
        isSeparatorAfter={misc?.isSeparatorAfter}
        labelWidth={labelWidth}
        tooltipText={tooltipText}
      >
        <FormControl fullWidth={misc?.fullWidth}>
          <BigidRichTextEditor
            label={null}
            data={currentEditorStateData}
            onChange={handleOnChange}
            onBlur={onBlur}
            options={['inline', 'link']}
          />
        </FormControl>
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);

FormRichTextField.displayName = 'FormRichTextField';
