import { BigidTabContentProps, BigidTabsAndContent, BigidTabsItem, BigidTabsProps } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { useEffect } from 'react';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { CONFIG } from '../../../../config/common';
import { $state } from '../../../services/angularServices';
import { isDataExplorerEnabled } from '../../DataExplorer/dataExplorerService';
import { useLocalTranslation } from '../translations';
import { DomainManagement } from './DomainManagement/DomainManagement';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const BusinessGlossarySettings = () => {
  const { t } = useLocalTranslation('Settings');

  useEffect(() => {
    if (!isDataExplorerEnabled()) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, []);

  useEffect(() => {
    pageHeaderService.setTitle({
      breadcrumbs: [
        { label: t('BreadCrumbs.dataExplorer'), onClick: () => $state.go(CONFIG.states.DATA_EXPLORER) },
        { label: t('BreadCrumbs.businessGlossary'), onClick: () => $state.go(CONFIG.states.BUSINESS_GLOSSARY) },
        { label: t('BreadCrumbs.settings') },
      ],
    });
  }, [t]);

  const tabProps: BigidTabsProps<BigidTabContentProps> = {
    tabs: [
      {
        label: t('Tabs.DomainManagement.title'),
        data: {
          component: DomainManagement,
        },
      },
      {
        label: t('Tabs.Notifications.title'),
        disabled: true,
      },
      {
        label: t('Tabs.CustomFields.title'),
        disabled: true,
      },
      {
        label: t('Tabs.ExternalOntology.title'),
        disabled: true,
      },
    ],
    selectedIndex: 0,
  };

  if (!isDataExplorerEnabled()) {
    return null;
  }

  return (
    <Container>
      <BigidTabsAndContent tabProps={tabProps} />
    </Container>
  );
};
