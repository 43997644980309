import React from 'react';
import { Step } from '@bigid-ui/guided-tour';
import { CurationGuidedTourStageId } from '../useCurationState';
import { SelectorModifier, generateDataAid, getGuidedTourIdSelector } from '@bigid-ui/utils';
import { CurationGuidedTourStep } from './CurationGuidedTourStep';
import { BigidDislikeIcon, BigidIntroductionActionIcon, BigidLikeIcon } from '@bigid-ui/icons';
import { CurationGuidedTourMoreActions } from './CurationGuidedTourMoreActions';
import { CurationGuidedTourReviewSample } from './CurationGuidedTourReviewSample';
import { getFixedT } from '../translations';

const t = getFixedT('CurationGuidedTour');
const getTranslation = (key: string): string => t(key);

export const curetionGuidTourSteps = (currentGuidedTour: CurationGuidedTourStageId) => {
  const dataAid = 'dataSourcesGuidTour';
  const firstStep = 'curation-main';

  const dataSourcesGuidTourSteps: Step[] = [
    {
      carouselItems: [
        {
          type: 'node',
          content: <BigidIntroductionActionIcon />,
          styles: {
            background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)',
          },
        },
      ],
      title: getTranslation('DataSource.tourHeader1'),
      content: getTranslation('DataSource.tourHeader2'),
      disableBeacon: true,
      placement: 'center',
      target: getGuidedTourIdSelector(firstStep, ['start']),
      inverseXButtonColor: true,
    },
    {
      title: getTranslation('DataSource.filterDataTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'filterDataContent'}
          dataAid={generateDataAid(dataAid, ['filterGuidedTour'])}
        />
      ),
      disableBeacon: true,
      placement: 'bottom-end',
      target: getGuidedTourIdSelector('BigidSelect-toolbar-filter'),
    },
    {
      title: getTranslation('DataSource.searchDataTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'searchDataContent'}
          dataAid={generateDataAid(dataAid, ['searchGuidedTour'])}
        />
      ),
      disableBeacon: true,
      placement: 'left-start',
      target: getGuidedTourIdSelector('BigidSearch-search'),
    },
    {
      title: getTranslation('DataSource.dataSourceTileTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'dataSourceTileContent'}
          dataAid={generateDataAid(dataAid, ['dataSourceTileGuidedTour'])}
        />
      ),
      disableBeacon: true,
      placement: 'top-start',
      target: getGuidedTourIdSelector('BigidGridRow', [], SelectorModifier.CONTAINS),
    },
    {
      title: getTranslation('DataSource.reviewSampleButtonTitle'),
      content: <CurationGuidedTourReviewSample dataAid={generateDataAid(dataAid, ['review-sample'])} />,
      disableBeacon: true,
      placement: 'right-end',
      target: getGuidedTourIdSelector('-actions-', [], SelectorModifier.CONTAINS),
    },
  ];

  const attributesGuidTourSteps: Step[] = [
    {
      title: getTranslation('Attributes.attributesTourHeader'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'attributesTourHeaderContent'}
          dataAid={generateDataAid(dataAid, ['attributesTourHeaderGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'bottom',
      target: getGuidedTourIdSelector(firstStep, ['start']),
    },
    {
      title: getTranslation('Attributes.attributeTileTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'attributeTileContent'}
          dataAid={generateDataAid(dataAid, ['attributeTileGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'bottom',
      target: getGuidedTourIdSelector('BigidGridRow', [], SelectorModifier.CONTAINS),
    },
    {
      title: getTranslation('Attributes.findingsTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'findingsContent'}
          dataAid={generateDataAid(dataAid, ['findingsGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'bottom',
      target: getGuidedTourIdSelector('findings', [], SelectorModifier.ENDS_WITH),
    },
    {
      title: getTranslation('Attributes.reviewedObjectsTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'reviewedObjectsContent'}
          dataAid={generateDataAid(dataAid, ['reviewedObjectsGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'bottom',
      target: getGuidedTourIdSelector('summary-reviewed-count', [], SelectorModifier.ENDS_WITH),
    },
    {
      title: getTranslation('Attributes.moreActionsTitle'),
      content: <CurationGuidedTourMoreActions dataAid={generateDataAid(dataAid, ['moreActionsGuidedTour'])} />,
      disableBeacon: true,
      hideBackButton: true,
      placement: 'left',
      target: getGuidedTourIdSelector('-actions-context-menu-toggler', [], SelectorModifier.ENDS_WITH),
    },
    {
      title: getTranslation('Attributes.reviewButtonTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'reviewButtonContent'}
          dataAid={generateDataAid(dataAid, ['reviewGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top',
      target: getGuidedTourIdSelector('-actions-review', [], SelectorModifier.ENDS_WITH),
    },
  ];

  const curatedFieldsGuidTourSteps: Step[] = [
    {
      title: getTranslation('Fields.objectsTourHeader'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'objectsTourHeaderContent'}
          dataAid={generateDataAid('CuratedFields', ['objectsTourHeaderGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'bottom',
      target: getGuidedTourIdSelector(firstStep, ['start']),
    },
    {
      title: getTranslation('Fields.approveRejectTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'approveRejectContent'}
          dataAid={generateDataAid('CuratedFields', ['approveRejectGuidedTour'])}
          icons={[BigidLikeIcon, BigidDislikeIcon]}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top-start',
      target: getGuidedTourIdSelector('BigidGridRow', [], SelectorModifier.CONTAINS),
    },
  ];

  const additionalAttributesStep: Step[] = [
    {
      title: getTranslation('AdditionalAttributes.additionalAttributesTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'additionalAttributesContent'}
          dataAid={generateDataAid('AdditionalAttributes', ['additionalAttributesGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top',
      target: getGuidedTourIdSelector(currentGuidedTour, ['start']),
    },
    {
      title: getTranslation('AdditionalAttributes.modifyAttributesTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'modifyAttributesContent'}
          dataAid={generateDataAid('AdditionalAttributes', ['modifyAttributesGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top',
      target: getGuidedTourIdSelector('modify-attributes', [], SelectorModifier.ENDS_WITH),
    },
  ];

  const columnProfileStep: Step[] = [
    {
      title: getTranslation('ColumnProfile.columnProfileTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'columnProfileContent'}
          dataAid={generateDataAid('ColumnProfile', ['columnProfileGuidedTour'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top',
      target: getGuidedTourIdSelector(currentGuidedTour, ['start']),
    },
  ];

  const attributePreviewStructuredStep: Step[] = [
    {
      title: getTranslation('Preview.objectPreviewTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'objectPreviewContent'}
          dataAid={generateDataAid('AttributePreview', ['objectPreview'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'top',
      target: getGuidedTourIdSelector(currentGuidedTour, ['start']),
    },
    {
      title: getTranslation('Preview.excludeListTitle'),
      content: (
        <CurationGuidedTourStep
          curationGuidedTourStageId={currentGuidedTour}
          body={'excludeListContent'}
          dataAid={generateDataAid('AttributePreview', ['excludeList'])}
        />
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'left-start',
      target: getGuidedTourIdSelector(currentGuidedTour, ['start']),
    },
  ];

  switch (currentGuidedTour) {
    case CurationGuidedTourStageId.DATA_SOURCES:
      return dataSourcesGuidTourSteps;
    case CurationGuidedTourStageId.ATTRIBUTES:
      return attributesGuidTourSteps;
    case CurationGuidedTourStageId.FIELDS:
      return curatedFieldsGuidTourSteps;
    case CurationGuidedTourStageId.PREVIEW:
      return attributePreviewStructuredStep;
    case CurationGuidedTourStageId.COLUMN_PROFILE:
      return columnProfileStep;
    case CurationGuidedTourStageId.ADDITIONAL_ATTRIBUTES:
      return additionalAttributesStep;
  }
};
