import { ReactText } from 'react';
import { httpService } from '../../../services/httpService';
import { SystemAttribute, SystemAttributeType } from '../DataCatalogService';
import {
  TagResponseEntity,
  attachTags,
  detachTags,
  createTag,
  TagEntity,
  TagAssignmentTarget,
  TagCompositionPartType,
  UseTagResponseData,
} from '../../TagsManagement/TagsManagementService';
import { getTagEntityByName } from '../../TagsManagement/TagsManagementUtils';
import { objectToQueryString, BigidTagBaseProps } from '@bigid-ui/components';
import { sendSSERequestWithEventId } from '../../../services/sseService';
import { DataCatalogAsyncOperationRoutingKey } from '../DataCatalogAsyncOps/DataCatalogAsyncOpsTypes';
import { ClusterBusinessAttrAssignmentPayload } from '../DataCatalogAsyncOps/operations/ClusterBusinessAttrAssignment';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const ENABLE_LEGACY_STRUCTURED_PREVIEW = 'ENABLE_LEGACY_STRUCTURED_PREVIEW';

export enum ManualFieldType {
  ATTRIBUTE = 'attribute',
}

export interface AttributeRanked extends SystemAttribute {
  rank: string;
  calc_confidence_level?: number;
}

export interface ManualField {
  hashId?: ReactText;
  attribute_id?: ReactText;
  fullyQualifiedName: string;
  fieldName?: string;
  value: string;
  attribute_type: SystemAttributeType;
  type: ManualFieldType;
  isNewAttribute?: boolean;
  confLevel?: string;
}

export interface DataCatalogCollection {
  fullyQualifiedName: string;
  source: string;
  objectName: string;
  container: string;
}

export interface DataCatalogObjectColumn {
  column_name: string;
  attribute_list: AttributeRanked[];
  fieldType?: string;
  isPrimary?: boolean;
  isProfiled?: boolean;
  businessAttribute?: ColumnBusinessAttribute;
  linkedColumns?: number;
  tags?: TagEntity[];
  clusterId?: string;
  order?: number;
  nullable?: boolean;
}

export interface UseColumnTagPayload {
  systemTags: TagEntity[];
  tags: BigidTagBaseProps[];
  fullyQualifiedName: string;
  source: string;
  columnName: string;
}

export enum ColumnBusinessAttributePopulatedBy {
  USER = 'USER',
  AUTO = 'AUTO',
}

export interface ColumnBusinessAttribute {
  friendlyName?: string;
  totalSuggestionsCount?: number;
  populatedBy?: ColumnBusinessAttributePopulatedBy;
}

export interface ColumnBusinessAttributeSuggestion {
  friendlyName: string;
  columnName: string;
  tableName: string;
  source: string;
  sourceType: string;
}

export interface ColumnBusinessAttributeSuggestionResponse {
  results: ColumnBusinessAttributeSuggestion[];
}

export interface UseColumnBusinessAttributeSuggestionPayload {
  fullyQualifiedName: string;
  columnName: string;
  friendlyName: string;
}

export interface UseClusterBusinessAttributeSuggestionPayload {
  clusterId: string;
  friendlyName: string;
}

export interface ColumnProfile {
  fieldCount?: number;
  inferredDataType?: string;
  avgNum?: string;
  minNum?: string;
  maxNum?: string;
  numCnt?: number;
  numDev?: string;
  avgStrLen?: number;
  minStrLen?: number;
  maxStrLen?: number;
  strDev?: string;
  maxStr?: string;
  minStr?: string;
  maxLexStr?: string;
  minLexStr?: string;
  emptyPct?: number;
  distinctPct?: number;
  isProfiled?: boolean;
}

export type CollectionLineageConnectionType = 'auto' | 'referential' | 'manual';

export interface CollectionLineageConnection {
  linkedCollection: string;
  originFields: string;
  destinationField: string;
  type: CollectionLineageConnectionType;
}

export type CollectionLineageFieldAttributeRank = 'Low' | 'Medium' | 'High';

export interface CollectionLineageFieldAttribute {
  name: string;
  rank: CollectionLineageFieldAttributeRank;
  confidence_level: number;
}

export interface CollectionLineageField {
  fieldName: string;
}

export interface CollectionLineage {
  name: string;
  fields: CollectionLineageField[];
  connectionDetails: CollectionLineageConnection[];
}

export interface CollectionLineageManualConnection {
  lineage_node: string;
  linked_collection: string;
  local_field: string;
  foreign_field: string;
}

export type DataCatalogObjectColumnsResponse = DataCatalogObjectColumn[];

export interface DataCatalogObjectColumnsCountResponse {
  count: number;
}

export interface DataCatalogObjectColumnUseTagResponseData {
  tags: TagEntity[];
  response?: UseTagResponseData[];
}

export interface ColumnPreviewResponse {
  amountOfRows: number;
  results: ColumnPreviewResults[];
}

export interface ColumnPreviewResults {
  fieldName: string;
  fieldValue: string;
  rowNumber: number;
}

export interface ColumnPreviewPayload {
  fullyQualifiedName: string;
  columnName: string;
  isDistinct?: boolean;
  isNotNullOnly?: boolean;
  limit?: number;
}

export interface SimilarColumn {
  fullyQualifiedName: string;
  columnName: string;
  businessAttribute: string;
  tableName: string;
  source: string;
  isPrimary: boolean;
  dataType: string;
  attributes: AttributeRanked[];
  tags: TagEntity[];
}

export interface SimilarColumnsResponse {
  totalCount: number;
  results: SimilarColumn[];
}

export const getColumnsByObjectName = (query?: string) => {
  return httpService
    .fetch<{ data: DataCatalogObjectColumnsResponse }>(`data-catalog/object-details/columns${query ? `?${query}` : ''}`)
    .then(({ data }) => data);
};

export const getColumnsCount = (objectName: string) => {
  const objectNameParsed = encodeURIComponent(objectName);
  return httpService
    .fetch<{ data: DataCatalogObjectColumnsCountResponse }>(
      `data-catalog/object-details/columns/count/?object_name=${objectNameParsed}`,
    )
    .then(({ data }) => data);
};

export const updateManualFields = (data: ManualField[]) => {
  return httpService.post(`data-catalog/manual-fields`, { data }).then(({ data }) => data);
};

export const deleteManualFields = (data: ManualField[]) => {
  return httpService.delete(`data-catalog/manual-fields`, { data }).then(({ data }) => data);
};

export const getColumnProfileCancelable = (objectName: string, fieldName: string) => {
  objectName = encodeURIComponent(objectName);
  fieldName = encodeURIComponent(fieldName);

  return httpService.cancelableFetch<{ data: ColumnProfile }>(
    `data-catalog/object-details/columns/column-profile/?object_name=${objectName}&field_name=${fieldName}`,
  );
};

export const getColumnProfile = (objectName: string, fieldName: string) => {
  objectName = encodeURIComponent(objectName);
  fieldName = encodeURIComponent(fieldName);

  return httpService
    .fetch<{ data: ColumnProfile }>(
      `data-catalog/object-details/columns/column-profile/?object_name=${objectName}&field_name=${fieldName}`,
    )
    .then(({ data }) => data);
};

export const getCollectionLineage = (collectionNames: string[], columnNames: string[]) => {
  const query = objectToQueryString({
    anchor_collections: JSON.stringify(collectionNames),
    columns_names: JSON.stringify(columnNames),
  });

  return httpService
    .fetch<{ data: CollectionLineage[] }>(`data-catalog/object-details/columns/lineage?${query}`)
    .then(({ data }) => data);
};

export const getCollectionsByName = (name?: string) => {
  return httpService
    .fetch<{ data: DataCatalogCollection[] }>(`data-catalog/tables${name ? `?filter=objectName=${name}` : ''}`)
    .then(({ data }) => data);
};

export const addLineageManualConnections = (data: CollectionLineageManualConnection[]) => {
  return httpService.post(`lineage/manual/connection`, { data }).then(({ data }) => data);
};

export const removeLineageManualConnections = (data: CollectionLineageManualConnection[]) => {
  return httpService.delete(`lineage/manual/connection`, { data }).then(({ data }) => data);
};

export const createAndAttachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: createdColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyCreatedTagEntities: TagEntity[] = [];
  const recentlyCreatedTagResponses: UseTagResponseData[] = [];

  for (const createdColumnTag of createdColumnTags) {
    const { isNew, name, value } = createdColumnTag;
    let nameCreated: TagResponseEntity;
    let valueCreated: TagResponseEntity;
    let tagNameIdToAttach: TagResponseEntity['_id'];
    let tagValueIdToAttach: TagResponseEntity['_id'];
    const tagNameDescription = `${name} tag name`;
    const tagValueDescription = `${value} tag value`;

    if (isNew) {
      const { data: tagCreatedResponse } = await createTag({
        name,
        type: TagCompositionPartType.tag,
        description: tagNameDescription,
      });
      nameCreated = tagCreatedResponse[0];
      tagNameIdToAttach = nameCreated._id;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    } else {
      tagNameIdToAttach = getTagEntityByName(
        [...systemTags, ...recentlyCreatedTagEntities],
        createdColumnTag.name,
      )?.tagId;
      const { data: valueCreatedResponse } = await createTag({
        name: value,
        type: TagCompositionPartType.value,
        description: tagValueDescription,
        parentId: tagNameIdToAttach,
      });
      valueCreated = valueCreatedResponse[0];
      tagValueIdToAttach = valueCreated._id;
    }

    if (tagNameIdToAttach && tagValueIdToAttach) {
      const { data } = await attachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          context: columnName,
          source,
          tags: [
            {
              tagId: tagNameIdToAttach,
              valueId: tagValueIdToAttach,
            },
          ],
        },
      ]);

      recentlyCreatedTagEntities.push({
        tagId: tagNameIdToAttach,
        tagName: name,
        valueId: tagValueIdToAttach,
        tagValue: value,
      });
      recentlyCreatedTagResponses.push(data);
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyCreatedTagEntities, response: recentlyCreatedTagResponses };
};

export const attachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: attachedColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyAttachedTagEntities: TagEntity[] = [];
  const recentlyAttachedTagResponses: UseTagResponseData[] = [];

  for (const attachedColumnTag of attachedColumnTags) {
    const { name, value } = attachedColumnTag;
    const tagToAttach = getTagEntityByName(systemTags, name, value);

    if (tagToAttach?.tagId && tagToAttach?.valueId) {
      const { tagId, valueId } = tagToAttach;
      const { data } = await attachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          source,
          context: columnName,
          tags: [
            {
              tagId,
              valueId,
            },
          ],
        },
      ]);

      recentlyAttachedTagEntities.push({
        tagId,
        tagName: name,
        valueId,
        tagValue: value,
      });
      recentlyAttachedTagResponses.push(data);
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyAttachedTagEntities, response: recentlyAttachedTagResponses };
};

export const detachMultipleTags = async ({
  columnName,
  fullyQualifiedName,
  source,
  systemTags,
  tags: detachedColumnTags,
}: UseColumnTagPayload): Promise<DataCatalogObjectColumnUseTagResponseData> => {
  const recentlyDetachedTagEntities: TagEntity[] = [];

  for (const detachedColumnTag of detachedColumnTags) {
    const { name, value } = detachedColumnTag;
    const tagToDetach = getTagEntityByName(systemTags, name, value);

    if (tagToDetach?.tagId && tagToDetach?.valueId) {
      const { tagId, valueId } = tagToDetach;
      await detachTags([
        {
          type: TagAssignmentTarget.column,
          fullyQualifiedName,
          context: columnName,
          source,
          tags: [
            {
              tagId,
              valueId,
            },
          ],
        },
      ]);

      recentlyDetachedTagEntities.push({
        tagId,
        tagName: name,
        valueId,
        tagValue: value,
      });
    } else {
      throw { message: 'Tag name or value does not exist' };
    }
  }

  return { tags: recentlyDetachedTagEntities };
};

export const getColumnBusinessAttributeSuggestions = (query: string) => {
  return httpService
    .fetch<{ data: ColumnBusinessAttributeSuggestionResponse }>(
      `data-catalog/column/business-attribute/suggestions?${query}`,
    )
    .then(({ data }) => data);
};

export const addColumnBusinessAttribute = (data: UseColumnBusinessAttributeSuggestionPayload) => {
  return httpService.post(`data-catalog/column/business-attribute`, data).then(({ data }) => data);
};

export const getColumnPreviewFromCache = (query: string) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService
      .fetch<{ data: ColumnPreviewResponse }>(`data-catalog/column-preview?${query}`)
      .then(({ data }) => data);
  }
  return httpService
    .fetch<{ data: ColumnPreviewResponse }>(`data-catalog/field-preview?${query}`)
    .then(({ data }) => data);
};

export const getColumnPreview = (data: ColumnPreviewPayload) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService.post(`data-catalog/column-preview`, data).then(({ data }) => data);
  }
  return httpService.post(`data-catalog/field-preview`, data).then(({ data }) => data);
};

export const deleteColumnPreview = (query: string) => {
  if (getApplicationPreference(ENABLE_LEGACY_STRUCTURED_PREVIEW)) {
    return httpService.delete(`data-catalog/column-preview?${query}`).then(({ data }) => data);
  }
  return httpService.delete(`data-catalog/field-preview?${query}`).then(({ data }) => data);
};

export const addClusterBusinessAttribute = (data: ClusterBusinessAttrAssignmentPayload) => {
  return sendSSERequestWithEventId(
    'put',
    `data-catalog/column/cluster/business-attribute`,
    { ...data },
    {},
    DataCatalogAsyncOperationRoutingKey.CLUSTER_BULK_ASSIGNMENT,
  );
};

export const getSimilarColumns = (query: string) => {
  return httpService
    .fetch<{ data: SimilarColumnsResponse }>(`data-catalog/column/cluster/similar?${query}`)
    .then(({ data }) => data.data);
};

export const getSimilarColumnsAsCSV = (query?: string) => {
  return httpService.downloadFile(`data-catalog/column/cluster/similar/export${query ? '?' + query : ''}`);
};
