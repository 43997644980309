import { ComponentType, HTMLAttributes } from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { cyberArkService } from '../../../services/cyberArkService';
import { hashiCorpService } from '../../../services/hashiCorpService';
import { thycoticService } from '../../../services/thycoticService';
import { $state } from '../../../services/angularServices';
import { DATA_SOURCES_INIT_VIEWS } from '../../DataSources/DataSources';
import { goToCatalogWithChangePreferences } from '../goToCatalogWithChangePreferences';
import CorrelationIcon from '../../../assets/icons/correlation.svg';
import ClassificationIcon from '../../../assets/icons/classification.svg';
import ClusteringIcon from '../../../assets/icons/clustering.svg';
import CatalogIcon from '../../../assets/icons/catalog.svg';
import InventoryIcon from '../../../assets/icons/inventory.svg';
import PoliciesIcon from '../../../assets/icons/policies.svg';
import ReportsIcon from '../../../assets/icons/reports.svg';
import AdministrationIcon from '../../../assets/icons/administration.svg';
import AccessIntelligenceDashboardbarIcon from '../../../assets/icons/AccessIntelligenceDashboard.svg';
import BusinessFlowIcon from '../../../assets/icons/BigidBusinessFlowsDashboard.svg';
import BreachResponseDashboardbarIcon from '../../../assets/icons/BreachResponseDashboard.svg';
import ConsentGovernanceDashboardbarIcon from '../../../assets/icons/BigidConsentGovernanceDashboard.svg';
import DSARIcon from '../../../assets/icons/RequestManagementDashboard.svg';
import RiskConfigurationDashboardbarIcon from '../../../assets/icons/BigidRiskConfigurationDashboard.svg';
import MIPDashboardbarIcon from '../../../assets/icons/BigidMIPDashboard.svg';
import CyberarkDashboardbarIcon from '../../../assets/icons/BigidCyberarkDashboard.svg';
import HashiCorpIcon from '../../../assets/icons/BigidAppHashiCorp.svg';
import ThycoticIcon from '../../../assets/icons/BigidAppThycotic.svg';
import { CONFIG } from '../../../../config/common';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  APPLICATIONS_PERMISSIONS,
  BUSINESS_FLOWS_PERMISSIONS,
  CATALOG_PERMISSIONS,
  CLASSIFICATION_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  CONSENT_GOVERNANCE_PERMISSIONS,
  CORRELATION_PERMISSIONS,
  CORRELATION_SET_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  DSAR_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  INVENTORY_PERMISSIONS,
  POLICIES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCAN_RESULT_DETAILS_PERMISSIONS,
  SCANS_PERMISSIONS,
} from '@bigid/permissions';
import { areAllPermitted } from '../../../services/userPermissionsService';
import { docsUrls } from '../../../config/publicUrls';
import { isMultiTenantModeEnabled } from '../../../utilities/multiTenantUtils';

export enum DashboardShortcutsTypeEnum {
  section = 'section',
  app = 'app',
}

interface ShortcutsConfigItemInterface {
  onClick?: () => void;
  state?: string;
  title: string;
  permissions?: string[];
  showOnMt?: boolean;
}

interface ShortcutsConfigItemTopInterface extends ShortcutsConfigItemInterface {
  children?: ShortcutsConfigItemInterface[];
  icon: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  type: DashboardShortcutsTypeEnum;
  infoLink: string;
}

const shortcuts: ShortcutsConfigItemTopInterface[] = [
  {
    title: 'Getting Started',
    type: DashboardShortcutsTypeEnum.section,
    icon: AdministrationIcon,
    state: CONFIG.states.DATA_SOURCE_INIT,
    permissions: [DATA_SOURCES_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.OVERVIEW,
    showOnMt: true,
    children: [
      {
        title: 'Connect new data source or system',
        onClick: () => {
          $state.go(CONFIG.states.DATA_SOURCE_INIT, { path: DATA_SOURCES_INIT_VIEWS.selectDataType.path });
        },
        state: CONFIG.states.DATA_SOURCE_INIT,
      },
      {
        title: 'Import data sources',
        onClick: () => {
          $state.go(CONFIG.states.DATA_SOURCE_INIT, { path: DATA_SOURCES_INIT_VIEWS.importDataSources.path });
        },
        state: 'noState',
      },
      {
        title: 'View recent scans',
        state: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
        permissions: [SCANS_PERMISSIONS.ACCESS.name],
      },
      {
        title: 'View scan results',
        state: CONFIG.states.CATALOG,
        permissions: [CATALOG_PERMISSIONS.ACCESS.name],
      },
      {
        title: 'Import glossary list',
        state: CONFIG.states.GENERAL_SETTINGS,
        permissions: [
          GENERAL_SETTINGS_PERMISSIONS.ACCESS.name,
          GENERAL_SETTINGS_PERMISSIONS.READ_BUSINESS_GLOSSARY.name,
        ],
      },
    ],
  },
  {
    title: 'Catalog',
    type: DashboardShortcutsTypeEnum.section,
    icon: CatalogIcon,
    state: CONFIG.states.CATALOG,
    permissions: [CATALOG_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.DATA_CATALOG_OBJECT_DETAILS,
    showOnMt: true,
    children: [
      {
        title: 'Review objects that contain findings',
        onClick: () => {
          goToCatalogWithChangePreferences({
            sideFilterSelection: [
              {
                field: 'contains_pi',
                operator: 'equal',
                value: 'true',
              },
            ],
            optionalSideFilterSections: [],
            searchQuery: '',
          });
        },
        state: CONFIG.states.CATALOG,
      },
      {
        title: 'View objects that has duplicates',
        onClick: () => {
          goToCatalogWithChangePreferences({
            sideFilterSelection: [
              {
                field: 'has_duplicates',
                operator: 'equal',
                value: 'true',
              },
            ],
            optionalSideFilterSections: [],
            searchQuery: '',
          });
        },
        state: CONFIG.states.CATALOG,
      },
      {
        title: 'View objects that are open to access',
        onClick: () => {
          goToCatalogWithChangePreferences({
            sideFilterSelection: [
              {
                field: 'metadata.access_rights',
                operator: 'equal',
                value: 'OPEN ACCESS',
              },
            ],
            optionalSideFilterSections: [],
            searchQuery: '',
          });
        },
        state: CONFIG.states.CATALOG,
      },
    ],
  },
  {
    title: 'Inventory',
    type: DashboardShortcutsTypeEnum.section,
    icon: InventoryIcon,
    state: CONFIG.states.INVENTORY,
    infoLink: docsUrls.INVENTORY,
    showOnMt: false,
    permissions: [INVENTORY_PERMISSIONS.ACCESS.name],
  },
  {
    title: 'Correlation',
    type: DashboardShortcutsTypeEnum.section,
    icon: CorrelationIcon,
    state: CONFIG.states.CORRELATION,
    infoLink: docsUrls.IDENTITY_LINEAGE,
    showOnMt: true,
    permissions: [CORRELATION_PERMISSIONS.ACCESS.name],
  },
  {
    title: 'Cluster Analysis',
    type: DashboardShortcutsTypeEnum.section,
    icon: ClusteringIcon,
    state: CONFIG.states.CLUSTERING,
    permissions: [CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.DATA_CLUSTERING,
    showOnMt: true,
    children: [
      {
        title: 'View the largest data clusters',
        state: CONFIG.states.CLUSTERING,
      },
      {
        title: 'View data clusters that contain findings',
        state: CONFIG.states.CLUSTERING,
        onClick: () => {
          $state.go(CONFIG.states.CLUSTERING, {
            filter: {
              sideFilterSelection: [
                {
                  field: 'contains_pi',
                  operator: 'equal',
                  value: 'true',
                },
              ],
            },
          });
        },
      },
      {
        title: 'View data clusters that contains duplicates',
        onClick: () => {
          $state.go(CONFIG.states.CLUSTERING, {
            filter: {
              sideFilterSelection: [
                {
                  field: 'contains_duplicate',
                  operator: 'equal',
                  value: 'true',
                },
              ],
            },
          });
        },
        state: CONFIG.states.CLUSTERING,
      },
    ],
  },
  {
    title: 'Classifications',
    type: DashboardShortcutsTypeEnum.section,
    icon: ClassificationIcon,
    state: CONFIG.states.CLASSIFICATION,
    infoLink: docsUrls.CLASSIFIERS,
    showOnMt: true,
    permissions: [CLASSIFICATION_PERMISSIONS.ACCESS.name],
  },
  {
    title: 'Policies',
    type: DashboardShortcutsTypeEnum.section,
    icon: PoliciesIcon,
    state: CONFIG.states.POLICIES,
    infoLink: docsUrls.POLICIES,
    showOnMt: true,
    permissions: [POLICIES_PERMISSIONS.ACCESS.name],
  },
  {
    title: 'Reports',
    type: DashboardShortcutsTypeEnum.section,
    icon: ReportsIcon,
    state: CONFIG.states.DIGEST_REPORT,
    infoLink: docsUrls.REPORTS,
    showOnMt: false,
    permissions: [REPORTS_PERMISSIONS.ACCESS.name],
    children: [
      {
        title: 'View activity highlights',
        state: CONFIG.states.DIGEST_REPORT,
        permissions: [REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name],
      },
      {
        title: 'View scan results details',
        state: CONFIG.states.SCAN_ANALYSIS,
        permissions: [SCAN_RESULT_DETAILS_PERMISSIONS.ACCESS.name],
      },
    ],
  },
  {
    title: 'Administration',
    type: DashboardShortcutsTypeEnum.section,
    icon: AdministrationIcon,
    infoLink: '',
    showOnMt: false,
    children: [
      {
        title: 'Configure data sources ',
        state: CONFIG.states.DATA_SOURCE_ROUTER,
        permissions: [DATA_SOURCES_PERMISSIONS.ACCESS.name],
      },
      {
        title: 'Configure correlation sets',
        state: CONFIG.states.CORRELATION,
        permissions: [CORRELATION_SET_PERMISSIONS.ACCESS.name],
      },
      {
        title: 'Configure classifiers',
        state: CONFIG.states.CLASSIFIERS,
        permissions: [CLASSIFIERS_PERMISSIONS.ACCESS.name],
      },
      {
        title: 'Set up email server',
        state: CONFIG.states.GENERAL_SETTINGS,
        onClick: () => {
          $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'emailSetup' });
        },
        permissions: [GENERAL_SETTINGS_PERMISSIONS.ACCESS.name, GENERAL_SETTINGS_PERMISSIONS.MANAGE_EMAIL_SETUP.name],
      },
      {
        title: 'Configure ignored list',
        onClick: () => {
          $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'ignoredList' });
        },
        state: CONFIG.states.GENERAL_SETTINGS,
        permissions: [GENERAL_SETTINGS_PERMISSIONS.ACCESS.name, GENERAL_SETTINGS_PERMISSIONS.READ_IGNORED_LISTS.name],
      },
      {
        title: 'Configure system licenses ',
        onClick: () => {
          $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'licenses' });
        },
        state: CONFIG.states.GENERAL_SETTINGS,
        permissions: [GENERAL_SETTINGS_PERMISSIONS.ACCESS.name, GENERAL_SETTINGS_PERMISSIONS.READ_LICENSE.name],
      },
      {
        title: 'Manage user roles and permissions',
        state: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
        permissions: [ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name],
      },
    ],
  },
  {
    title: 'Scans',
    type: DashboardShortcutsTypeEnum.section,
    icon: AdministrationIcon,
    state: CONFIG.states.SCANS_NEW,
    permissions: [SCANS_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.MANAGING_SCANS,
    showOnMt: true,
    children: [
      {
        title: 'Configure a new scan profile',
        state: CONFIG.states.CREATE_SCAN_PROFILE,
        permissions: [SCANS_PERMISSIONS.CREATE_SCAN_PROFILES.name],
      },
      {
        title: 'View last scans',
        state: CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
        permissions: [SCANS_PERMISSIONS.READ_SCAN_ACTIVITY.name],
      },
    ],
  },
  {
    title: 'Data Processing and Sharing',
    type: DashboardShortcutsTypeEnum.app,
    icon: BusinessFlowIcon,
    state: CONFIG.states.BUSINESS_FLOWS,
    infoLink: docsUrls.DATA_FLOWS_TOOL,
    showOnMt: false,
    permissions: [BUSINESS_FLOWS_PERMISSIONS.ACCESS.name],
    children: [
      // TODO: find way to open "popup of open Business Processes"
      // {
      //   title: 'View data processing and sharing',
      //   state: CONFIG.states.BUSINESS_FLOWS,
      // },
      {
        title: 'View recent data processing and sharing',
        state: CONFIG.states.BUSINESS_FLOWS,
      },
    ],
  },
  {
    title: 'Access Request Management',
    type: DashboardShortcutsTypeEnum.app,
    icon: DSARIcon,
    state: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
    permissions: [DSAR_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.SUBJECT_ACCESS_REQUEST,
    showOnMt: false,
    children: [
      {
        title: 'Run a new SAR',
        onClick: () => {
          $state.go(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'new-request' });
        },
        state: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
      },
      {
        title: 'View completed SAR scans',
        onClick: () => {
          $state.go(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'completed-requests' });
        },
        state: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
      },
      {
        title: 'Validate deletion',
        onClick: () => {
          $state.go(CONFIG.states.ACCESS_REQUEST_MANAGEMENT, { path: 'deletion-validation-requests' });
        },
        state: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
      },
    ],
  },
  {
    title: 'Consent Governance',
    type: DashboardShortcutsTypeEnum.app,
    icon: ConsentGovernanceDashboardbarIcon,
    state: CONFIG.states.CONSENT_GOVERNANCE,
    permissions: [CONSENT_GOVERNANCE_PERMISSIONS.ACCESS.name],
    infoLink: docsUrls.CONSENT_GOVERNANCE_OVERVIEW,
    showOnMt: false,
    children: [
      {
        title: 'Define a policy or agreement',
        state: CONFIG.states.CONSENT_GOVERNANCE_AGREEMENTS,
        permissions: [CONSENT_GOVERNANCE_PERMISSIONS.CREATE_AGREEMENT.name],
      },
      {
        title: 'View consent and preferences reports',
        state: `${CONFIG.states.CONSENT_GOVERNANCE_REPORTS}`,
        permissions: [CONSENT_GOVERNANCE_PERMISSIONS.READ_REPORT.name],
      },
      {
        title: 'Add a new consent source',
        state: CONFIG.states.NEW_CONSENT_CONNECTION,
        permissions: [CONSENT_GOVERNANCE_PERMISSIONS.CREATE_CONSENT_SOURCES.name],
      },
    ],
  },
  {
    title: 'Breach Response',
    type: DashboardShortcutsTypeEnum.app,
    icon: BreachResponseDashboardbarIcon,
    state: CONFIG.states.BREACH_RESPONSE,
    permissions: [APPLICATIONS_PERMISSIONS.READ_BREACH_RESPONSE.name],
    showOnMt: false,
    infoLink: docsUrls.BREACH_RESPONSE,
  },
  {
    title: 'Access Intelligence',
    type: DashboardShortcutsTypeEnum.app,
    icon: AccessIntelligenceDashboardbarIcon,
    state: CONFIG.states.ACCESS_GOVERNANCE,
    permissions: [APPLICATIONS_PERMISSIONS.READ_ACCESS_INTELLIGENCE.name],
    showOnMt: false,
    infoLink: docsUrls.DATA_ACCESS_GOVERNANCE,
  },
  {
    title: 'Risk Configuration',
    type: DashboardShortcutsTypeEnum.app,
    icon: RiskConfigurationDashboardbarIcon,
    state: CONFIG.states.RISK_CONFIGURATION,
    permissions: [APPLICATIONS_PERMISSIONS.READ_RISK_CONFIGURATION.name],
    showOnMt: false,
    infoLink: docsUrls.CONFIGURING_RISK,
  },
  {
    title: 'File Labeling',
    type: DashboardShortcutsTypeEnum.app,
    icon: MIPDashboardbarIcon,
    state: CONFIG.states.AZURE_INFORMATION_PROTECTION,
    permissions: [APPLICATIONS_PERMISSIONS.READ_MICROSOFT_INFORMATION_PROTECTION.name],
    showOnMt: false,
    infoLink: docsUrls.LABELING_MIP,
  },
  {
    title: 'CyberArk Configuration',
    type: DashboardShortcutsTypeEnum.app,
    icon: CyberarkDashboardbarIcon,
    permissions: [APPLICATIONS_PERMISSIONS.READ_CYBERARK.name],
    showOnMt: false,
    onClick: () => {
      cyberArkService.showDialog();
    },
    infoLink: docsUrls.CREDENTIALS_SECTION_CYBER_ARK,
    state: 'noState',
  },
  {
    title: 'HashiCorp Configuration',
    type: DashboardShortcutsTypeEnum.app,
    icon: HashiCorpIcon,
    permissions: [APPLICATIONS_PERMISSIONS.READ_HASHICORP.name],
    showOnMt: false,
    onClick: () => {
      hashiCorpService.showDialog();
    },
    state: 'noState',
    infoLink: docsUrls.CREDENTIALS_SECTION_HASHI_CORP,
  },
  {
    title: 'Thycotic Configuration',
    type: DashboardShortcutsTypeEnum.app,
    icon: ThycoticIcon,
    showOnMt: false,
    permissions: [APPLICATIONS_PERMISSIONS.READ_THYCOTIC.name],
    onClick: () => {
      thycoticService.showDialog();
    },
    state: 'noState',
    infoLink: docsUrls.CREDENTIALS_SECTION_THYCOTIC,
  },
];

type shortcutsExtraFilter = (item: ShortcutsConfigItemInterface) => boolean;

const getItem = (item: ShortcutsConfigItemInterface, extraFilter?: shortcutsExtraFilter): any => {
  const resultItem = (!item.permissions || areAllPermitted(item.permissions)) &&
    (!extraFilter || extraFilter(item)) && {
      ...item,
    };
  if (resultItem) {
    resultItem.onClick = resultItem.onClick
      ? resultItem.onClick
      : resultItem.state
      ? () => {
          $state.go(resultItem.state);
        }
      : null;
  }

  return resultItem;
};

const getItemTop = (
  item: ShortcutsConfigItemTopInterface,
  extraFilter?: shortcutsExtraFilter,
): ShortcutsConfigItemTopInterface => {
  const resultItem = getItem(item, extraFilter);

  return (
    resultItem && {
      ...resultItem,
      children: item?.children
        ?.map(child => getItem(child, extraFilter))
        .filter((item: ShortcutsConfigItemInterface) => item),
    }
  );
};

const filterShortcutsForMt = (shortcuts: ShortcutsConfigItemTopInterface[]) => {
  if (isMultiTenantModeEnabled()) {
    return shortcuts.filter((item: ShortcutsConfigItemInterface) => item.showOnMt);
  }
  return shortcuts;
};

export const getShortcuts = (extraFilter?: shortcutsExtraFilter) => {
  const filteredShortcuts = filterShortcutsForMt(shortcuts);
  return filteredShortcuts.reduce((result, item) => {
    const itemNormalized = getItemTop(item, extraFilter);
    return [...result, ...(itemNormalized ? [itemNormalized] : [])];
  }, []);
};
