import { httpService } from '../../../../services/httpService';

type DataSourceScanConfig = {
  onSuccess?: () => void;
  onError?: (error?: Error) => void;
};

const DEFAULT_SCAN_TEMPLATE_NAME = 'Data source scan';

export const runDefaultScanProfile = async (
  dataSourceName: string,
  dataSourceType: string,
  { onError, onSuccess }: DataSourceScanConfig = {},
) => {
  const additionalFilters = dataSourceType === 's3' ? '' : '&isSampleScan=true';
  try {
    const response = await httpService.post(
      `ds-connections/scan?scanType=dsScan${additionalFilters}&scanTemplateName=${DEFAULT_SCAN_TEMPLATE_NAME}`,
      {
        dsConnectionsNames: [dataSourceName],
      },
    );
    if (response?.status !== 200) {
      onError?.();
    }
    onSuccess?.();
  } catch (e) {
    onError?.(e);
  }
};
