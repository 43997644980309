import { module } from 'angular';
import { httpService } from '../../../app/react/services/httpService';
const app = module('app');

app.factory('policiesService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getPolicies: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/compliance-rules`).then(response => response),

      createPolicy: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/compliance-rules`, data)
          .then(response => response),

      updatePolicy: (id, data) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/compliance-rules/${id}`, data)
          .then(response => response),

      deletePolicy: id =>
        $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/compliance-rules/${id}`)
          .then(response => response),

      testPolicy: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/compliance-rules/test`, data)
          .then(response => response),

      downloadComplexAttributePolicy: policyID =>
        httpService.downloadFile(`compliance-rules/complex-attribute/file-download/${policyID}`),
    };
  },
]);
