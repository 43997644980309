import { styled } from '@mui/material';
import React, { FC } from 'react';
import { MetadataSearchVariant } from '../../../components/MetadataSearch/MetadataSearch';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { DataExplorerSearchBar } from '../../DataExplorerSearchResults/DataExplorerSearchBar';
import { useLocalTranslation } from '../translations';
import {
  DataExplorerSearchSubmitBiPayload,
  sendDataExplorerLandingPageBarSubmitBiAnalytics,
} from '../services/analytics';
import { MetadataSearchTransitionPayload } from '../../../components/MetadataSearch/MetadataSearchTypes';

const SearchBarContainer = styled('div')`
  width: 100%;
  height: 44px;
`;

type SearchBarProps = {
  dataAid?: string;
};

export const SearchBar: FC<SearchBarProps> = ({ dataAid = 'DataExplorerSearch' }) => {
  const { t } = useLocalTranslation('SearchBar');

  const isMetadataSearchEnabled = getApplicationPreference('METADATA_SEARCH_ENABLED');
  const placeholder = isMetadataSearchEnabled ? t('placeholder') : t('placeholderDisabled');

  const handleSearchBarSubmit = (payload: MetadataSearchTransitionPayload) => {
    const biPayload: DataExplorerSearchSubmitBiPayload = {
      searchText: payload.text,
    };

    sendDataExplorerLandingPageBarSubmitBiAnalytics(biPayload);
  };

  return (
    <SearchBarContainer>
      <DataExplorerSearchBar
        dataAid={dataAid}
        variant={MetadataSearchVariant.ENLARGED}
        placeholder={placeholder}
        isDisabled={!isMetadataSearchEnabled}
        onSubmit={handleSearchBarSubmit}
      />
    </SearchBarContainer>
  );
};
