import { BigidBody1, BigidEditableTextArea } from '@bigid-ui/components';
import { styled } from '@mui/system';
import React, { FC } from 'react';
import { useCardModalContext } from '../../CardModalContext';
import { useLocalTranslation } from '../../translations';

const Container = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FriendlyNameSection: FC = () => {
  const {
    state: { headerState },
    dispatchAction,
  } = useCardModalContext();

  const { t } = useLocalTranslation('Header');

  const { friendlyName } = headerState;

  const handleNameChange = (value: string) => {
    dispatchAction({
      type: 'SET_PARTIAL_HEADER_STATE',
      payload: { friendlyName: value },
    });
  };

  return (
    <Container>
      <BigidBody1 whiteSpace="nowrap">{t('friendlyName')}:</BigidBody1>
      <BigidEditableTextArea
        onSubmit={handleNameChange}
        value={friendlyName}
        isInlineEdit
        submitOnClickOutside
        isEditPencilAlwaysVisible
        showControls={false}
      />
    </Container>
  );
};
