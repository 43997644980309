import { EventEmitter } from '@bigid-ui/utils';

export enum SystemEvents {
  LOGIN = 'system.login',
  LOGOUT = 'system.logout',
  UPDATE_LICENSE = 'system.updateLicense',
  USER_CLOSED_EXPIRATION_BAR = 'userClosedExpirationBar',
  USER_CLOSED_SECRET_KEY_BAR = 'userClosedSecretKeyBar',
  NOTIFICATION = 'notification',
  SYSTEM_DIALOG = 'system.dialog',
  CLOSE_SYSTEM_DIALOG = 'close.system.dialog',
  FEEDBACK_DIALOG = 'feedback.dialog',
  IFRAME_HEARTBEAT = 'iframe.heartbeat',
  SYSTEM_ERROR = 'system.error,',
  SHOULD_SHOW_APPLICATION_BANNER = 'shouldShowApplicationExpiredBanner',
  USER_CLOSED_AUTH_SECRET_KEY_BAR = 'userClosedAuthSecretKeyBar',
  APPLICATION_PREFERENCES_UPDATED = 'applicationPreferencesUpdated',
}

export const systemEventsEmitter = new EventEmitter<SystemEvents>();
