import React, { FC, useEffect, useState, useContext, Dispatch, SetStateAction } from 'react';
import { NoJiraConfiguration, JiraConfiguration, CreateJiraTicket } from './JiraContent';
import { useLocalTranslation } from '../../../../translations';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { ActionableInsightsContext } from '../../../../hooks/ActionableInsightsContext';
import { BigidLoader } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { ModalContentProps } from '../../caseActionsService';
import { CaseModel } from '../../../../actionableInsightsService';

export enum JiraContentTypes {
  CONFIGURATION = 'configuration',
  NO_CONFIGURATION_WITHOUT_ACCESS = 'noConfigurationWithoutAccess',
  TICKET_CREATION = 'ticketCreation',
  LOADING = 'loading',
}

export interface JiraProps extends Omit<ModalContentProps, 'rowsData'> {
  updateDialogTitle: Dispatch<SetStateAction<string>>;
  rowsData: CaseModel;
}

const LoaderContainer = styled.div({
  paddingTop: 80,
  height: 200,
});

export const JiraModal: FC<JiraProps> = ({ updateDialogTitle, closeModal, rowsData }) => {
  const { jiraConfigurations } = useContext(ActionableInsightsContext);
  const [jiraContent, setJiraContent] = useState(JiraContentTypes.LOADING);
  const { t } = useLocalTranslation('Action.jira.dialogTitles');

  useEffect(() => {
    if (!isPermitted(ACTION_CENTER_PERMISSIONS.EDIT_CONFIGURATIONS.name)) {
      setJiraContent(JiraContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS);
    } else {
      if (jiraConfigurations?.length) {
        setJiraContent(JiraContentTypes.TICKET_CREATION);
      } else {
        setJiraContent(JiraContentTypes.CONFIGURATION);
      }
    }
  }, [jiraConfigurations]);

  useEffect(() => {
    updateDialogTitle(t(jiraContent));
  }, [jiraContent, t, updateDialogTitle]);

  const getJiraDialogContent = () => {
    switch (jiraContent) {
      case JiraContentTypes.CONFIGURATION:
        return <JiraConfiguration closeModal={closeModal} updateModalContent={setJiraContent} />;
      case JiraContentTypes.TICKET_CREATION:
        return <CreateJiraTicket closeModal={closeModal} updateModalContent={setJiraContent} rowData={rowsData} />;
      case JiraContentTypes.NO_CONFIGURATION_WITHOUT_ACCESS:
        return <NoJiraConfiguration />;
      default:
        return (
          <LoaderContainer>
            <BigidLoader position="relative" />
          </LoaderContainer>
        );
    }
  };

  return <>{getJiraDialogContent()}</>;
};
