import { useEffect, useState } from 'react';
import { ScopesItemResponse, TPAItemResponse } from '../types';
import { CredentialsTypesMetadata } from './../credentialsFormUtils';
import { credentialsService } from '../../../services/credentialsService';
import { useLocalTranslation } from '../translations';
import { rolesService } from '../../../services/angularServices';
import { customAppService } from '../../../services/customAppService';
import { notificationService } from '../../../services/notificationService';

interface Metadata {
  metadata: CredentialsTypesMetadata[];
  scopes: ScopesItemResponse[];
  apps: TPAItemResponse[];
}

export const useMetadata = () => {
  const [isMetadataLoading, setMetadataLoading] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<Metadata>({
    metadata: [],
    scopes: [],
    apps: [],
  });
  const { t } = useLocalTranslation();

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const [
          {
            data: { data: metadataResponse = [] },
          },
          {
            data: { scopes: scopesResponse = [] },
          },
          { data: appsResponse = [] },
        ] = await Promise.all([
          credentialsService.getCredentialsTypesMetadata(),
          rolesService.getRBACScopes(),
          customAppService.getCustomApps(),
        ]);

        setMetadata({
          metadata: metadataResponse,
          scopes: scopesResponse,
          apps: appsResponse,
        });
      } catch (error) {
        notificationService.error(t('message.commonError'));
      } finally {
        setMetadataLoading(false);
      }
    };

    fetchMetadata();
  }, [t]);

  return { ...metadata, isMetadataLoading };
};
