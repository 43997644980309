import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
  BigidBody1,
  BigidDropdownOption,
  BigidForm,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import { Content, FormWrapper } from './CredentialsStyles';
import styled from '@emotion/styled';
import { useActions } from './hooks/useActions';
import { CredentialGridRow, CredentialTypes, ForwardFormRef, ScopesItemResponse, TPAItemResponse } from './types';
import { getFormFields, getInitialValues, getPermissions } from './utils';
import { CustomCredentialApiCallDialog } from '../../components/Credentials/CustomCredentialApiCallDialog/CustomCredentialApiCallDialog';
import {
  defaultApiCallFormValue,
  VaultConnectionAPIConfiguration,
} from '../../components/Credentials/CustomCredentialApiCallDialog/customCredentailDialogUtils';
import { useLocalTranslation } from './translations';
import { CredentialsTypesMetadata } from './credentialsFormUtils';
import { generateDataAid } from '@bigid-ui/utils';
import { useRenderFields } from './hooks/useRenderFields';
import { BigidHelpIcon } from '@bigid-ui/icons';

interface CredentialsFormProps {
  row: CredentialGridRow;
  scopes: ScopesItemResponse[];
  apps: TPAItemResponse[];
  metadata: CredentialsTypesMetadata[];
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  setFormIsTouched: (isTouched: boolean) => void;
  cyberArkProviders?: BigidDropdownOption[];
}

const FieldIdentifierTitle = styled('div')`
  display: flex;
  margin-top: 18px;
  gap: 10px;
`;

export const CredentialsForm = forwardRef<ForwardFormRef, CredentialsFormProps>(
  ({ row, scopes, apps, metadata, formControls, setFormIsTouched, cyberArkProviders }, ref) => {
    const { fetchFunctionRequestParams, isPending } = row;
    const setFieldValueRef = useRef<(fieldname: string, value: any) => void>();
    const formElementRef = useRef<HTMLDivElement>();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [apiCallFormValue, setApiCallFormValue] = useState<VaultConnectionAPIConfiguration>(
      fetchFunctionRequestParams ?? defaultApiCallFormValue,
    );
    const { t } = useLocalTranslation();
    const { isEditPermitted, isCreatePermitted } = getPermissions();

    useEffect(() => {
      setFieldValueRef.current?.('fetchFunctionRequestParams', apiCallFormValue);
    }, [apiCallFormValue]);

    const { fieldsToRender, credentialType, selectedAppId } = useRenderFields({
      row,
      metadata,
    });
    const actions = useActions({
      credentialType,
      selectedAppId,
    });
    const fields = getFormFields({
      scopes,
      isEditMode: isPending,
      apps,
      actions,
      metadata,
      cyberArkProviders,
      credentialType,
    });
    const initialValues = useMemo(() => getInitialValues(fields, row), [row, fields]);

    useImperativeHandle(
      ref,
      () => ({
        fieldsToRender: fieldsToRender.map(({ name }) => name),
        formElement: formElementRef.current,
      }),
      [fieldsToRender],
    );

    const formProps: BigidFormProps = useMemo(
      () => ({
        controlButtons: false,
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        stateAndHandlersRef: formControls,
        initialValues,
        displayFormLevelError: true,
        fields: fields.filter(({ name }) => fieldsToRender.map(({ name }) => name).includes(name)),
        onChange: (values, { setFieldValue }) => {
          setFieldValueRef.current = setFieldValue;
          setTimeout(() => {
            setFormIsTouched(formControls.current?.formTouched);
          }, 0);
        },
        renderForm: ({ renderField, setValue, formLevelError, formLevelErrorDisplayed }: BigidFormRenderProps) => {
          const fieldsObject = fieldsToRender.reduce(
            (acc, field) => {
              if (field.name.includes('Identifier') || field.name.includes('usernameAsPlainText')) {
                acc.identifierFields.push(field);
              } else {
                acc.fields.push(field);
              }
              return acc;
            },
            { fields: [], identifierFields: [] },
          );

          return (
            <div>
              {fieldsObject.fields.map(({ name, additionalProps }) => renderField(name, additionalProps?.(setValue)))}
              {fieldsObject.identifierFields.length !== 0 ? (
                <FieldIdentifierTitle>
                  <BigidBody1>Map Custom Fields</BigidBody1>

                  <BigidTooltip title={'Map your custom identifier fields from your vault record'}>
                    <div>
                      <BigidHelpIcon />
                    </div>
                  </BigidTooltip>
                </FieldIdentifierTitle>
              ) : null}
              {fieldsObject.identifierFields.map(({ name, additionalProps }) =>
                renderField(name, additionalProps?.(setValue)),
              )}
              <div>{formLevelErrorDisplayed && formLevelError}</div>
            </div>
          );
        },
      }),
      [fields, fieldsToRender, formControls, initialValues, setFormIsTouched],
    );

    const isPermittedEdit = isPending ? isCreatePermitted : isEditPermitted;

    const handleOpenDialog = () => {
      setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };

    const handleSaveApiConfig = (values: VaultConnectionAPIConfiguration) => {
      setApiCallFormValue(values);
      formControls.current?.setTouched('fetchFunctionRequestParams');
    };

    return (
      <Content ref={formElementRef}>
        <FormWrapper>
          <BigidForm {...formProps} key={apps.length} />
          {credentialType === CredentialTypes.RemoteCustom && isPermittedEdit && (
            <>
              <PrimaryButton
                size="medium"
                text={t('buttons.configureApiCall')}
                dataAid={generateDataAid('CredentalsForm', ['configure-api-call'])}
                onClick={handleOpenDialog}
              />
              <CustomCredentialApiCallDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                value={{ ...apiCallFormValue }}
                onSaveApiConfig={handleSaveApiConfig}
              />
            </>
          )}
        </FormWrapper>
      </Content>
    );
  },
);

export const CredentialsFormMemo = memo(CredentialsForm);
