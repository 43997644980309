import '../react/views/DSAR/SarProfileSettings/DsarPageTitle';
import '../react/views/DSAR/SarProfileSettings/SarProfileSettingsWrapper';
import '../react/views/DSAR/SarProfileSettings/SarMainTabs';
import '../react/views/DSAR/DsarDictionary/DsarDictionary';
import '../react/views/DSAR/FilesRetrieval/FilesRetrievalLayout';
import '../react/views/DSAR/DsarReportTemplates/ReportTemplatesLayout';
import '../react/views/DSAR/DsarJointCorrelations/DsarJointCorrelations';
import '../react/views/DSAR/DsarAudit/DsarAuditing';
import '../react/views/DSAR/DsarRequestsGrid/DsarRequestsGrid';
import '../react/views/DSAR/DsarRequestsGrid/DsarDeletionValidationGrid';
import '../react/views/DsarBigidmeConfig/DsarBigidmeConfig';
import './subjectAccessRequest.component.scss';
import { module } from 'angular';
import { dateTimeService } from '@bigid-ui/i18n';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { subscribeToRepeatedSSEEventById, SSE_EVENTS } from '../react/services/sseService';
import { areSomePermitted, isPermitted } from '../react/services/userPermissionsService';
import { EVENTS } from './newSubjectAccessRequest/sarConsts.js';
import template from './subjectAccessRequest.component.html';
import { getApplicationPreference } from '../react/services/appPreferencesService';

const app = module('app');

app.component('subjectAccessRequest', {
  template,
  controller: function (
    $timeout,
    $rootScope,
    $state,
    $stateParams,
    $translate,
    $uibModal,
    notificationService,
    subjectAccessRequestService,
    DeleteConfirmation,
  ) {
    'ngInject';

    const CURRENT_PATH = $stateParams.path;
    const showDsarLegacyViews = !!getApplicationPreference('SHOW_DSAR_LEGACY_VIEWS');

    const COMPLETED_SUBJECT_REQUESTS = showDsarLegacyViews
      ? 'completed-subject-requests'
      : 'completed-dsar-requests-grid';
    const PENDING_SUBJECT_REQUESTS = showDsarLegacyViews ? 'pending-subject-requests' : 'pending-dsar-requests-grid';
    const NEW_SUBJECT_ACCESS_REQUEST = 'new-subject-access-request';
    const DELETION_VALIDATION_REQUESTS = 'deletion-validation-requests';
    const DELETION_VALIDATION_GRID = 'dsar-deletion-validation-grid';
    const DELETION_VALIDATIONS = showDsarLegacyViews ? DELETION_VALIDATION_REQUESTS : DELETION_VALIDATION_GRID;
    const DSAR_AUDIT_REQUEST = 'dsar-auditing';
    const SAR_PROFILE_SETTINGS_NAME = 'profile';
    const DSAR_ME_SETTINGS = 'dsar-me-settings';
    const JOINT_CORRELATIONS_MAPPING = 'correlations-mapping';
    const PDF_REPORT_TEMPLATES = 'report-templates';
    const FILES_RETRIEVAL = 'files-retrieval';

    const NEW_REQUEST_PATH = 'new-request';
    const PENDING_REQUEST_PATH = 'pending-requests';
    const COMPLETED_REQUEST_PATH = 'completed-requests';
    const DELETION_VALIDATION_PATH = 'deletion-validation-requests';
    const DSAR_AUDIT_PATH = 'audit';
    const SAR_PROFILE_SETTINGS_PATH = 'profile';
    const JOINT_CORRELATIONS_MAPPING_PATH = 'correlations-mapping';
    const PDF_REPORT_TEMPLATES_PATH = 'report-templates';
    const ME_SETTINGS_PATH = 'me-settings';
    const FILES_RETRIEVAL_PATH = 'files-retrieval';
    const DICTIONARY_PATH = 'dictionary';

    const DEFAULT_TAB_INDEX = 0;

    this.attributes = [];
    this.unregister = [];
    this.deletionValidationTooltip = undefined;
    this.isBigidmeAgentEnabled = getApplicationPreference('BIGIDME_AGENT_ENABLED');
    this.isShowAttributesEnrichmentPage = getApplicationPreference('SHOW_ATTRIBUTES_ENRICHMENT_PAGE');
    this.isUseDsarReportTemplates = getApplicationPreference('USE_DSAR_REPORT_TEMPLATES');
    this.isReactDsarPage = getApplicationPreference('DSAR_USE_CATALOG_COLUMNS');

    this.isTabBelongToSettingView = path =>
      [
        JOINT_CORRELATIONS_MAPPING_PATH,
        SAR_PROFILE_SETTINGS_PATH,
        PDF_REPORT_TEMPLATES_PATH,
        ME_SETTINGS_PATH,
        FILES_RETRIEVAL_PATH,
        DICTIONARY_PATH,
      ].includes(path);
    this.isSettingView = () => this.isTabBelongToSettingView(CURRENT_PATH);

    $translate('ACCESS_REQUEST_MANAGEMENT:DELETION_VALIDATION:DESCRIPTION').then(translation => {
      this.deletionValidationTooltip = translation;
    });

    this.hasManagePermission = isPermitted(DSAR_PERMISSIONS.MANAGE_DELETION_VALIDATION.name);
    this.completedStatus = 'Completed';
    this.pendingStatus = 'Started';
    this.tabs = [
      {
        title: 'New Request',
        selected: false,
        path: NEW_REQUEST_PATH,
        component: NEW_SUBJECT_ACCESS_REQUEST,
        isVisible: isPermitted(DSAR_PERMISSIONS.SUBMIT_REQUEST.name),
        isBelongToSettingsView: false,
      },
      {
        title: 'Pending Requests',
        selected: false,
        path: PENDING_REQUEST_PATH,
        component: PENDING_SUBJECT_REQUESTS,
        isVisible: isPermitted(DSAR_PERMISSIONS.READ_REQUEST.name),
        isBelongToSettingsView: false,
      },
      {
        title: 'Completed Requests',
        selected: false,
        path: COMPLETED_REQUEST_PATH,
        component: COMPLETED_SUBJECT_REQUESTS,
        isVisible: isPermitted(DSAR_PERMISSIONS.READ_REQUEST.name),
        isBelongToSettingsView: false,
      },
      {
        title: 'Deletion Validation',
        selected: false,
        path: DELETION_VALIDATION_PATH,
        component: DELETION_VALIDATIONS,
        isVisible: isPermitted(DSAR_PERMISSIONS.READ_DELETION_VALIDATION.name),
        isBelongToSettingsView: false,
      },
      {
        title: 'Audit',
        selected: false,
        path: DSAR_AUDIT_PATH,
        component: DSAR_AUDIT_REQUEST,
        isVisible: isPermitted(DSAR_PERMISSIONS.READ_AUDIT.name),
        isBelongToSettingsView: false,
      },
      {
        title: 'Profile Settings',
        selected: false,
        path: SAR_PROFILE_SETTINGS_PATH,
        component: SAR_PROFILE_SETTINGS_NAME,
        isVisible: isPermitted(DSAR_PERMISSIONS.READ_PROFILE_SETTINGS.name),
        isBelongToSettingsView: true,
      },
      {
        title: 'Mapping',
        selected: false,
        path: JOINT_CORRELATIONS_MAPPING_PATH,
        component: JOINT_CORRELATIONS_MAPPING,
        isVisible:
          this.isShowAttributesEnrichmentPage && isPermitted(DSAR_PERMISSIONS.READ_ATTRIBUTES_ENRICHMENT_FLOW.name),
        isBelongToSettingsView: true,
      },
      {
        title: 'Report Templates',
        selected: false,
        path: PDF_REPORT_TEMPLATES,
        component: PDF_REPORT_TEMPLATES_PATH,
        isVisible: this.isUseDsarReportTemplates && isPermitted(DSAR_PERMISSIONS.READ_REPORT_TEMPLATES.name),
        isBelongToSettingsView: true,
      },
      {
        title: 'Privacy Portal',
        selected: false,
        path: ME_SETTINGS_PATH,
        component: DSAR_ME_SETTINGS,
        isVisible: this.isBigidmeAgentEnabled && isPermitted(DSAR_PERMISSIONS.MANAGE_PRIVACY_PORTAL.name),
        isBelongToSettingsView: true,
      },
      {
        title: 'Files Retrieval',
        selected: false,
        path: FILES_RETRIEVAL_PATH,
        component: FILES_RETRIEVAL,
        isVisible:
          isPermitted(DSAR_PERMISSIONS.EDIT_FILES_RETRIEVAL.name) &&
          !!getApplicationPreference('DSAR_SHOW_FILES_RETRIEVAL'),
        isBelongToSettingsView: true,
      },
      {
        title: 'Dictionary',
        selected: false,
        path: DICTIONARY_PATH,
        component: DICTIONARY_PATH,
        isVisible: this.isReactDsarPage && isPermitted(DSAR_PERMISSIONS.READ_DICTIONARY.name),
        isBelongToSettingsView: true,
      },
    ]
      .filter(({ isBelongToSettingsView }) => (this.isSettingView() ? isBelongToSettingsView : !isBelongToSettingsView))
      .filter(({ isVisible }) => isVisible);

    this.getJitScans = () => {
      const { path } = $state.params;
      if (path === PENDING_REQUEST_PATH || path === COMPLETED_REQUEST_PATH) {
        const state = path === PENDING_REQUEST_PATH ? 'Started' : 'Completed';
        subjectAccessRequestService.getJit_Scans(state).then(result => {
          this.jitScanResults = result;
        });
      }
    };

    this.deleteScanItem = entity => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete Existing Data Subject Access Request',
        bodyText: `Are you sure you want to delete user data related to userID: ${entity.identity_unique_id}?`,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        subjectAccessRequestService.deleteJitScan(entity.request_id).then(
          () => {
            this.getJitScans();
            notificationService.success(`Request ${entity.identity_unique_id} Deleted Successfully! `);
          },
          () => {
            notificationService.error('Delete User Data Failed! ');
          },
        );
      });
    };

    this.$onInit = async () => {
      const targetTabIndex = this.tabs.findIndex(tab => tab.path === CURRENT_PATH);
      if (targetTabIndex > 0) {
        this.componentSelected = this.tabs[targetTabIndex].component;
        this.tabs[targetTabIndex]['selected'] = true;
      } else if (this.tabs[DEFAULT_TAB_INDEX]) {
        this.componentSelected = this.tabs[DEFAULT_TAB_INDEX].component;
        this.tabs[DEFAULT_TAB_INDEX]['selected'] = true;
      } else if (this.tabs.length > 0) {
        this.componentSelected = this.tabs[0].component;
        this.tabs[0]['selected'] = true;
      }

      if ($stateParams.userId !== null) {
        this.userQuery = `id=${$stateParams.userId}`;
      }

      this.unregister.push(
        $rootScope.$on(EVENTS.onSarBulkFileUploaded, (event, isSuccess) => {
          if (isSuccess) {
            this.goToPendingRequestTab();
          }
        }),
      );

      if (
        showDsarLegacyViews ||
        (!(this.componentSelected === COMPLETED_SUBJECT_REQUESTS) &&
          !(this.componentSelected === PENDING_SUBJECT_REQUESTS))
      ) {
        if (
          areSomePermitted([
            DSAR_PERMISSIONS.READ_PROFILE_SETTINGS.name,
            DSAR_PERMISSIONS.READ_REQUEST.name,
            DSAR_PERMISSIONS.SUBMIT_REQUEST.name,
          ])
        ) {
          subjectAccessRequestService
            .getProfiles()
            .then(({ data: { profiles } }) => {
              this.profiles = profiles;
              if (profiles.length) {
                const defaultProfile = profiles.find(profile => !profile.isCustom) || profiles[0];
                this.profileId = defaultProfile._id;
              } else {
                notificationService.warning('No profiles found for your user role.');
              }
            })
            .catch(() => notificationService.error('error getting profiles'));
        }
      }

      if (showDsarLegacyViews) {
        this.getJitScans();
        this.unregister.push(subscribeToRepeatedSSEEventById(SSE_EVENTS.SCAN_STATE_EVENT, this.getJitScans));
      }
    };

    this.$onDestroy = () => {
      this.unregister.forEach(unreg => unreg());
      this.unregister = null;
    };

    this.showTime = () => {
      this.lastUpdate = dateTimeService.formatDate(new Date());
    };

    this.updateDeletionValidationStatus = ({ is_enabled, days }) => {
      this.deletionValidationDays = days;
      setDeletionValidationStatus(is_enabled);
    };

    this.onDeletionValidationSwitchToggle = isEnabled => {
      setDeletionValidationStatus(isEnabled);

      if (!isEnabled) {
        return subjectAccessRequestService.setNewDeletionValidationStatus({ is_enabled: isEnabled }).catch(() => {
          setDeletionValidationStatus(!isEnabled);
        });
      }

      openDeletionValidationConfigurationDialog()
        .then(userSelection => {
          const days = userSelection ? userSelection.days : this.deletionValidationDays;

          const configuration = {
            is_enabled: true,
            days,
          };

          this.deletionValidationDays = days;

          setDeletionValidationStatus(true);
          return subjectAccessRequestService.setNewDeletionValidationStatus(configuration);
        })
        .catch(() => {
          // handle dialog $dismiss (cancel) action
          setDeletionValidationStatus(false);
        });
    };

    const openDeletionValidationConfigurationDialog = () => {
      return $uibModal.open({
        template:
          '<deletion-validation-modal-component days="$ctrl.deletionValidationDays" $close="$close(userSelection)" $dismiss="$dismiss()"></deletion-validation-modal-component>',
        windowClass: 'deletion-validation-modal',
        controllerAs: '$ctrl',
        controller: [
          'days',
          function (days) {
            this.deletionValidationDays = days;
          },
        ],
        resolve: {
          days: () => this.deletionValidationDays,
        },
      }).result;
    };

    const setDeletionValidationStatus = isEnabled => {
      this.isDeletionValidationActive = isEnabled;
      this.deletionValidationStatus = isEnabled ? 'Active' : 'Disabled';
    };

    this.changeTab = index => {
      const { path } = this.tabs[index];
      const transitionToState =
        'subjectAccessRequest' +
        ([
          SAR_PROFILE_SETTINGS_PATH,
          JOINT_CORRELATIONS_MAPPING_PATH,
          PDF_REPORT_TEMPLATES_PATH,
          FILES_RETRIEVAL_PATH,
          DICTIONARY_PATH,
        ].includes(path)
          ? `.${path}`
          : '');
      $state.transitionTo(transitionToState, { path }, { reload: false, notify: false });
    };

    this.submit = jitScanData => {
      subjectAccessRequestService.launchJITScan(jitScanData).then(() => {
        this.finishScan = true; // no-unused-vars candidate
      }),
        () => {
          notificationService.error('An error has occurred!'); // very informative!
        };

      this.goToPendingRequestTab();
    };

    this.goToPendingRequestTab = () => {
      $timeout(() => {
        const index = this.tabs.findIndex(tab => tab.component === PENDING_SUBJECT_REQUESTS);
        if (index !== -1) {
          this.changeTab(index);
        }
      }, 1000);
    };

    this.unselectedTab = () => {
      this.tabs.forEach(tab => {
        tab['selected'] = false;
      });
    };
  },
});
