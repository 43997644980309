import { BigidCheckbox } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC, ChangeEvent } from 'react';
import { useDataCatalogSearchResultsContext } from '../../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../../translations';
import { SortingControl } from './SortingControl';
import {
  DataExplorerSelectAllToggleBiPayload,
  sendDataExplorerResultsPageSelectAllBiAnalytics,
} from '../../../DataExplorer/services/analytics';

interface ControlsProps {
  isIntermediate: boolean;
}

const ControlsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 12px 38px;
`;

export const Controls: FC<ControlsProps> = ({ isIntermediate }) => {
  const { t } = useLocalTranslation('gridControls');
  const { state, dispatchCatalogSearchAction } = useDataCatalogSearchResultsContext();
  const {
    gridConfig: { isSelectAll, rows },
  } = state;
  const handleCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatchCatalogSearchAction({ type: 'TOGGLE_SELECT_ALL' });

    const biPayload: DataExplorerSelectAllToggleBiPayload = {
      isSelectAllToggled: checked,
    };

    sendDataExplorerResultsPageSelectAllBiAnalytics(biPayload);
  };

  return (
    <ControlsContainer>
      <BigidCheckbox
        dataAid="selectAllCheckbox"
        size="medium"
        indeterminate={isIntermediate}
        checked={isSelectAll}
        onChange={handleCheckboxChange}
        label={t('selectAll')}
        disabled={!rows?.length}
      />
      <SortingControl />
    </ControlsContainer>
  );
};
