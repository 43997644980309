import { BigidSideFilterSectionBase, ToolbarAction, ToolbarActionType } from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridProps, FetchDataFunction } from '@bigid-ui/grid';
import { BigidAttributeIcon, BigidTermIcon } from '@bigid-ui/icons';
import { BigidLayoutConfig, LayoutContentType, LayoutSideFilterProps } from '@bigid-ui/layout';
import {
  fetchAssetTypes,
  fetchAttributes,
  fetchAttributeTypes,
  fetchBusinessTerms,
  fetchCategories,
  fetchDataSources,
  fetchTags,
} from '../BusinessGlossaryService';
import { getFixedT } from '../translations';
import { BusinessGlossaryRecord, GetLayoutConfigProps } from '../types';
import { generateFakeGridData } from '../utils';

const t = getFixedT('Grid');

const sideFilterConfig: LayoutSideFilterProps = {
  showClearAll: true,
  isSideFilterOpen: true,
  getSectionsConfig: async (): Promise<BigidSideFilterSectionBase[]> => {
    try {
      const sectionsConfig = await Promise.allSettled([
        fetchAssetTypes(),
        fetchAttributes(),
        fetchBusinessTerms(),
        fetchTags(),
        fetchCategories(),
        fetchAttributeTypes(),
        fetchDataSources(),
      ]).then(results => {
        return results.map(result => {
          if (result.status === 'fulfilled') {
            return result.value;
          }
          return null;
        });
      });

      return sectionsConfig.filter(Boolean).flat();
    } catch (error) {
      console.error(error);
    }

    return [];
  },
};

const gridConfig: BigidGridProps<BusinessGlossaryRecord> = {
  showSortingControls: false,
  showSelectionColumn: false,
  columns: [
    {
      getCellValue: ({ businessObjectType }) => {
        const icon = businessObjectType === 'attribute' ? BigidAttributeIcon : BigidTermIcon;

        return {
          icon: {
            icon,
          },
        };
      },
      title: t('columns.businessObject'),
      name: 'businessObjectType',
      type: BigidGridColumnTypes.ICON,
      width: 140,
    },
    {
      getCellValue: row => row.objectName,
      title: t('columns.objectName'),
      name: 'objectName',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.friendlyName,
      title: t('columns.friendlyName'),
      name: 'friendlyName',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: ({ categories }) => {
        return categories.map(category => category).join(', ');
      },
      title: t('columns.categories'),
      name: 'categories',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.domain,
      title: t('columns.domain'),
      name: 'domain',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.definition,
      title: t('columns.definition'),
      name: 'definition',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.type,
      title: t('columns.type'),
      name: 'type',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      getCellValue: row => row.description,
      title: t('columns.description'),
      name: 'description',
      type: BigidGridColumnTypes.TEXT,
    },
    {
      title: t('columns.tags'),
      name: 'tags',
      type: BigidGridColumnTypes.TAGS,
      getCellValue: ({ manualTags }) => ({
        tags: {
          ...manualTags.tags,
          value: manualTags.tags.value.map(tagDetails => ({
            ...tagDetails,
            name: tagDetails.name,
          })),
        },
      }),
    },
  ],
};

const getToolbarActions = ({
  createNewDropdownClassName,
  onCreateAttributeClick,
  onCreateTermClick,
}: GetLayoutConfigProps): ToolbarAction[] => {
  return [
    {
      label: t('toolbar.actions.import'),
      type: ToolbarActionType.SECONDARY,
      isGlobal: true,
      show: () => true,
      execute: async () => {
        console.log('Import');
        return {};
      },
    },
    {
      label: t('toolbar.actions.createNew'),
      type: ToolbarActionType.DROPDOWN,
      dropdownProps: {
        className: createNewDropdownClassName,
        clearOnSelect: true,
        placeholder: 'Create New',
        options: [
          {
            label: t('toolbar.actions.attribute'),
            value: 'attribute',
            show: () => true,
            execute: async () => {
              onCreateAttributeClick();
              return {};
            },
          },
          {
            label: t('toolbar.actions.businessTerm'),
            value: 'businessTerm',
            show: () => true,
            execute: async () => {
              onCreateTermClick();
              return {};
            },
          },
        ],
      },
      show: () => true,
    },
  ];
};

const fetchGridData: FetchDataFunction<BusinessGlossaryRecord> = async () => {
  const data = generateFakeGridData(20);
  return {
    data,
    totalCount: 20,
  };
};

export const getFreeLayoutConfig = ({
  createNewDropdownClassName,
  onCreateAttributeClick,
  onCreateTermClick,
}: GetLayoutConfigProps): BigidLayoutConfig => ({
  filter: {
    sideFilter: sideFilterConfig,
    search: {
      isQueryLanguage: false,
      onSearchQueryChange: searchQuery => {
        console.log('searchQuery', searchQuery);
      },
    },
  },
  content: {
    entityName: t('toolbar.entityName'),
    contentTypes: [LayoutContentType.GRID],
    viewConfig: {
      fetchGridData,
      gridConfig,
      filterToolbarConfig: {
        searchConfig: {
          operator: 'contains',
          placeholder: 'Search',
          searchFilterKeys: ['businessObjectType'],
          autoSubmit: false,
        },
      },
    },

    toolbarActions: getToolbarActions({ createNewDropdownClassName, onCreateTermClick, onCreateAttributeClick }),
  },
});
