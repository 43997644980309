import { useCallback, useRef } from 'react';
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { BigidFormRenderProps } from '@bigid-ui/components';
import { httpService } from '../../../../services/httpService';
import { notificationService } from '../../../../services/notificationService';
import { $state, scopesService } from '../../../../services/angularServices';
import { sessionStorageService } from '../../../../../common/services/sessionStorageService';
import { isPermitted } from '../../../../services/userPermissionsService';
import { CONFIG } from '../../../../../config/common';
import { DataSourceConfigurationState, UpdateDataSourceConfigState } from './useDataSourceConfigState';
import { getAvailableScopes } from './useScopeOptions';
import { RefreshDataSourceDataType } from './useGetDataSourceByName';
import { trackCreateNewDataSourceBiEvent } from '../../DataSourcesEventTrackerUtils';
import { prepareDataSourceFormValueDataForSend } from '../utils/prepareDataSourceFormValueDataForSend';

export type OnSubmitDataSourceType = (redirectToEditPage?: boolean, saveAsDraft?: boolean) => Promise<boolean>;

export const updateScopeIfNeeded = async (dataSourceData: Record<string, any>, newScope?: Record<string, any>[]) => {
  if (!newScope || !isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name)) return dataSourceData;

  const { scopes } = await getAvailableScopes();
  const initialScope = scopes.filter(({ dataSourceNames }) => dataSourceNames.includes(dataSourceData.name));
  const scopesForDeleteDataSource = initialScope?.filter(({ name }) => !newScope.find(item => item.value === name));
  const scopesForAddDataSource = newScope?.filter(({ value }) => !initialScope.find(({ name }) => name === value));

  await Promise.all([
    ...scopesForDeleteDataSource?.map(scopeForUpdate =>
      scopesService.updateScope(scopeForUpdate?.id, {
        ...scopeForUpdate,
        dataSourceNames: scopeForUpdate?.dataSourceNames.filter(dsName => dsName !== dataSourceData.name),
      }),
    ),
    ...scopesForAddDataSource?.map(({ value }) => {
      const scopeForUpdate = scopes.find(({ name }) => name === value);
      return scopeForUpdate
        ? scopesService.updateScope(scopeForUpdate?.id, {
            ...scopeForUpdate,
            dataSourceNames: [...scopeForUpdate.dataSourceNames, dataSourceData.name],
          })
        : Promise.resolve();
    }),
  ]).catch(e => {
    console.error('Error when update scope', e);
  });

  return dataSourceData;
};

interface UseSubmitDataSourceProps extends Partial<DataSourceConfigurationState> {
  updateState: UpdateDataSourceConfigState;
  getFieldPropsFunction: React.MutableRefObject<BigidFormRenderProps['getFieldProps']>;
  refreshData: RefreshDataSourceDataType;
  shouldTriggerNotification?: boolean;
}

export const useSubmitDataSource = ({
  fields,
  id,
  updateState,
  getFieldPropsFunction,
  refreshData,
  shouldTriggerNotification = true,
}: UseSubmitDataSourceProps) => {
  const validateAndSubmitRef = useRef<(onSubmitCallback: any, onErrorCallback?: any) => Promise<any>>();
  const onSubmitHandler = useCallback<OnSubmitDataSourceType>(
    (redirect = true, saveAsDraft = false) => {
      const isScopeChanged = getFieldPropsFunction?.current('scope')?.touched;
      updateState({ saveError: '' });
      return new Promise(
        resolve =>
          validateAndSubmitRef.current instanceof Function &&
          validateAndSubmitRef.current(
            (values: Record<string, any>, redirectToUpdatePage = redirect) => {
              const newScope = isScopeChanged ? values?.scope : undefined;
              const handleSaveError = ({ response }: { response: { data?: Record<string, string> } }) => {
                const message = `Saved Failed! ${response?.data?.message || ''}`;
                shouldTriggerNotification && notificationService.error(message);
                updateState({ saveError: message });
                resolve(false);
              };
              updateState(currentState => {
                const dataForSend = prepareDataSourceFormValueDataForSend({
                  fields,
                  values,
                  getFieldPropsFunction: getFieldPropsFunction.current,
                  isTest: false,
                  isNewPassword: currentState?.isNewPassword,
                  encryptFields: currentState?.encryptFields,
                });

                if (id && values['name']) {
                  //TODO async/await
                  httpService
                    .put(`ds_connections/${id}`, {
                      ds_connection: dataForSend,
                      isSavedAsDraft: saveAsDraft,
                    })
                    .then(() => updateScopeIfNeeded(dataForSend, newScope))
                    .then(() => {
                      if (id !== values['name'] && redirectToUpdatePage) {
                        $state
                          .go(
                            CONFIG.states.CONFIG_DATA_SOURCE,
                            { id: values['name'] },
                            {
                              location: 'replace',
                            },
                          )
                          .then(() => {
                            const history: History[] = sessionStorageService.get('history') || [];
                            sessionStorageService.set('history', history.slice(0, -1));
                            shouldTriggerNotification &&
                              notificationService.success(`Updated ${values['name']} Successfully!`);
                          });
                      } else {
                        shouldTriggerNotification && notificationService.success(`Updated ${id} Successfully!`);
                        refreshData();
                      }
                      resolve(true);
                    })
                    .catch(handleSaveError)
                    .finally(() => {
                      updateState({ isSaveInProgress: false });
                    });
                } else {
                  httpService
                    .post('ds_connections', {
                      ds_connection: {
                        ...dataForSend,
                        notAddToScope: !!values?.scope?.length,
                      },
                      isSavedAsDraft: saveAsDraft,
                    })
                    .then(({ data }) => (values?.scope?.length ? updateScopeIfNeeded(data, newScope) : data))
                    .then(data => {
                      updateState({ id: data?.name, isNewPassword: false, saveError: '' });
                      if (redirectToUpdatePage) {
                        $state.go(CONFIG.states.CONFIG_DATA_SOURCE, { id: data?.name, isAfterSave: true });
                      }
                      shouldTriggerNotification &&
                        notificationService.success('Saved Successfully! ', { shouldCloseOnTransition: false });
                      dataForSend['creationSuccess'] = true;
                      resolve(true);
                    })
                    .catch(handleSaveError)
                    .finally(() => {
                      updateState({ isSaveInProgress: false });
                      trackCreateNewDataSourceBiEvent(dataForSend);
                    });
                }

                return { isSaveInProgress: true };
              });
            },
            (error: string) => {
              updateState({ saveError: error });
              resolve(false);
            },
          ),
      );
    },
    [fields, getFieldPropsFunction, id, refreshData, shouldTriggerNotification, updateState],
  );

  return { onSubmitHandler, validateAndSubmitRef };
};
