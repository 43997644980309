import { BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import { styled } from '@mui/system';
import React from 'react';
import { useCardModalContext } from '../../CardModalContext';
import { AttributeInfoTab } from './AttributeInfoTab/AttributeInfoTab';
import { BusinessTermsTab } from './BusinessTermsTab/BusinessTermsTab';
import { ITAssetsTab } from './ItAssetsTab';

const RootContainer = styled('section')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex-grow: 1;
`;

const TabContentContainer = styled('div')`
  display: flex;
  padding-top: 16px;
  flex-grow: 1;
`;

enum TabIdEnum {
  AttributeInfo = 'AttributeInfo',
  BusinessTerms = 'BusinessTerms',
  ITAssets = 'ITAssets',
  Lineage = 'Lineage',
}

const tabContents = [AttributeInfoTab, BusinessTermsTab, ITAssetsTab];

export const Tabs = () => {
  const {
    state: { tabs, type },
  } = useCardModalContext();

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

  const handleTabChange = (tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  };

  const getTabsConfig = (): BigidTabsItem[] => {
    return [
      {
        label: 'Attribute Info',
        id: TabIdEnum.AttributeInfo,
      },
      {
        label: 'Business Terms',
        id: TabIdEnum.BusinessTerms,
      },
      {
        label: 'IT Assets',
        id: TabIdEnum.ITAssets,
      },
      {
        label: 'Lineage',
        id: TabIdEnum.Lineage,
        disabled: true,
      },
    ];
  };

  const TabContent = tabContents[selectedTabIndex];

  return (
    <RootContainer>
      <div>
        <BigidTabs
          selectedIndex={selectedTabIndex}
          onChange={handleTabChange}
          orientation="horizontal"
          variant="standard"
          tabs={getTabsConfig()}
        />
      </div>

      <TabContentContainer>
        <TabContent />
      </TabContentContainer>
    </RootContainer>
  );
};
