import { BigidBody1, BigidButtonIcon, PrimaryButton } from '@bigid-ui/components';
import { BigidViewIcon, BigidLikeIcon, BigidMoreActionIcon, BigidXIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { useState } from 'react';
import { useCardModalContext } from '../../CardModalContext';
import { useLocalTranslation } from '../../translations';

const CounterContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const SectionContainer = styled('section')`
  display: flex;
  gap: 24px;
`;

export const ControlsSection = () => {
  const { t } = useLocalTranslation('Header');

  const {
    state: { headerState, isNewCard },
    dispatchAction,
    callbacks,
  } = useCardModalContext();

  const { onClose } = callbacks;

  const [isLiked, setIsLiked] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);

  const handleLikeClick = () => {
    console.log('like');
    setIsLiked(prevState => !prevState);

    if (!isLiked) {
      // TODO: remove once we have a real API
      dispatchAction({
        type: 'SET_PARTIAL_HEADER_STATE',
        payload: { likesCount: headerState.likesCount + 1 },
      });
    } else {
      // TODO: remove once we have a real API
      dispatchAction({
        type: 'SET_PARTIAL_HEADER_STATE',
        payload: { likesCount: headerState.likesCount - 1 },
      });
    }
  };

  const handleFollowClick = () => {
    console.log('follow');
    setIsFollowed(prevState => !prevState);
    if (!isFollowed) {
      // TODO: remove once we have a real API
      dispatchAction({
        type: 'SET_PARTIAL_HEADER_STATE',
        payload: { followersCount: headerState.followersCount + 1 },
      });
    } else {
      // TODO: remove once we have a real API
      dispatchAction({
        type: 'SET_PARTIAL_HEADER_STATE',
        payload: { followersCount: headerState.followersCount - 1 },
      });
    }
  };

  const handleSave = () => {
    console.log('save');
  };

  const handleMoreActions = () => {
    console.log('BigidMoreActionIcon');
  };

  const SocialControls = () => (
    <>
      <CounterContainer>
        <BigidButtonIcon icon={BigidViewIcon} onClick={handleFollowClick} variant="secondary" />
        <BigidBody1>
          {headerState.followersCount} {t('followers')}
        </BigidBody1>
      </CounterContainer>
      <CounterContainer>
        <BigidButtonIcon icon={BigidLikeIcon} onClick={handleLikeClick} variant="secondary" />
        <BigidBody1>
          {headerState.likesCount} {t('likes')}
        </BigidBody1>
      </CounterContainer>
    </>
  );

  return (
    <SectionContainer>
      {!isNewCard && <SocialControls />}
      <CounterContainer>
        {!isNewCard ? (
          <BigidButtonIcon icon={BigidMoreActionIcon} onClick={handleMoreActions} />
        ) : (
          <PrimaryButton onClick={handleSave} size="medium" text={t('save')} />
        )}
        <BigidButtonIcon icon={BigidXIcon} onClick={onClose} />
      </CounterContainer>
    </SectionContainer>
  );
};
