import React, { ComponentType, useEffect, useRef, useState } from 'react';
import {
  RouteLeavingContent,
  useRouteLeavingListener,
} from '../../components/RouteLeaving/hooks/useRouteLeavingListener';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import makeStyles from '@mui/styles/makeStyles';
import { AppInfo } from './utils/CustomAppTypes';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';

const useStyles = makeStyles({
  button: {
    paddingRight: '4px',
  },
});

const ROUTE_LEAVING_PROMPT_TEXTS: RouteLeavingContent = {
  header: 'Unsaved Changes',
  body: 'Are you sure you want to leave this page?',
};

export const reloadHomePage = (appInfo: AppInfo) =>
  $state.go(CONFIG.states.CUSTOM_APP, { id: appInfo.id, appInfo }, { reload: true });

export const withApplicationForm = (
  WrappedComponent: ComponentType<any>,
  formTitle: string,
  appInfo: AppInfo,
  props?: Record<string, any>,
) => {
  const ApplicationForm = () => {
    const [isSavingForm, setIsSavingForm] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isValueChanged, setIsValueChanged] = useState<boolean>(false);
    const onSaveMutableRef = useRef(null);
    const onCancelMutableRef = useRef(null);
    const classes = useStyles();
    const { friendlyName, name } = appInfo;
    const { updateIsRouteLeavingEnabled, updateRouteLeavingPrompt } = useRouteLeavingListener();

    useEffect(() => {
      updateRouteLeavingPrompt(ROUTE_LEAVING_PROMPT_TEXTS);
    }, [updateRouteLeavingPrompt]);

    useEffect(() => {
      updateIsRouteLeavingEnabled(isValueChanged && !isSavingForm);
    }, [isValueChanged, isSavingForm, updateIsRouteLeavingEnabled]);

    const onSave = () => {
      setIsSavingForm(true);
      onSaveMutableRef.current();
    };

    const onCancel = () => {
      onCancelMutableRef.current === null ? reloadHomePage(appInfo) : onCancelMutableRef.current();
    };

    const formButtons = (
      <>
        <span className={classes.button}>
          <SecondaryButton onClick={onCancel} size="medium" text="Cancel" />
        </span>
        <PrimaryButton
          onClick={onSave}
          size="medium"
          disabled={!isFormValid || !isValueChanged}
          dataAid={'form-save-button'}
          text="Save"
        />
      </>
    );

    useEffect(() => {
      pageHeaderService.setTitle({
        breadcrumbs: [
          {
            label: friendlyName || name,
            onClick: () => reloadHomePage(appInfo),
          },
          {
            label: formTitle,
          },
        ],
        rightSideComponentsContainer: formButtons,
      });
    }, [classes.button, formButtons, friendlyName, isFormValid, isValueChanged, name]);

    return (
      <WrappedComponent
        setIsFormValid={setIsFormValid}
        setIsValueChanged={setIsValueChanged}
        onSaveMutableRef={onSaveMutableRef}
        onCancelMutableRef={onCancelMutableRef}
        appInfo={appInfo}
        {...props}
      />
    );
  };
  return <ApplicationForm />;
};
