import React, { FC, useMemo, useContext, useState } from 'react';
import { BigidExternalLinkIcon, BigidFileIcon, BigidActionIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import {
  BigidHeading4,
  BigidButtonIcon,
  ToolbarActionType,
  ToolbarAction,
  BigidFilterType,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import {
  fetchCaseAffectedObjects,
  CaseReportAffectedObjects,
  adjustAffectedObjectsDataToGrid,
  fetchCountForObjects,
} from '../caseReportService';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { ActionableInsightsContext } from '../../../hooks/ActionableInsightsContext';
import { useLocalTranslation } from '../../../translations';
import { SensitivityLevelIndicator } from '../CaseReportComponents/SensitivityLevelIndicator';
import { HeaderIcon } from '../CaseReportComponents/caseReportStyles';
import { CaseSidePanelViewsIds } from '../../CaseSidePanelViews';
import { ActionsListProps, ReducerActions } from '../../hooks/CaseSidePanelReducer';
import { CaseActionsModal, CaseActionSubTypes } from './CaseActionWidget/CaseActionsModal';
import {
  ACTION_COMPLETED,
  REVOKE_EXTERNAL_ACCESS,
  ACTION_IN_PROGRESS,
  REVOKE_OPEN_ACCESS,
  SEND_TO_CORTEX,
  CaseActionCommands,
} from './CaseActionWidget/CaseActionsWidget';
import {
  trackActionableInsightsEvent,
  ActionableInsightsTrackingEvents,
} from '../../../actionableInsightsTrackingUtil';

const MainContainer = styled.div({
  padding: '24px 0',
  minHeight: '240px',
});

const HeaderTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '24px 0',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SensitivityIndicatorWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export interface SensitivityCellProps {
  sensitivity: string;
  priority: number;
}

const isOperatorInQuery = (op: string | any[]) => {
  return op != null && op.length !== 0;
};

const objectsGridFilter: BigidFilterType[] = [
  {
    title: 'Sensitivity',
    field: 'sensitivity',
    operator: 'in',
    value: [],
    single: true,
    disabled: true,
    options: [
      {
        label: 'Restricted',
        value: 'Restricted',
        isSelected: false,
      },
      {
        label: 'Confidential',
        value: 'Confidential',
        isSelected: false,
      },
      {
        label: 'Internal Use',
        value: 'Internal Use',
        isSelected: false,
      },
      {
        label: 'Public',
        value: 'Public',
        isSelected: false,
      },
    ],
  },
];

export const SensitivityCell: FC<SensitivityCellProps> = ({ sensitivity, priority }) => {
  return (
    <>
      <SensitivityIndicatorWrapper>
        <SensitivityLevelIndicator priority={priority} size={16} />
        {sensitivity}
      </SensitivityIndicatorWrapper>
    </>
  );
};

export const AffectedObjectsWidget: FC = () => {
  const { caseSidePanelData, actionsList, dispatch, selectedObjects, enableActionsOnCaseLevel } =
    useContext(CaseSidePanelContext);
  const { scMapping } = useContext(ActionableInsightsContext);
  const { t } = useLocalTranslation('CaseReport');
  const { dataSourceName, policyName, numberOfAffectedObjects } = caseSidePanelData;
  const [recentCount, setRecentCount] = useState<number>(0);

  const url = $state.href(CONFIG.states.CATALOG, {
    filter: `system IN ("${dataSourceName}") AND policy IN ("${policyName}")`,
  });

  const columns = useMemo<BigidGridColumn<CaseReportAffectedObjects>[]>(
    () => [
      {
        name: 'objectName',
        title: t('affectedObjectsFields.name'),
        getCellValue: row => row.name,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: false,
        sortingEnabled: true,
      },
      {
        name: 'path',
        title: t('affectedObjectsFields.path'),
        getCellValue: row => row.path,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: false,
      },
      {
        name: 'type',
        title: t('affectedObjectsFields.type'),
        getCellValue: row => row.type,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: false,
      },
      {
        name: 'sensitivity',
        title: t('affectedObjectsFields.sensitivity'),
        getCellValue: row => (
          <SensitivityCell sensitivity={row.sensitivity} priority={scMapping.get(row.sensitivity)} />
        ),
        type: BigidGridColumnTypes.CUSTOM,
        width: 120,
        disableTooltip: false,
      },
      {
        name: 'owner',
        title: t('affectedObjectsFields.owner'),
        getCellValue: row => row.owner,
        type: BigidGridColumnTypes.TEXT,
        width: 100,
        disableTooltip: false,
        sortingEnabled: true,
      },
      {
        name: 'attributes',
        title: t('affectedObjectsFields.attributes'),
        type: BigidGridColumnTypes.CHIPS,
        getCellValue: ({ attributes }) => attributes,
        width: 200,
        disableTooltip: false,
      },
      {
        name: 'tags',
        title: t('affectedObjectsFields.tags'),
        getCellValue: row => row.tags,
        type: BigidGridColumnTypes.TAGS,
        width: 200,
        disableTooltip: false,
      },
    ],
    [scMapping, t],
  );

  const actions = useMemo<ToolbarAction[]>(
    () => [
      {
        label: 'Actions',
        isGlobal: false,
        type: ToolbarActionType.DROPDOWN,
        dropdownProps: {
          placeholder: 'Actions',
          options: [
            {
              label: REVOKE_OPEN_ACCESS,
              value: REVOKE_OPEN_ACCESS,
              icon: BigidActionIcon,
              isDisabled:
                actionsList[REVOKE_OPEN_ACCESS]?.status === ACTION_IN_PROGRESS ||
                (actionsList[REVOKE_OPEN_ACCESS]?.status === ACTION_COMPLETED &&
                  actionsList[REVOKE_OPEN_ACCESS]?.isBlocked),
              show: ({ selectedRowIds }) =>
                selectedRowIds?.length > 0 && actionsList.hasOwnProperty(REVOKE_OPEN_ACCESS),
              execute: async ({ selectedRowIds }) => {
                await CaseActionsModal(
                  caseSidePanelData,
                  CaseActionSubTypes.REVOKE_OPEN_ACCESS,
                  false,
                  selectedRowIds as string[],
                  dispatch,
                  selectedObjects,
                );
                return {};
              },
            },
            {
              label: REVOKE_EXTERNAL_ACCESS,
              value: REVOKE_EXTERNAL_ACCESS,
              icon: BigidActionIcon,
              isDisabled:
                actionsList[REVOKE_EXTERNAL_ACCESS]?.status === ACTION_IN_PROGRESS ||
                (actionsList[REVOKE_EXTERNAL_ACCESS]?.status === ACTION_COMPLETED &&
                  actionsList[REVOKE_EXTERNAL_ACCESS]?.isBlocked),
              show: ({ selectedRowIds }) =>
                selectedRowIds?.length > 0 && actionsList.hasOwnProperty(REVOKE_EXTERNAL_ACCESS),
              execute: async ({ selectedRowIds }) => {
                await CaseActionsModal(
                  caseSidePanelData,
                  CaseActionSubTypes.REVOKE_EXTERNAL_ACCESS,
                  false,
                  selectedRowIds as string[],
                  dispatch,
                  selectedObjects,
                );
                return {};
              },
            },
            {
              label: SEND_TO_CORTEX,
              value: SEND_TO_CORTEX,
              icon: BigidActionIcon,
              isDisabled:
                actionsList[CaseActionCommands.SEND_TO_CORTEX as keyof ActionsListProps]?.status === ACTION_IN_PROGRESS,
              show: ({ selectedRowIds }) =>
                selectedRowIds?.length > 0 &&
                actionsList.hasOwnProperty(CaseActionCommands.SEND_TO_CORTEX as keyof ActionsListProps),
              execute: async ({ selectedRowIds }) => {
                await CaseActionsModal(
                  caseSidePanelData,
                  CaseActionSubTypes.SEND_TO_CORTEX,
                  false,
                  selectedRowIds as string[],
                  dispatch,
                  selectedObjects,
                );
                return {};
              },
            },
          ],
          clearOnSelect: true,
        },
        execute: async () => {
          return {};
        },
        disable: () => {
          return false;
        },
        show: () => true,
      },
    ],
    [actionsList, caseSidePanelData, dispatch, selectedObjects],
  );

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<CaseReportAffectedObjects>>(
    () => ({
      onGridStateChange: ({ selectedRowIds }) =>
        dispatch({
          type: ReducerActions.UPDATE_ENABLE_CASE_LEVEL_ACTIONS,
          payload: { enableActionsOnCaseLevel: selectedRowIds.length === 0 },
        }),
      pageSize: 5,
      pagingMode: true,
      hideColumnChooser: true,
      rowClickShouldKeepSelection: true,
      showSortingControls: true,
      displayActionToolbar: true,
      showSelectAll: false,
      showSelectionCheckboxes: false,
      toolbarActions: actions,
      onRowClick: row => {
        trackActionableInsightsEvent(ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_OBJECT_PREVIEW);
        dispatch({
          type: ReducerActions.UPDATE_SELECTED_OBJECT,
          payload: { objectFullyQualifiedName: row.fqn, objectSensitivity: row.sensitivity },
        });
        dispatch({
          type: ReducerActions.UPDATE_ACTIVE_VIEW_ID,
          payload: { activeViewId: CaseSidePanelViewsIds.OBJECT_PREVIEW },
        });
      },
      columns,
      filterToolbarConfig: {
        filters: objectsGridFilter,
      },
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        try {
          //Delete this section if request speed will changed for big amount of data
          // const requireTotalCount =
          //   isOperatorInQuery(queryComponents.filter) || !isOperatorInQuery(queryComponents.sort);
          const requireTotalCount = false;
          const { count } = (await fetchCountForObjects({ dataSourceName, policyName, queryComponents })) || {};
          const { data } = await fetchCaseAffectedObjects({
            dataSourceName,
            policyName,
            queryComponents,
            requireTotalCount,
          });

          let countResult = count;
          if (!isOperatorInQuery(queryComponents.filter)) {
            if (!isOperatorInQuery(queryComponents.sort)) {
              setRecentCount(count);
            } else {
              countResult = recentCount;
            }
          }

          dispatch({
            type: ReducerActions.UPDATE_SELECTED_ITEMS,
            payload: { selectedObjects: adjustAffectedObjectsDataToGrid(data) },
          });

          return {
            data: adjustAffectedObjectsDataToGrid(data),
            totalCount: countResult,
          };
        } catch ({ message }) {
          if (message) {
            console.error(`An error has occurred: ${message}`);
          }
        }
      },
    }),
    [actions, columns, dataSourceName, dispatch, policyName, recentCount],
  );

  return (
    <MainContainer>
      <Header>
        <HeaderTitle>
          <HeaderIcon>
            <BigidFileIcon />
          </HeaderIcon>
          <BigidHeading4 paddingLeft={'6px'} fontWeight={700}>
            {t('affectedObjectsFields.title')}
          </BigidHeading4>
          {/* Uncomment than objects amount problem will be fixed
          <BigidHeading4 paddingLeft={0.5} color={BigidColorsV2.purple[400]} fontWeight={700}>
            {formatNumberCompact(caseSidePanelData?.numberOfAffectedObjects, 1)}
          </BigidHeading4> */}
        </HeaderTitle>
        <BigidButtonIcon
          icon={BigidExternalLinkIcon}
          onClick={() => {
            window.open(url, '_blank');
          }}
          dataAid={generateDataAid('DirectToDataCatalog', ['button'])}
        />
      </Header>
      <BigidGridWithToolbar {...gridWithToolbarConfig} />
    </MainContainer>
  );
};
