import {
  BigidAttribute2Icon,
  BigidClassificationIcon,
  BigidClassificationMdIcon,
  BigidCorrelationIcon,
} from '@bigid-ui/icons';
import { ClassifierType, CuratedAttributeType } from '../curationService';

export const getAttributeTypeAvatar = (attributeType: CuratedAttributeType | ClassifierType) => {
  switch (attributeType) {
    case CuratedAttributeType.CLASSIFIER:
      return { icon: BigidClassificationIcon, name: CuratedAttributeType.CLASSIFIER };
    case ClassifierType.MD:
      return { icon: BigidClassificationMdIcon, name: CuratedAttributeType.CLASSIFICATION_MD };
    case CuratedAttributeType.CORRELATION:
      return { icon: BigidCorrelationIcon, name: CuratedAttributeType.CORRELATION };
    case ClassifierType.DOC:
      return { icon: BigidAttribute2Icon, name: CuratedAttributeType.DOC_CLASSIFICATION };
    default:
      return { icon: BigidAttribute2Icon, name: 'Attribute' };
  }
};
