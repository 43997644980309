import React from 'react';
import { useFetch, BigidGridProps, BigidGridColumnTypes } from '@bigid-ui/grid';
import {
  BigidDropdownOption,
  BigidFieldRenderProps,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValidateOnTypes,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { mapUserRoleToBigidDropdownOption, mapUserToBigidDropdownOption } from '../mappers/collaboration';
import { DataSourceConnectionCollaborationTextareaField } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationTextareaField';
import { DataSourceConnectionCollaborationEmailField } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationEmailField';
import { DataSourceConnectionCollaborationTypeField } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationTypeField';
import { DataSourceConnectionCollaborationListItem } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationListItem';
import { DataSourceConnectionCollaborationInviteExpiredField } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationInviteExpiredField';
import { DataSourceConnectionCollaborationRevokeField } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationRevokeField';
import { DataSourceCollaborationRemoveOption } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationRemoveOption';
import { DataSourceConnectionCollaborationTypeCell } from '../DataSourceConnectionCollaboration/components/DataSourceConnectionCollaborationTypeCell';
import type { UserRole } from '../hooks/ueGetUserRoles';
import type { User } from '../../../../../react/utilities/systemUsersUtils';
import type { CollaboratorGridRow } from '../types';

export enum CollaborationFieldAction {
  REVOKE = 'revoke',
  RESEND = 'resend',
  SEARCH = 'search',
  UPDATE = 'update',
  INVITE = 'invite',
}

export type GridRow = CollaboratorGridRow;

export type BigidDataFieldWithDataAid = Omit<BigidFormField, 'dropDownOptions'> & {
  dropDownOptions?: (BigidDropdownOption & { dataAid?: string })[];
};

type BigidFieldActions = Partial<Record<CollaborationFieldAction, (...props: unknown[]) => void>>;

type BigidFieldActionHandlers<T extends CollaborationFieldAction = CollaborationFieldAction> = {
  [Key in CollaborationFieldAction & T as `on${Capitalize<Key>}`]?: () => void;
};

type CollaborationRevokeAction = {
  id: string;
} & BigidFieldActionHandlers<CollaborationFieldAction.REVOKE>;

export type CollaborationFieldValues = {
  collaborators: BigidDropdownOption[];
  type?: [BigidDropdownOption];
  message?: string;
};

const createCollaborationActions = ({
  onRevoke,
}: CollaborationRevokeAction & Partial<BigidDropdownOption>): BigidDropdownOption[] => [
  {
    id: CollaborationFieldAction.REVOKE,
    value: 'revoke',
    displayValue: (
      <DataSourceCollaborationRemoveOption
        dataAid={generateDataAid('DataSourceConnectionCollaboration', ['action', CollaborationFieldAction.REVOKE])}
        onClick={onRevoke}
      />
    ) as unknown as string,
    applyOnChange: false,
  },
];

export const createGridConfig = (
  gridId: string,
  source: string,
  dsTypeLabel: string,
  config: ReturnType<typeof useFetch<GridRow>>,
  roles: UserRole[],
  t: (text: string) => string,
  actions: BigidFieldActions,
  overrides?: Partial<BigidGridProps<GridRow>>,
): BigidGridProps<GridRow> => ({
  gridId,
  rows: config.rows,
  totalRowsCount: config.totalRowsCount,
  skip: config.skip,
  onPagingChanged: config.onPagingChanged,
  onSortingChanged: config.onSortingChanged,
  onFiltersChange: config.onFiltersChanged,
  defaultSorting: config.defaultSorting,
  loading: config.isLoading,
  pageSize: 1000,
  hideHeadersRow: true,
  ...overrides,
  hiddenColumnNames: ['type'],
  columns: [
    {
      name: 'name',
      title: t('collaboration.name'),
      width: '484',
      getCellValue: ({ id, name, email }) => (
        <DataSourceConnectionCollaborationListItem
          dataAid={generateDataAid('DataSourceConnectionCollaboration', ['cell', 'name', id])}
          avatar={name}
          title={email}
        />
      ),
      type: BigidGridColumnTypes.CUSTOM,
      ...overrides?.columns?.find(({ name }) => name === 'name'),
    },
    {
      name: 'expired',
      title: t('collaboration.expired'),
      width: '38',
      getCellValue: ({ id, email }) => {
        const onResend = actions?.resend;

        const handleResendInvite = () => onResend?.(email);

        return (
          <DataSourceConnectionCollaborationInviteExpiredField
            id={email}
            sourceId={source}
            dataAid={generateDataAid('DataSourceConnectionCollaboration', ['cell', 'expired', id])}
            onResend={handleResendInvite}
            dsTypeLabel={dsTypeLabel}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
      ...overrides?.columns?.find(({ name }) => name === 'expired'),
    },
    {
      name: 'revoke',
      title: t('collaboration.revoke'),
      width: '38',
      getCellValue: ({ id, email }) => {
        const onRevoke = actions?.revoke;

        const handleRevokeAccess = () => onRevoke?.(email);

        return (
          <DataSourceConnectionCollaborationRevokeField
            id={email}
            sourceId={source}
            dataAid={generateDataAid('DataSourceConnectionCollaboration', ['cell', 'revoke', id])}
            onRevoke={handleRevokeAccess}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
      ...overrides?.columns?.find(({ name }) => name === 'revoke'),
    },
    {
      name: 'type',
      isHiddenByDefault: true,
      title: t('collaboration.type'),
      width: '150',
      getCellValue: ({ id, type, email }) => {
        const options = mapUserRoleToBigidDropdownOption(roles);
        const onUpdate = actions?.update;

        return (
          <DataSourceConnectionCollaborationTypeCell
            dataAid={generateDataAid('DataSourceConnectionCollaboration', ['cell', 'type', id])}
            options={options}
            id={email}
            source={source}
            dsTypeLabel={dsTypeLabel}
            defaultValue={type}
            onChange={() => onUpdate?.()}
          />
        );
      },
      type: BigidGridColumnTypes.CUSTOM,
      ...overrides?.columns?.find(({ name }) => name === 'type'),
    },
  ],
});

export const createFieldConfig = (
  roles: UserRole[],
  label: string,
  actions: BigidFieldActions,
  users: User[],
  showActions: boolean,
  t: (text: string) => string,
  id: string,
  overrides?: Record<string, Partial<BigidFormField>>,
): BigidDataFieldWithDataAid[] => [
  {
    name: 'collaborators',
    validate: value => {
      const isValid = Array.isArray(value) ? value.length : value;
      return isValid ? false : t('collaboration.required');
    },
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    render: props => {
      const onSearch = actions?.search;
      const options = mapUserToBigidDropdownOption(users);

      return (
        <DataSourceConnectionCollaborationEmailField
          dataAid={generateDataAid('DataSourceConnectionCollaboration', ['field', 'collaborators'])}
          onInputChange={onSearch}
          data={options}
          {...props}
        />
      );
    },
    ...overrides?.['collaborators'],
  },
  {
    name: 'type',
    isRequired: false,
    render: ({ value, setValue, error, errorIsShown }: BigidFieldRenderProps<unknown, BigidDropdownOption[]>) => {
      const { disabled: isDisabled = true } = overrides?.['type'] ?? {};
      const options = mapUserRoleToBigidDropdownOption(roles) ?? [];

      return isDisabled ? null : (
        <DataSourceConnectionCollaborationTypeField
          dataAid={generateDataAid('DataSourceConnectionCollaboration', ['field', 'type'])}
          actions={
            showActions
              ? createCollaborationActions({ id: CollaborationFieldAction.REVOKE, onRevoke: actions?.revoke })
              : []
          }
          value={value}
          options={options}
          isError={errorIsShown && !!error}
          errorMessage={errorIsShown ? (error as unknown as string) : null}
          onSelect={options => setValue(options)}
        />
      );
    },
    ...overrides?.['type'],
  },
  {
    name: 'message',
    type: BigidFormFieldTypes.TEXTAREA,
    render: props => (
      <DataSourceConnectionCollaborationTextareaField
        {...props}
        dataAid={generateDataAid('DataSourceConnectionCollaboration', ['field', 'message'])}
        label={label}
        key={id}
      />
    ),
    ...overrides?.['message'],
  },
];
