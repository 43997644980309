import { httpService } from '../../../services/httpService';
import { FilePermission } from './FilePermissions';

export interface ObjectDetails {
  attribute: string[];
  type: string;
}

export interface Annotations {
  [name: string]: string[];
}

interface OpenAccessFilesCount {
  count: number;
  isFinal: boolean;
}

export interface OpenAccessFilesResponse {
  data: OpenAccessFilesData[];
}

export interface OpenAccessFilesCountResponse {
  data: OpenAccessFilesCount[];
}

export interface OpenAccessFilesData {
  _id: string;
  fullyQualifiedName: string;
  source: string;
  objectDetails: ObjectDetails;
  annotations?: Annotations;
}

export interface ObjectPermissionsResponse {
  data: {
    permissions: FilePermission[];
    totalCount: number;
  };
}

export const getOpenAccessFiles = async (query: string) => {
  return httpService.fetch<OpenAccessFilesResponse>(`aci/data-manager?${query}`).then(({ data }) => data);
};

export const getCSVByQuery = async (query: string) => {
  return httpService.downloadFile(`aci/data-manager/file-download/csv?${query}`);
};

export const getWithPermissionsCSVByQuery = async (query: string) => {
  return httpService.downloadFile(`aci/data-manager/permissions/file-download/csv?${query}`);
};

export const getOpenAccessFilesCount = (query: string) => {
  return httpService
    .fetch<OpenAccessFilesCountResponse>(`aci/data-manager/count${query ? '?' + query : ''}`)
    .then(({ data }) => data.data);
};

export const getOpenAccessFilesCountAfterItsFinal = (query: string) => {
  return httpService.cancelableFetch<OpenAccessFilesCountResponse>(`aci/data-manager/count${query ? '?' + query : ''}`);
};

export const getFilePermissions = (fullyQualifiedName: string, gridConfigQuery: string) => {
  return httpService
    .fetch<ObjectPermissionsResponse>(
      `aci/data-manager/${encodeURIComponent(fullyQualifiedName)}/permissions${
        gridConfigQuery ? '?' + gridConfigQuery : ''
      }`,
    )
    .then(({ data }) => data.data);
};

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
