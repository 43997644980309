import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { ScanProfileGridRow } from './ScanProfileTypes';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  getScanOwnerChips,
  getScanTypeLabel,
  getScanTypesOptions,
  getScheduleGridCellValue,
  isScanIsTemplateBasedFFOff,
} from '../ScanUtils';
import { BigidFilterType } from '@bigid-ui/components';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { scheduledCell } from './scheduledCell';

export const getInitialScanProfileGridColumns: () => BigidGridColumn<ScanProfileGridRow>[] = () => [
  {
    name: 'name',
    title: 'Scan Profile',
    sortingEnabled: true,
    ...(isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_PROFILES.name)
      ? {
          formatter: {
            onClick: async ({ value }) => {
              const { link } = value;
              $state.go(CONFIG.states.EDIT_SCAN_PROFILE, { id: link.linkParams.id, name: link.linkParams.name });
              return {};
            },
          },
          getCellValue: row => ({
            link: {
              text: row.name,
              disabled: isScanIsTemplateBasedFFOff(row.scanTemplateId),
              linkParams: {
                id: row._id,
                name: row.name,
              },
            },
          }),
          type: BigidGridColumnTypes.LINK,
        }
      : {
          getCellValue: row => row.name,
          type: BigidGridColumnTypes.TEXT,
        }),
    width: 350,
  },
  {
    name: 'scanType',
    title: 'Type',
    sortingEnabled: false,
    getCellValue: row => getScanTypeLabel(row.scanType),
    type: BigidGridColumnTypes.TEXT,
    width: 175,
  },

  {
    name: 'owners',
    title: 'Scan Owners',
    sortingEnabled: false,
    getCellValue: ({ owners }) => {
      if (!owners?.length) {
        return;
      }
      return {
        chips: getScanOwnerChips(owners),
      };
    },
    type: BigidGridColumnTypes.CHIPS,
    width: 225,
  },
  {
    name: 'schedule',
    title: 'Schedule',
    sortingEnabled: false,
    getCellValue: ({ schedule }) => getScheduleGridCellValue(schedule),
    type: BigidGridColumnTypes.TEXT,
    width: 275,
  },
  {
    name: 'active',
    title: 'Scheduled',
    width: 125,
    sortingEnabled: false,
    getCellValue: row => {
      return scheduledCell(row.active);
    },
    type: BigidGridColumnTypes.CUSTOM,
  },
  {
    name: '_id',
    title: 'Scan Profile Id',
    getCellValue: row => row._id,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: true,
    isHiddenByDefault: true,
  },
  {
    name: 'description',
    title: 'Description',
    getCellValue: row => row.description,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    isHiddenByDefault: true,
    width: 500,
  },
];

export async function getInitialScanProfileFilters() {
  const scanTypeFilter = {
    title: 'Type',
    field: 'scanType',
    operator: 'in',
    options: getScanTypesOptions(),
    isSelected: true,
    value: [],
  } as BigidFilterType;

  const scheduledFilter = {
    title: 'Scheduled',
    field: 'active',
    operator: 'in',
    isSelected: true,
    single: true,
    value: [],
    options: [
      {
        label: 'Yes',
        value: true,
        isSelected: false,
      },
      {
        label: 'No',
        value: [false, null],
        isSelected: false,
      },
    ],
  } as BigidFilterType;

  const initialActivityQueueFilters: BigidGridWithToolbarProps<ScanProfileGridRow>['filterToolbarConfig'] = {
    filters: [scanTypeFilter, scheduledFilter],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      placeholder: 'Scan Profile Name',
      operator: 'contains',
      autoSubmit: true,
    },
  };
  return initialActivityQueueFilters;
}
