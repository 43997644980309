import React, { useState } from 'react';
import { BigidResetIcon } from '@bigid-ui/icons';
import { BigidLoader, BigidTooltip, BigidButtonIcon } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { useResendCollaborationInvite } from '../../hooks/useResendCollaborationInvite';
import { mapRowToResendCollaborationInvitePayload } from '../../mappers/collaboration';
import { CollaborationFieldAction } from '../../config/collaboration';
import { generateDataAid } from '@bigid-ui/utils';
import { notificationService } from '../../../../../services/notificationService';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../../DataSourcesEventTrackerUtils';

type DataSourceConnectionCollaborationInviteExpiredFieldProps = {
  dataAid?: string;
  sourceId: string;
  id: string;
  onResend?: () => void;
  dsTypeLabel: string;
};

export const DataSourceConnectionCollaborationInviteExpiredField = ({
  dataAid = 'DataSourceConnectionCollaborationInviteExpiredField',
  sourceId,
  id,
  onResend,
  dsTypeLabel,
}: DataSourceConnectionCollaborationInviteExpiredFieldProps) => {
  const { t } = useLocalTranslation();
  const [isReminderSuccessfullySent, setIsReminderSuccessfullySent] = useState(false);
  const { mutateAsync: resendInvite, isLoading } = useResendCollaborationInvite({
    mutation: {
      onError: () => notificationService.error(t('collaboration.resendError')),
      onSuccess: () => notificationService.success(t('collaboration.resendSuccess')),
    },
  });

  const handleResendInvite = async () => {
    try {
      const payload = mapRowToResendCollaborationInvitePayload({ email: id }, dsTypeLabel);

      await resendInvite(
        {
          dataSource: sourceId,
          data: payload,
        },
        {
          onSuccess: async () => {
            trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_RESEND_INVITATION_CLICK);
            onResend?.();
            setIsReminderSuccessfullySent(true);
            notificationService.success(t('collaboration.tooltip.inviteSent'));
          },
        },
      );
    } catch {}
  };

  return (
    <>
      {isLoading ? (
        <BigidLoader size={20} position="relative" />
      ) : (
        !isReminderSuccessfullySent && (
          <BigidTooltip placement="top" dataAid={dataAid} title={t('collaboration.tooltip.body')}>
            <div>
              <BigidButtonIcon
                icon={BigidResetIcon}
                onClick={handleResendInvite}
                dataAid={generateDataAid(dataAid, ['action', CollaborationFieldAction.RESEND])}
              />
            </div>
          </BigidTooltip>
        )
      )}
    </>
  );
};
