import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { DataSourceModel } from '../../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { getSupportedDataSources } from '../../../../../utilities/dataSourcesUtils';

export const selectDataSourceGridColumns: BigidGridColumn<DataSourceModel>[] = [
  {
    name: 'name',
    title: 'Name',
    sortingEnabled: true,
    getCellValue: row => row.name,
    type: BigidGridColumnTypes.TEXT,
    width: 350,
  },
  {
    name: 'type',
    title: 'Type',
    sortingEnabled: true,
    getCellValue: row => row.type,
    type: BigidGridColumnTypes.TEXT,
    width: 350,
  },
];

export async function getSelectDataSourceFilters() {
  const dataSourceTypes = await getSupportedDataSources();
  const filterToolbarConfig: BigidGridWithToolbarProps<DataSourceModel>['filterToolbarConfig'] = {
    filters: [
      {
        title: 'Type',
        field: 'type',
        operator: 'in',
        disabled: true,
        options: dataSourceTypes.map(({ name, displayName }) => ({
          label: displayName || name,
          value: name.toLowerCase(),
          isSelected: false,
        })),
        value: [],
        displayLimit: 500,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name', 'friendly_name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };

  return filterToolbarConfig;
}
