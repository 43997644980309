export interface PreviousQuestion {
  question: string;
  shortAnswer?: string;
  updatedAt: number;
}

export interface Links {
  url: string;
  title: string;
}

export interface AnswerWidget {
  shouldTriggerWidget: boolean;
  question?: string;
  answer?: string;
  links?: Links[];
}

export interface BigChatSetInHeaderProps {
  setShowBigChatInHeader: SetBigChatInHeader;
}

export enum EVENT_STATUS {
  STARTED = 'Started',
  IN_PROGRESS = 'InProgress',
  END = 'End',
}

export enum BIGCHAT_TRACKING_EVENTS {
  BIGCHAT_FEEDBACK_CLICK = 'BIGCHAT_FEEDBACK_CLICK',
}

export enum BIGCHAT_FEEDBACK_STATE {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum BIGCHAT_WIDGETS {
  FEEDBACK = 'feedback',
  LINKS = 'links',
  RESET = 'reset',
}

export enum DISCLAIMER_MODAL_STATE {
  NOT_SHOWED = 'NOT_SHOWED',
  DO_NOT_SHOW_AGAIN = 'DO_NOT_SHOW_AGAIN',
}

export type SetBigChatInHeader = (state: boolean) => void;
