import React, { FC, useEffect, useState } from 'react';
import { BigidTabsAndContent, BigidTabsAndContentProps, BigidLoader } from '@bigid-ui/components';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state, $transitions } from '../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';
import { CONFIG } from '../../../config/common';
import { isPermitted } from '../../services/userPermissionsService';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { ActionCenterMetadataContainer } from './useActionCenterMetadata';
import { UIView } from '@uirouter/react';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { MtMigrationWrapper } from '../../components/MtMigrationEmptyState/MtMigrationWrapper';
import { MtNotSupportedViews } from '../../components/MtMigrationEmptyState/mtMigrationEmptyStateUtils';

const pageBottomMargin = 10;
const tabHeight = 34;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${pageBottomMargin}px)`,
    width: '100%',
    overflow: 'hidden',
  },
  contentContainer: { overflow: 'hidden', display: 'none' },
  tabsContainer: { marginBottom: 0 },
  view: {
    height: `calc(100% - ${tabHeight}px)`,
  },
});

const AC_TABS = [
  // CONFIG.states.ACTION_CENTER_OVERVIEW,
  CONFIG.states.ACTION_CENTER_ACTIONS,
  CONFIG.states.ACTION_CENTER_AUDIT,
  CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
];

export const ActionCenterConsumer: FC = () => {
  const { isLoading, actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const { content, contentContainer, tabsContainer, view } = useStyles({});
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(AC_TABS.indexOf($state.$current.name) || 0);

  useEffect(() => {
    pageHeaderService.setTitle({ pageTitle: 'Action Center' });

    const deregisterListener = $transitions.onBefore(
      {
        to: state => state.name.includes(CONFIG.states.ACTION_CENTER),
      },
      transition => {
        const { name } = transition.to();
        if (name === CONFIG.states.ACTION_CENTER) {
          return $state.target(CONFIG.states.ACTION_CENTER_ACTIONS);
        }
        setSelectedTabIndex(AC_TABS.indexOf(name) || 0);
      },
    );

    if ($state.current.name === CONFIG.states.ACTION_CENTER) {
      $state.go(CONFIG.states.ACTION_CENTER_ACTIONS);
    }
    return () => deregisterListener?.();
  }, []);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { contentContainer, tabsContainer },
    tabProps: {
      tabs: [
        // {
        //   label: 'Overview',
        // },
        ...(isPermitted(ACTION_CENTER_PERMISSIONS.ACCESS_ACTIONS.name)
          ? [
              {
                label: 'Actions',
              },
            ]
          : []),
        ...(isPermitted(ACTION_CENTER_PERMISSIONS.ACCESS_AUDIT.name)
          ? [
              {
                label: 'History',
              },
            ]
          : []),
        ...(getApplicationPreference('SHOW_AC_CONFIGURATIONS') &&
        isPermitted(ACTION_CENTER_PERMISSIONS.ACCESS_CONFIGURATIONS.name)
          ? [
              {
                label: 'Configurations Management',
              },
            ]
          : []),
      ],
      onChange: tabIndex => {
        $state.go(AC_TABS[tabIndex]);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <ActionCenterMetadataContainer.Provider>
      <div className={content}>
        {isLoading && <BigidLoader />}
        {!isLoading && actionCenterMetadata && (
          <>
            <BigidTabsAndContent {...tabsAndContentConfig} />
            <UIView className={view} />
          </>
        )}
      </div>
    </ActionCenterMetadataContainer.Provider>
  );
};

export const ActionCenter: FC = () => {
  return (
    <ActionCenterMetadataContainer.Provider>
      <ActionCenterConsumer />
    </ActionCenterMetadataContainer.Provider>
  );
};
