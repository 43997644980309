import { module } from 'angular';
import '../../react/views/DSAR/SarInfo/ExtendedInfo/ExtendedInfo';
import './personalInfoReport.component.scss';
import template from './personalInfoReport.component.html';
import { HeaderEvents, headerEventEmitter } from '../../react/services/eventEmitters/headerEvents';
import { httpService } from '../../react/services/httpService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { openDownloadPdfReportTemplateDialog } from '../../../app/react/views/DSAR/DsarReportTemplates/Dialogs/DownloadPdfReportTemplateDialog';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { isMultiTenantModeEnabled } from '../../react/utilities/multiTenantUtils';

const isDsarReportsGrouped = () => {
  return getApplicationPreference('IS_DSAR_REPORTS_GROUPED');
};

const isDsarLegacySummaryReport = () => {
  return getApplicationPreference('IS_DSAR_LEGACY_SUMMARY_REPORT');
};

module('app').component('personalInfoReport', {
  template,
  controller: function (
    $state,
    $rootScope,
    $translate,
    $stateParams,
    $scope,
    personalInfoReportMakerService,
    userDetailsService,
    DeleteConfirmation,
    notificationService,
  ) {
    'ngInject';
    const TRANSLATION_REQUIRED = [
      'PERSONAL_INFO_REPORT',
      'PERSONAL_INFO_REPORT:TABS:PERSONAL_INFO',
      'PERSONAL_INFO_REPORT:TABS:GENERAL_INFO',
      'PERSONAL_INFO_REPORT:TABS:EXTENDED_INFO',
      'ACCESS_REQUEST_MANAGEMENT',
    ];

    const tabs = {
      personalInfo: '',
      extendedInfo: '',
      generalInfo: '',
    };

    const DEFAULT_TAB = 'personalInfo';

    const deleteUserData = userId => {
      userDetailsService
        .launchDeleteUserTask({ userId, profileId: this.profileId, profileName: this.profileName })
        .then(result => {
          if (result.status === 'SUCCESS') {
            $translate('PERSONAL_INFO:DELETE_CONFIRMATION_SUCCESS', { entityName: result.taskId }).then(translation => {
              notificationService.success(translation);
            });
            $state.go('taskList', { taskId: result.taskId });
          } else {
            $translate('PERSONAL_INFO:DELETE_CONFIRMATION_FAILURE').then(translation => {
              notificationService.error(translation);
            });
          }

          headerEventEmitter.emit(HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK);
        });
    };

    this.tabs = {};
    this.currentTab = '';
    this.isLoading = false;

    this.userId = null;
    this.userName = null;
    this.isDownloadButtonDisabled = false;

    this.$onInit = () => {
      this.isUseDsarReportTemplates = getApplicationPreference('USE_DSAR_REPORT_TEMPLATES');

      this.hasRequestToRemovePermission =
        isPermitted(DSAR_PERMISSIONS.RUN_REQUEST_TO_BE_REMOVED_PERSONAL_INFO.name) && !isMultiTenantModeEnabled();
      this.hasDownloadPermission = isPermitted(DSAR_PERMISSIONS.DOWNLOAD_REQUEST_REPORT.name);

      $translate(TRANSLATION_REQUIRED).then(translations => {
        const pageTitle = translations['PERSONAL_INFO_REPORT'];
        const breadcrumb = translations['ACCESS_REQUEST_MANAGEMENT'];

        $rootScope.$broadcast('changePage', pageTitle, false, true, null, [
          { label: breadcrumb, onClick: () => $state.go('subjectAccessRequest', { path: 'new-request' }) },
          {
            label: 'Completed Requests',
            onClick: () => $state.go('subjectAccessRequest', { path: 'completed-requests' }),
          },
          { label: pageTitle },
        ]);

        //tabs
        tabs.personalInfo = translations['PERSONAL_INFO_REPORT:TABS:PERSONAL_INFO'];
        tabs.generalInfo = translations['PERSONAL_INFO_REPORT:TABS:GENERAL_INFO'];
        tabs.extendedInfo = translations['PERSONAL_INFO_REPORT:TABS:EXTENDED_INFO'];

        if (this.isUseDsarReportTemplates) {
          delete tabs.generalInfo;
        }

        this.tabs = tabs;

        this.currentTab = DEFAULT_TAB;

        this.userId = $stateParams.userId;
        this.userName = $stateParams.userName;
        this.profileId = $stateParams.profileId;
        this.profileName = $stateParams.profileName;
      });
    };

    this.onTabSelected = tabName => {
      this.currentTab = tabName;

      this.isLoading = false;
    };

    this.onContentDataLoading = () => {
      this.isLoading = true;
    };

    this.onContentDataLoaded = userName => {
      if (typeof userName != 'undefined') {
        this.userName = userName;
      }

      $scope.$evalAsync(() => {
        this.isLoading = false;
      });
    };

    this.onDownloadReport = async () => {
      try {
        this.isDownloadButtonDisabled = true;
        if (isDsarReportsGrouped() || isDsarLegacySummaryReport()) {
          await personalInfoReportMakerService.createPDFReport(this.userId);
        } else {
          if (this.isUseDsarReportTemplates) {
            const { wasCreated } = await openDownloadPdfReportTemplateDialog(this.userId);
          } else {
            await httpService.downloadFile(`/file-download/sar/reports/${this.userId}/summary-report`);
            notificationService.success('Report is being generated, this might take a while');
          }
        }
      } catch (e) {
        console.error(e);
        notificationService.error('Failed to create PDF report. See the logs for additional information');
      } finally {
        $scope.$evalAsync(() => {
          this.isDownloadButtonDisabled = false;
        });
      }
    };

    this.onRequestToRemove = async () => {
      const closeButtonText = await $translate('BUTTONS:CLOSE');
      const actionButtonText = await $translate('BUTTONS:SUBMIT');
      const headerText = await $translate('PERSONAL_INFO:DELETE_CONFIRMATION_HEADER');
      const bodyText = await $translate('PERSONAL_INFO:DELETE_CONFIRMATION_BODY');

      DeleteConfirmation.showModal(
        {},
        {
          closeButtonText,
          actionButtonText,
          headerText,
          bodyText,
        },
      ).then(() => deleteUserData(this.userId));
    };
  },
});
