import React, { useState, FC, useCallback, useMemo } from 'react';
import {
  AsyncOperationProcessingWidget,
  AsyncOperationMap,
} from '../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { useTagBulkAssignment } from './operations/TagBulkAssignment';
import { useCusterBusinessAttrAssignment } from './operations/ClusterBusinessAttrAssignment';
import { useRulesBulkApplying } from './operations/RulesBulkApplying';

interface DataCatalogAsyncOpsProcessingWidgetProps {
  onUpdate: () => void;
}

export const DataCatalogAsyncOpsProcessingWidget: FC<DataCatalogAsyncOpsProcessingWidgetProps> = ({ onUpdate }) => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());

  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const { operations: tagsBulkOperations } = useTagBulkAssignment({ onOperationCompleted: onUpdate, onOperationRun });
  const { operations: clusterBusinessAttrOperations } = useCusterBusinessAttrAssignment({ onOperationRun });
  const { operations: rulesBulkApplyingOperations } = useRulesBulkApplying({ onOperationRun });

  const operations = useMemo(() => {
    return [...tagsBulkOperations, ...clusterBusinessAttrOperations, ...rulesBulkApplyingOperations];
  }, [tagsBulkOperations, clusterBusinessAttrOperations, rulesBulkApplyingOperations]);

  return (
    <AsyncOperationProcessingWidget
      dataAid="Data-catalog-processing"
      operations={operations}
      operationsMap={runningOperationsMap}
    />
  );
};
