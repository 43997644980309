import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ObjectDetailsProps, ObjectDetailsWrapper } from './ObjectTags';
import { CustomAccordeon } from './CustomAccordeon';
import { dateUtils } from '../../../../../services/angularServices';
import { formatBytes } from '../../../../../utilities/numericDataConverter';

interface ItemSectionProps {
  title: string;
  text: string;
}

const Text = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '22px',
});

const ItemWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '140px auto',
  columnGap: 20,
  alignItems: 'center',
});

const InfoWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '100%',
  rowGap: 8,
});

const ItemSection: FC<ItemSectionProps> = ({ title, text }) => {
  if (!text) {
    return null;
  }

  return (
    <ItemWrapper>
      <Text>{title}</Text>
      <Text>{text}</Text>
    </ItemWrapper>
  );
};

export const ObjectInfo: FC<ObjectDetailsProps> = ({ caseDetails }) => {
  return (
    <ObjectDetailsWrapper>
      <CustomAccordeon header="Object info">
        <InfoWrapper>
          <ItemSection title="Object Type:" text={caseDetails?.type} />
          <ItemSection title="Owner:" text={caseDetails?.owner} />
          <ItemSection title="Full Name:" text={caseDetails?.fullObjectName} />
          <ItemSection title="Size:" text={formatBytes(caseDetails?.sizeInBytes)} />
          <ItemSection title="Language:" text={caseDetails?.language} />
          <ItemSection title="Scan Status:" text={caseDetails?.scanStatus} />
          <ItemSection title="Created on:" text={dateUtils.formatDate(caseDetails?.created_date)} />
          <ItemSection title="Last Scanned:" text={dateUtils.formatDate(caseDetails?.last_scanned)} />
          <ItemSection title="Last Modified:" text={dateUtils.formatDate(caseDetails?.modified_date)} />
          <ItemSection title="Data Source:" text={caseDetails?.dataSourceName} />
          <ItemSection title="Data Source Type:" text={caseDetails?.ds[0]?.type} />
        </InfoWrapper>
      </CustomAccordeon>
    </ObjectDetailsWrapper>
  );
};
