import React, { FC, useState, useContext, useRef } from 'react';
import {
  BigidColorsV2,
  BigidBody1,
  BigidMenu,
  BigidMenuProps,
  BigidMenuItem,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../../translations';
import { BigidOpenTicketIcon, BigidMoreActionIcon, BigidAddIcon } from '@bigid-ui/icons';
import { Divider } from '@mui/material';
import { ActionableInsightsContext } from '../../../../../hooks/ActionableInsightsContext';
import { JiraContentTypes } from '../JiraModal';

import styled from '@emotion/styled';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../../../../services/userPermissionsService';
import { generateDataAid } from '@bigid-ui/utils';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const ProjectItem = styled.div({
  width: '100%',
  height: 48,
  borderRadius: 4,
  backgroundColor: BigidColorsV2.gray[50],
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& *:last-child': {
    marginLeft: 'auto',
  },
  position: 'relative',
});

const DotsIconContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const FieldLabel = styled.div({
  paddingBottom: 4,
});

export interface JiraProjectSelectorProps {
  selectedJiraProjectName: string;
  updateModalContent: React.Dispatch<React.SetStateAction<JiraContentTypes>>;
  updateSelectedJiraProject: (accountName: string) => void;
}

export const JiraProjectSelector: FC<JiraProjectSelectorProps> = ({
  selectedJiraProjectName,
  updateModalContent,
  updateSelectedJiraProject,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { jiraConfigurations } = useContext(ActionableInsightsContext);
  const menuIconRef = useRef();
  const { t } = useLocalTranslation('Action.jira');

  const handleClick = () => setIsMenuOpen(!isMenuOpen);
  const handleClose = () => setIsMenuOpen(false);

  const MenuChildren = () => (
    <>
      {jiraConfigurations.map(config => {
        return (
          <BigidMenuItem key={config.id} onClick={() => updateSelectedJiraProject(config.name)}>
            <BigidOpenTicketIcon />
            <BigidBody1 padding={0.5}>{config.name}</BigidBody1>
          </BigidMenuItem>
        );
      })}
      <Divider />
      {isPermitted(ACTION_CENTER_PERMISSIONS.EDIT_CONFIGURATIONS.name) && (
        <>
          <BigidMenuItem onClick={() => updateModalContent(JiraContentTypes.CONFIGURATION)}>
            <BigidAddIcon />
            <BigidBody1 padding={0.5}>{t('common.addProject')}</BigidBody1>
          </BigidMenuItem>
        </>
      )}
    </>
  );

  const menuProps: BigidMenuProps = {
    open: isMenuOpen,
    onMenuClose: handleClose,
    anchorEl: menuIconRef.current,
    children: <MenuChildren />,
  };

  return (
    <Wrapper>
      <FieldLabel>
        <BigidBody1>{t('form.jiraProject')}</BigidBody1>
      </FieldLabel>
      <ProjectItem data-aid={generateDataAid('JiraProjectSelector', ['selected-project', selectedJiraProjectName])}>
        <BigidOpenTicketIcon staticMode={true} />
        <BigidBody1 paddingLeft={0.5}>{selectedJiraProjectName}</BigidBody1>
        <DotsIconContainer ref={menuIconRef}>
          <BigidButtonIcon icon={BigidMoreActionIcon} selected={true} onClick={handleClick} />
        </DotsIconContainer>
      </ProjectItem>
      <BigidMenu {...menuProps} />
    </Wrapper>
  );
};
