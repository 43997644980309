import React, { Fragment, useMemo, useCallback, useRef, useEffect } from 'react';
import { BigidRemoveIcon, BigidEditIcon, BigidAddContainedIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import {
  BigidFieldFilter,
  ActionData,
  BigidColorsV2,
  ToolbarActionType,
  entityEventsEmitter,
  EntityEvents,
  BigidFilterOptionType,
} from '@bigid-ui/components';
import { BigidGridColumnTypes, BigidGridWithToolbar, BigidGridWithToolbarProps, BigidGridColumn } from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { DataSource, DsarManualRecords, SarProfile, SourceType } from '../ProfileSettingsTypes';
import { getCellValueForIncludeExcludeV2 as getCellValueForIncludeExclude } from '../gridCellValues';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { FieldType, NEW_DSAR_SETTINGS_STATE_NAMES } from '../SarProfileSettingsTypes';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { deleteManualRecord, getManualRecords, includeExcludeManualRecord } from '../../dsarService';
import { DataSourceLayoutNameCell } from '../../../DataSources/DataSourceConnections/DataSourceLayoutNameCell';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { DsarSettingsTrackingEvents, trackDsar } from '../../analyticsUtils';
import { EditManualRecordsDialog, useEditManualRecordsDialog } from './EditManualRecordsDialog';
import {
  GlossaryItemType,
  businessGlossaryService,
} from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { sortAlphabetically } from '../../../../../common/services/localizationHelper';
import { DsarEvents, dsarEventEmitter } from '../dsarEvents';

const GridWrapper = styled('div')`
  background-color: ${BigidColorsV2.white};
  border-radius: 4px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

type Grid = BigidGridWithToolbarProps<DsarManualRecords>;
type ManualRecordsProps = {
  fetchProfileData: () => Promise<DataSource[]>;
} & Pick<SarProfile, '_id' | 'dsConnections'>;

const columns: BigidGridColumn<DsarManualRecords>[] = [
  {
    width: 170,
    title: 'Field Name',
    name: 'field_name',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ fieldName }) => fieldName,
    filteringEnabled: false,
    sortingEnabled: true,
  },
  {
    width: 170,
    title: 'Value',
    name: 'value',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ value }) => value,
    filteringEnabled: false,
    sortingEnabled: true,
  },
  {
    width: 120,
    title: 'Selection',
    name: 'is_included',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ isIncluded }) => getCellValueForIncludeExclude(isIncluded),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 290,
    title: 'Purpose of Use',
    name: 'purpose_of_use_name',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ purposeOfUseValue }) => ({ chip: purposeOfUseValue ? { label: purposeOfUseValue } : null }),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 290,
    title: 'Category',
    name: 'category_name',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ categoryValue }) => ({ chip: categoryValue ? { label: categoryValue } : null }),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 150,
    title: 'Type',
    name: 'type',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ type }) => (type === FieldType.ENTITY_SOURCE ? FieldType.CORRELATION : type),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 170,
    title: 'Data Source',
    name: 'data_source',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ dataSource: name, dataSourceConnectionType, dataSourceType }) => {
      return name ? (
        <DataSourceLayoutNameCell
          row={{ id: name, type: dataSourceConnectionType, displayType: name } as DataSourceModel}
          iconWidth={24}
          isPlainText={dataSourceType !== SourceType.CONNECTED}
        />
      ) : null;
    },
    filteringEnabled: false,
    sortingEnabled: false,
  },
];

export const ManualRecords = ({ _id: profileId, dsConnections, fetchProfileData }: ManualRecordsProps) => {
  const gridFilter = useRef(null);
  const editFieldsDialogHook = useEditManualRecordsDialog();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInitialFilterToolbarConfig = useCallback(() => getFilterConfig(), [editFieldsDialogHook.key]);
  const {
    isReady,
    key: gridKey,
    preferences,
    gridColumns,
    filterToolbarConfig,
    updatePreferences,
  } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.MANUAL_RECORDS,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig,
  });

  useEffect(() => {
    const eventHandler = async () => {
      await editFieldsDialogHook.openDialog(profileId, null, dsConnections, fetchProfileData);
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    };
    dsarEventEmitter.addEventListener(DsarEvents.CREATE_STATIC_RECORD, eventHandler);

    return () => {
      dsarEventEmitter.removeEventListener(DsarEvents.CREATE_STATIC_RECORD, eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarManualRecords> = useMemo(() => {
    const includeExcludeExecutor =
      (isIncluded: boolean, isInlineAction?: boolean) =>
      async ({ allSelected, filter, selectedRowIds }: ActionData) => {
        if (!selectedRowIds.length) return {};
        const payload = {
          data: { isIncluded },
          query: {
            filter: allSelected
              ? filter
              : [
                  {
                    field: '_id',
                    operator: 'in',
                    value: selectedRowIds,
                  } as BigidFieldFilter,
                ],
          },
        };
        try {
          await includeExcludeManualRecord(profileId, payload);
        } catch (err) {
          notificationService.error(`Failed to Include/Exclude Manual Record`);
          console.error(`Failed to Include/Exclude Manual Record '${payload}': ${JSON.stringify(err)}`);
        }
        if (isInlineAction) {
          const event = isIncluded
            ? DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_INCLUDE_INLINE_ACTION
            : DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EXCLUDE_INLINE_ACTION;
          trackDsar(event);
          entityEventsEmitter.emit(EntityEvents.RELOAD);
        }
        return { shouldGridReload: true, shouldClearSelection: true };
      };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarManualRecords> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'Records',
      defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      toolbarActions: [
        {
          label: 'Include',
          type: ToolbarActionType.TERTIARY,
          icon: BigidAddContainedIcon,
          execute: includeExcludeExecutor(true),
          disable: ({ selectedRowIds }) => !selectedRowIds.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_INCLUDE_ACTION,
          },
        },
        {
          label: 'Exclude',
          type: ToolbarActionType.TERTIARY,
          icon: BigidRemoveIcon,
          execute: includeExcludeExecutor(false),
          disable: ({ selectedRowIds }) => !selectedRowIds.length,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EXCLUDE_ACTION,
          },
        },
        {
          label: 'Edit',
          type: ToolbarActionType.TERTIARY,
          icon: BigidEditIcon,
          execute: async ({ selectedRows }) => {
            const selectedRecord = selectedRows[0] as DsarManualRecords;
            const actionResult = await editFieldsDialogHook.openDialog(
              profileId,
              selectedRecord,
              dsConnections,
              fetchProfileData,
            );
            return { shouldClearSelection: actionResult, shouldGridReload: actionResult };
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length !== 1,
          show: ({ selectedRowIds }) =>
            selectedRowIds.length === 1 && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EDIT_ACTION,
          },
        },
        {
          label: 'Include',
          icon: BigidAddContainedIcon,
          execute: includeExcludeExecutor(true, true),
          disable: () => false,
          show: ({ selectedRows }) => !selectedRows?.[0]?.isIncluded,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Exclude',
          icon: BigidRemoveIcon,
          execute: includeExcludeExecutor(false, true),
          disable: () => false,
          show: ({ selectedRows }) => selectedRows?.[0]?.isIncluded,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Delete',
          icon: BigidDeleteIcon,
          execute: async ({ selectedRows }) => {
            const { id } = (selectedRows[0] || {}) as DsarManualRecords;
            trackDsar(DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_DELETE_INLINE_ACTION);
            try {
              await deleteManualRecord(profileId, id);
              entityEventsEmitter.emit(EntityEvents.RELOAD);
            } catch (err) {
              notificationService.error(`Delete Static Record Failed!`);
              console.error(`Could not delete static record ${id}`);
            }
            return { shouldClearSelection: true, shouldGridReload: true };
          },
          disable: () => false,
          show: () => true,
          isInline: true,
          hideActionInToolBar: true,
        },
        {
          label: 'Edit',
          icon: BigidEditIcon,
          execute: async ({ selectedRows }) => {
            trackDsar(DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EDIT_INLINE_ACTION);
            const selectedRecord = selectedRows[0] as DsarManualRecords;
            const actionResult = await editFieldsDialogHook.openDialog(
              profileId,
              selectedRecord,
              dsConnections,
              fetchProfileData,
            );
            entityEventsEmitter.emit(EntityEvents.RELOAD);
            return { shouldClearSelection: actionResult, shouldGridReload: actionResult };
          },
          disable: () => false,
          show: () => true,
          isInline: true,
          hideActionInToolBar: true,
        },
      ],
      filterToolbarConfig,
      fetchData: async gridQueryParams => {
        try {
          gridFilter.current = gridQueryParams.filter;
          const { objects, totalCount } = await getManualRecords(profileId, gridQueryParams);
          return {
            data: objects,
            totalCount,
          };
        } catch (err) {
          notificationService.error(`Failed to get Manual Records Settings.`);
          console.error(`Failed to get SAR Request profile by id '${profileId}': ${JSON.stringify(err)}`);
          return { data: [], totalCount: 0 };
        }
      },
    };

    return gridWithToolbarConfig;
  }, [
    gridColumns,
    preferences?.grid?.sort,
    filterToolbarConfig,
    updatePreferences,
    editFieldsDialogHook,
    profileId,
    dsConnections,
    fetchProfileData,
  ]);

  return (
    <Fragment>
      <EditManualRecordsDialog {...editFieldsDialogHook.dialogProps} />
      {isReady && (
        <GridWrapper>
          <BigidGridWithToolbar key={gridKey} {...gridWithToolbarConfig} />
        </GridWrapper>
      )}
    </Fragment>
  );
};

const getFilterConfig = async (): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Purpose of Use',
        field: 'purpose_of_use_id',
        operator: 'in',
        value: [],
        options: [],
        isSearchAsync: true,
        loadSearchOptions: () => getGlossaryItems(GlossaryItemType.PurposeOfProcessing),
        shouldLoadInitialOptions: true,
      },
      {
        title: 'Category',
        field: 'category_id',
        operator: 'in',
        value: [],
        options: [],
        isSearchAsync: true,
        loadSearchOptions: () => getGlossaryItems(GlossaryItemType.PersonalDataCategory),
        shouldLoadInitialOptions: true,
      },
      {
        title: 'Selection',
        field: 'is_included',
        operator: 'equal',
        single: true,
        value: [],
        options: [
          { value: true, label: 'Included', isSelected: false },
          { value: false, label: 'Excluded', isSelected: false },
        ],
      },
    ],
    searchConfig: {
      searchFilterKeys: ['field_name'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

const getGlossaryItems = async (type: string): Promise<BigidFilterOptionType[]> => {
  const glossaryItems = await businessGlossaryService.getGlossaryItems({ type });
  return glossaryItems
    .sort(({ name: a }, { name: b }) => sortAlphabetically(a, b))
    .map(glossaryItem => ({
      id: glossaryItem.glossary_id,
      label: glossaryItem.name,
      value: glossaryItem.glossary_id,
      isSelected: false,
    }));
};
