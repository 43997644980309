import { $state, localStorageService } from '../../services/angularServices';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { smallidOnboardingService } from '../../services/smallIdOnboardingService';
import { openOnBoardingModal } from '../../components/StartOnboardingModal/onboardingModalService';
import { isPermitted } from '../../services/userPermissionsService';
import { DASHBOARD_PERMISSIONS } from '@bigid/permissions';
import { CONFIG } from '../../../config/common';
import { isFunction } from 'lodash';
import { StateParams } from '@uirouter/core';
import { loginService } from '../../../authentication/login/login.service';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { userPreferencesService } from '../../services/userPreferencesService';
import { httpService } from '../../services/httpService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { getLoginOverrides } from './config/login';
import { LoginStrategies, type LoginOverride } from './types';

type RequestedPageType = {
  name: string;
  queryParams: StateParams;
};

const loginNavigationOverrides = getLoginOverrides();

const checkForNavigationOverrides = (): LoginOverride =>
  loginNavigationOverrides?.find(({ isPermitted }) => (isFunction(isPermitted) ? isPermitted() : isPermitted));

export const goToRequestedPage = async () => {
  const redirectPathInEnv = await getRedirectPath();
  const requestedPage: RequestedPageType = localStorageService.get('requestedPage');
  const navigationOverride = checkForNavigationOverrides();

  if (navigationOverride) {
    const { action: handleOverride, isFinal } = navigationOverride;
    handleOverride();
    if (isFinal) return;
  }

  if (redirectPathInEnv && (!requestedPage || requestedPage?.name === CONFIG.states.DASHBOARD)) {
    window.location.href = `#/${redirectPathInEnv}`;
    return;
  }

  const onBoardingService = smallIdLicenseService.isSmallIDLicense() ? smallidOnboardingService : openOnBoardingModal;

  if (getApplicationPreference('IS_SMALLID_FOR_DEV')) {
    return $state.go(CONFIG.states.SMALLID_FOR_DEVELOPERS).then(onBoardingService);
  }

  if (requestedPage) {
    return smallIdLicenseService.isAccessGranted(requestedPage.name)
      ? $state.go(requestedPage.name, requestedPage.queryParams).then(onBoardingService)
      : $state.go(smallIdLicenseService.getSmallIDLandingPage()).then(onBoardingService);
  }

  const landingPage = isPermitted(DASHBOARD_PERMISSIONS.ACCESS.name)
    ? CONFIG.states.DASHBOARD
    : CONFIG.states.TASKS_LIST;

  return $state
    .go(
      smallIdLicenseService.isAccessGranted(landingPage) ? landingPage : smallIdLicenseService.getSmallIDLandingPage(),
    )
    .then(onBoardingService);
};

const getRedirectPath = async () => {
  const isElasticEnabled = getApplicationPreference('DATA_OVERVIEW_ENABLED');
  const isLandingPageOverWritten = getApplicationPreference('DEFAULT_LANDING_PAGE');

  const defaultRedirectPath =
    isElasticEnabled && !isLandingPageOverWritten
      ? CONFIG.states.DATA_OVERVIEW
      : getApplicationPreference('DEFAULT_LANDING_PAGE');

  try {
    const useLandingPagePerUser = getApplicationPreference('ENABLE_LANDING_PAGE_PER_USER_FF');
    const landingPagePerUser = useLandingPagePerUser && (await userPreferencesService.get('landingPage'))?.data?.value;
    return landingPagePerUser || defaultRedirectPath;
  } catch (err) {
    console.error(err);
    return defaultRedirectPath;
  }
};

export const loginWithSSO = (userInfoRespondJson: string) => {
  if (userInfoRespondJson) {
    const decodedResponse = decodeURIComponent(userInfoRespondJson);
    const userInfoRespondObj = jsonParseSafe(atob(decodedResponse), null);
    loginService.performUserLoginFlow(userInfoRespondObj).then(() => goToRequestedPage());
  }
};

export const validateStringAndCompare = (valueToCompare: string, equalTo: string) => {
  return valueToCompare?.toUpperCase?.() === equalTo;
};

export const jsonParseSafe = (json: string, fallbackValue: { error: string }) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error(`Error parsing JSON in the login component, json:`, json);
    return fallbackValue;
  }
};

export const getErrorDisplayMessage = (errorCode?: string) => {
  const decodedError = atob(decodeURIComponent(errorCode));
  const errorToDisplay = jsonParseSafe(decodedError, { error: 'Unknown error occurred.' });
  return JSON.stringify(errorToDisplay?.error);
};

export const setLoginStrategyInSessionStorage = (isSamlIdpEnabled: boolean, isCloudIdpEnabled: boolean): void => {
  if (isSamlIdpEnabled) {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.SAML);
  } else if (isCloudIdpEnabled) {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.AUTH0);
  } else {
    sessionStorageService.set('LOGIN_STRATEGY', LoginStrategies.DEFAULT);
  }
};
