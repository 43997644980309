import { PrimaryButton } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { useState } from 'react';
import { useLocalTranslation } from '../../translations';
import { DomainModal } from './DomainModal/DomainModal';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const GridControls = styled('div')`
  display: flex;
  align-items: center;
`;

export const DomainManagement = () => {
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(true);
  const [selectedDomainId, setSelectedDomainId] = useState<string>('');
  const { t } = useLocalTranslation('Settings.Tabs.DomainManagement');

  return (
    <Container>
      <GridControls>
        <PrimaryButton size="medium" onClick={() => setIsDomainModalOpen(true)} text={t('newDomainButton.title')} />
      </GridControls>
      <DomainModal isOpen={isDomainModalOpen} domainId={selectedDomainId} onClose={() => setIsDomainModalOpen(false)} />
    </Container>
  );
};
