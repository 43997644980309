import React, { FC, Dispatch, SetStateAction } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFormBox } from '../../../../../components/BigidFormBox/BigidFormBox';
import { CustomAppParam } from '../EditCustomApp';
import { CustomAppParamsTable } from '../../../components/CustomAppParamsTable/CustomAppParamsTable';

export interface GlobalParamsSectionProps {
  globalParams: CustomAppParam[];
  globalParamsValues: Record<string, string>;
  setSectionValid: Dispatch<SetStateAction<boolean>>;
  onChange: (obj: Record<string, string>) => void;
}

const useStyles = makeStyles({
  globalParamsWidth: {
    width: '55%',
  },
});

export const GlobalParamsSection: FC<GlobalParamsSectionProps> = ({
  globalParams,
  globalParamsValues,
  onChange,
  setSectionValid,
}) => {
  const classes = useStyles({});
  const handleOnParamChange = (key: string, value: string) => {
    onChange({ [key]: value });
  };

  return (
    <BigidFormBox title="Global Parameters">
      <div className={classes.globalParamsWidth}>
        <CustomAppParamsTable
          params={globalParams}
          onParamChange={handleOnParamChange}
          setIsValuesValid={setSectionValid}
          values={globalParamsValues}
        />
      </div>
    </BigidFormBox>
  );
};
