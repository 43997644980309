import React, { FC, useContext, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColors,
  BigidHeading3,
  BigidBody1,
  PrimaryButton,
  SecondaryButton,
  BigidLink,
} from '@bigid-ui/components';
import { DsScanTypeEnum } from '../../types';
import { DataSourceConfigurationContext } from '../../DataSourceConfigurationContext';
import { isPermitted } from '../../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS, ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';
import { $translate, $uibModal } from '../../../../../services/angularServices';
import {
  showScanActivityInfoModal,
  TRANSLATION_REQUIRED,
} from '../../../../../../administration/scans/scans.component';
import { navigateToScansPage } from '../../../../Scans/ScanUtils';
import { getScannerLogs, GetHighlightedWordsForLogType } from '../../utils/scannerLogsUtils';
import { CONFIG } from '../../../../../../config/common';
import { isMultiTenantModeEnabled } from '../../../../../utilities/multiTenantUtils';

export const ScanTypeToLabelMap = {
  [DsScanTypeEnum.meta]: 'Meta Data',
  [DsScanTypeEnum.hyper]: 'Hyper',
};

export const useStyles = makeStyles({
  scanResultWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '348px',
    height: '570px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '8px',
    alignItems: 'center',
  },
  textWrapper: {
    color: BigidColors.gray[700],
  },
  dateLabelWrapper: {
    width: '105px',
    marginRight: '8px',
  },
  infoWrapper: {
    display: 'inline-flex',
  },
  buttonTextWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
  },
  buttonScanWrapper: {
    color: BigidColors.white,
  },
  textFailed: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '12px',
    padding: '16px',
  },
  buttonsContainerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0 16px 0',
  },
});

export interface ScanResultContentProps {
  runScan: () => void;
  isNotSaved: boolean;
  getHighlightedWordsForLog: GetHighlightedWordsForLogType;
}

const getSubScanWithDataOrFirst = (scans: Record<string, any>[]) =>
  scans?.length === 1 ? scans[0] : scans?.find((item: Record<string, any>) => item?.type === 'sub_scan');

export const ScanResultContent: FC<ScanResultContentProps> = ({ runScan, isNotSaved, getHighlightedWordsForLog }) => {
  const { scanStartDate, isScanSuccess, scanResults, scanType, scanCompleteDate, isTestAvailable } =
    useContext(DataSourceConfigurationContext);
  const { textWrapper, dateLabelWrapper, infoWrapper, scanResultWrapper, textFailed, buttonsContainerWrapper } =
    useStyles({});

  const navigateToScanPage = () => {
    navigateToScansPage(CONFIG.states.SCANS_SAVED_PROFILES);
  };

  const showResult = useCallback(async () => {
    showScanActivityInfoModal(
      getSubScanWithDataOrFirst(scanResults),
      $uibModal,
      await $translate(TRANSLATION_REQUIRED),
      $translate,
      () => false,
      null,
    );
  }, [scanResults]);

  return (
    <div data-aid="ScanResultContent" className={scanResultWrapper}>
      <BigidHeading3 gutterBottom className={textWrapper}>
        {ScanTypeToLabelMap[scanType]} Scan insights
      </BigidHeading3>
      {isScanSuccess ? (
        <React.Fragment>
          {scanStartDate && (
            <div className={infoWrapper} data-aid="DataSourceScanResultInitiated">
              <BigidBody1 className={dateLabelWrapper}>Initiated on: </BigidBody1> {scanStartDate}
            </div>
          )}
          {scanCompleteDate && (
            <div className={infoWrapper}>
              <BigidBody1 data-aid="DataSourceScanResultComplete" className={dateLabelWrapper}>
                {`Completed on: `}
              </BigidBody1>
              {scanCompleteDate}
            </div>
          )}
        </React.Fragment>
      ) : (
        <BigidHeading3 className={textFailed}>Scan failed</BigidHeading3>
      )}
      {isPermitted(SCANS_PERMISSIONS.READ_SCAN_ACTIVITY.name) && (
        <div className={buttonsContainerWrapper}>
          {isScanSuccess ? (
            <PrimaryButton
              dataAid="ScanResultShowResultButton"
              onClick={showResult}
              disabled={!isScanSuccess}
              size="large"
              margin="4px"
              text="Show Scan Result"
            />
          ) : (
            isPermitted(ADVANCE_TOOLS_PERMISSIONS.EXPORT_SERVICES_LOGS.name) &&
            !isMultiTenantModeEnabled() && (
              <SecondaryButton
                data-aid={`DataSourceTestConnectionViewLogsButtons`}
                onClick={() => {
                  getScannerLogs(scanStartDate, getHighlightedWordsForLog);
                }}
                size="large"
                margin="4px"
                text="View logs"
              />
            )
          )}
          {isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name) && (
            <SecondaryButton
              dataAid="ScanResultScanAgainButton"
              onClick={runScan}
              disabled={isNotSaved || !isTestAvailable}
              size="large"
              margin="4px"
              text="Scan Again"
            />
          )}
        </div>
      )}
      {isPermitted(SCANS_PERMISSIONS.ACCESS.name) && <BigidLink onClick={navigateToScanPage} text={'View Scans'} />}
    </div>
  );
};
