import React, { FC, useEffect, useMemo, memo } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { ActionData, EntityEvents, entityEventsEmitter, BigidSkeletonGenerator } from '@bigid-ui/components';
import { BigidLayout, LayoutContentType, BigidLayoutConfig, BigidHotspotsChartConfig } from '@bigid-ui/layout';
import {
  BigidGridProps,
  BigidGridRow,
  BigidGridColumnTypes,
  BigidGridDataFetchResult,
  FetchTotalCount,
} from '@bigid-ui/grid';
import { BigidTableColumnsIcon, BigidHotspotIcon } from '@bigid-ui/icons';
import { AggregationType, AttributesGridAggregationItem } from '../../catalogDiscoveryTypes';
import { useLocalTranslation } from './translations';
import { formatNumberCompact } from '../../../../utilities/numericDataConverter';
import {
  getAggregatedData,
  getAggregatedDataCountCancellable,
  GetAggregatedDataPayload,
  GetAggregatedDataResponse,
} from '../../catalogDiscoveryService';
import { AttributesHotspotToolbar, pageSizeAvailableOptions } from './AttributesHotspotToolbar';
import { v4 as uuid } from 'uuid';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { mapGridSortingToApiFormat } from '../../utils/common';
import { LayoutBasedWidgetWrapper } from '../../utils/LayoutBasedWidgetWrapper';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { gridWidgetSkeletonConfig } from '../../config/skeleton';
import { CatalogDiscoveryWidget } from '../../config/widgets';
import { GridBasedWidgetNoData } from '../../utils/GridBasedWidgetNoData/GridBasedWidgetNoData';
import { analyticsService } from '../../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../../config/BigIdBiEvents';
import { AttributesHotspotTooltipContent } from './AttributesHotspotTooltipContent';

export interface AttributesLayoutProps extends Pick<UseCatalogDiscoveryResponse, 'query' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
  onAttributesWidgetFilterChange?: UseCatalogDiscoveryResponse['onAttributesWidgetFilterChange'];
  isClickable?: boolean;
}

export type AttributesGridRecord = BigidGridRow & AttributesGridAggregationItem;

const layoutContentTypeGridId = 'grid';
const layoutContentTypeHeatMapId = 'heatMap';

export const AttributesLayout: FC<AttributesLayoutProps> = memo(
  ({
    dataAid = 'AttributesLayout',
    dataTourId = 'AttributesLayout',
    query,
    onAttributesWidgetFilterChange,
    onDataFetchStatusChange,
    isPageInitialised,
    isClickable = true,
  }) => {
    const { fetchAttributesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const { layoutId } = useMemo(
      () => ({
        layoutId: `${dataAid}-${uuid()}`,
      }),
      [dataAid],
    );

    useEffect(() => {
      if (typeof query === 'string' && isPageInitialised) {
        entityEventsEmitter.emit(EntityEvents.RELOAD_WITH_FILTER, {
          entityId: layoutId,
          payload: {
            searchQuery: query,
          },
        });
      }
    }, [isPageInitialised, layoutId, query]);

    const gridConfig: BigidGridProps<AttributesGridRecord> = useMemo(
      () => ({
        dataAid,
        showSortingControls: true,
        showSelectionColumn: isClickable,
        showSelectionCheckboxes: isClickable,
        rowClickShouldKeepSelection: true,
        showSelectAll: false,
        noDataContent: <GridBasedWidgetNoData dataAid={generateDataAid(dataAid, ['no-data'])} />,
        defaultSorting: [
          {
            field: 'docCount',
            order: 'desc',
          },
        ],
        columns: [
          {
            title: t('grid.columns.name'),
            name: 'aggItemName',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ aggItemName }) => aggItemName,
            sortingEnabled: false,
            width: 400,
          },
          {
            title: t('grid.columns.numOfFindings'),
            name: 'findings',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ findings }) => formatNumberCompact(findings),
            width: 200,
          },
          {
            title: t('grid.columns.numOfObjects'),
            name: 'docCount',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ docCount }) => formatNumberCompact(docCount),
            width: 160,
          },
          {
            title: t('grid.columns.dataSources'),
            name: 'dataSourceCount',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ dataSourceCount }) => formatNumberCompact(dataSourceCount),
            width: 160,
          },
          {
            title: t('grid.columns.categories'),
            name: 'categories',
            type: BigidGridColumnTypes.TEXT,
            getCellValue: ({ categories }) =>
              categories?.length > 0 ? categories.join(', ') : t('grid.placeholders.noCategories'),
            sortingEnabled: false,
            width: 200,
          },
        ],
      }),
      [isClickable, dataAid, t],
    );

    const hotspotsChartConfig: BigidHotspotsChartConfig = useMemo(
      () => ({
        notClickable: !isClickable,
        paddingInner: 4,
        height: 412, //NOTE: a temporal solution, change once hotspot layout bug is fixed
        showOnlyOneMenu: true,
        tooltipText: ({ row }) => {
          return <AttributesHotspotTooltipContent row={row} />;
        },
        textTop: ({ row }) => {
          return row.aggItemName;
        },
        textBottom: ({ row }) => {
          return `${formatNumberCompact(row.findings)} ${t('hotspot.numOfFindings')}`;
        },
        fields: [
          {
            label: t('hotspot.fields.name'),
            value: 'aggItemName',
          },
          {
            label: t('hotspot.fields.friendlyName'),
            value: 'friendlyName',
          },
          {
            label: t('hotspot.fields.numOfFindings'),
            value: 'findings',
          },
          {
            label: t('hotspot.fields.numOfObjects'),
            value: 'docCount',
          },
          {
            label: t('hotspot.fields.dataSources'),
            value: 'dataSourceCount',
          },
        ],
        hotspotsChartColorField: 'docCount',
        hotspotsChartBoxSizeField: 'findings',
        hotspotsChartViewSize: pageSizeAvailableOptions[0],
      }),
      [isClickable, t],
    );

    const layoutConfig: BigidLayoutConfig = useMemo(
      () => ({
        layoutId,
        dataTourId,
        content: {
          initLoadingLabel: '',
          entityName: t('grid.entityName'),
          entityTooltip: t('grid.entityTooltip'),
          contentTypes: [LayoutContentType.GRID, LayoutContentType.HOTSPOTS_CHART],
          contentTypesExtended: [
            {
              id: layoutContentTypeGridId,
              name: t('toolbar.viewSwitchers.grid'),
              type: LayoutContentType.GRID,
              icon: BigidTableColumnsIcon,
            },
            {
              id: layoutContentTypeHeatMapId,
              name: t('toolbar.viewSwitchers.hotspot'),
              type: LayoutContentType.HOTSPOTS_CHART,
              icon: BigidHotspotIcon,
            },
          ],
          defaultContentType: LayoutContentType.HOTSPOTS_CHART,
          defaultContentId: layoutContentTypeHeatMapId,
          customToolbarComponent: <AttributesHotspotToolbar />,
          onContentLoadingStatusChange: (isLoading: boolean) => {
            onDataFetchStatusChange(CatalogDiscoveryWidget.ATTRIBUTES_LAYOUT, isLoading);
          },
          viewConfig: {
            fetchGridData: async (queryComponents, { searchQuery }) => {
              try {
                const { requireTotalCount, sort, skip, limit } = queryComponents;
                const payload: GetAggregatedDataPayload = {
                  filter: searchQuery,
                  aggregations: [
                    {
                      aggName: AggregationType.ATTRIBUTE_NAME,
                      sorting: mapGridSortingToApiFormat(sort),
                      paging: {
                        limit,
                        skip,
                      },
                      isGrid: true,
                    },
                  ],
                };
                const { aggregations } = await fetchAttributesCancelable(
                  getAggregatedData(payload) as Promise<GetAggregatedDataResponse<AttributesGridAggregationItem>>,
                );

                if (!aggregations?.[0]) {
                  return {
                    data: [],
                    totalCount: 0,
                  };
                }

                const { aggData } = aggregations[0];
                const totalCount = requireTotalCount ? aggData.length : undefined;

                const result: BigidGridDataFetchResult<AttributesGridRecord> = {
                  data: (aggData ?? []).map(aggDataItem => ({
                    ...aggDataItem,
                    id: aggDataItem.aggItemName,
                  })) as AttributesGridRecord[],
                  totalCount,
                };

                if (requireTotalCount && limit === totalCount) {
                  const payload: GetAggregatedDataPayload = {
                    filter: searchQuery,
                    aggregations: [
                      {
                        aggName: AggregationType.ATTRIBUTE_CARDINALITY,
                        isTotalRequired: true,
                      },
                    ],
                  };
                  const { promise, cancel } = getAggregatedDataCountCancellable(payload);
                  const fetchCount: FetchTotalCount = {
                    fetchCountCanceler: cancel,
                    fetchCountFunction: async () => {
                      try {
                        const {
                          data: { aggregations },
                        } = await promise;

                        return {
                          totalCount: aggregations[0]?.aggTotal,
                        };
                      } catch ({ message }) {
                        console.error(`An error has occurred: ${message}`);

                        return {
                          totalCount: undefined,
                        };
                      }
                    },
                  };

                  result.fetchCount = fetchCount;
                }

                return result;
              } catch ({ isCanceled, message }) {
                if (!isCanceled) {
                  console.error(`An error has occurred: ${message}`);
                }

                return {
                  data: [],
                  totalCount: 0,
                };
              }
            },
            gridConfig,
            hotspotsChartConfig,
            toolbarConfig: {
              hideColumnChooser: true,
              hideToolbar: false,
            },
          },
          toolbarActions: [
            {
              label: t('toolbar.actions.applyToFilter'),
              isGlobal: false,
              placement: 'end',
              execute: async ({ selectedRows }: ActionData) => {
                onAttributesWidgetFilterChange(selectedRows);
                analyticsService.trackManualEvent(BIGID_BI_EVENTS.DATAINSIGHTS_GRID_COMPONENT_APPLY_FILTER_CLICK);

                return Promise.resolve({
                  shouldGridReload: false,
                  shouldClearSelection: true,
                });
              },
              disable: () => false,
              show: ({ selectedRows }) => selectedRows.length > 0 && Boolean(onAttributesWidgetFilterChange),
            },
          ],
        },
      }),
      [
        dataTourId,
        fetchAttributesCancelable,
        gridConfig,
        hotspotsChartConfig,
        layoutId,
        onAttributesWidgetFilterChange,
        onDataFetchStatusChange,
        t,
      ],
    );

    return (
      <LayoutBasedWidgetWrapper dataAid={dataAid} dataTourId={dataTourId} isReady={isPageInitialised}>
        {isPageInitialised ? (
          <BigidLayout config={layoutConfig} />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...gridWidgetSkeletonConfig} />
        )}
      </LayoutBasedWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
