import React from 'react';
import { BigidBody1 } from '@bigid-ui/components';
import { Trans } from 'react-i18next';
import { ScanTemplateGuidedTourStart } from '../ScanTemplates/GuidedTour/ScanTemplateGuidedTourStart';
import { SelectorModifier, getGuidedTourIdSelector } from '@bigid-ui/utils';
import { FavoriteTemplateBody } from '../ScanTemplates/GuidedTour/FavoriteTemplateBody';
import { GuidedTourType } from './stepTypes';
import { ConvertOldContent } from '../PlannedScans/GuidedTour/ConvertOldContent';
import converter from '../PlannedScans/GuidedTour/converter.json';
import { Step } from '@bigid-ui/guided-tour';
import BigidClassifiersGuidedTourSvg from '../../../assets/icons/BigidClassifiersGuidedTour.svg';
import BigidGuidedTourScanTemplateStartSvg from '../../../assets/icons/BigidGuidedTourScanTemplateStart.svg';
import { getFixedT } from '../translations';
import { ViewScanProfileContent } from '../PlannedScans/GuidedTour/ViewScanProfileContent';
import { ContentStartTourStep } from '../PlannedScans/GuidedTour/ContentStartTourStep';

const t = getFixedT('guidedTour');
const getTranslation = (key: string): string => t(key);

const scanTemplatesSteps: Step[] = [
  {
    carouselItems: [
      {
        type: 'node',
        content: <BigidGuidedTourScanTemplateStartSvg />,
        styles: { background: 'linear-gradient(257deg, #1737a7 8.06%, #7446d0 100%)' },
      },
    ],
    title: getTranslation('scanTemplates.start.title'),
    content: <ScanTemplateGuidedTourStart />,
    disableBeacon: true,
    placement: 'center',
    hideBackButton: true,
    target: '[data-tour="start"]',
    dataAid: 'ScanTemplateGuidedTourStep',
    inverseXButtonColor: true,
  },
  {
    title: getTranslation('scanTemplates.templates.title'),
    content: getTranslation('scanTemplates.templates.content'),
    disableBeacon: true,
    placement: 'top-center' as any,
    hideBackButton: true,
    target: getGuidedTourIdSelector('BigidGridCardView-container'),
    dataAid: 'ScanTemplateGuidedTourStep',
  },
  {
    title: getTranslation('scanTemplates.favoriteTemplates.title'),
    content: <FavoriteTemplateBody />,
    target: getGuidedTourIdSelector('ScanTemplateFavoritesIcon-', [], SelectorModifier.CONTAINS),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'right-start',
    dataAid: 'ScanTemplateGuidedTourStep',
  },
  {
    title: getTranslation('scanTemplates.addingTemplates.title'),
    content: getTranslation('scanTemplates.addingTemplates.content'),
    target: getGuidedTourIdSelector('create-new-template-button'),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'left',
    dataAid: 'ScanTemplateGuidedTourStep',
  },
];

const classificationSteps: Step[] = [
  {
    carouselItems: [
      {
        type: 'node',
        content: <BigidClassifiersGuidedTourSvg />,
        styles: { background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)' },
      },
    ],
    title: getTranslation('classification.start.title'),
    content: getTranslation('classification.start.content'),
    disableBeacon: true,
    placement: 'center',
    hideBackButton: true,
    target: '[data-tour="start"]',
    dataAid: 'ClassificationGuidedTourStep',
  },
  {
    title: getTranslation('classification.filterClassifiers.title'),
    content: getTranslation('classification.filterClassifiers.content'),
    disableBeacon: true,
    placement: 'bottom',
    hideBackButton: true,
    target: getGuidedTourIdSelector('BigidFancyTabs'),
    dataAid: 'ClassificationGuidedTourStep',
  },
  {
    title: getTranslation('classification.chooseClassifiers.title'),
    content: (
      <BigidBody1>
        <Trans
          t={t}
          i18nKey="classification.chooseClassifiers.content"
          components={{ bold: <BigidBody1 fontWeight={700} display="inline-block" /> }}
        />
      </BigidBody1>
    ),
    target: getGuidedTourIdSelector('BigidCard-', [], SelectorModifier.CONTAINS),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'right',
    dataAid: 'ClassificationGuidedTourStep',
  },
  {
    title: getTranslation('classification.globalClassifiers.title'),
    content: getTranslation('classification.globalClassifiers.content'),
    target: getGuidedTourIdSelector('BigidSwitch-useglobalclassifiersswitch'),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'right',
    dataAid: 'ClassificationGuidedTourStep',
  },
  {
    title: 'Manually select classifiers',
    content: (
      <BigidBody1>
        <Trans
          t={t}
          i18nKey="classification.selectClassifiers.content"
          components={{ bold: <BigidBody1 fontWeight={700} display="inline-block" /> }}
        />
      </BigidBody1>
    ),
    target: getGuidedTourIdSelector('BigidToolbarAction-global-add-classifiers'),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'right',
    dataAid: 'ClassificationGuidedTourStep',
  },
];

const plannedScansSteps: Step[] = [
  {
    title: getTranslation('plannedScans.start.title'),
    carouselItems: [
      {
        type: 'lottie',
        json: converter,
        styles: { background: 'linear-gradient(257deg, #1737a7 8.06%, #7446d0 100%)' },
      },
    ],
    content: <ContentStartTourStep />,
    disableBeacon: true,
    placement: 'center',
    hideBackButton: true,
    target: '[data-tour="start"]',
    dataAid: 'PlannedScansGuidedTourStep',
    inverseXButtonColor: true,
    showSkipButton: true,
  },
  {
    title: getTranslation('plannedScans.convert.title'),
    content: <ConvertOldContent />,
    target: getGuidedTourIdSelector('BigidGridRow-', [], SelectorModifier.CONTAINS),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'bottom-start',
    dataAid: 'PlannedScansGuidedTourStep',
  },
  {
    title: getTranslation('plannedScans.viewScanProfile.title'),
    content: <ViewScanProfileContent />,
    target: getGuidedTourIdSelector('BigidGridRow-', [], SelectorModifier.CONTAINS),
    disableBeacon: true,
    hideBackButton: false,
    placement: 'bottom-end',
    dataAid: 'PlannedScansGuidedTourStep',
  },
];

export const getStepsByName = (name: GuidedTourType) => {
  switch (name) {
    case GuidedTourType.classification:
      return classificationSteps;
    case GuidedTourType.scanTemplates:
      return scanTemplatesSteps;
    case GuidedTourType.plannedScans:
      return plannedScansSteps;
    default:
      break;
  }
};
