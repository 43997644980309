import React, { ReactText } from 'react';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { Finding, CuratedAttributeIdentifier, FindingDetails, CuratedAttributeType } from '../../../../curationService';
import { AttributePreviewField, AttributePreviewFieldValue, AttributePreviewGridRecord } from './AttributePreview';
import { AttributePreviewCuratedValue } from './AttributePreviewCuratedValue';
import { v4 as uuid } from 'uuid';
import { isEmpty } from 'lodash';
import { BigidColors } from '@bigid-ui/components';

export const ID_COL = Symbol('idCol');

function getRecordField(fieldName: string) {
  return fieldName === 'id' ? ID_COL : fieldName;
}

export function getIsFindingHighlighted(finding: FindingDetails, attributeId: CuratedAttributeIdentifier): boolean {
  return finding.attributeName === attributeId.attributeName && finding.attributeType === attributeId.attributeType;
}

export function getGridData(findings: Finding[], attributeId: CuratedAttributeIdentifier): AttributePreviewField[] {
  return findings.reduce((rows, { fields }) => {
    const row: AttributePreviewField = fields.reduce((gridRow, field) => {
      const { fieldName, fieldValueChunks } = field;

      if (fieldValueChunks?.length > 0) {
        const { chunkValue: fieldValue, findingDetails } = fieldValueChunks[0];
        return {
          ...gridRow,
          [fieldName]: {
            fieldValue,
            findingDetails: findingDetails.filter(finding => getIsFindingHighlighted(finding, attributeId)),
          },
        };
      } else {
        return {
          ...gridRow,
          [fieldName]: {
            fieldValue: null,
            findings: [],
          },
        };
      }
    }, {});

    return [...rows, row];
  }, []);
}

export function getGridRows(records: AttributePreviewField[]): AttributePreviewGridRecord[] {
  return records.map(record => ({ ...record, id: uuid(), ...('id' in record && { [ID_COL]: record.id }) }));
}

export function getGridColumns(
  gridId: ReactText,
  findings: Finding[],
  fieldId: ReactText,
  attributeId: CuratedAttributeIdentifier,
  targetedFieldName: string,
  onReload: () => void,
): BigidGridColumn<AttributePreviewGridRecord>[] {
  const [finding] = findings;

  const firstField = finding.fields.find(({ fieldName }) => fieldName === targetedFieldName);
  const hasFindings = !isEmpty(firstField.fieldValueChunks[0]?.findingDetails);
  const classificationMdType = attributeId.attributeType === CuratedAttributeType.CLASSIFICATION_MD;

  const firstColumn: BigidGridColumn<AttributePreviewGridRecord> = {
    title: targetedFieldName,
    name: targetedFieldName,
    ...(hasFindings
      ? {
          type: BigidGridColumnTypes.CUSTOM,
          width: 450,
          getCellValue: record => {
            return (
              <AttributePreviewCuratedValue
                gridId={gridId}
                rowId={record.id}
                fieldId={fieldId}
                fieldName={targetedFieldName}
                onReload={onReload}
                {...(record[getRecordField(targetedFieldName)] as AttributePreviewFieldValue)}
              />
            );
          },
        }
      : {
          type: BigidGridColumnTypes.TEXT,
          width: 250,
          getCellValue: record => {
            return (record[getRecordField(targetedFieldName)] as AttributePreviewFieldValue).fieldValue;
          },
        }),
  };

  if (classificationMdType) {
    firstColumn.highlight = {
      color: BigidColors.green[700],
      text: `Findings in this column: ${targetedFieldName}`,
    };
  }

  const columns: BigidGridColumn<AttributePreviewGridRecord>[] = finding.fields.map(({ fieldName }) => {
    if (fieldName === targetedFieldName) {
      return;
    }

    return {
      title: fieldName,
      name: fieldName,
      type: BigidGridColumnTypes.TEXT,
      width: 250,
      getCellValue: record => {
        return (record[getRecordField(fieldName)] as AttributePreviewFieldValue)?.fieldValue;
      },
    };
  });

  columns.unshift(firstColumn);
  return columns.filter(Boolean);
}
