import React, { FC, useState, ReactText } from 'react';
import angular from 'angular';
import { orderBy } from 'lodash';
import { BigidDialog, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import {
  BigidGrid,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridProps,
  BigidGridSorting,
  useFetch,
} from '@bigid-ui/grid';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { SystemRole } from '../../../services/userPermissionsService';

interface RolePickerProps {
  isOpen: boolean;
  onClose: () => void;
  onConnectRoles: (selectedRowIds: ReactText[]) => void;
  selectedRoles?: string[];
}

interface Role extends SystemRole {
  id: string;
  displayName: string;
  [key: string]: any;
}

const columns: BigidGridColumn<Role>[] = [
  {
    name: 'displayName',
    title: 'Name',
    getCellValue: (row: Role) => row.displayName,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'description',
    title: 'Description',
    getCellValue: (row: Role) => row.description,
    type: BigidGridColumnTypes.TEXT,
  },
];

const sortRoles = (sort: BigidGridSorting[], roles: Role[]) => {
  const fields = sort.map(
    ({ field }) =>
      (role: Role) =>
        role[field]?.toLowerCase(),
  );
  const orders = sort.map(({ order }) => order);
  return orderBy(roles, fields, orders);
};

export const RolePicker: FC<RolePickerProps> = ({ isOpen = false, onClose, onConnectRoles, selectedRoles = [] }) => {
  const [selectedRowIds, setSelectedRowIds] = useState<ReactText[]>([]);
  const onCloseDialog = () => {
    setSelectedRowIds([]);
    onClose();
  };

  const onConnectClick = () => {
    onConnectRoles(selectedRowIds);
    onCloseDialog();
  };

  const useFetchState = useFetch({
    fetchDataFunction: async ({ sort }) => {
      try {
        const {
          data: {
            data: { roles },
          },
        } = await httpService.fetch<{ data: { roles: Role[] } }>('access-management/roles');

        const sortedData = sortRoles(
          sort,
          roles.map((role: Role) => ({
            ...role,
            id: role._id,
          })),
        );

        return Promise.resolve({
          data: sortedData,
          totalCount: sortedData.length,
        });
      } catch (err) {
        notificationService.error(`Failed to get roles.`);
      }
    },
  });

  const gridConfig: BigidGridProps<Role> = {
    columns,
    showFilteringControls: false,
    showSortingControls: true,
    showSelectionColumn: true,
    rows: useFetchState.rows as Role[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    selectedRowIds,
    onSelectedRowIdsChanged: setSelectedRowIds,
    isRowDisabled: (row: Role) => selectedRoles.includes(row._id),
    showSelectAll: false,
  };

  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onCloseDialog}
      title="Connect Role to User"
      showCloseIcon
      buttons={[
        { text: 'Cancel', onClick: onCloseDialog, component: SecondaryButton },
        { text: 'Connect Role', onClick: onConnectClick, component: PrimaryButton },
      ]}
      isContentScrollable
    >
      <BigidGrid {...gridConfig} />
    </BigidDialog>
  );
};

angular
  .module('app')
  .component('rolePicker', convertToAngular(RolePicker, ['isOpen', 'onClose', 'onConnectRoles', 'selectedRoles']));
