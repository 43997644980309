import React, { FC, useContext } from 'react';
import { DataSourceConfigurationContext } from '../DataSourceConfigurationContext';
import { BigidHeading4, BigidBody1, BigidIcon } from '@bigid-ui/components';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BigidDataSourceNotFound from '../../../../assets/icons/BigidDataSourceNotFound.svg';

const useStyles = makeStyles({
  emptyStateContainer: {
    minHeight: '100%',
    flex: '1 1 auto',
    overflow: 'auto',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  contactWrapper: {
    marginTop: '16px',
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    padding: '130px 0 32px 0',
    justifyContent: 'center',
  },
  titleWrapper: {
    paddingBottom: '4px',
  },
});

export const DEFAULT_NOT_FOUND_DS_TITLE = 'Data Source Is Not Accessible for one of the following reasons:';

export const DEFAULT_NOT_FOUND_REASONS = [
  'The data source was deleted from the system',
  'You don’t have permission to view this data source',
  'Your scope does not include this data source',
];

export interface DataSourceConfigurationNoDataProps {
  title: string;
  reasonList: string[];
}

export const DataSourceConfigurationEmptyState: FC<DataSourceConfigurationNoDataProps> = ({ title, reasonList }) => {
  const { emptyStateContainer, contactWrapper, iconWrapper, titleWrapper } = useStyles({});

  return (
    <Paper className={emptyStateContainer} data-aid="dataSourceConfigurationNoData">
      <div data-aid="dataSourceConfigurationNoDataInnerContainer">
        <div className={iconWrapper}>
          <BigidIcon icon={BigidDataSourceNotFound} />
        </div>
        <BigidHeading4 className={titleWrapper}>{title}</BigidHeading4>
        <BigidBody1>
          {reasonList.map((text, index) => (
            <li key={index}>{text}</li>
          ))}
        </BigidBody1>
        <BigidBody1 className={contactWrapper}>Please contact your administrator for more information</BigidBody1>
      </div>
    </Paper>
  );
};

export const DataSourceConfigurationNoData: FC<DataSourceConfigurationNoDataProps> = props => {
  const { notFoundMessage, isLoading } = useContext(DataSourceConfigurationContext);

  return notFoundMessage && !isLoading ? <DataSourceConfigurationEmptyState {...props} /> : null;
};
