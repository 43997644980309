import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { CONFIG } from '../../../config/common';
import { MetadataSearchVariant } from '../../components/MetadataSearch/MetadataSearch';
import { $state } from '../../services/angularServices';
import { DataExplorerSearchBar } from './DataExplorerSearchBar';
import { SearchGrid } from './SearchGrid/CatalogSearchGrid';
import { ProcessingIndicator } from './SearchGrid/GridComponents/ProcessingIndicator/ProcessingIndicator';
import { useLocalTranslation } from './translations';
import { WithDataAid } from './types';
import {
  DataExplorerSearchSubmitBiPayload,
  sendDataExplorerResultsPageBarSubmitBiAnalytics,
} from '../DataExplorer/services/analytics';
import { MetadataSearchTransitionPayload } from '../../components/MetadataSearch/MetadataSearchTypes';

const SearchBarContainer = styled('div')`
  padding: 0 12px 0 16px;
  width: 640px;
  height: 40px;
`;

const Container = styled('div')`
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
`;

export const DataExplorerSearchResultsData: FC<WithDataAid> = ({ dataAid }) => {
  const { t } = useLocalTranslation();

  useEffect(() => {
    pageHeaderService.setTitle({
      breadcrumbs: [
        {
          label: t('header.parentPageName'),
          onClick: () => $state.go(CONFIG.states.DATA_EXPLORER),
        },
        {
          label: t('header.searchResults'),
        },
      ],
      rightSideComponentsContainer: <ProcessingIndicator />,
    });
  }, [t]);

  const handleSearchBarSubmit = (payload: MetadataSearchTransitionPayload) => {
    const biPayload: DataExplorerSearchSubmitBiPayload = {
      searchText: payload.text,
    };

    sendDataExplorerResultsPageBarSubmitBiAnalytics(biPayload);
  };

  return (
    <Container data-aid={generateDataAid(dataAid, ['container'])}>
      <SearchBarContainer>
        <DataExplorerSearchBar
          variant={MetadataSearchVariant.ENLARGED}
          dataAid={generateDataAid(dataAid, ['search', 'bar'])}
          placeholder={t('searchBar.placeholder')}
          onSubmit={handleSearchBarSubmit}
        />
      </SearchBarContainer>
      <SearchGrid dataAid={generateDataAid(dataAid, ['search', 'grid'])} />
    </Container>
  );
};
