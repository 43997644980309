import { httpService } from '../../../../services/httpService';
import { DataSourceConfigurationContextInterface } from '../DataSourceConfigurationContext';
import { notificationService } from '../../../../services/notificationService';
import { openConfigWindow } from '../../../../../administration/oauthWindow/trayOauthUtils';
import { BigidFormValues } from '@bigid-ui/components';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { CustomDsFieldItem } from '../fields/FormCustomParametersField';
import { MapTypeValueNormalizeBase } from '../utils/prepareDataSourceFormValueDataForSend';
import { DsTypesEnum } from '../types';
import { isNewConnectivityExperienceEnabled } from '../utils/isNewConnectivityExperienceEnabled';

const NEW_CONNECTORS_OAUTH_FF = 'NEW_CONNECTORS_OAUTH_FF';

const parametersMap = new Map<string, string>();

export type OauthActionType = (
  value: string,
  stateAndHandlers: DataSourceConfigurationContextInterface,
) => Promise<string | boolean>;

export const isReadyForOauthAction: OauthActionType = async (oauthConnectionType, { isTouched, onSubmitHandler }) => {
  const isSaved = !isTouched.current || (await onSubmitHandler());
  if (!isSaved || !oauthConnectionType) {
    notificationService.warning('Please save Data Source before connecting Oauth');
    return false;
  }
  return true;
};

const isReadyForTrayOauthAction: OauthActionType = async (oauthConnectionType, { isTouched, onSubmitHandler }) => {
  const isSaved = await onSubmitHandler();
  if (!isSaved || !oauthConnectionType) {
    notificationService.warning('Please save Data Source before connecting Oauth');
    return false;
  }
  return true;
};

const getCallbackBaseUrl = () => {
  const { hostname, protocol, port } = window.location;
  return `${protocol}//${hostname}${port ? ':' + port : ''}`;
};

const isLegacyOAuth = (dsType: string) => {
  return !getApplicationPreference(NEW_CONNECTORS_OAUTH_FF) || dsType in legacyOauthConnectors;
};

export const connectOauth: OauthActionType = async (
  oauthConnectionType: string,
  stateAndHandlers: DataSourceConfigurationContextInterface,
) => {
  parametersMap.clear();
  let oauthValidationPassed = false,
    isError = false;
  const { id, getValuesContainer, isNewPassword, oAuthHandlers } = stateAndHandlers;
  const formValues = getValuesContainer.current();
  const values: BigidFormValues = {
    ...formValues,
    customFields: MapTypeValueNormalizeBase[DsTypesEnum.customFieldsArray](formValues?.customFields),
  };

  const isNewExperienceEnabled: boolean = isNewConnectivityExperienceEnabled(values.type);
  oauthValidationPassed = (await Promise.resolve(
    isNewExperienceEnabled
      ? oAuthHandlers?.onStartConnection?.()
      : isReadyForOauthAction(oauthConnectionType, stateAndHandlers),
  )) as boolean;

  if (!oauthValidationPassed) return oauthConnectionType;

  const dsType = values.type;
  const legacyOAuth = isLegacyOAuth(dsType);

  let baseApi = 'oauth';
  if (legacyOAuth) {
    baseApi = `/api/v1/oauth-legacy`;
  }
  let destinationUrl = `${baseApi}/${oauthConnectionType}/${
    values?.name || id
  }?callbackBaseUrl=${getCallbackBaseUrl()}&type=${values.type}&redirectToNewConnectivity=${isNewExperienceEnabled}`;

  try {
    if (oauthConnectionType in typeToFunction) {
      typeToFunction[dsType](values, isNewPassword);
    } else if (isCustomOauthAuthorizationServer(values)) {
      addOauthCustomParams(values, isNewPassword);
    }
  } catch (err) {
    isError = true;
    notificationService.error('Please check whether all parameters for the authorization connection are filled');
    console.error('Add params error', err);
  }

  if (parametersMap.size > 0) {
    const joinedParameters = Array.from(parametersMap.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    destinationUrl = destinationUrl.concat(`&${joinedParameters}`);
  }

  if (legacyOAuth) {
    window.location.href = destinationUrl;
    return oauthConnectionType;
  }

  const res = await httpService.fetch(destinationUrl).catch(err => {
    isError = true;
    notificationService.error(err?.response?.data?.message || err.message || 'Failed to authenticate using OAuth');
  });
  await stateAndHandlers?.oAuthHandlers?.onFinishConnection?.(isError);
  if (!res) {
    return oauthConnectionType;
  }
  window.open(res.data.url, '_self');
  return oauthConnectionType;
};

export const connectTrayOauth: OauthActionType = async (
  oauthConnectionType: string,
  stateAndHandlers: DataSourceConfigurationContextInterface,
) => {
  let { id } = stateAndHandlers;

  if (!(await isReadyForTrayOauthAction(oauthConnectionType, stateAndHandlers))) {
    return;
  }

  const { getValuesContainer, createSetValueFunction, updateState } = stateAndHandlers;
  const values = getValuesContainer.current();
  let ds_connection: any;

  function trayAuthCallback(configData: { authId: any; ds_connection: any }) {
    console.log('in tray oauth callback');
    updateState({ isLoading: true });
    const values = getValuesContainer.current();
    createSetValueFunction.current('isOauthConfigured')(true);

    ds_connection.tray.auth_id = configData.authId;
    ds_connection.isOauthConfigured = true;

    //going to enable tray solution instance
    id = values.name;
    console.log('going to update db with oauth response with auth id: ' + configData.authId);

    delete ds_connection._id;
    httpService
      .put('ds_connections/' + id, {
        ds_connection: ds_connection,
      })
      .then(httpResponse => {
        console.log('going to update db with tray info: ' + httpResponse.data.tray);
        createSetValueFunction.current('tray')(httpResponse.data.tray);

        //workaround bigid.me defect, if enable failed we wont receive me_id in the response but still receive 201 created status code.
        if (httpResponse.data.tray.me_id) {
          createSetValueFunction.current('hiddenEnableSave')('true');
          createSetValueFunction.current('isOauthConfigured')(true);
          updateState({ isLoading: false });
          window.location.reload();
          notificationService.success('OAuth configuration for DSAR connector ' + values.name + ' set successfully!');
        } else {
          createSetValueFunction.current('isOauthConfigured')(false);
          ds_connection.isOauthConfigured = false;
          createSetValueFunction.current('hiddenEnableSave')(null);
          updateState({ isLoading: false });
          notificationService.error('OAuth configuration for DSAR connector ' + values.name + ' has failed');
        }
      })
      .catch(error => {
        console.log('failed to  tray oauth error callback, error: ' + error);

        createSetValueFunction.current('isOauthConfigured')(false);
        ds_connection.isOauthConfigured = false;
        createSetValueFunction.current('hiddenEnableSave')(null);
        updateState({ isLoading: false });

        if (error.response && error.response.status == 400) {
          notificationService.error(
            'Enabling DSAR connector ' +
              values.name +
              ' has failed, datasource name already exists, try recreate the datasource with new name',
            { duration: 10000 },
          );
        } else {
          notificationService.error('OAuth configuration for DSAR connector ' + values.name + ' has failed');
        }
      })
      .finally(() => {
        updateState({ isSaveInProgress: false });
      });
  }

  function authError() {
    console.log('in tray oauth error callback');
    createSetValueFunction.current('isOauthConfigured')(false);
    ds_connection.isOauthConfigured = false;
    notificationService.error('OAuth configuration for DSAR connector ' + values.name + ' did not complete');
  }

  //case of insert, or oauth not set
  if (!id || !values.isOauthConfigured) {
    const response = await httpService.fetch(`ds_connections/` + values.name);
    ds_connection = response.data.ds_connection;
    const destinationUrl = ds_connection.tray.auth_url;

    openConfigWindow(destinationUrl, trayAuthCallback, authError);
  }

  return true;
};

export const disconnectOauth: OauthActionType = async (value, stateAndHandlers) => {
  let isError = false;
  const { getValuesContainer, oAuthHandlers } = stateAndHandlers;
  const dsType = getValuesContainer?.current()?.type;
  let oauthValidationPassed = false;
  const isNewExperienceEnabled: boolean = isNewConnectivityExperienceEnabled(dsType);
  oauthValidationPassed = (await Promise.resolve(
    isNewExperienceEnabled ? oAuthHandlers?.onStartDisconnection?.() : isReadyForOauthAction(value, stateAndHandlers),
  )) as boolean;

  if (!oauthValidationPassed) return oauthValidationPassed;

  const { id, updateState, refreshData, resetValuesToInitFunction } = stateAndHandlers;
  try {
    updateState({ isSaveInProgress: true });
    await httpService.fetch(`ds_connections/disconnect-oauth/${id}`);
    await refreshData();
    resetValuesToInitFunction.current();
    return value;
  } catch (error) {
    isError = true;
    notificationService.warning('Error on OAuth disconnect');
  } finally {
    await stateAndHandlers?.oAuthHandlers?.onFinishDisconnection?.(isError);
    updateState({ isSaveInProgress: false });
  }
};

function addWorkdayOauthParams(value: BigidFormValues, isNewPassword: boolean): void {
  const {
    oAuth2Authentication: { clientId, client_secret_enc, authorization_url },
    resourceProperties: { resourceEntry, resourceAddress },
  } = value;

  parametersMap.set('host', resourceAddress);
  parametersMap.set('tenant', resourceEntry);
  parametersMap.set('authorizationHost', authorization_url);
  parametersMap.set('clientId', clientId);
  parametersMap.set('clientSecret', encodeURIComponent(client_secret_enc));
  addIsNewPasswordParamIfNeeded(isNewPassword);
}

function addJiraOauthParams(values: BigidFormValues, isNewPassword: boolean): void {
  addOauthCustomParams(values, isNewPassword);
  const { serviceUrl } = values;

  const matched = /https?:\/\/(\S+?)\./.exec(serviceUrl);
  if (matched && matched.length > 1) parametersMap.set('siteName', matched[1]);
}

function addDocusignOauthParams(values: BigidFormValues): void {
  const {
    oAuth2Authentication: { clientId },
    connectionProperties: {
      connectionData: { environment },
    },
  } = values;

  const environmentName = Array.isArray(environment) ? environment[0].value : 'PROD';
  parametersMap.set('clientId', encodeURIComponent(clientId));
  parametersMap.set('environment', environmentName);
}

function addDropboxOauthParams(values: BigidFormValues, isNewPassword: boolean): void {
  const {
    oAuth2Authentication: { clientId, client_secret_enc, access_code_enc },
  } = values;
  parametersMap.set('accessCodeEnc', encodeURIComponent(access_code_enc));
  parametersMap.set('clientId', encodeURIComponent(clientId));
  parametersMap.set('clientSecret', encodeURIComponent(client_secret_enc));
  addIsNewPasswordParamIfNeeded(isNewPassword);
}

function addSalesforceOauthParams(values: BigidFormValues): void {
  const { salesforceCustomUrl } = values;
  if (salesforceCustomUrl) parametersMap.set('oauthTokenHost', salesforceCustomUrl);
}

function addSnowflakeOauthParams(values: BigidFormValues, isNewPassword: boolean): void {
  const { authMethod, clientId, clientSecret, oauthScope, pingFederateServerURL, ...restParams } = values;
  const [{ value: authMethodValue }] = authMethod;
  parametersMap.set('authMethod', authMethodValue);
  parametersMap.set('clientId', encodeURIComponent(clientId));
  if (
    authMethodValue === 'azure_authorization_code' ||
    authMethodValue === 'snowflake_oauth' ||
    authMethodValue === 'pingfederate_oauth'
  ) {
    parametersMap.set('clientSecret', encodeURIComponent(clientSecret));
    addIsNewPasswordParamIfNeeded(isNewPassword);
  }
  if (authMethodValue === 'azure_authorization_code' || authMethodValue === 'azure_client_credentials') {
    const { tenantId } = restParams;
    parametersMap.set('tenantId', tenantId);
  }
  if (authMethodValue === 'snowflake_oauth') {
    const { account, regionId, customFields } = restParams;
    const customRegion = customFields?.find(
      (field: CustomDsFieldItem) => field.enable && field.field_name === 'custom_region_id',
    );
    const region = customRegion?.field_value ? customRegion.field_value : regionId?.[0]?.value?.regionId;
    const accountIdentifier = region ? `${account}.${region}` : account;

    parametersMap.set('accountIdentifier', accountIdentifier);
  }
  if (authMethodValue === 'pingfederate_oauth') {
    parametersMap.set('pingFederateServerURL', encodeURIComponent(pingFederateServerURL));
  }
  if (oauthScope) {
    parametersMap.set('scope', encodeURIComponent(oauthScope));
  }
}

function addFilenetOauthParams(values: BigidFormValues, isNewPassword: boolean): void {
  const {
    oAuth2Authentication: { clientId, client_secret_enc },
    resourceProperties: { resourceEntry: oidcConfigUrl },
  } = values;

  parametersMap.set('oidcConfigUrl', oidcConfigUrl);
  parametersMap.set('clientId', encodeURIComponent(clientId));
  parametersMap.set('clientSecret', encodeURIComponent(client_secret_enc));
  addIsNewPasswordParamIfNeeded(isNewPassword);
}

function addSubdomainOauthParams(values: BigidFormValues): void {
  const {
    resourceProperties: { resourceAddress },
  } = values;
  parametersMap.set('accountSubdomain', resourceAddress);
}

function addIManageOauthParams(values: BigidFormValues, isNewPassword: boolean): void {
  const {
    resourceProperties: { resourceAddress },
    extendedOAuth2Authentication: { username, password_enc },
    authenticationType: [{ value: authTypeValue }],
  } = values;
  parametersMap.set('authType', authTypeValue);
  parametersMap.set('host', resourceAddress);
  parametersMap.set('username', encodeURIComponent(username));
  parametersMap.set('password', encodeURIComponent(password_enc));
  addIsNewPasswordParamIfNeeded(isNewPassword);
}

function isCustomOauthAuthorizationServer(values: BigidFormValues): boolean {
  return (values.oAuth2Authentication && values?.oAuth2Authentication.url) || 'oauthURL' in values;
}

function addOauthCustomParams(values: BigidFormValues, isNewPassword: boolean): void {
  if ('oAuth2Authentication' in values) {
    const {
      oAuth2Authentication: { url, clientId, client_secret_enc },
    } = values;
    addOauthGivenParams(url, clientId, client_secret_enc, isNewPassword);
  } else {
    const { oauthURL, clientId, clientSecret } = values;
    addOauthGivenParams(oauthURL, clientId, clientSecret, isNewPassword);
  }
}

function addOauthGivenParams(oauthURL: string, clientId: string, clientSecret: string, isNewPassword: boolean): void {
  parametersMap.set('oauthTokenHost', oauthURL);
  parametersMap.set('clientId', encodeURIComponent(clientId));
  parametersMap.set('clientSecret', encodeURIComponent(clientSecret));
  addIsNewPasswordParamIfNeeded(isNewPassword);
}

function addIsNewPasswordParamIfNeeded(isNewPassword: boolean): void {
  if (isNewPassword || isNewPassword === true) {
    parametersMap.set('isNewPassword', 'true');
  }
}

const typeToFunction: Record<string, (value: BigidFormValues, isNewPassword: boolean) => void> = {
  aha: addSubdomainOauthParams,
  docusign: addDocusignOauthParams,
  dropbox: addDropboxOauthParams,
  filenet: addFilenetOauthParams,
  jira: addJiraOauthParams,
  salesforce: addSalesforceOauthParams,
  snowflake: addSnowflakeOauthParams,
  'workday-v2': addWorkdayOauthParams,
  'workday-v2-wql': addWorkdayOauthParams,
  'zendesk-v2': addSubdomainOauthParams,
  imanage: addIManageOauthParams,
};

const legacyOauthConnectors: Set<string> = new Set(['dropbox', 'generic-cloud']);
