import React, { FC, useState, useEffect } from 'react';
import { BigidGuidedTour, Step } from '@bigid-ui/guided-tour';
import { CallBackProps, STATUS } from 'react-joyride';
import { BigidLink } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import { NavigationGuidedTourStep } from './NavigationGuidedTourStep';
import stepOneLottieJson from './lottie/Step1.json';
import stepTwoLottieJson from './lottie/Step2.json';
import stepThreeLottieJson from './lottie/Step3.json';
import stepFourLottieJson from './lottie/Step4.json';
import stepFiveLottieJson from './lottie/Step5.json';
import { HeaderEvents, headerEventEmitter } from '../../../services/eventEmitters/headerEvents';
import { docsUrls } from '../../../config/publicUrls';
import { userPreferencesService } from '../../../services/userPreferencesService';

interface NavigationGuidedTourProps {
  dataAid?: string;
}

export const NavigationGuidedTour: FC<NavigationGuidedTourProps> = ({ dataAid = 'NavigationGuidedTour' }) => {
  const { t } = useLocalTranslation('guidedTour.steps');
  const [isTourRunning, setIsTourRunning] = useState(false);

  useEffect(() => {
    const unregister = headerEventEmitter.addEventListener(HeaderEvents.SHOW_NAVIGATION_GUIDED_TOUR, () =>
      setIsTourRunning(true),
    );

    return () => {
      unregister();
    };
  }, []);

  const joyrideCallback = ({ status }: CallBackProps) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsTourRunning(false);
    }
  };

  const steps: Step[] = [
    {
      carouselItems: [
        {
          type: 'lottie',
          json: stepOneLottieJson,
          styles: { background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)' },
        },
        {
          type: 'lottie',
          json: stepThreeLottieJson,
          styles: { background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)' },
        },
        {
          type: 'lottie',
          json: stepFourLottieJson,
          styles: { background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)' },
        },
        {
          type: 'lottie',
          json: stepFiveLottieJson,
          styles: { background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)' },
        },
      ],
      title: t('start.title'),
      content: t('start.content'),
      target: '#app',
      disableBeacon: true,
      placement: 'center',
      slotContent: (
        <BigidLink text={t('start.link')} underline="hover" href={docsUrls.NAVIGATION_PANEL} shouldOpenNewTab />
      ),
      dataAid: generateDataAid(dataAid, ['start']),
      inverseXButtonColor: true,
    },
    {
      title: t('toggle.title'),
      content: <NavigationGuidedTourStep lottieJson={stepTwoLottieJson} textContent={t('toggle.content')} />,
      target: '[data-aid="dualNavigationMenuToggle"]',
      disableBeacon: true,
      placement: 'right-end',
      dataAid: generateDataAid(dataAid, ['toggle']),
    },
    {
      title: t('order.title'),
      content: <NavigationGuidedTourStep lottieJson={stepThreeLottieJson} textContent={t('order.content')} />,
      target: '[data-aid="BigidNavigationItem-data-explorer"]',
      disableBeacon: true,
      placement: 'right',
      dataAid: generateDataAid(dataAid, ['order']),
    },
    {
      title: t('favorites.title'),
      content: <NavigationGuidedTourStep lottieJson={stepFourLottieJson} textContent={t('favorites.content')} />,
      target: '[data-aid="BigidNavigationItem-favorites"]',
      disableBeacon: true,
      placement: 'right',
      dataAid: generateDataAid(dataAid, ['favorites']),
    },
    {
      title: t('cusomize.title'),
      content: <NavigationGuidedTourStep lottieJson={stepFiveLottieJson} textContent={t('cusomize.content')} />,
      target: '[data-aid="bigidNavigationActions-EditModeButton"]',
      disableBeacon: true,
      placement: 'right-end',
      dataAid: generateDataAid(dataAid, ['cusomize']),
    },
  ];

  return <BigidGuidedTour steps={steps} joyrideCallback={joyrideCallback} run={isTourRunning} />;
};
