import React, { FC, useCallback, useMemo, useContext, useEffect } from 'react';
import {
  BigidGrid,
  BigidGridProps,
  useFetch,
  UseFetchActions,
  ChipsFormatterProps,
  BigidGridColumn,
  BigidGridColumnTypes,
} from '@bigid-ui/grid';
import { BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { ObjectPreviewContext } from '../hooks/ObjectPreviewContext';
import { getFilePermissions } from '../../../../AccessIntelligence/Files/OpenAccessFilesService';
import { PermissionType } from '../../../../AccessIntelligence/Files/FilePermissions';
import { queryService } from '../../../../../services/queryService';
import { notificationService } from '../../../../../services/notificationService';
import { BigidLayoutEmptyState } from '@bigid-ui/layout';
import { BigidCredentialsIllustration } from '@bigid-ui/icons';
import { capitalize } from 'lodash';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../../actionableInsightsTrackingUtil';

const useStyles = makeStyles({
  gridWrapper: {
    paddingTop: 16,
  },
  noData: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

export type ObjectPreviewFilePermission = {
  _id: string;
  id: string;
  type: PermissionType;
  name: string;
  email: string;
  access: string[];
  subType: string;
};

export const PermissionsTab: FC = () => {
  const classes = useStyles();
  const { objectPreviewData } = useContext(ObjectPreviewContext);
  const fullyQualifiedName = objectPreviewData.fullyQualifiedName;

  const getAccessChips = (access: string[]): ChipsFormatterProps => {
    return {
      chips: {
        value: access.map(accessPermission => ({
          label: accessPermission,
        })),
        isDisabled: true,
      },
    };
  };

  const getSubType = (subType: string): string => {
    if (!subType) {
      return '-';
    } else {
      return capitalize(subType);
    }
  };

  const columns = useMemo<BigidGridColumn<ObjectPreviewFilePermission>[]>(
    () => [
      {
        name: 'type',
        title: 'Type',
        getCellValue: ({ type }) => capitalize(type),
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'subType',
        title: 'Subtype',
        getCellValue: ({ subType }) => getSubType(subType),
        width: 200,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'name',
        title: 'Name',
        getCellValue: ({ name }) => capitalize(name),
        width: 250,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'email',
        title: 'Email / URL / ID',
        getCellValue: ({ email }) => capitalize(email),
        width: 250,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'access',
        title: 'Access Type',
        getCellValue: ({ access }) => getAccessChips(access),
        width: 300,
        type: BigidGridColumnTypes.CHIPS,
      },
    ],
    [],
  );

  const { dispatch: dispatchUseFetch, ...useFetchState } = useFetch({
    fetchDataFunction: async queryComponents => {
      try {
        const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents });
        const { permissions, totalCount } = await getFilePermissions(fullyQualifiedName, gridConfigQuery);
        return {
          data: permissions,
          totalCount,
        };
      } catch (err) {
        if (err.response.status !== 404) {
          notificationService.error(`Something went error`);
          console.error(err);
        }
      }
    },
  });

  const refreshGrid = useCallback(
    () => dispatchUseFetch({ type: UseFetchActions.FORCE_RELOAD, payload: {} }),
    [dispatchUseFetch],
  );

  useEffect(() => {
    trackActionableInsightsEvent(ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_OBJECT_PREVIEW_PERMISSIONS);
  }, []);

  useEffect(() => {
    refreshGrid();
  }, [refreshGrid, fullyQualifiedName]);

  const gridConfig: BigidGridProps<ObjectPreviewFilePermission> = {
    columns,
    showFilteringControls: false,
    showSortingControls: false,
    rows: useFetchState.rows as ObjectPreviewFilePermission[],
    skip: useFetchState.skip,
    onPagingChanged: useFetchState.onPagingChanged,
    onSortingChanged: useFetchState.onSortingChanged,
    totalRowsCount: useFetchState.totalRowsCount,
    onFiltersChange: useFetchState.onFiltersChanged,
    apiRef: useFetchState.apiRef,
    loading: useFetchState.isLoading,
    noDataContent: (
      <td className={classes.noData} colSpan={5}>
        <BigidLayoutEmptyState
          illustration={BigidCredentialsIllustration}
          description="Cannot retrieve permissions for this data type"
        />
      </td>
    ),
  };

  return (
    <div className={classes.gridWrapper}>
      <BigidPaper>
        <BigidGrid {...gridConfig} />
      </BigidPaper>
    </div>
  );
};
