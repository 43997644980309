import styled from '@emotion/styled';

export const AutoDiscoveryInfoList = styled('ul')`
  padding-inline-start: 16px;
  padding-top: 12px;
`;

export const AutoDiscoveryInfoListOrdered = styled('ol')`
  display: flex;
  flex-direction: column;
  padding-inline-start: 16px;
  gap: 4px;
`;

export const AutoDiscoveryInfoListItem = styled('li')`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 4px;
`;

export const AutoDiscoverWizardAsideItemTitle = styled.div`
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  align-items: center;
`;

export const AutoDiscoveryInfoListItemText = styled('li')`
  display: inline;
  margin-right: 4px;
  white-space: pre-wrap;
`;
