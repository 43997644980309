import React, { FC } from 'react';
import angular from 'angular';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { PrimaryButton, BigidColors } from '@bigid-ui/components';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { getApplicationPreference } from '../../../services/appPreferencesService';

interface ConnectButtonGroupProps {
  onSelectionClick: () => void;
  onPrefixClick: () => void;
  isRoot: boolean;
}

const useStyles = makeStyles({
  root: { display: 'flex', justifyContent: 'space-between', width: 350, alignItems: 'center' },
  rootWithOneButton: { width: 250 },
  title: { color: BigidColors.gray[700], fontSize: '0.75rem', fontWeight: 'bold' },
});

export const ScopesConnectButtonGroup: FC<ConnectButtonGroupProps> = ({ onSelectionClick, onPrefixClick, isRoot }) => {
  const classes = useStyles({});
  const shouldAllowWildcardScoping = isRoot && getApplicationPreference('WILDCARD_SCOPING');
  return (
    <div className={classnames(classes.root, !shouldAllowWildcardScoping && classes.rootWithOneButton)}>
      <div className={classes.title}>Connect Data Source</div>
      <PrimaryButton onClick={onSelectionClick} size="medium" dataAid="addDsBySelection" text="By Selection" />
      {shouldAllowWildcardScoping && (
        <PrimaryButton onClick={onPrefixClick} size="medium" dataAid="addDsByPrefix" text="By Prefix" />
      )}
    </div>
  );
};

angular
  .module('app')
  .component(
    'scopesConnectButtonGroup',
    convertToAngular(ScopesConnectButtonGroup, ['onSelectionClick', 'onPrefixClick', 'isRoot']),
  );
