import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { BigidLockIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React from 'react';
import { appsUrls } from '../../../../config/publicUrls';
import { useLocalTranslation } from '../translations';

const Container = styled('span')`
  padding: 0 24px 24px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MessageContainer = styled('span')`
  display: flex;
  gap: 4px;
`;

export const Footer = () => {
  const { t } = useLocalTranslation('Footer');
  return (
    <Container>
      <BigidLockIcon />
      <BigidBody1>
        <MessageContainer>
          <BigidLink href={appsUrls.DATA_STEWARDSHIP} shouldOpenNewTab underline="none" text={t('linkText')} />
          {t('messageText')}
        </MessageContainer>
      </BigidBody1>
    </Container>
  );
};
