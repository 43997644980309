import React, { useCallback, useState, PropsWithChildren, useEffect } from 'react';
import { omit } from 'lodash';
import {
  BigidCheckbox,
  BigidColorsV2,
  BigidDialog,
  BigidFancyTabs,
  BigidSelect,
  BigidSelectFieldState,
  BigidSelectOption,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { FormControl, FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DsarSettingsTrackingEvents } from '../../analyticsUtils';
import { DataSource, DsarManualRecords, IncludedDataSources, SourceType } from '../ProfileSettingsTypes';
import { GlossaryItemType } from '../../../../../administration/generalSettings/businessGlossary/businessGlossary.service';
import { GlossaryItemSelect } from '../SarFields/GlossaryItemSelect';
import { FieldType } from '../SarProfileSettingsTypes';
import { getCountries, createManualRecord, updateManualRecord, getAllIncludedDataSources } from '../../dsarService';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { notificationService } from '../../../../services/notificationService';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontSize: '0.875rem',
  },
  asterisk: {
    color: BigidColorsV2.red[600],
    width: '10px',
    display: 'inline-block',
    textAlign: 'center',
  },
  error: {
    color: BigidColorsV2.red[600],
  },
  dsWrapper: {
    width: '100%',
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '6px',
    backgroundColor: BigidColorsV2.gray[150],
  },
  flexWrapper: {
    display: 'flex',
  },
}));

export interface EditManualRecordsDialogProps {
  profileId: string;
  selectedRecord: DsarManualRecords;
  isOpen: boolean;
  isNewRecord: boolean;
  dsConnections?: DataSource[];
  fetchDsConnections?: () => Promise<DataSource[]>;
  onSave?: (isGlossaryChanged: boolean) => void;
  onClose?: () => void;
}

const fieldTypeOptions: BigidSelectOption[] = [
  { label: FieldType.CORRELATION, value: FieldType.ENTITY_SOURCE },
  { label: FieldType.PROXIMITY, value: FieldType.PROXIMITY },
  { label: FieldType.CLASSIFICATION, value: FieldType.CLASSIFICATION },
];
const defaultErrorMessage = 'Mandatory field';
const requiredFields: Partial<keyof DsarManualRecords>[] = ['fieldName', 'value', 'type', 'dataSourceType'];
const validate = (record: DsarManualRecords, includeDs: boolean) => {
  const fields = includeDs ? requiredFields.concat('dataSource') : requiredFields;
  return fields.filter(field => !record[field]);
};
const dsEmptyState = {
  isIncluded: false,
  selectedTab: 0,
  dsConnectedType: null as SourceType,
  dsConnectedValue: '',
  dsCustomType: null as SourceType,
  dsCustomValue: '',
  dsLocation: '',
};

export const EditManualRecordsDialog = ({
  profileId,
  selectedRecord,
  isOpen,
  isNewRecord,
  onSave,
  onClose,
  fetchDsConnections,
}: EditManualRecordsDialogProps) => {
  const classes = useStyles({});
  const [record, setRecord] = useState(selectedRecord);
  const [dsState, setDs] = useState(dsEmptyState);
  const [isGlossaryChanged, setGlossaryChanged] = useState(false);
  const [dsConnectionNames, setDsConnections] = useState<IncludedDataSources['names']>(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const isInvalid = (field: keyof DsarManualRecords) => invalidFields.includes(field);
  const setState = (newState: Partial<DsarManualRecords>) => {
    const recordKeys = Object.keys(newState);
    setInvalidFields(invalidFields => invalidFields.filter(f => !recordKeys.includes(f)));
    setRecord(state => ({ ...state, ...newState }));
  };
  const setDsState = (newState: Partial<typeof dsEmptyState>) => setDs(dsState => ({ ...dsState, ...newState }));

  useEffect(() => {
    if (!selectedRecord) {
      return;
    }
    setRecord(selectedRecord);
    setDsState({
      isIncluded: selectedRecord.dataSourceType !== SourceType.NONE,
      selectedTab: selectedRecord.dataSourceType === SourceType.CUSTOM ? 1 : 0,
      dsConnectedType: selectedRecord.dataSourceType === SourceType.CONNECTED ? SourceType.CONNECTED : null,
      dsConnectedValue: selectedRecord.dataSourceType === SourceType.CONNECTED ? selectedRecord.dataSource : '',
      dsCustomType: selectedRecord.dataSourceType === SourceType.CUSTOM ? SourceType.CUSTOM : null,
      dsCustomValue: selectedRecord.dataSourceType === SourceType.CUSTOM ? selectedRecord.dataSource : '',
      dsLocation: selectedRecord.dataSourceType === SourceType.CUSTOM ? selectedRecord.dataSourceLocation : '',
    });
  }, [selectedRecord]);

  const fetchDsConnectionNames = useCallback(async () => {
    if (dsConnectionNames) {
      return dsConnectionNames;
    }

    try {
      const dataSources = await fetchDsConnections();
      const dsNames = dataSources.map(ds => ({ name: ds.name }));
      setDsConnections(dsNames);
      return dsNames;
    } catch (err) {
      const errorMessage = 'Failed to fetch Data Sources';
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      return [];
    }
  }, [dsConnectionNames, fetchDsConnections]);

  const [isLoading, setIsLoading] = useState(false);

  if (!selectedRecord || !record) {
    return null;
  }

  const handleOnSave = () => {
    const invalidFields = validate(record, dsState.isIncluded);
    if (invalidFields.length) {
      setInvalidFields(invalidFields);
      return;
    }
    setIsLoading(true);
    const apiEndpoint = isNewRecord ? createManualRecord : updateManualRecord;
    const recordPayload = omit(record, ['dataSourceConnectionType', 'categoryValue', 'purposeOfUseValue']);
    apiEndpoint(profileId, recordPayload)
      .then(() => onSave(isGlossaryChanged))
      .catch(err => {
        const errorMessage = `Failed to ${isNewRecord ? 'create' : 'update'} manual field "${record.fieldName}"`;
        notificationService.error(errorMessage);
        console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      })
      .finally(handleOnClose);
  };

  const handleOnClose = () => {
    onClose();
    setInvalidFields([]);
    setIsLoading(false);
    setGlossaryChanged(false);
    setDs(dsEmptyState);
  };

  const handleOnGlossaryChanged = (prop: keyof DsarManualRecords) => (ids: string[]) => {
    setState({ [prop]: ids[0] });
    setGlossaryChanged(true);
  };

  return (
    <BigidDialog
      title={isNewRecord ? 'New Record' : 'Record Details'}
      isOpen={isOpen}
      onClose={handleOnClose}
      borderTop={true}
      maxWidth="xs"
      buttons={[
        {
          component: props => (
            <SecondaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EDIT_CANCEL_ACTION,
              }}
            />
          ),
          onClick: handleOnClose,
          text: 'Cancel',
        },
        {
          component: props => (
            <PrimaryButton
              {...props}
              bi={{
                eventType: DsarSettingsTrackingEvents.PROFILE_MANUAL_RECORDS_EDIT_SAVE_ACTION,
              }}
            />
          ),
          onClick: handleOnSave,
          text: isNewRecord ? 'Add Record' : 'Update',
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <Wrapper>
          <Label text="Field Name" />
          <BigidTextField
            defaultValue={record.fieldName}
            errorMessage={isInvalid('fieldName') ? defaultErrorMessage : ''}
            onChange={e => setState({ fieldName: e.target.value })}
            placeholder="Field Name"
            size="large"
            type="text"
            required
          />
        </Wrapper>
        <Wrapper>
          <Label text="Value" />
          <BigidTextField
            defaultValue={record.value}
            errorMessage={isInvalid('value') ? defaultErrorMessage : ''}
            onChange={e => setState({ value: e.target.value })}
            placeholder="Value"
            size="large"
            type="text"
            required
          />
        </Wrapper>
        <Wrapper>
          <GlossaryItemSelect
            title={<p className={classes.label}>{'Category'}</p>}
            type={GlossaryItemType.PersonalDataCategory}
            fieldName={'category'}
            onSelectionChange={handleOnGlossaryChanged('categoryId')}
            value={[record.categoryId]}
            size="large"
            margin="none"
          />
        </Wrapper>
        <Wrapper>
          <GlossaryItemSelect
            title={<p className={classes.label}>{'Purpose of Use'}</p>}
            type={GlossaryItemType.PurposeOfProcessing}
            fieldName={'purpose'}
            onSelectionChange={handleOnGlossaryChanged('purposeOfUseId')}
            value={[record.purposeOfUseId]}
            size="large"
            margin="none"
          />
        </Wrapper>
        <Wrapper>
          <div className={classes.label}>{'Type'}</div>
          <BigidSelect
            name={'type'}
            options={fieldTypeOptions}
            onChange={value => setState({ type: value[0].value })}
            value={[fieldTypeOptions.find(option => option.value === record.type) || fieldTypeOptions[0]]}
            menuPosition="fixed"
            size="large"
          />
        </Wrapper>
        <Wrapper>
          <BigidCheckbox
            label={'Include Data Source'}
            checked={dsState.isIncluded}
            onChange={(_, isChecked) => {
              setDsState({ isIncluded: isChecked });
              const dataSourceType = dsState.selectedTab === 0 ? dsState.dsConnectedType : dsState.dsCustomType;
              setState({ dataSourceType: isChecked ? dataSourceType || SourceType.CONNECTED : SourceType.NONE });
            }}
          />
        </Wrapper>
        {dsState.isIncluded && (
          <div className={classes.dsWrapper}>
            <BigidFancyTabs
              fullWidth
              onChange={tab => {
                setDsState({ selectedTab: tab });
                const dataSourceLocation = tab === 0 ? '' : dsState.dsLocation;
                const dataSource = tab === 0 ? dsState.dsConnectedValue : dsState.dsCustomValue;
                setState({
                  dataSourceType: tab === 0 ? SourceType.CONNECTED : SourceType.CUSTOM,
                  dataSource,
                  dataSourceLocation,
                });
              }}
              selectedIndex={dsState.selectedTab}
              size="medium"
              tabs={[
                {
                  label: 'Connected',
                },
                {
                  label: 'External',
                },
              ]}
            />
            {dsState.selectedTab === 0 && (
              <div>
                <Label text="Data Source" />
                <BigidSelect
                  name="dataSource"
                  options={dsConnectionNames?.map(ds => ({ value: ds.name, label: ds.name }))}
                  onChange={value => {
                    setDsState({ dsConnectedValue: value[0].value });
                    setState({ dataSource: value[0].value });
                  }}
                  value={
                    dsState.dsConnectedValue
                      ? [{ value: dsState.dsConnectedValue, label: dsState.dsConnectedValue }]
                      : []
                  }
                  placeholder="Select data source..."
                  menuPosition="fixed"
                  noOptionsMessage="No data sources included."
                  size="large"
                  loadOptions={() =>
                    fetchDsConnectionNames().then(dsConnectionNames =>
                      dsConnectionNames.map(ds => ({ value: ds.name, label: ds.name, isSelected: false })),
                    )
                  }
                  fieldState={isInvalid('dataSource') ? BigidSelectFieldState.error : BigidSelectFieldState.normal}
                  shouldLoadInitialOptions
                  isAsync
                  isSearchable
                />
                {isInvalid('dataSource') && <p className={classes.error}>{defaultErrorMessage}</p>}
              </div>
            )}
            {dsState.selectedTab === 1 && (
              <div>
                <Wrapper>
                  <Label
                    text="Data Source Name"
                    tooltip="Enter a name for the data source as you want it to appear in the report"
                  />
                  <BigidTextField
                    defaultValue={dsState.dsCustomValue}
                    errorMessage={isInvalid('dataSource') ? defaultErrorMessage : ''}
                    onChange={e => {
                      setDsState({ dsCustomValue: e.target.value });
                      setState({ dataSource: e.target.value });
                    }}
                    placeholder="Data Source Name"
                    size="large"
                    type="text"
                    required
                  />
                </Wrapper>
                <Wrapper>
                  <div className={classes.label}>{'Location'}</div>
                  <BigidSelect
                    name="dataSourceLocation"
                    options={[]}
                    onChange={value => {
                      setDsState({ dsLocation: value[0].value });
                      setState({ dataSourceLocation: value[0].value });
                    }}
                    value={dsState.dsLocation ? [{ value: dsState.dsLocation, label: dsState.dsLocation }] : []}
                    placeholder="Location"
                    menuPosition="fixed"
                    size="large"
                    loadOptions={() =>
                      getCountries()
                        .then(countries =>
                          countries.map(c => ({ value: c.name, label: c.displayName, isSelected: false })),
                        )
                        .catch(() => [])
                    }
                    shouldLoadInitialOptions
                    isAsync
                  />
                </Wrapper>
              </div>
            )}
          </div>
        )}
      </div>
    </BigidDialog>
  );
};

const Label = ({ text, tooltip }: { text: string; tooltip?: string }) => {
  const classes = useStyles({});
  return (
    <FormLabel className={classes.flexWrapper}>
      {text}
      <span className={classes.asterisk}>*</span>
      {tooltip && (
        <BigidTooltip title={tooltip} placement="top" width="240px">
          <div className={classes.flexWrapper}>
            <BigidHelpIcon size="medium" />
          </div>
        </BigidTooltip>
      )}
    </FormLabel>
  );
};

const Wrapper = ({ children }: PropsWithChildren) => (
  <FormControl
    fullWidth
    margin="none"
    sx={{
      marginBottom: '16px',
    }}
  >
    {children}
  </FormControl>
);

export interface UseEditManualRecordsDialog {
  dialogProps: EditManualRecordsDialogProps;
  openDialog: (
    profileId: string,
    selectedRecord?: DsarManualRecords,
    dsConnections?: DataSource[],
    fetchDsConnections?: () => Promise<DataSource[]>,
  ) => Promise<boolean>;
  key: number;
}

const initialState: EditManualRecordsDialogProps = {
  profileId: null,
  selectedRecord: null,
  isOpen: false,
  isNewRecord: false,
};

const newRecord = {
  type: FieldType.ENTITY_SOURCE,
  isIncluded: true,
  dataSourceType: SourceType.NONE,
};

export const useEditManualRecordsDialog = (): UseEditManualRecordsDialog => {
  const [dialogProps, setDialogProps] = useState<EditManualRecordsDialogProps>(initialState);
  const [key, setKey] = useState(0);

  const openDialog = useCallback<UseEditManualRecordsDialog['openDialog']>(
    (profileId, selectedRecord, dsConnections, fetchDsConnections) => {
      return new Promise<boolean>(resolve => {
        setDialogProps({
          profileId,
          selectedRecord: selectedRecord || (newRecord as DsarManualRecords),
          isOpen: true,
          isNewRecord: !selectedRecord,
          dsConnections,
          fetchDsConnections,
          onSave: (isGlossaryChanged: boolean) => {
            setKey(key => (isGlossaryChanged ? ++key : key));
            setDialogProps(initialState);
            resolve(true);
          },
          onClose: () => setDialogProps(initialState),
        });
      });
    },
    [],
  );

  return {
    openDialog,
    dialogProps,
    key,
  };
};
