import {
  BigidFlexibleListCardHeight,
  BigidFlexibleListCardProps,
  BigidFlexibleListCardWidth,
  BigidLoader,
  BigidTabs,
  BigidTabsItem,
} from '@bigid-ui/components';
import { BigidGrid, BigidGridColumn, BigidGridColumnTypes, BigidGridProps, PagingPanelPosition } from '@bigid-ui/grid';
import React, { FC, ReactText, useCallback, useMemo } from 'react';
import { CatalogSearchGridRow, ResultsEntityType, WithDataAid } from '../types';
import { styled } from '@mui/material';
import { CatalogSearchToolbar } from './GridComponents/CatalogSearchToolbar';
import { Actions } from './GridComponents/Toolbars/Actions/Actions';
import { generateDataAid } from '@bigid-ui/utils';
import { Controls } from './GridComponents/Controls';
import { useDataCatalogSearchResultsContext } from '../contexts/dataCatalogSearchResultsContext';
import { difference, noop } from 'lodash';
import { pageSize } from '../constants';
import { useLocalTranslation } from '../translations';
import { getAdaptedCardData } from './Card/adapter';
import { AlienEntityControls } from './GridComponents/AlienEntityControls';
//NOTE: keep disabled till the further notice from whoever is responsible for Stewardship app
// import { getDataStewardshipLicenseData, isDataStewardshipAppInstalled } from '../../DataExplorer/dataExplorerService';
import { $state, $stateParams } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  DataExplorerSearchTabSwitchBiPayload,
  sendDataExplorerResultsPageTabSwitchBiAnalytics,
} from '../../DataExplorer/services/analytics';

const PageContainer = styled('div')`
  min-height: 650px;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 16px 16px;
`;

const GridContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
`;

const LoaderContainer = styled('div')`
  display: flex;
  flex-grow: 1;
`;

const TabsWrapper = styled('section')`
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
`;
const resultsEntityTypeToIndexMap: Record<ResultsEntityType, number> = {
  catalog: 0,
  datasource: 1,
  policy: 2,
  business_attribute: 3,
  business_term: 4,
};

const getColumnsConfig = (entityType: ResultsEntityType): BigidGridColumn<CatalogSearchGridRow>[] => [
  {
    name: 'name',
    title: 'Name',
    type: BigidGridColumnTypes.FLEXIBLE_CARD,
    width: 'auto',
    getCellValue: row => {
      const adapterFn = getAdaptedCardData(entityType);
      const { contentComponent, footerComponent, icon, id, titleComponent, onTitleClick } = adapterFn(row);

      const isCatalog = entityType === 'catalog';
      return {
        card: {
          isCardSelectable: isCatalog,
          onSelect: noop,
          onTitleClick,
          cardData: {
            title: titleComponent,
            footer: footerComponent,
            avatar: {
              icon,
            },
            id,
          },
          content: contentComponent,
          config: {
            TITLE: {
              isCustomComponent: true,
            },
            SELECTION: {
              hasDelimeter: isCatalog,
            },
          },
          titleCustomWidth: 850,
          width: BigidFlexibleListCardWidth.REGULAR,
          maxHeight: 300,
          height: BigidFlexibleListCardHeight.FLEXIBLE,
        } as BigidFlexibleListCardProps,
      };
    },
  },
];

export const SearchGrid: FC<WithDataAid> = ({ dataAid = 'SearchGrid' }) => {
  const { state, dispatchCatalogSearchAction, isLoading, entityType } = useDataCatalogSearchResultsContext();
  const { gridConfig: contextGridConfig, savedFiltersMap } = state;
  const { totalCount, isSelectAll, page, rows, selectedRowsIds, unselectedRowsIds } = contextGridConfig;
  const { t } = useLocalTranslation('tabs');

  //NOTE: keep disabled till the further notice from whoever is responsible for Stewardship app
  // const license = getDataStewardshipLicenseData();

  const onSelectedRowsChanged = (selectedRowIds: ReactText[], lastUnselectedRowId: ReactText[] = []) => {
    dispatchCatalogSearchAction({
      type: 'PROCESS_ROWS_SELECTION',
      payload: {
        selectedRowIds: selectedRowIds.map(id => String(id)),
        lastUnselectedRowId: lastUnselectedRowId.map(id => String(id)),
      },
    });
  };

  const computedSelectedRows = useMemo(() => {
    if (isSelectAll) {
      return difference(
        rows?.map(row => row.id),
        unselectedRowsIds,
      );
    } else {
      return selectedRowsIds;
    }
  }, [selectedRowsIds, unselectedRowsIds, isSelectAll, rows]);

  const handlePagingChanged = useCallback(
    (page: number) => {
      dispatchCatalogSearchAction({
        type: 'UPDATE_GRID_CONFIG',
        payload: { page: page + 1 },
      });
    },
    [dispatchCatalogSearchAction],
  );

  const handleTabChange = (index: number, { data }: BigidTabsItem<TabData>) => {
    dispatchCatalogSearchAction({
      type: 'RESET_GRID_DATA',
    });

    dispatchCatalogSearchAction({
      type: 'UPDATE_SAVED_FILTERS_MAP',
      payload: {
        entityType,
        filter: $stateParams.filters,
      },
    });

    $state.go(CONFIG.states.CATALOG_SEARCH_RESULTS, {
      activeTab: data.entityType,
      sort: null,
      filters: savedFiltersMap[data.entityType],
    });

    const biPayload: DataExplorerSearchTabSwitchBiPayload = {
      tab: data.entityType,
    };

    sendDataExplorerResultsPageTabSwitchBiAnalytics(biPayload);
  };

  const columnConfig = useMemo(() => getColumnsConfig(entityType), [entityType]);
  const skip = (page - 1) * pageSize;
  const isCatalog = entityType === 'catalog';

  const gridConfig: BigidGridProps<CatalogSearchGridRow> = useMemo(
    () => ({
      columns: columnConfig,
      pageSize: pageSize,
      rows: rows || [],
      skip,
      totalRowsCount: totalCount,
      onPagingChanged: handlePagingChanged,
      selectedRowIds: computedSelectedRows,
      onSelectedRowIdsChanged: onSelectedRowsChanged,
      showFilteringControls: isCatalog,
      cardListMode: true,
      pagingMode: true,
      showSortingControls: true,
      showSelectionCheckboxes: isCatalog,
      showSelectionColumn: isCatalog,
      gridId: entityType,
      rowClickShouldKeepSelection: true,
      pagingPanelPosition: PagingPanelPosition.Center,
    }),
    [
      columnConfig,
      computedSelectedRows,
      entityType,
      onSelectedRowsChanged,
      rows,
      skip,
      totalCount,
      handlePagingChanged,
      isCatalog,
    ],
  );
  const isIntermediate = useMemo(() => {
    if (isSelectAll) {
      return unselectedRowsIds.length > 0;
    }
    return selectedRowsIds.length > 0;
  }, [isSelectAll, selectedRowsIds, unselectedRowsIds]);

  type TabData = {
    entityType: ResultsEntityType;
  };
  const tabs: BigidTabsItem<TabData>[] = [
    {
      label: t('catalog'),
      data: {
        entityType: 'catalog',
      },
    },
    {
      label: t('dataSources'),
      data: {
        entityType: 'datasource',
      },
    },
    {
      label: t('policies'),
      data: {
        entityType: 'policy',
      },
    },
  ];

  //NOTE: keep disabled till the further notice from whoever is responsible for Stewardship app
  // if (isDataStewardshipAppInstalled(license)) {
  //   tabs.push(
  //     {
  //       label: t('attributes'),
  //       data: {
  //         entityType: 'business_attribute',
  //       },
  //     },
  //     {
  //       label: t('terms'),
  //       data: {
  //         entityType: 'business_term',
  //       },
  //     },
  //   );
  // }

  const entityWithActions: ResultsEntityType[] = ['catalog'];
  const areActionsSupported = entityWithActions.includes(entityType);

  const gridControls = areActionsSupported ? (
    <>
      <Actions dataAid={generateDataAid(dataAid, ['actions'])} />
      <Controls isIntermediate={isIntermediate} />
    </>
  ) : (
    <AlienEntityControls />
  );

  const showLoader = isLoading || !rows;

  return (
    <PageContainer data-aid={generateDataAid(dataAid, ['page'])}>
      <TabsWrapper>
        <BigidTabs
          tabs={tabs}
          selectedIndex={resultsEntityTypeToIndexMap[entityType] || 0}
          onChange={handleTabChange}
        />
      </TabsWrapper>
      <GridContainer>
        <CatalogSearchToolbar dataAid={generateDataAid(dataAid, ['toolbar'])} />
        {gridControls}
        {showLoader ? (
          <LoaderContainer>
            <BigidLoader blocking={false} position="relative" />
          </LoaderContainer>
        ) : (
          <BigidGrid key={entityType} {...gridConfig} />
        )}
      </GridContainer>
    </PageContainer>
  );
};
