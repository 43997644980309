import { BigidBody3 } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../../../translations';
import { formatNumber } from '../../../../../../utilities/numericDataConverter';

type ItemsCountProps = {
  totalCount: number;
};
export const ItemsCount: FC<ItemsCountProps> = ({ totalCount }) => {
  const { palette } = useTheme();
  const { t } = useLocalTranslation('actions');

  // NOTE: typecasting due to the i18n issue with string
  const count = formatNumber(totalCount) as any;

  return <BigidBody3 color={palette.bigid.gray500}>{t('itemsCount', { count })}</BigidBody3>;
};
