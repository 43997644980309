import React, { ChangeEvent, FC, useContext, useState, useEffect } from 'react';
import { ScanTemplateWizardContext } from '../../../scanTemplateContext';
import { ClassificationSelectionGrid } from './ClassificationSelectionGrid';
import styled from '@emotion/styled';
import { BigidFancyTabs, BigidSwitch, BigidTabsItem, BigidInlineNotification } from '@bigid-ui/components';
import { BulkClassifierSelectionMethod } from '../../../scanTemplateTypes';
import { showGenericConfirmationDialog } from '../../../../../../services/genericConfirmationDialog';
import { getGlobalEnabledClassifierCount, CLASSIFIERS_LIMIT } from './classificationService';

const ClassificationStepWrapper = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
});

const GridWrapper = styled('div')<{ isUseGlobalClassifiers: boolean }>(
  { overflowX: 'hidden', flexGrow: 1 },
  ({ isUseGlobalClassifiers }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    gap: 8,
    width: '100%',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    ...(isUseGlobalClassifiers
      ? {
          overflowY: 'hidden',
          pointerEvents: 'none',
          opacity: 0.3,
          '.TableContainer-root': {
            overflow: 'hidden',
          },
        }
      : { overflowY: 'auto' }),
  }),
);

const Wrapper = styled('div')({
  display: 'flex',
  gap: 10,
});

const NotificationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

enum ClassificationGridView {
  REGULATION,
  CATEGORY,
}

const shouldChangeView = async (selectedTab: string, isClassifiersSelected: boolean) => {
  return isClassifiersSelected
    ? showGenericConfirmationDialog({
        title: 'Switch classification view',
        text: `All selections made on the ${selectedTab} screen will be cleared. Would you like to continue?`,
        actionButton: 'Yes',
        closeButton: 'No',
      })
    : true;
};

export const Classification: FC = () => {
  const { scanTemplateFormData, setScanTemplateFormData } = useContext(ScanTemplateWizardContext);
  const selectedIndex =
    scanTemplateFormData.bulkClassifierSelectionMethod === BulkClassifierSelectionMethod.CATEGORY
      ? ClassificationGridView.CATEGORY
      : ClassificationGridView.REGULATION;

  const [view, setView] = useState<number>(selectedIndex);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [enabledAmount, setEnabledAmount] = useState<number>(0);

  const tabs: BigidTabsItem[] = [
    { label: BulkClassifierSelectionMethod.REGULATION, disabled: scanTemplateFormData.isUseGlobalClassifiers },
    { label: BulkClassifierSelectionMethod.CATEGORY, disabled: scanTemplateFormData.isUseGlobalClassifiers },
  ];

  const onChangeTab = async (value: number, tab: BigidTabsItem) => {
    const isClassifiersSelected =
      scanTemplateFormData.classifiers?.length > 0 || scanTemplateFormData.bulkClassifierSelection?.length > 0;
    const isChangeView = await shouldChangeView(tabs[view].label.toLowerCase(), isClassifiersSelected);
    if (isChangeView) {
      setLoading(true);
      setView(value);
      setScanTemplateFormData(prevState => {
        return {
          ...prevState,
          classifiers: [],
          bulkClassifierSelection: [],
          bulkClassifierSelectionMethod: tab.label as BulkClassifierSelectionMethod,
        };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    const globalClassifiersExceedAmount =
      scanTemplateFormData?.isUseGlobalClassifiers === true && enabledAmount >= CLASSIFIERS_LIMIT;
    const shouldShowAlert =
      scanTemplateFormData?.classifiers?.length >= CLASSIFIERS_LIMIT || globalClassifiersExceedAmount;
    if (shouldShowAlert && !isAlertShown) {
      setIsAlertShown(true);
    } else if (isAlertShown && !shouldShowAlert) {
      setIsAlertShown(false);
    }
  }, [scanTemplateFormData]);

  useEffect(() => {
    (async () => {
      const enabledClassifierCount = await getGlobalEnabledClassifierCount();
      setEnabledAmount(enabledClassifierCount);
    })();
  }, []);

  const handleGlobalClassifiersSwitch = (event: ChangeEvent, checked: boolean) => {
    if (checked) {
      setScanTemplateFormData(prevState => ({
        ...prevState,
        classifiers: [],
        bulkClassifierSelection: [],
        isUseGlobalClassifiers: true,
      }));
    } else {
      setScanTemplateFormData(prevState => ({ ...prevState, isUseGlobalClassifiers: false }));
    }
  };

  return (
    <ClassificationStepWrapper>
      {
        <NotificationWrapper>
          {isAlertShown && (
            <BigidInlineNotification
              title={'Scan duration may increase '}
              type="warning"
              isExpandable={false}
              text={[
                {
                  subText: 'We recommend that you select up to 25 classifiers in order not to slow down the scan.',
                },
              ]}
              isClosable={true}
            />
          )}
          <Wrapper>
            <BigidFancyTabs selectedIndex={view} tabs={tabs} onChange={onChangeTab} />
            <BigidSwitch
              leftLabel={'Use Global Classifiers'}
              value={scanTemplateFormData.isUseGlobalClassifiers}
              checked={scanTemplateFormData.isUseGlobalClassifiers}
              onChange={handleGlobalClassifiersSwitch}
              dataAid={'useGlobalClassifiersSwitch'}
              dataTourId="useGlobalClassifiersSwitch"
            />
          </Wrapper>
        </NotificationWrapper>
      }
      {!loading && (
        <GridWrapper
          isUseGlobalClassifiers={scanTemplateFormData.isUseGlobalClassifiers}
          data-aid={'ClassifierSelectionGridWrapper'}
        >
          <ClassificationSelectionGrid
            scanTemplateFormData={{ ...scanTemplateFormData, classifiers: scanTemplateFormData?.classifiers ?? [] }}
            setScanTemplateFormData={setScanTemplateFormData}
            selectedTabLabel={tabs[view].label}
          />
        </GridWrapper>
      )}
    </ClassificationStepWrapper>
  );
};
