import React, { FC } from 'react';
import { PrimaryButton } from '@bigid-ui/components';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { CurationEvents } from '../../../Curation/curationEventTrackerUtils';

interface NavigateToFindingsReviewButtonProps {
  scanId: string;
  scanProfileName: string;
}

export const NavigateToFindingsReviewButton: FC<NavigateToFindingsReviewButtonProps> = ({
  scanId,
  scanProfileName,
}) => {
  const handleOnClick = () => {
    $state.go(CONFIG.states.CURATION, { scanId, scanProfileName });
  };

  return (
    <PrimaryButton
      onClick={handleOnClick}
      size="small"
      dataAid="NavigateToFindingsReviewButton"
      bi={{
        eventType: CurationEvents.SCAN_PROFILE_REVIEW_FINDINGS_SELECT_BUTTON,
      }}
      text="Tune Classifiers"
    />
  );
};

NavigateToFindingsReviewButton.displayName = 'NavigateToFindingsReviewButton';
