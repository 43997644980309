import { httpService } from '../../services/httpService';
import { GroupedCasesResponse } from '../ActionableInsights/actionableInsightsService';
import { DsCountByTypeResponse } from '../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { BigidDataCategoryName } from '../../utilities/dataCategories';

const RISK_ASSESSMENT_API_PATH = 'risk-assessment';

export interface ObjectsSummaryByDataType {
  totalObjects: number;
  totalObjectsWithPii: number;
  totalObjectsWithPiiAndOpenAccess: number;
  totalDataSources: number;
  type: string;
}

interface ObjectsSummaryByDataTypeResponse {
  summaryByTypes: ObjectsSummaryByDataType[];
}

export interface AggregationData {
  docCount: number;
  findings: number;
  aggItemName: string;
}
interface SensitiveDsTypesData {
  total: number;
  topSensitiveDsTypes: AggregationData[];
}

interface topDataCategories {
  topDataCategories: {
    name: BigidDataCategoryName;
    objectsCount: number;
  }[];
}

interface FileTypeLabel {
  count: number;
  label: string;
}

interface ExternalUserEmail {
  sharedObjectsCount: number;
  name: string;
}

interface Domain {
  sharedObjectsCount: number;
  domain: string;
}

interface AciDataForRiskAssessmentReport {
  topExternalUsers: ExternalUserEmail[];
  topExternalDomains: Domain[];
  topFilesSharedExternally: FileTypeLabel[];
  isAciEnabled: boolean;
}
export interface RiskAssessmentData extends AciDataForRiskAssessmentReport {
  companyName?: string;
  generalData: Record<string, unknown>;
  actionableInsightsGroupedPolicies: GroupedCasesResponse;
  dsCountersByTypes: DsCountByTypeResponse;
  objectSummaryByDataType: ObjectsSummaryByDataTypeResponse;
  topSensitiveDataCategories: topDataCategories;
  topSensitiveDsTypes: SensitiveDsTypesData;
}

const getRiskAssessmentData = async (): Promise<RiskAssessmentData> => {
  const {
    data: { data },
  } = await httpService.fetch(RISK_ASSESSMENT_API_PATH);
  return data;
};

const downloadRiskAssessmentPdf = () => {
  httpService.downloadFile(`${RISK_ASSESSMENT_API_PATH}/file-download/`);
};

export const RiskAssessmentService = {
  getRiskAssessmentData,
  downloadRiskAssessmentPdf,
};
