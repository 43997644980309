import React, { FC, ComponentType, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { BigidHeading5, BigidBody1 } from '@bigid-ui/components';
import { Paper, SvgIconProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LockIcon from '../../../../assets/icons/Lock.svg';
import { BigidWarningIcon } from '@bigid-ui/icons';

const useStyles = makeStyles(theme => ({
  dataSourcesInitCardContainer: ({ disabled, size }: Pick<DataSourcesInitCardProps, 'disabled' | 'size'>) => ({
    padding: '35px 27px',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    width: size === 'sm' ? 269 : 320,
    alignItems: 'center',
    cursor: disabled ? 'auto' : 'pointer',
    position: 'relative',
    boxShadow: theme.vars.tokens.bigid.shadow10,
    border: theme.vars.tokens.bigid.borderDefault,
  }),
  title: {
    marginBottom: 12,
    marginTop: 4,
  },
  align: {
    textAlign: 'center',
  },
  lockedIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
  },
  disabled: { opacity: 0.5 },
  disabledTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    justifyContent: 'space-between',
  },
  warningWrapper: {
    display: 'flex',
    gap: 4,
  },
  disabledText: {
    width: 232,
    paddingTop: 4,
  },
}));

interface DataSourcesInitCardProps {
  Icon: ComponentType<HTMLAttributes<SVGElement>> | ComponentType<SvgIconProps>;
  title: string;
  text: string;
  onClick: () => void;
  dataAid?: string;
  locked?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'lg';
  disabledText?: string;
}

export const DataSourcesInitCard: FC<DataSourcesInitCardProps> = ({
  Icon,
  title,
  text,
  onClick,
  dataAid,
  locked,
  disabled,
  disabledText,
  size = 'lg',
}) => {
  const classes = useStyles({ disabled, size });
  return (
    <div className={classes.disabledTextWrapper}>
      <Paper
        className={classnames(classes.dataSourcesInitCardContainer, disabled && classes.disabled)}
        onClick={disabled ? undefined : onClick}
        data-aid={dataAid || 'DataSourcesInitCard'}
      >
        {locked && <LockIcon data-aid="ImportCardLockedIcon" className={classes.lockedIcon} />}
        <div className={classes.iconWrapper}>
          <Icon />
        </div>
        <BigidHeading5 className={classnames(classes.title, classes.align)}>{title}</BigidHeading5>
        <BigidBody1 className={classes.align}>{text}</BigidBody1>
      </Paper>
      {disabled && (
        <div className={classes.warningWrapper}>
          <BigidWarningIcon />
          <BigidBody1 className={classes.disabledText}>{disabledText}</BigidBody1>
        </div>
      )}
    </div>
  );
};
