import { module } from 'angular';
import { notificationService } from '../../react/services/notificationService';
import { httpService } from '../../react/services/httpService';
import { sendSSERequestAndWait } from '../../react/services/sseService';
import {
  trackDataSourceTestConnectionBiEvent,
  trackTestConnectionNewExpBiEvent,
} from '../../react/views/DataSources/DataSourcesEventTrackerUtils';

const app = module('app');

app.factory('dataSourceConnectionsService', [
  '$http',
  'appSettings',
  ($http, appSettings) => {
    return {
      getAllDSWithScanPartsSupport: () => {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/ds-support-split-part`)
          .then(function (response) {
            if (response && response.data) {
              return response.data.data;
            } else return [];
          });
      },

      getDSSupportTypeGroups: dsType => {
        return $http
          .get(`${appSettings.serverPath}/${appSettings.version}/ds-support-split-part/${dsType}`)
          .then(function (response) {
            if (response && response.data) {
              return response.data.data.result;
            } else return false;
          });
      },

      getDataSourceConnectionsData: (idSourceFlag, scanTypeId, archiveFilter = false) => {
        const params = { idSource: idSourceFlag, archiveFilter };
        if (scanTypeId) {
          params.scanTypeId = scanTypeId;
        }
        return $http({
          url: appSettings.serverPath + '/' + appSettings.version + '/ds_connections',
          method: 'GET',
          params: params,
        }).then(response => response.data);
      },

      getDSConnectionDataByQuery: query => {
        return httpService.fetch(`ds-connections${query ? `?${query}` : ''}`).then(function (response) {
          return response?.data;
        });
      },

      getDataSourceConnectionsDataCount: () => {
        return httpService.fetch('license/dataSourcesCount').then(function (response) {
          return response.data.data;
        });
      },

      getDataSourceConnectionsDataByID: name => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/ds_connections/' + name)
          .then(function (response) {
            return response.data;
          });
      },
      getAllKafkaConnectPlugins: () => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/kafka-connect-ui/supported-connectors/')
          .then(function (response) {
            return response.data;
          });
      },

      testDataSourceConnectionSync: (ds_connection, params) => {
        return httpService.post('ds-connection-test', ds_connection, params).then(function (response) {
          return response;
        });
      },

      testDataSourceConnection: async (
        dsConnection,
        callback,
        params,
        options = { shouldTriggerNotification: true },
      ) => {
        try {
          const { results, errors, message } = await sendSSERequestAndWait(
            'post',
            'ds-connection-test/client',
            dsConnection,
            params ?? {},
            dsConnection.broadcastEvent,
          );
          // @info errors field can be an array or a single instance of an Error
          const { shouldTriggerNotification } = options;
          const isError = Array.isArray(errors) ? !!errors.length : !!errors;
          if (shouldTriggerNotification) {
            isError ? notificationService.error(message) : notificationService.success(message);
          }
          dsConnection.ds_connection['testConnectionSuccess'] = !isError;
          dsConnection.ds_connection['numOfTroubleshootingOptionsReturned'] =
            results[0]?.errorResolution?.resolutionSteps?.length || 0;
          if (dsConnection?.isNewConnection) {
            trackTestConnectionNewExpBiEvent(dsConnection);
          } else {
            trackDataSourceTestConnectionBiEvent(dsConnection?.ds_connection);
          }
          callback(results, Array.isArray(errors) ? errors : [errors]);
        } catch (error) {
          notificationService.error(error);
          callback([], [error]);
        }
      },

      importDSConnectionsFile: function importDSConnectionsFile(file, isNeedToBeEncrypted, shouldSendWelcomeEmail) {
        file.append('isNeedToBeEncrypted', isNeedToBeEncrypted);
        file.append('shouldSendWelcomeEmail', shouldSendWelcomeEmail);
        return $http
          .post(appSettings.serverPath + '/' + appSettings.version + '/ds_connections/import_from_file', file, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined },
          })
          .then(function (response) {
            return response.data;
          });
      },

      getAllSystems: () => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/systems/systems')
          .then(function (response) {
            return response.data;
          });
      },

      getSystemDetailsByName: systemName => {
        return $http
          .get(appSettings.serverPath + '/' + appSettings.version + '/systems/systems/' + systemName)
          .then(function (response) {
            return response.data;
          });
      },

      deletePiiDataForDataSource: systemName => {
        return $http
          .delete(appSettings.serverPath + '/' + appSettings.version + '/ds_connections/pii-data/' + systemName)
          .then(function (response) {
            return response.data;
          });
      },
    };
  },
]);
