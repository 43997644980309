import { useEffect } from 'react';
import { throttle } from 'lodash';
import { EntityEvents, entityEventsEmitter, BigidFilter } from '@bigid-ui/components';
import { subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { DataSourcesTypeMapping } from './useSupportedDataSources';
import { DataSource } from '../../../../utilities/dataSourcesUtils';

const DS_SSE_EVENT_NAME = 'ds-connection-event';

export enum DSActionType {
  ADD = 'add',
  UPDATE = 'update',
}

interface UseSSEDataSourceEvents {
  dataSourcesTypeMapping: DataSourcesTypeMapping;
  isActive: boolean;
  filterForDs?: BigidFilter;
}

export const useSSEDataSourceEvents = ({ dataSourcesTypeMapping, isActive, filterForDs }: UseSSEDataSourceEvents) => {
  useEffect(() => {
    const reloadWaitMs = 15000;
    const reloadGrid = throttle(() => {
      entityEventsEmitter.emit(EntityEvents.RELOAD);
    }, reloadWaitMs);
    const unregister = subscribeToRepeatedSSEEventById(DS_SSE_EVENT_NAME, ({ results }) => {
      if (!isActive || !results?.length) {
        return;
      }
      const [{ action, payload: dsConnection }] = results;

      switch (action) {
        case DSActionType.ADD:
          const { isReload, canBeAdded } = isDsCanByAddedWithCurrentFilter(filterForDs, dsConnection ?? {});
          if (canBeAdded) {
            if (isReload) {
              reloadGrid();
            } else {
              entityEventsEmitter.emit(EntityEvents.ADD, [
                { ...dsConnection, displayType: dataSourcesTypeMapping.get(dsConnection.type) || dsConnection.type },
              ]);
            }
          }
          break;
      }
    });

    return unregister;
  }, [dataSourcesTypeMapping, filterForDs, isActive]);
};

const TYPE_FILTER_NAME = 'type';

function isDsCanByAddedWithCurrentFilter(filterForDs: BigidFilter, { type }: DataSource) {
  const typeFilter = filterForDs?.find(({ field }) => field === TYPE_FILTER_NAME);
  const isReload = filterForDs?.length ? filterForDs?.length > 1 || !typeFilter : false;
  if (!typeFilter) {
    return {
      isReload,
      canBeAdded: true,
    };
  }

  const value = typeFilter?.value as string[];
  return {
    isReload,
    canBeAdded: !value?.includes || value.includes(type),
  };
}
