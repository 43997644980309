import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
} from '@bigid-ui/grid';
import {
  BigidFieldFilterOperator,
  BigidFilterType,
  BigidFlexibleListCardActionType,
  BigidFlexibleListCardArea,
  BigidFlexibleListCardData,
  BigidFlexibleListCardProps,
} from '@bigid-ui/components';
import {
  CORRELATION_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  REVIEW_FINDINGS_PERMISSIONS,
} from '@bigid/permissions';
import { v4 as uuid } from 'uuid';
import {
  CuratedAttribute,
  getCuratedAttributes,
  CuratedAttributeType,
  removeCuratedAttribute,
  CuratedAttributeRemovalPayload,
  CuratedAttributeKeys,
  getAttributeCategories,
} from '../curationService';
import { CuratedAttributesProps } from './CuratedAttributes';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { CuratedAttributeContent } from './CuratedAttributeContent';
import { omit, startCase } from 'lodash';
import { parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { showAssignCategoryDialog } from './actions/AssignCategoryDialog/categoryAssignDialogService';
import { useLocalTranslation } from '../translations';
import { showAssignFriendlyNameDialog } from './actions/AssignFriendlyNameDialog/assignFriendlyNameDialogService';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { isPermitted } from '../../../services/userPermissionsService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import { getPercentageValueFormatted } from '../curationUtils';
import { CuratedAttributeAccuracyOperands } from './CuratedAttributeAccuracyOperands';
import { generateDataAid } from '@bigid-ui/utils';
import { getAttributeTypeAvatar } from './CuratedAttributesUtils';
import { ReviewedEntitiesProgress } from '../ReviewedEntitiesProgress';

export type CuratedAttributesGridRecord = BigidGridRow & CuratedAttribute;

export type AttributesGridToolbarFilterConfig =
  BigidGridWithToolbarProps<CuratedAttributesGridRecord>['filterToolbarConfig'];

export type UseCuratedDataSourcesConfigState = {
  isReady: boolean;
  gridConfig: BigidGridWithToolbarProps<CuratedAttributesGridRecord>;
};

export type UseCuratedAttributesConfigProps = CuratedAttributesProps;

const searchFilterKeys: CuratedAttributeKeys = ['attributeName'];

export const useCuratedAttributesConfig = ({
  currentCuratedDataSource,
  onProceedToFieldsReview,
  curationStatus,
  curatedEntityName,
}: UseCuratedAttributesConfigProps): UseCuratedDataSourcesConfigState => {
  const [isReady] = useState<boolean>(true);
  const translation = 'CuratedAttributes.Config';
  const { t } = useLocalTranslation(translation);
  const gridId = useMemo(() => `CuratedAttributes-${uuid()}`, []);
  const [filterToolbarConfig, setFilterToolbarConfig] = useState<AttributesGridToolbarFilterConfig>();

  const { fetchData, columns } = useMemo(() => {
    const fetchData: FetchDataFunction<CuratedAttributesGridRecord> = async (
      queryComponents: BigidGridQueryComponents,
    ) => {
      try {
        const query = queryService.getGridConfigQuery(omit(queryComponents, ['filter'])); //NOTE: a temporal patch since BE requires a filter to be a query string
        const { data } = await getCuratedAttributes({
          sources: [currentCuratedDataSource.source],
          query: `${query}&filter=${parseFieldFiltersToSearchQuery(
            queryComponents.filter,
            Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
          )}`,
        });
        const { attributes, totalCount } = data;

        return {
          totalCount,
          data: attributes.map(attribute => ({
            ...attribute,
            id: attribute.attributeName,
            scannerTypeGroup: currentCuratedDataSource.scannerTypeGroup,
          })),
        };
      } catch ({ message }) {
        notificationService.error(t('fetchCuratedAttributeFailure'));
        console.error(`An error has occurred: ${message}`);

        return {
          totalCount: 0,
          data: [],
        };
      }
    };

    const columns: BigidGridColumn<CuratedAttributesGridRecord>[] = [
      {
        name: 'attributeName',
        title: 'attributeName',
        width: 'auto',
        getCellValue: data => {
          const { id, attributeName, displayName, attributeType, classifierType, approvedCount, rejectedCount } = data;
          const accuracy = approvedCount / (approvedCount + rejectedCount);
          const accuracyFormatted = !isNaN(accuracy) ? getPercentageValueFormatted(accuracy) : `N/A`;
          const cardData: BigidFlexibleListCardData<CuratedAttributesGridRecord> = {
            id,
            title: displayName,
            subTitleCustomTooltipProps: {
              followCursor: true,
              title: (
                <CuratedAttributeAccuracyOperands
                  dataAid={generateDataAid('CuratedAttributesAccuracy', [id, 'summary-accuracy-tooltip'])}
                  id={id}
                  approvedCount={approvedCount}
                  rejectedCount={rejectedCount}
                />
              ),
            },
            subTitle: `${t('accuracy')} ${accuracyFormatted}`,
            originalData: data,
            avatar: getAttributeTypeAvatar(classifierType || attributeType),
          };

          return {
            card: {
              titleCustomWidth: 400,
              cardData,
              content: CuratedAttributeContent,
              disableActionsOnceSelected: true,
              forceContextMenu: true,
              config: {
                [BigidFlexibleListCardArea.ACTIONS]: {
                  hasDelimeter: true,
                },
              },
              actions: [
                {
                  label: t('review'),
                  name: 'review',
                  isAlwaysExposed: true,
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_REVIEW);
                    onProceedToFieldsReview(originalData);

                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('removeFromCatalog'),
                  name: 'removeFromCatalog',
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_REMOVE_ATTRIBUTE);
                    try {
                      const { attributeName, displayName, attributeType } = originalData;

                      const isConfirmed = await showConfirmationDialog({
                        entityNameSingular: t('attributeRemoval'),
                        actionName: t('confirm'),
                        actionButtonName: t('confirm'),
                        customDescription: t('attributeRemovalConfirmation', { attributeName: displayName }),
                      });

                      if (isConfirmed) {
                        const payload: CuratedAttributeRemovalPayload = {
                          sources: [currentCuratedDataSource.source],
                          attributeName,
                          attributeType,
                        };
                        await removeCuratedAttribute(payload);
                        notificationService.success(t('attributeRemovalSuccess', { attributeName: displayName }));
                      }
                    } catch ({ message }) {
                      notificationService.error(t('attributeRemovalFailure', { attributeName: displayName }));
                      console.error(`An error has occurred: ${message}`);
                    } finally {
                      return;
                    }
                  },
                  getIsShown: () => isPermitted(REVIEW_FINDINGS_PERMISSIONS.REMOVE_FROM_CATALOG.name),
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('assignFriendName'),
                  name: 'assignFriendlyName',
                  getIsShown: () => isPermitted(CORRELATION_PERMISSIONS.MANAGE.name),
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_FRIENDLY_NAMES);
                    try {
                      await showAssignFriendlyNameDialog(originalData, gridId);
                    } catch ({ message }) {
                      notificationService.error(t('assignFriendlyNameFailure', { attributeName }));
                      console.error(`An error has occurred: ${message}`);
                    } finally {
                      return;
                    }
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('assignCategory'),
                  name: 'assignCategory',
                  getIsShown: () => isPermitted(CORRELATION_PERMISSIONS.MANAGE.name),
                  onExecute: async ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_ASSIGN_CATEGORIES);
                    try {
                      await showAssignCategoryDialog(originalData, gridId);
                    } catch ({ message }) {
                      notificationService.error(t('assignCategoryFailure', { attributeName }));
                      console.error(`An error has occurred: ${message}`);
                    } finally {
                      return;
                    }
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                },
                {
                  label: t('goToClassifier'),
                  name: 'goToClassifier',
                  onExecute: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_CLASSIFIER_PAGE);
                    $state.go(CONFIG.states.CLASSIFIERS, {
                      name: encodeURIComponent(originalData.attributeName),
                      type: encodeURIComponent(originalData.attributeType),
                    });

                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                  getIsShown: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>) => {
                    const { attributeType } = originalData;

                    return (
                      (attributeType === CuratedAttributeType.CLASSIFIER ||
                        attributeType === CuratedAttributeType.CLASSIFICATION_MD) &&
                      isPermitted(CLASSIFIERS_PERMISSIONS.ACCESS.name)
                    );
                  },
                },
                {
                  label: t('goToIgnoredList'),
                  name: 'goToIgnoredList',
                  onExecute: (): Promise<void> => {
                    trackEventCurationView(CurationEvents.CURATION_ATTRIBUTES_SELECT_ACTION_GO_TO_IGNORED_LIST);
                    $state.go(CONFIG.states.GENERAL_SETTINGS, { currentTab: 'ignoredList', showBackButton: true });

                    return;
                  },
                  type: BigidFlexibleListCardActionType.PRIMARY_BUTTON,
                  getIsShown: ({ originalData }: BigidFlexibleListCardData<CuratedAttribute>) => {
                    const { attributeType } = originalData;

                    return (
                      attributeType === CuratedAttributeType.CORRELATION &&
                      isPermitted(GENERAL_SETTINGS_PERMISSIONS.READ_IGNORED_LISTS.name)
                    );
                  },
                },
              ],
            } as BigidFlexibleListCardProps,
          };
        },
        type: BigidGridColumnTypes.FLEXIBLE_CARD,
      },
    ];

    return {
      fetchData,
      columns,
    };
  }, [currentCuratedDataSource.scannerTypeGroup, currentCuratedDataSource.source, gridId, onProceedToFieldsReview, t]);

  const fetchToolbarFilterInitialOptions = useCallback(async () => {
    const categoriesOptions = await getAttributeCategories(currentCuratedDataSource.source);

    setFilterToolbarConfig({
      filters: [
        {
          title: t('attributeType'),
          field: 'attributeType',
          operator: 'in',
          value: [],
          isSearchAsync: false,
          disabled: true,
          options: [
            {
              label: CuratedAttributeType.CLASSIFIER.toString(),
              value: CuratedAttributeType.CLASSIFIER,
              isSelected: false,
            },
            {
              label: startCase(CuratedAttributeType.CLASSIFICATION_MD),
              value: CuratedAttributeType.CLASSIFICATION_MD,
              isSelected: false,
            },
            {
              label: CuratedAttributeType.CORRELATION,
              value: CuratedAttributeType.CORRELATION,
              isSelected: false,
            },
          ],
        } as BigidFilterType,
        {
          title: t('categoryName'),
          field: 'categories.unique_name',
          operator: 'in',
          value: [],
          isSearchAsync: true,
          disabled: true,
          options: categoriesOptions,
        } as BigidFilterType,
      ],
      searchConfig: {
        searchFilterKeys,
        initialValue: '',
        operator: 'equal' as BigidFieldFilterOperator,
      },
    });
  }, [t]);

  useEffect(() => {
    fetchToolbarFilterInitialOptions();
  }, [fetchToolbarFilterInitialOptions]);

  return {
    isReady,
    gridConfig: {
      gridId,
      entityName: 'attributes',
      showSortingControls: false,
      showFilteringControls: false,
      hideColumnChooser: true,
      cardListMode: true,
      filterToolbarConfig,
      fetchData,
      columns,
      defaultSorting: [{ field: 'normalizedDisplayName', order: 'asc' }],
      foreignElement: curationStatus && (
        <ReviewedEntitiesProgress
          curationStatus={curationStatus}
          curatedEntityName={curatedEntityName}
          translation={translation}
        />
      ),
      foreignElementPlacement: 'right',
    },
  };
};
