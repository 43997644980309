import React, { FC, useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidHeading6,
  BigidHeading4,
  BigidLoader,
  BigidBreadcrumbs,
  Breadcrumb,
  BigidAccordion,
  BigidAccordionSummary,
  BigidAccordionDetails,
  AccordionSummarySizeEnum,
  SecondaryButton,
  BigidButtonIcon,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidChevronCircleLeftIcon } from '@bigid-ui/icons';
import { FmsdBase } from '../containers';
import { CredsStatus, FmsdRadio } from '../FmsdComponents';
import { FmsdExplorer, DsGridRow, ScanPanel, PendingResults } from './components';
import { $state } from '../../../../react/services/angularServices';
import { CONFIG } from '../../../../config/common';
import { useAutodiscoveryApp, cloudProviderLabel, saasConnectorsLabel } from './hooks/useAutodiscoveryApp';
import { FmsdTrackingEvents, FmsdPageViewsEvents, trackFmsdPageView, trackFmsdEvent } from '../fmsdEventsTrackerUtils';
import { CloudProvider, SaaSProvider } from '../../AutoDiscoveryWizard/autoDiscoveryWizardServices';
import { fetchFmsdState } from '../fmsdServices';
import { useLocalTranslation } from '../translations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  topContainer: {
    borderBottom: `2px solid ${theme.vars.palette.bigid.gray100}`,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 100,
  },
  leftHeaderContainer: {
    padding: 24,
  },
  rightHeaderContainer: {
    padding: 24,
    '& > :first-child': {
      marginRight: 16,
    },
  },
  breadcrumbs: {
    borderBottom: `1px solid ${theme.vars.palette.bigid.gray200}`,
    padding: '13px 16px',
    '& li a': {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: theme.vars.palette.bigid.gray700,
      cursor: 'default',
    },
    '& li:last-child a': {
      color: theme.vars.palette.bigid.gray700,
    },
    '&  li:not(:last-child) a:hover': {
      color: theme.vars.palette.bigid.gray700,
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 'calc(100% - 110px)',
    flex: '1',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '15%',
    minWidth: 300,
    padding: '24px 16px',
    borderRight: `2px solid ${theme.vars.palette.bigid.gray100}`,
  },
  rightContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '85%',
    minWidth: 700,
  },
  mainTitleContainer: {
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainTitle: {
    color: theme.vars.palette.bigid.gray700,
    fontSize: 22,
    paddingLeft: 8,
  },
  mainDescription: {
    paddingLeft: 42,
    fontWeight: 400,
  },
  subTitle: {
    padding: '6px 16px',
    margin: '8px 0',
    background: theme.vars.palette.bigid.gray150,
    borderRadius: 4,
  },
  accordion: { background: theme.vars.palette.bigid.gray150, borderRadius: 0 },
  accordionItem: {
    padding: '8px 0',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    '&:hover': {
      background: theme.vars.palette.bigid.primary50,
    },
  },
  loaderWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
  },
  loader: {
    position: 'relative',
    paddingRight: 15,
  },
  progress: {
    whiteSpace: 'nowrap',
  },
}));

type SectionState = {
  expand: boolean;
  show: boolean;
};

export interface DiscoveryMenuData {
  sections: DiscoveryMenuSection[];
}

export interface DiscoveryMenuSection {
  sectionTitle: string;
  options: DiscoveryMenuOption[];
  provider?: CloudProvider | SaaSProvider;
}

export interface DiscoveryMenuOption {
  value: string;
  label: string;
  category: CloudProvider | SaaSProvider;
  progress: string | boolean;
}

interface FmsdDiscoverProps {
  setActiveViewId: (id: string) => void;
  backToPreDiscover: () => void;
}

export const FmsdDiscover: FC<FmsdDiscoverProps> = ({ backToPreDiscover }) => {
  const classes = useStyles({});
  const [selectedDsIds, setSelectedDsIds] = useState<React.ReactText[]>([]);
  const [rowsData, setRowsData] = useState<DsGridRow[]>([]);
  const [sectionsState, setSectionsState] = useState<Record<string, SectionState>>({
    [CloudProvider.AWS]: { expand: true, show: false },
    [CloudProvider.AZURE]: { expand: true, show: false },
    [SaaSProvider.SAAS_CONNECTORS]: { expand: true, show: false },
  });
  const { t } = useLocalTranslation('Discover');

  const breadcrumbs: Record<CloudProvider | SaaSProvider, Breadcrumb> = {
    [CloudProvider.AWS]: { label: cloudProviderLabel[CloudProvider.AWS], dataAid: 'fmsd-breadcrumbs-aws' },
    [CloudProvider.AZURE]: { label: cloudProviderLabel[CloudProvider.AZURE], dataAid: 'fmsd-breadcrumbs-azure' },
    [SaaSProvider.SAAS_CONNECTORS]: {
      label: saasConnectorsLabel[SaaSProvider.SAAS_CONNECTORS],
      dataAid: 'fmsd-breadcrumbs-saasconnectors',
    },
  };

  const { discoveryMenuData, discoveryAppIsLoading, activeOption, updateActiveOption } = useAutodiscoveryApp();
  const dsTypeValue = activeOption?.value ?? '';
  const dsCategory = activeOption?.category;

  const expandHandler = (name: string) => {
    setSectionsState((prev: Record<string, SectionState>) => ({
      ...prev,
      [name]: { ...prev[name], expand: !prev[name].expand },
    }));
  };
  const onBackToWizard = () => {
    backToPreDiscover();
    trackFmsdEvent(FmsdTrackingEvents.FMSD_DISCOVER_BACK_CLICK);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    updateActiveOption(value);
    trackFmsdEvent(FmsdTrackingEvents.FMSD_DISCOVER_DS_TYPE_CLICK, {
      [FmsdTrackingEvents.DS_TYPE]: value,
    });
  };

  const viewDashboardClick = () => {
    $state.go(CONFIG.states.DASHBOARD);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { fmsdPreDiscoverConnectionsState } = await fetchFmsdState();
        for (const connector in fmsdPreDiscoverConnectionsState) {
          if (
            ['aws', 'azure'].includes(connector) &&
            fmsdPreDiscoverConnectionsState[connector].credsStatus === CredsStatus.VALID
          ) {
            setSectionsState((prev: Record<string, SectionState>) => ({
              ...prev,
              [connector]: { ...prev[connector], show: true },
            }));
          }
          if (
            ['salesforce'].includes(connector) &&
            fmsdPreDiscoverConnectionsState[connector].credsStatus === CredsStatus.VALID
          ) {
            setSectionsState((prev: Record<string, SectionState>) => ({
              ...prev,
              [SaaSProvider.SAAS_CONNECTORS]: { ...prev[SaaSProvider.SAAS_CONNECTORS], show: true },
            }));
          }
        }
      } catch {
        $state.go(CONFIG.states.DASHBOARD);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    trackFmsdPageView(FmsdPageViewsEvents.FMSD_DISCOVER_PAGE_VIEW);
  }, []);

  return (
    <FmsdBase>
      <div className={classes.wrapper}>
        <div className={classes.topContainer}>
          <div className={classes.leftHeaderContainer}>
            <div className={classes.mainTitleContainer}>
              <BigidButtonIcon
                icon={BigidChevronCircleLeftIcon}
                dataAid="fmsdDiscover-back-button"
                onClick={onBackToWizard}
              />
              <div className={classes.mainTitle}>{t('title')}</div>
            </div>
            <BigidHeading6 className={classes.mainDescription}>{t('subTitle')}</BigidHeading6>
          </div>
          <div className={classes.rightHeaderContainer}>
            <SecondaryButton
              size="medium"
              onClick={viewDashboardClick}
              text={t('viewDashboardButtonText')}
              bi={{ eventType: FmsdTrackingEvents.FMSD_DISCOVER_VIEW_DASHBOARD_CLICK }}
            />
          </div>
        </div>
      </div>
      <div className={classes.mainContainer}>
        <div className={classes.leftContainer}>
          {discoveryAppIsLoading ? (
            <BigidLoader position="relative" />
          ) : (
            <>
              {discoveryMenuData.sections.map(
                ({ sectionTitle, options, provider }, key) =>
                  sectionsState[provider].show && (
                    <BigidAccordion
                      expanded={sectionsState[provider].expand}
                      key={key}
                      dataAid={generateDataAid('BigidAccordion', [sectionTitle])}
                    >
                      <BigidAccordionSummary
                        onClick={() => expandHandler(provider)}
                        size={AccordionSummarySizeEnum.medium}
                        className={classes.accordion}
                      >
                        <BigidHeading4 className={classes.subTitle}>{sectionTitle}</BigidHeading4>
                      </BigidAccordionSummary>
                      <BigidAccordionDetails className={classes.accordionItem}>
                        <RadioGroup
                          value={dsTypeValue}
                          row={false}
                          name="permissions-category-options"
                          onChange={handleOptionChange}
                        >
                          {options.map((option, index) => (
                            <div key={index} className={classes.item}>
                              <FmsdRadio
                                option={{
                                  value: option.value,
                                  label: option.label,
                                  disabled: Boolean(option?.progress),
                                }}
                                activeOption={activeOption?.value}
                                dataAid="fmsdDiscoverMenu"
                              />
                              {option?.progress && (
                                <span className={classes.loaderWrapper}>
                                  <span className={classes.loader}>
                                    <BigidLoader size={10} />
                                  </span>
                                  <span className={classes.progress}>{option.progress}</span>
                                </span>
                              )}
                            </div>
                          ))}
                        </RadioGroup>
                      </BigidAccordionDetails>
                    </BigidAccordion>
                  ),
              )}
            </>
          )}
        </div>
        <div className={classes.rightContainer}>
          {dsTypeValue ? (
            <>
              <div className={classes.breadcrumbs}>
                {dsCategory && (
                  <BigidBreadcrumbs
                    breadcrumbs={[
                      breadcrumbs[dsCategory],
                      { label: activeOption?.label ?? '', dataAid: `fmsd-breadcrumbs-${dsTypeValue}` },
                    ]}
                  />
                )}
              </div>
              <FmsdExplorer
                dataSourceName={dsTypeValue}
                onSelection={setSelectedDsIds}
                onRowsDataChange={setRowsData}
              />
              <ScanPanel selectedDsIds={selectedDsIds} rowsData={rowsData} dataSourceName={dsTypeValue} />
            </>
          ) : (
            <PendingResults />
          )}
        </div>
      </div>
    </FmsdBase>
  );
};
