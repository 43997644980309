import React, { FC, useEffect, useState } from 'react';
import { BigidHeading1, BigidHeading5 } from '@bigid-ui/components';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { History } from '../../../components/BigidHeader/HeaderService';
import { $state } from '../../../../react/services/angularServices';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { DATA_SOURCES_INIT_VIEWS } from '../DataSources';
import BigidAutoDiscoveryCardIcon from '../../../assets/icons/BigidAutoDiscoveryCardIcon.svg';
import { DataSourcesInitCard } from './DataSourcesInitCard/DataSourcesInitCard';
import { AwsCredsDialog } from '../../AutoDiscoveryWizard/AwsCredsDialog/AwsCredsDialog';
import { getApplicationPreference } from '../../../services/appPreferencesService';

const useStyles = makeStyles({
  dataSourcesInitContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'calc(100% - 8px)',
  },
  title: {
    paddingTop: 54,
    color: 'black',
    textAlign: 'center',
  },
  text: {
    paddingTop: 16,
    paddingBottom: 56,
    textAlign: 'center',
  },
  cardsContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: 40,
  },
});

export const DataSourcesInitSmallId: FC = () => {
  const { dataSourcesInitContainer, title, text, cardsContainer } = useStyles({});
  const [isCredsDialogOpen, setIsCredsDialogOpen] = useState(false);

  const onCloseCredsDialog = () => {
    setIsCredsDialogOpen(false);
  };

  const onCardClick = () => {
    if (getApplicationPreference('USE_SAAS')) {
      setIsCredsDialogOpen(true);
    } else {
      $state.go(`dataSourcesInit`, {
        path: DATA_SOURCES_INIT_VIEWS.autoDiscovery.path,
      });
    }
  };

  useEffect(() => {
    const history: History[] = sessionStorageService.get('history');
    const isNotLicesnseChange = !history || history[history.length - 1].from?.state !== 'generalSettings'; // Avoid Creds Dialog auto open for a license change scenario

    if (isNotLicesnseChange && getApplicationPreference('USE_SAAS')) {
      setIsCredsDialogOpen(true);
    }
  }, []);

  return (
    <Paper className={dataSourcesInitContainer} data-aid="DataSourcesInitSmallId">
      <AwsCredsDialog isOpen={isCredsDialogOpen} onClose={onCloseCredsDialog} />
      <div>
        <BigidHeading1 className={title} data-aid="DataSourcesInitSmallIdTitle">
          Connect Your Data
        </BigidHeading1>
        <BigidHeading5 className={text} data-aid="DataSourcesInitSmallIdText">
          Start connecting your data sources by running an auto-discovery process.
        </BigidHeading5>
      </div>
      <div className={cardsContainer} data-aid="DataSourcesInitSmallIdCards">
        <DataSourcesInitCard
          Icon={BigidAutoDiscoveryCardIcon}
          title="Discover Cloud Data Sources"
          text="Automatically discover your AWS data sources and initiate a scan"
          onClick={onCardClick}
          dataAid="DataSourcesInitSmallIdStartSelect"
        />
      </div>
    </Paper>
  );
};
