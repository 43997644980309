import './activeAttributes.component.scss';
import { module } from 'angular';
const app = module('app');
const DEFAULT_CATEGORY_COLOR = '#7bd148';
import template from './activeAttributes.component.html';
import { CORRELATION_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { isEmpty } from 'lodash';

app.component('activeAttributes', {
  template,
  controller: function (
    identityLineageService,
    classifiersService,
    notificationService,
    DeleteConfirmation,
    $uibModal,
  ) {
    'ngInject';

    const asc = 'asc';
    const desc = 'desc';

    let attributesDataPrimary = [];

    this.CATEGORY_NO_COLOR = DEFAULT_CATEGORY_COLOR;
    this.editModalInstance = {};
    this.attributeSelected = false;
    this.showSelectedAttributesDiv = false;
    this.editFriendlyNameModalInstance = {};
    this.editCategoriesModalInstance = {};
    this.attributesSelected = [];
    this.categories = [];
    this.showCategories = false;

    this.showAttributeSideBar = true;
    this.userQuery = '';

    this.$onInit = () => {
      this.isManagePermitted = isPermitted(CORRELATION_PERMISSIONS.MANAGE.name);

      this.getAttributesDataFromServer();
      this.currentOrderAttributesNameType = asc;
      this.currentOrderIdentifiabilityType = asc;
      this.currentOrderClassificationsNameType = asc;
      this.currentOrderEnrichmentsNameType = asc;
    };

    this.getAttributesDataFromServer = () => {
      identityLineageService.getCategories().then(result => {
        this.showCategories = true;
        this.categories = result;
        identityLineageService.getLineageAttributes().then(({ data }) => {
          const attributesFromServerTemp = [];
          const classificationsFromServerTemp = [];
          const enrichmentsFromServerTemp = [];

          const { enrichment_attributes = [], idsor_attributes = [], classifications = [] } = data[0]?.attributes || {};

          this.setAttributeItem(enrichment_attributes, enrichmentsFromServerTemp, 'enrichment_attributes');
          this.setAttributeItem(idsor_attributes, attributesFromServerTemp, 'idsor_attributes');
          this.setAttributeItem(classifications, classificationsFromServerTemp, 'classifications');

          if (attributesFromServerTemp.length > 0) {
            attributesDataPrimary = attributesFromServerTemp;
            this.attributesData = attributesFromServerTemp;
          }
          if (classificationsFromServerTemp.length > 0) {
            this.classificationsData = classificationsFromServerTemp;
          }
          if (enrichmentsFromServerTemp.length > 0) {
            this.enrichmentsData = enrichmentsFromServerTemp;
          }
          if (this.attributesData && this.attributesData.length > 0) {
            this.attributesData[0].showImage = true;
            this.onEvent({
              data: {
                attribute: this.attributesData[0],
              },
            });
          } else if (this.classificationsData && this.classificationsData.length > 0) {
            this.classificationsData[0].showImage = true;
            this.onEvent({
              data: {
                attribute: this.classificationsData[0],
              },
            });
          } else if (this.enrichmentsData && this.enrichmentsData.length > 0) {
            this.enrichmentsData[0].showImage = true;
            this.onEvent({
              data: {
                attribute: this.enrichmentsData[0],
              },
            });
          }
        });
      });
    };

    this.setAttributeItem = (attributesList, attributesFromServerTemp, type) => {
      const setDuplicateKeys = new Set();
      attributesList.forEach(attribute => {
        const duplicateArray = attributesList.filter(
          x => x._id === attribute._id || x.friendly_name === attribute.friendly_name,
        );
        //build the first and the other items in the duplicate array
        if (duplicateArray && duplicateArray.length > 1) {
          if (!(setDuplicateKeys.has(duplicateArray[0]._id) || setDuplicateKeys.has(duplicateArray[0].friendly_name))) {
            setDuplicateKeys.add(
              duplicateArray[0].friendly_name ? duplicateArray[0].friendly_name : duplicateArray[0]._id,
            );
            attributesFromServerTemp.push({
              original_name: attribute._id,
              glossary_id: attribute.glossary_id,
              isTheHeaderOfGroup: true,
              showArrowDown: true,
              isShow: true,
              friendly_name: attribute.friendly_name,
              description: attribute.description,
              identifiability: attribute.identifiability,
              physicalNames: this.getPhysicalNames(duplicateArray),
              collections: this.getCollectionsFromDuplicateItems(duplicateArray),
              type: type,
              showImage: false,
            });
            duplicateArray.forEach(attribute => {
              attributesFromServerTemp.push({
                original_name: attribute._id,
                glossary_id: attribute.glossary_id,
                isChildOfGroup: true,
                isShow: false,
                categories: this.getCategories(attribute.categories),
                friendly_name: attribute.friendly_name,
                description: attribute.description,
                identifiability: attribute.identifiability,
                type: type,
                collections: attribute.collections,
                showImage: false,
              });
            });
          }
        }
        //if not exist as duplicate create regular item
        else {
          attributesFromServerTemp.push({
            original_name: attribute._id,
            glossary_id: attribute.glossary_id,
            friendly_name: attribute.friendly_name,
            description: attribute.description,
            identifiability: attribute.identifiability,
            isShow: true,
            categories: this.getCategories(attribute.categories),
            type: type,
            collections: attribute.collections,
            showImage: false,
          });
        }
      });
    };

    this.getCategories = categoriesArray => {
      if (categoriesArray) {
        const categories = [];
        categoriesArray.forEach(item => {
          if (item.unique_name) {
            const category = this.categories.filter(category => category.glossary_id === item.glossary_id);
            if (category.length > 0) {
              // categories.push(category[0]);
              categories.push(item);
            }
          }
        });
        return categories;
      }
      return null;
    };

    this.getPhysicalNames = duplicateArray => {
      const physicalNames = new Set();
      duplicateArray.forEach(item => {
        if (item._id) {
          physicalNames.add(item._id);
        }
      });
      return [...physicalNames];
    };

    this.getCollectionsFromDuplicateItems = duplicateArray => {
      const collections = new Set();
      duplicateArray.forEach(item => {
        if (item.collections) {
          item.collections.forEach(collection => {
            collections.add(collection);
          });
        }
      });
      return [...collections];
    };

    this.showChildren = (attribute, source, setSelectedChilds) => {
      const childsArray = source.filter(
        x =>
          x._id === attribute.original_name ||
          (x.friendly_name === attribute.friendly_name && x.isChildOfGroup === true),
      );
      if (childsArray) {
        childsArray.forEach(item => {
          item.isShow = !item.isShow;
          attribute.showArrowDown = !item.isShow;
          if (setSelectedChilds) {
            item.attributeSelected = true;
            this.attributesSelected.push(item);
          }
        });
      }
    };

    this.selectAttribute = (attribute, source) => {
      if (attribute.isTheHeaderOfGroup) {
        this.showChildren(attribute, source, true);
      }
      attribute.attributeSelected = !attribute.attributeSelected;
      if (attribute.attributeSelected) {
        if (
          !this.attributesSelected.find(
            attr => attr.friendly_name === attribute.friendly_name || attr.original_name === attribute.original_name,
          )
        ) {
          this.attributesSelected.push(attribute);
        }
        this.showSelectedAttributesDiv = true;
      } else {
        if (attribute.isChildOfGroup) {
          let attributesSelectedLocal = angular.copy(this.attributesSelected);
          this.attributesSelected = this.attributesSelected.filter(
            attr => attr.original_name !== attribute.original_name,
          );
          attributesSelectedLocal = attributesSelectedLocal.filter(
            attr => attr.friendly_name === attribute.friendly_name,
          );
          if (attributesSelectedLocal.length == 1) {
            this.showChildren(attribute, this.attributesData);
            source.forEach(attributeItem => {
              if (attributeItem.friendly_name === attribute.friendly_name) {
                attributeItem.attributeSelected = false;
              }
            });
          }
        } else {
          this.attributesSelected = this.attributesSelected.filter(
            attr => attr.friendly_name !== attribute.friendly_name && attr.original_name !== attribute.original_name,
          );
        }
        if (this.attributesSelected.length == 0) {
          this.showSelectedAttributesDiv = false;
        }
      }
    };

    this.clearSelection = () => {
      this.attributesSelected = [];
      this.showSelectedAttributesDiv = false;
      !isEmpty(this.attributesData) &&
        this.attributesData.forEach(attribute => {
          if (attribute.attributeSelected) {
            attribute.attributeSelected = false;
          }
        });
      !isEmpty(this.enrichmentsData) &&
        this.enrichmentsData.forEach(enrichment => {
          if (enrichment.attributeSelected) {
            enrichment.attributeSelected = false;
          }
        });
      !isEmpty(this.classificationsData) &&
        this.classificationsData.forEach(classification => {
          if (classification.attributeSelected) {
            classification.attributeSelected = false;
          }
        });
    };

    this.editCategory = (category, attribute) => {
      this.editCategoriesModalInstance = $uibModal
        .open({
          animation: true,
          template: `<categories-modal
              category="$ctrl.category"
              action="$ctrl.action"
              $close="$close(category)"
              $dismiss="$dismiss()"></categories-modal>`,
          controllerAs: '$ctrl',
          controller: function () {
            this.category = category;
            this.action = category ? 'Update' : 'Create';
          },
        })
        .result.then(category => {
          if (category) {
            const data = {
              glossary_id: category.glossary_id,
              unique_name: category.glossary_id || category.display_name,
              display_name: category.display_name,
              color: category.color || DEFAULT_CATEGORY_COLOR,
              description: category.description,
            };
            // in case the user update attribute or create new category
            if (attribute) {
              // in case the category exists in the category list
              identityLineageService
                .saveCategory(data)
                .then(result => {
                  if (attribute.categories) {
                    const tempCategory = attribute.categories.find(
                      category => category.unique_name === result.unique_name,
                    );
                    if (tempCategory) {
                      tempCategory.glossary_id = result.glossary_id;
                    }
                  }

                  return identityLineageService.saveAttributesData([attribute]).then(() => {
                    this.showSelectedAttributesDiv = false;
                    this.getAttributesDataFromServer();
                  });
                })
                .then(() => notificationService.success(`Attributes Updated`))
                .catch(err => notificationService.error(`Failed to update attributes with error: ${err}`));
            }
            // in case the user selected multiple attributes
            else {
              identityLineageService
                .saveCategory(data)
                .then(results => {
                  this.attributesSelected.forEach(attribute => {
                    if (!attribute.categories) {
                      attribute.categories = [];
                    }
                    attribute.categories.push({
                      display_name: category.display_name,
                      unique_name: results.glossary_id,
                    });
                  });
                  return identityLineageService.saveAttributesData(this.attributesSelected).then(() => {
                    this.showSelectedAttributesDiv = false;
                    this.attributesSelected = [];
                    this.getAttributesDataFromServer();
                  });
                })
                .then(() => notificationService.success(`Attributes Updated`))
                .catch(err => notificationService.error(`Failed to update attributes with error: ${err}`));
            }
          }
        });
    };

    this.deleteCategory = (category, attribute) => {
      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: `Delete ${category.display_name}`,
        bodyText: `Are you sure you want to delete "${category.display_name}"?`,
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        attribute.categories = attribute.categories.filter(
          categoryItem => categoryItem.unique_name !== category.unique_name,
        );
        identityLineageService
          .saveAttributesData([attribute])
          .then(() => {
            this.getAttributesDataFromServer();
            this.showSelectedAttributesDiv = false;
            notificationService.success(`${category.unique_name} deleted Successfully! `);
          })
          .catch(() => notificationService.error(`Delete ${category.unique_name} Failed!`));
      });
    };

    this.chooseAttribute = attribute => {
      if (this.attributesData && this.attributesData.length) {
        this.attributesData.forEach(function (attribute) {
          attribute.showImage = false;
        });
      }
      if (this.classificationsData && this.classificationsData.length) {
        this.classificationsData.forEach(function (attribute) {
          attribute.showImage = false;
        });
      }
      if (this.enrichmentsData && this.enrichmentsData.length) {
        this.enrichmentsData.forEach(function (attribute) {
          attribute.showImage = false;
        });
      }
      attribute.showImage = !attribute.showImage;
      this.onEvent({
        data: {
          attribute: attribute,
        },
      });
    };

    this.toggleAttributeSideBar = () => {
      this.onAttributeSidebarToggled();
    };

    //Start Order Table

    this.orderAttributesName = () => {
      if (this.attributesData && this.attributesData.length > 0) {
        if (this.currentOrderAttributesNameType === asc) {
          this.attributesData.sort(compareAscByName);
          this.currentOrderAttributesNameType = desc;
        } else {
          this.attributesData.sort(compareDescByName);
          this.currentOrderAttributesNameType = asc;
        }
      }
    };

    this.orderClassificationName = () => {
      if (this.classificationsData && this.classificationsData.length > 0) {
        if (this.currentOrderClassificationsNameType === asc) {
          this.classificationsData.sort(compareAscByName);
          this.currentOrderClassificationsNameType = desc;
        } else {
          this.classificationsData.sort(compareDescByName);
          this.currentOrderClassificationsNameType = asc;
        }
      }
    };

    this.orderEnrichmentName = () => {
      if (this.enrichmentsData && this.enrichmentsData.length > 0) {
        if (this.currentOrderEnrichmentsNameType === asc) {
          this.enrichmentsData.sort(compareAscByName);
          this.currentOrderEnrichmentsNameType = desc;
        } else {
          this.enrichmentsData.sort(compareDescByName);
          this.currentOrderEnrichmentsNameType = asc;
        }
      }
    };

    this.editFriendlyName = attribute => {
      this.editFriendlyNameModalInstance = $uibModal
        .open({
          animation: true,
          template:
            "<friendly-name-modal attribute='$ctrl.attribute' $close='$close(attribute)' $dismiss='$dismiss()'></friendly-name-modal>",
          controllerAs: '$ctrl',
          controller: [
            'attribute',
            '$uibModalInstance',
            function (attribute) {
              this.attribute = attribute;
            },
          ],

          resolve: {
            attribute: () => {
              return attribute;
            },
          },
        })
        .result.then(
          attribute => {
            if (attribute) {
              attribute.friendly_name = attribute.name;
              delete attribute.name;
              identityLineageService.saveAttributesData(attribute).then(() => {
                this.getAttributesDataFromServer();
              });
            }
          },
          () => {
            //dismiss
          },
        );
    };

    this.editClassification = (event, classification) => {
      event.stopPropagation();

      classifiersService.getClassifier(classification.original_name).then(
        result => {
          const classification = result.data;

          this.editModalInstance = $uibModal
            .open({
              animation: true,
              template:
                "<classifiers on-form-closed='$ctrl.onFormClosed()' " +
                " on-form-submitted='$ctrl.onFormSubmitted(isUpdated)' form-only='true' " +
                " form-data='$ctrl.classification'></classifiers>",
              controllerAs: '$ctrl',
              controller: [
                'classification',
                '$uibModalInstance',
                function (classification, $uibModalInstance) {
                  this.classification = classification;

                  this.onFormSubmitted = isUpdated => {
                    $uibModalInstance.close(isUpdated);
                  };

                  this.onFormClosed = () => {
                    $uibModalInstance.close();
                  };
                },
              ],
              size: 'lg',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                classification: () => {
                  return classification;
                },
              },
            })
            .result.then(isUpdated => {
              if (isUpdated) this.getAttributesDataFromServer();
            });
        },
        () => {
          notificationService.error('An error has occurred!');
        },
      );
    };

    this.deleteClassification = (event, classification) => {
      event.stopPropagation();

      const modalOptions = {
        closeButtonText: 'Close',
        actionButtonText: 'Delete',
        headerText: 'Delete classification',
        bodyText: "Are you sure you want to delete '" + classification.original_name + "'?",
      };

      DeleteConfirmation.showModal({}, modalOptions).then(() => {
        classifiersService.deleteClassifier(classification.name).then(
          () => {
            notificationService.success('Selected classification has been deleted successfully!');
            this.getAttributesDataFromServer();
          },
          () => {
            notificationService.error('An error has occurred!');
          },
        );
      });
    };

    this.addClassification = event => {
      event.stopPropagation();

      this.createModalInstance = $uibModal
        .open({
          animation: true,
          template:
            "<classifiers on-form-closed='$ctrl.onFormClosed()' " +
            " on-form-submitted='$ctrl.onFormSubmitted(isUpdated)' form-only='true'></classifiers>",
          controllerAs: '$ctrl',
          controller: [
            '$uibModalInstance',
            function ($uibModalInstance) {
              this.onFormSubmitted = isUpdated => {
                $uibModalInstance.close(isUpdated);
              };

              this.onFormClosed = () => {
                $uibModalInstance.close();
              };
            },
          ],
          size: 'lg',
          backdrop: 'static',
          keyboard: false,
        })
        .result.then(isUpdated => {
          if (isUpdated) this.getAttributesDataFromServer();
        });
    };

    this.onUserQueryChanged = query => {
      if (query === '') {
        this.attributesData = attributesDataPrimary;
      } else {
        const _query = query.toLowerCase();
        this.attributesData = attributesDataPrimary.filter(
          attr =>
            attr.original_name.toLowerCase().includes(_query) || attr.friendly_name.toLowerCase().includes(_query),
        );
      }
    };

    function compareDescByName(a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }

    function compareAscByName(a, b) {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    }

    this.orderIdentifiability = () => {
      if (this.attributesData && this.attributesData.length > 0) {
        if (this.currentOrderIdentifiabilityType === asc) {
          this.attributesData.sort(compareAscByIdentifiability);
          this.currentOrderIdentifiabilityType = desc;
        } else {
          this.attributesData.sort(compareDescByIdentifiability);
          this.currentOrderIdentifiabilityType = asc;
        }
      }
    };

    function compareDescByIdentifiability(a, b) {
      if (a.identifiability < b.identifiability) return -1;
      if (a.identifiability > b.identifiability) return 1;
      return 0;
    }

    function compareAscByIdentifiability(a, b) {
      if (a.identifiability > b.identifiability) return -1;
      if (a.identifiability < b.identifiability) return 1;
      return 0;
    }

    //End Order Table
  },
  bindings: {
    onEvent: '&',
    onAttributeSidebarToggled: '&',
  },
});

app.filter('parseFloatIdentifiabilityToPercentage', () => value => `${Math.floor(value * 100)}%`);
