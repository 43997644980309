import React, { FC, memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidHeading2, BigidBody1, BigidColorsV2, BigidLink } from '@bigid-ui/components';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { BigidSearchIllustration } from '@bigid-ui/icons';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  row: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  header: {
    color: BigidColorsV2.purple[400],
  },
  link: {
    marginLeft: '4px',
  },
});

const dataAid = 'MetadataSearchFullNoCriteriaHandler';

export const MetadataSearchNoCriteriaHandler: FC = memo(() => {
  const classes = useStyles();

  const handleToDashboardLinkClick = (): void => {
    $state.go(CONFIG.states.DASHBOARD);
  };

  return (
    <div className={classes.root} data-aid={dataAid}>
      <div className={classes.row}>
        <BigidSearchIllustration size="medium" />
      </div>
      <div className={classes.row}>
        <BigidHeading2 classes={{ root: classes.header }} data-aid={`${dataAid}-title`}>
          No Search Applied
        </BigidHeading2>
      </div>
      <div className={classes.row}>
        <BigidBody1 data-aid={`${dataAid}-description`}>
          It looks like you didn&apos;t enter a search term or apply a filter. Perform a Search/Filter or
          <BigidLink classNames={classes.link} text="Back to Dashboard" onClick={handleToDashboardLinkClick} />
        </BigidBody1>
      </div>
    </div>
  );
});

MetadataSearchNoCriteriaHandler.displayName = 'MetadataSearchNoCriteriaHandler';
