import { BigidShareUrl, BigidShareUrlProps } from '@bigid-ui/components';
import React, { FC, useEffect, useState } from 'react';
import { useLocalTranslation } from './translations';
import { useHashQueryParams } from '../../components/hooks/useHashQueryParams';

type ShareUrlProps = Pick<BigidShareUrlProps, 'onClose' | 'onToggle' | 'onCopy'>;

export const ShareUrl: FC<ShareUrlProps> = ({ onClose, onCopy, onToggle }) => {
  const [urlToShare, setUrlToShare] = useState(window.location.href);
  const { t } = useLocalTranslation('actions');
  const hashParams = useHashQueryParams();

  useEffect(() => {
    setUrlToShare(window.location.href);
  }, [hashParams]);

  return (
    <BigidShareUrl
      shareUrl={urlToShare}
      isDisabled={false}
      buttonText={t('shareResultsTitle')}
      onToggle={onToggle}
      onCopy={onCopy}
      onClose={onClose}
    />
  );
};
