import React, { FC } from 'react';
import { BigidTooltip, TertiaryButton } from '@bigid-ui/components';
import { BigidSaveIcon, BigidScheduleIcon } from '@bigid-ui/icons';
import { ScanCreateData } from './createScanContext';
import { onClickSaveScan } from './ScanCreateWizardUtils';

export const SCAN_NOW_BUTTON = 'Scan Now';
export const SCHEDULE_SCAN_BUTTON = 'Schedule Scan';
export const SAVE_SCAN_BUTTON = 'Save Scan';
export const SAVE_SCAN_BUTTON_TOOLTIP = 'Select a template to save the scan';
export const SCHEDULE_SCAN_BUTTON_TOOLTIP = 'Select a template to schedule the scan';
export const SCAN_NOW_TOOLTIP = 'Select a template to start the scan';

interface ScheduleButtonsProps {
  createScanData: ScanCreateData;
  isNextDisabled: boolean;
  onNext: () => void;
}

export const ScheduleButtons: FC<ScheduleButtonsProps> = ({ createScanData, isNextDisabled, onNext }) => {
  return (
    <>
      <BigidTooltip title={SAVE_SCAN_BUTTON_TOOLTIP} placement="bottom" isDisabled={!isNextDisabled}>
        <span>
          <TertiaryButton
            size="large"
            startIcon={<BigidSaveIcon />}
            onClick={() => onClickSaveScan(createScanData)}
            disabled={isNextDisabled}
            dataAid="create-scan-wizard-save-scan-button"
            text={SAVE_SCAN_BUTTON}
          />
        </span>
      </BigidTooltip>
      <BigidTooltip title={SCHEDULE_SCAN_BUTTON_TOOLTIP} placement="bottom" isDisabled={!isNextDisabled}>
        <span>
          <TertiaryButton
            size="large"
            startIcon={<BigidScheduleIcon />}
            onClick={onNext}
            disabled={isNextDisabled}
            dataAid="create-scan-wizard-schedule-scan-button"
            text={SCHEDULE_SCAN_BUTTON}
          />
        </span>
      </BigidTooltip>
    </>
  );
};
