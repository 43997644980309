import { getEncodedArray, queryService } from '../../../services/queryService';
import { httpService } from '../../../services/httpService';
import {
  FetchWorkflowsResponse,
  DeleteWorkflowsResponse,
  GetWorkflowsTotalByEventsResponse,
  WorkflowConfiguration,
  ActionCenterMetadata,
  OpenServiceTicketMetadata,
  GetJiraUsersInput,
  GetJiraUsersResponse,
} from './actionWorkflowTypes';
import { BigidGridQueryComponents } from '@bigid-ui/grid';

export const getWorkflows = async (queryComponents: BigidGridQueryComponents) => {
  const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
  const {
    data: { data },
  } = await httpService.fetch<{ data: FetchWorkflowsResponse }>(`action-center/workflows?${gridConfigQuery}`);
  return data;
};

export const deleteWorkflow = (id: string) =>
  httpService.delete<{ data: DeleteWorkflowsResponse }>(`action-center/workflows/${id}`);

export const getWorkflowsTotalByEvents = async () => {
  const {
    data: { data },
  } = await httpService.fetch<{ data: GetWorkflowsTotalByEventsResponse }>('action-center/workflows/events/count');
  return data;
};

export const saveWorkflow = (workflow: WorkflowConfiguration, workflowId?: string) =>
  workflowId
    ? httpService.put(`action-center/workflows/${workflowId}`, workflow)
    : httpService.post('action-center/workflows', workflow);

export const fetchActionCenterMetadata = () =>
  httpService.fetch<{ data: ActionCenterMetadata }>('action-center/workflows/metadata');

export const fetchOpenServiceTicketMetadata = async (configurationId: string): Promise<any> => {
  const {
    data: { data },
  } = await httpService.fetch<{ data: OpenServiceTicketMetadata }>(
    `action-center/configurations/ticket/metadata/${configurationId}`,
  );
  return data;
};

export const fetchJiraUsers = async ({ configurationId, query, skip = 0, limit = 50, ids }: GetJiraUsersInput) => {
  const idsQuery = ids?.length ? `&ids=${getEncodedArray(ids)}` : '';
  const fetchQuery = `skip=${skip}&limit=${limit}&query=${query}${idsQuery}`;
  const {
    data: { data },
  } = await httpService.fetch<{ data: GetJiraUsersResponse }>(
    `action-center/configurations/jira/users/${configurationId}?${fetchQuery}`,
  );
  return data;
};
