import React, { FC, useEffect, useState } from 'react';
import { convertToAngular } from '../../../common/services/convertToAngular';
import angular from 'angular';
import { Chatbot } from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { getConfig } from './config';
import { MessageParser } from './MessageParser';
import { ActionProvider } from './ActionProvider';
import {
  getBigchatLocalStorage,
  initBigchatSessionAndLocalStorage,
  loadMessages,
} from '../../services/bigchat/bigchatStorageService';
import { validateInput } from './BigChatUtils';
import { BigidTriggerButton, BigidTriggerButtonSize } from '@bigid-ui/components';
import { BigidAiRobotIcon, BigidChevronDownIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { BigChatContainer, ButtonWrapper } from './BigChatStyles';
import { openBigChatDisclaimerModal } from './disclaimer/disclaimerModalService';
import { BigChatCloseButton } from './BigChatCloseButton';
import { useLocalTranslation } from './translations';
import { BigChatSetInHeaderProps, DISCLAIMER_MODAL_STATE } from './BigChatTypes';

export const BigChat: FC<BigChatSetInHeaderProps> = ({ setShowBigChatInHeader }) => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
  const [disableOpenChat, setDisableOpenChat] = useState<boolean>(false);
  const { t } = useLocalTranslation();

  useEffect(() => {
    initBigchatSessionAndLocalStorage();
  }, []);

  const handleClick = (): void => {
    const shouldOpenDisclaimerModal = getBigchatLocalStorage() === DISCLAIMER_MODAL_STATE.NOT_SHOWED;
    if (shouldOpenDisclaimerModal && !openChat) {
      openBigChatDisclaimerModal(setOpenChat);
    } else {
      setOpenChat(prev => !prev);
    }
  };

  return (
    <div data-aid={`${generateDataAid('BigChat', ['wrapper'])}`}>
      <BigChatContainer data-aid={`${generateDataAid('BigChat', ['container'])}`}>
        {openChat && (
          <Chatbot
            config={getConfig(setDisableOpenChat)}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            messageHistory={loadMessages()}
            placeholderText={`${t('chatbot.placeHolder')}`}
            validator={validateInput}
            disableScrollToBottom={true}
          />
        )}
        {showCloseButton && !openChat && <BigChatCloseButton setShowBigChatInHeader={setShowBigChatInHeader} />}
        <ButtonWrapper
          data-aid={`${generateDataAid('BigChat', ['open', 'chat', 'button'])}`}
          onMouseEnter={() => {
            if (!disableOpenChat) {
              setShowCloseButton(true);
            }
          }}
          onMouseLeave={() => {
            setTimeout(() => setShowCloseButton(false), 3000);
          }}
        >
          <BigidTriggerButton
            variant="primary"
            size={BigidTriggerButtonSize.large}
            onClick={handleClick}
            icon={openChat ? BigidChevronDownIcon : BigidAiRobotIcon}
            isDisabled={disableOpenChat}
          />
        </ButtonWrapper>
      </BigChatContainer>
    </div>
  );
};

angular
  .module('app')
  .component('bigchat', convertToAngular<BigChatSetInHeaderProps>(BigChat, ['setShowBigChatInHeader']));
