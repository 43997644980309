import React, { FC, useMemo, useCallback, useState } from 'react';
import { BigidAddContainedIcon, BigidRemoveIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import {
  ActionData,
  BigidColorsV2,
  BigidFieldFilterOperator,
  BigidFilter,
  BigidSidePanel,
  ToolbarActionType,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridColumn,
  BigidGridQueryComponents,
  TagsFormatterProps,
  ChipsFormatterProps,
} from '@bigid-ui/grid';
import { notificationService } from '../../../../services/notificationService';
import { DsarObjects, DataSource, SarProfile } from '../ProfileSettingsTypes';
import { getCellValueForIncludeExcludeV2 as getCellValueForIncludeExclude } from '../gridCellValues';
import { isPermitted } from '../../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { NEW_DSAR_SETTINGS_STATE_NAMES, OnOffCellValue } from '../SarProfileSettingsTypes';
import { useUserPreferences } from '../../../../components/hooks/useUserPrefrences';
import { getObjects, updateObjects } from '../../dsarService';
import { getIconByObjectType, getObjectTypeName } from '../../../DataCatalog/utils';
import { TagAssignmentTarget, TagEntity, getTagsAllPairs } from '../../../TagsManagement/TagsManagementService';
import { getTagFormattedName, getTagIcon } from '../../../TagsManagement/TagsManagementUtils';
import { DataSourceLayoutNameCell } from '../../../DataSources/DataSourceConnections/DataSourceLayoutNameCell';
import { DataSourceModel } from '../../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { ObjectsSettingsSidePanelContent } from './ObjectsSettingsSidePanelContent';
import { DsarSettingsTrackingEvents } from '../../analyticsUtils';

const GridWrapper = styled('div')`
  background-color: ${BigidColorsV2.white};
  border-radius: 4px;
  border: 1px solid ${BigidColorsV2.gray[300]};
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const CellContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

type Grid = BigidGridWithToolbarProps<DsarObjects>;
type ObjectsProps = {
  fetchProfileData: () => Promise<DataSource[]>;
} & Pick<SarProfile, '_id' | 'dsConnections' | 'name'>;
type ColumnProps = DsarObjects & { tagsFormatter: TagsFormatterProps; attributesFormatter: ChipsFormatterProps };

const SIDE_PANEL_ALLOWED_OBJECT_TYPES = ['PARTITIONED_TABLE', 'rdb'];
const isFileObjectType = (type: string) => type?.toLowerCase() === 'file';

const columns: BigidGridColumn<ColumnProps>[] = [
  {
    width: 350,
    title: 'Object Name',
    name: 'objectName',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ objectName }) => objectName,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 120,
    title: 'Selection',
    name: 'included',
    type: BigidGridColumnTypes.CHIP,
    getCellValue: ({ included, objectType }) =>
      getCellValueForIncludeExclude(included, isFileObjectType(objectType) ? OnOffCellValue.NA : ''),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    title: 'Object Type',
    name: 'extendedObjectType',
    width: 150,
    type: BigidGridColumnTypes.CUSTOM,
    filteringEnabled: false,
    sortingEnabled: false,
    getCellValue: ({ extendedObjectType }) => {
      const Icon = getIconByObjectType(extendedObjectType);
      const objectTypeName = getObjectTypeName(extendedObjectType);

      return (
        <CellContent>
          {Icon && <Icon staticMode={true} />} {objectTypeName}
        </CellContent>
      );
    },
  },
  {
    width: 300,
    title: 'Data Source',
    name: 'source',
    type: BigidGridColumnTypes.CUSTOM,
    getCellValue: ({ dataSource, dataSourceType }) => (
      <DataSourceLayoutNameCell
        row={{ id: dataSource, type: dataSourceType, displayType: dataSource } as DataSourceModel}
        iconWidth={24}
      />
    ),
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    title: 'Attributes',
    name: 'attributes',
    type: BigidGridColumnTypes.CHIPS,
    width: 400,
    getCellValue: ({ attributesFormatter }) => attributesFormatter,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    title: 'Tags',
    name: 'tags',
    type: BigidGridColumnTypes.TAGS,
    width: 400,
    getCellValue: ({ tagsFormatter }) => tagsFormatter,
    filteringEnabled: false,
    sortingEnabled: false,
  },
  {
    width: 350,
    title: 'Full Object Name',
    name: 'fullObjectName',
    type: BigidGridColumnTypes.TEXT,
    getCellValue: ({ fullObjectName }) => fullObjectName,
    filteringEnabled: false,
    sortingEnabled: false,
  },
];

export const ObjectsSettings: FC<ObjectsProps> = ({ _id: profileId, fetchProfileData, name: profileName }) => {
  const [sidePanelState, setSidePanelState] = useState(null);
  const getInitialFilterToolbarConfig = useCallback(() => getFilterConfig(fetchProfileData), [fetchProfileData]);
  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.OBJECTS,
    initialGridColumns: columns,
    getInitialFilterToolbarConfig,
  });

  const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarObjects> = useMemo(() => {
    const includeExcludeExecutor = async (
      isIncludedInReport: boolean,
      { selectedRows, filter, allSelected }: ActionData,
    ) => {
      const ids = selectedRows.map(({ id }) => id);
      const shouldUpdate = await includeExcludeObjects(profileId, isIncludedInReport, ids, filter, allSelected);
      return { shouldGridReload: shouldUpdate, shouldClearSelection: shouldUpdate };
    };

    const gridWithToolbarConfig: BigidGridWithToolbarProps<DsarObjects> = {
      showFilteringControls: true,
      columns: gridColumns,
      entityName: 'Objects',
      isRowDisabled: row => isFileObjectType(row.objectType),
      onRowClick: row => {
        const isSidePanelApplicable = SIDE_PANEL_ALLOWED_OBJECT_TYPES.includes(row.objectType);
        if (isSidePanelApplicable) {
          setSidePanelState({
            profileId,
            dataSource: row.dataSource,
            objectName: row.objectName,
            fullyQualifiedObjectName: row.fullyQualifiedObjectName,
            objectId: row.id,
          });
        }
      },
      defaultSorting: preferences?.grid?.sort || [{ field: 'objectName', order: 'asc' }],
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      toolbarActions: [
        {
          label: 'Include',
          type: ToolbarActionType.TERTIARY,
          icon: BigidAddContainedIcon,
          execute: actionData => includeExcludeExecutor(true, actionData),
          disable: shouldDisableAction,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_INCLUDE_ACTION,
          },
        },
        {
          label: 'Exclude',
          type: ToolbarActionType.TERTIARY,
          icon: BigidRemoveIcon,
          execute: actionData => includeExcludeExecutor(false, actionData),
          disable: shouldDisableAction,
          show: ({ totalRows }) => !!totalRows && isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
          bi: {
            eventType: DsarSettingsTrackingEvents.PROFILE_OBJECTS_EXCLUDE_ACTION,
          },
        },
      ],
      filterToolbarConfig,
      fetchData: async gridQueryParams => {
        const { objects, totalCount } = await getObjectsById(profileId, gridQueryParams);
        const tags = await getTagsAllPairs();
        const gridDataWithFormatters = getGridDataWithFormatters(objects, tags);
        return {
          data: gridDataWithFormatters,
          totalCount,
        };
      },
    };

    return gridWithToolbarConfig;
  }, [gridColumns, preferences?.grid?.sort, profileId, filterToolbarConfig, updatePreferences]);

  return (
    <>
      {isReady && (
        <GridWrapper>
          <BigidGridWithToolbar key={profileId} {...gridWithToolbarConfig} />
          <BigidSidePanel
            isShowBackdrop
            maxWidth="large"
            open={!!sidePanelState}
            title={sidePanelState?.objectName}
            basicInfo={
              <>
                {'Data Source: '}
                {sidePanelState?.dataSource}
                <span style={{ marginLeft: 24 }}>
                  {'Profile: '}
                  {profileName}
                </span>
              </>
            }
            onClose={() => setSidePanelState(null)}
            content={sidePanelState ? <ObjectsSettingsSidePanelContent {...sidePanelState} /> : null}
            zIndex="99999"
          />
        </GridWrapper>
      )}
    </>
  );
};

const getObjectsById = async (
  id: string,
  gridQueryParams?: BigidGridQueryComponents,
): Promise<{ objects: DsarObjects[]; totalCount: number }> => {
  try {
    const { objects, totalCount } = await getObjects(id, gridQueryParams);
    return { objects, totalCount };
  } catch (err) {
    notificationService.error(`Failed to get Objects Settings.`);
    console.error(`Failed to get SAR Request objects by id '${id}': ${JSON.stringify(err)}`);
    return { objects: [], totalCount: 0 };
  }
};

const shouldDisableAction = ({ selectedRowIds, allSelected, filter }: ActionData) =>
  !selectedRowIds.length || (allSelected && !filter.length);

const includeExcludeObjects = async (
  profileId: string,
  isIncludedInReport: boolean,
  ids: string[],
  filter: BigidFilter,
  allSelected: boolean,
) => {
  try {
    await updateObjects(profileId, {
      data: {
        includedObjectToDsarScan: isIncludedInReport,
      },
      query: {
        filter: allSelected
          ? filter
          : [
              {
                field: '_id',
                operator: 'in' as BigidFieldFilterOperator,
                value: ids,
              },
            ],
      },
    });
    notificationService.success(`Changes saved`);
    return true;
  } catch (err) {
    notificationService.error(`Failed to update Objects Settings.`);
    console.error(`Failed to update Objects Settings by ids '${ids.join()}': ${JSON.stringify(err)}`);
    return false;
  }
};

const getFilterConfig = async (fetchProfileData: () => Promise<DataSource[]>): Promise<Grid['filterToolbarConfig']> => {
  return {
    filters: [
      {
        title: 'Data Source',
        field: 'source',
        operator: 'in',
        listWidth: 400,
        value: [],
        options: [],
        isSearchAsync: true,
        loadSearchOptions: () =>
          fetchProfileData().then(dsConnections =>
            dsConnections.map(ds => ({ value: ds.name, label: ds.name, isSelected: false })),
          ),
        shouldLoadInitialOptions: true,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['objectName'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

const getGridDataWithFormatters = (objects: DsarObjects[], allPairsTags: TagEntity[]) => {
  const getKey = (tag: TagEntity) => `${tag.tagId}-${tag.valueId}`;
  const allPairsTagsMapper = allPairsTags.reduce((mapper: Record<string, TagEntity>, tag) => {
    mapper[getKey(tag)] = tag;
    return mapper;
  }, {});

  const gridData = objects.map(object => {
    const tagsFormatter: TagsFormatterProps = {
      tags: {
        value: object.tags
          .reduce((tagsFiltered, objectTag) => {
            const allPairsTag = allPairsTagsMapper[getKey(objectTag)];
            const isObjectTag = objectTag.tagType === TagAssignmentTarget.object;

            const tag = {
              ...objectTag,
              ...allPairsTag,
            };

            const isHidden = tag.properties?.hidden;
            const tagExists = tagsFiltered.find(t => t.tagName === tag.tagName && t.tagValue === tag.tagValue);
            if (!isObjectTag || !allPairsTag || tagExists || isHidden) {
              return tagsFiltered;
            }

            return [tag, ...tagsFiltered];
          }, [])
          .map(({ tagName, tagValue, tagType, properties }) => ({
            name: getTagFormattedName(tagName),
            value: tagValue,
            iconDescription: undefined,
            icon: getTagIcon(properties, tagType),
          })),
        isDisabled: true,
        isAutoFit: false,
        entityMaxWidth: 150,
        tagBgColor: BigidColorsV2.purple[50],
      },
    };

    const attributesFormatter = {
      chips: {
        value: object.attributes.map(attr => ({
          label: attr,
        })),
        isDisabled: true,
        isAutoFit: false,
      },
    };

    return {
      ...object,
      tagsFormatter,
      attributesFormatter,
    };
  });

  return gridData;
};
