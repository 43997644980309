import React, { useEffect, useState } from 'react';
import { loadDefaultConfigsForWizard } from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';
import {
  AutoDiscoveryWizardContextState,
  DEFAULT_AUTO_DISCOVERY_CONFIG_STATE,
} from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { CloudProvider } from '../constants';

export interface UseDiscoveryConfigModalState {
  type: CloudProvider;
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

const useLoadDiscoveryParamsConfigIfNotPassed = (
  { type, discoveryConfigDataRef }: UseDiscoveryConfigModalState,
  discoveryConfigData: AutoDiscoveryWizardContextState,
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>,
) => {
  const isConfigLoaded = !!discoveryConfigData.configData;

  useEffect(() => {
    const loadConfig = async () => {
      const configs = await loadDefaultConfigsForWizard(type);
      discoveryConfigDataRef.current = {
        ...discoveryConfigDataRef.current,
        ...configs,
      };
      setDiscoveryConfigData(current => ({
        ...current,
        ...configs,
        type,
      }));
    };
    if (!isConfigLoaded) {
      loadConfig();
    }
  }, [discoveryConfigDataRef, isConfigLoaded, setDiscoveryConfigData, type]);

  return isConfigLoaded;
};

export const useDiscoveryConfigModalState = ({ type, discoveryConfigDataRef }: UseDiscoveryConfigModalState) => {
  const [discoveryConfigData, setDiscoveryConfigData] = useState<AutoDiscoveryWizardContextState>({
    ...DEFAULT_AUTO_DISCOVERY_CONFIG_STATE,
    ...discoveryConfigDataRef.current,
    type,
    isDiscovery: false,
  });

  const isConfigLoaded = useLoadDiscoveryParamsConfigIfNotPassed(
    { type, discoveryConfigDataRef },
    discoveryConfigData,
    setDiscoveryConfigData,
  );

  return { discoveryConfigData, setDiscoveryConfigData, isConfigLoaded };
};
