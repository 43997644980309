import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { BigidBody1, BigidColorsV2, BigidPaper } from '@bigid-ui/components';
import { BigidNonActionAddDs } from '@bigid-ui/icons';
import { ScanTypes, ScanTypesToDescriptions } from '../../../scanTemplateTypes';
import { getScanTypeLabel } from '../../../../ScanUtils';

const useStyles = makeStyles({
  paper: {
    padding: 16,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    marginBottom: 6,
    display: 'inline-block',
  },
});

const BigidNonActionAddDsIconResized = styled(BigidNonActionAddDs)`
  height: 18px;
  width: 27px;
  margin-right: 6px;
`;

const DescriptionHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const BoldBody1 = styled(BigidBody1)`
  font-weight: 700;
`;

interface ScanTypeDescriptionProps {
  scanType: ScanTypes;
}

export const ScanTypeDescription: FC<ScanTypeDescriptionProps> = ({ scanType }) => {
  const { paper } = useStyles();
  return (
    <BigidPaper classes={{ root: paper }} dataAid="ScanTypeDescription">
      <DescriptionHeaderWrapper>
        <BoldBody1>{getScanTypeLabel(scanType)}</BoldBody1>
      </DescriptionHeaderWrapper>
      <BigidBody1>{ScanTypesToDescriptions[scanType]}</BigidBody1>
    </BigidPaper>
  );
};
