import { BigidGrid, BigidGridColumn, BigidGridColumnTypes, BigidGridProps, useFetch } from '@bigid-ui/grid';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { module } from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { BigidFieldFilter, BigidFilter } from '@bigid-ui/components';
import { getFieldFiltersSorted } from '@bigid-ui/layout';
import { addPropertyToAllObjectsInArray } from '../DataSourceConnectionModal/utils/utils';

export enum DataSourceTestConnectionRowType {
  STRUCTURED = 'STRUCTURED',
  UNSTRUCTURED = 'UNSTRUCTURED',
  APP = 'APP',
  STRUCTURED_FILE = 'STRUCTURED_FILE',
  PARTITIONED_TABLE = 'PARTITIONED_TABLE',
}
export interface DataSourceTestConnectionRow {
  id: string;
  tblName?: string;
  schema?: string;
  columnsAsDelimitedString?: string;
  objectType?: DataSourceTestConnectionRowType;
}

interface DataSourcesTestConnectionGridProps {
  rows: DataSourceTestConnectionRow[];
  isSmall?: boolean;
  gridRowType?: DataSourceTestConnectionRowType;
  cellWidth?: string;
}

type DataSourceRowKeys = keyof DataSourceTestConnectionRow;

type FilterValues = {
  [fieldsName in DataSourceRowKeys]: Pick<BigidFieldFilter, 'value'>[];
};

const appDsColumns: BigidGridColumn<DataSourceTestConnectionRow>[] = [
  {
    title: 'Object Name',
    name: 'objectName',
    getCellValue: ({ tblName }) => tblName,
    type: BigidGridColumnTypes.TEXT,
    width: '560',
  },
];

const unstructuredDsColumns: BigidGridColumn<DataSourceTestConnectionRow>[] = [
  {
    title: 'Object Name',
    name: 'objectName',
    getCellValue: ({ tblName }) => tblName,
    type: BigidGridColumnTypes.TEXT,
    width: '280',
  },
  {
    title: 'Path',
    name: 'path',
    getCellValue: ({ schema }) => schema,
    type: BigidGridColumnTypes.TEXT,
    width: '280',
  },
];

const structureDsColumns: BigidGridColumn<DataSourceTestConnectionRow>[] = [
  {
    title: 'Schema',
    name: 'schema',
    getCellValue: ({ schema }) => schema,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    title: 'Table Name',
    name: 'tblName',
    getCellValue: ({ tblName }) => tblName,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    title: 'Columns',
    name: 'columnsAsDelimitedString',
    getCellValue: ({ columnsAsDelimitedString }) => columnsAsDelimitedString,
    type: BigidGridColumnTypes.TEXT,
  },
];

const mapDataSourceRowTypeToGridColumns: {
  [key in DataSourceTestConnectionRowType]: BigidGridColumn<DataSourceTestConnectionRow>[];
} = {
  [DataSourceTestConnectionRowType.STRUCTURED]: structureDsColumns,
  [DataSourceTestConnectionRowType.UNSTRUCTURED]: unstructuredDsColumns,
  [DataSourceTestConnectionRowType.APP]: appDsColumns,
  [DataSourceTestConnectionRowType.STRUCTURED_FILE]: unstructuredDsColumns,
  [DataSourceTestConnectionRowType.PARTITIONED_TABLE]: unstructuredDsColumns,
};

export const DataSourcesTestConnectionGrid: FC<DataSourcesTestConnectionGridProps> = ({
  rows = [],
  isSmall = false,
  gridRowType = DataSourceTestConnectionRowType.STRUCTURED,
  cellWidth,
}) => {
  const [id, setId] = useState(1);

  const useFetchState = useFetch({
    id,
    pageSize: 1000,
    fetchDataFunction: async ({ filter }) => {
      const data = getFilteredData(rows, filter);
      return {
        data,
        totalCount: data.length,
      };
    },
  });

  useEffect(() => {
    setId(currentId => currentId + 1);
  }, [rows]);

  const configuration: BigidGridProps<DataSourceTestConnectionRow> = useMemo(() => {
    let gridColumns = mapDataSourceRowTypeToGridColumns[gridRowType];
    gridColumns = cellWidth ? addPropertyToAllObjectsInArray(gridColumns, 'width', cellWidth) : gridColumns;
    return {
      showFilteringControls: true,
      showSortingControls: false,
      columns: isSmall
        ? gridColumns.map((columnConfig: BigidGridColumn<DataSourceTestConnectionRow>) => ({
            ...columnConfig,
            width: 110,
          }))
        : gridColumns,
      pageSize: useFetchState.pageSize,
      rows: useFetchState.rows as DataSourceTestConnectionRow[],
      skip: useFetchState.skip,
      onPagingChanged: useFetchState.onPagingChanged,
      onSortingChanged: useFetchState.onSortingChanged,
      totalRowsCount: useFetchState.totalRowsCount,
      onFiltersChange: useFetchState.onFiltersChanged,
      loading: useFetchState.isLoading,
    };
  }, [isSmall, useFetchState, gridRowType, cellWidth]);

  const getFilteredData = (data: DataSourceTestConnectionRow[], filter: BigidFilter) => {
    const fieldFiltersSorted = getFieldFiltersSorted(filter);
    const filterValues = (Object.keys(fieldFiltersSorted) as Array<DataSourceRowKeys>).reduce(
      (aggrFilters, fieldName) => {
        const fieldValues = [...fieldFiltersSorted[fieldName].keys()].reduce(
          (fieldValues, operator) => [...fieldValues, ...fieldFiltersSorted[fieldName].get(operator)],
          [],
        );

        return { ...aggrFilters, [fieldName]: [...(aggrFilters[fieldName] || []), ...fieldValues] };
      },
      {} as FilterValues,
    );

    return data.filter(row => {
      return (Object.keys(filterValues) as Array<DataSourceRowKeys>).every(fieldName => {
        const regex = new RegExp(filterValues[fieldName].join('|'), 'i');
        return regex.test(row[fieldName]);
      });
    });
  };

  return <BigidGrid {...configuration} />;
};

module('app').component(
  'testConnectionsDataSources',
  convertToAngular<DataSourcesTestConnectionGridProps>(DataSourcesTestConnectionGrid, ['rows']),
);
