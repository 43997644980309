import { httpService } from './httpService';
import { scansService } from './angularServices';
import { notificationService } from './notificationService';
import { downloadFile } from './downloadFile';
import { licenseService } from './licenseService';
import { smallIdLicenseService } from './smallIdLicenseService';
import { noop } from 'lodash';
import { BigidSystemDialogOptions, closeSystemDialog, openSystemDialog } from './systemDialogService';
import { PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { UsageReportDialogContent } from '../views/UsageReport/UsageReportDialogContent';
import {
  downloadUsageReport,
  UsageReportState,
  UsageReportStateSetters,
} from '../views/UsageReport/usageReportService';
import { ConfirmationDialogOptions, showConfirmationDialog } from './confirmationDialogService';
import { commonTranslate } from '../translations';
import { BIGID_BI_EVENTS } from '../config/BigIdBiEvents';
import { analyticsService } from './analyticsService';
import { getApplicationPreference } from './appPreferencesService';

const getScannedFilesReport = () => httpService.downloadFile('scanned-files-report/file-download/csv');
const getDataCustodianReport = () => httpService.downloadFile('data-custodian-report/file-download/csv');
const getFilesAttributeDistributionReport = () =>
  httpService.downloadFile('files-attribute-distribution-report/file-download/csv');
const getFailedObjectReport = () => httpService.fetch('failed-object-report');
const getLabelerReport = () =>
  httpService.fetch('labeler-report?type=User-Friendly', undefined, undefined, 'arraybuffer');
const getTaggedEntity = () => httpService.fetch('tagged-entities?type=csv');
const getMetadataClassificationReport = () => httpService.fetch('metadata-classification-report');

const downloadReport = async ({
  headerToTranslate,
  bodyToTranslate,
  getReport,
  fileName,
  downloadReport,
  fileExtension = 'csv',
}: {
  headerToTranslate: string;
  bodyToTranslate: string;
  getReport?: any;
  fileName?: string;
  downloadReport?: () => Promise<void>;
  fileExtension?: string;
}) => {
  if (smallIdLicenseService.isSmallIDLicense() && !smallIdLicenseService.isSmallIDEnabledReport(fileName)) {
    return licenseService.openLicenseUpgradeFlow();
  }

  const cancelButtonName = 'Cancel',
    actionButtonName = 'Download';

  const modalOptions: ConfirmationDialogOptions = {
    entityNameSingular: headerToTranslate,
    customDescription: bodyToTranslate,
    cancelButtonName: cancelButtonName,
    actionButtonName: actionButtonName,
    actionName: '',
  };

  await showConfirmationDialog(modalOptions)
    .then(async shouldDownload => {
      if (shouldDownload) {
        if (downloadReport) {
          await downloadReport();
          return;
        }
        try {
          const { data } = await getReport();
          if (data) {
            downloadFile(data, 'attachment/octet-stream', `${fileName}-${new Date().toISOString()}.${fileExtension}`);
          }
        } catch (err) {
          notificationService.error(commonTranslate('api.message.commonError'));
        }
      }
    })
    .catch(() => /** in case of cancel transaction **/ noop());
};

export const failedObjectReportFileName = 'bigid-scanned-failed-object-report';

export const onGetScannedFilesReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_SCANNED_FILES);

  downloadReport({
    headerToTranslate: 'modals.reports.scannedFiles.header',
    bodyToTranslate: 'modals.reports.scannedFiles.body',
    downloadReport: getScannedFilesReport,
  });
};

export const onGetFailedObjectReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_FAILED_OBJECT_REPORT);

  downloadReport({
    headerToTranslate: 'modals.reports.failedObject.header',
    bodyToTranslate: 'modals.reports.failedObject.body',
    fileName: failedObjectReportFileName,
    downloadReport: scansService.getFailedObjectReport,
  });
};

export const onGetLabelerReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_LABELING_PROPAGATION_REPORT);
  downloadReport({
    headerToTranslate: 'modals.reports.labeler.header',
    bodyToTranslate: 'modals.reports.labeler.body',
    getReport: getLabelerReport,
    fileName: 'bigid-labeler-report',
    fileExtension: 'zip',
  });
};

export const onGetMetadataClassifierReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_METADATA_CLASSIFICATION_REPORT);
  downloadReport({
    headerToTranslate: 'modals.reports.metadataClassifier.header',
    bodyToTranslate: 'modals.reports.metadataClassifier.body',
    getReport: getMetadataClassificationReport,
    fileName: 'bigid-metadata-classification-report',
  });
};

export const onGetDataCustodianReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_DATA_CUSTODIAN);
  downloadReport({
    headerToTranslate: 'modals.reports.dataCustodian.header',
    bodyToTranslate: 'modals.reports.dataCustodian.body',
    downloadReport: getDataCustodianReport,
  });
};

export const onGetFilesAttributeDistributionReport = () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_FILES_ATTRIBUTES_DISTRIBUTION);
  downloadReport({
    headerToTranslate: 'modals.reports.filesAttributeDistribution.header',
    bodyToTranslate: 'modals.reports.filesAttributeDistribution.body',
    downloadReport: getFilesAttributeDistributionReport,
  });
};

export const onGetTaggedEntityReport = async () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_TAGGED_ENTITIES);
  const { data } = await getTaggedEntity();
  downloadFile(data, 'attachment/octet-stream', `bigid-tagged-entities-report-${new Date().toISOString()}.csv`);
};

const downloadUsageReportProperties = (): UsageReportStateSetters => {
  let usageReportState: UsageReportState = { startDate: null, endDate: null, isChecked: false, hasErrors: false };

  return {
    getUsageReportState: () => usageReportState,
    setUsageReportState: (newState: Partial<UsageReportState>) =>
      (usageReportState = { ...usageReportState, ...newState }),
  };
};

export const onGetUsageReport = async () => {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.REPORTS_USAGE_REPORT);
  const { getUsageReportState, setUsageReportState } = downloadUsageReportProperties();
  const usageReportDialogOptions: BigidSystemDialogOptions = {
    title: 'Download Usages Report',
    onClose: noop,
    content: UsageReportDialogContent,
    contentProps: { setUsageReportState },
    maxWidth: 'xs',
    buttons: [
      {
        text: 'Close',
        component: SecondaryButton,
        onClick: noop,
        isClose: true,
        dataAid: 'usageReportCloseButton',
      },
      {
        text: 'Download',
        component: PrimaryButton,
        dataAid: 'usageReportDownloadButton',
        onClick: () => {
          const { isChecked, startDate, endDate, hasErrors } = getUsageReportState();
          const hasDates = Boolean(startDate && endDate);

          const hasValidParams = isChecked ? hasDates && !hasErrors : true;
          if (hasValidParams) {
            const queryParams = isChecked
              ? { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
              : null;
            downloadUsageReport(queryParams);
            closeSystemDialog();
          }
        },
      },
    ],
  };
  openSystemDialog(usageReportDialogOptions);
};

export const sidebarService = {
  onGetScannedFilesReport,
  onGetFailedObjectReport,
  onGetLabelerReport,
  onGetMetadataClassifierReport,
  onGetDataCustodianReport,
  onGetFilesAttributeDistributionReport,
  onGetTaggedEntityReport,
  getFailedObjectReport,
  onGetUsageReport,
};
