import { Modal, styled } from '@mui/material';
import React, { FC } from 'react';
import { isStewardshipPaid } from '../../utils';
import { CardModalContextProvider } from '../CardModalContext';
import { CardType } from '../types';
import { Footer } from './Footer';
import { Header } from './Header/Header';
import { Tabs } from './Tabs/Tabs';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardModal = styled('div')`
  height: 800px;
  width: 1300px;
  background-color: rgb(247, 247, 251);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

type GlossaryCardModalProps = {
  isOpen: boolean;
  entityId?: string;
  onClose: () => void;
  cardType: CardType;
};

export const GlossaryCardModal: FC<GlossaryCardModalProps> = ({ entityId, cardType, onClose, isOpen }) => {
  const isNotPaid = !isStewardshipPaid();
  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <CardModalContextProvider entityId={entityId} cardType={cardType} onClose={onClose}>
        <CardModal>
          <Header />
          <Tabs />
          {isNotPaid && <Footer />}
        </CardModal>
      </CardModalContextProvider>
    </StyledModal>
  );
};
