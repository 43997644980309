import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { BigidStarIcon, BigidStarFilledIcon } from '@bigid-ui/icons';
import { onSelectFavoritePage, getFavoritePages } from '../../NavigationSideBar/favoritePagesManagementService.ts';
import { $state } from '../../../services/angularServices';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { getInitialNavbarItems } from '../../NavigationSideBar/navigationSidebarUtils';
import { BigidButtonIcon, BigidNavigationItemType } from '@bigid-ui/components';
import {
  userPreferencesEventEmitter,
  UserPreferencesEvents,
} from '../../../services/eventEmitters/userPreferencesEvents';

const IconWrapper = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  verticalAlign: 'bottom',
}));

export const BigidPageFavoriteToggler = () => {
  const [selected, setSelected] = useState<boolean>(false);

  const isStartShowingNewNavigationItem = (navItems: BigidNavigationItemType[]) => {
    let isExistingItem = false;

    navItems.find(mainItem => {
      if (mainItem.selectedItemStates?.includes($state.$current.name)) {
        isExistingItem = true;
      }
      if (!isExistingItem) {
        mainItem?.quickViewSchema?.menuItems.find(subItem => {
          if (subItem.selectedItemStates?.includes($state.$current.name)) {
            isExistingItem = true;
          }
        });
      }
    });

    return isExistingItem;
  };

  const correctSubItemLink = (navItems: BigidNavigationItemType[]) => {
    let correctLink = '';

    navItems.map(mainItem => {
      if (!correctLink) {
        mainItem?.quickViewSchema?.menuItems.find(subItem => {
          if (subItem.selectedItemStates?.includes($state.$current.name)) {
            correctLink = subItem.link;
          }
        });
      }
    });

    return correctLink;
  };

  const favoritePages = (): BigidNavigationItemType[] => {
    return sessionStorageService.get('FAVORITE_PAGES');
  };

  const selectedPages: BigidNavigationItemType[] = favoritePages();

  const onNavigationItemClicked = (link: string) => {
    $state.go(link);
  };

  const allNavItems = getInitialNavbarItems(onNavigationItemClicked);
  const isNewNavigation = getApplicationPreference('ENABLE_NEW_UX_NAVIGATION');
  const isStarDisplay = isStartShowingNewNavigationItem(allNavItems);
  const isNewNav = isNewNavigation && isStarDisplay;

  const clickHandler = async () => {
    const realLink = correctSubItemLink(allNavItems) || $state.$current.name;
    setSelected(prev => !prev);
    await onSelectFavoritePage(realLink);
  };

  useEffect(() => {
    const isSelected = selectedPages?.some(el => el.selectedItemStates?.includes($state.$current.name));
    setSelected(isSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$state.$current.name]);

  useEffect(() => {
    const userPreferenceFavorite = async () => {
      const { favoriteItems: favPages } = await getFavoritePages(allNavItems);
      const isSelected = favPages?.some(el => el.selectedItemStates?.includes($state.$current.name));
      setSelected(isSelected);
    };
    if (selectedPages === null) {
      userPreferenceFavorite();
    }
    const destroyUpdateFavoritesListener = userPreferencesEventEmitter.addEventListener(
      UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES,
      userPreferenceFavorite,
    );
    return () => {
      destroyUpdateFavoritesListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isNewNav && (
        <IconWrapper>
          <BigidButtonIcon
            onClick={clickHandler}
            icon={selected ? BigidStarFilledIcon : BigidStarIcon}
            className="FavoriteIcon"
          />
        </IconWrapper>
      )}
    </>
  );
};
