import { BigidBody1, BigidBody2, BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { Hierarchy } from './Hierarchy';

const GraphNodeRoot = styled('div')`
  display: flex;
  flex-direction: row;
`;

type IconContainerProps = {
  isLastElement?: boolean;
};

const OuterIconContainer = styled('div')<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  position: absolute;
  left: -36px;
  top: -2%;
  box-shadow: 0px 1.13961px 4.55846px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme, isLastElement }) =>
    isLastElement ? BigidColorsV2.blue[200] : theme.palette.background.paper};
  border: ${({ isLastElement }) => (isLastElement ? `1px solid ${BigidColorsV2.blue[300]}` : 'none')};
`;

const InnerIconContainer = styled('div')<IconContainerProps>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ isLastElement }) => (isLastElement ? BigidColorsV2.blue[300] : BigidColorsV2.blue[200])};
`;

const GraphNodeInfoContainer = styled('div')<IconContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isLastElement }) =>
    isLastElement ? BigidColorsV2.blue[50] : theme.palette.background.paper};
  border-radius: 3px;
  padding: 4px 8px 4px 24px !important;
  box-shadow: 0px 1.22222px 4.88889px rgba(0, 0, 0, 0.1), 0px 1px 21px rgba(75, 75, 75, 0.06);
`;

const NodeTitle = styled(BigidBody2)`
  color: ${BigidColorsV2.gray[500]};
  white-space: nowrap;
`;

const NodeValue = styled(BigidBody1)`
  font-weight: 600;
  white-space: nowrap;
`;

export const GraphNode: FC<Hierarchy> = ({ type, name, isActive, icon }) => {
  return (
    <GraphNodeRoot>
      <OuterIconContainer isLastElement={isActive}>
        <InnerIconContainer isLastElement={isActive}>{icon}</InnerIconContainer>
      </OuterIconContainer>
      <GraphNodeInfoContainer isLastElement={isActive}>
        <NodeTitle>{type}</NodeTitle>
        <NodeValue>{name}</NodeValue>
      </GraphNodeInfoContainer>
    </GraphNodeRoot>
  );
};
