import { useCallback } from 'react';
import { BigidFormField } from '@bigid-ui/components';
import { useSetTemplateConfig as useSetTemplateConfigV1, UseSetTemplateConfigProps } from './useSetTemplateConfig';
import { HIDDEN_FIELDS_OVERRIDE } from '../../DataSourceConnectionModal/constants/constants';
import { DsConnectionFieldSectionsEnum } from '../types';
import { sortByArray } from '../../DataSourceConnectionModal/utils/utils';
import { uniq } from 'lodash';

type UpdateStateProps = { fields: BigidFormField[] };

const DETAILS_SECTION_SORT_ORDER: string[] = [
  'enabled',
  'friendlyName',
  'businessOwners',
  'itOwners',
  'location',
  'scope',
  'tags',
  'securityTier',
  'description',
  'comments',
];

const defaultFieldOverrides = (fields: BigidFormField[], defaultSortOrder: string[]): BigidFormField[] =>
  fields.map(mapHiddenFieldsToDetailsSection).reduce<BigidFormField[]>((acc, _, __, arr) => {
    return sortByArray([...arr], 'name', defaultSortOrder);
  }, []);

// @info show hidden new connectivity fields in details section
const mapHiddenFieldsToDetailsSection = (field: BigidFormField): BigidFormField => {
  const isHidden = HIDDEN_FIELDS_OVERRIDE.includes(field.name);
  return {
    ...field,
    misc: { ...field?.misc, section: isHidden ? DsConnectionFieldSectionsEnum.details : field?.misc?.section },
  };
};

export const useSetTemplateConfigWithStateOverrides = (
  { updateState, ...rest }: UseSetTemplateConfigProps,
  enabled = false,
) => {
  const updateStateOverride = useCallback(
    ({ fields, ...props }: UpdateStateProps) => {
      const defaultSortOrder = uniq([...DETAILS_SECTION_SORT_ORDER, ...(fields?.map(({ name }) => name) ?? [])]);

      if (!fields) {
        updateState({ ...props });
        return;
      }

      // @info make changes to the fields before updating
      updateState({
        ...props,
        fields: defaultFieldOverrides(fields, defaultSortOrder),
        fieldConfig: fields,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateState],
  );

  useSetTemplateConfigV1({
    ...rest,
    updateState: enabled ? updateStateOverride : updateState,
  });
};
