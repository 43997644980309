import React, { FC, memo, useRef, useState } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidFormFieldSideLabelWrapper,
  BigidExtraField,
  useExtraFieldControl,
  BigidExtraFieldLabelPosition,
  BigidSwitch,
  BigidFormFieldSideSelect,
  BigidFormFieldSideText,
  BigidHeading4,
} from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { FormPasswordWithMaskField } from './FormPasswordWithMaskField';
import { ConnectionSectionCustomParametersInfo } from '../DataSourceConfigurationSection/SectionsInfo/ConnectionSection/ConnectionSectionCustomParametersInfo';
import { uniqueId } from 'lodash';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
  },
  headerWrapper: {
    display: 'inline-block',
  },
  headerTitleWrapper: {
    margin: '20px 0',
  },
});

export enum CustomDsFieldTypes {
  encrypted = 'encrypted',
  clear = 'clear',
}

export const CustomDsFieldTypeToLabel = {
  [CustomDsFieldTypes.clear]: 'Clear text',
  [CustomDsFieldTypes.encrypted]: 'Encrypted',
};

export interface CustomDsFieldTypeOption {
  value: CustomDsFieldTypes;
  label: string;
}

export interface CustomDsFieldItem {
  enable: boolean;
  field_name: string;
  field_type: CustomDsFieldTypeOption[] | CustomDsFieldTypes;
  is_changed: boolean;
  field_value: string;
}

export interface FormCustomParametersFieldOneItemProps {
  onChange: (value: any) => void;
  value: CustomDsFieldItem;
  id: string;
  disabled: boolean;
}

export const FIELD_TYPE_OPTIONS = [
  {
    value: CustomDsFieldTypes.clear,
    label: CustomDsFieldTypeToLabel[CustomDsFieldTypes.clear],
  },
  {
    value: CustomDsFieldTypes.encrypted,
    label: CustomDsFieldTypeToLabel[CustomDsFieldTypes.encrypted],
  },
];

export const createNewItem = () => ({
  id: uniqueId(),
  value: {
    field_type: [
      {
        value: CustomDsFieldTypes.clear,
        label: CustomDsFieldTypeToLabel[CustomDsFieldTypes.clear],
      },
    ],
    field_name: '',
    field_value: '',
    enable: true,
  },
});

export const FormCustomParametersFieldOneItem: FC<FormCustomParametersFieldOneItemProps> = memo(
  ({ value, onChange, id, disabled }) => {
    const valueRef = useRef(value);
    valueRef.current = value;
    const { wrapper } = useStyles({});
    const [passwordTouched, setPasswordTouched] = useState(false);
    const ValueComponent =
      valueRef.current?.field_type === CustomDsFieldTypes.encrypted ||
      (valueRef.current?.field_type?.[0] as CustomDsFieldTypeOption)?.value === CustomDsFieldTypes.encrypted
        ? FormPasswordWithMaskField
        : BigidFormFieldSideText;

    return (
      <BigidFormFieldSideLabelWrapper
        id={`bigid-form-field-custom-item-${id}`}
        name={`custom-field-item${id}`}
        isRequired={false}
        tooltipText=""
        noIndent
      >
        <div className={wrapper}>
          <BigidSwitch
            checked={valueRef.current?.enable ?? true}
            disabled={disabled}
            onChange={() => {
              onChange({ ...valueRef.current, enable: !valueRef.current?.enable });
            }}
          />
          <BigidFormFieldSideSelect
            labelWidth={'60px'}
            value={valueRef.current?.field_type}
            label="Type"
            touched={false}
            error={null}
            errorIsShown={false}
            name={`FormCustomParametersFieldType-${id}`}
            setValue={field_type => {
              onChange({ ...valueRef.current, field_type });
            }}
            options={FIELD_TYPE_OPTIONS}
            isSubmitted={false}
            isValidated={false}
            isRequired={false}
            wasSubmitted={false}
            disabled={disabled}
          />
          <BigidFormFieldSideText
            labelWidth={'60px'}
            value={valueRef.current?.field_name}
            label="Name"
            touched={false}
            errorIsShown={false}
            error={null}
            name={`FormCustomParametersFieldName-${id}`}
            setValue={field_name => {
              onChange({ ...valueRef.current, field_name });
            }}
            isSubmitted={false}
            isValidated={false}
            isRequired={false}
            wasSubmitted={false}
            disabled={disabled}
          />
          <ValueComponent
            labelWidth={'50px'}
            value={valueRef.current?.field_value}
            label="Value"
            touched={passwordTouched}
            errorIsShown={false}
            error={null}
            name={`FormCustomParametersFieldValue-${id}`}
            setValue={field_value => {
              setPasswordTouched(true);
              onChange({ ...valueRef.current, field_value });
            }}
            isSubmitted={false}
            isValidated={false}
            isRequired={false}
            wasSubmitted={false}
            disabled={disabled}
            misc={{ showValueOnlyIfDirty: true }}
          />
        </div>
      </BigidFormFieldSideLabelWrapper>
    );
  },
  compareObjectsExceptFunctions,
);
FormCustomParametersFieldOneItem.displayName = 'FormCustomParametersFieldOneItem';

const initCustomItem = (value: CustomDsFieldItem, index: number) => ({
  id: `${value?.field_name}_${index || ''}`,
  value: {
    field_type: [
      {
        value: value?.field_type,
        label:
          CustomDsFieldTypeToLabel[
            value?.field_type === CustomDsFieldTypes.encrypted ? CustomDsFieldTypes.encrypted : CustomDsFieldTypes.clear
          ],
      },
    ],
    field_name: value?.field_name ?? '',
    field_value: value?.field_value ?? '',
    enable: value?.enable ?? true,
  },
});

const INIT_DATA = [createNewItem()];

export const FormCustomParametersField: FC<BigidFieldRenderProps> = memo(({ value, setValue, disabled }) => {
  const { headerWrapper, headerTitleWrapper } = useStyles({});
  const { onAddNew, onDelete, onChangeItem, data } = useExtraFieldControl({
    initialData: value?.length ? value.map(initCustomItem) : INIT_DATA,
    createNewItem: createNewItem,
    onChange: setValue,
  });

  return (
    <React.Fragment>
      <div className={headerWrapper}>
        <BigidHeading4 className={headerTitleWrapper}>Custom Parameters</BigidHeading4>
        <ConnectionSectionCustomParametersInfo />
      </div>
      <BigidExtraField
        disabled={disabled}
        data={data}
        onDelete={onDelete}
        onAddNew={onAddNew}
        labelPosition={BigidExtraFieldLabelPosition.top}
        fieldComponent={FormCustomParametersFieldOneItem}
        onChangeItem={onChangeItem}
        alignCenter={true}
      />
    </React.Fragment>
  );
}, compareObjectsExceptFunctions);

FormCustomParametersField.displayName = 'FormCustomParametersField';
