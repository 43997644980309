import React, { FunctionComponent, Fragment } from 'react';
import classnames from 'classnames';
import { differenceInDays } from 'date-fns';
import { dateTimeService } from '@bigid-ui/i18n';
import styles from './AccountLicense.scss';
import { licenseService, License } from '../../../../services/licenseService';
import { smallIdLicenseService } from '../../../../services/smallIdLicenseService';
import BigidExclamationCircle from '../../../../assets/icons/BigidExclamationCircle.svg';
import BigidCheckCircle from '../../../../assets/icons/BigidCheckCircle.svg';
import { BigidTooltip } from '@bigid-ui/components';
import { MeteringSummary } from '../../../../services/meteringService';
import { publicUrls } from '../../../../config/publicUrls';

const CUSTOMER_SUPPORT_LINK = publicUrls.SUPPORT_BIGID;

interface LicenseTabProps {
  license: License;
  meteringSummary: MeteringSummary;
}

const getTotal = (total: number): number | string => (total ? (total === -1 ? 'unlimited' : total) : 0);
const getAmountOfDaysUntilExpired = (expirationDate: string): number =>
  differenceInDays(new Date(expirationDate), new Date());

export const LicenseInformation: FunctionComponent<LicenseTabProps> = ({ license, meteringSummary }) => {
  const hasLicense = licenseService.hasLicense();
  const isExpired = licenseService.isExpired();
  const isTrial = licenseService.isTrial();
  const shouldNotifyLicenseExpire = licenseService.shouldNotifyLicenseExpiration();
  const shouldDisplayDatasourcesCount = !smallIdLicenseService.isSmallIDLicense();
  const shouldDisplayQuotaLimit = license?.quotaLimit?.total > 0;

  // this is always false for now.
  const shouldDisplayEntities = false;

  const trialPrefix = isTrial ? 'Trial version - ' : '';
  const timeLeftUntilExpiration = isExpired
    ? 'License Expired'
    : `${getAmountOfDaysUntilExpired(license.expirationDate)} days left`;
  const licenseInfoMessage = hasLicense ? `${trialPrefix}${timeLeftUntilExpiration}` : 'License key is required';

  const iconToDisplay = shouldNotifyLicenseExpire ? (
    <BigidExclamationCircle className={styles.icon} />
  ) : (
    <BigidCheckCircle className={styles.icon} />
  );

  const wrapperClassnames = classnames(
    styles.licenseInformationWrapper,
    shouldNotifyLicenseExpire && (isTrial ? styles.trialExpired : styles.expired),
  );
  const bannerClassnames = classnames(
    styles.banner,
    shouldNotifyLicenseExpire && (isExpired ? styles.expired : styles.aboutToExpire),
  );
  const licenseInfoMessageClassnames = classnames(
    styles.licenseInfoMessage,
    shouldNotifyLicenseExpire && (isExpired ? styles.expired : styles.aboutToExpire),
  );

  return (
    <div className={wrapperClassnames}>
      <h3 className={styles.licenseInformationTitle}>{'License Information'}</h3>
      <div
        className={classnames(styles.licenseInformation, hasLicense && styles.hasLicense)}
        data-aid={'license-info-message'}
      >
        <div className={styles.bannerWrapper}>
          <div className={bannerClassnames}>{iconToDisplay}</div>
        </div>
        <div className={classnames(styles.licenseInfoMessageWrapper, shouldNotifyLicenseExpire && styles.expired)}>
          <div className={licenseInfoMessageClassnames}>{licenseInfoMessage}</div>
          {hasLicense && (
            <div className={styles.licenseExpireMessage}>{`Your license ${
              isExpired ? 'expired' : 'expires'
            } on ${dateTimeService.formatDate(license.expirationDate, { formatString: 'MM/dd/yyyy' })}`}</div>
          )}
        </div>
        {shouldNotifyLicenseExpire && (
          <Fragment>
            <div className={styles.divider} />
            <div className={styles.licenseInfoContactMessage}>
              {'Contact '}
              <a href={CUSTOMER_SUPPORT_LINK} target="_blank" rel="noopener noreferrer" className={styles.link}>
                {'our team'}
              </a>
              {' for assistance'}
            </div>
          </Fragment>
        )}
      </div>
      {hasLicense && (
        <Fragment>
          <div
            className={classnames(styles.licenseItemInfo, styles.issued)}
            data-aid={'license-issuer-message'}
          >{`License issued for ${license.owner}`}</div>
          {shouldDisplayDatasourcesCount && (
            <BigidTooltip
              title={'Information about the number of data sources that you are allowed to manage with BigID'}
              placement="bottom-start"
            >
              <div className={styles.licenseItemInfo}>
                {`Using ${license.dataSources.used || 0} out of ${getTotal(license.dataSources.total)} data sources`}
              </div>
            </BigidTooltip>
          )}
          {shouldDisplayEntities && (
            <BigidTooltip
              title={'Information about the number of entities that you are allowed to manage with BigID'}
              placement="bottom-start"
            >
              <div className={styles.licenseItemInfo}>
                {`Using ${license.identitiesCount.used || 0} out of ${getTotal(
                  license.identitiesCount.total,
                )} entities`}
              </div>
            </BigidTooltip>
          )}
          {shouldDisplayQuotaLimit && (
            <Fragment>
              <BigidTooltip
                title={'Information about the quota limit that you are allowed to consume with BigID'}
                placement="bottom-start"
              >
                <div className={styles.licenseItemInfo}>{`Quota Limit: ${license.quotaLimit.total} GB / month`}</div>
              </BigidTooltip>
              <div className={styles.licenseItemInfo}>{`Quota used this month: ${
                meteringSummary.quotaUsedInGB ? meteringSummary.quotaUsedInGB.toFixed(2) : 0
              } GB`}</div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};
