import {
  DataCatalogObjectDetails,
  getDetailsByObjectName,
} from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { getPolicyAttributesInArray } from '../CaseReport/caseReportService';
import { getAttributesByObjectName } from '../../../DataCatalog/DataCatalogAttributes';
import { ChipFormatterProps } from '@bigid-ui/grid';
import { BigidColorsV2 } from '@bigid-ui/components';

export interface fetchAttributesResponse {
  objectAttributes: string[];
  filteredByPolicyAttrs: boolean;
}

const chipPriorityColor = ['#FFF2F2', '#FFF2E5', '#FFFCE0'];
const chipTextPriorityColor = [BigidColorsV2.red[900], '#BF4000', '#995900'];
const chipDefaultColor = '#F0FFF7';
const chipTextDefaultColor = '#138045';

export const fetchObjectDetails = async (fqn: string): Promise<DataCatalogObjectDetails> => {
  const { data } = await getDetailsByObjectName(fqn);
  return data;
};

export const fetchObjectAttributes = async (
  objectName: string,
  policyName: string,
): Promise<fetchAttributesResponse> => {
  const { data } = await getAttributesByObjectName(objectName);
  const policyAttributes = await getPolicyAttributesInArray(policyName);
  const objectAttributes = data.map(attr => attr.attribute_name);
  if (!policyAttributes.length) {
    return { objectAttributes: objectAttributes, filteredByPolicyAttrs: false };
  }
  return {
    objectAttributes: objectAttributes.filter(attr => policyAttributes.includes(attr)),
    filteredByPolicyAttrs: true,
  };
};

export const getObjectSensitivityChipProps = (scMapping: Map<string, number>, sensitivity = ''): ChipFormatterProps => {
  if (!sensitivity) {
    return { chip: null };
  }
  return {
    chip: {
      label: sensitivity,
      bgColor: getChipColorByPriority(scMapping.get(sensitivity)),
      color: getChipTextColorByPriority(scMapping.get(sensitivity)),
    },
  };
};

const getChipColorByPriority = (priorityLevel: number) => {
  return chipPriorityColor[priorityLevel] || chipDefaultColor;
};

const getChipTextColorByPriority = (priorityLevel: number) => {
  return chipTextPriorityColor[priorityLevel] || chipTextDefaultColor;
};
