import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { uniq } from 'lodash';
import Grid from '@mui/material/Grid';
import { ApplicationCard } from '../../Components/ApplicationCard';
import {
  ApplicationsManagementTabProps,
  applicationManagementService,
  MarketplaceApp,
} from '../../applicationManagementService';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidHeading4 } from '@bigid-ui/components';
import { CustomAppIcon } from '../../Components/CustomAppIcon';
import { appsLicenseService } from '../../../../services/appsLicenseService';
import { AppInfoModal, goToMarketplace } from './AppInfoModal/AppInfoModal';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { CreateAppPayload, customAppService } from '../../../../services/customAppService';
import { CustomAppEvents, customAppEventsEmitter } from '../../../../services/customAppEvents';
import { $state } from '../../../../services/angularServices';
import { AppInstallationStateModal, InstallAppState } from './AppInfoModal/AppInstallationStateModal';
import { AppLicenseDialog } from '../../../CreateCustomApp/AppLicenseDialog';
import { CONFIG } from '../../../../../config/common';
import { TPAPageViewsEvents, trackTPAPageView } from '../../../CustomApp/customAppEventsTrackerUtils';

interface GlobalTpaAfterInstall {
  tpaId: string;
  vendor: string;
  name: string;
}

const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'scroll',
    padding: 16,
  },
  categoryTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    fontSize: '0.875rem',
    color: BigidColorsV2.purple[500],
    '&:not(:first-child)': {
      marginTop: 24,
    },
  },
});

//TODO : Shavic - move inside component
let globalTpaAfterInstall: GlobalTpaAfterInstall = { tpaId: '', name: '', vendor: '' };

export const MarketplaceConnectivity: FC<ApplicationsManagementTabProps> = ({
  apps,
  marketplaceApps,
  selectedCategory,
}) => {
  const [selectedAppData, setSelectedAppData] = useState<MarketplaceApp>();
  const [showAppInfoModal, setShowAppInfoModal] = useState<boolean>(false);
  const [showAppLicenseDialog, setShowAppLicenseDialog] = useState<boolean>(false);
  const [installAppState, setInstallAppState] = useState<InstallAppState>(InstallAppState.NONE);
  const [clickToInstallAppPayload, setClickToInstallAppPayload] = useState<CreateAppPayload>(null);

  const classes = useStyles({});

  useEffect(() => {
    trackTPAPageView(TPAPageViewsEvents.TPA_MARKETPLACE_APPLICATIONS_VIEW);
  }, []);

  const categoriesToDisplay = useMemo(
    () =>
      applicationManagementService.getCategoriesToDisplay(
        selectedCategory,
        uniq(marketplaceApps.map(app => app.appSpecs.category)),
      ),
    [marketplaceApps, selectedCategory],
  );

  const openLearnMoreModal = (appData: MarketplaceApp) => {
    setSelectedAppData(appData);
    setShowAppInfoModal(true);
  };
  const handleCloseShowMoreModal = () => {
    setShowAppInfoModal(false);
  };

  const handleApplicationCardClick = (appData: MarketplaceApp) => {
    const { isInstalled, appName, marketplaceLink } = appData;
    if (isInstalled) {
      appsLicenseService.showAppExpirationNotificationForCustomAppAndGoToCustomAppPage(appName, appName, '');
    } else {
      getApplicationPreference('SAAS_APPLICATIONS_CLICK_TO_INSTALL')
        ? openLearnMoreModal(appData)
        : goToMarketplace(marketplaceLink);
    }
  };

  const installApp = (appPayload: CreateAppPayload) => {
    setShowAppInfoModal(false);
    setInstallAppState(InstallAppState.INSTALLING);
    customAppService.createCustomAppUsingURL({
      createAppPayload: appPayload,
      onUrlIsNotValid: () => {
        setInstallAppState(InstallAppState.ERROR);
      },
      onSuccess: async (tpaId, name, vendor) => {
        await customAppService.handleAppLicenseAfterInstall();
        globalTpaAfterInstall = { tpaId, name, vendor };
        setInstallAppState(InstallAppState.SUCCESS);
      },
      onError: () => {
        setInstallAppState(InstallAppState.ERROR);
      },
    });
  };

  const handleClickToInstall = async (appUrl: string) => {
    const appPayload: CreateAppPayload = { appUrl, isRemoteApplication: false };
    setClickToInstallAppPayload(appPayload);
    setInstallAppState(InstallAppState.INSTALLING);
    await customAppService.customAppVerification({
      verifyAppPayload: appPayload,
      onVerified: () => {
        setInstallAppState(InstallAppState.NONE);
        setShowAppLicenseDialog(true);
      },
      onVerifiedWithLicense: async () => {
        installApp(appPayload);
      },
      onNotVerifiedByBigId: () => {
        //Some app apps does not have license verification key
        // And we done want to fail click to install process for these apps.
        installApp(appPayload);
      },
      onError: () => {
        setInstallAppState(InstallAppState.ERROR);
      },
    });
  };

  const handleInstallationStateModalClose = () => {
    if (installAppState === InstallAppState.SUCCESS) {
      //If the user will not brows to the app, we want to update the apps list so the new app will appear
      customAppEventsEmitter.emit(CustomAppEvents.UPDATE_APP_LIST);
    }
    setInstallAppState(InstallAppState.NONE);
  };

  const handleOnInstallationModalButtonClicked = () => {
    if (installAppState === InstallAppState.SUCCESS) {
      const { tpaId, vendor, name } = globalTpaAfterInstall;
      $state.go(CONFIG.states.CUSTOM_APP_EDIT, { id: tpaId, name, vendor });
    } else {
      setInstallAppState(InstallAppState.NONE);
    }
  };

  const handleAppLicenseDialogClose = () => {
    setShowAppLicenseDialog(false);
  };

  const handleLicenseDialogApply = async () => {
    handleAppLicenseDialogClose();
    installApp(clickToInstallAppPayload);
  };

  return (
    <div className={classes.root}>
      {categoriesToDisplay.map((mainCategory, idx) => {
        const appsForCategory = apps.filter(({ category }) => category === mainCategory);
        const marketplaceAppsForCategory = marketplaceApps.filter(
          ({ appSpecs: { category } }) => category === mainCategory,
        );
        const emptyCategory = appsForCategory.length === 0;
        return (
          <Fragment key={idx}>
            {!emptyCategory && (
              <div className={classes.categoryTitle}>
                <BigidHeading4 color={'inherit'}>{mainCategory}</BigidHeading4>
              </div>
            )}
            <Grid container spacing={2} alignItems="stretch">
              {appsForCategory.map(
                ({ id, name, description, to, link, icon, onClick, dataAid, isCertified, isPaid }) => {
                  return (
                    <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
                      <ApplicationCard
                        name={name}
                        description={description}
                        icon={icon}
                        to={to}
                        link={link}
                        onClick={onClick}
                        dataAid={dataAid}
                        showMarketplaceProps
                        isInstalled
                        isCertified={isCertified}
                        isPaid={isPaid}
                      />
                    </Grid>
                  );
                },
              )}
              {marketplaceAppsForCategory.map(appData => {
                const {
                  appName,
                  friendlyName,
                  description,
                  icon,
                  isCertified,
                  appSpecs: { version },
                  isInstalled,
                  isPaid,
                } = appData;
                if (!(friendlyName || appName)) return;
                return (
                  <Grid item key={appName} xs={12} sm={6} md={4} lg={3}>
                    <ApplicationCard
                      id={appName}
                      custom
                      name={friendlyName || appName}
                      description={description}
                      onClick={() => handleApplicationCardClick(appData)}
                      version={version}
                      logo={icon && <CustomAppIcon logo={icon} />}
                      dataAid={`open-marketplace-app-${appName}`}
                      isCertified={isCertified}
                      isPaid={isPaid}
                      showMarketplaceProps
                      isInstalled={isInstalled}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Fragment>
        );
      })}
      <AppInfoModal
        appData={selectedAppData}
        isOpen={showAppInfoModal}
        onClose={handleCloseShowMoreModal}
        onClickToInstall={handleClickToInstall}
      />

      <AppInstallationStateModal
        appName={selectedAppData?.friendlyName || selectedAppData?.appName}
        appIcon={selectedAppData?.icon}
        state={installAppState}
        onClose={handleInstallationStateModalClose}
        onActionButtonClicked={handleOnInstallationModalButtonClicked}
      />

      <AppLicenseDialog
        isOpen={showAppLicenseDialog}
        onClose={handleAppLicenseDialogClose}
        onApply={handleLicenseDialogApply}
        appPayload={clickToInstallAppPayload}
      />
    </div>
  );
};
