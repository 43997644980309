import React, { useState, useMemo, useEffect, ReactNode, useCallback } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbarProps,
  FetchDataFunction,
  BigidGridUpdateRowsPayload,
  BigidGridUpdateRowByIdPayload,
} from '@bigid-ui/grid';
import {
  BigidColorsV2,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidFilter,
  EntityEvents,
  entityEventsEmitter,
  ToolbarAction,
  ToolbarActionType,
  EntityEventPayload,
  BigidFilterType,
  BigidTabsItem,
  ActionData,
} from '@bigid-ui/components';
import { CATALOG_PERMISSIONS, REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import { BigidLayoutConfig, LayoutContentType, parseFieldFiltersToSearchQuery } from '@bigid-ui/layout';
import {
  BigidDislikeIcon,
  BigidLikeIcon,
  BigidSearchQueryIcon,
  BigidDislikeFilledIcon,
  BigidLikeFilledIcon,
} from '@bigid-ui/icons';
import {
  CuratedAttribute,
  CuratedField,
  CuratedFieldKeys,
  CuratedFieldStatus,
  ConfidenceLevel,
  curateField,
  CurateFieldPayload,
  getCuratedFields,
  getSystemUsers,
  triggerPreviewCurationAttributes,
  DetailedObjectType,
  structuredObjectsTypes,
  getObjectsFields,
} from '../curationService';
import { CuratedFieldsProps } from './CuratedFields';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { makeStyles } from '@mui/styles';
import {
  getPercentageValueFormatted,
  getCuratedFieldMasterDetailsIcon,
  getCuratedFieldDisplayName,
  getStatusDisplayName,
  getAdditionalAttributesDialogProps,
} from '../curationUtils';
import { AttributePreview as AttributePreviewStructured } from './details/structured/AttributePreview/AttributePreview';
import { AttributePreview as AttributePreviewUnstructured } from './details/unstructured/AttributePreview/AttributePreview';
import { ColumnProfileComponent } from './details/structured/ColumnProfile/ColumnProfile';
import { omit, capitalize, partition, includes, isEmpty } from 'lodash';
import { AdditionalAttributes } from './details/common/AdditionalAttributes/AdditionalAttributes';
import { CuratedFieldsObjectNameCell } from './CuratedFieldsObjectNameCell';
import { generateDataAid } from '@bigid-ui/utils';
import { useLocalTranslation } from '../translations';
import { isPermitted } from '../../../services/userPermissionsService';
import { v4 as uuid } from 'uuid';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { CurationEvents, trackEventCurationView } from '../curationEventTrackerUtils';
import { openModifyAdditionalAttributesDialog } from './details/common/AdditionalAttributes/additionalAttributeService';

export type CuratedFieldRecord = CuratedField &
  BigidGridRow & {
    displayFieldName: string;
    icon?: ReactNode;
  };

export type CuratedAttributeRecord = CuratedAttribute &
  BigidGridRow & {
    displayFieldName: string;
    icon?: ReactNode;
  };

export type CuratedAttributeRowUpdatePayload = BigidGridUpdateRowByIdPayload<CuratedAttributeRecord>;

export type AttributesGridToolbarFilterConfig = BigidGridWithToolbarProps<CuratedFieldRecord>['filterToolbarConfig'];

export type UseCuratedFieldsConfigState = {
  isReady: boolean;
  layoutConfig: BigidLayoutConfig;
  amountOfReviewedObjects: number;
};

export type UseCuratedAttributesConfigProps = CuratedFieldsProps;

type CuratedFieldRowUpdatePayload = BigidGridUpdateRowByIdPayload<CuratedFieldRecord>;

export type AdditionalAttributesObjectIdentifier = {
  fieldName: string;
  fullyQualifiedName: string;
};

export type AdditionalAttributesDialogConfig = {
  objectsSelected: AdditionalAttributesObjectIdentifier[];
  gridId: string;
  dataAid: string;
  orderAfterSave: boolean;
  isBulkMode?: boolean;
};

const searchFilterKeys = ['fieldNameFromSearchBar', 'objectNameFromSearchBar'];

const BACKEND_SUPPORT_LIMIT = 100;
const SELECTION_LIMIT = BACKEND_SUPPORT_LIMIT + 1;
const BULK_PREVIEW_FETCH_LIMIT = 100;

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
    border: `1px solid ${BigidColorsV2.purple[200]}`,
    borderRadius: '8px',
  },
});

export const useCuratedFieldsConfig = ({
  currentCuratedAttribute,
  currentCuratedDataSource,
  onFieldReviewed,
  curationStatus,
}: UseCuratedAttributesConfigProps): UseCuratedFieldsConfigState => {
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.CurationFieldsConfig');
  const [isReady, setIsReady] = useState<boolean>(false);
  const [filterToolbarConfig, setFilterToolbarConfig] =
    useState<BigidGridWithToolbarProps<CuratedFieldRecord>['filterToolbarConfig']>();
  const [amountOfReviewedObjects, setAmountOfReviewedObjects] = useState<number>(0);

  useEffect(() => {
    if (curationStatus) {
      setAmountOfReviewedObjects(curationStatus?.curatedCount);
    }
  }, [curationStatus]);

  const fetchToolbarFilterConfig = useCallback(async () => {
    const filterToolbarConfig: BigidGridWithToolbarProps<CuratedFieldRecord>['filterToolbarConfig'] = {
      searchConfig: {
        searchFilterKeys,
        operator: 'equal',
      },
      filters: [
        {
          title: t('reviewStatus'),
          field: 'reviewStatus',
          operator: 'in',
          options: Object.values(CuratedFieldStatus).map(status => {
            const statusDisplayName = getStatusDisplayName(status);

            return {
              label: capitalize(statusDisplayName),
              value: status,
              isSelected: false,
            };
          }),
          value: [],
          disabled: true,
        },
      ],
    };

    filterToolbarConfig.filters.push(
      {
        title: t('confidenceLevel'),
        field: 'confidenceLevel',
        operator: 'in',
        options: Object.values(ConfidenceLevel).map(level => ({
          label: capitalize(level),
          value: level,
          isSelected: false,
        })),
        value: [],
        disabled: true,
      } as BigidFilterType,
      {
        title: t('updatedConfidenceLevel'),
        field: 'updatedConfidenceLevel',
        operator: 'in',
        options: Object.values(ConfidenceLevel).map(level => ({
          label: capitalize(level),
          value: level,
          isSelected: false,
        })),
        value: [],
        disabled: true,
      } as BigidFilterType,
    );

    try {
      const systemUsersOptions = await getSystemUsers();

      const { containers, objectNames, fieldNames } = await getObjectsFields(
        currentCuratedDataSource.source,
        currentCuratedAttribute.attributeType,
        currentCuratedAttribute.attributeName,
      );

      filterToolbarConfig.filters.push({
        title: t('curatedByUser'),
        field: 'curatedByUser',
        operator: 'in',
        value: [],
        disabled: true,
        isSearchAsync: true,
        options: systemUsersOptions,
        loadSearchOptions: getSystemUsers,
      } as BigidFilterType);

      filterToolbarConfig.filters.push({
        title: t('fieldName'),
        field: 'fieldName',
        operator: 'in',
        value: [],
        disabled: true,
        isSearchAsync: true,
        options: fieldNames,
      } as BigidFilterType);

      filterToolbarConfig.filters.push({
        title: t('objectName'),
        field: 'objectName',
        operator: 'in',
        value: [],
        disabled: true,
        isSearchAsync: true,
        options: objectNames,
      } as BigidFilterType);

      filterToolbarConfig.filters.push({
        title: t('containerName'),
        field: 'container',
        operator: 'in',
        value: [],
        disabled: true,
        isSearchAsync: true,
        options: containers,
      } as BigidFilterType);
    } finally {
      filterToolbarConfig.filters = [
        ...filterToolbarConfig.filters.slice(-3),
        ...filterToolbarConfig.filters.slice(0, -3),
      ];
      setFilterToolbarConfig(filterToolbarConfig);
      setIsReady(true);
    }
  }, [t]);

  const { fetchGridData, columns, toolbarActions, actions, gridId } = useMemo(() => {
    const gridId = `CuratedFields-${uuid()}`;

    const fetchGridData: FetchDataFunction<CuratedFieldRecord> = async (queryComponents: BigidGridQueryComponents) => {
      try {
        const [searchQueryFilter, queryComponentFilter] = partition(queryComponents.filter, ({ field }) =>
          includes(searchFilterKeys, field),
        );

        const filter: BigidFilter = [
          {
            field: 'attributeName',
            value: encodeURIComponent(currentCuratedAttribute.attributeName),
            operator: 'in',
          },
          {
            field: 'attributeType',
            value: currentCuratedAttribute.attributeType,
            operator: 'in',
          },
          {
            field: 'source',
            value: [currentCuratedDataSource.source],
            operator: 'in',
          },
          ...queryComponentFilter,
        ];

        const updatedSearchQuery = searchQueryFilter.map(searchTerm => {
          if (searchTerm.field === searchFilterKeys[0]) {
            searchTerm.field = 'fieldName';
          } else if (searchTerm.field === searchFilterKeys[1]) {
            searchTerm.field = 'objectName';
          }
          return searchTerm;
        });

        const filterQuery = parseFieldFiltersToSearchQuery(
          filter,
          Boolean(getApplicationPreference('NEW_QUERY_FILTER_ENABLED')),
        );

        const filterQueryExtended = isEmpty(updatedSearchQuery)
          ? filterQuery
          : `${filterQuery} AND (${updatedSearchQuery
              .map(({ field, value }) => `${field} = "${value}"`)
              .join(' OR ')})`;

        const { data } = await getCuratedFields({
          query: `${queryService.getGridConfigQuery(omit(queryComponents, ['filter']))}&filter=${filterQueryExtended}`,
        });
        const { fields, totalCount } = data;

        return {
          totalCount,
          data: fields.map(curatedField => {
            const displayFieldName = getCuratedFieldDisplayName(curatedField);

            return {
              ...curatedField,
              displayFieldName,
              icon: getCuratedFieldMasterDetailsIcon(curatedField.detailedObjectType),
            };
          }),
        };
      } catch ({ message }) {
        notificationService.error(t('errors.fetchingCuratedFields'));
        console.error(`An error has occurred: ${message}`);

        return {
          totalCount: 0,
          data: [],
        };
      }
    };

    const sampleColumns: BigidGridColumn<CuratedFieldRecord>[] = !getApplicationPreference('DATA_PREVIEW_DISABLED')
      ? [
          {
            title: t('sample1'),
            name: 'sample1',
            type: BigidGridColumnTypes.TEXT,
            isTooltipFollowCursor: true,
            getCellValue: ({ sample1 }) => sample1,
          },
          {
            title: t('sample2'),
            name: 'sample2',
            type: BigidGridColumnTypes.TEXT,
            isTooltipFollowCursor: true,
            getCellValue: ({ sample2 }) => sample2,
          },
        ]
      : [];

    const columns: BigidGridColumn<CuratedFieldRecord>[] = [
      {
        title: t('objectName'),
        name: 'objectName',
        isListColumn: true,
        type: BigidGridColumnTypes.CUSTOM,
        getCellValue: record => (
          <CuratedFieldsObjectNameCell
            {...record}
            dataAid={generateDataAid('CuratedFieldsObjectNameCell', [record.id])}
          />
        ),
      },
      {
        title: t('fieldName'),
        name: 'fieldName',
        type: BigidGridColumnTypes.TEXT,
        isTooltipFollowCursor: true,
        getCellValue: ({ displayFieldName }) => displayFieldName,
      },
      {
        title: t('containerName'),
        name: 'container',
        type: BigidGridColumnTypes.TEXT,
        isTooltipFollowCursor: true,
        getCellValue: ({ container }) => container,
      },
      {
        title: t('confidenceLevel'),
        name: 'confidenceLevel',
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ confidenceLevel, confidenceValue }) => {
          return confidenceLevel && !isNaN(confidenceValue)
            ? {
                chip: {
                  icon: <BigidConfidenceIndicator level={confidenceLevel.toLowerCase() as BigidConfidenceLevel} />,
                  size: 'small',
                  label: `${confidenceLevel} (${getPercentageValueFormatted(confidenceValue)})`,
                  bgColor: BigidColorsV2.white,
                  shadow: true,
                },
              }
            : null;
        },
      },
      {
        title: t('updatedConfidenceLevel'),
        name: 'updatedConfidenceLevel',
        type: BigidGridColumnTypes.CHIP,
        getCellValue: ({ updatedConfidenceLevel }) => {
          return updatedConfidenceLevel
            ? {
                chip: {
                  icon: (
                    <BigidConfidenceIndicator level={updatedConfidenceLevel.toLowerCase() as BigidConfidenceLevel} />
                  ),
                  size: 'small',
                  label: updatedConfidenceLevel,
                  bgColor: BigidColorsV2.white,
                  shadow: true,
                },
              }
            : null;
        },
      },
      {
        title: t('objectType'),
        name: 'objectType',
        type: BigidGridColumnTypes.TEXT,
        isTooltipFollowCursor: true,
        getCellValue: ({ detailedObjectType }) => detailedObjectType,
      },
      ...sampleColumns,
      {
        title: t('numberOfFindings'),
        name: 'totalFindings',
        type: BigidGridColumnTypes.TEXT,
        isTooltipFollowCursor: true,
        getCellValue: ({ totalFindings }) => totalFindings,
      },
      {
        title: t('curatedByUser'),
        name: 'curatedByUser',
        type: BigidGridColumnTypes.TEXT,
        isTooltipFollowCursor: true,
        getCellValue: ({ curatedByUser }) => curatedByUser,
      },
      {
        title: t('curatedAt'),
        name: 'curatedAt',
        type: BigidGridColumnTypes.DATE,
        isTooltipFollowCursor: true,
        getCellValue: ({ curatedAt }) => curatedAt,
      },
    ];

    const toolbarHasAccessibleActions = isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);

    const toolbarActionApproveProps: ToolbarAction = {
      label: t('approve'),
      isTooltipFollowCursor: true,
      disableTooltip: true,
      execute: async ({ selectedRows }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_INLINE_ACTION_APPROVE);
        const { id, fieldName, reviewStatus } = selectedRows[0] || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.APPROVED,
          };

          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed();
          }

          if (reviewStatus === CuratedFieldStatus.UNCURATED) {
            setAmountOfReviewedObjects(prevValue => prevValue + 1);
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: { reviewStatus: CuratedFieldStatus.APPROVED },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);

          return {
            shouldGridReload: true,
            shouldClearSelection: false,
            entityEventToEmit: EntityEvents.RELOAD,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldApprovalFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
      disable: () => {
        return false;
      },
      isInline: true,
      hideActionInToolBar: true,
    };

    const toolbarActionRejectProps: ToolbarAction = {
      label: t('reject'),
      isTooltipFollowCursor: true,
      disableTooltip: true,
      execute: async ({ selectedRows }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_INLINE_ACTION_REJECT);
        const { id, fieldName, reviewStatus } = selectedRows[0] || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.REJECTED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed();
          }

          if (reviewStatus === CuratedFieldStatus.UNCURATED) {
            setAmountOfReviewedObjects(prevValue => prevValue + 1);
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: { reviewStatus: CuratedFieldStatus.REJECTED },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);

          return {
            shouldGridReload: true,
            shouldClearSelection: false,
            entityEventToEmit: EntityEvents.RELOAD,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldRejectionFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
      disable: () => {
        return false;
      },
      isInline: true,
      hideActionInToolBar: true,
    };

    const toolbarActions: ToolbarAction[] = [
      {
        label: t('actions'),
        isGlobal: false,
        type: ToolbarActionType.DROPDOWN,
        dropdownProps: {
          placeholder: t('actions'),
          options: [
            {
              label: t('approve'),
              value: 'approve',
              execute: async ({ selectedRows }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_BULK_ACTION_APPROVE);
                try {
                  const isSelectionLimitExceeded = selectedRows.length > BACKEND_SUPPORT_LIMIT;

                  if (isSelectionLimitExceeded) {
                    notificationService.error(t('errors.selectionLimitExceeded', { BACKEND_SUPPORT_LIMIT }));
                    return {};
                  } else {
                    const payload: CurateFieldPayload = {
                      ids: selectedRows.map(({ id }) => id),
                      reviewStatus: CuratedFieldStatus.APPROVED,
                    };
                    notificationService.success(t('bulkApprove'));
                    await curateField(payload);

                    onFieldReviewed(true);

                    const eventPayload: EntityEventPayload<BigidGridUpdateRowsPayload> = {
                      entityId: gridId,
                      payload: {
                        rows: selectedRows.map(row => ({
                          ...row,
                          reviewStatus: CuratedFieldStatus.APPROVED,
                        })),
                      },
                    };
                    entityEventsEmitter.emit(EntityEvents.UPDATE, eventPayload);

                    return {
                      shouldGridReload: true,
                      shouldClearSelection: true,
                    };
                  }
                } catch ({ message }) {
                  const notificationMessage = t('errors.approvingSelectedFields', { BACKEND_SUPPORT_LIMIT });

                  console.error(`${notificationMessage}: ${message}`);
                  notificationService.error(`${notificationMessage}.`);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              show: () => {
                return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);
              },
            },
            {
              label: t('reject'),
              value: 'reject',
              execute: async ({ selectedRows }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_BULK_ACTION_REJECT);
                try {
                  const isSelectionLimitExceeded = selectedRows.length > BACKEND_SUPPORT_LIMIT;

                  if (isSelectionLimitExceeded) {
                    notificationService.error(t('errors.selectionLimitExceeded', { BACKEND_SUPPORT_LIMIT }));
                    return {};
                  } else {
                    const payload: CurateFieldPayload = {
                      ids: selectedRows.map(({ id }) => id),
                      reviewStatus: CuratedFieldStatus.REJECTED,
                    };
                    notificationService.success(t('bulkReject'));
                    await curateField(payload);

                    onFieldReviewed(true);

                    const eventPayload: EntityEventPayload<BigidGridUpdateRowsPayload> = {
                      entityId: gridId,
                      payload: {
                        rows: selectedRows.map(row => ({
                          ...row,
                          reviewStatus: CuratedFieldStatus.REJECTED,
                        })),
                      },
                    };
                    entityEventsEmitter.emit(EntityEvents.UPDATE, eventPayload);

                    return {
                      shouldGridReload: true,
                      shouldClearSelection: true,
                    };
                  }
                } catch ({ message }) {
                  const notificationMessage = t('errors.rejectingSelectedField');

                  console.error(`${notificationMessage}: ${message}`);
                  notificationService.error(`${notificationMessage}.`);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              show: () => {
                return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name);
              },
            },
            {
              label: t('loadPreview'),
              value: 'loadPreview',
              execute: async ({ selectedRows }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_BULK_ACTION_LOAD_PREVIEW);
                try {
                  await Promise.all(selectedRows.map(row => triggerPreviewCurationAttributes(row.id)));
                  notificationService.success(t('bulkPreviewFetch'));

                  return {
                    shouldGridReload: true,
                    shouldClearSelection: true,
                  };
                } catch ({ message }) {
                  const notificationMessage = t('errors.fetchingObjectsPreviews');

                  console.error(`${notificationMessage}: ${message}`);
                  notificationService.error(`${notificationMessage}.`);

                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              show: ({ selectedRows }: ActionData) => {
                return (
                  isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) &&
                  selectedRows.length > 1 &&
                  selectedRows.length <= BULK_PREVIEW_FETCH_LIMIT
                );
              },
            },
            {
              label: t('addAttributes'),
              value: 'addAttributes',
              execute: async ({ selectedRows }) => {
                trackEventCurationView(CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW_BULK);

                try {
                  const objectsSelected: AdditionalAttributesObjectIdentifier[] = selectedRows.map(
                    ({ displayFieldName, fullyQualifiedName }) => ({
                      fieldName: displayFieldName,
                      fullyQualifiedName,
                    }),
                  );

                  const additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig = {
                    objectsSelected,
                    gridId,
                    dataAid: 'ModifyAttributesBulk',
                    orderAfterSave: false,
                    isBulkMode: true,
                  };

                  openModifyAdditionalAttributesDialog(
                    getAdditionalAttributesDialogProps(additionalAttributesDialogConfigSetup),
                  );
                } catch ({ message }) {
                  notificationService.error(t('modifyingAttributesError'));
                  console.error(`${t('genericError')}: ${message}`);
                } finally {
                  return {
                    shouldGridReload: false,
                    shouldClearSelection: false,
                  };
                }
              },
              isInline: true,
              show: () => {
                return isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name);
              },
            },
          ],
          clearOnSelect: true,
        },
        disable: () => {
          return false;
        },
        show: ({ selectedRowIds }) => {
          return selectedRowIds.length > 0 && toolbarHasAccessibleActions;
        },
      },
      {
        ...toolbarActionApproveProps,
        show: ({ selectedRows }) =>
          !(selectedRows[0].reviewStatus === CuratedFieldStatus.APPROVED) &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: BigidLikeIcon,
      },
      {
        ...toolbarActionApproveProps,
        show: ({ selectedRows }) =>
          selectedRows[0].reviewStatus === CuratedFieldStatus.APPROVED &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: () => {
          return <BigidLikeFilledIcon color="positive" />;
        },
      },
      {
        ...toolbarActionRejectProps,
        show: ({ selectedRows }) =>
          !(selectedRows[0].reviewStatus === CuratedFieldStatus.REJECTED) &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: BigidDislikeIcon,
      },
      {
        ...toolbarActionRejectProps,
        show: ({ selectedRows }) =>
          selectedRows[0].reviewStatus === CuratedFieldStatus.REJECTED &&
          isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name),
        icon: () => {
          return <BigidDislikeFilledIcon color="negative" />;
        },
      },
      {
        label: t('modifyAttributes'),
        isTooltipFollowCursor: true,
        disableTooltip: true,
        execute: async ({ selectedRows }) => {
          const objectsSelected: AdditionalAttributesObjectIdentifier[] = [
            {
              fieldName: selectedRows[0].fieldName,
              fullyQualifiedName: selectedRows[0].fullyQualifiedName,
            },
          ];

          const additionalAttributesDialogConfigSetup: AdditionalAttributesDialogConfig = {
            objectsSelected,
            gridId,
            dataAid: 'ModifyAttributes',
            orderAfterSave: false,
          };

          trackEventCurationView(CurationEvents.CURATION_FIELDS_ADDTIONAL_ATTRIBUTES_MODIFY_ATRRIBUTE_VIEW);

          try {
            openModifyAdditionalAttributesDialog(
              getAdditionalAttributesDialogProps(additionalAttributesDialogConfigSetup),
            );
          } catch ({ message }) {
            notificationService.error(t('modifyingAttributesError'));
            console.error(`${t('genericError')}: ${message}`);
          } finally {
            return {
              shouldGridReload: false,
            };
          }
        },

        disable: () => {
          return false;
        },
        isInline: true,
        hideActionInToolBar: true,
        icon: BigidSearchQueryIcon,
        show: () => {
          return isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name);
        },
      },
    ];

    const approveObjectCommonProps: ToolbarAction = {
      label: t('approveObject'),
      type: ToolbarActionType.SECONDARY,
      isGlobal: true,
      execute: async ({ selectedItem }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_APRROVE_ACTION);
        const { id, fieldName, reviewStatus } = selectedItem || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.APPROVED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed();
          }

          if (reviewStatus === CuratedFieldStatus.UNCURATED) {
            setAmountOfReviewedObjects(prevValue => prevValue + 1);
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: { reviewStatus: CuratedFieldStatus.APPROVED },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);
          entityEventsEmitter.emit(EntityEvents.RELOAD, gridId);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldApprovalFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
    };

    const rejectObjectCommonProps: ToolbarAction = {
      label: t('rejectObject'),
      type: ToolbarActionType.SECONDARY,
      isGlobal: true,
      execute: async ({ selectedItem }) => {
        trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_REJECT_ACTION);
        const { id, fieldName, reviewStatus } = selectedItem || ({} as CuratedFieldRecord);

        try {
          const payload: CurateFieldPayload = {
            ids: [id],
            reviewStatus: CuratedFieldStatus.REJECTED,
          };
          const {
            data: { changedNumber },
          } = await curateField(payload);

          if (changedNumber === 1 && reviewStatus === CuratedFieldStatus.UNCURATED) {
            onFieldReviewed();
          }

          if (reviewStatus === CuratedFieldStatus.UNCURATED) {
            setAmountOfReviewedObjects(prevValue => prevValue + 1);
          }

          const eventPayload: EntityEventPayload<CuratedFieldRowUpdatePayload> = {
            entityId: gridId,
            payload: {
              rowId: id,
              row: { reviewStatus: CuratedFieldStatus.REJECTED },
            },
          };
          entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, eventPayload);
          entityEventsEmitter.emit(EntityEvents.RELOAD, gridId);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        } catch ({ message }) {
          const notificationMessage = t('errors.fieldRejectionFailed', { fieldName });

          console.error(`${notificationMessage}: ${message}`);
          notificationService.error(`${notificationMessage}.`);

          return {
            shouldGridReload: false,
            shouldClearSelection: false,
          };
        }
      },
    };

    const actions: ToolbarAction[] = [
      {
        ...approveObjectCommonProps,
        icon: BigidLikeIcon,
        show: ({ selectedItem }: ActionData) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus !== CuratedFieldStatus.APPROVED;
        },
      },
      {
        ...approveObjectCommonProps,
        icon: () => {
          return <BigidLikeFilledIcon color="positive" />;
        },
        show: ({ selectedItem }: ActionData) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus === CuratedFieldStatus.APPROVED;
        },
      },
      {
        ...rejectObjectCommonProps,
        icon: BigidDislikeIcon,
        show: ({ selectedItem }) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus !== CuratedFieldStatus.REJECTED;
        },
      },
      {
        ...rejectObjectCommonProps,
        icon: () => {
          return <BigidDislikeFilledIcon color="negative" />;
        },
        show: ({ selectedItem }) => {
          const { reviewStatus } = selectedItem;
          return isPermitted(REVIEW_FINDINGS_PERMISSIONS.REVIEW.name) && reviewStatus === CuratedFieldStatus.REJECTED;
        },
      },
    ];

    return {
      gridId,
      fetchGridData,
      columns,
      toolbarActions,
      actions,
    };
  }, [
    currentCuratedAttribute.attributeName,
    currentCuratedAttribute.attributeType,
    currentCuratedDataSource.source,
    onFieldReviewed,
    t,
  ]);

  useEffect(() => {
    fetchToolbarFilterConfig();
  }, [fetchToolbarFilterConfig]);

  return {
    isReady,
    layoutConfig: {
      content: {
        entityName: 'Objects',
        toolbarActions,
        contentTypes: [LayoutContentType.MASTER_DETAILS],
        viewConfig: {
          fetchGridData,
          gridConfig: {
            gridId,
            pageSize: SELECTION_LIMIT,
            showSelectAll: true,
            columns,
            showSortingControls: false,
            defaultSorting: [{ field: 'objectName', order: 'asc' }],
          },
          selectedItemPropsMapping: {
            id: 'id',
            name: 'displayFieldName',
            fieldName: 'fieldName',
            displayFieldName: 'displayFieldName',
            icon: 'icon',
            reviewStatus: 'reviewStatus',
            fullyQualifiedName: 'fullyQualifiedName',
            detailedObjectType: 'detailedObjectType',
          },
          masterDetailsConfig: {
            tabsAndContent: {
              classes: {
                contentContainer: classes.contentContainer,
              },
              tabProps: {
                selectedIndex: 0,
                onChange: (value: number, tab: BigidTabsItem) => {
                  trackEventCurationView(CurationEvents.CURATION_FIELDS_TAB_CLICK, {
                    tab: tab.label,
                  });
                },
                tabs: [
                  {
                    label: t('preview'),
                    data: {
                      component: AttributePreviewStructured,
                      customProps: {
                        attributeName: currentCuratedAttribute.attributeName,
                        attributeType: currentCuratedAttribute.attributeType,
                        curatedFieldsGridId: gridId,
                      },
                    },
                    getIsAvailable: ({ detailedObjectType }) => {
                      return (
                        structuredObjectsTypes.includes(detailedObjectType) &&
                        !getApplicationPreference('DATA_PREVIEW_DISABLED')
                      );
                    },
                  },
                  {
                    label: t('columnProfile'),
                    data: {
                      component: ColumnProfileComponent,
                    },
                    getIsAvailable: ({ detailedObjectType }) => structuredObjectsTypes.includes(detailedObjectType),
                  },
                  {
                    label: t('preview'),
                    data: {
                      component: AttributePreviewUnstructured,
                      customProps: {
                        attributeName: currentCuratedAttribute.attributeName,
                        attributeType: currentCuratedAttribute.attributeType,
                        curatedFieldsGridId: gridId,
                      },
                    },
                    getIsAvailable: ({ detailedObjectType }) => {
                      const objectsTypes = [DetailedObjectType.UNSTRUCTURED, DetailedObjectType.APP];
                      return (
                        objectsTypes.includes(detailedObjectType) && !getApplicationPreference('DATA_PREVIEW_DISABLED')
                      );
                    },
                  },
                  {
                    label: t('additionalAttributes'),
                    data: {
                      component: AdditionalAttributes,
                    },
                  },
                ],
              },
            },
            actions,
          },
          filterToolbarConfig,
        },
      },
    },
    amountOfReviewedObjects,
  };
};
