import React, { FC, useMemo, useState, useCallback, Fragment } from 'react';
import angular from 'angular';
import { debounce } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { BigidDialog, PrimaryButton, SecondaryButton, BigidSearch, BigidColors } from '@bigid-ui/components';
import {
  BigidGridWithToolbar,
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridWithToolbarProps,
  BigidGridQueryComponents,
} from '@bigid-ui/grid';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { queryService } from '../../../services/queryService';
import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import { Scope } from './DataSourcesCollapsables/ScopesDataSourcesContent';
import {
  DataSourceModel,
  DataSourcesResponse,
} from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { ALLOWED_NAME_REGEX, INVALID_NAME_REGEX_MESSAGE } from '../../../config/consts';

interface DataSourceExpression {
  operator: string;
  value: string;
}

interface ConnectDataSourcesByPrefixDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onUpdatePrefix: (value: DataSourceExpression) => void;
  scope: Scope;
}

const useStyles = makeStyles({
  wrapper: { display: 'flex', flexDirection: 'column' },
  searchWrapper: { display: 'flex', alignItems: 'center' },
  searchTitle: { color: BigidColors.gray[700], fontSize: '0.875rem', fontWeight: 'bold', marginRight: 40 },
  searchContainer: { display: 'flex', width: 300 },
  error: { color: BigidColors.failureRed, fontSize: '0.75rem', marginLeft: 130, height: 20 },
});

const gridColumns: BigidGridColumn<DataSourceModel>[] = [
  {
    name: 'name',
    title: 'Data Source',
    getCellValue: ({ name }) => name,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 300,
  },
  {
    name: 'type',
    title: 'Type',
    getCellValue: ({ type }) => type,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 300,
  },
  {
    name: 'location',
    title: 'Location',
    getCellValue: ({ location }) => location,
    filteringEnabled: true,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
    width: 300,
  },
];

const ERROR_MESSAGE = 'The prefix entered already exists';

export const ConnectDataSourcesByPrefixDialog: FC<ConnectDataSourcesByPrefixDialogProps> = ({
  isOpen = false,
  onClose,
  onUpdatePrefix,
  scope: { dataSourceExpressions = [] },
}) => {
  const classes = useStyles({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [externalFilter, setExternalFilter] = useState([]);

  const resetDialog = useCallback(() => {
    setSearchValue('');
    setExternalFilter([]);
  }, []);

  const onCloseDialog = useCallback(() => {
    onClose();
    resetDialog();
  }, [resetDialog, onClose]);

  const onConnectClick = () => {
    onUpdatePrefix({ operator: 'startsWith', value: searchValue });
    resetDialog();
  };

  const buttons = useMemo(
    () => [
      { text: 'cancel', onClick: onCloseDialog, component: SecondaryButton },
      {
        text: 'Connect Data Sources',
        onClick: onConnectClick,
        component: PrimaryButton,
        disabled: errorMessage !== '' || searchValue === '', // TODO: disable also when no searches appear for prefix
      },
    ],
    [errorMessage, onCloseDialog, onConnectClick, searchValue],
  );

  const onSearchChange = useCallback(
    debounce((searchValue: string) => {
      const invalidDsName = !ALLOWED_NAME_REGEX.test(searchValue);
      const hasError =
        searchValue !== '' && (!!dataSourceExpressions.find(({ value }) => value === searchValue) || invalidDsName);
      setErrorMessage(hasError ? (invalidDsName ? INVALID_NAME_REGEX_MESSAGE : ERROR_MESSAGE) : '');
      setSearchValue(searchValue);
      if (!hasError) {
        setExternalFilter([{ field: 'name', operator: 'startsWith', value: searchValue, caseSensitive: true }]);
      }
    }, 150),
    [],
  );

  const gridWithToolbarConfig = useMemo<BigidGridWithToolbarProps<DataSourceModel>>(
    () => ({
      columns: gridColumns,
      pageSize: 200,
      customRowIdName: 'id',
      entityName: 'Prefixes',
      externalFilter,
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        let data: DataSourceModel[] = [];
        let totalCount = 0;

        try {
          const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);

          const {
            data: {
              data: { ds_connections, totalCount: count },
            },
          } = await httpService.fetch<{ data: DataSourcesResponse }>(`ds-connections?${gridConfigQuery}`);

          data = ds_connections;
          totalCount = count;
        } catch (e) {
          console.error(e);
          notificationService.error('Could not fetch data. See logs for more information');
        }

        return {
          data,
          totalCount,
        };
      },
      showSortingControls: true,
      hideColumnChooser: true,
      defaultSorting: [{ field: 'name', order: 'asc' }],
    }),
    [externalFilter],
  );

  return (
    <BigidDialog
      showCloseIcon
      isOpen={isOpen}
      onClose={onCloseDialog}
      title="Connect Data Sources by Prefix"
      buttons={buttons}
      maxWidth="md"
    >
      <Fragment>
        <div className={classes.wrapper}>
          <div className={classes.searchWrapper}>
            <div className={classes.searchTitle}>Search Prefix</div>
            <div className={classes.searchContainer}>
              <BigidSearch
                value={searchValue}
                onChange={onSearchChange}
                onSubmit={onSearchChange}
                placeholder="Search"
              />
            </div>
          </div>
          <div className={classes.error}>{errorMessage}</div>
        </div>
        <BigidGridWithToolbar {...gridWithToolbarConfig} />
      </Fragment>
    </BigidDialog>
  );
};

angular
  .module('app')
  .component(
    'connectDataSourcesByPrefixDialog',
    convertToAngular(ConnectDataSourcesByPrefixDialog, ['isOpen', 'onClose', 'onUpdatePrefix', 'scope']),
  );
