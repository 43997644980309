import React, { FC } from 'react';
import { PlannedScanGridRow } from '../PlannedScanTypes';
import { getScheduleGridCellValue } from '../../ScanUtils';
import { BigidChip, BigidColorsV2, BigidBody2 } from '@bigid-ui/components';

const PAUSED_TEXT = 'Paused';

export const ScanScheduleCell: FC<PlannedScanGridRow> = ({ schedule, active }) => {
  if (!active && schedule) {
    return <BigidChip bgColor={BigidColorsV2.gray[100]} size="medium" label={PAUSED_TEXT} />;
  }
  const text = getScheduleGridCellValue(schedule);
  return <BigidBody2>{text}</BigidBody2>;
};
