import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  BigidBody1,
  BigidHeading6,
  BigidNotes1,
  BigidTooltip,
  BigidLink,
  BigidButtonIcon,
  SecondaryButton,
} from '@bigid-ui/components';
import { ScansUITrackingEvent, trackEventScansView } from '../../ScansEventTrackerUtils';
import { userPreferencesService } from '../../../../services/userPreferencesService';
import { generateDataAid } from '@bigid-ui/utils';
import { ScanProfileInfo } from '../ScanInsightTypes';
import { BigidCopyIcon, BigidHelpIcon } from '@bigid-ui/icons';
import { dateTimeService } from '@bigid-ui/i18n';
import { isEmpty, startCase } from 'lodash';
import { NOT_APPLICABLE, copyToClipboard, getScanTypeLabel, handleScanTemplateMoreDetailsClick } from '../../ScanUtils';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { getTemplateById } from '../../ScanTemplates/scanTemplatesService';
import { notificationService } from '../../../../services/notificationService';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { CompletedParentScanState, ProgressType, SSEType, ScanUpdateSSE, ScansGridRow } from '../../ScanTypes';
import { subscribeToRepeatedSSEEventById } from '../../../../services/sseService';
import { openConvertProfileDialog } from '../../PlannedScans/SavedScans';

const ScanDetailsTitle = styled(BigidHeading6)`
  font-size: 16px;
  margin-bottom: 24px;
`;

const BoxInfo = styled('div')`
  width: 220px;
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: flex-start;
`;

const BoxInfoTitleSection = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;

export const BigidTooltipContainer = styled(BigidTooltip)`
  display: flex;
`;

const InformationSection = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
`;

interface ScanDetailsProps extends ScanProfileInfo {
  dataAid?: string;
}

export const ScanDetails: FC<ScanDetailsProps> = ({
  dataAid = 'ScanDetails',
  previousScanCompleted,
  type,
  startedAt,
  completedAt,
  _id,
  scanProfileId,
  description,
  nextScheduledRun,
  isActive,
  progress = {},
  runningTime,
  scanTemplateId,
  scanTemplateName,
  name: profileName,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [scanProgress, setScanProgress] = useState<ProgressType>(progress as ProgressType);
  const isScanTemplatesEnabled = getApplicationPreference('ENABLE_SCAN_TEMPLATES');
  const hasScanTemplate = scanTemplateId && scanTemplateId !== NOT_APPLICABLE;

  useEffect(() => {
    handleScanDetailsIsOpen();

    const unregister = subscribeToRepeatedSSEEventById<ScanUpdateSSE>(SSEType.PARENT, data => {
      const { results } = data;
      const result = results.find(({ id }) => id === _id);
      if (result?.payload != null) {
        const { progress: updatedProgress } = result.payload as ScansGridRow;
        setScanProgress(updatedProgress);
      }
    });
    return () => unregister();
  }, [_id]);

  const handleScanDetailsIsOpen = async () => {
    try {
      const scanInsightUserPreference = await userPreferencesService.get('scanInsights');
      setExpanded(Boolean(scanInsightUserPreference?.data?.isOpenScanDetails));
    } catch (error) {
      console.error('Error to get the user preference', error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyClipboardHandler = (id: string) => {
    copyToClipboard(id);
    notificationService.info('Scan id copied to clipboard', {
      duration: 3000,
    });
  };

  const { state } = scanProgress;

  // the items are not aligned in the UI, it's because the icons are not in the correct size, when the icons will merge we will can fix that.
  const data = [
    {
      title: 'Scan ID',
      icon: () => (
        <BigidTooltipContainer title={'Copy to clipboard'} enterDelay={500} placement="right">
          <span>
            <BigidCopyIcon onClick={() => copyClipboardHandler(_id)} />
          </span>
        </BigidTooltipContainer>
      ),
      info: _id,
      isClickable: true,
    },
    {
      title: 'Start Date',
      icon: () => (
        <BigidTooltipContainer
          title={'Exact date and time the scan started performing'}
          enterDelay={500}
          placement="right"
        >
          <span>
            <BigidHelpIcon />
          </span>
        </BigidTooltipContainer>
      ),
      info: dateTimeService.formatDate(startedAt),
      isClickable: false,
    },
    {
      title: 'Running Time',
      icon: () => (
        <BigidTooltipContainer
          title={'Calculation of the time difference between completed and started dates'}
          enterDelay={500}
          placement="right"
        >
          <span>
            <BigidHelpIcon />
          </span>
        </BigidTooltipContainer>
      ),
      info: runningTime,
      isClickable: false,
    },
    {
      title: 'Previous Scan End Date',
      info: previousScanCompleted ? dateTimeService.formatDate(previousScanCompleted) : NOT_APPLICABLE,
    },
    {
      title: 'Scan Type',
      info: getScanTypeLabel(type),
    },
    {
      title: 'Completed Date',
      icon: () => (
        <BigidTooltipContainer
          title={'The date and time when all sub-scan processes finished running'}
          enterDelay={500}
          placement="right"
        >
          <span>
            <BigidHelpIcon />
          </span>
        </BigidTooltipContainer>
      ),
      info: Object.values(CompletedParentScanState).includes(state as CompletedParentScanState)
        ? dateTimeService.formatDate(completedAt)
        : `Scan ${startCase(state)}`,
      isClickable: false,
    },
    {
      title: 'Next Run Date',
      info: nextScheduledRun
        ? isActive
          ? dateTimeService.formatDate(nextScheduledRun)
          : `${dateTimeService.formatDate(nextScheduledRun)} - Disabled`
        : 'None',
    },
    {
      title: hasScanTemplate ? 'Template Name' : 'Profile Name',
      info: (
        <BigidLink
          onClick={async () => {
            trackEventScansView(ScansUITrackingEvent.EDIT_SCAN_PROFILE_CLICK);

            if (hasScanTemplate) {
              try {
                const scanTemplate = await getTemplateById(scanTemplateId, true);
                handleScanTemplateMoreDetailsClick({ ...scanTemplate, shouldDisplayWarning: true }, 'Scan Template');
              } catch (e) {
                notificationService.error('Failed to fetch template. See logs for more information.');
                console.error(e);
              }
              return;
            }

            if (isScanTemplatesEnabled) {
              openConvertProfileDialog({
                scanProfileId: scanProfileId as string,
                isForReview: true,
                scanType: getScanTypeLabel(type),
              });
              return;
            }

            $state.go(CONFIG.states.EDIT_SCAN_PROFILE, {
              id: scanProfileId,
              ...(isScanTemplatesEnabled && { isHideSave: true }),
              name: profileName,
            });
          }}
          text={hasScanTemplate ? scanTemplateName : profileName}
        />
      ),
    },
  ];

  return (
    <div>
      <ScanDetailsTitle data-aid={generateDataAid(dataAid, ['BigidAccordionSummary', 'ScanDetailsTitle'])}>
        Scan Details
      </ScanDetailsTitle>
      <InformationSection>
        {data.map(({ icon: Icon, ...row }, index) => (
          <BoxInfo key={index}>
            <BoxInfoTitleSection>
              <BigidNotes1>{row.title}</BigidNotes1>
              {Icon ? row.isClickable ? <BigidButtonIcon icon={Icon} /> : <Icon /> : null}
            </BoxInfoTitleSection>
            <BigidBody1>{row.info}</BigidBody1>
          </BoxInfo>
        ))}
      </InformationSection>
    </div>
  );
};
