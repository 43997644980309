import React, { FC } from 'react';
import { GlossaryRecordStatus } from '../types';
import { statusToColorMap } from './common';
import { useLocalTranslation } from '../translations';
import { styled } from '@mui/system';

type ColorProps = {
  backgroundColor: string;
};

const Color = styled('div')<ColorProps>`
  background-color: ${props => props.backgroundColor};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

type TooltipTitleProps = {
  status: GlossaryRecordStatus;
};
export const TooltipTitle: FC<TooltipTitleProps> = ({ status }) => {
  const { t } = useLocalTranslation('common.recordStatus');

  return (
    <Container>
      <Color backgroundColor={statusToColorMap[status]} />
      <div>{t(status)}</div>
    </Container>
  );
};
