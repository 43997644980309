import { BigidHeading4, BigidHeading6 } from '@bigid-ui/components';
import { BigidGraph, BigidGraphGraph, BigidGraphNode } from '@bigid-ui/visualisation';
import React, { FC, ReactNode, useMemo } from 'react';
import { GraphNode } from './GraphNode';
import { styled } from '@mui/material';
import { useLocalTranslation } from '../../../../../translations';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 16px;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const getGraphDataFromHierarchy = (hierarchy: Hierarchy[]): BigidGraphGraph => {
  const nodes: BigidGraphNode[] = [];
  const edges: BigidGraphGraph['edges'] = [];

  hierarchy.forEach(({ icon, type, name, isActive }, index) => {
    nodes.push({
      id: name,
      view: () => <GraphNode type={type} name={name} icon={icon} isActive={isActive} />,
    });
    if (index > 0) {
      edges.push({
        source: {
          id: hierarchy[index - 1].name,
        },
        target: {
          id: name,
        },
      });
    } else {
      edges.push({
        source: {
          id: name,
        },
        target: {
          id: hierarchy[index + 1].name,
        },
      });
    }
  });

  return {
    nodes,
    edges,
  };
};

const NoDataContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const NoData = () => {
  const { t } = useLocalTranslation('Settings.DomainModal.Content');
  return (
    <NoDataContainer>
      <BigidHeading4>{t('hierarchyNoData')}</BigidHeading4>
    </NoDataContainer>
  );
};

const Heading = styled(BigidHeading6)`
  padding: 16px 16px 12px 16px;
  line-height: 20px;
  width: 100%;
`;

export type Hierarchy = {
  type: string;
  icon: ReactNode;
  name: string;
  isActive?: boolean;
};

type HierarchyProps = {
  title: string;
  hierarchy: Hierarchy[];
};
export const Hierarchy: FC<HierarchyProps> = ({ hierarchy, title }) => {
  const data = useMemo(() => {
    return getGraphDataFromHierarchy(hierarchy);
  }, [hierarchy]);

  const shouldShowHierarchy = hierarchy.length > 0;

  return (
    <Container>
      <Heading>{title}</Heading>
      {shouldShowHierarchy ? <BigidGraph data={data} /> : <NoData />}
    </Container>
  );
};
