import { customAppService } from '../../../services/customAppService';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGridConfiguration';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { ExecutionPayload, generatedUniquePresetName } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { notificationService } from '../../../services/notificationService';
import { BigidColorsV2, EntityEvents } from '@bigid-ui/components';
import { ExecutionStatus } from '../../CustomApp/components/PresetCard/PresetLatestExecutionStatus';

export const discoverAction = async ({ _id, appId, appRunAction }: AutoDiscoveryConfigModel) => {
  await customAppService.runCustomAppPreset(appRunAction._id, _id, appId);
  return {
    shouldGridReload: true,
  };
};

export const editAction = async ({ _id, appId, appRunAction }: AutoDiscoveryConfigModel) => {
  const params = {
    id: appId,
    actionId: appRunAction._id,
    presetId: _id,
    onReturnState: JSON.stringify({
      url: CONFIG.states.DATA_SOURCE_CONNECTIONS,
      params: {
        tab: 'discovery',
      },
    }),
  };
  $state.go(CONFIG.states.CUSTOM_APP_EDIT_PRESET, params);
  return {
    shouldGridReload: false,
    entityEventToEmit: EntityEvents.RELOAD,
  };
};

export const deleteAction = async ({ appId, _id, appRunAction, name }: AutoDiscoveryConfigModel) => {
  try {
    await customAppService.deleteActionPreset(appId, appRunAction._id, _id);
    notificationService.success(`${name} Config - Deleted`, { shouldCloseOnTransition: false });
    return {
      shouldGridReload: true,
      shouldClearSelection: true,
      entityEventToEmit: EntityEvents.RELOAD,
    };
  } catch (err) {
    notificationService.error(`${name} Config delete error. ${err?.response?.data?.error || ''}`);
    console.warn(err);
    return {
      shouldGridReload: false,
      shouldClearSelection: false,
    };
  }
};

export const stopAction = async ({ appId, _id, appStopAction }: AutoDiscoveryConfigModel) => {
  const { data } = await customAppService.getCustomAppLastExecutions(appId);
  const executionId = data.find(({ preset_id }: ExecutionPayload) => preset_id === _id)._id;
  await customAppService.editActionPreset(appId, appStopAction._id, appStopAction.presets[0]._id, {
    paramsKeyValue: {
      executionId,
    },
  });
  await customAppService.runCustomAppPreset(appStopAction._id, appStopAction.presets[0]._id, appId);

  notificationService.success(`Stop request - the action will be stopped within a few minutes`, {
    shouldCloseOnTransition: false,
  });
  return {
    shouldGridReload: false,
  };
};

export const copyAction = async ({
  appId,
  name,
  description,
  paramsKeyValue,
  overrideGlobalParams,
  globalParamsKeyValue,
  appRunAction,
}: AutoDiscoveryConfigModel) => {
  const existingNames = appRunAction.presets.map(({ name }) => name);
  const nameForCloning = generatedUniquePresetName(`Clone - ${name}`, existingNames);
  try {
    const presetData = {
      name: nameForCloning,
      description,
      paramsKeyValue,
      overrideGlobalParams,
      globalParamsKeyValue: overrideGlobalParams ? globalParamsKeyValue : undefined,
    };
    await customAppService.addActionPreset(appId, appRunAction._id, presetData);

    notificationService.success(`Cloned configuration successfully`, { shouldCloseOnTransition: false });
  } catch (err) {
    console.warn(err);
    const message = 'There is a problem cloning this configuration';
    notificationService.error(message);
  }
  return {
    shouldGridReload: true,
    shouldClearSelection: true,
    entityEventToEmit: EntityEvents.RELOAD,
  };
};

export const getTheRequiredColorsToChip = (status: ExecutionStatus) => {
  switch (status) {
    case ExecutionStatus.COMPLETED:
      return {
        color: BigidColorsV2.green[900],
        bgColor: BigidColorsV2.green[100],
      };
    case ExecutionStatus.ERROR:
      return {
        color: BigidColorsV2.red[700],
        bgColor: BigidColorsV2.red[100],
      };
    case ExecutionStatus.IN_PROGRESS:
      return {
        color: BigidColorsV2.blue[900],
        bgColor: BigidColorsV2.blue[100],
      };
    default:
      return {
        bgColor: BigidColorsV2.gray[150],
        color: BigidColorsV2.gray[600],
      };
  }
};
