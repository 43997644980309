import React, { createContext } from 'react';
import { ScanTemplate, ScanTypes } from '../ScanTemplates/scanTemplateTypes';
import { SchedulerSettingsWithCronExpression } from '@bigid-ui/components';

export type SetScanWizardState = React.Dispatch<React.SetStateAction<ScanCreateData>>;

export interface dataSourceInfoForMap {
  name: string;
  type: string;
  enabled?: string;
}

export interface ScanCreateData {
  dataSourcesPreselected?: dataSourceInfoForMap[];
  isTemplateCreate: boolean;
  correlationRequired: boolean;
  dataSourcesIds: string[];
  templateIds: string[];
  supportedScanTypes?: ScanTypes[];
  isLoading: boolean;
  dataSourceNameToTypeMap: Record<string, string>;
  correlationSupported: boolean;
  classificationSupported: boolean;
  classificationRequired: boolean;
  scanTemplateCreateInWizard?: ScanTemplate;
  schedule?: SchedulerSettingsWithCronExpression;
  name: string;
  owners?: string[];
  scanId: string;
  description?: string;
  isDisabledDataSourcesSelected?: boolean;
  isPrefillDsStep?: boolean;
  allEnabledDs?: boolean;
  isShowSelectedToggleActive?: boolean;
}

export interface CreateScanFormContext {
  scanWizardState: ScanCreateData;
  setScanWizardState: SetScanWizardState;
  onChangeToCreateTemplate: () => void;
  scanBasicDetailsHandlersRef: any;
}

export const CreateScanWizardContext = createContext<CreateScanFormContext>(null);

export const SCAN_CREATE_INIT_STATE: ScanCreateData = {
  isTemplateCreate: false,
  dataSourcesIds: [],
  correlationRequired: false,
  supportedScanTypes: Object.values(ScanTypes) as ScanTypes[],
  isLoading: true,
  templateIds: [],
  dataSourceNameToTypeMap: {},
  correlationSupported: true,
  classificationSupported: false,
  classificationRequired: false,
  name: '',
  schedule: null,
  owners: [],
  scanId: null,
  description: '',
  allEnabledDs: false,
  isShowSelectedToggleActive: false,
};
