import { BigidGridRow, Statuses } from '@bigid-ui/grid';
import { httpService } from '../../services/httpService';
import {
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormStateAndHandlers,
  BigidFormValues,
  BigidSelectOption,
  convertCronToRecurringScheduleObject,
  convertRecurringScheduleObjectToCron,
  RecurringSchedule,
  SchedulerSettingsWithCronExpression,
} from '@bigid-ui/components';
import { notificationService } from '../../services/notificationService';
import { AxiosError } from 'axios';
import { difference, isEmpty, isEqual, omit, pick } from 'lodash';

import {
  AdaptersLabels,
  AdapterTypeOptions,
  CODE_400,
  DATA_INSIGHTS_STUDIO,
  DATA_PIPELINE,
  DEFAULT_BULK_SIZE,
  ETL_METADATA_URL,
  ETL_SETTINGS_URL,
  ETL_TEST_CONNECTION_URL,
  FIELDS_TO_PICK_FOR_TEST_CONNECTION,
  S3AuthenticationMethod,
  S3AuthenticationMethodLabels,
  S3FileTypes,
  S3FileTypesLabels,
  SELECT_TYPE_FIELD,
} from './consts/ReportingEtlConsts';
import { MutableRefObject } from 'react';
import { TestConnectionResponse } from '../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import { isPermitted } from '../../services/userPermissionsService';
import { REPORTS_PERMISSIONS } from '@bigid/permissions';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { appsLicenseService } from '../../services/appsLicenseService';
import { tConfigurationDialog, tNotifications, tTitle } from './translations/EtlFixedTranslations';
import { analyticsService } from '../../services/analyticsService';
import { ReportingEtlTrackingEvents } from './consts/ReportingEtlEventsConsts';
import { $state } from '../../services/angularServices';
import { TitleObject } from '../../../common/services/pageHeaderService';

export enum ReportingEtlFieldSet {
  TOP = 'TOP',
  ADVANCED = 'ADVANCED',
  SCHEDULER = 'SCHEDULER',
  CONNECTIVITY = 'CONNECTIVITY',
}

export interface ReportingEtlConfigurationFormField {
  name: keyof ReportingEtlConfiguration;
  displayName: string;
  type: BigidFormFieldTypes;
  required?: boolean;
  description?: string;
  options?: BigidSelectOption[];
  fieldset: ReportingEtlFieldSet;
  adapterType?: AdapterTypeOptions;
}

interface EtlSchedulerConfiguration {
  schedulerObject: SchedulerSettingsWithCronExpression;
  numberOfRuns: number;
}

export interface ReportingEtlConfiguration {
  load_bulk_size: number;
  container_name: string;
  container_location: string;
  hash_sensitive_values: boolean;
  jobs_split_threshold: number;
  jobs_split_max_parts: number;
  post_command_wait_internal_millis?: number;
  ignore_max_bad_records: number;
  schedule: EtlSchedulerConfiguration;
  max_lines_per_execution: number;
  site_name: string;
  site_location: string;
  site_region: string;
  credentials_enc: string;
  sensitive_values_hash_algo: string;
  access_key?: string;
  adapterType?: AdapterTypeOptions;
  authentication_method?: S3AuthenticationMethod;
  file_type?: S3FileTypes;
  role_session_name?: string;
  role_arn?: string;
}

export enum EtlMode {
  FULL_REPLACE = 'FULL_REPLACE',
  INCREMENTAL = 'INCREMENTAL',
}

export enum OnBoardingStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum ETLStatusMode {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOUD_IN_PROGRESS = 'CLOUD_IN_PROGRESS',
  STOPPED = 'STOPPED', //legacy
  CALCULATING = 'CALCULATING',
  CANCELLED = 'CANCELLED',
}

export enum EtlStageName {
  PRE = 'PRE',
  STREAM = 'STREAM',
  POST = 'POST',
  CLOUD = 'CLOUD',
}

export type ReportingEtlFormValues = Omit<
  ReportingEtlConfiguration,
  'sensitive_values_hash_algo' | 'schedule' | 'select_type' | 'authentication_method' | 'file_type'
> & {
  sensitive_values_hash_algo: BigidSelectOption[];
  select_type: BigidSelectOption[];
  authentication_method: BigidSelectOption[];
  schedule: RecurringSchedule;
  file_type: BigidSelectOption[];
};

interface ETlPartStatus {
  id: number;
  start_row: number;
  end_row: number;
  total_row_count: number;
  total_row_processed: number;
  status: ETLStatusMode;
}

export interface ReportingEtlMonitoringResponse {
  data: {
    monitoring: GroupedEtlMonitoringData[];
    totalCount: number;
  };
}

interface ReportingEtlConfigurationResponse {
  data: {
    settings: ReportingEtlConfiguration;
  };
}

interface ReportingEtlExecuteResponse {
  data: {
    message: string;
    isExecuted: boolean;
    globalEtlRunId: string;
  };
}

interface ReportingEtlStoppedResponse {
  data: {
    message: string;
    isStopped: boolean;
    globalEtlRunIds: string[];
  };
}

export interface RunningAdapters {
  S3?: boolean;
  BigQuery?: boolean;
  GCS?: boolean;
  DIS?: boolean;
  Snowflake?: boolean;
}

interface ReportingEtlProgressResponse {
  data: {
    message: string;
    allRunningAdapters: RunningAdapters;
    currentActiveAdapter: AdapterTypeOptions;
  };
}

export interface ReportingEtlMonitoringModel extends BigidGridRow {
  etl_unique_name: string;
  source_table: string;
  target_table: string;
  etl_mode: EtlMode;
  increment_field_name: string;
  last_success_increment_value: any;
  started_at: string;
  completed_at: string;
  updated_at: string;
  duration_min: string;
  status: string;
  error: string;
  num_of_parts: number;
  total_row_count: number;
  total_row_processed: number;
  parts_status: ETlPartStatus[];
  adapter_type: AdapterTypeOptions;
  container_name: string;
  global_etl_run_id: string;
  stage: string;
}

export interface GroupedEtlMonitoringData {
  key: string;
  childRows: ReportingEtlMonitoringModel[];
}

export interface SelectedAndDefaultAdapter {
  selectedAdapter: BigidSelectOption;
  defaultAdapter: BigidSelectOption;
}

export interface ShouldDisableFieldsByLicense {
  isDisLicensed?: boolean;
  isDataPipelineLicense?: boolean;
  selectedAdapter: AdapterTypeOptions;
  fieldName: string;
  isLoading: boolean;
}

export interface EtlLicenses {
  isDisLicense: boolean;
  isDataPipeLineLicense: boolean;
}

interface StatusCounts {
  COMPLETED: number;
  FAILED: number;
  IN_PROGRESS: number;
  STOPPED: number;
  CALCULATING: number;
}

export async function getReportingETlMonitoringData(
  query: string,
  isDataInsightsStudio: boolean,
): Promise<ReportingEtlMonitoringResponse> {
  const { data } = await httpService.fetch<ReportingEtlMonitoringResponse>(`reporting-etl/monitoring/audit?${query}`, {
    isDataInsightsStudio: `${isDataInsightsStudio}`,
  });
  return data;
}

export async function getReportingETlSettings(isDataInsightsStudio: boolean) {
  if (getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE')) {
    return isDataInsightsStudio ? await getDisSettings() : await getDataPipelineSettings();
  }
  // TODO after removing the ENABLE_NEW_ETL_LANDING_PAGE FF we can delete all the code below and fetchAllSettingsOrMetadata function
  const [
    { settingsMetadata: S3SettingsMetadata },
    { settingsMetadata: BigQuerySettingsMetadata },
    { settingsMetadata: GcsSettingsMetadata },
    { settingsMetadata: DisSettingsMetaData },
    { settingsMetadata: SnowflakeSettingsMetaData },
  ] = await fetchAllSettingsOrMetadata(ETL_METADATA_URL);

  const [
    { settings: S3Settings },
    { settings: BigQuerySettings },
    { settings: GcsSettings },
    { settings: DisSettings },
    { settings: SnowflakeSettings },
  ] = await fetchAllSettingsOrMetadata(ETL_SETTINGS_URL);
  const {
    data: {
      data: { settings },
    },
  } = await httpService.fetch<ReportingEtlConfigurationResponse>(`reporting-etl/settings`);

  const currSettings = adjustSettingsToForm(settings);
  const BigQueryFormSettings = adjustSettingsToForm(BigQuerySettings);
  const S3FormSettings = adjustSettingsToForm(S3Settings);
  const GcsFormSettings = adjustSettingsToForm(GcsSettings);
  const DisFormSettings = adjustSettingsToForm(DisSettings);
  const SnowflakeFormSettings = adjustSettingsToForm(SnowflakeSettings);
  return {
    settings: currSettings,
    settingsMetadata: [
      ...GcsSettingsMetadata,
      ...S3SettingsMetadata,
      ...BigQuerySettingsMetadata,
      ...DisSettingsMetaData,
      ...SnowflakeSettingsMetaData,
    ],
    allSettings: {
      BigQuery: BigQueryFormSettings,
      S3: S3FormSettings,
      GCS: GcsFormSettings,
      DIS: DisFormSettings,
      Snowflake: SnowflakeFormSettings,
    },
  };
}

async function getDataPipelineSettings() {
  const [
    { settingsMetadata: S3SettingsMetadata },
    { settingsMetadata: BigQuerySettingsMetadata },
    { settingsMetadata: GcsSettingsMetadata },
    { settingsMetadata: SnowflakeSettingsMetaData },
  ] = await fetchAllDataPipelineSettingsOrMetadata(ETL_METADATA_URL);

  const [
    { settings: S3Settings },
    { settings: BigQuerySettings },
    { settings: GcsSettings },
    { settings: SnowflakeSettings },
  ] = await fetchAllDataPipelineSettingsOrMetadata(ETL_SETTINGS_URL);

  const {
    data: {
      data: { settings },
    },
  } = await httpService.fetch<ReportingEtlConfigurationResponse>(`reporting-etl/settings`);

  const currSettings = adjustSettingsToForm(settings);
  const BigQueryFormSettings = adjustSettingsToForm(BigQuerySettings);
  const S3FormSettings = adjustSettingsToForm(S3Settings);
  const GcsFormSettings = adjustSettingsToForm(GcsSettings);
  const SnowflakeFormSettings = adjustSettingsToForm(SnowflakeSettings);
  return {
    settings: currSettings.adapterType !== AdapterTypeOptions.DIS ? currSettings : BigQueryFormSettings,
    settingsMetadata: [
      ...GcsSettingsMetadata,
      ...S3SettingsMetadata,
      ...BigQuerySettingsMetadata,
      ...SnowflakeSettingsMetaData,
    ],
    allSettings: {
      BigQuery: BigQueryFormSettings,
      S3: S3FormSettings,
      GCS: GcsFormSettings,
      Snowflake: SnowflakeFormSettings,
    },
  };
}

async function getDisSettings() {
  const {
    data: {
      data: { settingsMetadata: disSettingsMetaData },
    },
  } = await httpService.fetch(ETL_METADATA_URL + AdapterTypeOptions.DIS);
  const {
    data: {
      data: { settings: disSettings },
    },
  } = await httpService.fetch<ReportingEtlConfigurationResponse>(ETL_SETTINGS_URL + AdapterTypeOptions.DIS);

  const disFormSettings = adjustSettingsToForm(disSettings);

  return {
    settings: disFormSettings,
    settingsMetadata: disSettingsMetaData,
    allSettings: {
      DIS: disFormSettings,
    },
  };
}
export async function updateConfiguration(
  configurationToUpdate: BigidFormValues,
  adapterType: AdapterTypeOptions,
): Promise<ReportingEtlConfiguration> {
  try {
    const { _id, updated_at, site_id, select_type, schedule, ...configuration } = configurationToUpdate;
    const {
      data: {
        data: { settings },
      },
    } = await httpService.put<ReportingEtlConfigurationResponse>('reporting-etl/settings', {
      ...configuration,
      adapterType,
      load_bulk_size: DEFAULT_BULK_SIZE,
      schedule: { schedulerObject: schedule, numberOfRuns: 0 },
    });
    notificationService.success(tNotifications('successSave'));
    analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_CONFIGURATION_SAVE_SUCCESS, {
      adapterType,
    });
    return settings;
  } catch (e) {
    handleError(e);
    analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_CONFIGURATION_SAVE_FAIL, {
      adapterType,
    });
  }
}

export async function executeEtl(isDataInsightsStudio: boolean) {
  try {
    notificationService.success(tNotifications('startExecution'));
    const {
      data: {
        data: { globalEtlRunId, isExecuted, message },
      },
    } = await httpService.post<ReportingEtlExecuteResponse>('reporting-etl/manager/execute', {
      isDataInsightsStudio: `${isDataInsightsStudio}`,
    });

    if (isExecuted) {
      notificationService.success(`${tNotifications('successExecution')} ${globalEtlRunId}.`);
      analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_SUCCESSFUL_EXECUTION, {
        globalEtlRunId,
      });
    } else {
      notificationService.warning(`${tNotifications('errorExecution')} ${message}.`);
      analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_FAILED_EXECUTION, {
        globalEtlRunId,
      });
    }
  } catch (e) {
    handleError(e);
  }
}

export async function stopRunningETL() {
  try {
    const {
      data: {
        data: { globalEtlRunIds, isStopped, message },
      },
    } = await httpService.post<ReportingEtlStoppedResponse>('reporting-etl/manager/stop');

    if (isStopped) {
      notificationService.success(`${tNotifications('stopSuccess')} ${globalEtlRunIds.join(',')}.`);
      analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_SUCCESSFUL_ETL_STOP, {
        globalEtlRunIds,
      });
    } else {
      notificationService.warning(`${tNotifications('stopFail')} ${message}.`);
      analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_FAILED_ETL_STOP, {
        globalEtlRunIds,
      });
    }
  } catch (e) {
    handleError(e);
  }
}

export async function isETLProcessRunning(): Promise<{
  runningAdapters: RunningAdapters;
  currentActiveAdapter: AdapterTypeOptions;
}> {
  let runningAdapters = {};
  let currentActiveAdapter = AdapterTypeOptions.BigQuery;
  try {
    const {
      data: {
        data: { allRunningAdapters, currentActiveAdapter: currentActiveAdapterFromDB },
      },
    } = await httpService.fetch<ReportingEtlProgressResponse>('reporting-etl/manager/status');
    runningAdapters = allRunningAdapters;
    currentActiveAdapter = currentActiveAdapterFromDB;
  } catch (e) {
    handleError(e);
  }
  return { runningAdapters, currentActiveAdapter };
}

export function normalizeFormValues(
  values: BigidFormValues,
  configurationsMetadata: ReportingEtlConfigurationFormField[],
  selectedType: AdapterTypeOptions,
): ReportingEtlFormValues {
  return Object.entries(values).reduce((result, [fieldName, configuration]) => {
    const fieldMetadata = configurationsMetadata.find(
      metadataName => metadataName.name === fieldName && metadataName.adapterType === selectedType,
    );

    if (!fieldMetadata) {
      return result;
    }

    switch (fieldMetadata.type) {
      case BigidFormFieldTypes.TEXT:
        result[fieldName] = configuration.trim();
        break;
      case BigidFormFieldTypes.SCHEDULE_PICKER:
        result[fieldName] = convertRecurringScheduleObjectToCron(configuration);
        break;
      case BigidFormFieldTypes.NUMBER:
        result[fieldName] = Number(configuration);
        break;
      case BigidFormFieldTypes.SELECT:
        const selectOption = configuration as BigidSelectOption[];
        result[fieldName] = selectOption?.[0]?.value || '';
        break;
      default:
        result[fieldName] = configuration;
    }

    return result;
  }, {} as any);
}

function handleError(
  e: AxiosError<{
    message: string;
    errors: { message: string }[];
  }>,
) {
  const {
    response: { data: { message, errors } = {} },
  } = e;
  const errMessage = errors?.map(({ message }) => message).join(',') || message || 'See logs for more information.';
  notificationService.error(`${tNotifications('errorInOperation')} ${errMessage}`);
  console.error(e);
}

export function generateConfigurationFields(
  configurationsMetadata: ReportingEtlConfigurationFormField[],
  selectedType: AdapterTypeOptions = AdapterTypeOptions.BigQuery,
  fields: BigidFormField[],
  S3AuthMethod: S3AuthenticationMethod,
) {
  return configurationsMetadata
    .filter(currentField => fields.some(field => field.name === currentField.name))
    .reduce(
      (result, current) => {
        if (current.adapterType === selectedType) {
          switch (current.fieldset) {
            case ReportingEtlFieldSet.ADVANCED:
              result.advancedFields.push(current);
              break;
            case ReportingEtlFieldSet.TOP:
              result.topFields.push(current);
              break;
            case ReportingEtlFieldSet.CONNECTIVITY:
              if (handleS3AuthFields(current.name, S3AuthMethod)) {
                result.connectivityFields.push(current);
              }
              break;
            default:
              result.schedulerFields.push(current);
              break;
          }
        }
        return result;
      },
      {
        topFields: [],
        connectivityFields: [],
        advancedFields: [],
        schedulerFields: [],
      },
    );
}

export function shouldShowErrorsOnGroupedFields(
  errors: Record<string, string | boolean>,
  wasSubmitted: boolean,
  connectivityFields: ReportingEtlConfigurationFormField[],
  advancedFields: ReportingEtlConfigurationFormField[],
) {
  const result = {
    isErrorsInConnectivityFields: false,
    isErrorsInAdvancedFields: false,
  };
  if (!wasSubmitted) {
    return result;
  }
  const connectivityFieldsName = connectivityFields.map(({ name }) => name) as string[];
  const advancedFieldsName = advancedFields.map(({ name }) => name) as string[];
  return {
    isErrorsInConnectivityFields: connectivityFields.some(
      ({ name }) => connectivityFieldsName.includes(name) && errors[name],
    ),
    isErrorsInAdvancedFields: advancedFields.some(({ name }) => advancedFieldsName.includes(name) && errors[name]),
  };
}

export function adjustSettingsToForm(settings: ReportingEtlConfiguration): ReportingEtlFormValues {
  const { sensitive_values_hash_algo, schedule, adapterType, authentication_method, file_type } = settings;
  const recurringScheduleObject = convertCronToRecurringScheduleObject({
    ...schedule.schedulerObject,
    startOn: new Date(schedule.schedulerObject.startOn),
  });
  return {
    ...settings,
    sensitive_values_hash_algo: [
      {
        label: sensitive_values_hash_algo.toUpperCase(),
        value: sensitive_values_hash_algo,
      },
    ],
    select_type: [{ label: AdaptersLabels[adapterType], value: adapterType }],
    schedule: recurringScheduleObject,
    authentication_method: [
      {
        label: S3AuthenticationMethodLabels[authentication_method],
        value: authentication_method,
      },
    ],
    file_type: [
      {
        label: S3FileTypesLabels[file_type],
        value: file_type,
      },
    ],
  };
}

async function fetchAllSettingsOrMetadata(URL: string) {
  const [
    {
      data: { data: S3Data },
    },
    {
      data: { data: BigQueryData },
    },
    {
      data: { data: GcsSettings },
    },
    {
      data: { data: DisSettings },
    },
    {
      data: { data: SnowflakeSettings },
    },
  ] = await Promise.all(Object.values(AdapterTypeOptions).map(adapterType => httpService.fetch(URL + adapterType)));
  return [S3Data, BigQueryData, GcsSettings, DisSettings, SnowflakeSettings];
}

async function fetchAllDataPipelineSettingsOrMetadata(URL: string) {
  const [
    {
      data: { data: S3Data },
    },
    {
      data: { data: BigQueryData },
    },
    {
      data: { data: GcsSettings },
    },
    {
      data: { data: SnowflakeSettings },
    },
  ] = await Promise.all(
    Object.values(AdapterTypeOptions)
      .filter(adapterType => adapterType !== AdapterTypeOptions.DIS)
      .map(adapterType => httpService.fetch(URL + adapterType)),
  );
  return [S3Data, BigQueryData, GcsSettings, SnowflakeSettings];
}

export function isConfigurationEqual(configuration: BigidFormValues, values: BigidFormValues): boolean {
  const fieldsToOmit = difference(Object.keys(values), Object.keys(configuration));
  return isEqual(omit(configuration, fieldsToOmit), omit(values, fieldsToOmit));
}

export const handleTestConnection = (formControls?: MutableRefObject<BigidFormStateAndHandlers>) => async () => {
  let adapterType;
  try {
    const formValues = formControls.current.getValues() as ReportingEtlFormValues;
    adapterType = formValues.adapterType;
    await formControls.current.validate();
    const configurationToTest = arrangeConfigurationToTest(formValues);
    const {
      data: {
        data: { success, error },
      },
    } = await httpService.post<
      {
        data: TestConnectionResponse;
      },
      Partial<ReportingEtlFormValues>
    >(ETL_TEST_CONNECTION_URL, configurationToTest);
    if (!success) {
      throw new Error(error);
    }
    notificationService.success(tNotifications('testConnectionSuccess'));
    analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_TEST_CONNECTION_SUCCESS, {
      adapterType,
    });
  } catch (e) {
    if (!e?.message.includes(CODE_400)) {
      notificationService.error(`${tNotifications('testConnectionError')} ${e}`);
      analyticsService.trackManualEvent(ReportingEtlTrackingEvents.REPORTING_ETL_TEST_CONNECTION_FAILS, {
        adapterType,
      });
      console.error(e);
    }
  }
};

const arrangeConfigurationToTest = (formValues: ReportingEtlFormValues): Partial<ReportingEtlFormValues> => {
  const { adapterType } = formValues;
  const fieldsToPick = FIELDS_TO_PICK_FOR_TEST_CONNECTION[adapterType];
  const configurationToTest = pick(formValues, fieldsToPick);
  if (adapterType === AdapterTypeOptions.S3) {
    configurationToTest.authentication_method = configurationToTest.authentication_method?.[0]?.value || '';
  }
  return configurationToTest;
};

export const shouldDisableAdapterFields = ({
  fieldName,
  isDisLicensed,
  selectedAdapter,
  isDataPipelineLicense = true,
  isLoading,
}: ShouldDisableFieldsByLicense): boolean => {
  const shouldDisable = !isPermitted(REPORTS_PERMISSIONS.MANAGE_REPORTING_ETL.name) || isLoading;
  if (fieldName === SELECT_TYPE_FIELD) return shouldDisable;
  return (
    (!isDisLicensed && selectedAdapter === AdapterTypeOptions.DIS) ||
    (!isDataPipelineLicense && selectedAdapter !== AdapterTypeOptions.DIS)
  );
};

export async function getLookerUrl(): Promise<string> {
  let lookerUrl = '';
  try {
    const {
      data: {
        data: { lookerUrl: lookerUrlResponse },
      },
    } = await httpService.fetch('reporting-etl/customer-boarding/looker-url');
    lookerUrl = lookerUrlResponse;
  } catch (e) {
    handleError(e);
  }

  return lookerUrl;
}

export const getEtlStatusForIcon = (status: string): Statuses => {
  if (status === ETLStatusMode.IN_PROGRESS || status === ETLStatusMode.CALCULATING) {
    return undefined;
  } else if (status === ETLStatusMode.FAILED) {
    return 'failed';
  } else if (status === ETLStatusMode.CANCELLED || status === ETLStatusMode.STOPPED) {
    return 'warning';
  }
  return 'success';
};

export const getFinalEtlRunStatus = (rows: ReportingEtlMonitoringModel[]): ETLStatusMode => {
  const statusCounts = rows.reduce(
    (counts, row) => {
      const status = row.status as keyof StatusCounts;
      counts[status] = (counts[status] || 0) + 1;
      return counts;
    },
    { COMPLETED: 0, FAILED: 0, IN_PROGRESS: 0, STOPPED: 0, CALCULATING: 0 },
  );
  switch (true) {
    case statusCounts.FAILED > 0:
      return ETLStatusMode.FAILED;
    case statusCounts.STOPPED > 0:
      return ETLStatusMode.CANCELLED;
    case statusCounts.IN_PROGRESS > 0:
      return ETLStatusMode.IN_PROGRESS;
    case statusCounts.CALCULATING > 0:
      return ETLStatusMode.CALCULATING;
    default:
      return ETLStatusMode.COMPLETED;
  }
};

export const handleS3AuthFields = (
  fieldName: keyof ReportingEtlConfiguration,
  S3AuthMethod: S3AuthenticationMethod,
): boolean => {
  if (
    (fieldName === 'role_arn' || fieldName === 'role_session_name') &&
    (S3AuthMethod === S3AuthenticationMethod.Credential || S3AuthMethod === S3AuthenticationMethod.IAM)
  ) {
    return false;
  } else if (
    (fieldName === 'access_key' || fieldName === 'credentials_enc') &&
    (S3AuthMethod === S3AuthenticationMethod.Role || S3AuthMethod === S3AuthenticationMethod.IAM)
  ) {
    return false;
  } else return true;
};

export const stringValidator = (value: string, required: boolean, displayName: string): boolean | string => {
  const valueToValidate = typeof value === 'string' ? value.trim() : value;
  if (required && isEmpty(valueToValidate)) {
    return `${displayName} is required`;
  }
  return false;
};

export const isEtlLicensed = (isDataInsightsStudio: boolean): boolean => {
  const etlLicenses = getEtlLicenses();
  if (isDataInsightsStudio && etlLicenses.isDisLicense) {
    return true;
  }
  return !isDataInsightsStudio && etlLicenses.isDataPipeLineLicense;
};

export const sendEmail = async (email: string, isDataInsightsStudio: boolean) => {
  const userEmail = sessionStorageService.get('userEmail') as string;
  const etlApp = isDataInsightsStudio ? DATA_INSIGHTS_STUDIO : DATA_PIPELINE;
  const mailOptions = {
    to: email,
    subject: 'Activate BigID Data Insights Studio License',
    text: `Hi,\n ${userEmail} requested to activate BigID ${etlApp} app in BigID. For information on how to add an app license to BigID, please contact your BigID representative.`,
  };
  await httpService.post('mailers/sendMail', mailOptions);
};

export const isSelectedAdapterRunning = (
  runningAdapters: RunningAdapters,
  selectedAdapter: AdapterTypeOptions,
): boolean => {
  if (getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE')) {
    return runningAdapters[selectedAdapter];
  }
  return Object.keys(runningAdapters).length > 0;
};

export const getEtlLicenses = (): EtlLicenses => ({
  isDisLicense: appsLicenseService.isAppLicenseRegistered(DATA_INSIGHTS_STUDIO),
  isDataPipeLineLicense: appsLicenseService.isAppLicenseRegistered(DATA_PIPELINE),
});

export const getLicenseWarningMessage = (selectedAdapter: BigidSelectOption): string => {
  const etlLicenses = getEtlLicenses();
  const isDIsLicenseMissing = !etlLicenses.isDisLicense && selectedAdapter?.value === AdapterTypeOptions.DIS;
  return isDIsLicenseMissing
    ? `${DATA_INSIGHTS_STUDIO} ${tConfigurationDialog('licenseAlert')}`
    : `${DATA_PIPELINE} ${tConfigurationDialog('licenseAlert')}`;
};

export const getEtlTitle = (): TitleObject => {
  if (!getApplicationPreference('ENABLE_NEW_ETL_LANDING_PAGE')) {
    return {
      pageTitle: tTitle('dataInsightsStudioTitle'),
    };
  }

  if ($state.params.isDataInsightsStudio) {
    return {
      breadcrumbs: [
        {
          label: tTitle('dataInsightsStudioTitle'),
          onClick: () => $state.reload(),
        },
        { label: 'Manage' },
      ],
    };
  }

  return {
    pageTitle: tTitle('dataPipeLineTitle'),
  };
};

export async function getDisOnBoardingStatus(): Promise<OnBoardingStatus> {
  let onBoardingStatus = OnBoardingStatus.FAILED;
  try {
    const {
      data: {
        data: { onBoardingStatus: onBoardingStatusResponse },
      },
    } = await httpService.fetch('reporting-etl/customer-boarding/on-boarding-status');
    onBoardingStatus = onBoardingStatusResponse as OnBoardingStatus;
  } catch (e) {
    handleError(e);
  }

  return onBoardingStatus;
}
