import React, { FC } from 'react';
import { BigidHeading6 } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { SubScansInfo } from './ScanInsightTypes';
import { DataSnippet, DataSnippetType } from './ScanInsightsInfo/DataSnippet';
import styled from '@emotion/styled';

const SubScanTitle = styled(BigidHeading6)`
  font-size: 16px;
`;

const useStyles = makeStyles({
  subscanInfo: {
    display: 'flex',
    margin: '30px 24px 0 24px',
    gap: '140px',
  },
  subscanInfoTitle: {
    marginTop: '25px',
    marginLeft: '24px',
  },
  subscanInfoContent: {
    alignContent: 'center',
    alignSelf: 'center',
    display: 'flex',
    width: '100%',
    padding: '10px 10px 8px 10px',
    flexDirection: 'column',
    gap: '8px',
  },
  item: {
    width: '100%',
  },
});

export const SubScanInfo: FC<SubScansInfo> = ({
  completedSubScans = 0,
  failedSubScans = 0,
  totalSubScans = 0,
  completedParts = 0,
  failedParts = 0,
  totalPartsScanned = 0,
}) => {
  const subScanInfo: DataSnippetType[] = [
    { title: 'Total Data Source Scans', data: totalSubScans },
    { title: 'Completed Data Source Scans', data: completedSubScans },
    { title: 'Failed Data Source Scans', data: failedSubScans, color: 'error' },
  ];

  const classes = useStyles();
  return (
    <>
      <div className={classes.subscanInfoTitle}>
        <SubScanTitle>Sub Scans</SubScanTitle>
      </div>
      <div data-aid={'subScanInfo'} className={classes.subscanInfo}>
        <DataSnippet dataSnippets={subScanInfo} />
      </div>
    </>
  );
};
