import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  AsyncOperationMap,
  AsyncOperationProcessingWidget,
} from '../../../../../components/AsyncOperationProcessingWidget/AsyncOperationProcessingWidget';
import { notificationService } from '../../../../../services/notificationService';
import { useLocalTranslation } from '../../../translations';
import { useTagBulkAssignment } from './operations/TagBulkAssignment';
import { useTagBulkUnassignment } from './operations/TagBulkUnassignment';

export const ProcessingIndicator: FC = () => {
  const [runningOperationsMap, setRunningOperationsMap] = useState<AsyncOperationMap>(new Map());
  const { t } = useLocalTranslation('operations');
  const onOperationRun = useCallback((sseRoute: string, isOperationRan: boolean) => {
    setRunningOperationsMap(prevRunningOperationsMap => {
      return prevRunningOperationsMap.set(sseRoute, isOperationRan);
    });
  }, []);

  const onTagAssignmentOperationCompleted = useCallback(() => {
    notificationService.success(t('tags.assign.completed'));
  }, [t]);

  const onTagUnAssignmentOperationCompleted = useCallback(() => {
    notificationService.success(t('tags.unassign.completed'));
  }, [t]);

  const { operations: tagsBulkOperations } = useTagBulkAssignment({
    onOperationRun,
    onOperationCompleted: onTagAssignmentOperationCompleted,
  });
  const { operations: rulesBulkApplyingOperations } = useTagBulkUnassignment({
    onOperationRun,
    onOperationCompleted: onTagUnAssignmentOperationCompleted,
  });

  const operations = useMemo(() => {
    return [...tagsBulkOperations, ...rulesBulkApplyingOperations];
  }, [tagsBulkOperations, rulesBulkApplyingOperations]);

  return <AsyncOperationProcessingWidget operations={operations} operationsMap={runningOperationsMap} />;
};
