import React, { useCallback, useEffect, useState } from 'react';
import {
  BigidCheckbox,
  BigidColorsV2,
  BigidFancyTabs,
  BigidSelect,
  BigidSelectFieldState,
  BigidSelectOption,
  BigidTooltip,
} from '@bigid-ui/components';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { notificationService } from '../../../../services/notificationService';
import { getDictionaries } from '../../dsarService';
import { DsarDictionary } from '../../DsarTypes';
import { UploadDictionary, useDictionary } from '../../DsarDictionary/DsarEditDictionaryDialog';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';

const useEditDictionaryStyles = makeStyles((theme: Theme) => ({
  dsWrapper: {
    width: '100%',
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: '6px',
    backgroundColor: BigidColorsV2.gray[150],
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  error: {
    color: BigidColorsV2.red[600],
    marginTop: '5px',
  },
  tooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const multipleValueLabel = 'Multiple Values';

export const useEditDictionary = (selectedFieldsDictionaries: BigidSelectOption[], shouldResetState: boolean) => {
  const classes = useEditDictionaryStyles({});
  const [isChecked, setChecked] = useState(false);
  const [isIndeterminate, setIndeterminate] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [dictionaries, setDictionaries] = useState<DsarDictionary[] | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<BigidSelectOption[]>([]);
  const [isSelectDirty, setSelectDirty] = useState(false);
  const { dictionary, file, errors, handleOnError, handleResetState, handleSetDictionary, handleSetFile, callApi } =
    useDictionary(null, true);
  const skipApiCall =
    !isChecked ||
    (selectedTab === 0 && selectedOptions?.[0]?.label === multipleValueLabel) ||
    (selectedTab === 0 && !isSelectDirty);

  useEffect(() => {
    const selectedFieldsWithValues = selectedFieldsDictionaries.filter(field => !!field.value);
    const atLeastOneFieldWithoutDictionary = !!selectedFieldsDictionaries.find(field => !field.value);
    const selectedDictionaryMapper = [...new Set(selectedFieldsWithValues.map(field => field.value))];

    const allSelectedHaveDictionary = selectedFieldsDictionaries.length === selectedFieldsWithValues.length;
    const sameDictionaryValueOnly = selectedDictionaryMapper.length === 1;
    const multipleDictionaryValues = selectedDictionaryMapper.length > 1;
    const atLeastOneWithDictionary = !!selectedFieldsWithValues.length;
    const multipleValuesOption = [{ label: multipleValueLabel, value: undefined }] as BigidSelectOption[];

    // one or more rows with dictionary are selected with same dictionary
    if (allSelectedHaveDictionary && sameDictionaryValueOnly) {
      setSelectedOptions(selectedFieldsWithValues.slice(0, 1));
      setChecked(true);
      setIndeterminate(false);
      return;
    }

    // more than one rows are selected with different dictionary
    if (allSelectedHaveDictionary && multipleDictionaryValues) {
      setSelectedOptions(multipleValuesOption);
      setChecked(true);
      setIndeterminate(false);
      return;
    }

    // more than one rows are selected with at least one dictionary
    if (!allSelectedHaveDictionary && atLeastOneFieldWithoutDictionary && atLeastOneWithDictionary) {
      setSelectedOptions(multipleValuesOption);
      setChecked(true);
      setIndeterminate(true);
    }
  }, [selectedFieldsDictionaries]);

  const onCheck = useCallback((isChecked: boolean) => {
    setChecked(isChecked);
  }, []);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
      handleResetState();
    },
    [handleResetState],
  );

  const resetState = useCallback(() => {
    setChecked(false);
    setIndeterminate(false);
    setSelectedOptions([]);
    setSelectedTab(0);
    handleResetState();
    setSelectDirty(false);
  }, [handleResetState]);

  useEffect(() => {
    if (shouldResetState) {
      resetState();
    }
  }, [shouldResetState, resetState]);

  useEffect(() => {
    const [selectedOption] = selectedOptions;
    if (!selectedOption || selectedTab !== 0) {
      return;
    }
    handleSetDictionary({ name: selectedOption.label, id: selectedOption.value } as DsarDictionary);
  }, [selectedTab, selectedOptions, handleSetDictionary]);

  const isInvalid = (field: string) => errors.find(error => error.name === field);

  const fetchDictionaries = useCallback(async () => {
    if (dictionaries) {
      return dictionaries;
    }

    try {
      const response = await getDictionaries({ skip: 0, limit: 10000 });
      setDictionaries(response.objects);
      return response.objects;
    } catch (err) {
      const errorMessage = 'Failed to fetch Dictionaries';
      notificationService.error(errorMessage);
      console.error(`${errorMessage}: ${JSON.stringify(err)}`);
      return [];
    }
  }, [dictionaries]);

  const uploadDictionary = useCallback(async (): Promise<{
    id: string;
  } | null> => {
    if (skipApiCall) {
      return Promise.resolve(null);
    }

    if (dictionary?.id) {
      return Promise.resolve({ id: dictionary.id });
    }

    return callApi(() => {
      setDictionaries(null);
    }).catch(err => {
      handleOnError(err);
      throw err;
    });
  }, [skipApiCall, callApi, dictionary?.id, handleOnError]);

  return {
    isChecked,
    selectedTab,
    onCheck,
    onTabChange,
    resetState,
    uploadDictionary,
    checkbox: (
      <BigidCheckbox
        label={
          <BigidTooltip title="Replace original field value with the referenced value from a dictionary">
            <div className={classes.tooltipWrapper}>
              {'Use referenced value '}
              <BigidHelpIcon />
            </div>
          </BigidTooltip>
        }
        checked={isChecked}
        indeterminate={isChecked && isIndeterminate}
        onChange={(_, isChecked) => {
          onCheck(isChecked);
        }}
      />
    ),
    tabs: isChecked && (
      <div className={classes.dsWrapper}>
        <BigidFancyTabs
          fullWidth
          onChange={tab => {
            onTabChange(tab);
          }}
          selectedIndex={selectedTab}
          size="medium"
          tabs={[
            {
              label: 'Select a dictionary',
            },
            {
              label: 'Upload a new dictionary',
              disabled: !isPermitted(DSAR_PERMISSIONS.CREATE_DICTIONARY.name),
            },
          ]}
        />
        {selectedTab === 0 && (
          <div>
            <p className={classes.title}>{'Replace original value with dictionary value'}</p>
            <BigidSelect
              name="dictionary"
              options={dictionaries?.map(dict => ({ value: dict.name, label: dict.name }))}
              onChange={value => {
                const dictionary = dictionaries.find(dict => dict.name === value[0].value);
                handleSetDictionary(dictionary);
                setSelectedOptions([{ label: dictionary.name, value: dictionary.id }]);
                setSelectDirty(true);
              }}
              value={selectedOptions}
              placeholder="Select dictionary..."
              menuPosition="fixed"
              noOptionsMessage="No dictionaries."
              size="large"
              loadOptions={() =>
                fetchDictionaries().then(dictionaries =>
                  dictionaries.map(dict => ({ value: dict.name, label: dict.name, isSelected: false })),
                )
              }
              fieldState={isInvalid('name') ? BigidSelectFieldState.error : BigidSelectFieldState.normal}
              shouldLoadInitialOptions
              isAsync
              isSearchable
            />
            {isInvalid('name') && <p className={classes.error}>{'Select dictionary.'}</p>}
          </div>
        )}
        {selectedTab === 1 && isPermitted(DSAR_PERMISSIONS.CREATE_DICTIONARY.name) && (
          <div>
            <UploadDictionary
              dictionary={dictionary}
              file={file}
              errors={errors}
              onSetDictionary={handleSetDictionary}
              onSetFile={handleSetFile}
            />
          </div>
        )}
      </div>
    ),
  };
};
