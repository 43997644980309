import React, { FC, useContext } from 'react';
import BigidScanInProgress from '../../../../../assets/icons/BigidScanInProgress.svg';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColors, BigidBody1, BigidHeading3, SecondaryButton, BigidLink } from '@bigid-ui/components';
import { DataSourceConfigurationContext } from '../../DataSourceConfigurationContext';
import { isPermitted } from '../../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS, CATALOG_PERMISSIONS } from '@bigid/permissions';
import { $state } from '../../../../../services/angularServices';

export const useStyles = makeStyles({
  wrapper: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '570px',
  },
  labelWrapper: {
    color: BigidColors.green[400],
    margin: '16px',
  },
  buttonTextWrapper: {
    maxHeight: '18px',
    lineHeight: '18px',
  },
});

export interface ScanResultContentProps {
  scanStartDate: string;
  endDate: string;
}

export interface ScanInProgressContentProps {
  stopScan: () => void;
}

export const ScanInProgressContent: FC<ScanInProgressContentProps> = ({ stopScan }) => {
  const { scanStartDate } = useContext(DataSourceConfigurationContext);
  const { labelWrapper, wrapper, buttonTextWrapper } = useStyles({});
  return (
    <div data-aid="ScanInProgressContent" className={wrapper}>
      <BigidScanInProgress />
      <BigidHeading3 data-aid="ScanInProgressContentLabel" className={labelWrapper}>
        Scan in Progress…
      </BigidHeading3>
      <BigidBody1 data-aid="ScanInProgressContentData">{scanStartDate}</BigidBody1>
      {isPermitted(SCANS_PERMISSIONS.EDIT_SCAN_ACTIVITY.name) && (
        <SecondaryButton
          dataAid="ScanInProgressContentStopButton"
          onClick={stopScan}
          size="large"
          margin="16px"
          text="Stop Scan"
        />
      )}
      {isPermitted(CATALOG_PERMISSIONS.ACCESS.name) && (
        <BigidLink onClick={() => $state.go(`dataCatalog`)} text={'View Catalog'} />
      )}
    </div>
  );
};
