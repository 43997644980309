import React, { FC, PropsWithChildren } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidColorsV2,
  BigidHeading4,
  BigidHeading6,
  TertiaryButton,
  BigidTooltip,
} from '@bigid-ui/components';
import {
  AccordionInfoPanelStyled,
  accordionDetailsStyle,
  AccordionInfoPanelItemTitle,
  AccordionInfoTitleEndItem,
  accordionCommonStyle,
  accordionSummary,
  accordionStyle,
  AccordionInfoPanelVariants,
  mapVariantToStyle,
} from './styles';

export interface AccordionInfoPanelProps extends PropsWithChildren {
  dataAid: string;
  title: string;
  titleIcon?: React.FC<any>;
  titleIconColor?: string;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, expanded: boolean) => void;
  variant: AccordionInfoPanelVariants;
  endLinkTitle?: string;
  endLinkOnClick?: () => void;
  endLinkIcon?: React.FC<any>;
}

export const AccordionInfoPanel: FC<AccordionInfoPanelProps> = ({
  title,
  titleIconColor,
  expanded,
  onChange,
  titleIcon: TitleIcon,
  children,
  variant,
  endLinkTitle,
  endLinkOnClick,
  endLinkIcon: EndLinkIcon,
  dataAid,
}) => {
  const style = mapVariantToStyle[variant];
  return (
    <AccordionInfoPanelStyled showExpandIcon={!expanded} data-aid={dataAid}>
      <BigidAccordion
        dataAid={'AutoDiscoverAsidePermissionsSummary'}
        sx={{ ...accordionCommonStyle, ...accordionStyle, ...style }}
        onChange={onChange}
        expanded={expanded}
      >
        <BigidAccordionSummary sx={{ ...accordionSummary, ...style }} size={AccordionSummarySizeEnum.large}>
          <BigidHeading6 sx={{ width: '100%' }}>
            <AccordionInfoPanelItemTitle>
              <TitleIcon
                size={'large'}
                {...(titleIconColor ? { color: titleIconColor } : {})}
                style={{ padding: '3px' }}
              />
              <BigidTooltip title={title}>
                <BigidHeading4>{title}</BigidHeading4>
              </BigidTooltip>
              {endLinkTitle && (
                <AccordionInfoTitleEndItem>
                  <TertiaryButton
                    size={'medium'}
                    onClick={endLinkOnClick}
                    endIcon={EndLinkIcon && <EndLinkIcon color={BigidColorsV2.blue[600]} />}
                    text={endLinkTitle}
                  />
                </AccordionInfoTitleEndItem>
              )}
            </AccordionInfoPanelItemTitle>
          </BigidHeading6>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={{ ...accordionCommonStyle, ...accordionDetailsStyle, ...style }}>
          {children}
        </BigidAccordionDetails>
      </BigidAccordion>
    </AccordionInfoPanelStyled>
  );
};
