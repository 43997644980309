import { httpService } from '../../../services/httpService';
import { notificationService } from '../../../services/notificationService';
import {
  ScanChildInfo,
  ScanInsightsData,
  SubScanGridRow,
  GetDataSourcesDetailsByParentIdResponse,
  GetAttributesDetailsByParentIdResponse,
  GetAttributesDetailsByParentIdParams,
} from './ScanInsightTypes';

type ChildScanResponse = {
  scanChildren: SubScanGridRow[];
  totalCount?: number;
};

export async function fetchScanInsightsGridData(gridConfigQuery: string, scanId: string): Promise<ChildScanResponse> {
  const {
    data: {
      data: { scanChildren = [], totalCount = 0 },
    },
  } = await httpService.fetch<{ data: ChildScanResponse }>(`scans/child-scans/${scanId}?${gridConfigQuery}`);

  return {
    scanChildren,
    totalCount,
  };
}

export async function fetchScanInsightsData(scanId: string) {
  const {
    data: {
      data: { scanInsights },
    },
  } = await httpService.fetch<{ data: { scanInsights: ScanInsightsData } }>(`scans/insights/parent-scans/${scanId}`);

  return scanInsights;
}

export async function fetchScanChildInfo(childScanId: string) {
  const {
    data: {
      data: { scanChildInfo },
    },
  } = await httpService.fetch<{ data: { scanChildInfo: ScanChildInfo } }>(`scans/insights/child-scans/${childScanId}`);

  return scanChildInfo;
}

export async function getDataSourcesDetailsByParentId(parentId: string, gridConfigQuery = '') {
  try {
    const { data } = await httpService.fetch<{ data: GetDataSourcesDetailsByParentIdResponse }>(
      `scans/insights/parent-scans/${parentId}/data-sources?${gridConfigQuery}`,
    );
    return data.data;
  } catch ({ message }) {
    notificationService.error('An error has occurred fetching data sources details.');
    console.error(`An error has occurred: ${message}`);
    return {
      totalCount: 0,
      dataSourcesInsights: [],
    };
  }
}

export async function getAttributesDetailsByParentId({
  parentId,
  type,
  gridConfigQuery,
}: GetAttributesDetailsByParentIdParams) {
  try {
    const endpoint = `scans/insights/parent-scans/${parentId}/attributes?${gridConfigQuery}${
      type ? `&type=${type}` : ''
    }`;
    const { data } = await httpService.fetch<{ data: GetAttributesDetailsByParentIdResponse }>(endpoint);
    return data.data;
  } catch ({ message }) {
    notificationService.error('An error has occurred fetching attributes details.');
    console.error(`An error has occurred: ${message}`);
    return {
      totalCount: 0,
      dataSourcesInsights: [],
    };
  }
}
