import React, { FunctionComponent, useEffect, useState, useMemo, useCallback } from 'react';
import {
  BigidPaper,
  BigidIconSize,
  BigidConfidenceIndicator,
  BigidConfidenceLevel,
  BigidContentItem,
  entityEventsEmitter,
  EntityEvents,
  ToolbarAction,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridRow,
  BigidGridColumn,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  NextGridState,
} from '@bigid-ui/grid';
import { DataCatalogRecord, DataCatalogRecordScannerTypeGroup } from '../DataCatalogService';
import makeStyles from '@mui/styles/makeStyles';
import { capitalize } from 'lodash';
import {
  DataCatalogAttribute,
  AttributesGridColumn,
  getAttributesByObjectName,
  AssociatedColumn,
} from './DataCatalogAttributesService';
import { AttributeClearValue } from './AttributeClearValue';
import CachedValue from '../../../assets/icons/BigidCache.svg';
import classNames from 'classnames';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';
import { deleteManualFields, ManualFieldType } from '../DataCatalogColumns';
import {
  AttributeMappingDialog,
  AttributeMappingDialogProps,
} from '../DataCatalogColumns/modalDialogEditors/AttributeMappingDialog';
import { notificationService } from '../../../services/notificationService';
import {
  AttributesDeleteConfirmationDialog,
  AttributesDeleteConfirmationDialogProps,
} from './AttributesDeleteConfirmationDialog';
import { ConfidenceLevelExplanation } from '../../../components/ConfidenceLevelExplanation/ConfidenceLevelExplanation';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import {
  DataCatalogObjectDetails,
  getDetailsByObjectName,
  ObjectAttributeDetails,
} from '../DataCatalogDetails/DataCatalogDetailsService';
import { CatalogEventsEnum } from '../events';
import { analyticsService } from '../../../services/analyticsService';
import { getConfidenceLevelScore, isAttributeExpired } from './utils';
import { BigidExternalLinkIcon } from '@bigid-ui/icons';
import { useLocalTranslation } from '../translations';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { appsLicenseService } from '../../../services/appsLicenseService';
import { STEWARDSHIP_APP_NAME } from './constants';

const UNSTRUCTURED = DataCatalogRecordScannerTypeGroup.UNSTRUCTURED;
const EMAIL = DataCatalogRecordScannerTypeGroup.EMAIL;

const CONFIDENCE_LEVEL_COLUMN_NAME = 'confidenceLevel';
const COLUMNS_COLUMN_NAME = 'columns';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    height: '100%',
    padding: '5px',
  },
  gridWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  shrinkGrid: {
    width: 'calc(100% - 260px)',
    paddingRight: 16,
  },
  clearValueWrapper: {
    display: 'flex',
    width: 260,
  },
});

const excludedAttributeTypes = ['ClassificationMd', 'Classification', 'Manual', 'Enrichment Attribute'];

function getNumberOfFindings(attributesDetails: ObjectAttributeDetails[], attributeName: string): number {
  let numberOfFindings;
  const attributeDetails = attributesDetails?.find(({ name }) => name === attributeName);

  if (attributeDetails) {
    numberOfFindings = attributeDetails.count;
  }

  return numberOfFindings;
}

export const DataCatalogAttributes: FunctionComponent<DataCatalogRecord> = ({
  fullyQualifiedName,
  scanner_type_group,
  source,
  scannerType,
}: DataCatalogRecord) => {
  const classes = useStyles({});
  const { t } = useLocalTranslation('DataCatalogAttributes');

  const [selectedItem, setSelectedItem] = useState<BigidContentItem>(null);
  const [attributesData, setAttributesData] = useState<DataCatalogAttribute[]>([]);
  const [isClearValueWidgetEnabled, setIsClearValueWidgetEnabled] = useState<boolean>(false);
  const [displayGridColumn, setDisplayGridColumn] = useState<BigidGridColumn<AttributesGridColumn>[]>(null);
  const [onlyOneCheckboxIsSelected, setOnlyOneCheckboxIsSelected] = useState<boolean>(false);
  const isScannerTypeGroupEnabled = scanner_type_group === UNSTRUCTURED || scanner_type_group === EMAIL;

  const [attributeMappingDialogState, setAttributeMappingDialogState] = useState<AttributeMappingDialogProps>({
    fullyQualifiedName,
    columnName: undefined,
    isOpen: false,
    dialogTitle: 'Add Attribute',
    scannerType: '',
  });

  const [deleteConfirmationDialogState, setDeleteConfirmationDialogState] =
    useState<AttributesDeleteConfirmationDialogProps>({
      isOpen: false,
    });

  const { confidenceLevelExplainTooltipEnabled, clusteringEnabled } = useMemo(
    () => ({
      confidenceLevelExplainTooltipEnabled: getApplicationPreference('CONFIDENCE_LEVEL_EXPLAIN_TOOLTIP_ENABLED'),
      clusteringEnabled: getApplicationPreference('CLUSTERING_ENABLED'),
    }),
    [],
  );

  const { isFetchClearAttrValueAvailable, isDataCatalogAddUnstructuredAttributeEnabled, isAddAttributeEnabled } =
    useMemo(() => {
      const isDataCatalogAddUnstructuredAttributeEnabled = Boolean(
        getApplicationPreference('DATA_CATALOG_ADD_UNSTRUCTURED_ATTRIBUTE'),
      );

      return {
        isDataCatalogAddUnstructuredAttributeEnabled,
        isFetchClearAttrValueAvailable:
          Boolean(getApplicationPreference('FETCH_CLEAR_ATTR_VALUE_ENABLED')) &&
          isPermitted(CATALOG_PERMISSIONS.INVESTIGATE.name),
        isAddAttributeEnabled: isScannerTypeGroupEnabled && isDataCatalogAddUnstructuredAttributeEnabled,
      };
    }, [isScannerTypeGroupEnabled]);

  const closeEditAttributeMappingDialog = useCallback(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  const closeDeleteConfirmationDialog = useCallback(() => {
    setDeleteConfirmationDialogState({
      isOpen: false,
    });
  }, []);

  useEffect(() => {
    setAttributeMappingDialogState(prevState => ({
      ...prevState,
      fullyQualifiedName,
    }));
  }, [fullyQualifiedName]);

  useEffect(() => {
    const trackData = {
      fullyQualifiedName,
      scannerType: scanner_type_group,
      dsType: scannerType,
      dsName: source,
    };

    analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ATTRIBUTE_TAB, trackData);
  }, [fullyQualifiedName, scanner_type_group, scannerType, source]);

  useEffect(() => {
    if (!isScannerTypeGroupEnabled || !isDataCatalogAddUnstructuredAttributeEnabled) {
      setOnlyOneCheckboxIsSelected(true);
    }
  }, [isDataCatalogAddUnstructuredAttributeEnabled, isScannerTypeGroupEnabled]);

  const attributeMappingDialogConfig: AttributeMappingDialogProps = useMemo(
    () => ({
      ...attributeMappingDialogState,
      onClose: closeEditAttributeMappingDialog,
    }),
    [attributeMappingDialogState, closeEditAttributeMappingDialog],
  );

  const deleteConfirmationDialogConfig: AttributesDeleteConfirmationDialogProps = useMemo(
    () => ({
      ...deleteConfirmationDialogState,
      onClose: closeDeleteConfirmationDialog,
    }),
    [closeDeleteConfirmationDialog, deleteConfirmationDialogState],
  );

  const getGridData = useCallback(
    (data: DataCatalogAttribute[], objectDetails: DataCatalogObjectDetails): AttributesGridColumn[] => {
      return data.map((att: DataCatalogAttribute) => {
        const {
          attribute_id,
          attribute_original_name,
          attribute_type,
          attribute_original_type,
          attribute_name,
          column_list = [],
          categories = [],
          description,
          business_flow = [],
          is_support_investigation = false,
          investigation_scan_id_list = [],
        } = att;
        const { attribute_details } = objectDetails;

        const colsToDisplay = column_list
          .reduce((aggCol, col: AssociatedColumn) => {
            const { rank, calc_confidence_level, column_name } = col;
            const confidenceLevel = calc_confidence_level
              ? `(${rank}  ${(calc_confidence_level * 100).toFixed(0)}%)`
              : `(${rank})`;

            return aggCol.length > 0
              ? `${aggCol} ${column_name} ${confidenceLevel}, `
              : `${column_name} ${confidenceLevel}, `;
          }, '')
          .replace(/,\s*$/, '');

        return {
          id: attribute_id,
          attribute_name,
          attribute_original_name,
          attribute_type,
          attribute_original_type,
          columns: colsToDisplay,
          categories,
          description,
          purposes: business_flow.join(', '),
          is_support_investigation,
          investigation_scan_id_list,
          confidenceLevel: {
            chips: {
              value: column_list.map(({ column_name = '', calc_confidence_level, rank }) => {
                const confidenceLevelScore = getConfidenceLevelScore(calc_confidence_level, rank);
                const confidenceLevelIndication = capitalize(rank);
                const structuredLabel = confidenceLevelScore ? `${column_name} (${confidenceLevelScore})` : column_name;
                const shouldDisplayTooltip = confidenceLevelExplainTooltipEnabled && clusteringEnabled;
                const title = isScannerTypeGroupEnabled
                  ? confidenceLevelIndication
                  : `${column_name} (${confidenceLevelIndication})`;
                const label = isScannerTypeGroupEnabled ? confidenceLevelScore : structuredLabel;

                return {
                  id: attribute_id,
                  label,
                  icon: <BigidConfidenceIndicator level={rank.toLowerCase() as BigidConfidenceLevel} />,
                  title,
                  ...(shouldDisplayTooltip && {
                    tooltipProps:
                      !excludedAttributeTypes.includes(attribute_type) && calc_confidence_level !== undefined
                        ? {
                            width: '400px',
                            title: (
                              <ConfidenceLevelExplanation
                                item={{
                                  fullyQualifiedName,
                                  fieldName: column_name,
                                  attribute_type,
                                  attribute_name,
                                  confidence_level: calc_confidence_level,
                                }}
                              />
                            ),
                          }
                        : undefined,
                  }),
                };
              }),
              isDisabled: true,
            },
          },
          cachedValues:
            investigation_scan_id_list.length > 0
              ? {
                  icon: {
                    icon: CachedValue,
                    size: BigidIconSize.REGULAR,
                  },
                }
              : undefined,
          numberOfFindings: getNumberOfFindings(attribute_details, attribute_original_name),
        };
      });
    },
    [confidenceLevelExplainTooltipEnabled, clusteringEnabled, isScannerTypeGroupEnabled, fullyQualifiedName],
  );

  const gridColumns = useMemo((): BigidGridColumn<AttributesGridColumn>[] => {
    return [
      {
        name: 'attribute_name',
        title: 'Attribute',
        getCellValue: ({ attribute_name }) => attribute_name,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'numberOfFindings',
        title: '# of Findings',
        getCellValue: ({ numberOfFindings }) => numberOfFindings,
        type: BigidGridColumnTypes.NUMBER,
        sortingEnabled: false,
      },
      {
        name: CONFIDENCE_LEVEL_COLUMN_NAME,
        title: isScannerTypeGroupEnabled ? 'Confidence level' : 'Column names (confidence level)',
        getCellValue: ({ confidenceLevel }) => confidenceLevel,
        type: BigidGridColumnTypes.CHIPS,
        width: 350,
        sortingEnabled: false,
      },
      {
        name: COLUMNS_COLUMN_NAME,
        title: 'Column names',
        getCellValue: ({ columns }) => columns,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'categories',
        title: 'Categories',
        getCellValue: ({ categories }) => ({
          categories: {
            value: categories?.map(({ display_name, color }) => ({
              categoryName: display_name,
              categoryColor: color,
            })),
          },
        }),
        type: BigidGridColumnTypes.CATEGORIES,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'purposes',
        title: 'Purposes',
        getCellValue: ({ purposes }) => purposes,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'description',
        title: 'Description',
        getCellValue: ({ description }) => description,
        type: BigidGridColumnTypes.TEXT,
        isListColumn: true,
        sortingEnabled: false,
      },
      {
        name: 'cachedValues',
        title: 'Cached value',
        getCellValue: ({ cachedValues }) => cachedValues,
        type: BigidGridColumnTypes.ICON,
        isListColumn: true,
        isHiddenByDefault: !isFetchClearAttrValueAvailable,
        sortingEnabled: false,
      },
    ];
  }, [isFetchClearAttrValueAvailable, isScannerTypeGroupEnabled]);

  useEffect(() => {
    setIsClearValueWidgetEnabled(false);
    setSelectedItem(null);
  }, [fullyQualifiedName]);

  useMemo(() => {
    let filteredGridColumn = gridColumns;
    const displayConfidenceLevelColumn = getApplicationPreference('ATTR_CONFIDENCE_LEVEL_COLUMN_ENABLED');
    if (isScannerTypeGroupEnabled || displayConfidenceLevelColumn) {
      filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== COLUMNS_COLUMN_NAME);
    }

    if (!displayConfidenceLevelColumn) {
      filteredGridColumn = filteredGridColumn.filter(({ name }) => name !== CONFIDENCE_LEVEL_COLUMN_NAME);
    }
    setDisplayGridColumn(filteredGridColumn);
  }, [gridColumns, isScannerTypeGroupEnabled]);

  const handleGridRowClick = (row: BigidGridRow): void => {
    setSelectedItem(row as BigidContentItem);
    setIsClearValueWidgetEnabled(!!row && isFetchClearAttrValueAvailable);
  };

  const handleClearValueFetched = (): void => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  };

  const handleClearValueWidgetClose = (): void => {
    setIsClearValueWidgetEnabled(false);
    setSelectedItem(null);
  };

  const handleGridChange = useCallback(
    (state: NextGridState) => {
      const { selectedRowIds } = state;
      isScannerTypeGroupEnabled &&
        isDataCatalogAddUnstructuredAttributeEnabled &&
        setOnlyOneCheckboxIsSelected(selectedRowIds.length === 1 && !!selectedItem);
      if (selectedRowIds.length > 1) {
        setSelectedItem(null);
      }
    },
    [isDataCatalogAddUnstructuredAttributeEnabled, isScannerTypeGroupEnabled, selectedItem],
  );

  const actions: ToolbarAction[] = [];

  if (isAddAttributeEnabled) {
    const attributeActions: ToolbarAction[] = [
      {
        label: t('actions.addAttribute.label'),
        isGlobal: true,
        execute: async () => {
          return new Promise((resolve, reject) => {
            setAttributeMappingDialogState(prevState => {
              return {
                ...prevState,
                isOpen: true,
                onSubmit: () => {
                  closeEditAttributeMappingDialog();
                  resolve({ shouldGridReload: true, shouldClearSelection: true });
                },
                onClose: () => {
                  reject();
                },
              };
            });
          });
        },
        show: () =>
          isPermitted(CATALOG_PERMISSIONS.EDIT_MANUAL_FIELDS.name) &&
          isPermitted(CATALOG_PERMISSIONS.READ_MANUAL_FIELDS.name),
      },
      {
        label: t('actions.deleteAttribute.label'),
        isGlobal: false,
        execute: async params => {
          return new Promise((resolve, reject) => {
            setDeleteConfirmationDialogState({
              isOpen: true,
              onSubmit: () => {
                try {
                  closeDeleteConfirmationDialog();
                  const data = params.selectedRows.map(
                    ({ attribute_original_name, attribute_type, attribute_original_type }) => ({
                      fullyQualifiedName,
                      value: attribute_original_name,
                      attribute_type: attribute_original_type ?? attribute_type,
                      type: ManualFieldType.ATTRIBUTE,
                    }),
                  );

                  deleteManualFields(data);
                } catch ({ message }) {
                  console.error(`An error has occurred: ${message}`);
                  notificationService.error('An error has occurred');
                } finally {
                  resolve({ shouldGridReload: true, shouldClearSelection: true });
                }
              },
              onClose: () => {
                reject();
              },
            });
          });
        },
        show: params => params.selectedRowIds.length > 0,
      },
    ];
    actions.push(...attributeActions);
  }

  if (appsLicenseService.isAppLicenseRegistered(STEWARDSHIP_APP_NAME)) {
    actions.push({
      label: t('actions.viewInStewardship.label'),
      hideActionInToolBar: true,
      show: () => true,
      icon: BigidExternalLinkIcon,
      isInline: true,
      execute: async ({ selectedRowIds: [attribute_id] }) => {
        return new Promise((resolve, reject) => {
          const urlParams = new URLSearchParams();
          urlParams.set('entityType', 'business_attribute');
          urlParams.set('entityId', String(attribute_id));

          const params = {
            id: STEWARDSHIP_APP_NAME,
            queryParams: urlParams.toString(),
          };

          $state.go(CONFIG.states.CUSTOM_APP, params);
        });
      },
      isGlobal: false,
    });
  }

  const gridWithToolbarConfig: BigidGridWithToolbarProps<AttributesGridColumn> = {
    hideColumnChooser: true,
    columns: displayGridColumn,
    showSelectAll: isAddAttributeEnabled,
    displayActionToolbar: isAddAttributeEnabled,
    onRowClick: isFetchClearAttrValueAvailable && handleGridRowClick,
    fetchData: async () => {
      const { data: objectDetails } = await getDetailsByObjectName(fullyQualifiedName);
      const { data } = await getAttributesByObjectName(fullyQualifiedName);
      setAttributesData(data);
      const gridData = getGridData(data, objectDetails);

      const trackData = {
        fullyQualifiedName,
        dsType: scannerType,
        dsName: objectDetails.dataSourceName,
        attributeName: objectDetails.objectName,
        scannerType: objectDetails.scanner_type_group,
      };

      analyticsService.trackManualEvent(CatalogEventsEnum.CATALOG_ATTRIBUTE_TAB_FETCH_VALUES, trackData);

      return {
        totalCount: gridData.length,
        data: gridData,
      };
    },
    showSortingControls: true,
    onGridStateChange: handleGridChange,
    toolbarActions: actions,
  };

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.gridWrapper,
          isClearValueWidgetEnabled && onlyOneCheckboxIsSelected && classes.shrinkGrid,
        )}
      >
        <BigidPaper>
          <BigidGridWithToolbar key={fullyQualifiedName} {...gridWithToolbarConfig} />
        </BigidPaper>
        <AttributeMappingDialog {...attributeMappingDialogConfig} />
      </div>
      {isClearValueWidgetEnabled && onlyOneCheckboxIsSelected && (
        <div className={classes.clearValueWrapper} data-aid="AttributeClearValue">
          <BigidPaper>
            <AttributeClearValue
              fullyQualifiedName={fullyQualifiedName}
              selectedItem={selectedItem}
              onFetched={handleClearValueFetched}
              onClose={handleClearValueWidgetClose}
              dataAid={`AttributeClearValue-${selectedItem.id}`}
              isExpired={isAttributeExpired(attributesData, selectedItem)}
            />
          </BigidPaper>
        </div>
      )}
      <AttributesDeleteConfirmationDialog {...deleteConfirmationDialogConfig} />
    </div>
  );
};
