import React, { FC, useContext, useEffect, useState } from 'react';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { ObjectPreviewContext } from '../hooks/ObjectPreviewContext';
import { fetchObjectAttributes } from '../objectPreviewService';
import { BigidLoader } from '@bigid-ui/components';
import { ObjectDetailsWrapper, ObjectTags } from './ObjectTags';
import { ObjectAttributes } from './ObjectAttributes';
import { ObjectInfo } from './ObjectInfo';

export const DetailsTab: FC<any> = ({ caseDetails }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { caseSidePanelData } = useContext(CaseSidePanelContext);
  const { objectPreviewData } = useContext(ObjectPreviewContext);
  const [objectAttributes, setObjectAttributes] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { objectAttributes } = await fetchObjectAttributes(
          objectPreviewData.fullyQualifiedName,
          caseSidePanelData.policyName,
        );
        setObjectAttributes(objectAttributes);
      } catch ({ message }) {
        if (message) {
          console.error(`failed to get object attributes, got error: ${message}`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (objectPreviewData.fullyQualifiedName && caseSidePanelData.policyName) {
      fetchData().catch(console.error);
    }
  }, [caseSidePanelData, objectPreviewData]);

  return (
    <div>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <ObjectDetailsWrapper>
          <ObjectTags caseDetails={caseDetails} />
          <ObjectAttributes objectAttributes={objectAttributes} />
          <ObjectInfo caseDetails={caseDetails} />
        </ObjectDetailsWrapper>
      )}
    </div>
  );
};
