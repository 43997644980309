import React, { useCallback, useMemo, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidDeleteIcon,
  BigidDuplicateIcon,
  BigidAppLogoDataRightsFulfillmentIcon,
  BigidEditIcon,
  BigidAddIcon,
} from '@bigid-ui/icons';
import {
  BigidFlexibleListCardData,
  BigidFlexibleListCardActionType,
  ToolbarActionType,
  BigidFilterOptionType,
  entityEventsEmitter,
  EntityEvents,
  BigidSnake,
  BigidColorsV2,
} from '@bigid-ui/components';
import {
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { ProfileManagement, ProfileManagementProps, SarProfileType } from './ProfileManagement/ProfileManagement';
import { $state, rolesService } from '../../../services/angularServices';
import { isPermitted } from '../../../services/userPermissionsService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { PROFILE_ACTION } from './ProfileManagement/ProfileManagementDialog';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { getProfiles } from '../dsarService';
import { Scope, NEW_DSAR_SETTINGS_STATE_NAMES, SarProfileTabNames } from './SarProfileSettingsTypes';
import { useLocationChange } from './useLocationChange';
import { CONFIG } from '../../../../config/common';

export interface NormalizedSarProfile extends SarProfileType {
  id: string;
}

interface Row {
  id: number;
  name: string;
  subTitle: string;
  key: string;
  childRows: [];
}

type ConfigProps = BigidGridWithToolbarProps<Row>;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    paddingBottom: '12px',
    backgroundColor: BigidColorsV2.white,
    borderRadius: '4px',
    border: `1px solid ${BigidColorsV2.gray[300]}`,
  },
});

export const SarProfileSettingsV2 = () => {
  const classes = useStyles();
  const resultsCacheRef = useRef<NormalizedSarProfile[]>([]);
  const [profileManagementState, setProfileManagementState] = useState<ProfileManagementProps>(null);
  const [isSnakeOpen, setIsSnakeOpen] = useState<boolean>(false);
  const showDsarSnake = getApplicationPreference('SHOW_DSAR_SNAKE');
  const { stateParams } = useLocationChange();
  const [key, setKey] = useState(0);

  const openProfileManagementDialog = useCallback(
    (selectedIds: string[], actionType: PROFILE_ACTION): Promise<boolean> => {
      return new Promise(resolve => {
        if (actionType !== PROFILE_ACTION.CREATE && !selectedIds.length) {
          resolve(false);
        }
        const [profileId] = selectedIds;
        setProfileManagementState({
          profileId,
          profiles: resultsCacheRef.current,
          isOpen: true,
          actionType,
          onActionSuccess: () => {
            setKey(key => ++key);
            resolve(true);
            resetDialogState();
            entityEventsEmitter.emit(EntityEvents.RELOAD);
          },
        });
      });
    },
    [],
  );

  const defaultColumns = useMemo(() => getDefaultColumns(openProfileManagementDialog), [openProfileManagementDialog]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInitialFilterToolbarConfig = useCallback(() => getFilterConfig(), [key]);
  const {
    isReady,
    gridColumns,
    filterToolbarConfig,
    updatePreferences,
    key: gridKey,
  } = useUserPreferences({
    stateName: NEW_DSAR_SETTINGS_STATE_NAMES.LISTING,
    initialGridColumns: defaultColumns,
    getInitialFilterToolbarConfig,
  });

  const config: ConfigProps = useMemo(
    () => ({
      showSelectionCheckboxes: false,
      hideHeadersRow: true,
      hideColumnChooser: true,
      cardListMode: true,
      showFilteringControls: false,
      showSortingControls: true,
      onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
      fetchData: async queryComponents => {
        const sarProfiles = await getSarProfiles();
        const scopes = await getScopes();
        const scopesMapper: Record<string, string> = scopes.reduce(
          (dict, scope) => ({ ...dict, [scope.id]: scope.name }),
          {},
        );
        if (!resultsCacheRef.current) {
          resetDialogState();
        }
        resultsCacheRef.current = sarProfiles;
        const filteredProfiles = filterProfiles(sarProfiles, queryComponents);

        return {
          totalCount: filteredProfiles.length,
          data: filteredProfiles.map((item, i) => ({
            ...item,
            subTitle:
              item.scopes
                ?.map(scope => scopesMapper[scope])
                .filter(Boolean)
                .join(' ') || '',
            key: i.toString(),
            childRows: [],
          })),
        };
      },
      filterToolbarConfig,
      columns: gridColumns,
      toolbarActions: [
        {
          label: 'Create new profile',
          icon: BigidAddIcon,
          isGlobal: true,
          placement: 'end',
          type: ToolbarActionType.TERTIARY,
          execute: async () => {
            const actionResult = await openProfileManagementDialog([], PROFILE_ACTION.CREATE);
            return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
          },
          disable: () => false,
          show: () => isPermitted(DSAR_PERMISSIONS.CREATE_PROFILE_SETTINGS.name),
        },
        {
          label: 'Snake',
          isGlobal: true,
          placement: 'end',
          type: ToolbarActionType.TERTIARY,
          execute: () => {
            setIsSnakeOpen(true);
            return null;
          },
          show: () => showDsarSnake,
        },
      ],
      shouldShowViewToggle: false,
      entityName: 'Items',
      onRowClick: profile => {
        if (profile.id !== $state.params.profileId) {
          $state.go(
            CONFIG.states.ACCESS_REQUEST_MANAGEMENT_PROFILE,
            { ...$state.params, profileId: profile.id, tab: SarProfileTabNames.ES, profileName: profile.name },
            { reload: false, notify: false, inherit: true },
          );
        }
      },
    }),
    [filterToolbarConfig, gridColumns, updatePreferences, openProfileManagementDialog, showDsarSnake],
  );

  const resetDialogState = () => setProfileManagementState(null);

  if (stateParams.profileId) {
    return null;
  }

  return (
    <div className={classes.root}>
      {isReady && <BigidGridWithToolbar key={gridKey} {...config} />}
      {profileManagementState && <ProfileManagement {...profileManagementState} />}
      {showDsarSnake && <BigidSnake isOpen={isSnakeOpen} onClose={() => setIsSnakeOpen(false)} />}
    </div>
  );
};

const getSarProfiles = async (): Promise<NormalizedSarProfile[]> => {
  try {
    const profiles = await getProfiles();
    return profiles
      .map(profile => ({
        id: profile._id,
        ...profile,
      }))
      .sort((p1, p2) =>
        isDefaultProfile(p1) === isDefaultProfile(p2)
          ? p1.name?.localeCompare(p2.name)
          : +isDefaultProfile(p2) - +isDefaultProfile(p1),
      );
  } catch (error) {
    console.error('could not get sar profiles');
    return [];
  }
};

const getScopes = async () => {
  try {
    const {
      data: { scopes },
    } = await rolesService.getRBACScopes();
    return (scopes as Scope[]).sort((a, b) =>
      a.id === 'root' ? -1 : b.id === 'root' ? 1 : a.name.localeCompare(b.name),
    );
  } catch (error) {
    console.error('could not get sar profiles');
    return [];
  }
};

const profilesToOptions = (profiles: NormalizedSarProfile[]): BigidFilterOptionType[] =>
  Array.isArray(profiles)
    ? profiles.map(p => ({
        id: p.id,
        value: p.name?.toLowerCase(),
        label: p.name,
        isSelected: p.id === $state.params.profileId,
      }))
    : [];

const scopesToOptions = (scopes: Scope[]) =>
  Array.isArray(scopes)
    ? scopes.map(scope => ({
        id: scope.id,
        value: scope.id,
        label: scope.name,
        subLabel: scope.description,
        isSelected: false,
      }))
    : [];

const isDefaultProfile = (profile: NormalizedSarProfile) => !profile?.isCustom;

const filterProfiles = (profiles: NormalizedSarProfile[], queryComponents: BigidGridQueryComponents) =>
  profiles.filter(profile => {
    const getFilter = (filterName: string) => queryComponents.filter.find(filter => filter.field === filterName);

    const namePredicate = () => {
      const nameFilter = getFilter('name');
      if (!nameFilter) {
        return true;
      }
      const profileProp = nameFilter.field as keyof NormalizedSarProfile;
      const profileValue = profile[profileProp] as keyof NormalizedSarProfile;
      const value = profileValue?.toLowerCase();
      const filterValue = nameFilter.value as string[];
      return filterValue.includes(value);
    };

    const scopesPredicate = () => {
      const scopeFilter = getFilter('scopes');
      if (!scopeFilter) {
        return true;
      }
      const profileProp = scopeFilter.field as keyof NormalizedSarProfile;
      const profileScopes = profile[profileProp] as keyof NormalizedSarProfile;
      const filterScopes = scopeFilter.value as string[];
      return !!filterScopes.filter(Set.prototype.has, new Set(profileScopes)).length;
    };

    const searchPredicate = () => {
      const searchFilter = getFilter('search');
      if (!searchFilter) {
        return true;
      }
      const profileValue = profile['name'] as keyof NormalizedSarProfile;
      const value = profileValue?.toLowerCase();
      const filterValue = (searchFilter.value as string).toLowerCase();
      return value.includes(filterValue);
    };

    return namePredicate() && scopesPredicate() && searchPredicate();
  });

const getFilterConfig = async (): Promise<ConfigProps['filterToolbarConfig']> => {
  const profiles = await getSarProfiles();
  const scopes = await getScopes();
  return {
    filters: [
      {
        title: 'Profile Name',
        field: 'name',
        operator: 'in',
        value: [],
        options: profilesToOptions(profiles),
      },
      {
        title: 'Scope',
        field: 'scopes',
        operator: 'in',
        value: [],
        options: scopesToOptions(scopes),
      },
    ],
    searchConfig: {
      searchFilterKeys: ['search'],
      initialValue: '',
      operator: 'textSearch',
    },
  };
};

const getDefaultColumns = (
  openProfileManagementDialog: (selectedIds: string[], actionType: PROFILE_ACTION) => Promise<boolean>,
): ConfigProps['columns'] => {
  return [
    {
      name: 'name',
      title: 'name',
      width: 'auto',
      getCellValue: data => {
        const { id, name, subTitle } = data;
        const cardData: BigidFlexibleListCardData<Row> = {
          id,
          title: name,
          subTitle: subTitle,
          avatar: { icon: () => <BigidAppLogoDataRightsFulfillmentIcon size="small" />, name: 'Profile' },
          originalData: data,
        };
        const handleExecute =
          (actionType: PROFILE_ACTION) =>
          async ({ id }: BigidFlexibleListCardData<object>) => {
            const actionResult = await openProfileManagementDialog([id] as string[], actionType);
            return { shouldGridReload: actionResult, shouldClearSelection: actionResult };
          };

        return {
          card: {
            actions: [
              {
                label: 'Edit',
                name: 'edit',
                tooltip: 'Edit profile',
                onExecute: handleExecute(PROFILE_ACTION.EDIT),
                getIsShown: () => isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name),
                isAlwaysExposed: true,
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidEditIcon,
              },
              {
                label: 'Duplicate',
                name: 'duplicate',
                tooltip: 'Duplicate profile',
                onExecute: handleExecute(PROFILE_ACTION.DUPLICATE),
                getIsShown: () => isPermitted(DSAR_PERMISSIONS.CREATE_PROFILE_SETTINGS.name),
                isAlwaysExposed: true,
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidDuplicateIcon,
              },
              {
                label: 'Delete',
                name: 'delete',
                tooltip: 'Delete profile',
                onExecute: handleExecute(PROFILE_ACTION.DELETE),
                getIsShown: ({ originalData }) =>
                  !isDefaultProfile(originalData as NormalizedSarProfile) &&
                  isPermitted(DSAR_PERMISSIONS.DELETE_PROFILE_SETTINGS.name),
                getIsDisabled: ({ originalData }) => isDefaultProfile(originalData as NormalizedSarProfile),
                type: BigidFlexibleListCardActionType.ICON,
                getActionIcon: () => BigidDeleteIcon,
              },
            ],
            cardData,
            disableActionsTransformation: true,
          },
        };
      },
      type: BigidGridColumnTypes.FLEXIBLE_CARD,
    },
  ];
};

angular.module('app').component('sarProfileSettingsV2', convertToAngular(SarProfileSettingsV2));
