import {
  BigidBody1,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidTextField,
  EntityEventPayload,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { ChangeEvent, FC, useEffect, useReducer, useState } from 'react';
import { SystemDialogContentProps } from '../../../../../services/systemDialogService';
import { CuratedAttributeRowUpdatePayload } from '../../../CuratedFields/useCurationFieldsConfig';
import { CuratedAttribute, CuratedAttributeFriendlyName } from '../../../curationService';
import { useLocalTranslation } from '../../../translations';
import { assignFriendlyNameToAttribute, createFriendlyName, getAttributesList } from './assignFriendlyNameService';
import { friendlyNameReducer, FriendlyNameReducerActionTypes } from './utils';

const ContentContainer = styled('main')`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  gap: 24px;
`;

const FlexItem = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  > :nth-child(2) {
    width: 244px;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
  align-self: flex-end;
`;

interface AssignFriendlyNameDialogContent {
  attribute: CuratedAttribute;
  gridId: string;
}

export const AssignFriendlyNameDialogContent: FC<SystemDialogContentProps<AssignFriendlyNameDialogContent>> = ({
  onClose,
  attribute,
  gridId,
}) => {
  const getOptions = async (): Promise<BigidDropdownValue> => {
    const attributes = await getAttributesList();

    return attributes.map(item => ({
      value: item.glossary_id,
      displayValue: item.name,
      id: item._id,
    }));
  };

  const [{ description, friendlyName, glossaryId, originalName }, dispatchFriendlyNameState] = useReducer(
    friendlyNameReducer,
    attribute.friendlyName,
  );
  const [options, setOptions] = useState<BigidDropdownValue>([]);

  const { t } = useLocalTranslation('AssignFriendlyNameDialog');

  useEffect(() => {
    getOptions().then(setOptions);
  }, []);

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatchFriendlyNameState({ type: FriendlyNameReducerActionTypes.SET_DESCRIPTION, payload: e.target.value });
  };

  const handleOnCloseClick = () => {
    onClose();
  };
  const handleOnSaveClick = async () => {
    const assignObject = {
      friendlyName,
      description,
    };
    await assignFriendlyNameToAttribute(attribute, assignObject, glossaryId);
    const payload: EntityEventPayload<CuratedAttributeRowUpdatePayload> = {
      entityId: gridId,
      payload: {
        rowId: attribute.attributeName,
        row: {
          displayName: friendlyName,
          friendlyName: {
            description,
            friendlyName,
            glossaryId,
            originalName,
          },
        },
      },
    };

    entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, payload);
    onClose();
  };

  const createFriendlyNameCallback = async (newFriendlyName: string) => {
    const created = await createFriendlyName({
      friendlyName: newFriendlyName,
      originalName: attribute.attributeName,
    });

    const newOption = {
      value: created.glossary_id,
      displayValue: newFriendlyName,
      id: created.glossary_id,
    };

    return newOption;
  };

  const handleFriendlyNameCreate = async (name: string): Promise<BigidDropdownOption> => {
    const friendlyName = await createFriendlyNameCallback(name);

    setOptions(prevOpts => [friendlyName, ...prevOpts]);
    return friendlyName;
  };

  const dropdownValue: BigidDropdownValue = [{ displayValue: friendlyName, value: glossaryId, id: glossaryId }];

  const handleOptionChange = async ([{ value }]: BigidDropdownValue) => {
    const attributes = await getAttributesList();
    const selectedAttribute = attributes.find(name => name.glossary_id === value);

    const selectedFriendlyName: CuratedAttributeFriendlyName = {
      glossaryId: selectedAttribute.glossary_id,
      friendlyName: selectedAttribute.name,
      description: selectedAttribute.description,
      originalName: originalName,
    };
    dispatchFriendlyNameState({
      type: FriendlyNameReducerActionTypes.SET_FRIENDLY_NAME_OBJECT,
      payload: selectedFriendlyName,
    });
  };

  const isSubmitDisabled = !friendlyName;

  return (
    <ContentContainer>
      <FlexItem>
        <BigidBody1>{t('physicalName')}</BigidBody1>
        <BigidBody1>{attribute.attributeName}</BigidBody1>
      </FlexItem>
      <FlexItem>
        <BigidBody1>{t('friendlyName')}</BigidBody1>
        <BigidDropdown
          options={options}
          onSelect={handleOptionChange}
          isSearchable
          isCreatable
          onCreate={handleFriendlyNameCreate}
          value={dropdownValue}
        />
      </FlexItem>
      <FlexItem>
        <BigidBody1>{t('description')}</BigidBody1>
        <BigidTextField
          multiline
          value={description}
          placeholder={t('descriptionPlaceholder')}
          onChange={handleDescriptionChange}
        />
      </FlexItem>
      <ButtonContainer>
        <SecondaryButton size="medium" onClick={handleOnCloseClick} text={t('closeButtonText')} />
        <PrimaryButton
          size="medium"
          onClick={handleOnSaveClick}
          disabled={isSubmitDisabled}
          text={t('submitButtonText')}
        />
      </ButtonContainer>
    </ContentContainer>
  );
};
