import React from 'react';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { docsUrls } from '../../../../config/publicUrls';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  inlineBlock: {
    display: 'inline-block',
  },
});

export const ViewDetailsDocumentation = () => {
  const classes = useStyles();
  return (
    <div>
      <BigidBody1>The scan insights can&apos;t be retrieved. For information, see the </BigidBody1>
      <BigidLink shouldOpenNewTab href={docsUrls.SCANS_DOCUMENTATION} text="documentation" />
      <BigidBody1 className={classes.inlineBlock}>&nbsp;Best practice for scans</BigidBody1>
    </div>
  );
};
