import React, { FC, useEffect } from 'react';
import { BigidDropZone, BigidPrimaryCheckbox, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { openSystemDialog } from '../../../react/services/systemDialogService';
import { notificationService } from '../../../react/services/notificationService';
import { DialogContentText } from '@mui/material';

export interface BigidImportDialogProps {
  onFileUpdate: (file: File, includeExcludeList: boolean) => void;
  description?: string;
  accept?: string[] | string;
  maxSize?: number;
}

type OnImportClicked = (file: File, isImportExcludeList: boolean) => void;

export const BigidImportDialog: FC<BigidImportDialogProps> = ({
  description = '',
  accept = 'text/csv',
  maxSize,
  onFileUpdate,
}) => {
  const [fileToImport, setFileToImport] = React.useState<File>(null);
  const [isImportExcludeList, setIsImportExcludeList] = React.useState<boolean>(true);

  useEffect(() => {
    if (fileToImport) {
      onFileUpdate(fileToImport, isImportExcludeList);
    }
  }, [fileToImport, isImportExcludeList, onFileUpdate]);

  const handleOnDrop = (files: File[]) => {
    const file = files.length ? files[0] : null;
    setFileToImport(file);
  };

  return (
    <div>
      <DialogContentText>{description}</DialogContentText>
      <BigidDropZone
        accept={accept}
        files={fileToImport ? [fileToImport] : []}
        onDrop={handleOnDrop}
        maxSize={maxSize}
      />
      <BigidPrimaryCheckbox
        checked={isImportExcludeList}
        label="Import classifiers exclude list"
        onChange={(_e, checked: boolean) => setIsImportExcludeList(checked)}
        dataAid="BigidCheckbox-ExcludeList"
      />
    </div>
  );
};

export const openImportClassifiersDialog = async (onImportClicked: OnImportClicked) => {
  let requireFile: File;
  let requireIsImportExcludeList: boolean;
  const contentProps = {
    onFileUpdate: (file: File, isImportExcludeList: boolean) => {
      requireFile = file;
      requireIsImportExcludeList = isImportExcludeList;
    },
  };
  return new Promise<any>(resolve => {
    openSystemDialog({
      title: 'Import Classifier',
      content: BigidImportDialog,
      contentProps,
      onClose: () => null,
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => null,
          isClose: true,
        },
        {
          text: 'Submit',
          component: PrimaryButton,
          onClick: async () => {
            if (!requireFile) {
              notificationService.error('Please choose a file to import');
              resolve(null);
              return;
            }
            if (requireFile) {
              await onImportClicked(requireFile, requireIsImportExcludeList);
              resolve(null);
            }
          },
          isClose: true,
        },
      ],
    });
  });
};
