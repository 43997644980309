import { analyticsService } from '../../services/analyticsService';

export enum ActionableInsightsTrackingEvents {
  SMALLID_DASHBOARD_TOP_CRITICAL_CASES_WIDGET_CLICK = 'SMALLID_DASHBOARD_TOP_CRITICAL_CASES_WIDGET_CLICK',
  SMALLID_DASHBOARD_CASES_BY_SEVERITY_WIDGET_CLICK = 'SMALLID_DASHBOARD_CASES_BY_SEVERITY_WIDGET_CLICK',

  DATA_RISK_MANAGEMENT_REMEDIATE_CASE_CLICK = 'DATA_RISK_MANAGEMENT_REMEDIATE_CASE_CLICK',
  DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CLOSE_CLICK = 'DATA_RISK_MANAGEMENT_REMEDIATE_CASE_DIALOG_CLOSE_CLICK',
  DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_CLICK = 'DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_CLICK',
  DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_DIALOG_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_DIALOG_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_DIALOG_CLOSE_CLICK = 'DATA_RISK_MANAGEMENT_ACKNOWLEDGE_CASE_DIALOG_CLOSE_CLICK',
  DATA_RISK_MANAGEMENT_ASSIGN_USER_CLICK = 'DATA_RISK_MANAGEMENT_ASSIGN_USER_CLICK',
  DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_ASSIGN_CLICK = 'DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_ASSIGN_CLICK',
  DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_ASSIGN_USER_DIALOG_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_SILENCE_CASE_CLICK = 'DATA_RISK_MANAGEMENT_SILENCE_CASE_CLICK',
  DATA_RISK_MANAGEMENT_SILENCE_CASE_DIALOG_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_SILENCE_CASE_DIALOG_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_SILENCE_CASE_DIALOG_CLOSE_CLICK = 'DATA_RISK_MANAGEMENT_SILENCE_CASE_DIALOG_CLOSE_CLICK',
  DATA_RISK_MANAGEMENT_INVESTIGATE_CASE_CLICK = 'DATA_RISK_MANAGEMENT_INVESTIGATE_CASE_CLICK',
  DATA_RISK_MANAGEMENT_VIEW_CLICK = 'DATA_RISK_MANAGEMENT_VIEW_CLICK',
  DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_CLICK = 'DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_CLICK',
  DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_FAILED = 'DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_FAILED',
  DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_ERROR_FATCHING_DATA = 'DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_ERROR_FATCHING_DATA',
  DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CREATE_CLICK = 'DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CREATE_CLICK',
  DATA_RISK_MANAGEMENT_JIRA_TICKET_CANCEL_CLICK = 'DATA_RISK_MANAGEMENT_JIRA_TICKET_CANCEL_CLICK',
  DATA_RISK_MANAGEMENT_JIRA_TICKET_CREATE_CLICK = 'DATA_RISK_MANAGEMENT_JIRA_TICKET_CREATE_CLICK',
  DATA_RISK_MANAGEMENT_OBJECT_PREVIEW = 'DATA_RISK_MANAGEMENT_OBJECT_PREVIEW',
  DATA_RISK_MANAGEMENT_OBJECT_PREVIEW_PERMISSIONS = 'DATA_RISK_MANAGEMENT_OBJECT_PREVIEW_PERMISSIONS',
}

export enum ActionableInsightsPageViewsEvents {
  DATA_RISK_MANAGEMENT_PAGE_VIEW = 'DATA_RISK_MANAGEMENT_PAGE_VIEW',
  DATA_RISK_MANAGEMENT_CASES_PAGE_VIEW = 'DATA_RISK_MANAGEMENT_CASES_PAGE_VIEW',
}

export const trackActionableInsightsPageView = (eventType: ActionableInsightsPageViewsEvents) => {
  analyticsService.trackPageView(eventType as any);
};

export const trackActionableInsightsEvent = (
  eventType: ActionableInsightsTrackingEvents,
  data?: Record<string, unknown>,
) => {
  analyticsService.trackManualEvent(eventType as any, data);
};
