import React, { FC, ReactNode, useMemo, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidPaper } from '@bigid-ui/components';
import { REVIEW_FINDINGS_PERMISSIONS } from '@bigid/permissions';
import { generateGuidedTourId } from '@bigid-ui/utils';
import { $state, $stateParams } from '../../services/angularServices';
import { useCurationState, CurationStageId } from './useCurationState';
import { CuratedDataSources } from './CuratedDataSources/CuratedDataSources';
import { CuratedAttributes } from './CuratedAttributes/CuratedAttributes';
import { CuratedFields } from './CuratedFields/CuratedFields';
import { CurationStatus } from './curationService';
import classNames from 'classnames';
import { getFixedT } from './translations';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { isPermitted } from '../../services/userPermissionsService';
import { CurationGuidedTour } from './GuidedTour/CurationGuidedTour';
import { CurationTabsLayoutStateContext } from './CurationStateContext';
import { omit } from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)',
    width: '100%',
    position: 'relative',
  },
  progress: {
    height: '52px',
    padding: '0px 13px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentWithoutProgressBar: {
    height: 'calc(100% - 12px)',
  },
  contentWithProgressBar: {
    height: 'calc(100% - 12px)',
  },
  navigation: {
    width: '100%',
    height: '64px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 12px',
    gap: 8,
  },
});

export const Curation: FC = () => {
  const classes = useStyles({});
  const {
    currentStageId,
    attributesCurationStatus,
    fieldsCurationStatus,
    currentCuratedAttribute,
    currentCuratedDataSource,
    onProceedToAttributeList,
    onProceedToFieldsReview,
    onFieldReviewed,
    guidedTourStatus,
    isPageInitialised,
    onGuidedTourStageFinished,
    onProceedToFieldsReviewTab,
    currentGuidedTour,
    onCurationGuidedTourStart,
    onGuidedTourFirstRun,
  } = useCurationState({ scanId: $stateParams.scanId, scanProfileName: $stateParams.scanProfileName });

  useEffect(() => {
    if (isPageInitialised) {
      onGuidedTourFirstRun(currentGuidedTour);
    }
  }, [currentGuidedTour, isPageInitialised, onGuidedTourFirstRun]);

  /**
   * removing params that should only serve the wizard's init purpose i.e. deep linking and futher list filtering
   * must be cleaned up for stable wizard work
   */
  const cleanUpDeepLinkParamsOnStageChange = useCallback((stageId: CurationStageId) => {
    if (stageId !== CurationStageId.CURATED_SOURCES) {
      const { params } = $state.get($state.current.name);
      const cleanedUpParams = Object.keys(omit(params, ['dataSource', 'attribute']));

      $state.go(
        $state.current.name,
        cleanedUpParams.reduce(
          (paramsObject, paramName) => {
            return { ...paramsObject, [paramName]: $stateParams[paramName] };
          },
          { dataSource: null, attributes: null },
        ),
        {
          reload: false,
          notify: false,
        },
      );
    }
  }, []);

  const getCurationStageComponent = (stageId: CurationStageId): ReactNode => {
    let component: ReactNode;

    cleanUpDeepLinkParamsOnStageChange(stageId);

    switch (stageId) {
      case CurationStageId.CURATED_SOURCES:
        {
          const filter = $stateParams.dataSource;

          component = (
            <CuratedDataSources
              scanId={$stateParams.scanId}
              onProceedToAttributeList={onProceedToAttributeList}
              filter={filter}
            />
          );
        }
        break;
      case CurationStageId.CURATED_ATTRIBUTES:
        component = (
          <CuratedAttributes
            currentCuratedDataSource={currentCuratedDataSource}
            onProceedToFieldsReview={onProceedToFieldsReview}
            curationStatus={curationStatusDisplayed}
            curatedEntityName={curatedEntityName}
          />
        );
        break;
      case CurationStageId.CURATED_FIELDS:
        component = (
          <CurationTabsLayoutStateContext.Provider
            value={{
              onProceedToFieldsReviewTab: onProceedToFieldsReviewTab,
              onCurationGuidedTourStart: onCurationGuidedTourStart,
            }}
          >
            <CuratedFields
              currentCuratedDataSource={currentCuratedDataSource}
              currentCuratedAttribute={currentCuratedAttribute}
              onFieldReviewed={onFieldReviewed}
              curationStatus={curationStatusDisplayed}
              curatedEntityName={curatedEntityName}
            />
          </CurationTabsLayoutStateContext.Provider>
        );
        break;
    }

    return component;
  };

  const { curationStatusDisplayed, curatedEntityName } = useMemo(() => {
    const t = getFixedT('CurationProgressBar');
    let curationStatusDisplayed: CurationStatus;
    let curatedEntityName: string;

    switch (currentStageId) {
      case CurationStageId.CURATED_ATTRIBUTES:
        curationStatusDisplayed = attributesCurationStatus;
        curatedEntityName = t('entity.attributes');
        break;
      case CurationStageId.CURATED_FIELDS:
        curationStatusDisplayed = fieldsCurationStatus;
        curatedEntityName = t('entity.objects');
        break;
    }

    return {
      curationStatusDisplayed,
      curatedEntityName,
    };
  }, [currentStageId, fieldsCurationStatus, attributesCurationStatus]);

  const isCurationAccessible =
    getApplicationPreference('CLASSIFIER_TUNING_ENABLED') && isPermitted(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name);

  useEffect(() => {
    if (!isCurationAccessible) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, [isCurationAccessible]);

  return (
    isCurationAccessible &&
    isPageInitialised && (
      <div className={classes.root} data-tour-id={generateGuidedTourId('curation-main', ['start'])}>
        <BigidPaper>
          <div
            className={classNames(
              classes.content,
              curationStatusDisplayed ? classes.contentWithProgressBar : classes.contentWithoutProgressBar,
            )}
          >
            {getCurationStageComponent(currentStageId)}
          </div>
        </BigidPaper>
        <CurationGuidedTour
          guidedTourStatus={guidedTourStatus}
          onGuidedTourStageFinished={onGuidedTourStageFinished}
          currentGuidedTour={currentGuidedTour}
        />
      </div>
    )
  );
};
