import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

export const Image = styled.div`
  margin-bottom: 1rem;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Actions = styled.div`
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  & button {
    width: 216px;
    border: none;
  }

  & button:hover {
    border: none;
  }
`;

export const ScansButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonsSeparator = styled.div`
  width: 1px;
  background-color: white;
`;

export const ScanNowButtonWrapper = styled.div`
  & button {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 175px;
  }
`;

export const CustomScanButtonWrapper = styled.div`
  & button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 40px;
  }
`;
