import React, { useRef } from 'react';
import { noop, isEqual } from 'lodash';
import { openSystemDialog } from '../../../../services/systemDialogService';
import { DataSourceTemplateValueBase } from '../types';
import { BigidBody1, BigidLink } from '@bigid-ui/components';
import { BigidWarningIcon } from '@bigid-ui/icons';

export const useCustomNotification = (
  fields: DataSourceTemplateValueBase[] | any[],
  fieldName: string,
  parentFieldName?: string,
) => {
  const existingValues = useRef<Record<string, any>>({});

  const currentField = fields?.find((el: DataSourceTemplateValueBase | any) => el?.name === parentFieldName);
  const objectFields = currentField?.misc?.objectFields;
  const currentSubfield = objectFields?.find((el: DataSourceTemplateValueBase | any) => el.notificationContent);

  const NotificationContent = () => (
    <BigidBody1>
      {currentSubfield?.notificationContent?.message} See{' '}
      <BigidLink
        shouldOpenNewTab={true}
        text="BigID documentation"
        href={currentSubfield?.notificationContent?.url}
        rel="noreferrer"
      />{' '}
      for more information.
    </BigidBody1>
  );

  const additionalNotification = (values: Record<string, any>) => {
    if (isEqual(existingValues.current, {})) {
      existingValues.current = values;
    }

    switch (true) {
      case values[parentFieldName]?.[fieldName] && !existingValues.current[parentFieldName]?.[fieldName]:
        existingValues.current = values;

        openSystemDialog({
          title: currentSubfield?.notificationContent?.title,
          content: NotificationContent,
          onClose: noop,
          icon: BigidWarningIcon,
        });
        break;
    }
  };

  return { additionalNotification };
};
