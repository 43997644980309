import React, { FC, useEffect, useState, useCallback } from 'react';
import { BigidDialog, PrimaryButton, SecondaryButton, BigidSelect, BigidSelectOption } from '@bigid-ui/components';
import { FormControl, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataSource, SarProfileConnection, SarProfileConnectionModel } from '../ProfileSettingsTypes';
import { notificationService } from '../../../../services/notificationService';
import { sarConfigService } from '../sarConfigService';
import { GenericField, getPutData, getSelectedOptions } from '../editGridRowsUtils';
import { SarProfileSourceTypes } from '../SarProfileSettingsTypes';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    padding: theme.spacing(0, 3),
  },
}));

type ParitalPutFields = Partial<SarProfileConnection>;

export interface EditDataSourceDialogProps {
  profileId: string;
  sourceType?: SarProfileSourceTypes;
  isOpen: boolean;
  isLoading?: boolean;
  onSave?: (outputState: { newState: ParitalPutFields; status: { isChanged?: boolean; isOk: boolean } }) => void;
  onClose?: () => void;
  sources: DataSource[];
  isSelectionDisabled?: boolean;
}

export const EditDataSourceDialog: FC<EditDataSourceDialogProps> = ({
  sourceType,
  profileId,
  isOpen,
  isLoading,
  onSave,
  onClose,
  sources,
  isSelectionDisabled,
}) => {
  const classes = useStyles({});

  const titleText = `Edit Scan Settings`;
  const subTitleText = `Editing scan settings for ${sources.length} ${
    sourceType == SarProfileSourceTypes.DS ? 'data sources' : 'correlation sets'
  }`;
  const buttonText = 'Save';
  const profileEnabledText =
    sourceType == SarProfileSourceTypes.DS ? 'Include in data scan' : 'Include in correlation sets';

  const [isCaseInSensitiveOptions] = useState<BigidSelectOption[]>([
    { label: 'On', value: false },
    { label: 'Off', value: true },
  ]);

  const [isReferentialIntegrityOptions] = useState<BigidSelectOption[]>([
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ]);

  const [isExactMatchOptions] = useState<BigidSelectOption[]>([
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ]);

  const [profileEnabledOptions] = useState<BigidSelectOption[]>([
    { label: 'Include', value: true },
    { label: 'Exclude', value: false },
  ]);

  const [isCaseSensitiveSelectDisabled, setIsCaseSensitiveSelectDisabled] = useState<boolean>();
  const [selectedIsCaseInSensitive, setSelectedIsCaseInSensitive] = useState<BigidSelectOption[]>([]);
  const [selectedIsReferentialIntegrity, setSelectedIsReferentialIntegrity] = useState<BigidSelectOption[]>([]);
  const [selectedProfileEnabled, setSelectedProfileEnabled] = useState<BigidSelectOption[]>([]);
  const [isReferentialIntegritySelectDisabled, setIsReferentialIntegritySelectDisabled] = useState<boolean>();
  const [isExactMatchSelectDisabled, setIsExactMatchSelectDisabled] = useState<boolean>();
  const [selectedIsExactMatch, setSelectedIsExactMatch] = useState<BigidSelectOption[]>();

  const setFormDataFromSources = useCallback(
    (sources: DataSource[]) => {
      const isCaseInSensitiveSupportedSources = sources.filter(
        ({ isCaseInSensitiveSupported }) => isCaseInSensitiveSupported,
      );

      const isReferentialIntegritySupportedSources = sources.filter(
        ({ isReferentialIntegritySupported }) => isReferentialIntegritySupported,
      );
      const exactMatchSupportedSources = sources.filter(({ isExactMatchSupported }) => isExactMatchSupported);

      setIsCaseSensitiveSelectDisabled(!isCaseInSensitiveSupportedSources.length);
      setSelectedIsCaseInSensitive(
        getSelectedOptions(isCaseInSensitiveSupportedSources, 'isCaseInSensitive', isCaseInSensitiveOptions),
      );
      setIsReferentialIntegritySelectDisabled(!isReferentialIntegritySupportedSources.length);
      setIsExactMatchSelectDisabled(!exactMatchSupportedSources.length);
      setSelectedIsReferentialIntegrity(
        getSelectedOptions(
          isReferentialIntegritySupportedSources,
          'isReferentialIntegrity',
          isReferentialIntegrityOptions,
        ),
      );
      setSelectedIsExactMatch(getSelectedOptions(exactMatchSupportedSources, 'isExactMatch', isExactMatchOptions));
      setSelectedProfileEnabled(getSelectedOptions(sources, 'profileEnabled', profileEnabledOptions));
    },
    [isCaseInSensitiveOptions, isExactMatchOptions, isReferentialIntegrityOptions, profileEnabledOptions],
  );

  useEffect(() => {
    if (isOpen) {
      setFormDataFromSources(sources);
    } else {
      setIsCaseSensitiveSelectDisabled(false);
      setIsReferentialIntegritySelectDisabled(false);
      setIsExactMatchSelectDisabled(false);
      setSelectedIsCaseInSensitive([]);
      setSelectedIsReferentialIntegrity([]);
      setSelectedIsExactMatch([]);
      setSelectedProfileEnabled([]);
    }
  }, [isOpen, setFormDataFromSources, sources]);

  const handleOnSave = () => {
    const newState: ParitalPutFields = {
      enabled: selectedProfileEnabled?.[0]?.value,
      isCaseInSensitive: selectedIsCaseInSensitive?.[0]?.value,
      isReferentialIntegrity: selectedIsReferentialIntegrity?.[0]?.value,
      isExactMatch: selectedIsExactMatch?.[0]?.value,
    };

    updateSources(profileId, newState, sources)
      .then(() => {
        onSave({ newState, status: { isChanged: true, isOk: true } });
      })
      .catch(() => {
        onSave({ newState, status: { isChanged: false, isOk: false } });
      });
  };

  return (
    <BigidDialog
      title={titleText}
      isOpen={isOpen}
      onClose={onClose}
      borderTop={true}
      buttons={[
        { component: SecondaryButton, onClick: onClose, text: 'Cancel' },
        {
          component: PrimaryButton,
          onClick: handleOnSave,
          text: buttonText,
        },
      ]}
      isLoading={isLoading}
    >
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off">
          <fieldset>
            <FormControl fullWidth margin="normal">
              <div>{subTitleText}</div>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <div>{profileEnabledText}</div>
              <BigidSelect
                name={'profileEnabled'}
                options={profileEnabledOptions}
                onChange={setSelectedProfileEnabled}
                value={selectedProfileEnabled}
                menuPosition="fixed"
                message={selectedProfileEnabled?.[0]?.label === 'Multiple Values' ? 'Select Value to Apply to all' : ''}
                isDisabled={isSelectionDisabled}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <div>Case Sensitive</div>
              <BigidSelect
                name={'isCaseInSensitive'}
                options={isCaseInSensitiveOptions}
                isDisabled={isCaseSensitiveSelectDisabled}
                placeholder={isCaseSensitiveSelectDisabled ? 'N/A' : ''}
                onChange={setSelectedIsCaseInSensitive}
                value={selectedIsCaseInSensitive}
                menuPosition="fixed"
              />
            </FormControl>
            {sourceType === SarProfileSourceTypes.DS && (
              <FormControl fullWidth margin="normal">
                <div>Linked Tables</div>
                <BigidSelect
                  name={'isReferentialIntegrity'}
                  options={isReferentialIntegrityOptions}
                  isDisabled={isReferentialIntegritySelectDisabled}
                  placeholder={isReferentialIntegritySelectDisabled ? 'N/A' : ''}
                  onChange={setSelectedIsReferentialIntegrity}
                  value={selectedIsReferentialIntegrity}
                  menuPosition="fixed"
                />
              </FormControl>
            )}
            <FormControl fullWidth margin="normal">
              <div>Exact Match</div>
              <BigidSelect
                name={'isExactMatch'}
                options={isExactMatchOptions}
                isDisabled={isExactMatchSelectDisabled}
                placeholder={isExactMatchSelectDisabled ? 'N/A' : ''}
                onChange={setSelectedIsExactMatch}
                value={selectedIsExactMatch}
                menuPosition="fixed"
              />
            </FormControl>
          </fieldset>
        </form>
      </div>
    </BigidDialog>
  );
};

export async function updateSources(sarProfileId: string, newState: ParitalPutFields, sources: DataSource[]) {
  const genericFields: GenericField<DataSource, ParitalPutFields>[] = [
    {
      name: 'enabled',
    },
    {
      name: 'isCaseInSensitive',
      getValue: ({ isCaseInSensitive }, { isCaseInSensitiveSupported }) => {
        return isCaseInSensitiveSupported ? isCaseInSensitive : undefined;
      },
    },
    {
      name: 'isReferentialIntegrity',
      getValue: ({ isReferentialIntegrity }, { isReferentialIntegritySupported }) => {
        return isReferentialIntegritySupported ? isReferentialIntegrity : undefined;
      },
    },
    {
      name: 'isExactMatch',
      getValue: ({ isExactMatch }, { isExactMatchSupported }) => {
        return isExactMatchSupported ? isExactMatch : undefined;
      },
    },
  ];

  const sourcesToUpdate = getPutData(
    ['name', 'sourceType', 'isCaseInSensitiveSupported'],
    genericFields,
    newState,
    sources,
  );

  if (!sourcesToUpdate.length) {
    notificationService.success(`Nothing to update`);
    return;
  }

  const newDbState: SarProfileConnectionModel = {
    connections: sourcesToUpdate as SarProfileConnection[],
    profileId: sarProfileId,
  };

  return sarConfigService
    .addConnections(sarProfileId, newDbState)
    .then(() => notificationService.success(`Changes saved`))
    .catch(err => {
      notificationService.error(`Failed to save changes`);
      console.error(`Failed to update sar-profile item with the new state:`, JSON.stringify(newDbState), err);
      throw err;
    });
}
