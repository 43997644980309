import { useCallback, useMemo } from 'react';
import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarFilterUnion,
  BigidAdvancedToolbarProps,
  SelectedOptionsConfig,
} from '@bigid-ui/components';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { useCatalogFilters } from './useCatalogFiltersConfig';
import { $state } from '../../../../../../services/angularServices';
import {
  AdvancedToolbarOverrideValueMinified,
  getFilterCompressedToOverrideValue,
  getFilterMinified,
  getFilterUnminified,
} from '../../../../filterMinifier';
import {
  DataExplorerSearchFilterSelectBiPayload,
  sendDataExplorerResultsPageFilterSelectBiAnalytics,
} from '../../../../../DataExplorer/services/analytics';
import { parseCatalogFilterOutputToQueryString } from '../../../../queryHelpers';

export function useFilterConfig() {
  const { dispatchCatalogSearchAction, filterOverrideValues } = useDataCatalogSearchResultsContext();

  const handleFiltersChange = useCallback(
    (selectedFilters: BigidAdvancedToolbarFilterUnion[]) => {
      dispatchCatalogSearchAction({
        type: 'UPDATE_SAVED_FILTERS_MAP',
        payload: {
          entityType: 'catalog',
          filter: parseCatalogFilterOutputToQueryString(selectedFilters),
        },
      });

      dispatchCatalogSearchAction({
        payload: {
          selectedRowsIds: [],
          unselectedRowsIds: [],
        },
        type: 'UPDATE_GRID_CONFIG',
      });

      const minifiedFilters = selectedFilters.map(filter => getFilterMinified(filter));

      $state.go(
        $state.$current.name,
        {
          filters: selectedFilters.length > 0 ? JSON.stringify(minifiedFilters) : null,
        },
        { reload: false, notify: false, location: 'replace' },
      );

      const biPayload: DataExplorerSearchFilterSelectBiPayload = {
        filter: selectedFilters.map(filter => getFilterCompressedToOverrideValue(filter)),
      };

      sendDataExplorerResultsPageFilterSelectBiAnalytics(biPayload);
    },
    [dispatchCatalogSearchAction],
  );

  const overrideConfig: SelectedOptionsConfig = {
    strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
    values: (JSON.parse(filterOverrideValues || '[]') as unknown as AdvancedToolbarOverrideValueMinified[]).map(
      filter => getFilterUnminified(filter),
    ),
  };

  const filters = useCatalogFilters();
  const filterConfig: BigidAdvancedToolbarProps = useMemo(() => {
    return {
      filters,
      onFiltersChange: handleFiltersChange,
      selectedOptionsConfig: overrideConfig,
    };
  }, []);

  const getFilterConfigById = useCallback(
    (filterId: string | number) => {
      return filterConfig.filters.find(({ id }) => id === filterId);
    },
    [filterConfig.filters],
  );

  return {
    filterConfig,
    getFilterConfigById,
  };
}
