import React, { FC } from 'react';
import { BigidHeading3, BigidHeading5 } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../../../translations';
import { BigidNonActionIllusIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';

const MainContainer = styled.div({
  padding: 16,
  width: '100%',
  height: '100%',
});

const ContentContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  paddingBottom: 24,
  paddingTop: 8,
});

const ContentWrapperContainer = styled.div({
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: 8,
  textAlign: 'center',
  paddingBottom: 72,
});

const BigidNonActionIllusIconWrapper = styled.div({
  paddingBottom: 24,
  paddingTop: 8,
});

export const NoJiraConfiguration: FC = () => {
  const { t } = useLocalTranslation('Action');

  return (
    <MainContainer>
      <ContentContainer>
        <ContentWrapperContainer>
          <BigidNonActionIllusIconWrapper>
            <BigidNonActionIllusIcon dataAid={generateDataAid('noConfiguration', ['icon'])} width={130} />
          </BigidNonActionIllusIconWrapper>
          <BigidHeading3>{t('body.noJiraConfiguration')}</BigidHeading3>
          <BigidHeading5>{t('body.contactAdministrator')}</BigidHeading5>
        </ContentWrapperContainer>
      </ContentContainer>
    </MainContainer>
  );
};
