import React, { FC } from 'react';
import { BigidMessageIcon } from '@bigid-ui/icons';
import { SecondaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';

export interface AutoDiscoveryCollaborationProps {
  onClick: () => void;
}

export const AutoDiscoveryCollaborateButton: FC<AutoDiscoveryCollaborationProps> = ({ onClick }) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  return (
    <SecondaryButton
      size={'large'}
      onClick={onClick}
      dataAid={'prerequisites-collaborate-button'}
      startIcon={<BigidMessageIcon />}
      text={`${t('prerequisites.buttons.collaborate')}`}
    />
  );
};
