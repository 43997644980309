import { BigidGridColumn, BigidGridColumnTypes, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { ScanTemplateGridRow } from './scanTemplateTypes';
import { getCreatedByText, getScanTypeLabel, getScanTypesOptions } from '../ScanUtils';
import { BigidFieldFilterOperator, BigidFilterOptionValue } from '@bigid-ui/components';
import { LoadSearchOptions, loadUserSearchOptions } from '../../ActionCenter/ActionWorkflow/actionWorkflowUtils';

const SCAN_TEMPLATE_FETCH_LIMIT = 500;

export const getInitialScanTemplateGridColumns: () => BigidGridColumn<ScanTemplateGridRow>[] = () => [
  {
    name: 'name',
    title: 'Name',
    sortingEnabled: true,
    getCellValue: row => row.name,
    type: BigidGridColumnTypes.TEXT,
    width: 350,
  },
  {
    name: 'scanType',
    title: 'Type',
    sortingEnabled: true,
    getCellValue: row => getScanTypeLabel(row.scanType),
    type: BigidGridColumnTypes.TEXT,
    width: 175,
  },

  {
    name: 'usesClassifiers',
    title: 'Uses Classifiers',
    sortingEnabled: false,
    getCellValue: ({ classifiers, isUseGlobalClassifiers }) =>
      !!classifiers?.length || isUseGlobalClassifiers ? 'Yes' : 'No',
    type: BigidGridColumnTypes.TEXT,
    width: 150,
  },
  {
    name: 'usesCorrelation',
    title: 'Uses Correlation',
    sortingEnabled: false,
    getCellValue: ({ idConnectionList, allEnabledIdSor }) =>
      !!idConnectionList?.length || allEnabledIdSor ? 'Yes' : 'No',
    type: BigidGridColumnTypes.TEXT,
    width: 150,
  },
  {
    name: 'createdBy',
    title: 'Created By',
    getCellValue: row => {
      return getCreatedByText(row?.createdBy);
    },
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'owner',
    title: 'Template Owner',
    getCellValue: row => getCreatedByText(row.owner),
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
  {
    name: 'updated_at',
    title: 'Last Modified Date',
    getCellValue: row => row.updated_at,
    type: BigidGridColumnTypes.DATE,
    sortingEnabled: true,
    isHiddenByDefault: false,
    width: 200,
  },
  {
    name: '_id',
    title: 'Scan Template Id',
    getCellValue: row => row._id,
    type: BigidGridColumnTypes.TEXT,
    sortingEnabled: false,
    isHiddenByDefault: true,
  },
];

const getInitialActivityQueueFilters = (
  scanSupportedTypes?: string[],
): BigidGridWithToolbarProps<ScanTemplateGridRow>['filterToolbarConfig'] => {
  let scanTypesOptions = getScanTypesOptions();
  if (scanSupportedTypes?.length > 0) {
    scanTypesOptions = scanTypesOptions.filter(scanType => scanSupportedTypes.includes(scanType.value));
  }
  return {
    filters: [
      {
        title: 'Type',
        field: 'scanType',
        operator: 'in',
        options: scanTypesOptions,
        value: [],
      },
      {
        title: 'Uses Classifiers',
        field: 'hasClassifiers',
        operator: 'equal',
        single: true,
        value: [],
        disabled: true,
        options: [
          {
            label: 'Yes',
            value: true,
            isSelected: false,
          },
          {
            label: 'No',
            value: false,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Uses Correlation',
        field: 'hasCorrelation',
        operator: 'equal',
        single: true,
        value: [],
        disabled: true,
        options: [
          {
            label: 'Yes',
            value: true,
            isSelected: false,
          },
          {
            label: 'No',
            value: false,
            isSelected: false,
          },
        ],
      },
      {
        title: 'Created By',
        field: 'createdBy.username',
        operator: 'in' as BigidFieldFilterOperator,
        value: [] as BigidFilterOptionValue,
        isSearchAsync: true,
        disabled: true,
        loadSearchOptions: loadUserSearchOptions({
          isFilter: true,
          limit: SCAN_TEMPLATE_FETCH_LIMIT,
        }) as LoadSearchOptions,
        options: [],
        shouldLoadInitialOptions: true,
      },
      {
        title: 'Owner',
        field: 'owner.username',
        operator: 'in' as BigidFieldFilterOperator,
        value: [] as BigidFilterOptionValue,
        isSearchAsync: true,
        disabled: true,
        loadSearchOptions: loadUserSearchOptions({
          isFilter: true,
          limit: SCAN_TEMPLATE_FETCH_LIMIT,
        }) as LoadSearchOptions,
        options: [],
        shouldLoadInitialOptions: true,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['name'],
      initialValue: '',
      placeholder: '',
      operator: 'contains',
      autoSubmit: true,
    },
  };
};

export function getInitialScanTemplatesFilters(scanSupportedTypes: any) {
  return {
    ...getInitialActivityQueueFilters(scanSupportedTypes),
    filters: getInitialActivityQueueFilters(scanSupportedTypes).filters.map(filter => ({ ...filter, disabled: true })),
  };
}

export async function getInitialScanTemplatesFiltersAsync() {
  return {
    ...getInitialActivityQueueFilters(),
    filters: getInitialActivityQueueFilters().filters.map(filter => ({ ...filter, isSelected: true })),
  };
}
