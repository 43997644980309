import {
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidRadio,
  EntityEvents,
  entityEventsEmitter,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useLocalTranslation } from '../../../translations';
import { CreateNewCategory } from './CreateNewCategory';
import { assignCategoryToAttributeRequest, createCategoryRequest } from './assignCategoryService';
import { CategoryAssignModes, CategoryDetailsActions, CreateCategory, DetailsActionsTypes } from '../types';
import { getCategoriesDetails } from '../../../../AccessIntelligence/UsersPermissions/UsersPermissionsService';
import { SystemDialogContentProps } from '../../../../../services/systemDialogService';
import { CuratedAttribute, LegacyCategoryDetails } from '../../../curationService';
import { getPayload, mapCategoriesToDropdownValues, mapLegacyCategoriesToDropdownValues } from './utils';
import { notificationService } from '../../../../../services/notificationService';
import { generateDataAid } from '@bigid-ui/utils';

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 8px 0 8px;
`;

export const FormItem = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
  align-self: flex-end;
`;

const defaultCategoryDetails: CreateCategory = {
  color: BigidColorsV2.yellow[500],
  description: '',
  name: '',
};

const categoryDetailsReducer = (state: CreateCategory, action: CategoryDetailsActions) => {
  switch (action.type) {
    case DetailsActionsTypes.SET_CATEGORY_DETAILS_FIELD:
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    case DetailsActionsTypes.SET_CATEGORY_DETAILS:
      return action.payload;
    case DetailsActionsTypes.RESET_CATEGORY_DETAILS:
      return defaultCategoryDetails;
    default:
      return state;
  }
};

export interface AssignCategoryDialogContentProps {
  attribute: CuratedAttribute;
  gridId: string;
}

export const AssignCategoryDialogContent: FC<SystemDialogContentProps<AssignCategoryDialogContentProps>> = ({
  onClose,
  attribute,
  gridId,
}) => {
  const [state, dispatchState] = useReducer(categoryDetailsReducer, defaultCategoryDetails);
  const [selectedCategories, setSelectedCategories] = useState<BigidDropdownValue>(
    mapCategoriesToDropdownValues(attribute.categories),
  );
  const [categories, setCategories] = useState<LegacyCategoryDetails[]>([]);
  const { t } = useLocalTranslation('AssignCategoryDialogContent');
  const [categoryAssignMode, setCategoryAssignMode] = useState<CategoryAssignModes>(CategoryAssignModes.EXISTING);
  const options: BigidDropdownOption[] = useMemo(() => {
    return mapLegacyCategoriesToDropdownValues(categories);
  }, [categories]);

  useEffect(() => {
    const fetchCategories = async () => {
      const catagoriesResponse = await getCategoriesDetails();
      setCategories(catagoriesResponse.data as unknown as LegacyCategoryDetails[]);
    };
    fetchCategories();
  }, []);

  const handleCategorySelect = (options: BigidDropdownOption[]) => {
    setSelectedCategories(options);
  };

  const handleOnSaveClick = async () => {
    if (categoryAssignMode === CategoryAssignModes.NEW) {
      if (!state.name) {
        notificationService.info(t('messages.nameRequired'));
        return;
      }

      const response = await createCategoryRequest(state);
      if (response?.success) {
        const selectedCategoryIds = selectedCategories.map(({ id }) => id);
        await assignCategoryToAttributeRequest(attribute, [...selectedCategoryIds, response.glossary_id]);

        const previousSelectedCategories = selectedCategories.map(({ displayValue, id, value }) => ({
          color: value.color,
          description: value.description,
          displayName: displayValue,
          uniqueName: id,
        }));

        const newCategory = {
          color: state.color,
          description: state.description,
          displayName: state.name,
          uniqueName: response.glossary_id,
        };

        const allCategories = previousSelectedCategories.concat(newCategory);

        entityEventsEmitter.emit(
          EntityEvents.UPDATE_BY_ID,
          getPayload({
            attributeName: attribute.attributeName,
            categories: allCategories,
            gridId,
          }),
        );
        onClose();
      }
    } else {
      const selectedCategoryIds = selectedCategories.map(({ id }) => id);
      await assignCategoryToAttributeRequest(attribute, selectedCategoryIds);

      entityEventsEmitter.emit(
        EntityEvents.UPDATE_BY_ID,
        getPayload({
          attributeName: attribute.attributeName,
          categories: selectedCategories.map(({ displayValue, id, value }) => ({
            color: value.color,
            description: value.description,
            displayName: displayValue,
            uniqueName: id,
          })),
          gridId,
        }),
      );

      onClose();
    }
  };

  const handleOnCloseClick = () => {
    onClose();
  };

  const isAssignExisting = categoryAssignMode === CategoryAssignModes.EXISTING;
  const isAssignNew = categoryAssignMode === CategoryAssignModes.NEW;
  const shouldSubmitDisabled = isAssignNew && !state.name.trim();

  return (
    <FormContainer>
      <BigidRadio
        label={t('ExistingCategory.radioActionLabel')}
        dataAid={generateDataAid('AssignCategoryDialogContent', ['existingCategoryRadio'])}
        checked={isAssignExisting}
        onChange={() => setCategoryAssignMode(CategoryAssignModes.EXISTING)}
      />
      <BigidDropdown
        value={selectedCategories}
        onSelect={handleCategorySelect}
        options={options}
        isMulti
        isDisabled={isAssignNew}
        placeholder={t('ExistingCategory.dropdownPlaceholder')}
      />
      <BigidRadio
        label={t('NewCategory.radioActionLabel')}
        dataAid={generateDataAid('AssignCategoryDialogContent', ['newCategoryRadio'])}
        checked={isAssignNew}
        onChange={() => setCategoryAssignMode(CategoryAssignModes.NEW)}
      />
      <CreateNewCategory disabled={isAssignExisting} categoryState={state} dispatchCategoryAction={dispatchState} />
      <ButtonContainer>
        <SecondaryButton size="medium" onClick={handleOnCloseClick} text={t('closeButtonText')} />
        <PrimaryButton
          size="medium"
          onClick={handleOnSaveClick}
          disabled={shouldSubmitDisabled}
          text={t('submitButtonText')}
        />
      </ButtonContainer>
    </FormContainer>
  );
};
