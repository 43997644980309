import {
  BigidRadioGroup,
  PrimaryButton,
  TertiaryButton,
  BigidTextFieldAutocomplete,
  BigidSelect,
  BigidTextField,
  BigidHeading6,
  BigidSelectOption,
  BigidHeading5,
} from '@bigid-ui/components';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import BigidCopyIcon from '../../assets/icons/BigidCopyIcon.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import { getDistinctAttributes } from '../../views/DataCatalog/DataCatalogService';
import BigidHelpIcon from '../../assets/icons/BigidHelpFilled.svg';

interface ActionOnTriggerProps {
  onChangeTriggerType: (type: string) => void;
  onChangeTriggerValue: (value: string) => void;
  onChangeQuery: (query: string) => void;
}

const useStyles = makeStyles({
  queryToolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  copyWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarSpaceing: {
    marginRight: '10px',
  },
  toolbarButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  helpCircle: {
    margin: '5px',
    width: '30px',
  },
  actionOnTrigger: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '20px 0px',
  },
  actionOnTriggerInputs: {
    display: 'flex',
    width: '45%',
    alignItems: 'center',
  },
  asterisk: {
    color: '#fc003b',
    fontSize: '8px',
    marginLeft: '5px',
  },
  autocompleteWrapper: {
    width: '50%',
  },
  actionOnTriggerHeader: {
    color: 'black',
    marginTop: '20px',
  },
});

const selectOptions = [{ label: 'Assign Attribute', value: 'assignAttribute' }];
const queryBuilder = 'queryBuilder';

export const ActionOnTrigger: FC<ActionOnTriggerProps> = ({
  onChangeTriggerType,
  onChangeTriggerValue,
  onChangeQuery,
}) => {
  const [radioValue, setRadioValue] = useState<string>(queryBuilder);
  const [userQuery, setUserQuery] = useState<string>('');
  const [isValidQuery, setIsValidQuery] = useState<boolean>(true); //:NOTE: use setIsValidQuery when Ben has the validation function
  const [attributeList, setAttributeList] = useState<string[]>([]);
  const classes = useStyles({});

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const attributesList = await getDistinctAttributes();
        setAttributeList(attributesList.map(({ value }) => value));
      } catch (error) {
        console.warn('Could not get attribute List');
      }
    };
    fetchAttributes();
  }, []);

  useEffect(() => {
    onChangeQuery(userQuery);
    //:TODO: check if user query is valid - (Ben is working on it)
    // if (not valid) {
    // setIsValidQuery(false);
    // }
  }, [onChangeQuery, userQuery]);

  const handleQueryToolbarRadioChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRadioValue(target.value);
  };

  const handleOnTextQueryChange = (value: string) => {
    setUserQuery(value);
  };

  const handleAddGroup = () => {
    //:TODO:
  };

  const handleActionOnTriggerValue = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    onChangeTriggerValue(target.value);
  };

  const handleActionOnTriggerType = (value: BigidSelectOption[]) => {
    onChangeTriggerType(value[0].label);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(userQuery).catch(err => {
      console.error("Can't copy query", err);
    });
  };

  return (
    <div>
      <BigidHeading5 className={classes.actionOnTriggerHeader}>Action on Trigger</BigidHeading5>
      <div className={classes.actionOnTrigger}>
        <div className={classes.actionOnTriggerInputs}>
          <BigidSelect
            displayLimit={5}
            onChange={handleActionOnTriggerType}
            options={selectOptions}
            value={[selectOptions[0]]}
          />
        </div>
        <div className={classes.actionOnTriggerInputs}>
          <BigidHeading6>Attribute</BigidHeading6>
          <div className={classes.asterisk}>
            <i className="fa fa-asterisk"></i>
          </div>
          <BigidHelpIcon className={classes.helpCircle} />
          <BigidTextField onChange={handleActionOnTriggerValue} placeholder="Attribute Name" />
        </div>
      </div>

      <div className={classes.queryToolbar}>
        <BigidRadioGroup
          defaultValue={queryBuilder}
          horizontal
          onChange={handleQueryToolbarRadioChange}
          options={[
            {
              label: 'Query Builder',
              value: queryBuilder,
            },
            {
              label: 'Input Query',
              value: 'inputQuery',
            },
          ]}
        />

        <div className={classes.toolbarButtons}>
          {radioValue === queryBuilder && (
            <div className={classes.toolbarSpaceing}>
              <PrimaryButton onClick={handleAddGroup} size="small" text="Add Group" startIcon={<PlusIcon />} />
            </div>
          )}

          <div className={classes.copyWrapper}>
            <TertiaryButton onClick={copyToClipboard} size="small" text="Copy Query" startIcon={<BigidCopyIcon />} />
          </div>
        </div>
      </div>

      {radioValue === queryBuilder ? (
        <div>query builder component</div>
      ) : (
        <div className={classes.autocompleteWrapper}>
          <BigidTextFieldAutocomplete
            autocompleteListItems={attributeList}
            triggerChar={'= '}
            onChange={handleOnTextQueryChange}
            rows={5}
            dropdownHeight={100}
            multiline
            errorMessage={isValidQuery ? '' : 'Invalid Query'}
            maxAutocompleteListLength={100}
            disableEnter
          />
        </div>
      )}
    </div>
  );
};

angular
  .module('app')
  .component(
    'actionOnTrigger',
    convertToAngular<ActionOnTriggerProps>(ActionOnTrigger, [
      'onChangeTriggerType',
      'onChangeTriggerValue',
      'onChangeQuery',
    ]),
  );
