import React from 'react';
import { BigidBody2, BigidColorsV2 } from '@bigid-ui/components';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material';

type ImageStyleProps = {
  src: string;
};

type OverlayStyleProps = {
  isBackgroundTransparent?: boolean;
};

type ConnectionGuideWrapperStyleProps = {
  showExpandIcon: boolean;
};

type ErrorInsightsRatingControlStyleProps = {
  isRated: boolean;
};

type SectionStyleProps = {
  width: string;
};

type CollaborationFormStyleProps = {
  showDivider: boolean;
  isColumn: boolean;
} & { theme: Theme };

type CollaborationActionsStyleProps = {
  isColumn: boolean;
} & { theme: Theme };

// @info Add global style overrides
export const GlobalStyles = (): JSX.Element => (
  <Global
    styles={css`
      .MuiDialog-root {
        z-index: 1049 !important;
      }

      .DataSourceConnectionConfiguration .MuiFormHelperText-root.Mui-error {
        height: auto !important;
      }
    `}
  />
);

export const GlobalCollaborationStyles = (): JSX.Element => (
  <Global
    styles={css`
      [data-aid='BigidGuidedTourTooltip-close'] > svg {
        color: white;
      }
    `}
  />
);

export const Footer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  padding-right: 1.5rem;
  border-top: 1px solid ${BigidColorsV2.gray[200]};

  & button,
  & button:hover,
  & button:disabled {
    border: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderImage = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  border: 1px solid ${BigidColorsV2.gray[200]};
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

export const HeaderCheckbox = styled.div``;

export const Image = styled.div`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: ${(props: ImageStyleProps) => (props?.src ? `url('${props.src}')` : 'none')};
`;

export const Content = styled.div`
  padding: 2rem;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;

  & .dataSourceConfigurationSection-header.MuiAccordionSummary-root {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 0;

    & .MuiAccordionSummary-expandIconWrapper {
      margin-left: 0px;
      margin-right: 8px;
      transform: rotate(270deg);
    }

    & .Mui-expanded.MuiAccordionSummary-expandIconWrapper {
      transform: rotate(360deg);
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 0.5rem;
`;

export const Field = styled.div``;

export const Section = styled.div`
  max-width: ${(props: SectionStyleProps) => props.width ?? '60%'};
  margin-top: 0.1rem;

  & .MuiAccordionSummary-root {
    justify-content: flex-start;
    border: none;
  }

  & .MuiAccordionSummary-contentGutters > p {
    font-size: 14px;
    font-weight: 700;
  }

  & .MuiFormControl-root {
    flex-direction: column;
  }

  & .MuiAccordionSummary-content,
  & .MuiAccordionSummary-content.Mui-expanded {
    max-width: 200px;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ isBackgroundTransparent = true }: OverlayStyleProps) =>
    isBackgroundTransparent ? 'initial' : 'white'}; ;
`;

export const AsideWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
`;

export const AsideContent = styled.div`
  overflow: auto;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const AsideDescription = styled.div`
  margin-top: -15px;
`;

export const MessageWrapper = styled.div`
  position: relative;
  width: calc(100% + 4rem);
  left: 0;
  bottom: 0;
  margin-left: -2rem;

  & .MuiAlert-message {
    width: 100% !important;
  }
`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
`;

export const MessageLink = styled.div`
  margin-left: auto;
  margin-right: 0.5rem;
`;

export const Prerequisites = styled.div`
  margin-top: 1rem;
`;

export const ConnectionGuideWrapper = styled.div`
  margin-top: 1.5rem;

  .MuiAccordionSummary-expandIconWrapper {
    display: ${(props: ConnectionGuideWrapperStyleProps) => (props.showExpandIcon ? 'initial' : 'none')};
  }

  .MuiAccordionSummary-content {
    cursor: ${(props: ConnectionGuideWrapperStyleProps) => (props.showExpandIcon ? 'pointer' : 'default')};
  }

  .DataSourceConnectionGuideTab .MuiAccordionSummary-expandIconWrapper,
  .DataSourceConnectionGuideTab .MuiAccordionSummary-content {
    display: initial;
    cursor: 'pointer';
  }
`;

export const ConnectionGuideTitle = styled.div`
  min-height: 54px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 0.25rem;
  padding-right: 0.5rem;
  align-items: center;
`;

export const ConnectionGuideLoader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const ConnectionGuideImage = styled.div`
  color: ${BigidColorsV2.blue[900]};
  display: inline-block;
  position: relative;
  top: 0.15rem;
  left: 0.1rem;
`;

export const ConnectionGuideTab = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ConnectionGuideTabNoContent = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

export const ErrorInsightsWrapper = styled.div`
  margin-bottom: 3rem;

  & div[type='error'] {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
  }
`;

export const ErrorInsightsContent = styled.div`
  position: relative;
  left: -30px;
  width: 480px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
`;

export const ErrorInsightsTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 380px;
  overflow: hidden;
`;

export const ErrorInsightsSupport = styled.div`
  display: flex;
  align-items: center;
  & button,
  & button:hover {
    background-color: transparent;
  }
`;

export const ErrorInsightsBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 0.75rem;
  align-items: center;
  margin-top: 0.5rem;
  padding-left: 0.5rem;

  & button,
  & button:hover {
    background-color: transparent;
  }
`;

export const ErrorInsightsOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

export const ErrorInsightsLogs = styled.div`
  position: absolute;
  right: 0.1rem;
  top: -1.8rem;

  & a {
    color: ${BigidColorsV2.blue[900]};
  }
`;

export const ErrorInsightsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${BigidColorsV2.gray[200]};
  height: 40px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
`;

export const ErrorInsightsDetails = styled.div`
  margin-bottom: 0rem;
`;

export const ErrorInsightsControls = styled.div`
  display: flex;
  gap: 0.5em;
`;

export const ErrorInsightsRatingControl = styled.div`
  .MuiButton-root {
    background-color: ${({ isRated }: ErrorInsightsRatingControlStyleProps) => (isRated ? '#2222221F' : 'initial')};
    border-radius: 4px;
  }
`;

export const ModalContentLoader = styled.div`
  width: 100%;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Accordion = styled.div``;

export const CollaborationForm = styled.div`
  ${({ showDivider, isColumn, theme }: CollaborationFormStyleProps) =>
    `
border-bottom: ${showDivider ? `1px solid ${theme.vars.palette.bigid.gray200}` : 'none'};
padding-bottom: ${showDivider ? '1rem' : '0'};
& form fieldset:nth-child(1) {
    width: 100%;
    max-width: ${isColumn ? '88%' : '100%'};
    flex: 1 1;
  }

  & form fieldset:nth-child(2) {
    width: 30%;
  }
`}

  & form fieldset:nth-child(3) {
    width: 100%;
    height: 200px;
  }

  & form > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 1rem;
  }
`;

export const CollaborationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.25rem 0.25rem 1.25rem 0.25rem;

  & .DataSourceConnectionCollaborationGrid > div {
    max-height: 272px !important;
    & > div:first-child {
      padding-bottom: 8px;
    }
  }

  & [data-aid*='field-collaborators-bar-chips'] {
    min-height: 34px;
  }

  & [data-aid='BigidDropdown-list-option-revoke'] {
    & > div {
      padding: 0.2rem 0 !important;
      border-top: ${({ theme }) => `1px solid ${theme.vars.palette.bigid.gray200} !important;`};
    }
  }
`;

export const CollaborationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CollaborationActions = styled.div`
  position: absolute;

  & button {
    z-index: 1;
  }

  ${({ isColumn }: CollaborationActionsStyleProps) => `
    top: ${isColumn ? '79px' : '120px'};
    right: 20px;
  `}
`;

export const CollaborationMessage = styled.div`
  width: 100%;

  & .MuiAccordionDetails-root {
    padding: 0;
  }

  & button {
    font-weight: normal;
  }
`;

export const CollaborationField = styled.div`
  & [data-aid='BigidDropdown-bar'] {
    & > div {
      min-height: 34px !important;
    }
  }
`;

export const CollaborationTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`;

export const CollaborationFooterContent = styled(BigidBody2)`
  ${({ compact = true }: { compact?: boolean }) =>
    compact &&
    `max-width: 412px;
     overflow: hidden;
    text-overflow: ellipsis;`}
`;
export const CollaborationNotification = styled.div`
  & > div {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    height: 40px;

    & > div > div:first-child {
      display: flex;
      align-items: center;
    }
  }
`;

export const CollaborationGrid = styled.div`
  & .MuiTableRow-root {
    & .MuiTableCell-root {
      padding: 0 !important;
    }
  }

  & td {
    border: none !important;
  }

  & .DataSourceConnectionCollaborationGrid > div > div {
    border: none !important;
  }

  & .MuiListItemText-root > span {
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const CollaborationOption = styled.div`
  position: relative;
  left: -5px;

  & > button {
    background-color: initial !important;
  }

  .MuiTypography-root,
  svg {
    color: ${({ theme }) => `${theme.vars.palette.bigid.red500} !important;`};
  }
`;

export const CollaborationGuidedTour = styled.div``;
