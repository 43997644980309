import React, { FC, useEffect, useState, ReactNode } from 'react';
import styled from '@emotion/styled';
import { BigidDownloadIcon } from '@bigid-ui/icons';
import { RiskAssessmentService } from './RiskAssessmentService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { RiskAssessmentUITrackingEvent } from './RiskAssessmentEventTrackerUtils';
import { useLocalTranslation } from './translations';
import { startCase } from 'lodash';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  BigidLoader,
  BigidHeading2,
  BigidColorsV2,
  SecondaryButton,
  BigidRiskAssessmentReport,
} from '@bigid-ui/components';
import { RiskAssessmentEmptyState } from './RiskAssessmentEmptyState';

export const PAGE_TITLE = 'Risk Assessment';
const ReportWrapper = styled('div')({
  background: 'white',
  margin: '2% 10%',
  borderRadius: 8,
  boxShadow: `1px 1px 1px ${BigidColorsV2.gray[400]}`,
});

const ReportHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: 30,
});

export const RiskAssessment: FC = ({}) => {
  const { t } = useLocalTranslation('main');
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);

  pageHeaderService.setTitle({ pageTitle: PAGE_TITLE });

  useEffect(() => {
    const initialSetup = async () => {
      setIsLoading(true);
      const reportDataResponse = await RiskAssessmentService.getRiskAssessmentData();
      setReportData({
        ...reportDataResponse,
        classifiersLink: `/#/${CONFIG.states.CLASSIFIERS}`,
        policiesLink: `/#/${CONFIG.states.POLICIES}`,
      });
      setIsLoading(false);
    };
    if (getApplicationPreference('RISK_ASSESSMENT_ENABLED')) {
      initialSetup();
      userPreferencesService.setSharedBooleanUserPreference(
        userPreferencesService.SHARED_PREFERENCES.RISK_ASSESSMENT_REPORT_WAS_VISITED,
        true,
      );
    } else {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, []);

  const getTitle = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    const prettyDate = `${month} ${day}, ${year}`;
    return `${startCase(reportData?.companyName)} | ${prettyDate}`;
  };

  const renderReport = (): ReactNode => {
    return (
      <>
        <ReportHeader>
          <BigidHeading2>{getTitle()}</BigidHeading2>
          <SecondaryButton
            endIcon={<BigidDownloadIcon color="inherit" />}
            onClick={() => {
              RiskAssessmentService.downloadRiskAssessmentPdf();
            }}
            bi={{ eventType: RiskAssessmentUITrackingEvent.RISK_ASSESSMENT_PDF_DOWNLOAD }}
            size="medium"
            text={t('buttonText')}
          />
        </ReportHeader>
        <BigidRiskAssessmentReport reportData={reportData} />
      </>
    );
  };

  const renderSwitch = () => {
    return reportData?.objectSummaryByDataType?.summaryByTypes?.length > 0 ? (
      renderReport()
    ) : (
      <RiskAssessmentEmptyState />
    );
  };

  return isLoading ? <BigidLoader /> : <ReportWrapper>{renderSwitch()}</ReportWrapper>;
};
