import { module } from 'angular';
import { $injector } from 'ngimport';
const app = module('app');

export let credentialsService;

app.factory('credentialsService', [
  '$http',
  'appSettings',
  function ($http, appSettings) {
    return {
      getCredentials: () =>
        $http.get(`${appSettings.serverPath}/${appSettings.version}/credentials`).then(response => response),

      getCredential: id =>
        $http
          .get(`${appSettings.serverPath}/${appSettings.version}/credentials/${encodeURIComponent(id)}`)
          .then(response => response),

      createCredential: data =>
        $http.post(`${appSettings.serverPath}/${appSettings.version}/credentials`, data).then(response => response),

      updateCredential: (id, data) =>
        $http
          .put(`${appSettings.serverPath}/${appSettings.version}/credentials/${encodeURIComponent(id)}`, data)
          .then(response => response),

      deleteCredential: id =>
        $http
          .delete(`${appSettings.serverPath}/${appSettings.version}/credentials/${encodeURIComponent(id)}`)
          .then(response => response),

      testConnection: data =>
        $http
          .post(`${appSettings.serverPath}/${appSettings.version}/credentials/credentials-providers/test`, data)
          .then(response => response.data),
    };
  },
]);

app.run(() => {
  credentialsService = $injector.get('credentialsService');
});
