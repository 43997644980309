import { BigidAttributeIcon, BigidTermIcon } from '@bigid-ui/icons';
import { styled } from '@mui/system';
import React, { ChangeEvent, FC } from 'react';
import { useCardModalContext } from '../../CardModalContext';
import { useLocalTranslation } from '../../translations';
import { InlineInput } from './InlineInput';

const InputContainer = styled('div')`
  max-width: 300px;
  gap: 14px;
  display: flex;
  align-items: center;
`;

export const NameSection: FC = () => {
  const {
    state: {
      headerState: { name },
      isNewCard,
      type,
    },
    dispatchAction,
  } = useCardModalContext();

  const { t } = useLocalTranslation('Header.Naming');

  const handleNameChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatchAction({
      type: 'SET_PARTIAL_HEADER_STATE',
      payload: { name: target.value },
    });
  };

  const Icon = type === 'attribute' ? BigidAttributeIcon : BigidTermIcon;

  const placeholder = type === 'attribute' ? t('attribute') : t('term');

  return (
    <InputContainer>
      <Icon />
      <InlineInput value={name} onChange={handleNameChange} placeholder={placeholder} readOnly={!isNewCard} />
    </InputContainer>
  );
};
