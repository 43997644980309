import { BigidBody3, BigidColorsV2, BigidHeading4 } from '@bigid-ui/components';
import { BigidLockIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { TextAreaProps } from './TextArea';

const AreaTitleContainer = styled('div')`
  display: flex;
  gap: 12px;
`;

type TitleProps = Pick<TextAreaProps, 'title' | 'disabled'>;

export const AreaTitle: FC<TitleProps> = ({ title, disabled }) => {
  if (disabled) {
    return (
      <AreaTitleContainer>
        <BigidLockIcon />
        <BigidBody3 color={BigidColorsV2.gray[500]}>{title}</BigidBody3>
      </AreaTitleContainer>
    );
  }

  return <BigidHeading4>{title}</BigidHeading4>;
};
