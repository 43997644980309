import React from 'react';
import angular from 'angular';
import { convertToAngular } from '../../../../common/services/convertToAngular';
import { SarProfileSettings } from './SarProfileSettings';
import { SarProfileSettingsV2 } from './SarProfileSettingsV2';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { SarProfileSettingsDetailsPage } from './SarProfileSettingsDetailsPage';

export const SarProfileSettingsWrapper = () => {
  const isNewProfileSettingsPage = getApplicationPreference('DSAR_USE_CATALOG_COLUMNS');

  if (!isNewProfileSettingsPage) {
    return <SarProfileSettings />;
  }

  return (
    <>
      <SarProfileSettingsV2 />
      <SarProfileSettingsDetailsPage />
    </>
  );
};

angular.module('app').component('sarProfileSettingsWrapper', convertToAngular(SarProfileSettingsWrapper));
