import { BigidBody2, BigidDropdown, BigidDropdownOption, BigidTextField } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { intersectionBy } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocalTranslation } from '../../../../../translations';
import { fetchUsers, mapUsersToDropdownOption, OwnerDetails } from '../../../domainManagementService';
import { useDomainCreationContext } from '../../DomainCreationContext';

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  justify-content: space-between;
`;

const Section = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const DropdownSection = styled('section')`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 82%;
  padding-bottom: 32px;
`;

const ListItem = styled('div')`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
`;

const DropdownWrapper = styled('div')`
  width: 265px;
`;

export const ModalForm: FC = () => {
  const { t } = useLocalTranslation('Settings.DomainModal.Content');

  const {
    state: { businessOwners, domainOwners, itOwners, name, description },
    dispatchAction,
  } = useDomainCreationContext();

  const [userList, setUserList] = useState<OwnerDetails[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      const users = await fetchUsers();
      setUserList(users);
    };
    getUsers();
  }, []);

  const handleNameEdit = (name: string) => {
    dispatchAction({ type: 'UPDATE_PARTIAL', payload: { name } });
  };

  const handleDescriptionEdit = (description: string) => {
    dispatchAction({ type: 'UPDATE_PARTIAL', payload: { description } });
  };

  const handleBusinessOwnerSelect = (options: BigidDropdownOption[]) => {
    const businessOwners = intersectionBy(userList, options, 'id');
    dispatchAction({ type: 'UPDATE_PARTIAL', payload: { businessOwners } });
  };

  const handleItOwnerSelect = (options: BigidDropdownOption[]) => {
    const itOwners = intersectionBy(userList, options, 'id');
    dispatchAction({ type: 'UPDATE_PARTIAL', payload: { itOwners } });
  };

  const handleDomainOwnerSelect = (options: BigidDropdownOption[]) => {
    const domainOwners = intersectionBy(userList, options, 'id');

    dispatchAction({ type: 'UPDATE_PARTIAL', payload: { domainOwners } });
  };

  const businessOwnerOptions = useMemo(() => {
    const options = mapUsersToDropdownOption(userList, businessOwners);

    return options;
  }, [businessOwners, userList]);

  const domainOwnerOptions = useMemo(() => {
    const options = mapUsersToDropdownOption(userList, domainOwners);
    return options;
  }, [domainOwners, userList]);

  const itOwnerOptions = useMemo(() => {
    const options = mapUsersToDropdownOption(userList, itOwners);
    return options;
  }, [itOwners, userList]);

  return (
    <FormContainer>
      <Section>
        <BigidTextField onChange={e => handleNameEdit(e.target.value)} label={t('nameLabel')} value={name} required />
        <BigidTextField
          onChange={e => handleDescriptionEdit(e.target.value)}
          multiline
          label={t('descriptionLabel')}
          value={description}
          rows={8}
        />
      </Section>
      <DropdownSection>
        <ListItem>
          <BigidBody2>{t('businessOwnerLabel')}</BigidBody2>
          <DropdownWrapper>
            <BigidDropdown
              options={businessOwnerOptions}
              value={businessOwnerOptions.filter(option => option.isSelected)}
              isMulti
              onSelect={handleBusinessOwnerSelect}
            />
          </DropdownWrapper>
        </ListItem>
        <ListItem>
          <BigidBody2>{t('itOwnerLabel')}</BigidBody2>
          <DropdownWrapper>
            <BigidDropdown
              options={itOwnerOptions}
              value={itOwnerOptions.filter(option => option.isSelected)}
              isMulti
              onSelect={handleItOwnerSelect}
            />
          </DropdownWrapper>
        </ListItem>
        <ListItem>
          <BigidBody2>{t('domainOwnerLabel')}</BigidBody2>
          <DropdownWrapper>
            <BigidDropdown
              options={domainOwnerOptions}
              value={domainOwnerOptions.filter(option => option.isSelected)}
              isMulti
              onSelect={handleDomainOwnerSelect}
            />
          </DropdownWrapper>
        </ListItem>
      </DropdownSection>
    </FormContainer>
  );
};
