import { FC } from 'react';
import { BigidFieldRenderProps } from '@bigid-ui/components';
import { FormCredentialField } from '../fields/FormCredentialField';
import { FormSystemUserSelectField, FormSystemUserSelectFieldProps } from '../fields/FormSystemUserSelectField';
import { FormScopeField } from '../fields/FormScopeField';
import { FormCertificateField } from '../fields/FormCertificatesField';
import { FormScanWindowField } from '../fields/FormScanWindowField';

export const SOURCE_TO_COMPONENT_MAP: Record<string, FC<BigidFieldRenderProps> | FC<FormSystemUserSelectFieldProps>> = {
  credentialIDs: FormCredentialField,
  systemUsers: FormSystemUserSelectField,
  scopes: FormScopeField,
  certificates: FormCertificateField,
  scanWindow: FormScanWindowField,
};
