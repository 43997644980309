import React, { forwardRef, memo, useImperativeHandle, useMemo, useRef } from 'react';
import {
  BigidForm,
  BigidFormProps,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
} from '@bigid-ui/components';
import { Content, FormWrapper } from './ProxiesStyles';
import { ForwardFormRef, ProxyGridRow } from './types';
import { getFormFields, getInitialValues } from './utils';
import { useRenderFields } from './hooks/useRenderFields';
import { CredentialItemResponse } from '../Credentials/types';
import { CertificateModel } from '../CertificatesManagement/CertificatesManagement';

interface CredentialsFormProps {
  row: ProxyGridRow;
  formControls: React.MutableRefObject<BigidFormStateAndHandlers>;
  setFormIsTouched: (isTouched: boolean) => void;
  credentialsOptions: CredentialItemResponse[];
  certificatesOptions: CertificateModel[];
}

export const ProxiesForm = forwardRef<ForwardFormRef, CredentialsFormProps>(
  ({ row, formControls, setFormIsTouched, credentialsOptions, certificatesOptions }, ref) => {
    const setFieldValueRef = useRef<(fieldname: string, value: any) => void>();
    const formElementRef = useRef<HTMLDivElement>();
    const { fieldsToRender } = useRenderFields();
    const fields = getFormFields({ credentialsOptions, certificatesOptions });
    const initialValues = useMemo(() => getInitialValues(fields, row), [row, fields]);
    useImperativeHandle(
      ref,
      () => ({
        fieldsToRender: fieldsToRender.map(({ name }) => name),
        formElement: formElementRef.current,
      }),
      [fieldsToRender],
    );

    const formProps: BigidFormProps = useMemo(
      () => ({
        controlButtons: false,
        validateOn: BigidFormValidateOnTypes.SUBMIT,
        stateAndHandlersRef: formControls,
        initialValues,
        displayFormLevelError: true,
        fields: fields.filter(({ name }) => fieldsToRender.map(({ name }) => name).includes(name)),
        onChange: (values, { setFieldValue }) => {
          setFieldValueRef.current = setFieldValue;
          setTimeout(() => {
            setFormIsTouched(formControls.current?.formTouched);
          }, 0);
        },
        renderForm: ({ renderField, formLevelError, formLevelErrorDisplayed, getValues }: BigidFormRenderProps) => {
          const values = getValues();
          return (
            <>
              {fieldsToRender
                .filter(field => ['name', 'description', 'protocolsLabel'].includes(field.name))
                .map(({ name }) => renderField(name))}
              <div data-aid="checkBoxesWrapper">
                {renderField('http')}
                {renderField('https')}
              </div>
              {values['http'] === true && (
                <>
                  {renderField('httpProxyHost')}
                  {renderField('httpProxyPort')}
                </>
              )}
              {values['https'] === true && (
                <>
                  {renderField('httpsProxyHost')}
                  {renderField('httpsProxyPort')}
                </>
              )}
              {renderField('credentialsId')}
              {renderField('certificateId')}
              <>{formLevelErrorDisplayed && formLevelError}</>
            </>
          );
        },
      }),
      [fields, fieldsToRender, formControls, initialValues, setFormIsTouched],
    );

    return (
      <Content ref={formElementRef}>
        <FormWrapper>
          <BigidForm {...formProps} />
        </FormWrapper>
      </Content>
    );
  },
);

export const ProxiesFormMemo = memo(ProxiesForm);
