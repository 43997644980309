import {
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  AdvancedToolbarOverrideValue,
} from '@bigid-ui/components';
import { isEmpty, pick } from 'lodash';

type MinifierDictionary = Record<string, string>;

const blacklistProps = ['value', 'v'];

const minifierDictionaryMap: MinifierDictionary = {
  id: 'id',
  options: 'o',
  type: 't',
  value: 'v',
  displayValue: 'dv',
  currentRangeOptionSelected: 'r',
  pickersState: 'ps',
  dates: 'ds',
  from: 'fr',
  until: 'un',
  currentMode: 'cm',
};

export type AdvancedToolbarOverrideValueMinified = object;

export function getFilterCompressedToOverrideValue(
  filter: BigidAdvancedToolbarFilterUnion,
): AdvancedToolbarOverrideValue {
  switch (filter.type) {
    case BigidAdvancedToolbarFilterTypes.DROPDOWN:
      return {
        ...pick(filter, ['id', 'parentId', 'type']),
        options: filter.options.map(option => pick(option, ['id', 'value', 'displayValue'])),
      };
    case BigidAdvancedToolbarFilterTypes.DATE_RANGE:
      return pick(filter, ['id', 'type', 'options']);
  }
}

function getMinifiedFilterKey(key: string) {
  return minifierDictionaryMap[key] ?? key;
}

function getUnminifiedFilterKey(minifiedKey: string) {
  return getObjectKeys<MinifierDictionary>(minifierDictionaryMap).find(
    key => minifierDictionaryMap[key] === minifiedKey,
  ) as unknown as keyof AdvancedToolbarOverrideValue;
}

function getObjectKeys<T = any>(object: T) {
  return Object.keys(object) as Array<keyof T>;
}

export function getFilterMinified(filter: BigidAdvancedToolbarFilterUnion): AdvancedToolbarOverrideValueMinified {
  function minify<T = object>(filter: T): object {
    return getObjectKeys<T>(filter).reduce((minifiedObject, key) => {
      const minifiedKey = getMinifiedFilterKey(key as string);
      const filterValue = filter[key] as unknown;

      if (blacklistProps.includes(key as string)) {
        return {
          ...minifiedObject,
          [minifiedKey]: filterValue,
        };
      } else {
        switch (true) {
          case Array.isArray(filterValue): {
            return {
              ...minifiedObject,
              [minifiedKey]: (filterValue as any[]).map(value => minify(value as object)),
            };
          }
          case typeof filterValue === 'object' && !isEmpty(filterValue): {
            return {
              ...minifiedObject,
              [minifiedKey]: minify(filterValue as object),
            };
          }
          default:
            return {
              ...minifiedObject,
              [minifiedKey]: filterValue,
            };
        }
      }
    }, {});
  }

  const overrideValue = getFilterCompressedToOverrideValue(filter);

  return overrideValue ? minify<AdvancedToolbarOverrideValue>(overrideValue) : overrideValue;
}

export function getFilterUnminified(
  minifiedFilter: AdvancedToolbarOverrideValueMinified,
): AdvancedToolbarOverrideValue {
  function unminify(filter: object): AdvancedToolbarOverrideValue {
    return getObjectKeys(filter).reduce((unminifiedObject, key) => {
      const unminifiedKey = getUnminifiedFilterKey(key as string);
      const filterValue = filter[key] as unknown;

      if (blacklistProps.includes(key as string)) {
        return {
          ...unminifiedObject,
          [unminifiedKey]: filterValue,
        };
      } else {
        switch (true) {
          case Array.isArray(filterValue): {
            return {
              ...unminifiedObject,
              [unminifiedKey]: (filterValue as any[]).map(value => unminify(value as object)),
            };
          }
          case typeof filterValue === 'object' && !isEmpty(filterValue): {
            return {
              ...unminifiedObject,
              [unminifiedKey]: unminify(filterValue as object),
            };
          }
          //NOTE: This is a special case for date range filter, where we need to convert the string to Date object
          case ['from', 'until'].includes(unminifiedKey): {
            return {
              ...unminifiedObject,
              [unminifiedKey]: new Date(filterValue as string),
            };
          }
          default:
            return {
              ...unminifiedObject,
              [unminifiedKey]: filterValue,
            };
        }
      }
    }, {} as unknown as AdvancedToolbarOverrideValue);
  }

  return unminify(minifiedFilter);
}
