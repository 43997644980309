import React, { FC } from 'react';
import { BigidLink } from '@bigid-ui/components';
import { Trans } from 'react-i18next';
import { useLocalTranslation } from './translations';
import { docsUrls } from '../../../../config/publicUrls';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';

export const RiskGroupsBarChartTooltip: FC = () => {
  const { t } = useLocalTranslation('tooltip');

  const handleLinkClick = () => {
    $state.go(CONFIG.states.RISK_CONFIGURATION);
  };

  return (
    <Trans
      t={t}
      i18nKey="text"
      components={{
        link: <BigidLink text={t('linkText')} onClick={handleLinkClick} underline="none" />,
      }}
    />
  );
};
