import { notificationService } from '../../../../services/notificationService';
import { format } from 'date-fns';
import { rolesService } from '../../../../services/angularServices';
import {
  BigidDropdownOption,
  EntityEvents,
  entityEventsEmitter,
  BigidFormFieldTypes,
  BigidFieldFilterOperator,
  BigidFormField,
  BigidFormValues,
} from '@bigid-ui/components';
import { httpService } from '../../../../services/httpService';
import { getFixedT } from '../../translations';
import { CaseModel, CaseStatus, ViewType } from '../../actionableInsightsService';
import { OpenServiceTicketMetadata } from '../../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import {
  loadJiraUsersSearchOptions,
  ISSUE_TYPE_FIELD_NAME,
} from '../../../ActionCenter/ActionWorkflow/ActionWorkflowContent/ConfigureActionWorkflow/ActionForms/OpenServiceTicket/openServiceTicketUtils';
import { queryService } from '../../../../services/queryService';
import { Filters } from '../../../../types/actionableInsights';
import { ActionsDialogTypes } from './hooks/useCaseActions';

export type RBACUser = {
  _id: string;
  name: string;
  firstName: string;
  lastName: string;
};

export interface ModalContentProps {
  closeModal: () => void;
  rowsData: CaseModel[];
  onRemediateCase?: () => void;
  onAcknowledgeCase?: () => void;
  onSilenceCase?: () => void;
  onAssignCase?: (assignee: string) => void;
  viewType?: ViewType;
  filters?: Filters;
}

export interface OptionalFields {
  dueDate?: boolean;
  priority?: boolean;
}

export interface JiraTicketData {
  configurationId: string;
  summary: string;
  issueType: string;
  description: string;
  assignee: string;
  priority?: string;
  duedate?: string;
  dueDate?: string; // due to BCO-9894 - Jira fields convention issue
}

export enum JiraValidationErrorType {
  CANT_VERIFY = 'cantVerify',
  REQUEST_FAILURE = 'requestFailure',
}

export interface JiraValidationResult {
  isValid: boolean;
  errorType?: JiraValidationErrorType;
  title?: string;
  message?: string;
}

const tErrorMessages = getFixedT('Action.common.errors');
const tNotificationMessages = getFixedT('Action.common.notifications');
const tActionLabels = getFixedT('Action.label');
const tJiraForm = getFixedT('Action.jira.form');
const tPriorityOptions = getFixedT('Action.jira.priorityOptions');
const tJiraTicketDesc = getFixedT('Action.jira.ticketDescription');

export enum JiraProirity {
  HIGHEST = '1',
  HIGH = '2',
  MEDIUM = '3',
  LOW = '4',
  LOWEST = '5',
}

export const jiraPriortyTypesDropdownOptions = [
  {
    value: JiraProirity.HIGHEST,
    displayValue: tPriorityOptions('highest'),
    id: JiraProirity.HIGHEST,
  },
  {
    value: JiraProirity.HIGH,
    displayValue: tPriorityOptions('high'),
    id: JiraProirity.HIGH,
  },
  {
    value: JiraProirity.MEDIUM,
    displayValue: tPriorityOptions('medium'),
    id: JiraProirity.MEDIUM,
  },
  {
    value: JiraProirity.LOW,
    displayValue: tPriorityOptions('low'),
    id: JiraProirity.LOW,
  },
  {
    value: JiraProirity.LOWEST,
    displayValue: tPriorityOptions('lowest'),
    id: JiraProirity.LOWEST,
  },
];

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

type CaseActionBody = {
  type: string;
  subType: string;
  additionalProperties: {
    field: string;
    newValue: string;
    casesFilters?: {
      filterField: string;
      filterValues: string[];
    }[];
    auditReason?: string;
    allCases?: boolean;
  };
};

const buildFilters = (filters: Omit<Filters, 'callback'>) => {
  const casesFilters = [];

  if (filters.caseIds?.length) {
    casesFilters.push({
      filterField: '_id',
      filterValues: filters.caseIds,
    });
  }

  if (filters.policies?.length && !filters?.caseIds) {
    casesFilters.push({
      filterField: 'policyName',
      filterValues: filters.policies.map(policy => policy.policyName),
    });
  }

  if (filters.status) {
    casesFilters.push({
      filterField: 'caseStatus',
      filterValues: [filters.status],
    });
  }

  return casesFilters;
};

export const updateCaseAssignee = async (
  filters: Omit<Filters, 'callback'>,
  assignee: string,
  onAssignCase: (assignee: string, filters: Filters, modalType: ActionsDialogTypes) => void,
) => {
  const { caseIds, policies, status } = filters;
  const additionalProperties: any = {
    field: 'assignee',
    newValue: assignee,
    casesFilters: buildFilters({ caseIds, policies, status }),
  };

  try {
    const body: CaseActionBody = {
      type: 'CasesDB',
      subType: 'updateCases',
      additionalProperties,
    };

    await httpService.patch(`actionable-insights/cases:action`, body);
    onAssignCase(assignee, filters, ActionsDialogTypes.ASSIGN);
    notificationService.success(tNotificationMessages('caseSucessfullyAssigned', { assignee }));
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('updatingCaseAssignee'));
  }
};

export const fetchSystemUsers = async (): Promise<BigidDropdownOption[]> => {
  try {
    const {
      data: { users },
    } = await rolesService.getRBACUsers();
    return users.map((user: RBACUser) => ({
      id: user._id,
      value: user.name,
      displayValue: user.firstName && user.lastName ? `${user.firstName} ${user.lastName} (${user.name})` : user.name,
    }));
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('fetchingUsersInfo'));
  }
};

export const updateCaseStatus = async (
  filters: Omit<Filters, 'callback'>,
  caseStatus: CaseStatus,
  auditReason?: string,
  closeCaseCallback?: (caseStatus: CaseStatus) => void,
) => {
  const notifcactionStatus = tActionLabels(caseStatus);
  try {
    const additionalProperties: any = {
      field: 'caseStatus',
      newValue: caseStatus,
      casesFilters: buildFilters(filters),
      userName: 'TEST',
      ...(auditReason && { auditReason }),
    };

    await httpService.patch(`actionable-insights/cases:action`, {
      type: 'CasesDB',
      subType: 'updateCases',
      additionalProperties,
    });

    notificationService.success(tNotificationMessages('caseStatusUpdatedSucessfully', { notifcactionStatus }));
    closeCaseCallback?.(caseStatus);
  } catch (e) {
    console.error(e);
    notificationService.error(tErrorMessages('updateCaseStatus', { notifcactionStatus }));
  }
};

export const generateDropdownOptionsForIssueTypes = (data: OpenServiceTicketMetadata): BigidDropdownOption[] => {
  const issueTypeField = data.fields.find(field => field.name === ISSUE_TYPE_FIELD_NAME);
  return issueTypeField.options.map(option => {
    return { value: option.value, displayValue: option.label, id: option.value };
  });
};

export const generateJiraConfigurationFormFields = (
  data: OpenServiceTicketMetadata,
  configurationId: string,
  optionalFields: OptionalFields = {},
): BigidFormField[] => {
  return [
    {
      name: 'issueType',
      label: tJiraForm('issueType') as string,
      type: BigidFormFieldTypes.DROP_DOWN,
      dropDownOptions: generateDropdownOptionsForIssueTypes(data),
      isRequired: true,
      validate: (value: BigidDropdownOption[]) => {
        if (!value?.length) {
          return tJiraForm('issueTypeValidation') as string;
        }
        return false;
      },
    },
    {
      name: 'summary',
      label: tJiraForm('summary') as string,
      type: BigidFormFieldTypes.TEXT,
      isRequired: true,
      validate: (summary: string) => {
        if (!summary) {
          return tJiraForm('summaryValidation') as string;
        }
        return false;
      },
    },
    {
      name: 'assignee',
      label: tJiraForm('assignee') as string,
      type: BigidFormFieldTypes.SELECT,
      fieldProps: {
        isAsync: true,
        shouldLoadInitialOptions: true,
        loadOptions: loadJiraUsersSearchOptions(configurationId),
      },
      isRequired: true,
      validate: (assignee: string) => {
        if (!assignee) {
          return tJiraForm('assigneeValidation') as string;
        }
        return false;
      },
    },
    ...(optionalFields.priority
      ? [
          {
            name: 'priority',
            label: tJiraForm('priority') as string,
            type: BigidFormFieldTypes.DROP_DOWN,
            dropDownOptions: jiraPriortyTypesDropdownOptions,
          },
        ]
      : []),
    ...(optionalFields.dueDate
      ? [
          {
            name: 'dueDate',
            label: tJiraForm('dueDate') as string,
            type: BigidFormFieldTypes.DATEPICKER,
            fieldProps: {
              placeholder: tJiraForm('dueDatePlaceHolder'),
            },
          },
        ]
      : []),
    {
      name: 'description',
      label: tJiraForm('description') as string,
      misc: {
        rows: 6,
      },
      fieldProps: {
        multiline: true,
      },
      type: BigidFormFieldTypes.TEXTAREA,
      isRequired: false,
    },
  ];
};

export const generateJiraTicketDataFromFormValues = (
  values: BigidFormValues,
  configurationId = '',
): JiraTicketData => ({
  configurationId,
  summary: values.summary,
  issueType: values.issueType[0]?.value,
  ...(values.assignee?.length && { assignee: values.assignee[0]?.value }),
  ...(values.priority?.length && { priority: values.priority[0]?.value }),
  ...(values.description && { description: values.description }),
  ...(values.dueDate && { duedate: values.dueDate }),
});

export const createJiraTicket = async (ticketData: JiraTicketData, caseId: string) => {
  try {
    if (ticketData.duedate) {
      ticketData.dueDate = format(new Date(ticketData.duedate), 'yyyy-MM-dd');
      delete ticketData.duedate; // due to BCO-9894 - Jira fields convention issue
    }
    const reqBody = {
      type: 'jira',
      subType: 'createTicket',
      additionalProperties: ticketData,
    };
    const response = await httpService.post(`actionable-insights/cases/${caseId}:action`, reqBody);
    if (response?.status !== 200) {
      throw new Error(`Jira ticket creation fail with status code ${response?.status}`);
    }
  } catch (e) {
    throw new Error(e?.response?.data?.message || e);
  }
};

export const generateJiraTicketDescription = (data: Record<string, any>): string => {
  const ticketDesc = [];
  data.policyName && ticketDesc.push(`${tJiraTicketDesc('dataRiskType')}: ${data.policyName}\n`);
  data.dataSourceName && ticketDesc.push(`${tJiraTicketDesc('dataSourceName')}: ${data.dataSourceName}\n`);
  data.dataSourceOwner && ticketDesc.push(`${tJiraTicketDesc('dataSourceOwner')}: ${data.dataSourceOwner}\n`);
  data.numberOfAffectedObjects &&
    ticketDesc.push(`${tJiraTicketDesc('numberOfAffectedObjects')}: ${data.numberOfAffectedObjects}\n`);
  data.policyDescription && ticketDesc.push(`${tJiraTicketDesc('description')}: ${data.policyDescription}`);

  return ticketDesc.join('');
};

export const getJiraTicketWithRetryAndDelay = async (query: string, retries: number, delayInMs: number) => {
  let success = false;

  while (retries && !success) {
    const {
      data: {
        data: { cases },
      },
    } = await httpService.fetch(`actionable-insights/all-cases?${query}`);

    if (cases.length && cases[0].jiraTicketUrl?.includes('http')) {
      const jiraTicketNumber = cases[0].jiraTicketUrl?.split('/').at(-1);
      notificationService.success(tNotificationMessages('jiraTicketSuccessfullyCreated', { jiraTicketNumber }));
      entityEventsEmitter.emit(EntityEvents.UPDATE_BY_ID, cases[0].id, {
        jiraTicketUrl: cases[0].jiraTicketUrl,
        jiraTicketMetadata: cases[0].jiraTicketMetadata,
      });
      success = true;
      return success;
    }

    retries--;
    await delay(delayInMs);
  }
  return success;
};

export const validateJiraTicketCreationAndUpdateGrid = async (
  caseData: Record<string, any>,
): Promise<JiraValidationResult> => {
  try {
    const query = queryService.getGridConfigQuery({
      filter: [
        {
          field: 'dataSourceName',
          operator: 'equal' as BigidFieldFilterOperator,
          value: caseData.dataSourceName,
        },
        {
          field: 'policyName',
          operator: 'equal' as BigidFieldFilterOperator,
          value: caseData.policyName,
        },
      ],
      requireTotalCount: false,
      limit: 1,
    });
    const isJiraTicketCreated = await getJiraTicketWithRetryAndDelay(query, 3, 2000);
    if (isJiraTicketCreated) {
      return { isValid: true };
    } else {
      return {
        isValid: false,
        errorType: JiraValidationErrorType.CANT_VERIFY,
        title: "Can't verify ticket creation",
        message: 'Try again',
      };
    }
  } catch (e) {
    return {
      isValid: false,
      message: e.message,
      title: 'Error in creating the Jira ticket',
      errorType: JiraValidationErrorType.REQUEST_FAILURE,
    };
  }
};
