import { userPreferencesService } from '../../../services/userPreferencesService';
import { map, uniq } from 'lodash';
import {
  ACTION_DATA_KEY_BY_TYPE,
  AUTO_DISCOVER_PREREQUISITES_CONTENT_ID,
  DEFAULT_FORM_FIELDS_NAMES,
  DISCOVER_ACTION_TYPE_BY_AUTH_TYPE,
  DiscoveryErrors,
  ERRORS_MESSAGES_TO_KEY,
  TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS,
  TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS,
  TYPE_TO_VALUES_COMPUTE,
  TYPE_TO_VISIBLE_BY_AUTH_FIELDS,
  CloudProvider,
  ASSESSMENT_SCAN_KEY_NAME,
} from '../constants';
import { notificationService } from '../../../services/notificationService';
import { BigidFieldFilterOperator, BigidFormValues } from '@bigid-ui/components';
import { ActionItemParamsEntity } from '../../CustomApp/types';
import { AutoDiscoveryWizardContextState } from './autoDiscoveryWizardContext';
import { customAppService } from '../../../services/customAppService';
import { AutoDiscoveryWizardDsProps } from './AutoDiscoveryWizardMainModal/AutoDiscoveryWizardForm/AutoDiscoveryWizardDsList/AutoDiscoveryWizardDs';
import {
  AUTO_DISCOVERY_CHECK_PERMISSION_PARAM,
  extractPermissionsFromMessage,
  findActionByType,
  getAutoDiscoveryAppsConfigsList,
} from '../../../services/autoDiscoveryService';
import { PresetPayload } from '../../CustomApp/views/CustomAppActions/CustomAppActions';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { getDiscoverAuthTypeString } from './autoDiscoveryWizardFormUtils';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { sessionStorageService } from '../../../../common/services/sessionStorageService';
import { CredsStatus } from '../../Fmsd/FmsdComponents';

const DONT_SHOW_AGAIN_PREFERENCE_NAME = 'dontShowAgainAutoDiscoverPrerequisites';

export interface DoNotShowAgainPrerequisitesPreferences {
  typeNotShow: string[];
}

export interface CheckAutoDiscoveryConfigPermissions {
  discoveryConfigData: AutoDiscoveryWizardContextState;
  fieldsNamesByAuthTypeFields: string[];
  values: Record<string, any>;
}

export const getDoNotShowAgainPrerequisites = async () => {
  const result = await userPreferencesService.get<DoNotShowAgainPrerequisitesPreferences>(
    DONT_SHOW_AGAIN_PREFERENCE_NAME,
  );
  return result?.data?.typeNotShow;
};

export const onDoNotShowAgainPrerequisites = async (type: CloudProvider, checked: boolean) => {
  const preference = await getDoNotShowAgainPrerequisites();
  if (!preference) {
    if (checked) {
      await userPreferencesService.add<DoNotShowAgainPrerequisitesPreferences>({
        preference: DONT_SHOW_AGAIN_PREFERENCE_NAME,
        data: { typeNotShow: [type] },
      });
    }
  } else {
    await userPreferencesService.update<DoNotShowAgainPrerequisitesPreferences>({
      preference: DONT_SHOW_AGAIN_PREFERENCE_NAME,
      data: { typeNotShow: checked ? uniq([...preference, type]) : preference.filter(item => item !== type) },
    });
  }
};

export function getTextFromDomNode(node: Node): string {
  if (node.nodeType === Node.TEXT_NODE) {
    return node.textContent;
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    if ((node as HTMLLinkElement).tagName === 'A') {
      return `${node.textContent}: ${(node as HTMLLinkElement).href} `;
    } else {
      return map(node.childNodes, getTextFromDomNode).join('');
    }
  }
  return '';
}

export const getInstructionFromElementById = (id: string) => {
  const element = document.getElementById(id);
  return element ? getTextFromDomNode(element) : '';
};

export const copyPrerequisitesInstructions = async () => {
  const instruction = getInstructionFromElementById(AUTO_DISCOVER_PREREQUISITES_CONTENT_ID);
  try {
    await navigator.clipboard.writeText(instruction);
    notificationService.success('Instructions was copied to clipboard');
  } catch (err) {
    console.error("Can't copy Instructions", err);
  }
};

export async function findPresetInData(discoveryConfigData: AutoDiscoveryWizardContextState, appId: string) {
  const { data } = await customAppService.getCustomAppActions(appId);
  const authType = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
  const actionName = DISCOVER_ACTION_TYPE_BY_AUTH_TYPE[discoveryConfigData.type][authType];
  const runAction = findActionByType(actionName, discoveryConfigData.type, data);
  return runAction.presets.find((preset: PresetPayload) => preset.name === discoveryConfigData?.formData?.name);
}

export const runAutoDiscovery = async (
  discoveryConfigData: AutoDiscoveryWizardContextState,
  values: BigidFormValues,
) => {
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigData;
  await createAutoDiscoveryConfig(discoveryConfigData, values);

  const preset = await findPresetInData(discoveryConfigData, appId);
  return customAppService.runCustomAppPreset(appRunAction._id, preset._id, appId);
};

const prepareParamsKeyValue = (
  params: ActionItemParamsEntity[],
  values: { [p: string]: any },
  fieldsForAdd: string[],
) => {
  return params.reduce((acc, { param_name, default_value }) => {
    return {
      ...acc,
      [param_name]: fieldsForAdd.includes(param_name) ? values[param_name]?.trim?.() : default_value,
    };
  }, {});
};

export const convertSelectedAuthTypeToParams = (discoveryConfigData: AutoDiscoveryWizardContextState) => {
  const { formData, type } = discoveryConfigData;
  const authTypeValue = getDiscoverAuthTypeString(formData.authType);
  const valuesByAuth = TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS[type][authTypeValue] ?? {};
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const computedValues = TYPE_TO_VALUES_COMPUTE[type][authTypeValue]?.(formData) ?? {};
  const fieldsForAdd = [
    ...DEFAULT_FORM_FIELDS_NAMES,
    ...TYPE_TO_VISIBLE_BY_AUTH_FIELDS[discoveryConfigData.type][authTypeValue],
  ];

  return {
    ...prepareParamsKeyValue(
      discoveryConfigData[fieldInStateForGetConfig].appRunAction.params,
      { ...formData, ...computedValues },
      fieldsForAdd,
    ),
    ...valuesByAuth,
  };
};

export interface DiscoverActionItemParamsEntity extends ActionItemParamsEntity {
  input_items?: string[];
}

const getDsType = (type: CloudProvider, dsList?: AutoDiscoveryWizardDsProps[]) => {
  const dsType = dsList?.filter(({ selected }) => selected).map(({ id }) => id);
  return {
    ds_type: dsType?.length ? dsType.join(',') : 'all',
  };
};

export const createAutoDiscoveryConfig = async (
  discoveryConfigData: AutoDiscoveryWizardContextState,
  values?: BigidFormValues,
) => {
  const { name, dsList, authType } = values ?? discoveryConfigData.formData;
  const authTypeValue = getDiscoverAuthTypeString(authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigData;
  const paramsByAuthType = convertSelectedAuthTypeToParams(discoveryConfigData);
  const dsType = getDsType(discoveryConfigData.type, dsList);
  const isCheckAvailable = TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[discoveryConfigData.type][authTypeValue];
  const presetData = {
    name,
    description: '',
    paramsKeyValue: {
      ...paramsByAuthType,
      ...dsType,
      ...(isCheckAvailable
        ? {
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'false',
          }
        : {}),
      ...(isRunAndScanAvailable(discoveryConfigData)
        ? {
            [ASSESSMENT_SCAN_KEY_NAME]: values?.[ASSESSMENT_SCAN_KEY_NAME],
          }
        : {}),
    },
  };
  const result = await customAppService.addActionPreset(appId, appRunAction._id, presetData);
  return {
    result,
    fieldInStateForGetConfig,
  };
};

export const checkAutoDiscoveryConfigPermissions = async ({
  discoveryConfigData,
  fieldsNamesByAuthTypeFields,
  values,
}: CheckAutoDiscoveryConfigPermissions) => {
  const { authType } = discoveryConfigData.formData;
  const authTypeValue = getDiscoverAuthTypeString(authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
  const {
    [fieldInStateForGetConfig]: { appId, appRunAction },
  } = discoveryConfigData;
  try {
    const paramsByAuthType = convertSelectedAuthTypeToParams(discoveryConfigData);
    const paramsFromFormByAuth = fieldsNamesByAuthTypeFields.reduce(
      (acc, fieldName) => ({ ...acc, [fieldName]: values[fieldName] }),
      {},
    );
    const toUpdate = {
      actionParamsKeyValuesMap: [
        {
          actionId: appRunAction._id,
          paramsKeyValuesMap: {
            ...paramsByAuthType,
            ...paramsFromFormByAuth,
            [AUTO_DISCOVERY_CHECK_PERMISSION_PARAM]: 'true',
          },
        },
      ],
      paramsKeyValuesMap: {},
      generalKeyValuesMap: {},
    };

    await customAppService.updateCustomAppParamValues(appId, toUpdate);

    const { data } = await customAppService.runCustomAppPreset(appRunAction._id, '', appId);
    const connectionInfo = extractPermissionsFromMessage(data.data.message);
    const isValid = connectionInfo?.credsStatus === CredsStatus.VALID;
    const errorMessageKey =
      findErrorKeyFromMessage(data.data?.message) ?? (!isValid && DiscoveryErrors.PERMISSIONS_COMMON);

    return {
      connectionInfo,
      ...data.data,
      isValid,
      errorMessageKey,
    };
  } catch (err) {
    const message = err?.response?.data?.message ?? err?.message ?? err;
    const errorMessageKey = findErrorKeyFromMessage(message) ?? DiscoveryErrors.PERMISSIONS_COMMON;

    return {
      isValid: false,
      connectionInfo: { permissions: [], credsStatus: CredsStatus.CANT_EVALUATE },
      message,
      errorMessageKey,
    };
  }
};

export const loadDefaultConfigsForWizard = async (type: CloudProvider) => {
  const queryComponents = {
    filter: [
      {
        field: 'is_default',
        value: true,
        operator: 'eq' as BigidFieldFilterOperator,
      },
      {
        field: 'type',
        value: [type],
        operator: 'equal' as BigidFieldFilterOperator,
      },
    ],
  };
  const result = await getAutoDiscoveryAppsConfigsList(queryComponents);

  return {
    configData: result.data[0],
    configDataMultiply: result.data[1],
  };
};

export const goToTpaPresetEdit = async (discoveryConfigData: AutoDiscoveryWizardContextState) => {
  try {
    const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
    const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];
    const params = {
      id: discoveryConfigData[fieldInStateForGetConfig].appId,
      actionId: discoveryConfigData[fieldInStateForGetConfig].actionId,
      presetId: '',
      onReturnState: JSON.stringify({
        url: CONFIG.states.DATA_SOURCE_CONNECTIONS,
        params: {
          tab: 'discovery',
        },
      }),
    };
    if (discoveryConfigData.formData?.name) {
      await createAutoDiscoveryConfig(discoveryConfigData);
      const preset = await findPresetInData(discoveryConfigData, discoveryConfigData.configData.appId);
      params.presetId = preset._id;
    }
    $state.go(CONFIG.states.CUSTOM_APP_EDIT_PRESET, params);
  } catch (err) {
    notificationService.error('Problem with go to advanced edit');
  }
};

export function findErrorKeyFromMessage(messageInError?: string) {
  return messageInError
    ? ERRORS_MESSAGES_TO_KEY.find(item => item.messages.some(messagePart => messageInError.includes(messagePart)))?.key
    : DiscoveryErrors.NOT_FOUND;
}

export function getEmailCcForHelp() {
  const collaborateEmail = getApplicationPreference('AUTO_DISCOVERY_COLLABORATION_EMAIL');
  const userEmail = sessionStorageService.get('userEmail');
  return `cc=${userEmail}${collaborateEmail ? `,${collaborateEmail}` : ''}`;
}

export const isRunAndScanAvailable = (discoveryConfigData: AutoDiscoveryWizardContextState) => {
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData.authType);
  const fieldInStateForGetConfig = ACTION_DATA_KEY_BY_TYPE[discoveryConfigData.type][authTypeValue];

  return !!discoveryConfigData[fieldInStateForGetConfig]?.appRunAction?.params?.some(
    ({ param_name }) => param_name === ASSESSMENT_SCAN_KEY_NAME,
  );
};
