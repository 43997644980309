import React, { FC, useEffect, useRef, useContext, useState } from 'react';
import { useLocalTranslation } from '../../../../../translations';
import {
  BigidForm,
  BigidFormField,
  BigidFormProps,
  BigidFormValues,
  BigidFormStateAndHandlers,
  PrimaryButton,
  SecondaryButton,
  BigidLoader,
  BigidInlineNotification,
} from '@bigid-ui/components';
import { ActionableInsightsContext } from '../../../../../hooks/ActionableInsightsContext';
import styled from '@emotion/styled';
import { JiraContentTypes } from '../JiraModal';
import {
  generateJiraConfigurationFormFields,
  generateJiraTicketDataFromFormValues,
  generateJiraTicketDescription,
  createJiraTicket,
  validateJiraTicketCreationAndUpdateGrid,
  JiraValidationResult,
  JiraValidationErrorType,
} from '../../../caseActionsService';
import { JiraProjectSelector } from './JiraProjectSelector';
import { fetchOpenServiceTicketMetadata } from '../../../../../../ActionCenter/ActionWorkflow/actionWorkflowService';
import { OpenServiceTicketMetadata } from '../../../../../../ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { notificationService } from '../../../../../../../services/notificationService';
import { ActionableInsightsTrackingEvents } from '../../../../../../ActionableInsights/actionableInsightsTrackingUtil';
import { generateDataAid } from '@bigid-ui/utils';
import { CaseModel } from '../../../../../actionableInsightsService';

const MainContainer = styled.div({
  paddingBottom: 16,
  width: '100%',
  minHeight: 480,
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingTop: 8,
  marginTop: 'auto',
  '& > :first-child': {
    marginLeft: 8,
  },
});

const AlertContainer = styled.div({
  paddingBottom: 16,
});

export interface CreateJiraTicketProps {
  updateModalContent: React.Dispatch<React.SetStateAction<JiraContentTypes>>;
  closeModal: () => void;
  rowData: CaseModel;
}

export enum JiraTicketFields {
  DUE_DATE = 'duedate',
  PRIORITY = 'priority',
}

export const CreateJiraTicket: FC<CreateJiraTicketProps> = ({ closeModal, updateModalContent, rowData }) => {
  const { t } = useLocalTranslation();
  const formControls = useRef<BigidFormStateAndHandlers>();
  const issueIdRef = useRef('');
  const { jiraConfigurations, viewType } = useContext(ActionableInsightsContext);
  const [formFields, setFormFields] = useState<BigidFormField[]>([]);
  const [selectedJiraProjectName, setselectedJiraProjectName] = useState(
    jiraConfigurations.length ? jiraConfigurations[0].name : t('Action.jira.common.noConfiguredProjects'),
  );
  const [ticketMetadata, setTicketMetadata] = useState<OpenServiceTicketMetadata>();
  const [isLoading, setIsLoading] = useState(true);
  const [jiraValidationResult, setJiraValidationResult] = useState<JiraValidationResult>();
  const configDataId = jiraConfigurations?.find(config => config.name === selectedJiraProjectName)?.id;

  const onFormChange = (values: BigidFormValues) => {
    if (values?.issueType?.length) {
      const { id: selectedIssueId } = values.issueType[0];
      if (issueIdRef.current !== selectedIssueId) {
        const issueTypeFields = ticketMetadata.fieldsForIssueTypeMap[selectedIssueId];
        setFormFields(
          generateJiraConfigurationFormFields(ticketMetadata, configDataId, {
            dueDate: issueTypeFields.some(config => config.name === JiraTicketFields.DUE_DATE),
            priority: issueTypeFields.some(config => config.name === JiraTicketFields.PRIORITY),
          }),
        );
        issueIdRef.current = selectedIssueId;
      }
    }
  };

  const formProps: BigidFormProps = {
    fields: formFields,
    isFieldsBySelection: false,
    controlButtons: false,
    stateAndHandlersRef: formControls,
    onChange: onFormChange,
    initialValues: {
      dueDate: '',
      description: generateJiraTicketDescription(rowData),
      summary: rowData?.caseLabel,
    },
  };

  useEffect(() => {
    const fetchJiraMetadata = async () => {
      try {
        setIsLoading(true);
        const data: OpenServiceTicketMetadata = await fetchOpenServiceTicketMetadata(configDataId);
        setTicketMetadata(data);
        setFormFields(generateJiraConfigurationFormFields(data, configDataId));
        setIsLoading(false);
      } catch {
        notificationService.error(t('Action.common.errors.errorFetchingJiraConfig'));
        closeModal();
      }
    };
    fetchJiraMetadata();
  }, [closeModal, configDataId, t]);

  const updateSelectedJiraProject = (projectName: string) => {
    if (projectName !== selectedJiraProjectName) {
      setIsLoading(true);
      setselectedJiraProjectName(projectName);
    }
  };

  const onCreateButtonClick = async () => {
    formControls.current.validateAndSubmit(async (values: BigidFormValues) => {
      setIsLoading(true);
      try {
        await createJiraTicket(generateJiraTicketDataFromFormValues(values, configDataId), rowData?.id);
        const jiraValidation = await validateJiraTicketCreationAndUpdateGrid(rowData);
        if (jiraValidation.errorType === JiraValidationErrorType.CANT_VERIFY) {
          notificationService.warning(t('Action.common.notifications.jiraTicketNotVerified'), { duration: 12000 });
          closeModal();
        }
        if (jiraValidation.isValid) {
          closeModal();
        } else {
          setJiraValidationResult(jiraValidation);
          setIsLoading(false);
        }
      } catch (e) {
        setJiraValidationResult({
          isValid: false,
          message: e.message,
          title: t('Action.common.errors.errorCreatingJiraTicket'),
        });
        setIsLoading(false);
      }
    });
  };

  return (
    <MainContainer data-aid={generateDataAid('CreateJiraTicket', ['main-container'])}>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          {jiraValidationResult?.message && (
            <AlertContainer>
              <BigidInlineNotification
                type="error"
                text={[{ subText: jiraValidationResult?.message }]}
                title={jiraValidationResult?.title}
                showMoreButton={false}
                open
              />
            </AlertContainer>
          )}
          <JiraProjectSelector
            selectedJiraProjectName={selectedJiraProjectName}
            updateModalContent={updateModalContent}
            updateSelectedJiraProject={updateSelectedJiraProject}
          />
          <BigidForm {...formProps} />
          <ButtonsContainer>
            <PrimaryButton
              size="medium"
              dataAid={generateDataAid('CreateJiraTicket', ['create', 'button'])}
              text={t('Action.button.create')}
              onClick={onCreateButtonClick}
              bi={{
                eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_TICKET_CREATE_CLICK,
                eventData: {
                  VIEW_TYPE: viewType,
                },
              }}
            />
            <SecondaryButton
              size="medium"
              text={t('Action.button.cancel')}
              dataAid={generateDataAid('CreateJiraTicket', ['cancel', 'button'])}
              onClick={closeModal}
              bi={{
                eventType: ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_TICKET_CANCEL_CLICK,
                eventData: {
                  VIEW_TYPE: viewType,
                },
              }}
            />
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};
