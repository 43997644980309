import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TFunction } from 'i18next';
import { EntityEvents, entityEventsEmitter, PrimaryButton } from '@bigid-ui/components';
import { BigidSystemDialogOptions, openSystemDialog } from '../../../services/systemDialogService';
import { AutoDiscoverWizardPrerequisitesModal } from '../AutoDiscoveryWizard/AutoDiscoveryWizardPrerequisitesModal/AutoDiscoverWizardPrerequisitesModal';
import { TYPE_TO_ICON, CloudProvider, cloudProviderLabel } from '../constants';
import {
  DoNotShowAgainPrerequisites,
  DoNotShowAgainPrerequisitesProps,
} from '../AutoDiscoveryWizard/AutoDiscoveryWizardPrerequisitesModal/DoNotShowAgainPrerequisites';
import { getDoNotShowAgainPrerequisites } from '../AutoDiscoveryWizard/autoDiscoveryWizardUtils';
import { AutoDiscoveryWizardContextState } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';
import { AutoDiscoveryWizardMainModal } from '../AutoDiscoveryWizard/AutoDiscoveryWizardMainModal/AutoDiscoveryWizardMainModal';
import { HeaderImage } from '../../DataSources/DataSourceConnectionModal/DataSourceConnectionModalStyles';
import { AutoDiscoveryConfigModel } from '../AutoDiscoveryGrid/AutoDiscoveryGridConfiguration';
import { useLocalTranslation } from '../translations';
import { noop } from 'lodash';
import { SelectDiscoveryTypeModal } from '../SelectDiscoveryTypeModal/SelectDiscoveryTypeModal';
import { AutoDiscoveryCollaboration } from '../AutoDiscoveryWizard/autoDiscoveryCommonComponents/AutoDiscoveryCollaboration/AutoDiscoveryCollaboration';
import { AutoDiscoveryCollaborateButton } from '../AutoDiscoveryWizard/autoDiscoveryCommonComponents/AutoDiscoveryCollaboration/AutoDiscoveryCollaborateButton';

type PrerequisitesShowed = Record<CloudProvider, null | boolean>;

const DEFAULT_PREREQUISITES_SHOWED: PrerequisitesShowed = {
  [CloudProvider.AWS]: null,
  [CloudProvider.AZURE]: null,
  [CloudProvider.GCP]: null,
};

export interface AutoDiscoveryModalProps {
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
}

interface CreatePrerequisitesButtonsConfig extends DoNotShowAgainPrerequisitesProps {
  contentProps: AutoDiscoveryModalProps;
  t: TFunction;
}

function reloadGridIfDiscoveryStarted(state: AutoDiscoveryWizardContextState) {
  if (state?.isDiscovery) {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }
}
const createPrerequisitesButtonsConfig = ({ type, onCheck, contentProps, t }: CreatePrerequisitesButtonsConfig) => {
  const Icon = TYPE_TO_ICON[type];

  return [
    {
      text: t('prerequisites.buttons.dontShowItAgain'),
      component: () => <DoNotShowAgainPrerequisites type={type} onCheck={onCheck} />,
      onClick: () => true,
      isClose: false,
    },
    {
      text: t('prerequisites.buttons.collaborate'),
      component: () => (
        <AutoDiscoveryCollaboration
          discoveryConfigDataRef={contentProps.discoveryConfigDataRef}
          button={AutoDiscoveryCollaborateButton}
        />
      ),
      onClick: noop,
      isClose: false,
    },
    {
      text: 'Next',
      component: () => (
        <PrimaryButton
          size={'large'}
          onClick={() => {
            setTimeout(() => {
              openSystemDialog({
                ...MAIN_MODAL_CONFIG,
                title: t('configModal.mainTitle', { type: cloudProviderLabel[type] }),
                contentProps,
                onClose: () => {
                  reloadGridIfDiscoveryStarted(contentProps.discoveryConfigDataRef?.current);
                },
                icon: () => (
                  <HeaderImage>
                    <Icon />
                  </HeaderImage>
                ),
              });
            }, 300);
          }}
          dataAid={'prerequisites-next-button'}
          text={`${t('prerequisites.buttons.next')}`}
        />
      ),
      onClick: noop,
      isClose: true,
    },
  ];
};

const PREREQUISITES_MODAL_CONFIG = {
  onClose: noop,
  content: AutoDiscoverWizardPrerequisitesModal,
};

const MAIN_MODAL_CONFIG: BigidSystemDialogOptions = {
  title: `Auto-Discovery`,
  onClose: noop,
  content: AutoDiscoveryWizardMainModal,
  fixedHeight: true,
  borderBottom: false,
  borderTop: true,
  maxWidth: 'xl',
  muiOverrides: {
    disableEnforceFocus: true,
  },
};

let notShowPrerequisites = DEFAULT_PREREQUISITES_SHOWED;

export const useAutoDiscoverWizard = () => {
  const discoveryConfigDataRef = useRef<AutoDiscoveryWizardContextState>({});

  const [isReady, setIsReady] = useState(true);
  const { t } = useLocalTranslation('AutoDiscovery');

  const updateDoNotShowPrerequisites = useCallback(async () => {
    const preferences = await getDoNotShowAgainPrerequisites();
    notShowPrerequisites = {
      [CloudProvider.AWS]: preferences?.includes(CloudProvider.AWS),
      [CloudProvider.AZURE]: preferences?.includes(CloudProvider.AZURE),
      [CloudProvider.GCP]: preferences?.includes(CloudProvider.GCP),
    };
    setIsReady(true);
  }, []);

  useEffect(() => {
    updateDoNotShowPrerequisites();
  }, [updateDoNotShowPrerequisites]);

  const openAutoDiscoveryModalWithData = useCallback(
    (type: CloudProvider, configData?: AutoDiscoveryConfigModel, configDataMultiply?: AutoDiscoveryConfigModel) => {
      discoveryConfigDataRef.current = {
        ...discoveryConfigDataRef.current,
        configData,
        configDataMultiply,
        type,
      };

      const showPrerequisites = !notShowPrerequisites?.[type];
      const contentProps = {
        discoveryConfigDataRef,
        configData,
        configDataMultiply,
        type,
        updateDoNotShowPrerequisites,
      };
      const Icon = TYPE_TO_ICON[type];
      const modalOptions = {
        contentProps,
        borderBottom: true,
        ...(showPrerequisites
          ? {
              ...PREREQUISITES_MODAL_CONFIG,
              title: t('prerequisites.mainTitle', { type: cloudProviderLabel[type] }),
              buttons: createPrerequisitesButtonsConfig({
                type,
                onCheck: (checked: boolean) => {
                  notShowPrerequisites = { ...notShowPrerequisites, [type]: checked };
                },
                contentProps,
                t,
              }),
            }
          : {
              ...MAIN_MODAL_CONFIG,
              title: t('configModal.mainTitle', { type: cloudProviderLabel[type] }),
              onClose: () => {
                reloadGridIfDiscoveryStarted(discoveryConfigDataRef?.current);
              },
            }),
        icon: () => (
          <HeaderImage>
            <Icon />
          </HeaderImage>
        ),
      };
      openSystemDialog(modalOptions);
    },
    [t, updateDoNotShowPrerequisites],
  );

  const openAutoDiscoveryModal = useCallback(
    (type: CloudProvider) => {
      if (type) {
        openAutoDiscoveryModalWithData(type);
      } else {
        openSystemDialog({
          contentProps: {
            openAutoDiscoveryModal: openAutoDiscoveryModalWithData,
          },
          borderBottom: false,
          title: 'Choose the onboarding type',
          onClose: noop,
          content: SelectDiscoveryTypeModal,
          maxWidth: 'xl',
        });
      }
    },
    [openAutoDiscoveryModalWithData],
  );

  return {
    isReady,
    openAutoDiscoveryModalWithData,
    openAutoDiscoveryModal,
  };
};
