import React, { ChangeEvent, FC, useState } from 'react';
import { BigidTextField, PrimaryButton, BigidCheckbox, BigidLoader } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { BigidFormBox } from '../../components/BigidFormBox/BigidFormBox';
import { CreateAppPayload, customAppService } from '../../services/customAppService';
import { isEmpty } from 'lodash';

type Props = {
  onClick: () => void;
  onChange: (obj: Record<string, any>) => void;
  appPayload: CreateAppPayload;
  isLoading: boolean;
  enableInstallButton: boolean;
};

const useStyles = makeStyles({
  urlField: {
    width: '60%',
  },
  field: {
    width: '60%',
    paddingTop: '16px',
  },
  button: {
    paddingTop: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    marginLeft: '8px',
  },
});

export const AddAppForm: FC<Props> = ({ onClick, onChange, appPayload, isLoading, enableInstallButton }) => {
  const classes = useStyles({});
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);
  const [isRemoteApp, setIsRemoteApp] = useState<boolean>(false);
  const [isScannerGroupValid, setIsScannerGroupValid] = useState<boolean>(true);

  const onUrlChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!customAppService.isValidURL(target.value)) {
      setIsUrlValid(false);
      return;
    }

    setIsUrlValid(true);
    const updatedPayload: CreateAppPayload = { ...appPayload, appUrl: target.value };
    onChange(updatedPayload);
  };

  const onIsRemoteAppChane = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setIsRemoteApp(prevState => !prevState);
    const updatedPayload: CreateAppPayload = { ...appPayload, isRemoteApplication: target.checked };
    onChange(updatedPayload);
    setIsScannerGroupValid(true);
  };

  const onScannerGroupChange = ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedPayload: CreateAppPayload = { ...appPayload, scannerGroup: target.value };
    onChange(updatedPayload);
    setIsScannerGroupValid(true);
  };

  const onSubmit = () => {
    if (appPayload.isRemoteApplication && isEmpty(appPayload.scannerGroup)) {
      setIsScannerGroupValid(false);
      return;
    }
    onClick();
  };

  return (
    <BigidFormBox title={'Add Application Form'} description={'Fill the form with the necessary application details'}>
      <div className={classes.urlField}>
        <BigidTextField
          onChange={onUrlChange}
          errorMessage={isUrlValid ? '' : 'Please enter valid application url'}
          required
          placeholder={'Base URL'}
        />
      </div>
      <div className={classes.field}>
        <BigidCheckbox
          label={'Remote App'}
          checked={isRemoteApp}
          onChange={onIsRemoteAppChane}
          dataAid={'addRemoteAppCheckbox'}
        />
      </div>
      {isRemoteApp && (
        <div className={classes.field}>
          <BigidTextField
            label={'Scanner Group'}
            placeholder={'Scanner Group'}
            errorMessage={
              isScannerGroupValid ? '' : `Please provide the scanner's scannerGroup that will communicate with the app`
            }
            onChange={onScannerGroupChange}
          />
        </div>
      )}
      <div className={classes.button}>
        <PrimaryButton
          size="medium"
          onClick={onSubmit}
          dataAid="addAppBaseUrlButton"
          disabled={!enableInstallButton || !isUrlValid || !isScannerGroupValid || isLoading}
          text="Go"
        />
        <div className={classes.loader}>{isLoading && <BigidLoader position={'relative'} size={20} />}</div>
      </div>
    </BigidFormBox>
  );
};
