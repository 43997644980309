import React, { useEffect, useMemo, useState } from 'react';
import {
  BigidBody2,
  BigidDropdownOption,
  BigidForm,
  BigidFormField,
  BigidFormProps,
  BigidFormValues,
  BigidHeading6,
  BigidLoader,
  PrimaryButton,
} from '@bigid-ui/components';
import { DataSourceConnectionCollaborationFooter } from './components/DataSourceConnectionCollaborationFooter';
import { DataSourceConnectionCollaborationSaveModal } from './components/DataSourceConnectionCollaborationSaveModal';
import { Collaboration } from '../mappers/styles';
import { BigidGrid } from '@bigid-ui/grid';
import { useLayout } from '@bigid-ui/layout';
import { useForm } from '../hooks/useForm';
import { useGetDsCollaborators } from '../hooks/useGetDsCollaborators';
import { useCreateDsCollaborator } from '../hooks/useCreateDsCollaborator';
import { useDeleteDsCollaborator } from '../hooks/useDeleteDsCollaborator';
import { useGetUserRolesWithScope } from '../hooks/ueGetUserRoles';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { useUser } from '../hooks/useUser';
import { useKey } from '../../DataSourceConfiguration/hooks/useKey';
import { useLocalTranslation } from '../translations';
import { notificationService } from '../../../../services/notificationService';
import { getApplicationPreference } from '../../../../services/appPreferencesService';
import { COLLABORATION_FF } from '../constants/constants';
import {
  CollaborationFieldAction,
  CollaborationFieldValues,
  createFieldConfig,
  createGridConfig,
} from '../config/collaboration';
import {
  mapDataSourceCollaboratorToBigidGridDataFetchResult,
  mapFieldValuesToCreateDsCollaboratorPayload,
  mapFieldValuesToDeleteDsCollaboratorPayload,
} from '../mappers/collaboration';
import { generateDataAid } from '@bigid-ui/utils';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../../DataSourcesEventTrackerUtils';
import type { CollaboratorGridRow } from '../types';

type DataSourceConnectionCollaborationProps = {
  dataAid?: string;
  type: string;
  dsTypeLabel: string;
  shouldShowSaveBeforeCreate?: boolean;
  sourceId?: string;
  name?: string;
  onChange?: (values: BigidFormValues) => void;
  onSave?: (dataSourceName: string) => Promise<boolean>;
  onClose?: () => void;
};

type BigidDataFieldWithDataAid = Omit<BigidFormField, 'dropDownOptions'> & {
  dropDownOptions?: (BigidDropdownOption & { dataAid?: string })[];
};

const NO_ROWS = 0;

const style = { display: 'inline-flex', alignItems: 'center', gap: '0.1rem', paddingLeft: '0.5rem' };

export const DataSourceConnectionCollaboration = ({
  dataAid = 'DataSourceConnectionCollaboration',
  onChange,
  onSave,
  sourceId,
  dsTypeLabel,
  name: dataSourceName,
  type,
  shouldShowSaveBeforeCreate = false,
}: DataSourceConnectionCollaborationProps): JSX.Element => {
  const { control, getValues, validate } = useForm<CollaborationFieldValues>();
  const [searchTerm, setSearchTerm] = useState('');
  const [action, setAction] = useState<string>();
  const [showSaveBeforeAction, setShowSaveBeforeAction] = useState(false);
  const [isReadyToAddCollaborator, setIsReadyToAddCollaborator] = useState(
    () => !!getValues?.()?.collaborators?.length,
  );
  const { email } = useUser();
  const [key, refreshFields] = useKey();
  const { t } = useLocalTranslation();

  const { data: roles } = useGetUserRolesWithScope();

  const {
    data: collaborators,
    isFetching: isLoadingCollaborators,
    isLoading: isLoadingInitialCollaborators,
    refetch: refetchCollaborators,
  } = useGetDsCollaborators(sourceId, null, {
    query: {
      select: response => response?.data?.collaborators ?? [],
      onError: () => notificationService.error(t('collaboration.fetchError')),
      enabled: !!sourceId && getApplicationPreference(COLLABORATION_FF),
    },
  });

  const { data: users, refetch: refetchUsers } = useSearchUsers(
    searchTerm,
    { filter: null },
    {
      query: {
        select({ data }) {
          // @info remove current user and active collaborators from user list
          const excluded = [email, ...(collaborators?.map(({ email }) => email) ?? [])];
          return data?.users.filter(({ name }) => !excluded.includes(name));
        },
        enabled: !isLoadingInitialCollaborators,
      },
    },
  );

  const { mutateAsync: createCollaborator, isLoading: isCreating } = useCreateDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.createError')),
      onSuccess: () => notificationService.success(t('collaboration.createSuccess')),
    },
  });

  const { mutateAsync: deleteCollaborator, isLoading: isDeleting } = useDeleteDsCollaborator({
    mutation: {
      onError: () => notificationService.error(t('collaboration.deleteError')),
      onSuccess: () => notificationService.success(t('collaboration.deleteSuccess')),
    },
  });

  const {
    gridId,
    rows,
    isLoading,
    apiRef,
    refresh: refreshGrid,
    ...rest
  } = useLayout<CollaboratorGridRow>('DataSourceCollaboration', {
    fetchDataFunction: async () => mapDataSourceCollaboratorToBigidGridDataFetchResult(collaborators),
    initialSorting: [{ field: 'name', order: 'asc' }],
  });

  const isGridReady = sourceId ? (isLoadingCollaborators ? true : !!collaborators?.length) : false;
  const isProvisioned = true; // @info disable collaboration 'type' selection if not deployed on prem - temp disabled
  const isDisabled = !isReadyToAddCollaborator || isCreating || isDeleting;

  const handleOnChange = (values: BigidFormValues) => {
    const { collaborators } = (values as CollaborationFieldValues) ?? {};

    setIsReadyToAddCollaborator(!!collaborators?.length);
    onChange?.(values);
  };

  const handleRefresh = async () => {
    control.current.clearField('collaborators');
    await refetchCollaborators();
    await refetchUsers();
  };

  const handleRevokeAccess = async (forceAction?: unknown) => {
    try {
      const isValid = await validate('collaborators');

      if (!isValid) return;

      if (!forceAction && shouldShowSaveBeforeCreate) {
        setAction(CollaborationFieldAction.REVOKE);
        setShowSaveBeforeAction(true);
        return;
      }

      const values = getValues();
      const payload = mapFieldValuesToDeleteDsCollaboratorPayload(values);

      // @todo add optional check to only include emails from existing collaborators

      await deleteCollaborator(
        {
          dataSource: sourceId,
          data: payload,
        },
        {
          onSuccess: async () => handleRefresh(),
        },
      );
    } catch {}
  };

  const handleAddCollaborator = async (forceAction?: unknown, sourceName?: string) => {
    try {
      const isValid = await validate();

      if (!isValid) return;

      if (!forceAction && shouldShowSaveBeforeCreate) {
        setAction(CollaborationFieldAction.INVITE);
        setShowSaveBeforeAction(true);
        return;
      }

      const values = getValues();
      const { collaborators } = values;
      const payload = mapFieldValuesToCreateDsCollaboratorPayload(values, dsTypeLabel);

      await createCollaborator(
        {
          dataSource: sourceName ?? sourceId,
          data: payload,
        },
        {
          onSuccess: async () => {
            trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_INVITE_CLICK, {
              collaborators: collaborators?.map(({ value }) => value) ?? [],
              ds_name: dataSourceName,
              ds_type: type,
            });
            handleRefresh();
            refreshFields();
          },
        },
      );
    } catch {}
  };

  const handleSearchChange = (...props: unknown[]) => {
    // @info disabled dynamic search feature for now
    const [term] = props as [string];
    setSearchTerm(term);
  };

  const handleFieldUpdate = () => refetchCollaborators();

  const handleRevoke = (...args: unknown[]) => {
    const [email] = args ?? [];

    trackEventDataSources(DataSourcesUITrackingEvent.DS_COLLABORATION_DELETE_COLLABORATOR_CLICK, {
      collaborators: email ? [email] : [],
    });
    handleFieldUpdate();
  };

  const handleSaveDataSource = async (dataSourceName: string, action: string) => {
    try {
      const isSuccess = await onSave?.(dataSourceName);
      const onAction = modalActions[action as CollaborationFieldAction];

      if (isSuccess) {
        await onAction?.(dataSourceName);
        setShowSaveBeforeAction(false);
      }
    } catch {
      setShowSaveBeforeAction(false);
    }
  };

  useEffect(() => {
    const shouldRefreshGrid = !isLoadingCollaborators;
    shouldRefreshGrid && refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCollaborators]);

  useEffect(() => {
    sourceId && refetchCollaborators({ cancelRefetch: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldActions = {
    [CollaborationFieldAction.REVOKE]: async () => handleRevokeAccess(false),
    [CollaborationFieldAction.SEARCH]: handleSearchChange,
  } as const;

  const gridActions = {
    [CollaborationFieldAction.RESEND]: handleFieldUpdate,
    [CollaborationFieldAction.UPDATE]: handleFieldUpdate,
    [CollaborationFieldAction.REVOKE]: handleRevoke,
  } as const;

  const modalActions: Partial<Record<CollaborationFieldAction, (...props: unknown[]) => Promise<void>>> = {
    [CollaborationFieldAction.INVITE]: async (dataSourceName: unknown) =>
      handleAddCollaborator(true, dataSourceName as string),
    [CollaborationFieldAction.REVOKE]: async () => handleRevokeAccess(true),
  } as const;

  const fields: BigidDataFieldWithDataAid[] = useMemo(
    () => createFieldConfig(roles, dsTypeLabel, fieldActions, users, !shouldShowSaveBeforeCreate, t, key),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roles, dsTypeLabel, users, shouldShowSaveBeforeCreate, key],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gridConfig = useMemo(
    () =>
      createGridConfig(
        gridId,
        sourceId,
        dsTypeLabel,
        {
          ...rest,
          rows,
          isLoading: isLoadingCollaborators || isLoading,
          apiRef,
        },
        roles,
        t,
        gridActions,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gridId, sourceId, dsTypeLabel, isLoading, isLoadingCollaborators, roles],
  );

  const formProps: BigidFormProps = useMemo(
    () => ({
      fields,
      onChange: handleOnChange,
      controlButtons: false,
      stateAndHandlersRef: control,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields],
  );

  return (
    <>
      <Collaboration.Content data-aid={dataAid}>
        <Collaboration.Actions isColumn={isProvisioned}>
          <PrimaryButton
            dataAid={generateDataAid(dataAid, ['action', CollaborationFieldAction.INVITE])}
            size="medium"
            text={t('buttons.invite')}
            onClick={() => handleAddCollaborator(false)}
            disabled={isDisabled}
          />
          {(isCreating || isDeleting) && <BigidLoader size={20} />}
        </Collaboration.Actions>
        <Collaboration.Form isColumn={isProvisioned} showDivider={isGridReady}>
          <BigidForm {...formProps} />
        </Collaboration.Form>
        {isGridReady && (
          <Collaboration.List>
            <BigidHeading6 style={style}>
              {t('collaboration.grid.header')}
              <BigidBody2>{t('collaboration.grid.count', { count: rows?.length ?? NO_ROWS })}</BigidBody2>
            </BigidHeading6>
            <Collaboration.Grid className="DataSourceConnectionCollaborationGrid">
              <BigidGrid {...gridConfig} />
            </Collaboration.Grid>
          </Collaboration.List>
        )}
        <DataSourceConnectionCollaborationFooter />
      </Collaboration.Content>
      {/** @info show save modal if data source was not created yet */}
      {showSaveBeforeAction && (
        <DataSourceConnectionCollaborationSaveModal
          source={dataSourceName}
          action={action}
          isOpen={showSaveBeforeAction}
          onClose={() => setShowSaveBeforeAction(false)}
          onSave={handleSaveDataSource}
        />
      )}
    </>
  );
};
