import React, { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material';
import { BigidHeading4, BigidNotes1, SecondaryButton } from '@bigid-ui/components';
import { DataSourceConfigurationContext } from '../../../DataSourceConfigurationContext';
import { mapTestConnectionResultsForNewExperience } from '../../../../DataSourceConnectionModal/mappers/connection';
import {
  DataSourceTestConnectionRowType,
  DataSourcesTestConnectionGrid,
} from '../../../../DataSourcesTestConnectionGrid/DataSourcesTestConnectionGrid';
import { useLocalTranslation } from '../../../../DataSourceConnectionModal/translations';
import { generateDataAid } from '@bigid-ui/utils';
import { useTheme } from '@mui/styles';
import { DataSourcesUITrackingEvent } from '../../../../DataSourcesEventTrackerUtils';

interface MainContentConnectedStatusProps {
  onTestAgain: () => void;
}

enum tableResultsCellWidth {
  SMALL = '304',
  MEDIUM = '457',
  LARGE = '914',
}

const mapRowTypeToCellWidth: { [key in DataSourceTestConnectionRowType]: tableResultsCellWidth } = {
  [DataSourceTestConnectionRowType.APP]: tableResultsCellWidth.LARGE,
  [DataSourceTestConnectionRowType.PARTITIONED_TABLE]: tableResultsCellWidth.MEDIUM,
  [DataSourceTestConnectionRowType.STRUCTURED]: tableResultsCellWidth.SMALL,
  [DataSourceTestConnectionRowType.STRUCTURED_FILE]: tableResultsCellWidth.MEDIUM,
  [DataSourceTestConnectionRowType.UNSTRUCTURED]: tableResultsCellWidth.MEDIUM,
};

const TableResultHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 20px 16px 20px;
`;
const GridWrapper = styled('div')`
  margin-right: 20px;
  margin-left: 20px;
`;

export const MainContentConnectedStatus: FC<MainContentConnectedStatusProps> = ({ onTestAgain }) => {
  const { tablesResult, lastTestDate } = useContext(DataSourceConfigurationContext);
  const { gridRowType, tablesResult: mappedTableResults } = useMemo(() => {
    return mapTestConnectionResultsForNewExperience(tablesResult || []);
  }, [tablesResult]);
  const { t } = useLocalTranslation();
  const theme = useTheme();

  return (
    <>
      <TableResultHeader>
        <BigidHeading4 flexGrow={1}>{t('connectionTab.testConnectionResults')}</BigidHeading4>
        <BigidNotes1 margin="0px 12px 0px 0" color={theme.vars.palette.bigid.gray500}>
          {lastTestDate}
        </BigidNotes1>
        <SecondaryButton
          dataAid={generateDataAid('DataSourceConnectionTab', ['connected', 'test', 'again'])}
          onClick={onTestAgain}
          text={t('buttons.testAgain')}
          size="small"
          bi={{
            eventType: DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_TAB_CONNECTED_TEST_AGAIN_CLICK,
          }}
        />
      </TableResultHeader>
      <GridWrapper>
        <DataSourcesTestConnectionGrid
          gridRowType={gridRowType ?? DataSourceTestConnectionRowType.STRUCTURED}
          rows={mappedTableResults}
          cellWidth={mapRowTypeToCellWidth[gridRowType ?? DataSourceTestConnectionRowType.STRUCTURED]}
        />
      </GridWrapper>
    </>
  );
};
