import React, { FC, memo } from 'react';
import { BigidChevronCircleLeftIcon, BigidChevronCircleRightIcon } from '@bigid-ui/icons';
import { generateDataAid } from '@bigid-ui/utils';
import { styled } from '@mui/material';
import { BigidHeading6, TertiaryButton } from '@bigid-ui/components';
import { ChangeChunkAction } from './CuratedFieldValuePreview';
import { useLocalTranslation } from '../../translations';

const PreviewHeaderContainer = styled('div')`
  display: flex;
`;

const ButtonGroupContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const NavigateChunkButton = styled(TertiaryButton)`
  display: flex;
  align-items: center;
`;

const Separator = styled(BigidHeading6)`
  padding: 0px 5px;
`;

interface CuratedFieldValuePreviewHeaderProps {
  dataAid: string;
  currentIndex: number;
  chunksCount: number;
  onIndexChange: (event: ChangeChunkAction) => void;
}

export const CuratedFieldValuePreviewHeader: FC<CuratedFieldValuePreviewHeaderProps> = memo(
  ({ dataAid, onIndexChange, currentIndex, chunksCount }) => {
    const { t } = useLocalTranslation('CuratedFieldsPreview');
    const handlePrevClick = () => {
      onIndexChange(ChangeChunkAction.PREV);
    };

    const handleNextClick = () => {
      onIndexChange(ChangeChunkAction.NEXT);
    };

    const isBackButtonDisabled = currentIndex === 0;
    const isNextButtonDisabled = currentIndex + 1 >= chunksCount;

    return (
      <PreviewHeaderContainer>
        <ButtonGroupContainer>
          <NavigateChunkButton
            onClick={handlePrevClick}
            size="medium"
            startIcon={<BigidChevronCircleLeftIcon />}
            disabled={isBackButtonDisabled}
            text={t('previous')}
            data-aid={generateDataAid(dataAid, ['prev-chunk-button'])}
          ></NavigateChunkButton>
          <Separator>/</Separator>
          <NavigateChunkButton
            onClick={handleNextClick}
            size="medium"
            endIcon={<BigidChevronCircleRightIcon />}
            disabled={isNextButtonDisabled}
            text={t('next')}
            data-aid={generateDataAid(dataAid, ['next-chunk-button'])}
          ></NavigateChunkButton>
        </ButtonGroupContainer>
      </PreviewHeaderContainer>
    );
  },
);
