import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import BigidAppGenericIcon from '../../../../../assets/icons/BigidAppGeneric.svg';
import { BigidDialog, BigidLoader, BigidIconType, LoaderProps, PrimaryButton, BigidBody3 } from '@bigid-ui/components';
import { CustomAppIcon } from '../../../Components/CustomAppIcon';
import { BigidNonActionCta, BigidMaintenanceIcon } from '@bigid-ui/icons';

export enum InstallAppState {
  NONE = 'NONE',
  INSTALLING = 'INSTALLING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface AppInstallationStateModalProps {
  appName: string;
  appIcon: string;
  state: InstallAppState;
  onClose: () => void;
  onActionButtonClicked: () => void;
}

interface ModalContent {
  text: string;
  buttonText: string;
  Icon?: BigidIconType | FC<LoaderProps>;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    alignContent: 'space-around',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '324px',
  },
  appIcon: {
    height: 24,
    width: 24,
  },
  textWrapper: {
    textAlign: 'center',
  },
  iconWrapper: {
    width: '124px',
  },
});

const getModalContent = (state: InstallAppState): ModalContent => {
  switch (state) {
    case InstallAppState.INSTALLING:
      return { text: 'Installing', buttonText: '', Icon: BigidLoader };
    case InstallAppState.SUCCESS:
      return { text: 'The App has been installed successfully', buttonText: 'Open App', Icon: BigidNonActionCta };
    case InstallAppState.ERROR:
      return {
        text: 'The App install process failed. Please try again later and if the problem persists contact BigID Support.',
        buttonText: 'Understood',
        Icon: BigidMaintenanceIcon,
      };
    default:
      return {
        text: '',
        buttonText: '',
      };
  }
};

export const AppInstallationStateModal: FC<AppInstallationStateModalProps> = ({
  onActionButtonClicked,
  onClose,
  state,
  appName,
  appIcon,
}) => {
  const classes = useStyles();
  const { Icon, text, buttonText } = getModalContent(state);
  return (
    <BigidDialog
      isOpen={state !== InstallAppState.NONE}
      onClose={onClose}
      title={appName}
      showCloseIcon={true}
      maxWidth="xs"
      icon={() =>
        appIcon ? (
          <CustomAppIcon logo={appIcon} isSidebar={true} />
        ) : (
          <BigidAppGenericIcon className={classes.appIcon} />
        )
      }
    >
      <div className={classes.root}>
        <div className={classes.iconWrapper}>{Icon && <Icon />}</div>
        {text && <BigidBody3 className={classes.textWrapper}>{text}</BigidBody3>}
        {buttonText && <PrimaryButton size="medium" onClick={onActionButtonClicked} text={buttonText} />}
      </div>
    </BigidDialog>
  );
};
