import {
  AUTH_TYPE_FIELD_NAME,
  DiscoveryAuthTypes,
  DS_LIST_BY_TYPE,
  TYPE_TO_AUTH_OPTIONS,
  TYPE_TO_OVERRIDE_FIELDS,
  TYPE_TO_VISIBLE_BY_AUTH_FIELDS,
  CloudProvider,
} from '../constants';
import { BigidFormField, BigidFormFieldTypes, BigidSelectOption } from '@bigid-ui/components';
import { AutoDiscoveryWizardContextState } from './autoDiscoveryWizardContext';
import { ActionItemParamsEntity } from '../../CustomApp/types';
import { DataSourceTemplateConditionOperatorsEnum, DsTypesEnum } from '../../DataSources/DataSourceConfiguration/types';
import { uniqBy } from 'lodash';
import { AutoDiscoverySelectAuthTypeField } from './autoDiscoveryCommonComponents/fields/AutoDiscoverySelectAuthTypeField';

interface ActionItemParam extends ActionItemParamsEntity {
  param_friendly_name?: string;
}

export interface DsOptionInWizard {
  id: string;
  label: string;
  isPermitted: boolean;
  selected: boolean;
}

export const generateAutoDiscoveryFieldsData = ({
  configData,
  configDataMultiply,
  type,
  formData,
}: AutoDiscoveryWizardContextState) => {
  const defaultInitialValues = {
    name: '',
    authType: formData?.authType
      ? TYPE_TO_AUTH_OPTIONS[type]?.filter(({ value }) => value === formData?.authType)
      : [TYPE_TO_AUTH_OPTIONS[type]?.[0]],
  };

  const mainFields = getAutodiscoveryConfigFormGeneralFields(type);
  const fieldsByType = Object.entries(TYPE_TO_VISIBLE_BY_AUTH_FIELDS[type]);
  const overrides = TYPE_TO_OVERRIDE_FIELDS[type];

  const { initialValues, fields } = [
    ...configData.appRunAction.params,
    ...(configDataMultiply?.appRunAction?.params ?? []),
  ].reduce<{
    initialValues: Record<string, any>;
    fields: BigidFormField[];
  }>(
    (
      acc,
      { param_name, param_friendly_name, is_mandatory, default_value, is_cleartext, param_type }: ActionItemParam,
    ) => {
      const conditionValues = fieldsByType
        .filter(([_authTypeValue, fieldsForAuthType]) => fieldsForAuthType?.includes(param_name))
        .map(([authTypeValue]) => authTypeValue);
      const visibleIf = conditionValues.length && [
        {
          field: AUTH_TYPE_FIELD_NAME,
          value: {
            operator: DataSourceTemplateConditionOperatorsEnum.or,
            values: conditionValues,
          },
        },
      ];
      const overrideParams = overrides[param_name] ?? { misc: {} };

      const field = {
        name: param_name,
        label: param_friendly_name,
        isRequired: is_mandatory,
        type: getFieldType({ is_cleartext, param_type }),
        ...overrideParams,
        misc: {
          ...(overrideParams?.misc ?? {}),
          ...(visibleIf ? { visibleIf } : {}),
        },
        validate: visibleIf
          ? (value: string, allValues: Record<string, any>) => {
              if (!conditionValues.includes(getDiscoverAuthTypeString(allValues.authType)) || !is_mandatory) {
                return false;
              }
              if (!value?.trim()) {
                return `Please enter ${param_friendly_name}`;
              }
              return false;
            }
          : undefined,
      };
      return {
        initialValues: {
          ...acc.initialValues,
          [param_name]: default_value,
        },
        fields: [...acc.fields, field],
      };
    },
    {
      initialValues: defaultInitialValues,
      fields: [],
    },
  );

  return {
    initialValues,
    mainFields,
    fields: uniqBy(fields, 'name'),
  };
};

function getAutodiscoveryConfigFormGeneralFields(type: CloudProvider): BigidFormField[] {
  return [
    {
      name: 'name',
      label: 'Onboarding Name',
      isRequired: true,
      validate: (name: string) => {
        if (!name.trim()) {
          return 'Please enter Preset Name';
        }
        return false;
      },
    },
    {
      name: AUTH_TYPE_FIELD_NAME,
      isRequired: true,
      render: AutoDiscoverySelectAuthTypeField,
      misc: {
        type: type,
        typeForConditionCheck: DsTypesEnum.options,
      },
    },
  ];
}

export function getDsOptions(permissions: string[], type: CloudProvider): DsOptionInWizard[] {
  return Object.entries(DS_LIST_BY_TYPE[type]).map(([id, { label, value }]) => {
    const isPermitted = permissions.some(dsTypePermitted => value === dsTypePermitted);
    return {
      id,
      label,
      isPermitted,
      selected: isPermitted,
    };
  });
}

export function getDiscoverAuthTypeString(authTypeValue: DiscoveryAuthTypes | BigidSelectOption[]): DiscoveryAuthTypes {
  return typeof authTypeValue === 'string' ? authTypeValue : authTypeValue?.[0]?.value;
}

function getFieldType({ is_cleartext, param_type }: Record<string, any>) {
  switch (param_type) {
    case 'String':
      return is_cleartext ? BigidFormFieldTypes.TEXT : BigidFormFieldTypes.PASSWORD;
    default:
      return BigidFormFieldTypes.TEXT;
  }
}

export function resetStateOnAuthTypeChanged(
  values: Record<string, any>,
  currentState: AutoDiscoveryWizardContextState,
) {
  if (values?.[AUTH_TYPE_FIELD_NAME]?.[0]?.value !== currentState?.formData?.[AUTH_TYPE_FIELD_NAME]?.[0]?.value) {
    return {
      errorMessage: undefined,
      errorMessageKey: undefined,
    };
  }
  return {};
}
