import React, { FC, PropsWithChildren, Reducer, useEffect, useReducer } from 'react';
import { DomainDetails, fetchDomainDetails } from '../domainManagementService';

type DomainCreationContext = {
  state: DomainDetails;
  dispatchAction: (action: Actions) => void;
};
export const DomainCreationContext = React.createContext<DomainCreationContext>(null);

type State = DomainDetails;

type SetDetailsAction = {
  type: 'SET_DETAILS';
  payload: DomainDetails;
};

type UpdatePartialAction = {
  type: 'UPDATE_PARTIAL';
  payload: Partial<DomainDetails>;
};

export type Actions = SetDetailsAction | UpdatePartialAction;

const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case 'SET_DETAILS':
      return action.payload;
    case 'UPDATE_PARTIAL':
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
};

export type DomainCreationContextProps = {
  domainId: string;
};

export const DomainManagementProvider: FC<PropsWithChildren<DomainCreationContextProps>> = ({ children, domainId }) => {
  const [state, dispatch] = useReducer(reducer, {
    id: '',
    name: '',
    description: '',
    parentDomainId: '',
    businessOwners: [],
    itOwners: [],
    domainOwners: [],
    hierarchy: [],
  });

  useEffect(() => {
    const getDomainDetails = async () => {
      const domainDetails = await fetchDomainDetails(domainId);
      dispatch({
        payload: domainDetails,
        type: 'SET_DETAILS',
      });
    };

    if (domainId) {
      getDomainDetails();
    }
  }, [domainId]);

  return (
    <DomainCreationContext.Provider
      value={{
        state,
        dispatchAction: dispatch,
      }}
    >
      {children}
    </DomainCreationContext.Provider>
  );
};

export const useDomainCreationContext = () => React.useContext(DomainCreationContext);
