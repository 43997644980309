export type LoginOverride = {
  name?: string;
  isPermitted: () => boolean | boolean;
  isFinal?: boolean;
  action: () => void;
};

export enum LoginStrategies {
  AUTH0 = 'auth0',
  SAML = 'saml',
  DEFAULT = 'default',
}
