import React, { createContext, FC, PropsWithChildren, Reducer, useEffect, useReducer, useContext } from 'react';
import { TagEntity } from '../../TagsManagement/TagsManagementService';
import { fetchEntityDetails } from './cardModalService';
import { CardType } from './types';

type CardModalContextProps = {
  state: ModalContextState;
  dispatchAction: (action: Actions) => void;
  callbacks: {
    onClose: () => void;
  };
};

const CardModalContext = createContext<CardModalContextProps>(null);

export type CardModalContextProviderProps = {
  entityId?: string;
  cardType?: CardType;
  onClose?: () => void;
};

type CardModalState<Type extends CardType> = {
  type: Type;
  isNewCard: boolean;
  headerState: {
    name: string;
    followersCount: number;
    likesCount: number;
    friendlyName: string;
  };
};

type BaseTab = {
  name: string;
};

type Category = {
  id: string;
  name: string;
};

type AttributeInfoTab = BaseTab & {
  description: string;
  hierarchy: string[];
  attributeType: string;
  attributeOrigin: string;
  domain: string;
  project: string;
  categories: Category[];
  tags: TagEntity[];
  isCriticalDataElement: boolean;
  definition: string;
  itAssets: string;
  additionalDomains: string[][];
  contributors: {
    createdBy: string;
    lastModifiedBy: string;
    dataSteward: string;
    stewardManager: string;
    dataOwner: string;
  };
};

type AttributeTabs = {
  info: AttributeInfoTab;
};

type AttributeModalState = CardModalState<'attribute'> & {
  tabs: AttributeTabs;
};

type TermModalState = CardModalState<'term'> & {
  tabs: AttributeTabs;
};

type ModalContextState = AttributeModalState | TermModalState;

type SetCardDataAction = {
  type: 'SET_CARD_DATA';
  payload: AttributeModalState;
};

type SetPartialHeaderStateAction = {
  type: 'SET_PARTIAL_HEADER_STATE';
  payload: Partial<AttributeModalState['headerState']>;
};

type SetPartialTabStateAction = {
  type: 'SET_PARTIAL_TAB_STATE';
  payload: {
    tabName: keyof AttributeTabs;
    data: Partial<AttributeTabs[keyof AttributeTabs]>;
  };
};

type Actions = SetCardDataAction | SetPartialHeaderStateAction | SetPartialTabStateAction;

const cardModalReducer: Reducer<ModalContextState, Actions> = (state, action) => {
  switch (action.type) {
    case 'SET_CARD_DATA':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_PARTIAL_HEADER_STATE':
      return {
        ...state,
        headerState: {
          ...state.headerState,
          ...action.payload,
        },
      };

    case 'SET_PARTIAL_TAB_STATE':
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.tabName]: {
            ...state.tabs[action.payload.tabName],
            ...action.payload.data,
          },
        },
      };
    default:
      return state;
  }
};

type GetDefaultState = (isNewCard: boolean, cardType: CardType) => ModalContextState;

const getDefaultState: GetDefaultState = (isNewCard: boolean, cardType) => ({
  type: cardType,
  headerState: {
    name: '',
    followersCount: 0,
    likesCount: 0,
    friendlyName: 'test name',
  },
  isNewCard,
  tabs: {
    info: {
      additionalDomains: [
        ['Healthcare', 'Medical'],
        ['Insurance', 'Claims'],
        ['Banking', 'Credit Cards'],
        ['Banking', 'Checking Accounts'],
      ],
      attributeOrigin: '',
      attributeType: '',
      categories: [
        {
          id: '1',
          name: 'Personal Sensitive',
        },

        {
          id: '2',
          name: 'Sensitive - Personal Information',
        },
        {
          id: '3',
          name: 'Card Holder Data',
        },
        {
          id: '4',
          name: 'Financial Data',
        },
        {
          id: '5',
          name: 'PCI',
        },
        {
          id: '6',
          name: 'PII',
        },
        {
          id: '7',
          name: 'Legal Department',
        },
        {
          id: '8',
          name: 'Long Term Storage',
        },
      ],
      definition: '',
      description: '',
      domain: '',
      hierarchy: ['Banking', 'Credit Cards', 'Credit Card Number'],
      isCriticalDataElement: false,
      itAssets: '',
      name: '',
      project: '',
      tags: [
        {
          tagId: '1',
          valueId: '1',
          tagName: 'test',
          tagValue: 'test',
        },
      ],
      contributors: {
        createdBy: '',
        lastModifiedBy: 'Ricky Gervais',
        dataSteward: 'George Michael',
        stewardManager: 'Martin Freeman',
        dataOwner: 'Michael Scott',
      },
    },
  },
});

export const CardModalContextProvider: FC<PropsWithChildren<CardModalContextProviderProps>> = ({
  children,
  entityId,
  cardType,
  onClose,
}) => {
  const [modalState, dispatchAction] = useReducer(cardModalReducer, getDefaultState(!entityId, cardType));

  useEffect(() => {
    if (entityId) {
      fetchEntityDetails(entityId).then(data => {
        console.log(data);
        // dispatchAction({ type: 'SET_CARD_DATA', payload: data });
      });
    }
  }, [entityId]);

  return (
    <CardModalContext.Provider
      value={{
        state: modalState,
        dispatchAction,
        callbacks: {
          onClose,
        },
      }}
    >
      {children}
    </CardModalContext.Provider>
  );
};

export const useCardModalContext = () => {
  const context = useContext(CardModalContext);
  if (context === undefined) {
    throw new Error('useCardModalContext must be used within a CardModalProvider');
  }
  return context;
};
