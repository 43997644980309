import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export type ProxiesHeaderProps = {
  title: string;
};

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
`;

const styles = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: 700,
  marginRight: '1rem',
};

export const ProxiesHeader = ({ title }: ProxiesHeaderProps): JSX.Element => (
  <Header>
    <Typography sx={styles} variant="h4">
      {title}
    </Typography>
  </Header>
);
