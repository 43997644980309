import React from 'react';
import { BigidGuidedTour, Step } from '@bigid-ui/guided-tour';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import GuidedTourIllustration from '../../../../assets/icons/CollaborationGuidedTour.svg';
import { Collaboration } from '../mappers/styles';
import { STATUS as DataTourStatus } from 'react-joyride';
import { useTheme } from '@mui/styles';

export {  STATUS as DataTourStatus, LIFECYCLE as DataTourLifecycle, EVENTS as DataTourEvent, ACTIONS as DataTourAction } from 'react-joyride';

type DataSourceCollaborationGuidedTourProps = {
  source: string;
  loading: boolean;
  start?: boolean;
  onSkip?: (props: CallbackProps) => void;
  onDone?: (props: CallbackProps) => void;
  onClose?: (props: CallbackProps) => void;
  onUpdate?: (props: CallbackProps)  =>void;
};

export interface CallbackProps {
  action: string;
  controlled: boolean;
  index: number;
  lifecycle: string;
  size: number;
  status: string;
  step: Step;
  type: string;
}

export const INIT_STEP_DATA_TOUR_ID = "start-collaboration";

const formatRowId = (id: string) => id.toLowerCase().replace(/ /g, "-")

const findRowWithId = (id: string): string =>
  id ? `[data-tour-id="BigidGridRow-${formatRowId(id)}"]` : 'tbody.MuiTableBody-root > tr:first-of-type';

export const DataSourceCollaborationGuidedTour = ({
  start,
  source,
  loading: isLoading,
  onSkip,
  onDone,
  onUpdate,
  onClose,
}: DataSourceCollaborationGuidedTourProps) => {
  const { t } = useLocalTranslation();
  const theme = useTheme();

  const steps: Step[] = [
    {
      carouselItems: [
        {
          content: <GuidedTourIllustration />,
          styles: {
            background: theme.vars.palette.bigid.white,
          },
          type: 'node',
        },
      ],
      content: (
        <>
          <BigidBody1 style={{ marginBottom: '24px' }}>{t('collaboration.tour.slide1.body1')}</BigidBody1>
          <BigidBody1>{t('collaboration.tour.slide1.body2')}</BigidBody1>
        </>
      ),
      disableBeacon: true,
      hideBackButton: true,
      placement: 'center',
      showSkipButton: true,
      target: '[data-tour-id="start-collaboration"]',
      title: t('collaboration.tour.title1'),
    },
    {
      content: (
        <>
          <BigidBody1 style={{ marginBottom: '24px' }}>{t('collaboration.tour.slide2.body1')}</BigidBody1>
          <BigidBody1>{t('collaboration.tour.slide2.body2')}</BigidBody1>
        </>
      ),
      disableBeacon: true,
      hideBackButton: false,
      placement: 'right-end',
      target: findRowWithId(source),
      title: t('collaboration.tour.title2'),
    },
  ];

  const handleSkipTour = (props: CallbackProps) => onSkip?.(props);

  const handleCompleteTour = (props: CallbackProps) => onDone?.(props);

  const handleCloseTour = (props: CallbackProps) => onClose?.(props);

  const handleUpdateTour = (props: CallbackProps) => onUpdate?.(props)

  const actions: Partial<Record<string, (props: CallbackProps) => void>> = {
    [DataTourStatus.SKIPPED]: handleSkipTour,
    [DataTourStatus.FINISHED]: handleCompleteTour,
    [DataTourStatus.PAUSED]: handleCloseTour,
    [DataTourStatus.RUNNING]: handleUpdateTour,
  };

  const handleCallback = (props: CallbackProps) => {
    const { status } = props;
    const handleAction = actions[status];

    handleAction?.(props);
  };

  return (
    <>
      <Collaboration.Global />
      {!isLoading && (
        <Collaboration.GuidedTour data-tour-id="start-collaboration">
          <BigidGuidedTour
            run={start}
            delay={3000}
            callback={handleCallback}
            disableOverlayClose={false}
            disableScrolling
            disableOverlay={false}
            steps={steps}
          />
        </Collaboration.GuidedTour>
      )}
    </>
  );
};
