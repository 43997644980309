import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { noop } from 'lodash';
import {
  DEFAULT_CONFIG_DS_STATE,
  useDataSourceConfigState,
} from '../DataSourceConfiguration/hooks/useDataSourceConfigState';
import type { OAuthHandlers } from './hooks/useOAuth';

export type DataSourceConnectionModalContextType = ReturnType<typeof useDataSourceConfigState> & {
  isDsCollaborator: boolean;
} & OAuthHandlers;

type DataSourceConnectionModalProviderProps = {
  config: ReturnType<typeof useDataSourceConfigState> & OAuthHandlers;
  isDsCollaborator?: boolean;
} & PropsWithChildren;

export const DataSourceConnectionModalContext = createContext<DataSourceConnectionModalContextType>({
  configDataSourceState: DEFAULT_CONFIG_DS_STATE,
  updateState: noop,
  generateInitValues: noop,
  isDsCollaborator: false,
  oAuthHandlers: {},
});

export const DataSourceConnectionModalProvider = ({
  children,
  config,
  isDsCollaborator = false,
}: DataSourceConnectionModalProviderProps): JSX.Element => {
  const value = useMemo(() => ({ ...config, isDsCollaborator }), [config, isDsCollaborator]);
  return (
    <DataSourceConnectionModalContext.Provider value={value}>{children}</DataSourceConnectionModalContext.Provider>
  );
};
