import React, { FC, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidHeading6,
  BigidBody1,
} from '@bigid-ui/components';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { AutoDiscoverWizardAsideItemTitle } from './autoDiscoverInfoStyledComponents';

export interface AutoDiscoverWizardAsideOptionItem {
  title: string;
  subTitle?: string;
  children?: ReactNode;
  name?: AutoDiscoverWizardAsideExpandedStates;
  isExpanded?: boolean;
  onChange?: () => void;
}

const accordionDetailsStyle = {
  padding: '4px 24px 20px 32px !important',
};

export const AutoDiscoverWizardAsideOptionItemSubtitle = styled(BigidBody1)`
  padding-left: 4px;
`;

export const AutoDiscoverWizardAsideOptionItem: FC<AutoDiscoverWizardAsideOptionItem> = ({
  title,
  subTitle,
  children,
  name,
  isExpanded,
  onChange,
}) => {
  const { setDiscoveryConfigData, discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const expanded = discoveryConfigData.asideExpandedState === name;
  const controlledProps = name
    ? {
        expanded: expanded,
        onChange: () =>
          setDiscoveryConfigData(current => ({
            ...current,
            asideExpandedState: expanded ? AutoDiscoverWizardAsideExpandedStates.OPENED : name,
          })),
      }
    : {
        ...(isExpanded !== undefined ? { expanded: isExpanded, onChange } : {}),
      };

  return (
    <BigidAccordion
      className={'AutoDiscoverWizardAsideOptionItem'}
      {...controlledProps}
      sx={{ borderRadius: '8px !important' }}
      dataAid={'AutoDiscoverWizardAsideOptionItem'}
    >
      <BigidAccordionSummary
        sx={{
          width: '100%',
        }}
        size={AccordionSummarySizeEnum.large}
      >
        <AutoDiscoverWizardAsideItemTitle>
          <BigidHeading6 sx={{ cursor: 'pointer' }}>{title}</BigidHeading6>
          <AutoDiscoverWizardAsideOptionItemSubtitle>{subTitle ?? ''}</AutoDiscoverWizardAsideOptionItemSubtitle>
        </AutoDiscoverWizardAsideItemTitle>
      </BigidAccordionSummary>
      <BigidAccordionDetails sx={accordionDetailsStyle}>{children}</BigidAccordionDetails>
    </BigidAccordion>
  );
};
