import React, { FC, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidContentItem, BigidLoader, BigidColorsV2, BigidBody1, BigidButtonIcon } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { CuratedField } from '../../../../curationService';
import { notificationService } from '../../../../../../services/notificationService';
import {
  getColumnProfile,
  ColumnProfile,
} from '../../../../../DataCatalog/DataCatalogColumns/DataCatalogColumnsService';
import { ColumnProfileValue, ColumnProfileValueProps } from './ColumnProfileValue';
import { ColumnProfilePercentage } from './ColumnProfilePercentage';
import classNames from 'classnames';
import { useLocalTranslation } from '../../../../translations';
import { CurationGuidedTourStageId } from '../../../../../Curation/useCurationState';
import { BigidHelpIcon } from '@bigid-ui/icons';
import { CurationTabsLayoutStateContext } from '../../../../CurationStateContext';

export interface ColumnProfileProps extends BigidContentItem, Omit<CuratedField, 'id' | 'tags'> {
  dataAid?: string;
  onProceedToFieldsReviewTab: (currentGuidedTour: CurationGuidedTourStageId, openGuidedTour: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  content: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
    overflowY: 'auto',
    marginTop: '30px',
  },
  value: {
    width: '50%',
    padding: '8px',
  },
  busy: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
  helpIcon: {
    position: 'absolute',
    display: 'flex',
    alignSelf: 'flex-end',
    padding: '15px',
  },
});

const NUMERIC_TYPE = 'Numeric';

function getColumnProfileEntities(data: ColumnProfile): ColumnProfileValueProps[] {
  const {
    inferredDataType,
    avgNum,
    avgStrLen,
    minNum,
    maxNum,
    strDev,
    numDev,
    maxLexStr,
    minLexStr,
    emptyPct,
    distinctPct,
  } = data;

  const avgLenReduced = inferredDataType ? avgStrLen : undefined;
  const minNumReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? minNum : minLexStr) : undefined;
  const maxNumReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? maxNum : maxLexStr) : undefined;

  const emptyPctReduced = !isNaN(emptyPct) ? Number(emptyPct % 1 === 0 ? emptyPct : emptyPct.toFixed(2)) : undefined;
  const distinctPctReduced = !isNaN(distinctPct)
    ? Number(distinctPct % 1 === 0 ? distinctPct : distinctPct.toFixed(2))
    : undefined;
  const avgNumReduced = avgNum;
  const devReduced = inferredDataType ? (inferredDataType === NUMERIC_TYPE ? numDev : strDev) : undefined;

  let columnProfileEntities = [
    {
      name: 'Inferred data type',
      value: inferredDataType,
    },
    {
      name: '% Null/Empty values',
      value: emptyPctReduced,
      valueColor: !isNaN(emptyPctReduced) ? BigidColorsV2.cyan[700] : BigidColorsV2.gray[700],
      widget: !isNaN(emptyPctReduced) ? (
        <ColumnProfilePercentage value={emptyPctReduced} color={BigidColorsV2.cyan[700]} />
      ) : undefined,
    },
    {
      name: 'Average length',
      value: avgLenReduced ? (avgLenReduced % 1 === 0 ? avgLenReduced : avgLenReduced.toFixed(2)) : undefined,
    },
    {
      name: '% Distinct values',
      value: distinctPctReduced,
      valueColor: !isNaN(distinctPctReduced) ? BigidColorsV2.cyan[700] : BigidColorsV2.gray[700],
      widget: !isNaN(distinctPctReduced) ? (
        <ColumnProfilePercentage value={distinctPctReduced} color={BigidColorsV2.cyan[700]} />
      ) : undefined,
    },
    {
      name: 'Min value',
      value: minNumReduced,
    },
    {
      name: 'Max value',
      value: maxNumReduced,
    },
  ];

  if (inferredDataType === NUMERIC_TYPE) {
    columnProfileEntities = [
      ...columnProfileEntities,
      {
        name: 'Average value',
        value: avgNumReduced,
      },
      {
        name: 'Standard deviation',
        value: devReduced,
      },
    ];
  }

  return columnProfileEntities;
}

const currentGuidedTour = CurationGuidedTourStageId.COLUMN_PROFILE;

export const ColumnProfileComponent: FC<ColumnProfileProps> = ({
  dataAid = 'ColumnProfileComponent',
  fieldName,
  fullyQualifiedName,
}) => {
  const { onProceedToFieldsReviewTab, onCurationGuidedTourStart } = useContext(CurationTabsLayoutStateContext);
  const classes = useStyles();
  const { t } = useLocalTranslation('CuratedFields.structured.ColumnProfile');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnProfileValues, setColumnProfileValues] = useState<ColumnProfileValueProps[]>([]);

  useEffect(() => {
    onProceedToFieldsReviewTab(currentGuidedTour, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);

    getColumnProfile(fullyQualifiedName, fieldName)
      .then(({ data }) => {
        setColumnProfileValues(getColumnProfileEntities(data));
      })
      .catch(({ response, message }) => {
        if (response?.status === 418) {
          setColumnProfileValues(null);
        } else {
          notificationService.error(t('errors.fetchingProfile'));
          console.error(`${t('errors.generic')}: ${message}`);
          setColumnProfileValues([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [fieldName, fullyQualifiedName, t]);

  const handleHelpIconClick = () => {
    onCurationGuidedTourStart(currentGuidedTour);
  };

  return (
    <div
      className={classNames(classes.root, isLoading && classes.busy)}
      data-aid={generateDataAid(dataAid, [fullyQualifiedName])}
      data-tour-id={generateGuidedTourId(currentGuidedTour, ['start'])}
    >
      <div className={classes.helpIcon}>
        <BigidButtonIcon icon={BigidHelpIcon} onClick={handleHelpIconClick} />
      </div>
      {isLoading && <BigidLoader />}
      <div className={classes.content}>
        {columnProfileValues ? (
          columnProfileValues.map(
            ({ name, value, valuePlaceholder, valueColor, widget }: ColumnProfileValueProps, index) => (
              <div key={index} className={classes.value}>
                <ColumnProfileValue
                  dataAid={generateDataAid(dataAid, [fullyQualifiedName, name])}
                  name={name}
                  value={value}
                  valueColor={valueColor}
                  valuePlaceholder={valuePlaceholder}
                  widget={widget}
                />
              </div>
            ),
          )
        ) : (
          <BigidBody1 data-aid={generateDataAid(dataAid, [fullyQualifiedName, 'unavailable'])}>
            {t('errors.unavailable')}
          </BigidBody1>
        )}
      </div>
    </div>
  );
};
