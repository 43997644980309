import { BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';

type DataSourceConnectionDialogStylesProps = {
  isSimple: boolean;
};

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: ${({ isSimple }: DataSourceConnectionDialogStylesProps) => (isSimple ? '600px' : 'calc(100% - 580px)')};
  justify-content: stretch;
  border-right: 1px solid ${BigidColorsV2.gray[200]};
`;

export const Header = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  height: 80px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-height: calc(100% - 80px);
  overflow: auto;
  flex: 1 1 auto;
`;

export const Aside = styled.div`
  width: 580px;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
`;

export const Dialog = styled(Paper)`
  display: flex;
  margin: -16px;
  height: calc(100% + 32px);
  position: relative;
  overflow: hidden;

  & * {
    -ms-overflow-style: none !important;
  }

  & *::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const Container = styled.div``;
