import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterTypes,
  BigidDropdownOption,
  BigidFieldFilter,
  BigidFilter,
} from '@bigid-ui/components';
import { isNil } from 'lodash';
import { AggregationFilterOperand, AggregationType } from '../catalogDiscoveryTypes';
import { nestedFilterOptionIdDelimeter } from '../filter/utils';

enum CatalogFilterField {
  SYSTEM = 'system',
  SCAN_STATUS = 'scanStatus',
  APPLICATION = 'application',
  CLOUD = 'source.type',
  TAGS = 'catalog_tag',
  SENSITIVITY = 'catalog_tag.system.sensitivityClassification',
  RISK = 'catalog_tag.system.risk.riskGroup',
  HAS_DUPLICATES = 'has_duplicates',
  FILE_TYPE = 'fileType',
  FIELD = 'field',
  ACCESS_TYPE = 'catalog_tag.Access Type',
}

enum PreprocessorType {
  PARENT_ID = 'PARENT_ID',
}

type PreprocessorFunc = (value: string) => string;

type Preprocessor = { [key in PreprocessorType]?: PreprocessorFunc };

const preprocessors: { [key in AggregationType]?: Preprocessor } = {
  [AggregationType.SENSITIVITY_FILTER]: {
    [PreprocessorType.PARENT_ID]: (value: string): string => {
      const regex = new RegExp(/^(system.sensitivityClassification\.)/);

      return value.replace(regex, '');
    },
  },
};

const parentIdCatalogFiltersIgnoreList: CatalogFilterField[] = [CatalogFilterField.RISK];

const catalogToInsightsFilterMap: Record<string, AggregationType> = {
  [CatalogFilterField.SYSTEM]: AggregationType.DATA_SOURCE_NAME,
  [CatalogFilterField.SCAN_STATUS]: AggregationType.OBJECT_SCAN_STATUS,
  [CatalogFilterField.APPLICATION]: AggregationType.APPLICATION,
  [CatalogFilterField.CLOUD]: AggregationType.CLOUD_TYPE,
  [CatalogFilterField.TAGS]: AggregationType.TAGS,
  [CatalogFilterField.SENSITIVITY]: AggregationType.SENSITIVITY_FILTER,
  [CatalogFilterField.HAS_DUPLICATES]: AggregationType.HAS_DUPLICATES,
  [CatalogFilterField.FILE_TYPE]: AggregationType.FILE_TYPE,
  [CatalogFilterField.FIELD]: AggregationType.ATTRIBUTE_CATEGORY,
  [CatalogFilterField.RISK]: AggregationType.RISK,
  [CatalogFilterField.ACCESS_TYPE]: AggregationType.ACCESS_TYPE_FILTER,
};

const booleanFilterOptionsMap = {
  [AggregationFilterOperand.HAS_DUPLICATES]: {
    id: AggregationType.HAS_DUPLICATES,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [
      {
        id: 'with',
        value: {
          aggItemName: 'with',
        },
        displayValue: 'Yes',
        isSelected: true,
      },
    ],
  },
  [AggregationFilterOperand.OBJECT_STATUS]: {
    id: AggregationType.OBJECT_STATUS,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    options: [
      {
        id: 'with',
        value: {
          aggItemName: 'with',
        },
        displayValue: 'Yes',
        isSelected: true,
      },
    ],
  },
};

function getValuePreproccessed(value: string, filterId: AggregationType, preprocessorType: PreprocessorType): string {
  const preprocessor = preprocessors[filterId]?.[preprocessorType];

  return preprocessor ? preprocessor(value) : value;
}

function getFilterId({ field }: BigidFieldFilter): AggregationType {
  let filterId;

  switch (true) {
    case field.startsWith(CatalogFilterField.RISK):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.RISK];
      break;
    case field.startsWith(CatalogFilterField.SENSITIVITY):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.SENSITIVITY];
      break;
    case field.startsWith(CatalogFilterField.ACCESS_TYPE):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.ACCESS_TYPE];
      break;
    case field.startsWith(CatalogFilterField.TAGS):
      filterId = catalogToInsightsFilterMap[CatalogFilterField.TAGS];
      break;
    default:
      filterId = catalogToInsightsFilterMap[field];
  }

  return filterId;
}

function getFilterOptionId(id: string, parentId: string): string {
  return parentId ? `${parentId}${nestedFilterOptionIdDelimeter}${id}` : id;
}

function getFilterOptions(filter: BigidFieldFilter): BigidDropdownOption[] {
  const { value, parentId, field } = filter;
  const filterId = getFilterId(filter);
  let options: BigidDropdownOption[];

  if (!value) {
    options = [];
  } else {
    const aggItemName = String(value);
    const aggItemGroup =
      !parentIdCatalogFiltersIgnoreList.includes(field as CatalogFilterField) &&
      parentId &&
      getValuePreproccessed(String(parentId), filterId, PreprocessorType.PARENT_ID);
    const id = getFilterOptionId(aggItemName, aggItemGroup);

    options = [
      {
        id,
        ...(aggItemGroup ? { parentId: aggItemGroup } : {}),
        value: {
          aggItemName,
          ...(aggItemGroup ? { aggItemGroup } : {}),
        },
        displayValue: aggItemName,
        isSelected: true,
      },
    ];
  }

  return options;
}

export function getToolbarValuesFromCatalogFilters(catalogFilters: BigidFilter): AdvancedToolbarOverrideValue[] {
  return catalogFilters
    ?.filter(({ value }) => !isNil(value))
    .reduce<AdvancedToolbarOverrideValue[]>((values, catalogFilter) => {
      const filterId = getFilterId(catalogFilter);

      switch (true) {
        case catalogFilter.id === AggregationFilterOperand.HAS_DUPLICATES: {
          values.push(booleanFilterOptionsMap[AggregationFilterOperand.HAS_DUPLICATES]);

          return values;
        }
        case filterId === catalogToInsightsFilterMap.field &&
          catalogFilter.id === AggregationFilterOperand.OBJECT_STATUS: {
          values.push(booleanFilterOptionsMap[AggregationFilterOperand.OBJECT_STATUS]);

          return values;
        }
        default: {
          const existingFilterIndex = values.findIndex(({ id }) => id === filterId);

          if (existingFilterIndex >= 0) {
            (values[existingFilterIndex].options as BigidDropdownOption[]).push(...getFilterOptions(catalogFilter));
          } else {
            values.push({
              id: filterId,
              type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
              options: getFilterOptions(catalogFilter),
            });
          }

          return values;
        }
      }
    }, [])
    .filter(({ id }) => id);
}
