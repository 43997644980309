import { licenseService } from '../../services/licenseService';
import { isPermitted } from '../../services/userPermissionsService';
import {
  BigidApplicationsIcon,
  BigidDiscoveryIcon,
  BigidDataSourceOnlyIcon,
  BigidDashboardIcon,
  BigidFlameIcon,
  BigidStarIcon,
  BigidSpaceshipDefaultIcon,
  BigidReportCenterIcon,
  BigidAdministrationIcon,
  BigidReportsIllustration,
  BigidDataManagementIllustration,
  BigidDataExplorerIllustration,
  BigidSettingsIllustration,
  BigidFavoritesIllustration,
  BigidDataSearchIcon,
  BigidHistoryIcon,
  BigidCorrelationSetIcon,
  BigidApplicationSetupIcon,
  BigidClassifiersIcon,
  BigidCompoundAttributesIcon,
  BigidTagIcon,
  BigidSavedQueriesIcon,
  BigidSensitivityClassificationIcon,
  BigidPoliciesIcon,
  BigidScanWindowIcon,
  BigidSecondarySourcesIcon,
  BigidHotspotIcon,
  BigidExecutiveReportIcon,
  BigidScanResultsDetailsIcon,
  BigidUsageReportIcon,
  BigidLabelingReportIcon,
  BigidGroupIcon,
  BigidActionCenterIcon,
  BigidActivityHighlightsIcon,
  BigidAdvanceSearchIcon,
  BigidScanResultsSummaryIcon,
  BigidScannedFilesIcon,
  BigidCertificatesIcon,
  BigidCredentialsIcon,
  BigidDataCustodianIcon,
  BigidInventoryIcon,
  BigidCatalogIcon,
  BigidCorrelationsIcon,
  BigidClassificationIcon,
  BigidClusterAnalysisIcon,
  BigidDashboardManagerIcon,
  BigidReportRiskIcon,
  BigidVaultIcon,
  BigidProxyIcon,
  BigidCheckedViewIcon,
} from '@bigid-ui/icons';
import { CONFIG } from '../../../config/common';
import { BigidNavigationItemType, MenuSettingsItemBase, inserItemInMenu } from '@bigid-ui/components';
import { sidebarService } from '../../services/sidebarService';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
} from '../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';

export const PREFERENCES_NAME = 'sideNavigation';

export const isNavbarItemVisible = (permissions: string[]) =>
  !licenseService.shouldBlockUserWithLicenseExpired() && permissions.every(permission => isPermitted(permission));

export const getInitialNavbarItems = (onNavigationItemClicked: (link: string) => void): BigidNavigationItemType[] => [
  {
    isVisible: true,
    icon: BigidDashboardIcon,
    title: 'Data Overview',
    tooltipText: 'Data Overview',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.CATALOG_DISCOVERY,
    selectedItemStates: [CONFIG.states.CATALOG_DISCOVERY],
  },
  {
    isVisible: true,
    icon: BigidFlameIcon,
    title: 'Security Posture',
    tooltipText: 'Security Posture',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.ACTIONABLE_INSIGHTS,
    selectedItemStates: [CONFIG.states.ACTIONABLE_INSIGHTS],
  },
  {
    isVisible: true,
    icon: BigidDiscoveryIcon,
    title: 'Data Explorer',
    tooltipText: 'Data Explorer',
    onItemClicked: onNavigationItemClicked,
    link: 'dataExplorerTEMP',
    selectedItemStates: [
      'dataExplorerTEMP',
      CONFIG.states.INVENTORY,
      CONFIG.states.CORRELATION,
      CONFIG.states.CLUSTERING,
      CONFIG.states.CLASSIFICATION,
      CONFIG.states.CATALOG,
      CONFIG.states.DATA_EXPLORER,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      //masterPageLink: 'masterLink', //TODO: Uncomment when master page is added
      emptyStateIcon: BigidDataExplorerIllustration,
      emptyStateText: 'Click edit and turn on your Catalog',
      menuItems: [
        {
          isVisible: true,
          title: 'Data Explorer',
          link: CONFIG.states.DATA_EXPLORER,
          selectedItemStates: [CONFIG.states.DATA_EXPLORER],
          icon: BigidDiscoveryIcon,
        },
        {
          isVisible: true,
          title: 'Catalog',
          link: CONFIG.states.CATALOG,
          icon: BigidCatalogIcon,
          selectedItemStates: [CONFIG.states.CATALOG],
        },
        {
          isVisible: true,
          title: 'Inventory',
          link: CONFIG.states.INVENTORY,
          icon: BigidInventoryIcon,
          selectedItemStates: [CONFIG.states.INVENTORY],
        },
        {
          isVisible: true,
          title: 'Correlation',
          link: CONFIG.states.CORRELATION,
          icon: BigidCorrelationsIcon,
          selectedItemStates: [CONFIG.states.CORRELATION],
        },
        {
          isVisible: true,
          title: 'Cluster Analysis',
          link: CONFIG.states.CLUSTERING,
          icon: BigidClusterAnalysisIcon,
          selectedItemStates: [CONFIG.states.CLUSTERING],
        },
        {
          isVisible: true,
          title: 'Classification',
          link: CONFIG.states.CLASSIFICATION,
          icon: BigidClassificationIcon,
          selectedItemStates: [CONFIG.states.CLASSIFICATION],
        },
      ],
    },
  },
  {
    icon: BigidReportCenterIcon,
    title: 'Reports',
    tooltipText: 'Reports',
    onItemClicked: onNavigationItemClicked,
    link: 'reportsTEMP', //TODO: Replace when reports master page is added
    isQuickView: true,
    selectedItemStates: [
      'reportsTEMP',
      CONFIG.states.EXECUTIVE_REPORT,
      CONFIG.states.DIGEST_REPORT,
      CONFIG.states.SCAN_ANALYSIS,
      CONFIG.states.SCAN_RESULT,
      CONFIG.states.DASHBOARD,
      CONFIG.states.CUSTOM_DASHBOARD,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when reports master page is added
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Click edit and turn on your Reports',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'BigID Dashboard',
          link: CONFIG.states.DASHBOARD,
          icon: BigidDashboardIcon,
          selectedItemStates: [CONFIG.states.DASHBOARD],
        },
        {
          isVisible: true,
          title: 'Dashboard Manager',
          link: CONFIG.states.CUSTOM_DASHBOARD,
          icon: BigidDashboardManagerIcon,
          selectedItemStates: [CONFIG.states.CUSTOM_DASHBOARD],
        },
        {
          isVisible: true,
          title: 'Executive Report',
          link: CONFIG.states.EXECUTIVE_REPORT,
          icon: BigidExecutiveReportIcon,
          selectedItemStates: [CONFIG.states.EXECUTIVE_REPORT],
        },
        {
          isVisible: true,
          title: 'Activity Highlights',
          link: CONFIG.states.DIGEST_REPORT,
          icon: BigidActivityHighlightsIcon,
          selectedItemStates: [CONFIG.states.DIGEST_REPORT],
        },
        {
          isVisible: true,
          title: 'Risk Assessment',
          link: CONFIG.states.RISK_ASSESSMENT,
          icon: BigidReportRiskIcon,
          selectedItemStates: [CONFIG.states.RISK_ASSESSMENT],
        },
        {
          isVisible: true,
          title: 'Scan Results Details',
          link: CONFIG.states.SCAN_ANALYSIS,
          icon: BigidScanResultsDetailsIcon,
          selectedItemStates: [CONFIG.states.SCAN_ANALYSIS],
        },
        {
          isVisible: true,
          title: 'Scan Results Summary',
          link: CONFIG.states.SCAN_RESULT,
          icon: BigidScanResultsSummaryIcon,
          selectedItemStates: [CONFIG.states.SCAN_RESULT],
        },
        {
          isVisible: true,
          title: 'Scanned Files',
          link: 'scannedFilesDOWNLOAD',
          icon: BigidScannedFilesIcon,
          onItemClicked: sidebarService.onGetScannedFilesReport,
        },
        {
          isVisible: true,
          title: 'Data Custodian',
          link: 'dataCustodianDOWNLOAD',
          icon: BigidDataCustodianIcon,
          onItemClicked: sidebarService.onGetDataCustodianReport,
        },
        {
          isVisible: true,
          title: 'Labeling Propagation',
          icon: BigidLabelingReportIcon,
          link: 'labelingPropagationDOWNLOAD',
          onItemClicked: sidebarService.onGetLabelerReport,
        },
        {
          isVisible: true,
          title: 'Usage Report',
          link: 'usageReportDOWNLOAD',
          icon: BigidUsageReportIcon,
          onItemClicked: sidebarService.onGetUsageReport,
        },
        {
          isVisible: true,
          title: 'Hotspots Report',
          link: CONFIG.states.HOTSPOTS_REPORT,
          icon: BigidHotspotIcon,
          selectedItemStates: [CONFIG.states.HOTSPOTS_REPORT],
        },
      ],
    },
  },
  {
    icon: BigidDataSourceOnlyIcon,
    title: 'Data Management',
    tooltipText: 'Data Management',
    onItemClicked: onNavigationItemClicked,
    link: 'dataManagementTEMP', //TODO: Replace when data management master page is added
    isQuickView: true,
    selectedItemStates: [
      'dataManagementTEMP',
      CONFIG.states.DATA_SOURCE_CONNECTIONS,
      CONFIG.states.SCANS_CONTROLLER,
      CONFIG.states.SCANS_NEW,
      CONFIG.states.SCANS,
      CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
      CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
      CONFIG.states.SCANS_SAVED_PROFILES,
      CONFIG.states.EDIT_SCAN_PROFILE,
      CONFIG.states.CREATE_SCAN_PROFILE,
      CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
      CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION,
      CONFIG.states.SECONDARY_CONNECTIONS,
      CONFIG.states.NEW_SECONDARY_CONNECTION,
      CONFIG.states.APPLICATIONS_SETUP,
      CONFIG.states.CLASSIFIERS,
      CONFIG.states.RULES,
      CONFIG.states.SCAN_WINDOW,
      CONFIG.states.TAGS,
      CONFIG.states.SAVED_QUERIES,
      CONFIG.states.SENSITIVITY_CLASSIFICATION,
      CONFIG.states.AUDIT,
      CONFIG.states.POLICIES,
      CONFIG.states.SCANS_SCAN_TEMPLATES,
      CONFIG.states.SCANS_PLANNED_SCANS,
      CONFIG.states.DATA_SOURCE_INIT,
      CONFIG.states.CREATE_SCAN,
      CONFIG.states.SCAN_TEMPLATE,
      CONFIG.states.AUTO_DISCOVERY,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink',
      emptyStateIcon: BigidDataManagementIllustration,
      emptyStateText: 'Click edit and turn on your Data Sources and Scans pages',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Data Sources',
          link: CONFIG.states.DATA_SOURCE_ROUTER,
          icon: BigidDataSourceOnlyIcon,
          selectedItemStates: [
            CONFIG.states.DATA_SOURCE_CONNECTIONS,
            CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
            CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
            CONFIG.states.DATA_SOURCE_INIT,
            CONFIG.states.DATA_SOURCE_ROUTER,
            CONFIG.states.AUTO_DISCOVERY,
          ],
        },
        {
          isVisible: true,
          title: 'Scans',
          link: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
          icon: BigidDataSearchIcon,
          selectedItemStates: [
            CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
            CONFIG.states.SCANS_NEW,
            CONFIG.states.SCANS,
            CONFIG.states.SCANS_SCAN_TEMPLATES,
            CONFIG.states.SCANS_PLANNED_SCANS,
            CONFIG.states.CREATE_SCAN,
            CONFIG.states.SCAN_TEMPLATE,
            CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
            CONFIG.states.SCANS_SAVED_PROFILES,
            CONFIG.states.EDIT_SCAN_PROFILE,
            CONFIG.states.CREATE_SCAN_PROFILE,
            CONFIG.states.SCANS_CONTROLLER,
          ],
        },
        {
          isVisible: true,
          title: 'Classifier Tuning',
          link: CONFIG.states.CURATION,
          icon: BigidCheckedViewIcon,
          selectedItemStates: [CONFIG.states.CURATION],
        },
        {
          isVisible: true,
          title: 'Correlation Sets',
          link: CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
          icon: BigidCorrelationSetIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_SOURCE_CONNECTIONS, CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION],
        },
        {
          isVisible: true,
          title: 'Secondary Sources',
          link: CONFIG.states.SECONDARY_CONNECTIONS,
          icon: BigidSecondarySourcesIcon,
          selectedItemStates: [CONFIG.states.SECONDARY_CONNECTIONS, CONFIG.states.NEW_SECONDARY_CONNECTION],
        },
        {
          isVisible: true,
          title: 'Application Setup',
          link: CONFIG.states.APPLICATIONS_SETUP,
          icon: BigidApplicationSetupIcon,
          selectedItemStates: [CONFIG.states.APPLICATIONS_SETUP],
        },
        {
          isVisible: true,
          title: 'Classifiers',
          link: CONFIG.states.CLASSIFIERS,
          icon: BigidClassifiersIcon,
          selectedItemStates: [CONFIG.states.CLASSIFIERS],
        },
        {
          isVisible: true,
          title: 'Compound Attributes',
          link: CONFIG.states.RULES,
          icon: BigidCompoundAttributesIcon,
          selectedItemStates: [CONFIG.states.RULES],
        },
        {
          isVisible: true,
          title: 'Scan Windows',
          link: CONFIG.states.SCAN_WINDOW,
          icon: BigidScanWindowIcon,
          selectedItemStates: [CONFIG.states.SCAN_WINDOW],
        },
        {
          isVisible: true,
          title: 'Tags',
          link: CONFIG.states.TAGS,
          icon: BigidTagIcon,
          selectedItemStates: [CONFIG.states.TAGS],
        },
        {
          isVisible: true,
          title: 'Saved Queries',
          link: CONFIG.states.SAVED_QUERIES,
          icon: BigidSavedQueriesIcon,
          selectedItemStates: [CONFIG.states.SAVED_QUERIES],
        },
        {
          isVisible: true,
          title: 'Sensitivity Classification',
          link: CONFIG.states.SENSITIVITY_CLASSIFICATION,
          icon: BigidSensitivityClassificationIcon,
          selectedItemStates: [CONFIG.states.SENSITIVITY_CLASSIFICATION],
        },
        {
          isVisible: true,
          title: 'Policies',
          link: CONFIG.states.POLICIES,
          icon: BigidPoliciesIcon,
          selectedItemStates: [CONFIG.states.POLICIES],
        },
      ],
    },
  },
  {
    icon: BigidAdministrationIcon,
    title: 'Settings',
    tooltipText: 'Settings',
    onItemClicked: onNavigationItemClicked,
    link: 'settingsTEMP', //TODO: Replace when settings master page is added
    selectedItemStates: [
      'settingsTEMP',
      CONFIG.states.GENERAL_SETTINGS,
      CONFIG.states.ADVANCED_TOOLS,
      CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
      CONFIG.states.CERTIFICATES_MANAGEMENT,
      CONFIG.states.PROXIES,
      CONFIG.states.CREDENTIALS,
      CONFIG.states.CREDENTIAL_PROVIDERS,
      CONFIG.states.ACTION_CENTER,
      CONFIG.states.ACTION_CENTER_AUDIT,
      CONFIG.states.ACTION_CENTER_ACTIONS,
      CONFIG.states.ACTION_CENTER_OVERVIEW,
      CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when settings master page is added
      emptyStateIcon: BigidSettingsIllustration,
      emptyStateText: 'Click edit and turn on your Settings',
      menuItems: [
        {
          title: 'General Settings',
          isVisible: true,
          link: CONFIG.states.GENERAL_SETTINGS,
          icon: BigidAdministrationIcon,
          selectedItemStates: [CONFIG.states.GENERAL_SETTINGS],
        },
        {
          title: 'Advanced Tools',
          isVisible: true,
          link: CONFIG.states.ADVANCED_TOOLS,
          icon: BigidAdvanceSearchIcon,
          selectedItemStates: [CONFIG.states.ADVANCED_TOOLS],
        },
        {
          title: 'Access Management',
          isVisible: true,
          link: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
          icon: BigidGroupIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_ACCESS_MANAGEMENT],
        },
        {
          title: 'Certificates',
          isVisible: true,
          link: CONFIG.states.CERTIFICATES_MANAGEMENT,
          icon: BigidCertificatesIcon,
          selectedItemStates: [CONFIG.states.CERTIFICATES_MANAGEMENT],
        },
        {
          title: 'Credentials',
          isVisible: true,
          link: CONFIG.states.CREDENTIALS,
          icon: BigidCredentialsIcon,
          selectedItemStates: [CONFIG.states.CREDENTIALS],
        },
        {
          title: 'Proxies',
          isVisible: true,
          link: CONFIG.states.PROXIES,
          icon: BigidProxyIcon,
          selectedItemStates: [CONFIG.states.PROXIES],
        },
        {
          title: 'Credential Providers',
          isVisible: true,
          link: CONFIG.states.CREDENTIAL_PROVIDERS,
          icon: BigidVaultIcon,
          selectedItemStates: [CONFIG.states.CREDENTIAL_PROVIDERS],
        },
        {
          title: 'Action Center',
          isVisible: true,
          link: CONFIG.states.ACTION_CENTER_ACTIONS,
          icon: BigidActionCenterIcon,
          selectedItemStates: [
            CONFIG.states.ACTION_CENTER_ACTIONS,
            CONFIG.states.ACTION_CENTER,
            CONFIG.states.ACTION_CENTER_AUDIT,
            CONFIG.states.ACTION_CENTER_OVERVIEW,
            CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
          ],
        },
        {
          isVisible: true,
          title: 'Audit',
          link: CONFIG.states.AUDIT,
          icon: BigidHistoryIcon,
          selectedItemStates: [CONFIG.states.AUDIT],
        },
      ],
    },
  },
];

export const getInitialFooterItems = (onNavigationItemClicked: (link: string) => void): BigidNavigationItemType[] => [
  {
    icon: BigidApplicationsIcon,
    title: 'Apps Center',
    tooltipText: 'Apps Center',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.APPLICATIONS_MANAGEMENT,
    isQuickView: true,
    selectedItemStates: [CONFIG.states.APPLICATIONS_MANAGEMENT],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      masterPageLink: CONFIG.states.APPLICATIONS_MANAGEMENT,
      emptyStateIcon: BigidSettingsIllustration,
      emptyStateText: 'Click edit and turn on your Settings',
      menuItems: [],
    },
  },
];

export const FAVORITES_LINK = 'favoritesLinkPlaceholder';

export const getInitialHeaderItems = (onNavigationItemClicked: (link: string) => void): BigidNavigationItemType[] => [
  {
    isVisible: true,
    icon: BigidSpaceshipDefaultIcon,
    title: 'Getting Started',
    tooltipText: 'Getting Started',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.GETTING_STARTED,
    selectedItemStates: [CONFIG.states.GETTING_STARTED],
  },
  {
    icon: BigidStarIcon,
    title: 'Favorites',
    tooltipText: 'Favorites',
    isVisible: true,
    onItemClicked: onNavigationItemClicked,
    link: FAVORITES_LINK, //TODO: Replace when favorites master page is added
    hasDivider: true,
    isQuickView: true,
    selectedItemStates: [FAVORITES_LINK],
    isDeleteInQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      //masterPageLink: 'masterLink', //TODO: Uncomment when master page is added
      emptyStateIcon: BigidFavoritesIllustration,
      emptyStateText: 'Star your favorite pages',
      menuItems: [],
    },
  },
];

export function reorderFavoritesItemsByUserSettings(
  favoritesItemInMenu: BigidNavigationItemType[],
  userMenuItemsSettings: MenuSettingsItemBase[],
) {
  return favoritesItemInMenu
    .reduce<any>(
      (acc, favoritesItem, index) => {
        const indexInSettings = userMenuItemsSettings.findIndex(({ id }) => id === favoritesItem.link);
        if (indexInSettings > -1) {
          if (acc[indexInSettings] && acc[indexInSettings]?.id !== favoritesItem.link) {
            return [...acc, favoritesItem];
          }
          acc[indexInSettings] = favoritesItem;
          return acc;
        }

        return inserItemInMenu<BigidNavigationItemType>(acc, favoritesItem, index);
      },
      [...userMenuItemsSettings],
    )
    .filter((item: BigidNavigationItemType) => !!item.link);
}

export async function deleteAppsFromPreferences(appsIds?: string[]) {
  try {
    const {
      data: { favorites = [], recentlyUsed = [] },
    } = await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME);
    await userPreferencesService.update<ApplicationManagementPreferences>({
      preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
      data: {
        favorites: favorites?.filter(({ appId }) => !appsIds?.includes(appId)),
        recentlyUsed: recentlyUsed?.filter(({ appId }) => !appsIds?.includes(appId)),
      },
    });
    userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
