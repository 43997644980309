import React, { FC, useMemo, useState, useEffect } from 'react';
import { useLocalTranslation } from '../translations';
import styled from '@emotion/styled';
import {
  BigidHeading2,
  BigidBody1,
  PrimaryButton,
  TertiaryButton,
  BigidIconType,
  BigidInlineNotification,
  StyledButtonType,
  BigidInlineNotificationProps,
} from '@bigid-ui/components';
import { BigidBIEventData, generateDataAid } from '@bigid-ui/utils';
import { BigidDisLookerIllustration, BigidAdministrationIcon } from '@bigid-ui/icons';
import { ReportingEtlMonitoring } from '../EtlGridComponents/ReportingEtlMonitoring';
import { getDisOnBoardingStatus, getLookerUrl, OnBoardingStatus } from '../reportingEtlMonitoringUtils';
import { appsLicenseService } from '../../../services/appsLicenseService';
import { DATA_INSIGHTS_STUDIO } from '../consts/ReportingEtlConsts';
import { ReportingEtlTrackingEvents } from '../consts/ReportingEtlEventsConsts';
import isPropValid from '@emotion/is-prop-valid';

const DisWelcomePageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const TitleWrapper = styled('div')`
  margin-bottom: 20px;
  text-align: center;
`;

const DescriptionWrapper = styled('div')`
  margin-bottom: 20px;
  justify-content: center;
  width: 75%;
  gap: 10px;
  text-align: center;
`;

const ActionsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled('div')`
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const InLineNotificationWrapper = styled('div', {
  shouldForwardProp: prop => isPropValid(prop) || prop === 'data-aid',
})`
  margin-top: 25px;
`;

const IllustrationWrapper = styled('div')`
  margin-top: 25px;
  height: 50%;
`;

interface DisWelcomePageActions {
  label: string;
  execute: () => Promise<void>;
  show?: () => boolean;
  disable?: boolean;
  isTertiary?: boolean;
  icon?: BigidIconType;
  bi?: BigidBIEventData;
}

interface DisWelcomePageProps {
  dataAid?: string;
}
export const DisWelcomePage: FC<DisWelcomePageProps> = ({ dataAid = 'DisWelcomePage' }) => {
  const { t } = useLocalTranslation();
  const [shouldRenderMonitoring, setShouldRenderMonitoring] = useState<boolean>();
  const [isOnBoardingSucceeded, setIsOnBoardingSucceeded] = useState<OnBoardingStatus>();

  useEffect(() => {
    (async () => {
      const onBoardingStatus = await getDisOnBoardingStatus();
      // make onBoardingStatus return 'failed' | 'success' | 'in_progress' , add to the certificate object a status of the onBoarding
      setIsOnBoardingSucceeded(onBoardingStatus);
    })();
  }, []);

  const actions: DisWelcomePageActions[] = useMemo(
    () => [
      {
        label: t('disWelcomePage.manage'),
        execute: async () => {
          setShouldRenderMonitoring(true);
        },
        show: () => appsLicenseService.isAppLicenseRegistered(DATA_INSIGHTS_STUDIO),
        isTertiary: true,
        icon: BigidAdministrationIcon,
      },
      {
        label: t('disWelcomePage.viewReports'),
        execute: async () => {
          const lookerUrl = await getLookerUrl();
          window.open(lookerUrl, '_blank');
        },
        show: () => true,
        disable: isOnBoardingSucceeded === OnBoardingStatus.FAILED,
        bi: { eventType: ReportingEtlTrackingEvents.REPORTING_ETL_GO_TO_LOOKER_BUTTON_CLICK },
      },
    ],
    [isOnBoardingSucceeded, t],
  );

  const memoizedActions: JSX.Element[] = useMemo(
    () =>
      actions
        .filter(({ show }) => show?.())
        .map((action, index) => {
          const { label, execute, isTertiary, icon: Icon, disable, bi } = action;

          const buttonProps: StyledButtonType = {
            dataAid: generateDataAid(dataAid, ['action', label]),
            size: 'large',
            startIcon: Icon && <Icon />,
            disabled: disable,
            onClick: () => execute(),
            bi,
          };

          const ActionButton = isTertiary ? TertiaryButton : PrimaryButton;

          return (
            <ButtonWrapper key={index}>
              <ActionButton {...buttonProps} text={label} />
            </ButtonWrapper>
          );
        }),
    [actions, dataAid],
  );

  const inLineNotification: JSX.Element = useMemo(() => {
    let type: BigidInlineNotificationProps['type'] = 'warning';
    let subText = t('disWelcomePage.licenseWarning');
    if (appsLicenseService.isAppLicenseRegistered(DATA_INSIGHTS_STUDIO)) {
      switch (isOnBoardingSucceeded) {
        case OnBoardingStatus.FAILED:
          type = 'error';
          subText = t('disWelcomePage.onBoardingFailed');
          break;
        case OnBoardingStatus.IN_PROGRESS:
          type = 'info';
          subText = t('disWelcomePage.onBoardingInProgress');
          break;
        case OnBoardingStatus.SUCCESS:
          type = 'success';
          subText = t('disWelcomePage.onBoardingSucceeded');
          break;
      }
    }
    const inLineNotificationProps: BigidInlineNotificationProps = {
      showMoreButton: false,
      isClosable: false,
      type,
      text: [{ subText }],
    };
    return (
      <InLineNotificationWrapper data-aid={generateDataAid(dataAid, [type])}>
        <BigidInlineNotification {...inLineNotificationProps} open />
      </InLineNotificationWrapper>
    );
  }, [dataAid, isOnBoardingSucceeded, t]);

  return (
    <>
      {shouldRenderMonitoring ? (
        <ReportingEtlMonitoring />
      ) : (
        <DisWelcomePageWrapper data-aid={dataAid}>
          <TitleWrapper>
            <BigidHeading2 data-aid={generateDataAid(dataAid, ['title'])}>
              {t('disWelcomePage.title.firstLine')}
              <br />
              {t('disWelcomePage.title.secondLine')}
            </BigidHeading2>
          </TitleWrapper>
          <DescriptionWrapper>
            <BigidBody1 data-aid={generateDataAid(dataAid, ['description'])}>
              {t('disWelcomePage.description')}
            </BigidBody1>
          </DescriptionWrapper>
          <ActionsWrapper>{memoizedActions}</ActionsWrapper>
          {inLineNotification}
          <IllustrationWrapper>
            <BigidDisLookerIllustration data-aid={generateDataAid(dataAid, ['illustration'])} />
          </IllustrationWrapper>
        </DisWelcomePageWrapper>
      )}
    </>
  );
};
