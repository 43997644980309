import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { BigidInlineNotification } from '@bigid-ui/components';
import { CaseSidePanelContext } from '../../hooks/CaseSidePanelContext';
import { dateUtils } from '../../../../../services/angularServices';
import { ActionsListProps } from '../../hooks/CaseSidePanelReducer';
import {
  ACTION_COMPLETED,
  ACTION_IN_PROGRESS,
  CaseActionCommands,
  mapCommandToDisplayName,
} from '../CaseReportWidgets/CaseActionWidget/CaseActionsWidget';
import { generateDataAid } from '@bigid-ui/utils';

const Main = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
});

const ItemWrapper = styled.div({
  paddingBottom: 16,
});

interface StatusBannerProps {
  title: string;
  isShown: boolean;
  start: Date;
  end: number;
}

const StatusBanner: FC<StatusBannerProps> = ({ title, isShown, start, end }) => {
  const generatedMessage = (start: Date, end: number) => {
    if (start && end) {
      return `Start time: ${dateUtils.formatDate(start)} | Estimated End Time: ${end} minute(s)`;
    } else {
      return `Start time: ${dateUtils.formatDate(start)}`;
    }
  };

  if (!isShown) {
    return;
  }

  return (
    <ItemWrapper data-aid={generateDataAid('CaseWidget', ['status', title])}>
      <BigidInlineNotification
        type="info"
        title={`${title} action is currently in progress and will be disabled temporarily until the ongoing execution is complete.
        Track its status in the Action Center`}
        open={true}
        text={[
          {
            subText: generatedMessage(start, end),
          },
          {
            subText: 'After completion, it is recommended to re-scan objects to validate successful remediation',
          },
        ]}
      />
    </ItemWrapper>
  );
};

export const AccessStatusContainer: FC = () => {
  const { actionsList } = useContext(CaseSidePanelContext);
  const listOfActions = Object.keys(actionsList);

  return (
    <Main>
      {listOfActions?.map((action: string) => {
        const displayName = mapCommandToDisplayName.get(action as CaseActionCommands);
        const endEstimation = Math.round(actionsList[action as keyof ActionsListProps]?.estimatedRemainingTimeMinutes);

        return (
          <StatusBanner
            title={displayName}
            isShown={
              actionsList[action as keyof ActionsListProps]?.status === ACTION_IN_PROGRESS ||
              (actionsList[action as keyof ActionsListProps]?.status === ACTION_COMPLETED &&
                actionsList[action as keyof ActionsListProps]?.isBlocked)
            }
            start={actionsList[action as keyof ActionsListProps]?.created_at}
            end={endEstimation}
            key={action}
          />
        );
      })}
    </Main>
  );
};
