import { BigidButtonIcon, BigidHeading4 } from '@bigid-ui/components';
import { BigidGlobeIcon, BigidXIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { useLocalTranslation } from '../../../translations';

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

const NameSection = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type ModalHeaderProps = {
  domainName?: string;
  onClose: () => void;
};

export const ModalHeader: FC<ModalHeaderProps> = ({ domainName, onClose }) => {
  const { t } = useLocalTranslation('Settings.DomainModal');

  const domainValue = domainName ?? t('noNamePlaceholder');

  return (
    <HeaderContainer>
      <NameSection>
        <BigidGlobeIcon />
        <BigidHeading4>{domainValue}</BigidHeading4>
      </NameSection>
      <BigidButtonIcon icon={BigidXIcon} onClick={onClose} />
    </HeaderContainer>
  );
};
