import { httpService } from '../../services/httpService';
import { notificationService } from '../../services/notificationService';
import { ActionData, BigidFieldFilterOperator, EntityEvents } from '@bigid-ui/components';
import { ParentScanType, ScanActions, ScansGridRow, ScansTypes } from './ScanTypes';
import { ConfirmationDialogOptions, showConfirmationDialog } from '../../services/confirmationDialogService';
import { isNil, startCase } from 'lodash';
import { ReactText } from 'react';
import { runScanProfile } from './ScanProfiles/scanProfileService';
import { ScansUITrackingEvent, trackEventScansView } from './ScansEventTrackerUtils';
import { fetchScanData, getFailedObjectReport } from './ScanUtils';
import { getFixedT } from './translations';

export const getScanProfileById = async (id: string) => {
  const t = getFixedT('general');
  try {
    const { data } = await httpService.fetch(`/scanProfiles/${id}`);
    if (isNil(data)) {
      notificationService.error(t('scanProfileNotExist'));
    }
    return data || {};
  } catch (error) {
    notificationService.error(t('failedToLoadScanProfile'));
    return {};
  }
};

export async function getScansData(gridConfigQuery: string) {
  const {
    data: {
      data: { scanParents, totalCount },
    },
  } = await httpService.fetch<{ data: { scanParents: ScansGridRow[]; totalCount: number } }>(
    `scans/parent-scans?${gridConfigQuery}`,
  );

  return {
    scanParents,
    totalCount,
  };
}

function getTheRequiredScanType(scanType: string): ParentScanType {
  let requiredScanType = scanType;

  if (requiredScanType === ParentScanType.FULL_SCAN) {
    requiredScanType = 'dsScan';
  }

  if (requiredScanType === ParentScanType.LABELING) {
    requiredScanType = ParentScanType.DS_TAG;
  }

  return requiredScanType as ParentScanType;
}

export async function executeScanAction(actionData: ActionData, scanAction: ScanActions) {
  const { selectedRows } = actionData;
  try {
    const [{ _id, type: scanType, name }] = selectedRows;
    switch (scanAction) {
      case ScanActions.RESUME:
        await resumeScan(_id);
        break;
      case ScanActions.STOP:
        scanType === ParentScanType.LABELING ? await stopLabelingScan(_id) : await stopSubScan(_id);
        break;
      case ScanActions.ARCHIVE:
        await archiveScan(_id);
        notificationService.success('Scan archived successfully!');
        return {
          row: selectedRows[0],
          entityEventToEmit: EntityEvents.DELETE,
        };
      case ScanActions.DOWNLOAD_FAILED_OBJECTS_REPORT:
        await getFailedObjectReport(_id);
        notificationService.success('Failed objects report downloaded successfully!');
        break;
      case ScanActions.RETRY:
        await retryScan(_id);
        break;
      case ScanActions.RETRY_PREDICTION:
        await retryPredictionScan(_id, scanType);
        break;
      case ScanActions.PAUSE:
        await pauseScan(_id);
        break;
      case ScanActions.RESCAN:
        await runScanProfile({
          scanType: getTheRequiredScanType(scanType),
          scanProfileName: name,
        });
        return;
    }

    notificationService.info(`${startCase(scanAction)} in progress. This may take a while.`);
    return {};
  } catch (error) {
    notificationService.error(`Failed to ${startCase(scanAction)} selected scan. See logs for more information.`);
    return {};
  }
}

export async function runScanActionDialog(actionData: ActionData, actionType: ScanActions) {
  const isIdentitySubScan = actionData.selectedRows[0].type === ScansTypes.ID_SUB_SCAN;
  const runParentScanActionDialogProps: ConfirmationDialogOptions = {
    entityNameSingular: 'Scan',
    entityNamePlural: 'Scans',
    actionName: startCase(actionType),
    actionButtonName: actionType,
    isContentScrollable: true,
  };
  if (isIdentitySubScan) {
    runParentScanActionDialogProps.customDescription =
      'Stopping this Identity scan will stop the entire scan. Do you wish to proceed?';
  }
  const shouldCompleteAction = await showConfirmationDialog(runParentScanActionDialogProps);
  if (shouldCompleteAction) {
    trackEventScansView(ScansUITrackingEvent.SCAN_ACTION_CLICK, {
      actionName: actionType,
      ...actionData.selectedRows[0],
    });
    return executeScanAction(actionData, actionType);
  }
  return {};
}

async function pauseScan(scanId: ReactText) {
  await httpService.put(`scans/${scanId}/requestPause`);
}

async function retryScan(scanId: ReactText) {
  await httpService.put(`scans/${scanId}/requestRetry`);
}

async function retryPredictionScan(scanId: ReactText, type: string) {
  await httpService.post(`scans/${scanId}/retryPredict`, { type });
}

async function resumeScan(scanId: ReactText) {
  await httpService.put(`scans/${scanId}/requestResume`);
}

async function archiveScan(scanId: ReactText) {
  await httpService.post(`scans/archived`, { scan_ids: [scanId] });
}

async function stopLabelingScan(scanId: ReactText) {
  await httpService.put(`labeler/${scanId}/request-stop`);
}

async function stopSubScan(scanId: ReactText) {
  await httpService.put(`scans/${scanId}/requestStop`);
}

export const getScanByName = async (scanName: string) => {
  const filterByName = [
    {
      field: 'name',
      value: scanName,
      operator: 'equal' as BigidFieldFilterOperator,
    },
  ];

  const { data: createdScan } = await fetchScanData(
    {
      skip: 0,
      sort: [],
      limit: 1,
      filter: filterByName,
    },
    [],
  );
  return createdScan?.[0];
};

export const getScanMetricsByDays = (days: number) => httpService.fetch(`scans/parent-scans/metrics?days=${days}`);
