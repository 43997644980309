import { size, uniq } from 'lodash';
import { notificationService } from './notificationService';
import { httpService } from './httpService';
import { SystemEvents, systemEventsEmitter } from './systemEvents';
import { loginService } from '../../authentication/login/login.service';
import { objectToQueryString } from '@bigid-ui/components';
import { getApplicationPreference } from './appPreferencesService';

export interface SystemRole {
  _id: string;
  name: string;
  granularPermissions: string[];
  isSystem: boolean;
  legacyPermission?: string;
  permissions?: string[];
  scopes: string[];
  description?: string;
}

interface UserPermissions {
  userPermissions: Record<string, boolean>;
  isRootScope: boolean;
}

interface UserDataSourcePermissions {
  data: {
    isPermitted: boolean;
  };
}
interface UserDataSourcePermissionsMap {
  data: Record<string, boolean>;
}
const PERMISSION_PREFIX = "permission.";
let userPermissions: Record<string, boolean> = {};
let isRootScope = false;
let userScopedPermissionsCache: Record<string, Record<string, boolean>> = {};

export const updateUserPermissions = async () => {
  try {
    const { data } = await httpService.fetch<UserPermissions>('roles/rbac/user-permissions');
    userPermissions = data.userPermissions;
    isRootScope = data.isRootScope;
    userScopedPermissionsCache = {};
  } catch (error) {
    notificationService.error('Error getting user permissions');
  }
};

export const initPermissions = async () => {
  if (loginService.isLoggedIn() && size(userPermissions) === 0) {
    await updateUserPermissions();
  }
};

export const isPermitted = (permission: string) => userPermissions[permission] || false;
export const areAllPermitted = (permissions: string[]) => permissions.every(permission => isPermitted(permission));
export const areSomePermitted = (permissions: string[]) => permissions.some(permission => isPermitted(permission));
export const getAllPermissions = () => userPermissions;
export const getAllRoles = (): Record<string, boolean> =>
  Object.entries(userPermissions)
    .filter(([name]) => !name.startsWith(PERMISSION_PREFIX))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
export const hasRootScope = () => isRootScope;

const initUserPermissionsListener = systemEventsEmitter.addEventListener(SystemEvents.LOGIN, initPermissions);

const cleanUserPermissionsListener = systemEventsEmitter.addEventListener(SystemEvents.LOGOUT, () => {
  userPermissions = {};
});

export const getScopedPermissionByDataSource = async (permission: string, dataSourceName: string) => {
  try {
    if (!getApplicationPreference('SCOPE_PERMISSIONS_ENABLE')) {
      return isPermitted(permission);
    }
    const { data } = await httpService.fetch<UserDataSourcePermissions>(
      `roles/rbac/user-scoped-permissions/${permission}/data-source/${dataSourceName}`,
    );
    return data?.data?.isPermitted;
  } catch (error) {
    console.log(error);
    notificationService.error('Error getting user scoped permissions');
    return false;
  }
};

export const getScopedPermissionByDataSources = async (
  permission: string,
  dataSourcesNames: string[] = [],
): Promise<Record<string, boolean>> => {
  try {
    if (getApplicationPreference('SCOPE_PERMISSIONS_ENABLE')) {
      const query = objectToQueryString({
        dataSources: uniq(dataSourcesNames).join(','),
      });
      const { data } = await httpService.fetch<UserDataSourcePermissionsMap>(
        `roles/rbac/user-scoped-permissions/${permission}?${query}`,
      );
      return data?.data;
    } else {
      const result = isPermitted(permission);
      return dataSourcesNames.reduce((acc, name) => ({ ...acc, [name]: result }), {});
    }
  } catch (error) {
    console.log(error);
    notificationService.error('Error getting user scoped permissions');
    return {};
  }
};

window.addEventListener('unload', () => {
  initUserPermissionsListener();
  cleanUserPermissionsListener();
});
