import React, { FC } from 'react';
import { styled } from '@mui/material';
import { AutoDiscoverWizardAsideGuide } from './AutoDiscoverWizardAsideGuide';
import { AutoDiscoverWizardAsideErrors } from './AutoDiscoverWizardAsideErrors';
import { AutoDiscoverAsidePermissionsSummary } from './AutoDiscoverAsidePermissionsSummary/AutoDiscoverAsidePermissionsSummary';

const AutoDiscoverWizardAsideWrapper = styled('div')`
  display: flex;
  padding: 24px 28px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const AutoDiscoverWizardAside: FC = () => {
  return (
    <AutoDiscoverWizardAsideWrapper>
      <AutoDiscoverWizardAsideGuide />
      <AutoDiscoverAsidePermissionsSummary />
      <AutoDiscoverWizardAsideErrors />
    </AutoDiscoverWizardAsideWrapper>
  );
};
