import { getScanByName } from '../../Scans/ScanService';
import { openSystemDialog } from '../../../services/systemDialogService';
import { ActionData, SecondaryButton } from '@bigid-ui/components';
import { $state } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  createScheduledScan,
  getQuickScanToRun,
  handleScheduledScanErrors,
} from '../../Scans/ScanCreateWizard/createScanService';
import { runScanProfile } from '../../Scans/ScanProfiles/scanProfileService';
import { ParentScanType } from '../../Scans/ScanTypes';
import { ScanTemplateGridRow } from '../../Scans/ScanTemplates/scanTemplateTypes';
import { ScanStartedDialog } from './ScanStartedDialog';
import { notificationService } from '../../../services/notificationService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { ASSESSMENT_SCAN, DEFAULT_DATA_SOURCE_SCAN_NAME } from '../../Scans/ScanTemplates/scanTemplatesService';

const DEFAULT_SCAN_WARNING_TEXT =
  'We are going to scan selected data sources with the settings defined in the connectors page. All retrieved data will be classified for the most sensitive information such as passwords, certificates, credit card information and SSN numbers. Would you like to continue?';

const INSTANT_SCAN_WARNING_TEXT = 'The selected data sources will be scanned now. Are you sure you want to continue?';
const DEFAULT_INSTANT_SCANS = [DEFAULT_DATA_SOURCE_SCAN_NAME, ASSESSMENT_SCAN];

export const openScanRunningDialog = async (scanName: string, templateName: string, templateId?: string) => {
  const { id: scanId } = await getScanByName(scanName);
  openSystemDialog({
    title: 'Your scan has started',
    maxWidth: 'xs',
    buttons: [
      {
        component: SecondaryButton,
        text: 'View Scan',
        onClick: () => {
          $state.go(CONFIG.states.SCANS_SCAN_INSIGHTS, {
            scanProfileName: scanName,
            scanId,
          });
        },
      },
    ],
    content: () => ScanStartedDialog(templateName, templateId),
    onClose: () => null,
  });
};

const executeQuickScanWithTemplate =
  (label: string, scanType: string) =>
  async ({ selectedRows, selectedOptions }: ActionData) => {
    const [selectedTemplate] = selectedOptions;
    const ownerName = selectedTemplate.value.currentOwner.name;
    const templateId = selectedTemplate.value.scanTemplateId;
    const templateName = selectedTemplate.label;
    const isOTTB = selectedTemplate.hasSeparator;
    const dataSourcesIds = selectedRows.map(({ name }) => name);
    const shouldRun =
      templateName === DEFAULT_DATA_SOURCE_SCAN_NAME.toUpperCase()
        ? await showConfirmationDialog({
            allSelected: false,
            actionName: 'Run Scan',
            entityNameSingular: '',
            actionButtonName: 'Yes',
            cancelButtonName: 'No',
            customDescription: DEFAULT_SCAN_WARNING_TEXT,
          })
        : await showConfirmationDialog({
            allSelected: false,
            actionName: 'Instant Scan',
            entityNameSingular: '',
            actionButtonName: 'Continue',
            cancelButtonName: 'Cancel',
            customDescription: INSTANT_SCAN_WARNING_TEXT,
          });
    if (!shouldRun) {
      return { shouldClearSelection: false };
    }
    const scanToRun = getQuickScanToRun({ ownerName, templateName, templateId, dataSourcesIds });

    try {
      notificationService.info(`Your scan is processing, we'll let you know when it starts.`);
      const {
        data: { scanProfile },
      } = await createScheduledScan(scanToRun, selectedTemplate.value.scanTemplateId, false, true);

      const isRunScanSuccessful = await runScanProfile({
        scanType: scanType as ParentScanType,
        scanProfileName: scanProfile.name,
      });

      if (isRunScanSuccessful) {
        await openScanRunningDialog(scanProfile.name, label, isOTTB ? '' : templateId);
      }
    } catch (e) {
      handleScheduledScanErrors(e, selectedTemplate.label, 'create');
    }
    return { shouldClearSelection: true };
  };

export const getQuickScanOptions = (favoriteTemplates: ScanTemplateGridRow[], currentOwner: string) => {
  return favoriteTemplates.map(({ name: label, _id: scanTemplateId, scanType }) => {
    const isDefaultTemplate = DEFAULT_INSTANT_SCANS.includes(label);
    return {
      label: isDefaultTemplate ? label.toUpperCase() : label,
      value: {
        scanTemplateId,
        currentOwner,
      },
      hasSeparator: isDefaultTemplate,
      execute: executeQuickScanWithTemplate(label, scanType),
    };
  });
};
