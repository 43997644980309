import React, { FC, memo, ReactText, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidLink, BigidTooltip, BigidBody1 } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { AttributePreviewFieldValue } from './AttributePreview';
import { AttributePreviewFindingStatus } from './AttributePreviewFindingStatus';
import {
  CuratedFieldStatus,
  excludeFinding,
  unexcludeFinding,
  CurateFindingPayload,
  MatchType,
  DeleteCurateFindingPayload,
  FindingCurateProps,
} from '../../../../curationService';
import { getFindingHighlightColorByStatus } from '../../../../curationUtils';
import { openFieldValuePreviewDialog } from './attributePreviewService';
import { notificationService } from '../../../../../../services/notificationService';
import { CuratedFieldValuePreviewTooltip } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewTooltip';
import { useLocalTranslation } from '../../../../translations';
import { CurationEvents, trackEventCurationView } from '../../../../curationEventTrackerUtils';
import { CuratedFieldValuePreviewExcludeDialog } from '../../../CuratedFieldValuePreview/CuratedFieldValuePreviewExcludeDialog';
import { isPermitted } from '../../../../../../services/userPermissionsService';
import { CLASSIFIERS_PERMISSIONS, GENERAL_SETTINGS_PERMISSIONS } from '@bigid/permissions';

export type AttributePreviewCuratedValueProps = AttributePreviewFieldValue & {
  dataAid?: string;
  gridId: ReactText;
  rowId: ReactText;
  fieldId: ReactText;
  fieldName: string;
  onReload: () => void;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  findingValue: {
    flex: '0 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  moreContainer: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  moreLink: {
    marginRight: '8px',
  },
});

const showMoreAvailabilityThreshold = 35;

export const AttributePreviewCuratedValue: FC<AttributePreviewCuratedValueProps> = memo(
  ({
    dataAid = 'AttributePreviewCuratedValue',
    rowId,
    fieldId,
    fieldName,
    fieldValue,
    findingDetails = [],
    onReload,
  }) => {
    const { findingValue, reviewStatus } = findingDetails[0] || {};
    const isShowMoreAvailable = findingDetails?.length > 1 || findingValue?.length > showMoreAvailabilityThreshold;

    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [radioButtonValue, setRadioButtonValue] = useState<MatchType>();
    const { t } = useLocalTranslation('CuratedFields.structured.AttributePreview');

    const uncuratedFindingsCount = findingDetails.filter(
      ({ reviewStatus }) => reviewStatus === CuratedFieldStatus.UNCURATED,
    ).length;
    const approvedFindingsCount = findingDetails.filter(
      ({ reviewStatus }) => reviewStatus === CuratedFieldStatus.APPROVED,
    ).length;
    const rejectedFindingsCount = findingDetails.filter(
      ({ reviewStatus }) => reviewStatus === CuratedFieldStatus.REJECTED,
    ).length;

    const handleFindingCurate = async ({ finding, status, fieldId, matchType }: FindingCurateProps) => {
      const { findingValue, excludePatternId } = finding;
      try {
        const excludePayload: CurateFindingPayload = {
          curationFieldId: fieldId,
          value: findingValue,
          matchType: matchType,
        };

        const unexcludePayload: DeleteCurateFindingPayload = {
          curationFieldId: fieldId,
          patternId: excludePatternId,
        };

        switch (status) {
          case CuratedFieldStatus.UNCURATED:
            await unexcludeFinding(unexcludePayload);
            break;
          case CuratedFieldStatus.REJECTED:
            await excludeFinding(excludePayload);
            break;
        }
        onReload();
      } catch ({ message }) {
        const notificationMessage = t('errors.curation');
        notificationService.error(notificationMessage);
        console.error(`${t('errors.generic')}: ${message}`);
      }
    };
    const isPermittedToExclude =
      isPermitted(CLASSIFIERS_PERMISSIONS.EDIT.name) &&
      isPermitted(CLASSIFIERS_PERMISSIONS.DELETE.name) &&
      isPermitted(GENERAL_SETTINGS_PERMISSIONS.EDIT_IGNORED_LISTS.name) &&
      isPermitted(GENERAL_SETTINGS_PERMISSIONS.CREATE_IGNORED_LISTS.name);

    const handleLinkClick = () => {
      trackEventCurationView(CurationEvents.CURATION_FIELDS_ATTRIBUTE_PREVIEW_MORE_BUTTON_CLICK);
      openFieldValuePreviewDialog({
        fieldName,
        valueChunks: [
          {
            chunkValue: fieldValue,
            findingDetails,
          },
        ],
        onFindingCurate: handleFindingCurate,
        isPermittedToExclude,
      });
    };

    const handleOnDialogStateChange = (isOpen: boolean) => {
      setIsDialogOpen(isOpen);
    };

    const handleExcludeSelectedOption = (option: MatchType) => {
      setRadioButtonValue(option);
    };

    return (
      <div className={classes.root} data-aid={generateDataAid(dataAid, [rowId, fieldName])}>
        {isPermittedToExclude ? (
          <BigidTooltip
            title={
              <CuratedFieldValuePreviewTooltip
                dataAid={generateDataAid(dataAid, [rowId, fieldName, 'tooltip'])}
                finding={findingDetails[0]}
                onFindingCurate={handleFindingCurate}
                onDialogStateChange={handleOnDialogStateChange}
                onExcludeOptionSelected={handleExcludeSelectedOption}
                fieldId={fieldId}
              />
            }
          >
            <BigidBody1
              className={classes.findingValue}
              sx={{ backgroundColor: getFindingHighlightColorByStatus(reviewStatus) }}
            >
              {findingValue}
            </BigidBody1>
          </BigidTooltip>
        ) : (
          <BigidBody1
            className={classes.findingValue}
            sx={{ backgroundColor: getFindingHighlightColorByStatus(reviewStatus) }}
          >
            {findingValue}
          </BigidBody1>
        )}
        {isPermittedToExclude && (
          <CuratedFieldValuePreviewExcludeDialog
            dataAid={generateDataAid(dataAid, [rowId, fieldName, 'dialog'])}
            finding={findingDetails[0]}
            onDialogStateChange={handleOnDialogStateChange}
            isOpen={isDialogOpen}
            excludeSelectedOption={radioButtonValue}
            onExcludeOptionSelected={handleExcludeSelectedOption}
            fieldId={fieldId}
            onFindingCurate={handleFindingCurate}
          />
        )}
        <div className={classes.moreContainer}>
          {isShowMoreAvailable && (
            <>
              <div className={classes.moreLink}>
                <BigidTooltip title={t('moreTooltip')}>
                  <div>
                    <BigidLink
                      text={t('more')}
                      onClick={handleLinkClick}
                      dataAid={generateDataAid(dataAid, [rowId, fieldName, 'toggle-modal-link'])}
                    />
                  </div>
                </BigidTooltip>
              </div>
              <AttributePreviewFindingStatus
                dataAid={generateDataAid(dataAid, [rowId, fieldName, 'legend'])}
                approvedFindingsCount={approvedFindingsCount}
                rejectedFindingsCount={rejectedFindingsCount}
                uncuratedFindingsCount={uncuratedFindingsCount}
              />
            </>
          )}
        </div>
      </div>
    );
  },
);
