import React, { useMemo, FC, useCallback, memo, useRef } from 'react';
import { debounce } from 'lodash';
import {
  BigidForm,
  BigidFormRenderProps,
  BigidFormStateAndHandlers,
  compareObjectsExceptFunctions,
} from '@bigid-ui/components';
import { styled } from '@mui/material';
import { AutoDiscoveryWizardContextState } from '../../autoDiscoveryWizardContext';
import { generateAutoDiscoveryFieldsData, resetStateOnAuthTypeChanged } from '../../autoDiscoveryWizardFormUtils';
import { AutoDiscoveryWizardAuthSection } from './AutoDiscoveryWizardAuthSection';
import { AutoDiscoveryGoToAdvanced } from './AutoDiscoveryGoToAdvanced';

const AutoDiscoverWizardFormWrapper = styled('div')`
  padding: 24px 32px;
  max-width: 600px;
  min-width: 400px;
`;

export interface AutoDiscoverWizardFormProps {
  discoveryConfigDataRef: React.MutableRefObject<AutoDiscoveryWizardContextState>;
  formControlRef: React.MutableRefObject<Partial<BigidFormStateAndHandlers>>;
  setDiscoveryConfigData: React.Dispatch<React.SetStateAction<AutoDiscoveryWizardContextState>>;
}

export const AutoDiscoverWizardForm: FC<AutoDiscoverWizardFormProps> = memo(
  ({ setDiscoveryConfigData, discoveryConfigDataRef, formControlRef }) => {
    const containerRef = useRef(null);
    const onChange = useCallback(
      debounce((values: Record<string, any>) => {
        setDiscoveryConfigData(current => ({
          ...current,
          formData: {
            ...current.formData,
            ...values,
          },
          ...resetStateOnAuthTypeChanged(values, current),
        }));
      }, 500),
      [setDiscoveryConfigData],
    );

    const formProps = useMemo(() => {
      const { initialValues, mainFields, fields } = generateAutoDiscoveryFieldsData(discoveryConfigDataRef.current);
      const renderForm = ({
        renderField,
        getValues,
        getFieldProps,
        validateAndSubmit,
        setErrors,
        errors,
      }: BigidFormRenderProps) => {
        const values = getValues();
        formControlRef.current = { validateAndSubmit, setErrors };

        return (
          <>
            {mainFields.map(({ name }) => renderField(name))}
            <AutoDiscoveryWizardAuthSection
              errors={errors}
              renderField={renderField}
              getFieldProps={getFieldProps}
              fields={fields}
              values={values}
            />
          </>
        );
      };
      return {
        initialValues,
        onChange,
        controlButtons: false,
        fields: [...mainFields, ...fields],
        renderForm,
      };
    }, [discoveryConfigDataRef, formControlRef, onChange]);

    return (
      <AutoDiscoverWizardFormWrapper ref={containerRef} data-aid={'AutoDiscoverWizardForm'}>
        <BigidForm {...formProps} />
        <AutoDiscoveryGoToAdvanced />
      </AutoDiscoverWizardFormWrapper>
    );
  },
  compareObjectsExceptFunctions,
);
