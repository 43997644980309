import React, { FC } from 'react';
import { SecondaryButton } from '@bigid-ui/components';
import { $state } from '../../../../services/angularServices';
import { CONFIG } from '../../../../../config/common';
import { CurationEvents } from '../../../Curation/curationEventTrackerUtils';
import styled from '@emotion/styled';
import { BigidCheckedViewIcon } from '@bigid-ui/icons';

const TuneClassifiersButton = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

export const NavigateToFindingsReviewFromDsButton: FC = () => {
  const handleOnClick = () => {
    $state.go(CONFIG.states.CURATION);
  };

  return (
    <TuneClassifiersButton>
      <SecondaryButton
        onClick={handleOnClick}
        size="large"
        dataAid="NavigateToFindingsReviewButton"
        bi={{
          eventType: CurationEvents.SCAN_PROFILE_REVIEW_FINDINGS_SELECT_BUTTON,
        }}
        startIcon={<BigidCheckedViewIcon color="inherit" />}
        text="Classifier Tuning"
      />
    </TuneClassifiersButton>
  );
};

NavigateToFindingsReviewFromDsButton.displayName = 'NavigateToFindingsReviewFromDsButton';
