import { useCallback, useRef } from 'react';
import { getApps } from '../../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesService } from '../../../services/userPreferencesService';
import { FavoritePagePreferences, FAVORITES_PAGE_USER_PREFERENCE_NAME } from '../favoritePagesManagementService.ts';
import { deleteAppsFromPreferences, FAVORITES_LINK, PREFERENCES_NAME } from '../navigationSidebarUtils';
import { MenuSettings, MenuSettingsItem } from '@bigid-ui/components';
import {
  userPreferencesEventEmitter,
  UserPreferencesEvents,
} from '../../../services/eventEmitters/userPreferencesEvents';

function filterAppsNotDeleted(apps: any[], favorites: MenuSettingsItem) {
  const appsOriginal = getApps();
  const currentFavoritesAppsWithLinks = apps.map(({ appId, _id }) => {
    const app = appsOriginal?.find?.(({ id }) => id === appId);
    return {
      appId,
      link: app?.to || appId || _id,
    };
  });
  const favoritesAppsDeleted = currentFavoritesAppsWithLinks.filter(({ link }) => {
    return !favorites?.menuItems?.some(({ id }) => id === link);
  });

  return favoritesAppsDeleted;
}

export const useFavoritesAggregated = () => {
  const favoritesRef = useRef({ pages: [], apps: [] });
  const settingsRef = useRef({ main: [], header: [] });

  const updateUserFavorites = useCallback(async (settingsNew: MenuSettings) => {
    const updateSettingsPromises = [];
    const { apps, pages } = favoritesRef.current;
    const favorites = settingsNew.find(({ id }) => id === FAVORITES_LINK);

    const favoritesPagesAfterDeleteOperation = pages.filter(({ link }) => {
      return favorites?.menuItems?.some(({ id }) => id === link);
    });

    const favoritesAppsDeleted = filterAppsNotDeleted(apps, favorites);

    if (favoritesPagesAfterDeleteOperation?.length !== pages?.length) {
      updateSettingsPromises.push(
        (async () => {
          await userPreferencesService.update<FavoritePagePreferences>({
            preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
            data: { favorites: favoritesPagesAfterDeleteOperation },
          });
          userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
        })(),
      );
    }

    if (favoritesAppsDeleted?.length) {
      const favoritesAppsIDs = favoritesAppsDeleted?.map(({ appId }) => appId);
      updateSettingsPromises.push(deleteAppsFromPreferences(favoritesAppsIDs));
    }

    updateSettingsPromises.push(
      userPreferencesService.update({
        preference: PREFERENCES_NAME,
        data: {
          header: settingsNew,
          main: settingsRef.current.main,
        },
      }),
    );
    settingsRef.current = {
      ...settingsRef.current,
      header: settingsNew,
    };
    await Promise.all(updateSettingsPromises);

    return true;
  }, []);

  return {
    updateUserFavorites,
    favoritesRef,
    settingsRef,
  };
};
