import { BigidButtonIcon, BigidIconType, BigidTabs, BigidTabsItem } from '@bigid-ui/components';
import React, { useState, useEffect, useMemo, ReactNode } from 'react';
import { ScanChildInfo, SubscanStage } from '../ScanInsightTypes';
import { dateTimeService } from '@bigid-ui/i18n';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { copyToClipboard, NOT_APPLICABLE } from '../../ScanUtils';
import { Details } from './Details';
import { Insights } from './Insights';
import { ParentScanType } from '../../ScanTypes';
import styled from '@emotion/styled';
import { InsightsFFforLabeling } from './InsightsFFforLabeling';

const enum ScanChildInfoEnum {
  SCANNER_ID = 'Scanner ID',
  SCANNER_GROUP = 'Scanner Group',
  SUBSCAN_ID = 'SubScan ID',
  NUMBER_OF_THREADS = 'Number Of Threads',
  STARTED_AT = 'Started at',
  ENDED_AT = 'Ended at',
  LAST_UPDATED_AT = 'Last updated at',
  SCAN = 'Scan',
  CORRELATION = 'Correlation',
  SUMMARY = 'Summary',
  ENUMERATION_SCANNER_ID = 'Enumeration Scanner ID',
  ENUMERATION_STATUS = 'Enumeration Status',
  OBJECTS_ENUMERATED = 'Objects Enumerated',
  LAST_UPDATED_ENUMERATION = 'Last updated enumeration',
}

const TabsContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabsBox = styled('div')`
  margin-bottom: 12px;
  padding: 16px 16px 0;
`;

const TabsContentContainer = styled('div')`
  flex: 1;
  overflow: scroll;
`;

const TabsContent = styled('div')`
  padding: 16px;
`;

const INSIGHTS = 'Insights';
const DETAILS = 'Details';

const DETAILS_TAB: BigidTabsItem = { label: DETAILS, disabled: false };
const INSIGHTS_TAB = { label: INSIGHTS, disabled: false };

const TABS = [DETAILS_TAB, INSIGHTS_TAB];

export interface ScanDetailRowContent {
  title: string;
  info?: string | number | ReactNode;
  icon?: BigidIconType;
}

export const Content = (props: Partial<ScanChildInfo>) => {
  const [selectedTab, setSelectedTab] = useState(DETAILS_TAB);
  const [scanDetails, setScanDetails] = useState<Partial<ScanChildInfo>>();
  const [tabs, setTabs] = useState(TABS);

  const {
    _id,
    startedAt,
    endedAt,
    scannerGroup,
    numberOfThreads,
    updatedAt,
    type,
    enumerationScannerId,
    enumerationDetails = { status: '', objectsEnumerated: 0, lastUpdatedAt: '' },
    parentScanType,
    errorMessageStack,
    errorMessage,
    scannerIds = [],
  } = props || {};
  const isDsScan = type === SubscanStage.DATA_SOURCE_SCAN;
  const isLabelingScan = type === SubscanStage.TAGGING_SCAN;

  useEffect(() => {
    setSelectedTab(DETAILS_TAB);
    updateTabs();
    // TODO: this line was disabled on global icon change, the owner of this code should take a look at why there are missing dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  const updateTabs = () => {
    if ((!isDsScan && !isLabelingScan) || parentScanType === ParentScanType.METADATA_SCAN) {
      const requiredIndex = tabs.findIndex(tab => tab.label === INSIGHTS);
      const copyTabs = [...tabs];
      copyTabs[requiredIndex] = {
        ...copyTabs[requiredIndex],
        disabled: true,
      };
      setTabs(copyTabs);
    } else {
      setTabs(TABS);
    }
  };

  const onChangeTab = (_tabIndex: number, tab: { label: string }) => {
    setSelectedTab(tab);
  };

  const getSselectedTab = () => {
    return tabs.findIndex(tab => tab.label === selectedTab.label);
  };

  const insightSection = useMemo(() => {
    if (selectedTab.disabled === false && selectedTab.label === INSIGHTS_TAB.label) {
      return isLabelingScan ? (
        <InsightsFFforLabeling scanDetails={scanDetails || props} onUpdateScanDetails={setScanDetails} />
      ) : (
        <Insights scanDetails={scanDetails || props} onUpdateScanDetails={setScanDetails} />
      );
    }
  }, [selectedTab.disabled, selectedTab.label, scanDetails, props]);

  const getBaseScanDetails = () => {
    return [
      { title: ScanChildInfoEnum.STARTED_AT, info: dateTimeService.formatDate(startedAt) || NOT_APPLICABLE },
      {
        title: ScanChildInfoEnum.ENDED_AT,
        info: dateTimeService.formatDate(endedAt, { invalidDateText: endedAt }) || NOT_APPLICABLE,
      },
      { title: ScanChildInfoEnum.LAST_UPDATED_AT, info: dateTimeService.formatDate(updatedAt) || NOT_APPLICABLE },
    ];
  };

  const getSubScanDetails = () => {
    return [
      {
        title: ScanChildInfoEnum.SUBSCAN_ID,
        info: _id,
        icon: () => <BigidButtonIcon icon={BigidCopyIcon} onClick={() => copyToClipboard(_id)} />,
      },
      { title: ScanChildInfoEnum.STARTED_AT, info: dateTimeService.formatDate(startedAt) || NOT_APPLICABLE },
      { title: ScanChildInfoEnum.LAST_UPDATED_AT, info: dateTimeService.formatDate(updatedAt) || NOT_APPLICABLE },
      {
        title: ScanChildInfoEnum.ENDED_AT,
        info: dateTimeService.formatDate(endedAt, { invalidDateText: endedAt }) || NOT_APPLICABLE,
      },
      { title: ScanChildInfoEnum.ENUMERATION_STATUS, info: enumerationDetails.status },
      {
        title: ScanChildInfoEnum.LAST_UPDATED_ENUMERATION,
        info: dateTimeService.formatDate(enumerationDetails.lastUpdatedAt),
      },
      { title: ScanChildInfoEnum.SCANNER_GROUP, info: scannerGroup },
      { title: ScanChildInfoEnum.NUMBER_OF_THREADS, info: numberOfThreads || NOT_APPLICABLE },
    ];
  };

  const getScanDetailsToShow = () => {
    if (isDsScan) {
      return getSubScanDetails();
    }
    return getBaseScanDetails();
  };

  return (
    <TabsContainer>
      <TabsBox>
        <BigidTabs tabs={tabs} orientation="horizontal" selectedIndex={getSselectedTab()} onChange={onChangeTab} />
      </TabsBox>
      <TabsContentContainer>
        <TabsContent>
          {selectedTab.label === DETAILS_TAB.label && (
            <Details
              scanDetailsToShow={getScanDetailsToShow()}
              scannersIds={scannerIds ?? []}
              isDsScan={isDsScan}
              enumerationScannerId={enumerationScannerId}
              errorMessages={errorMessageStack}
              errorMessage={errorMessage}
            />
          )}

          {((isDsScan && parentScanType !== ParentScanType.METADATA_SCAN) || type === SubscanStage.TAGGING_SCAN) &&
            insightSection}
        </TabsContent>
      </TabsContentContainer>
    </TabsContainer>
  );
};
