import { isPermitted, getAllRoles } from '../../../services/userPermissionsService';
import { isBoolean, isNil } from 'lodash';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { $state, localStorageService } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import {
  DS_COLLABORATOR_ROLE_NAME,
  COLLABORATION_FF,
} from '../../DataSources/DataSourceConnectionModal/constants/constants';
import type { LoginOverride } from '../types';

/**
 * @info Conditional navigation overrides triggered after logging in
 * executed on first come first serve basis based on the 'isPermitted' flag
 **/

const LOGIN_NAVIGATION_OVERRIDES: LoginOverride[] = [
  {
    name: 'SHOW_COLLABORATION_GUIDED_TOUR',
    isPermitted: () => {
      const isDsCollaborator = getApplicationPreference(COLLABORATION_FF) && isPermitted(DS_COLLABORATOR_ROLE_NAME);
      const skipGuidedTour = localStorageService.get('skipGuidedTour');
      const shouldDisplayGuidedTour = isBoolean(skipGuidedTour) ? !skipGuidedTour : skipGuidedTour !== 'true';

      return isDsCollaborator && shouldDisplayGuidedTour;
    },
    isFinal: true,
    action: () => {
      // postpone other guided tour until next login or indefinitely
      localStorageService.set('guidedTourPostponed', true);
      $state.go(CONFIG.states.DATA_SOURCE_CONNECTIONS, {
        shouldDisplayGuidedTour: true,
      });
    },
  },
  {
    name: 'ENABLE_NAVIGATION_GUIDED_TOUR_AFTER_COLLABORATION_TOUR',
    isPermitted: () => !isNil(localStorageService.get('guidedTourPostponed')),
    isFinal: false,
    action: () => {
      const isDsCollaborator = getApplicationPreference(COLLABORATION_FF) && isPermitted(DS_COLLABORATOR_ROLE_NAME);
      const hasSingleRole = !Object.keys(getAllRoles()).length
      const isExternalCollaborator = isDsCollaborator && hasSingleRole
      // never show other tours for collaborators with only `ds-collaborator` role
      !isExternalCollaborator && localStorageService.set('guidedTourPostponed', false)
    },
  },
];

const overrides = [...LOGIN_NAVIGATION_OVERRIDES] as const;

export const getLoginOverrides = (): readonly LoginOverride[] => overrides;
