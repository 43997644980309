import React, { FC, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { BigidHeading4 } from '@bigid-ui/components';
import { BigidGrid } from '@bigid-ui/grid';
import { ConvertScanProfileContext } from './convertScanProfileContext';
import { getInitialScanProfileOverviewGridData } from './ConvertScanProfileConfiguration';
import { generateDataAid } from '@bigid-ui/utils';

const Container = styled('div')`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  flex: 1;
  overflow: hidden;
`;

const Wrapper = styled('div')`
  display: flex;
  overflow: hidden;
`;

const GridWrapper = styled('div')<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ isLast }) => (isLast ? 0 : '24px')};
  width: ${({ isLast }) => (isLast ? '100%' : 'calc(100% - 25px)')};
`;

const GridWrapperTitle = styled(BigidHeading4)`
  margin-bottom: 16px;
`;

export const ConvertScanProfileOverviewContent: FC = () => {
  const { scanProfileData } = useContext(ConvertScanProfileContext);
  const scanProfileOverviewGridData = useMemo(
    () => getInitialScanProfileOverviewGridData(scanProfileData),
    [scanProfileData],
  );
  const onPagingChanged = () => {
    return;
  };
  return (
    <Container>
      {scanProfileOverviewGridData.map((data, index) => (
        <Wrapper key={index}>
          <GridWrapper isLast={index === scanProfileOverviewGridData.length - 1}>
            <GridWrapperTitle>{data.title}</GridWrapperTitle>
            <BigidGrid
              dataAid={generateDataAid(`${data.title}-BigidGrid`, [])}
              {...data}
              onPagingChanged={onPagingChanged}
            />
          </GridWrapper>
          {data.hasDivider && <Divider orientation="vertical" />}
        </Wrapper>
      ))}
    </Container>
  );
};
