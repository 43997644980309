import { userPreferencesService } from '../../services/userPreferencesService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { BigidNavigationItemType } from '@bigid-ui/components';

export const FAVORITES_PAGE_USER_PREFERENCE_NAME = 'favoritePagesManagement';

export interface FavoritePageProps {
  link: string;
  dateAdded: Date;
}

export interface FavoritePagePreferences {
  favorites?: FavoritePageProps[];
}

export const onSelectFavoritePage = async (link: string) => {
  const preference = await userPreferencesService.get<FavoritePagePreferences>(FAVORITES_PAGE_USER_PREFERENCE_NAME);
  if (!preference) {
    await userPreferencesService.add<FavoritePagePreferences>({
      preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
      data: { favorites: [{ link, dateAdded: new Date() }] },
    });
  } else {
    const {
      data: { favorites = [], ...preferenceData },
    } = preference;
    const favoritesIndex = favorites.findIndex(favorite => favorite.link === link);
    if (favoritesIndex === -1) {
      await userPreferencesService.update<FavoritePagePreferences>({
        preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
        data: { favorites: [...favorites, { link, dateAdded: new Date() }], ...preferenceData },
      });
    } else {
      favorites.splice(favoritesIndex, 1);
      await userPreferencesService.update<FavoritePagePreferences>({
        preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
        data: { favorites, ...preferenceData },
      });
    }
  }
  userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
};

export const deleteFavoritePageFromPreferences = async (itemLink: string) => {
  const {
    data: { favorites = [] },
  } = await userPreferencesService.get<FavoritePagePreferences>(FAVORITES_PAGE_USER_PREFERENCE_NAME);
  await userPreferencesService.update<FavoritePagePreferences>({
    preference: FAVORITES_PAGE_USER_PREFERENCE_NAME,
    data: {
      favorites: favorites.filter(({ link }) => link !== itemLink),
    },
  });
  userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
};

export const getFavoritePages = async (initialNavigationItems: BigidNavigationItemType[]) => {
  const { data } =
    (await userPreferencesService.get<FavoritePagePreferences>(FAVORITES_PAGE_USER_PREFERENCE_NAME)) || {};

  if (!data?.favorites) {
    sessionStorageService.set('FAVORITE_PAGES', []);
    return { favoriteItems: [], favoritesOriginal: [] };
  }

  const favoriteItems: BigidNavigationItemType[] = [];

  data.favorites.forEach(favoriteElement => {
    const mainItemElement = initialNavigationItems?.find(navEl => navEl.link === favoriteElement.link);
    if (mainItemElement) {
      favoriteItems.push(mainItemElement);
    } else {
      initialNavigationItems.forEach(element => {
        const readySubElement = element?.quickViewSchema?.menuItems?.find(
          (navEl: any) => navEl.link === favoriteElement.link,
        );
        readySubElement && favoriteItems.push(readySubElement as BigidNavigationItemType);
      });
    }
  });
  sessionStorageService.set('FAVORITE_PAGES', favoriteItems);
  return { favoriteItems, favoritesOriginal: data?.favorites };
};
