import { useLayoutEffect, useState } from 'react';
import { CredentialTypes } from '../types';
import { ActionItemResponse } from '../../CustomApp/types';
import { customAppService } from '../../../services/customAppService';
import { notificationService } from '../../../services/notificationService';
import { useLocalTranslation } from '../translations';

interface UseActions {
  credentialType: CredentialTypes;
  selectedAppId: string;
}

export const useActions = ({ credentialType, selectedAppId }: UseActions) => {
  const [actions, setActions] = useState<ActionItemResponse[]>([]);
  const { t } = useLocalTranslation();

  useLayoutEffect(() => {
    const fetchActions = async () => {
      try {
        const { data } = await customAppService.getCustomAppActions(selectedAppId);
        const actionsData = (data as ActionItemResponse[]).filter(
          ({ execution_type }) => execution_type === 'OneTimeRun',
        );
        setActions(actionsData);
      } catch (error) {
        notificationService.error(t('message.commonError'));
      }
    };

    if (credentialType === CredentialTypes.CustomApplicationVault && selectedAppId) {
      fetchActions();
    }
  }, [credentialType, selectedAppId, t]);

  return actions;
};
