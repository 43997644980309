import { styled } from '@mui/material';
import React from 'react';
import { useLocalTranslation } from '../../../translations';
import { TextArea } from '../components/TextArea';
import { AttributeDataCard } from './AttributeDataCard/AttributeDataCard';
import { AttributeTabHeading } from './AttributeTabHeading';

const GridContainer = styled('div')`
  display: grid;
  flex-grow: 1;
  gap: 24px;
  grid-template-rows: auto 2fr 1fr;
  grid-template-columns: 1.5fr 1fr 1fr;
  max-height: 573px;
`;

const AttributeData = styled('div')`
  display: flex;
  grid-row: span 2;
  flex-grow: 1;
  overflow-y: scroll;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const CustomField = styled('div')`
  display: flex;
  grid-column: span 2;
  flex-grow: 1;
`;

export const AttributeInfoTab = () => {
  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Cards');

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(event.target.value);
  };

  return (
    <GridContainer>
      <AttributeTabHeading />
      <TextArea
        title={t('Description.title')}
        onChange={handleDescriptionChange}
        placeholder={t('Description.placeholder')}
      />
      <AttributeData>
        <AttributeDataCard />
      </AttributeData>
      <CustomField>
        <TextArea title={t('CustomFields.notPaidDescription')} disabled />
      </CustomField>
    </GridContainer>
  );
};
