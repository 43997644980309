import React, { FC, useState } from 'react';
import { PrimaryButton, SecondaryButton, BigidLoader } from '@bigid-ui/components';
import {
  closeSystemDialog,
  openSystemDialog,
  SystemDialogContentProps,
} from '../../../react/services/systemDialogService';
import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty, isEqual, differenceWith, uniqBy } from 'lodash';
import { ExtraFieldIgnoreValue } from '../ExtraFieldIgnoreValue/ExtraFieldIgnoreValue';
import { notificationService } from '../../../react/services/notificationService';

export type IgnoreValue = {
  classification_name: string;
  value: string;
  id: string;
  _id?: string;
  type: string;
};

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: '16px',
  },
});

export const ClassifiersExcludeListDialog: FC<SystemDialogContentProps<any>> = ({
  onChangeRegex,
  onChangeString,
  regexList,
  stringList,
  classificationName,
  deleteExcludeValueHandler,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles({});

  return (
    <div className={classes.container}>
      {isLoading && <BigidLoader />}
      <ExtraFieldIgnoreValue
        initialData={regexList}
        onChange={onChangeRegex}
        classificationName={classificationName}
        label="Enter a regex"
        setIsLoading={setIsLoading}
        titleWithPrefixCount="regex added"
        type="regex"
        deleteExcludeValueHandler={deleteExcludeValueHandler}
      />
      <Divider orientation="vertical" />
      <ExtraFieldIgnoreValue
        initialData={stringList}
        onChange={onChangeString}
        classificationName={classificationName}
        label="Enter a string"
        setIsLoading={setIsLoading}
        titleWithPrefixCount="values added"
        type="string"
        deleteExcludeValueHandler={deleteExcludeValueHandler}
      />
    </div>
  );
};

export const openCreateExcludeListClassifierDialog = async (
  classificationName: string,
  regexList: IgnoreValue[] = [],
  stringList: IgnoreValue[] = [],
  deleteExcludeValueHandler: Function,
) => {
  let ignoreValuesRegex: IgnoreValue[] = regexList;
  let ignoreValuesString: IgnoreValue[] = stringList;
  const contentProps = {
    onChangeRegex: (value: IgnoreValue[]) => {
      ignoreValuesRegex = value;
    },
    onChangeString: (value: IgnoreValue[]) => {
      ignoreValuesString = value;
    },
    regexList,
    stringList,
    classificationName,
    deleteExcludeValueHandler,
  };
  return new Promise<{ data: IgnoreValue[]; isCancelOccured: boolean }>(resolve => {
    openSystemDialog({
      title: `Exclude List in Classifier "${classificationName}"`,
      maxWidth: 'md',
      content: ClassifiersExcludeListDialog,
      contentProps,
      onClose: () => resolve({ data: [], isCancelOccured: true }),
      buttons: [
        {
          text: 'Cancel',
          component: SecondaryButton,
          onClick: () => resolve({ data: [], isCancelOccured: true }),
          isClose: true,
        },
        {
          text: 'Submit',
          component: PrimaryButton,
          dataAid: 'submit-exclude-list',
          onClick: () => {
            const ignoreValuesRegexFiltered = ignoreValuesRegex.filter(
              (ignoreValue: IgnoreValue) => !isEmpty(ignoreValue.value.trim()),
            );
            const ignoreValuesStringFiltered = ignoreValuesString.filter(
              (ignoreValue: IgnoreValue) => !isEmpty(ignoreValue.value.trim()),
            );
            const hasDuplicateRegex =
              uniqBy(ignoreValuesRegexFiltered, 'value').length !== ignoreValuesRegexFiltered.length;
            const hasDuplicateStrings =
              uniqBy(ignoreValuesStringFiltered, 'value').length !== ignoreValuesStringFiltered.length;
            if (!hasDuplicateRegex && !hasDuplicateStrings) {
              const diffRegexList = differenceWith(ignoreValuesRegexFiltered, regexList, isEqual);
              const diffStringList = differenceWith(ignoreValuesStringFiltered, stringList, isEqual);
              resolve({ data: [...diffRegexList, ...diffStringList], isCancelOccured: false });
              closeSystemDialog();
            } else {
              notificationService.error('You have duplicate values');
            }
          },
          isClose: false,
        },
      ],
    });
  });
};
