import React, { FC } from 'react';
import styled from '@emotion/styled';
import { PrimaryButton, SecondaryButton, StyledButtonType, TertiaryButton } from '@bigid-ui/components';
import { BigidDeleteIcon } from '@bigid-ui/icons';
import { noop } from 'lodash';
import { useLocalTranslation } from './translations';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidSystemDialogOptions, openSystemDialog } from '../../services/systemDialogService';
import { CredentialProvidersDialogContent } from './CredentialProvidersDialogContent';
import { CredentialProviderRow } from './types';
import { isPermitted } from '../../services/userPermissionsService';
import { CREDENTIAL_PROVIDERS_PERMISSIONS } from '@bigid/permissions';

export type CredentialProvidersFooterProps = {
  row: CredentialProviderRow;
  handleSubmit: () => void;
  handleDelete: () => void;
  handleTestConnection: () => void;
  isBusy: boolean;
};

const Buttons = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  justifyContent: 'flex-end',
  height: '100%',
  padding: '0 1rem',
  flexGrow: 1,
});

export const CredentialProvidersFooter: FC<CredentialProvidersFooterProps> = ({
  row,
  handleSubmit,
  handleDelete,
  handleTestConnection,
  isBusy,
}) => {
  const { t } = useLocalTranslation();

  const showConfirmDeleteDialog = () => {
    const dialogOptions: BigidSystemDialogOptions = {
      title: t('modal.delete.title'),
      onClose: noop,
      maxWidth: 'xs',
      buttons: [
        {
          component: SecondaryButton,
          dataAid: generateDataAid('CredentialProvidersFooterDialog', ['cancel', 'delete']),
          isClose: true,
          onClick: noop,
          text: t('buttons.cancel'),
        },
        {
          component: PrimaryButton,
          dataAid: generateDataAid('CredentialProvidersFooterDialog', ['confirm', 'delete']),
          isClose: true,
          disabled: !isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.DELETE.name),
          onClick: handleDelete,
          text: t('buttons.delete'),
        },
      ],
      content: CredentialProvidersDialogContent,
      contentProps: { body: t('modal.delete.body', { entityName: row.name }) },
      borderTop: true,
    };

    openSystemDialog(dialogOptions);
  };

  const footerActions: (StyledButtonType & { component: FC<StyledButtonType> })[] = [
    {
      component: TertiaryButton,
      dataAid: generateDataAid('CredentialProvidersFooter', ['delete']),
      startIcon: <BigidDeleteIcon />,
      disabled: !isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.DELETE.name) || isBusy,
      onClick: () => showConfirmDeleteDialog(),
      color: 'grey',
      size: 'medium',
      text: t('buttons.delete'),
    },
    {
      component: PrimaryButton,
      dataAid: generateDataAid('CredentialProvidersFooter', ['save']),
      disabled:
        (row?.isPending
          ? !isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.CREATE.name)
          : !isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.EDIT.name)) || isBusy,
      onClick: handleSubmit,
      size: 'medium',
      text: t('buttons.save'),
    },
    {
      component: PrimaryButton,
      dataAid: 'CredentialProvidersFooter-testConnectionButton',
      onClick: handleTestConnection,
      color: 'grey',
      size: 'medium',
      text: t('buttons.testConnection'),
      disabled: !isPermitted(CREDENTIAL_PROVIDERS_PERMISSIONS.TEST_CONNECTION.name) || isBusy,
    },
  ];

  return (
    <Buttons>
      {footerActions.map(({ component: Component, ...rest }) => (
        <Component key={rest.dataAid} {...rest} />
      ))}
    </Buttons>
  );
};
