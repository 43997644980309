import React, { FC, Fragment, ReactText, useEffect, useMemo, useRef, useState } from 'react';
import { BigidGridProps, BigidGridQueryComponents, BigidGridRow, FetchDataFunction } from '@bigid-ui/grid';
import { BigidLayout, BigidLayoutConfig, BigidMasterDetailsContentProps, LayoutContentType } from '@bigid-ui/layout';
import { httpService } from '../../../services/httpService';
import { queryService } from '../../../services/queryService';
import { notificationService } from '../../../services/notificationService';
import { pageHeaderService } from '../../../../common/services/pageHeaderService';
import { DataMinimizationObjectDetails } from '../DataMinimizationObjectDetails/DataMinimizationObjectDetails';
import { $state, $stateParams } from '../../../services/angularServices';
import { CONFIG } from '../../../../config/common';
import { isPermitted } from '../../../services/userPermissionsService';
import { CATALOG_PERMISSIONS, DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import {
  ActionData,
  BigidFilter,
  BigidLoader,
  EntityEvents,
  entityEventsEmitter,
  ToolbarActionType,
} from '@bigid-ui/components';
import { DELETION_ALERT, DialogResponse, openEditDialog } from './Diaolgs/EditDialog';
import makeStyles from '@mui/styles/makeStyles';
import { SSE_EVENTS, subscribeToRepeatedSSEEventById } from '../../../services/sseService';
import {
  dataMinimizationObjectsDefaultColumns,
  getDataMinimizationObjectsFilterConfig,
  OBJECT_TYPE,
  REQUEST_TYPE,
  SCANS,
} from './DataMinimizationObjectsGridConfiguration';
import { useUserPreferences } from '../../../components/hooks/useUserPrefrences';
import { PreviewFileContent } from '../../DataCatalog/PreviewFileObjects/PreviewFileContent';
import { DataCatalogTablePreview } from '../../DataCatalog/DataCatalogTablePreview';
import { DataCatalogAttributes } from '../../DataCatalog/DataCatalogAttributes';
import { DataCatalogColumns } from '../../DataCatalog/DataCatalogColumns';
import { DataCatalogRecordScannerTypeGroup } from '../../DataCatalog/DataCatalogService';
import { DeletionValidationsResponse } from '../DataMinimizationRequests/DataMinimizationRequests';
import { getApplicationPreference } from '../../../services/appPreferencesService';
import { cancelRequests } from './DataMinimizationObjectsService';

export enum actionTypes {
  DELETE_AUTO = 'Delete Automatically',
  DELETE_MANUAL = 'Delete Manually',
  RETAINED = 'Retained',
  COMPLETION_DELETE_MANUAL = 'Completion Delete Manually',
  CLEAR_ACTION = 'Clear Action',
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    paddingBottom: 10,
  },
  actionsButton: {
    minWidth: 180,
  },
  contentContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexFlow: 'row nowrap',
    flex: '1 1 auto',
  },
});

export interface DsarDeletionObjectModel extends BigidGridRow {
  _id: string;
  requestId?: string;
  objectName: string;
  schemaName?: string;
  dataSourceName?: string;
  fullObjectName: string;
  scannerType: string;
  query?: string;
  createdAt: string;
  state?: string;
  executedUser?: string;
  decidedUser?: string;
  deletedRows?: string;
  estimatedRows?: string;
  errorMessage?: string;
  executedAt?: string;
  markedAs?: actionTypes;
  reason?: string;
  objectType: OBJECT_TYPE;
  requestType: REQUEST_TYPE;
  fullyQualifiedName?: string;
  scanner_type_group?: string;
  isObjectScanned?: boolean;
  isExpired?: boolean;
  properties?: [{ label: string; value: string; link?: string; order?: number }];
}

interface DsarDeletionObjectResponse {
  deleteQueries: DsarDeletionObjectModel[];
  totalCount: number;
}

export interface FilterParams {
  actionData?: ActionData;
  queryComponents?: BigidGridQueryComponents;
}

export const onBroadCastEventFormUpdatePart = () => {
  entityEventsEmitter.emit(EntityEvents.RELOAD);
};

export const showDeletionConfirmationDialog = async (objects: number) => {
  const entityName = objects > 1 ? 's' : '';
  const customDescription = `Are you sure you want to execute delete ${objects} data deletion object${entityName}?${DELETION_ALERT}`;
  return showConfirmationDialog({
    entityNameSingular: 'Data Deletion Object',
    customDescription,
  });
};

export const DataMinimizationObjects: FC<DsarDeletionObjectModel> = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [isExpired, setIsExpired] = useState(false);

  const isExpiredWorkAroundRef = useRef(isExpired);
  isExpiredWorkAroundRef.current = isExpired;

  const classes = useStyles({});
  //const [deletedObjects, setDeletedObjects] = useState<number>(0);
  let deletedObjects = 0;
  const { requestId, dsName } = $stateParams;
  const title = requestId ? requestId : dsName;
  const page = requestId ? CONFIG.states.DATA_MINIMIZATION_REQUESTS : CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES;
  const unregisterUpdateHandler = useRef(null);

  const { isReady, preferences, gridColumns, filterToolbarConfig, updatePreferences } = useUserPreferences({
    stateName: $state.$current.name,
    initialGridColumns: dataMinimizationObjectsDefaultColumns,
    getInitialFilterToolbarConfig: getDataMinimizationObjectsFilterConfig,
  });

  useEffect(() => {
    pageHeaderService.setTitle({
      showBackButton: true,
      defaultFrom: { state: page, params: {} },
      breadcrumbs: [
        { label: 'Data Deletion', onClick: () => $state.go(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES) },
        {
          label: requestId ? 'DSAR Requests' : 'All Objects',
          onClick: () =>
            $state.go(
              requestId ? CONFIG.states.DATA_MINIMIZATION_REQUESTS : CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES,
            ),
        },
        { label: title },
      ],
    });
  }, [page, requestId, title]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        if (requestId) {
          const filter: BigidFilter = [{ field: 'requestId', operator: 'contains', value: requestId }];
          const gridConfigQuery = queryService.getGridConfigQuery({ filter });
          const {
            data: {
              data: { dataMinimizationRequests },
            },
          } = await httpService.fetch<{ data: DeletionValidationsResponse }>(
            `data-minimization/requests?${gridConfigQuery}`,
          );
          const request = dataMinimizationRequests[0];
          setIsExpired(request.state === SCANS.EXPIRED);
          const stateMessage = request.state === SCANS.STARTED ? ' - Creating Objects...' : '';
          pageHeaderService.setTitle({
            showBackButton: true,
            defaultFrom: { state: page, params: {} },
            breadcrumbs: [
              { label: 'Data Deletion', onClick: () => $state.go(page) },
              {
                label: 'DSAR Requests',
                onClick: () => $state.go(CONFIG.states.DATA_MINIMIZATION_REQUESTS),
              },
              { label: title + ' ' + stateMessage },
            ],
          });
        }
      } catch (e) {
        console.error('error getting owners', e);
      }
    };
    initialSetup();
  }, [page, requestId, title]);

  useEffect(() => {
    const uniqueBroadcastEventForUpdatePart: any = SSE_EVENTS.DATA_MINIMIZATION_OBJECT_EVENT;

    if (uniqueBroadcastEventForUpdatePart) {
      unregisterUpdateHandler.current = subscribeToRepeatedSSEEventById(
        uniqueBroadcastEventForUpdatePart,
        onBroadCastEventFormUpdatePart,
      );
    }

    return () => {
      unregisterUpdateHandler.current?.();
    };
  }, []);

  const { isPreviewAvailable } = useMemo(
    () => ({ isPreviewAvailable: isPermitted(CATALOG_PERMISSIONS.PREVIEW_FILE_INVESTIGATION.name) }),
    [],
  );

  const { actionsButton } = useStyles({});

  const gridConfig: BigidGridProps<DsarDeletionObjectModel> = {
    columns: gridColumns,
    showSortingControls: true,
    defaultSorting: preferences?.grid?.sort || [{ field: 'name', order: 'asc' }],
  };

  const executeActionForRequest = async (actionData: ActionData, actionType: actionTypes) => {
    let shouldExecuteAction = false;
    const isOpenDialog = actionType !== actionTypes.CLEAR_ACTION && actionType !== actionTypes.COMPLETION_DELETE_MANUAL;
    const noDialogResponse = { reason: '', shouldExecute: true };
    try {
      const { reason, shouldExecute }: DialogResponse = isOpenDialog ? await openEditDialog() : noDialogResponse;
      shouldExecuteAction = shouldExecute;
      if (shouldExecuteAction) {
        const { filter: deletionFilter } = getFilterForDeletion({ actionData });
        await httpService.post(`data-minimization/objects/action`, {
          query: { filter: deletionFilter },
          actionType,
          reason,
        });
        notificationService.success(`Action saved successfully`);
      }
    } catch (e) {
      console.error(e);
      notificationService.error('Could not save action. See logs for more information');
    } finally {
      return { shouldGridReload: shouldExecuteAction, shouldClearSelection: shouldExecuteAction };
    }
  };

  const getFilterForDeletion = ({ actionData, queryComponents }: FilterParams) => {
    const { filter: oldFilter = [], allSelected = false, selectedRowIds = [] } = { ...actionData, ...queryComponents };
    const filter: BigidFilter = buildDeletionFilter(oldFilter, allSelected, selectedRowIds);
    const gridConfigQuery = queryService.getGridConfigQuery({ ...queryComponents, filter });
    return { filter, gridConfigQuery };
  };

  const buildDeletionFilter = (filter: BigidFilter = [], allSelected = false, selectedRowIds: ReactText[] = []) => {
    const deletionFilter: BigidFilter = filter ? [...filter] : [];
    if (requestId) {
      deletionFilter.push({ field: 'requestId', operator: 'equal', value: requestId });
    } else if (dsName) {
      deletionFilter.push({ field: 'dataSourceName', operator: 'equal', value: dsName });
    }
    if (!allSelected && selectedRowIds?.length) {
      deletionFilter.push({ field: '_id', operator: 'in', value: selectedRowIds });
    }
    return deletionFilter;
  };

  const fetchGridData: FetchDataFunction<DsarDeletionObjectModel> = async queryComponents => {
    let data: DsarDeletionObjectModel[] = [];
    let totalCount = 0;

    try {
      const { gridConfigQuery } = getFilterForDeletion({ queryComponents });
      const { gridConfigQuery: gridConfigQueryForCount } = getFilterForDeletion({});
      const [
        {
          data: {
            data: { deleteQueries, totalCount: total },
          },
        },
        {
          data: {
            data: { count },
          },
        },
      ] = await Promise.all([
        httpService.fetch<{ data: DsarDeletionObjectResponse }>(`data-minimization/objects?${gridConfigQuery}`),
        httpService.fetch(`data-minimization/objects/execute/count?${gridConfigQueryForCount}`),
      ]);

      deletedObjects = count;
      //setDeletedObjects(count);
      totalCount = total;
      data = deleteQueries.map(query => {
        query.id = query._id;
        query.scanner_type_group =
          query.objectType === OBJECT_TYPE.RDB
            ? DataCatalogRecordScannerTypeGroup.STRUCTURED
            : DataCatalogRecordScannerTypeGroup.UNSTRUCTURED;
        return query;
      });
    } catch (e) {
      console.error(e);
      notificationService.error('Could not fetch data. See logs for more information');
    }

    return { data, totalCount };
  };

  const masterDetailsConfig: BigidMasterDetailsContentProps = {
    tabsAndContent: {
      classes: {
        contentContainer: classes.contentContainer,
      },
      tabProps: {
        onChange: tabIndex => {
          setSelectedTabIndex(tabIndex);
        },
        selectedIndex: selectedTabIndex,
        tabs: [
          {
            label: 'Details',
            data: { component: DataMinimizationObjectDetails },
          },
          {
            label: 'Attributes',
            data: { component: DataCatalogAttributes },
            getIsAvailable: ({ isObjectScanned }) => isObjectScanned,
          },
          {
            label: 'Columns',
            data: { component: DataCatalogColumns },
            getIsAvailable: ({ objectType, isObjectScanned }) => objectType === OBJECT_TYPE.RDB && isObjectScanned,
          },
          {
            label: 'Preview',
            data: { component: PreviewFileContent },
            getIsAvailable: ({ objectType, isObjectScanned }) =>
              objectType === OBJECT_TYPE.FILE && isPreviewAvailable && isObjectScanned,
          },
          {
            label: 'Preview',
            data: { component: DataCatalogTablePreview },
            getIsAvailable: ({ objectType, isObjectScanned /*scannerType*/ }) =>
              objectType === OBJECT_TYPE.RDB && isPreviewAvailable && isObjectScanned, //&&
            //!previewTableDataSourcesBlacklist.includes(scannerType),
          },
        ],
      },
    },
  };

  const layoutConfig: BigidLayoutConfig = {
    content: {
      entityName: 'objects',
      contentTypes: [LayoutContentType.MASTER_DETAILS],
      viewConfig: {
        filterToolbarConfig,
        onGridStateChange: ({ filter, ...gridState }) => updatePreferences({ filterState: { filter }, gridState }),
        fetchGridData,
        gridConfig,
        masterDetailsConfig,
        selectedItemPropsMapping: {
          name: 'objectName',
          requestId: 'requestId',
          objectName: 'objectName',
          query: 'query',
          createdAt: 'createdAt',
          schemaName: 'schemaName',
          dataSourceName: 'dataSourceName',
          fullObjectName: 'fullObjectName',
          fullyQualifiedName: 'fullyQualifiedName',
          scannerType: 'scannerType',
          state: 'state',
          executedUser: 'executedUser',
          deletedRows: 'deletedRows',
          estimatedRows: 'estimatedRows',
          errorMessage: 'errorMessage',
          executedAt: 'executedAt',
          reason: 'reason',
          markedAs: 'markedAs',
          decidedUser: 'decidedUser',
          objectType: 'objectType',
          scanner_type_group: 'scanner_type_group',
          source: 'dataSourceName',
          isObjectScanned: 'isObjectScanned',
          isExpired: 'isExpired',
          properties: 'properties',
        },
      },
      toolbarActions: [
        {
          label: 'Export',
          execute: async (actionData: ActionData) => {
            try {
              const { gridConfigQuery } = getFilterForDeletion({ actionData });
              httpService.downloadFile(`data-minimization/objects/file-download/export?${gridConfigQuery}`);
            } catch (e) {
              console.error(e);
              notificationService.error('Could not export. See logs for more information');
            } finally {
              return { shouldGridReload: false };
            }
          },
          disable: ({ selectedRowIds }) => selectedRowIds.length === 0,
          show: () => isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name),
        },
        {
          label: 'Execute Delete',
          isGlobal: true,
          execute: async () => {
            let shouldExecute = false;
            try {
              shouldExecute = await showDeletionConfirmationDialog(deletedObjects);

              if (shouldExecute) {
                const { filter } = getFilterForDeletion({});
                await httpService.post(`data-minimization/objects/execute`, { query: { filter } });
                notificationService.success(`Execute objects confirmed`);
              }
            } catch (e) {
              console.error(e);
              notificationService.error('Could not execute. See logs for more information');
            } finally {
              return { shouldGridReload: shouldExecute, shouldClearSelection: shouldExecute };
            }
          },
          disable: () => isExpiredWorkAroundRef.current,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXECUTE.name) &&
            selectedRowIds.length === 0 &&
            deletedObjects > 0,
        },
        {
          label: 'Approve Completion',
          execute: async (actionData: ActionData) =>
            executeActionForRequest(actionData, actionTypes.COMPLETION_DELETE_MANUAL),
          disable: () => isExpiredWorkAroundRef.current,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.MANAGE.name) && !!selectedRowIds.length,
        },
        {
          label: 'Cancel Requests',
          execute: async (actionData: ActionData) => cancelRequests(actionData, getFilterForDeletion),
          disable: () => isExpiredWorkAroundRef.current,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.CANCEL_REQUEST.name) &&
            getApplicationPreference('ENABLE_CANCEL_DATA_DELETION_REQUEST') &&
            !!selectedRowIds.length,
        },
        {
          isGlobal: true,
          label: 'Download DSAR Report',
          execute: async () => {
            try {
              httpService.downloadFile(
                `/sar/reports/file-download/${requestId}?format=csv&filterByScopes=true&excludeObjects=true`,
              );
            } catch (e) {
              console.error(e);
              notificationService.error('Could not download full report. See logs for more information');
            } finally {
              return { shouldGridReload: false, shouldClearSelection: false };
            }
          },
          disable: () => false,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.EXPORT.name) && requestId && selectedRowIds.length === 0,
        },
        {
          label: 'Assign An Action',
          type: ToolbarActionType.DROPDOWN,
          disable: () => isExpiredWorkAroundRef.current,
          show: ({ selectedRowIds }) =>
            isPermitted(DATA_MINIMIZATION_PERMISSIONS.MANAGE.name) && !!selectedRowIds.length,
          dropdownProps: {
            placeholder: 'More Actions',
            clearOnSelect: true,
            className: actionsButton,
            options: [
              {
                label: 'Delete',
                value: 'delete automatically',
                show: () => true,
                execute: async (actionData: ActionData) => executeActionForRequest(actionData, actionTypes.DELETE_AUTO),
              },
              {
                label: 'Mark For Delete Manually',
                value: 'delete manually',
                show: () => true,
                execute: async (actionData: ActionData) =>
                  executeActionForRequest(actionData, actionTypes.DELETE_MANUAL),
              },
              {
                label: 'Retain',
                value: 'retain',
                show: () => true,
                execute: async (actionData: ActionData) => executeActionForRequest(actionData, actionTypes.RETAINED),
              },
              {
                label: 'Clear Action',
                value: 'clear',
                show: () => true,
                execute: async (actionData: ActionData) =>
                  executeActionForRequest(actionData, actionTypes.CLEAR_ACTION),
              },
            ],
          },
        },
      ],
    },
  };

  return (
    <Fragment>
      {!isReady && <BigidLoader />}
      {isReady && <BigidLayout config={layoutConfig} />}
    </Fragment>
  );
};
