import { BigidGlobeIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { FC } from 'react';
import { getFixedT, useLocalTranslation } from '../../../../translations';
import { useDomainCreationContext } from '../DomainCreationContext';
import { Hierarchy } from './Hierarchy/Hierarchy';
import { ModalForm } from './Hierarchy/ModelForm';

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 16px;
`;

const convertStringsToHierarchy = (hierarchy: string[]): Hierarchy[] => {
  const t = getFixedT('Settings.DomainModal.Content');
  const isActive = (index: number) => index === hierarchy.length - 1;
  return hierarchy.map((name, index) => {
    const type = index === 0 ? t('domain') : t('subDomain');
    return {
      name,
      type,
      icon: <BigidGlobeIcon color={isActive(index) ? 'white' : 'default'} />,
      isActive: isActive(index),
    };
  });
};

export const ModalContent: FC = () => {
  const {
    state: { hierarchy },
  } = useDomainCreationContext();

  const { t } = useLocalTranslation('Settings.DomainModal.Content');
  return (
    <Container>
      <ModalForm />
      <Hierarchy hierarchy={convertStringsToHierarchy(hierarchy)} title={t('hierarchyTitle')} />
    </Container>
  );
};
