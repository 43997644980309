import { BigidSelectOption } from '@bigid-ui/components';

export enum AdapterTypeOptions {
  S3 = 'S3',
  BigQuery = 'BigQuery',
  GCS = 'GCS',
  DIS = 'DIS',
  Snowflake = 'Snowflake',
}

export enum AdaptersLabels {
  S3 = 'Amazon S3',
  GCS = 'Google Cloud Storage',
  BigQuery = 'Google BigQuery',
  DIS = 'Data Insights Studio',
  Snowflake = 'Snowflake',
}

export enum S3FileTypes {
  CSV = `CSV`,
  Parquet = `Parquet`,
}

export enum S3FileTypesLabels {
  CSV = 'CSV File Format',
  Parquet = 'Parquet File Format',
}

export enum S3AuthenticationMethod {
  Credential = `Credential`,
  IAM = `IAM`,
  Role = 'Role',
}

export enum S3AuthenticationMethodLabels {
  Credential = 'IAM User Access Key',
  IAM = 'IAM Role',
  Role = 'Cross-Account Assume Role',
}

export const DEFAULT_BULK_SIZE = 5000;
export const ETL_METADATA_URL = 'reporting-etl/settings/metadata/';
export const ETL_SETTINGS_URL = 'reporting-etl/settings/';
export const ETL_TEST_CONNECTION_URL = 'reporting-etl/data-adapter/test-connection';
export const GCP_ADAPTERS = [AdapterTypeOptions.BigQuery, AdapterTypeOptions.GCS];
export const DefaultAdapter: BigidSelectOption = {
  label: AdaptersLabels[AdapterTypeOptions.BigQuery],
  value: AdapterTypeOptions.BigQuery,
};
export const ENCRYPTED_CREDENTIALS_FORMAT = '******';
export const CODE_400 = 'code 400';
export const FIELDS_TO_IGNORE = ['_id', 'select_type', 'updated_at'];
export const GCP_FIELDS_TO_PICK = ['credentials_enc', 'container_location', 'adapterType'];
export const S3_FIELDS_TO_PICK = [
  'credentials_enc',
  'container_location',
  'adapterType',
  'access_key',
  'container_name',
  'role_session_name',
  'role_arn',
  'authentication_method',
];
export const FIELDS_TO_PICK_FOR_TEST_CONNECTION = {
  S3: [...S3_FIELDS_TO_PICK],
  GCS: [...GCP_FIELDS_TO_PICK],
  BigQuery: [...GCP_FIELDS_TO_PICK],
  DIS: ['adapterType'],
  Snowflake: ['credentials_enc', 'username', 'account', 'role', 'schema', 'warehouse', 'container_name', 'adapterType'],
};
export const SELECT_TYPE_FIELD = 'select_type';
export const DATA_INSIGHTS_STUDIO = 'Data Insights Studio';
export const DATA_PIPELINE = 'Data Pipeline';
