import { $injector } from 'ngimport';
import { StateService, StateParams, TransitionService } from '@uirouter/core';
import { ILocalStorageService } from 'angular-local-storage';
import { dateTimeService } from '@bigid-ui/i18n';
import angular, { ILocationService } from 'angular';
import { BigidTask } from '../views/AutoDiscovery/AutoDiscoveryWizard/autoDiscoveryCommonComponents/AutoDiscoveryCollaboration/autoDiscoveryCollaborationService';

export interface BigidScanProfile {
  active: boolean;
  created_at: string;
  description: string;
  id: string;
  name: string;
}

export let $state: StateService;
export let $stateParams: StateParams;
export let $translate: ng.translate.ITranslateService;
export let localStorageService: ILocalStorageService;
export let $uibModal: angular.ui.bootstrap.IModalService;
export const dateUtils = dateTimeService;
export let lazyLoadService: bigid.LazyLoadService;
export let DeleteConfirmation: any;
export let $transitions: TransitionService;
export let scansService: {
  downloadJitScanFailedObjectReport: (id: string) => Promise<void>;
  runScan: (scanParams: Record<string, any>) => Promise<any>;
  createScanProfile: (data: Record<string, any>) => Promise<Record<string, any>>;
  updateScanProfile: (id: string, data: Record<string, any>) => Promise<Record<string, any>>;
  deleteScanProfile: (id: string) => Promise<any>;
  getScanProfilesData: () => Promise<any>;
  stopSubScan: (scanId: string | number) => Promise<any>;
  stopLabelingScan: (scanId: string) => Promise<any>;
  getScanData: (scanId: string) => Promise<any>;
  getScanInsights: (scanId: string) => Promise<any>;
  getFailedObjectReport: () => Promise<any>;
  archiveScan: (scanId: string | number) => Promise<any>;
  retryScan: (scanId: string | number) => Promise<any>;
  pauseScan: (scanId: string | number) => Promise<any>;
  resumeScan: (scanId: string | number) => Promise<any>;
};
export let explorationBarChartService: { clearGetApplicationRisksRequest: () => void };
export let commonMethods: {
  getAbbrNum(number: number, decPlaces: number): string;
  encodeUrlParams(params: any): string;
};
export let privacyRiskTrendService: any;
export let queryStringService: any;
export let systemUsersService: any;
export let dataSourceConnectionsService: any;
export let agreementsService: any;
export let locationService: any;
export let entitySummaryService: { getPanelData: () => Promise<any> };
export let privacyComplianceService: { getComplianceSummaries(): Promise<any> };
export let taskListService: {
  getAllTask(status: string, filterBy: string, type?: string): Promise<{ data: BigidTask[] }>;
  createNewTask(data: BigidTask): Promise<void>;
};

export interface riskTrendServiceProps {
  getRiskSnapShots: (queryString: string, isClearCache: boolean) => Promise<any>;
  clearGetRiskSnapShotsDataRequest: () => Promise<void>;
}

export let riskTrendService: riskTrendServiceProps;

export let trendSnapshotsService: {
  getRiskTrendSnapshots(): Promise<any>;
  getPiisTrendSnapshots(): Promise<any>;
  getAttributesTrendSnapshots(): Promise<any>;
  getDataSourcesTrendSnapshots(): Promise<any>;
  getActivityHighlightsTrendSnapshots(): Promise<any>;
};
export let privacyRiskMapService: {
  getPrivacyRiskMapResults(queryString: string | null, isClearCache: boolean): Promise<any>;
};
export let credentialsService: any;
export let betaToolsService: any;
export let downloadFileService: any;
export let scopesService: any;
export let rolesService: any;
export let cyberArcProvidersService: any;
export let $location: ILocationService;

angular.module('app').run(() => {
  $state = $injector.get<StateService>('$state');
  $stateParams = $injector.get<StateParams>('$stateParams');
  $translate = $injector.get<ng.translate.ITranslateService>('$translate');
  localStorageService = $injector.get<ILocalStorageService>('localStorageService');
  $uibModal = $injector.get('$uibModal');
  lazyLoadService = $injector.get('lazyLoadService');
  DeleteConfirmation = $injector.get('DeleteConfirmation');
  $transitions = $injector.get('$transitions');
  scansService = $injector.get('scansService');
  commonMethods = $injector.get('commonMethods');
  explorationBarChartService = $injector.get('explorationBarChartService');
  privacyRiskTrendService = $injector.get('privacyRiskTrendService');
  queryStringService = $injector.get('queryStringService');
  systemUsersService = $injector.get('systemUsersService');
  dataSourceConnectionsService = $injector.get('dataSourceConnectionsService');
  agreementsService = $injector.get('agreementsService');
  locationService = $injector.get('locationService');
  entitySummaryService = $injector.get('entitySummaryService');
  privacyComplianceService = $injector.get('privacyComplianceService');
  riskTrendService = $injector.get<riskTrendServiceProps>('riskTrendService');
  trendSnapshotsService = $injector.get('trendSnapshotsService');
  privacyRiskMapService = $injector.get('privacyRiskMapService');
  credentialsService = $injector.get('credentialsService');
  betaToolsService = $injector.get('betaToolsService');
  downloadFileService = $injector.get('downloadFileService');
  scopesService = $injector.get('scopesService');
  rolesService = $injector.get('rolesService');
  cyberArcProvidersService = $injector.get('cyberArkService');
  $location = $injector.get('$location');
  taskListService = $injector.get('taskListService');
});
