import React, { FC, useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidScheduler,
  EndingConditionType,
  FrequencyType,
  RecurringSchedule,
  convertRecurringScheduleObjectToCron,
  convertCronToRecurringScheduleObject,
} from '@bigid-ui/components';
import { CreateScanWizardContext } from '../../createScanContext';

const useStyles = makeStyles({
  scheduler: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    overflowY: 'scroll',
  },
});

export const getDefaultScheduleRecurrence: () => RecurringSchedule = () => ({
  startDate: new Date(),
  frequency: FrequencyType.Daily,
  numberOfRepetitions: 1,
  weeklyRecurrence: [],
  monthlyRecurrence: null,
  monthlyRecurrenceDate: null,
  monthlyRecurrencePatternDay: null,
  monthlyRecurrencePatternCount: null,
  endingCondition: EndingConditionType.None,
  endDate: null,
  endingOccurrencesNumber: 1,
});

export const ScanStep: FC = () => {
  const { scheduler } = useStyles();
  const { scanWizardState, setScanWizardState } = useContext(CreateScanWizardContext);
  const initialScheduleState = scanWizardState.schedule
    ? convertCronToRecurringScheduleObject({
        ...scanWizardState.schedule,
        startOn: new Date(scanWizardState.schedule.startOn),
      })
    : getDefaultScheduleRecurrence();
  const [schedule, setSchedule] = useState<RecurringSchedule>(initialScheduleState);

  const handleChange = (newSchedule: RecurringSchedule) => {
    setSchedule(newSchedule);
    setScanWizardState({ ...scanWizardState, schedule: convertRecurringScheduleObjectToCron(newSchedule) });
  };

  return (
    <div className={scheduler}>
      <BigidScheduler onChange={handleChange} scheduleData={schedule} />
    </div>
  );
};
