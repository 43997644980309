import React, { FC, useCallback, useMemo } from 'react';
import { BigidGuidedTour, Step } from '@bigid-ui/guided-tour';
import { getGuidedTourIdSelector, generateDataAid } from '@bigid-ui/utils';
import { BigidIntroductionActionIcon } from '@bigid-ui/icons';
import { Actions } from './steps/Actions';
import { Filters } from './steps/Filters';
import { Hotspot } from './steps/Hotspot';
import { Start } from './steps/Start';
import { HighLevelTotals } from './steps/HighLevelTotals';
import { Insights } from './steps/Insights';
import { DataSourcesInventory } from './steps/DataSourcesInventory';
import { CatalogDiscoveryGuidedTourStatus } from '../catalogDiscoveryTypes';
import { UseCatalogDiscoveryResponse } from '../useCatalogDiscovery';
import { useLocalTranslation } from './translations';

export interface CatalogDiscoveryGuidedTourProps extends Pick<UseCatalogDiscoveryResponse, 'onGuidedTourFinished'> {
  dataAid?: string;
  status: CatalogDiscoveryGuidedTourStatus;
}

export const CatalogDiscoveryGuidedTour: FC<CatalogDiscoveryGuidedTourProps> = ({
  dataAid = 'CatalogDiscoveryGuidedTour',
  status,
  onGuidedTourFinished,
}) => {
  const { t } = useLocalTranslation('steps');
  const shouldTourRun = useMemo(() => {
    return [
      CatalogDiscoveryGuidedTourStatus.STARTED_AUTOMATICALLY,
      CatalogDiscoveryGuidedTourStatus.STARTED_MANUALLY,
    ].includes(status);
  }, [status]);
  const steps: Step[] = useMemo(
    () => [
      {
        carouselItems: [
          {
            type: 'node',
            content: <BigidIntroductionActionIcon />,
            styles: {
              background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)',
            },
          },
        ],
        title: t('Start.title'),
        content: <Start dataAid={generateDataAid(dataAid, ['start'])} />,
        target: getGuidedTourIdSelector('CatalogDiscovery', ['start']),
        disableBeacon: true,
        placement: 'center',
        inverseXButtonColor: true,
      },
      {
        title: t('HighLevelTotals.title'),
        content: <HighLevelTotals dataAid={generateDataAid(dataAid, ['high-level-totals'])} />,
        target: getGuidedTourIdSelector('CatalogDiscovery', ['high-level-totals']),
        disableBeacon: true,
        placement: 'bottom',
      },
      {
        title: t('Insights.title'),
        content: <Insights dataAid={generateDataAid(dataAid, ['insights'])} />,
        target: getGuidedTourIdSelector('CatalogDiscovery', ['insights']),
        disableBeacon: true,
        placement: 'bottom',
      },
      {
        title: t('Filters.title'),
        content: <Filters dataAid={generateDataAid(dataAid, ['filters'])} />,
        target: getGuidedTourIdSelector('BigidAdvancedToolbarFilter', ['add', 'filter']),
        disableBeacon: true,
        placement: 'right',
      },
      {
        title: t('DataSourcesInventory.title'),
        content: <DataSourcesInventory dataAid={generateDataAid(dataAid, ['dataSourcesInventory'])} />,
        target: getGuidedTourIdSelector('DataSourcesLayout', []),
        disableBeacon: true,
        placement: 'top',
      },
      {
        title: t('Hotspot.title'),
        content: <Hotspot dataAid={generateDataAid(dataAid, ['hotspot'])} />,
        target: getGuidedTourIdSelector('AttributesLayout', []),
        disableBeacon: true,
        placement: 'top',
      },
      {
        title: t('Actions.title'),
        content: <Actions dataAid={generateDataAid(dataAid, ['actions'])} />,
        target: getGuidedTourIdSelector('CatalogDiscoveryToolbar', ['actions']),
        disableBeacon: true,
        placement: 'bottom',
      },
    ],
    [dataAid, t],
  );

  const joyrideCallback = useCallback(() => {
    onGuidedTourFinished(status);
  }, [onGuidedTourFinished, status]);

  return (
    <BigidGuidedTour
      joyrideCallback={joyrideCallback}
      run={shouldTourRun}
      scrollOffset={350}
      steps={steps}
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
    />
  );
};
