import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ThumbsUpIcon from '../../../assets/icons/BigidThumbsUp.svg';
import { BigidColors, BigidBody1, BigidHeading4 } from '@bigid-ui/components';

export interface ResponseContentProps {
  isExist: boolean;
  ticketId: string;
  created: boolean;
}

const useStyles = makeStyles({
  root: { display: 'flex', alignItems: 'center', margin: '0 60px' },
  icon: {
    fill: BigidColors.purple[600],
    height: 60,
    width: 60,
    marginRight: 40,
  },
});

export const ResponseContent: FC<ResponseContentProps> = ({ isExist, created, ticketId }) => {
  const { root, icon } = useStyles({});

  const isReported = isExist && !created;

  return (
    <div className={root}>
      <div>
        <ThumbsUpIcon className={icon} />
      </div>
      <div>
        <BigidHeading4>
          {isReported ? `The issue has already been reported.` : `Thanks for helping us improve!`}
        </BigidHeading4>
        <BigidBody1>{`Your issue was submitted with ticket number ${ticketId}. Use this number to track the issue with our Support team or your Customer Success Manager`}</BigidBody1>
      </div>
    </div>
  );
};
