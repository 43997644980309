import React, { FC, useContext, useEffect, useReducer, useState } from 'react';
import { CaseSidePanelContext } from '../hooks/CaseSidePanelContext';
import { ObjectPreviewContext } from './hooks/ObjectPreviewContext';
import { objectPreviewReducer, getInitialReducerState, ReducerActions } from './hooks/ObjectPreviewReducer';
import styled from '@emotion/styled';
import { fetchObjectDetails } from './objectPreviewService';
import { ObjectPreviewInfo } from './ObjectPreviewComponents';
import { BigidLoader, BigidTabs } from '@bigid-ui/components';
import { useLocalTranslation } from '../../translations';
import { DataCatalogObjectDetails } from '../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';
import { TabContent } from './ObjectPreviewComponents/TabContent';

const InfoPanel = styled.div({
  height: 48,
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
});

const ObjectPreviewTabsWrapper = styled.div({
  padding: '10px 10px 0',
  display: 'flex',
  flexDirection: 'column',
  rowGap: 20,
});

export const ObjectPreview: FC = () => {
  const { objectFullyQualifiedName } = useContext(CaseSidePanelContext);
  const [state, dispatch] = useReducer(objectPreviewReducer, {}, getInitialReducerState);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [caseDetails, setCaseDetails] = useState<DataCatalogObjectDetails>();

  const { t } = useLocalTranslation('ObjectPreview');
  const tabs = [{ label: t('tabs.details') }, { label: t('tabs.permissions') }];
  const onChangeTab = (_tabIndex: number, tab: { label: string }) => {
    setSelectedTab(_tabIndex);
  };

  useEffect(() => {
    if (objectFullyQualifiedName) {
      const fetchData = async () => {
        const data = await fetchObjectDetails(objectFullyQualifiedName);
        setCaseDetails(data);
        dispatch({
          type: ReducerActions.UPDATE_OBJECT_DETAILS,
          payload: { objectPreviewData: data },
        });
      };
      fetchData().catch(console.error);
      setIsLoading(false);
    }
  }, [objectFullyQualifiedName]);

  return (
    <>
      {isLoading ? (
        <BigidLoader />
      ) : (
        <>
          <ObjectPreviewContext.Provider
            value={{
              objectPreviewData: state.objectPreviewData,
              dispatch,
            }}
          >
            <InfoPanel>
              <ObjectPreviewInfo />
            </InfoPanel>
            <ObjectPreviewTabsWrapper>
              <BigidTabs tabs={tabs} orientation="horizontal" selectedIndex={selectedTab} onChange={onChangeTab} />
              <TabContent tab={selectedTab} caseDetails={caseDetails} />
            </ObjectPreviewTabsWrapper>
          </ObjectPreviewContext.Provider>
        </>
      )}
    </>
  );
};
