import React, { useMemo, useCallback } from 'react';
import { licenseService } from '../../services/licenseService';
import { isPermitted, hasRootScope } from '../../services/userPermissionsService';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  ACTION_CENTER_PERMISSIONS,
  ADVANCE_TOOLS_PERMISSIONS,
  APPLICATION_SETUP_PERMISSIONS,
  AUDIT_PERMISSIONS,
  CERTIFICATES_PERMISSIONS,
  CLASSIFIERS_PERMISSIONS,
  CLUSTER_ANALYSIS_PERMISSIONS,
  CORRELATION_PERMISSIONS,
  CREDENTIALS_PERMISSIONS,
  DASHBOARD_PERMISSIONS,
  DATA_SOURCES_PERMISSIONS,
  GENERAL_SETTINGS_PERMISSIONS,
  POLICIES_PERMISSIONS,
  REPORTS_PERMISSIONS,
  SCAN_RESULT_DETAILS_PERMISSIONS,
  SCAN_WINDOWS_PERMISSIONS,
  SCANS_PERMISSIONS,
  SECONDARY_SOURCE_PERMISSIONS,
  TAGS_PERMISSIONS,
  TAGS_SAVED_QUERIES_PERMISSIONS,
  COMPOUND_ATTRIBUTE_PERMISSIONS,
  INVENTORY_PERMISSIONS,
  CATALOG_PERMISSIONS,
  SENSITIVITY_CLASSIFICATION_PERMISSIONS,
  ACTIONABLE_INSIGHTS_PERMISSIONS,
  CLASSIFICATION_PERMISSIONS,
  CUSTOM_DASHBOARD_PERMISSIONS,
  CORRELATION_SET_PERMISSIONS,
  GETTING_STARTED_PERMISSIONS,
  PROXIES_PERMISSIONS,
  REVIEW_FINDINGS_PERMISSIONS,
  CREDENTIAL_PROVIDERS_PERMISSIONS,
} from '@bigid/permissions';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { BigidNavigationItemType } from '@bigid-ui/components';
import { isDataExplorerEnabled } from '../../views/DataExplorer/dataExplorerService';
import { isMultipleCyberArkProvidersEnabled } from '../../views/Credentials/utils';
import { isMultiTenantConfigEnabled, isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';

export interface UsePermissionNavigationItemsProps {
  navigationItems: BigidNavigationItemType[];
  footerItems: BigidNavigationItemType[];
  headerItems: BigidNavigationItemType[];
}

interface CheckMainPermissionProps {
  permissionsContext: Record<string, boolean>;
  mainPermission: boolean;
  mainItemLink: string;
}

export const usePermissionNavigationItems = ({
  navigationItems,
  footerItems,
  headerItems,
}: UsePermissionNavigationItemsProps) => {
  const isShown = (permission: string) =>
    !licenseService.shouldBlockUserWithLicenseExpired() && isPermitted(permission);
  const isSmallId = () => smallIdLicenseService.isSmallIDLicense();

  const checkMainItemPermission = useCallback(
    ({ permissionsContext, mainPermission, mainItemLink }: CheckMainPermissionProps) => {
      const mainItemPermission = mainPermission;
      const subItems = navigationItems.find(({ link }: BigidNavigationItemType) => link === mainItemLink);
      const isSubItemExist = Boolean(subItems?.quickViewSchema && subItems?.quickViewSchema?.menuItems.length > 0);
      const isSubItemsHavePermission = subItems?.quickViewSchema?.menuItems?.some(
        ({ link }) => permissionsContext[link] === true,
      );
      return isSubItemExist ? isSubItemsHavePermission && mainItemPermission : mainItemPermission;
    },
    [navigationItems],
  );

  const permissionSchema: Record<string, boolean> = useMemo(
    () => ({
      //Header
      [CONFIG.states.GETTING_STARTED]: isSmallId()
        ? isShown(GENERAL_SETTINGS_PERMISSIONS.ACCESS.name)
        : getApplicationPreference('SHOW_GETTING_STARTED_PAGE_FF') && isShown(GETTING_STARTED_PERMISSIONS.ACCESS.name),
      ['favoritesLinkPlaceholder']: true,

      //Main items
      [CONFIG.states.CATALOG_DISCOVERY]:
        isShown(INVENTORY_PERMISSIONS.ACCESS.name) && getApplicationPreference('DATA_OVERVIEW_ENABLED'),
      get [CONFIG.states.ACTIONABLE_INSIGHTS]() {
        return checkMainItemPermission({
          permissionsContext: this,
          mainPermission:
            getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED') &&
            isShown(ACTIONABLE_INSIGHTS_PERMISSIONS.ACCESS.name),
          mainItemLink: CONFIG.states.ACTIONABLE_INSIGHTS,
        });
      },
      get ['dataExplorerTEMP']() {
        return checkMainItemPermission({
          permissionsContext: this,
          mainPermission: true,
          mainItemLink: 'dataExplorerTEMP',
        });
      },
      get ['reportsTEMP']() {
        return checkMainItemPermission({
          permissionsContext: this,
          mainPermission: true,
          mainItemLink: 'reportsTEMP',
        });
      },
      get ['dataManagementTEMP']() {
        return checkMainItemPermission({
          permissionsContext: this,
          mainPermission: true,
          mainItemLink: 'dataManagementTEMP',
        });
      },
      get ['settingsTEMP']() {
        return checkMainItemPermission({
          permissionsContext: this,
          mainPermission: true,
          mainItemLink: 'settingsTEMP',
        });
      },

      //Sub items
      [CONFIG.states.INVENTORY]:
        isShown(INVENTORY_PERMISSIONS.ACCESS.name) &&
        !getApplicationPreference('DATA_OVERVIEW_ENABLED') &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.CATALOG]: isShown(CATALOG_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CLUSTERING]:
        getApplicationPreference('CLUSTERING_ENABLED') && isShown(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.DASHBOARD]:
        getApplicationPreference('SHOW_DASHBOARD_PAGE') && isShown(DASHBOARD_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CUSTOM_DASHBOARD]:
        getApplicationPreference('SHOW_CUSTOM_DASHBOARD') &&
        isShown(CUSTOM_DASHBOARD_PERMISSIONS.ACCESS.name) &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.EXECUTIVE_REPORT]:
        isShown(REPORTS_PERMISSIONS.ACCESS_EXECUTIVE_REPORT.name) &&
        getApplicationPreference('SHOW_EXECUTIVE_REPORT') &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.DIGEST_REPORT]:
        isShown(REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name) && !isMultiTenantModeEnabled(),
      [CONFIG.states.SCAN_ANALYSIS]: isShown(SCAN_RESULT_DETAILS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.SCAN_RESULT]:
        isShown(REPORTS_PERMISSIONS.READ_SCAN_RESULT_SUMMARY.name) && !isMultiTenantModeEnabled(),
      ['scannedFilesDOWNLOAD']:
        isShown(REPORTS_PERMISSIONS.DOWNLOAD_SCAN_FILES_REPORT.name) &&
        getApplicationPreference('ENABLE_SCANNED_FILES_REPORT') &&
        !isMultiTenantModeEnabled(),
      ['dataCustodianDOWNLOAD']:
        isShown(REPORTS_PERMISSIONS.DOWNLOAD_DATA_CUSTODIAN_REPORT.name) && !isMultiTenantModeEnabled(),
      ['labelingPropagationDOWNLOAD']:
        getApplicationPreference('LABELER_ENABLED') &&
        isShown(REPORTS_PERMISSIONS.DOWNLOAD_LABELING_PROPAGATION_REPORT.name) &&
        !isMultiTenantModeEnabled(),
      ['usageReportDOWNLOAD']:
        isShown(REPORTS_PERMISSIONS.DOWNLOAD_USAGE_REPORT.name) && hasRootScope() && !isMultiTenantModeEnabled(),
      [CONFIG.states.HOTSPOTS_REPORT]:
        isShown(CLUSTER_ANALYSIS_PERMISSIONS.ACCESS.name) &&
        getApplicationPreference('ENABLE_HOTSPOTS_REPORT') &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.DATA_SOURCE_ROUTER]: isShown(DATA_SOURCES_PERMISSIONS.ACCESS.name),
      [CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS]: isShown(SCANS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CURATION]:
        isShown(REVIEW_FINDINGS_PERMISSIONS.ACCESS.name) && getApplicationPreference('CLASSIFIER_TUNING_ENABLED'),
      [CONFIG.states.CORRELATION]: isShown(CORRELATION_PERMISSIONS.ACCESS.name),
      [CONFIG.states.IDENTITY_SOURCE_CONNECTIONS]: isShown(CORRELATION_SET_PERMISSIONS.ACCESS.name),
      [CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION]: isShown(CORRELATION_SET_PERMISSIONS.ACCESS.name),
      [CONFIG.states.SECONDARY_CONNECTIONS]:
        isShown(SECONDARY_SOURCE_PERMISSIONS.ACCESS.name) && !isMultiTenantModeEnabled(),
      [CONFIG.states.APPLICATIONS_SETUP]:
        isShown(APPLICATION_SETUP_PERMISSIONS.ACCESS.name) && !isMultiTenantModeEnabled(),
      [CONFIG.states.CLASSIFIERS]: isShown(CLASSIFIERS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.RULES]:
        isShown(COMPOUND_ATTRIBUTE_PERMISSIONS.ACCESS.name) && getApplicationPreference('RULES_PAGE_ENABLED_FF'),
      [CONFIG.states.SCAN_WINDOW]:
        isShown(SCAN_WINDOWS_PERMISSIONS.ACCESS.name) &&
        getApplicationPreference('SCAN_WINDOW_FF') &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.TAGS]: isShown(TAGS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.SAVED_QUERIES]: isShown(TAGS_SAVED_QUERIES_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CLASSIFICATION]: isShown(CLASSIFICATION_PERMISSIONS.ACCESS.name),
      [CONFIG.states.SENSITIVITY_CLASSIFICATION]:
        isShown(SENSITIVITY_CLASSIFICATION_PERMISSIONS.READ_CONFIGS.name) &&
        getApplicationPreference('SENSITIVITY_CLASSIFICATION_ENABLED'),
      [CONFIG.states.AUDIT]: isShown(AUDIT_PERMISSIONS.ACCESS.name),
      [CONFIG.states.POLICIES]: isShown(POLICIES_PERMISSIONS.ACCESS.name),
      [CONFIG.states.GENERAL_SETTINGS]: isShown(GENERAL_SETTINGS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.ADVANCED_TOOLS]:
        isShown(ADVANCE_TOOLS_PERMISSIONS.ACCESS.name) && (!isMultiTenantModeEnabled() || isMultiTenantConfigEnabled()),
      [CONFIG.states.IDENTITY_ACCESS_MANAGEMENT]: isShown(ACCESS_MANAGEMENT_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CERTIFICATES_MANAGEMENT]: isShown(CERTIFICATES_PERMISSIONS.ACCESS.name),
      [CONFIG.states.CREDENTIALS]: isShown(CREDENTIALS_PERMISSIONS.ACCESS.name),
      [CONFIG.states.PROXIES]: isShown(PROXIES_PERMISSIONS.ACCESS.name) && getApplicationPreference('ENABLE_PROXIES'),
      [CONFIG.states.ACTION_CENTER_ACTIONS]:
        isShown(ACTION_CENTER_PERMISSIONS.ACCESS.name) && getApplicationPreference('ENABLE_ACTION_CENTER'),
      [CONFIG.states.DATA_EXPLORER]: isDataExplorerEnabled(),
      [CONFIG.states.RISK_ASSESSMENT]:
        getApplicationPreference('RISK_ASSESSMENT_ENABLED') &&
        isShown(REPORTS_PERMISSIONS.READ_ACTIVITY_HIGHLIGHTS.name) &&
        !isMultiTenantModeEnabled(),
      [CONFIG.states.CREDENTIAL_PROVIDERS]:
        isMultipleCyberArkProvidersEnabled() && isShown(CREDENTIAL_PROVIDERS_PERMISSIONS.READ.name),
      //Footer
      [CONFIG.states.APPLICATIONS_MANAGEMENT]: !isSmallId(),
    }),
    [checkMainItemPermission],
  );

  const checkPermissions = useCallback(
    (items: BigidNavigationItemType[]) => {
      return items
        .filter(({ link }: BigidNavigationItemType) => permissionSchema[link])
        .map((item: BigidNavigationItemType) => ({
          ...item,
          quickViewSchema: {
            ...item.quickViewSchema,
            menuItems: item.quickViewSchema?.menuItems.filter(({ link }) => permissionSchema[link]),
          },
        }));
    },
    [permissionSchema],
  );

  const navigationItemsPermitted = useMemo(() => {
    const result = checkPermissions(navigationItems);
    return result;
  }, [checkPermissions, navigationItems]);

  const footerItemsPermitted = useMemo(() => {
    const result = checkPermissions(footerItems);
    return result;
  }, [checkPermissions, footerItems]);

  const headerItemsPermitted = useMemo(() => {
    const result = checkPermissions(headerItems);
    return result;
  }, [checkPermissions, headerItems]);

  return { navigationItemsPermitted, footerItemsPermitted, headerItemsPermitted };
};
