import React, { FC, useEffect, useState } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidColorsV2,
  BigidFieldFilterOperator,
  BigidHeading6,
  BigidLoader,
} from '@bigid-ui/components';
import styled from '@emotion/styled';
import { generateDataAid } from '@bigid-ui/utils';
import { ScanOverviewBox, ScanOverviewBoxProps } from './ScanOverviewBox';
import {
  BigidDataClassifierIllustration,
  BigidDataCorrelationIllustration,
  BigidHelpIcon,
  BigidDataObjectIllustration,
  BigidDataDataSourceIllustration,
} from '@bigid-ui/icons';
import { PieInsightChart } from '../../ScanInsightsSidePanel/PieInsightChart';
import { DatasourceScanInsightsInfo, ScanInsightsInfo, ScanProfileInfo } from '../../ScanInsightTypes';
import { sanitizeInsightValues } from '../../../ScanUtils';
import { ScansUITrackingEvent, trackEventScansView } from '../../../ScansEventTrackerUtils';
import { openSystemDialog } from '../../../../../services/systemDialogService';
import { AttributesViewDetails } from '../AttributesViewDetails';
import { ObjectsDataViewDetails } from '../ObjectsDataViewDetails';
import { CurationEvents } from '../../../../Curation/curationEventTrackerUtils';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { goToCatalogWithChangePreferences } from '../../../../Dashboard/goToCatalogWithChangePreferences';
import { ScanOverviewBottomSection } from './ScanOverviewBottomSection';
import { dateTimeService } from '@bigid-ui/i18n';
import { isNil } from 'lodash';
import { userPreferencesService } from '../../../../../services/userPreferencesService';
import { Typography } from '@mui/material';
import { BigidTooltipContainer, ScanDetails } from '../../ScanDetails/ScanDetails';
import { ParentScanType } from '../../../ScanTypes';

const TOTAL_OBJECTS_TOOLTIP =
  'This number represents the total number of objects that were successfully scanned in this scan. Please refer to the Catalog for all objects scanned in the most recent scan. Objects are tables, files, folders, mailboxes, etc. depending on a data source selected in the scan.';

const TOTAL_ENUMERATED_TOOLTIP =
  'The number of objects in the data source that were counted and whose metadata was retrieved.';

const ScanOverviewTitle = styled(BigidHeading6)`
  font-size: 16px;
`;

const BigidAccordionSummaryContainer = styled(BigidAccordionSummary)`
  width: min-content;
  position: relative;
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
  }
`;

const BigidAccordionContainer = styled(BigidAccordion)`
  padding: 18px 12px;
`;

const DateSection = styled('div')`
  position: absolute;
  right: -190px;
  pointer-events: none;
  font-size: 12px;
  color: ${BigidColorsV2.gray[500]};
  top: 9px;
`;

const BigidAccordionDetailsContainer = styled(BigidAccordionDetails)`
  border-top: none !important;
  padding: 16px !important;
  padding: 24px 24px 20px !important;
`;

const ScanOverviewContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
`;

const MainSectionContainer = styled('div')`
  display: flex;
  gap: 8px;
  font-size: 14px;
`;

type ScanOverviewProps = DatasourceScanInsightsInfo & {
  dataAid?: string;
  scanProfileData: ScanProfileInfo;
  type: ParentScanType;
};

enum DsInsightAttributeType {
  attribute = 'attribute',
  classifier = 'classifier',
}

interface NavigateToCatalogPage extends Pick<ScanInsightsInfo, 'allEnabledDs' | 'dataSourceNames'> {
  attributes?: string[];
}

const navigateToCatalogPage =
  ({ allEnabledDs, dataSourceNames, attributes }: NavigateToCatalogPage) =>
  () => {
    const dsFilter = allEnabledDs
      ? []
      : (dataSourceNames || []).map(dsName => ({
          field: 'system',
          value: dsName,
          operator: 'equal' as BigidFieldFilterOperator,
        }));

    const csFilter = (attributes || []).map(csName => ({
      field: 'field',
      value: csName,
      operator: 'equal' as BigidFieldFilterOperator,
    }));

    goToCatalogWithChangePreferences({
      sideFilterSelection: [...dsFilter, ...csFilter],
      optionalSideFilterSections: [],
      searchQuery: '',
    });
  };

const handleEventAttributeDetailsClick = (type: string) => {
  if (type === DsInsightAttributeType.attribute) {
    trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_CORRELATION_SET_ATTRIBUTES_CLICK);
  }
  if (type === DsInsightAttributeType.classifier) {
    trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_CLASSIFICATION_ATTRIBUTES_CLICK);
  }
};

interface MainSectionProps {
  added?: number | string;
  removed?: number | string;
  pii?: number | string;
  changed?: number | string;
  isScanComplete?: boolean;
  labeled?: number | string;
  failed?: number | string;
  skipped?: number | string;
}

const SKIPPED_TOOLTIP =
  "Labeling was skipped as MIP won't lower existing label priority." +
  " To override this, mark the checkbox 'Overwrite existing labels' in the scan template";

const MainSection: FC<MainSectionProps> = ({
  added,
  removed,
  pii,
  changed,
  isScanComplete,
  labeled,
  failed,
  skipped,
}) => {
  return (
    <MainSectionContainer>
      {!isNil(added) && <Typography>{added} Added</Typography>}
      {!isNil(removed) && <Typography>{removed} Removed</Typography>}
      {!isNil(pii) && <Typography>{pii} With PII</Typography>}
      {!isNil(changed) && isScanComplete && <Typography>{changed} Changed</Typography>}
      {!isNil(labeled) && <Typography>{labeled} Labeled</Typography>}
      {!isNil(failed) && <Typography>{failed} Failed</Typography>}
      {!isNil(skipped) && (
        <>
          <Typography>{skipped} Skipped</Typography>

          <BigidTooltipContainer title={SKIPPED_TOOLTIP} enterDelay={500} placement="right">
            <span>
              <BigidHelpIcon />
            </span>
          </BigidTooltipContainer>
        </>
      )}
    </MainSectionContainer>
  );
};

export const ScanOverview: FC<ScanOverviewProps> = ({
  dataAid = 'ScanOverview',
  scannedDataSources = 0,
  objectsScanned = 0,
  objectsEnumerated = 0,
  dataSourcesWithPi = 0,
  objectsWithPi = 0,
  attributes = {},
  failed = 0,
  skipped = 0,
  objectsSkipped = 0,
  objectsFailed = 0,
  classifications = {},
  changeFromPreviousScan = 0,
  isScanComplete,
  allEnabledDs,
  dataSourceNames,
  csAttributeNames,
  classifierAttributeNames,
  updatedAt,
  scanId,
  scanProfileName,
  isReviewFindingsEnabled,
  dataSourceCompleted,
  scanProfileData,
  type,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleScanOverviewIsOpen();
  }, []);

  const handleScanOverviewIsOpen = async () => {
    try {
      const scanInsightUserPreference = await userPreferencesService.get('scanInsights');
      setExpanded(Boolean(scanInsightUserPreference?.data?.isOpenScanOverview));
    } catch (error) {
      console.error('Error to get the user preference', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDetailsAttributes = (title: string, type: string) => {
    handleEventAttributeDetailsClick(type);
    openSystemDialog({
      title,
      content: AttributesViewDetails,
      contentProps: {
        scanId,
        type,
        title,
        scannedDataSources,
      },
      onClose: () => null,
      maxWidth: 'md',
    });
  };

  const handleViewDetailsObjectsData = () => {
    trackEventScansView(ScansUITrackingEvent.VIEW_DETAILS_DATE_SOURCES_INSIGHTS_CLICK);
    openSystemDialog({
      title: 'Data Source Scan Results',
      content: ObjectsDataViewDetails,
      contentProps: {
        scanId,
        scannedDataSources,
        type,
      },
      onClose: () => null,
      maxWidth: 'md',
    });
  };

  const goToCuration = () => {
    $state.go(CONFIG.states.CURATION, { scanId, scanProfileName });
  };

  const handleExpanded = async () => {
    trackEventScansView(ScansUITrackingEvent.SCAN_OVERVIEW_COLLAPSE_CLICK, { isOpen: !expanded });
    setExpanded(prevExpanded => !prevExpanded);
    const scanInsightUserPreference = await userPreferencesService.get('scanInsights');
    await userPreferencesService.update({
      preference: 'scanInsights',
      data: { ...(scanInsightUserPreference?.data || {}), isOpenScanOverview: !expanded },
    });
  };

  const scanOverviewBoxes: ScanOverviewBoxProps[] = [
    {
      title: sanitizeInsightValues(scannedDataSources),
      icon: BigidDataDataSourceIllustration,
      subTitle: 'Data Sources Scanned',
      mainContent: (
        <PieInsightChart
          data={[
            { category: 'Completed', value: dataSourceCompleted || 0, color: '#70EFA7' },
            { category: 'Failed', value: failed || 0, color: '#FF7E7C' },
          ]}
          withTooltips
          showZeroedData
          disableFiltering
          alignItems="center"
          size="lg"
        />
      ),
      bottomContent: <div>Data sources with findings: {sanitizeInsightValues(dataSourcesWithPi)}</div>,
      dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'DataSourcesScanned']),
    },
    {
      title: sanitizeInsightValues(attributes.total),
      icon: BigidDataCorrelationIllustration,
      subTitle: 'Distinct Correlation Set Attributes',
      mainContent: <MainSection added={attributes.newlyAdded} removed={attributes.falseRemoved} />,
      bottomContent: (
        <ScanOverviewBottomSection
          viewDetails={() => handleViewDetailsAttributes('Correlations Set Attributes', 'attribute')}
          viewInCatalog={
            isScanComplete && navigateToCatalogPage({ allEnabledDs, dataSourceNames, attributes: csAttributeNames })
          }
          bi={{ eventType: ScansUITrackingEvent.VIEW_DETAILS_CORRELATION_SET_ATTRIBUTES_CLICK }}
          buttonText="View in Catalog"
        />
      ),
      dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'CorrelationSetAttributes']),
    },
    {
      title: sanitizeInsightValues(classifications.total),
      icon: BigidDataClassifierIllustration,
      subTitle: 'Distinct Classification Attributes',
      mainContent: <MainSection added={classifications.newlyAdded} removed={classifications.falseRemoved} />,
      bottomContent: (
        <ScanOverviewBottomSection
          viewDetails={() => handleViewDetailsAttributes('Classifications Attributes', 'classifier')}
          viewInCatalog={
            isScanComplete &&
            navigateToCatalogPage({ allEnabledDs, dataSourceNames, attributes: classifierAttributeNames })
          }
          bi={{ eventType: ScansUITrackingEvent.VIEW_DETAILS_CLASSIFICATION_ATTRIBUTES_CLICK }}
          buttonText="View in Catalog"
        />
      ),
      dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'ClassificationAttributes']),
    },
    ...(type === ParentScanType.METADATA_SCAN
      ? [
          {
            title: sanitizeInsightValues(objectsEnumerated),
            icon: BigidDataObjectIllustration,
            subTitle: 'Objects Enumerated',
            tooltip: TOTAL_ENUMERATED_TOOLTIP,
            mainContent: <MainSection isScanComplete={isScanComplete} />,
            dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'ObjectsEnumerated']),
          },
        ]
      : [
          {
            title: sanitizeInsightValues(objectsScanned),
            icon: BigidDataObjectIllustration,
            subTitle: 'Objects Scanned',
            tooltip: TOTAL_OBJECTS_TOOLTIP,
            mainContent: <MainSection isScanComplete={isScanComplete} />,
            bottomContent: <ScanOverviewBottomSection viewDetails={handleViewDetailsObjectsData} />,
            dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'ObjectsScanned']),
          },
        ]),
  ];

  const scanOverviewBoxesForLabeling: ScanOverviewBoxProps[] = [
    {
      title: sanitizeInsightValues(scannedDataSources),
      icon: BigidDataDataSourceIllustration,
      subTitle: 'Data Sources Scanned',
      mainContent: (
        <PieInsightChart
          data={[
            { category: 'Completed', value: dataSourceCompleted || 0, color: '#70EFA7' },
            { category: 'Failed', value: failed || 0, color: '#FF7E7C' },
            { category: 'Skipped', value: skipped || 0, color: BigidColorsV2.gray[500] },
          ]}
          withTooltips
          showZeroedData
          disableFiltering
          alignItems="center"
          size="lg"
        />
      ),
      bottomContent: <></>,
      dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'DataSourcesScanned']),
    },
    {
      title: sanitizeInsightValues(objectsScanned + objectsFailed),
      icon: BigidDataObjectIllustration,
      subTitle: 'Objects Labeled',
      mainContent: (
        <MainSection
          labeled={sanitizeInsightValues(objectsScanned)}
          failed={sanitizeInsightValues(objectsFailed)}
          skipped={sanitizeInsightValues(objectsSkipped)}
        />
      ),
      tooltip: 'Number of objects labeled successfully and failed in this scan.',
      bottomContent: <ScanOverviewBottomSection viewDetails={handleViewDetailsObjectsData} />,
      dataAid: generateDataAid(dataAid, ['BigidAccordionDetailsContainer', 'ObjectsLabeled']),
    },
  ];

  return (
    <div>
      {isLoading && <BigidLoader />}
      {!isLoading && (
        <BigidAccordionContainer expanded={expanded} onChange={handleExpanded}>
          <BigidAccordionSummaryContainer
            size={'normal' as AccordionSummarySizeEnum}
            dataAid={generateDataAid(dataAid, ['BigidAccordionSummary'])}
          >
            <ScanOverviewTitle data-aid={generateDataAid(dataAid, ['BigidAccordionSummary', 'ScanOverviewTitle'])}>
              Scan Overview
            </ScanOverviewTitle>
            <DateSection>Last Update: {dateTimeService.formatDate(updatedAt)}</DateSection>
          </BigidAccordionSummaryContainer>
          <BigidAccordionDetailsContainer dataAid={generateDataAid(dataAid, ['BigidAccordionDetailsContainer'])}>
            <ScanOverviewContainer>
              {(type === ParentScanType.DS_TAG ? scanOverviewBoxesForLabeling : scanOverviewBoxes)?.map(
                (row, index) => (
                  <ScanOverviewBox
                    title={row.title}
                    subTitle={row.subTitle}
                    icon={row.icon}
                    bottomContent={row.bottomContent}
                    mainContent={row.mainContent}
                    dataAid={row.dataAid}
                    tooltip={row.tooltip}
                    key={index}
                  />
                ),
              )}
            </ScanOverviewContainer>
            <ScanDetails {...scanProfileData} />
          </BigidAccordionDetailsContainer>
        </BigidAccordionContainer>
      )}
    </div>
  );
};
