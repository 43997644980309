import {
  BigidBody1,
  BigidButtonIcon,
  BigidChip,
  BigidColorsV2,
  BigidDropdown,
  BigidDropdownOption,
  BigidTag,
} from '@bigid-ui/components';
import { BigidAddIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React from 'react';
import { useCardModalContext } from '../../../CardModalContext';
import { useLocalTranslation } from '../../../translations';

const TabControls = styled('div')`
  display: flex;
  grid-column: span 3;
  gap: 24px;
  align-items: flex-start;
`;

const DropdownWrapper = styled('div')`
  width: 220px;
`;

const ScrollableFlexContainer = styled('div')`
  display: flex;
  max-width: 450px;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  max-height: 100px;
`;

export const AttributeTabHeading = () => {
  const {
    state: {
      tabs: { info },
    },
    dispatchAction,
  } = useCardModalContext();

  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Heading');

  const { tags, categories } = info;

  const handleSelect = (options: BigidDropdownOption[]) => {
    console.log('selected');
  };

  const handleCategoryDelete = (id: string) => {
    dispatchAction({
      type: 'SET_PARTIAL_TAB_STATE',
      payload: { tabName: 'info', data: { categories: categories.filter(category => category.id !== id) } },
    });
  };

  const handleCategoryAdd = () => {
    console.log('Add category');
  };

  const handleTagAdd = () => {
    console.log('Add tag');
  };

  return (
    <TabControls>
      <DropdownWrapper>
        <BigidDropdown options={[]} onSelect={handleSelect} placeholder={t('selectDomain')} />
      </DropdownWrapper>
      <DropdownWrapper>
        <BigidDropdown options={[]} onSelect={handleSelect} placeholder={t('project')} isDisabled />
      </DropdownWrapper>
      <ScrollableFlexContainer>
        <BigidBody1>{t('categoryLabel')}</BigidBody1>:
        {categories.map(({ id, name }) => (
          <BigidChip
            label={name}
            size="small"
            bgColor={BigidColorsV2.white}
            outline="solid"
            variant="outlined"
            outlineColor={BigidColorsV2.gray[500]}
            key={id}
            onDelete={() => handleCategoryDelete(id)}
          />
        ))}
        <BigidButtonIcon icon={BigidAddIcon} onClick={handleCategoryAdd} />
      </ScrollableFlexContainer>
      <ScrollableFlexContainer sx={{ alignItems: 'center' }}>
        <BigidBody1>{t('tagsLabel')}:</BigidBody1>
        {tags.map(tag => (
          <BigidTag name={tag.tagName} size="small" shadow value={tag.tagValue} key={tag.tagId} />
        ))}
        <BigidButtonIcon icon={BigidAddIcon} onClick={handleTagAdd} />
      </ScrollableFlexContainer>
    </TabControls>
  );
};
