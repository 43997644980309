import { BigidBody2, BigidDropdown } from '@bigid-ui/components';
import React from 'react';
import { useLocalTranslation } from '../../../../translations';
import { ControlsWrapper, DataRow, Heading } from './AttributeDataCard';

export const ApprovalsSection = () => {
  const { t } = useLocalTranslation('Tabs.AttributeInfoTab.Cards.AttributeData');

  const handleDefinitionSelect = () => {
    console.log('Definition');
  };
  const handleItAssetsSelect = () => {
    console.log('It assets');
  };

  return (
    <>
      <Heading title={t('approvals')}></Heading>
      <DataRow>
        <BigidBody2>{t('definition')}</BigidBody2>
        <ControlsWrapper>
          <BigidDropdown onSelect={handleDefinitionSelect} options={[]} />
        </ControlsWrapper>
      </DataRow>
      <DataRow>
        <BigidBody2>{t('itAssets')}</BigidBody2>
        <ControlsWrapper>
          <BigidDropdown onSelect={handleItAssetsSelect} options={[]} />
        </ControlsWrapper>
      </DataRow>
    </>
  );
};
