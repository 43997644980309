import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { BigidBody1, BigidColorsV2, BigidHeading4 } from '@bigid-ui/components';

interface SupportTabProps {
  support: string;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: BigidColorsV2.gray[200],
    minHeight: '152px',
    padding: '8px',
  },
});

export const SupportTab: FC<SupportTabProps> = ({ support }) => {
  const classes = useStyles();
  return <div className={classes.root}>{support}</div>;
};
