import React, { useContext } from 'react';
import { BigidSubtitle, BigidLink } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { goToTpaPresetEdit } from '../../autoDiscoveryWizardUtils';
import { useLocalTranslation } from '../../../translations';

const AutoDiscoveryGoToAdvancedWrapper = styled('div')`
  margin-top: 12px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
`;

export const AutoDiscoveryGoToAdvanced = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');

  return (
    <AutoDiscoveryGoToAdvancedWrapper>
      <BigidSubtitle>{t('configModal.needMore')}</BigidSubtitle>
      <BigidLink
        underline="none"
        text={t('configModal.buttons.goToAdvancedSettings')}
        onClick={() => goToTpaPresetEdit(discoveryConfigData)}
      />
    </AutoDiscoveryGoToAdvancedWrapper>
  );
};
