import React, { useCallback, useState } from 'react';
import { useLocationChange } from './useLocationChange';
import { EntitySources } from './DataSource/EntitySourcesV2';
import { DataSources } from './DataSource/DataSourcesV2';
import { FieldsSettings } from './SarFields/FieldsSettingsV2';
import { ObjectsSettings } from './DsarObjects/ObjectsSettings';
import { getProfileById } from '../dsarService';
import { SarProfileTabNames } from './SarProfileSettingsTypes';
import makeStyles from '@mui/styles/makeStyles';
import { ManualRecords } from './ManualRecords/ManualRecords';

const componentTabMapper: Record<
  SarProfileTabNames,
  typeof EntitySources | typeof DataSources | typeof FieldsSettings | typeof ObjectsSettings
> = {
  [SarProfileTabNames.ES]: EntitySources,
  [SarProfileTabNames.DS]: DataSources,
  [SarProfileTabNames.FIELDS]: FieldsSettings,
  [SarProfileTabNames.OBJECTS]: ObjectsSettings,
  [SarProfileTabNames.MANUAL_RECORDS]: ManualRecords,
};

const getComponentBasedOnTab = (tab: SarProfileTabNames) =>
  componentTabMapper[tab] || componentTabMapper[SarProfileTabNames.ES];

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
});

export const SarProfileSettingsDetailsPage = () => {
  const classes = useStyles();
  const { stateParams } = useLocationChange();
  const [props, setProps] = useState(null);

  const fetchProfileData = useCallback(async () => {
    if (!stateParams.profileId) {
      return;
    }

    if (stateParams.profileId === props?._id) {
      return props.dsConnections;
    }

    const profile = await getProfileById(
      stateParams.profileId,
      {},
      {
        excludeEsConnections: true,
      },
    );
    setProps({
      _id: profile._id,
      allEnabledEs: profile.allEnabledEs,
      allEnabledDs: profile.allEnabledDs,
      dsConnections: profile.dsConnections,
      hasSelectedEs: profile.hasSelectedEs,
      hasSelectedDs: profile.hasSelectedDs,
      name: profile.name,
    });
    return profile.dsConnections;
  }, [props, stateParams.profileId]);

  if (!stateParams.profileId) {
    return null;
  }

  const Component = getComponentBasedOnTab(stateParams.tab);

  return (
    <div className={classes.root}>
      <Component {...props} _id={stateParams.profileId} fetchProfileData={fetchProfileData} />
    </div>
  );
};
