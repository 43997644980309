import React, { useEffect, useState } from 'react';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { BigidTabsAndContent, BigidTabsAndContentProps } from '@bigid-ui/components';
import { $state, $transitions } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { UIView } from '@uirouter/react';
import { isPermitted } from '../../services/userPermissionsService';
import { DATA_MINIMIZATION_PERMISSIONS } from '@bigid/permissions';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const pageBottomMargin = 10;
const tabHeight = 34;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${pageBottomMargin}px)`,
    width: '100%',
    overflow: 'hidden',
  },
  contentContainer: { overflow: 'hidden', display: 'none' },
  view: {
    height: `calc(100% - ${tabHeight}px)`,
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
  },
});

const DATA_DELETION_TABS = [CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES, CONFIG.states.DATA_MINIMIZATION_REQUESTS];

export const DataMinimization: React.FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(
    DATA_DELETION_TABS.indexOf($state.$current.name) || 0,
  );
  const { content, contentContainer, view, buttonContainer } = useStyles({});

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: 'Data Deletion',
      ...(isPermitted(DATA_MINIMIZATION_PERMISSIONS.SETTINGS.name) && {
        rightSideComponentsContainer: (
          <IconButton title={'Settings'} onClick={() => $state.go(CONFIG.states.DATA_DELETION_GLOBAL_SETTINGS)}>
            <SettingsOutlinedIcon />
          </IconButton>
        ),
      }),
    });

    const deregisterListener = $transitions.onBefore(
      {
        to: state => state.name.includes(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES),
      },
      transition => {
        const { name } = transition.to();
        if (name === CONFIG.states.DATA_MINIMIZATION) {
          return $state.target(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES);
        }
        setSelectedTabIndex(DATA_DELETION_TABS.indexOf(name) || 0);
      },
    );

    if ($state.current.name === CONFIG.states.DATA_MINIMIZATION) {
      $state.go(CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES);
    }
    return () => deregisterListener?.();
  }, [buttonContainer]);

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    classes: { /*tabsContainer,*/ contentContainer },
    tabProps: {
      size: 'large',
      tabs: [{ label: 'All Objects' }, { label: 'DSAR Requests' }],
      onChange: tabIndex => {
        $state.go(DATA_DELETION_TABS[tabIndex]);
        setSelectedTabIndex(tabIndex);
      },
      selectedIndex: selectedTabIndex,
    },
  };

  return (
    <div className={content}>
      <BigidTabsAndContent {...tabsAndContentConfig} />
      <UIView className={view} />
    </div>
  );
};
