import React, { useEffect, useRef, useState, useCallback } from 'react';
import angular, { IAngularEvent } from 'angular';
import makeStyles from '@mui/styles/makeStyles';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { $stateParams, $translate, queryStringService, $state } from '../../services/angularServices';
import {
  AccountMenuProps,
  BigidHeader,
  HeaderActionItem,
  Breadcrumb,
  BadgeVariant,
  BadgeOverlap,
} from '@bigid-ui/components';
import { BigidHorizontalColorLogoIcon } from '@bigid-ui/icons';
import SmallIdLogoIcon from '../../assets/icons/SmallIdLogo.svg';
import {
  TitleInfo,
  accountMenuToTranslate,
  productNameToTranslate,
  getAccountInfo,
  getHeaderActions,
  getNumberOfUnreadTasks,
  getProductName,
  getTitleInfo,
  HistoryItem,
  getRiskScore,
  getQueryRiskScore,
} from './HeaderService';
import { getUnreadNotifications, getNotifications } from '../../services/headerNotificationsService';
import { getUpdatedSubscriptionsHeaderItem } from '../../services/headerCloudSubscriptionsService';
import { notificationService } from '../../services/notificationService';
import { HeaderEvents, headerEventEmitter } from '../../services/eventEmitters/headerEvents';
import { BigidPageTitle } from './BigidPageTitle/BigidPageTitle';
import { $rootScope } from 'ngimport';
import { BigidSearchHeader } from '../../../commonComponents/searchHeader/searchHeader.component';
import { sidebarWidth } from '../Sidebar/Sidebar';
import { useIsSmallIdLicense } from '../../components/hooks/useIsSmallIdLicense';
import { subscribeToRepeatedSSEEventById, SSE_EVENTS } from '../../services/sseService';
import { MetadataSearch, MetadataSearchInitialState } from '../MetadataSearch/MetadataSearch';
import {
  MetadataSearchResultEntity,
  MetadataSearchUserPreferences,
  MetadataSearchTransitionPayload,
  MetadataSearchEntityTypeConfig,
} from '../MetadataSearch/MetadataSearchTypes';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  getMetadataSearchQuickResults,
  getMetadataSearchFilters,
  MetadataSearchFetchQuickResultsPayload,
  MetadataSearchFetchQuickResultsResponse,
  MetadataSearchFetchFiltersPayload,
  MetadataSearchFetchFiltersResponse,
  MetadataSearchFetchFilterSuggestionsResponse,
  getMetadataSearchFilterSuggestions,
  updateMetadataSearchPreferences,
} from '../MetadataSearch/MetadataSearchService';
import {
  handleTransitionPayloadReceive,
  handleTransitionPayloadSend,
  previewSearchResultEntity,
} from '../MetadataSearch/MetadataSearchUtils';
import { CONFIG } from '../../../config/common';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { goToRequestedPage } from '../../views/Login/loginUtils';
import { BigChatHeaderAvatar } from '../Bigchat/widgets/BigChatAvatars';
import { getShouldShowBigChatInHeader } from '../../services/bigchat/bigchatStorageService';
import { BigChatSetInHeaderProps } from '../Bigchat/BigChatTypes';
import { DataExplorerSearchBar } from '../../views/DataExplorerSearchResults/DataExplorerSearchBar';
import { BypassMenu } from './BypassMenu';
import {
  sendMetadataSearchBarFetchFiltersBiAnalytics,
  sendMetadataSearchBarSubmitBiAnalytics,
  MetadataSearchBarFetchFiltersBiPayload,
  MetadataSearchBarSubmitBiPayload,
} from '../MetadataSearch/services/analytics';
import {
  DataExplorerSearchSubmitBiPayload,
  sendDataExplorerSystemHeaderBarSubmitBiAnalytics,
} from '../../views/DataExplorer/services/analytics';

export const HEADER_HEIGHT = 48;

type UpdateEventPayload = {
  data: {
    isLoading: boolean;
    isScroll?: boolean;
    hasError?: boolean;
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  headerContent: {
    marginLeft: sidebarWidth + 8,
  },
  search: {
    width: '100%',
  },
  headerContentHidden: {
    display: 'none',
  },
  bigChat: {
    '& [data-aid="BigChat-header-avatar-icon-type"]': {
      animation: `$backgroundColorAnimation 500ms`,
    },
  },
  '@keyframes backgroundColorAnimation': {
    to: {
      backgroundColor: theme.vars.palette.bigid.primary300,
    },
  },
}));

const pagesToShowWidgets = ['Inventory', 'Scan Results Summary', 'Dashboard', 'Entity Details'];

export const BigidHeaderComponent = ({ setShowBigChatInHeader }: BigChatSetInHeaderProps) => {
  const classes = useStyles({});
  const { text, filter } = handleTransitionPayloadReceive($stateParams);
  const initialState: MetadataSearchInitialState = {
    query: typeof text === 'string' ? text : undefined,
    filters: Array.isArray(filter) ? filter : undefined,
  };

  const [productName, setProductName] = useState<string>(null);
  const [accountInfo, setAccountInfo] = useState<AccountMenuProps>(null);
  const [actions, setActions] = useState<HeaderActionItem[]>(null);
  const [titleInfo, setTitleInfo] = useState<TitleInfo>(null);
  const [riskScore, setRiskScore] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isHeaderHidden, setIsHeaderHidden] = useState(pageHeaderService.getIsHidden());
  const isSmallID = useIsSmallIdLicense();
  const isMetadataSearchAvailable =
    !isSmallID &&
    getApplicationPreference('METADATA_SEARCH_ENABLED') &&
    $state.current.name !== CONFIG.states.UPDATE_PASSWORD &&
    $state.current.name !== CONFIG.states.DATA_EXPLORER &&
    $state.current.name !== CONFIG.states.CATALOG_SEARCH_RESULTS;
  const wrapper = useRef<HTMLDivElement>();

  const updateNotificationsDisplay = ({ data: { isLoading, isScroll, hasError } }: UpdateEventPayload) => {
    setActions(actionsState => {
      const notificationsIndex = actionsState?.findIndex(action => action.notificationsConfiguration);
      if (notificationsIndex > -1) {
        const newActions = [...actionsState];
        const currentNotificationConfiguration = newActions[notificationsIndex].notificationsConfiguration;
        if (hasError) {
          newActions[notificationsIndex].notificationsConfiguration = {
            ...currentNotificationConfiguration,
            hasError,
            isLoading: false,
          };
        } else {
          if (isLoading) {
            newActions[notificationsIndex].notificationsConfiguration = {
              ...currentNotificationConfiguration,
              ...(isScroll ? { isScrollLoading: true } : { isLoading: true }),
              hasError: false,
            };
          } else {
            newActions[notificationsIndex].notificationsConfiguration = {
              ...currentNotificationConfiguration,
              ...(isScroll ? { isScrollLoading: false } : { isLoading: false }),
              notifications: getNotifications(),
              hasError: false,
            };
          }
        }

        return newActions;
      }
    });
  };

  const updateCloudSubscriptions = ({ data: { isLoading, hasError } }: UpdateEventPayload) => {
    setActions(actionState =>
      getUpdatedSubscriptionsHeaderItem({ isLoading, hasError, headerActionItem: actionState }),
    );
  };

  const updateNotificationsDot = async () => {
    try {
      const hasUnread = await getUnreadNotifications();
      setActions(actionsState => {
        const notificationsIndex = actionsState?.findIndex(action => action.notificationsConfiguration);
        const newActions = [...actionsState];
        const { badge, ...currentConfiguration } = newActions[notificationsIndex];
        if (hasUnread) {
          newActions[notificationsIndex] = {
            ...currentConfiguration,
            badge: { badgeContent: '', variant: BadgeVariant.DOT, overlap: BadgeOverlap.CIRCULAR },
          };
        } else {
          newActions[notificationsIndex] = currentConfiguration;
        }
        return newActions;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateActions = async () => {
    try {
      const actions = getHeaderActions(await getNumberOfUnreadTasks(), await getUnreadNotifications());
      if (getApplicationPreference('BIGCHAT_FF_ENABLED') && getShouldShowBigChatInHeader()) {
        const bigChatAction: HeaderActionItem = {
          icon: () => {
            return (
              <div className={classes.bigChat}>
                <BigChatHeaderAvatar />
              </div>
            );
          },
          tooltip: 'BigChat',
          dataAid: 'BigChat-header-action',
          onClick: () => {
            setShowBigChatInHeader(false);
          },
        };
        setActions([bigChatAction, ...actions]);
      } else {
        setActions([...actions]);
      }
    } catch (error) {
      notificationService.error($translate.instant('API:MESSAGE:COMMON_ERROR'));
    }
  };

  const updateRiskScore = async () => {
    setRiskScore(await getRiskScore());
  };

  const fetchMetadataSearchRecentObjects = useCallback(async (): Promise<MetadataSearchResultEntity[]> => {
    try {
      const preferences = await userPreferencesService.get<MetadataSearchUserPreferences>('metadataSearchResults');

      return preferences?.data?.recentObjects || [];
    } catch ({ message }) {
      console.error(`An error has occurred: ${message}`);
      return [];
    }
  }, []);

  const saveMetadataSearchRecentObjects = useCallback(async (recentObjects: MetadataSearchResultEntity[]) => {
    await updateMetadataSearchPreferences({ recentObjects });
  }, []);

  const fetchMetadataSearchResults = useCallback(
    async (payload: MetadataSearchFetchQuickResultsPayload): Promise<MetadataSearchFetchQuickResultsResponse> => {
      const { promise } = getMetadataSearchQuickResults(payload);
      const { data } = await promise;

      return data;
    },
    [],
  );

  const fetchMetadataSearchFilters = useCallback(
    async (payload: MetadataSearchFetchFiltersPayload): Promise<MetadataSearchFetchFiltersResponse> => {
      const { promise } = getMetadataSearchFilters(payload);
      const { data } = await promise;

      const biPayload: MetadataSearchBarFetchFiltersBiPayload = {
        text: payload.text,
        filter: payload.filter,
      };

      sendMetadataSearchBarFetchFiltersBiAnalytics(biPayload);

      return data;
    },
    [],
  );

  const fetchMetadataSearchFilterSuggestions = useCallback(
    async (payload: MetadataSearchFetchFiltersPayload): Promise<MetadataSearchFetchFilterSuggestionsResponse> => {
      const { promise } = getMetadataSearchFilterSuggestions(payload);

      const { data } = await promise;
      return data;
    },
    [],
  );

  const handleMetadataSearchSubmit = (payload: MetadataSearchTransitionPayload) => {
    $state.go(CONFIG.states.METADATA_SEARCH_RESULTS, handleTransitionPayloadSend(payload), { reload: true });

    const biPayload: MetadataSearchBarSubmitBiPayload = {
      text: payload.text,
      filter: payload.filter,
    };

    sendMetadataSearchBarSubmitBiAnalytics(biPayload);
  };

  const handleDataExplorerSearchBarSubmit = (payload: MetadataSearchTransitionPayload) => {
    const biPayload: DataExplorerSearchSubmitBiPayload = {
      searchText: payload.text,
    };

    sendDataExplorerSystemHeaderBarSubmitBiAnalytics(biPayload);
  };

  const handleMetadataSearchResultEntityClick = async (
    entity: MetadataSearchResultEntity,
    payload: MetadataSearchTransitionPayload,
    entityTypeConfig: MetadataSearchEntityTypeConfig,
  ) => {
    previewSearchResultEntity(entity, payload, entityTypeConfig);
  };

  const updateTotalRecordsAndRiskScore = (event: [number, number]) => {
    const [queryRiskScore, totalRecords] = event;
    setRiskScore(queryRiskScore);
    setTotalRecords(totalRecords);
  };

  useEffect(() => {
    async function initialSetup() {
      setProductName(getProductName(await $translate(productNameToTranslate)));
      setAccountInfo(getAccountInfo(await $translate(accountMenuToTranslate)));
      await updateActions();
      await updateRiskScore();
    }

    initialSetup();

    const unregisterUpdateUnreadTasksListener = headerEventEmitter.addEventListener(
      HeaderEvents.UPDATE_UNREAD_TASKS,
      updateActions,
    );

    const unregisterUpdateUnreadTasksAndRiskListener = headerEventEmitter.addEventListener(
      HeaderEvents.UPDATE_UNREAD_TASKS_AND_RISK,
      async () => {
        await updateActions();
        await updateRiskScore();
      },
    );

    const unregisterUpdateTotalRecordsAndRiskScoreListener = headerEventEmitter.addEventListener(
      HeaderEvents.SET_TOTAL_RECORDS_AND_RISK,
      updateTotalRecordsAndRiskScore,
    );

    const unregisterNotificationsListener = subscribeToRepeatedSSEEventById(
      SSE_EVENTS.NOTIFICATION,
      updateNotificationsDot,
    );

    const unregisterUpdateNotificationsDisplayListener = headerEventEmitter.addEventListener(
      HeaderEvents.UPDATE_NOTIFICATIONS,
      updateNotificationsDisplay,
    );

    const unregisterUpdateCloudSubscriptionsListener = headerEventEmitter.addEventListener(
      HeaderEvents.UPDATE_CLOUD_SUBSCRIPTIONS,
      updateCloudSubscriptions,
    );

    const unregisterHeaderHideListener = headerEventEmitter.addEventListener(
      HeaderEvents.HEADER_HIDE,
      ({ data: { isHidden } }) => {
        setIsHeaderHidden(isHidden);
      },
    );

    const unregisterBigChatInHeaderListener = headerEventEmitter.addEventListener(
      HeaderEvents.SHOW_BIGCHAT_IN_HEADER,
      ({ data: { showBigChatInHeader } }) => {
        if (showBigChatInHeader) {
          const bigChatAction: HeaderActionItem = {
            icon: () => {
              return (
                <div className={classes.bigChat}>
                  <BigChatHeaderAvatar />
                </div>
              );
            },
            tooltip: 'BigChat',
            dataAid: 'BigChat-header-action',
            onClick: () => {
              setShowBigChatInHeader(false);
            },
          };
          setActions(actionsState => [bigChatAction, ...actionsState]);
        } else {
          setActions(actionsState => actionsState.filter(({ tooltip }) => tooltip !== 'BigChat'));
        }
      },
    );

    const unregisterChangePageListener = $rootScope.$on(
      'changePage',
      async (
        event: IAngularEvent,
        pageName: string,
        showSearchHeader = false,
        showBackButton = false,
        from: HistoryItem = null,
        breadcrumbs: Breadcrumb[],
        rightSideComponentsContainer,
        titleHelperComponent,
      ) => {
        document.title = `BigID ${pageName}`;

        const shouldShowWidgets = pagesToShowWidgets.includes(pageName);

        if (shouldShowWidgets) {
          const id = decodeURIComponent($stateParams.id);
          const query =
            pageName !== 'Entity Details' ? $stateParams.filter : queryStringService.getQueryStringFilter(null, [id]);
          await getQueryRiskScore(query);
        }

        setTitleInfo({
          title: pageName,
          showSearchHeader,
          backButton: showBackButton,
          from,
          shouldShowWidgets,
          breadcrumbs,
          rightSideComponentsContainer,
          titleHelperComponent,
        });
      },
    );

    return function cleanup() {
      unregisterUpdateUnreadTasksListener();
      unregisterUpdateUnreadTasksAndRiskListener();
      unregisterChangePageListener();
      unregisterUpdateTotalRecordsAndRiskScoreListener();
      unregisterNotificationsListener();
      unregisterUpdateNotificationsDisplayListener();
      unregisterUpdateCloudSubscriptionsListener();
      unregisterHeaderHideListener();
      unregisterBigChatInHeaderListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    wrapper.current?.focus();
  }, []);

  const handleLogoOnClick = useCallback(() => {
    goToRequestedPage();
  }, []);

  const headerContentClass = isHeaderHidden ? classes.headerContentHidden : classes.headerContent;

  const isDataExplorerEnabled = getApplicationPreference('DATA_EXPLORER_ENABLED');

  return (
    <div className={classes.root} ref={wrapper} tabIndex={0}>
      <BypassMenu />
      {accountInfo && (
        <BigidHeader
          accountInfo={accountInfo}
          logo={isSmallID ? SmallIdLogoIcon : BigidHorizontalColorLogoIcon}
          actions={actions}
          productName={productName}
          hasSearch={isMetadataSearchAvailable}
          logoOnClick={handleLogoOnClick}
        >
          {isMetadataSearchAvailable && (
            <div className={classes.search}>
              {isDataExplorerEnabled ? (
                <DataExplorerSearchBar
                  width={650}
                  placeholder="Search in BigID"
                  onSubmit={handleDataExplorerSearchBarSubmit}
                />
              ) : (
                <MetadataSearch
                  width={650}
                  initialState={initialState}
                  placeholder="Search in BigID"
                  fetchRecentObjects={fetchMetadataSearchRecentObjects}
                  saveRecentObjects={saveMetadataSearchRecentObjects}
                  fetchResults={fetchMetadataSearchResults}
                  fetchFilters={fetchMetadataSearchFilters}
                  fetchFilterSuggestions={fetchMetadataSearchFilterSuggestions}
                  onSubmit={handleMetadataSearchSubmit}
                  onResultEntityClick={handleMetadataSearchResultEntityClick}
                  isResultsRouteActive={$state.current.name === CONFIG.states.METADATA_SEARCH_RESULTS}
                />
              )}
            </div>
          )}
        </BigidHeader>
      )}
      <div className={headerContentClass}>
        {titleInfo && <BigidPageTitle {...getTitleInfo(titleInfo, riskScore, totalRecords)} />}
        <BigidSearchHeader />
      </div>
    </div>
  );
};

angular
  .module('app')
  .component('bigidHeaderComponent', convertToAngular<any>(BigidHeaderComponent, ['setShowBigChatInHeader']));
