import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BigidGridColumn,
  BigidGridColumnTypes,
  BigidGridQueryComponents,
  BigidGridRow,
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
} from '@bigid-ui/grid';
import { BigidBody1, BigidPaper } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import { getDataSourcesDetailsByParentId } from '../ScanInsightService';
import { queryService } from '../../../../services/queryService';
import { DataSourceDetails } from '../ScanInsightTypes';
import { EmptyTemplate } from '../../../../components/EmptyTemplate/EmptyTemplate';
import { ViewDetailsDocumentation } from '../ViewDetailsDocumentation/ViewDetailsDocumentation';
import { ParentScanType } from '../../ScanTypes';
import { isScanInsightsAvailable } from '../../ScanUtils';

interface ObjectsDataViewDetailsProps {
  scanId: string;
  scannedDataSources: number;
  type?: ParentScanType;
}

const useStyles = makeStyles({
  gridWrapper: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: '70vh',
    maxHeight: '70vh',
    overflow: 'hidden',
  },
});

const columns: BigidGridColumn<DataSourceDetails & BigidGridRow>[] = [
  {
    name: 'source',
    title: 'Data Source Name',
    width: 200,
    getCellValue: ({ source }) => source,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'state',
    title: 'State',
    getCellValue: ({ state }) => state,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
    width: 200,
  },
  {
    name: 'totalObjects',
    title: 'Objects Scanned',
    getCellValue: ({ totalObjects }) => totalObjects || 0,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
  },
];
const columnsForLabeling: BigidGridColumn<DataSourceDetails & BigidGridRow>[] = [
  {
    name: 'source',
    title: 'Data Source Name',
    width: 200,
    getCellValue: ({ source }) => source,
    sortingEnabled: true,
    type: BigidGridColumnTypes.TEXT,
  },
  {
    name: 'state',
    title: 'State',
    getCellValue: ({ state }) => state,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
    width: 200,
  },
  {
    name: 'objectsLabeled',
    title: 'Objects Labeled',
    getCellValue: ({ totalObjects }) => totalObjects || 0,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
    width: 180,
  },
  {
    name: 'failed',
    title: 'Failed',
    getCellValue: ({ totalFailedObjects }) => totalFailedObjects || 0,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
    width: 140,
  },
  {
    name: 'skipped',
    title: 'Skipped',
    getCellValue: ({ totalSkippedObjects }) => totalSkippedObjects || 0,
    sortingEnabled: false,
    type: BigidGridColumnTypes.TEXT,
    width: 140,
  },
];

export const ObjectsDataViewDetails: FC<ObjectsDataViewDetailsProps> = ({ scanId, scannedDataSources, type }) => {
  const [isOldFlow, setIsOldFlow] = useState(true);
  const [isFinishLoading, setIsFinishLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    handleOldFlow();
  }, []);

  const requiredColumns = useMemo(() => {
    let updatedColumns = [...columns];
    if (!isScanInsightsAvailable()) {
      updatedColumns = [
        ...updatedColumns,
        {
          name: 'totalObjectsWithPii',
          title: 'Objects With PII',
          getCellValue: ({ totalObjectsWithPii }) => totalObjectsWithPii || 0,
          sortingEnabled: false,
          type: BigidGridColumnTypes.TEXT,
        },
      ];
    }
    return updatedColumns;
  }, []);

  const handleOldFlow = async () => {
    if (scannedDataSources === 0) {
      return;
    }
    const response = await getDataSourcesDetailsByParentId(scanId, 'limit=1');
    setIsFinishLoading(true);
    if (response?.dataSourcesInsights) {
      setIsOldFlow(false);
    }
  };

  const gridConfig: BigidGridWithToolbarProps<any> = useMemo(
    () => ({
      pageSize: 10,
      entityName: 'Datasource Insights',
      defaultSorting: [{ field: '_id', order: 'desc' }],
      showSortingControls: true,
      showSelectAll: false,
      columns: type === ParentScanType.DS_TAG ? columnsForLabeling : requiredColumns,
      filterToolbarConfig: {
        searchConfig: {
          searchFilterKeys: ['source'],
          initialValue: '',
          placeholder: 'Data source name',
          operator: 'textSearch',
          autoSubmit: true,
        },
      },
      fetchData: async (queryComponents: BigidGridQueryComponents) => {
        const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
        const response = await getDataSourcesDetailsByParentId(scanId, gridConfigQuery);
        return { data: response.dataSourcesInsights, totalCount: response.totalCount };
      },
    }),
    [scanId],
  );

  return (
    <>
      {scannedDataSources === 0 && (
        <EmptyTemplate
          title="Can't Fetch the Data"
          content={<BigidBody1>No data sources were scanned so there are no insights.</BigidBody1>}
        />
      )}
      {isFinishLoading && isOldFlow === true && (
        <EmptyTemplate title="Can't Fetch the Data" content={<ViewDetailsDocumentation />} />
      )}
      {isFinishLoading && isOldFlow === false && (
        <div className={classes.gridWrapper}>
          <BigidPaper>
            <BigidGridWithToolbar {...gridConfig} />
          </BigidPaper>
        </div>
      )}
    </>
  );
};
