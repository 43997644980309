import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import classnames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidPrimaryCheckbox,
  BigidHeading5,
  BigidDatePickerLazy,
  BigidNotes1,
  BigidSubtitle,
  BigidColorsV2,
} from '@bigid-ui/components';
import { isRangeInvalid, UsageReportStateSetters, hasOneNullDate } from './usageReportService';

const USAGE_REPORT_NOTE = `The report summarizes data over the last 30 days, the last 90 days and month-to-date. Fill in the information here to include an additional date range:`;
const CHECKBOX_TEXT = 'Add another date range';
const ERROR_MESSAGE = 'Invalid date range.  Fill in both dates, with an end date later than the start date.';
const WARNING_MESSAGE = 'You must fill in both dates.';

type UsageReportDialogContentProps = Pick<UsageReportStateSetters, 'setUsageReportState'>;

const useStyles = makeStyles({
  subtitle: { margin: '0 0 8px 8px' },
  checkboxWrapper: { marginBottom: 16 },
  dateRangeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
  },
  error: {
    color: BigidColorsV2.red[600],
    marginTop: 8,
  },
  warning: { color: BigidColorsV2.yellow[700] },
  disabled: { opacity: 0.5 },
  dateFieldsWrapper: { display: 'flex' },
  datePickerWrapper: { maxWidth: 144 },
  heading: {
    margin: '0 16px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const UsageReportDialogContent: FC<UsageReportDialogContentProps> = ({ setUsageReportState }) => {
  const [isDateRangeChecked, setIsDateRangeChecked] = useState(false);
  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);
  const [hasErrors, setHasErrors] = useState(false);
  const {
    dateFieldsWrapper,
    subtitle,
    checkboxWrapper,
    heading,
    dateRangeWrapper,
    error,
    warning,
    disabled,
    datePickerWrapper,
  } = useStyles();

  const handleCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsDateRangeChecked(checked);
  };

  useEffect(() => {
    setUsageReportState({ isChecked: isDateRangeChecked });
  }, [isDateRangeChecked, setUsageReportState]);

  useEffect(() => {
    const hasErrors = isRangeInvalid({ startDate, endDate });
    setUsageReportState({ startDate, endDate, hasErrors });
    setHasErrors(hasErrors);
  }, [isDateRangeChecked, startDate, endDate, setUsageReportState]);

  const isDatePickersDisabled = !isDateRangeChecked;
  const shouldDisplayError = !isDatePickersDisabled && hasErrors;
  const shouldDisplayWarning = shouldDisplayError && hasOneNullDate({ startDate, endDate });

  return (
    <>
      <div className={checkboxWrapper}>
        <BigidSubtitle className={subtitle}>{USAGE_REPORT_NOTE}</BigidSubtitle>
        <BigidPrimaryCheckbox
          checked={isDateRangeChecked}
          label={CHECKBOX_TEXT}
          onChange={handleCheckboxChange}
          dataAid="usageReportCustomRangeCheckbox"
        />
      </div>
      <div className={dateRangeWrapper}>
        <div className={classnames(dateFieldsWrapper, isDatePickersDisabled && disabled)}>
          <div className={datePickerWrapper}>
            <BigidDatePickerLazy
              dataAid="usageReportCustomFromDate"
              format="dd MMM yyyy"
              disableFuture
              inputProps={{ readOnly: true, placeholder: 'Start date' }}
              value={startDate}
              onChange={setStartDate}
              disabled={isDatePickersDisabled}
              size="large"
            />
          </div>
          <BigidHeading5 data-aid="usageReportCustomRangeLabel" className={heading}>
            to
          </BigidHeading5>
          <div className={datePickerWrapper}>
            <BigidDatePickerLazy
              dataAid="usageReportCustomToDate"
              format="dd MMM yyyy"
              inputProps={{ readOnly: true, placeholder: 'End date' }}
              value={endDate}
              onChange={setEndDate}
              disableFuture
              disabled={isDatePickersDisabled}
              size="large"
            />
          </div>
        </div>
        {shouldDisplayError && (
          <BigidNotes1
            className={classnames(error, shouldDisplayWarning && warning)}
            data-aid="usageReportCustomRangeError"
          >
            {shouldDisplayWarning ? WARNING_MESSAGE : ERROR_MESSAGE}
          </BigidNotes1>
        )}
      </div>
    </>
  );
};
