import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import {
  BigidFieldRenderProps,
  compareObjectsExceptFunctions,
  BigidLoader,
  BigidFormFieldSideSelect,
  BigidSelectOption,
  BigidBody1,
  BigidLink,
} from '@bigid-ui/components';
import { CERTIFICATES_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { getCertificates } from '../../../CertificatesManagement/CertificatesManagement';
import { $state } from '../../../../services/angularServices';
import makeStyles from '@mui/styles/makeStyles';

export interface UseCertificateOptions {
  options: BigidSelectOption[];
  isLoading: boolean;
  value: BigidSelectOption[];
}

const DEFAULT_CERTIFICATE_OPTIONS_STATE: UseCertificateOptions = {
  options: [],
  isLoading: true,
  value: [],
};

export const useCertificateOptions = (
  canViewCertificates: boolean,
  selectedCertificate: BigidSelectOption[] = [],
): UseCertificateOptions => {
  const [{ options, value, isLoading }, setState] = useState<UseCertificateOptions>(DEFAULT_CERTIFICATE_OPTIONS_STATE);

  const updateOptions = useCallback(async () => {
    try {
      const certificates = canViewCertificates ? (await getCertificates('')).results : [];
      const selectedCertificateId = selectedCertificate[0]?.value || '';

      const [value, options] = certificates.reduce(
        ([values, options], { name: label, _id: value }) => {
          const option = {
            label,
            value,
          };
          return [
            [...values, ...(selectedCertificateId === value ? [option] : [])],
            [...options, option],
          ];
        },
        [
          [],
          [
            {
              value: '',
              label: '',
            },
          ],
        ],
      );

      setState({
        options,
        value,
        isLoading: false,
      });
    } catch (e) {
      setState({
        ...DEFAULT_CERTIFICATE_OPTIONS_STATE,
        isLoading: false,
      });

      console.error(e);
      throw new Error('Error getting Certificates');
    }
  }, [canViewCertificates, selectedCertificate]);

  useEffect(() => {
    updateOptions();
  }, [updateOptions]);

  return { options, isLoading, value };
};

const useStyles = makeStyles({ noCertificateText: { marginTop: 8, marginLeft: 8 } });

export const FormCertificateField: FC<BigidFieldRenderProps> = memo(props => {
  const { noCertificateText } = useStyles();
  const { value: selectedCertificate, misc } = props;
  const isHidden = misc?.hidden ?? false;
  const canViewCertificates = isPermitted(CERTIFICATES_PERMISSIONS.READ.name);
  const { isLoading, options, value } = useCertificateOptions(canViewCertificates, selectedCertificate);
  const tooltipText = canViewCertificates
    ? props.tooltipText
    : `You don't have the required permissions to view this field, please contact the administrator to gain access`;

  if (isLoading) {
    return <BigidLoader />;
  }

  return isHidden ? null : canViewCertificates && options.length <= 1 ? (
    <BigidBody1 className={noCertificateText}>
      {`No Certificates defined, use the `}
      <BigidLink onClick={() => $state.go(`certificatesManagement`)} text={'Certificate Management'} />
      {' page to create one'}
    </BigidBody1>
  ) : (
    <BigidFormFieldSideSelect
      {...props}
      misc={{ ...props.misc, isMulti: false }}
      options={options}
      value={value}
      disabled={props.disabled || !canViewCertificates}
      tooltipText={tooltipText}
      isClearable={true}
    />
  );
}, compareObjectsExceptFunctions);

FormCertificateField.displayName = 'FormCertificateField';
