import { CloudProvider } from './types';
import { BigidFormField, BigidFormFieldTypes, BigidSelectOption } from '@bigid-ui/components';
import { MaskedJsonField } from '../AutoDiscoveryWizard/autoDiscoveryCommonComponents/fields/MaskedJsonField';

export enum DiscoveryAuthTypes {
  CREDENTIALS = 'credentials',
  CREDENTIALS_MULTI = 'credentialsMulti',
  IAM_ROLE = 'isIAMRole',
  IAM_ROLE_MULTI = 'isIAMRoleMulti',
}

export const TYPE_TO_AUTH_OPTIONS: Record<CloudProvider, BigidSelectOption[]> = {
  [CloudProvider.AWS]: [
    { label: 'IAM Role (Single Account)', value: DiscoveryAuthTypes.IAM_ROLE },
    { label: 'IAM Role (Multiple Accounts)', value: DiscoveryAuthTypes.IAM_ROLE_MULTI },
    {
      label: 'Credentials (Single Account)',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
    {
      label: 'Credentials (Multiple Accounts)',
      value: DiscoveryAuthTypes.CREDENTIALS_MULTI,
    },
  ],
  [CloudProvider.AZURE]: [
    {
      label: 'Credentials (Single Account)',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
    {
      label: 'Credentials (Multiple Accounts)',
      value: DiscoveryAuthTypes.CREDENTIALS_MULTI,
    },
    { label: 'Secretless (Single Account)', value: DiscoveryAuthTypes.IAM_ROLE },
    { label: 'Secretless (Multiple Accounts)', value: DiscoveryAuthTypes.IAM_ROLE_MULTI },
  ],
  [CloudProvider.GCP]: [
    {
      label: 'Credentials',
      value: DiscoveryAuthTypes.CREDENTIALS,
    },
  ],
};

export const AUTH_TYPE_FIELD_NAME = 'authType';

const managedIdentityClientIdField = {
  name: 'managed_identity_client_id',
  tooltipText: 'Required if you are using User-Assigned Managed Identity',
};

const gcpCredentialsField = {
  name: 'credentials',
  type: BigidFormFieldTypes.TEXTAREA,
  render: MaskedJsonField,
  misc: {
    multiline: true,
    rows: 6,
    isSensitive: true,
  },
};

export const TYPE_TO_VISIBLE_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: string[];
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['access_key_id', 'secret_access_key'],
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: [
      'access_key_id',
      'secret_access_key',
      'aws_config_aggregator_name',
      'aws_config_aggregator_region',
      'base_role_name',
    ],
    [DiscoveryAuthTypes.IAM_ROLE]: ['aws_role_arn'],
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: [
      'aws_role_arn',
      'aws_config_aggregator_name',
      'aws_config_aggregator_region',
      'base_role_name',
    ],
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['client_id', 'client_secret', 'tenant_id'],
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: ['client_id', 'client_secret', 'tenant_id', 'management_group_name'],
    [DiscoveryAuthTypes.IAM_ROLE]: ['managed_identity_client_id'],
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: ['managed_identity_client_id', 'management_group_name'],
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: ['credentials'],
  },
};

export const TYPE_TO_OVERRIDE_FIELDS: Record<CloudProvider, Record<string, BigidFormField>> = {
  [CloudProvider.AWS]: {},
  [CloudProvider.GCP]: {
    credentials: gcpCredentialsField,
  },
  [CloudProvider.AZURE]: {
    managed_identity_client_id: managedIdentityClientIdField,
  },
};

export const TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: boolean;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: false,
    [DiscoveryAuthTypes.IAM_ROLE]: true,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: false,
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: true,
    [DiscoveryAuthTypes.IAM_ROLE]: true,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: true,
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: true,
  },
};

export const TYPE_TO_DEFAULT_VALUES_BY_AUTH_FIELDS: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: Record<string, any>;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: { is_iam_role: 'false' },
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: { is_iam_role: 'false' },
    [DiscoveryAuthTypes.IAM_ROLE]: { is_iam_role: 'true' },
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: { is_iam_role: 'true' },
  },
  [CloudProvider.AZURE]: {},
  [CloudProvider.GCP]: {},
};

const getRoleNameBasedOnArn = ({ base_role_name, aws_role_arn }: Record<string, any>) => {
  if (base_role_name) {
    return { base_role_name };
  }
  const match = aws_role_arn?.match(/role\/(.+)$/);
  return { base_role_name: match?.[1] || '' };
};

const fillCredentialWithStringIfEmptyForSaveDraft = (values: Record<string, any>) => ({
  credentials: values?.credentials || '_',
});

export const TYPE_TO_VALUES_COMPUTE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: (args: Record<string, any>) => Record<string, any>;
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: getRoleNameBasedOnArn,
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: getRoleNameBasedOnArn,
  },
  [CloudProvider.AZURE]: {},
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: fillCredentialWithStringIfEmptyForSaveDraft,
  },
};

export const ACTION_DATA_KEY_BY_TYPE: Record<
  CloudProvider,
  {
    [key in DiscoveryAuthTypes]?: 'configData' | 'configDataMultiply';
  }
> = {
  [CloudProvider.AWS]: {
    [DiscoveryAuthTypes.CREDENTIALS]: 'configData',
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: 'configDataMultiply',
    [DiscoveryAuthTypes.IAM_ROLE]: 'configData',
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: 'configDataMultiply',
  },
  [CloudProvider.AZURE]: {
    [DiscoveryAuthTypes.CREDENTIALS]: 'configDataMultiply',
    [DiscoveryAuthTypes.CREDENTIALS_MULTI]: 'configDataMultiply',
    [DiscoveryAuthTypes.IAM_ROLE]: 'configDataMultiply',
    [DiscoveryAuthTypes.IAM_ROLE_MULTI]: 'configDataMultiply',
  },
  [CloudProvider.GCP]: {
    [DiscoveryAuthTypes.CREDENTIALS]: 'configData',
  },
};
