import React, { FC, useCallback, useMemo, useState } from 'react';
import { useInterval, BigidFilter, BigidHeading4, BigidColorsV2, EntityEvents } from '@bigid-ui/components';
import {
  BigidGridWithToolbar,
  BigidGridWithToolbarProps,
  BigidGridRow,
  BigidGridColumnTypes,
  BigidGridColumn,
  ActionsFormatterProps,
  FormatterOnClickHandlerPayloadData,
  ActionsFormatterAlignment,
  ActionsFormatterAction,
} from '@bigid-ui/grid';
import makeStyles from '@mui/styles/makeStyles';
import { module } from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { notificationService } from '../../services/notificationService';
import {
  MetadataSearchIndexingStatus,
  MetadataSearchTypeIndexingStatus,
} from '../../components/MetadataSearch/MetadataSearchTypes';
import {
  MetadataSearchTypeIndexingStatusLabelMap,
  formatDateTime,
} from '../../components/MetadataSearch/MetadataSearchUtils';
import {
  getMetadataSearchIndexingStatus,
  MetadataSearchIndexingStatusResponse,
  startMetadataSearchIndexing,
  stopMetadataSearchIndexing,
} from '../../components/MetadataSearch/MetadataSearchService';
import { PlayCircleOutline, StopOutlined } from '@mui/icons-material';
import { isPermitted } from '../../services/userPermissionsService';
import { ADVANCE_TOOLS_PERMISSIONS } from '@bigid/permissions';

interface MetadataSearchIndexingProgressGridColumn
  extends BigidGridRow,
    Omit<MetadataSearchTypeIndexingStatus, 'nextIndexingAt'> {
  statusText: string;
}

const getDridData = (response: MetadataSearchIndexingStatusResponse): MetadataSearchIndexingProgressGridColumn[] => {
  return response.statusPerType.map(record => {
    const { status, entityName, startedIndexingAt, finishedIndexingAt } = record;

    return {
      id: entityName,
      entityName,
      status,
      statusText: MetadataSearchTypeIndexingStatusLabelMap[status],
      startedIndexingAt: formatDateTime(startedIndexingAt),
      finishedIndexingAt: formatDateTime(finishedIndexingAt),
    };
  });
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    padding: '10px 0px 15px 10px',
  },
});

const FORCE_RELOAD_INTERVAL = 60000;

export const MetadataSearchIndexingProgressGrid: FC = () => {
  const isStartIndexingPermitted = isPermitted(ADVANCE_TOOLS_PERMISSIONS.METADATA_SEARCH_INDEXING_RUN.name);

  const classes = useStyles();
  const [externalFilter, setExternalFilter] = useState<BigidFilter>([]);

  const getActionIconConfig = useCallback(
    (status: MetadataSearchIndexingStatus): ActionsFormatterAction['iconConfig'] => {
      switch (status) {
        case MetadataSearchIndexingStatus.FAILED:
          return {
            icon: PlayCircleOutline,
            label: 'Start indexing',
            color: BigidColorsV2.blue[300],
          };
        case MetadataSearchIndexingStatus.INDEXING:
          return {
            icon: StopOutlined,
            label: 'Stop indexing',
            color: BigidColorsV2.blue[300],
          };
        case MetadataSearchIndexingStatus.UPDATED:
          return {
            icon: PlayCircleOutline,
            label: 'Start indexing',
            color: BigidColorsV2.blue[300],
          };
        case MetadataSearchIndexingStatus.CANCELLED:
          return {
            icon: PlayCircleOutline,
            label: 'Start indexing',
            color: BigidColorsV2.blue[300],
          };
      }
    },
    [],
  );

  const runIndexingActionByCurrentStatus = useCallback(
    (entityTypeCategory: string, status: MetadataSearchIndexingStatus): void => {
      switch (status) {
        case MetadataSearchIndexingStatus.FAILED:
          startMetadataSearchIndexing(entityTypeCategory);
          break;
        case MetadataSearchIndexingStatus.INDEXING:
          stopMetadataSearchIndexing(entityTypeCategory);
          break;
        case MetadataSearchIndexingStatus.UPDATED:
          startMetadataSearchIndexing(entityTypeCategory);
          break;
        case MetadataSearchIndexingStatus.CANCELLED:
          startMetadataSearchIndexing(entityTypeCategory);
          break;
      }
    },
    [],
  );

  const columns = useMemo(() => {
    const columns: BigidGridColumn<MetadataSearchIndexingProgressGridColumn>[] = [
      {
        name: 'entityName',
        title: 'Entity Name',
        getCellValue: ({ entityName }) => entityName,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'status',
        title: 'Indexing Status',
        getCellValue: ({ statusText }) => statusText,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'startedIndexingAt',
        title: 'Started At',
        getCellValue: ({ startedIndexingAt }) => startedIndexingAt,
        type: BigidGridColumnTypes.TEXT,
      },
      {
        name: 'finishedIndexingAt',
        title: 'Last Finished At',
        getCellValue: ({ finishedIndexingAt }) => finishedIndexingAt,
        type: BigidGridColumnTypes.TEXT,
      },
    ];

    if (isStartIndexingPermitted) {
      columns.push({
        name: 'actions',
        title: '',
        width: '150',
        noHeader: true,
        isNotDraggable: true,
        type: BigidGridColumnTypes.ACTIONS,
        getCellValue: ({ status, entityName }) => {
          const actionsFormatted: ActionsFormatterProps = {
            alignment: ActionsFormatterAlignment.CENTER,
            actions: Object.values(MetadataSearchIndexingStatus).includes(status as MetadataSearchIndexingStatus)
              ? [
                  {
                    name: 'index',
                    iconConfig: getActionIconConfig(status),
                    execute: async ({ row }: FormatterOnClickHandlerPayloadData) => {
                      try {
                        await runIndexingActionByCurrentStatus(entityName, status);

                        return {
                          row,
                          shouldGridReload: false,
                          entityEventToEmit: EntityEvents.RELOAD,
                        };
                      } catch ({ message }) {
                        notificationService.error('An error has occurred');
                        console.error(`An error has occurred: ${message}`);
                        return {
                          row,
                          shouldGridReload: false,
                        };
                      }
                    },
                  },
                ]
              : [],
          };

          return actionsFormatted;
        },
      });
    }

    return columns;
  }, [getActionIconConfig, runIndexingActionByCurrentStatus, isStartIndexingPermitted]);

  const gridConfig: BigidGridWithToolbarProps<MetadataSearchIndexingProgressGridColumn> = {
    externalFilter,
    entityName: 'entity types',
    showSortingControls: false,
    hideColumnChooser: true,
    fetchData: async () => {
      try {
        const response = await getMetadataSearchIndexingStatus();
        const data = getDridData(response);

        return {
          totalCount: data.length,
          data,
        };
      } catch ({ message }) {
        notificationService.error('An error has occurred');
        console.error(`An error has occurred: ${message}`);
        return {
          totalCount: 0,
          data: [],
        };
      }
    },
    columns,
  };

  useInterval(() => {
    setExternalFilter([]);
  }, FORCE_RELOAD_INTERVAL);

  return (
    <div className={classes.root} data-aid="MetadataSearchIndexingProgressGrid">
      <div className={classes.header}>
        <BigidHeading4 data-aid="MetadataSearchIndexingProgressGrid-header">Search Indexing Status</BigidHeading4>
      </div>
      <BigidGridWithToolbar {...gridConfig} />
    </div>
  );
};

module('app').component('metadataSearchIndexingProgressGrid', convertToAngular(MetadataSearchIndexingProgressGrid));
