import React, { FC, Dispatch } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2, BigidHeading2, PrimaryButton } from '@bigid-ui/components';
import { ACTION_CENTER_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../services/userPermissionsService';

export const topBarHeight = 64;

interface ActionWorkflowTopProps {
  setIsCreateMode: Dispatch<boolean>;
  isFormOpen: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${BigidColorsV2.gray[200]}`,
    height: topBarHeight,
  },
  title: { color: BigidColorsV2.gray[700] },
});

export const ActionWorkflowTop: FC<ActionWorkflowTopProps> = ({ isFormOpen, setIsCreateMode }) => {
  const { root, title } = useStyles({});
  const shouldShowCreateWorkflowButton = isPermitted(ACTION_CENTER_PERMISSIONS.CREATE_ACTIONS.name) && !isFormOpen;
  return (
    <div className={root}>
      <BigidHeading2 className={title}>Actions</BigidHeading2>
      {shouldShowCreateWorkflowButton && (
        <PrimaryButton onClick={() => setIsCreateMode(true)} size="medium" dataAid={`ac-add-new`} text="Add Action" />
      )}
    </div>
  );
};
