import { BigidLayout } from '@bigid-ui/layout';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { isStewardshipPaid } from './utils';
import { getPaidLayoutConfig } from './paid/paidLayoutConfig';
import { getFreeLayoutConfig } from './free/freeLayoutConfig';
import { GlossaryCardModal } from './CardModal/GlossaryCardModal/GlossaryCardModal';
import { CardType } from './CardModal/types';
import { isDataExplorerEnabled } from '../DataExplorer/dataExplorerService';
import { $state } from '../../services/angularServices';
import { CONFIG } from '../../../config/common';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useLocalTranslation } from './translations';
import { BigidAdministrationIcon } from '@bigid-ui/icons';
import { BigidButtonIcon } from '@bigid-ui/components';

const useStyles = makeStyles({
  createNewDropdown: {
    width: 124,
  },
});

export const BusinessGlossary = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityType, setEntityType] = useState<CardType>('attribute');

  const { t } = useLocalTranslation();

  const classes = useStyles();

  useEffect(() => {
    if (!isDataExplorerEnabled()) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, []);

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Data Explorer', onClick: () => $state.go(CONFIG.states.DATA_EXPLORER) },
      { label: t('title') },
    ];

    pageHeaderService.setTitle({
      breadcrumbs,
      rightSideComponentsContainer: (
        <BigidButtonIcon
          icon={BigidAdministrationIcon}
          onClick={() => $state.go(CONFIG.states.BUSINESS_GLOSSARY_SETTINGS)}
        />
      ),
    });
  }, [t]);

  const handleCreateAttributeClick = () => {
    setIsModalOpen(true);
    setEntityType('attribute');
  };

  const handleCreateTermClick = () => {
    setIsModalOpen(true);
    setEntityType('term');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEntityType(null);
  };

  const getLayoutConfigFn = isStewardshipPaid() ? getPaidLayoutConfig : getFreeLayoutConfig;

  const layoutConfig = getLayoutConfigFn({
    createNewDropdownClassName: classes.createNewDropdown,
    onCreateAttributeClick: handleCreateAttributeClick,
    onCreateTermClick: handleCreateTermClick,
  });

  if (!isDataExplorerEnabled()) {
    return null;
  }

  return (
    <div>
      <GlossaryCardModal cardType={entityType} isOpen={isModalOpen} onClose={handleModalClose} />
      <BigidLayout config={layoutConfig} />
    </div>
  );
};
