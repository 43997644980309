import React, { FC, useCallback, useState, useEffect, memo } from 'react';
import { BigidSkeletonGenerator } from '@bigid-ui/components';
import { generateDataAid, generateGuidedTourId } from '@bigid-ui/utils';
import { UseCatalogDiscoveryResponse } from '../../useCatalogDiscovery';
import { BarChartWidget, BarChartWidgetItem } from '../../widgets/BarChartWidget/BarChartWidget';
import { getAggregatedData, GetAggregatedDataPayload, GetAggregatedDataResponse } from '../../catalogDiscoveryService';
import { AggregationType } from '../../catalogDiscoveryTypes';
import { getBarChartWidgetKnownData } from '../../utils/widgets';
import { BarChartWidgetWrapper } from '../../utils/BarChartWidgetWrapper';
import { useLocalTranslation } from './translations';
import { useFetchDataCancelable } from '../../config/useFetchDataCancelable';
import { barChartWidgetSkeletonConfig } from '../../config/skeleton';
import { CatalogDiscoveryWidget } from '../../config/widgets';
import { RiskGroupsBarChartTooltip } from './RiskGroupsBarChartTooltip';

export interface RiskGroupsBarChartProps
  extends Pick<UseCatalogDiscoveryResponse, 'query' | 'filter' | 'onDataFetchStatusChange'> {
  dataAid?: string;
  dataTourId?: string;
  isPageInitialised: boolean;
  onWidgetFilterChange?: UseCatalogDiscoveryResponse['onWidgetFilterChange'];
}

export const RiskGroupsBarChart: FC<RiskGroupsBarChartProps> = memo(
  ({
    dataAid = 'RiskGroupsBarChart',
    dataTourId = 'RiskGroupsBarChart',
    query,
    filter,
    onDataFetchStatusChange,
    onWidgetFilterChange,
    isPageInitialised,
  }) => {
    const { fetchTopCategoriesCancelable } = useFetchDataCancelable();
    const { t } = useLocalTranslation();
    const [chartData, setChartData] = useState<BarChartWidgetItem[]>(
      getBarChartWidgetKnownData({ aggregationData: [], type: AggregationType.RISK }),
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAggregationData = useCallback(async () => {
      try {
        const payload: GetAggregatedDataPayload = {
          filter: query,
          aggregations: [
            {
              aggName: AggregationType.RISK,
              isTotalRequired: true,
              sorting: [
                {
                  field: 'docCount',
                  order: 'DESC',
                },
              ],
            },
          ],
        };

        setIsLoading(true);
        onDataFetchStatusChange(CatalogDiscoveryWidget.RISK_GROUPS_BAR_CHART, true);

        const { aggregations } = await fetchTopCategoriesCancelable(
          getAggregatedData(payload) as Promise<GetAggregatedDataResponse>,
        );
        const data = getBarChartWidgetKnownData({
          aggregationData: aggregations?.[0]?.aggData ?? [],
          type: AggregationType.RISK,
          filter,
        });

        setChartData(data);
      } catch ({ isCanceled, message }) {
        if (!isCanceled) {
          console.error(`An error has occurred: ${message}`);
        }
      } finally {
        setIsLoading(false);
        onDataFetchStatusChange(CatalogDiscoveryWidget.RISK_GROUPS_BAR_CHART, false);
      }
    }, [fetchTopCategoriesCancelable, filter, onDataFetchStatusChange, query]);

    const handleChartItemClick = ({ aggItem, isActive }: BarChartWidgetItem) => {
      onWidgetFilterChange?.(AggregationType.RISK, [aggItem], isActive);
    };

    useEffect(() => {
      if (isPageInitialised) {
        fetchAggregationData();
      }
    }, [fetchAggregationData, isPageInitialised]);

    return (
      <BarChartWidgetWrapper dataAid={dataAid}>
        {isPageInitialised ? (
          <BarChartWidget
            dataAid={generateDataAid(dataAid, ['widget'])}
            dataTourId={generateGuidedTourId(dataTourId, ['widget'])}
            title={t('title')}
            noDataText={t('noData')}
            data={chartData}
            isBusy={isLoading}
            tooltipText={<RiskGroupsBarChartTooltip />}
            onItemClick={onWidgetFilterChange ? handleChartItemClick : undefined}
          />
        ) : (
          <BigidSkeletonGenerator dataAid={generateDataAid(dataAid, ['skeleton'])} {...barChartWidgetSkeletonConfig} />
        )}
      </BarChartWidgetWrapper>
    );
  },
  (prevProps, newProps) =>
    prevProps.query === newProps.query && prevProps.isPageInitialised === newProps.isPageInitialised,
);
