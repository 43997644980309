import React, { FC, PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import { BigidBlueCloudBottomBack, BigidBlueCloudBottomFront } from '@bigid-ui/icons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    flex: '1 1 auto',
    background: BigidColorsV2.white,
    boxShadow: '0px 8px 28px rgba(24, 39, 75, 0.12), 0px 18px 88px rgba(24, 39, 75, 0.14)',
    borderRadius: '8px',
  },
  leftContainer: {
    minWidth: '10%',
  },
  contentContainer: {
    width: 984,
    padding: '36px 40px',
    margin: '0 auto',
    position: 'relative',
  },
  background: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

export const MainArea: FC<PropsWithChildren> = ({ children }) => {
  const { container, leftContainer, contentContainer, background } = useStyles({});

  return (
    <div className={container}>
      <BigidBlueCloudBottomBack className={background} />
      <BigidBlueCloudBottomFront className={background} />
      <div className={leftContainer}></div>
      <div className={contentContainer}>{children}</div>
    </div>
  );
};
