import React, { useState, useEffect } from 'react';

type Indexable = { [key: string]: string };

export function getQueryParamsFromHash(hash: string): Indexable {
  const queryString = hash.split('?')[1];
  if (!queryString) return {};

  const params = new URLSearchParams(queryString);
  const obj: Indexable = {};

  for (const [key, value] of params.entries()) {
    obj[key] = value;
  }

  return obj;
}

// Custom hook to track hash updates
export function useHashQueryParams(): Indexable {
  const [queryParams, setQueryParams] = useState<Indexable>(getQueryParamsFromHash(window.location.hash));

  useEffect(() => {
    const handleHashChange = () => {
      setQueryParams(getQueryParamsFromHash(window.location.hash));
    };

    // Listen to hash changes
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return queryParams;
}
