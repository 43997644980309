import React, { FC, ComponentType, ReactText, ReactNode, useCallback } from 'react';
import { styled } from '@mui/material';
import { IconComponentProps, BigidInfoIcon } from '@bigid-ui/icons';
import {
  BigidBody1,
  BigidColorsV2,
  BigidHeading3,
  BigidLoader,
  BigidTooltip,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';

export type SummaryWidgetActionType = 'primary' | 'secondary';

export type SummaryWidgetAction = {
  text: string;
  icon?: ComponentType<IconComponentProps>;
  type?: SummaryWidgetActionType;
  onClick: () => void;
  isDisabled?: boolean;
};

export interface SummaryWidgetProps {
  dataAid?: string;
  dataTourId?: string;
  icon: ComponentType<IconComponentProps>;
  label: string;
  value: ReactText;
  height?: string;
  width?: string;
  tooltipText?: ReactNode;
  isBusy?: boolean;
  leftAction?: SummaryWidgetAction;
  rightAction?: SummaryWidgetAction;
}

const Root = styled('div')<Pick<SummaryWidgetProps, 'height' | 'width'>>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const FlexibleRowWrapper = styled('div')`
  display: flex;
`;

const ValueContainer = styled(FlexibleRowWrapper)<{ isShrinked: boolean }>`
  height: 32px;
  width: ${({ isShrinked }) => (isShrinked ? '23px' : 'calc(100% - 30px)')};
`;

const IconContainer = styled(FlexibleRowWrapper)`
  margin-left: auto;
  width: 30px;
`;

const ActionsContainer = styled(FlexibleRowWrapper)<{ hasLeftAction: boolean; hasRightAction: boolean }>`
  margin-top: auto;
  height: 32px;
  justify-content: ${({ hasLeftAction, hasRightAction }) => {
    if (hasLeftAction && hasRightAction) {
      return 'space-between';
    } else if (hasLeftAction) {
      return 'flex-start';
    } else {
      return 'flex-end';
    }
  }};
`;

const OverflowTypography = styled('div')`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Value = styled('div')`
  display: flex;
  align-items: center;
`;

const Info = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const SecondaryAction = styled('div')<{ isDisabled: boolean }>`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
`;

const SecondaryActionText = styled(BigidBody1)<{ isDisabled: boolean }>`
  color: ${({ isDisabled }) => (isDisabled ? BigidColorsV2.gray[500] : BigidColorsV2.gray[700])};
`;

const spinnerSize = 23;
const spinnerThickness = 3;

export const SummaryWidget: FC<SummaryWidgetProps> = ({
  dataAid = 'SummaryWidget',
  dataTourId = 'SummaryWidget',
  label,
  value,
  icon: Icon,
  tooltipText,
  leftAction,
  rightAction,
  height = '100%',
  width = '100%',
  isBusy,
}: SummaryWidgetProps) => {
  const getPrimaryActionComponent = useCallback(
    (action: SummaryWidgetAction): ReactNode => {
      const { text, onClick } = action;

      return (
        <SecondaryButton
          dataAid={generateDataAid(dataAid, ['primary-action', text])}
          size="medium"
          onClick={onClick}
          text={text}
          disabled={isBusy}
          width="auto"
        />
      );
    },
    [dataAid, isBusy],
  );

  const getSecondaryActionComponent = useCallback(
    (action: SummaryWidgetAction): ReactNode => {
      const { text, icon: Icon, onClick, isDisabled } = action;

      return (
        <TertiaryButton
          dataAid={generateDataAid(dataAid, ['secondary-action', text])}
          size="medium"
          onClick={onClick}
          startIcon={<Icon />}
          disabled={isBusy || isDisabled}
          text={text}
        />
      );
    },
    [dataAid, isBusy],
  );

  const renderAction = useCallback(
    (action: SummaryWidgetAction): ReactNode => {
      switch (action.type) {
        case 'primary':
          return getPrimaryActionComponent(action);
        case 'secondary':
          return getSecondaryActionComponent(action);
      }
    },
    [getPrimaryActionComponent, getSecondaryActionComponent],
  );

  return (
    <Root height={height} width={width} data-aid={dataAid} data-tour-id={dataTourId}>
      <FlexibleRowWrapper>
        <ValueContainer isShrinked={isBusy}>
          {isBusy ? (
            <BigidLoader
              dataAid={generateDataAid(dataAid, ['loader'])}
              size={spinnerSize}
              thickness={spinnerThickness}
              position="relative"
            />
          ) : (
            <>
              <Value>
                <BigidHeading3 data-aid={generateDataAid(dataAid, ['value'])}>{value ?? 0}</BigidHeading3>
              </Value>
              {tooltipText && (
                <BigidTooltip title={tooltipText}>
                  <Info>
                    <BigidInfoIcon dataAid={generateDataAid(dataAid, ['info-icon'])} staticMode />
                  </Info>
                </BigidTooltip>
              )}
            </>
          )}
        </ValueContainer>
        <IconContainer>
          <Icon dataAid={generateDataAid(dataAid, ['icon'])} size="large" />
        </IconContainer>
      </FlexibleRowWrapper>
      <FlexibleRowWrapper>
        <OverflowTypography>
          <BigidBody1 data-aid={generateDataAid(dataAid, ['label'])}>{label}</BigidBody1>
        </OverflowTypography>
      </FlexibleRowWrapper>
      <ActionsContainer hasLeftAction={Boolean(leftAction)} hasRightAction={Boolean(rightAction)}>
        {leftAction && renderAction(leftAction)}
        {rightAction && renderAction(rightAction)}
      </ActionsContainer>
    </Root>
  );
};
