import { useCallback, useEffect, useRef, useState } from 'react';
import {
  fetchFavoriteTemplates,
  fetchTemplateFavoritePreferences,
  getDefaultScanTemplate,
} from '../../../Scans/ScanTemplates/scanTemplatesService';
import { getCurrentUser } from '../../../../utilities/systemUsersUtils';
import { isTemplatesEnabled } from '../../../Scans/ScanUtils';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SCANS_PERMISSIONS } from '@bigid/permissions';
import { notificationService } from '../../../../services/notificationService';
import { ScanTemplateGridRow, ScanTypes } from '../../../Scans/ScanTemplates/scanTemplateTypes';
import { isEmpty, uniqBy } from 'lodash';
import { getQuickScanOptions } from '../InstantScanUtils';

const INITIAL_STATE = {
  isScanTemplatesLoading: true,
  isScanTemplatesEnabled: false,
};

export interface InstantScanOptionsState {
  isScanTemplatesLoading: boolean;
  isScanTemplatesEnabled: boolean;
  currentOwner?: { firstName: string; lastName: string; name: string; id: string };
  favoriteTemplates?: ScanTemplateGridRow[];
  defaultScanTemplates?: ScanTemplateGridRow[];
}

export const useInstantScanData = () => {
  const [state, setState] = useState<InstantScanOptionsState>(INITIAL_STATE);
  const favoriteTemplatesRef = useRef<any>();
  const oneFavoriteScanTemplate = useRef<null | Record<string, any>>(null);

  useEffect(() => {
    const init = async () => {
      try {
        const [favoriteScanTemplates, defaultScanTemplates, currentOwner] = await Promise.all([
          fetchTemplateFavoritePreferences(),
          getDefaultScanTemplate(),
          getCurrentUser(),
        ]);
        const { favoriteTemplates } = await fetchFavoriteTemplates(
          favoriteScanTemplates.map(({ scanTemplateId }) => scanTemplateId),
        );

        setState({
          isScanTemplatesLoading: false,
          isScanTemplatesEnabled: true,
          currentOwner,
          favoriteTemplates,
          defaultScanTemplates,
        });
      } catch (e) {
        notificationService.error('Failed to load favorite templates. See logs for more information.');
        console.error(e);
        setState({
          isScanTemplatesLoading: false,
          isScanTemplatesEnabled: false,
        });
      }
    };

    if (isTemplatesEnabled() && isPermitted(SCANS_PERMISSIONS.RUN_SCAN_PROFILES.name)) {
      init();
    } else {
      setState({
        isScanTemplatesLoading: false,
        isScanTemplatesEnabled: false,
      });
    }
  }, []);

  const updateFavoriteTemplates = useCallback(
    (supportedScanTypesForSelectedDs: ScanTypes[]) => {
      if (!state.defaultScanTemplates || !state.favoriteTemplates) {
        return;
      }
      const favoriteTemplatesWithSupportedScanTypes = uniqBy(
        [...(!isEmpty(state.defaultScanTemplates) ? state.defaultScanTemplates : []), ...state.favoriteTemplates],
        '_id',
      ).filter(template => supportedScanTypesForSelectedDs?.includes(template.scanType as ScanTypes));

      const scanTemplates = getQuickScanOptions(favoriteTemplatesWithSupportedScanTypes, state.currentOwner.name);

      oneFavoriteScanTemplate.current =
        scanTemplates?.length === 1 && scanTemplates[0]?.hasSeparator ? scanTemplates[0] : null;
      favoriteTemplatesRef.current = scanTemplates;
    },
    [state],
  );

  return { favoriteTemplatesRef, oneFavoriteScanTemplate, updateFavoriteTemplates, ...state };
};
