import { ReactNode } from 'react';
import { BadgeProps } from '@mui/material';
import { AccountMenuProps, BigidColors, BigidColorsV2, Breadcrumb, HeaderActionItem } from '@bigid-ui/components';
import { sessionStorageService } from '../../../common/services/sessionStorageService';
import { $state, $translate, commonMethods, privacyRiskTrendService } from '../../services/angularServices';
import { loginService } from '../../../authentication/login/login.service';
import { LICENSE_TYPES, licenseService } from '../../services/licenseService';
import {
  BigidHeaderTasksIcon,
  BigidNotificationIcon,
  BigidHelpLargeIcon,
  BigidApplicationsIcon,
} from '@bigid-ui/icons';
import { httpService } from '../../services/httpService';
import { BigidPageTitleProps } from './BigidPageTitle/BigidPageTitle';
import { $rootScope, $timeout } from 'ngimport';
import { headerEventEmitter, HeaderEvents } from '../../services/eventEmitters/headerEvents';
import { getProductProperties } from '../../services/productTypePropertiesService';
import { showAboutDialog } from '../BigidAboutDialog/BigidAboutDialog';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { getNotifications, getNewNotifications, updateNotifications } from '../../services/headerNotificationsService';
import { getNewSubscriptions, getSubscriptions } from '../../services/headerCloudSubscriptionsService';
import { noop } from 'lodash';
import { CONFIG } from '../../../config/common';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { smallIdLicenseService } from '../../services/smallIdLicenseService';
import { BIGID_BI_EVENTS } from '../../config/BigIdBiEvents';
import { analyticsService } from '../../services/analyticsService';
import { isPermitted } from '../../services/userPermissionsService';
import { HELP_ABOUT_PERMISSIONS, TASKS_PERMISSIONS } from '@bigid/permissions';
import { isMultiTenantModeEnabled } from '../../utilities/multiTenantUtils';

const USER_ORIGIN_SHOW_UPDATE_PASSWORD = ['local', 'Okta'];

export const productNameToTranslate = ['BIGID_HEADER:SAP_BRANDING:LABEL'];

export const accountMenuToTranslate = [
  'BIGID_HEADER:USER_MENU:UPDATE_PASSWORD',
  'BIGID_HEADER:USER_MENU:MY_FOLLOWED_OBJECTS',
  'BIGID_HEADER:USER_MENU:LOGOUT',
  'BIGID_HEADER:USER_MENU:NAVIGATION_GUIDE',
];

interface Configurations {
  [key: string]: string;
}

export interface TitleInfo {
  title: string;
  showSearchHeader?: boolean;
  backButton?: boolean;
  from?: HistoryItem;
  shouldShowWidgets: boolean;
  breadcrumbs?: Breadcrumb[];
  rightSideComponentsContainer?: ReactNode;
  titleHelperComponent?: ReactNode;
}

export interface HistoryItem {
  state: string;
  params?: any;
}

export interface History {
  to: HistoryItem;
  from: HistoryItem;
}

const onBackButtonClick = (from: HistoryItem) => () => {
  const history: History[] = sessionStorageService.get('history') || [];
  if (from) {
    const { state, params } = from;
    $state.go(state, params);
  } else {
    if (history.length > 0) {
      const target = history.pop();
      const { state, params } = target.from;
      sessionStorageService.set('history', history);
      $state.go(state, params);
    }
  }
};

export const getTitleInfo = (
  {
    title,
    backButton,
    from,
    shouldShowWidgets,
    breadcrumbs,
    rightSideComponentsContainer,
    titleHelperComponent,
  }: TitleInfo,
  riskScore: number,
  totalRecords: number,
): BigidPageTitleProps => {
  const history: History[] = sessionStorageService.get('history') || [];
  const shouldShowBackButton = backButton && (history?.length > 0 || from);

  const widgets = [];

  widgets.push({
    color: BigidColors.gray[900],
    label: commonMethods.getAbbrNum(Math.round(totalRecords), 2),
    description: 'Personal Data Records Found',
    tooltip: `Personal Data Records Found: ${Math.round(totalRecords)}`,
  });

  widgets.push({
    color: BigidColorsV2.red[900],
    label: riskScore,
    description: 'Query Risk Score',
  });

  return {
    title,
    breadcrumbs,
    rightSideComponentsContainer,
    titleHelperComponent,
    ...(shouldShowBackButton && { onBackButtonClick: onBackButtonClick(from) }),
    ...(shouldShowWidgets && { widgets }),
  };
};

export const getAccountInfo = (translations: Configurations): AccountMenuProps => {
  const userOrigin: string = sessionStorageService.get('userOrigin');
  const hideUpdatePassword = userOrigin && !USER_ORIGIN_SHOW_UPDATE_PASSWORD.includes(userOrigin);
  const isFollowObjectEnabled: boolean =
    !isMultiTenantModeEnabled() && getApplicationPreference('FOLLOW_OBJECT_ENABLED');
  const shouldAddDivider = !hideUpdatePassword || isFollowObjectEnabled;

  return {
    accountName: sessionStorageService.get('displayName'),
    menu: [
      ...(hideUpdatePassword
        ? []
        : [
            {
              label: translations['BIGID_HEADER:USER_MENU:UPDATE_PASSWORD'],
              onClick: () => $state.go('updatePassword'),
            },
          ]),
      ...(isFollowObjectEnabled
        ? [
            {
              label: translations['BIGID_HEADER:USER_MENU:MY_FOLLOWED_OBJECTS'],
              onClick: () => $state.go(CONFIG.states.CATALOG_FOLLOWED_OBJECTS),
            },
          ]
        : []),
      ...(shouldAddDivider ? [{ divider: true }] : []),
      { label: translations['BIGID_HEADER:USER_MENU:LOGOUT'], onClick: () => loginService.logout() },
    ],
  };
};

const getProductTypeTranslation = (toTranslate: string) => {
  const { translationPostfix } = getProductProperties();
  return translationPostfix ? `${toTranslate}${translationPostfix}` : toTranslate;
};

export const getProductName = (translations: Configurations): string => {
  if (licenseService.isSAPLicense()) {
    const sapProductName = translations['BIGID_HEADER:SAP_BRANDING:LABEL'];
    return getApplicationPreference('USE_SAAS') ? `${sapProductName}, cloud edition` : sapProductName;
  }
  return null;
};

const onTasksClick = () => {
  if (!licenseService.shouldBlockUserWithLicenseExpired()) {
    return $state.go('taskList');
  }
};

export const getHeaderActions = (unreadTasks: number, unreadNotifications: number): HeaderActionItem[] => {
  const { type, docsLink, supportLink } = getProductProperties();
  const isSAPProductType = type === LICENSE_TYPES.DDM;
  const isHelpAboutIconVisible = isPermitted(HELP_ABOUT_PERMISSIONS.ACCESS.name);
  const isTasksIconVisible = isPermitted(TASKS_PERMISSIONS.ACCESS.name);
  const isNewNavigationEnabled = getApplicationPreference('ENABLE_NEW_UX_NAVIGATION');

  const handleOpenNotifications = () => {
    analyticsService.trackManualEvent(BIGID_BI_EVENTS.USER_NOTIFICATION);
    getNewNotifications();
  };

  const documentationHelpMenuItem = {
    label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:DOCUMENTATION'),
    onClick: () => window.open(docsLink, '_blank'),
  };

  const supportHelpMenuItem = {
    label: $translate.instant(getProductTypeTranslation('BIGID_HEADER:SUPPORT_MENU:SUPPORT')),
    onClick: () => {
      analyticsService.trackManualEvent(BIGID_BI_EVENTS.SUPPORT_AND_CONFIGURATION);
      window.open(supportLink, '_blank');
    },
  };

  const feedbackHelpMenuItem = {
    label: 'Give Feedback',
    onClick: () => systemEventsEmitter.emit(SystemEvents.FEEDBACK_DIALOG),
  };

  const aboutHelpMenuItem = {
    label: $translate.instant('BIGID_HEADER:SUPPORT_MENU:ABOUT'),
    onClick: showAboutDialog({ isProductTypeVisible: isSAPProductType, supportLink, isSAPProductType }),
  };

  const navigationGuidedTourItem = {
    label: $translate.instant('BIGID_HEADER:USER_MENU:NAVIGATION_GUIDE'),
    onClick: () => headerEventEmitter.emit(HeaderEvents.SHOW_NAVIGATION_GUIDED_TOUR),
  };

  return [
    ...(!isMultiTenantModeEnabled() && isTasksIconVisible
      ? [
          {
            icon: BigidHeaderTasksIcon,
            onClick: onTasksClick,
            tooltip: 'Tasks',
            badge: { badgeContent: unreadTasks, max: 99 },
            dataAid: 'tasks',
          },
        ]
      : []),
    ...(isHelpAboutIconVisible
      ? [
          {
            icon: BigidHelpLargeIcon,
            tooltip: 'Help / About',
            dataAid: 'help',
            menu: [
              documentationHelpMenuItem,
              { divider: true },
              ...(!isMultiTenantModeEnabled() ? [supportHelpMenuItem, { divider: true }] : []),
              ...(isNewNavigationEnabled ? [navigationGuidedTourItem, { divider: true }] : []),
              ...(getApplicationPreference('SHOW_CUSTOMER_FEEDBACK') && !isMultiTenantModeEnabled()
                ? [feedbackHelpMenuItem, { divider: true }]
                : []),
              aboutHelpMenuItem,
            ],
          },
        ]
      : []),
    ...(getApplicationPreference('ENABLE_NOTIFICATIONS') && !isMultiTenantModeEnabled()
      ? [
          {
            icon: BigidNotificationIcon,
            tooltip: 'Notifications',
            notificationsConfiguration: {
              notifications: getNotifications(),
              onScroll: updateNotifications,
              onOpen: handleOpenNotifications,
              isLoading: false,
            },
            dataAid: 'notification',
            ...(unreadNotifications > 0 && {
              badge: { badgeContent: '', variant: 'dot', overlap: 'circular' } as Partial<BadgeProps>,
            }),
          },
        ]
      : []),

    ...(smallIdLicenseService.isSmallIDSmergerMode() && !isMultiTenantModeEnabled()
      ? [
          {
            icon: BigidApplicationsIcon,
            itemsDrawerConfiguration: {
              content: getSubscriptions(),
              displayBottomLink: true,
              onOpen: getNewSubscriptions,
              onScroll: () => noop,
            },
            dataAid: 'subscriptions',
            tooltip: 'Subscriptions',
          },
        ]
      : []),
  ];
};

export const getNumberOfUnreadTasks = async () => {
  const { data } = await httpService.fetch('tasks/count');
  return data?.total_unread_tasks;
};

export const getRiskScore = async () => {
  const { risk_trends } = await privacyRiskTrendService.getRiskTrendData();
  const globalRisk = Math.round(risk_trends[0].risk) || 0;
  sessionStorageService.set('globalRiskScore', globalRisk);
  return globalRisk;
};

export const getQueryRiskScore = async (query?: string) => {
  const { risk_trends = [] } = await privacyRiskTrendService.getRiskTrendData(query);
  const queryRiskScore = Math.round(risk_trends[0]?.risk);
  $timeout(() => $rootScope.$emit('getQueryRiskScore', queryRiskScore, query));
  headerEventEmitter.emit(HeaderEvents.SET_TOTAL_RECORDS_AND_RISK, [queryRiskScore, risk_trends[0]?.count]);
};
