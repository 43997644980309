import { BigidDialog, BigidDialogButtonType, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { getRegExpressionForTester } from './ClassifierTesterUtils';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { ClassifierTesterDialogContent, ClassifierTesterDialogContentProps } from './ClassifierTesterDialogContent';
import { Classifier } from '../../services/classifiersService';
import { $translate } from '../../services/angularServices';
import { ClassifierTesterResultsDto, ClassifierTesterValuesDto } from './ClassifierTesterTypes';

type OnSaveClicked = (classifierName: string, regexToUpdate: string, classifierPerformance: number) => void;

export interface BigidClassifierTesterDialogProps {
  isOpen: boolean;
  onClose: () => void;
  classifier: Classifier;
  onSave: OnSaveClicked;
}

export const BigidClassifierTesterDialog: FC<BigidClassifierTesterDialogProps> = ({
  isOpen,
  onClose,
  classifier,
  onSave,
}) => {
  const [isSaveModeDisabled, setSaveModeDisabled] = useState<boolean>(true);
  const [testerValues, setTesterValues] = useState<ClassifierTesterValuesDto>();
  const [testerResults, setTesterResults] = useState<ClassifierTesterResultsDto>();

  const handleTesterValues = (
    newValues: ClassifierTesterValuesDto | React.SetStateAction<ClassifierTesterValuesDto>,
  ) => {
    setTesterValues(newValues);
  };

  const handleTesterResults = (
    newValues: ClassifierTesterResultsDto | React.SetStateAction<ClassifierTesterResultsDto>,
  ) => {
    setTesterResults(newValues);
  };

  const initialSetup = (classificationRegex: string) => {
    setTesterValues({
      textToTest: '',
      regexToTest: classificationRegex,
      isRegexChanged: true,
    });
    setSaveModeDisabled(true);
    setTesterResults({
      contentText: '',
      isTestMode: false,
      performance: 0,
    });
  };
  const handleClose = () => {
    onClose();
  };

  const contentProps: ClassifierTesterDialogContentProps = {
    handleTesterResults,
    handleTesterValues,
    testerResults,
    classificationRegex: classifier.classification_regex,
    isOutOfTheBox: classifier.isOutOfTheBox,
    setSaveModeDisabled,
  };

  const buttons: BigidDialogButtonType[] = [
    {
      text: 'Cancel',
      component: SecondaryButton,
      dataAid: 'cancel-classifier-tester',
      onClick: () => {
        handleClose();
      },
      isClose: true,
      alignment: 'left',
    },
    {
      text: 'Test',
      component: SecondaryButton,
      dataAid: 'test-classifier-tester',
      onClick: (event: MouseEvent) => {
        try {
          if (!testerValues.regexToTest) {
            setTesterResults({
              ...testerResults,
              isTestMode: false,
              onErrorTesterValue: $translate.instant('VALIDATION:MESSAGE:REQUIRED'),
            });
          } else {
            let compiledPattern = testerValues.compiledRegexPattern;
            let pattern = testerValues.regexToTest;
            if (testerValues.isRegexChanged) {
              pattern = testerValues.regexToTest;
              compiledPattern = getRegExpressionForTester(testerValues.regexToTest);
              setSaveModeDisabled(true);
            }
            const matches = testerValues.textToTest.matchAll(compiledPattern);
            setTesterResults({
              ...testerResults,
              regexMatchesDetails: matches,
              regexPattern: pattern,
              contentText: testerValues.textToTest,
              isTestMode: true,
              onErrorTesterValue: '',
            });
            setTesterValues({
              ...testerValues,
              compiledRegexPattern: compiledPattern,
              isRegexChanged: false,
            });
          }
        } catch (error) {
          setTesterResults({
            ...testerResults,
            isTestMode: false,
            onErrorTesterValue: $translate.instant('CLASSIFIERS:TESTER:ERROR:PATTERN:MSG'),
          });
          setSaveModeDisabled(true);
          console.error(`An error has occurred: ${error}`);
        }
      },
      isClose: false,
    },
    {
      text: 'Save',
      component: PrimaryButton,
      dataAid: 'save-classifier-tester',
      onClick: (event: MouseEvent) => {
        onSave(classifier.classification_name, testerResults.regexPattern, testerResults.performance);
      },
      disabled: isSaveModeDisabled,
    },
  ];

  useEffect(() => {
    if (isOpen) {
      initialSetup(classifier.classification_regex);
    }
  }, [classifier.classification_regex, isOpen]);

  return (
    <BigidDialog
      title={`Test Classifier : ${classifier.classification_name}`}
      maxWidth="md"
      isOpen={isOpen}
      onClose={handleClose}
      buttons={buttons}
    >
      <ClassifierTesterDialogContent {...contentProps} />
    </BigidDialog>
  );
};

angular
  .module('app')
  .component(
    'bigidClassifierTesterDialog',
    convertToAngular<BigidClassifierTesterDialogProps>(BigidClassifierTesterDialog, [
      'isOpen',
      'onClose',
      'classifier',
      'onSave',
    ]),
  );
