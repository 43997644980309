import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BigidNavigation,
  BigidNavigationItemType,
  MenuSettings,
  NavigationEditActions,
  SideBarStatus,
  useSidebarNavigation,
} from '@bigid-ui/components';
import { $state, $transitions } from '../../services/angularServices';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
  CategoriesEnum,
} from '../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { getFavoriteApps } from '../Sidebar/SidebarUtils';
import { customAppService } from '../../services/customAppService';
import { getApplicationPreference } from '../../services/appPreferencesService';
import { CustomAppEvents, customAppEventsEmitter } from '../../services/customAppEvents';
import { EventEmitterDeregistrator } from '@bigid-ui/utils';
import {
  FAVORITES_LINK,
  getInitialFooterItems,
  getInitialHeaderItems,
  getInitialNavbarItems,
  PREFERENCES_NAME,
  reorderFavoritesItemsByUserSettings,
} from './navigationSidebarUtils';
import { CONFIG } from '../../../config/common';
import { notificationService } from '../../services/notificationService';
import { usePermissionNavigationItems } from './usePermissionNavigationItems';
import { getFavoritePages } from './favoritePagesManagementService.ts';
import { useFavoritesAggregated } from './hooks/useFavoritesAggregated';
import { getPopularApps } from './utils';
import { NavigationGuidedTour } from './NavigationGuidedTour/NavigationGuidedTour';

const LINKS_WITHOUT_REDIRECT = [
  'settingsTEMP',
  'dataManagementTEMP',
  'reportsTEMP',
  'dataExplorerTEMP',
  'favoritesLinkPlaceholder',
];

const CUSTOM_APP_ACTION_PAGE = 'customAppActionsPage';
export const CUSTOM_APP_PAGE = 'customApp';

export const NavigationSidebar: FC = () => {
  const [customApps, setCustomApps] = useState<any>([]);

  const onNavigationItemClicked = useCallback((link: string) => {
    $state.go(link);
  }, []);

  const updateCustomApplications = (): void => {
    customAppService.getCustomApps().then(({ data }) => {
      setCustomApps(data.map((item: any) => ({ ...item, category: CategoriesEnum.UTILITIES })));
    });
  };

  const initialNavigationItems: BigidNavigationItemType[] = useMemo(
    () => getInitialNavbarItems(onNavigationItemClicked),
    [onNavigationItemClicked],
  );

  const initialFooterItems: BigidNavigationItemType[] = useMemo(
    () => getInitialFooterItems(onNavigationItemClicked),
    [onNavigationItemClicked],
  );

  const initialHeaderItems: BigidNavigationItemType[] = useMemo(
    () => getInitialHeaderItems(onNavigationItemClicked),
    [onNavigationItemClicked],
  );

  const { navigationItemsPermitted, footerItemsPermitted, headerItemsPermitted } = usePermissionNavigationItems({
    navigationItems: initialNavigationItems,
    footerItems: initialFooterItems,
    headerItems: initialHeaderItems,
  });

  const {
    setSelectedItemLink,
    selectedItemLink,
    navigationItems,
    footerItems,
    favorites,
    defaultMenuSettings,
    dispatchMenuEditAction,
    headerItems,
    hoveredItem,
    saveSettingsRequired,
  } = useSidebarNavigation({
    selectedItemLink: CONFIG.states.DASHBOARD,
    navigationItems: navigationItemsPermitted,
    footerItems: footerItemsPermitted,
    headerItems: headerItemsPermitted,
  });
  const { favoritesRef, settingsRef, updateUserFavorites } = useFavoritesAggregated();

  useEffect(() => {
    const fetchDynamicData = async () => {
      const { data: { favorites = [] } = {} } =
        (await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME)) || {};
      const { data: { main = [], header = [] } = {} } =
        (await userPreferencesService.get<{ main: MenuSettings; header: MenuSettings }>(PREFERENCES_NAME)) || {};
      const favoriteMenuItems = getFavoriteApps(favorites, customApps, false);
      const { favoriteItems: favoritePages, favoritesOriginal } = await getFavoritePages(
        navigationItemsPermitted || [],
      );
      const favoritesUserOrder = header.find(({ id }) => id === FAVORITES_LINK)?.menuItems ?? [];
      const allFavoriteItems: Partial<BigidNavigationItemType | any>[] = [...favoriteMenuItems, ...favoritePages];

      favoritesRef.current = {
        apps: [...favorites, ...(customApps || [])],
        pages: favoritesOriginal,
      };
      settingsRef.current = {
        main,
        header,
      };
      const favItemsReOrdered = reorderFavoritesItemsByUserSettings(
        allFavoriteItems.map(({ onClick, title, icon, selectedStates, selectedItemStates, link }) => {
          const onItemClicked =
            onClick || (() => onNavigationItemClicked(selectedStates?.[0] || selectedItemStates?.[0]));
          return {
            title,
            icon,
            onItemClicked,
            isVisible: true,
            link: selectedStates?.[0] || selectedItemStates?.[0] || link,
            selectedItemStates: selectedStates?.[0] || selectedItemStates,
          };
        }),
        favoritesUserOrder,
      );

      dispatchMenuEditAction({
        type: NavigationEditActions.SET_FAVORITES,
        payload: favItemsReOrdered,
      });

      dispatchMenuEditAction({
        type: NavigationEditActions.SET_USER_SETTINGS,
        payload: main,
      });
    };

    fetchDynamicData();

    const destroyUpdateFavoritesListener = userPreferencesEventEmitter.addEventListener(
      UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES,
      fetchDynamicData,
    );

    const deregister = $transitions.onSuccess({}, transition => {
      const toState = transition.to().name;
      const { id } = transition.params();
      if (toState === CUSTOM_APP_ACTION_PAGE) {
        setSelectedItemLink(`${CUSTOM_APP_PAGE}/${id}/actions`);
      } else if (toState === CUSTOM_APP_PAGE) {
        setSelectedItemLink(`${CUSTOM_APP_PAGE}/${id}`);
      } else {
        setSelectedItemLink(toState);
      }
    });

    return () => {
      deregister();
      destroyUpdateFavoritesListener();
    };
  }, [
    customApps,
    dispatchMenuEditAction,
    favoritesRef,
    navigationItemsPermitted,
    onNavigationItemClicked,
    setSelectedItemLink,
    settingsRef,
  ]);

  useEffect(() => {
    dispatchMenuEditAction({
      type: NavigationEditActions.SET_HEADER_ITEMS,
      payload: headerItemsPermitted.map((item: BigidNavigationItemType) =>
        item.link === FAVORITES_LINK
          ? { ...item, quickViewSchema: { ...item.quickViewSchema, menuItems: favorites } }
          : item,
      ),
    });
  }, [dispatchMenuEditAction, favorites, headerItemsPermitted]);

  useEffect(() => {
    let customAppEventListener: EventEmitterDeregistrator = null;

    if (getApplicationPreference('SHOW_CUSTOM_APPS')) {
      updateCustomApplications();
      customAppEventListener = customAppEventsEmitter.addEventListener(
        CustomAppEvents.UPDATE_APP_LIST,
        updateCustomApplications,
      );
    }
    return () => {
      customAppEventListener?.();
    };
  }, []);

  useEffect(() => {
    const popularAppsItems: Partial<BigidNavigationItemType | any>[] = getPopularApps(customApps, true);
    dispatchMenuEditAction({
      type: NavigationEditActions.SET_FOOTER_ITEMS,
      payload: footerItemsPermitted.map((item: BigidNavigationItemType) =>
        item.link === CONFIG.states.APPLICATIONS_MANAGEMENT
          ? {
              ...item,
              quickViewSchema: {
                ...item.quickViewSchema,
                menuItems: popularAppsItems.map(({ onClick, title, icon, selectedStates, selectedItemStates }) => {
                  const onItemClicked = onClick || (() => onNavigationItemClicked(selectedStates?.[0]));
                  return {
                    title,
                    icon,
                    onItemClicked,
                    isVisible: true,
                    link: selectedStates?.[0] || selectedItemStates?.[0] || title,
                    selectedItemStates: selectedStates?.[0] || selectedItemStates?.[0] || [],
                  };
                }),
              },
            }
          : item,
      ),
    });
  }, [customApps, dispatchMenuEditAction, footerItemsPermitted, onNavigationItemClicked]);

  const TourComponent = useMemo(() => <NavigationGuidedTour />, []);

  return (
    <>
      <BigidNavigation
        initialStatus={SideBarStatus.SEMI_OPEN}
        navigationItems={navigationItems}
        footerItems={footerItems}
        selectedItemLink={selectedItemLink}
        headerItems={headerItems}
        onSaveSettings={async settingsNew => {
          try {
            const favoritesItem = settingsNew.find(({ id }) => id === FAVORITES_LINK);
            if (favoritesItem) {
              return updateUserFavorites(settingsNew);
            } else {
              await userPreferencesService.update({
                preference: PREFERENCES_NAME,
                data: {
                  main: settingsNew,
                  header: settingsRef.current.header,
                },
              });
              settingsRef.current = {
                ...settingsRef.current,
                main: settingsNew,
              };

              return true;
            }
          } catch (e) {
            notificationService.error('Failed to update user settings');
            return false;
          }
        }}
        defaultMenuSettings={defaultMenuSettings}
        dispatchMenuEditAction={dispatchMenuEditAction}
        hoveredItem={hoveredItem}
        saveSettingsRequired={saveSettingsRequired}
        linksWithoutRedirect={LINKS_WITHOUT_REDIRECT}
        dataBypass="bypass-sidebar"
      />
      {TourComponent}
    </>
  );
};

angular.module('app').component('navigationSidebar', convertToAngular(NavigationSidebar));
