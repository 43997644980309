import { analyticsService } from '../../../services/analyticsService';
import { BIGID_BI_EVENTS } from '../../../config/BigIdBiEvents';
import { MetadataSearchQuery } from '../MetadataSearchTypes';
import {
  MetadataSearchFieldFilter,
  MetadataSearchFilterFieldName,
  MetadataSearchFilterFieldType,
} from '../MetadataSearchFilters/MetadataSearchFiltersTypes';
import { pick } from 'lodash';

export type MetadataSearchBarSubmitBiPayload = {
  text: MetadataSearchQuery;
  filter: MetadataSearchFieldFilter[];
};

export type MetadataSearchBarFetchFiltersBiPayload = {
  text: MetadataSearchQuery;
  filter: MetadataSearchFieldFilter[];
  selectedField?: MetadataSearchFilterFieldName;
  selectedFieldType?: MetadataSearchFilterFieldType;
  selectedFieldValue?: string;
};

export function sendMetadataSearchBarSubmitBiAnalytics(biPayload: MetadataSearchBarSubmitBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.METADATA_SEARCH_BAR_SUBMIT, biPayload);
}

export function sendMetadataSearchBarFetchFiltersBiAnalytics(biPayload: MetadataSearchBarFetchFiltersBiPayload): void {
  analyticsService.trackManualEvent(BIGID_BI_EVENTS.METADATA_SEARCH_BAR_SUBMIT, {
    text: biPayload.text,
    filter: biPayload.filter.map(filterObj => {
      return pick(filterObj, ['field', 'fieldDisplayName', 'value']);
    }),
  });
}
