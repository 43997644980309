import { BigidColorsV2 } from '@bigid-ui/components';
import { GlossaryRecordStatus } from '../types';

export const statusToColorMap: Record<GlossaryRecordStatus, string> = {
  draft: BigidColorsV2.purple[500],
  underReview: BigidColorsV2.yellow[900],
  approved: BigidColorsV2.green[700],
  pendingAttestation: BigidColorsV2.blue[200],
  rejected: BigidColorsV2.red[600],
  sentForApproval: BigidColorsV2.green[500],
};
