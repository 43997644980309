import React, { FC, useState, useEffect, ReactText, Dispatch, SetStateAction } from 'react';
import { styled } from '@mui/material';
import { generateDataAid } from '@bigid-ui/utils';
import { FieldValueChunk, FindingCurateProps, MatchType } from '../../curationService';
import { CuratedFieldValuePreviewHeader } from './CuratedFieldValuePreviewHeader';
import {
  CuratedFieldValuePreviewContent,
  CuratedFieldValuePreviewContentProps,
} from './CuratedFieldValuePreviewContent';

export interface CuratedFieldValuePreviewProps
  extends Pick<CuratedFieldValuePreviewContentProps, 'isPermittedToExclude' | 'onFindingCurate'> {
  dataAid?: string;
  valueChunks: FieldValueChunk[];
  fieldId?: ReactText;
  dispatchGridReload?: Dispatch<SetStateAction<any[]>>;
}

const AttributePreviewContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AttributePreviewContent = styled('div')`
  padding: 8px;
  flex: 1;
  overflow-y: auto;
`;

export enum ChangeChunkAction {
  NEXT = 'next',
  PREV = 'prev',
}

export const CuratedFieldValuePreview: FC<CuratedFieldValuePreviewProps> = ({
  dataAid = 'CuratedFieldValuePreview',
  valueChunks = [],
  isPermittedToExclude,
  onFindingCurate,
  fieldId,
  dispatchGridReload,
}) => {
  const [chunkIndex, setChunkIndex] = useState<number>(0);

  const handleIndexChange = (action: ChangeChunkAction): void => {
    switch (action) {
      case ChangeChunkAction.NEXT:
        setChunkIndex(prevChunkIndex => (prevChunkIndex < valueChunks.length - 1 ? prevChunkIndex + 1 : 0));
        break;
      case ChangeChunkAction.PREV:
        setChunkIndex(prevChunkIndex => (prevChunkIndex > 0 ? prevChunkIndex - 1 : 0));
        break;
    }
  };

  const handleFindingCurate = (findingCurateProps: FindingCurateProps): void => {
    const findingCurate = { ...findingCurateProps, matchType: findingCurateProps.matchType || MatchType.EXACT };
    onFindingCurate(findingCurate);
  };

  useEffect(() => {
    setChunkIndex(0);
  }, [valueChunks]);

  const currentChunk = valueChunks[chunkIndex];

  return (
    <AttributePreviewContainer data-aid={dataAid}>
      <CuratedFieldValuePreviewHeader
        dataAid={generateDataAid(dataAid, ['header'])}
        onIndexChange={handleIndexChange}
        chunksCount={valueChunks.length}
        currentIndex={chunkIndex}
      />
      <AttributePreviewContent data-aid={generateDataAid(dataAid, ['content-container'])}>
        <CuratedFieldValuePreviewContent
          dataAid={generateDataAid(dataAid, ['content'])}
          content={currentChunk.chunkValue}
          curatedFindingDetails={currentChunk.findingDetails}
          isPermittedToExclude={isPermittedToExclude}
          onFindingCurate={handleFindingCurate}
          fieldId={fieldId}
          dispatchGridReload={dispatchGridReload}
        />
      </AttributePreviewContent>
    </AttributePreviewContainer>
  );
};
