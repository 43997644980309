import React, { FC, useContext } from 'react';
import { BigidTooltip, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { CreateScanWizardContext } from '../../createScanContext';

const info = 'Select a Correlation template from the list below or go back to select data sources';
const prefix = 'scan-create-wizard-create-template';

export const CreateTemplateButton: FC = () => {
  const { scanWizardState, setScanWizardState, onChangeToCreateTemplate } = useContext(CreateScanWizardContext);
  const onCreateHandle = () => {
    setScanWizardState({ ...scanWizardState, isTemplateCreate: true });
    onChangeToCreateTemplate();
  };
  const disabled = !scanWizardState.allEnabledDs && scanWizardState?.dataSourcesIds?.length === 0;
  return (
    <BigidTooltip title={info} placement="bottom" isDisabled={!disabled} dataAid={`${prefix}-tooltip`}>
      <span>
        <SecondaryButton
          size={'medium'}
          onClick={onCreateHandle}
          dataAid={`${prefix}-button`}
          disabled={disabled}
          text="Create Template"
        />
      </span>
    </BigidTooltip>
  );
};
