import React from 'react';
import { Footer } from './DataSourceConnectionModalStyles';
import type { BigidDialogButtonType as BigidButtonType } from '@bigid-ui/components';

type DataSourceConnectionModalButton = BigidButtonType & { hidden?: boolean };

type DataSourceConnectionModalFooterProps = {
  buttons: DataSourceConnectionModalButton[];
};

export const DataSourceConnectionModalFooter = ({ buttons }: DataSourceConnectionModalFooterProps): JSX.Element => {
  return (
    <Footer>
      {buttons.map(
        ({ component: Button, hidden: isHidden = false, dataAid, ...rest }) =>
          !isHidden && <Button bi={{ disabled: true }} key={dataAid} dataAid={dataAid} size="large" {...rest} />,
      )}
    </Footer>
  );
};
