import { showSaveDialog, SaveDialogResult } from '../../../../services/saveDialogService';
import { useCallback, useRef } from 'react';

export interface OnCloseDataSourceConfigOptions {
  onSave: () => void;
  onNotSave: () => void;
  onClose: () => void;
  isError: boolean;
}

export const useOnCloseHandler = () => {
  const isModalOpened = useRef(false);

  const onCloseDSConfigPage = useCallback(({ onSave, onNotSave, onClose, isError }: OnCloseDataSourceConfigOptions) => {
    if (isModalOpened.current) return;

    isModalOpened.current = true;
    showSaveDialog({
      entityName: 'Data Source',
      isError,
      onClose: () => {
        onClose();
        isModalOpened.current = false;
      },
    })
      .then(save => {
        if (save === SaveDialogResult.cancel) return;

        if (save === SaveDialogResult.save) {
          onSave();
        } else {
          onNotSave();
        }
      })
      .finally(() => {
        isModalOpened.current = false;
      });
  }, []);

  return onCloseDSConfigPage;
};
