import React, { SyntheticEvent, useState, useEffect } from 'react';
import { Aside } from './mappers/styles';
import { DataSourceConnectionErrorInsights } from './DataSourceConnectionErrorInsights';
import { DataSourceConnectionGuide } from './DataSourceConnectionGuide/DataSourceConnectionGuide';
import { mapTestConnectionResolutionStepsToOptions } from './mappers/connection';
import { DataSourcesUITrackingEvent, trackEventDataSources } from '../DataSourcesEventTrackerUtils';
import { DsConnectionDocItemResource, DsConnectionDocPreConnectionType as PreConnectionType } from './types';
import { useDataSourceModalContext } from './hooks/useDataSourceModalContext';
import type { TestConnectionErrorResolution } from './hooks/useTestConnection';
import type { GetHighlightedWordsForLogType } from '../DataSourceConfiguration/utils/scannerLogsUtils';

type DataSourceConnectionModalAsideProps = {
  documentationUrl: string;
  loading?: boolean;
  content: DsConnectionDocItemResource[];
  logs: GetHighlightedWordsForLogType;
  errorInsights: TestConnectionErrorResolution;
  authType: string;
  onCollaborate: () => void;
};

export const DataSourceConnectionModalAside = ({
  documentationUrl,
  content,
  logs,
  loading: isContentLoading,
  errorInsights,
  authType,
  onCollaborate,
}: DataSourceConnectionModalAsideProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState(true);
  const [isHowToConnectTabExpanded, setHowToConnectTabExpanded] = useState(true);
  const { configDataSourceState } = useDataSourceModalContext();

  const { resolutionSteps, summarizedErrorMessage, detailedErrorMessage } = errorInsights ?? {};
  const options = mapTestConnectionResolutionStepsToOptions(resolutionSteps);
  const tabOverrides = { [PreConnectionType.HOW_TO_CONNECT]: { isOpen: isHowToConnectTabExpanded } };

  const isErrorInsightsVisible = !!errorInsights;

  const handleShowDocumentation = () => {
    if (documentationUrl) {
      trackEventDataSources(DataSourcesUITrackingEvent.DATA_SOURCES_NEW_CONNECTION_SEE_DOCS_CLICK, {
        dsType: configDataSourceState?.selectedType,
      });
      window.open(documentationUrl);
    }
  };

  const handleConnectionGuideTabChange = (_: SyntheticEvent, expanded: boolean) => setExpanded(expanded);

  useEffect(() => {
    isErrorInsightsVisible && setExpanded(false);
  }, [isErrorInsightsVisible]);

  useEffect(() => {
    setHowToConnectTabExpanded(true);
  }, [authType]);

  return (
    <Aside.Wrapper>
      <Aside.Content>
        <>
          <DataSourceConnectionGuide
            expanded={!isErrorInsightsVisible ? true : isExpanded}
            overrides={tabOverrides}
            showExpandIcon={isErrorInsightsVisible}
            loading={isContentLoading}
            content={content}
            onShowDocumentation={handleShowDocumentation}
            onChange={handleConnectionGuideTabChange}
            onCollaborate={onCollaborate}
          />
          {isErrorInsightsVisible && (
            <DataSourceConnectionErrorInsights
              logs={logs}
              title={summarizedErrorMessage ?? detailedErrorMessage}
              options={options ?? []}
              resolution={errorInsights}
              authType={authType}
            />
          )}
        </>
      </Aside.Content>
    </Aside.Wrapper>
  );
};
