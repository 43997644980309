import React, { useContext, useEffect } from 'react';
import { BigidFormStateAndHandlers } from '@bigid-ui/components';
import { AutoDiscoveryWizardContext } from '../AutoDiscoveryWizard/autoDiscoveryWizardContext';

const EMPTY_ERRORS_STATE = {};

export const useCheckPermissionsErrors = (
  formControlRef: React.MutableRefObject<Partial<BigidFormStateAndHandlers>>,
) => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);

  useEffect(() => {
    if (!formControlRef?.current?.setErrors) {
      return;
    }

    const fieldsErrors = discoveryConfigData?.errorMessageKey
      ? discoveryConfigData.fieldsNamesByAuthTypeFields?.reduce((acc, fieldName) => {
          return {
            ...acc,
            [fieldName]: fieldName !== 'name' && true,
          };
        }, EMPTY_ERRORS_STATE)
      : EMPTY_ERRORS_STATE;

    formControlRef.current.setErrors(fieldsErrors ?? EMPTY_ERRORS_STATE);
  }, [
    discoveryConfigData?.connectionInfo,
    discoveryConfigData?.errorMessageKey,
    discoveryConfigData.fieldsNamesByAuthTypeFields,
    formControlRef,
  ]);
};
