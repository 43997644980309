import React, { FC, useState, useRef, MouseEvent, useContext } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  BigidBody1,
  BigidFormStateAndHandlers,
  BigidForm,
  BigidFormValidateOnTypes,
  BigidFormField,
  BigidFormFieldTypes,
  BigidFormValues,
  BigidFormFieldLabelPosition,
  BigidLoader,
} from '@bigid-ui/components';
import { BigidConnectionIcon } from '@bigid-ui/icons';
import styled from '@emotion/styled';
import { useLocalTranslation } from '../../../../../translations';
import { generateDataAid } from '@bigid-ui/utils';
import {
  getConfigurationInitialFormValue,
  getConfigurationTypeOptions,
  getFormKey,
  getServiceFormatFromForm,
} from '../../../../../../ActionCenter/ConfigurationManagement/configurationManagementUtils';
import { ALLOWED_NAME_REGEX, TRAILING_SPACES, TRAILING_SPACES_MESSAGE } from '../../../../../../../config/consts';
import {
  ConfigurationFormFields,
  FieldType,
  fieldTypeToFormFieldType,
} from '../../../../../../ActionCenter/ConfigurationManagement/configurationManagementTypes';
import {
  createConfiguration,
  testConnectionConfiguration,
} from '../../../../../../ActionCenter/ConfigurationManagement/configurationManagementService';
import { notificationService } from '../../../../../../../services/notificationService';
import { isDuplicationError } from '../../../../../../ActionCenter/ActionWorkflow/actionWorkflowUtils';
import { JIRA_CONFIGURATION_NAME } from '../../../../../actionableInsightsService';
import {
  ActionableInsightsTrackingEvents,
  trackActionableInsightsEvent,
} from '../../../../../actionableInsightsTrackingUtil';
import { JiraContentTypes } from '../JiraModal';
import { ActionableInsightsContext } from '../../../../../hooks/ActionableInsightsContext';
import { ReducerActions } from '../../../../../hooks/ActionableInsightsReducer';

export enum JiraConfigurationFields {
  HOST = 'host',
  PROJECT_NAME = 'project',
}

const MainContainer = styled.div({
  height: 750,
  display: 'flex',
  flexDirection: 'column',
});

const TextAndIconContainer = styled.div({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingBottom: 8,
  marginTop: 'auto',
  '& > :first-child': {
    marginLeft: 8,
  },
});

const TestConnectionTextContainer = styled.div({
  paddingLeft: 6,
});

export interface JiraConfigurationProps {
  updateModalContent: React.Dispatch<React.SetStateAction<JiraContentTypes>>;
  closeModal: () => void;
}

export const JiraConfiguration: FC<JiraConfigurationProps> = ({ closeModal, updateModalContent }) => {
  const { jiraConfigurationsMetadata, jiraConfigurations, viewType, dispatch } = useContext(ActionableInsightsContext);
  const [isCreateEnabled, setIsCreateEnabled] = useState(false);
  const formControls = useRef<BigidFormStateAndHandlers>();
  const { t } = useLocalTranslation('Action');
  const [isFormLoading, setIsFormLoading] = useState(false);

  const createFormFields = (): BigidFormField[] => {
    const fieldsArray: BigidFormField[] = [];
    fieldsArray.push({
      type: BigidFormFieldTypes.TEXT,
      name: 'name',
      label: t('label.configurationName'),
      isRequired: true,
      misc: {
        fullWidth: true,
        placeholder: t('placeHolder.jiraConfigurationName'),
      },
      validate: (value: string) => {
        if (!value) {
          return t('validation.jiraConfigurationName');
        }
        if (!ALLOWED_NAME_REGEX.test(value)) {
          return t('validation.specialCharacters');
        }
        if (TRAILING_SPACES.test(value)) {
          return TRAILING_SPACES_MESSAGE;
        }
      },
    });
    if (jiraConfigurationsMetadata.length) {
      jiraConfigurationsMetadata[0].fields.forEach(field => {
        fieldsArray.push({
          name: getFormKey(JIRA_CONFIGURATION_NAME, field?.name),
          type: fieldTypeToFormFieldType[field?.type],
          label: field?.displayName,
          isRequired: field?.required ? field?.required : false,
          tooltipText: field?.description,
          options: field?.options,
          validateOn: BigidFormValidateOnTypes.SUBMIT,
          labelPosition: field?.type === FieldType.CHECKBOX ? BigidFormFieldLabelPosition.left : null,
          validate: field?.required
            ? (value: string) => {
                if (!value) {
                  return getFieldValidationErrorString(field?.name);
                }
              }
            : null,
        });
      });
    }
    return fieldsArray;
  };

  const getFieldValidationErrorString = (fieldName: string) => {
    switch (fieldName?.toLowerCase()) {
      case JiraConfigurationFields.HOST:
        return t('validation.jiraHost');
      case JiraConfigurationFields.PROJECT_NAME:
        return t('validation.jiraProjectName');
      default:
        return t('validation.emptyField');
    }
  };

  const closeAndTrackEvent = () => {
    trackActionableInsightsEvent(
      ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CANCEL_CLICK,
      { VIEW_TYPE: viewType },
    );
    if (jiraConfigurations?.length) {
      updateModalContent(JiraContentTypes.TICKET_CREATION);
    } else {
      closeModal();
    }
  };

  const handleTestConnection = async () => {
    try {
      setIsFormLoading(true);
      trackActionableInsightsEvent(
        ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_CLICK,
        { VIEW_TYPE: viewType },
      );
      await formControls?.current?.validate();
      jiraConfigurationsMetadata.map(({ type, displayName }) => ({
        label: displayName,
        value: type,
      }));
      const formValues = formControls.current.getValues() as ConfigurationFormFields;
      const testConfiguration = getServiceFormatFromForm(formValues, jiraConfigurationsMetadata);
      const { success, error } = await testConnectionConfiguration(testConfiguration);

      if (!success) {
        throw new Error(error);
      }
      notificationService.success(t('common.notifications.jiraConnectionSuccessful'));
      success && setIsCreateEnabled(true);
    } catch (error) {
      setIsCreateEnabled(false);
      trackActionableInsightsEvent(
        ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_TEST_CONNECTION_FAILED,
        { VIEW_TYPE: viewType },
      );
      const notificationMessage = error?.message.includes('code 400')
        ? t('common.errors.missingConfigurationName')
        : `${error?.message}.`;
      showNotificationIfErrorIsNotMissingMandatoryFields(notificationMessage);
    } finally {
      setIsFormLoading(false);
    }
  };

  const showNotificationIfErrorIsNotMissingMandatoryFields = (notificationMessage: string) => {
    if (
      !notificationMessage?.toLowerCase()?.includes('missing') &&
      !notificationMessage?.toLowerCase()?.includes('uri')
    ) {
      notificationService.error(t('common.errors.jiraTestConnection', { notificationMessage }));
    }
  };

  const isCreateButtonDisabled = !isCreateEnabled || isFormLoading;

  const handleFormSubmit = async (values: BigidFormValues) => {
    try {
      setIsFormLoading(true);
      trackActionableInsightsEvent(
        ActionableInsightsTrackingEvents.DATA_RISK_MANAGEMENT_JIRA_CONFIGURATION_CREATE_CLICK,
        { VIEW_TYPE: viewType },
      );
      values.type = getConfigurationTypeOptions(jiraConfigurationsMetadata);
      const configurationToUpdate = getServiceFormatFromForm(values, jiraConfigurationsMetadata);
      const {
        data: {
          data: { id: newConfigurationId },
        },
      } = await createConfiguration(configurationToUpdate);
      dispatch({
        type: ReducerActions.UPDATE_ACTIONABLE_INSIGHTS_DATA,
        payload: {
          jiraConfigurations: [...jiraConfigurations, { name: configurationToUpdate.name, id: newConfigurationId }],
        },
      });
      updateModalContent(JiraContentTypes.TICKET_CREATION);
      notificationService.success(t('common.notifications.jiraConfigurationSavedSuccessfully'));
    } catch (error) {
      console.error(`An error has occurred: ${error}`);
      const message = isDuplicationError(error)
        ? t('common.errors.jiraConfigurationNameAlreadyExist')
        : t('common.errors.jiraCreateConfigurationError');
      notificationService.error(message);
    }
  };

  const onCreateButtonClick = async (event: MouseEvent) => {
    formControls?.current?.validateAndSubmit(() => {
      formControls?.current?.submit(event);
    });
  };

  return (
    <MainContainer data-aid={generateDataAid('JiraConfiguration', ['main-container'])}>
      <BigidBody1>{t('body.configureJiraInfo')}</BigidBody1>
      <BigidForm
        controlButtons={false}
        fields={createFormFields()}
        initialValues={getConfigurationInitialFormValue(jiraConfigurationsMetadata)}
        stateAndHandlersRef={formControls}
        onSubmit={handleFormSubmit}
        validateOn={BigidFormValidateOnTypes.SUBMIT}
      />
      <ButtonsContainer>
        <PrimaryButton
          size="medium"
          text={t('button.create')}
          onClick={onCreateButtonClick}
          disabled={isCreateButtonDisabled}
          dataAid={generateDataAid('createConfiguration', ['button'])}
        />
        <SecondaryButton
          size="medium"
          text={t('button.cancel')}
          onClick={closeAndTrackEvent}
          disabled={isFormLoading}
          dataAid={generateDataAid('cancelCreateConfiguration', ['button'])}
        />
        {isFormLoading ? (
          <BigidLoader />
        ) : (
          <TextAndIconContainer
            data-aid={generateDataAid('JiraConfiguration', ['test-connection'])}
            onClick={handleTestConnection}
          >
            <BigidConnectionIcon />
            <TestConnectionTextContainer>
              <BigidBody1>{t('body.testConnection')}</BigidBody1>
            </TestConnectionTextContainer>
          </TextAndIconContainer>
        )}
      </ButtonsContainer>
    </MainContainer>
  );
};
