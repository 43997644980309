import React, { SyntheticEvent } from 'react';
import { Guide } from '../mappers/styles';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionSummary,
  BigidAccordionDetails,
  BigidLink,
  BigidColorsV2,
  BigidHeading6,
  BigidHeading4,
  BigidBody1,
} from '@bigid-ui/components';
import { BigidBookIcon } from '@bigid-ui/icons';
import { OpenInNew } from '@mui/icons-material';
import { useLocalTranslation } from '../translations';
import { DsConnectionDocItemResource, DsConnectionDocPreConnectionType as PreConnectionType } from '../types';
import { decodeContent } from '../mappers/markdown';
import { DataSourceConnectionGuideLoader } from './DataSourceConnectionGuideLoader';
import { DataSourceConnectionGuideContent } from './DataSourceConnectionGuideContent';
import { DataSourceConnectionGuideNoContent } from './DataSourceConnectionGuideNoContent';
import { useDataSourceModalContext } from '../hooks/useDataSourceModalContext';

type DataSourceConnectionGuideProps = {
  dataAid?: string;
  content: DsConnectionDocItemResource[];
  expanded?: boolean;
  loading?: boolean;
  showExpandIcon: boolean;
  overrides?: { [key in PreConnectionType]?: { isOpen?: boolean } };
  onChange?: (event: SyntheticEvent, expanded: boolean) => void;
  onShowDocumentation: () => void;
  onCollaborate: () => void;
};

const style = {
  backgroundColor: BigidColorsV2.gray[100],
  width: '100%',
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
};

const accordionStyle = {
  gap: '0px',
  boxShadow: 'none',
  borderRadius: '8px !important',
  border: `1px solid ${BigidColorsV2.gray[150]}`,
};

const accordionDetailsStyle = {
  padding: '12px 20px 20px !important',
};

export const DataSourceConnectionGuide = ({
  dataAid = 'DataSourceConnectionGuide',
  content,
  expanded = false,
  loading: isLoading = false,
  showExpandIcon,
  overrides,
  onChange,
  onShowDocumentation,
  onCollaborate,
}: DataSourceConnectionGuideProps): JSX.Element => {
  const { t } = useLocalTranslation();
  const { isDsCollaborator } = useDataSourceModalContext();

  const getTabOverrides = (type: PreConnectionType) => overrides?.[type] ?? {};

  const config = [
    {
      id: PreConnectionType.PREREQUISITES,
      title: t('connectionGuide.prerequisites'),
      content: decodeContent(PreConnectionType.PREREQUISITES, content),
      ...getTabOverrides(PreConnectionType.PREREQUISITES),
    },
    {
      id: PreConnectionType.HOW_TO_CONNECT,
      title: t('connectionGuide.howToConnect'),
      content: decodeContent(PreConnectionType.HOW_TO_CONNECT, content),
      ...getTabOverrides(PreConnectionType.HOW_TO_CONNECT),
    },
  ];

  const isContentAvailable = !isLoading && config.some(({ content }) => !!content);
  const isDocumentationVisible = isContentAvailable && !isDsCollaborator;

  return (
    <Guide.Wrapper showExpandIcon={showExpandIcon}>
      <BigidAccordion
        dataAid={dataAid}
        sx={{ ...style, ...accordionStyle }}
        onChange={(...props) => onChange?.(...props)}
        expanded={expanded}
      >
        <BigidAccordionSummary
          sx={{ '& .MuiAccordionSummary-content': { marginLeft: '4px !important' } }}
          size={AccordionSummarySizeEnum.large}
        >
          <BigidHeading6 sx={{ width: '100%' }}>
            <Guide.Title>
              <BigidBookIcon style={{ padding: '3px' }} />
              <BigidHeading4>{t('connectionGuide.header')}</BigidHeading4>
              {isDocumentationVisible && (
                <BigidBody1 sx={{ marginLeft: 'auto', '& a': { color: BigidColorsV2.blue[900] } }}>
                  <BigidLink onClick={onShowDocumentation} underline="none" text={t('connectionGuide.link2')} />
                  <Guide.Image>
                    <OpenInNew color="inherit" fontSize="inherit" />
                  </Guide.Image>
                </BigidBody1>
              )}
            </Guide.Title>
          </BigidHeading6>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={{ ...style, ...accordionDetailsStyle }}>
          {isLoading ? (
            <DataSourceConnectionGuideLoader />
          ) : isContentAvailable ? (
            <DataSourceConnectionGuideContent onCollaborate={onCollaborate} tabs={config} />
          ) : (
            <DataSourceConnectionGuideNoContent
              onCollaborate={onCollaborate}
              onShowDocumentation={onShowDocumentation}
            />
          )}
        </BigidAccordionDetails>
      </BigidAccordion>
    </Guide.Wrapper>
  );
};
