import React, { FC, useState, useEffect, useMemo, Fragment } from 'react';
import classnames from 'classnames';
import { map, startCase, remove, isEmpty, reduce, flatten } from 'lodash';
import { BigidColors } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';

import { PermissionsAction } from './PermissionsAction';
import {
  ACCESS_MANAGEMENT_PERMISSIONS,
  PermissionActions,
  PermissionModules,
  APPLICATIONS_PERMISSIONS,
} from '@bigid/permissions';
import { BigidCheckTool } from '../../../../components/BigidCheckTool/BigidCheckTool';
import { isPermitted } from '../../../../services/userPermissionsService';
import { InfoHelper } from './InfoHelper';
import { BigidCollapsable } from '../../../../components/BigidCollapsable/BigidCollapsable';
import {
  getCustomDependedPermissions,
  getCustomPermissionsDependency,
  shouldDisablePermissionOfCustomScenario,
  isTpaReadPermission,
  isTpaManagePermission,
} from './customPermissionsDependencyUtils';

interface BigidPermission {
  label: string;
  description?: string;
  action: PermissionActions | string;
  module: PermissionModules;
  category?: string;
  isConfigurableInUI?: boolean;
  name: string;
}

export interface ModulePermissions {
  modulePermissions: BigidPermission[];

  [key: string]: BigidPermission[];
}

interface PermissionsCollapsableProps {
  module: string;
  permissions: ModulePermissions;
  onCheckPermission: (
    permissions: string[],
  ) => (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
  isPermissionSelected: (permission: string) => boolean;
  isSystemRole: boolean;
  roleName: string;
  hideCheckTool?: boolean;
}

const useStyles = makeStyles({
  root: { border: `1px solid ${BigidColors.gray[100]}`, borderRadius: 4, marginBottom: 16 },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${BigidColors.gray[100]}`,
  },
  leftHeader: { display: 'flex', alignItems: 'center' },
  module: { color: BigidColors.gray[700] },
  action: { fontSize: '0.8125rem' },
  collapseIcon: { display: 'flex', cursor: 'pointer', padding: '18px 12px 18px 11px' },
  wrapperInner: { display: 'flex', flexDirection: 'column' },
  moduleActionWrapper: { display: 'flex', padding: '20px 20px 0 20px', flexWrap: 'wrap' },
  bottomPadding: { paddingBottom: 20 },
  categoriesWrapper: { paddingTop: 20 },
  categoriesWrapperWithModulePermissions: { paddingTop: 8 },
  categoryWrapper: { display: 'flex', padding: '0 20px 8px 20px', flexDirection: 'column' },
  actionsWrapper: { display: 'flex', flexWrap: 'wrap' },
  category: {
    fontSize: '0.875rem',
    color: BigidColors.gray[700],
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
  },
  checkTools: { marginLeft: 20 },
});

const DISABLED_TOOLTIP = 'This permission must be selected in order to select other permissions';
const DISABLED_CATEGORY_TOOLTIP = `This permission must be selected in order to select other permissions in this category`;

const moduleDescriptions: Record<string, string> = {
  accessManagement: 'Users with access can only see users and roles that are part of their scope',
  clusterAnalysis: 'To be able to preview files, select the relevant permission under "Catalog" section',
};

const categoryDescriptions: Record<string, string> = {
  manual_fields: 'Define attributes manually',
  tags: 'Define tags manually',
};

const isSomeCategoryPermissionSelected = (
  permissionsByCategory: { [key: string]: BigidPermission[] },
  isSelected: (permission: string) => boolean,
): boolean => {
  const permissionsByCategoryFlat = reduce(
    permissionsByCategory,
    (acc, permissions) => {
      acc = [...acc, ...permissions.map(({ name }) => name)];
      return acc;
    },
    [],
  );
  return permissionsByCategoryFlat.some(permission => isSelected(permission));
};

export const PermissionsCollapsable: FC<PermissionsCollapsableProps> = ({
  module,
  permissions,
  onCheckPermission,
  isPermissionSelected,
  isSystemRole,
  roleName,
  hideCheckTool = false,
}) => {
  const classes = useStyles({});
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  useEffect(() => {
    setIsCollapseOpen(false);
  }, [roleName]);

  const { modulePermissions = [], ...permissionsByCategory } = permissions;
  const modulePermissionsClone = [...modulePermissions];

  const access = remove(modulePermissionsClone, ({ action }) => action === PermissionActions.ACCESS)?.[0];
  const moduleRead = modulePermissions.find(({ action }) => action === PermissionActions.READ)?.name;
  const categoryReads = useMemo(
    () =>
      reduce(
        permissionsByCategory,
        (acc, permissions) => {
          acc = [
            ...acc,
            ...permissions.filter(({ action }) => action === PermissionActions.READ).map(({ name }) => name),
          ];
          return acc;
        },
        [],
      ),
    [permissionsByCategory],
  );

  const isAccessSelected = access && isPermissionSelected(access.name);
  const isModulePermissionsSelected =
    modulePermissionsClone.filter(({ name, action }) => action !== PermissionActions.READ && isPermissionSelected(name))
      .length > 0;
  const isCategoryPermissionSelected = isSomeCategoryPermissionSelected(permissionsByCategory, isPermissionSelected);
  const categoryReadsSelected = categoryReads.filter(permission => isPermissionSelected(permission));

  const allPermissions = useMemo(() => {
    return reduce(permissions, (all, category) => [...all, ...category.map(({ name }) => name)], []);
  }, [permissions]);

  const onCheckAll = () => {
    onCheckPermission(allPermissions)(null, true);
  };
  const onCheckNone = () => {
    onCheckPermission(allPermissions)(null, false);
  };

  const checkIfExternalApplicationPermissionsExist = (permission: string) => {
    return (
      (isTpaReadPermission(permission) && isPermissionSelected(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name)) ||
      (isTpaManagePermission(permission) && isPermissionSelected(APPLICATIONS_PERMISSIONS.MANAGE_TPA_CUSTOM_APPS.name))
    );
  };

  const isAllPermissionsSelected = allPermissions.every(permission => isPermissionSelected(permission));
  const isNoPermissionsSelected = allPermissions.every(permission => !isPermissionSelected(permission));
  const isManagePermitted = isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.MANAGE.name);
  const shouldShowPermissionsCheckTool = !isSystemRole && isManagePermitted;
  const shouldDisableAction = !isManagePermitted || isSystemRole;

  const customPermissionsDependency = getCustomPermissionsDependency(allPermissions);
  const isAccessDisabled = access
    ? shouldDisableAction ||
      shouldDisablePermissionOfCustomScenario(access.name, customPermissionsDependency, isPermissionSelected)
    : false;
  const leftHeader = (
    <Fragment>
      <div className={classes.module}>{startCase(module)}</div>
      {moduleDescriptions[module] && <InfoHelper tooltip={moduleDescriptions[module]} />}
      {shouldShowPermissionsCheckTool && (
        <div className={classes.checkTools}>
          {!hideCheckTool && (
            <BigidCheckTool
              onCheckAll={onCheckAll}
              onCheckNone={onCheckNone}
              disabled={{ all: isAllPermissionsSelected, none: isNoPermissionsSelected }}
              dataAid={module}
            />
          )}
        </div>
      )}
    </Fragment>
  );

  const rightHeader = access && (
    <PermissionsAction
      checked={isAccessSelected}
      onChange={onCheckPermission([
        access.name,
        ...(!isPermissionSelected(access.name) ? (moduleRead ? [moduleRead] : categoryReads) : []),
      ])}
      title="Visible in UI"
      disabled={isAccessDisabled}
      marginBottom={false}
    />
  );

  return (
    <BigidCollapsable
      dataAid={module}
      leftHeader={leftHeader}
      rightHeader={rightHeader}
      isOpen={isCollapseOpen}
      onCollapseClick={() => setIsCollapseOpen(!isCollapseOpen)}
    >
      {modulePermissionsClone.length > 0 && (
        <div
          className={classnames(classes.moduleActionWrapper, isEmpty(permissionsByCategory) && classes.bottomPadding)}
        >
          {map(modulePermissionsClone, ({ label, name, action, description }) => {
            const isRead = action === PermissionActions.READ;
            const shouldSelectReadPermission = moduleRead && !isRead && !isPermissionSelected(name);

            const shouldDisableRead = isAccessSelected || isModulePermissionsSelected || isCategoryPermissionSelected;
            const customAffectedPermissions = getCustomDependedPermissions(name, customPermissionsDependency);
            const customPermissionsToChangeAccordingToDependency =
              customAffectedPermissions.length && !isPermissionSelected(name) ? customAffectedPermissions : [];
            const disabled =
              shouldDisableAction ||
              (isRead && shouldDisableRead) ||
              shouldDisablePermissionOfCustomScenario(name, customPermissionsDependency, isPermissionSelected);

            return (
              <PermissionsAction
                key={label}
                title={label}
                checked={isPermissionSelected(name)}
                onChange={onCheckPermission([
                  name,
                  ...(shouldSelectReadPermission ? [moduleRead] : []),
                  ...customPermissionsToChangeAccordingToDependency,
                ])}
                disabled={disabled}
                tooltip={description || (isRead && !isSystemRole && DISABLED_TOOLTIP)}
              />
            );
          })}
        </div>
      )}
      {!isEmpty(permissionsByCategory) && (
        <div
          className={classnames(
            isEmpty(modulePermissionsClone)
              ? classes.categoriesWrapper
              : classes.categoriesWrapperWithModulePermissions,
          )}
        >
          {map(permissionsByCategory, (categoryPermissions, category) => {
            const categoryRead = categoryPermissions.find(({ action }) => action === PermissionActions.READ)?.name;
            const isNoneReadPermissionSelected = categoryPermissions
              .filter(({ action }) => action !== PermissionActions.READ)
              .map(({ name }) => name)
              .some(permission => isPermissionSelected(permission));
            return (
              <div className={classes.categoryWrapper} data-aid={`PermissionsCategory-${category}`} key={category}>
                <div className={classes.category}>
                  {startCase(category)}
                  {categoryDescriptions[category] && <InfoHelper tooltip={categoryDescriptions[category]} />}
                </div>
                <div className={classes.actionsWrapper}>
                  {map(categoryPermissions, ({ label, name, description, action }) => {
                    const isRead = action === PermissionActions.READ;
                    const isSingleReadSelectedAndAccessSelected =
                      isAccessSelected &&
                      !moduleRead &&
                      categoryReadsSelected.length === 1 &&
                      categoryReadsSelected.includes(name);
                    const shouldDisableRead =
                      isRead && (isNoneReadPermissionSelected || isSingleReadSelectedAndAccessSelected);
                    const disabled =
                      shouldDisableAction ||
                      shouldDisableRead ||
                      shouldDisablePermissionOfCustomScenario(name, customPermissionsDependency, isPermissionSelected);

                    const shouldSelectModuleReadPermission = moduleRead && !isPermissionSelected(name);
                    const shouldSelectCategoryReadPermission = categoryRead && !isRead && !isPermissionSelected(name);

                    const customAffectedPermissions = getCustomDependedPermissions(name, customPermissionsDependency);
                    const customPermissionsToChangeAccordingToDependency =
                      customAffectedPermissions.length && !isPermissionSelected(name) ? customAffectedPermissions : [];

                    const tooltip = description || (isRead && !isSystemRole && DISABLED_CATEGORY_TOOLTIP);
                    return (
                      <PermissionsAction
                        key={label}
                        title={label}
                        checked={isPermissionSelected(name) || checkIfExternalApplicationPermissionsExist(name)}
                        onChange={onCheckPermission([
                          name,
                          ...(shouldSelectModuleReadPermission ? [moduleRead] : []),
                          ...(shouldSelectCategoryReadPermission ? [categoryRead] : []),
                          ...customPermissionsToChangeAccordingToDependency,
                        ])}
                        disabled={disabled}
                        tooltip={tooltip}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </BigidCollapsable>
  );
};
