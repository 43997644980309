import React, { FC, useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { CONFIG } from '../../../config/common';
import { getQueryClient } from '../../config/query';
import { $state } from '../../services/angularServices';
import { isDataExplorerEnabled } from '../DataExplorer/dataExplorerService';
import { DataCatalogSearchResultsContextProvider } from './contexts/dataCatalogSearchResultsContext';
import { DataExplorerSearchResultsData } from './DataExplorerSearchResultsData';
import { WithDataAid } from './types';

export const DataExplorerSearchResults: FC<WithDataAid> = ({ dataAid = 'DataExplorerSearchResults' }) => {
  const queryClient = getQueryClient();

  useEffect(() => {
    if (!isDataExplorerEnabled()) {
      $state.go(CONFIG.states.FORBIDDEN);
    }
  }, []);

  if (!isDataExplorerEnabled()) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <DataCatalogSearchResultsContextProvider>
        <DataExplorerSearchResultsData dataAid={dataAid} />
      </DataCatalogSearchResultsContextProvider>
    </QueryClientProvider>
  );
};
