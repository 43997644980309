import { DataSource } from '../../../../utilities/dataSourcesUtils';
import { useCallback, useState } from 'react';
import {
  getSupportedScanTypesByDsNames,
  useGetDataSourceTypeToSupportedScansMap,
} from '../../../Scans/ScanCreateWizard/hooks/useUpdateSupportedScanTypesBySelectedDs';
import { DataSourceModel } from '../DataSourceConnectionTypes';
import { ScanTypes } from '../../../Scans/ScanTemplates/scanTemplateTypes';

export const useSupportedScanTypesForSelectedDs = (supportedDataSources: DataSource[]) => {
  const [dataSourceNameToTypeMap, setDataSourceNameToTypeMap] = useState({});
  const supportedScansMap = useGetDataSourceTypeToSupportedScansMap(supportedDataSources);
  const [disabledDataSources, setDisabledDataSources] = useState<Record<string, true>>({});
  const [archivedDataSources, setArchivedDataSources] = useState<Record<string, true>>({});
  const setDisabledAndArchivedDataSources = useCallback((dataSources: DataSourceModel[]) => {
    const { disabledDataSources, archivedDataSources } = dataSources.reduce(
      (acc, { name, enabled, archived }) => ({
        disabledDataSources: { ...acc.disabledDataSources, ...(enabled !== 'yes' ? { [name]: true } : {}) },
        archivedDataSources: { ...acc.archivedDataSources, ...(archived ? { [name]: true } : {}) },
      }),
      { disabledDataSources: {}, archivedDataSources: {} },
    );
    setDisabledDataSources(curr => ({ ...curr, ...disabledDataSources }));
    setArchivedDataSources(curr => ({ ...curr, ...archivedDataSources }));
  }, []);
  const [supportedScanTypesForSelectedDs, setSupportedScanTypesForSelectedDs] = useState<ScanTypes[]>([]);

  const updateSupportedScanTypes = useCallback(
    (dataSourcesIds: string[]) => {
      if (dataSourcesIds.some(id => archivedDataSources[id])) {
        return [];
      }
      const isDisabledSelected = dataSourcesIds.some(id => disabledDataSources[id]);
      const scanTypes = getSupportedScanTypesByDsNames(
        { dataSourceNameToTypeMap, dataSourcesIds },
        supportedScansMap,
        isDisabledSelected,
        false,
      );
      setSupportedScanTypesForSelectedDs(scanTypes);

      return scanTypes;
    },
    [, dataSourceNameToTypeMap, supportedScansMap, archivedDataSources, disabledDataSources],
  );

  return {
    updateSupportedScanTypes,
    setDataSourceNameToTypeMap,
    supportedScanTypesForSelectedDs,
    setDisabledAndArchivedDataSources,
  };
};
