import { BigidHeading6 } from '@bigid-ui/components';
import { styled } from '@mui/material';

import React, { FC, PropsWithChildren } from 'react';
import { AdditionalDomainsSection } from './AdditionalDomainsSection';
import { ApprovalsSection } from './ApprovalsSection';
import { AttributesSection } from './AttributeSection';
import { ContributorsSection } from './ContributorsSection';

const Container = styled('section')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  gap: 8px;
`;

type DataRowProps = {
  isShort?: boolean;
};

export const DataRow = styled('span')<DataRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${({ isShort }) => (isShort ? '45%' : '100%')};
`;

export const ControlsWrapper = styled('div')`
  width: 166px;
`;

type HeadingProps = PropsWithChildren<{
  title: string;
}>;

export const Heading: FC<HeadingProps> = ({ children, title }) => {
  return (
    <DataRow sx={{ paddingTop: '8px' }}>
      <BigidHeading6 justifyContent="space-between" alignItems="center">
        {title}
      </BigidHeading6>
      {children}
    </DataRow>
  );
};

export const AttributeDataCard = () => {
  return (
    <Container>
      <AttributesSection />
      <ApprovalsSection />
      <AdditionalDomainsSection />
      <ContributorsSection />
    </Container>
  );
};
