import React from 'react';
import { BigidButtonTypography, BigidRadioGroup, BigidRadioProps } from '@bigid-ui/components';
import { BigidFilterContainer } from '@bigid-ui/layout';
import { SarProfilePutModel } from '../ProfileSettingsTypes';
import { sarConfigService } from '../sarConfigService';
import { notificationService } from '../../../../services/notificationService';
import { DSAR_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../../../services/userPermissionsService';
import { SarProfileSourceTypes } from '../SarProfileSettingsTypes';
import makeStyles from '@mui/styles/makeStyles';

export interface EnableAllProps {
  profileId: string;
  isAllEnabled: boolean;
  sourceType: SarProfileSourceTypes;
  onAllEnabled: (isEnabled: boolean) => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  label: {
    marginRight: '24px',
  },
});

const isButtonDisabled = () =>
  !isPermitted(DSAR_PERMISSIONS.EDIT_PROFILE_SETTINGS.name) ||
  !isPermitted(DSAR_PERMISSIONS.MANAGE_ALL_ENABLED_PROFILE_SETTINGS.name);

export const EnableAll = ({ profileId, sourceType, isAllEnabled, onAllEnabled }: EnableAllProps) => {
  const classes = useStyles();
  const { filter, setFilter } = BigidFilterContainer.useContainer();
  const label = `Include ${sourceType === SarProfileSourceTypes.DS ? 'Data Sources' : 'Correlation Sets'}`;

  const toggleButtons: BigidRadioProps[] = [
    {
      value: 'true',
      checked: isAllEnabled,
      label: 'Enabled By Admin',
      disabled: isButtonDisabled(),
    },
    {
      value: 'false',
      checked: !isAllEnabled,
      label: 'Custom Selection',
      disabled: isButtonDisabled(),
    },
  ];

  return (
    <div className={classes.root}>
      <BigidButtonTypography className={classes.label}>{label}</BigidButtonTypography>
      <BigidRadioGroup
        horizontal
        options={toggleButtons}
        onChange={event => {
          const newAllEnabledDs = JSON.parse(event.target.value) ?? false;
          onAllEnabled(newAllEnabledDs);
          updateProfile(profileId, sourceType, newAllEnabledDs).then(() => {
            setFilter([...filter]);
          });
        }}
      />
    </div>
  );
};

function updateProfile(profileId: string, sourceType: EnableAllProps['sourceType'], value: boolean) {
  const newDbState: SarProfilePutModel = {
    ...(sourceType === SarProfileSourceTypes.DS ? { allEnabledDs: value } : {}),
    ...(sourceType === SarProfileSourceTypes.ES ? { allEnabledEs: value } : {}),
  };
  return sarConfigService
    .updateProfile(profileId, newDbState)
    .then(result => {
      if (result.isModified) {
        notificationService.success(`Changes saved`);
      }
    })
    .catch(err => {
      notificationService.error(`Failed to save changes`);
      console.error(`Failed to update sar_profile item with the new state:`, JSON.stringify(newDbState), err);
      throw err;
    });
}
