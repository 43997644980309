import React, { FC, memo } from 'react';
import { BigidHeading5, BigidBody1, BigidIcon, BigidIconType, BigidIconSize, BigidColors } from '@bigid-ui/components';
import makeStyles from '@mui/styles/makeStyles';
import BigidEntities from '../../../../../assets/icons/BigidEntities.svg';
import BigidLocation from '../../../../../assets/icons/BigidLocation.svg';
import BigidScope from '../../../../../assets/icons/BigidScope.svg';
import CheckIcon from '@mui/icons-material/Check';

const MAP_ICON_NAME_TO_COMPONENT: Record<string, BigidIconType> = {
  user: BigidEntities,
  location: BigidLocation,
  scope: BigidScope,
};

export const useStyles = makeStyles({
  dataSourceDetailsBoxItemWrapper: {
    display: 'flex',
    padding: '32px 24px 24px 0',
    borderBottom: `1px solid ${BigidColors.gray[100]}`,
    margin: '0 8px',
    alignItems: 'center',
  },
  iconWrapper: ({ done }: Pick<DataSourceDetailsBoxItemProps, 'done'>) => ({
    display: 'flex',
    margin: `0 24px 0 ${done ? '26' : '16'}px`,
    minWidth: done ? '32px' : '42px',
    minHeight: done ? '32px' : '42px',
    justifyContent: 'center',
    backgroundColor: done ? BigidColors.green[400] : 'transparent',
    borderRadius: '100%',
  }),
  titleWrapper: ({ done }: Pick<DataSourceDetailsBoxItemProps, 'done'>) => ({
    display: 'inline-flex',
    paddingBottom: '12px',
    fontSize: '18px',
    color: done ? BigidColors.gray[400] : BigidColors.gray[700],
  }),
  descriptionWrapper: ({ done }: Pick<DataSourceDetailsBoxItemProps, 'done'>) => ({
    display: 'inline-flex',
    fontSize: '12px',
    color: done ? BigidColors.gray[400] : BigidColors.gray[700],
  }),
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export interface DataSourceDetailsBoxItemProps {
  title: string;
  description: string;
  iconName: string;
  done: boolean;
}

export const DataSourceDetailsBoxItem: FC<DataSourceDetailsBoxItemProps> = memo(
  ({ title, description, iconName, done }) => {
    const { dataSourceDetailsBoxItemWrapper, iconWrapper, titleWrapper, descriptionWrapper, infoWrapper } = useStyles({
      done,
    });
    const iconProps = done
      ? {
          size: BigidIconSize.REGULAR_PLUS,
          icon: CheckIcon,
          color: BigidColors.white,
        }
      : {
          size: BigidIconSize.LARGE,
          icon: MAP_ICON_NAME_TO_COMPONENT[iconName],
        };

    return (
      <div
        data-aid={`dataSourceDetailsBoxItem-${title}-${done ? 'filled' : 'empty'}`}
        className={dataSourceDetailsBoxItemWrapper}
      >
        <div data-aid={`dataSourceDetailsBoxItemIconWrapper-${title}`} className={iconWrapper}>
          {<BigidIcon {...iconProps} />}
        </div>
        <div data-aid="dataSourceDetailsBoxItemIconInfo" className={infoWrapper}>
          <BigidHeading5 className={titleWrapper}>{title}</BigidHeading5>
          <BigidBody1 className={descriptionWrapper}>{description}</BigidBody1>
        </div>
      </div>
    );
  },
);

DataSourceDetailsBoxItem.displayName = 'DataSourceDetailsBoxItem';
