import React, { FC, useCallback, useContext } from 'react';
import { styled } from '@mui/material';
import { BigidRefreshIcon } from '@bigid-ui/icons';
import {
  BigidConnectionInProgressAnimation,
  BigidFieldRenderProps,
  BigidFormField,
  BigidHeading6,
  PrimaryButton,
  StringOrBooleanMap,
  TertiaryButton,
} from '@bigid-ui/components';
import { AutoDiscoverWizardAsideExpandedStates, AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import { fieldConditionCheck } from '../../../../DataSources/DataSourceConfiguration/utils/conditionUtils';
import { AutoDiscoverWizardDsList } from './AutoDiscoveryWizardDsList/AutoDiscoverWizardDsList';
import { checkAutoDiscoveryConfigPermissions } from '../../autoDiscoveryWizardUtils';
import { getDiscoverAuthTypeString, getDsOptions } from '../../autoDiscoveryWizardFormUtils';
import { DiscoveryAuthTypes, TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS } from '../../../constants';
import { useLocalTranslation } from '../../../translations';

const AutoDiscoveryAuthAndPermissionsWrapper = styled('div')`
  padding: 20px 16px;
  gap: 24px;
  background-color: ${({ theme }) => theme.vars.palette.bigid.gray150};
  border-radius: 4px;
`;

const AutoDiscoveryAuthAndPermissionsActions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 8px;
`;

const AutoDiscoveryAuthAndPermissionsLoading = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;

export interface AutoDiscoveryWizardAuthSectionProps {
  values?: Record<string, any>;
  visibleFields?: string[];
  fields: BigidFormField[];
  getFieldProps: (fieldName: string) => BigidFieldRenderProps<any, any>;
  renderField: (fieldName: string, additionalProps?: Record<string, any>) => any;
  errors: StringOrBooleanMap;
}

export const AutoDiscoveryWizardAuthSection: FC<AutoDiscoveryWizardAuthSectionProps> = ({
  values,
  fields,
  renderField,
  getFieldProps,
  errors,
}) => {
  const { discoveryConfigData, setDiscoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const { t } = useLocalTranslation('AutoDiscovery');
  const fieldsNamesByAuthTypeFields: string[] = [];
  const isChecked = discoveryConfigData?.connectionInfo?.credsStatus;
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData?.authType);

  const onCheck = useCallback(async () => {
    setDiscoveryConfigData(current => ({
      ...current,
      isCheck: true,
      errorMessage: undefined,
      errorMessageKey: undefined,
    }));

    const { connectionInfo, message, isValid, errorMessageKey } = await checkAutoDiscoveryConfigPermissions({
      discoveryConfigData,
      fieldsNamesByAuthTypeFields,
      values,
    });
    const asideExpandedState = isValid
      ? AutoDiscoverWizardAsideExpandedStates.PERMISSIONS_SUMMARY
      : AutoDiscoverWizardAsideExpandedStates.OPENED_ERROR;

    setDiscoveryConfigData(current => ({
      ...current,
      connectionInfo,
      formData: {
        ...current.formData,
        dsList: isValid ? getDsOptions(connectionInfo.permissions, current.type) : [],
      },
      asideExpandedState,
      isCheck: false,
      errorMessage: message,
      errorMessageKey,
      fieldsNamesByAuthTypeFields,
    }));
  }, [discoveryConfigData, setDiscoveryConfigData, values, fieldsNamesByAuthTypeFields]);

  const checkPermissionsDisabled =
    !authTypeValue ||
    discoveryConfigData.isCheck ||
    !TYPE_TO_AVAILABLE_CHECK_PERMISSIONS_BY_AUTH_FIELDS[discoveryConfigData.type]?.[
      authTypeValue as DiscoveryAuthTypes
    ];

  return (
    values?.authType?.length && (
      <AutoDiscoveryAuthAndPermissionsWrapper>
        {discoveryConfigData.isCheck ? (
          <AutoDiscoveryAuthAndPermissionsLoading data-aid={'AutoDiscoveryAuthAndPermissionsLoading'}>
            <BigidConnectionInProgressAnimation width={96} height={96} />
            <BigidHeading6>{t('configModal.loaders.checkingPermissions')}</BigidHeading6>
          </AutoDiscoveryAuthAndPermissionsLoading>
        ) : (
          <>
            {fields.map(({ name, misc: { visibleIf } }) => {
              const isVisible = fieldConditionCheck(false, visibleIf, field => ({
                value: values[field],
                type: getFieldProps(field).misc?.typeForConditionCheck,
              }));
              if (isVisible) {
                fieldsNamesByAuthTypeFields.push(name);
              }
              return (
                isVisible &&
                renderField(name, {
                  error: errors?.[name],
                  errorIsShown: !!errors?.[name],
                })
              );
            })}
            <AutoDiscoveryAuthAndPermissionsActions>
              <AutoDiscoverWizardDsList />
              {isChecked ? (
                <TertiaryButton
                  dataAid={'AutoDiscoveryWizardReCheckPermissionsButton'}
                  onClick={onCheck}
                  size={'medium'}
                  startIcon={<BigidRefreshIcon />}
                  text={t('configModal.buttons.recheck')}
                />
              ) : (
                <PrimaryButton
                  dataAid={'AutoDiscoveryWizardCheckPermissionsButton'}
                  disabled={checkPermissionsDisabled}
                  onClick={onCheck}
                  size={'medium'}
                  text={t('configModal.buttons.checkPermissions')}
                />
              )}
            </AutoDiscoveryAuthAndPermissionsActions>
          </>
        )}
      </AutoDiscoveryAuthAndPermissionsWrapper>
    )
  );
};
