import React, { FC, useState, useEffect, ChangeEvent, useMemo, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as bigidQueryObjectSerialization from '@bigid/query-object-serialization';
import {
  BigidCaption,
  BigidColorsV2,
  BigidPaper,
  BigidRadioGroup,
  BigidTextFieldAutocomplete,
  QueryNode,
  TertiaryButton,
  usePrevious,
} from '@bigid-ui/components';
import { BigidCopyIcon, BigidWarningFilledIcon } from '@bigid-ui/icons';
import { CatalogRuleQueryBuilder } from './CatalogRuleQueryBuilder';
import { CatalogRuleDetailsValidationState } from './CatalogRuleDetails';
import { CatalogRule } from '../catalogRulesService';
import { showConfirmationDialog } from '../../../services/confirmationDialogService';
import { SystemAttribute } from '../../DataCatalog/DataCatalogService';
import { debounce } from 'lodash';
import classNames from 'classnames';

export interface CatalogRuleQueryProps {
  dataAid: string;
  rule: CatalogRule;
  isReadOnly: boolean;
  attributesList: SystemAttribute[];
  onQueryValidated: (isValid: boolean) => void;
  onStringifiedQueryChange: (query: string) => void;
  onObjectifiedQueryChange: (object: QueryNode) => void;
  validationState: CatalogRuleDetailsValidationState;
  isForcedStrigifiedQueryMode: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
  },
  wrapper: {
    backgroundColor: BigidColorsV2.purple[50],
    padding: '10px 10px 20px 10px',
    width: '100%',
    overflowX: 'auto',
  },
  invalid: {
    border: `1px solid ${BigidColorsV2.red[600]}`,
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
  },
  alignControlRight: {
    justifyContent: 'flex-end',
  },
  copyWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  copyIcon: {
    marginRight: '10px',
  },
  errorMsg: {
    color: BigidColorsV2.red[600],
    marginTop: '5px',
    fontSize: '0.75rem', //NOTE: a fix to provide consistency among form controls
  },
});

const QUERY_BUILDER_RADIO_OPTION = 'queryBuilder';
const QUERY_INPUT_RADIO_OPTION = 'inputQuery';

const QUERY_BUILDER_RADIO_OPTIONS = [
  {
    label: 'Query Builder',
    value: QUERY_BUILDER_RADIO_OPTION,
  },
  {
    label: 'Input Query',
    value: QUERY_INPUT_RADIO_OPTION,
  },
];

const INVALID_QUERY_MESSAGE_PER_RADIO_OPTION: Record<string, string> = {
  [QUERY_BUILDER_RADIO_OPTION]: 'Each rule must have a valid query.',
  [QUERY_INPUT_RADIO_OPTION]: 'The query must be valid SQL.',
};

export const CatalogRuleQuery: FC<CatalogRuleQueryProps> = ({
  dataAid = 'CatalogRuleQuery',
  onStringifiedQueryChange,
  onObjectifiedQueryChange,
  onQueryValidated,
  rule,
  isReadOnly,
  attributesList,
  validationState,
  isForcedStrigifiedQueryMode,
}) => {
  const classes = useStyles();

  const [currRadioButtonValue, setCurrRadioButtonValue] = useState<string>(QUERY_BUILDER_RADIO_OPTION);
  const [shouldShowWarning, setShouldShowWarning] = useState<boolean>(false);

  const prevStringifiedQuery = usePrevious(rule?.bigidQuery);

  const isQueryValid = useMemo(() => {
    const { valid } = bigidQueryObjectSerialization.validateQuery(rule?.bigidQuery);
    return valid;
  }, [rule?.bigidQuery]);

  useEffect(() => {
    setShouldShowWarning(false);
  }, [rule?.id]);

  useEffect(() => {
    if (rule?.bigidQueryObject === null || isForcedStrigifiedQueryMode) {
      setCurrRadioButtonValue(QUERY_INPUT_RADIO_OPTION);
    } else {
      setCurrRadioButtonValue(QUERY_BUILDER_RADIO_OPTION);
    }
  }, [rule?.bigidQueryObject, isForcedStrigifiedQueryMode]);

  useEffect(() => {
    onQueryValidated(isQueryValid);
  }, [isQueryValid, onQueryValidated]);

  const handleOnChangeQueryBuilder = useCallback(
    (query: QueryNode) => {
      const queryAsString = bigidQueryObjectSerialization.parseGraphQueryNodes(
        query as unknown as bigidQueryObjectSerialization.RulesStructNode,
      );

      setShouldShowWarning(true);
      onObjectifiedQueryChange(query);
      onStringifiedQueryChange(queryAsString);
    },
    [onObjectifiedQueryChange, onStringifiedQueryChange],
  );

  const handleQueryToolbarRadioChange = useCallback(
    ({ target }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setCurrRadioButtonValue(target.value);
    },
    [],
  );

  const handleOnTextQueryChange = useCallback(
    async (value: string) => {
      if (value !== rule?.bigidQuery) {
        if (shouldShowWarning) {
          const isTextualQueryChangeConfirmed = await showConfirmationDialog({
            actionName: 'Warning',
            entityNameSingular: '',
            customDescription:
              'Editing this query syntax, will make reversible changes if you will wish to continue editing it via the query builder.',
            icon: () => {
              return <BigidWarningFilledIcon color="warning" />;
            },
          });

          setShouldShowWarning(!isTextualQueryChangeConfirmed);

          if (isTextualQueryChangeConfirmed) {
            onObjectifiedQueryChange(null);
            onStringifiedQueryChange(value);
          } else {
            onStringifiedQueryChange(value);
            onStringifiedQueryChange(prevStringifiedQuery);
          }
        } else {
          if (prevStringifiedQuery !== value) {
            onStringifiedQueryChange(value);
          }
        }
      }
    },
    [rule?.bigidQuery, shouldShowWarning, onObjectifiedQueryChange, onStringifiedQueryChange, prevStringifiedQuery],
  );

  const handleOnTextQueryChangeDebounced = useMemo(
    () => debounce(handleOnTextQueryChange, 500),
    [handleOnTextQueryChange],
  );

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(rule?.bigidQuery || '').catch(err => {
      console.error("Can't copy query", err);
    });
  }, [rule?.bigidQuery]);

  const autocompleteListItems = useMemo(() => {
    return attributesList.map(({ attribute_original_name }) => attribute_original_name);
  }, [attributesList]);

  const isInvalid = !isQueryValid || validationState?.query;

  return (
    <div className={classes.root} data-aid={dataAid}>
      <BigidPaper
        dataAid={`${dataAid}-body`}
        classes={{ root: classNames(classes.wrapper, isInvalid && classes.invalid) }}
      >
        <div
          className={classNames(classes.toolbar, isForcedStrigifiedQueryMode && classes.alignControlRight)}
          data-aid={`${dataAid}-toolbar`}
        >
          {!isForcedStrigifiedQueryMode && (
            <BigidRadioGroup
              value={currRadioButtonValue}
              horizontal
              onChange={handleQueryToolbarRadioChange}
              options={QUERY_BUILDER_RADIO_OPTIONS}
            />
          )}

          <TertiaryButton
            onClick={copyToClipboard}
            size="small"
            dataAid={`${dataAid}-copy-button`}
            startIcon={<BigidCopyIcon />}
            text="Copy Query"
          />
        </div>
        {currRadioButtonValue === QUERY_BUILDER_RADIO_OPTION ? (
          <CatalogRuleQueryBuilder
            dataAid={`${dataAid}-builder`}
            attributesList={attributesList}
            onChangeQueryBuilder={handleOnChangeQueryBuilder}
            query={rule?.bigidQueryObject}
            isReadOnly={isReadOnly}
          />
        ) : (
          <BigidTextFieldAutocomplete
            dataAid={`${dataAid}-textual`}
            autocompleteListItems={autocompleteListItems}
            triggerChar="= "
            onChange={handleOnTextQueryChangeDebounced}
            rows={5}
            dropdownHeight={100}
            multiline
            maxAutocompleteListLength={100}
            disableEnter
            value={rule?.bigidQuery}
            disabled={isReadOnly}
            shouldEvalOnChangeWhenInit={false}
          />
        )}
      </BigidPaper>
      {isInvalid && (
        <BigidCaption className={classes.errorMsg} data-aid={`${dataAid}-error-message`}>
          {validationState?.query || INVALID_QUERY_MESSAGE_PER_RADIO_OPTION[currRadioButtonValue]}
        </BigidCaption>
      )}
    </div>
  );
};
