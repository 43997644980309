import './identityAccessManagement.component.scss';
import template from './identityAccessManagement.component.html';
import { module } from 'angular';
import { $state } from '../../react/services/angularServices';
const app = module('app');
import { ACCESS_MANAGEMENT_PERMISSIONS } from '@bigid/permissions';
import { isPermitted } from '../../react/services/userPermissionsService';
import { getApplicationPreference } from '../../react/services/appPreferencesService';

export const DEFAULT_TAB_ACCESS_MANAGEMENT = 'systemUsers';

app.component('identityAccessManagement', {
  template,
  controller: function ($rootScope, $translate, $scope, $stateParams) {
    'ngInject';

    const TRANSLATION_REQUIRED = [
      'IDENTITY_ACCESS_MANAGEMENT',
      'IDENTITY_ACCESS_MANAGEMENT:TABS:ROLES',
      'IDENTITY_ACCESS_MANAGEMENT:TABS:SCOPES',
      'IDENTITY_ACCESS_MANAGEMENT:TABS:SYSTEM_USERS',
      'IDENTITY_ACCESS_MANAGEMENT:TABS:IDP',
    ];

    this.tabs = {};
    this.currentTab = '';
    this.isLoading = false;

    const setSelectedTabIfPermitted = tabs => {
      const tabToSet = tabs.find(tab => this.tabs[tab]);
      this.currentTab = tabToSet || Object.keys(this.tabs)?.[0];
    };

    this.$onInit = () => {
      $translate(TRANSLATION_REQUIRED).then(translation => {
        $rootScope.$broadcast('changePage', translation['IDENTITY_ACCESS_MANAGEMENT'], false);
        const ENABLE_CLOUD_MERGER = getApplicationPreference('ENABLE_CLOUD_MERGER');
        const ENABLE_CUSTOMER_IDP = getApplicationPreference('ENABLE_CUSTOMER_IDP');
        if (isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.READ.name)) {
          this.tabs.systemUsers = translation['IDENTITY_ACCESS_MANAGEMENT:TABS:SYSTEM_USERS'];
          if (!ENABLE_CLOUD_MERGER) {
            this.tabs.roles = translation['IDENTITY_ACCESS_MANAGEMENT:TABS:ROLES'];
            this.tabs.scopes = translation['IDENTITY_ACCESS_MANAGEMENT:TABS:SCOPES'];
          }
        }
        if (isPermitted(ACCESS_MANAGEMENT_PERMISSIONS.READ_IDP.name) && ENABLE_CUSTOMER_IDP) {
          this.tabs.idp = translation['IDENTITY_ACCESS_MANAGEMENT:TABS:IDP'];
        }

        setSelectedTabIfPermitted([$stateParams.currentTab, DEFAULT_TAB_ACCESS_MANAGEMENT]);
      });
    };

    this.onTabSelected = tabName => {
      this.isLoading = false;
      $state.go('identityAccessManagement', { currentTab: tabName });
    };

    this.onContentDataLoading = () => {
      this.isLoading = true;
    };

    this.onContentDataLoaded = () => {
      $scope.$applyAsync(() => {
        this.isLoading = false;
      });
    };
  },
});
