import React from 'react';
import { ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import { BigidAvatar } from '@bigid-ui/components';

export type DataSourceConnectionCollaborationListItemProps = {
  dataAid?: string;
  title: string;
  avatar?: string;
};

export const DataSourceConnectionCollaborationListItem = ({
  dataAid = 'DataSourceConnectionCollaborationListItem',
  title,
  avatar,
}: DataSourceConnectionCollaborationListItemProps) => {
  return (
    <>
      <ListItem data-aid={dataAid} alignItems="center">
        <ListItemAvatar>
          <BigidAvatar userName={avatar ?? title?.charAt(0)} />
        </ListItemAvatar>
        <ListItemText primary={title} />
      </ListItem>
    </>
  );
};
