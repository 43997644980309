import React, { FC, useEffect, useState, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  BigidColorsV2,
  BigidHeading4,
  BigidFilterToolbar,
  BigidFieldFilterOperator,
  BigidFilterOptionValue,
  BigidFilterOptionType,
} from '@bigid-ui/components';
import { ActionCenterMetadata } from '../actionWorkflowTypes';
import { getEntityFilters, loadUserSearchOptions } from '../actionWorkflowUtils';
import { topBarHeight } from '../ActionWorkflowTop';
import { OnFiltersChanged } from '@bigid-ui/grid';
import { ActionCenterMetadataContainer } from '../../useActionCenterMetadata';

type LoadSearchOptions = (inputValue: string) => Promise<BigidFilterOptionType[]>;

interface ActionWorkflowContentToolbarProps {
  category: string;
  subCategory?: string;
  totalWorkflows: number;
  onFiltersChanged: OnFiltersChanged;
  isEditMode: boolean;
  workflowName?: string;
  shouldUpdateCount: boolean;
  isLoadingFilters: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: '22px 24px',
    alignItems: 'center',
    borderBottom: `1px solid ${BigidColorsV2.gray[200]}`,
    minHeight: topBarHeight,
  },
  title: {
    marginRight: 16,
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  toolbarWrapper: { flexGrow: 1 },
});

const searchConfig = { searchFilterKeys: ['name'], operator: 'contains' as BigidFieldFilterOperator, initialValue: '' };

interface GetHeading
  extends Omit<ActionWorkflowContentToolbarProps, 'onFiltersChanged' | 'shouldUpdateCount' | 'isLoadingFilters'> {
  actionCenterMetadata: ActionCenterMetadata;
}

const getTitle = ({
  category,
  subCategory,
  totalWorkflows,
  isEditMode,
  workflowName,
  actionCenterMetadata,
}: GetHeading): string => {
  if (isEditMode) {
    return workflowName ? `Edit ${workflowName}` : 'New Action';
  }
  if (subCategory) {
    return `${actionCenterMetadata.events.find(({ name }) => name === subCategory).displayName} Events`;
  }
  const { displayName } = actionCenterMetadata.categories.find(({ name }) => name === category);
  return `${displayName} (${totalWorkflows})`;
};

export const ActionWorkflowContentToolbar: FC<ActionWorkflowContentToolbarProps> = ({
  category,
  subCategory,
  totalWorkflows,
  onFiltersChanged,
  isEditMode,
  workflowName,
  shouldUpdateCount,
  isLoadingFilters,
}) => {
  const { actionCenterMetadata } = ActionCenterMetadataContainer.useContainer();
  const { root, title: titleStyle, toolbarWrapper } = useStyles({});
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (shouldUpdateCount) {
      setTitle(getTitle({ actionCenterMetadata, category, subCategory, totalWorkflows, isEditMode, workflowName }));
    }
  }, [shouldUpdateCount, actionCenterMetadata, category, subCategory, totalWorkflows, isEditMode, workflowName]);

  const entityFilters = getEntityFilters(actionCenterMetadata, category, subCategory);

  const filters = useMemo(
    () => [
      {
        title: 'Owners',
        field: 'receivers.username',
        operator: 'in' as BigidFieldFilterOperator,
        value: [] as BigidFilterOptionValue,
        isSearchAsync: true,
        loadSearchOptions: loadUserSearchOptions({ isFilter: true }) as LoadSearchOptions,
        options: [],
        disabled: true,
        shouldLoadInitialOptions: true,
      },
      ...entityFilters,
      {
        title: 'Actions',
        field: 'actions.type',
        operator: 'in' as BigidFieldFilterOperator,
        value: [] as BigidFilterOptionValue,
        options: actionCenterMetadata.actions.map(({ name, displayName }) => ({
          label: displayName,
          value: name,
          isSelected: false,
        })),
        disabled: true,
      },
    ],
    [entityFilters, actionCenterMetadata.actions],
  );

  return (
    <div className={root}>
      <BigidHeading4 className={titleStyle} data-aid={'ActionWorkflowContentToolbar-title'}>
        {title}
      </BigidHeading4>
      {!isLoadingFilters && !isEditMode && (
        <div className={toolbarWrapper}>
          <BigidFilterToolbar filters={filters} onFiltersChange={onFiltersChanged} searchConfig={searchConfig} />
        </div>
      )}
    </div>
  );
};
