import { Dispatch } from 'react';
import { ParentAPI } from 'postmate';
import { v4 as uuidv4 } from 'uuid';
import {
  getToken,
  useToast,
  openDialog,
  getApiUrl,
  getBffUrl,
  ActionLogicFunction,
  getPermissions,
  bigidAPI,
  changeRoute,
  getRouteParams,
  hidePageHeader,
  sendDataLakeEvent,
  trackManualEvent,
  trackPageView,
} from './actionsLogic';
import { ParentTopic, topicsDefaultResponses } from './sdkTopicHandler';
import { AppInfo } from '../../../utils/CustomAppTypes';
import { Action } from '../CustomAppUI';

export interface ChildRequest {
  resolve: (data: any) => void;
  topicName: ParentTopic;
  dataSent?: any;
}

export interface ChildDataInterface {
  data: any;
  actionName: string;
  uuid: string;
}

export interface ChildResponse {
  appResponse: any;
  uuid: string;
}

type LogicMap = Record<string, ActionLogicFunction>;
const childRequests: Record<string, ChildRequest> = {};
const SDK_TIMEOUT_FOR_DEFAULT_RESPONSE = 20000;
const sdkSupportedEvents: Set<string> = new Set<string>();
export const initSdkSupportedEvents = (sdkSupported: string[]) => {
  sdkSupportedEvents.size && sdkSupportedEvents.clear();
  sdkSupported?.forEach(supportedEvent => sdkSupportedEvents.add(supportedEvent));
};

export const sdkHandler = async (
  child: ParentAPI,
  childData: ChildDataInterface,
  appInfo: AppInfo,
  dispatch: Dispatch<Action>,
) => {
  const { actionName, data, uuid } = childData;
  try {
    const logic: LogicMap = {
      getToken,
      useToast,
      getApiUrl,
      openDialog,
      getBffUrl,
      getPermissions,
      bigidAPI,
      changeRoute,
      getRouteParams,
      hidePageHeader,
      sendDataLakeEvent,
      trackManualEvent,
      trackPageView,
    };

    if (logic.hasOwnProperty(actionName)) {
      const response: any = await logic[actionName]({ data, dispatch, appInfo });
      child.call('response', { data: response, actionName, uuid });
    } else {
      throw `The action ${actionName} does not exists`;
    }
  } catch (error) {
    child.call('response', { data: { error }, actionName, uuid });
  }
};

export const callChild = async (child: ParentAPI, topicName: ParentTopic, data: any) => {
  const defaultResponse = topicsDefaultResponses.get(topicName);
  if (!sdkSupportedEvents.has(topicName) || !child) return defaultResponse;
  const appCall = new Promise(resolve => {
    const uuid: string = uuidv4();
    childRequests[uuid] = { resolve, dataSent: data, topicName };
    child.call('parentTopic', { topicName, uuid, data });
  });
  return waitForAppResponse(appCall, defaultResponse);
};

export const sdkResponse = async (childData: ChildResponse) => {
  if (childRequests[childData.uuid]) {
    const { resolve, topicName } = childRequests[childData.uuid];
    const defaultResponse = topicsDefaultResponses.get(topicName as ParentTopic);
    resolve(childData.appResponse.error ? defaultResponse : childData.appResponse);
  }
};

const waitForAppResponse = async (appCall: Promise<any>, defaultResponse: any) => {
  const appTimeout = new Promise(resolve =>
    setTimeout(() => {
      resolve(defaultResponse);
    }, SDK_TIMEOUT_FOR_DEFAULT_RESPONSE),
  );
  return Promise.race([appCall, appTimeout]);
};
