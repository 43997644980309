import React, { FC, useState } from 'react';
import { Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from 'lodash';
import {
  BigidBody1,
  BigidButtonIcon,
  BigidColorsV2,
  BigidHeading5,
  BigidTooltip,
  PrimaryButton,
} from '@bigid-ui/components';
import {
  BigidDeleteIcon,
  BigidEditIcon,
  BigidCopyIcon,
  BigidChevronCircleDownIcon,
  BigidChevronCircleUpIcon,
} from '@bigid-ui/icons';
import { showConfirmationDialog } from '../../../../services/confirmationDialogService';
import { PresetLatestExecutionStatus, ExecutionData } from './PresetLatestExecutionStatus';
import { PresetScheduleInfo } from './PresetScheduleInfo';

import { CustomAppParam } from '../../views/EditCustomApp/EditCustomApp';
import { EditActionPreset } from '../../views/SetActionPreset/EditActionPreset';
import { CustomAppAction, Preset } from '../../views/CustomAppActions/CustomAppActions';
import { PresetEmptyState } from './PresetEmptyState';
import { TPATrackingEvents, trackTPAEvent } from '../../customAppEventsTrackerUtils';

export interface PresetCardProps {
  title: string;
  description?: string;
  schedule?: string;
  errorMessage?: string;
  enableDelete?: boolean;
  isAppOnline: boolean;
  hasManagerPermission: boolean;
  action: CustomAppAction;
  globalParams: CustomAppParam[];
  preset: Preset;
  latestExecution?: ExecutionData;

  onSchedule: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onRun: () => void;
  onClone: () => void;

  noParamsForThisAction: boolean;
}

const CARD_ACTIONS_HEIGHT = 80;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: ({ errorMessage }: Partial<PresetCardProps>) => ({
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 0px 5px ${errorMessage ? BigidColorsV2.red[600] : 'rgba(0, 0, 0, 0.15)'}`,
    borderRadius: 4,
    marginBottom: 6,
    overflow: 'hidden',
    padding: '0 16px',
  }),
  actions: {
    display: 'flex',
    alignItems: 'center',
    height: CARD_ACTIONS_HEIGHT,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    rowGap: '4px',
    height: '100%',
    overflow: 'hidden',
    paddingLeft: '16px',
    width: '100%',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    width: '100%',
    columnGap: '16px',
    whiteSpace: 'nowrap',
    marginTop: '8px',
    alignContent: 'center',
    alignItems: 'center',
  },

  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionWrapper: {
    width: '90%',
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  allButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `2px solid ${BigidColorsV2.gray[400]}`,
    height: '40px',
    paddingLeft: '16px',
  },
  iconsButtonsContainer: {
    display: 'flex',
    columnGap: '8px',
    flexGrow: 1,
    alignItems: 'center',
  },
  primaryButtonsContainer: {
    display: 'flex',
    columnGap: '16px',
    marginRight: '16px',
    width: '200px',
    justifyContent: 'flex-end',
  },
  errorMessageContainer: {
    color: BigidColorsV2.red[600],
    fontSize: '14px',
  },
  quickView: {
    margin: '16px',
    minWidth: '520px',
  },
});

const MAX_TITLE_LENGTH = 70;
const MAX_DESCRIPTION_LENGTH = 200;

export const getTooltipContentForNoParamsInTheApp = (currentAction: string) =>
  `${currentAction} is not allowed since there are no params to action`;

export const PresetCard: FC<PresetCardProps> = ({
  title,
  description,
  schedule,
  errorMessage,
  onSchedule,
  onRun,
  onDelete,
  onEdit,
  hasManagerPermission,
  isAppOnline,
  enableDelete = true,
  onClone,
  globalParams,
  preset,
  action,
  noParamsForThisAction,
  latestExecution,
}) => {
  const classes = useStyles({ errorMessage });

  const [isActionStarted, setIsActionStarted] = useState<boolean>(false);
  const [showQuickView, setShowQuickView] = useState<boolean>(false);

  const handleRunClick = async () => {
    if (action.sequentialExecute) {
      setIsActionStarted(true);
    }
    await onRun();
    setIsActionStarted(false);
  };

  const handleOnDelete = async () => {
    if (enableDelete && hasManagerPermission) {
      const shouldExecute = await showConfirmationDialog({
        entityNameSingular: '',
        entityNamePlural: '',
        actionName: 'Delete Preset?',
        customDescription: `Are you sure you want to delete ${title}?`,
      });

      shouldExecute && onDelete();
    }
  };
  const isSequentialExecutionInProgress = (latestExecution: { status: string }, action: CustomAppAction): boolean => {
    const isExecutionInProgress = latestExecution && latestExecution.status === 'IN_PROGRESS';
    const isMultipleExecutionNotAllowed = action.sequentialExecute;

    return (isExecutionInProgress && isMultipleExecutionNotAllowed) || isActionStarted;
  };
  const handleOnEdit = () => {
    !noParamsForThisAction && hasManagerPermission && onEdit();
  };
  const handleOnScheduleClick = () => {
    !shouldDisableExecutionButton && onSchedule();
  };
  const shouldDisableExecutionButton =
    !!errorMessage ||
    !isAppOnline ||
    !hasManagerPermission ||
    !action.isEnabled ||
    isSequentialExecutionInProgress(latestExecution, action);

  const handleOnClone = () => {
    !noParamsForThisAction && hasManagerPermission && !errorMessage && onClone();
  };

  const handleQuickView = () => {
    trackTPAEvent(TPATrackingEvents.TPA_ACTION_PRESET_PREVIEW_CLICK);
    setShowQuickView(!showQuickView);
  };

  const truncatedTitle = truncate(title, { length: MAX_TITLE_LENGTH });
  const truncatedDescription = truncate(description, { length: MAX_DESCRIPTION_LENGTH });
  const isEmptyPreviewState =
    !(globalParams.length || action.params.length) || (!action.params.length && preset.isDefaultPreset);
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.actions}>
          <div className={classes.textContainer}>
            <div className={classes.titleContainer}>
              <BigidTooltip placement={'top'} title={title} isDisabled={!(title.length > MAX_TITLE_LENGTH)}>
                <BigidHeading5 className={classes.title} data-aid="preset_name">
                  {truncatedTitle}
                </BigidHeading5>
              </BigidTooltip>
              {schedule && (
                <PresetScheduleInfo
                  schedule={schedule}
                  onClick={handleOnScheduleClick}
                  disabled={shouldDisableExecutionButton}
                />
              )}
              {latestExecution && <PresetLatestExecutionStatus latestExecution={latestExecution} />}
            </div>
            <div className={classes.descriptionWrapper}>
              <BigidTooltip
                placement={'top'}
                title={description}
                isDisabled={!(description?.length > MAX_DESCRIPTION_LENGTH)}
              >
                <span>
                  <BigidBody1 className={classes.description}>{truncatedDescription}</BigidBody1>
                </span>
              </BigidTooltip>
            </div>
          </div>
          <div className={classes.allButtonsContainer}>
            <div className={classes.iconsButtonsContainer}>
              <BigidTooltip title={'Delete'}>
                <span>
                  <BigidButtonIcon
                    icon={BigidDeleteIcon}
                    disabled={!enableDelete || !hasManagerPermission}
                    onClick={handleOnDelete}
                    dataAid="delete_preset"
                  />
                </span>
              </BigidTooltip>
              <BigidTooltip title={noParamsForThisAction ? getTooltipContentForNoParamsInTheApp('Clone') : 'Clone'}>
                <span>
                  <BigidButtonIcon
                    icon={BigidCopyIcon}
                    disabled={!hasManagerPermission || !!errorMessage || noParamsForThisAction}
                    onClick={handleOnClone}
                    dataAid="clone_preset"
                  />
                </span>
              </BigidTooltip>
              <BigidTooltip
                title={noParamsForThisAction ? getTooltipContentForNoParamsInTheApp('Edit') : 'Edit Params'}
              >
                <span>
                  <BigidButtonIcon
                    icon={BigidEditIcon}
                    onClick={handleOnEdit}
                    disabled={!hasManagerPermission || noParamsForThisAction}
                    dataAid="edit_preset"
                  />
                </span>
              </BigidTooltip>
              <BigidTooltip title={'Quick View'}>
                <span>
                  <BigidButtonIcon
                    icon={showQuickView ? BigidChevronCircleUpIcon : BigidChevronCircleDownIcon}
                    onClick={handleQuickView}
                    dataAid="quick_view"
                  />
                </span>
              </BigidTooltip>
            </div>
            <div className={classes.primaryButtonsContainer} data-aid="action-on-preset">
              <PrimaryButton
                size={'medium'}
                onClick={handleOnScheduleClick}
                disabled={shouldDisableExecutionButton}
                text={schedule ? 'Edit Schedule' : 'Schedule'}
              />
              <PrimaryButton
                size={'medium'}
                onClick={handleRunClick}
                color={'purple'}
                disabled={shouldDisableExecutionButton}
                data-aid="run_preset"
                text="Run"
              />
            </div>
          </div>
        </div>

        <Collapse in={showQuickView}>
          {showQuickView && (
            <>
              <div className={classes.quickView}>
                {!isEmptyPreviewState && (
                  <EditActionPreset readOnly action={action} globalParams={globalParams} preset={preset} />
                )}
              </div>
              {isEmptyPreviewState && <PresetEmptyState />}
            </>
          )}
        </Collapse>
      </div>

      {errorMessage && <div className={classes.errorMessageContainer}>{errorMessage}</div>}
    </div>
  );
};
