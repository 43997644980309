import { BigidAnimationPlayer, BigidAnimationComponentProps, BigidBody1 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import React, { FC } from 'react';

interface NavigationGuidedTourStepProps {
  lottieJson: BigidAnimationComponentProps['lottieJson'];
  textContent: string;
}

const PlayerContainer = styled('div')({
  marginBottom: '24px',
  background: 'linear-gradient(75deg, #6C45CE 0%, #120F93 66.15%)',
  borderRadius: 4,
  overflow: 'hidden',

  '& svg': {
    verticalAlign: 'top',
  },
});

export const NavigationGuidedTourStep: FC<NavigationGuidedTourStepProps> = ({ lottieJson, textContent }) => {
  return (
    <>
      <PlayerContainer>
        <BigidAnimationPlayer lottieJson={lottieJson} />
      </PlayerContainer>
      <BigidBody1>{textContent}</BigidBody1>
    </>
  );
};
