import React, { FC, useMemo } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidGrid, BigidGridColumn, BigidGridColumnTypes, BigidGridProps } from '@bigid-ui/grid';
import {
  BigidHeading6,
  BigidHeading1,
  BigidLoader,
  BigidChip,
  BigidColorsV2,
  ToolbarAction,
  BigidPaper,
  BigidCategoriesArea,
  BigidCategoryChipProps,
  BigidTag,
} from '@bigid-ui/components';
import { BigidInfoIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import { AggregationItemName, ObjectPreviewEntity } from '../../../catalogDiscoveryTypes';
import { useLocalTranslation } from '../translations';
import { GridBasedWidgetNoData } from '../../../utils/GridBasedWidgetNoData/GridBasedWidgetNoData';
import { getObjectTypeCellDisplayValue } from '../dataSourcesLayoutUtils';
import { UseCatalogDiscoveryResponse } from '../../../useCatalogDiscovery';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { SensitivityPieChart } from '../../SensitivityPieChart/SensitivityPieChart';
import { RiskGroupsBarChart } from '../../RiskGroupsBarChart/RiskGroupsBarChart';
import { AttributesLayout } from '../../AttributesLayout/AttributesLayout';
import { useDataSourceSidePanelContent } from './useDataSourcesSidePanel';
import { formatNumberCompact } from '../../../../../utilities/numericDataConverter';

export interface DataSourceSidePanelProps extends Pick<UseCatalogDiscoveryResponse, 'filter'> {
  dataAid?: string;
  dataSourceName: AggregationItemName;
}

const Root = styled('div')`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
`;

const Content = styled('div')<{ isBusy: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  ${({ isBusy }) =>
    isBusy &&
    `
    visibility: hidden;
  `}
`;

const Section = styled('div')<{ direction?: 'row' | 'column'; gap?: number; height?: number }>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'column'};
  ${({ height }) => height && `min-height: ${height}px;`};
  gap: ${({ gap }) => `${gap ?? 0}px`};
`;

const WidgetWrapper = styled('div')<{ width: string }>`
  display: flex;
  width: ${({ width }) => width || 'auto'};
  height: 100%;
`;

const SectionTitle = styled(BigidHeading6)`
  margin-bottom: 8px;
`;

const SectionBody = styled('div')<{ minHeight?: number }>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  ${({ minHeight }) =>
    minHeight &&
    `
    min-height: ${minHeight}px
  `}
`;

const TotalObjects = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DataSourceSidePanel: FC<DataSourceSidePanelProps> = ({
  dataAid = 'DataSourceSidePanel',
  dataSourceName,
  filter,
}) => {
  const { t } = useLocalTranslation('sidePanel');
  const {
    useFetchState,
    dataSource,
    fullQueryComputed,
    handleWidgetDataLoadingStatusChanged,
    isRiskWidgetAvailable,
    isSensitivityWidgetAvailable,
    sectionsDataLoadingStatus,
    totalObjects,
  } = useDataSourceSidePanelContent({
    dataSourceName,
    filter,
  });

  const columns: BigidGridColumn<ObjectPreviewEntity>[] = useMemo(
    () => [
      {
        name: 'objectName',
        title: t('content.objectsPreview.columns.objectName.title'),
        getCellValue: ({ objectName }) => objectName,
        type: BigidGridColumnTypes.TEXT,
        width: 210,
      },
      {
        name: 'fullObjectName',
        title: t('content.objectsPreview.columns.fullObjectName.title'),
        getCellValue: ({ fullObjectName }) => fullObjectName,
        type: BigidGridColumnTypes.TEXT,
        width: 210,
      },
      {
        name: 'containerName',
        title: t('content.objectsPreview.columns.containerName.title'),
        getCellValue: ({ containerName }) => containerName,
        type: BigidGridColumnTypes.TEXT,
        width: 210,
      },
      {
        name: 'owner',
        title: t('content.objectsPreview.columns.objectOwner.title'),
        getCellValue: ({ owner }) => owner,
        type: BigidGridColumnTypes.TEXT,
        width: 125,
      },
      {
        name: 'objectType',
        title: t('content.objectsPreview.columns.objectType.title'),
        getCellValue: ({ objectType }) => getObjectTypeCellDisplayValue(objectType),
        type: BigidGridColumnTypes.TEXT,
        width: 125,
      },
    ],
    [t],
  );

  const inlineActions: ToolbarAction[] = useMemo(
    () => [
      {
        label: t('content.objectsPreview.columns.inlineInfoAction.title'),
        isTooltipFollowCursor: true,
        show: () => true,
        execute: async ({ selectedRows }) => {
          const { fullyQualifiedName, objectName } = selectedRows[0];

          $state.go(CONFIG.states.CATALOG_PREVIEW, {
            id: encodeURIComponent(fullyQualifiedName),
            name: encodeURIComponent(objectName),
          });

          return { shouldGridReload: false };
        },
        disable: () => {
          return false;
        },
        isInline: true,
        hideActionInToolBar: true,
        icon: BigidInfoIcon,
      },
    ],
    [t],
  );

  const gridConfig: BigidGridProps<ObjectPreviewEntity> = useMemo(
    () => ({
      columns,
      apiRef: useFetchState.apiRef,
      rows: useFetchState.rows as ObjectPreviewEntity[],
      skip: useFetchState.skip,
      totalRowsCount: useFetchState.totalRowsCount,
      showSortingControls: false,
      onPagingChanged: useFetchState.onPagingChanged,
      onSortingChanged: useFetchState.onSortingChanged,
      onFiltersChange: useFetchState.onFiltersChanged,
      defaultSorting: useFetchState.defaultSorting,
      loading: useFetchState.isLoading,
      inlineActions,
      noDataContent: (
        <GridBasedWidgetNoData
          dataAid={generateDataAid(dataAid, ['content', 'objects-preview', 'body', 'grid', 'no-data'])}
        />
      ),
    }),
    [columns, useFetchState, inlineActions, dataAid],
  );

  const { hasCategories, hasTags, hasApplications, isReady, categoryChips, tagsData } = useMemo(() => {
    const hasCategories = dataSource?.categoryNames?.length > 0;
    const hasTags = dataSource?.tagNames?.length > 0;
    const hasApplications = dataSource?.applicationNames?.length > 0;

    const categoryChips: BigidCategoryChipProps[] = dataSource?.categories.map(({ name, color }) => {
      return {
        categoryName: name,
        categoryColor: color,
        dataAid: generateDataAid('BigidCategoryChip', [name]),
      };
    });

    const tagsData = dataSource?.tagNames?.map(tag => {
      const [tagName, tagValue] = tag.split(':');
      return {
        nameAndValue: tag,
        name: tagName,
        value: tagValue,
      };
    });

    return {
      hasCategories,
      hasTags,
      hasApplications,
      isReady: Object.values(sectionsDataLoadingStatus).every(isLoaded => isLoaded),
      categoryChips,
      tagsData,
    };
  }, [
    dataSource?.applicationNames?.length,
    dataSource?.categories,
    dataSource?.categoryNames?.length,
    dataSource?.tagNames,
    sectionsDataLoadingStatus,
  ]);

  return (
    <Root data-aid={dataAid}>
      {!isReady && <BigidLoader dataAid={generateDataAid(dataAid, ['loader'])} size={42} thickness={6} />}
      {typeof fullQueryComputed === 'string' && (
        <Content data-aid={generateDataAid(dataAid, ['content'])} isBusy={!isReady}>
          <>
            <Section direction="row">
              <TotalObjects>
                <BigidHeading1 data-aid={generateDataAid(dataAid, ['content', 'total', 'objects'])}>
                  {formatNumberCompact(totalObjects)}
                </BigidHeading1>
                <BigidHeading6>{t('content.totalObjects.title')}</BigidHeading6>
              </TotalObjects>
            </Section>
            {(isSensitivityWidgetAvailable || isRiskWidgetAvailable) && (
              <Section direction="row" gap={24} height={175}>
                {isSensitivityWidgetAvailable && (
                  <WidgetWrapper width="50%">
                    <BigidPaper>
                      <SensitivityPieChart
                        dataAid={generateDataAid(dataAid, ['content', 'sensitivity', 'pie', 'chart'])}
                        isPageInitialised
                        onDataFetchStatusChange={handleWidgetDataLoadingStatusChanged}
                        query={fullQueryComputed}
                        size="lg"
                        width="auto"
                        legendOffset={35}
                      />
                    </BigidPaper>
                  </WidgetWrapper>
                )}
                {isRiskWidgetAvailable && (
                  <WidgetWrapper width="50%">
                    <BigidPaper>
                      <RiskGroupsBarChart
                        dataAid={generateDataAid(dataAid, ['content', 'risk', 'bar', 'chart'])}
                        isPageInitialised
                        onDataFetchStatusChange={handleWidgetDataLoadingStatusChanged}
                        query={fullQueryComputed}
                      />
                    </BigidPaper>
                  </WidgetWrapper>
                )}
              </Section>
            )}
            {hasCategories && (
              <Section data-aid={generateDataAid(dataAid, ['content', 'categories'])}>
                <SectionTitle data-aid={generateDataAid(dataAid, ['content', 'categories', 'title'])}>
                  {t('content.categories.title')}
                </SectionTitle>
                <SectionBody data-aid={generateDataAid(dataAid, ['content', 'categories', 'body'])}>
                  <BigidCategoriesArea
                    dataAid={generateDataAid(dataAid, ['content', 'categories', 'body', 'chips'])}
                    value={categoryChips}
                    isMultiLineMode
                  />
                </SectionBody>
              </Section>
            )}
            <Section data-aid={generateDataAid(dataAid, ['content', 'attributes'])}>
              <SectionBody data-aid={generateDataAid(dataAid, ['content', 'attributes', 'body'])}>
                <AttributesLayout
                  dataAid={generateDataAid(dataAid, ['attributes'])}
                  query={fullQueryComputed}
                  onDataFetchStatusChange={handleWidgetDataLoadingStatusChanged}
                  isPageInitialised
                  isClickable={false}
                />
              </SectionBody>
            </Section>
            {hasTags && (
              <Section data-aid={generateDataAid(dataAid, ['content', 'tags'])}>
                <SectionTitle data-aid={generateDataAid(dataAid, ['content', 'tags', 'title'])}>
                  {t('content.tags.title')}
                </SectionTitle>
                <SectionBody data-aid={generateDataAid(dataAid, ['content', 'tags', 'body'])}>
                  {tagsData.map(tag => (
                    <BigidTag
                      key={tag.nameAndValue}
                      dataAid={generateDataAid(dataAid, ['content', 'tags', 'body', tag.nameAndValue])}
                      name={tag.name}
                      value={tag.value}
                    />
                  ))}
                </SectionBody>
              </Section>
            )}
            {hasApplications && (
              <Section data-aid={generateDataAid(dataAid, ['content', 'applications'])}>
                <SectionTitle data-aid={generateDataAid(dataAid, ['content', 'applications', 'title'])}>
                  {t('content.applications.title')}
                </SectionTitle>
                <SectionBody data-aid={generateDataAid(dataAid, ['content', 'applications', 'body'])}>
                  {dataSource.applicationNames.map((application, index) => (
                    <BigidChip
                      key={index}
                      dataAid={generateDataAid(dataAid, ['content', 'applications', 'body', application])}
                      label={application}
                      bgColor={BigidColorsV2.white}
                      size="small"
                      shadow
                    />
                  ))}
                </SectionBody>
              </Section>
            )}
            <Section data-aid={generateDataAid(dataAid, ['content', 'objects-preview'])}>
              <SectionTitle data-aid={generateDataAid(dataAid, ['content', 'objects-preview', 'title'])}>
                {t('content.objectsPreview.title')}
              </SectionTitle>
              <SectionBody data-aid={generateDataAid(dataAid, ['content', 'objects-preview', 'body'])} minHeight={325}>
                <BigidGrid
                  dataAid={generateDataAid(dataAid, ['content', 'objects-preview', 'body', 'grid'])}
                  {...gridConfig}
                />
              </SectionBody>
            </Section>
          </>
        </Content>
      )}
    </Root>
  );
};
