import { styled } from '@mui/material';
import React from 'react';
import { ShareUrl } from '../../ShareUrl';
import { SortingControl } from './SortingControl';
import { ItemsCount } from './Toolbars/components/ItemsCount';
import { useDataCatalogSearchResultsContext } from '../../contexts/dataCatalogSearchResultsContext';

const ControlsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`;

const SectionContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const AlienEntityControls = () => {
  const {
    state: { gridConfig },
  } = useDataCatalogSearchResultsContext();
  const { totalCount } = gridConfig;

  return (
    <ControlsContainer>
      <SectionContainer>
        <ItemsCount totalCount={totalCount} />
        <ShareUrl />
      </SectionContainer>
      <SortingControl />
    </ControlsContainer>
  );
};
