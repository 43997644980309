import { openSystemDialog } from '../../services/systemDialogService';
import React, { useState, useEffect, FC, Dispatch } from 'react';
import { BigidHeading4, BigidPrimaryCheckbox, PrimaryButton } from '@bigid-ui/components';
import { betaToolsService } from '../../services/angularServices';
import { notificationService } from '../../services/notificationService';
import angular from 'angular';
import { convertToAngular } from '../../../common/services/convertToAngular';
import makeStyles from '@mui/styles/makeStyles';

interface DeletePIIDataDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleDeletePIIData: () => void;
}

const useStyles = makeStyles({
  content: {
    marginTop: '0px',
    marginBottom: '16px',
  },
});

interface DeletePIIDataDialogContentProps {
  deletePersonalInfo: boolean;
  deleteMetadata: boolean;
  deleteScanHistory: boolean;
  stopAllRunningScans: boolean;
  setDeletePersonalInfo: Dispatch<boolean>;
  setDeleteMetadata: Dispatch<boolean>;
  setDeleteScanHistory: Dispatch<boolean>;
  setStopAllRunningScans: Dispatch<boolean>;
}

const DeletePIIDataDialogContent: FC<DeletePIIDataDialogContentProps> = ({
  deletePersonalInfo,
  deleteMetadata,
  deleteScanHistory,
  stopAllRunningScans,
  setDeletePersonalInfo,
  setDeleteMetadata,
  setDeleteScanHistory,
  setStopAllRunningScans,
}) => {
  const classes = useStyles({});
  return (
    <>
      <div className={classes.content}>
        <BigidHeading4 gutterBottom>Warning: this cannot be undone.</BigidHeading4>
      </div>
      <BigidPrimaryCheckbox
        label={'All personal information will be deleted'}
        checked={deletePersonalInfo}
        onChange={(event, checked) => setDeletePersonalInfo(checked)}
      />
      <BigidPrimaryCheckbox
        label={'All metadata will be deleted'}
        checked={deleteMetadata}
        onChange={(event, checked) => setDeleteMetadata(checked)}
      />
      <BigidPrimaryCheckbox
        label={'Scan history will be deleted and running scans will be distorted'}
        checked={deleteScanHistory}
        onChange={(event, checked) => setDeleteScanHistory(checked)}
      />
      <BigidPrimaryCheckbox
        label={'All Scans will be stopped (Optional. This may take a while)'}
        checked={stopAllRunningScans}
        onChange={(event, checked) => setStopAllRunningScans(checked)}
      />
    </>
  );
};

const deleteAndNotify = async (handleDeletePIIData: () => void, stopAllRunningScans: boolean) => {
  try {
    await betaToolsService.deleteData(stopAllRunningScans);
    notificationService.success('PII Data has been deleted successfully! ');
    setTimeout(handleDeletePIIData, 30000);
  } catch {
    notificationService.error('An error has occurred!');
  } finally {
    handleDeletePIIData();
  }
};

export const DeletePIIDataDialog: FC<DeletePIIDataDialogProps> = ({ handleDeletePIIData, isOpen, onClose }) => {
  const [deletePersonalInfo, setDeletePersonalInfo] = useState(false);
  const [deleteMetadata, setDeleteMetadata] = useState(false);
  const [deleteScanHistory, setDeleteScanHistory] = useState(false);
  const [stopAllRunningScans, setStopAllRunningScans] = useState(false);

  const disabled = !(deletePersonalInfo && deleteMetadata && deleteScanHistory);

  const deletePIIDataDialog: () => void = () => {
    openSystemDialog<DeletePIIDataDialogContentProps>({
      title: 'Delete PII DATA',
      onClose: () => {
        onClose();
        setDeletePersonalInfo(false);
        setDeleteMetadata(false);
        setDeleteScanHistory(false);
        setStopAllRunningScans(false);
      },
      content: DeletePIIDataDialogContent,
      contentProps: {
        deletePersonalInfo,
        deleteMetadata,
        deleteScanHistory,
        stopAllRunningScans,
        setDeletePersonalInfo,
        setDeleteMetadata,
        setDeleteScanHistory,
        setStopAllRunningScans,
      },
      buttons: [
        {
          text: "DON'T DELETE PII DATA",
          component: PrimaryButton,
          onClick: () => {
            return null;
          },
          isClose: true,
        },
        {
          text: 'YES, DELETE PII DATA',
          component: PrimaryButton,
          onClick: () => deleteAndNotify(handleDeletePIIData, stopAllRunningScans),
          isClose: true,
          disabled: disabled,
        },
      ],
    });
  };

  useEffect(() => {
    if (isOpen) {
      deletePIIDataDialog();
    }
  }, [isOpen, deletePIIDataDialog]);
  return null;
};

angular
  .module('app')
  .component(
    'deletePiiDataDialog',
    convertToAngular<DeletePIIDataDialogProps>(DeletePIIDataDialog, ['isOpen', 'onClose', 'handleDeletePIIData']),
  );
