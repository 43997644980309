import React, { FC } from 'react';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { styled } from '@mui/system';
import { AreaTitle } from './AreaTitle';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 16px;
  border-radius: 4px;
`;

const StyledTextArea = styled(TextareaAutosize)`
  flex-grow: 1;
  border: none;
  resize: none;
  max-height: 275px;
`;

export type TextAreaProps = Pick<TextareaAutosizeProps, 'placeholder' | 'value'> & {
  title: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
};

export const TextArea: FC<TextAreaProps> = ({ value, onChange, placeholder, title, disabled }) => {
  return (
    <Container>
      <AreaTitle title={title} disabled={disabled} />
      <StyledTextArea
        placeholder={placeholder}
        readOnly={disabled}
        value={value}
        onChange={onChange}
        draggable={false}
      />
    </Container>
  );
};
