import { isFunction, uniqueId } from 'lodash';

export type ValidationRuleMessage<T> = T extends {
  id: string;
  validate(value: unknown): boolean;
  message: infer R;
}
  ? R
  : string;

export type ValidationRule<TValue, TInfo = unknown> =
  | {
      id: string;
      validate(value: TValue, info?: TInfo): Promise<boolean> | boolean;
      message: string | ((value: string) => string);
    }
  | ((value?: TValue) => { isValid: boolean; message: string });

export type ValidationError = { message: string };

export type ValidationResult<TInfo = unknown> = {
  isValid: boolean;
  errors?: Array<ValidationError>;
  info?: TInfo;
};

export type Validator<TValue = unknown, TInfo = unknown> = {
  validate(value: TValue, rules: Array<string>, info?: TInfo): Promise<ValidationResult>;
  addRule(rule: ValidationRule<TValue, TInfo>): string;
  clearRules(rules: string[]): void;
};

// @info add common rule set here to be reused
export const DEFAULT_RULES: Record<string, ValidationRule<unknown>> = {} as const;

const defaultRuleMap: Record<string, ValidationRule<unknown>> = { ...DEFAULT_RULES };

const addValidationRule = (rule: ValidationRule<unknown>) => {
  if (!isFunction(rule)) {
    defaultRuleMap[rule.id] = rule;
  }
};

const validate = <TValue = unknown, TInfo = unknown>(
  value: TValue,
  rules: Array<string>,
  info?: TInfo,
): Promise<ValidationResult<TInfo>> =>
  rules.length
    ? rules.reduce<Promise<ValidationResult<TInfo>>>(async (acc, rule) => {
        const asyncAccResolved = await acc;
        const condition = defaultRuleMap[rule] ?? { validate: () => true };
        const { isValid, message } = isFunction(condition)
          ? condition(value)
          : {
              isValid: await Promise.resolve(condition.validate(value, info)),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              message: (condition as any)?.message as ValidationRuleMessage<typeof condition>,
            };
        const errors: Array<ValidationError> = isValid
          ? asyncAccResolved.errors
          : [
              ...(asyncAccResolved.errors ?? []),
              { message: isFunction(message) ? message(value as unknown as string) : message },
            ];

        return {
          ...asyncAccResolved,
          isValid: asyncAccResolved.isValid ? isValid : false,
          errors,
          info,
        };
      }, Promise.resolve({ isValid: true, errors: null }))
    : Promise.resolve({ isValid: true, errors: null, info });

export const getValidator = <TValue = unknown>(): Validator<TValue> => ({
  validate: validate<TValue, unknown>,
  addRule(rule: ValidationRule<TValue>): string {
    if (!isFunction(rule)) {
      if (!defaultRuleMap[rule.id]) {
        addValidationRule(rule);
      } else {
        console.error(`getValidator: Rule with id ${rule.id} already exists`);
      }

      return rule.id;
    } else {
      const ruleId = uniqueId();

      const newRule = {
        id: ruleId,
        validate: (value: TValue) => {
          const { isValid } = rule(value as unknown as TValue);
          return isValid;
        },
        message: (value: string) => {
          const { message } = rule(value as unknown as TValue);
          return message;
        },
      };

      addValidationRule(newRule);

      return ruleId;
    }
  },
  clearRules: (rules: string[]) => rules.forEach(rule => delete defaultRuleMap[rule]),
});

export function isNameValid(name = '') {
  const pattern = new RegExp(/^[\w\-\s():]+$/);
  return pattern.test(name) && name.trim().length === name.length;
}
