import React from 'react';
import { BusinessGlossaryRecord, BusinessObject, GlossaryRecordStatus } from './types';
import { randCompanyName, randDepartment, randWord, randSentence, randNumber, randAccount } from '@ngneat/falso';
import { v4 } from 'uuid';
import { BigidShieldIcon, BigidTagIcon } from '@bigid-ui/icons';
import { BigidColorsV2, BigidEditableTagAreaValue } from '@bigid-ui/components';
import { TagsFormatterProps } from '@bigid-ui/grid';
import { appsLicenseService, AppStatus } from '../../services/appsLicenseService';

const STEWARDSHIP_APP_ID = 'BigID.Data Stewardship';

function getRandomElement<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

const generateCategories = (): string[] => {
  const length = Math.floor(Math.random() * (3 + 1));
  return Array.from({ length }, () => randDepartment());
};

const generateTags = (): TagsFormatterProps => {
  const length = Math.floor(Math.random() * (3 + 1));
  const value: BigidEditableTagAreaValue = Array.from({ length }, () => {
    const Icon = getRandomElement([BigidTagIcon, BigidShieldIcon]);
    return {
      id: v4(),
      name: randWord(),
      icon: <Icon />,
    };
  });

  return {
    tags: {
      value,
      tagBgColor: BigidColorsV2.purple[50],
      isDisabled: true,
    },
  };
};

const generateStatus = (): GlossaryRecordStatus => {
  const statuses: GlossaryRecordStatus[] = [
    'draft',
    'underReview',
    'approved',
    'rejected',
    'sentForApproval',
    'pendingAttestation',
  ];
  return getRandomElement(statuses);
};

const generateBusinessGlossaryRecord = (): BusinessGlossaryRecord => {
  const term = getRandomElement(['attribute', 'businessTerm']);
  return {
    businessObjectType: term as BusinessObject,
    objectName: randCompanyName(),
    id: v4(),
    friendlyName: randAccount(),
    categories: generateCategories(),
    domain: randWord(),
    definition: randWord(),
    type: randWord(),
    description: randSentence(),
    manualTags: generateTags(),
    status: generateStatus(),
    attributesCount: randNumber({ min: 0, max: 30 }),
    itAssetsCount: randNumber({ min: 0, max: 30 }),
    termsCount: randNumber({ min: 0, max: 30 }),
    project: randWord(),
  };
};

export const generateFakeGridData = (count: number): BusinessGlossaryRecord[] => {
  return Array.from({ length: count }, generateBusinessGlossaryRecord);
};

class FakeSelectionSingleton {
  private isSelected = false;

  public getIsSelected = (): boolean => {
    return this.isSelected;
  };

  public setIsSelected = (isSelected: boolean): void => {
    this.isSelected = isSelected;
  };
}

export const fakeSelection = new FakeSelectionSingleton();

export const isStewardshipPaid = (): boolean => {
  const license = appsLicenseService.getAppsLicense().find(({ id }) => id === STEWARDSHIP_APP_ID);

  return license?.status === AppStatus.PAID ?? false;
};
