import React, { FC, useContext } from 'react';
import { AutoDiscoveryWizardContext } from '../../autoDiscoveryWizardContext';
import {
  AutoDiscoveryInfoListItem,
  AutoDiscoveryInfoListOrdered,
} from '../AutoDiscoveryComponents/autoDiscoverInfoStyledComponents';
import { getDiscoverAuthTypeString } from '../../autoDiscoveryWizardFormUtils';
import { HowToContentOptionTypes, TYPE_TO_HOW_TO_CONTENT } from '../../../constants/howTo';

export interface AutoDiscoverHowToContentOrderedListProps {
  items: string[];
}

const AutoDiscoverHowToContentOrderedList: FC<AutoDiscoverHowToContentOrderedListProps> = ({ items }) => {
  return (
    <AutoDiscoveryInfoListOrdered>
      {items.map((item, index) => (
        <AutoDiscoveryInfoListItem key={index}>{item}</AutoDiscoveryInfoListItem>
      ))}
    </AutoDiscoveryInfoListOrdered>
  );
};

const contentTypeToComponentMap = {
  [HowToContentOptionTypes.NumberedList]: AutoDiscoverHowToContentOrderedList,
};

export const AutoDiscoverWizardAsideGuideHowTo: FC = () => {
  const { discoveryConfigData } = useContext(AutoDiscoveryWizardContext);
  const authTypeValue = getDiscoverAuthTypeString(discoveryConfigData.formData?.authType);
  const data = TYPE_TO_HOW_TO_CONTENT[discoveryConfigData.type]?.[authTypeValue];
  const Component = data?.type && contentTypeToComponentMap[data.type];

  return Component ? <Component items={data.content as string[]} /> : <div>TBD</div>;
};
