import React from 'react';
import styled from '@emotion/styled';
import { BigidAddIcon, BigidDataSourceIllustration } from '@bigid-ui/icons';
import { BigidBody1, PrimaryButton } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';
import { AutoDiscoveryConfigModel } from './AutoDiscoveryGridConfiguration';
import { CloudProvider } from '../constants';

const AutoDiscoveryNoDataContentWrapper = styled('div')`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 90%;
  position: absolute;
  & > svg {
    margin-bottom: 20px;
  }
  opacity: 0;
  animation: fadeInAnimation 500ms forwards;
  @keyframes fadeInAnimation {
    to {
      opacity: 1;
    }
  }
`;

export interface AutoDiscoveryNoDataContentProps {
  configsListRef: React.MutableRefObject<AutoDiscoveryConfigModel[]>;
  openAutoDiscoveryModal: (
    type?: CloudProvider,
    defaultPreset?: AutoDiscoveryConfigModel,
    defaultPresetMultiData?: AutoDiscoveryConfigModel,
  ) => void;
  isAutoDiscoveryAvailable: boolean;
}

export const AutoDiscoveryNoDataContent: React.FC<AutoDiscoveryNoDataContentProps> = ({
  configsListRef,
  openAutoDiscoveryModal,
  isAutoDiscoveryAvailable,
}) => {
  const { t } = useLocalTranslation('AutoDiscovery');
  return (
    <AutoDiscoveryNoDataContentWrapper data-aid="AutoDiscoveryNoDataContent">
      <BigidDataSourceIllustration />
      <BigidBody1>{t('noDataContent.description')}</BigidBody1>
      <PrimaryButton
        onClick={() => {
          if (configsListRef.current?.length) {
            openAutoDiscoveryModal();
          }
        }}
        dataAid={'add-auto-discovery'}
        size={'large'}
        disabled={!isAutoDiscoveryAvailable}
        startIcon={<BigidAddIcon />}
        text={t('noDataContent.start')}
      />
    </AutoDiscoveryNoDataContentWrapper>
  );
};
